
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { Bill } from '../../../store/pages/pointOfSale/types';
import { ITranslationContext } from '../../../translations';


export interface BillTotalsProps {
    bill: Bill;
}

const BillTotals = (props: BillTotalsProps, context: ITranslationContext) => {

    const { bill } = props;
    const { t } = context;

    const outstanding = bill.outstandingBalance;
    const depositAmount = bill.depositAmount || 0;

    const deposit = depositAmount === 0 ? null : <div className='underline'><span className='pos_total_label'>{t('PointOfSale:deposit')}</span><span className={`pos_total_value ${(bill.paidAmount >= depositAmount ? 'text-success' : 'text-danger')}`}>{`${t('Global:currencySymbol')}${bill.depositAmount.toFixed(2)}`}</span></div>;

    const taxBreakdown = bill.taxBreakdown.length === 1
        ? <><div><span className='pos_total_label'>{t('PointOfSale:tax')} ({bill.taxBreakdown[0].rate.toFixed(2)}%)</span><span className='pos_total_value'>{`${t('Global:currencySymbol')}${bill.totalTaxAmount.toFixed(2)}`}</span></div></>
        : <>
            <div key='tax'><span className='pos_total_label'>{t('PointOfSale:tax')}</span><span className='pos_total_value'>{`${t('Global:currencySymbol')}${bill.totalTaxAmount.toFixed(2)}`}</span></div>
            {bill.taxBreakdown.map(b => <div key={`tax_${b.rate}`}><span className='pos_total_label' style={{ marginLeft: '10px' }}>{b.rate.toFixed(2)}% ({t('Global:currencySymbol')}{b.rateTotal.toFixed(2)})</span><span className='pos_total_value'>{`${t('Global:currencySymbol')}${b.rateTaxTotal.toFixed(2)}`}</span></div>)}
        </>
    return (
        <div className='pos_totals'>
            <div className='underline pos_main_total'><span className='pos_total_label'>{t('PointOfSale:total')}</span><span className='pos_total_value'>{`${t('Global:currencySymbol')}${bill.totalAmount.toFixed(2)}`}</span></div>
            {taxBreakdown}
            {deposit}
            <div className='underline'><span className='pos_total_label'>{t('PointOfSale:paid')}</span><span className='pos_total_value'>{`${t('Global:currencySymbol')}${bill.paidAmount.toFixed(2)}`}</span></div>
            <div className='pos_main_total text-danger'><span className='pos_total_label'>{t('PointOfSale:balance')}</span><span className='pos_total_value'>{`${t('Global:currencySymbol')}${outstanding.toFixed(2)}`}</span></div>
        </div>
    );
}

BillTotals.contextTypes = {
    t: PropTypes.func
}

export default BillTotals;