
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { RegistrationEventBookingNote } from '../../../store/pages/reception/types';
import { ITranslationContext } from '../../../translations';
import { clickHandler } from '../../../utils/util';


interface BookingNotesOverlayProps {
    notes: RegistrationEventBookingNote[];
    bookingName: string;
    billNumber: string;
    close: () => void;
}

const BookingNotesOverlay = (props: BookingNotesOverlayProps, context: ITranslationContext) => {
    return <div className='reception-booking-notes-overlay'>
        <h3 className='center'>{context.t('ReceptionPage:bookingNotesHeading', {name: props.bookingName, billNumber: props.billNumber}) }</h3>

        {props.notes.map(n => <p>{n.text}</p>)}

        <div className='btn-toolbar' style={({ marginTop: '20px' })}>
            <button className='btn btn-basic' onClick={e => clickHandler(e, props.close)}>{context.t('Global:close')}</button>
        </div>
    </div>;
}

BookingNotesOverlay.contextTypes = {
    t: PropTypes.func
}

export default BookingNotesOverlay;