
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'

import * as api from '../../../store/apiClient';
import * as VenueActions from '../../../store/pages/venues/actions';
import * as LoginActions from '../../../store/pages/login/actions';
import { ApplicationState } from '../../../store';
import { isNullOrEmpty, mapLocalDateTime, mapUtcDate } from '../../../utils/util';
import Loading from '../../global/loading';
import Pagination from '../../global/pagination';
import RecentPayment from './recentPayment'
import { DateFormat, TimeFormat, Venue } from '../../../store/pages/venues/types';
import { PaymentStatus } from '../../../store/pages/pointOfSale/types';
import * as ht from './types'

interface LocalProps { }

interface LocalState {
    selectedVenueId: string;
    venue?: Venue;
}

interface Actions {
    loadVenues: () => void;
    logout: () => void;
}

type RecentPaymentsProps = LocalState & Actions & LocalProps;


interface RecentPaymentsResult {
    payments: ht.RecentPayment[];
    currentPage: number;
    moreResultsAvailable: boolean;
}

interface RecentPaymentsState {
    isLoading: boolean;
    payments: ht.RecentPayment[];
    pageNumber: number;
    maxPage: number;
    pageSize: number;
    error: string | null;
}

class RecentPayments extends React.Component<RecentPaymentsProps, RecentPaymentsState> {
    constructor(props: RecentPaymentsProps) {
        super(props);

        this.state = { isLoading: false, pageNumber: 1, pageSize: 25, maxPage: 0, payments: [], error: null };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        const { selectedVenueId, loadVenues } = this.props;
        if (isNullOrEmpty(selectedVenueId)) {
        } else {
            const { pageNumber, pageSize } = this.state;
            this.loadPayments(pageNumber, pageSize);
        }
    }

    componentDidUpdate(prevProps: RecentPaymentsProps) {
        if (prevProps.selectedVenueId !== this.props.selectedVenueId) {
            const { pageNumber, pageSize } = this.state;
            this.loadPayments(pageNumber, pageSize);
        }
    }

    setPage = (pageNumber: number) => this.loadPayments(pageNumber, this.state.pageSize);

    setPageSize = (pageSize: number) => this.loadPayments(this.state.pageNumber, pageSize);

    loadPayments = (pageNumber: number, pageSize: number) => {
        this.setState({ isLoading: true, pageNumber: pageNumber, pageSize: pageSize });

        const { selectedVenueId, logout } = this.props;

        const url = `api/v1/payments/${selectedVenueId}/recent/?pageNumber=${pageNumber}&pageSize=${pageSize}`;
        api.getWithAuth<RecentPaymentsResult>(url, logout)
            .subscribe(
                resp => {
                    const response = resp as RecentPaymentsResult;
                    this.setState({
                        payments: response.payments.map(p => ({
                            ...p,
                            eventDate: mapLocalDateTime(p.eventDate),
                            payentTakenDateTime: mapUtcDate(p.payentTakenDateTime),
                            lastPaymentAttempt: mapUtcDate(p.lastPaymentAttempt)
                        })), maxPage: response.moreResultsAvailable ? response.currentPage + 1 : response.currentPage, isLoading: false
                    });
                },
                e => {
                    // TODO: Handle refresh token error & logout
                    this.setState({ error: e.toString(), payments: [], maxPage: 0, pageSize: 0, pageNumber: 0, isLoading: false });
                }
            );
    }

    render() {
        const { t } = this.context;
        const { maxPage, pageSize, pageNumber} = this.state;

        return (
            <div className='sub-section-wrapper'>
                <section className='due-payments-list'>
                    <header className='section-header'>
                        <div className='page-heading'>
                            <h3 className='due-payments-title'>{t('RecentPayments:heading')}</h3>
                        </div>
                    </header>
                    <div className='sub-section-panel sub-section-panel-240px'>
                        {this.state.isLoading ? <Loading /> : this.renderPayments()}
                        <Pagination maxPage={maxPage} pageSize={pageSize} pageNumber={pageNumber} setPage={this.setPage} setPageSize={this.setPageSize} />
                    </div>
                </section>
            </div>
        );
    }

    renderPayments = () => {
        const { t } = this.context;
        const { selectedVenueId, venue } = this.props;
        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;

        const payments = this.state.payments.map((pymt: ht.RecentPayment) => {
            const paidDateTime = pymt.payentTakenDateTime || pymt.lastPaymentAttempt;

            let status: JSX.Element | null = null;
            if (pymt.void) {
                status = <span className='label label-danger'>{t('Global:void')}</span>
            //} else if (pymt.gatewayPaymentStatus !== null && pymt.gatewayPaymentStatus !== GatewayPaymentStatus.None && pymt.gatewayPaymentStatus !== GatewayPaymentStatus.Success) {
            //    status = <span className='label label-warning'>{t(`GatewayPaymentStatus:${GatewayPaymentStatus[pymt.gatewayPaymentStatus]}`)}</span>
            } else if (pymt.paymentStatus !== PaymentStatus.None && pymt.paymentStatus !== PaymentStatus.Success && pymt.paymentStatus !== PaymentStatus.Scheduled) {
                status = <span className={`label label-${pymt.paymentStatus === PaymentStatus.GatewayCancelled ? 'danger' : 'warning'}`}>{t(`PaymentStatus:${PaymentStatus[pymt.paymentStatus]}`)}</span>
            }

            return <RecentPayment key={pymt.paymentId} pymt={pymt} paidDateTime={paidDateTime} selectedVenueId={selectedVenueId} timeFormat={timeFormat} dateFormat={dateFormat} />
        });

        return <ul className='list-unstyled list-separator'>
            {payments}
        </ul>;
    }
}


const matStateToProps = (state: ApplicationState) => ({
    selectedVenueId: state.venues.selectedVenueId,
    venue: state.venues.venues.find(v => v.id === state.venues.selectedVenueId)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadVenues: bindActionCreators(VenueActions.actionCreators.loadVenues, dispatch),
    logout: bindActionCreators(LoginActions.actionCreators.logout, dispatch),
});

export default connect(
    matStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps        // Selects which action creators are merged into the component's props
)(RecentPayments);
