
import * as React from 'react';
import moment from 'moment';

//import Toasts from 'components/global/toasts'
import Nav from './nav/nav';
import Modal from './modal';

const App = ({ children }: any) => {

    moment.locale('en-GB');

    return <div className='app-container'>
        { /*<Toasts />*/
        }
        <Nav />
        <div id='app-content-container' className='app-content-container'>
            <div className='app-content-content'>
                {children}
            </div>
            <Modal />
        </div>
    </div>;
};

export default App
