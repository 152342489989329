
import * as React from 'react';

import { Venue } from '../../store/pages/venues/types';
import * as ct from './controls';

interface VenueSelectionProps {
    isAuthenticated: boolean;
    venues: Venue[];
    selectedVenueId: string;
    venueChanged: (venue: Venue) => void;
}

interface VenueSelectionState {
    currentVenue: Venue | null;
}

class VenueSelection extends React.Component<VenueSelectionProps, VenueSelectionState> {

    constructor(props: VenueSelectionProps) {
        super(props);

        this.state = { currentVenue: null };
    }

    switchVenue = (venueId: string) => {
        this.setSelectedVenue(venueId);
    }

    setSelectedVenue = (venueId: string) => {
        const venue = this.props.venues.filter(v => !v.archived).find(v => v.id === venueId);
        if (venue) {
            this.props.venueChanged(venue);
        }
    }

    componentDidUpdate() {
        // ensure a venue is selected & current selection is valid
        const { venues, selectedVenueId } = this.props;
        const { currentVenue } = this.state;

        const activeVenues = venues.filter(v => !v.archived);

        if (activeVenues.length > 0) {
            const venue = activeVenues.find(v => v.id === selectedVenueId);
            if (!venue) {
                this.setSelectedVenue(activeVenues.filter(v => !v.archived)[0].id);
            } else if (!currentVenue || currentVenue.id !== selectedVenueId) {
                this.setState({ currentVenue: venue });
            }
        }
    }

    render() {
        const { currentVenue } = this.state;
        const { venues, isAuthenticated } = this.props;

        const activeVenues = venues ? venues.filter(v => !v.archived) : [];

        if (!isAuthenticated || activeVenues.length < 2) {
            return null;
        }

        // eslint-disable-next-line
        const selections = activeVenues.sort((v1, v2) => v1.name < v2.name ? -1 : v1.name === v2.name ? 0 : 1)
                                       .map(v => ({ key: v.id, name: v.name }));

        return <ct.PlainSelect id='venueSelection' className='hdr-selection' options={selections} value={currentVenue ? currentVenue.id.toString() : ''} buttonClassOverride='btn btn-basic dropdown-toggle' styleOverride={{}} callback={id => this.switchVenue(id) } />
    }
}

export default VenueSelection;