
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import * as api from '../../../store/apiClient';

import { CustomerType, FindCustomerSummary, MarketingPreference, VenueDate } from '../../../store/pages/customer/types';
import { clickHandler, isNullOrEmpty, range } from '../../../utils/util';
import { Tag } from '../../../store/pages/tags/types';
import { allCountries } from '../../../store/global/countries';
import { monthNameAbbreviations, monthNames } from '../../../utils/extensions';
import ApiError from '../../global/apiError';
import { DateFormat } from '../../../store/pages/venues/types';
import Password from '../../icons/password';

interface CustomerMergeFormProps {
    customers: FindCustomerSummary[];
    defaultCountryId: number;
    dateFormat: DateFormat;
    close: (reload: boolean) => void;
    logout: () => void;
}

interface CustomerMergeFormState {
    targetCustomerId: string | null;
    firstname: ct.FormValue<string>;
    lastname: ct.FormValue<string>;
    companyName: ct.FormValue<string>;
    birthDay: ct.FormValue<number>;
    birthMonth: ct.FormValue<number>;
    birthYear: ct.FormValue<number>;
    addressLine1: ct.FormValue<string>;
    addressLine2: ct.FormValue<string>;
    addressLine3: ct.FormValue<string>;
    addressLine4: ct.FormValue<string>;
    town: ct.FormValue<string>;
    county: ct.FormValue<string>;
    countryId: ct.FormValue<number>;
    postalCode: ct.FormValue<string>;
    emailAddress: ct.FormValue<string>;
    phoneNumber: ct.FormValue<string>;
    emergencyContactName: ct.FormValue<string>;
    emergencyContactNumber: ct.FormValue<string>;
    resultsPreference: ct.FormValue<MarketingPreference>
    marketingPreference: ct.FormValue<MarketingPreference>
    publicResultsConsent: ct.FormValue<boolean>;
    notes: ct.FormValue<string>;
    nickname: ct.FormValue<string>;
    tags: Tag[];
    errorMessage: string | null;
    isMerging: boolean;
    mergeError: api.ApiError | null;
    mergeComplete: boolean;
}

class CustomerMergeForm extends React.Component<CustomerMergeFormProps, CustomerMergeFormState> {

    constructor(props: CustomerMergeFormProps) {
        super(props);

        const dobState = this.validateDob(0, 0, 0);

        this.state = {
            targetCustomerId: null,
            firstname: this.validateFirstName(''),
            lastname: this.validateLastName(''),
            companyName: this.validateCompanyName(''),
            emailAddress: this.validateEmailAddress(''),
            phoneNumber: this.validatePhoneNumber(''),
            addressLine1: this.validateAddressLine1('', false),
            addressLine2: this.validateAddressLine2('', false),
            addressLine3: this.validateAddressLine3('', false),
            addressLine4: this.validateAddressLine4('', false),
            town: this.validateTown('', false),
            county: this.validateCounty('', false),
            countryId: this.validateCountryId(props.defaultCountryId, false),
            postalCode: this.validatePostalCode('', false),
            birthDay: dobState.birthDay,
            birthMonth: dobState.birthMonth,
            birthYear: dobState.birthYear,
            emergencyContactName: this.validateEmergencyContactName(''),
            emergencyContactNumber: this.validateEmergencyContactNumber(''),
            resultsPreference: this.validateResultsPreference(MarketingPreference.None),
            marketingPreference: this.validateEmailMarketingPreference(MarketingPreference.None),
            publicResultsConsent: this.validatePublicResultsConsent(false),
            tags: [],
            nickname: this.validateNickname(''),
            notes: this.validateNotes(''),
            errorMessage: '',
            isMerging: false,
            mergeError: null,
            mergeComplete: false
        }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    close = (reload: boolean) => this.props.close(reload);

    mergeCustomers = () => {
        const { targetCustomerId, firstname, lastname, companyName, birthDay, birthMonth, birthYear,
            addressLine1, addressLine2, addressLine3, addressLine4, town, county, countryId, postalCode, emailAddress, phoneNumber,
            emergencyContactName, emergencyContactNumber, resultsPreference, marketingPreference, publicResultsConsent, notes, nickname, tags
        } = this.state;
        const { customers, close, logout } = this.props;

        if (!v.isValid(this.state)) {
            this.setState({ errorMessage: 'Global:formNotValid' })
            return;
        }

        const body = {
            mergeToCustomerId: targetCustomerId,
            mergeFromCustomerIds: customers.filter(c => c.id !== targetCustomerId).map(c => c.id),
            customerType: customers.reduce((ct, c) => ct | c.customerType, CustomerType.None),
            firstname: firstname.value,
            lastname: lastname.value,
            companyName: companyName.value,
            birthDay: birthDay.value,
            birthMonth: birthMonth.value,
            birthYear: birthYear.value,
            addressLine1: addressLine1.value,
            addressLine2: addressLine2.value,
            addressLine3: addressLine3.value,
            addressLine4: addressLine4.value,
            town: town.value,
            county: county.value,
            countryId: countryId.value,
            postalCode: postalCode.value,
            emailAddress: emailAddress.value,
            phoneNumber: phoneNumber.value,
            emergencyContactName: emergencyContactName.value,
            emergencyContactNumber: emergencyContactNumber.value,
            resultsPreference: resultsPreference.value,
            marketingPreference: marketingPreference.value,
            publicResultsConsent: publicResultsConsent.value,
            notes: notes.value,
            nickname: nickname.value,
            tags: tags.map(t => t.id)
        }

        this.setState({ isMerging: true, mergeError: null });
        api.postWithAuth(`api/v1/customer/merge`, body, logout)
            .subscribe(
                _ => this.setState({ isMerging: false, mergeError: null, mergeComplete: true }, () => setTimeout(() => close(true), 750)),
                e => this.setState({ isMerging: false, mergeError: e }));
    }

    copyDetails = (cus: FindCustomerSummary) => { }

    buildStateFromCustomer = (customer: FindCustomerSummary): CustomerMergeFormState => {
        const { companyName, firstname, lastname, emailAddress, phoneNumber, customerType, addressLine1, addressLine2, addressLine3, addressLine4, town, county,
            countryId, postalCode, marketingPreference, resultsPreference, publicResultsConsent, tags, notes, birthDay, birthMonth, birthYear,
            emergencyContactName, emergencyContactNumber, nickname } = customer;

        const { customers } = this.props;

        const state = this.validateAddress(addressLine1 || '', addressLine2 || '', addressLine3 || '', addressLine4 || '', town || '', county || '', countryId, postalCode || '');
        const dobState = this.validateDob(birthYear || 0, birthMonth || 0, birthDay || 0);

        const mergedNotes = customers.filter(c => c.id !== customer.id).reduce((merged, c) => merged + '\n' + c.notes, notes);
        const mergedTags = customers.filter(c => c.id !== customer.id).reduce((merged, c) => {
            for (let ti = 0; ti < c.tags.length; ti++) {
                if (merged.findIndex(t => t.id == c.tags[ti].id) === -1) merged.push(c.tags[ti])
            }
            return merged;
        }, tags);

        return {
            ...state,
            targetCustomerId: customer.id,
            companyName: this.validateCompanyName(companyName || ''),
            firstname: this.validateFirstName(firstname || ''),
            lastname: this.validateLastName(lastname || ''),
            emailAddress: this.validateEmailAddress(emailAddress || ''),
            phoneNumber: this.validatePhoneNumber(phoneNumber || ''),
            marketingPreference: this.validateEmailMarketingPreference(marketingPreference),
            resultsPreference: this.validateResultsPreference(resultsPreference),
            publicResultsConsent: this.validatePublicResultsConsent(publicResultsConsent),
            tags: mergedTags,
            notes: this.validateNotes(mergedNotes),
            emergencyContactName: this.validateEmergencyContactName(emergencyContactName || ''),
            emergencyContactNumber: this.validateEmergencyContactNumber(emergencyContactNumber || ''),
            birthYear: dobState.birthYear,
            birthMonth: dobState.birthMonth,
            birthDay: dobState.birthDay,
            nickname: this.validateNickname(nickname || ''),
            errorMessage: '',
            isMerging: false,
            mergeComplete: false,
            mergeError: null
        }
    }

    setAddress = (addressLine1: string, addressLine2: string, addressLine3: string, addressLine4: string, town: string, county: string, countryId: number, postalCode: string) => {
        this.setState(() => this.validateAddress(addressLine1, addressLine2, addressLine3, addressLine4, town, county, countryId, postalCode));
    }

    validateAddress = (addressLine1: string, addressLine2: string, addressLine3: string, addressLine4: string, town: string, county: string, countryId: number, postalCode: string) => {

        const addressRequired = !isNullOrEmpty(addressLine1)
            || !isNullOrEmpty(addressLine2)
            || !isNullOrEmpty(addressLine3)
            || !isNullOrEmpty(addressLine4)
            || !isNullOrEmpty(town)
            || !isNullOrEmpty(county)
            || !isNullOrEmpty(postalCode);

        // Default the country to the venue's country if not set
        if (countryId === 0) {
            countryId = this.props.defaultCountryId;
        }

        return {
            addressLine1: this.validateAddressLine1(addressLine1, addressRequired),
            addressLine2: this.validateAddressLine2(addressLine2, false),
            addressLine3: this.validateAddressLine3(addressLine3, false),
            addressLine4: this.validateAddressLine4(addressLine4, false),
            town: this.validateTown(town, false),
            county: this.validateCounty(county, false),
            countryId: this.validateCountryId(countryId, addressRequired),
            postalCode: this.validatePostalCode(postalCode, addressRequired),
        };
    }

    setDob = (birthYear: number, birthMonth: number, birthDay: number) => this.setState(this.validateDob(birthYear, birthMonth, birthDay))

    validateDob = (birthYear: number, birthMonth: number, birthDay: number) => {
        if (birthYear === 0 && birthMonth === 0 && birthDay === 0) {
            return { birthYear: ct.asFormValue('birthYear', birthYear), birthMonth: ct.asFormValue('birthMonth', birthMonth), birthDay: ct.asFormValue('birthDay', birthDay) }
        }

        const today = new Date();
        return {
            birthYear: ct.asFormValue('birthYear', birthYear, birthYear >= today.getFullYear() - 100 && birthYear <= today.getFullYear(), true),
            birthMonth: ct.asFormValue('birthMonth', birthMonth, birthMonth >= 1 && birthMonth <= 12, true),
            birthDay: ct.asFormValue('birthDay', birthDay, new Date(birthYear, birthMonth - 1, birthDay).getDate() === birthDay, true)
        }
    }

    validateCompanyName = (val: string) => v.validate(val, 'companyName', [], []);
    validateFirstName = (val: string) => v.validate(val, 'firstName', [v.required], []);
    validateLastName = (val: string) => v.validate(val, 'lastName', [v.required], []);
    validateEmailAddress = (val: string) => v.validate(val, 'emailAddress', [v.required], []);
    validatePhoneNumber = (val: string) => v.validate(val, 'phoneNumber', [v.required], []);
    validateAddressLine1 = (val: string, isRequired: boolean) => v.validate(val, 'addressLine1', isRequired ? [v.required] : [], []);
    validateAddressLine2 = (val: string, isRequired: boolean) => v.validate(val, 'addressLine2', isRequired ? [v.required] : [], []);
    validateAddressLine3 = (val: string, isRequired: boolean) => v.validate(val, 'addressLine3', isRequired ? [v.required] : [], []);
    validateAddressLine4 = (val: string, isRequired: boolean) => v.validate(val, 'addressLine4', isRequired ? [v.required] : [], []);
    validateTown = (val: string, isRequired: boolean) => v.validate(val, 'town', isRequired ? [v.required] : [], []);
    validateCounty = (val: string, isRequired: boolean) => v.validate(val, 'county', isRequired ? [v.required] : [], []);
    validateCountryId = (val: number, isRequired: boolean) => v.validate(val, 'countryId', isRequired ? [v.numeric, v.required] : [], []);
    validatePostalCode = (val: string, isRequired: boolean) => v.validate(val, 'postalCode', isRequired ? [v.required] : [], []);
    validateCustomerType = (val: CustomerType) => v.validate(val, 'customerType', [], []);
    validateEmailMarketingPreference = (val: MarketingPreference) => v.validate(val, 'marketingPreference', [], []);
    validateResultsPreference = (val: MarketingPreference) => v.validate(val, 'resultsPreference', [], []);
    validatePublicResultsConsent = (val: boolean) => v.validate(val, 'publicResultsConsent', [], []);
    validateEmergencyContactName = (val: string) => v.validate(val, 'emergencyContactName', [], []);
    validateEmergencyContactNumber = (val: string) => v.validate(val, 'emergencyContactNumber', [], []);
    validateNickname = (val: string) => v.validate(val, 'nickname', [], []);
    validateNotes = (val: string) => v.validate(val, 'notes', [], []);

    render() {
        const { t } = this.context;
        const { customers, dateFormat } = this.props;
        const { targetCustomerId, firstname, lastname, companyName, addressLine1, addressLine2, addressLine3, addressLine4, town, county, countryId, postalCode,
                birthDay, birthMonth, birthYear, emailAddress, phoneNumber, emergencyContactName, emergencyContactNumber,
                marketingPreference, resultsPreference, publicResultsConsent, notes, nickname, tags, errorMessage, isMerging, mergeComplete, mergeError } = this.state;

        return <div className='customerMergeForm'>
            <h1 className='customerMergeForm_title'>{t('CustomerMergeForm:heading')}</h1>

            <form className='data-form' onSubmit={e => e.preventDefault()} autoComplete='off'>
                {isNullOrEmpty(targetCustomerId)
                    ? <div>
                        <table className='table table-condensed'>
                            <thead>
                                <tr>
                                    <th>{t('CustomerMergeForm:name')}</th>
                                    <th>{t('CustomerMergeForm:email')}</th>
                                    <th>{t('CustomerMergeForm:phone')}</th>
                                    <th></th>
                                    <th>{t('CustomerMergeForm:created')}</th>
                                    <th>{t('CustomerMergeForm:lastBooking')}</th>
                                    <th>{t('CustomerMergeForm:lastEvent')}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {customers.map(c => <tr key={`${c.id}_header`}>
                                    <td>{c.firstname} {c.lastname}</td>
                                    <td>{c.emailAddress}</td>
                                    <td>{c.phoneNumber}</td>
                                    <td>{c.hasPassword ? <Password width={20} height={20} colour='#337ab7' /> : null}</td>
                                    <td>{c.created.toAbbrDateString(dateFormat, t)}</td>
                                    <td>{this.formatLastDate(c.lastBooking)}</td>
                                    <td>{this.formatLastDate(c.lastVisit)}</td>
                                    <td><button className='btn btn-primary' onClick={e => clickHandler(e, () => this.setState(this.buildStateFromCustomer(c)))}>{t('Global:select')}</button></td>
                                </tr>)}
                            </tbody>
                        </table>
                        <div className='alert alert-info'>{t('CustomerMergeForm:selectPrimaryCustomer')}</div>
                    </div>
                    : <table className='table table-condensed'>
                        <tbody>
                            {this.renderTextFieldSelection('firtname', 'Global:firstName', customers, c => c.firstname, firstname, v => this.setState({firstname: this.validateFirstName(v)}) ) }
                            {this.renderTextFieldSelection('lastname', 'Global:lastName', customers, c => c.lastname, lastname, v => this.setState({ lastname: this.validateLastName(v) }))}
                            {this.renderTextFieldSelection('companyName', 'Global:companyName', customers, c => c.companyName, companyName, v => this.setState({ companyName: this.validateCompanyName(v) }))}
                            {this.renderDobSelection('birthDate', 'Global:dateOfBirth', customers, birthDay, birthMonth, birthYear, (day, month, year) => this.setDob(year, month, day))}
                            {this.renderTextFieldSelection('addressLine1', 'Global:addressLine1', customers, c => c.addressLine1, addressLine1, v => this.setAddress(v, addressLine2.value, addressLine3.value, addressLine4.value, town.value, county.value, countryId.value, postalCode.value))}
                            {this.renderTextFieldSelection('addressLine2', 'Global:addressLine2', customers, c => c.addressLine2, addressLine2, v => this.setAddress(addressLine1.value, v, addressLine3.value, addressLine4.value, town.value, county.value, countryId.value, postalCode.value))}
                            {this.renderTextFieldSelection('addressLine3', 'Global:addressLine3', customers, c => c.addressLine3, addressLine3, v => this.setAddress(addressLine1.value, addressLine2.value, v, addressLine4.value, town.value, county.value, countryId.value, postalCode.value))}
                            {this.renderTextFieldSelection('addressLine4', 'Global:addressLine4', customers, c => c.addressLine4, addressLine4, v => this.setAddress(addressLine1.value, addressLine2.value, addressLine3.value, v, town.value, county.value, countryId.value, postalCode.value))}
                            {this.renderTextFieldSelection('town', 'Global:town', customers, c => c.town, town, v => this.setAddress(addressLine1.value, addressLine2.value, addressLine3.value, addressLine4.value, v, county.value, countryId.value, postalCode.value))}
                            {this.renderTextFieldSelection('county', 'Global:county', customers, c => c.county, county, v => this.setAddress(addressLine1.value, addressLine2.value, addressLine3.value, addressLine4.value, town.value, v, countryId.value, postalCode.value))}
                            {this.renderCountrySelection('country', 'Global:country', customers, c => c.countryId, countryId, v => this.setAddress(addressLine1.value, addressLine2.value, addressLine3.value, addressLine4.value, town.value, county.value, v, postalCode.value))}
                            {this.renderTextFieldSelection('postalCode', 'Global:postalCode', customers, c => c.postalCode, postalCode, v => this.setAddress(addressLine1.value, addressLine2.value, addressLine3.value, addressLine4.value, town.value, county.value, countryId.value, v))}
                            {this.renderTextFieldSelection('emailAddress', 'Global:emailAddress', customers, c => c.emailAddress, emailAddress, v => this.setState({ emailAddress: this.validateEmailAddress(v) }))}
                            {this.renderTextFieldSelection('phoneNumber', 'Global:phoneNumber', customers, c => c.phoneNumber, phoneNumber, v => this.setState({ phoneNumber: this.validatePhoneNumber(v) }))}
                            {this.renderTextFieldSelection('emergencyContactName', 'Global:emergencyContactName', customers, c => c.emergencyContactName, emergencyContactName, v => this.setState({ emergencyContactName: this.validateEmergencyContactName(v) }))}
                            {this.renderTextFieldSelection('emergencyContactNumber', 'Global:emergencyContactNumber', customers, c => c.emergencyContactNumber, emergencyContactNumber, v => this.setState({ emergencyContactNumber: this.validateEmergencyContactNumber(v) }))}
                            {this.renderMarketingSelection('emailMarketing', 'customerDetails:marketingPreference', customers, c => c.marketingPreference, marketingPreference, v => this.setState({ marketingPreference: this.validateEmailMarketingPreference(v) }))}
                            {this.renderMarketingSelection('resultsPreference', 'customerDetails:resultsPreference', customers, c => c.resultsPreference, resultsPreference, v => this.setState({ resultsPreference: this.validateResultsPreference(v) }))}
                            {this.renderListSelection('publicResultsConsent', 'Global:publicResultsConsent', customers, [{ key: 'Y', name: t('Global:yes') }, { key: 'N', name: t('Global:no') }], c => c.publicResultsConsent ? 'Y' : 'N', { ...publicResultsConsent, value: publicResultsConsent.value ? 'Y' : 'N' }, v => this.setState({ publicResultsConsent: this.validatePublicResultsConsent(v === 'Y') }))}
                            {this.renderTextFieldSelection('nickname', 'Global:nickname', customers, c => c.nickname, nickname, v => this.setState({ nickname: this.validateNickname(v) }))}
                            {this.renderTextAreaSelection('notes', 'Global:notes', customers, c => c.notes, notes, v => this.setState({ notes: this.validateNotes(v) }))}
                            {this.renderTags('tags', 'Global:tags', customers, c => c.tags, tags)}

                        </tbody>
                    </table>
                }

                <p />
                <div className='btn-toolbar'>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.mergeCustomers)} disabled={isMerging || mergeComplete}>{t('CustomersPage:merge')}</button>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, () => this.close(false))} disabled={isMerging}>{t('Global:cancel')}</button>
                </div>
                <p />
                {errorMessage ? <div className='alert alert-danger'>{t(errorMessage)}</div> : null}
                {isMerging ? <div className='alert alert-info'>{t('CustomerMergeForm:merging')}</div> : null}
                {mergeComplete ? <div className='alert alert-info'>{t('CustomerMergeForm:mergeComplete')}</div> : null}
                {mergeError ? <ApiError error={mergeError} /> : null}
            </form>
        </div>
    }

    formatLastDate = (dates: VenueDate[]) => {
        const { t } = this.context;
        const { dateFormat } = this.props;

        if (dates.length === 0) return null;
        const latestDate = dates.reduce<Date | null>((latest, vd) => latest === null || vd.date > latest ? vd.date : latest, null);
        return latestDate ? latestDate.toAbbrDateString(dateFormat, t) : null;
    }

    renderSelectIcon = (c: FindCustomerSummary, selected: boolean, canSelect: boolean, selector: () => void) => {
        const baseStyle = { fontSize: '18px' }
        const style = selected ? { ...baseStyle, color: '#92b8ff' } : canSelect ? { ...baseStyle, color: '#33be00' } : { ...baseStyle, color: '#eee' };
        return canSelect
            ? <button onClick={e => clickHandler(e, selector)} className='btn btn-xs'><span className='glyphicon glyphicon-arrow-right' style={style}></span></button>
            : <span className='glyphicon glyphicon-arrow-right' style={{ ...style, margin: '5px 0 4px 0' }}></span>
    }

    renderTextFieldSelection = (fieldName: string, labelKey: string, customers: FindCustomerSummary[], valueSelector: (c: FindCustomerSummary) => string, val: ct.FormValue<string>, setter: (v: string) => void) => {
        const { t } = this.context;

        const multiRowCellStyle = { lineHeight: '10px', padding: '0', verticalAlign: 'middle' }

        const matchingCustomers = customers.filter(c => valueSelector(c) === val.value);
        const matchingCustomerId = matchingCustomers.length > 0 ? matchingCustomers[0].id : '';

        return [<tr key={`${fieldName}_${customers[0].id}`}>
            <td rowSpan={customers.length} style={{verticalAlign: 'middle'}}><label>{t(labelKey)}</label></td>
            <td style={multiRowCellStyle}>{valueSelector(customers[0])}</td>
            <td style={multiRowCellStyle} className='text-center'>{this.renderSelectIcon(customers[0], matchingCustomerId === customers[0].id, valueSelector(customers[0]) !== val.value, () => setter(valueSelector(customers[0] || '')))}</td>
            <td rowSpan={customers.length} style={{ verticalAlign: 'middle' }}>
                <ct.TextBox id={fieldName} labelKey='' placeholderKey='' value={val} callback={setter} minimal={true} />
            </td>
        </tr>].concat(customers.filter((c, ix) => ix > 0).map(c => <tr key={`${fieldName}_${c.id}`}>
            <td style={{ ...multiRowCellStyle, borderTop: '0' }}>{valueSelector(c)}</td>
            <td style={{ ...multiRowCellStyle, borderTop: '0' }} className='text-center'>{this.renderSelectIcon(c, matchingCustomerId === c.id, valueSelector(c) !== val.value, () => setter(valueSelector(c) || ''))}</td>
        </tr>))
    }

    renderTextAreaSelection = (fieldName: string, labelKey: string, customers: FindCustomerSummary[], valueSelector: (c: FindCustomerSummary) => string, val: ct.FormValue<string>, setter: (v: string) => void) => {
        const { t } = this.context;

        const multiRowCellStyle = { lineHeight: '10px', padding: '0', verticalAlign: 'middle' }

        const matchingCustomers = customers.filter(c => valueSelector(c) === val.value);
        const matchingCustomerId = matchingCustomers.length > 0 ? matchingCustomers[0].id : '';

        return [<tr key={`${fieldName}_${customers[0].id}`}>
            <td rowSpan={customers.length} style={{ verticalAlign: 'middle' }}><label>{t(labelKey)}</label></td>
            <td style={multiRowCellStyle}>{valueSelector(customers[0])}</td>
            <td style={multiRowCellStyle} className='text-center'>{this.renderSelectIcon(customers[0], matchingCustomerId === customers[0].id, valueSelector(customers[0]) !== val.value, () => setter(valueSelector(customers[0] || '')))}</td>
            <td rowSpan={customers.length} style={{ verticalAlign: 'middle' }}>
                <ct.TextArea id={fieldName} labelKey='' placeholderKey='' value={val} callback={setter} minimal={true} rows={5} />
            </td>
        </tr>].concat(customers.filter((c, ix) => ix > 0).map(c => <tr key={`${fieldName}_${c.id}`}>
            <td style={{ ...multiRowCellStyle, borderTop: '0' }}>{valueSelector(c)}</td>
            <td style={{ ...multiRowCellStyle, borderTop: '0' }} className='text-center'>{this.renderSelectIcon(c, matchingCustomerId === c.id, valueSelector(c) !== val.value, () => setter(valueSelector(c) || ''))}</td>
        </tr>))
    }

    renderListSelection = (fieldName: string, labelKey: string, customers: FindCustomerSummary[], options: ct.SelectOption[], valueSelector: (c: FindCustomerSummary) => string, val: ct.FormValue<string>, setter: (v: string) => void) => {
        const { t } = this.context;

        const multiRowCellStyle = { lineHeight: '10px', padding: '0', verticalAlign: 'middle' }

        const matchingCustomers = customers.filter(c => valueSelector(c) === val.value);
        const matchingCustomerId = matchingCustomers.length > 0 ? matchingCustomers[0].id : '';

        const formatValue = (val: string) => {
            const opt = options.filter(o => o.key === val);
            return opt.length === 0 ? '' : opt[0].name;
        }

        return [<tr key={`${fieldName}_${customers[0].id}`}>
            <td rowSpan={customers.length} style={{ verticalAlign: 'middle' }}><label>{t(labelKey)}</label></td>
            <td style={multiRowCellStyle}>{formatValue(valueSelector(customers[0]))}</td>
            <td style={multiRowCellStyle} className='text-center'>{this.renderSelectIcon(customers[0], matchingCustomerId === customers[0].id, valueSelector(customers[0]) !== val.value, () => setter(valueSelector(customers[0])))}</td>
            <td rowSpan={customers.length} style={{ verticalAlign: 'middle' }}>
                <ct.Select id={fieldName} labelKey='' options={options} value={val} callback={setter} minimal={true} />
            </td>
        </tr>].concat(customers.filter((c, ix) => ix > 0).map(c => <tr key={`${fieldName}_${c.id}`}>
            <td style={{ ...multiRowCellStyle, borderTop: '0' }}>{formatValue(valueSelector(c))}</td>
            <td style={{ ...multiRowCellStyle, borderTop: '0' }} className='text-center'>{this.renderSelectIcon(c, matchingCustomerId === c.id, valueSelector(c) !== val.value, () => setter(valueSelector(c)))}</td>
        </tr>))
    }

    renderCountrySelection = (fieldName: string, labelKey: string, customers: FindCustomerSummary[], valueSelector: (c: FindCustomerSummary) => number, val: ct.FormValue<number>, setter: (v: number) => void) => {
        const { t } = this.context;

        const multiRowCellStyle = { lineHeight: '10px', padding: '0', verticalAlign: 'middle' }

        const matchingCustomers = customers.filter(c => valueSelector(c) === val.value);
        const matchingCustomerId = matchingCustomers.length > 0 ? matchingCustomers[0].id : '';

        const getCountryName = (id: number) => allCountries.filter(c => c.id === id).map(c => c.name)[0];

        return [<tr key={`${fieldName}_${customers[0].id}`}>
            <td rowSpan={customers.length} style={{ verticalAlign: 'middle' }}><label>{t(labelKey)}</label></td>
            <td style={multiRowCellStyle}>{getCountryName(valueSelector(customers[0]))}</td>
            <td style={multiRowCellStyle} className='text-center'>{this.renderSelectIcon(customers[0], matchingCustomerId === customers[0].id, valueSelector(customers[0]) !== val.value, () => setter(valueSelector(customers[0])))}</td>
            <td rowSpan={customers.length} style={{ verticalAlign: 'middle' }}>
                <ct.Country id='country' labelKey='' value={val} callback={setter} countries={allCountries} minimal={true} />
            </td>
        </tr>].concat(customers.filter((c, ix) => ix > 0).map(c => <tr key={`${fieldName}_${c.id}`}>
            <td style={{ ...multiRowCellStyle, borderTop: '0' }}>{getCountryName(valueSelector(c))}</td>
            <td style={{ ...multiRowCellStyle, borderTop: '0' }} className='text-center'>{this.renderSelectIcon(c, matchingCustomerId === c.id, valueSelector(c) !== val.value, () => setter(valueSelector(c)))}</td>
        </tr>))
    }

    renderDobSelection = (fieldName: string, labelKey: string, customers: FindCustomerSummary[], birthDay: ct.FormValue<number>, birthMonth: ct.FormValue<number>, birthYear: ct.FormValue<number>, setter: (day: number, month: number, year: number) => void) => {
        const { t } = this.context;

        const multiRowCellStyle = { lineHeight: '10px', padding: '0', verticalAlign: 'middle' }
        const baseStyle = { display: 'inline-block' }
        const dayOptions = range(1, 31).map(d => ({ key: d.toString(), name: d.toString() }))
        const monthOptions = monthNames.map((m, ix) => ({ key: (ix + 1).toString(), name: t(m) }))
        const today = new Date();
        const yearOptions = range(today.getFullYear() - 100, today.getFullYear()).reverse().map(y => ({ key: y.toString(), name: y.toString() }))

        const matchingCustomers = customers.filter(c => c.birthDay === birthDay.value && c.birthMonth === birthMonth.value && c.birthYear === birthYear.value);
        const matchingCustomerId = matchingCustomers.length > 0 ? matchingCustomers[0].id : '';

        const formatDate = (cus: FindCustomerSummary) => {
            if (cus.birthDay === 0 && cus.birthMonth === 0 && cus.birthYear === 0) return '';
            return `${cus.birthDay} ${t(monthNameAbbreviations[cus.birthMonth-1])} ${cus.birthYear}`
        }

        return [<tr key={`${fieldName}_${customers[0].id}`}>
            <td rowSpan={customers.length} style={{ verticalAlign: 'middle' }}><label>{t(labelKey)}</label></td>
            <td style={multiRowCellStyle}>{formatDate(customers[0])}</td>
            <td style={multiRowCellStyle} className='text-center'>{this.renderSelectIcon(customers[0], matchingCustomerId === customers[0].id, customers[0].birthDay !== birthDay.value || customers[0].birthMonth !== birthMonth.value || customers[0].birthYear !== birthYear.value, () => setter(customers[0].birthDay, customers[0].birthMonth, customers[0].birthYear ))}</td>
            <td rowSpan={customers.length} style={{ verticalAlign: 'middle' }}>
                <div>
                    <ct.Select id='birthDay' labelKey='' style={{ ...baseStyle, width: '90px' }} value={{ ...birthDay, value: birthDay.value.toString() }} callback={val => setter(parseInt(val), birthMonth.value, birthYear.value)} options={dayOptions} minimal={true} />
                    <ct.Select id='birthMonth' labelKey='' style={{ ...baseStyle, width: '130px' }} value={{ ...birthMonth, value: birthMonth.value.toString() }} callback={val => setter(birthDay.value, parseInt(val), birthYear.value)} options={monthOptions} minimal={true} />
                    <ct.Select id='birthYear' labelKey='' style={{ ...baseStyle, width: '100px' }} value={{ ...birthYear, value: birthYear.value.toString() }} callback={val => setter(birthDay.value, birthMonth.value, parseInt(val))} options={yearOptions} minimal={true} />
                </div>
            </td>
        </tr>].concat(customers.filter((c, ix) => ix > 0).map(c => <tr key={`${fieldName}_${c.id}`}>
            <td style={{ ...multiRowCellStyle, borderTop: '0' }}>{formatDate(c)}</td>
            <td style={{ ...multiRowCellStyle, borderTop: '0' }} className='text-center'>{this.renderSelectIcon(c, matchingCustomerId === c.id, c.birthDay !== birthDay.value || c.birthMonth !== birthMonth.value || c.birthYear !== birthYear.value, () => setter(c.birthDay, c.birthMonth, c.birthYear))}</td>
        </tr>))
    }

    renderMarketingSelection = (fieldName: string, labelKey: string, customers: FindCustomerSummary[], valueSelector: (c: FindCustomerSummary) => MarketingPreference, val: ct.FormValue<MarketingPreference>, setter: (v: MarketingPreference) => void) => {
        const { t } = this.context;

        const multiRowCellStyle = { lineHeight: '10px', padding: '0', verticalAlign: 'middle' }

        const matchingCustomers = customers.filter(c => valueSelector(c) === val.value);
        const matchingCustomerId = matchingCustomers.length > 0 ? matchingCustomers[0].id : '';

        const selections = [MarketingPreference.Email, MarketingPreference.Phone]
        const formatSelections = (cus: FindCustomerSummary) => selections.filter(s => (s & valueSelector(cus)) === s).map(s => t(`MarketingPreference:${MarketingPreference[s]}`))

        return [<tr key={`${fieldName}_${customers[0].id}`}>
            <td rowSpan={customers.length} style={{ verticalAlign: 'middle' }}><label>{t(labelKey)}</label></td>
            <td style={multiRowCellStyle}>{formatSelections(customers[0])}</td>
            <td style={multiRowCellStyle} className='text-center'>{this.renderSelectIcon(customers[0], matchingCustomerId === customers[0].id, valueSelector(customers[0]) !== val.value, () => setter(valueSelector(customers[0] || '')))}</td>
            <td rowSpan={customers.length} style={{ verticalAlign: 'middle' }}>
                <div className='flex' style={{ justifyContent: 'space-around' }}>
                    <ct.Checkbox id={`${fieldName}_email`} labelKey='Global:emailMarketing' placeholderKey='' value={ct.asFormValue(`${fieldName}_email`, (val.value & MarketingPreference.Email) === MarketingPreference.Email)} callback={chk => setter(chk ? val.value | MarketingPreference.Email : val.value & ~MarketingPreference.Email)} minimal={true} />
                    <ct.Checkbox id={`${fieldName}_phone`} labelKey='Global:phoneMarketing' placeholderKey='' value={ct.asFormValue(`${fieldName}_phone`, (val.value & MarketingPreference.Phone) === MarketingPreference.Phone)} callback={chk => setter(chk ? val.value | MarketingPreference.Phone : val.value & ~MarketingPreference.Phone)} minimal={true} />
                </div>
            </td>
        </tr>].concat(customers.filter((c, ix) => ix > 0).map(c => <tr key={`${fieldName}_${c.id}`}>
            <td style={{ ...multiRowCellStyle, borderTop: '0' }}>{formatSelections(c)}</td>
            <td style={{ ...multiRowCellStyle, borderTop: '0' }} className='text-center'>{this.renderSelectIcon(c, matchingCustomerId === c.id, valueSelector(c) !== val.value, () => setter(valueSelector(c)))}</td>
        </tr>))
    }

    renderTags = (fieldName: string, labelKey: string, customers: FindCustomerSummary[], valueSelector: (c: FindCustomerSummary) => Tag[], tags: Tag[]) => {
        const { t } = this.context;

        const multiRowCellStyle = { lineHeight: '10px', padding: '0', verticalAlign: 'middle' }

        return [<tr key={`${fieldName}_${customers[0].id}`}>
            <td rowSpan={customers.length} style={{ verticalAlign: 'middle' }}><label>{t(labelKey)}</label></td>
            <td style={multiRowCellStyle}><div style={{ padding: '5px 0' }}>{valueSelector(customers[0]).map(t => <span key={t.id} className='label' style={({ display: 'inline-block', backgroundColor: t.colour, margin: '0 2px' })}>{t.name}</span>)}</div></td>
            <td style={multiRowCellStyle} className='text-center'></td>
            <td rowSpan={customers.length} style={{ verticalAlign: 'middle' }}><div>{tags.map(t => <span key={t.id} className='label' style={({ display: 'inline-block', backgroundColor: t.colour, margin: '0 2px' })}>{t.name}</span>)}</div></td>
        </tr>].concat(customers.filter((c, ix) => ix > 0).map(c => <tr key={`${fieldName}_${c.id}`}>
            <td style={{ ...multiRowCellStyle, borderTop: '0' }}><div style={{ padding: '5px 0' }}>{valueSelector(c).map(t => <span key={t.id} className='label' style={({ display: 'inline-block', backgroundColor: t.colour, margin: '0 2px' })}>{t.name}</span>)}</div></td>
            <td style={{ ...multiRowCellStyle, borderTop: '0' }} className='text-center'></td>
        </tr>))
    }
}

export default CustomerMergeForm;


