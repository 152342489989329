
import * as React from 'react';

import { Tag } from '../../store/pages/tags/types';
import { clickHandler } from '../../utils/util';
import TagLabel from './tagLabel';

interface TagListItemProps {
    tag: Tag
    removeTag?: (tagId: string) => void;
}

const TagListItem = (props: TagListItemProps) => {
    const { removeTag } = props;
    const remove = removeTag ? <span onClick={e => clickHandler(e, () => removeTag(props.tag.id))} className='glyphicon glyphicon-trash red' style={({ cursor: 'pointer', padding: '5px' })}></span> : null;
    return <li key={props.tag.id}><TagLabel name={props.tag.name} colour={props.tag.colour} />{remove}</li>
}

export default TagListItem;