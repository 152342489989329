
import * as React from 'react';

import { Resource } from '../../../store/pages/resources/types';
import { Reservation as IReservation } from './types';
import { ActivityFormat } from '../../../store/pages/activityFormats/types';
import { formatBgColour, clickHandler } from '../../../utils/util';
import { Bill } from '../../../store/pages/pointOfSale/types';
import { Product } from '../../../store/pages/products/types';
import { ReservationType } from '../../../store/pages/diary/types';
import { TimeFormat } from '../../../store/pages/venues/types';

interface EventReservationsProps {
    reservations: IReservation[];
    resources: Resource[];
    bills: Bill[];
    products: Product[];
    activityFormats: ActivityFormat[];
    timeFormat: TimeFormat;
    showNotes: (key: string) => void;
    editReservation: (reservation: IReservation) => void;
    t: (key: string) => string;
}

export const EventReservations = (props: EventReservationsProps) => {

    const { timeFormat, t } = props;

    const renderResourceHeader = (r: IReservation) => {
        const resource = props.resources.find(res => res.id === r.resourceId);
        const resourceConfig = resource && resource.configurations ? resource.configurations.find(c => c.id === r.resourceConfigurationId) : null;
        return <strong>{resourceConfig ? resourceConfig.name : resource ? resource.name : ''}</strong>;
    }

    const calculateItemTotalPrice = (reservation: IReservation) => {

        if (props.bills) {
            const totalPrice = props.bills.filter(b => !b.bookingCancelled).reduce((ttl, bill) => ttl + bill.items.reduce((it, item) => {
                const ttl = item.reservationId === reservation.key ? it + item.totalItemPrice : it
                return ttl;
            }, 0), 0);
            return totalPrice;
        }
        return 0;
    }

    const renderActivity = (reservation: IReservation) => {
        const selectedActivityFormat = props.activityFormats.find(af => af.id === reservation.activityFormatId);
        const activityFormatName = selectedActivityFormat ? selectedActivityFormat.name : '';
        const variation = selectedActivityFormat ? selectedActivityFormat.variations.find(v => v.id === reservation.activityFormatVariationId) : null;

        return (activityFormatName && selectedActivityFormat && selectedActivityFormat.variations.length > 1 && variation) ? `${activityFormatName} - [${variation.minParticipants} - ${variation.maxParticipants}]` : activityFormatName;
    }

    const renderLink = (r: IReservation, inner: any) => <button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => props.editReservation(r))}>{inner}</button>;

    const reservations = props.reservations.sort((r1,r2) => r1.startTime.toDate().getTime() - r2.startTime.toDate().getTime()).map(r => {
        const price = calculateItemTotalPrice(r);
        const resource = props.resources.find(res => res.id === r.resourceId);
        const rowHeaderStyle = resource ? { background: formatBgColour(resource.colour, 0.1) } : {};
        const bookedParticipants = r.bookedParticipants.reduce((ttl, cp) => ttl + cp.count, 0)
        const participants = r.reservationType === ReservationType.Exclusive ? `${bookedParticipants}` : `${bookedParticipants}/${r.maxParticipants}`;
        return [
            <tr key={`${r.key}_header`} style={rowHeaderStyle} data-key={`${r.key}_header`}>
                <td className='expand' colSpan={2}>{renderLink(r, renderResourceHeader(r))}</td>
                <td className='shrink'>{renderLink(r, <div>{r.startTime.toDate().toShortTimeString(timeFormat)} - {r.endTime.toDate().toShortTimeString(timeFormat)}</div>)}</td>
            </tr>,
            <tr key={`${r.key}_2nd_row`} style={rowHeaderStyle} data-key={`${r.key}_2nd_row`}>
                <td className='table-second-row expand'>{renderLink(r, renderActivity(r))}</td>
                <td className='table-second-row shrink'><span className='glyphicon glyphicon-user'></span> <span style={({fontSize: 'larger'})}>{renderLink(r, participants)}</span></td>
                <td className='table-second-row text-right shrink'>{renderLink(r, `${t('Global:currencySymbol')}${(price).toFixed(2)}`)}</td>
            </tr>
        ];
    }).reduce((res, x) => res.concat(x), []);

    return <div>
        <table className='table table-condensed'>
            <tbody>
                {reservations}
            </tbody>
            <tfoot>
                <tr key='footer' data-key='footer'>
                    <td colSpan={2}></td>
                    <td className='text-right' style={({ padding: '5px', borderTop: 'solid 2px black' })}>{`${t('Global:currencySymbol')}${props.reservations.reduce((total, r) => total + calculateItemTotalPrice(r), 0).toFixed(2)}`}</td>
                </tr>
            </tfoot>
        </table>


    </div>;
}
