
import * as PropTypes from 'prop-types'

import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';
import { Registration, RegistrationEventBookingNote } from '../../../store/pages/reception/types';
import RegistrationEvent from './registrationEvent';
import { IBooking, IEvent } from './types';
import { ITranslationContext } from '../../../translations';

interface EventListProps {
    dateFormat: DateFormat;
    timeFormat: TimeFormat;
    registrations: Registration[];
    events: IEvent[];
    expandedEvents: string[];
    hiddenResources: string[];
    addRegistrationsToBooking: (bookingId: string, registrationIds: string[]) => void;
    expand: (eventId: string) => void;
    collapse: (eventId: string) => void;
    unregister: (bookingId: string, registrationId: string) => void;
    payIndividually: (bookingId: string, billId: string, customerId: string) => void;
    payRemaining: (bookingId: string, billId: string, amount: number) => void;
    viewBill: (bookingId: string, billId: string) => void;
    findCustomers: (booking: IBooking) => void;
    editRegistration: (bookingId: string, registrationId: string) => void;
    checkIn: (bookingId: string, registrationId: string) => void;
    undoCheckIn: (bookingId: string, registrationId: string) => void;
    addBooking: (event: IEvent) => void;
    showNotes: (notes: RegistrationEventBookingNote[], name: string, billNumber: string) => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
}

const EventsView = (props: EventListProps, context: ITranslationContext) => {
    const { dateFormat, timeFormat, registrations, events, addRegistrationsToBooking, expandedEvents, expand, collapse, hiddenResources } = props;
    const { t } = context;

    if (events.length === 0) {
        return <div className='flex-stretch'><div className='alert alert-info'>{t('ReceptionPage:noEvents')}</div></div>
    }

    return <div className='reception-event-list'>
        {events
            .filter(e => e.reservations && e.reservations.filter(r => r.isBookable && !hiddenResources.includes(r.resourceId)).length > 0)
            .sort((a, b) => a.startTime.getTime() - b.startTime.getTime())
            .map(e => <RegistrationEvent
                key={e.id}
                event={e}
                registrations={registrations}
                timeFormat={timeFormat}
                dateFormat={dateFormat}
                payIndividually={props.payIndividually}
                payRemaining={props.payRemaining}
                viewBill={props.viewBill}
                unregister={props.unregister}
                addRegistrationsToBooking={addRegistrationsToBooking}
                findCustomers={props.findCustomers}
                editRegistration={props.editRegistration}
                checkIn={props.checkIn}
                undoCheckIn={props.undoCheckIn}
                expanded={expandedEvents.indexOf(e.id) >= 0}
                expand={expand}
                collapse={collapse}
                addBooking={props.addBooking}
                showNotes={props.showNotes}
                showModal={props.showModal}
                closeModal={props.closeModal}
            />
            )}
    </div>

}

EventsView.contextTypes = {
    t: PropTypes.func
}

export default EventsView;