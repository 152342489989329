import { ValidationError, Time, Days } from '../../global/types';
import * as api from '../../apiClient';
import { isNullOrEmpty } from '../../../utils/util';
import { dayFromDate } from '../activityFormats/types';

export enum ResourceType {
    Circuit = 1,
    Room = 2
}

export interface Resource {
    id: string;
    venueId: string;
    name: string;
    shortName: string;
    colour: string;
    sequence: number;
    arrivalTimeBeforeEvent: Time;
    snapOnlineBookingsTo: number;
    numberOfLanes: number;
    maxLaneCapacity: number | null;
    allowMixedGroupsInLane: boolean;
    archived: boolean;
    overlappedResourceIds: string[];
    taskDefinitionIds: string[];
    configurations: ResourceConfiguration[];
    additionalProducts: ResourceAdditionalProduct[];
    breaks: ResourceBreak[];
}

export const buildResourceSelectionId = (resourceId: string, configId: string | null) => isNullOrEmpty(configId) ? resourceId : `${resourceId}:${configId}`;

export const deconstructResourceSelectionId = (resourceSelectionId: string) => {
    const resourceParts = resourceSelectionId.split(':');
    const resourceId = resourceParts[0];
    const resourceConfigId = resourceParts.length > 1 ? resourceParts[1] : '';
    return { resourceId: resourceId, resourceConfigId: resourceConfigId }
}

export interface ResourceState {
    isLoading: boolean;
    resources: Resource[];
    loadError: api.ApiError | null;
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
}

export interface ResourceConfiguration {
    id: string;
    name: string;
    code: string;
    archived: boolean;
}

export enum ProductQuantityUnit {
    None = 0,
    PerBooking = 1,
    PerParticipant = 2,
}

export interface ResourceAdditionalProduct {
    id: string;
    productId: string;
    minQuantity: number;
    maxQuantity: number;
    defaultQuantity: number;
    quantityUnit: ProductQuantityUnit;
    sequence: number;
}

export interface ResourceBreak {
    id: string;
    text: string;
    days: Days;
    startTime: Time;
    duration: Time;
    activeFrom: Date;
    activeTo: Date;
    archived: boolean;
}

export enum ResourceActionTypes {
    RequestResources = 'REQUEST_RESOURCE',
    ReceivedResources = 'RECEIVE_RESOURCE',
    SaveResource = 'SAVE_RESOURCE',
    ResourceSaved = 'RESOURCE_SAVED',
    ResourceSaveFailed = 'RESOURCE_SAVE_FAILED',
    EditResource = 'EDIT_RESOURCE'
}

export interface LoadResources {
    type: ResourceActionTypes.RequestResources;
}


export interface ReceiveResources {
    type: ResourceActionTypes.ReceivedResources;
    resources: Resource[];
    error: api.ApiError | null;
}

export interface SaveResource {
    type: ResourceActionTypes.SaveResource;
    isNew: boolean;
    resourceId?: string;
    resource: Resource;
}

export interface ResourceSaved {
    type: ResourceActionTypes.ResourceSaved;
    resourceId: string;
}

export interface ResourceSaveFailed {
    type: ResourceActionTypes.ResourceSaveFailed;
    error: api.ApiError;
}

export interface EditResource {
    type: ResourceActionTypes.EditResource
}

export const findBreaksForDay = (resource: Resource, date: Date) => {
    const day = dayFromDate(date);
    const matchDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    return resource.breaks.filter(b => {
        const from = new Date(b.activeFrom.getFullYear(), b.activeFrom.getMonth(), b.activeFrom.getDate());
        const to = new Date(b.activeTo.getFullYear(), b.activeTo.getMonth(), b.activeTo.getDate(), 23, 59, 59);
        return !b.archived && (b.days & day) === day && from <= matchDate && to >= matchDate;
    })
}
