
import * as React from 'react';
import { IconProps } from './iconProps';

const Flag = (props: IconProps) => (
    <svg style={({ width: `${props.width}px`, height: `${props.height}px` })} viewBox='0 0 24 24'>
        <path fill={props.colour} d="M14.4,6L14,4H5V21H7V14H12.6L13,16H20V6H14.4Z" />
    </svg>
);

export default Flag;