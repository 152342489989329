
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { clickHandler, isNullOrEmpty } from '../../../utils/util';
import { IEvent, IBooking, PanelStatus } from './types'
import { Registration, RegistrationEventBookingNote } from '../../../store/pages/reception/types';
import { stringComparer } from '../../../utils/comparers';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';
import RegistrationEventHeader from './registrationEventHeader';
import BookingRegistration from './bookingRegistration';
import EventReservations from './eventReservations';
import ReservationBookingHeader from './reservationBookingHeader';

interface RegistrationEventProps {
    event: IEvent;
    registrations: Registration[];
    expanded: boolean;
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
    expand: (eventId: string) => void;
    collapse: (eventId: string) => void;
    addRegistrationsToBooking: (bookingId: string, registrationIds: string[]) => void;
    unregister: (bookingId: string, registrationId: string) => void;
    payIndividually: (bookingId: string, billId: string, customerId: string) => void;
    payRemaining: (bookingId: string, billId: string, amount: number) => void;
    viewBill: (bookingId: string, billId: string) => void;
    findCustomers: (booking: IBooking) => void;
    editRegistration: (bookingId: string, registrationId: string) => void;
    checkIn: (bookingId: string, registrationId: string) => void;
    undoCheckIn: (bookingId: string, registrationId: string) => void;
    addBooking: (event: IEvent) => void;
    showNotes: (notes: RegistrationEventBookingNote[], name: string, billNumber: string) => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
}

class RegistrationEvent extends React.Component<RegistrationEventProps, {}> {

    static contextTypes = {
        t: PropTypes.func
    }

    constructor(props: RegistrationEventProps) {
        super(props);

        this.state = { status: PanelStatus.Collapsed };
    }

    toggleExpandCollapse = () => this.props.expanded ? this.props.collapse(this.props.event.id) : this.props.expand(this.props.event.id);

    register = (bookingId: string, registrationId: string) => {
        this.props.addRegistrationsToBooking(bookingId, [registrationId]);
    }

    toggleCheckedIn = (bookingId: string, reg: Registration) => reg.checkedIn ? this.props.undoCheckIn(bookingId, reg.id) : this.props.checkIn(bookingId, reg.id)

    render() {
        const { event, expanded, timeFormat } = this.props;

        return (
            <div className='reception-event' style={{ borderLeft: `solid 5px ${event.colour}`, borderBottom: `solid 1px ${event.colour}`, borderRight: `solid 5px ${event.colour}` }}>
                <div className='reception-event-heading'>
                    {event.reservations.length < 1
                        ? <RegistrationEventHeader event={event} timeFormat={timeFormat} expanded={expanded} toggleExpandCollapse={this.toggleExpandCollapse} />
                        : <EventReservations event={event} expanded={expanded} timeFormat={timeFormat} toggleExpandCollapse={this.toggleExpandCollapse} />}
                </div>
                {expanded ? this.renderDetails(event) : this.renderBookingNames(event)}
            </div>
        );
    }

    renderBookingNames = (event: IEvent) => {
        const bookingNames = event.bookings.map(bk => `${bk.organiserFirstName} ${bk.organiserLastName}`).sort(stringComparer).join(' | ');
        return <div className='reception-event-customer-names'>{bookingNames}</div>
    }

    renderDetails = (event: IEvent) => {
        const { t } = this.context;
        const { registrations, dateFormat, timeFormat, findCustomers, payRemaining, viewBill, addBooking, showNotes, editRegistration, payIndividually } = this.props;

        const bookings = event.bookings
            .sort((b1, b2) => stringComparer(`${b1.organiserFirstName} ${b1.organiserLastName}`, `${b2.organiserFirstName} ${b2.organiserLastName}`))
            .map(b => {
                const bookingRegistrations = registrations.filter(r => {
                    const ix = r.bookings.findIndex(x => x.bookingId === b.id);
                    const wasRemoved = r.registeredForEvents.findIndex(e => e.eventId === event.id && e.removed) >= 0;
                    return ix >= 0 && !wasRemoved
                });

                const actions = [<button key='view_bill' className='btn btn-info btn-sm' onClick={e => clickHandler(e, () => viewBill(b.id, b.billId))} style={{ padding: '1px 10px' }}>{t('Global:viewBill')}</button>]
                if (b.outstandingAmount > 0) {
                    actions.push(<button key='pay_all' className='btn btn-primary btn-sm' onClick={e => clickHandler(e, () => payRemaining(b.id, b.billId, b.outstandingAmount))} style={{ padding: '1px 10px', marginTop: '4px' }}>{t('Global:payAll')}</button>)
                }

                return <ReservationBookingHeader
                    booking={b}
                    enableSelectBooking={true}
                    venueId={event.venueId}
                    eventId={event.id}
                    actions={actions}
                    showNotes={showNotes}>
                    {
                        bookingRegistrations.length === 0
                            ? <div className='reception-booking-no-registrations'>{t('ReceptionPage:noRegistrations')}</div>
                            : bookingRegistrations
                                .sort((r1, r2) => stringComparer(`${r1.customerFirstname} ${r1.customerLastname}`, `${r2.customerFirstname} ${r2.customerLastname}`))
                                .map(r => <BookingRegistration booking={b} reg={r} eventId={event.id} dateFormat={dateFormat} timeFormat={timeFormat} editRegistration={editRegistration} payIndividually={payIndividually} toggleCheckedIn={this.toggleCheckedIn} />)
                    }
                    <div key={`${b.id}_actions`} className='reception-booking-buttons'>
                        <button className='btn btn-link' onClick={e => clickHandler(e, () => findCustomers(b))}>{t('ReceptionPage:registerDriver', { billNumber: b.billNumber })}</button>
                        {bookingRegistrations.length === 0 && !isNullOrEmpty(b.organiserLastRegistrationId) ? <><span>|</span><button className='btn btn-link' onClick={e => clickHandler(e, () => this.register(b.id, b.organiserLastRegistrationId))}>{t('ReceptionPage:registerOrganiser', { organiserName: `${b.organiserFirstName} ${b.organiserLastName}` })}</button></> : null}
                    </div>
                </ReservationBookingHeader>
        })

        if (bookings.length < 1) {
            return <div className='reception-booking-header'>
                <div className='flex flex-col'>
                    <div>{t('ReceptionPage:noBookings')}</div>
                    <div>
                        <button className='btn btn-link' onClick={e => clickHandler(e, () => addBooking(event))}>{t('ReceptionPage:addBooking')}</button>
                    </div>
                </div>
            </div>
        } 

        return <>
            {bookings}
            <div className='reception-booking-actions'>
                <button className='btn btn-link' onClick={e => clickHandler(e, () => addBooking(event))}>{t('ReceptionPage:addBooking')}</button>
            </div>
        </>;
    }
}

export default RegistrationEvent;