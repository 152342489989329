
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import {Customer} from '../../../store/pages/customer/types';
import {getAge, properCase, substitue, isNullOrEmpty } from '../../../utils/util';
import {CounterSignature} from '../../../store/apiClient';
import NavButtons from './navButtons';
import SignaturePad from '../../global/signaturePad';

interface RegistrationTermsProps {
    customer: Customer;
    terms: string;
    confirmTermsText: string;
    counterSignatoryTermsId: string;
    counterSignatoryTerms: string;
    counterSignatoryConfirmationText: string;
    SeparateCounterSignatoryTermsRequired: boolean;
    showNickname: boolean;
    minAgeForSelfRegistration: number | null;
    register: (customer: Customer, signatureSvg: string, counterSignature: CounterSignature | null) => void;
    registering: boolean;
    requireSignatures: boolean;
    cancel: () => void;
}

interface RegistrationTermsState {
    customerAge: number;
    requireCounterSignature: boolean;
    termsConfirmed: ct.FormValue<boolean>;
    counterSignatoryTermsConfirmed: ct.FormValue<boolean>;
    counterSignatoryFirstName: ct.FormValue<string>;
    counterSignatoryLastName: ct.FormValue<string>;
    signature: string;
    counterSignatorySignature: string;
    nickname: ct.FormValue<string>;
}

class RegistrationTerms extends React.Component<RegistrationTermsProps, RegistrationTermsState> {

    constructor(props: RegistrationTermsProps) {
        super(props);

        const age = getAge(new Date(props.customer.birthYear, props.customer.birthMonth-1, props.customer.birthDay));
        const requireCounterSignature = props.minAgeForSelfRegistration !== null && age < props.minAgeForSelfRegistration;
        this.state = {
            customerAge: age,
            requireCounterSignature: requireCounterSignature,
            termsConfirmed: this.validateConfirmTerms(false),
            counterSignatoryTermsConfirmed: this.validateCounterSignatoryTermsConfirmed(false),
            counterSignatoryFirstName: this.validateCounterSignatoryFirstName('', requireCounterSignature),
            counterSignatoryLastName: this.validateCounterSignatoryLastName('', requireCounterSignature),
            signature: '',
            counterSignatorySignature: '',
            nickname: this.validateNickname(props.customer.nickname || '')
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    validateConfirmTerms = (val: boolean) => v.validate(val, 'confirmTerms', []);
    validateCounterSignatoryTermsConfirmed = (val: boolean) => v.validate(val, 'confirmCounterSignatoryTerms', []);
    validateCounterSignatoryFirstName = (val: string, requireCounterSignature: boolean) => v.validate(properCase(val), 'counterSignatoryFirstName', requireCounterSignature ? [v.required] : []);
    validateCounterSignatoryLastName = (val: string, requireCounterSignature: boolean) => v.validate(properCase(val), 'counterSignatoryLastName', requireCounterSignature ? [v.required] : []);
    validateNickname = (val: string) => v.validate(val, 'nickname', []);

    onSignatureChanged = (imgData: string) => this.setState({ signature: imgData });

    onCounterSignatorySignatureChanged = (imgData: string) => this.setState({ counterSignatorySignature: imgData });

    cancel = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        this.props.cancel();
    }

    submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {
            const { register, customer, counterSignatoryTermsId, showNickname } = this.props;
            const { requireCounterSignature, counterSignatoryTermsConfirmed, counterSignatoryFirstName, counterSignatoryLastName, signature, counterSignatorySignature, nickname } = this.state;

            var cust = showNickname ? { ...customer, nickname: nickname.value || customer.nickname } : customer;

            register(cust,
                signature,
                requireCounterSignature ?
                    {
                        termsAndConditionsId: counterSignatoryTermsId,
                        counterSignatureRequired: requireCounterSignature,
                        counterSigned: counterSignatoryTermsConfirmed.value,
                        counterSignatoryFirstName: counterSignatoryFirstName.value,
                        counterSignatoryLastName: counterSignatoryLastName.value,
                        signatureSvg: counterSignatorySignature
                    }
                    : null);
        }
    }

    render() {
        const { t } = this.context;
        const { customer, confirmTermsText, terms, cancel, registering, requireSignatures, showNickname } = this.props;
        const { termsConfirmed, requireCounterSignature, counterSignatoryTermsConfirmed, counterSignatoryFirstName, counterSignatoryLastName, customerAge, signature, counterSignatorySignature } = this.state;

        const canMoveNext = ((!requireSignatures && termsConfirmed.value) || (requireSignatures && !isNullOrEmpty(signature)))
            && (!requireCounterSignature || (counterSignatoryFirstName.isValid && counterSignatoryLastName.isValid && ((!requireSignatures && counterSignatoryTermsConfirmed.value) || (requireSignatures && !isNullOrEmpty(counterSignatorySignature)))));

        const counterSig = this.renderCounterSig();
        // Currently I can't get the kiosk to move the page up when the keyboard opens (seems to be an Android issue and I haven't yet found a work-around)
        // so for the time being adding some space to the end of the page to leave space for the keyboard
        const w = window.innerWidth;
        const h = window.innerHeight;

        const dirtyHackToAddSpaceAtBottomOnKiosk = counterSig === null || w !== 800 || h !== 1280 ? null : <div style={({ height: '360px' })}></div>;
        const age = requireCounterSignature ? <span className='warning-message'>{`  (${t('Global:age')} ${customerAge})`}</span> : null;
        const formattedConfirmTermsText = substitue(confirmTermsText, { customer_name: `${customer.firstname} ${customer.lastname}` });

        return (
            <div>
                <form className='registration-form' onSubmit={this.submit} autoComplete='off'>

                    <div className='registration-form-terms' dangerouslySetInnerHTML={{ __html: terms }}></div>
                    <p/>

                    <span className='registration-terms-name'>{customer.firstname} {customer.lastname}</span>
                    {age}

                    {requireSignatures
                        ? <div><label>{formattedConfirmTermsText}</label><SignaturePad id='signature' labelKey='Registration:signature' width='100%' format='image/svg+xml' signatureChanged={this.onSignatureChanged} /></div>
                        : <ct.Checkbox id='confirmTerms' labelKey={formattedConfirmTermsText} value={termsConfirmed} callback={val => this.setState({ termsConfirmed: this.validateConfirmTerms(val) })} style={({ maxWidth: '9999px' })} />
                    }

                    <p />

                    {counterSig}

                    <p />

                    {showNickname
                        ? <div>
                            <ct.TextBox key='nickname' id='nickname' labelKey='Global:nickname' placeholderKey='Global:nicknamePlaceholder' hintKey='Registration:nicknameHint' value={this.state.nickname} callback={val => this.setState({ nickname: this.validateNickname(val) })} />
                        </div>
                        : null
                    }

                    <NavButtons t={t} cancel={cancel} preventNext={!canMoveNext || registering} />
                </form>
                {dirtyHackToAddSpaceAtBottomOnKiosk}
            </div>
        );
    }

    renderCounterSig = () => {
        if (!this.state.requireCounterSignature)
            return null;

        const counterSignatoryConfirmationText = substitue(this.props.counterSignatoryConfirmationText, { customer_name: `${this.props.customer.firstname} ${this.props.customer.lastname}` });

        return (
            <div>
                <hr />

                {this.props.SeparateCounterSignatoryTermsRequired ? <div className='registration-form-terms' dangerouslySetInnerHTML={{ __html: this.props.counterSignatoryTerms }}></div> : null}
                <p/>

                <ct.TextBox id='counterSignatoryFirstName' labelKey='Registration:counterSignatoryFirstName' placeholderKey='Registration:counterSignatoryFirstName' value={this.state.counterSignatoryFirstName} callback={val => this.setState({ counterSignatoryFirstName: this.validateCounterSignatoryFirstName(val, this.state.requireCounterSignature) })} />
                <ct.TextBox id='counterSignatoryLastName' labelKey='Registration:counterSignatoryLastName' placeholderKey='Registration:counterSignatoryLastName' value={this.state.counterSignatoryLastName} callback={val => this.setState({ counterSignatoryLastName: this.validateCounterSignatoryLastName(val, this.state.requireCounterSignature) })} />
                {this.props.requireSignatures
                    ? <div><label>{counterSignatoryConfirmationText}</label><SignaturePad id='signature' labelKey='Registration:signature' width='100%' format='image/svg+xml' signatureChanged={this.onCounterSignatorySignatureChanged} /></div>
                    : <ct.Checkbox id='confirmCounterSignatoryTerms' labelKey={counterSignatoryConfirmationText} value={this.state.counterSignatoryTermsConfirmed} callback={val => this.setState({ counterSignatoryTermsConfirmed: this.validateConfirmTerms(val) })} style={({ maxWidth: '9999px' })} />
                }

                <p />
            </div>
        );
    }
}

export default RegistrationTerms;