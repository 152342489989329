

import * as React from 'react';
import * as PropTypes from 'prop-types'

import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';

import * as api from '../../../../../store/apiClient';

import { AjaxResponse } from 'rxjs/ajax';
import { BillPayment, GatewayPaymentStatus } from '../../../../../store/pages/pointOfSale/types';
import { PaymentMethod } from '../../../../../store/pages/paymentMethods/types';
import { clickHandler, isNullOrEmpty } from '../../../../../utils/util';

interface StripeTerminalPaymentProps {
    venueId: string;
    billId: string;
    payment: BillPayment;
    amount: number;
    paymentMethod: PaymentMethod;
    terminalId: string;
    cancelStripePayment: (paymentGatewayPaymentId: string | null) => void;
    paymentFailed: () => void;
    onSaveError: (saveError: api.ApiError) => void;
    onComplete: (billId: string) => void;
    logout: () => void;
}

interface StripeTerminalPaymentState {
    initialized: boolean;
    paymentGatewayPaymentId: string | null;
    paymentAmount: number;
    token: string | null;
    paymentIntentId: string | null;
    processing: boolean;
    error: string | null;
}

interface ICreateStripeTerminalGatewayPaymentResponse {
    gatewayPayment: GatewayPayment;
    paymentAmount: number;
    token: string;
    cancelled: boolean;
}

interface IGetGatewayPaymentResponse {
    status: GatewayPaymentStatus;
}

interface GatewayPayment {
    paymentGatewayPaymentId: string;
}


export default class StripeTerminalPayment extends React.Component<StripeTerminalPaymentProps, StripeTerminalPaymentState> {

    timer: NodeJS.Timeout | null = null;

    constructor(props: StripeTerminalPaymentProps) {
        super(props);

        this.state = {
            initialized: false,
            paymentGatewayPaymentId: null,
            paymentAmount: props.amount,
            token: null,
            paymentIntentId: null,
            processing: false,
            error: null
        }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        const { billId, payment, amount, paymentMethod, terminalId } = this.props;
        this.initiateTerminalGatewayPayment(billId, payment, amount, paymentMethod, terminalId);
    }

    initiateTerminalGatewayPayment = (billId: string, payment: BillPayment, amount: number, paymentMethod: PaymentMethod, terminalId: string) => {
        const { logout, onSaveError } = this.props;
        const body = {
            venueId: this.props.venueId,
            billId: billId,
            billPaymentId: payment.id,
            paymentMethodId: paymentMethod.id,
            description: payment.description,
            isSecurityPayment: payment.isSecurityPayment,
            amount: amount,
            additionalInfo: payment.additionalInfo,
            terminalId: terminalId
        };

        const responseHandler = (response: AjaxResponse) => {
            const resp = response.response as ICreateStripeTerminalGatewayPaymentResponse;
            if (resp) {
                this.setState({
                    initialized: true,
                    processing: true,
                    error: null,
                    paymentGatewayPaymentId: resp.gatewayPayment.paymentGatewayPaymentId,
                    paymentAmount: resp.paymentAmount,
                    token: resp.token
                }, () => this.timer = setTimeout(() => this.checkPaymentProgress(resp.token, terminalId, 0), 2000));
            }
        }

        if (isNullOrEmpty(payment.id)) {
            api.postWithAuth(`api/v1/paymentGateway/payment/stripe/terminal`, body, logout).subscribe(responseHandler, onSaveError);
        } else {
            api.putWithAuth(`api/v1/paymentGateway/payment/stripe/terminal`, body, logout).subscribe(responseHandler, onSaveError);
        }
    }
    
    checkPaymentProgress = (token: string, terminalId: string, retryCount: number = 0) => {
        api.putWithAuth(`api/v1/paymentGateway/customerPayment/stripe/checkTerminal`, { token: token , terminalId: terminalId }, () => ({ /* TODO: Handle auth error*/ }))
            .subscribe(resp => {
                const response = resp.response as IGetGatewayPaymentResponse;
                if (response.status === GatewayPaymentStatus.Success) {
                    this.props.onComplete(this.props.billId)
                } else if (response.status === GatewayPaymentStatus.InProgress) {
                    // wait a coupe of seconds for processing to complete
                    this.timer = setTimeout(() => this.checkPaymentProgress(token, terminalId, retryCount + 1), 2000);
                } else {
                    this.setState({ processing: false, error: this.context.t('ProcessStripePayment:failed') })
                }
            }, err => this.setState({ processing: false, error: err }));
    }

    cancelStripePayment = () => {
        const { cancelStripePayment } = this.props;
        const { paymentGatewayPaymentId } = this.state;

        const timer = this.timer;
        if (timer)
            clearTimeout(timer);

        cancelStripePayment(paymentGatewayPaymentId);
    }

    render() {
        const { t } = this.context;
        const { paymentFailed } = this.props;
        const { initialized, processing, error } = this.state;

        if (!initialized)
            return this.renderInitializing();

        const buttonStyle: React.CSSProperties = { margin: '0 8px' }

        return (
            <>
                <div className='row'>
                    <div className='col-xs-12'>
                    </div>
                </div>
                {processing ? <div className='row'><div className='col-xs-12 alert alert-info'>{t('ProcessStripePayment:processingReaderPayment')}</div></div> : null}
                {error
                    ? <div className='row'>
                        <div className='col-xs-12 alert alert-danger'>{error}</div>
                        <div className='col-xs-12 btn-toolbar-centered mt-15'>
                            <button className='btn btn-default' type='button' style={buttonStyle} onClick={e => clickHandler(e, paymentFailed)}>{t('Global:close')}</button>
                        </div>
                      </div>
                    : <div className='row'>
                        <div className='col-xs-12 btn-toolbar-centered mt-15'>
                            <button className='btn btn-default' type='button' style={buttonStyle} onClick={e => clickHandler(e, this.cancelStripePayment)}>{t('Global:cancel')}</button>
                        </div>
                    </div>
                }
            </>
        )
    }

    renderInitializing = () => {
        const { t } = this.context;
        return <div className='row'><div className='col-xs-12'>{t('ProcessStripePayment:loading')}</div></div>
    }
}