
import * as mt from './types';

export type ModalActions = mt.ShowModal | mt.CloseModal;


export const actionCreators = {
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => ({ type: mt.ModalActionTypes.ShowModal, overlayComponent: overlayComponent, screenName: screenName, noScroll: noScroll }),
    closeModal: () => ({ type: mt.ModalActionTypes.CloseModal })
}

