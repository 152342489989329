
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { ITranslationContext } from '../../../../translations';
import { ActivityFormatAvailability } from '../../../../store/pages/activityFormats/types';
import { clickHandler } from '../../../../utils/util';
import { DateFormat } from '../../../../store/pages/venues/types';
import { Days } from '../../../../store/global/types';

interface OnLineAvailabilityProps {
    availability: ActivityFormatAvailability[];
    dateFormat: DateFormat;
    addAvailability: () => void;
    editAvailability: (key: string) => void;
    removeAvailability: (key: string) => void;
}

const OnLineAvailability = (props: OnLineAvailabilityProps, context: ITranslationContext) => {
    const { t } = context;
    const { availability, addAvailability, editAvailability, removeAvailability, dateFormat } = props;
    const dayStyle = { width: '30px' }; 
    return (
        <div className='activity-format-products'>
            <table className='table table-super-condensed table-borderless' style={({ width: 'auto' })}>
                <thead>
                    <tr>
                        <th colSpan={7} className='text-center'>{t('ActivityFormatAvailability:days')}</th>
                        <th className='text-center'>{t('ActivityFormatAvailability:times')}</th>
                        <th className='text-center'>{t('ActivityFormatAvailability:participants')}</th>
                        <th className='text-center'>{t('ActivityFormatAvailability:daysBefore')}</th>
                        <th className='text-center'>{t('ActivityFormatAvailability:start')}</th>
                        <th className='text-center'>{t('ActivityFormatAvailability:end')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {availability.sort((a1, a2) => {
                        if (a1.startDate) {
                            return a2.startDate ? a1.startDate.getTime() - a2.startDate.getTime() : 1;
                        } else {
                            return a2.startDate ? -1 : 0;
                        }
                        }).map(a => <tr key={a.key} onClick={e => clickHandler(e, () => editAvailability(a.key))}>
                        <td className='btn-link btn-no-padding text-center' style={dayStyle}>{(a.days & Days.Monday) === Days.Monday ? t('Global:Monday2ch') : null}</td>
                        <td className='btn-link btn-no-padding text-center' style={dayStyle}>{(a.days & Days.Tuesday) === Days.Tuesday ? t('Global:Tuesday2ch') : null}</td>
                        <td className='btn-link btn-no-padding text-center' style={dayStyle}>{(a.days & Days.Wednesday) === Days.Wednesday ? t('Global:Wednesday2ch') : null}</td>
                        <td className='btn-link btn-no-padding text-center' style={dayStyle}>{(a.days & Days.Thursday) === Days.Thursday ? t('Global:Thursday2ch') : null}</td>
                        <td className='btn-link btn-no-padding text-center' style={dayStyle}>{(a.days & Days.Friday) === Days.Friday ? t('Global:Friday2ch') : null}</td>
                        <td className='btn-link btn-no-padding text-center' style={dayStyle}>{(a.days & Days.Saturday) === Days.Saturday ? t('Global:Saturday2ch') : null}</td>
                        <td className='btn-link btn-no-padding text-center' style={dayStyle}>{(a.days & Days.Sunday) === Days.Sunday ? t('Global:Sunday2ch') : null}</td>
                        <td className='btn-link btn-no-padding text-center'>{a.fromTime.toShortTimeString()} - {a.toTime.toShortTimeString()}</td>
                        <td className='btn-link btn-no-padding text-center'>{a.minParticipants.toFixed(0)} - {a.maxParticipants.toFixed(0)}</td>
                        <td className='btn-link btn-no-padding text-center'>{a.maxDaysBefore.toFixed(0)} - {a.minDaysBefore.toFixed(0)}</td>
                        <td className='btn-link btn-no-padding text-center'>{a.startDate ? a.startDate.toShortDateString(dateFormat) : ''}</td>
                        <td className='btn-link btn-no-padding text-center'>{a.endDate ? a.endDate.toShortDateString(dateFormat) : ''}</td>
                        <td><span className='glyphicon glyphicon-trash red' onClick={e => clickHandler(e, () => removeAvailability(a.key))} style={({ cursor: 'pointer', padding: '5px' })}></span></td>
                    </tr>)}                    
                </tbody>
            </table>
            <div><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, addAvailability)}>{t('ActivityFormatAvailability:addAvailability')}</button></div>
        </div>
    );
}

OnLineAvailability.contextTypes = {
    t: PropTypes.func
}

export default OnLineAvailability;