
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { PaymentReminderConfig, PaymentReminderPaymentTypes } from '../../../../store/pages/paymentReminderConfigurations/types';
import PaymentReminderConfigForm from './paymentReminderConfigForm';
import Loading from '../../../global/loading';
import { clickHandler } from '../../../../utils/util';
import { ClientEmailTemplate } from '../../../../store/pages/emailTemplates/types';
import { Venue } from '../../../../store/pages/venues/types';


interface PaymentReminderConfigListProps {
    venue: Venue;
    isLoadingPaymentReminderConfigs: boolean;
    paymentReminderConfigs: PaymentReminderConfig[];
    isLoadingPaymentReminderEmailTemplates: boolean;
    paymentReminderEmailTemplates: ClientEmailTemplate[];
    editPaymentReminderConfig: () => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
}

class PaymentReminderConfigList extends React.Component<PaymentReminderConfigListProps, {}> {

    static contextTypes = {
        t: PropTypes.func
    }

    private editPaymentReminderConfig = (paymentReminderConfig: PaymentReminderConfig) => {
        this.showOverlay(paymentReminderConfig, false);
    }

    private addPaymentReminderConfig = () => {
        this.showOverlay(null, true);
    }

    private showOverlay = (paymentReminderConfig: PaymentReminderConfig | null, addingNew: boolean) => {
        const { id: venueId } = this.props.venue;
        this.props.editPaymentReminderConfig();
        this.props.showModal(<PaymentReminderConfigForm isNew={addingNew} venueId={venueId} paymentReminderConfig={paymentReminderConfig} />, 'PaymentReminderConfigForm');
    }

    isLoading = () => this.props.isLoadingPaymentReminderConfigs || this.props.isLoadingPaymentReminderEmailTemplates;

    render() {
        const body = this.isLoading() ? <Loading /> : this.renderPaymentReminderConfigList();

        return <section>
            <header className='section-header'>
                <h4>{this.context.t('PaymentReminderConfigList:title')}</h4>
            </header>
            <div className='at-panel sub-section-panel-240px'>
                {body}
            </div>
        </section>;
    }

    getPaymentTypes = (paymentTypes: PaymentReminderPaymentTypes, t: (key: string) => string) => {
        if (PaymentReminderPaymentTypes.AllPayments === (paymentTypes & PaymentReminderPaymentTypes.AllPayments)) {
            return <span className="label label-info">{t('PaymentReminderConfigForm:AllPayments')}</span>
        } else if (PaymentReminderPaymentTypes.Deposit === (paymentTypes & PaymentReminderPaymentTypes.Deposit)) {
            return <span className="label label-info">{t('PaymentReminderConfigForm:Deposit')}</span>
        } else {
            return <span className="label label-info">{t('PaymentReminderConfigForm:NonDeposit')}</span>
        }
    }
    
    getSendValue = (timeOffset: number, t: (key: string, args?: any) => string) => {
        if (timeOffset === 0) {
            return t('PaymentReminderConfigList:sendOnDayDue');
        } else if (timeOffset < 0) {
            return t('PaymentReminderConfigList:sendDaysOverdue', { days: Math.abs(timeOffset) });
        } else {
            return t('PaymentReminderConfigList:sendDaysBefore', { days: Math.abs(timeOffset) });
        }
    }

    private renderPaymentReminderConfigList = () => {

        const { t } = this.context;
        const { venue, paymentReminderConfigs, paymentReminderEmailTemplates } = this.props;
        const { id: venueId } = venue;

        const configs = paymentReminderConfigs.filter(cfg => cfg.venueId === venueId).map(cfg => {
            const emailTemplate = paymentReminderEmailTemplates.find(e => e.clientEmailTemplateId === cfg.clientEmailTemplateId);
            return (
                <tr key={cfg.id}>
                    <td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.editPaymentReminderConfig(cfg))}>{cfg.name}</button></td>
                    <td>{emailTemplate ? emailTemplate.name : ''}</td>
                    <td>{this.getPaymentTypes(cfg.paymentTypes, t)}</td>
                    <td>{this.getSendValue(cfg.timeOffsetInDays, t)}</td>
                </tr>
            )
        });

        return (
            <div>
                <table className='table table-condensed'>
                    <thead>
                        <tr>
                            <th>{t('PaymentReminderConfigList:nameHeading')}</th>
                            <th>{t('PaymentReminderConfigList:emailTemplateHeading')}</th>
                            <th>{t('PaymentReminderConfigList:applyToHeading')}</th>
                            <th>{t('PaymentReminderConfigList:sendHeading')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {configs}
                    </tbody>
                </table>
                <button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, this.addPaymentReminderConfig)}>{this.context.t('PaymentReminderConfigList:addPaymentReminderConfig')}</button>
            </div>
        );
    }
}

export default PaymentReminderConfigList;
