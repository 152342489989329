
// https://blog.angularindepth.com/how-to-reduce-action-boilerplate-90dc3d389e2b

import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as it from './types';
import { IStore } from '../..';

export type ActivityFormatGroupActions = it.LoadActivityFormatGroups | it.ReceiveActivityFormatGroups | it.SaveActivityFormatGroup | it.ActivityFormatGroupSaved | it.ActivityFormatGroupSaveFailed | it.EditActivityFormatGroup;

export const actionCreators = {
    loadActivityFormatGroups: () => ({ type: it.ActivityFormatGroupActionTypes.RequestActivityFormatGroups }),
    loadActivityFormatGroupsComplete: (activityFormatGroups: it.ActivityFormatGroup[], err: api.ApiError | null) => ({ type: it.ActivityFormatGroupActionTypes.ReceivedActivityFormatGroups, activityFormatGroups: activityFormatGroups, error: err }),
    editActivityFormatGroup: () => ({ type: it.ActivityFormatGroupActionTypes.EditActivityFormatGroup }),
    saveActivityFormatGroup: (isNew: boolean, activityFormatGroup: it.ActivityFormatGroup, img: File | null) => ({ type: it.ActivityFormatGroupActionTypes.SaveActivityFormatGroup, isNew: isNew, activityFormatGroup: activityFormatGroup, img: img }),
    activityFormatGroupSaved: (activityFormatGroupId: string) => ({ type: it.ActivityFormatGroupActionTypes.ActivityFormatGroupSaved, activityFormatGroupId: activityFormatGroupId }),
    activityFormatGroupSaveFailed: (error: api.ApiError) => ({ type: it.ActivityFormatGroupActionTypes.ActivityFormatGroupSaveFailed, error: error })
}

interface IGetActivityFormatGroupsResponse {
    activityFormatGroups: it.ActivityFormatGroup[];
}

interface ISaveActivityFormatGroupResponse {
    activityFormatGroupId: string;
}

export const handleClientChange = (store: IStore) => [actionCreators.loadActivityFormatGroups]

//https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-after-catching-and-processing-an-error-with-emitting-somethi

const loadActivityFormatGroups = () => Observable.defer(() => api.getJson<IGetActivityFormatGroupsResponse>('api/v1/activityFormatGroup/'))
    .map(response => {
        return actionCreators.loadActivityFormatGroupsComplete(response.activityFormatGroups, null)
    });

export const loadActivityFormatGroupEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        it.ActivityFormatGroupActionTypes.RequestActivityFormatGroups,
        action => loadActivityFormatGroups(),
        err => actionCreators.loadActivityFormatGroupsComplete([], err));

export const reloadActivityFormatGroupEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        it.ActivityFormatGroupActionTypes.ActivityFormatGroupSaved,
        action => loadActivityFormatGroups(),
        err => actionCreators.loadActivityFormatGroupsComplete([], err));

export const saveActivityFormatGroupEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        it.ActivityFormatGroupActionTypes.SaveActivityFormatGroup,
        action => {
            const safg = action as it.SaveActivityFormatGroup;
            let activityFormatGroupId = safg.activityFormatGroup.id;

            const img = safg.img;

            const imageObservables: Observable<string>[] = [img ? api.uploadFile(img, 'api/v1/clientimage').map(res => res.response.imageId) : Observable.of(null)];

            const images = Observable.forkJoin(imageObservables);
            return images.flatMap(imgIds => {
                var bodyWithImg = {
                    ...safg.activityFormatGroup,
                    imageId: imgIds[0]
                };

                return (safg.isNew ? api.post('api/v1/activityFormatGroup/', bodyWithImg) : api.put(`api/v1/activityFormatGroup/${safg.activityFormatGroup.id}`, bodyWithImg))
                    .map(response => {
                        if (safg.isNew) {
                            const sir = response.response as ISaveActivityFormatGroupResponse;
                            if (sir) {
                                activityFormatGroupId = sir.activityFormatGroupId;
                            }
                        }

                        return ({ type: it.ActivityFormatGroupActionTypes.ActivityFormatGroupSaved, activityFormatGroup: activityFormatGroupId });
                    });
            });
        },
        (err: api.ApiError) => actionCreators.activityFormatGroupSaveFailed(err));
