import { time } from "console";
import { digits, formatTime } from "../../utils/util";
import { TimeFormat } from "../pages/venues/types";

export interface ValidationError {
    field: string;
    messageKey: string;
    fieldValue: string | null;
}

export const colours = ['#F44236', '#EA1E63', '#9C28B1', '#673BB7', '#3E50B4', '#2196F3', '#03A9F5', '#00BCD5', '#009788', '#4CB050', '#8BC24A', '#CDDC39', '#FFEB3C', '#FEC107', '#FF9700', '#FD5621', '#785446', '#9E9E9E', '#607D8B'];
//export const colours = ['#F0A30A', '#825A2C', '#0050EF', '#A20024', '#1BA1E2', '#D70072', '#A3C400', '#6900FE', '#60A917', '#008A00', '#75608A', '#6D8764', '#FA6800', '#F471D0', '#E51400', '#7A3B3F', '#647587', '#00ABA8', '#A900FE', '#D7C000'];

export enum Days {
    None = 0,
    Monday = 1,
    Tuesday = 1 << 1,
    Wednesday = 1 << 2,
    Thursday = 1 << 3,
    Friday = 1 << 4,
    Saturday = 1 << 5,
    Sunday = 1 << 6
}

export class Time {

    hours: number;
    minutes: number;
    seconds: number;

    constructor(hours: number, minutes: number, seconds: number) {
        this.hours = hours;
        this.minutes = minutes;
        this.seconds = seconds;
    }

    static parse(time: string | Time): Time {
        if (typeof time === 'string') {
            const parts = time.split(':');
            const hours = parts.length > 0 ? parseInt(parts[0]) : 0;
            const minutes = parts.length > 1 ? parseInt(parts[1]) : 0;
            const seconds = parts.length > 2 ? parseInt(parts[2]) : 0;

            return new Time(hours, minutes, seconds);
        }

        return time;
    }

    static fromDate(date: Date): Time {
        return new Time(date.getHours(), date.getMinutes(), date.getSeconds());
    }

    static fromSeconds(valueInSeconds: number): Time {
        const hours = Math.floor(valueInSeconds / 60 / 60);
        const minutes = Math.floor((valueInSeconds - (hours * 60 * 60)) / 60);
        const seconds = valueInSeconds - (hours * 60 * 60) - (minutes * 60);
        return new Time(hours, minutes, seconds);
    }

    static merge(date: Date, time: Time): Date {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes(), time.getSeconds());
    }

    static zero = () => new Time(0, 0, 0);

    isZero(): boolean {
        return this.hours === 0 && this.minutes === 0 && this.seconds === 0;
    }

    asDate(): Date {
        var ms = (this.hours * 3600 + this.minutes * 60 + this.seconds) * 1000;
        var dt = new Date(ms);
        return dt;
    }

    getHours(): number {
        return this.hours;
    }

    getMinutes(): number {
        return this.minutes;
    }

    getSeconds(): number {
        return this.seconds;
    }

    val(): number {
        return (this.hours * 3600) + (this.minutes * 60) + this.seconds;
    }

    toShortTimeString(timeFormat?: TimeFormat): string {
        return formatTime(this.hours, this.minutes, timeFormat ? timeFormat: TimeFormat.TwentyFourHour);
    //    if (timeFormat && timeFormat === TimeFormat.TwelveHour) {
    //        return `${this.hours > 12 ? this.hours - 12 : this.hours}:${digits(this.minutes, 2)} ${this.hours >= 12 ? "pm" : "am"}`;
    //    } else {
    //        return `${this.hours}:${digits(this.minutes, 2)}`;
    //    }
    }

    toString(): string {
        return `${this.hours}:${digits(this.minutes, 2)}:${digits(this.seconds, 2)}`;
    }

    add(other: Time): Time {
        const newTime = this.val() + other.val();
        var h = Math.floor(newTime / 3600);
        var m = Math.floor((newTime - (h * 3600)) / 60);
        var s = newTime - (h * 3600) - (m * 60);
        return new Time(h, m, s);
    }

    subtract(other: Time): Time {
        const newTime = this.val() - other.val();
        var h = Math.floor(newTime / 3600);
        var m = Math.floor((newTime - (h * 3600)) / 60);
        var s = newTime - (h * 3600) - (m * 60);
        return new Time(h, m, s);
    }
}

export enum IntervalUnit {
    Year = 1,
    Month = 2,
    Day = 3,
    Hour = 4,
    Minute = 5
}

export interface Interval {
    value: number;
    unit: IntervalUnit
}