
import { EmailType } from '../emailTemplates/types';
import * as api from '../../apiClient';

export enum EmailStatus {
    Created = 0,
    Queued = 1,
    Processing = 2,
    Sent = 3,
    Failed = 4,
    Cancelled = 5
}

export enum EmailEventType {
    Created = 0,
    Queued = 1,
    Processing = 2,
    Sent = 3,
    Failed = 4,
    Processed = 5, // 0
    Deferred = 6, // 1
    Delivered = 7, // 2
    Open = 8, // 3
    Click = 9, // 4
    Bounced = 10,  // 5
    Dropped = 11, // 6
    Spamreport = 12, // 7
    Unsubscribe = 13 // 8
}

export interface Email {
    id: string;
    createdDate: Date;
    templateName: string;
    emailAddress: string;
    subject: string;
    emailType: EmailType;
    status: EmailStatus;
    processingError: string;
    createDateTime: Date;
    timeSent: Date | null;
    customerFirstName: string | null;
    customerLastName: string | null;
    customerLatestEmailAddress: string | null;
    processed: Date | null;
    deferred: Date | null;
    delivered: Date | null;
    open: Date | null;
    click: Date | null;
    bounce: Date | null;
    dropped: Date | null;
    spamreport: Date | null;
    unsubscribe: Date | null;
}

export interface EmailDetails {

}

export interface EmailState {
    isActive: boolean;
    isLoading: boolean;
    emails: Email[];
    pageNumber: number;
    pageSize: number;
    maxPage: number;
    loadError: api.ApiError | null;
}

export enum EmailActionTypes {
    RequestEmails = 'REQUEST_EMAILS',
    ReceivedEmails = 'RECEIVE_EMAILS',
}

export interface LoadEmails {
    type: EmailActionTypes.RequestEmails;
    pageNumber: number;
    pageSize: number;
    to: string;
    status: EmailStatus;
    fromDate: Date | null;
    toDate: Date | null;
}

export interface ReceiveEmails {
    type: EmailActionTypes.ReceivedEmails;
    emails: Email[];
    page: number;
    maxPage: number;
    error: api.ApiError | null;
}
