
import * as PropTypes from 'prop-types'

import * as ct from '../../../global/controls';

import { Variation } from './activityFormatForm';
import { clickHandler } from '../../../../utils/util';
import { Time } from '../../../../store/global/types';
import { ITranslationContext } from '../../../../translations';
import { ActivityFormat } from '../../../../store/pages/activityFormats/types';

interface ActivityFormatVariationListItemProps {
    activityFormatId: string;
    variation: Variation;
    activityFormats: ActivityFormat[];
    minParticipantsChanged: (key: string, val: number) => void;
    maxParticipantsChanged: (key: string, val: number) => void;
    nameChanged: (key: string, val: string) => void;
    runningTimeChanged: (key: string, scheduleId: string, val: Time) => void;
    removeVariation: (key: string) => void;
    minGapBeforeChanged: (key: string, scheduleId: string, val: Time) => void;
    minGapAfterChanged: (key: string, scheduleId: string, val: Time) => void;
    mustBookItemsTogetherChanged: (key: string, val: boolean) => void;
    toggleCompatibleActivityFormatVariation: (key: string, activityFormatId: string, activityFormatVariationId: string, selected: boolean) => void;
    minBreakDurationChanged: (key: string, scheduleId: string, index: number, val: Time | null) => void;
    maxBreakDurationChanged: (key: string, scheduleId: string, index: number, val: Time | null) => void;
    scheduleTimingTemplateNameChanged: (key: string, scheduleId: string, val: string) => void;
    timingTemplateNameChanged: (key: string, val: string) => void;
    addSchedule: (key: string) => void;
    removeSchedule: (variationKey: string, scheduleId: string) => void;
    canRemove: boolean;
}

const ActivityFormatVariationListItem = (props: ActivityFormatVariationListItemProps, context: ITranslationContext) => {
    const { t } = context;
    const { variation, removeVariation, canRemove, activityFormatId, activityFormats } = props;
    const { key, minParticipants, maxParticipants, mustBookItemsTogether, compatibleActivityFormatVariations, timingTemplateName } = props.variation;

    const minParticipantsChanged = (val: number) => props.minParticipantsChanged(variation.key, val && !isNaN(val) ? val : 1);
    const maxParticipantsChanged = (val: number) => props.maxParticipantsChanged(variation.key, val && !isNaN(val) ? val : 1);
    const timingTemplateNameChanged = (value: string) => props.timingTemplateNameChanged(variation.key, value);
    const runningTimeChanged = (scheduleId: string, val: Time | null) => props.runningTimeChanged(variation.key, scheduleId, val ? val : Time.zero());
    const minGapBeforeChanged = (scheduleId: string, val: Time | null) => props.minGapBeforeChanged(variation.key, scheduleId, val ? val : Time.zero());
    const minGapAfterChanged = (scheduleId: string, val: Time | null) => props.minGapAfterChanged(variation.key, scheduleId, val ? val : Time.zero());
    const mustBookItemsTogetherChanged = (val: boolean) => props.mustBookItemsTogetherChanged(variation.key, val);
    const minBreakDurationChanged = (scheduleId: string, index: number, val: Time | null) => props.minBreakDurationChanged(variation.key, scheduleId, index, val);
    const maxBreakDurationChanged = (scheduleId: string, index: number, val: Time | null) => props.maxBreakDurationChanged(variation.key, scheduleId, index, val);
    const scheduleTimingTemplateNameChanged = (scheduleId: string, value: string) => props.scheduleTimingTemplateNameChanged(variation.key, scheduleId, value);
    const removeSchedule = (scheduleId: string) => props.removeSchedule(variation.key, scheduleId);

    const toggleCompatibleActivityFormatVariation = (key: string, selected: boolean) => {
        const [activityFormatId, variationId] = key.split('|');
        props.toggleCompatibleActivityFormatVariation(variation.key, activityFormatId, variationId, selected)
    }

    const compatibleActivityOptions = activityFormats.filter(a => a.id !== activityFormatId).reduce<ct.MultiSelectOption[]>((options, a) => {
        for (let i = 0; i < a.variations.length; i++) {
            const v = a.variations[i];
            options.push({
                key: `${a.id}|${v.id}`,
                name: `${a.name}${(a.variations.length > 1 ? ` [${v.minParticipants}-${v.maxParticipants}]` : '')}`,
                selected: (compatibleActivityFormatVariations || []).findIndex(x => x.activityFormatId === a.id && x.activityFormatVariationId === v.id) >= 0
            })
        }

        return options;
    }, []);

    return (
        <li key={key} className='activity-format-variation-list-item'>
            <div className='row'>
                <div className='col-xs-6 col-lg-2'>
                    <ct.NumberBox id={`${key}_minParticipants`} labelKey='ActivityFormatForm:minParticipants' placeholderKey='' min='1' value={minParticipants} callback={minParticipantsChanged} />
                </div>
                <div className='col-xs-6 col-lg-2'>
                    <ct.NumberBox id={`${key}_maxParticipants`} labelKey='ActivityFormatForm:maxParticipants' placeholderKey='' min='1' value={maxParticipants} callback={maxParticipantsChanged} />
                </div>
                <div className='col-xs-5 col-lg-3'>
                    <ct.Checkbox id={`${key}_mustBookItemsTogether`} labelKey='ActivityFormatForm:mustBookItemsTogether' value={mustBookItemsTogether} callback={val => mustBookItemsTogetherChanged(val)} style={{ marginTop: '20px' }} />
                </div>
                <div className='col-xs-6 col-lg-4'>
                    {
                        mustBookItemsTogether.value
                            ? <ct.TextBox id={`${key}_timingTemplateName`} labelKey='ActivityFormatForm:timingTemplate' value={timingTemplateName} callback={timingTemplateNameChanged} />
                            : <ct.MultiSelect id={`${key}_compatibility`} labelKey='ActivityFormatForm:compatibleActivityFormats' hintKey='ActivityFormatForm:compatibleActivityFormatsHint' options={compatibleActivityOptions} callback={toggleCompatibleActivityFormatVariation} />}
                </div>
                <div className='col-xs-1 col-lg-1 text-right'>
                    {canRemove ? <span onClick={e => clickHandler(e, () => removeVariation(key))} className='glyphicon glyphicon-trash red' style={({ cursor: 'pointer', padding: '5px' })}></span> : null}
                </div>
            </div>

            <div className='row'>
                <div className='col-xs-12'>
                    <label>{t('ActivityFormatForm:schedule')}</label>
                    {
                        variation.schedule.sort((s1, s2) => s1.sequence - s2.sequence).map((s, ix) => <div key={ix} className='activity-format-variation-schedule-item'>
                            {ix > 0
                                ? <div key={`${s.id}_breaks`} className='row'>
                                    <div className='col-xs-12'>
                                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'center' }}>
                                            <div style={{ flex: '1 1 50%' }}>
                                                <div style={{ borderWidth: '1px 0', borderColor: '#bbb', borderStyle: 'solid', height: '2px' }}></div>
                                            </div>
                                            <div style={{ flex: '0 0 auto', margin: '0 10px' }}><ct.Time id={`${key}_${s.id}_minBreakTime`} labelKey='ActivityFormatForm:minBreakDuration' value={s.minBreakDuration} callback={val => minBreakDurationChanged(s.id, ix, val)} /></div>
                                            <div style={{ flex: '0 0 auto', margin: '0 10px'  }}><ct.Time id={`${key}_${s.id}_maxBreakTime`} labelKey='ActivityFormatForm:maxBreakDuration' value={s.maxBreakDuration} callback={val => maxBreakDurationChanged(s.id, ix, val)} /></div>
                                            <div style={{ flex: '1 1 50%' }}>
                                                <div style={{ borderWidth: '1px 0', borderColor: '#bbb', borderStyle: 'solid', height: '2px' }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null
                            }

                            <div key={`${s.id}_main_row`} className='row'>
                                <div className='col-xs-12'>
                                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between'}}>
                                        <div style={{ flex: '1 1 auto', paddingTop: '22px' }}>{ix+1}</div>
                                        <div style={{ flex: '1 1 auto' }}><ct.Time id={`${key}_${s.id}_runningTime`} labelKey='ActivityFormatForm:variationRunningTime' value={s.runningTime} callback={val => runningTimeChanged(s.id, val)} /></div>
                                        <div style={{ flex: '1 1 auto' }}><ct.Time id={`${key}_${s.id}_minGapBefore`} labelKey='ActivityFormatForm:minGapBefore' value={s.minGapBefore} callback={val => minGapBeforeChanged(s.id, val)} /></div>
                                        <div style={{ flex: '1 1 auto' }}><ct.Time id={`${key}_${s.id}_minGapAfter`} labelKey='ActivityFormatForm:minGapAfter' value={s.minGapAfter} callback={val => minGapAfterChanged(s.id, val)} /></div>
                                        {mustBookItemsTogether.value
                                            ? null
                                            : <div style={{ flex: '1 1 auto' }}><ct.TextBox id={`${key}_${s.id}_timingTemplateName`} labelKey='ActivityFormatForm:timingTemplate' value={s.timingTemplateName} callback={val => scheduleTimingTemplateNameChanged(s.id, val)} /></div>
                                        }
                                        <div style={{ flex: '1 1 auto', paddingTop: '22px' }}>{ix > 0 ? <span onClick={e => clickHandler(e, () => removeSchedule(s.id))} className='glyphicon glyphicon-trash red' style={({ cursor: 'pointer', padding: '5px' })}></span> : null}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )}

                    <div className='row'>
                        <div className='col-xs-12'>
                            <button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => props.addSchedule(key))}>{t('ActivityFormatForm:addSchedule')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    );
}

ActivityFormatVariationListItem.contextTypes = {
    t: PropTypes.func
}
export default ActivityFormatVariationListItem;