
import { Action } from 'redux';
import { LocationChangeAction } from 'react-router-redux';

import { EmailTemplateActionTypes, EmailTemplatesState } from './types';
import { EmailTemplateActions } from './actions'
import { createReducer } from '../../reducer';
import { parseUtcDate } from '../../../utils/util';


const unloadedState: EmailTemplatesState = { isActive: false, isLoading: false, emailTemplates: [], selectableEmailTypes: [], loadError: null, isSaving: false, saveComplete: false, saveError: null, validationErrors: [] };

export const reducer = (state: EmailTemplatesState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: EmailTemplatesState, incomingAction: Action): EmailTemplatesState => {

    if (incomingAction.type === '@@router/LOCATION_CHANGE') {
        const locationChangeAction = incomingAction as LocationChangeAction;
        if (locationChangeAction) {
            return { ...state, isActive: locationChangeAction.payload.pathname === '/clientEmailTemplates' };
        }
    }

    const action = incomingAction as EmailTemplateActions;
    switch (action.type) {
        case EmailTemplateActionTypes.RequestEmailTemplates:
            return {
                ...state,
                isLoading: true,
                emailTemplates: state.emailTemplates,
                selectableEmailTypes: state.selectableEmailTypes,
                loadError: null
            };
        case EmailTemplateActionTypes.ReceivedEmailTemplates:
            return {
                ...state,
                isLoading: false,
                emailTemplates: action.emailTemplates.map(t => ({ ...t, attachments: t.attachments.map(a => ({ ...a, createDateTime: parseUtcDate(a.createDateTime) })) })),
                selectableEmailTypes: action.selectableEmailTypes,
                loadError: action.error
            };
        case EmailTemplateActionTypes.EditEmailTemplate:
            return {
                ...state,
                isSaving: false,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case EmailTemplateActionTypes.SaveEmailTemplate:
            return {
                ...state,
                isSaving: true,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case EmailTemplateActionTypes.EmailTemplateSaved:
            return {
                ...state,
                isSaving: false,
                saveComplete: true,
                saveError: null,
                validationErrors: []
            };
        case EmailTemplateActionTypes.EmailTemplateSaveFailed:
            return {
                ...state,
                isSaving: false,
                saveError: action.error,
                saveComplete: false,
                validationErrors: action.error && action.error.validationErrors ? action.error.validationErrors : []
            };
        case EmailTemplateActionTypes.EmailAttachmentSaved:
            return state;
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

