
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../../../store';
import { ActivityFormat } from '../../../../store/pages/activityFormats/types';

import * as ActivityFormatActions from '../../../../store/pages/activityFormats/actions';

import ActivityFormatForm from './activityFormatForm';
import Loading from '../../../global/loading';

interface MappedState {
    activityFormats: ActivityFormat[];
    activityFormatsLoading: boolean;
}

interface Actions {
    loadActivityFormats: () => void;
}

interface ActivityFormatPageRouteProps {
    venueId: string;
    activityFormatId: string;
}

type ActivityFormatPageProps = MappedState & Actions & RouteComponentProps<ActivityFormatPageRouteProps>;

class ActivityFormatPage extends React.Component<ActivityFormatPageProps, {}> {
    
    goBackToVenuePage = () => {
        this.props.history.goBack();
    }

    render() {
        const { activityFormatsLoading, activityFormats, match } = this.props;
        const { venueId, activityFormatId } = match.params;

        if (activityFormatsLoading) return <Loading />

        const isNew = activityFormatId === 'new';
        const activityFormat = activityFormats.find(af => af.id === activityFormatId);
        if (!isNew && !activityFormat) return <div>not found</div>

        return <ActivityFormatForm isNew={isNew} venueId={venueId} activityFormat={activityFormat ? activityFormat : null} close={this.goBackToVenuePage} />
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return ({
        activityFormats: state.activityFormats.activityFormats,
        activityFormatsLoading: state.activityFormats.isLoading,
    });
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadActivityFormats: bindActionCreators(ActivityFormatActions.actionCreators.loadActivityFormats, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(ActivityFormatPage);
