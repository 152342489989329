
import * as PropTypes from 'prop-types'
import { clickHandler } from '../../../../utils/util';
import PeriodSelection from './../periodSelection';
import { DateFormat, DayOfWeek } from '../../../../store/pages/venues/types';
import { ITranslationContext } from '../../../../translations';
import ComparisonPeriodSelection from './../comparisonPeriodSelection';
import { AnalyticsPeriod, ComparisonPeriod } from '../../../../store/pages/analytics/types';


interface RevenueReportPageHeaderProps {
    name: string | null;
    firstDayOfWeek: DayOfWeek;
    dateFormat: DateFormat;
    period: AnalyticsPeriod;
    comparisonPeriod: ComparisonPeriod;
    hasUnappliedChanges: boolean;
    updatePeriod: (period: AnalyticsPeriod) => void
    updateComparisonPeriod: (period: ComparisonPeriod) => void
    refreshReport: () => void;
    save: () => void;
}

const RevenueReportPageHeader = (props: RevenueReportPageHeaderProps, context: ITranslationContext) => {

    const { t } = context;
    const { name, period, comparisonPeriod, firstDayOfWeek, dateFormat, updatePeriod, updateComparisonPeriod, refreshReport, hasUnappliedChanges, save } = props;

    return <div className='analytics-report-content-header'>
        <div className='analytics-report-content-header-name'>{name}</div>
        <div className='analytics-report-content-header-period-selection-wrapper'>
            <div className='analytics-report-content-header-period-selections'>
                <PeriodSelection
                    period={period}
                    firstDayOfWeek={firstDayOfWeek}
                    dateFormat={dateFormat}
                    periodChanged={(p) => updatePeriod(p)} />
                <ComparisonPeriodSelection
                    period={period}
                    comparisonPeriod={comparisonPeriod}
                    firstDayOfWeek={firstDayOfWeek}
                    dateFormat={dateFormat}
                    periodChanged={(p) => updateComparisonPeriod(p)} />
            </div>
            <div>
                <button className={`btn btn-${hasUnappliedChanges ? 'primary' : 'default'} btn-sm`} onClick={e => clickHandler(e, refreshReport)} disabled={!hasUnappliedChanges}>{t('Global:apply')}</button>
                <button className='btn btn-info btn-sm' style={{ marginLeft: '6px' }} onClick={e => clickHandler(e, save)}>{t('Global:save')}</button>
            </div>
        </div>
    </div>
}

RevenueReportPageHeader.contextTypes = {
    t: PropTypes.func
}

export default RevenueReportPageHeader