import * as api from '../../apiClient';

export enum TermsAndConditionsType {
    Participant,
    CounterSignatory
}

export interface TermsAndConditions {
    id: string;
    venueId: string;
    text: string;
    type: TermsAndConditionsType;
    confirmationText: string;
    createdDate: Date;
}

export interface RegistrationTerms {
    id: string;
    venueId: string;
    termsId: string;
    name: string;
    isFallbackTerms: boolean;
    participantTerms: string;
    counterSignatoryTermsId: string;
    counterSignatoryTerms: string;
    separateCounterSignatoryTermsRequired: boolean;
    participantConfirmationText: string;
    counterSignatoryConfirmationText: string;
    briefingPageContent: string;
    briefingPageConfirmationText: string;
    registrationVideoId: string | null;
    registrationVideoName: string | null;
    registrationVideoCreated: Date | null;
    registrationVideoSize: number | null;
}

export interface BookingTerms {
    id: string;
    venueId: string;
    name: string;
    isFallbackTerms: boolean;
    bookingTermsId: string;
    terms: string;
    bookingConfirmationText: string;
}

export interface RegistrationVideo {
    id: string;
    fileName: string;
    createDateTime: Date,
    sizeInMb: number | null;
}

export interface WebShopPurchaseTerms {
    id: string;
    venueId: string;
    webShopPurchaseTermsId: string;
    terms: string;
    webShopPurchaseConfirmationText: string
}

export interface TermsAndConditionsState {
    termsAndConditions: TermsAndConditions[];
    isLoading: boolean;
    registrationTerms: RegistrationTerms[];
    isLoadingRegistrationTerms: boolean;
    bookingTerms: BookingTerms[];
    isLoadingBookingTerms: boolean;
    webShopPurchaseTerms: WebShopPurchaseTerms[];
    isLoadingWebShopPurchaseTerms: boolean;
    loadError: api.ApiError | null;
    isSavingRegistrationTerms: boolean;
    registrationTermsSaveError: api.ApiError | null;
    isSavingBookingTerms: boolean;
    bookingTermsSaveError: api.ApiError | null;
    isSavingWebShopPurchaseTerms: boolean;
    webShopPurchaseTermsSaveError: api.ApiError | null;
    registrationVideos: RegistrationVideo[];
    isLoadingRegistrationVideos: boolean;
}

export enum TermsAndConditionsActionTypes {
    LoadTermsAndConditions = 'LOAD_TERMS_AND_CONDITIONS',
    ReceivedTermsAndConditions = 'RECEIVE_TERMS_AND_CONDITIONS',
    LoadRegistrationTerms = 'LOAD_REGISTRATION_TERMS',
    ReceivedRegistrationTerms = 'RECEIVE_REGISTRATION_TERMS',
    LoadBookingTerms = 'LOAD_BOOKING_TERMS',
    ReceivedBookingTerms = 'RECEIVE_BOOKING_TERMS',
    SaveRegistrationTerms = 'SAVE_REGISTRATION_TERMS',
    RegistrationTermsSaved = 'REGISTRATION_TERMS_SAVED',
    RegistrationTermsSaveFailed = 'REGISTRATION_TERMS_SAVE_FAILED',
    SaveBookingTerms = 'SAVE_BOOKING_TERMS',
    BookingTermsSaved = 'BOOKING_TERMS_SAVED',
    BookingTermsSaveFailed = 'BOOKING_TERMS_SAVE_FAILED',
    LoadWebShopPurchaseTerms = 'LOAD_WEB_SHOP_PURCHASE_TERMS',
    ReceivedWebShopPurchaseTerms = 'RECEIVE_WEB_SHOP_PURCHASE_TERMS',
    SaveWebShopPurchaseTerms = 'SAVE_WEB_SHOP_PURCHASE_TERMS',
    WebShopPurchaseTermsSaved = 'WEB_SHOP_PURCHASE_TERMS_SAVED',
    WebShopPurchaseTermsSaveFailed = 'WEB_SHOP_PURCHASE_TERMS_SAVE_FAILED',
    LoadRegistrationVideos = 'LOAD_REGISTRATION_VIDEOS',
    ReceivedRegistrationVideos = 'RECEIVE_REGISTRATION_VIDEOS'
}

export interface LoadTermsAndConditions {
    type: TermsAndConditionsActionTypes.LoadTermsAndConditions;
    termsAndConditionsIds: string[];
}

export interface ReceivedTermsAndConditions {
    type: TermsAndConditionsActionTypes.ReceivedTermsAndConditions;
    termsAndConditions: TermsAndConditions[];
    error: api.ApiError | null;
}

export interface LoadRegistrationTerms {
    type: TermsAndConditionsActionTypes.LoadRegistrationTerms;
}

export interface ReceivedRegistrationTerms {
    type: TermsAndConditionsActionTypes.ReceivedRegistrationTerms;
    registrationTerms: RegistrationTerms[];
    error: api.ApiError | null;
}

export interface LoadBookingTerms {
    type: TermsAndConditionsActionTypes.LoadBookingTerms;
}

export interface ReceivedBookingTerms {
    type: TermsAndConditionsActionTypes.ReceivedBookingTerms;
    bookingTerms: BookingTerms[];
    error: api.ApiError | null;
}

export interface SaveRegistrationTerms {
    type: TermsAndConditionsActionTypes.SaveRegistrationTerms;
    registrationTermsId: string | null;
    venueId: string;
    name: string;
    isFallbackTerms: boolean;
    termsAndConditions: string;
    confirmTermsText: string;
    counterSignatoryTerms: string;
    separateCounterSignatoryTermsRequired: boolean;
    confirmCounterSignatoryTermsText: string;
    briefingPageContent: string;
    briefingPageConfirmationText: string;
    registrationVideoId: string | null;
    removeVideo: boolean;
}

export interface RegistrationTermsSaved {
    type: TermsAndConditionsActionTypes.RegistrationTermsSaved;
    venueId: string;
}

export interface RegistrationTermsSaveFailed {
    type: TermsAndConditionsActionTypes.RegistrationTermsSaveFailed;
    error: api.ApiError;
}

export interface SaveBookingTerms {
    type: TermsAndConditionsActionTypes.SaveBookingTerms;
    bookingTermsId: string | null;
    venueId: string;
    name: string;
    isFallbackTerms: boolean;
    termsAndConditions: string;
    confirmTermsText: string;
}

export interface BookingTermsSaved {
    type: TermsAndConditionsActionTypes.BookingTermsSaved;
    venueId: string;
}

export interface BookingTermsSaveFailed {
    type: TermsAndConditionsActionTypes.BookingTermsSaveFailed;
    error: api.ApiError;
}


export interface LoadWebShopPurchaseTerms {
    type: TermsAndConditionsActionTypes.LoadWebShopPurchaseTerms;
}

export interface ReceivedWebShopPurchaseTerms {
    type: TermsAndConditionsActionTypes.ReceivedWebShopPurchaseTerms;
    webShopPurchaseTerms: WebShopPurchaseTerms[];
    error: api.ApiError | null;
}

export interface SaveWebShopPurchaseTerms {
    type: TermsAndConditionsActionTypes.SaveWebShopPurchaseTerms;
    webShopPurchaseTermsId: string | null;
    venueId: string;
    termsAndConditions: string;
    confirmTermsText: string;
}

export interface WebShopPurchaseTermsSaved {
    type: TermsAndConditionsActionTypes.WebShopPurchaseTermsSaved;
    venueId: string;
}

export interface WebShopPurchaseTermsSaveFailed {
    type: TermsAndConditionsActionTypes.WebShopPurchaseTermsSaveFailed;
    error: api.ApiError;
}

export interface LoadRegistrationVideos {
    type: TermsAndConditionsActionTypes.LoadRegistrationVideos;
    venueId: string;
}

export interface ReceivedRegistrationVideos {
    type: TermsAndConditionsActionTypes.ReceivedRegistrationVideos;
    registrationVideos: RegistrationVideo[];
    error: api.ApiError | null;
}