
import * as PropTypes from 'prop-types'
import { IEvent } from './types';
import { TimeFormat } from '../../../store/pages/venues/types';
import EventReservation from './eventReservation';

interface EventReservationsProps {
    event: IEvent;
    expanded: boolean;
    timeFormat: TimeFormat;
    toggleExpandCollapse?: () => void;
}

const EventReservations = (props: EventReservationsProps) => {
    const { event, expanded, timeFormat, toggleExpandCollapse } = props;
    const outstandingBal = event.bookings.reduce((ttl, b) => ttl + b.outstandingAmount, 0);
    return <>{
        event.reservations
            .filter(r => r.isBookable)
            .sort((r1, r2) => r1.startTime.getTime() - r2.startTime.getTime())
            .map((r, ix) => <EventReservation key={`${event.id}_${r.reservationId}`} ix={ix} reservation={r} outstandingBal={outstandingBal} expanded={expanded} timeFormat={timeFormat} toggleExpandCollapse={toggleExpandCollapse} />)
    }</>
}


EventReservations.contextTypes = {
    t: PropTypes.func
}

export default EventReservations;
