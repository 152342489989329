

import '../../../css/reactdatetime.css';
import '../../../css/rangeSlider.css';

import * as React from 'react';
import * as PropTypes from 'prop-types'

import moment from 'moment';

import { formatTime } from '../../../utils/util';
import { Venue, getOpenHour, getCloseHour, TimeFormat } from '../../../store/pages/venues/types';
import { findBreaksForDay, Resource } from '../../../store/pages/resources/types';
import * as dt from '../../../store/pages/diary/types';
import DiaryResource from './diaryResource';
import DiaryResourceHeader from './daryResourceHeader';
import ScrollViewer, { IScrollPosition } from '../../global/scrollViewer';
import { BehaviorSubject, Subscription } from 'rxjs';

interface DiaryCalendarProps {
    venue: Venue;
    slotSizeInMinutes: number;
    date: moment.Moment;
    showCancelled: boolean;
    resources: Resource[];
    openingTimes: dt.OpeningTimes[];
    reservations: dt.DiaryReservation[];
    scrollPosition: IScrollPosition;
    setScrollPosition: (top: number, left: number) => void;
    loadDiaryReservations: (venue: Venue) => void;
    addReservation: (resource: Resource, startTime: Date, endTime: Date) => void;
    editReservation: (reservationId: string, eventId: string) => void;
    logout: () => void;
}

interface DiaryCalendarState {
    slotHeight: number;
    calBodyWidth?: string;
    calBodyHeight?: number;
    calBodyScrollWidth?: string;
    calBodyScrollLeft?: number;
    timeOfDay: number;
}


class DiaryCalendar extends React.Component<DiaryCalendarProps, DiaryCalendarState> {

    headerScrollSync = new BehaviorSubject<IScrollPosition>({ top: 0, left: 0 });
    currentScrollPosition: IScrollPosition = { top: 0, left: 0 };
    scrollSubscription: Subscription | null = null;
    intervalHandle: NodeJS.Timeout | null;

    constructor(props: DiaryCalendarProps) {
        super(props);

        this.intervalHandle = null;
        this.state = { slotHeight: 24, timeOfDay: this.getTimeOfDay() };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        this.scrollSubscription = this.headerScrollSync.subscribe(p => this.props.setScrollPosition(p.top || 0, p.left || 0));

        this.intervalHandle = setInterval(() => this.setState({ timeOfDay: this.getTimeOfDay() }), 1000 * 60);

        const { left, top } = this.props.scrollPosition;
        if ((left || 0) > 0 || (top || 0) > 0) {
            this.headerScrollSync.next(this.props.scrollPosition);
        }
    }

    componentWillUnmount() {
        if (this.intervalHandle) clearInterval(this.intervalHandle);

        if (this.scrollSubscription) this.scrollSubscription.unsubscribe();
    }


    getTimeOfDay() {
        var now = new Date();
        return now.getHours() + (now.getMinutes() / 60)
    }

    render() {
        const { venue } = this.props;
        const calendarRenderInfo = this.calculateCalendarRenderInfo(venue.id, this.props.date);

        return <div className='diary-calendar-container'>
            {this.renderCalendarHeaders(calendarRenderInfo.venueResources, calendarRenderInfo.resourceWidth)}
            {this.renderCalendar(calendarRenderInfo.venueResources, calendarRenderInfo.resourceWidth, calendarRenderInfo.startTime, calendarRenderInfo.endTime)}
        </div>
    }

    handleCalBodyWidthChange = (newWidth: number) => {
        this.setState(() => ({ calBodyWidth: `${newWidth}px` }));
    }

    handleCalBodyHeightChange = (newHeight: number) => {
        this.setState(() => ({ calBodyHeight: newHeight }));
    }

    // Need to subtract 65 from the width to cater for the width of the left hand times div otherwise the % width calcs for the resources won't match.
    handleCalBodyScrollWidthChange = (newWidth: number) => {
        this.setState(() => ({ calBodyScrollWidth: `${newWidth}px` }));
    }

    handleCalBodyScrollLeftChange = (newOffset: number) => {
        this.setState(() => ({ calBodyScrollLeft: newOffset }));
    }

    calculateCalendarRenderInfo = (venueId: string, date: moment.Moment) => {
        const { resources, openingTimes, venue } = this.props;

        var venueResources = resources.filter(r => r.venueId === venueId && !r.archived);
        venueResources.sort((a, b) => a.sequence - b.sequence);

        const resourceWidth = 100 / venueResources.length;
        const ot = getOpenHour(venue, openingTimes, date.toDate());
        const ct = getCloseHour(venue, openingTimes, date.toDate());

        const startTime = ot === null ? 8 : Math.max(0, ot - 2);
        const endTime = ct === 0 ? 24 : Math.min(24, ct + 2);

        return {
            venueResources,
            resourceWidth,
            startTime,
            endTime
        };
    }

    renderCalendarHeaders = (venueResources: Resource[], resourceWidth: number) => {
        const { openingTimes, date, venue } = this.props;
        const { calBodyWidth, calBodyScrollWidth } = this.state;

        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;

        const times = openingTimes.filter(t => t.date.getFullYear() === date.year() && t.date.getMonth() === date.month() && t.date.getDate() === date.date());
        const ot = times.length > 0 ? times[0] : null;
        const resourceHeaders = this.renderResourceHeaders(venueResources, resourceWidth, ot ? ot : null, timeFormat);

        return (
            <ScrollViewer name='header' className='cal-header-scroll-wrapper' style={({ marginLeft: `${timeFormat === TimeFormat.TwelveHour ? '90px' : '65px'}`, width: calBodyWidth })} scrollObserver={this.headerScrollSync}>
                <div className='cal-header' style={({ width: calBodyScrollWidth })}>
                    {resourceHeaders}
                </div>
            </ScrollViewer>
        );
    }

    renderCalendar = (venueResources: Resource[], resourceWidth: number, startTime: number, endTime: number) => {
        const { date, venue } = this.props;
        interface time { val: string; hour: boolean };
        const { slotSizeInMinutes } = this.props;
        const { slotHeight } = this.state;

        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        const resourceItems = this.renderResources(venueResources, resourceWidth, startTime, endTime);

        const times: time[] = [];
        for (let h = startTime; h < endTime; h++) {
            for (let m = 0; m < 60; m += slotSizeInMinutes) {
                times.push({ val: formatTime(h, m, timeFormat), hour: m === 0 });
            }
        }

        const slots = times.length;
        const lineHeightStyle = { lineHeight: `${slotHeight}px` };

        const diaryDate = date.toDate();
        const now = new Date();
        const currentHr = now.getHours() + (now.getMinutes() / 60);
        const dayHours = endTime - startTime;

        const timePercent = diaryDate.isFutureDay() ? 100 : diaryDate.isToday() ? (currentHr - startTime) / dayHours * 100 : 0;

        const timesStyle = { ...lineHeightStyle, backgroundImage: `linear-gradient(to bottom, #ddd, #ddd ${timePercent}%, transparent ${timePercent}%, transparent 100%)` }

        const eventStyle = { ...lineHeightStyle, minWidth: `${venueResources.length * 180}px`, height: `${slots * slotHeight}px` };

        const timeItems = times.slice(1).map(t => <div key={t.val} className={t.hour ? 'cal-hour cal-time' : 'cal-time'}>{t.val}</div>);

        return (
            <div className='cal-body'>
                <ScrollViewer name='times' className='cal-times-scroll-wrapper' style={{ width: `${timeFormat === TimeFormat.TwelveHour ? '90px' : '65px'}` }} scrollObserver={this.headerScrollSync}>
                    <div className='cal-times' style={timesStyle}>
                        {timeItems}
                    </div>
                </ScrollViewer>
                <ScrollViewer name='resources' className='cal-events-scroll-wrapper' onClientWidthChanged={this.handleCalBodyWidthChange} onClientHeightChanged={this.handleCalBodyHeightChange} onScrollWidthChanged={this.handleCalBodyScrollWidthChange} scrollObserver={this.headerScrollSync} scrollChanged={this.headerScrollSync}>
                    <div className='cal-events' id='events' style={eventStyle}>
                        {resourceItems}
                    </div>
                </ScrollViewer>
            </div >
        );
    }
    renderResourceHeaders = (resources: Resource[], resourceWidth: number, openingTimes: dt.OpeningTimes | null, timeFormat: TimeFormat) => {
        return resources.map(r => {
            const ot = openingTimes ? openingTimes.resourceOverrides.find(ot => ot.resourceId === r.id) : null;
            const times = ot ? { closed: ot.closed, open: ot.open, close: ot.close } : openingTimes ? { closed: openingTimes.closed, open: openingTimes.open, close: openingTimes.close } : null
            return <DiaryResourceHeader key={r.id} colour={r.colour} name={r.name} width={resourceWidth} opeiningTimes={times} timeFormat={timeFormat} />
        });
    }

    renderResources = (resources: Resource[], resourceWidth: number, startTime: number, endTime: number) => {
        const { venue, reservations, slotSizeInMinutes, date, showCancelled } = this.props;
        const { slotHeight,  } = this.state;

        const dateAsDate = date.toDate();
        const startOfDay = new Date(dateAsDate.getFullYear(), dateAsDate.getMonth(), dateAsDate.getDate());
        const endOfDay = new Date(dateAsDate.getFullYear(), dateAsDate.getMonth(), dateAsDate.getDate(), 23, 59, 59);
        return resources.map(r => {

            const filteredReservations = reservations
                .filter(res => {
                    const resourceMatch = res.resourceId === r.id || res.blockedResourceIds.includes(r.id);
                    const show = resourceMatch && ((res.startTime >= startOfDay && res.startTime <= endOfDay) || (res.endTime >= startOfDay && res.endTime <= endOfDay)) && (showCancelled || (!res.deleted && !res.cancelled && !res.archived))
                    return show;
                })
                .sort((r1, r2) => r1.startTime.getTime() - r2.startTime.getTime());

            return <DiaryResource
                key={r.id}
                venue={venue}
                resource={r}
                width={resourceWidth}
                addReservation={this.props.addReservation}
                slotHeight={slotHeight}
                slotSizeInMinutes={slotSizeInMinutes}
                date={dateAsDate}
                startTime={startTime}
                endTime={endTime}
                reservations={filteredReservations}
                breaks={findBreaksForDay(r, dateAsDate)}
                editReservation={this.props.editReservation} />;
        });
    }
}


export default DiaryCalendar;