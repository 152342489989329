
import * as api from '../../apiClient';
import { CustomFieldApplication } from '../activityFormats/types';
import { MarketingPreference } from '../customer/types';
import { CustomerCategoryCount, CustomFieldValue, ReservationType } from '../diary/types';
import { Tag } from '../tags/types';

export enum ReceptionView {
    Events,
    People
}

export interface RegistrationCustomFieldValue extends CustomFieldValue {
    values: string[];
    appliesTo: CustomFieldApplication;
}

export interface CustomerCategoryCustomFieldValue extends CustomFieldValue {
    customerCategoryId: string;
    customerCategoryCode: string;
    customerCategoryName: string;
    values: string[];
}

export interface Registration {
    id: string;
    customerId: string;
    customerFirstname: string;
    customerLastname: string;
    dateOfBirth: Date | null;
    age: number | null;
    minAgeWarning: boolean;
    emailAddress: string;
    phoneNumber: string
    companyName: string;
    registrationTime: Date;
    termsLastAgreed: Date;
    lastTermsAgreedName: string;
    kioskName: string;
    kioskId: string;
    isWebRegistration: boolean;
    publicResultsConsent: boolean;
    resultsPreference: MarketingPreference;
    marketingPreference: MarketingPreference;
    watchedVideo: boolean;
    checkedIn: boolean;
    bookings: RegistrationBooking[];
    categoryCustomFields: CustomerCategoryCustomFieldValue[];
    customerTags: Tag[];
    nickname: string | null;
    registeredForEvents: EventRegistration[];
}

export interface RegistrationBooking {
    bookingId: string;
    customFields: RegistrationCustomFieldValue[];
    amountPaid: number;
}

export interface EventRegistration {
    eventId: string;
    removed: boolean;
    startTime: Date | null;
    eventName: string;
    resourceName: string;
    allowMultipleCategoriesPerCustomer: boolean;
    activityFormatName: string;
    customerCategories: EventRegistrationCategory[];
}

export interface EventRegistrationCategory {
    id: string;
    customerCategoryId: string;
    customerCategoryCode: string;
    customerCategoryName: string;
}

export interface RegistrationEventBookingNote {
    id: string;
    text: string;
    createdBy: string;
    createDateTime: Date;
}

export interface RegistrationEvent {
    id: string;
    venueId: string;
    name: string;
    startTime: Date,
    endTime: Date,
    colour: string;
    totalBilled: number;
    paidAmount: number;
    flagged: boolean;
    allowMultipleCategoriesPerCustomer: boolean;
    reservations: RegistrationEventReservation[];
    bookings: RegistrationEventBooking[];
}

export interface RegistrationEventReservation {
    reservationId: string;
    activityFormatId: string;
    activityFormatVariationId: string;
    activityFormatVariationScheduleId: string;
    resourceId: string;
    reservationType: ReservationType;
    resourceConfigurationId: string | null;
    resourceName: string;
    resourceShortName: string;
    resourceColour: string;
    maxParticipants: number;
    isBookable: boolean;
    bookedParticipants: CustomerCategoryCount[];
    confirmedParticipants: CustomerCategoryCount[];
    registeredParticipants: CustomerCategoryCount[];
    checkedInParticipants: CustomerCategoryCount[];
    startTime: Date;
    endTime: Date;
    flagged: boolean;
    notes: string;
}

export interface RegistrationEventBooking {
    id: string;
    organiserCustomerId: string;
    organiserFirstName: string;
    organiserLastName: string;
    organiserCompanyName: string;
    organiserPhoneNumber: string;
    organiserPostcode: string;
    organiserLastRegistrationId: string;
    billId: string;
    billNumber: string;
    bookedParticipants: CustomerCategoryCount[];
    checkedInParticipants: CustomerCategoryCount[];
    registeredParticipants: CustomerCategoryCount[];
    totalAmount: number;
    paidAmount: number;
    flagged: boolean;
    allowMultipleCategoriesPerCustomer: boolean;
    notes: RegistrationEventBookingNote[];
}

export interface ReceptionState {
    isActive: boolean;
    isLoadingRegistrations: boolean;
    isLoadingEvents: boolean;
    isSaving: boolean;
    selectedDate: Date;
    registrations: Registration[];
    expandedEvents: string[];
    events: RegistrationEvent[];
    loadRegistrationsError: api.ApiError | null;
    loadEventsError: api.ApiError | null;
    saveError: api.ApiError | null;
    selectedView: ReceptionView;
}

export enum ReceptionActionTypes {
    SwitchDate = 'RECEPTION_SWITCH_DATE',
    RequestRegistrations = 'REQUEST_REGISTRATIONS',
    ReceivedRegistrations = 'RECEIVE_REGISTRATIONS',
    RequestEvents = 'REQUEST_RECEPTION_EVENTS',
    ReceivedEvents = 'RECEIVE_RECEPTION_EVENTS',
    AddRegistrationsToBooking = 'ADD_REGISTRATIONS_TO_BOOKING',
    AddRegistrationsToBookingSuccess = 'ADD_REGISTRATIONS_TO_BOOKING_SUCCESS',
    AddRegistrationsToBookingFailed = 'ADD_REGISTRATIONS_TO_BOOKING_FAILED',
    CheckInCustomers = 'CHECK_IN_CUSTOMERS',
    CheckInCustomersSuccess = 'CHECK_IN_CUSTOMERS_SUCCESS',
    CheckInCustomersFailed = 'CHECK_IN_CUSTOMERS_FAILED',
    UndoCheckedInCustomers = 'UNDO_CHECKED_IN_CUSTOMERS',
    UndoCheckedInCustomersSuccess = 'UNDO_CHECKED_IN_CUSTOMERS_SUCCESS',
    UndoCheckedInCustomersFailed = 'UNDO_CHECKED_IN_CUSTOMERS_FAILED',
    Unregister = 'UNREGISTER',
    UnregisterSuccess = 'UNREGISTER_SUCCESS',
    UnregisterFailed = 'UNREGISTER_FAILED',
    RegistrationsChanged = 'REGISTRATIONS_CHANGED',
    CustomerCheckedIn = 'CUSTOMER_CHECKED_IN',
    EventsChanged = 'EVENTS_CHANGES',
    UpdateRegistration = 'UPDATE_REGISTRATION',
    UpdateRegistrationSuccess = 'UPDATE_REGISTRATION_SUCCESS',
    UpdateRegistrationFailed = 'UPDATE_REGISTRATION_FAILED',
    ExpandEvent = 'EXPAND_EVENT',
    CollapseEvent = 'COLLAPSE_EVENT',
    SetReceptionView = "SET_RECEPTION_VIEW"
}

export interface SwitchDate {
    type: ReceptionActionTypes.SwitchDate;
    date: Date;
}

export interface LoadRegistrations {
    type: ReceptionActionTypes.RequestRegistrations;
}

export interface ReceiveRegistrations {
    type: ReceptionActionTypes.ReceivedRegistrations;
    registrations: Registration[];
    error: api.ApiError | null;
}

export interface LoadEvents {
    type: ReceptionActionTypes.RequestEvents;
}

export interface ReceiveEvents {
    type: ReceptionActionTypes.ReceivedEvents;
    events: RegistrationEvent[];
    error: api.ApiError | null;
}

export interface AddRegistrationsToBooking {
    type: ReceptionActionTypes.AddRegistrationsToBooking;
    bookingId: string;
    registrationIds: string[];
}

export interface AddRegistrationsToBookingSuccess {
    type: ReceptionActionTypes.AddRegistrationsToBookingSuccess;
}

export interface AddRegistrationsToBookingFailed {
    type: ReceptionActionTypes.AddRegistrationsToBookingFailed;
    error: api.ApiError;
}

export interface CheckInCustomers {
    type: ReceptionActionTypes.CheckInCustomers;
    venueId: string;
    registrationIds: string[];
}

export interface CheckInCustomersSuccess {
    type: ReceptionActionTypes.CheckInCustomersSuccess;
}

export interface CheckInCustomersFailed {
    type: ReceptionActionTypes.CheckInCustomersFailed;
    error: api.ApiError;
}

export interface UndoCheckedInCustomers {
    type: ReceptionActionTypes.UndoCheckedInCustomers;
    venueId: string;
    registrationIds: string[];
}

export interface UndoCheckedInCustomersSuccess {
    type: ReceptionActionTypes.UndoCheckedInCustomersSuccess;
}

export interface UndoCheckedInCustomersFailed {
    type: ReceptionActionTypes.UndoCheckedInCustomersFailed;
    error: api.ApiError;
}

export interface Unregister {
    type: ReceptionActionTypes.Unregister;
    venueId: string;
    bookingId: string;
    registrationId: string;
}

export interface UnregisterSuccess {
    type: ReceptionActionTypes.UnregisterSuccess;
}

export interface UnregisterFailed {
    type: ReceptionActionTypes.UnregisterFailed;
    error: api.ApiError;
}

export interface RegistrationsChanged {
    type: ReceptionActionTypes.RegistrationsChanged;
    venueId: string;
}

export interface CustomerCheckedIn {
    type: ReceptionActionTypes.CustomerCheckedIn;
    venueId: string;
    bookingDate: Date | null;
}

export interface EventsChanged {
    type: ReceptionActionTypes.EventsChanged;
    venueId: string;
    bookingDate: Date | null;
}

export interface UpdateRegistration {
    type: ReceptionActionTypes.UpdateRegistration;
    venueId: string;
    registrationId: string;
    bookingId: string;
    dateOfBirth: Date;
    nickname: string;
    publicResultsConsent: boolean;
    marketingPreference: MarketingPreference;
    resultsPreference: MarketingPreference;
    eventSelections: RegistrationEventSelection[];
    customFields: RegistrationCustomFieldValue[];
    categoryCustomFields: CustomerCategoryCustomFieldValue[];
}

export interface RegistrationEventSelection {
    eventId: string;
    removed: boolean;
    customerCategories: RegistrationEventSelectionCategory[];
}

export interface RegistrationEventSelectionCategory {
    id: string | null;
    customerCategoryId: string;
}


export interface UpdateRegistrationSuccess {
    type: ReceptionActionTypes.UpdateRegistrationSuccess;
}

export interface UpdateRegistrationFailed {
    type: ReceptionActionTypes.UpdateRegistrationFailed;
    error: api.ApiError;
}

export interface ExpandEvent {
    type: ReceptionActionTypes.ExpandEvent;
    eventId: string;
}
export interface CollapseEvent {
    type: ReceptionActionTypes.CollapseEvent;
    eventId: string;
}

export interface SetReceptionView {
    type: ReceptionActionTypes.SetReceptionView;
    view: ReceptionView;
}
