
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../../../../store';

import Dropzone from 'react-dropzone';
import { quillStandardToolbar } from '../../../global/quillSettings';

import * as api from '../../../../store/apiClient';
import * as TermsActions from '../../../../store/pages/termsAndConditions/actions';
import * as ModalActions from '../../../../store/global/modal/actions';
import * as ct from '../../../global/controls';
import * as v from '../../../global/validation';
import { defaultFormHandler, clickHandler, isNullOrEmpty } from '../../../../utils/util';
import ApiError from '../../../global/apiError';

import { RegistrationTerms, RegistrationVideo } from '../../../../store/pages/termsAndConditions/types';
import { DateFormat, TimeFormat } from '../../../../store/pages/venues/types';

interface EditRegistrationTermsState {
    name: ct.FormValue<string>;
    isFallbackTerms: boolean;
    termsAndConditions: ct.FormValue<string>;
    counterSignatoryTerms: ct.FormValue<string>;
    confirmTermsText: ct.FormValue<string>;
    confirmCounterSignatoryTermsText: ct.FormValue<string>;
    separateCounterSignatoryTermsRequired: ct.FormValue<boolean>;
    briefingPageContent: ct.FormValue<string>;
    briefingPageConfirmationText: ct.FormValue<string>;
    registrationVideoId: string | null;
    registrationVideoName: string | null;
    registrationVideoCreated: Date | null;
    registrationVideoSize: number | null;
    registrationVideoFile: File | null;
    registrationVideoFileError: string | null;
    removeVideo: boolean;
    videoUploadProgress: number | null;
    savingImage: boolean;
    canSelectDefault: boolean;
}

interface LocalProps {
    venueId: string;
    terms: RegistrationTerms | null;
}

interface MappedReduxState {
    isSavingRegistrationTerms: boolean;
    registrationTermsSaveError: api.ApiError | null;
    registrationVideos: RegistrationVideo[];
    isLoadingRegistrationVideos: boolean;
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
}

interface Actions {
    saveRegistrationTerms: (registrationTermsId: string | null, venueId: string, name: string, isFallbackTerms: boolean, termsAndConditions: string, confirmTermsText: string, counterSignatoryTerms: string, separateCounterSignatoryTermsRequired: boolean, confirmCounterSignatoryTermsText: string, briefingPageContent: string, briefingPageConfirmationText: string, registrationVideoId: string | null, removeVideo: boolean) => void;
    loadRegistrationVideos: (venueId: string) => void;
    closeModal: () => void;
}

type EditRegistrationTermsProps = MappedReduxState & Actions & LocalProps;

class EditRegistrationTerms extends React.Component<EditRegistrationTermsProps, EditRegistrationTermsState> {

    constructor(props: EditRegistrationTermsProps) {
        super(props);

        const { terms } = props;

        this.state = {
            name: this.validateName(terms ? terms.name : ''),
            isFallbackTerms: terms && terms.isFallbackTerms ? true : false,
            termsAndConditions: this.validateTerms(terms ? terms.participantTerms : ''),
            counterSignatoryTerms: this.validateCounterSignatoryTerms(terms ? terms.counterSignatoryTerms : ''),
            confirmTermsText: this.validateConfirmTermsText(terms ? terms.participantConfirmationText : ''),
            separateCounterSignatoryTermsRequired: this.validateSeparateCounterSignatoryTermsRequired(terms ? terms.separateCounterSignatoryTermsRequired : true),
            confirmCounterSignatoryTermsText: this.validateConfirmCounterSignatoryTermsText(terms ? terms.counterSignatoryConfirmationText : ''),
            briefingPageContent: this.validateBriefingPageContent(terms ? terms.briefingPageContent : ''),
            briefingPageConfirmationText: this.validateBriefingPageConfirmationText(terms ? terms.briefingPageConfirmationText : ''),
            registrationVideoId: terms ? terms.registrationVideoId : null,
            registrationVideoName: terms ? terms.registrationVideoName : '',
            registrationVideoCreated: terms ? terms.registrationVideoCreated : null,
            registrationVideoSize: terms ? terms.registrationVideoSize : null,
            registrationVideoFile: null,
            registrationVideoFileError: null,
            removeVideo: false,
            videoUploadProgress: null,
            savingImage: false,
            canSelectDefault: !terms || !terms.isFallbackTerms ? true : false,
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    validateName = (val: string) => v.validate(val, 'name', [v.required], []);
    validateConfirmTermsText = (val: string) => v.validate(val, 'confirmTermsText', [v.required], []);
    validateSeparateCounterSignatoryTermsRequired = (val: boolean) => v.validate(val, 'separateCounterSignatoryTermsRequired', [], []);
    validateConfirmCounterSignatoryTermsText = (val: string) => v.validate(val, 'confirmCounterSignatoryTermsText', [v.required], []);
    validateTerms = (val: string) => v.validate(val, 'terms', [], []);
    validateCounterSignatoryTerms = (val: string) => v.validate(val, 'counterSignatoryTerms', [], []);
    validateBriefingPageContent = (val: string) => v.validate(val, 'briefingPageContent', [], []);

    termsAndConditionsChanged = (val: string) => this.setState({ termsAndConditions: this.validateTerms(val) });
    confirmCounterSignatoryTermsChanged = (val: string) => this.setState({ counterSignatoryTerms: this.validateCounterSignatoryTerms(val) });
    briefingPageContentChanged = (val: string) => this.setState({ briefingPageContent: this.validateBriefingPageContent(val) });

    validateBriefingPageConfirmationText = (val: string) => v.validate(val, 'briefingPageConfirmationText', [], []);

    onRegistrationVideoDrop = (files: File[]) => {
        const { t } = this.context;
        const file = files[0];
        const maxFileSize = 89128960;
        const maxFileSizeInMb = maxFileSize / 1024 / 1024;
        if (file.size > maxFileSize) {
            this.setState({ registrationVideoFile: null, registrationVideoFileError: t('OnlineRegistrationSettingsForm:videoTooLarge', { max: maxFileSizeInMb.toFixed(2), size: (file.size / 1024 / 1024).toFixed(2) }) });
        } else {
            this.setState({ registrationVideoFile: file, registrationVideoFileError: null });
        }
    }

    componentDidMount() {
        const { isLoadingRegistrationVideos, venueId, loadRegistrationVideos} = this.props;
        if (!isLoadingRegistrationVideos) {
            loadRegistrationVideos(venueId);
        }
    }

    componentDidUpdate(prevProps: EditRegistrationTermsProps) {
        const { isSavingRegistrationTerms: prevIsSavingRegistrationTerms } = prevProps;
        const { isSavingRegistrationTerms, registrationTermsSaveError, closeModal } = this.props;

        // Close modal overlay if diary note save is complete
        if (prevIsSavingRegistrationTerms && !isSavingRegistrationTerms && !registrationTermsSaveError) {
            closeModal();
        }
    }

    selectedVideoChanged = (videoId: string) => {
        if (isNullOrEmpty(videoId)) {
            this.setState(s => ({
                registrationVideoId: null,
                registrationVideoName: null,
                registrationVideoCreated: null,
                registrationVideoSize: null,
                removeVideo: true
            }))
        } else {
            const vid = this.props.registrationVideos.find(v => v.id === videoId);
            if (vid) {
                this.setState(s => ({
                    registrationVideoId: vid.id,
                    registrationVideoName: vid.fileName,
                    registrationVideoCreated: vid.createDateTime,
                    registrationVideoSize: vid.sizeInMb,
                    removeVideo: false
                }))
            }
        }
    }

    save = () => {
        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {
            const { t } = this.context;
            const { venueId, terms, saveRegistrationTerms } = this.props;
            const { name, isFallbackTerms, termsAndConditions, confirmTermsText, counterSignatoryTerms, separateCounterSignatoryTermsRequired, confirmCounterSignatoryTermsText,
                briefingPageContent, briefingPageConfirmationText, registrationVideoId, registrationVideoFile, removeVideo } = this.state;

            const registrationTermsId = terms ? terms.id : null;

            const saveSettings = (registrationVideoId: string | null) => {
                saveRegistrationTerms(
                    registrationTermsId,
                    venueId,
                    name.value,
                    isFallbackTerms,
                    termsAndConditions.value,
                    confirmTermsText.value,
                    counterSignatoryTerms.value,
                    separateCounterSignatoryTermsRequired.value,
                    confirmCounterSignatoryTermsText.value,
                    briefingPageContent.value,
                    briefingPageConfirmationText.value,
                    registrationVideoId,
                    removeVideo
                );
            }

            if (registrationVideoFile) {
                this.setState({ savingImage: true });

                api.uploadFile(registrationVideoFile, `api/v1/registration/${venueId}/video`, progress => {
                    this.setState({ videoUploadProgress: progress })
                })
                    .subscribe(result => {
                        this.setState({ videoUploadProgress: null, savingImage: false })
                        var videoId = result.response.imageId;

                        saveSettings(videoId);
                    }, e => this.setState({ registrationVideoFileError: t('Global:uploadFailed'), savingImage: false }));
            } else {
                saveSettings(registrationVideoId);
            }
        }
    }

    render() {
        const { closeModal, isSavingRegistrationTerms, registrationTermsSaveError, registrationVideos, isLoadingRegistrationVideos, timeFormat, dateFormat } = this.props;
        const { name, isFallbackTerms, termsAndConditions, confirmTermsText, counterSignatoryTerms, separateCounterSignatoryTermsRequired,
            confirmCounterSignatoryTermsText, briefingPageContent, briefingPageConfirmationText, registrationVideoId, registrationVideoName, registrationVideoCreated,
            registrationVideoSize, registrationVideoFile, registrationVideoFileError, videoUploadProgress, savingImage, canSelectDefault } = this.state;
        const { t } = this.context;

        const message = (registrationTermsSaveError) ? <ApiError error={registrationTermsSaveError} /> : null;

        let videoInfo: JSX.Element | null = null;

        if (registrationVideoFile) {
            videoInfo = (<div>
                <label>{t('OnlineRegistrationSettingsForm:registrationVideo')}</label>
                <label>{registrationVideoFile.name} (new)</label>
            </div>)
        } else {
            const formatVideoName = (name: string, created: Date, size: number | null) => `${name} - ${created.toAbbrDateTimeString(timeFormat, dateFormat, t)} ${size ? `- ${size.toFixed(2)} MB` : ''}`;

            if (!isLoadingRegistrationVideos && registrationVideos.length > 0) {
                const videoOptions = [{ key: '', name: t('OnlineRegistrationSettingsForm:noVideo')}].concat(registrationVideos.map(v => ({ key: v.id, name: formatVideoName(v.fileName, v.createDateTime, v.sizeInMb) })))
                videoInfo = <ct.Select id='registrationVideo' labelKey='' value={ct.asFormValue('registrationVideo', registrationVideoId ? registrationVideoId : '')} callback={this.selectedVideoChanged} options={videoOptions} />
            } else if (registrationVideoName && registrationVideoCreated) {
                videoInfo = <div>
                    <label>{t('OnlineRegistrationSettingsForm:registrationVideo')}</label>
                    <label>{formatVideoName(registrationVideoName, registrationVideoCreated, registrationVideoSize)}</label>
                </div>
            }
        }

        return (
            <form className='data-form' onSubmit={defaultFormHandler} autoComplete='off'>
                <div>

                    <ct.TextBox id='name' labelKey='Global:name' placeholderKey='Global:name' value={name} callback={val => this.setState({ name: this.validateName(val) })} />

                    <ct.Checkbox id='isFallbackTerms' labelKey='VenueForm:defaultWaiver' value={ct.asFormValue('isFallbackTerms', isFallbackTerms)} disabled={!canSelectDefault} callback={val => this.setState({ isFallbackTerms: val })} />

                    <h4>{t('VenueForm:waiver')}</h4>
                    <ct.HtmlInput id='termsTemplate' labelKey='' value={termsAndConditions} callback={this.termsAndConditionsChanged} modules={quillStandardToolbar} quillClassName='quill-dark' inlineStyles={true} />
                    <ct.TextBox id='confirmTermsText' labelKey='VenueForm:confirmWaiverText' placeholderKey='VenueForm:confirmWaiverText' helpTextKey='VenueForm:confirmTermsTextHelp' value={confirmTermsText} callback={val => this.setState({ confirmTermsText: this.validateConfirmTermsText(val) })} />

                    <div className='alert alert-info'>
                        <span>{this.context.t('VenueForm:confirmTermsTextTagsText')}</span>
                        <ul className='plain-list'>
                            <li key='customer_name'>{'{{customer_name}}'} <ct.Help text={t('VenueFormTag:customer_name')} /></li>
                        </ul>
                    </div>

                    <h4>{t('VenueForm:countersignatoryTerms')}</h4>
                    <ct.Checkbox id='separateCounterSignatoryTermsRequired' labelKey='VenueForm:separateCounterSignatoryTermsRequired' value={separateCounterSignatoryTermsRequired} callback={val => this.setState({ separateCounterSignatoryTermsRequired: this.validateSeparateCounterSignatoryTermsRequired(val) })} />
                    {separateCounterSignatoryTermsRequired.value ? <ct.HtmlInput id='termsTemplate' labelKey='' value={counterSignatoryTerms} callback={this.confirmCounterSignatoryTermsChanged} modules={quillStandardToolbar} quillClassName='quill-dark' inlineStyles={true} /> : null}
                    <ct.TextBox id='confirmCounterSignatoryTermsText' labelKey='VenueForm:confirmCounterSignatoryWaiverText' placeholderKey='VenueForm:confirmCounterSignatoryWaiverText' helpTextKey='VenueForm:confirmCounterSignatoryTermsTextHelp' value={confirmCounterSignatoryTermsText} callback={val => this.setState({ confirmCounterSignatoryTermsText: this.validateConfirmCounterSignatoryTermsText(val) })} />

                    <div className='alert alert-info'>
                        <span>{this.context.t('VenueForm:confirmCounterSignatoryTermsTextTagsText')}</span>
                        <ul className='plain-list'>
                            <li key='customer_name'>{'{{customer_name}}'} <ct.Help text={t('VenueFormTag:customer_name')} /></li>
                            <li key='dependents_name'>{'{{dependents_name}}'} <ct.Help text={t('VenueFormTag:dependents_name')} /></li>
                        </ul>
                    </div>


                    <h4>{t('OnlineRegistrationSettingsForm:briefingPageContent')}</h4>
                    <ct.HtmlInput id='termsTemplate' labelKey='' value={briefingPageContent} callback={this.briefingPageContentChanged} modules={quillStandardToolbar} quillClassName='quill-dark' inlineStyles={true} />

                    <ct.TextBox id='briefingPageConfirmationText' labelKey='OnlineRegistrationSettingsForm:briefingPageConfirmationText' placeholderKey='OnlineRegistrationSettingsForm:briefingPageConfirmationText' value={briefingPageConfirmationText} callback={val => this.setState({ briefingPageConfirmationText: this.validateBriefingPageConfirmationText(val) })} />

                    <div>
                        <h4>{t('OnlineRegistrationSettingsForm:registrationVideo')}</h4>
                        {videoInfo}
                        <Dropzone multiple={false} accept={{ 'video/*': ['.mp4', '.mpeg'] }} onDrop={this.onRegistrationVideoDrop}>
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()} className='file-drop'>
                                    <input {...getInputProps()} />
                                    <p>{t('Global:imageDropText')}</p>
                                </div>
                            )}
                        </Dropzone>
                        {registrationVideoFileError ? <div className='alert alert-danger'>{registrationVideoFileError}</div> : null}
                        {videoUploadProgress ? videoUploadProgress < 100 ? <div>{t('OnlineRegistrationSettingsForm:uploading', { percent: videoUploadProgress })}</div> : <div>{t('OnlineRegistrationSettingsForm:processingUpload')}</div> : null}
                    </div>

                    {message}

                    <div className='btn-toolbar' style={({ marginTop: '20px' })}>
                        <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)} disabled={isSavingRegistrationTerms || savingImage}>{t('Global:save')}</button>
                        <button className='btn btn-basic' onClick={e => clickHandler(e, closeModal)} disabled={isSavingRegistrationTerms || savingImage}>{t('Global:cancel')}</button>
                    </div>
                </div>
            </form>
        );
    }
}


const matStateToProps = (state: ApplicationState) => {
    return {
        isSavingRegistrationTerms: state.termsAndConditions.isSavingRegistrationTerms,
        registrationTermsSaveError: state.termsAndConditions.registrationTermsSaveError,
        registrationVideos: state.termsAndConditions.registrationVideos,
        isLoadingRegistrationVideos: state.termsAndConditions.isLoadingRegistrationVideos,
        timeFormat: state.venues.timeFormat,
        dateFormat: state.venues.dateFormat
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    saveRegistrationTerms: bindActionCreators(TermsActions.actionCreators.saveRegistrationTerms, dispatch),
    loadRegistrationVideos: bindActionCreators(TermsActions.actionCreators.loadRegistrationVideos, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    matStateToProps,
    mapDispatchToProps
)(EditRegistrationTerms);
