
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { TimeFormat } from '../../../store/pages/venues/types';
import { Registration, RegistrationEventBookingNote } from '../../../store/pages/reception/types';
import { IEvent } from './types';
import { clickHandler } from '../../../utils/util';
import { SelectedRegistration } from '../diary/types';
import RegistrationEventHeader from './registrationEventHeader';
import EventReservations from './eventReservations';
import { stringComparer } from '../../../utils/comparers';
import ReservationBookingHeader from './reservationBookingHeader';
import { ITranslationContext } from '../../../translations';

interface ExistingBookingSelectionProps {
    timeFormat: TimeFormat;
    registrations: Registration[];
    events: IEvent[];
    customersToRegister: SelectedRegistration[];
    bookingSelected: (bookingId: string) => void;
    showNotes: (notes: RegistrationEventBookingNote[], name: string, billNumber: string) => void;
    close: () => void;
}

const ExistingBookingSelection = (props: ExistingBookingSelectionProps, context: ITranslationContext) => {
    const { timeFormat, events, showNotes, bookingSelected, close } = props;
    const { t } = context;

    return <div className='flex flex-col flex-stretch' style={{ overflow: 'hidden'}}>
        <h3 className='text-center flex-shrink'>{t('ReceptionPage:selectBooking')}</h3>

        <ul className='list-unstyled flex-stretch' style={{ overflow: 'auto', backgroundColor: '#eee', padding: '6px' }}>
            {events.map(event => <li>
                <div className='reception-event' style={{ borderLeft: `solid 5px ${event.colour}`, borderBottom: `solid 1px ${event.colour}`, borderRight: `solid 5px ${event.colour}` }}>
                    <div className='reception-event-heading'>
                        {event.reservations.length > 1 ? <RegistrationEventHeader event={event} timeFormat={timeFormat} expanded={true} /> : null}
                        <EventReservations event={event} expanded={true} timeFormat={timeFormat} />
                    </div>
                    {event.bookings
                        .sort((b1, b2) => stringComparer(`${b1.organiserFirstName} ${b1.organiserLastName}`, `${b2.organiserFirstName} ${b2.organiserLastName}`))
                        .map(b => <ReservationBookingHeader
                            booking={b}
                            enableSelectBooking={false}
                            venueId={event.venueId}
                            eventId={event.id}
                            showNotes={showNotes}
                            actions={[<button className='btn btn-primary btn-sm' onClick={e => clickHandler(e, () => bookingSelected(b.id))} style={{ padding: '1px 10px' }}>{t('Global:select')}</button>]} />
                        )}
                </div>
            </li>)}
        </ul>

        <div className='flex flex-shrink flex-row' style={{ justifyContent: 'space-evenly', paddingBottom: '10px' }}>
            <button className='btn btn-basic' onClick={e => clickHandler(e, close)}>{t('Global:cancel')}</button>
        </div>

    </div>
}


ExistingBookingSelection.contextTypes = {
    t: PropTypes.func
}

export default ExistingBookingSelection;