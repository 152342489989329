
import * as api from '../../apiClient';

export interface TimeZone {
    id: number;
    name: string;
}

export interface TimeZoneState {
    isLoading: boolean;
    timeZones: TimeZone[];
    loadError: api.ApiError | null;
}

export enum TimeZoneActionTypes {
    RequestTimeZones = 'REQUEST_TIME_ZONES',
    ReceivedTimeZones = 'RECEIVE_TIME_ZONES',
    RequestTimeZonesError = 'REQUEST_TIME_ZONES_ERROR'
}


export interface RequestTimeZones {
    type: TimeZoneActionTypes.RequestTimeZones;
}

export interface ReceiveTimeZones {
    type: TimeZoneActionTypes.ReceivedTimeZones;
    timeZones: TimeZone[];
}

export interface RequestTimeZonesError {
    type: TimeZoneActionTypes.RequestTimeZonesError;
    error: api.ApiError;
}
