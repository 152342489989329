import { ValidationError } from '../../global/types';
import * as api from '../../apiClient';

export interface Integration {
    id: string;
    integrationType: IntegrationType;
    implementationType: IntegrationImplementationType;
    name: string;
    settings: string;
    archived: boolean;
}

export enum IntegrationType {
    Timing = 1,
    TransactionalEmail = 2,
    ViewPointVideo = 3,
    MarketingEmail = 4
}

export enum IntegrationImplementationType {
    None = 0,
    EmailSendGrid = 1,
}

export interface IntegrationsState {
    isActive: boolean;
    isLoading: boolean;
    integrations: Integration[];
    loadError: api.ApiError | null;
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
}

export enum IntegrationActionTypes {
    RequestIntegrations = 'REQUEST_INTEGRATIONS',
    ReceivedIntegrations = 'RECEIVE_INTEGRATIONS',
    SaveIntegration = 'SAVE_INTEGRATION',
    IntegrationSaved = 'INTEGRATION_SAVED',
    IntegrationSaveFailed = 'INTEGRATION_SAVE_FAILED',
    EditIntegration = 'EDIT_INTEGRATION'
}


export interface LoadIntegrations {
    type: IntegrationActionTypes.RequestIntegrations;
}


export interface ReceiveIntegrations {
    type: IntegrationActionTypes.ReceivedIntegrations;
    integrations: Integration[];
    error: api.ApiError | null;
}

export interface SaveIntegration {
    type: IntegrationActionTypes.SaveIntegration;
    isNew: boolean;
    integrationId: string | null;
    integrationType: IntegrationType;
    implementationType: IntegrationImplementationType;
    name: string;
    settings: string;
    archived: boolean;
}

export interface IntegrationSaved {
    type: IntegrationActionTypes.IntegrationSaved;
    integrationId: string;
}

export interface IntegrationSaveFailed {
    type: IntegrationActionTypes.IntegrationSaveFailed;
    error: api.ApiError;
}

export interface EditIntegration {
    type: IntegrationActionTypes.EditIntegration
}