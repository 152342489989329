
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { Link } from "react-router-dom";
import { Registration } from "../../../store/pages/reception/types";
import { DateFormat, TimeFormat } from "../../../store/pages/venues/types";
import { ITranslationContext } from "../../../translations";
import { clickHandler, isNullOrEmpty } from "../../../utils/util";
import { IBooking } from "./types";
import TagList from "../../global/tagList";
import Incognito from "../../icons/incognito";


interface BookingRegistrationProps {
    booking: IBooking;
    reg: Registration;
    eventId: string
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
    payIndividually: (bookingId: string, billId: string, customerId: string) => void;
    editRegistration: (bookingId: string, registrationId: string) => void;
    toggleCheckedIn: (bookingId: string, reg: Registration) => void;
}

const BookingRegistration = (props: BookingRegistrationProps, context: ITranslationContext) => {
    const { t } = context;
    const { booking, reg, eventId, timeFormat, dateFormat, payIndividually, editRegistration, toggleCheckedIn } = props;

    const ageClass = reg.minAgeWarning ? 'reception-booking-registration-age warning' : 'reception-booking-registration-age';
    const registeredCategories = reg.registeredForEvents.filter(re => re.eventId === eventId).flatMap(e => e.customerCategories);
    const regBooking = reg.bookings.filter(b => b.bookingId === booking.id);
    const fieldsWithValues = regBooking.flatMap(b => b.customFields).filter(f => !isNullOrEmpty(f.value)).map(f => ({ name: f.customFieldName, value: f.value }))
        .concat(reg.categoryCustomFields
            .filter(cf => !isNullOrEmpty(cf.value) && registeredCategories.findIndex(x => x.customerCategoryId === cf.customerCategoryId) >= 0)
            .map(cf => ({ name: `${cf.customFieldName} (${cf.customerCategoryName})`, value: cf.value })));

    const amountPaid = regBooking.length > 0 ? regBooking[0].amountPaid : 0;
    const eventRegistrations = reg.registeredForEvents.filter(re => re.eventId === eventId);
    const activityFormatName = eventRegistrations.length > 0 ? eventRegistrations[0].activityFormatName : '';

    return <div key={`${booking.id}_${reg.id}`} className='reception-booking-registration'>
        <div className='reception-booking-registration-main-row'>
            <div className='reception-booking-registration-actions'>
                <button className='btn btn-default' onClick={e => clickHandler(e, () => editRegistration(booking.id, reg.id))} style={{ padding: '1px 4px' }}><i className='glyphicon glyphicon-pencil' style={({ color: '#337ab7' })}></i></button>
            </div>
            <div className='reception-booking-registration-name'><Link to={{ pathname: `/customer/${reg.customerId}` }}>{reg.customerFirstname} {reg.customerLastname} {isNullOrEmpty(reg.nickname) ? null : `(${reg.nickname})`}</Link></div>
            <div className={ageClass}>{reg.age}</div>
            <div className='reception-booking-registration-cls'>{registeredCategories.reduce<string[]>((cats, e) => cats.includes(e.customerCategoryName) ? cats : cats.concat(e.customerCategoryName), []).join(" | ")}</div>
            <div className='reception-booking-registration-activity'>{activityFormatName}</div>
            <div className='reception-booking-registration-tags'><TagList tags={reg.customerTags} /></div>
            <div className='reception-booking-registration-time'>
                <div>{reg.registrationTime.toAbbrMonthDayString(dateFormat, t)} {reg.registrationTime.toShortTimeString(timeFormat)}</div>
                <div>{reg.lastTermsAgreedName}</div>
            </div>
            <div className='reception-booking-registration-info-web'>{reg.isWebRegistration ? <span className="label label-info">{t('Global:webBooking')}</span> : null}</div>
            <div className='reception-booking-registration-info-checked-in' onDoubleClick={e => clickHandler(e, () => toggleCheckedIn(booking.id, reg))}><span className={`glyphicon glyphicon-${reg.checkedIn ? 'check' : 'unchecked'}`} style={({ padding: '5px', fontSize: '20px', color: '#5cb85c' })}></span></div>
            <div className='reception-booking-registration-info-video'>{reg.watchedVideo ? <i className='glyphicon glyphicon-facetime-video' style={({ color: '#5cb85c' })}></i> : null}</div>
            <div className='reception-booking-registration-info-hide-results'>{!reg.publicResultsConsent ? <div><Incognito width={24} height={24} colour='orange' /></div> : null}</div>
            <div className='reception-booking-registration-payment'>{amountPaid > 0 ? `${t('Global:currencySymbol')}${amountPaid.toFixed(2)}` : null}</div>
            <div className='reception-booking-registration-pay'><button className='btn btn-primary btn-sm' onClick={e => clickHandler(e, () => payIndividually(booking.id, booking.billId, reg.customerId))} style={{ padding: '1px 10px' }}>{t('Global:pay')}</button></div>
        </div>
        {fieldsWithValues.length > 0
            ? <div className='reception-booking-registration-custom-fields'>{fieldsWithValues.map(f => <div key={f.name}><label>{f.name}</label>: {f.value}</div>)}</div>
            : null}
    </div>
}

BookingRegistration.contextTypes = {
    t: PropTypes.func
}

export default BookingRegistration;