
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { ITranslationContext } from '../../../translations';
import BookingExtraField from './bookingExtraField';
import { BookingCustomFieldValue } from './types';

interface BookingExtraFieldsProps {
    customFields: BookingCustomFieldValue[];
    fieldValueChanged: (fieldName: string, newValue: string) => void;
}

const BookingExtraFields = (props: BookingExtraFieldsProps, context: ITranslationContext) => {
    const { t } = context;
    const { customFields, fieldValueChanged } = props;
    if (customFields.length < 1) return null;

    return <div className='panel'>
        <label>{t('BookingExtraFields:customFields')}</label>
        <table className='table table-condensed table-borderless'>
            <tbody>
                {customFields.map(f =>
                    <tr key={f.customFieldName}>
                        <td className='shrink'><label>{f.customFieldName}</label></td>
                        <td className='expand'><BookingExtraField name={f.customFieldName} type={f.type} value={f.value} values={f.values} valueChanged={fieldValueChanged} /></td>
                    </tr>)}
            </tbody>
        </table>
    </div>

}

BookingExtraFields.contextTypes = {
    t: PropTypes.func
}

export default BookingExtraFields;
