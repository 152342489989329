import moment from "moment";
import { ProdPrice } from "./types";

import * as v from '../../../global/validation';

import { Time, ValidationError } from "../../../../store/global/types";
import { ProductPricePoint, ProductPricingMode } from "../../../../store/pages/products/types";
import { monitorEventLoopDelay } from "node:perf_hooks";


const epoc = moment("1970-01-01")

export const validatePricingUnitPrice = (val: number | null, validationErrors: ValidationError[]) => v.validate(val, 'pricingUnitPrice', [v.numeric, v.required], validationErrors);
export const validatePricingEffectiveFrom = (val: moment.Moment, validationErrors: ValidationError[]) => v.validate(val, 'pricingEffectiveFrom', [v.required], validationErrors);
export const validatePricingEffectiveTo = (val: moment.Moment | null, validationErrors: ValidationError[]) => v.validate(val, 'pricingEffectiveTo', [], validationErrors);
export const validatePricingEffectiveDatesPricePoint = (val: ProductPricePoint, validationErrors: ValidationError[]) => v.validate(val, 'pricingEffectiveDatesPricePoint', [], validationErrors);
export const validatePricingTagId = (val: string | null, validationErrors: ValidationError[]) => v.validate(val, 'pricingTagId', [], validationErrors);
export const validatePricingAppliesOnPricePoint = (val: ProductPricePoint, validationErrors: ValidationError[]) => v.validate(val, 'pricingAppliesOnPricePoint', [], validationErrors);
export const validatePricingApplyPriceFrom = (val: Time | null, validationErrors: ValidationError[]) => v.validate(val, 'pricingApplyPriceFrom', [], validationErrors);
export const validatePricingApplyPriceTo = (val: Time | null, validationErrors: ValidationError[]) => v.validate(val, 'pricingApplyPriceTo', [], validationErrors);
export const validatePricingArchived = (val: boolean, validationErrors: ValidationError[]) => v.validate(val, 'pricingArchived', [], validationErrors);


const dateRangeOverlaps = (aStart: moment.Moment, aEnd: moment.Moment | null, bStart: moment.Moment, bEnd: moment.Moment | null) => {
    // https://stackoverflow.com/questions/22784883/check-if-more-than-two-date-ranges-overlap
    const aEpocStart = aStart.diff(epoc, 'days');
    const aEpocEnd = aEnd ? aEnd.diff(epoc, 'days') : Number.MAX_SAFE_INTEGER;
    const bEpocStart = bStart.diff(epoc, 'days');
    const bEpocEnd = bEnd ? bEnd.diff(epoc, 'days') : Number.MAX_SAFE_INTEGER;

    if (aEpocStart <= bEpocStart && bEpocStart <= aEpocEnd) return true; // b starts in a
    if (aEpocStart <= bEpocEnd && bEpocEnd <= aEpocEnd) return true; // b ends in a
    if (bEpocStart < aEpocStart && aEpocEnd < bEpocEnd) return true; // a in b
    return false;
}

export const validatePricing = (standardPrices: ProdPrice[]) => {
    if (hasPriceOverlap(standardPrices)) {
        return 'ProductForm:standardPricesCannotOverlap';
    }

    return checkForPriceGaps(standardPrices);
}

export const validateFixedPricing = (mode: ProductPricingMode, pricing: ProdPrice[]) => {
    if (mode != ProductPricingMode.Fixed) {
        return null;
    }

    for (var i = 0; i < pricing.length; i++) {
        if (pricing[i].fixedPricing.filter(fp => fp.maxQuantity === 0).length > 0) { 
            return 'ProductForm:fixedPriceMaxCannotBeZero';
        }
        if (pricing[i].fixedPricing.filter(fp => fp.maxQuantity < fp.minQuantity).length > 0) { 
            return 'ProductForm:fixedPriceMaxCanotBeLessThanMin';
        }
    }

    return null;
}

export const hasPriceOverlap = (standardPrices: ProdPrice[]) => {
    for (let pi = standardPrices.length - 1; pi >= 0; pi--) {
        const priceToCheck = standardPrices[pi];
        for (let opi = pi - 1; opi >= 0; opi--) {
            const otherPrrice = standardPrices[opi];
            if (dateRangeOverlaps(priceToCheck.effectiveFrom.value, priceToCheck.effectiveTo.value, otherPrrice.effectiveFrom.value, otherPrrice.effectiveTo.value)) {
                return true;
            }
        }
    }

    return false;
}

const checkForPriceGaps = (standardPrices: ProdPrice[]) => {
    const orderedPrices = standardPrices.sort((p1, p2) => p1.effectiveFrom.value.valueOf() - p2.effectiveFrom.value.valueOf());
    const now = moment();

    if (orderedPrices.length > 0 && orderedPrices[0].effectiveFrom.value > now) {
        return 'ProductForm:standardPricesMustStartOnOrBeforeToday'
    }

    for (let pi = 0; pi < orderedPrices.length; pi++) {
        const priceToCheck = orderedPrices[pi];

        if (priceToCheck.effectiveTo.value) {
            const priceEpocEnd = priceToCheck.effectiveTo.value ? priceToCheck.effectiveTo.value.diff(epoc, 'days') : Number.MAX_SAFE_INTEGER;

            if (standardPrices.length > pi + 1) {
                const other = standardPrices[pi + 1];
                const otherEpocStart = other.effectiveFrom.value.diff(epoc, 'days');

                if (otherEpocStart > priceEpocEnd + 1) {
                    return 'ProductForm:standardPricesCannotHaveGaps'
                }
            } else {
                return 'ProductForm:lastPriceMustHaveABlankEffectiveToDate'
            }
        }
    }

    return null;
}