
import * as React from 'react';
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import moment from 'moment';

import * as ct from '../../../global/controls';

import { ApplicationState } from '../../../../store';
import { clickHandler, isNullOrEmpty } from '../../../../utils/util';
import { DateFormat} from '../../../../store/pages/venues/types';
import { ProdPrice, ProductFixedPricing } from './types';

interface ComponentProps {
    isFixed: boolean;
    canDelete: boolean;
    productPrice: ProdPrice;
    effectiveFromChanged: (key: string, newDate: moment.Moment) => void;
    effectiveToChanged: (key: string, newDate: moment.Moment | null) => void;
    unitPriceChanged: (key: string, unitPrice: number) => void;
    removePrice: (key: string) => void;
    fixedPricingChanged: (key: string, fixedPricing: ProductFixedPricing[]) => void;
}

interface MappedReduxState {
    dateFormat: DateFormat;
}

interface Actions { }

type StandardProductPriceProps = MappedReduxState & Actions & ComponentProps;

interface StandardProductPriceState {
}

class StandardProductPrice extends React.Component<StandardProductPriceProps, StandardProductPriceState> {

    _popup: React.RefObject<HTMLDivElement>;

    constructor(props: StandardProductPriceProps) {
        super(props);

        this._popup = React.createRef();
    }

    static contextTypes = {
        t: PropTypes.func
    }

    setEffectiveFrom = (val: moment.Moment) => this.props.effectiveFromChanged(this.props.productPrice.key, val);
    setEffectiveTo = (val: moment.Moment | null) => this.props.effectiveToChanged(this.props.productPrice.key, val);
    setUnitPrice = (val: number) => this.props.unitPriceChanged(this.props.productPrice.key, val);
    removePrice = () => this.props.removePrice(this.props.productPrice.key);

    fixedPriceChanged = (index: number, min: number, max: number, price: number) => {
        const { productPrice, fixedPricingChanged } = this.props;
        fixedPricingChanged(productPrice.key, productPrice.fixedPricing.map((p, ix) => ix === index ? { minQuantity: min, maxQuantity: max, price: price, errorKey: null } : p))
    }

    addFixedPriceRule = () => {
        const { productPrice, fixedPricingChanged } = this.props;
        const { fixedPricing } = productPrice;
        const lastRule = fixedPricing.length > 0 ? fixedPricing[fixedPricing.length - 1] : { minQuantity: 0, maxQuantity: 0, price: 0, errorKey: null };
        fixedPricingChanged(productPrice.key, fixedPricing.concat({ minQuantity: lastRule.maxQuantity + 1, maxQuantity: lastRule.maxQuantity + 1, price: 0, errorKey: null }))
    }

    render() {
        const { isFixed, canDelete, productPrice, fixedPricingChanged, dateFormat } = this.props;
        const { t } = this.context;

        const inlineControlStyle = ({ minHeight: '10px', margin: '2px 10px 2px 0' });

        const fixedPrices = isFixed
            ? productPrice.fixedPricing.filter((p, i) => i > 0)
                .map((p, i) => <tr key={`${productPrice.key}_price_${i + 1}`}>
                    <td colSpan={2}></td>
                    <td><ct.NumberBox id={`${i + 1}_min`} labelKey='' placeholderKey='' min='1' step='1' value={ct.asFormValue(`${i + 1}_minQuantity`, p.minQuantity)} callback={val => this.fixedPriceChanged(i + 1, val || 0, p.maxQuantity, p.price)} style={({ ...inlineControlStyle, maxWidth: '140px', minWidth: '50px' })} minimal={true} /></td>
                    <td><ct.NumberBox id={`${i + 1}_max`} labelKey='' placeholderKey='' min={p.minQuantity.toString()} step='1' value={ct.asFormValue(`${i + 1}_maxQuantity`, p.maxQuantity)} callback={val => this.fixedPriceChanged(i + 1, p.minQuantity, val || 0, p.price)} style={({ ...inlineControlStyle, maxWidth: '140px', minWidth: '50px' })} minimal={true} /></td>
                    <td><ct.DecimalNumberBox id={`${i + 1}_price`} labelKey='' placeholderKey='' min='0' step='0.01' value={ct.asFormValue(`${i + 1}_price`, p.price)} callback={val => this.fixedPriceChanged(i + 1, p.minQuantity, p.maxQuantity, val || 0)} style={({ ...inlineControlStyle, maxWidth: '140px', minWidth: '50px' })} minimal={true} /></td>
                    <td><span onClick={e => clickHandler(e, () => fixedPricingChanged(productPrice.key, productPrice.fixedPricing.filter((p, ix) => ix !== i + 1)))} className='glyphicon glyphicon-trash red' style={({ cursor: 'pointer', padding: '5px' })}></span></td>
                    <td>{!isNullOrEmpty(p.errorKey) ? <span className='error-message'>{t(p.errorKey)}</span> : null}</td>
                </tr>)
                .concat([<tr key='additional-person-price'>
                    <td colSpan={4} className='text-right' style={{paddingRight: '8px'}}><label>{t('ProductForm:additionalItemPrice')}</label></td>
                    <td><ct.DecimalNumberBox id='unitPrice' labelKey='' placeholderKey='' min='0' step='0.01' value={productPrice.unitPrice} callback={this.setUnitPrice} style={({ ...inlineControlStyle, maxWidth: '140px', minWidth: '50px' })} minimal={true} /></td>
                </tr>,
                    <tr key='add-rule'>
                        <td colSpan={5}><button className='btn btn-link' onClick={e => clickHandler(e, this.addFixedPriceRule)}>{t('ProductForm:addRule')}</button></td>
                    </tr>])
            : [];

        return [(
            <tr key={productPrice.key}>
                <td><ct.DatePicker id={`${productPrice.key}_effectiveFrom`} labelKey='' value={productPrice.effectiveFrom} callback={this.setEffectiveFrom} style={({ ...inlineControlStyle, minWidth: '140px' })} minimal={true} dateFormat={dateFormat} timeFormat={undefined} /></td>
                <td><ct.DatePicker id={`${productPrice.key}_effectiveTo`} labelKey='' value={productPrice.effectiveTo} callback={this.setEffectiveTo} style={({ ...inlineControlStyle, minWidth: '140px' })} minimal={true} dateFormat={dateFormat} timeFormat={undefined} /></td>
                {
                    isFixed
                        ? <>
                            <td><div style={({ ...inlineControlStyle, maxWidth: '140px', minWidth: '50px' })}>1</div></td>
                            <td><ct.NumberBox id={`${0}_max`} labelKey='' placeholderKey='' min={productPrice.fixedPricing[0].maxQuantity.toString()} step='1' value={ct.asFormValue(`${0}_maxQuantity`, productPrice.fixedPricing[0].maxQuantity)} callback={val => this.fixedPriceChanged(0, productPrice.fixedPricing[0].minQuantity, val || 0, productPrice.fixedPricing[0].price)} style={({ ...inlineControlStyle, maxWidth: '140px', minWidth: '50px' })} minimal={true} /></td>
                        </>
                        : null
                }
                <td>
                    {isFixed
                        ? <ct.DecimalNumberBox id={`${productPrice.key}_unitPrice`} labelKey='' placeholderKey='' min='0' step='0.01' value={ct.asFormValue(`${0}_price`, productPrice.fixedPricing[0].price)} callback={val => this.fixedPriceChanged(0, productPrice.fixedPricing[0].minQuantity, productPrice.fixedPricing[0].maxQuantity, val || 0)} style={({ ...inlineControlStyle, maxWidth: '140px', minWidth: '50px' })} minimal={true} />
                        : <ct.DecimalNumberBox id={`${productPrice.key}_unitPrice`} labelKey='' placeholderKey='' min='0' step='0.01' value={productPrice.unitPrice} callback={this.setUnitPrice} style={({ ...inlineControlStyle, maxWidth: '140px', minWidth: '50px' })} minimal={true} />
                    }
                </td>
                    <td>{isFixed && fixedPrices.length > 0 && !isNullOrEmpty(productPrice.fixedPricing[0].errorKey) ? <span className='error-message'>{t(productPrice.fixedPricing[0].errorKey)}</span> : null}</td>
                {canDelete && isNullOrEmpty(productPrice.id) ? <td><span onClick={e => clickHandler(e, this.removePrice)} className='glyphicon glyphicon-trash red' style={({ cursor: 'pointer', padding: '5px' })}></span></td> : null}
            </tr>)].concat(fixedPrices)
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    dateFormat: state.venues.dateFormat,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
});

// Wire up the React component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(StandardProductPrice);