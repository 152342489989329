import { ValidationError } from '../../global/types';
import * as api from '../../apiClient';


export enum PaymentAmountType {
    None = 0,
    FixedAmount = 1,
    Percentage = 2,
    AmountPerPerson = 3
}

export enum PaymentIntervalType {
    None = 0,
    AfterBookingDate = 1,
    BeforeEventDate = 2
}

export interface PaymentSchedule {
    id: string;
    venueId: string;
    name: string;
    enableForWebBooking: boolean;
    minimumAmountThreshold: number;
    immediatePaymentAmount: number | null;
    immediatePaymentAmountType: PaymentAmountType;
    finalPaymentDescription: string;
    finalPaymentDueInterval: number;
    finalPaymentDueIntervalType: PaymentIntervalType;
    archived: boolean;
    intermediatePayments: PaymentSchedulePayment[]
}

export interface PaymentSchedulePayment {
    id: string;
    description: string;
    amount: number;
    amountType: PaymentAmountType;
    dueInterval: number;
    dueIntervalType: PaymentIntervalType;
}

export interface PaymentScheduleState {
    isLoading: boolean;
    paymentSchedules: PaymentSchedule[];
    loadError: api.ApiError | null;
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
}

export enum PaymentScheduleActionTypes {
    RequestPaymentSchedules = 'REQUEST_PAYMENT_SCHEDULES',
    ReceivedPaymentSchedules = 'RECEIVE_PAYMENT_SCHEDULES',
    EditPaymentSchedule = "EDIT_PAYMENT_SCHEDULE",
    SavePaymentSchedule = 'SAVE_PAYMENT_SCHEDULE',
    PaymentScheduleSaved = 'PAYMENT_SCHEDULE_SAVED',
    PaymentScheduleSaveFailed = 'PAYMENT_SCHEDULE_SAVE_FAILED',
}


export interface LoadPaymentSchedules {
    type: PaymentScheduleActionTypes.RequestPaymentSchedules;
}


export interface ReceivePaymentSchedules {
    type: PaymentScheduleActionTypes.ReceivedPaymentSchedules;
    paymentSchedules: PaymentSchedule[];
    error: api.ApiError | null;
}

export interface SavePaymentSchedule {
    type: PaymentScheduleActionTypes.SavePaymentSchedule;
    isNew: boolean;
    paymentScheduleId: string;
    paymentSchedule: PaymentSchedule;
}

export interface EditPaymentSchedule {
    type: PaymentScheduleActionTypes.EditPaymentSchedule
}

export interface PaymentScheduleSaved {
    type: PaymentScheduleActionTypes.PaymentScheduleSaved;
    paymentScheduleId: string;
}

export interface PaymentScheduleSaveFailed {
    type: PaymentScheduleActionTypes.PaymentScheduleSaveFailed;
    error: api.ApiError;
}