
import { Action } from 'redux';

import { TagActionTypes, TagState } from './types';
import { TagActions } from './actions'
import { createReducer } from '../../reducer';


const unloadedState: TagState = { isLoading: false, tags: [], loadError: null, isSaving: false, saveComplete: false, saveError: null, validationErrors: [] };

export const reducer = (state: TagState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: TagState, incomingAction: Action): TagState => {

    const action = incomingAction as TagActions;
    switch (action.type) {
        case TagActionTypes.RequestTags:
            return {
                ...state,
                isLoading: true,
                tags: state.tags,
                loadError: null
            };
        case TagActionTypes.ReceivedTags:
            const ordered = action.tags ? action.tags : [];
            ordered.sort((a, b) => a.name.localeCompare(b.name));

            return {
                ...state,
                isLoading: false,
                tags: ordered,
                loadError: action.error
            };
        case TagActionTypes.SaveTag:
            return {
                ...state,
                isSaving: true,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case TagActionTypes.TagSaved:
            return {
                ...state,
                isSaving: false,
                saveComplete: true,
                saveError: null,
                tags: state.tags.concat({ ...action.tag, customerCount: 0, productCount: 0 }),
                validationErrors: []
            };
        case TagActionTypes.TagChanged:
            return {
                ...state,
            };
        case TagActionTypes.TagSaveFailed:
            return {
                ...state,
                isSaving: false,
                saveError: action.error,
                saveComplete: false,
                validationErrors: action.error && action.error.validationErrors ? action.error.validationErrors : []
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

