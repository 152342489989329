
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'
import Dropzone from 'react-dropzone';

import moment from 'moment';

import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import * as api from '../../../store/apiClient';
import { ApplicationState } from '../../../store';
import * as MembershipActions from '../../../store/pages/memberships/actions';
import * as ModalActions from '../../../store/global/modal/actions';
import { TaxRate } from '../../../store/pages/taxRates/types';
import { clickHandler, generateTempId, isNullOrEmpty } from '../../../utils/util';
import ApiError from '../../global/apiError';
import { ImageFile, getImageProps } from '../../../utils/images';
import { Days, Interval, IntervalUnit, Time, ValidationError } from '../../../store/global/types';
import { Venue } from '../../../store/pages/venues/types';
import { ProductPricePoint } from '../../../store/pages/products/types';
import { ClientEmailTemplate, EmailType } from '../../../store/pages/emailTemplates/types';
import { stringComparer } from '../../../utils/comparers';
import StandardProductPrice from '../venues/products/standardProductPrice';
import { hasPriceOverlap, validatePricing } from '../venues/products/pricingValidation';
import { ProdPrice, ProductFixedPricing } from '../venues/products/types';
import { MembershipExpiryType, MembershipType } from '../../../store/pages/memberships/types';
import { VenuePublicWebsiteSettings } from '../../../store/pages/publicWebsiteSettings/types';
import TagSelection from '../../global/tagSelection';
import { SpecialTagType, Tag } from '../../../store/pages/tags/types';
import { Promotion } from '../../../store/pages/promotions/types';

interface VenueSelection {
    venue: Venue;
    selected: boolean;
}

interface VenuePurchaseSettings {
    venue: Venue;
    publicWebsiteIdsToSellOn: number[];
    sellOnPointOfSale: boolean;
}

interface MappedReduxState {
    saveComplete: boolean;
    saveError: api.ApiError | null;
    saving: boolean;
    validationErrors: ValidationError[];
    taxRates: TaxRate[];
    venues: Venue[];
    publicWebsiteSettings: VenuePublicWebsiteSettings[];
    membershipEmailTemplates: ClientEmailTemplate[];
    membershipTags: Tag[];
    promotions: Promotion[];
}

interface LocalProps {
    isNew: boolean;
    membershipType: MembershipType | null;
}

interface Actions {
    closeModal: () => void;
    saveMembershipType: (isNew: boolean, membershipType: MembershipType, productImg: File | null, membershipImg: File | null) => void;
}

type MembershipTypeFormProps = MappedReduxState & Actions & LocalProps;

interface MembershipTypeFormState {
    name: ct.FormValue<string>;
    description: ct.FormValue<string>;
    priceEffectiveDatesPricePoint: ct.FormValue<ProductPricePoint>
    pricing: ProdPrice[];
    taxRateId: ct.FormValue<string>;
    archived: ct.FormValue<boolean>;
    accountingCategory: ct.FormValue<string>;
    accountingDepartment: ct.FormValue<string>;
    nominalCode: ct.FormValue<string>;
    productImageUrl: string | null;
    productImage: ImageFile | null;
    productImageError: string | null;
    membershipImageUrl: string | null;
    membershipImage: ImageFile | null;
    membershipImageError: string | null;
    numberCustomPrefix: ct.FormValue<string>;
    numberOfDigitsToPad: ct.FormValue<number>;
    expiryType: ct.FormValue<MembershipExpiryType | null>;
    expiryDate: ct.FormValue<moment.Moment | null>;
    expiryInterval: ct.FormValue<Interval | null>;
    clientEmailTemplateId: ct.FormValue<string>;
    tagId: string;
    promotionIds: string[];
    venueSelections: VenueSelection[];
    venuePurchaseSettings: VenuePurchaseSettings[];
    productInformation: ct.FormValue<string>;
    validationError: string | null;
    standardPricingError: string | null;
    venuePromotionSelection: Map<string, string>
}

class MembershipTypeForm extends React.Component<MembershipTypeFormProps, MembershipTypeFormState> {

    constructor(props: MembershipTypeFormProps) {
        super(props);

        this.state = this.buildStateFromProps(this.props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps(props: MembershipTypeFormProps): MembershipTypeFormState {

        const { isNew, membershipType } = props;

        const expiryType = (isNew || !membershipType) ? null : membershipType.expiryType;

        const activeVenues = props.venues.filter(v => !v.archived);
        return {
            name: this.validateName((isNew || !membershipType) ? '' : membershipType.name),
            description: this.validateDescription((isNew || !membershipType) ? '' : membershipType.description),
            priceEffectiveDatesPricePoint: this.validatePriceEffectiveDatesPricePoint(ProductPricePoint.PurchaseDate),
            pricing: (isNew || !membershipType) ? [this.createDefaultPrice(false, ProductPricePoint.PurchaseDate)] : membershipType.pricing.map(pp => ({
                key: pp.id,
                id: pp.id,
                isOverride: pp.isOverride,
                unitPrice: this.validatePricingUnitPrice(pp.unitPrice),
                fixedPricing: this.validatePricingFixedPricing(pp.fixedPricing.sort((p1, p2) => p1.minQuantity - p2.minQuantity).map(p => ({
                    minQuantity: p.minQuantity,
                    maxQuantity: p.maxQuantity,
                    price: p.price,
                    errorKey: null
                }))),
                effectiveFrom: this.validatePricingEffectiveFrom(moment(pp.effectiveFrom)),
                effectiveTo: this.validatePricingEffectiveTo(pp.effectiveTo ? moment(pp.effectiveTo) : null),
                effectiveDatesPricePoint: this.validatePricingEffectiveDatesPricePoint(pp.effectiveDatesPricePoint),
                tagId: this.validatePricingTagId(pp.tagId),
                appliesOnPricePoint: this.validatePricingAppliesOnPricePoint(pp.appliesOnPricePoint),
                applyPriceOnDays: pp.applyPriceOnDays,
                applyPriceFrom: this.validatePricingApplyPriceFrom(pp.applyPriceFrom),
                applyPriceTo: this.validatePricingApplyPriceTo(pp.applyPriceTo),
                archived: this.validatePricingArchived(pp.archived),
            })),
            taxRateId: this.validateTaxRate((isNew || !membershipType) ? '' : membershipType.taxRateId),
            archived: this.validateArchived((isNew || !membershipType) ? false : membershipType.archived),
            accountingCategory: this.validateAccountingCategory((isNew || !membershipType) ? '' : membershipType.accountingCategory),
            accountingDepartment: this.validateAccountingDepartment((isNew || !membershipType) ? '' : membershipType.accountingDepartment),
            nominalCode: this.validateNominalCode((isNew || !membershipType) ? '' : membershipType.nominalCode),
            numberCustomPrefix: this.validateNumberCustomPrefix((isNew || !membershipType) ? '' : membershipType.numberCustomPrefix),
            numberOfDigitsToPad: this.validateNumberOfDigitsToPad((isNew || !membershipType) ? 5 : membershipType.numberOfDigitsToPad),
            expiryType: this.validateExpiryType(expiryType),
            expiryDate: this.validateExpiryDate((isNew || !membershipType) ? null : moment(membershipType.expiryDate), expiryType),
            expiryInterval: this.validateExpiryInterval((isNew || !membershipType) ? null : membershipType.expiryInterval, expiryType),
            tagId: (isNew || !membershipType) ? '' : membershipType.tag.tagId,
            promotionIds: (isNew || !membershipType) ? [] : membershipType.promotionIds,
            productInformation: this.validateProductInformation((isNew || !membershipType) ? '' : membershipType.productInformation),
            venueSelections: activeVenues.map(v => {
                const selected = !isNew && membershipType && membershipType.venueIds.includes(v.id) ? true : activeVenues.length === 1;
                return {
                    venue: v,
                    selected: selected
                };
            }),
            venuePurchaseSettings: activeVenues.map(v => {
                const voucherVenueSetting = isNew || !membershipType ? null : membershipType.venuePurchaseSettings.filter(s => s.venueId === v.id)[0];
                return {
                    venue: v,
                    sellOnPointOfSale: isNew || !membershipType || membershipType.venuePurchaseSettings.findIndex(s => s.venueId === v.id && s.sellOnPointOfSale) >= 0,
                    publicWebsiteIdsToSellOn: voucherVenueSetting ? voucherVenueSetting.publicWebsiteIdsToSellOn : []
                };
            }),
            productImageUrl: (isNew || !membershipType) ? null : membershipType.productImageUrl,
            membershipImageUrl: (isNew || !membershipType) ? null : membershipType.membershipImageUrl,
            clientEmailTemplateId: this.validateClientEmailTemplateId((isNew || !membershipType) ? '' : membershipType.clientEmailTemplateId),
            productImage: null,
            productImageError: null,
            membershipImage: null,
            membershipImageError: null,
            validationError: null,
            standardPricingError: null,
            venuePromotionSelection: new Map<string,string>()
        };
    }

    componentDidUpdate(prevProps: MembershipTypeFormProps) {
        // Only update state is resource has changed
        const { membershipType: prevMembershipType, saveComplete: prevSaveComplete } = prevProps;
        const { membershipType, saveComplete } = this.props;
        if ((!prevMembershipType && membershipType) || (prevMembershipType && !membershipType) || (prevMembershipType && membershipType && prevMembershipType.id !== membershipType.id)) {
            this.setState(this.buildStateFromProps(this.props));
        }

        if (saveComplete && !prevSaveComplete) {
            setTimeout(() => { this.close(); }, 750);
        }
    }

    private saveProduct = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }

    private close = () => {
        this.props.closeModal();
    }

    private save = () => {
        if (!v.isValid(this.state)) {
            this.setState({ validationError: this.context.t('Global:formNotValid') });
            // TODO: Show error message!
        } else {
            const membershipTypeId = this.props.isNew || !this.props.membershipType ? null : this.props.membershipType.id;
            const productId = this.props.isNew || !this.props.membershipType ? null : this.props.membershipType.productId;
            const { isNew, saveMembershipType, publicWebsiteSettings, membershipTags } = this.props;
            const { name, description, taxRateId, archived, accountingCategory, accountingDepartment, nominalCode,
                productImage, membershipImage, numberCustomPrefix, numberOfDigitsToPad, expiryType,
                expiryDate, expiryInterval, venueSelections, venuePurchaseSettings, tagId, clientEmailTemplateId, pricing,
                productInformation, promotionIds } = this.state;

            if (pricing.length === 0) {
                this.setState({ validationError: this.context.t('ProductForm:productMustHaveAPrice') });
                return;
            }

            if (pricing.filter(p => !v.isValid(p)).length > 0) {
                this.setState({ validationError: this.context.t('Global:formNotValid') });
                return;
            }

            // check for overlapping prices
            const pricingValidation = validatePricing(pricing.filter(p => !p.isOverride && !p.archived.value));
            if (!isNullOrEmpty(pricingValidation)) {
                this.setState({ standardPricingError: this.context.t(pricingValidation) });
                return;
            }

            if (venueSelections.filter(vs => vs.selected).length === 0) {
                this.setState({ validationError: this.context.t('Global:formNotValid') });
                return;
            }

            if (isNew && (!productImage || !productImage.file)) {
                this.setState({ validationError: this.context.t('MembershipTypeForm:missingProductImage') });
                return;
            }

            if (isNew && (!membershipImage || !membershipImage.file)) {
                this.setState({ validationError: this.context.t('MembershipTypeForm:missingMembershipImage') });
                return;
            }

            const publicWebsiteCount = publicWebsiteSettings.filter(s => !s.archived).length;
            const tag = membershipTags.find(t => t.id === tagId);

            saveMembershipType(isNew,
                {
                    id: membershipTypeId || '',
                    productId: productId || '',
                    name: name.value,
                    description: description.value,
                    pricing: pricing.map(p => ({
                        id: p.id || '',
                        isOverride: p.isOverride,
                        unitPrice: p.unitPrice.value || 0,
                        fixedPricing: p.fixedPricing.map(fp => ({ minQuantity: fp.minQuantity, maxQuantity: fp.maxQuantity, price: fp.price })),
                        effectiveFrom: p.effectiveFrom.value.toDate(),
                        effectiveTo: p.effectiveTo.value ? p.effectiveTo.value.toDate() : null,
                        effectiveDatesPricePoint: p.effectiveDatesPricePoint.value,
                        tagId: p.tagId.value,
                        appliesOnPricePoint: p.appliesOnPricePoint.value,
                        applyPriceOnDays: p.applyPriceOnDays,
                        applyPriceFrom: p.applyPriceFrom.value,
                        applyPriceTo: p.applyPriceTo.value,
                        archived: p.archived.value,
                    })),
                    taxRateId: taxRateId.value,
                    accountingDepartment: accountingDepartment.value,
                    accountingCategory: accountingCategory.value,
                    nominalCode: nominalCode.value,
                    numberCustomPrefix: numberCustomPrefix.value,
                    numberOfDigitsToPad: numberOfDigitsToPad.value,
                    expiryType: expiryType.value || MembershipExpiryType.None,
                    expiryDate: expiryType.value === MembershipExpiryType.Fixed && expiryDate.value ? expiryDate.value.toDate() : null,
                    expiryInterval: expiryType.value === MembershipExpiryType.Rolling ? expiryInterval.value : null,
                    venueIds: venueSelections.filter(s => s.selected).map(s => s.venue.id),
                    venuePurchaseSettings: venuePurchaseSettings.map(s => {
                        return ({ venueId: s.venue.id, publicWebsiteIdsToSellOn: s.publicWebsiteIdsToSellOn, sellOnPointOfSale: s.sellOnPointOfSale })
                    }),
                    tag: { tagId: tagId, tagName: tag ? tag.name : '', tagColour: tag ? tag.colour : '' },
                    promotionIds: promotionIds,
                    clientEmailTemplateId: clientEmailTemplateId.value,
                    productInformation: productInformation.value,
                    archived: archived.value,
                    productImageUrl: '',
                    membershipImageUrl: '',
                    taxRate: 0,
                    taxRateName: '',
                    categoryIds: [],
                    version: 0
                },
                productImage ? productImage.file : null,
                membershipImage ? membershipImage.file : null);

            this.setState({ validationError: null, standardPricingError: null });
        }
    }

    taxRateChanged = (newValue: string) => this.setState({ taxRateId: this.validateTaxRate(newValue) });

    expiryTypeChanged = (newValue: string) => this.setState(s => {
        const val: MembershipExpiryType = parseInt(newValue);
        return ({
            expiryType: this.validateExpiryType(val),
            expiryInterval: val === MembershipExpiryType.Rolling && s.expiryInterval.value == null ? this.validateExpiryInterval({ value: 1, unit: IntervalUnit.Year }, val) : s.expiryInterval,
            expiryDate: this.validateExpiryDate(s.expiryDate.value, val)
        })
    });

    onExpiryDateChanged = (date: moment.Moment | null) => {
        if (moment.isMoment(date)) {
            this.setState(s => ({ expiryDate: this.validateExpiryDate(date, s.expiryType.value) }));
        }
    }

    venueSelectionChanged = (venueId: string, selected: boolean) => this.setState(s => ({ venueSelections: s.venueSelections.map(vs => ({...vs, selected: vs.venue.id === venueId ? selected : vs.selected })) }))

    validateName = (val: string) => v.validate(val, 'name', [v.required], this.props.validationErrors);
    validateDescription = (val: string) => v.validate(val, 'description', [v.required], this.props.validationErrors);
    validateUnitPrice = (val: number) => v.validate(val, 'unitPrice', [v.numeric, v.requiredNonZero], this.props.validationErrors);
    validateTaxRate = (val: string) => v.validate(val, 'taxRate', [v.required], this.props.validationErrors);
    validateArchived = (val: boolean) => v.validate(val, 'archived', [], this.props.validationErrors);
    validateAccountingCategory = (val: string) => v.validate(val, 'accountingCategory', [], this.props.validationErrors);
    validateAccountingDepartment = (val: string) => v.validate(val, 'accountingDepartment', [], this.props.validationErrors);
    validateNominalCode = (val: string) => v.validate(val, 'nominalCode', [], this.props.validationErrors);
    validateNumberCustomPrefix = (val: string) => v.validate(val, 'numberCustomPrefix', [], this.props.validationErrors);
    validateNumberOfDigitsToPad = (val: number) => v.validate(val, 'numberOfDigitsToPad', [v.required], this.props.validationErrors);
    validateExpiryType = (val: MembershipExpiryType | null) => v.validate(val, 'expiryType ', [v.required], this.props.validationErrors);
    validateExpiryDate = (val: moment.Moment | null, expiryType: MembershipExpiryType | null) => v.validate(val, 'expiryDate', expiryType === MembershipExpiryType.Fixed ? [v.required, v.validMoment] : [], this.props.validationErrors);
    validateExpiryInterval = (val: Interval | null, expiryType: MembershipExpiryType | null) => v.validate(val, 'expiryInterval', expiryType === MembershipExpiryType.Rolling ? [v.required] : [], this.props.validationErrors);
    validateProductTagName = (val: string) => v.validate(val, 'productTagName', [v.required], this.props.validationErrors);
    validateProductTagColour = (val: string) => v.validate(val, 'productTagColour', [v.required], this.props.validationErrors);
    validateProductInformation = (val: string) => v.validate(val, 'productInformation', [], this.props.validationErrors);
    validateClientEmailTemplateId = (val: string) => v.validate(val, 'clientEmailTemplateId', [v.required], this.props.validationErrors);

    validatePriceEffectiveDatesPricePoint = (val: ProductPricePoint) => v.validate(val, 'priceEffectiveDatesPricePoint', [], this.props.validationErrors);
    validatePricingUnitPrice = (val: number | null) => v.validate(val, 'pricingUnitPrice', [v.numeric, v.required], this.props.validationErrors);
    validatePricingEffectiveFrom = (val: moment.Moment) => v.validate(val, 'pricingEffectiveFrom', [v.required], this.props.validationErrors);
    validatePricingEffectiveTo = (val: moment.Moment | null) => v.validate(val, 'pricingEffectiveTo', [], this.props.validationErrors);
    validatePricingEffectiveDatesPricePoint = (val: ProductPricePoint) => v.validate(val, 'pricingEffectiveDatesPricePoint', [], this.props.validationErrors);
    validatePricingTagId = (val: string | null) => v.validate(val, 'pricingTagId', [], this.props.validationErrors);
    validatePricingAppliesOnPricePoint = (val: ProductPricePoint) => v.validate(val, 'pricingAppliesOnPricePoint', [], this.props.validationErrors);
    validatePricingApplyPriceFrom = (val: Time | null) => v.validate(val, 'pricingApplyPriceFrom', [], this.props.validationErrors);
    validatePricingApplyPriceTo = (val: Time | null) => v.validate(val, 'pricingApplyPriceTo', [], this.props.validationErrors);
    validatePricingArchived = (val: boolean) => v.validate(val, 'pricingArchived', [], this.props.validationErrors);
    validatePricingFixedPricing = (fixedPricing: ProductFixedPricing[]) => {
        return fixedPricing.reduce<ProductFixedPricing[]>((result, p) => {
            let errorKey: string | null = null;

            if (p.maxQuantity < p.minQuantity) {
                errorKey = 'ProductForm:maxMustBeGreaterThanMin';
            } else if (p.price === 0) {
                errorKey = 'ProductForm:priceMustBeGreaterThanZero';
            }

            if (result.length > 0) {
                const prev = result[result.length - 1];
                if (p.minQuantity <= prev.maxQuantity) {
                    errorKey = 'ProductForm:quantitiesCannotOverlap'
                }
            }

            result.push({ ...p, errorKey: errorKey });
            return result;
        }, []);
    }

    onProductImageDrop = (files: File[]) => {
        const file = files[0];

        getImageProps(file).then(img => {
            if (img.width > 160 || img.height > 160) {
                this.setState({ productImageError: this.context.t('Global:imageWrongSize', { w: 160, h: 160 }) });
            } else {
                this.setState({ productImageError: null, productImage: { file: file, preview: URL.createObjectURL(file) } });
            }
        });
    }

    onMembershipImageDrop = (files: File[]) => {
        const file = files[0];

        getImageProps(file).then(img => {
            if (img.width > 640 || img.height > 640) {
                this.setState({ membershipImageError: this.context.t('Global:imageWrongSize', {w:640, h:640}) });
            } else {
                this.setState({ membershipImageError: null, membershipImage: { file: file, preview: URL.createObjectURL(file) } });
            }
        });
    }

    onVenuePosSettingChanged = (venueId: string, checked: boolean) => {
        this.setState(s => ({ venuePurchaseSettings: s.venuePurchaseSettings.map(vs => vs.venue.id === venueId ? { ...vs, sellOnPointOfSale: checked } : vs) }))
    }

    onVenueWebShopSettingChanged = (venueId: string, publicWebsiteId: number, checked: boolean) => {
        this.setState(s => ({
            venuePurchaseSettings: s.venuePurchaseSettings.map(vs => {
                if (vs.venue.id !== venueId) return vs;
                let publicWebsiteIds = vs.publicWebsiteIdsToSellOn.filter(x => x !== publicWebsiteId);
                if (checked) publicWebsiteIds = publicWebsiteIds.concat(publicWebsiteId);
                return { ...vs, publicWebsiteIdsToSellOn: publicWebsiteIds }
            })
        }))
    }

    onVenuePromotionSelected = (venueId: string, promotionId: string) => {
        this.setState(s => {
            var venuePromotionSelection = s.venuePromotionSelection;
            venuePromotionSelection.set(venueId, promotionId);
            return ({ venuePromotionSelection: venuePromotionSelection })
        });
    }

    addVenuePromotion = (venueId: string) => {
        const selectedPromotion = this.state.venuePromotionSelection.get(venueId);
        if (selectedPromotion)
            this.setState(s => ({ promotionIds: s.promotionIds.concat(selectedPromotion) }))
    }

    removeVenuePromotion = (promotionId: string) => {
        this.setState(s => ({ promotionIds: s.promotionIds.filter(p => p !== promotionId) }))
    }

    membershipEmailTemplateChanged = (val: string) => this.setState({ clientEmailTemplateId: this.validateClientEmailTemplateId(val) });

    effectiveFromChanged = (key: string, newDate: moment.Moment) => this.setState(s => {
        const newPricing = s.pricing.map(pp => pp.key === key ? { ...pp, effectiveFrom: this.validatePricingEffectiveFrom(newDate) } : pp);
        const standardPricingError = validatePricing(newPricing.filter(p => !p.isOverride && !p.archived.value));
        return { pricing: newPricing, standardPricingError: standardPricingError };
    })
    effectiveToChanged = (key: string, newDate: moment.Moment | null) => this.setState(s => {
        const newPricing = s.pricing.map(pp => pp.key === key ? { ...pp, effectiveTo: this.validatePricingEffectiveTo(newDate) } : pp);
        const standardPricingError = validatePricing(newPricing.filter(p => !p.isOverride && !p.archived.value));
        return { pricing: newPricing, standardPricingError: standardPricingError };
    })
    effectivePricePointChanged = (key: string, pricePoint: ProductPricePoint) => this.setState(s => ({ pricing: s.pricing.map(pp => pp.key === key ? { ...pp, effectiveDatesPricePoint: this.validatePricingEffectiveDatesPricePoint(pricePoint) } : pp) }))
    unitPriceChanged = (key: string, unitPrice: number) => this.setState(s => ({ pricing: s.pricing.map(pp => pp.key === key ? { ...pp, unitPrice: this.validatePricingUnitPrice(unitPrice) } : pp) }))
    removePrice = (key: string) => this.setState(s => ({ pricing: s.pricing.filter(pp => pp.key !== key) }))

    createDefaultPrice = (isOverride: boolean, pricePoint: ProductPricePoint) => ({
        key: generateTempId(),
        id: '',
        isOverride: isOverride,
        unitPrice: this.validatePricingUnitPrice(null),
        fixedPricing: [],
        effectiveFrom: this.validatePricingEffectiveFrom(moment(new Date())),
        effectiveTo: this.validatePricingEffectiveTo(null),
        effectiveDatesPricePoint: this.validatePricingEffectiveDatesPricePoint(pricePoint),
        tagId: this.validatePricingTagId(null),
        appliesOnPricePoint: this.validatePricingAppliesOnPricePoint(pricePoint),
        applyPriceOnDays: Days.None,
        applyPriceFrom: this.validatePricingApplyPriceFrom(null),
        applyPriceTo: this.validatePricingApplyPriceTo(null),
        archived: this.validatePricingArchived(false)
    }) 

    addPrice = (isOverride: boolean) => this.setState(s => {
        const newPricing = s.pricing.concat(this.createDefaultPrice(isOverride, ProductPricePoint.PurchaseDate))
        const standardPricingError = hasPriceOverlap(newPricing.filter(p => !p.isOverride && !p.archived.value)) ? 'ProductForm:standardPricesCannotOverlap' : null;
        return { pricing: newPricing, standardPricingError: standardPricingError };
    })

    render() {

        let message: any;
        const { t } = this.context;
        const { name, description, taxRateId, archived, accountingCategory, accountingDepartment, nominalCode, productImage,
            membershipImage, membershipImageError, membershipImageUrl, numberCustomPrefix, numberOfDigitsToPad,
            expiryType, expiryDate, expiryInterval, venueSelections, venuePurchaseSettings, tagId, productImageUrl, clientEmailTemplateId,
            pricing, validationError, standardPricingError, productInformation, productImageError, promotionIds } = this.state;
        const { saveError, saving, saveComplete, taxRates, membershipEmailTemplates, isNew, membershipTags } = this.props;

        if (saveError) {
            message = <ApiError error={saveError} />;
        } else if (validationError) {
            message = (<div className='alert alert-danger'>{validationError}</div>);
        }
        else if (saveComplete) {
            message = (<div className='bg-success'>{t('Global:saveComplete')}</div>);
        }

        const taxRateOptions = [{ key: '', name: t('VoucherProductForm:selectTaxRate') }].concat(taxRates.map(c => ({ key: c.id, name: c.name })));
        const expiryTypeOptions = Object.keys(MembershipExpiryType).filter(key => typeof MembershipExpiryType[key as any] === 'number').map(key => ({ key: MembershipExpiryType[key as any].toString(), name: t(`MembershipExpiryType:${key}`) }));

        let previewProductImg: JSX.Element | null = null;
        const prodImageUrl = productImage ? productImage.preview : productImageUrl !== null ? productImageUrl : null;
        if (prodImageUrl) {
            previewProductImg = <img src={prodImageUrl} className='file-preview' alt='preview' style={({ width: '100px'})}></img>;
        }

        let previewMembershipImg: JSX.Element | null = null;
        const mbrImageUrl = membershipImage ? membershipImage.preview : membershipImageUrl !== null ? membershipImageUrl : null;
        if (mbrImageUrl) {
            previewMembershipImg = <img src={mbrImageUrl} className='file-preview' alt='preview' style={({ width: '100px' })}></img>;
        }

        const membershipEmailTemplateOptions = [{ key: '', name: t('Global:selectTemplate') }].concat(membershipEmailTemplates.map(et => ({ key: et.clientEmailTemplateId || '', name: et.name || '' })).filter(x => !isNullOrEmpty(x.key) && !isNullOrEmpty(x.name)));

        const tag = membershipTags.find(t => t.id === tagId);

        return <div className='MembershipTypeForm'>
            <h2 className='membership_type_title'>{isNew ? t('MembershipTypeForm:addMembershipType') : name.value}</h2>

            <form className='data-form' onSubmit={this.saveProduct} autoComplete='off'>
                <ct.TextBox id='name' labelKey='Global:name' placeholderKey='Global:name' value={name} callback={val => this.setState({ name: this.validateName(val) })} />

                <ct.TextArea id='description' labelKey='Global:description' placeholderKey='Global:description' value={description} callback={val => this.setState({ description: this.validateDescription(val) })} rows={4} />

                <label>{t('ProductForm:pricing')}</label>
                <table style={{ margin: '15px 0' }}>
                    <thead>
                        <tr>
                            <th>{t('ProductForm:priceEffectiveFrom')}</th>
                            <th style={{ minWidth: '140px' }}>{t('ProductForm:priceEffectiveTo')}</th>
                            <th>{t('Global:price')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pricing.filter(p => !p.isOverride).map(p => <StandardProductPrice
                            key={p.key}
                            isFixed={false}
                            canDelete={pricing.length > 1}
                            productPrice={p}
                            effectiveFromChanged={this.effectiveFromChanged}
                            effectiveToChanged={this.effectiveToChanged}
                            unitPriceChanged={this.unitPriceChanged}
                            removePrice={this.removePrice}
                            fixedPricingChanged={(key: string, fp: ProductFixedPricing[]) => { } }
                            ref={null} />)}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={4}>
                                <button className='btn btn-primary' onClick={e => clickHandler(e, () => this.addPrice(false))}>{t('ProductForm:addPrice')}</button>
                            </td>
                        </tr>
                    </tfoot>
                </table>

                {standardPricingError ? <div className='alert alert-danger'>{t(standardPricingError)}</div> : null}

                <ct.Select id='clientEmailTemplateId' labelKey='MembershipTypeForm:membershipEmailTemplate' value={clientEmailTemplateId} callback={this.membershipEmailTemplateChanged} options={membershipEmailTemplateOptions} />

                <div style={{ borderTop: 'solid 1px #999', margin: '15px 0', maxWidth: '400px' }}>
                    <h4>{t('MembershipTypeForm:membershipNumberOptions')}</h4>

                   <ct.TextBox id='numberCustomPrefix' labelKey='MembershipTypeForm:numberCustomPrefix' placeholderKey='MembershipTypeForm:numberCustomPrefix' value={numberCustomPrefix} callback={val => this.setState({ numberCustomPrefix: this.validateNumberCustomPrefix(val) })} />

                    <ct.NumberBox id='numberOfDigitsToPad' labelKey='MembershipTypeForm:numberOfDigitsToPad' placeholderKey='MembershipTypeForm:numberOfDigitsToPad' value={numberOfDigitsToPad} callback={val => this.setState({ numberOfDigitsToPad: this.validateNumberOfDigitsToPad(val || 0) })} min='0' step='1' />
                </div>

                <div style={{ borderTop: 'solid 1px #999', margin: '15px 0', maxWidth: '400px' }}>
                    <h4>{t('MembershipTypeForm:expiryOptions')}</h4>

                    <ct.Select id='expiryType' labelKey='MembershipTypeForm:expiryType' value={({ ...expiryType, value: (expiryType.value || '').toString() })} callback={this.expiryTypeChanged} options={expiryTypeOptions} />

                    {expiryType.value === MembershipExpiryType.Fixed
                        ? <ct.DatePicker id='expiryDate' labelKey='MembershipTypeForm:expiryDate' value={expiryDate} callback={this.onExpiryDateChanged} />
                        : null}

                    {expiryType.value === MembershipExpiryType.Rolling
                        ? <ct.TimeInterval id='expiryInterval' labelKey='MembershipTypeForm:expiryInterval' value={{ ...expiryInterval, value: expiryInterval.value || { value: 1, unit: IntervalUnit.Year } }} callback={val => this.setState({ expiryInterval: this.validateExpiryInterval(val, expiryType.value) })} />
                        : null}
                </div>

                {this.renderVenueSettings(venueSelections)}

                <p />

                {this.renderVenuePurchaseSettings(venuePurchaseSettings, promotionIds)}

                <ct.HtmlInput id='productInformation' labelKey='VoucherProductForm:productInformation' value={productInformation} callback={val => this.setState({ productInformation: this.validateProductInformation(val) })} />

                <p />

                <div className='row'>
                    <div className='col-xs-12'>
                        <h4>{t('MembershipTypeForm:tagSettings')}</h4>
                        <TagSelection
                            selectedTags={tag ? [tag] : []}
                            canCreateTag={true}
                            canAddTag={!tag}
                            specialTagType={SpecialTagType.Membership}
                            addTagLabel={'MembershipTypeForm:selectTag'}
                            tagFilter={t => t.specialTagType === SpecialTagType.Membership}
                            tagAdded={(tag: Tag) => this.setState(s => ({ tagId: tag.id }))}
                            removeTag={(tagId: string) => this.setState(s => ({ tagId: '' }))} />

                        {!tag ? <div className='alert alert-danger'>{t('MembershipTypeForm:selectTag')}</div> : null}
                    </div>
                </div>

                <div style={{ borderTop: 'solid 1px #999', margin: '15px 0', maxWidth: '400px' }}>
                    <h4>{t('MembershipTypeForm:accountingSettings')}</h4>
                </div>
                <ct.Select id='taxRate' labelKey='ProductForm:taxRate' value={({ ...taxRateId, value: taxRateId.value.toString() })} callback={this.taxRateChanged} options={taxRateOptions} />

                <ct.TextBox id='accountingCategory' labelKey='ProductForm:accountingCategory' placeholderKey='ProductForm:accountingCategory' value={accountingCategory} callback={val => this.setState({ accountingCategory: this.validateAccountingCategory(val) })} />

                <ct.TextBox id='accountingDepartment' labelKey='ProductForm:accountingDepartment' placeholderKey='ProductForm:accountingDepartment' value={accountingDepartment} callback={val => this.setState({ accountingDepartment: this.validateAccountingDepartment(val) })} />

                <ct.TextBox id='nominalCode' labelKey='ProductForm:nominalCode' placeholderKey='ProductForm:nominalCode' value={nominalCode} callback={val => this.setState({ nominalCode: this.validateNominalCode(val) })} />

                <label>{t('ProductForm:productImage')}</label>
                <div>
                    <Dropzone multiple={false} accept={{ 'image/*': ['.png', '.gif', '.jpeg', '.jpg'] }} onDrop={this.onProductImageDrop}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className='file-drop'>
                                <input {...getInputProps()} />
                                <p>{t('Global:imageDropText')}</p>
                            </div>
                        )}
                    </Dropzone>
                    {previewProductImg}
                    {isNullOrEmpty(productImageError) ? null : <div className='alert alert-danger'>{productImageError}</div>}
                </div>

                <label>{t('MembershipTypeForm:membershipImage')}</label>
                <div>
                    <Dropzone multiple={false} accept={{ 'image/*': ['.png', '.gif', '.jpeg', '.jpg'] }} onDrop={this.onMembershipImageDrop}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className='file-drop'>
                                <input {...getInputProps()} />
                                <p>{t('Global:imageDropText')}</p>
                            </div>
                        )}
                    </Dropzone>
                    {previewMembershipImg}
                    {isNullOrEmpty(membershipImageError) ? null : <div className='alert alert-danger'>{membershipImageError}</div>}
                </div>

                <ct.Checkbox id='archived' labelKey='Global:archive' value={archived} callback={val => this.setState({ archived: this.validateArchived(val) })} />

                {message}

                <p />
                <div className='btn-toolbar'>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)} disabled={saving}>{t('Global:save')}</button>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{t('Global:cancel')}</button>
                </div>
            </form>
        </div>;
    }

    renderVenueSettings = (venueSelections: VenueSelection[]) => {
        const { t } = this.context;

        if (venueSelections.length === 1) {
            return null;
        }

        return <div className='form-group'>
            <label>{t('MembershipTypeForm:useAtVenues')}</label>
            <ul className='list-unstyled'>
                {
                    venueSelections.map(v => {
                        return [(
                            <li key={v.venue.id}>
                                <input type='checkbox' id={v.venue.id} checked={v.selected} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.venueSelectionChanged(v.venue.id, e.currentTarget.checked)} />
                                <label htmlFor={v.venue.id} style={{marginLeft: '8px'}}>{v.venue.name}</label>
                            </li>)]
                    })
                }
            </ul>

            {venueSelections.filter(vs => vs.selected).length === 0 ? <div className='alert alert-danger'>{t('MembershipTypeForm:noVenuesSelected')}</div> : null}
        </div>
    }

    renderVenuePurchaseSettings = (venuePurchaseSelections: VenuePurchaseSettings[], promotionIds: string[]) => {
        const { t } = this.context;
        const { venuePromotionSelection } = this.state;
        const { publicWebsiteSettings, promotions } = this.props;

        const activeWebsites = publicWebsiteSettings.filter(s => !s.archived);
        const showVenueWebShopSetting = activeWebsites.length > 0;
        const now = new Date();

        return <table className='table table-condensed' style={{ maxWidth: '600px' }}>
            <thead>
                <tr key='heading'>
                    <th colSpan={showVenueWebShopSetting ? 3 : 2} className='text-center'>{t('VoucherProductForm:VenueSettingsHeader')}</th>
                </tr>
                <tr key='headers'>
                    <th>{t('VoucherProductForm:VenueHeader')}</th>
                    <th className='text-center'>{t('VoucherProductForm:SellOnPointOfSaleHeader')}</th>
                    {showVenueWebShopSetting ? <th className='text-center'>{t('VoucherProductForm:SellOnWebShopHeader')}</th> : null}
                    <th>{t('VoucherProductForm:promotions')}</th>
                </tr>
            </thead>
            <tbody>
                {venuePurchaseSelections.map(v => {
                    const venuePublicWebsites = publicWebsiteSettings.filter(ws => ws.venueId === v.venue.id && !ws.archived)
                    const venuePromotions = promotions.filter(p => p.venueId === v.venue.id);
                    return <tr key={v.venue.id}>
                        <td>{v.venue.name}</td>
                        <td className='text-center'><input id={`${v.venue.id}_pos`} type='checkbox' checked={v.sellOnPointOfSale} onChange={e => this.onVenuePosSettingChanged(v.venue.id, e.currentTarget.checked)} /></td>
                        <td>
                            {showVenueWebShopSetting && venuePublicWebsites.length > 0
                                ? venuePublicWebsites.length > 1 
                                    ? venuePublicWebsites.map(ws => <div>
                                        <label>
                                            <input id={`${v.venue.id}_${ws.publicWebsiteId}_webshop`} type='checkbox' checked={v.publicWebsiteIdsToSellOn.indexOf(ws.publicWebsiteId) >= 0} onChange={e => this.onVenueWebShopSettingChanged(v.venue.id, ws.publicWebsiteId, e.currentTarget.checked)} />
                                            {venuePublicWebsites.length > 1 ? <span style={{ marginLeft: 10 }}>{ws.publicWebsiteName}</span> : null}
                                        </label>
                                        </div>)
                                        : <input id={`${v.venue.id}_${venuePublicWebsites[0].publicWebsiteId}_webshop`} type='checkbox' checked={v.publicWebsiteIdsToSellOn.indexOf(venuePublicWebsites[0].publicWebsiteId) >= 0} onChange={e => this.onVenueWebShopSettingChanged(v.venue.id, venuePublicWebsites[0].publicWebsiteId, e.currentTarget.checked)} />
                                : null}
                        </td>
                        <td>
                            <div>
                                <ul className='list-unstyled'>
                                    {
                                        venuePromotions.filter(p => promotionIds.includes(p.id))
                                            .sort((p1, p2) => stringComparer(p1.name, p2.name))
                                            .map(p => <li>{p.name} <span onClick={e => clickHandler(e, () => this.removeVenuePromotion(p.id))} className='glyphicon glyphicon-trash red' style={({ cursor: 'pointer', padding: '5px' })}></span></li>)
                                    }
                                    <div className='flex flex-row flex-center'>
                                        <ct.Select id={`${v.venue.id}_addPromotion`} labelKey='VoucherProductForm:selectPromotion' style={{ minWidth: '200px'}} value={ct.asFormValue('', venuePromotionSelection.get(v.venue.id) || '')} callback={val => this.onVenuePromotionSelected(v.venue.id, val)} options={venuePromotions.filter(p => !promotionIds.includes(p.id) && (p.endDate == null || p.endDate > now)).map(p => ({key: p.id, name: p.name}))} />
                                        <button className='btn btn-primary' style={{ marginBottom: '5px', marginLeft: '6px' }} onClick={e => clickHandler(e, () => this.addVenuePromotion(v.venue.id))}>{t('VoucherProductForm:addPromotion')}</button>
                                    </div>
                                </ul>
                            </div>
                        </td>
                    </tr>
                })}
            </tbody>
        </table>
    }
};

const mapStateToProps = (state: ApplicationState) => ({
    saveComplete: state.memberships.saveComplete,
    saveError: state.memberships.saveError,
    saving: state.memberships.isSaving,
    validationErrors: state.memberships.validationErrors,
    taxRates: state.taxRates.taxRates,
    venues: state.venues.venues,
    publicWebsiteSettings: state.publicWebsiteSettings.publicWebsiteSettings,
    membershipTags: state.tags.tags.filter(t => t.specialTagType === SpecialTagType.Membership),
    membershipEmailTemplates: state.emailTemplates.emailTemplates.filter(t => t.emailType === EmailType.MembershipPurchaseConfirmation && !t.archived).sort((t1, t2) => stringComparer(t1.name || '', t2.name || '')),
    promotions: state.promotions.promotions
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    saveMembershipType: bindActionCreators(MembershipActions.actionCreators.saveMembershipType, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch)
});

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(MembershipTypeForm);
