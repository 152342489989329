/*
Copied from: React-Quill
https://github.com/zenoamaro/react-quill
to add additional features
*/

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import isEqual from 'lodash/isEqual';

import Quill, { QuillOptionsStatic, RangeStatic, BoundsStatic, StringMap, Sources, } from 'quill';
import Delta from 'quill-delta';

export type Value = string | Delta;
export type Range = RangeStatic | null;

export interface QuillOptions extends QuillOptionsStatic {
    tabIndex?: number,
}

export interface ReactQuillProps {
    bounds?: string | HTMLElement,
    children?: React.ReactElement<any>,
    className?: string,
    defaultValue?: Value,
    formats?: string[],
    id?: string,
    modules?: StringMap,
    onChange?(value: string, delta: Delta, source: Sources, editor: UnprivilegedEditor, ): void,
    onChangeSelection?(selection: Range, source: Sources, editor: UnprivilegedEditor, ): void,
    onFocus?(selection: Range, source: Sources, editor: UnprivilegedEditor, ): void,
    onBlur?(previousSelection: Range, source: Sources, editor: UnprivilegedEditor, ): void,
    onKeyDown?: React.EventHandler<any>,
    onKeyPress?: React.EventHandler<any>,
    onKeyUp?: React.EventHandler<any>,
    placeholder?: string,
    preserveWhitespace?: boolean,
    readOnly?: boolean,
    scrollingContainer?: string | HTMLElement,
    style?: React.CSSProperties,
    editorStyle?: React.CSSProperties,
    tabIndex?: number,
    theme?: string,
    value?: Value,
}

export interface UnprivilegedEditor {
    getLength(): number;
    getText(index?: number, length?: number): string;
    getHTML(): string;
    getBounds(index: number, length?: number): BoundsStatic;
    getSelection(focus?: boolean): RangeStatic;
    getContents(index?: number, length?: number): Delta;
}

interface ReactQuillState {
    generation: number,
    value: Value,
    selection: Range,
}

class ReactQuill extends React.Component<ReactQuillProps, ReactQuillState> {

    static displayName = 'React Quill'

    /*
    Export Quill to be able to call `register`
    */
    static Quill = Quill;

    /*
    Changing one of these props should cause a full re-render and a
    re-instantiation of the Quill editor.
    */
    dirtyProps: (keyof ReactQuillProps)[] = [
        'modules',
        'formats',
        'bounds',
        'theme',
        'children',
        'editorStyle'
    ]

    /*
    Changing one of these props should cause a regular update. These are mostly
    props that act on the container, rather than the quillized editing area.
    */
    cleanProps: (keyof ReactQuillProps)[] = [
        'id',
        'className',
        'style',
        'readOnly',
        'placeholder',
        'tabIndex',
        'onChange',
        'onChangeSelection',
        'onFocus',
        'onBlur',
        'onKeyPress',
        'onKeyDown',
        'onKeyUp',
        'value'
    ]

    static defaultProps = {
        theme: 'snow',
        modules: {},
        readOnly: false,
    }

    state: ReactQuillState = {
        generation: 0,
        selection: null,
        value: '',
    }

    /*
    The Quill Editor instance.
    */
    editor?: Quill

    /*
    Reference to the element holding the Quill editing area.
    */
    editingArea?: React.ReactInstance | null

    /*
    Used to compare whether deltas from `onChange` are being used as `value`.
    */
    lastDeltaChangeSet?: Delta

    /*
    Stores the contents of the editor to be restored after regeneration.
    */
    regenerationSnapshot?: {
        delta: Delta,
        selection: Range,
    }

    /*
    A weaker, unprivileged proxy for the editor that does not allow accidentally
    modifying editor state.
    */
    unprivilegedEditor?: UnprivilegedEditor

    constructor(props: ReactQuillProps) {
        super(props);
        const value = this.isControlled() ? props.value : props.defaultValue;
        this.state.value = value ?? '';
    }

    validateProps(props: ReactQuillProps): void {
        if (React.Children.count(props.children) > 1) throw new Error(
            'The Quill editing area can only be composed of a single React element.'
        );

        if (React.Children.count(props.children)) {
            const child = React.Children.only(props.children);
            if (child?.type === 'textarea') throw new Error(
                'Quill does not support editing on a <textarea>. Use a <div> instead.'
            );
        }

        if (
            this.lastDeltaChangeSet &&
            props.value === this.lastDeltaChangeSet
        ) throw new Error(
            'You are passing the `delta` object from the `onChange` event back ' +
            'as `value`. You most probably want `editor.getContents()` instead. ' +
            'See: https://github.com/zenoamaro/react-quill#using-deltas'
        );
    }

    shouldComponentUpdate(nextProps: ReactQuillProps, nextState: ReactQuillState) {
        this.validateProps(nextProps);

        // If the component has been regenerated, we already know we should update.
        if (this.state.generation !== nextState.generation) {
            return true;
        }

        return [...this.cleanProps, ...this.dirtyProps].some((prop) => {
            const hasChanged = !isEqual(nextProps[prop], this.props[prop]);
            if (hasChanged) {
                console.log(`property ${prop} has changed`);
            }
            return hasChanged;
        });
    }

    shouldComponentRegenerate(nextProps: ReactQuillProps): boolean {
        // Whenever a `dirtyProp` changes, the editor needs reinstantiation.
        return this.dirtyProps.some((prop) => {
            const hasChanged = !isEqual(nextProps[prop], this.props[prop]);
            if (hasChanged) {
                console.log(`property ${prop} has changed`);
            }
            return hasChanged;
        });
    }

    componentDidMount() {
        this.instantiateEditor();
        this.setEditorContents(this.editor!, this.state.value);
    }

    componentWillUnmount() {
        this.destroyEditor();
    }

    componentDidUpdate(prevProps: ReactQuillProps, prevState: ReactQuillState) {
        let editor: Quill | undefined | null = this.editor;

        // If we're changing one of the `dirtyProps`, the entire Quill Editor needs
        // to be re-instantiated. Regenerating the editor will cause the whole tree,
        // including the container, to be cleaned up and re-rendered from scratch.
        // Store the contents so they can be restored later.
        if (editor && this.shouldComponentRegenerate(prevProps)) {
            const delta = editor.getContents();
            const selection = editor.getSelection();
            this.regenerationSnapshot = { delta, selection };
            this.setState({ generation: this.state.generation + 1 });
            this.destroyEditor();
            editor = null;
        }

        // The component has been regenerated, so it must be re-instantiated, and
        // its content must be restored to the previous values from the snapshot.
        if (this.state.generation !== prevState.generation) {
            const { delta, selection } = this.regenerationSnapshot!;
            delete this.regenerationSnapshot;
            editor = this.instantiateEditor();
            editor.setContents(delta);
            if (selection) editor.setSelection(selection);
            editor.focus();
        }

        // Update only if we've been passed a new `value`. This leaves components
        // using `defaultValue` alone.
        if (editor && 'value' in this.props) {
            const prevContents = prevState.value;
            const nextContents = this.props.value ?? '';

            // NOTE: Seeing that Quill is missing a way to prevent edits, we have to
            //       settle for a hybrid between controlled and uncontrolled mode. We
            //       can't prevent the change, but we'll still override content
            //       whenever `value` differs from current state.
            // NOTE: Comparing an HTML string and a Quill Delta will always trigger a
            //       change, regardless of whether they represent the same document.
            if (!this.isEqualValue(nextContents, prevContents)) {
                this.setEditorContents(editor, nextContents);
            }
        }

        // We can update readOnly state in-place.
        if (editor && 'readOnly' in this.props) {
            if (this.props.readOnly !== prevProps.readOnly) {
                this.setEditorReadOnly(editor, this.props.readOnly!);
            }
        }
    }

    instantiateEditor() {
        if (this.editor) {
            throw new Error('Editor is already instantiated');
        }

        const editor = this.createEditor(
            this.getEditingArea(),
            this.getEditorConfig()
        );

        this.editor = editor;
        return editor;
    }

    destroyEditor(): void {
        if (!this.editor) {
            throw new Error('Destroying editor before instantiation');
        }
        this.unhookEditor(this.editor);
        delete this.editor;
    }

    /*
    We consider the component to be controlled if `value` is being sent in props.
    */
    isControlled(): boolean {
        return 'value' in this.props;
    }

    getEditorConfig(): QuillOptions {
        return {
            bounds: this.props.bounds,
            formats: this.props.formats,
            modules: this.props.modules,
            placeholder: this.props.placeholder,
            readOnly: this.props.readOnly,
            scrollingContainer: this.props.scrollingContainer,
            tabIndex: this.props.tabIndex,
            theme: this.props.theme,
        };
    }

    getEditor(): Quill {
        if (!this.editor) throw new Error('Accessing non-instantiated editor');
        return this.editor;
    }

    /**
    Creates an editor on the given element. The editor will be passed the
    configuration, have its events bound,
    */
    createEditor(element: Element, config: QuillOptions) {
        const editor = new Quill(element, config);
        if (config.tabIndex != null) {
            this.setEditorTabIndex(editor, config.tabIndex);
        }
        this.hookEditor(editor);
        return editor;
    }

    hookEditor(editor: Quill) {
        // Expose the editor on change events via a weaker, unprivileged proxy
        // object that does not allow accidentally modifying editor state.
        this.unprivilegedEditor = this.makeUnprivilegedEditor(editor);
        editor.on('editor-change', this.onEditorChange);
    }

    unhookEditor(editor: Quill) {
        editor.off('editor-change', this.onEditorChange);
    }

    getEditorContents(): Value {
        return this.state.value;
    }

    getEditorSelection(): Range {
        return this.state.selection;
    }

    /*
    True if the value is a Delta instance or a Delta look-alike.
    */
    isDelta(value: any): boolean {
        return value && value.ops;
    }

    /*
    Special comparison function that knows how to compare Deltas.
    */
    isEqualValue(value: any, nextValue: any): boolean {
        if (this.isDelta(value) && this.isDelta(nextValue)) {
            return isEqual(value.ops, nextValue.ops);
            //return value.ops === nextValue.ops;
        } else {
            return isEqual(value, nextValue);
            //return value === nextValue;
        }
    }

    /*
    Replace the contents of the editor, but keep the previous selection hanging
    around so that the cursor won't move.
    */
    setEditorContents(editor: Quill, value: Value) {
        const sel = editor.getSelection();
        if (typeof value === 'string') {
            editor.setContents(editor.clipboard.convert(value));
        } else {
            editor.setContents(value);
        }
        if (sel && editor.hasFocus()) this.setEditorSelection(editor, sel);
    }

    setEditorSelection(editor: Quill, range: Range) {
        if (range) {
            // Validate bounds before applying.
            const length = editor.getLength();
            range.index = Math.max(0, Math.min(range.index, length - 1));
            range.length = Math.max(0, Math.min(range.length, (length - 1) - range.index));
        }
        // Quill types (erroneously) do not specify that `null` is accepted here.
        editor.setSelection(range!);
    }

    setEditorTabIndex(editor: Quill, tabIndex: number) {
        if (editor?.scroll?.domNode) {
            (editor.scroll.domNode as HTMLElement).tabIndex = tabIndex;
        }
    }

    setEditorReadOnly(editor: Quill, value: boolean) {
        if (value) {
            editor.disable();
        } else {
            editor.enable();
        }
    }

    /*
    Returns a weaker, unprivileged proxy object that only exposes read-only
    accessors found on the editor instance, without any state-modifying methods.
    */
    makeUnprivilegedEditor(editor: Quill) {
        const e = editor;
        return {
            getHTML: () => e.root.innerHTML,
            getLength: e.getLength.bind(e),
            getText: e.getText.bind(e),
            getContents: e.getContents.bind(e),
            getSelection: e.getSelection.bind(e),
            getBounds: e.getBounds.bind(e),
        };
    }

    getEditingArea(): Element {
        if (!this.editingArea) {
            throw new Error('Instantiating on missing editing area');
        }
        const element = ReactDOM.findDOMNode(this.editingArea);
        if (!element) {
            throw new Error('Cannot find element for editing area');
        }
        if (element.nodeType === 3) {
            throw new Error('Editing area cannot be a text node');
        }
        return element as Element;
    }

    /*
    Renders an editor area, unless it has been provided one to clone.
    */
    renderEditingArea(): JSX.Element {
        const { children, preserveWhitespace, tabIndex, editorStyle } = this.props;
        const { generation } = this.state;

        const properties = {
            tabIndex,
            key: generation,
            ref: (instance: React.ReactInstance | null) => {
                this.editingArea = instance
            },
            style: editorStyle
        };

        if (React.Children.count(children)) {
            return React.cloneElement(
                React.Children.only(children)!,
                properties
            );
        }

        return preserveWhitespace ?
            <pre {...properties} /> :
            <div {...properties} />;
    }

    render() {
        return (
            <div
                id={this.props.id}
                style={this.props.style}
                key={this.state.generation}
                className={`quill ${this.props.className ?? ''}`}
                onKeyPress={this.props.onKeyPress}
                onKeyDown={this.props.onKeyDown}
                onKeyUp={this.props.onKeyUp}
            >
                {this.renderEditingArea()}
            </div>
        );
    }

    onEditorChange = (
        eventName: 'text-change' | 'selection-change',
        rangeOrDelta: Range | Delta,
        oldRangeOrDelta: Range | Delta,
        source: Sources,
    ) => {
        if (eventName === 'text-change') {
            this.onEditorChangeText?.(
                this.editor!.root.innerHTML,
                rangeOrDelta as Delta,
                source,
                this.unprivilegedEditor!
            );
        }
        if (eventName === 'text-change' || eventName === 'selection-change') {
            this.onEditorChangeSelection?.(
                rangeOrDelta as RangeStatic,
                source,
                this.unprivilegedEditor!
            );
        }
    };

    onEditorChangeText(
        value: string,
        delta: Delta,
        source: Sources,
        editor: UnprivilegedEditor,
    ): void {
        if (!this.editor) return;
        const currentContents = this.getEditorContents();

        // We keep storing the same type of value as what the user gives us,
        // so that value comparisons will be more stable and predictable.
        const nextContents = this.isDelta(currentContents)
            ? editor.getContents()
            : editor.getHTML();

        if (!this.isEqualValue(nextContents, currentContents)) {
            // Taint this `delta` object, so we can recognize whether the user
            // is trying to send it back as `value`, preventing a likely loop.
            this.lastDeltaChangeSet = delta;
            this.setState({ value: nextContents });
            this.props.onChange?.(value, delta, source, editor);
        }
    }

    onEditorChangeSelection(
        nextSelection: RangeStatic,
        source: Sources,
        editor: UnprivilegedEditor,
    ): void {
        if (!this.editor) return;
        const currentSelection = this.getEditorSelection();
        const hasGainedFocus = !currentSelection && nextSelection;
        const hasLostFocus = currentSelection && !nextSelection;

        if (isEqual(nextSelection, currentSelection)) return;
        //if (nextSelection === currentSelection) return;

        this.setState({ selection: nextSelection });
        this.props.onChangeSelection?.(nextSelection, source, editor);

        if (hasGainedFocus) {
            this.props.onFocus?.(nextSelection, source, editor);
        } else if (hasLostFocus) {
            this.props.onBlur?.(currentSelection, source, editor);
        }
    }

    focus(): void {
        if (!this.editor) return;
        this.editor.focus();
    }

    blur(): void {
        if (!this.editor) return;
        this.setEditorSelection(this.editor, null);
    }
}

export default ReactQuill;
