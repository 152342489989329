
import * as React from 'react';
import * as PropTypes from 'prop-types'
import { ITranslationContext } from '../../translations';
import { clickHandler } from '../../utils/util';

interface ExternalWindowCoordinatorProps {
    urlToOpen: string;
    windowName: string;
    windowWidth?: number;
    content: JSX.Element;
    popupBlockedMessageKey: string;
    retryAction:() => void;
    completionCallback: () => void;
}

const ExternalWindowCoordinator = (props: ExternalWindowCoordinatorProps, context: ITranslationContext) => {
    const { urlToOpen, windowName, windowWidth, content, retryAction, completionCallback, popupBlockedMessageKey } = props;
    const { t } = context;
    const paymentWindow = window.open(urlToOpen, windowName, `menubar=0, toolbar=0, dependent=1, height=${window.innerHeight - 20}, width=${windowWidth || 735}`);

    if (!paymentWindow) {
        return (
            <div className='text-center at-panel'>
                <h3>{t('PointOfSale:PopupBlockedHeader')}</h3>

                <p>{t(popupBlockedMessageKey)}</p>

                <div>
                    <button onClick={e => clickHandler(e, retryAction)}>{t('Global:retry')}</button>
                </div>
            </div>
        )
    } else {
        const intervalId = setInterval(() => {
            if (paymentWindow.closed) {
                clearInterval(intervalId);
                completionCallback();
            }
        }, 500);
    }

    return content;
}

ExternalWindowCoordinator.contextTypes = {
    t: PropTypes.func
}

export default ExternalWindowCoordinator;