
import * as React from 'react';


interface DiaryResourceHeaderProps {
    quantity?: number;
    totalQuantity: number;
    icon: string;
    showWhenComplete: boolean
    className?: string;
}

const ReservationInfoLabel: React.SFC<DiaryResourceHeaderProps> = (props) => {
    const { quantity, totalQuantity, icon, showWhenComplete, className } = props;

    if (totalQuantity < 1) return null;

    const style = showWhenComplete && quantity && quantity >= totalQuantity ? { backgroundColor: '#9df57a' } : {}
    const cls = className ?? 'cal-reservation-info';
    return (
        <div className={cls} style={style}>
            <span className={`glyphicon glyphicon-${icon} cal-reservation-icon`}></span>
            <span className='cal-reservation-text'>{typeof quantity !== 'undefined' ? `${quantity}/${totalQuantity}` : totalQuantity}</span>
        </div>);
};

export default ReservationInfoLabel;


