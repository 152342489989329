
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'
import { PaymentReminderConfig, PaymentReminderPaymentTypes, PaymentReminderTriggerType } from '../../../../store/pages/paymentReminderConfigurations/types';
import * as ct from '../../../global/controls';
import * as v from '../../../global/validation';
import { ApplicationState } from '../../../../store';
import * as PaymentReminderConfigActions from '../../../../store/pages/paymentReminderConfigurations/actions';
import * as ModalActions from '../../../../store/global/modal/actions';
import * as EmailTemplateActions from '../../../../store/pages/emailTemplates/actions';
import { ClientEmailTemplate, EmailType } from '../../../../store/pages/emailTemplates/types';
import { ValidationError } from '../../../../store/global/types';
import { clickHandler } from '../../../../utils/util';
import { Resource } from '../../../../store/pages/resources/types';
import * as api from '../../../../store/apiClient';
import ApiError from '../../../global/apiError';

const validPaymentTypes = [PaymentReminderPaymentTypes.AllPayments, PaymentReminderPaymentTypes.Deposit, PaymentReminderPaymentTypes.NonDeposit];

enum TimeOffsetSelection {
    DaysBefore,
    DaysOverdue,
    DaysAfterCreation
}

interface LocalProps {
    isNew: boolean;
    venueId: string;
    paymentReminderConfig: PaymentReminderConfig | null;
}

interface MappedState {
    isLoading: boolean;
    isSaving: boolean;
    saveComplete: boolean;
    paymentReminderEmailTemplates: ClientEmailTemplate[];
    isLoadingEmailTemplates: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
}

interface MappedActions {
    savePaymentReminderConfig: (isNew: boolean, paymentReminderConfigId: string | null, paymentReminderConfig: PaymentReminderConfig) => void;
    loadEmailTemplates: () => void;
    closeModal: () => void;
}

type PaymentReminderConfigFormProps = MappedState & MappedActions & LocalProps;

interface PaymentReminderConfigFormState {
    name: ct.FormValue<string>;
    timeOffset: ct.FormValue<number>;
    timeOffsetSelection: ct.FormValue<TimeOffsetSelection>;
    archived: ct.FormValue<boolean>;
    queueEmailImmediately: ct.FormValue<boolean>;
    clientEmailTemplateId: ct.FormValue<string | null>;
    paymentTypes: ct.FormValue<PaymentReminderPaymentTypes | null>;
}

class PaymentReminderConfigForm extends React.Component<PaymentReminderConfigFormProps, PaymentReminderConfigFormState> {

    constructor(props: PaymentReminderConfigFormProps) {
        super(props);

        this.state = this.buildStateFromProps(this.props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps(props: PaymentReminderConfigFormProps): PaymentReminderConfigFormState {

        const { isNew, paymentReminderConfig } = props;

        let timeOffsetSelection: TimeOffsetSelection;
        if (!isNew && paymentReminderConfig && paymentReminderConfig.triggerType === PaymentReminderTriggerType.CreationDate) {
            timeOffsetSelection = TimeOffsetSelection.DaysAfterCreation;
        } else {
            timeOffsetSelection = (isNew || !paymentReminderConfig || paymentReminderConfig.timeOffsetInDays >= 0) ? TimeOffsetSelection.DaysBefore : TimeOffsetSelection.DaysOverdue
        }

        return {
            name: this.validateName((isNew || !paymentReminderConfig) ? '' : paymentReminderConfig.name),
            clientEmailTemplateId: this.validateEmailTemplateId((isNew || !paymentReminderConfig) ? null : paymentReminderConfig.clientEmailTemplateId),
            paymentTypes: this.validatePaymentTypes((isNew || !paymentReminderConfig) ? PaymentReminderPaymentTypes.AllPayments : paymentReminderConfig.paymentTypes),
            timeOffset: this.validateTimeOffset((isNew || !paymentReminderConfig) ? 0 : Math.abs(paymentReminderConfig.timeOffsetInDays)),
            timeOffsetSelection: this.validateTimeOffsetSelection(timeOffsetSelection),
            queueEmailImmediately: this.validateQueueEmailImmediately((isNew || !paymentReminderConfig) ? true : paymentReminderConfig.queueEmailImmediately),
            archived: this.validateArchived((isNew || !paymentReminderConfig) ? false : paymentReminderConfig.archived),
        };
    }
    componentDidUpdate(prevProps: PaymentReminderConfigFormProps) {
        // Only update state if resource has changed
        const { paymentReminderConfig: prevPaymentReminderConfig, saveComplete: prevSaveComplete } = prevProps;
        const { paymentReminderConfig, saveComplete } = this.props;

        if ((!prevPaymentReminderConfig && paymentReminderConfig) || (prevPaymentReminderConfig && !paymentReminderConfig) || (prevPaymentReminderConfig && paymentReminderConfig && prevPaymentReminderConfig.id !== paymentReminderConfig.id)) {
            this.setState(this.buildStateFromProps(this.props));
        }

        if (saveComplete && !prevSaveComplete) {
            setTimeout(() => { this.close(); }, 750);
        }
    }

    private savePaymentReminderConfig = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }

    private close = () => {
        this.props.closeModal();
    }

    private save = () => {
        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {
            const { isNew, paymentReminderConfig, venueId } = this.props;
            const { name, clientEmailTemplateId, paymentTypes, timeOffset, timeOffsetSelection, queueEmailImmediately, archived } = this.state;

            const paymentReminderConfigId = isNew || !paymentReminderConfig ? null : paymentReminderConfig.id;
            const timeOffsetValue = isNaN(timeOffset.value) || timeOffset.value < 0 ? 0 : timeOffset.value;
            this.props.savePaymentReminderConfig(isNew,
                paymentReminderConfigId,
                {
                    id: paymentReminderConfigId || '',
                    venueId: venueId,
                    name: name.value,
                    timeOffsetInDays: timeOffsetValue * (timeOffsetSelection.value === TimeOffsetSelection.DaysOverdue ? -1 : 1),
                    clientEmailTemplateId: clientEmailTemplateId.value || '',
                    triggerType: timeOffsetSelection.value === TimeOffsetSelection.DaysAfterCreation ? PaymentReminderTriggerType.CreationDate : PaymentReminderTriggerType.DueDate,
                    paymentTypes: paymentTypes.value || 0,
                    queueEmailImmediately: queueEmailImmediately.value,
                    archived: archived.value,
                }
            );
        }
    }

    emailTemplateChanged = (emailTemplateId: string) => {
        const { venueId } = this.props;
        const template = this.props.paymentReminderEmailTemplates.find(t => t.venueId === venueId && t.clientEmailTemplateId === emailTemplateId);
        this.setState({ clientEmailTemplateId: this.validateEmailTemplateId(template ? emailTemplateId : null) });
    }

    paymentTypesChanged = (selectedPaymentTypes: string) => {
        var paymentType = parseInt(selectedPaymentTypes);
        var pt = validPaymentTypes.find(x => x === paymentType);
        this.setState({ paymentTypes: this.validatePaymentTypes(pt ? pt : null) });
    }

    onTimeOffsetSelectionChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
        var val = parseInt(event.currentTarget.value);
        this.setState({ timeOffsetSelection: this.validateTimeOffsetSelection(val) });
    }

    validateName = (val: string) => v.validate(val, 'name', [v.required], this.props.validationErrors);
    validateArchived = (val: boolean) => v.validate(val, 'archived', [], this.props.validationErrors);
    validateQueueEmailImmediately = (val: boolean) => v.validate(val, 'queueEmailImmediately', [], this.props.validationErrors);
    validateVariationRunningTime = (key: string, val: Date) => v.validate(val, `${key}_runningTime`, [], this.props.validationErrors);
    validateTimeOffset = (val: number) => v.validate(val, 'timeOffset_value', [], this.props.validationErrors);
    validateTimeOffsetSelection = (val: TimeOffsetSelection) => v.validate(val, 'timeOffset_selection', [], this.props.validationErrors);
    validateEmailTemplateId = (val: string | null) => v.validate(val, 'clientEmailTemplateId', [v.required], this.props.validationErrors);
    validatePaymentTypes = (val: PaymentReminderPaymentTypes | null) => v.validate(val, 'paymentTypes', [v.required], this.props.validationErrors);
    validateTriggerType = (val: PaymentReminderTriggerType) => v.validate(val, 'triggerTypes', [v.required], this.props.validationErrors);

    render() {

        let message: any = null;
        const t = this.context.t;
        const { saveError, saveComplete, isNew, venueId, paymentReminderEmailTemplates } = this.props;
        const { name, archived, clientEmailTemplateId, paymentTypes, timeOffset, timeOffsetSelection, queueEmailImmediately } = this.state;

        const venueEmailTemplates = paymentReminderEmailTemplates.filter(t => t.venueId === venueId);

        if (saveError) {
            message = <ApiError error={saveError} />;
        } else if (saveComplete) {
            message = (<div className='bg-success'>{t('Global:saveComplete')}</div>);
        }

        const emailTemplateOptions = [{ key: 'no_selection', name: t('PaymentReminderConfigForm:noEmailTemplate') }].concat(venueEmailTemplates.map(t => ({ key: t.clientEmailTemplateId || '', name: t.name })));
        const paymentTypesOptions = validPaymentTypes.map(pt => ({ key: pt.toString(), name: t(`PaymentReminderConfigForm:${PaymentReminderPaymentTypes[pt]}`) }));
        const timeOffsetSelectionOptions = Object.keys(TimeOffsetSelection).filter(key => typeof TimeOffsetSelection[key as any] === 'number').map(key => ({ key: TimeOffsetSelection[key as any], name: t(`TimeOffsetSelection:${key}`) })).map(o => <option key={o.key} value={o.key}>{o.name}</option>);

        return <div className='paymentReminderConfigForm'>
            <h2 className='paymentReminderConfig_title'>{isNew ? t('PaymentReminderConfigForm:addPaymentReminderConfig') : name.value}</h2>

            <form className='data-form' onSubmit={this.savePaymentReminderConfig} autoComplete='off'>
                <ct.TextBox id='name' labelKey='PaymentReminderConfigForm:name' placeholderKey='PaymentReminderConfigForm:name' value={name} callback={val => this.setState({ name: this.validateName(val) })} />

                <ct.Select id='clientEmailTemplateId' labelKey='PaymentReminderConfigForm:emailTemplate' value={({ ...clientEmailTemplateId, value: clientEmailTemplateId.value || '' })} callback={this.emailTemplateChanged} options={emailTemplateOptions} />

                {venueEmailTemplates.length === 0 ? <div className='alert alert-danger'>{t('PaymentReminderConfigForm:noEmailTemplates')}</div> : null}

                <ct.Select id='paymentTypes' labelKey='PaymentReminderConfigForm:paymentTypes' value={({ ...paymentTypes, value: paymentTypes.value ? paymentTypes.value.toString() : '' })} callback={this.paymentTypesChanged} options={paymentTypesOptions} />

                <div className='form-group control-wrapper'>
                    <label>{t('PaymentReminderConfigForm:timeOffset')}</label>
                    <div>
                        <input id='timeOffset_value' className='form-control' type='number' value={timeOffset.value} onChange={e => this.setState({ timeOffset: this.validateTimeOffset(parseInt(e.currentTarget.value)) })} min='0' style={({maxWidth: '80px', display: 'inline-block'})} />
                        <select id='timeOffset_selection' className='form-control' onChange={this.onTimeOffsetSelectionChanged} value={timeOffsetSelection.value} style={({ maxWidth: '200px', display: 'inline-block', marginLeft: '15px' })}>{timeOffsetSelectionOptions}</select>
                    </div>
                </div>

                <ct.Checkbox id='queueEmailImmediately' labelKey='PaymentReminderConfigForm:queueEmailImmediately' hintKey='PaymentReminderConfigForm:queueEmailImmediatelyHelp' value={queueEmailImmediately} callback={val => this.setState({ queueEmailImmediately: this.validateQueueEmailImmediately(val) })} />

                <ct.Checkbox id='archived' labelKey='Global:archive' value={archived} callback={val => this.setState({ archived: this.validateArchived(val) })} />

                {message}

                <p />
                <div className='btn-toolbar'>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)}>{t('Global:save')}</button>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{t('Global:cancel')}</button>
                </div>
            </form>
        </div>;
    }       

    buildResourceStyle(resource: Resource) {
        return { marginLeft: '5px', paddingLeft: '3px', borderLeft: `4px solid ${resource.colour}` };
    }
};


const mapStateToProps = (state: ApplicationState) => ({
    isLoading: state.paymentReminderConfigs.isLoading,
    isSaving: state.paymentReminderConfigs.isSaving,
    saveComplete: state.paymentReminderConfigs.saveComplete,
    saveError: state.paymentReminderConfigs.saveError,
    validationErrors: state.paymentReminderConfigs.validationErrors,
    isLoadingEmailTemplates: state.emailTemplates.isLoading,
    paymentReminderEmailTemplates: state.emailTemplates.emailTemplates.filter(t => t.emailType === EmailType.PaymentReminder && t.clientEmailTemplateId !== null && !t.archived)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    savePaymentReminderConfig: bindActionCreators(PaymentReminderConfigActions.actionCreators.savePaymentReminderConfig, dispatch),
    loadEmailTemplates: bindActionCreators(EmailTemplateActions.actionCreators.loadEmailTemplates, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch)
});

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(PaymentReminderConfigForm);
