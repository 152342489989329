
import { Action } from 'redux';

import { CurrencyActionTypes, CurrencyState } from './types';
import { CurrencyActions } from './actions'
import { createReducer } from '../../reducer';


const unloadedState: CurrencyState = { isLoading: false, currencies: [], loadError: null };

export const reducer = (state: CurrencyState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: CurrencyState, incomingAction: Action): CurrencyState => {

    const action = incomingAction as CurrencyActions;
    switch (action.type) {
        case CurrencyActionTypes.RequestCurrencies:
            return {
                ...state,
                isLoading: true,
                currencies: state.currencies,
                loadError: null
            };
        case CurrencyActionTypes.ReceivedCurrencies:
            const ordered = action.currencies ? action.currencies : [];
            ordered.sort((a, b) => a.name.localeCompare(b.name));

            return {
                ...state,
                isLoading: false,
                currencies: ordered,
                loadError: null
            };
        case CurrencyActionTypes.RequestCurrenciesError:
            return {
                ...state,
                isLoading: false,
                currencies: [],
                loadError: action.error
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

