
import * as api from '../../apiClient';
import moment from 'moment';
import { digits } from '../../../utils/util';
import { ReportFilterType } from '../reportDefinitions/types';

export enum ReportTypes {
    None = 0,
    Payments = 1,
    ProductSales = 2,
    ActivitySales = 3,
    Bookings = 4,
    DeletedEvents = 5,
    BillSummary = 6,
    Vouchers = 7,
    Customers = 8,
    WebAnalysis = 9,
    Registrations = 10
}

export enum ReportColumnType {
    String = 1,
    Number = 2,
    Currency = 3,
    Date = 4,
    Time = 5,
    DateTime = 6
}

export enum ColumnTextAlignment {
    Left = 1,
    Center = 2,
    Right = 3
}


export interface ReportDataElement {
    key: string;
    name: string;
    type: ReportColumnType;
    canTotal: boolean;
}

export interface ReportTypeFilter {
    name: string;
    type: ReportFilterType;
}

export interface ReportType {
    reportType: ReportTypes;
    dataElements: ReportDataElement[];
    filterDefinitions: ReportTypeFilter[];
}

export interface ReportTypeState {
    isLoading: boolean;
    reportTypes: ReportType[];
    loadError: api.ApiError | null;
}

export interface ReportRow {
    data: string[];
}

export interface ReportHeader {
    heading: string;
    alignment: ColumnTextAlignment;
    translate: boolean;
}

export interface ReportResult {
    headings: ReportHeader[];
    rows: ReportRow[];
    totals: ReportRow;
}

export interface RunReportResponse {
    report: ReportResult;
}

export enum ReportTypeActionTypes {
    RequestReportTypes = 'REQUEST_REPORT_TYPES',
    ReceivedReportTypes = 'RECEIVE_REPORT_TYPES',
}


export interface LoadReportTypes {
    type: ReportTypeActionTypes.RequestReportTypes;
}


export interface ReceiveReportTypes {
    type: ReportTypeActionTypes.ReceivedReportTypes;
    reportTypes: ReportType[];
    error: api.ApiError | null;
}

export const parseFilterDate = (val: string | moment.Moment) => {
    if (moment.isMoment(val)) {
        return val;
    }

    const pattern = /^(\d{4})-(\d{2})-(\d{2})$/;
    const matches = val.match(pattern);

    if (!matches || matches.length !== 4) {
        return moment();
    }

    var year = parseInt(matches[1]);
    var month = parseInt(matches[2]);
    var day = parseInt(matches[3]);

    return (!isNaN(year) && !isNaN(month) && !isNaN(day)) ? moment({ year: year, month: month - 1, date: day}) : moment();
} 

export const parseFilterDateTime = (val: string | moment.Moment) => {
    if (moment.isMoment(val)) {
        return val;
    }

    var pattern = /^(\d{4})-(\d{2})-(\d{2})[ ](\d{2}):(\d{2})$/;
    const matches = val.match(pattern);

    if (!matches || matches.length !== 6) {
        return moment();
    }

    var year = parseInt(matches[1]);
    var month = parseInt(matches[2]);
    var day = parseInt(matches[3]);
    var hour = parseInt(matches[4]);
    var minute = parseInt(matches[5]);

    return (!isNaN(year) && !isNaN(month) && !isNaN(day) && !isNaN(hour) && !isNaN(minute)) ? moment({ year: year, month: month - 1, date: day, hour: hour, minute: minute }) : moment();
}

export const parseNumericFilter = (val: string | null) => {
    if (val === null) return null;

    var parsed = parseFloat(val);

    return !isNaN(parsed) ? parsed : null;
}

export const formatFilterDate = (val: moment.Moment | null) => val ? `${digits(val.years(), 2)}-${digits(val.months() + 1, 2)}-${digits(val.date(), 2)}` : '';

export const formatFilterDateTime = (val: moment.Moment | null) => val ? `${digits(val.years(), 2)}-${digits(val.months() + 1, 2)}-${digits(val.date(), 2)} ${digits(val.hours(), 2)}:${digits(val.minutes(), 2)}` : '';

export const formatNumber = (val: number | null) => val ? val.toFixed(2) : '';