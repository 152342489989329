
import { Observable, Subject, Subscriber } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ajax, AjaxError, AjaxResponse, AjaxRequest } from 'rxjs/ajax';

import { getAccessToken, storeAccessToken, getRefreshToken, storeRefreshToken } from '../utils/auth';
import {RegistrationSettings} from './pages/venues/types';
import {Customer} from './pages/customer/types';
import { ValidationError } from './global/types';
import { isNullOrEmpty } from '../utils/util';


//  https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-after-catching-and-processing-an-error-with-emitting-somethi
//  https://auth0.com/blog/jwt-authentication-with-observables/
//  

interface authenticateResp {
    refreshToken: string;
    accessToken: string;
    mustChangePassword: boolean;
    validFor: number;
    userFirstName: string | null;
    userLastName: string | null;
    c: number;
    st: number | null;
}

interface authenticateRegistrationResp {
    refreshToken: string;
    accessToken: string;
    validFor: number;
    registrationSettings: RegistrationSettings;
}

export interface tokenResp {
    accessToken: string;
    validFor: number;
    c: number;
    st: number | null;
}

export interface ApiError {
    status: number;
    messageKey: string;
    message: string;
    unauthenticated: boolean;
    validationErrors: ValidationError[]
}

class Urls {

    baseApiUrl = '';
    appVersion = '0.0.0.0';

    initialize(baseApiUrl: string) {
        this.baseApiUrl = baseApiUrl + (baseApiUrl.length > 0 && baseApiUrl[baseApiUrl.length-1] !== '/' ? '/' :'');
    }

    setAppVersion(appVersion: string) {
        this.appVersion = appVersion;
    }

    formatUrl(releativeUrl: string) {
        return `${this.baseApiUrl}${releativeUrl}`;
    }

    buildHeaders(customHeaders: any) {
        return { ...customHeaders, 'Client-App-Version': this.appVersion };
    }
}

const urls = new Urls();
const versionCheckObservable = new Subject<boolean>();

const handlError = (error: AjaxError) => {
    console.error('ApiService::handleError', error);
    return Observable.throw(createApiError(error));
}

export const versionCheck = () => versionCheckObservable;

export const setBaseUrl = (baseApiUrl: string) => {
    urls.initialize(baseApiUrl);
}

export const setAppVersion = (appVersion: string) => {
    urls.setAppVersion(appVersion);
}

export const getAppVersion = () => urls.appVersion;

export const getClientImageUrl = (imageId: string) => urls.formatUrl(`api/v1/clientImage/${imageId}`);

export const getClientImageThunbUrl = (imageId: string, width?: number, height?: number) => {
    const query: string[] = [];

    if (width)
        query.push(`w=${width}`);

    if (height)
        query.push(`h=${height}`);

    return urls.formatUrl(`api/v1/clientImage/${imageId}/thumb${query.length > 0 ? '?' + query.join('&') : ''}`);
}

export interface AuthenticateResult {
    mustChangePassword: boolean;
    at: string;
    sessionTimeout: number | null;
    userFirstName: string | null;
    userLastName: string | null;
}

export const authenticate = (username: string, password: string): Observable<AuthenticateResult> => {
    const body = { username: username, password: password };

    return internalSend('api/v1/token/authorize', body, 'POST', false, '')
        .pipe(map(res => {
            var { mustChangePassword, ...result } = res.response as authenticateResp;

            if (!mustChangePassword) {
                storeRefreshToken(result.refreshToken);
                storeAccessToken(result.accessToken, result.validFor, result.c, result.st);
            }

            return { mustChangePassword: mustChangePassword, at: result.accessToken, sessionTimeout: result.st, userFirstName: result.userFirstName, userLastName: result.userLastName };
        }), catchError(handlError));
};

export const authenticateRegistrationKiosk = (code: string, password: string): Observable<authenticateRegistrationResp> => {
    const body = { code: code, password: password };

    return internalSend('api/v1/token/authorizeRegistration', body, 'POST', false, '')
        .pipe(map(res => res.response as authenticateRegistrationResp), catchError(err => {
            if (err.status && err.status === 401) {
                const apiError = createApiError(err);
                const mappedError = { ...apiError, messageKey: 'LoginForm:invalidCredentials' };
                return Observable.throw(mappedError);
            } else {
                return handlError(err);
            }
        }));
}

export const getRegistrationSettings = (venueId: string, kioskCode: string) => {
    return internalSend(`api/v1/registration/${venueId}/settings/${kioskCode}`, { }, 'GET', false, '')
        .pipe(map(res => res.response as RegistrationSettings), catchError(err => {
            if (err.status && err.status === 401) {
                const apiError = createApiError(err);
                const mappedError = { ...apiError, messageKey: 'LoginForm:invalidCredentials' };
                return Observable.throw(mappedError);
            } else {
                return handlError(err);
            }
        }));
}

export const changeUserPassword = (at: string, oldPassword: string, newPassword: string, confPassword: string) => {
    const body = { password: oldPassword, newPassword: newPassword, confNewPassword: confPassword };

    return internalSend('api/v1/token/changePassword', body, 'POST', true, at)
        .pipe(map(res => {
            var { mustChangePassword, ...result } = res.response as authenticateResp;

            if (!mustChangePassword) {
                storeRefreshToken(result.refreshToken);
                storeAccessToken(result.accessToken, result.validFor, result.c, result.st);
            }

            return { mustChangePassword: mustChangePassword, at: result.accessToken };
        }),
        catchError(handlError));
}

export const resetUserPassword = (token: string, username: string, newPassword: string, confPassword: string) => {
    const body = { token: token, username: username, newPassword: newPassword, confNewPassword: confPassword };

    return internalSend('api/v1/token/resetPassword', body, 'POST', false, '')
        .pipe(map(res => {
            var { mustChangePassword, ...result } = res.response as authenticateResp;

            if (!mustChangePassword) {
                storeRefreshToken(result.refreshToken);
                storeAccessToken(result.accessToken, result.validFor, result.c, result.st);
            }

            return { mustChangePassword: mustChangePassword, at: result.accessToken };
        }), 
        catchError(handlError));
}

export const resetPassword = (username: string) => {
    return internalSend(`api/v1/user/${username}/forgotPassword`, {}, 'POST', false, '')
        .pipe(map(res => ({ })), catchError(handlError));
}

export const switchClient = (clientId: number) => {
    localStorage.removeItem('clientIdOverride');

    return internalRefreshToken(clientId, null, true).pipe(map(res => {
        localStorage.setItem('clientIdOverride', clientId.toString());
        return res;
    }));
}

export const logout = (token: string | null) => {
    return internalSend(`api/v1/user/logout`, { token }, 'PUT', false, '')
        .pipe(map(res => ({ })), catchError(handlError));
}

export const refreshToken = (refreshToken: string | null, storeToken: boolean = true) => {

    const clientIdVal = localStorage.getItem('clientIdOverride');
    const clientId = clientIdVal ? parseInt(clientIdVal) : null;
    
    return internalRefreshToken(clientId, refreshToken, storeToken);
}

const internalRefreshToken = (clientId: number | null, refreshToken: string | null, storeToken: boolean) => {
    var token = refreshToken || getRefreshToken();

    if (isNullOrEmpty(token)) {
        return Observable.throw({
            status: 401,
            message: 'Invalid or missing refresh token',
            unauthenticated: true,
            messageKey: '',
            validationErrors: []
        });
    }

    const body = { refreshToken: token };

    var url = `api/v1/token${clientId ? `?clientId=${clientId}` : ''}`;
    return internalSend(url, body, 'POST', false, '').pipe(
        map(res => {
            var result = res.response as tokenResp;

            if (storeToken) {
                storeAccessToken(result.accessToken, result.validFor, result.c, result.st);
            }
            return result;
        }), catchError(handlError)); 
}

export const get = (url: string): Observable<AjaxResponse> => {
    var accessToken = getAccessToken();
    return internalGet(url, accessToken.token);
}

const internalGet = (url: string, token: string) => {
    const headers = urls.buildHeaders({ Authorization: `Bearer ${token}` });
    return ajax.get(urls.formatUrl(url), headers).pipe(map(r => responseHandler(r)), catchError(err => Observable.throw(createApiError(err))));
}

const responseHandler = (response: AjaxResponse) => {
    const appUpdateHeader = response.xhr.getResponseHeader('client-app-update');
    var versionMissmatch = !isNullOrEmpty(appUpdateHeader) && appUpdateHeader === 'True';

    if (versionMissmatch) {
        console.log('Client update available');
    }

    versionCheckObservable.next(versionMissmatch);

    return response;
}

const internalGetJson = <T>(url: string, token: string): Observable<T> => {

    const headers = { Authorization: `Bearer ${token}` };

    var settings = {
        url: urls.formatUrl(url),
        method: 'GET',
        crossDomain: true,
        async: true,
        headers: urls.buildHeaders(headers),
        responseType: 'json'
    };

    return ajax(settings).pipe(map(r => responseHandler(r).response), catchError(err => {
        return Observable.throw(createApiError(err))
    }));
}

export const getJson = <T>(url: string): Observable<T> => {
    var accessToken = getAccessToken();
    return internalGetJson(url, accessToken.token);
}

export const post = (url: string, body: any) => internalSend(url, body, 'POST', true, getAccessToken().token);

export const put = (url: string, body: any) => internalSend(url, body, 'PUT', true, getAccessToken().token);

export const uploadFile = (file: File, url: string, progressCallback?: (progress: number) => void) => {
    return internalUploadFile(file, url, progressCallback)
        .pipe(map(resp => resp), catchError(err => {
            if (err.status === 401) {
                return tryRefreshToken(() => { }, () => internalUploadFile(file, url, progressCallback));
            } else {
                return Observable.throw(createApiError(err));
            }
        }));
}

const internalUploadFile = (file: File, url: string, progressCallback?: (progress: number) => void) => {

    const fd = new FormData();
    fd.append('file', file, file.name);

    const at = getAccessToken();
    const headers = { Authorization: `Bearer ${at.token}` };

    const progesssSubscriber = progressCallback
        ? Subscriber.create((e?: ProgressEvent) => {
            if (e && e.type === 'progress' && progressCallback) {
                const percentComplete = Math.floor(e.loaded / e.total * 100);
                progressCallback(percentComplete);
            }
        })
        : undefined;

    var settings: AjaxRequest = {
        url: urls.formatUrl(url),
        method: 'POST',
        body: fd,
        crossDomain: true,
        async: true,
        headers: urls.buildHeaders(headers),
        progressSubscriber: progesssSubscriber
    };

    return ajax(settings).pipe(map(r => responseHandler(r)), catchError(err => Observable.throw(createApiError(err))));
}

export const uploadFileWithProgress = (file: File, url: string) => {

    const fd = new FormData();
    fd.append('file', file, file.name);

    const at = getAccessToken();
    const headers = { Authorization: `Bearer ${at.token}` };

    var subj = new Subject<number>();

    var progress = Subscriber.create((e?: ProgressEvent) => {
        if (e) {
            if (e.type === 'progress') {//Detect if it is response of Progress ( not XHR complete response )
                const percentComplete = Math.floor(e.loaded / e.total * 100);
                if (percentComplete < 100)
                    subj.next(percentComplete);
                else
                    subj.complete();
            } else {
                const type = e.type;
                console.log(`Unknown event type ${type}`);
            }
        }
    }, subj.error, subj.complete);

    var settings: AjaxRequest = {
        url: urls.formatUrl(url),
        method: 'POST',
        body: fd,
        crossDomain: true,
        async: true,
        headers: urls.buildHeaders(headers),
        progressSubscriber: progress
    };

    ajax(settings).subscribe(r => responseHandler(r), subj.error, subj.complete);

    return subj.pipe(map(resp => resp), catchError(err => Observable.throw(createApiError(err))));
}

const internalSendWithAuth = (url: string, body: any, method: string, notAuthAction: () => void): Observable<AjaxResponse> => {
    return internalSend(url, body, method, true, getAccessToken().token)
        .pipe(resp => resp, catchError((err: ApiError) => {
            if (err.status === 401) {
                return tryRefreshToken(notAuthAction, () => internalSend(url, body, method, true, getAccessToken().token));
            }

            return Observable.throw(err);
        }));
}

const tryRefreshToken = <T>(notAuthAction: () => void, retryAction: () => Observable<T>) => {
    return refreshToken(null).pipe(map(r => r), catchError((refreshError: ApiError) => {
        if (refreshError.status === 401) {
            notAuthAction();
        }
        return Observable.throw(refreshError);
    })).pipe(switchMap(retryAction));
}

const internalSend = (url: string, body: any, method: string, includeAuthHeader: boolean, accessToken: string) : Observable<AjaxResponse> => {
    const contentType = { 'Content-Type': 'application/json' };
    let headers: Object;

    if (includeAuthHeader) {
        headers = { ...contentType, Authorization: `Bearer ${accessToken}` };
    } else {
        headers = contentType;
    }

    var settings = {
        url: urls.formatUrl(url),
        method: method,
        body: body,
        crossDomain: true,
        async: true,
        headers: urls.buildHeaders(headers)
    };

    return ajax(settings).pipe(map(r => responseHandler(r)), catchError(err => {
        return Observable.throw(createApiError(err))
    }));
}

interface FindCustomerResponse {
    customers: Customer[];
}

export const searchForCustomer = (at: string, firstName: string, lastName: string, birthDay: number, birthMonth: number): Observable<FindCustomerResponse> => {

    const url = `api/v1/customer/find?fn=${firstName}&ln=${lastName}&bd=${birthDay}&bm=${birthMonth}`;
    return internalGetJson<FindCustomerResponse>(url, at);
}

export interface CounterSignature {
    termsAndConditionsId: string;
    counterSignatureRequired: boolean;
    counterSigned: boolean;
    counterSignatoryFirstName: string;
    counterSignatoryLastName: string;
    signatureSvg: string;
}

export const registerCustomer = (at: string, venueId: string, kioskId: string, customer: Customer, termsAndConditionsId: string, signatureSvg: string, counterSignature: CounterSignature | null) => {
    const url = 'api/v1/registration';
    const { id, ...cus } = customer;
    return internalSend(url, { venueId: venueId, registrationKioskId: kioskId, customerId: id, ...cus, termsAndConditionsId: termsAndConditionsId, signatureSvg: signatureSvg, counterSignature: counterSignature }, 'POST', true, at);
}

export const getWithAuth = <T>(url: string, notAuthAction: () => void) : Observable<T> => {
    return getJson<T>(url).pipe(map(r => r), catchError((err: ApiError) => {
        if (err.status === 401) {
                return tryRefreshToken<T>(notAuthAction, () => getJson<T>(url));
            } else {
                return Observable.throw(err);
            }
        }));
}

const createApiError = (err: AjaxError): ApiError => {
    return ({
        status: err.status,
        message: err.message,
        unauthenticated: err.status >= 401,
        messageKey: getErrorKey(err),
        validationErrors: err.status === 422 ? err.response.errors : []
    });
}

const getErrorKey = (err: AjaxError): string => {
    switch (err.status) {
        case 0:
            return 'ApiError:unableToConnect';
        case 403:
            return 'ApiError:unauthorized';
        case 422:
            return 'ApiError:unprocessableEntity';
        case 500:
            return 'ApiError:serverError';
    }

    return '';
}

export const postWithAuth = (url: string, body: any, notAuthAction: () => void) => internalSendWithAuth(url, body, 'POST', notAuthAction);

export const putWithAuth = (url: string, body: any, notAuthAction: () => void) => internalSendWithAuth(url, body, 'PUT', notAuthAction);

export const downloadFile = (url: string, fileName: string, callback: (success: boolean, error: string | null) => void) => {

    var accessToken = getAccessToken();

    // taken from https://stackoverflow.com/questions/4545311/download-a-file-by-jquery-ajax
    fetch(urls.formatUrl(url), { headers: new Headers({ Authorization: `Bearer ${accessToken.token}` }) })
        .then(resp => {
            return resp.blob();
        })
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            callback(true, null);
        })
        .catch(err => {
            alert('oh no!');
            callback(false, err.message);
        });
}


export const downloadFile1 = (url: string) => {

    var accessToken = getAccessToken();

    const fileReq = new XMLHttpRequest();
    fileReq.withCredentials = true;
    fileReq.open("GET", urls.formatUrl(url), true);
    fileReq.setRequestHeader("Authorization", `Bearer ${accessToken.token}`);
    fileReq.responseType = "blob";

    fileReq.onload = function (oEvent: ProgressEvent) {

        if (oEvent.type === 'load' && fileReq.status === 200) {

            const blob = new Blob(fileReq.response, { type: fileReq.response.type })

            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = 'myfile.csv';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(downloadUrl);
        }
    };

    fileReq.send();
}