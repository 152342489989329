import {ValidationError} from '../../global/types';
import * as api from '../../apiClient';

export interface IUser {
    id: string;
    clientId: number;
    userName: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    systemAdmin: boolean;
    clientAdmin: boolean;
    mustChangePassword: boolean;
    deactivated: boolean;
    sessionTimeout: number | null;
}

export interface UserAccessLog {
    userId: string;
    address: string;
    userAgent: string;
    created: Date;
    lastRefreshed: Date;
}

export interface UserState {
    isActive: boolean;
    isLoading: boolean;
    users: IUser[];
    loadError: api.ApiError | null;
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
}

export enum UserActionTypes {
    RequestUsers = 'REQUEST_USERS',
    ReceivedUsers = 'RECEIVE_USERS',
    SaveUser = 'SAVE_USER',
    UserSaved = 'USER_SAVED',
    UserSaveFailed = 'USER_SAVE_FAILED',
    EditUser = 'EDIT_USER'
}

export interface LoadUsers {
    type: UserActionTypes.RequestUsers;
}


export interface ReceiveUsers {
    type: UserActionTypes.ReceivedUsers;
    users: IUser[];
    error: api.ApiError | null;
}

export interface SaveUser {
    type: UserActionTypes.SaveUser;
    isNew: boolean;
    userId: string|null;
    username: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    clientAdmin: boolean;
    sysAdmin: boolean;
    password: string|null;
    mustChangePassword: boolean;
    deactivated: boolean;
    sessionTimeout: number | null;
}

export interface UserSaved {
    type: UserActionTypes.UserSaved;
    userId: string;
}

export interface UserSaveFailed {
    type: UserActionTypes.UserSaveFailed;
    error: api.ApiError;
}

export interface EditUser {
    type: UserActionTypes.EditUser
}