import { Interval, ValidationError } from '../../global/types';
import * as api from '../../apiClient';

export interface Leaderboard {
    id: string;
    venueId: string;
    name: string;
    sequence: number;
    kartTypeFilter: string | null;
    sessionTypeFilter: TimingSessionType | null;
    trackName: string;
    fromDate: Date | null;
    toDate: Date | null;
    fromInterval: Interval | null;
    toInterval: Interval | null;
    maxRows: number;
    isPublic: boolean;
    customerTagId: string | null;
    archived: boolean;
}

export interface TimingSettings {
    lastUpdate: Date;
    settings: VenueTimingSettings[];
}

export interface VenueTimingSettings {
    venueId: string;
    trackNames: string[];
    kartTypes: string[];
}

export enum TimingSessionType
{
    Unknown = 0,
    Practice = 1,
    Qualifying = 2,
    Race = 3,
    TimeTrial = 4
}

export interface LeaderboardState {
    isLoading: boolean;
    leaderboards: Leaderboard[];
    loadError: api.ApiError | null;
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
    timingSettings: TimingSettings;
    loadingTimingSettings: boolean;
}

export enum LeaderboardActionTypes {
    RequestLeaderboards = 'REQUEST_LEADERBOARDS',
    ReceivedLeaderboards = 'RECEIVE_LEADERBOARDS',
    SaveLeaderboard = 'SAVE_LEADERBOARD',
    LeaderboardSaved = 'LEADERBOARD_SAVED',
    LeaderboardSaveFailed = 'LEADERBOARD_SAVE_FAILED',
    EditLeaderboard = 'EDIT_LEADERBOARD',
    RequestTimingSettings = 'REQUEST_TIMING_SETTINGS',
    ReceivedTimingSettings = 'RECEIVE_TIMING_SETTINGS'
}

export interface LoadLeaderboards {
    type: LeaderboardActionTypes.RequestLeaderboards;
}

export interface ReceiveLeaderboards {
    type: LeaderboardActionTypes.ReceivedLeaderboards;
    leaderboards: Leaderboard[];
    error: api.ApiError | null;
}

export interface SaveLeaderboard {
    type: LeaderboardActionTypes.SaveLeaderboard;
    isNew: boolean;
    leaderboardId?: string;
    leaderboard: Leaderboard;
}

export interface LeaderboardSaved {
    type: LeaderboardActionTypes.LeaderboardSaved;
    leaderboardId: string;
}

export interface LeaderboardSaveFailed {
    type: LeaderboardActionTypes.LeaderboardSaveFailed;
    error: api.ApiError;
}

export interface EditLeaderboard {
    type: LeaderboardActionTypes.EditLeaderboard
}

export interface LoadTimingSettings {
    type: LeaderboardActionTypes.RequestTimingSettings;
}

export interface ReceiveTimingSettings {
    type: LeaderboardActionTypes.ReceivedTimingSettings;
    settings: VenueTimingSettings[];
    error: api.ApiError | null;
}
