
import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../../../store/index';
import { connect } from 'react-redux'
import { setLanguage } from 'redux-i18n';
import { RegistrationSettings } from '../../../store/pages/venues/types';
import * as api from '../../../store/apiClient';
import RegistrationLogin from './registrationLogin';
import RegistrationWrapper from './registrationWrapper';
import {getKioskId, saveKioskId} from '../../../utils/auth';


interface MappedReduxState {
    i18Lang: string;
}

interface Actions {
    setLanguage: (labg: string) => void;
}

interface LocalProps { }

interface RegistrationPageState {
    at: string | null;
    rt: string | null;
    te: number;
    registrationSettings: RegistrationSettings | null;
    loginError: api.ApiError | null;
} 

type RegistrationPageProps = MappedReduxState & Actions & LocalProps;

class RegistrationPage extends React.Component<RegistrationPageProps, RegistrationPageState> {

    constructor(props: RegistrationPageProps) {
        super(props);

        this.state = { at: null, rt: null, te: 0, registrationSettings: null, loginError: null };
    }

    login = (code: string, password: string) => {

        api.authenticateRegistrationKiosk(code, password)
            .subscribe(resp => {
                saveKioskId(code);

                const settings = resp.registrationSettings;
                const cleansedSettings = {
                    ...settings,
                    termsAndConditions: settings.termsAndConditions || 'TODO: Set up terms and conditions!',
                    finalInstructions: settings.finalInstructions || 'TODO: Set up final instructions!',
                    confirmTermsText: settings.confirmTermsText || 'TODO: Set up confirm terms text'
                }

                this.setState({ at: resp.accessToken, rt: resp.refreshToken, te: resp.validFor, registrationSettings: cleansedSettings, loginError: null });
                this.props.setLanguage(settings.culture);
            }, err => {
                this.setState({ at: null, rt: null, te: 0, registrationSettings: null, loginError: err});
            });
    }

    logout = () => {
        this.setState({ at: null, rt: null, te: 0, registrationSettings: null, loginError: null });
    }

    reloadSettings = () => {
        const { registrationSettings } = this.state;

        if (!registrationSettings)
            return;

        api.getRegistrationSettings(registrationSettings.venueId, getKioskId())
            .subscribe(settings => {
                const cleansedSettings = {
                    ...settings,
                    termsAndConditions: settings.termsAndConditions || 'TODO: Set up terms and conditions!',
                    finalInstructions: settings.finalInstructions || 'TODO: Set up final instructions!',
                    confirmTermsText: settings.confirmTermsText || 'TODO: Set up confirm terms text'
                }

                this.setState({ registrationSettings: cleansedSettings });
                this.props.setLanguage(settings.culture);
            }, err => {
                // TODO: what do we do here???
            });
    }

    render() {

        const { at, rt, registrationSettings} = this.state;
        let body: any = null;
        let style: any = null;
        
        if (!at || !rt || !registrationSettings) {
            body = this.renderLoginForm();
            style = {};
        } else {
            style = registrationSettings && registrationSettings.registrationImageUrl ? { backgroundImage: `url(${registrationSettings.registrationImageUrl}` } : {};
            body = <RegistrationWrapper at={at} rt={rt} logout={this.logout} reloadSettings={this.reloadSettings} registrationSettings={registrationSettings} />;
        }

        return (
            <div style={style} className='registration-page'>
                <div className='registration-content'>
                    {body}
                </div>
            </div>);
    }

    renderLoginForm = () => {
        return <RegistrationLogin login={this.login} loginError={this.state.loginError} />;
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    i18Lang: state.i18nState.lang
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setLanguage: bindActionCreators(setLanguage, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPage);
