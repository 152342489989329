
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'
import { ApplicationState } from '../../../store';
import * as et from '../../../store/pages/emails/types';
import * as EmailActions from '../../../store/pages/emails/actions';
import * as ModalActions from '../../../store/global/modal/actions';
import Loading from '../../global/loading';
import Pagination from '../../global/pagination';
import { EmailDetailsPage } from './emailDetailsPage';
import { EmailsFilter } from './emailsFilter';
import { clickHandler } from '../../../utils/util';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';


interface LocalProps { }

interface LocalState {
    isLoading: boolean;
    emails: et.Email[];
    pageNumber: number;
    maxPage: number;
    pageSize: number;
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
}

interface Actions {
    loadEmails: (pageNumber: number, pageSize: number, to: string, status: et.EmailStatus | -1, fromDate: Date | null, toDate: Date | null) => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
}

interface EmailsPageState {
    showFilter: boolean;
    filterTo: string;
    filterStatus: et.EmailStatus | -1;
    filterFromDate: Date | null;
    filterToDate: Date | null;
}

type EmailsPageProps = LocalState & Actions & LocalProps;

class EmailsPage extends React.Component<EmailsPageProps, EmailsPageState> {

    constructor(props: EmailsPageProps) {
        super(props);

        this.state = { showFilter: false, filterTo:'', filterStatus: -1, filterFromDate: null, filterToDate: null };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        const { pageNumber, pageSize } = this.props;
        const { filterTo, filterStatus, filterFromDate, filterToDate } = this.state;

        // This method runs when the component is first added to the page
        this.props.loadEmails(pageNumber, pageSize, filterTo, filterStatus, filterFromDate, filterToDate);
    }

    setPage = (pageNumber: number) => this.props.loadEmails(pageNumber, this.props.pageSize, this.state.filterTo, this.state.filterStatus, this.state.filterFromDate, this.state.filterToDate);

    setPageSize = (pageSize: number) => this.props.loadEmails(this.props.pageNumber, pageSize, this.state.filterTo, this.state.filterStatus, this.state.filterFromDate, this.state.filterToDate);

    reloadEmails = () => this.props.loadEmails(this.props.pageNumber, this.props.pageSize, this.state.filterTo, this.state.filterStatus, this.state.filterFromDate, this.state.filterToDate);

    toggleFilter = () => this.setState(state => ({ showFilter: !state.showFilter }));

    applyFilter = (to: string, status: et.EmailStatus | -1, fromDate: Date | null, toDate: Date | null) => {
        this.setState({ showFilter: false, filterTo: to, filterStatus: status, filterFromDate: fromDate, filterToDate: toDate });
        this.props.loadEmails(1, this.props.pageSize, to, status, fromDate, toDate);
    }
    
    render() {

        const { showFilter } = this.state;
        const body = this.props.isLoading ? <Loading /> : this.renderEmailsTable();
        
        return <section className='emailsPage'>
            <header className='section-header'>
                <div className='page-heading'>
                    <h1 className='emails_title'>{this.context.t('EmailsPage:title')}</h1>
                </div>
                <div className='section-actions right'>
                    <button type='button' className='btn btn-xs btn-info' onClick={e => clickHandler(e, this.toggleFilter)}>
                        <span className='glyphicon glyphicon-filter'></span>
                    </button>
                    <button type='button' className='btn btn-xs btn-info' onClick={e => clickHandler(e, this.reloadEmails)}>
                        <span className='glyphicon glyphicon-refresh'></span>
                    </button>
                </div>
            </header>
            <div className='clearfix' />
            <EmailsFilter show={showFilter} to={''} status={-1} fromDate={null} toDate={null} applyFilter={this.applyFilter} />
            {body}
            <Pagination maxPage={this.props.maxPage} pageSize={this.props.pageSize} pageNumber={this.props.pageNumber} setPage={this.setPage} setPageSize={this.setPageSize}  />
        </section>;
    }

    viewEmailDetail = (email: et.Email) => {
        const { timeFormat, dateFormat, showModal, closeModal } = this.props;
        showModal(<EmailDetailsPage emailId={email.id} timeFormat={timeFormat} dateFormat={dateFormat} close={closeModal} />, 'EmailDetails');
    }

    renderEmailsTable = () => {
        const { t } = this.context;
        const { timeFormat, dateFormat } = this.props;

        const emails = this.props.emails.map(email =>
            <tr key={email.id}>
                <td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.viewEmailDetail(email))}>{email.createDateTime.toAbbrDateTimeString(timeFormat, dateFormat, t)}</button></td>
                <td>{email.emailAddress}</td>
                <td>{email.templateName}</td>
                <td>{email.subject}</td>
                <td>{this.renderStatus(email)}</td>
                <td>{email.timeSent ? email.timeSent.toAbbrDateTimeString(timeFormat, dateFormat, t) : ''}</td>
            </tr>
        );

        return <table className='table table-condensed'>
            <thead>
                <tr key='email-templates-header'>
                    <th>{t('EmailsPage:timeHeading')}</th>
                    <th>{t('EmailsPage:addressHeading')}</th>
                    <th>{t('EmailsPage:templateHeading')}</th>
                    <th>{t('EmailsPage:subjectHeading')}</th>
                    <th>{t('EmailsPage:statusHeading')}</th>
                    <th>{t('EmailsPage:timeSentHeading')}</th>
                </tr>
            </thead>
            <tbody>
                {emails}
            </tbody>
        </table>;
    }

    renderStatus = (email: et.Email) => {
        const { t } = this.context;
        let label: JSX.Element | null = null;

        interface EmailState { lbl: string, key: string, time: Date  }

        var allStatuses: EmailState[] = [];
        if (email.open) allStatuses.push({ lbl: 'success', key: 'EmailEventType:Open', time: email.open})
        if (email.delivered) allStatuses.push({ lbl: 'success', key: 'EmailEventType:Delivered', time: email.delivered })
        if (email.deferred) allStatuses.push({ lbl: 'warning', key: 'EmailEventType:Deferred', time: email.deferred })
        if (email.dropped) allStatuses.push({ lbl: 'warning', key: 'EmailEventType:Dropped', time: email.dropped })
        if (email.bounce) allStatuses.push({ lbl: 'warning', key: 'EmailEventType:Bounced', time: email.bounce })
        if (email.processed) allStatuses.push({ lbl: 'warning', key: 'EmailEventType:Processed', time: email.processed })

        if (allStatuses.length > 0) {
            const latestStatus = allStatuses.sort((s1, s2) => s2.time.getTime() - s1.time.getTime())[0];
            label = <span className={`label label-${latestStatus.lbl}`}>{t(latestStatus.key)}</span>
        } else if (email.status === et.EmailStatus.Failed) {
            label = <span className='label label-danger'>{t(`EmailStatus:${et.EmailStatus[email.status]}`)}</span>
        } else if(email.status === et.EmailStatus.Cancelled) {
            label = <span className='label label-default'>{t(`EmailStatus:${et.EmailStatus[email.status]}`)}</span>
        } else if (email.status === et.EmailStatus.Sent) {
            label = <span className='label label-info'>{t(`EmailStatus:${et.EmailStatus[email.status]}`)}</span>
        } else if (email.status === et.EmailStatus.Processing) {
            label = <span className='label label-info'>{t(`EmailStatus:${et.EmailStatus[email.status]}`)}</span>
        } else if (email.status === et.EmailStatus.Queued) {
            label = <span className='label label-warning'>{t(`EmailStatus:${et.EmailStatus[email.status]}`)}</span>
        } else if (email.status === et.EmailStatus.Created) {
            label = <span className='label label-default'>{t(`EmailStatus:${et.EmailStatus[email.status]}`)}</span>
        }

        if (email.spamreport) {
            return <>{label}<span className='label label-danger'>{t('EmailEventType:Spamreport')}</span></>
        } else {
            return label
        }
    }
};

const mapStateToProps = (state: ApplicationState) => {
    const venueId = state.venues.selectedVenueId;
    const venue = state.venues.venues.find(v => v.id === venueId);
    return {
        isLoading: state.emails.isLoading,
        emails: state.emails.emails,
        pageNumber: state.emails.pageNumber,
        maxPage: state.emails.maxPage,
        pageSize: state.emails.pageSize,
        timeFormat: venue ? venue.timeFormat : TimeFormat.TwentyFourHour,
        dateFormat: venue ? venue.dateFormat : DateFormat.DMY
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadEmails: bindActionCreators(EmailActions.actionCreators.loadEmails, dispatch),
    showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch)
});

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EmailsPage);

