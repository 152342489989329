
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as ct from './types'; 


export type CurrencyActions = ct.RequestCurrencies | ct.ReceiveCurrencies | ct.RequestCurrenciesError;

export const actionCreators = {
    loadCurrencies: () => ({ type: ct.CurrencyActionTypes.RequestCurrencies}),
    loadCurrenciesError: (err: api.ApiError) => ({ type: ct.CurrencyActionTypes.RequestCurrenciesError, error: err }),
}

interface IGetCurrenciesResponse {
    currencies: ct.Currency[];
}

//https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-after-catching-and-processing-an-error-with-emitting-somethi

const loadCurrencies = () => Observable.defer(() => api.getJson<IGetCurrenciesResponse>('api/v1/localization/currencies'))
    .map(response => ({ type: ct.CurrencyActionTypes.ReceivedCurrencies, currencies: response.currencies }));

export const loadCurrenciesEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        ct.CurrencyActionTypes.RequestCurrencies,
        action => loadCurrencies(),
        err => actionCreators.loadCurrenciesError(err));
