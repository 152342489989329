
import { Action } from 'redux';

import { BookingEmailScheduleConfigActionTypes, BookingEmailScheduleConfigState} from './types';
import { TaskDefinitionActions } from './actions'
import { createReducer } from '../../reducer';


const unloadedState: BookingEmailScheduleConfigState = { isLoading: false, configs: [], loadError: null, isSaving: false, saveComplete: false, saveError: null, validationErrors: [] };

export const reducer = (state: BookingEmailScheduleConfigState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: BookingEmailScheduleConfigState, incomingAction: Action): BookingEmailScheduleConfigState => {

    const action = incomingAction as TaskDefinitionActions;
    switch (action.type) {
        case BookingEmailScheduleConfigActionTypes.RequestBookingEmailScheduleConfigs:
            return {
                ...state,
                isLoading: true,
                configs: state.configs,
                loadError: null
            };
        case BookingEmailScheduleConfigActionTypes.ReceivedBookingEmailScheduleConfigs:
            const ordered = action.configs ? action.configs : [];
            ordered.sort((a, b) => a.name.localeCompare(b.name));

            return {
                ...state,
                isLoading: false,
                configs: ordered,
                loadError: action.error
            };
        case BookingEmailScheduleConfigActionTypes.EditBookingEmailScheduleConfig:
            return {
                ...state,
                isSaving: false,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case BookingEmailScheduleConfigActionTypes.SaveBookingEmailScheduleConfig:
            return {
                ...state,
                isSaving: true,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case BookingEmailScheduleConfigActionTypes.BookingEmailScheduleConfigSaved:
            return {
                ...state,
                isSaving: false,
                saveComplete: true,
                saveError: null,
                validationErrors: []
            };
        case BookingEmailScheduleConfigActionTypes.BookingEmailScheduleConfigSaveFailed:
            return {
                ...state,
                isSaving: false,
                saveError: action.error,
                saveComplete: false,
                validationErrors: action.error && action.error.validationErrors ? action.error.validationErrors : []
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

