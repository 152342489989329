
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {Subject, Observable } from 'rxjs';
import { AnonymousSubscription } from 'rxjs/Subscription';

interface ScrollViewerState {
    hasVScroll: boolean;
    hasHScroll: boolean;
    clientWidth: number;
    clientHeight: number;
    scrollWidth: number;
    scrollHeight: number;
    scrollTop: number;
    scrollLeft: number;
}

interface LocalProps {
    name: string;
    children: React.ReactNode;
    style?: React.CSSProperties;
    onClientWidthChanged?: (newClientWidth: number) => void;
    onClientHeightChanged?: (newClientHeight: number) => void;
    onScrollWidthChanged?: (newClientWidth: number) => void;
    onScrollHeightChanged?: (newClientHeight: number) => void;
    scrollObserver?: Observable<IScrollPosition>;
    scrollChanged?: Subject<IScrollPosition>;
}

type ScrollViewerProps = LocalProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export interface IScrollPosition {
    sender?: string;
    left?: number;
    top?: number;
}

export default class ScrollViewer extends React.Component<ScrollViewerProps, ScrollViewerState> {

    private scrolledItemRef = React.createRef<HTMLDivElement>();    
    private scrollSubscription?: AnonymousSubscription;

    state = { hasVScroll: false, hasHScroll: false, clientWidth: 0, clientHeight: 0, scrollWidth: 0, scrollHeight: 0, scrollTop: 0, scrollLeft: 0 };

    notifyValueChanged = (oldValue: number, newValue: number, callback?: (newVal: number) => void) => {
        if (oldValue !== newValue && callback) {
            callback(newValue);
        }
    }

    updateStats = () => {
        const ref = this.scrolledItemRef.current

        if (!ref) {
            return;
        }

        const { onClientWidthChanged, onClientHeightChanged, onScrollWidthChanged, onScrollHeightChanged, scrollChanged, name } = this.props;
        const { scrollTop, scrollLeft, scrollHeight, scrollWidth, clientHeight, clientWidth } = ref;
        const { hasVScroll: currentHasVScroll, hasHScroll: currentHasHScroll, clientWidth: currentClientWidth, clientHeight: currentClientHeight,
            scrollWidth: currentScrollWidth, scrollHeight: currentScrollHeight, scrollTop: currentScrollTop, scrollLeft: currentScrollLeft } = this.state;

        const hasVScroll = scrollHeight > clientHeight;
        const hasHScroll = scrollWidth > clientWidth;

        if (scrollChanged && (currentScrollTop !== scrollTop || currentScrollLeft !== scrollLeft)) {
            scrollChanged.next({ sender: name, left: scrollLeft, top: scrollTop });
        }

        if (currentHasVScroll !== hasVScroll || currentHasHScroll !== hasHScroll
            || currentClientWidth !== clientWidth || currentClientHeight !== clientHeight
            || currentScrollWidth !== scrollWidth || currentScrollHeight !== scrollHeight
            || currentScrollTop !== scrollTop || currentScrollLeft !== scrollLeft) {
            this.setState((prevState) => {
                this.notifyValueChanged(prevState.clientWidth, clientWidth, onClientWidthChanged);
                this.notifyValueChanged(prevState.clientHeight, clientHeight, onClientHeightChanged);
                this.notifyValueChanged(prevState.scrollWidth, scrollWidth, onScrollWidthChanged);
                this.notifyValueChanged(prevState.scrollHeight, scrollHeight, onScrollHeightChanged);

                return {
                    hasVScroll: hasVScroll,
                    hasHScroll: hasHScroll,
                    clientWidth: clientWidth,
                    clientHeight: clientHeight,
                    scrollWidth: scrollWidth,
                    scrollHeight: scrollHeight,
                    scrollTop: scrollTop,
                    scrollLeft: scrollLeft
                };
            });
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateStats);

        const { scrollObserver, name } = this.props;
        if (scrollObserver) {
            this.scrollSubscription = scrollObserver.filter(x => x.sender !== name).subscribe(this.updateScroll);
        }

        this.updateStats();
    }

    componentDidUpdate() {
        this.updateStats();
    }

    updateScroll = (scrollPos: IScrollPosition) => {
        const ref = this.scrolledItemRef.current
        if (ref) {
            const scrollable = ReactDOM.findDOMNode(ref) as HTMLDivElement;
            scrollable.scrollTo(scrollPos.left || 0, scrollPos.top || 0);
        }

    }

    componentWillUnmount() {
        if (this.scrollSubscription) {
            this.scrollSubscription.unsubscribe();
        }

        window.removeEventListener('resize', this.updateStats);
    }

    handleScroll = () => {
        this.updateStats();
    }

    render() {
        // need to specify non-standard properties to prevent errors
        const { children, onClientWidthChanged, onClientHeightChanged, onScrollWidthChanged, onScrollHeightChanged, scrollChanged, scrollObserver, ...otherProps } = this.props;

        return <div ref={this.scrolledItemRef} {...otherProps} onScroll={this.handleScroll}>{children}</div>;
    }
}