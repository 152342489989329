
import { Action } from 'redux';
import { LocationChangeAction } from 'react-router-redux';

import { ActivityFormatGroupActionTypes, ActivityFormatGroupsState } from './types';
import { ActivityFormatGroupActions } from './actions'
import { createReducer } from '../../reducer';


const unloadedState: ActivityFormatGroupsState = { isActive: false, isLoading: false, activityFormatGroups: [], loadError: null, isSaving: false, saveComplete: false, saveError: null, validationErrors: [] };

export const reducer = (state: ActivityFormatGroupsState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: ActivityFormatGroupsState, incomingAction: Action): ActivityFormatGroupsState => {

    if (incomingAction.type === '@@router/LOCATION_CHANGE') {
        const locationChangeAction = incomingAction as LocationChangeAction;
        if (locationChangeAction) {
            return { ...state, isActive: locationChangeAction.payload.pathname === '/activityFormatGroups' };
        }
    }

    const action = incomingAction as ActivityFormatGroupActions;
    switch (action.type) {
        case ActivityFormatGroupActionTypes.RequestActivityFormatGroups:
            return {
                ...state,
                isLoading: true,
                activityFormatGroups: state.activityFormatGroups,
                loadError: null
            };
        case ActivityFormatGroupActionTypes.ReceivedActivityFormatGroups:
            return {
                ...state,
                isLoading: false,
                activityFormatGroups: action.activityFormatGroups,
                loadError: action.error
            };
        case ActivityFormatGroupActionTypes.EditActivityFormatGroup:
            return {
                ...state,
                isSaving: false,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case ActivityFormatGroupActionTypes.SaveActivityFormatGroup:
            return {
                ...state,
                isSaving: true,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case ActivityFormatGroupActionTypes.ActivityFormatGroupSaved:
            return {
                ...state,
                isSaving: false,
                saveComplete: true,
                saveError: null,
                validationErrors: []
            };
        case ActivityFormatGroupActionTypes.ActivityFormatGroupSaveFailed:
            return {
                ...state,
                isSaving: false,
                saveError: action.error,
                saveComplete: false,
                validationErrors: action.error && action.error.validationErrors ? action.error.validationErrors : []
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

