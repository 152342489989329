
import * as React from 'react';
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ApplicationState } from '../../../store';
import * as LoginActions from '../../../store/pages/login/actions';

import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import * as api from '../../../store/apiClient';

import { Bill, formatItemName, IUpdatePaymentResponse } from '../../../store/pages/pointOfSale/types';
import { EventBooking, formatCustomerName } from '../../../store/pages/diary/types';
import { BillPayment, deserializeBill } from '../../../store/pages/pointOfSale/types';

import { ValidationError } from '../../../store/global/types';
import { clickHandler, isNullOrEmpty } from '../../../utils/util';
import ConfirmVoidItem from '../pointOfSale/confirmVoidItem';
import { CustomerCategory } from '../../../store/pages/customerCategories/types';
import { DateFormat, TimeFormat, Venue } from '../../../store/pages/venues/types';
import PointOfSalePanel from '../pointOfSale/pointOfSalePanel';
import { PaymentMethod } from '../../../store/pages/paymentMethods/types';
import { ProductCategory } from '../../../store/pages/productCategories/types';

interface LocalProps {
    venue: Venue;
    booking: EventBooking;
    bill: Bill;
    customerCategories: CustomerCategory[];
    paymentUpdated: (bill: Bill, closeOverlay: boolean) => void;
    cancel: () => void;
    confirm: (bookingId:string, reason: string) => void;
}

interface MappedReduxState {
    paymentMethods: PaymentMethod[];
    productCategories: ProductCategory[];
}

interface Actions { logout: () => void; }

type CancelBookingProps = MappedReduxState & Actions & LocalProps;

interface CancelBookingState {
    bill: Bill;
    reason: ct.FormValue<string>;
    confirmVoid: boolean;
    voidPayment: BillPayment | null;
    showRefund: boolean;
    saving: boolean;
    saveError: api.ApiError | null;
    saveValidationErrors: ValidationError[];
}

class CancelBooking extends React.Component<CancelBookingProps, CancelBookingState> {
    
    static contextTypes = {
        t: PropTypes.func
    }

    constructor(props: CancelBookingProps) {
        super(props);

        this.state = { reason: this.validateReason(''), bill: props.bill, confirmVoid: false, voidPayment: null, showRefund: false, saving: false, saveError: null, saveValidationErrors: [] }
    }

    validateReason = (val: string) => v.validate(val, 'reason', [v.required], []);

    confirm = () => {
        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {
            const { reason } = this.state;
            const { booking, confirm} = this.props;
            confirm(booking.id, reason.value);
        }
    }

    voidPayment = (payment: BillPayment) => this.setState({ confirmVoid: true, voidPayment: payment})

    closeVoidPayment = () => this.setState({ confirmVoid: false, voidPayment: null })

    confirmVoidPayment = (payment: BillPayment, reason: string) => {
        const { bill } = this.state;
        const { venue } = this.props;

        this.setState({ saving: true });

        api.putWithAuth(`api/v1/bill/${bill.id}/payment/${payment.id}/void`, { venueId: venue.id, voidReason: reason }, () => ({ /* TODO: Handle auth error*/ }))
            .subscribe(response => {
                const mpr = response.response as IUpdatePaymentResponse;
                if (mpr) {
                    const mappedBill = deserializeBill(mpr.bill);
                    this.paymentVoided(mappedBill);
                }
            }, (err: api.ApiError) => {
                this.setState({ saving: false, saveError: err, saveValidationErrors: err.validationErrors });
            });
    }

    paymentVoided = (bill: Bill) => {
        this.props.paymentUpdated(bill, false);
        this.setState({ bill: bill });
        this.closeVoidPayment();
    }

    refund = () => this.setState({ showRefund: true })

    render() {
        const { t } = this.context;
        const { booking, customerCategories, paymentMethods, productCategories, venue, cancel, logout, paymentUpdated } = this.props;
        const { reason, bill, showRefund } = this.state;

        if (!bill) return [];

        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;
        const sortedItems = bill.items.sort((a, b) => (a.reservationStartTime ? a.reservationStartTime.getUTCMilliseconds() : 0) - (b.reservationStartTime ? b.reservationStartTime.getUTCMilliseconds() : 0));
        const first = sortedItems[0];
        const last = sortedItems[Math.max(0, sortedItems.length - 1)];
        const multiDayEvent = sortedItems.length > 1 && first.reservationStartTime && last.reservationStartTime ? first.reservationStartTime.daysDifference(last.reservationStartTime) !== 0 : false;

        const items = sortedItems.map(i => {
            return <div key={i.key} className='row'>
                <div className='col-xs-2 text-right'>{i.quantity}</div>
                <div className='col-xs-8'>{formatItemName(i, customerCategories, multiDayEvent, timeFormat, dateFormat, t)}</div>
            </div>
        });

        const payments = bill && bill.payments ? bill.payments.filter(p => p.paid && !p.void && !p.deleted).map(p => {
            return <div key={p.key} className='row'>
                <div className='col-xs-4 text-right'>{p.payentTakenDateTime ? p.payentTakenDateTime.toAbbrDateString(venue.dateFormat, t) : ''}</div>
                <div className='col-xs-4'>{[p.paymentMethodName, p.description].filter(x => x && !isNullOrEmpty(x)).join(' - ')}</div>
                <div className='col-xs-2 text-right'>{`${t('Global:currencySymbol')}${p.amount.toFixed(2)}`}</div>
                <div className='col-xs-2'><button className='btn btn-danger btn-sm pull-right' style={({ margin: '2px 0' })} onClick={e => clickHandler(e, () => this.voidPayment(p))}>{t('Global:void')}</button></div>
            </div>
        }) : [];

        const refunds = bill && bill.refunds
            ? bill.refunds.filter(r => !r.void).map(r => <div key={r.id} className='row'>
                <div className='col-xs-4 text-right'>{r.voidedDateTime ? r.voidedDateTime.toAbbrDateString(venue.dateFormat, t) : ''}</div>
                <div className='col-xs-4'>{[r.paymentMethodName, r.reason].filter(x => x && !isNullOrEmpty(x)).join(' - ')}</div>
                <div className='col-xs-2 text-right'>{`${t('Global:currencySymbol')}${r.amount.toFixed(2)}`}</div>
                <div className='col-xs-2'></div>
            </div>)
            : [];

        let totalPaid = null;
        if (bill) {
            totalPaid = <div className='row'>
                <div className='col-xs-4 text-right'></div>
                <div className='col-xs-4 text-right'>{t('CancelBooking:totalPaid')}</div>
                <div className='col-xs-2 text-right'>{`${t('Global:currencySymbol')}${bill.paidAmount.toFixed(2)}`}</div>
                <div className='col-xs-2'></div>
            </div>
        }

        if (showRefund) {
            return <PointOfSalePanel
                venue={venue}
                showRefund={true}
                products={[]}
                eventProducts={[]}
                activityFormats={[]}
                productCategories={productCategories}
                paymentMethods={paymentMethods}
                customerCategories={customerCategories}
                fees={[]}
                taxRates={[]}
                promotions={[]}
                booking={null}
                billInfo={bill.id}
                paymentAmount={0}
                customerId={bill.customerId ? bill.customerId : undefined}
                posSessionComplete={b => this.setState({ showRefund: false, bill: b }, () => paymentUpdated(b, false))}
                vouchers={[]} 
                membershipTypes={[]}
                logout={logout} />
        }

        return (
            <div>
                <div style={({ maxWidth: '600px', margin: '0 auto' })}>
                    <h3 className='text-center'>{t('CancelBooking:heading')}</h3>
                    <p />

                    <div className='text-center'>
                        <h4>{formatCustomerName(booking.customer, t('Global:unconfirmedWebBooking'))}</h4>
                    </div>

                    {items}

                    <p />
                    <h4 className='text-center'>{t('customerDetails:payments')}</h4>
                    {payments}
                    {refunds.length > 0
                        ? <>
                        <p />
                        <h4 className='text-center'>{t('customerDetails:refunds')}</h4>
                            {refunds}
                        </>
                        : null}
                    <p />
                    {totalPaid}

                    <p />
                    <div className='row'>
                        <div className='col-xs-8'></div>
                        <div className='col-xs-4'><button className='btn btn-warning pull-right' onClick={e => clickHandler(e, this.refund)}>{t('PointOfSale:refund')}</button></div>
                    </div>
                    <p />
                    <div className='row'>
                        <div className='col-xs-12'>
                            <ct.TextBox id='reason' labelKey='CancelBooking:cancellationReason' placeholderKey='CancelBooking:cancellationReason' value={reason} callback={val => this.setState({ reason: this.validateReason(val) })} />
                        </div>
                    </div>

                    <p />
                    <div className='btn-toolbar' style={({ marginTop: '20px' })}>
                        <button className='btn btn-primary' onClick={e => clickHandler(e, this.confirm)}>{t('Global:confirmCancel')}</button>
                        <button className='btn btn-basic' onClick={e => clickHandler(e, cancel)}>{t('Global:cancel')}</button>
                    </div>
                </div>
                {this.renderVoidOverlay()}
            </div>
        );
    }

    renderVoidOverlay = () => {
        const { confirmVoid, voidPayment, bill, saveError, saveValidationErrors, saving } = this.state;

        if (confirmVoid && voidPayment && bill) {
            return <ConfirmVoidItem
                headerKey='PointOfSale:confirmVoidPayment'
                confirmButtonKey='PointOfSale:yesVoidPayment'
                confirm={reason => this.confirmVoidPayment(voidPayment, reason)}
                close={this.closeVoidPayment}
                saveError={saveError}
                saveValidationErrors={saveValidationErrors}
                saving={saving} />
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state: ApplicationState) => {
    const venueId = state.venues.selectedVenueId;

    return {
        paymentMethods: state.paymentMethods.paymentMethods.filter(x => x.venueId === venueId && !x.archived),
        productCategories: state.productCategories.productCategories.filter(x => x.venueId === venueId && !x.archived)
    }
};


const mapDispatchToProps = (dispatch: Dispatch) => ({ logout: bindActionCreators(LoginActions.actionCreators.logout, dispatch) });


// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(CancelBooking);
