
import { Action } from 'redux';

import { TermsAndConditionsActionTypes, TermsAndConditionsState } from './types';
import { TermsAndConditionsActions } from './actions'
import { createReducer } from '../../reducer';
import { mapUtcDate } from '../../../utils/util';

const unloadedState: TermsAndConditionsState = {
    termsAndConditions: [],
    isLoading: false,
    loadError: null,
    registrationTerms: [],
    isLoadingRegistrationTerms: false,
    bookingTerms: [],
    isLoadingBookingTerms: false,
    isSavingRegistrationTerms: false,
    registrationTermsSaveError: null,
    isSavingBookingTerms: false,
    bookingTermsSaveError: null,
    isLoadingRegistrationVideos: false,
    registrationVideos: [],
    webShopPurchaseTerms: [],
    isLoadingWebShopPurchaseTerms: false,
    isSavingWebShopPurchaseTerms: false,
    webShopPurchaseTermsSaveError: null,
}

export const reducer = (state: TermsAndConditionsState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: TermsAndConditionsState, incomingAction: Action): TermsAndConditionsState => {

    const action = incomingAction as TermsAndConditionsActions;

    switch (action.type) {
        case TermsAndConditionsActionTypes.LoadTermsAndConditions:
            return {
                ...state,
                isLoading: true,
                loadError: null
            };
        case TermsAndConditionsActionTypes.ReceivedTermsAndConditions:
                return {
                ...state,
                termsAndConditions: state.termsAndConditions.merge(action.termsAndConditions || [], (a, b) => a.id === b.id),
                isLoading: false,
                loadError: action.error
            };
        case TermsAndConditionsActionTypes.LoadRegistrationTerms:
            return {
                ...state,
                isLoadingRegistrationTerms: true,
                loadError: null
            };
         case TermsAndConditionsActionTypes.ReceivedRegistrationTerms:
            return {
                ...state,
                registrationTerms: action.registrationTerms.map(t => ({ ...t, registrationVideoCreated: mapUtcDate(t.registrationVideoCreated)})),
                isLoadingRegistrationTerms: false,
                loadError: action.error
            };
        case TermsAndConditionsActionTypes.LoadBookingTerms:
            return {
                ...state,
                isLoadingBookingTerms: true,
                loadError: null
            };
        case TermsAndConditionsActionTypes.ReceivedBookingTerms:
            return {
                ...state,
                bookingTerms: action.bookingTerms,
                isLoadingBookingTerms: false,
                loadError: action.error
            };
        case TermsAndConditionsActionTypes.SaveRegistrationTerms:
            return {
                ...state,
                isSavingRegistrationTerms: true,
                registrationTermsSaveError: null
            };
        case TermsAndConditionsActionTypes.RegistrationTermsSaved:
            return {
                ...state,
                isSavingRegistrationTerms: false,
                registrationTermsSaveError: null
            };
        case TermsAndConditionsActionTypes.RegistrationTermsSaveFailed:
            return {
                ...state,
                isSavingRegistrationTerms: false,
                registrationTermsSaveError: action.error
            };
        case TermsAndConditionsActionTypes.SaveBookingTerms:
            return {
                ...state,
                isSavingBookingTerms: true,
                bookingTermsSaveError: null
            };
        case TermsAndConditionsActionTypes.BookingTermsSaved:
            return {
                ...state,
                isSavingBookingTerms: false,
                bookingTermsSaveError: null
            };
        case TermsAndConditionsActionTypes.BookingTermsSaveFailed:
            return {
                ...state,
                isSavingBookingTerms: false,
                bookingTermsSaveError: action.error
            };
        case TermsAndConditionsActionTypes.LoadRegistrationVideos:
            return {
                ...state,
                isLoadingRegistrationVideos: true,
                loadError: null
            };
        case TermsAndConditionsActionTypes.ReceivedRegistrationVideos:
            return {
                ...state,
                registrationVideos: action.registrationVideos.map(v => ({ ...v, createDateTime: new Date(v.createDateTime) })),
                isLoadingRegistrationVideos: false,
                loadError: action.error
            };

        case TermsAndConditionsActionTypes.LoadWebShopPurchaseTerms:
            return {
                ...state,
                isLoadingWebShopPurchaseTerms: true,
                loadError: null
            };
        case TermsAndConditionsActionTypes.ReceivedWebShopPurchaseTerms:
            return {
                ...state,
                webShopPurchaseTerms: action.webShopPurchaseTerms,
                isLoadingWebShopPurchaseTerms: false,
                loadError: action.error
            };

        case TermsAndConditionsActionTypes.SaveWebShopPurchaseTerms:
            return {
                ...state,
                isSavingWebShopPurchaseTerms: true,
                webShopPurchaseTermsSaveError: null
            };

        case TermsAndConditionsActionTypes.WebShopPurchaseTermsSaved:
            return {
                ...state,
                isSavingWebShopPurchaseTerms: false,
                webShopPurchaseTermsSaveError: null
            };

        case TermsAndConditionsActionTypes.WebShopPurchaseTermsSaveFailed:
            return {
                ...state,
                isSavingWebShopPurchaseTerms: false,
                webShopPurchaseTermsSaveError: action.error
            };

        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

