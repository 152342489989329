
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import * as api from '../../../store/apiClient';

import { Bill, BillFee, calculateFeeAmount } from '../../../store/pages/pointOfSale/types';
import { Fee } from '../../../store/pages/fees/types';
import { TaxRate } from '../../../store/pages/taxRates/types';
import { clickHandler, round } from '../../../utils/util';
import ApiError from '../../global/apiError';

interface AddBillFeeProps {
    bill: Bill;
    fees: Fee[];
    taxRates: TaxRate[];
    addFee: (billId: string, fee: BillFee, callback: (success: boolean, error: api.ApiError | null) => void) => void;
    close: () => void;
}

interface AddBillFeeState {
    billTotalExFees: number;

    description: ct.FormValue<string>;
    feeId: ct.FormValue<string>;
    amount: ct.FormValue<number>;
    saving: boolean;
    error: string | null;
    apiError: api.ApiError | null;
}

export default class AddBillFee extends React.Component<AddBillFeeProps, AddBillFeeState> {

    constructor(props: AddBillFeeProps) {
        super(props);

        const { bill } = props;

        const feeAmount = bill.fees && bill.fees.length > 0 ? bill.fees.reduce((ttl, f) => ttl + f.amount, 0) : 0;

        this.state = {
            billTotalExFees: bill.totalAmount - feeAmount,
            description: this.validateDescription(''),
            feeId: this.validateFeeId(''),
            amount: this.validateAmount(0),
            saving: false,
            error: null,
            apiError: null
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    addFee = () => {
        const { fees, taxRates } = this.props;
        const { amount, feeId, description } = this.state;

        const fee = fees.find(f => f.id === feeId.value);
        if (!fee) {
            this.setState({ error: 'PointOfSale:noFeeSelected' });
        } else {
            var tr = taxRates.find(tr => tr.id === fee.taxRateId);
            if (!tr) {
                this.setState({ error: 'PointOfSale:unknownTaxRate' });
            } else {

                this.setState({ saving: true, error: null, apiError: null });

                this.props.addFee(this.props.bill.id, {
                    id: '',
                    feeId: feeId.value,
                    amount: amount.value,
                    description: description.value,
                    feeType: fee.feeType,
                    maxAmount: fee.maxAmount,
                    percentage: fee.percentage,
                    taxRateId: fee.taxRateId,
                    taxRate: tr.rate,
                    deleted: false
                }, this.onFeeSaved);
            }
        }
    }

    onFeeSaved = (success: boolean, error: api.ApiError | null) => {
        this.setState({ saving: false, error: null, apiError: error });
        if (success) {
            this.props.close();
        }
    }

    validateDescription = (val: string) => v.validate(val, 'description', [v.required], []);
    validateFeeId = (val: string) => v.validate(val, 'feeId', [], []);
    validateAmount = (val: number) => v.validate(val, 'amount', [], []);

    onFeeChanged = (val: string) => {
        const { bill, fees } = this.props;
        var fee = fees.find(f => f.id === val);
        this.setState(s => ({
            feeId: this.validateFeeId(fee ? fee.id : ''),
            amount: fee ? this.validateAmount(calculateFeeAmount(bill, fee)) : s.amount,
            description: fee ? this.validateDescription(fee.name) : s.description,
        }));
    }

    render() {
        const { fees,  } = this.props;
        const { description, feeId, amount, billTotalExFees, saving, error, apiError } = this.state;
        const { t } = this.context;

        const feeTypeOptions = [{ key: '', name: t('PointOfSale:selectFee') }].concat(fees.filter(f => !f.archived).map(f => ({ key: f.id.toString(), name: f.name })));

        return (
            <div>
                <h2>{ t('PointOfSale:addFee')}</h2>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div>
                            <label>{t('PointOfSale:billTotalExFee')}: {t('Global:currencySymbol')}{billTotalExFees.toFixed(2)}</label>
                        </div>

                        <ct.Select id='fee' labelKey='PointOfSale:fee' value={feeId} callback={this.onFeeChanged} options={feeTypeOptions} />

                        <ct.TextBox id='description' labelKey='Global:description' placeholderKey='Global:description' value={description} callback={val => this.setState({ description: this.validateDescription(val) })} />

                        <ct.NumberBox id='amount' labelKey='PointOfSale:feeAmount' placeholderKey='' value={amount} callback={val => this.setState({ amount: this.validateAmount(round(val || 0, 2)) })} min='0' />

                    </div>


                    <div className='col-xs-12'>
                        {error ? <div className='alert alert-danger'>{error}</div> : null}
                        {apiError ? <ApiError error={apiError} /> : null}
                    </div>
                </div>

                <div className='row button-panel'>
                    <button onClick={e => clickHandler(e, this.addFee)} className='btn btn-primary' disabled={saving}>{t('Global:add')}</button>
                    <button onClick={e => clickHandler(e, this.props.close)} className='btn btn-basic' disabled={saving}>{t('Global:cancel')}</button>
                </div>
            </div>
        );
    }
}