
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { ITranslationContext } from '../../../translations';
import { formatBgColour } from '../../../utils/util';
import { Time } from '../../../store/global/types';
import { TimeFormat } from '../../../store/pages/venues/types';

export interface OpeningTimes {
    closed: boolean
    open: Time
    close: Time
}

interface DiaryResourceHeaderProps {
    colour: string;
    name: string;
    width: number;
    opeiningTimes: OpeningTimes | null;
    timeFormat: TimeFormat;
}

const DiaryResourceHeader: React.SFC<DiaryResourceHeaderProps> = (props, ctx: ITranslationContext) => {
    const { name, opeiningTimes, width, colour, timeFormat } = props;
    const { t } = ctx;

    const resStyle = { width: `calc(${width}% - 10px`, backgroundColor: formatBgColour(colour, 1) };

    return (
        <div className='cal-resource-header' style={resStyle}>
            <h3>{name}</h3>
            <div className='cal-resource-header-times'>{opeiningTimes ? opeiningTimes.closed ? <>{t('Global:closed')}</> : <span>{opeiningTimes.open.toShortTimeString(timeFormat)} - {opeiningTimes.close.toShortTimeString(timeFormat)}</span> : null }</div>
       </div>
    );
};

DiaryResourceHeader.contextTypes = {
    t: PropTypes.func
}

export default DiaryResourceHeader;
