
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import { PaymentMethod } from '../../../store/pages/paymentMethods/types';
import { AdditionalPaymentInfo } from '../../../store/pages/pointOfSale/types';
import { clickHandler, isNullOrEmpty } from '../../../utils/util';


interface PaymentExtraInfoProps {
    paymentMethod: PaymentMethod;
    amount: number;
    completePayment: (extraValues: AdditionalPaymentInfo[]) => void;
    cancel: () => void;
}

interface PaymentInfo {
    id: string;
    name: string;
    required: boolean;
    value: ct.FormValue<string>;
}

interface PaymentExtraInfoState {
    info: PaymentInfo[];
}

export default class PaymentExtraInfo extends React.Component<PaymentExtraInfoProps, PaymentExtraInfoState> {

    constructor(props: PaymentExtraInfoProps) {
        super(props);

        this.state = {
            info: props.paymentMethod.extraFields.map(f => ({ id: f.id, name: f.name, required: f.required, value: v.validate('', f.name, f.required ? [v.required] : [], []) }))
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    setItemValue = (id: string, controlId: string, value: string) => {
        this.setState(prev => ({ info: prev.info.map(i => i.id === id ? { ...i, value: v.validate(value, controlId, i.required ? [v.required] : []) } : i) }));
    }

    completePayment = () => {
        const { info } = this.state;

        if (info.filter(f => !f.value.isValid).length > 0) {
            // show error
        } else {
            //******** TODO: Pass filled in fields here, send to server and save
            this.props.completePayment(info.filter(i => !isNullOrEmpty(i.value.value)).map(i => ({ paymentInformationId: i.id, text: i.value.value, name: i.name })));
        }
    }

    render() {
        const { t } = this.context;
        const { info } = this.state;

        return (
            <div style={({ maxWidth: '480px' })}>
                <h3>{t('PointOfSale:paymentInfo')}</h3>

                <form className='data-form' onSubmit={e => e.preventDefault()} autoComplete='off'>

                    {info.map(i => (
                        <div className='row' key={i.id}>
                            <div className='col-sm-12'>
                                <ct.TextBox id={i.name} labelKey={i.name} placeholderKey={i.name} value={i.value} callback={val => this.setItemValue(i.id, i.name, val)} />
                            </div>
                        </div>
                    ))}

                    <div className='row'>
                        <div className='col-sm-12 button-panel'>
                            <button className='btn btn-primary' onClick={e => clickHandler(e, this.completePayment)}>{t('PointOfSale:makePayment')}</button>
                            <button onClick={e => clickHandler(e, this.props.cancel)} className='btn btn-basic'>{t('Global:cancel')}</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

}