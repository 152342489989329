
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as rt from './types';
import * as bt from '../pointOfSale/types';
import { mapLocalDateTime, parseLocalDateTime } from '../../../utils/util';
import { MarketingPreference } from '../customer/types';
import { IStore } from '../..';

export type ReceptionActions = rt.SwitchDate | rt.LoadRegistrations | rt.ReceiveRegistrations | rt.LoadEvents | rt.ReceiveEvents | rt.AddRegistrationsToBooking
    | rt.AddRegistrationsToBookingSuccess | rt.AddRegistrationsToBookingFailed | rt.RegistrationsChanged | rt.CheckInCustomers | rt.CheckInCustomersSuccess | rt.CheckInCustomersFailed | rt.UndoCheckedInCustomers
    | rt.UndoCheckedInCustomersSuccess | rt.UndoCheckedInCustomersFailed | rt.CustomerCheckedIn | rt.Unregister | rt.UnregisterFailed | rt.UnregisterSuccess | rt.UpdateRegistration | rt.UpdateRegistrationFailed
    | rt.UpdateRegistrationSuccess | rt.ExpandEvent | rt.CollapseEvent | rt.SetReceptionView;

export const actionCreators = {
    switchDate: (date: Date) => ({ type: rt.ReceptionActionTypes.SwitchDate, date: date }),

    loadRegistrations: (venueId: string, date: Date) => ({ type: rt.ReceptionActionTypes.RequestRegistrations, venueId: venueId, date: date }),
    loadRegistrationsComplete: (registrations: rt.Registration[], err: api.ApiError | null) => ({ type: rt.ReceptionActionTypes.ReceivedRegistrations, registrations: registrations, error: err }),

    loadEvents: (venueId: string, date: Date) => ({ type: rt.ReceptionActionTypes.RequestEvents, venueId: venueId, date: date }),
    loadEventsComplete: (events: rt.RegistrationEvent[], err: api.ApiError | null) => ({ type: rt.ReceptionActionTypes.ReceivedEvents, events: events, error: err }),

    addRegistrationsToBooking: (bookingId: string, registrationIds: string[]) => ({ type: rt.ReceptionActionTypes.AddRegistrationsToBooking, bookingId: bookingId, registrationIds: registrationIds }),
    addRegistrationsToBookingSuccess: () => ({ type: rt.ReceptionActionTypes.AddRegistrationsToBookingSuccess }),
    addRegistrationsToBookingFailed: (error: api.ApiError) => ({ type: rt.ReceptionActionTypes.AddRegistrationsToBookingFailed, error: error }),
    checkInCustomers: (venueId: string, registrationIds: string[]) => ({ type: rt.ReceptionActionTypes.CheckInCustomers, venueId:venueId, registrationIds: registrationIds }),
    checkInCustomersSuccess: () => ({ type: rt.ReceptionActionTypes.CheckInCustomersSuccess }),
    checkInCustomersFailed: (error: api.ApiError) => ({ type: rt.ReceptionActionTypes.CheckInCustomersFailed, error: error }),
    undoCheckedInCustomers: (venueId: string, registrationIds: string[]) => ({ type: rt.ReceptionActionTypes.UndoCheckedInCustomers, venueId: venueId, registrationIds: registrationIds }),
    undoCheckedInCustomersSuccess: () => ({ type: rt.ReceptionActionTypes.UndoCheckedInCustomersSuccess }),
    undoCheckedInCustomersFailed: (error: api.ApiError) => ({ type: rt.ReceptionActionTypes.UndoCheckedInCustomersFailed, error: error }),
    unregister: (venueId: string, bookingId: string, registrationId: string) => ({ type: rt.ReceptionActionTypes.Unregister, venueId: venueId, bookingId: bookingId, registrationId: registrationId }),
    unregisterSuccess: () => ({ type: rt.ReceptionActionTypes.UnregisterSuccess }),
    unregisterFailed: (error: api.ApiError) => ({ type: rt.ReceptionActionTypes.UnregisterFailed, error: error }),
    registrationChanged: (venueId: string) => ({ type: rt.ReceptionActionTypes.RegistrationsChanged, venueId: venueId }),
    customerCheckedIn: (venueId: string, bookingDate: Date | null) => ({ type: rt.ReceptionActionTypes.CustomerCheckedIn, venueId: venueId, bookingDate: bookingDate }),
    eventsChanged: (venueId: string, bookingDate: Date | null) => ({ type: rt.ReceptionActionTypes.EventsChanged, venueId: venueId, bookingDate: bookingDate }),
    updateRegistration: (venueId: string, registrationId: string, bookingId: string, dateOfBirth: Date, nickname: string, publicResultsConsent: boolean, marketingPreference: MarketingPreference, resultsPreference: MarketingPreference, eventSelections: rt.RegistrationEventSelection[], customFields: rt.RegistrationCustomFieldValue[], categoryCustomFields: rt.CustomerCategoryCustomFieldValue[]) => ({ type: rt.ReceptionActionTypes.UpdateRegistration, venueId: venueId, registrationId: registrationId, bookingId: bookingId, dateOfBirth: dateOfBirth, nickname: nickname, publicResultsConsent: publicResultsConsent, marketingPreference: marketingPreference, resultsPreference: resultsPreference, eventSelections: eventSelections, customFields: customFields, categoryCustomFields: categoryCustomFields }),
    updateRegistrationSuccess: () => ({ type: rt.ReceptionActionTypes.UndoCheckedInCustomersSuccess }),
    updateRegistrationFailed: (error: api.ApiError) => ({ type: rt.ReceptionActionTypes.UndoCheckedInCustomersFailed, error: error }),
    expand: (eventId: string) => ({ type: rt.ReceptionActionTypes.ExpandEvent, eventId: eventId}),
    collapse: (eventId: string) => ({ type: rt.ReceptionActionTypes.CollapseEvent, eventId: eventId }),
    setReceptionView: (view: rt.ReceptionView) => {
        localStorage.setItem(receptionViewStorageKey, view.toString());
        return { type: rt.ReceptionActionTypes.SetReceptionView, view: view };
    },
}

interface IGetRegistrationsResponse {
    registrations: rt.Registration[];
}

interface IGetEventBookingsResponse {
    events: rt.RegistrationEvent[];
}

const receptionViewStorageKey = 'receptionView'

export const getReceptionView = () => {
    const defaultView = rt.ReceptionView.Events;
    const val = localStorage.getItem(receptionViewStorageKey);
    const view = val ? parseInt(val) : defaultView;
    return isNaN(view) ? defaultView : view;
}

const loadRegistrations = (venueId: string, date: Date) => Observable.defer(() => api.getJson<IGetRegistrationsResponse>(`api/v1/registration?venueId=${venueId}&dt=${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`))
    .map(response => actionCreators.loadRegistrationsComplete(response.registrations.map(r => {
        const { registrationTime, termsLastAgreed, ...reg } = r;
        const date = parseLocalDateTime(registrationTime);
        const termsAgreed = parseLocalDateTime(termsLastAgreed);
        return { ...reg, registrationTime: date, termsLastAgreed: termsAgreed, registeredForEvents: reg.registeredForEvents.map(re => ({ ...re, startTime: mapLocalDateTime(re.startTime) })) };
    }), null));

const loadEvents = (venueId: string, date: Date) => Observable.defer(() => api.getJson<IGetEventBookingsResponse>(`api/v1/venue/${venueId}/eventBookings?from=${date.toYMDDateString()}&to=${date.toYMDDateString()}`))
    .map(response => {
        return actionCreators.loadEventsComplete(response.events, null)
    });

export const switchDateEpic = (action$: ActionsObservable<any>, store: any) =>
    action$.ofType(rt.ReceptionActionTypes.SwitchDate)
        .filter(() => store.value.venues.selectedVenueId !== null)
        .mergeMap(action => Observable.of(actionCreators.loadRegistrations(store.value.venues.selectedVenueId, action.date), actionCreators.loadEvents(store.value.venues.selectedVenueId, action.date)));

export const loadRegistrationsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        rt.ReceptionActionTypes.RequestRegistrations,
        action => loadRegistrations(action.venueId, action.date),
        err => actionCreators.loadRegistrationsComplete([], err));

export const loadEventsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        rt.ReceptionActionTypes.RequestEvents,
        action => loadEvents(action.venueId, action.date),
        err => actionCreators.loadEventsComplete([], err));

export const addRegistrationsToBookingEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        rt.ReceptionActionTypes.AddRegistrationsToBooking,
        action => api.post(`api/v1/booking/${action.bookingId}/registration`, ({ registrationIds: action.registrationIds })).map(response => actionCreators.addRegistrationsToBookingSuccess()),
        err => actionCreators.addRegistrationsToBookingFailed(err));

export const checkInCustomersEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        rt.ReceptionActionTypes.CheckInCustomers,
        action => api.put(`api/v1/registration/checkin`, ({ venueId: action.venueId, registrationIds: action.registrationIds }))
            .mergeMap(_ => Observable.of(actionCreators.checkInCustomersSuccess(), actionCreators.customerCheckedIn(action.venueId, null))),
        err => actionCreators.checkInCustomersFailed(err));

export const undoCheckedInCustomersEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        rt.ReceptionActionTypes.UndoCheckedInCustomers,
        action => api.put(`api/v1/registration/undoCheckin`, ({ venueId: action.venueId, registrationIds: action.registrationIds }))
            .mergeMap(_ => Observable.of(actionCreators.undoCheckedInCustomersSuccess(), actionCreators.customerCheckedIn(action.venueId, null))),
        err => actionCreators.undoCheckedInCustomersFailed(err));

export const unregisterEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        rt.ReceptionActionTypes.Unregister,
        action => api.put(`api/v1/registration/unregister`, ({ venueId: action.venueId, bookingId: action.bookingId, registrationId: action.registrationId }))
            .mergeMap(_ => Observable.of(actionCreators.unregisterSuccess(), actionCreators.registrationChanged(action.venueId))),
        err => actionCreators.unregisterFailed(err));

export const updateRegistrationEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        rt.ReceptionActionTypes.UpdateRegistration,
        action => api.put(`api/v1/registration`, ({
            venueId: action.venueId,
            registrationId: action.registrationId,
            bookingId: action.bookingId,
            birthDay: action.dateOfBirth.getDate(),
            birthMonth: action.dateOfBirth.getMonth()+1,
            birthYear: action.dateOfBirth.getFullYear(),
            nickname: action.nickname,
            publicResultsConsent: action.publicResultsConsent,
            marketingPreference: action.marketingPreference,
            resultsPreference: action.resultsPreference,
            registeredForEvents: action.eventSelections.map((ev: rt.RegistrationEventSelection) => ({ eventId: ev.eventId, removed: ev.removed, customerCategories: ev.customerCategories })),
            customFields: action.customFields,
            categoryCustomFields: action.categoryCustomFields
        }))
            .mergeMap(_ => Observable.of(actionCreators.updateRegistrationSuccess(), actionCreators.registrationChanged(action.venueId))),
        err => actionCreators.updateRegistrationFailed(err));

export const registrationsChangedEpic = (action$: ActionsObservable<any>, store: any) =>
    action$.ofType(rt.ReceptionActionTypes.RegistrationsChanged)
        .filter(action => {
            const selecteVenueId = store.value.venues.selectedVenueId;
            return selecteVenueId !== null && selecteVenueId === action.venueId;
        })
        .switchMap(action => Observable.of(actionCreators.loadRegistrations(action.venueId, store.value.reception.selectedDate)));

export const customerCheckedInEpic = (action$: ActionsObservable<any>, store: any) =>
    epic.createDebounced(action$,
        rt.ReceptionActionTypes.CustomerCheckedIn,
        2000,
        action => {
            return Observable.of(actionCreators.loadRegistrations(action.venueId, store.value.reception.selectedDate))
        },
        err => actionCreators.loadRegistrationsComplete([], err),
        (action, ix) => {
            const selecteVenueId = store.value.venues.selectedVenueId;
            const dateMatch = !action.bookingDate || action.bookingDate.isSameDay(store.value.reception.selectedDate);
            return selecteVenueId !== null && selecteVenueId === action.venueId && dateMatch;
        }
    );

export const eventsChangedEpic = (action$: ActionsObservable<any>, store: any) =>
    epic.createDebounced(action$,
        rt.ReceptionActionTypes.EventsChanged,
        2000,
        action => {
            return Observable.of(actionCreators.loadEvents(action.venueId, store.value.reception.selectedDate))
        },
        err => actionCreators.loadRegistrationsComplete([], err),
        (action, ix) => {
            const selecteVenueId = store.value.venues.selectedVenueId;
            const dateMatch = !action.bookingDate || action.bookingDate.isSameDay(store.value.reception.selectedDate);
            return selecteVenueId !== null && selecteVenueId === action.venueId && dateMatch;
        }
    );

export const billChangedEpic = (action$: ActionsObservable<any>, store: IStore) =>
    epic.createDebounced(action$,
        bt.BillActionTypes.BillChanged,
        2000,
        action => {
            const venueId = store.value.venues.selectedVenueId;
            const dt = store.value.reception.selectedDate;
            return Observable.merge(Observable.of(actionCreators.loadEvents(venueId, dt)), Observable.of(actionCreators.loadRegistrations(venueId, dt)))
        },
        err => actionCreators.loadRegistrationsComplete([], err),
        (action, ix) => {
            const registrationsWithBill = store.value.reception.events.filter(e => e.bookings.findIndex(b => b.billId === action.billId) >= 0);
            return registrationsWithBill.length > 0;
        }
    );

