

import * as React from 'react';
import * as PropTypes from 'prop-types'
import * as H from 'history';

import * as api from '../../../store/apiClient';

import * as ct from '../../global/controls';

import { EventBooking, BookingCustomer, formatCustomerName, ReservationType } from '../../../store/pages/diary/types';
import MarketingPreferences from '../../global/marketingPreferences';
import { isNullOrEmpty, clickHandler, generateTempId } from '../../../utils/util';
import { Bill, BillPayment, BillItem, formatItemName, BillFee, BillRefund, BillDiscount, BillItemVoucher } from '../../../store/pages/pointOfSale/types';
import EventCustomerForm, { CustomerDetails } from './eventCustomerForm';
import BillItemsPanel from '../pointOfSale/billItemsPanel';
import BillTotals from '../pointOfSale/billTotals';
import BillHeading from '../pointOfSale/billHeading';
import EditBillItem, { BillItemReservation } from '../pointOfSale/editBillItem';
import { Product, ProductPricingMode } from '../../../store/pages/products/types';
import { ActivityFormat } from '../../../store/pages/activityFormats/types';
import * as aft from '../../../store/pages/activityFormats/types';
import { Booking, Reservation, NewBookingReservation, CustomerCategoryDefault } from './types';
import CreateEvent from './createEvent';
import { DateFormat, TimeFormat, Venue } from '../../../store/pages/venues/types';
import CancelBooking from './cancelBooking';
import { CustomerType } from '../../../store/pages/customer/types';
import SearchCustomerPanel from './searchCustomerPanel';
import { CustomerCategory } from '../../../store/pages/customerCategories/types';
import { getActivityProducts, ActivityFormatProduct, buildProductKey, createReservation } from './helpers';
import { ClientEmailTemplate } from '../../../store/pages/emailTemplates/types';
import BookingCommunications from './bookingCommunications'
import TagList from '../../global/tagList';
import BookingNotes from './bookingNotes';
import { Resource } from '../../../store/pages/resources/types';
import EditBillFee from '../pointOfSale/editBillFee';
import EditBillRefund from '../pointOfSale/editBillRefund';
import AddBillDiscount from '../pointOfSale/addBillDiscount';
import { Promotion, PromotionStatus } from '../../../store/pages/promotions/types';
import { colours } from '../../../store/global/types';
import { findLinkedBillItems, LinkedItemQuantities } from '../pointOfSale/types';
import VoucherDetails from '../vouchers/voucherDetails';
import Flag from '../../icons/flag';
import CustomerBookingNotes from './customerBookingNotes';

interface BookingDetailsProps {
    history: H.History;
    venue: Venue;
    booking: Booking | null;
    eventCancelled: boolean;
    isSaving: boolean;
    bill?: Bill;
    products: Product[];
    customerCategories: CustomerCategory[];
    defaultCountryId: number;
    reservations: Reservation[];
    activityFormats: ActivityFormat[];
    resources: Resource[];
    clientEmailTemplates: ClientEmailTemplate[];
    promotions: Promotion[];
    editBill: (booking: Booking, bill: Bill) => void;
    paymentUpdated: (bill: Bill, closeModal: boolean) => void;
    addPayment: (bill: Bill, isSecurityPayment: boolean) => void;
    onCustomerUpdated: (bookingKey: string, customer: BookingCustomer) => void;
    makeScheduledPayment: (booking: Booking, bill: Bill, payment: BillPayment) => void;
    editPayment: (bill: Bill, payment: BillPayment) => void;
    updateBillItem: (bill: Bill, item: BillItem, quantity: number, archived: boolean, unitPrice: number, productId: string, placesToBookPerUnit: number | null, reservationId: string | null, customerCategoryId: string | null, pricingMode: ProductPricingMode, fixedPriceOverride: number | null, productPriceId: string, linkedItemQuantities: LinkedItemQuantities[], completionCallback: () => void) => void;
    updateBillFee: (billId: string, fee: BillFee, callback: (success: boolean, error: api.ApiError | null) => void) => void;
    voidRefund: (billId: string, refundId: string, voidReason: string, callback: (success: boolean, error: api.ApiError | null) => void) => void;
    replaceDiscount: (billId: string, promotionId: string, amountOverride: number | null, callback: (success: boolean, error: api.ApiError | null) => void) => void;
    removeDiscount: (billId: string, billDiscountId: string, callback: (success: boolean, error: api.ApiError | null) => void) => void;
    addReservationsToBooking: (bookingId: string, newReservations: NewBookingReservation[], successCallback: () => void) => void;
    cancelBooking: (bookingId: string, reason: string) => void;
    reinstateBooking: (bookingId: string) => void;
    completeWebBooking: (bookingId: string) => void;
    flagBooking: (bookingId: string, flagged: boolean) => void;
    onBookingWebsiteChanged: (bookingId: string, publicWebsiteId: number) => void;
    sendEmail: (clientEmailTemplate: ClientEmailTemplate, toEmailAddress: string, booking: Booking | null, customerId: string | null, emailSent: (error: string | null) => void) => void;
    sendPaymentLink: (clientEmailTemplateId: string, billPaymentId: string, toEmailAddress: string, emailSent: (error: string | null) => void) => void;
    onBookingChanged: (eventIdOverride?: string) => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    hasInvalidReservation: boolean;
    closeModal: () => void;
    logout: () => void;
}

class BookingDetails extends React.Component<BookingDetailsProps, {}> {

    static contextTypes = {
        t: PropTypes.func
    }

    today: Date;

    constructor(props: BookingDetailsProps) {
        super(props);

        this.today = new Date().datePart();
    }       

    // TODO IMPORTANT: Refactor this out (it's duplicated from billPanel)- either use Redux or introduce a service.
    updateItem = (itemKey: string, quantity: number, unitPrice: number, productId: string, placesToBookPerUnit: number | null, customerCategoryId: string | null, reservationId: string | null, pricingMode: ProductPricingMode, fixedPriceOverride: number | null, productPriceId: string, linkedItemQuantities: LinkedItemQuantities[]) => {
        const { bill, closeModal, updateBillItem } = this.props;

        if (!bill) return;

        const item = bill.items.find(i => i.key === itemKey);
        if (item) {
            updateBillItem(bill, item, quantity, false, unitPrice, productId, placesToBookPerUnit, customerCategoryId, reservationId, pricingMode, fixedPriceOverride, productPriceId, linkedItemQuantities, closeModal);
        }
    }

    removeItem = (itemKey: string) => {
        const { bill, closeModal, updateBillItem } = this.props;
        if (!bill) return;

        const item = bill.items.find(i => i.key === itemKey);
        if (item) {
            updateBillItem(bill, item, item.quantity, true, item.unitPrice, item.productId, item.placesToBookPerUnit, item.reservationId, item.customerCategoryId, item.pricingMode, item.pricingMode === ProductPricingMode.Fixed ? item.totalItemPrice : null, item.productPriceId, [], closeModal);
        }
    }

    findActivityFormat = (activityFormatId: string) => {
        const { activityFormats } = this.props;
        const activity = activityFormats.find(a => a.id === activityFormatId);
        return activity ? activity : null;
    }

    getProductsForBillItem = (item: BillItem): ActivityFormatProduct[] => {
        const activityFormat = this.findActivityFormat(item.activityFormatId || '');
        const productSelections = activityFormat ? this.getProductsForActivityFormat(this.findActivityFormat(item.activityFormatId || ''), item.reservationId, item.reservationStartTime) : [];
        return productSelections.findIndex(s => s.product.id === item.productId) >= 0 ? productSelections : this.getProductSelection(item.productId);
    }

    getProductSelection = (productId: string): ActivityFormatProduct[]=> {
        const { reservations, activityFormats, products, customerCategories } = this.props;

        const filteredActivityFormats = reservations.map(r => ({ reservation: r, activityFormat: activityFormats.find(af => af.id === r.activityFormatId) }));
        const matchingProducts = filteredActivityFormats.reduce<aft.ActivityFormatProduct[]>((acc, x) => x.activityFormat ? acc.concat(x.activityFormat.products.filter(p => p.productId === productId)) : acc, []);
        if (matchingProducts.length < 1) {
            return [];
        }

        const categoryProducts = matchingProducts.reduce<ActivityFormatProduct[]>((prods, p) => {
            const prod = products.find(p => p.id === productId);
            if (prod) {
                if (p.customerCategories.length === 0) {
                    prods.push({ key: p.productId, product: prod, displayName: prod.name, placesPerUnit: 1, customerCategoryId: null, customerCategoryName: null, reservationId: null, reservationTime: null })
                } else {
                    for (var ci = 0; ci < p.customerCategories.length; ci++) {
                        const cat = customerCategories.find(c => c.id === p.customerCategories[ci]);
                        if (cat && !cat.archived) {
                            prods.push({ key: buildProductKey(p.productId, cat.id), product: prod, displayName: prod.name, placesPerUnit: 1, customerCategoryId: cat.id, customerCategoryName: cat.name, reservationId: null, reservationTime: null })
                        }
                    }
                }
            }
            return prods;
        }, []);

        return categoryProducts;
    }

    getProductsForActivityFormat = (activityFormat: ActivityFormat | null, reservationId: string | null, reservationTime: Date | null): ActivityFormatProduct[]  => {
        const { products, customerCategories } = this.props;
        if (!activityFormat)
            return [];

        return getActivityProducts(activityFormat, reservationId, reservationTime, products, customerCategories);
    }

    getReservationsForProduct = (item: BillItem): BillItemReservation[] => {
        const reservations = this.props.reservations.map(r => {
            const activityFormat = this.findActivityFormat(r.activityFormatId);
            const resource = this.props.resources.find(rsc => rsc.id === r.resourceId);
            return {
                reservationId: r.id || '',
                resourceId: r.resourceId,
                resourceName: resource ? resource.name : '',
                startTime: r.startTime.toDate(),
                activityId: r.activityFormatId,
                activityName: activityFormat ? activityFormat.name : '',
                activityProducts: this.getProductsForActivityFormat(activityFormat, item.reservationId, item.reservationStartTime)
            }
        });
        
        const filtered = reservations.filter(r => {
            if (!isNullOrEmpty(item.reservationId)) {
                return r.reservationId === item.reservationId;
            } else {
                return r.activityProducts.findIndex(p => p.product.id === item.productId) >= 0;
            }
        });

        return filtered;
    }

    editItem = (item: BillItem) => {
        const { bill, customerCategories, products, venue, eventCancelled } = this.props;
        const { t } = this.context;
        if (!bill || eventCancelled) {
            return;
        }

        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;

        const productSelections = this.getProductsForBillItem(item);
        
        // If the bill item isn't for the main reservation activity, then don't allow the reseration to be selected
        const itemReservations = productSelections.length > 0 ? this.getReservationsForProduct(item) : [];

        const linkedItems = findLinkedBillItems(item, bill.items);

        const primaryReservationItems = bill.items.filter(i => i.reservationId && !i.primaryBillItemId);

        // Item can only be removed if it's not a reservation proucts, it's a primary item and it's not the last remaining reserfvation item
        const canRemove = item.reservationId && (item.primaryBillItemId || primaryReservationItems.length < 2) ? false : true;

        this.props.showModal(<EditBillItem
            item={item}
            linkedItems={linkedItems}
            itemName={formatItemName(item, customerCategories, false, timeFormat, dateFormat, t)}
            customerTags={bill.customerTags}
            customerCategories={customerCategories}
            updateItem={this.updateItem}
            canUpdate={true}
            canRemove={canRemove}
            removeItem={this.removeItem}
            cancel={this.props.closeModal}
            canSelectProduct={item.reservationId ? productSelections.length > 0 : false}
            productSelections={productSelections}
            reservations={itemReservations}
            products={products}
            timeFormat={timeFormat}
        />, 'EditBillItem');
    }

    editBillFee = (billFee: BillFee) => {
        const { bill, closeModal, updateBillFee } = this.props;

        if (!bill) {
            return;
        }

        this.props.showModal(<EditBillFee
            bill={bill}
            fee={billFee}
            updateBillFee={updateBillFee}
            close={closeModal}
        />, 'EditBillFee');
    }

    editRefund = (refund: BillRefund) => {
        const { bill, venue, closeModal, voidRefund } = this.props;

        if (!bill) {
            return;
        }

        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;

        this.props.showModal(<EditBillRefund
            bill={bill}
            refund={refund}
            voidRefund={voidRefund}
            timeFormat={timeFormat}
            dateFormat={dateFormat}
            close={closeModal}
        />, 'EditBillRefund');
    }

    editDiscount = (discount: BillDiscount | null, isMember: boolean) => {
        const { venue, bill, promotions, closeModal, logout, replaceDiscount, removeDiscount } = this.props;

        if (!bill) {
            return;
        }

        this.props.showModal(<AddBillDiscount
            bill={bill}
            promotions={promotions.filter(p => p.venueId === venue.id && p.state === PromotionStatus.Active)}
            isMember={isMember}
            close={closeModal}
            logout={logout}
            addDiscount={replaceDiscount}
            removeDiscount={removeDiscount}
        />, 'AddBillDiscount');
    }

    editContact = (booking: EventBooking) => {
        if (!booking.customer) {
            this.props.showModal(<SearchCustomerPanel
                defaultCountryId={this.props.defaultCountryId}
                setAsOrganiser={true}
                addCustomer={(cus) => this.props.onCustomerUpdated(booking.key, cus)}
                editCustomer={(cus) => this.props.onCustomerUpdated(booking.key, cus)}
                close={this.props.closeModal} />, 'SearchCustomerPanel');
        } else {
            this.props.showModal(<EventCustomerForm
                add={false}
                customer={({ ...booking.customer, isOrganiser: true, birthYear: 0, birthMonth: 0, birthDay: 0 })}
                requirePhoneNumber={false}
                showIsOrganiser={true}
                canChangeDob={false}
                canAddPhoto={false}
                defaultCountryId={this.props.venue.countryId}
                cancel={this.props.closeModal}
                addCustomer={() => { }}
                updateCustomer={this.onContactUpdated} />, 'EventCustomerForm');
        }
    }

    onContactUpdated = (customer: CustomerDetails, photoImg: File | null) => {
        const { booking } = this.props;

        if (!booking) {
            return;
        }

        const bookingCustomer = booking.customer ? booking.customer : { key: generateTempId(), customerId: '', customerType: CustomerType.Organiser, hasActiveMembership: false };
        this.props.onCustomerUpdated(booking.key, {
            ...bookingCustomer,
            companyName: customer.companyName,
            firstname: customer.firstname,
            lastname: customer.lastname,
            nickname: customer.nickname,
            emailAddress: customer.emailAddress,
            phoneNumber: customer.phoneNumber,
            addressLine1: customer.addressLine1,
            addressLine2: customer.addressLine2,
            addressLine3: customer.addressLine3,
            addressLine4: customer.addressLine4,
            town: customer.town,
            county: customer.county,
            countryId: customer.countryId,
            postalCode: customer.postalCode,
            gender: customer.gender,
            marketingPreference: customer.marketingPreference,
            resultsPreference: customer.resultsPreference,
            publicResultsConsent: customer.publicResultsConsent,
            emergencyContactName: customer.emergencyContactName,
            emergencyContactNumber: customer.emergencyContactNumber,
            tags: customer.tags,
            notes: customer.notes
        });
    }

    editBill = () => {
        const { bill, booking } = this.props;

        if (bill && booking)
            this.props.editBill(booking, bill);
    }

    addReservationsToBooking = (bookingId: string, newReservations: NewBookingReservation[], successCallback?: () => void) => {
        const { addReservationsToBooking, closeModal } = this.props;
        addReservationsToBooking(bookingId, newReservations, successCallback || closeModal);
    }

    reservationAdded = (eventIds: string[]) => {
        const { onBookingChanged, closeModal } = this.props;
        const eventId = eventIds.length > 0 ? eventIds[0] : undefined;
        onBookingChanged(eventId);
        closeModal();
    }

    addEventToBooking = () => {
        const { venue, booking, bill, reservations, resources, isSaving, showModal, closeModal } = this.props;

        if (!booking || !booking.customer) {
            return;
        }

        const defaultResourceId = reservations.length > 0 ? reservations[0].resourceId : '';
        const resourceIx = Math.max(0, resources.findIndex(r => r.id === defaultResourceId));
        const resource = resources[resourceIx];
        const lastReservation = reservations.length > 0 ? reservations.sort((r1, r2) => r2.startTime.unix() - r1.startTime.unix())[0] : null;
        const startTime = lastReservation ? lastReservation.startTime.toDate().addMinutes(30) : new Date();
        const endTime = startTime.addMinutes(30);

        const reservation = createReservation(resource, resource.configurations[0], '', ReservationType.NonExclusive, '', '', '', 0, false, startTime, endTime, 0, '', true, colours[0], null, false);

        const existingReservationIds = bill ? bill.items.filter(i => i.reservationId).map(i => i.reservationId || '').filter(i => !isNullOrEmpty(i)) : [];
        const categoryDefaults = bill
            ? bill.items.filter(i => i.reservationId && i.placesToBookPerUnit).reduce<CustomerCategoryDefault[]>((cats, i) =>
                cats.findIndex(c => c.customerCategoryId === i.customerCategoryId) < 0
                    ? cats.concat({ customerCategoryId: i.customerCategoryId || '', quantity: i.quantity })
                    : cats
                , [])
            : [];

        showModal(<CreateEvent
            venue={venue}
            showSelectCustomer={false}
            customersToRegister={[]}
            booking={booking}
            billItemsToReschedule={[]}
            customerCategoryDefaults={categoryDefaults}
            bookedReservationIds={existingReservationIds}
            defaultCountryId={venue.countryId}
            reservation={reservation}
            isSaving={isSaving}
            eventCreated={this.reservationAdded}
            cancel={closeModal} />, 'CreateEvent');
    }

    viewVoucher = (voucher: BillItemVoucher) => {
        const { venue, showModal, closeModal, logout } = this.props;
        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;

        showModal(<VoucherDetails
            voucherId={voucher.voucherId}
            timeFormat={timeFormat}
            dateFormat={dateFormat}
            close={closeModal}
            logout={logout} />, 'VoucherDetails')
    }

    rescheduleItem = (item: BillItem) => {
        const { venue, booking, bill, isSaving, showModal, closeModal } = this.props;

        if (!booking || !booking.customer) {
            return;
        }

        const reservation = booking.reservations.find(r => r.id === item.reservationId);

        if (!reservation) {
            console.log(`Bill item has no reservation or reservation not found [itemId: ${item.id}, reservationId: ${item.reservationId}]`)
            return;
        }

        // First get the items to reschedule.
        const primaryItemsToReschedule = bill ? bill.items.filter(i => i.reservationId && (i.id === item.id || i.primaryBillItemId === item.id)) : [];

        // Next, find any other bill items for these reservations
        const allItemsToReschedule = bill ? bill.items.filter(i => primaryItemsToReschedule.findIndex(pi => pi.id === i.id || pi.reservationId === i.reservationId) >= 0) : [];

        // We need to allow the same reservations to be selected when rescheduling, so exclude them from the existing reservations list
        const otherReservationIds = bill ? bill.items.filter(i => i.reservationId && allItemsToReschedule.findIndex(ai => ai.id === i.id) < 0).map(i => i.reservationId || '').filter(i => !isNullOrEmpty(i)) : [];

        const categoryDefaults = bill
            ? bill.items.filter(i => i.reservationId && i.placesToBookPerUnit).reduce<CustomerCategoryDefault[]>((cats, i) =>
                cats.findIndex(c => c.customerCategoryId === i.customerCategoryId) < 0
                    ? cats.concat({ customerCategoryId: i.customerCategoryId || '', quantity: i.quantity })
                    : cats
                , [])
            : [];

        showModal(<CreateEvent
            venue={venue}
            showSelectCustomer={false}
            customersToRegister={[]}
            booking={booking}
            billItemsToReschedule={allItemsToReschedule}
            customerCategoryDefaults={categoryDefaults}
            bookedReservationIds={otherReservationIds}
            defaultCountryId={venue.countryId}
            reservation={reservation}
            isSaving={isSaving}
            eventCreated={this.reservationAdded}
            createButtonTextKey='EventCustomers:reschedule'
            cancel={closeModal} />, 'CreateEvent');
    }

    cancelBooking = () => {
        const { venue, booking, bill, customerCategories, paymentUpdated, showModal, closeModal } = this.props;

        if (!booking || !bill) return;
        
        showModal(<CancelBooking venue={venue} booking={booking} bill={bill} customerCategories={customerCategories} confirm={this.confirmCancelBooking} cancel={closeModal} paymentUpdated={paymentUpdated} />, 'CancelBooking');
    }

    reinstateBooking = (bookingId: string) => this.props.reinstateBooking(bookingId);

    completeBooking = (bookingId: string) => this.props.completeWebBooking(bookingId);

    confirmCancelBooking = (bookingId: string, reason: string) => {
        const { cancelBooking, closeModal } = this.props;
        cancelBooking(bookingId, reason);
        closeModal();
    }

    addPayment = (isScheduledPayment: boolean) => {
        const { bill } = this.props;

        if (!bill) return;
        this.props.addPayment(bill, isScheduledPayment);
    }

    makePayment = (bill: Bill, payment: BillPayment) => {
        const { booking } = this.props;

        if (booking) {
            this.props.makeScheduledPayment(booking, bill, payment);
        }
    }

    editPayment = (bill: Bill, payment: BillPayment) => {
        this.props.editPayment(bill, payment);
    }

    sendEmail = (clientEmailTemplate: ClientEmailTemplate, toEmailAddress: string, customerId: string | null, emailSent: (error: string | null) => void) => this.props.sendEmail(clientEmailTemplate, toEmailAddress, this.props.booking, customerId, emailSent)

    sendPaymentLink = (clientEmailTemplateId: string, billPaymentId: string, toEmailAddress: string, emailSent: (error: string | null) => void) => this.props.sendPaymentLink(clientEmailTemplateId, billPaymentId, toEmailAddress, emailSent)

    onBookingWebsiteChanged = (bookingId: string, publicWebsiteId: string) => {
        const pwsId = parseInt(publicWebsiteId);
        if (!isNaN(pwsId))
            this.props.onBookingWebsiteChanged(bookingId, pwsId);
    }

    render() {
        const { venue, booking, bill, clientEmailTemplates, activityFormats, reservations, resources, flagBooking } = this.props;
        const { t } = this.context;

        const customer = booking ? booking.customer : null;

        if (!booking) {
            return <div className='bg-danger text-center'>{t('EventCustomers:noBookingSelected')}</div>
        }

        const contactInfo = customer ? [customer.phoneNumber, customer.emailAddress, customer.postalCode].filter(x => !isNullOrEmpty(x)).join(' | ') : [];

        const companyDetails = customer && !isNullOrEmpty(customer.companyName)
            ? <div>{customer.companyName}</div>
            : null;

        const scheduledPayments = bill ? bill.payments.filter(p => !p.paid && p.paymentDueDate) : [];
        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;

        const websiteOptions = venue ? venue.publicWebsites.map(w => ({ key: w.id.toString(), name: w.name })) : []

        const headerCls = `at-panel selected-item${booking && booking.cancelled ? '-cancelled' : ''} at-panel-rounded event-form-booking-header`;
        const headerStyle = booking && booking.cancelled ? { overflow: 'unset', borderRadius: '6px 6px 0 0', paddingBottom: '10px' } : { overflow: 'unset' }

        return <div>
            <div className='row'>
                <div className='col-md-12'>
                    <div className={headerCls} style={headerStyle}>
                        <div className='event-form-booking-header-row'>
                            <button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.editContact(booking))}>
                                <h4 className='no-margin black-text'>{formatCustomerName(customer, `${t('Global:unconfirmedWebBooking')} (${t('EventCustomers:clickToSelectCustomer')})`)} {!customer || isNullOrEmpty(customer.notes) ? null : <span className='glyphicon glyphicon-comment' ></span>}</h4>
                            </button>
                            <div className='event-form-booking-header-booking-info'>
                                {booking && websiteOptions.length > 1 ? <div className='right' style={{ minWidth: '150px', margin: '0 6px' }}><ct.PlainSelect id='website' options={websiteOptions} value={(booking.publicWebsiteId || 0).toString()} callback={val => this.onBookingWebsiteChanged(booking.id, val)} /></div> : null}
                                {customer && customer.tags.length > 0 ? <TagList tags={customer.tags} /> : null}
                                {booking && booking.isWebBooking ? <div className='right'><label className='label label-default'>{t('Global:webBooking')}</label></div> : null}
                                {booking ? <span onClick={e => clickHandler(e, () => flagBooking(booking.id, !booking.flagged))} style={{ cursor: 'pointer' }}><Flag width={18} height={18} colour={booking.flagged ? '#d9534f' : '#999'} /></span> : null}
                            </div>
                        </div>
                        <div className='event-form-booking-header-row'>
                            {companyDetails}
                        </div>
                        <div className='event-form-booking-header-row' style={({ color: '#444' })}>
                            <div>{contactInfo}{customer ? <MarketingPreferences preference={customer.marketingPreference} customerId={customer.customerId} /> : null}</div>
                            <div className='event-form-booking-header-booking-info'>
                                {t('Global:created')}: {booking.createDateTimeInLocalTime.toAbbrDateTimeString(timeFormat, dateFormat, t)}
                            </div>
                        </div>
                      
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    {booking && booking.cancelled ? <div className='event-form-booking-header-cancelled-banner'>{t('Global:cancelled')} - <span className='event-form-booking-header-cancellation-reason'>{booking.cancellationReason}</span></div> : null}
                </div>
            </div>

            {this.renderBill()}

            <div className='row row-flex'>
                <div className='col-md-6 col-xs-12 mt-15'>
                    <div className='booking-bill-wrapper at-panel full-height'>
                        <CustomerBookingNotes bookingId={booking.id} />
                        <BookingNotes bookingId={booking.id} activityFormats={activityFormats} reservations={reservations.map(r => {
                            const rsc = resources.find(rs => rs.id === r.resourceId);
                            return { key: r.key, startTime: r.startTime.toDate(), notes: r.notes, activityFormatId: r.activityFormatId, resourceId: r.resourceId, resourceName: rsc ? rsc.name : '' }
                        })} resources={resources} />
                    </div>
                </div>
                <div className='col-md-6 col-xs-12 mt-15'>
                    <div className='booking-bill-wrapper at-panel full-height'>
                        <BookingCommunications
                            bookingId={booking.id}
                            customerId={booking && booking.customer ? booking.customer.customerId : null}
                            confirmationSent={!isNullOrEmpty(booking.bookingConfirmationClientEmailTemplateId)}
                            clientEmailTemplates={clientEmailTemplates}
                            customerEmailAddress={booking.customer ? booking.customer.emailAddress : ''}
                            registrationLink={booking.registrationLink}
                            scheduledPayments={scheduledPayments}
                            sendEmail={this.sendEmail}
                            sendPaymentLink={this.sendPaymentLink}
                            dateFormat={dateFormat}
                        />
                    </div>
                </div>
            </div>
        </div>
    }

    renderBill = () => {
        const { booking, eventCancelled, bill, reservations, customerCategories, venue } = this.props;

        if (!bill)
            return null;

        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;
        const isCancelled = eventCancelled || (booking !== null && booking.cancelled);

        return (
            <div className='row mt-15'>
                <div className='col-md-12'>
                    <div className='booking-bill-wrapper at-panel at-panel-rounded'>
                        <BillHeading bill={bill} hideCustomer={true} editBill={isCancelled ? undefined : this.editBill} />
                        <BillItemsPanel
                            bill={bill}
                            customerCategories={customerCategories}
                            bookingCancelled={isCancelled}
                            timeFormat={timeFormat}
                            dateFormat={dateFormat}
                            editBillItem={this.editItem}
                            editBillFee={this.editBillFee}
                            editRefund={this.editRefund}
                            editDiscount={d => this.editDiscount(d, booking && booking.customer && booking.customer.hasActiveMembership ? true : false)}
                            editBillPayment={p => this.editPayment(bill, p)}
                            makePayment={p => this.makePayment(bill, p)}
                            rescheduleItem={booking && booking.customer && !booking.cancelled && (!booking.isWebBooking || booking.webBookingComplete) ? this.rescheduleItem : undefined}
                            eventReservationIds={reservations.map(r => r.id ? r.id : '').filter(x => !isNullOrEmpty(x))} />
                        <BillTotals bill={bill} />
                        {this.renderActions(bill)}
                    </div>
                </div>
            </div>
        );
    }

    renderActions = (bill?: Bill) => {
        if (!bill) {
            return null;
        }
        const { t } = this.context;
        const { eventCancelled, isSaving, booking, hasInvalidReservation } = this.props;

        const buttons: JSX.Element[] = []

        if (!booking) {
            return null;
        }

        const hasResevationBillItems = bill.items.reduce((hasLinkedItem, i) => hasLinkedItem || !isNullOrEmpty(i.reservationId), false);
        const warning = hasResevationBillItems ? null : <div className='alert alert-danger text-center'>{t('EventCustomers:MissingReservationLinks')}</div>

        if (booking.cancelled) {
            if (!eventCancelled) {
                buttons.push(<button key='reinstate-button' className='btn btn-warning' onClick={e => clickHandler(e, () => this.reinstateBooking(booking.id))} disabled={isSaving}>{t('EventCustomers:reinstateBooking')}</button>)
            }
        } else if (booking.isWebBooking && !booking.cancelled && !booking.webBookingComplete) {
            buttons.push(<button key='complete-button' className='btn btn-primary' onClick={e => clickHandler(e, () => this.completeBooking(booking.id))}> {t('EventCustomers:completeBooking')}</button>)
            buttons.push(<button key='cancel-button' className='btn btn-danger' onClick={e => clickHandler(e, () => this.cancelBooking())} disabled={isSaving}>{t('EventCustomers:cancelBooking')}</button>)
        } else {
            buttons.push(<button key='add-event-button' className='btn btn-info' onClick={e => clickHandler(e, () => this.addEventToBooking())} disabled={isSaving || hasInvalidReservation || !hasResevationBillItems}>{t('EventCustomers:addSessions')}</button>)
            buttons.push(<button key='cancel-button' className='btn btn-danger' onClick={e => clickHandler(e, () => this.cancelBooking())} disabled={isSaving}>{t('EventCustomers:cancelBooking')}</button>)

            if (booking.customer && booking.customer.hasActiveMembership) {
                buttons.push(<button key='discount-button' className='btn btn-primary' onClick={e => clickHandler(e, () => this.editDiscount(null, true))} disabled={isSaving || hasInvalidReservation || !hasResevationBillItems}>{t('PointOfSale:memberDiscount')}</button>)
            } else { 
                buttons.push(<button key='discount-button' className='btn btn-info' onClick={e => clickHandler(e, () => this.editDiscount(null, false))} disabled={isSaving || hasInvalidReservation || !hasResevationBillItems}>{t('PointOfSale:discount')}</button>)
            }

            buttons.push(<button key='payment-button' className='btn btn-success' onClick={e => clickHandler(e, () => this.addPayment(false))} disabled={isSaving || hasInvalidReservation || !hasResevationBillItems}>{t('PointOfSale:takePayment')}</button>)
        }

        return <div className='row'>
            <div className={'col-md-12'}>
                {warning}
                <div className='btn-toolbar-spaced center-block'>
                    {buttons}                    
                </div>
            </div>
        </div>

    }
}

export default BookingDetails;
