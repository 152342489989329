
import { Interval, ValidationError } from '../../global/types';
import * as api from '../../apiClient';
import { ProductPrice, ProductTag } from '../products/types';

export enum MembershipExpiryType {
    None = 0,
    Fixed = 1,
    Annual = 2,
    Rolling = 3
}

export interface MembershipTypeTag {
    tagId: string;
    tagName: string;
    tagColour: string;
}

export interface MembershipTypeVenuePurchaseSettings {
    venueId: string;
    publicWebsiteIdsToSellOn: number[];
    sellOnPointOfSale: boolean;
}

export interface MembershipType {
    id: string;
    productId: string;
    name: string;
    description: string;
    pricing: ProductPrice[];
    taxRateId: string;
    taxRate: number;
    taxRateName: string;
    accountingDepartment: string;
    accountingCategory: string;
    nominalCode: string;
    productImageUrl: string;
    membershipImageUrl: string;
    numberCustomPrefix: string;
    numberOfDigitsToPad: number;
    expiryType: MembershipExpiryType;
    expiryDate: Date | null;
    expiryInterval: Interval | null;
    productInformation: string;
    tag: MembershipTypeTag;
    promotionIds: string[];
    archived: boolean;
    clientEmailTemplateId: string;
    venueIds: string[];
    venuePurchaseSettings: MembershipTypeVenuePurchaseSettings[];
    categoryIds: string[];
    version: number;
}

export interface MembershipState {
    isActive: boolean;
    isLoading: boolean;
    membershipTypes: MembershipType[];
    loadError: api.ApiError | null;
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
    membershipSearchMembershipTypeId: string | null;
    membershipSearch: string;
    searchPageNumber: number;
    searchPageSize: number;
    searchMaxPage: number;
    isSearching: boolean;
    searchError: api.ApiError | null;
    memberships: Membership[];
}

export interface Membership {
    id: string;
    membershipTypeId: string;
    billItemId: string;
    customerId: string;
    membershipNumber: string;
    expiryDate: Date | null;
    hasExpired: boolean;
    purchaseDate: Date;
    customerFirstname: string;
    customerLastname: string;
    customerEmail: string;
    createdBy: string;
    tagId: string;
    tagName: string;
    tagColour: string;
}

export enum MembershipActionTypes {
    RequestMembershipTypes = 'REQUEST_MEMBERSHIP_TYPES',
    ReceivedMembershipTypes = 'RECEIVE_MEMBERSHIP_TYPES',
    SaveMembershipType = 'SAVE_MEMBERSHIP_TYPE',
    MembershipTypeSaved = 'MEMBERSHIP_TYPE_SAVED',
    MembershipTypeSaveFailed = 'MEMBERSHIP_TYPET_SAVE_FAILED',
    EditMembershipType = 'EDIT_MEMBERSHIP_TYPE',
    SearchMemberships = "SEARCH_MEMBERSHIPS",
    MembershipsReceived = "MEMBERSHIPS_RECEIVED",
}

export interface LoadMembershipTypes {
    type: MembershipActionTypes.RequestMembershipTypes;
}


export interface ReceiveMembershipTypes {
    type: MembershipActionTypes.ReceivedMembershipTypes;
    membershipTypes: MembershipType[];
    error: api.ApiError | null;
}

export interface SaveMembershipType {
    type: MembershipActionTypes.SaveMembershipType;
    isNew: boolean;
    membershipType: MembershipType;
    productImg: File | null;
    membershipImg: File | null;
}

export interface MembershipTypeSaved {
    type: MembershipActionTypes.MembershipTypeSaved;
    membershipTypeId: string;
}

export interface MembershipTypeSaveFailed {
    type: MembershipActionTypes.MembershipTypeSaveFailed;
    error: api.ApiError;
}

export interface EditMembershipType {
    type: MembershipActionTypes.EditMembershipType
}

export interface SearchMemberships {
    type: MembershipActionTypes.SearchMemberships,
    membershipTypeId: string;
    search: string;
    pageNumber: number;
    pageSize: number;
}

export interface MembershipsReceived {
    type: MembershipActionTypes.MembershipsReceived,
    searchError: api.ApiError | null;
    memberships: Membership[];
    maxPage: number;
}

