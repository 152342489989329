
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import StepHeader from './stepHeader';
import {RegistrationField} from '../../../store/pages/venues/types';
import {allCountries } from '../../../store/global/countries';
import { Customer, Gender } from '../../../store/pages/customer/types';
import NavButtons from './navButtons';
import { upper, properCase } from '../../../utils/util';

interface RegistrationAddressProps {
    customer: Customer;
    defaultCountryId: number;
    registrationFields: RegistrationField;
    requiredRegistrationFields: RegistrationField;
    headerText: string;
    showStep1: () => void;
    cancel: () => void;
    next: (customer: Customer) => void;
}

interface RegistrationAddressState {
    addressLine1: ct.FormValue<string>;
    addressLine2: ct.FormValue<string>;
    addressLine3: ct.FormValue<string>;
    addressLine4: ct.FormValue<string>;
    town: ct.FormValue<string>;
    county: ct.FormValue<string>;
    countryId: ct.FormValue<number>;
    postalCode: ct.FormValue<string>;
    gender: ct.FormValue<Gender>;
    emergencyContactName: ct.FormValue<string>;
    emergencyContactNumber: ct.FormValue<string>;
}

class RegistrationAddress extends React.Component<RegistrationAddressProps, RegistrationAddressState> {

    constructor(props: RegistrationAddressProps) {
        super(props);

        this.state = {
            addressLine1: this.validateAddressLine1(''),
            addressLine2: this.validateAddressLine2(''),
            addressLine3: this.validateAddressLine3(''),
            addressLine4: this.validateAddressLine4(''),
            town: this.validateTown(''),
            county: this.validateCounty(''),
            countryId: this.validateCountry(props.defaultCountryId),
            postalCode: this.validatePostalCode(''),
            gender: this.validateGender(Gender.NotProvided),
            emergencyContactName: this.validateEmergencyContactName(''),
            emergencyContactNumber: this.validateEmergencyContactNumber(''),
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }


    editCustomer = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        this.props.showStep1();
    }

    validateAddressLine1 = (val: string) => v.validate(properCase(val), 'addressLine1', this.props.requiredRegistrationFields & RegistrationField.BasicAddress || this.props.requiredRegistrationFields & RegistrationField.FullAddress ? [v.required] : undefined, []);
    validateAddressLine2 = (val: string) => v.validate(properCase(val), 'addressLine2', [], []);
    validateAddressLine3 = (val: string) => v.validate(properCase(val), 'addressLine3', [], []);
    validateAddressLine4 = (val: string) => v.validate(properCase(val), 'addressLine4', [], []);
    validateTown = (val: string) => v.validate(properCase(val), 'town', [], []);
    validateCounty = (val: string) => v.validate(properCase(val), 'county', [], []);
    validateCountry = (val: number) => v.validate(val, 'country', [], []);
    validatePostalCode = (val: string) => v.validate(upper(val), 'postalCode', this.props.requiredRegistrationFields & RegistrationField.BasicAddress || this.props.requiredRegistrationFields & RegistrationField.FullAddress ? [v.required] : undefined, []);
    validateGender = (val: number) => v.validate(val, 'gender', (this.props.requiredRegistrationFields & RegistrationField.Gender) == RegistrationField.Gender ? [v.required] : [], []);
    validateEmergencyContactName = (val: string) => v.validate(properCase(val), 'addressLine4', this.props.requiredRegistrationFields & RegistrationField.EmergencyContactName ? [v.required] : undefined, []);
    validateEmergencyContactNumber = (val: string) => v.validate(properCase(val), 'addressLine4', this.props.requiredRegistrationFields & RegistrationField.EmergencyContactNumber ? [v.required] : undefined, []);


    submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {
            const { addressLine1, addressLine2, addressLine3, addressLine4, town, county, countryId, postalCode, gender, emergencyContactName, emergencyContactNumber } = this.state;

            this.props.next({
                ...this.props.customer,
                addressLine1: addressLine1.value,
                addressLine2: addressLine2.value,
                addressLine3: addressLine3.value,
                addressLine4: addressLine4.value,
                town: town.value,
                county: county.value,
                countryId: countryId.value,
                postalCode: postalCode.value,
                gender: gender.value,
                emergencyContactName: emergencyContactName.value,
                emergencyContactNumber: emergencyContactNumber.value
            });
        }
    }

    render() {
        const { t } = this.context;
        const { addressLine1, addressLine2, town, county, countryId, postalCode, gender, emergencyContactNumber, emergencyContactName } = this.state;
        const { registrationFields, headerText, cancel} = this.props;

        const addressFields: JSX.Element[] = [];

        const genderOptions = Object.keys(Gender).filter(k => typeof Gender[k as any] === 'number').map(k => ({ key: Gender[k as any].toString(), name: t(`Gender:${k}`) }));

        if (registrationFields & RegistrationField.BasicAddress || registrationFields & RegistrationField.FullAddress) {
            addressFields.push(<ct.TextBox key='addrLine1' id='addressLine1' classNames='col-xs-6' labelKey='Global:addressLine1' placeholderKey='Global:addressLine1' value={addressLine1} callback={val => this.setState({ addressLine1: this.validateAddressLine1(val) })} />);
        }

        if (registrationFields & RegistrationField.FullAddress) {
            addressFields.push(<ct.TextBox key='addrLine2' id='addressLine2' classNames='col-xs-6' labelKey='Global:addressLine2' placeholderKey='Global:addressLine2' value={addressLine2} callback={val => this.setState({ addressLine2: this.validateAddressLine2(val) })} />);
        }

        if (registrationFields & RegistrationField.FullAddress) {
            addressFields.push(<ct.TextBox key='town' id='town' classNames='col-xs-6' labelKey='Global:town' placeholderKey='Global:town' value={town} callback={val => this.setState({ town: this.validateTown(val) })} />);
        }

        if (registrationFields & RegistrationField.FullAddress) {
            addressFields.push(<ct.TextBox key='county' id='county' classNames='col-xs-6' labelKey='Global:county' placeholderKey='Global:county' value={county} callback={val => this.setState({ county: this.validateCounty(val) })} />);
        }

        if (registrationFields & RegistrationField.FullAddress) {
            addressFields.push(<ct.Country key='country' id='country' classNames='col-xs-6' labelKey='Global:country' placeholderKey='Global:country' value={countryId} callback={val => this.setState({ countryId: this.validateCountry(val) })} countries={allCountries} />);
        }

        if (registrationFields & RegistrationField.BasicAddress || registrationFields & RegistrationField.FullAddress) {
            addressFields.push(<ct.TextBox key='postalCode' id='postalCode' classNames='col-xs-6' labelKey='Global:postalCode' placeholderKey='Global:postalCode' value={postalCode} callback={val => this.setState({ postalCode: this.validatePostalCode(val) })} />);
        }

        if (registrationFields & RegistrationField.Gender || registrationFields & RegistrationField.Gender) {
            addressFields.push(<ct.Select key='gender' id='gender' classNames='col-xs-6' labelKey='Global:gender' value={{ ...gender, value: gender.value.toString() }} options={genderOptions} callback={val => this.setState({ gender: this.validateGender(parseInt(val)) })} />);
        }

        if (registrationFields & RegistrationField.EmergencyContactName || registrationFields & RegistrationField.EmergencyContactName) {
            addressFields.push(<ct.TextBox key='emergencyContactName' id='emergencyContactName' classNames='col-xs-6' labelKey='Global:emergencyContactName' placeholderKey='Global:emergencyContactName' value={emergencyContactName} callback={val => this.setState({ emergencyContactName: this.validateEmergencyContactName(val) })} />);
        }

        if (registrationFields & RegistrationField.EmergencyContactNumber || registrationFields & RegistrationField.EmergencyContactNumber) {
            addressFields.push(<ct.TextBox key='emergencyContactNumber' id='emergencyContactNumber' classNames='col-xs-6' labelKey='Global:emergencyContactNumber' placeholderKey='Global:emergencyContactNumber' value={emergencyContactNumber} callback={val => this.setState({ emergencyContactNumber: this.validateEmergencyContactNumber(val) })} />);
        }

        return (
            <div>
                <form className='registration-form' onSubmit={this.submit} autoComplete='off'>
                    <StepHeader headerText={headerText} />
                    {this.buildAddressForm(addressFields)}

                    <NavButtons t={t} cancel={cancel} preventNext={!v.isValid(this.state)} />
                </form>
            </div>
        );
    }

    buildAddressForm = (addressFields: JSX.Element[]) => {
        const result: JSX.Element[] = [];

        for (let i = 0; i < addressFields.length; i += 2) {
            result.push(
                <div className='row' key={`addr_row_${i}`}>
                    {addressFields[i]}
                    {i < addressFields.length - 1 ? addressFields[i + 1] : null}
                </div>
            );
        }

        return result;
    }
}

export default RegistrationAddress;