

import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';
import * as v from '../../global/validation';

import { ClientEmailTemplate, EmailType } from '../../../store/pages/emailTemplates/types';
import { clickHandler, copyToClipboard, isNullOrEmpty } from '../../../utils/util';
import { BillPayment } from '../../../store/pages/pointOfSale/types';
import { DateFormat } from '../../../store/pages/venues/types';
import { stringComparer } from '../../../utils/comparers';

interface BookingCommunicationsProps {
    bookingId: string | null;
    customerId: string | null;
    confirmationSent: boolean;
    registrationLink: string | null;
    customerEmailAddress: string;
    clientEmailTemplates: ClientEmailTemplate[];
    scheduledPayments: BillPayment[];
    dateFormat: DateFormat;
    sendEmail: (clientEmailTemplate: ClientEmailTemplate, toEmailAddress: string, customerId: string | null, emailSent: (error: string | null) => void) => void;
    sendPaymentLink: (clientEmailTemplateId: string, billPaymentId: string, toEmailAddress: string, emailSent: (error: string | null) => void) => void;
}

interface EmailSelection {
    key: string;
    type: EmailType;
    clientEmailTemplateId: string;
    billPaymentId: string | null;
    text: (t: (key: any, params?: any, comment?: string) => string) => string;
}

interface BookingCommunicationsState {
    emailSelections: EmailSelection[];
    clientEmailTemplateKey: ct.FormValue<string>;
    emailAddress: ct.FormValue<string>;
    error: string | null;
    message: string | null;
    sendingEmail: boolean;
}


class BookingCommunications extends React.Component<BookingCommunicationsProps, BookingCommunicationsState> {

    static contextTypes = {
        t: PropTypes.func
    }

    constructor(props: BookingCommunicationsProps) {
        super(props);

        this.state = this.setStateFromProps(props);
    }

    setStateFromProps = (props: BookingCommunicationsProps) => {
        const { clientEmailTemplates, scheduledPayments, confirmationSent, registrationLink, dateFormat } = props;

        const standardEmailSelections: EmailSelection[]  = clientEmailTemplates
            .filter(et => et.emailType === EmailType.BookingRegistrationLink || et.emailType === EmailType.BookingConfirmation || et.emailType === EmailType.GeneralBookingEmail || et.emailType === EmailType.General)
            .sort((t1, t2) => t1.emailType === t2.emailType ? stringComparer(t1.name || '', t2.name || '') : t1.emailType - t2.emailType)
            .map(et => ({
                key: et.clientEmailTemplateId || '',
                type: et.emailType,
                clientEmailTemplateId: et.clientEmailTemplateId || '',
                billPaymentId: null,
                text: t => et.name || t(`EmailTemplateType:${et.templateType}`)
            }))

        const paymentEmailSelections = clientEmailTemplates
            .filter(et => et.emailType === EmailType.PaymentReminder)
            .reduce<EmailSelection[]>((pt, et) => pt.concat(scheduledPayments.map(p => ({
                key: `${et.clientEmailTemplateId || ''}_${p.id}`,
                type: et.emailType,
                clientEmailTemplateId: et.clientEmailTemplateId || '',
                billPaymentId: p.id,
                text: t => `${et.name} ${p.paymentDueDate ? p.paymentDueDate.toAbbrDateString(dateFormat, t) : ''} ${p.description} ${t('Global:currencySymbol')}${p.amount.toFixed(2)}`
            }))), [])

        const bookingConfirmationEmails = !confirmationSent ? standardEmailSelections.filter(t => t.type === EmailType.BookingConfirmation) : [];
        const registrationEmails = isNullOrEmpty(registrationLink) ? [] : standardEmailSelections.filter(t => t.type === EmailType.BookingRegistrationLink);

        let defaultTemplateKey = bookingConfirmationEmails.length > 0 ? bookingConfirmationEmails[0].key : registrationEmails.length > 0 ? registrationEmails[0].key : '';

        return {
            emailSelections: standardEmailSelections.concat(paymentEmailSelections),
            clientEmailTemplateKey: this.validateClientEmailTemplateKey(defaultTemplateKey),
            emailAddress: this.validateEmailAddress(props.customerEmailAddress),
            error: null,
            message: null,
            sendingEmail: false
        }
    }

    componentDidUpdate(prevProps: BookingCommunicationsProps) {
        if (this.props.bookingId !== prevProps.bookingId || !this.compareScheduledPayments(this.props.scheduledPayments, prevProps.scheduledPayments)) {
            this.setState(this.setStateFromProps(this.props))
        }
    }

    compareScheduledPayments = (a1: BillPayment[], a2: BillPayment[]) => {
        if (!a1 || !a2 || a1.length != a2.length) return false;
        for (var i = 0; i < a1.length; i++) {
            if (a1[i].id !== a2[i].id || a1[i].paid !== a2[i].paid || a1[i].void !== a2[i].void) return false;
        }
        return true;
    }

    validateClientEmailTemplateKey = (val: string) => v.validate(val, 'clientEmailTemplateKey', [v.required], []);
    validateEmailAddress = (val: string) => v.validate(val, 'emailAddress', [v.required], []);

    emailSent = () => {
        this.setState({ error: null, sendingEmail: false, message: 'BookingCommunications:emailSent' });
    }

    sendEmail = () => {
        const { emailAddress, clientEmailTemplateKey } = this.state;
        const { registrationLink, customerId, clientEmailTemplates, sendEmail, sendPaymentLink } = this.props;
        const { emailSelections } = this.state;

        if (!clientEmailTemplateKey.isValid) {
            this.setState({ error: 'BookingCommunications:selectEmailTemplate' })
            return;
        }

        if (!emailAddress.isValid) {
            this.setState({ error: 'BookingCommunications:enterEmailAddress' })
            return;
        }

        const clientEmailTemplateSelection = emailSelections.find(t => t.key === clientEmailTemplateKey.value);
        if (!clientEmailTemplateSelection) {
            this.setState({ error: 'BookingCommunications:selectEmailTemplate' })
            return;
        }

        if (clientEmailTemplateSelection.type === EmailType.BookingRegistrationLink && isNullOrEmpty(registrationLink)) {
            this.setState({ error: 'BookingCommunications:noRegistrationLink' })
            return;
        }

        this.setState({ sendingEmail: true });

        if (clientEmailTemplateSelection.type === EmailType.PaymentReminder) {
            sendPaymentLink(clientEmailTemplateSelection.clientEmailTemplateId, clientEmailTemplateSelection.billPaymentId || '', emailAddress.value, this.emailSent);
        } else {
            const emailTemplate = clientEmailTemplates.find(t => t.clientEmailTemplateId === clientEmailTemplateSelection.clientEmailTemplateId)
            if (emailTemplate) {
                sendEmail(emailTemplate, emailAddress.value, customerId, this.emailSent);
            }
        }

        this.setState({ error: null });
    }

    render() {
        const { t } = this.context;
        const { registrationLink } = this.props;
        const { clientEmailTemplateKey, emailSelections, emailAddress, error, message, sendingEmail } = this.state;


        const clientEmailTemplateOptions = emailSelections.map(et => ({ key: et.key, name: et.text(t) }))

        return (
            <div className='row'>
                <div className='col-md-12'>
                    <div className='section-heading'>{t('BookingCommunications:communications')}</div>

                    <div className='row'>
                        <div className='col-xs-12'>
                            <ct.Select id='clientEmailTemplateId' labelKey='BookingCommunications:emailType' value={clientEmailTemplateKey} callback={val => this.setState({ clientEmailTemplateKey: this.validateClientEmailTemplateKey(val) })} options={clientEmailTemplateOptions} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-xs-12'>
                            <ct.TextBox id='emailAddress' labelKey='BookingCommunications:emailAddress' value={emailAddress} callback={val => this.setState({ emailAddress: this.validateEmailAddress(val) })} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-xs-12'>
                            <button className='btn btn-primary pull-right' onClick={e => clickHandler(e, this.sendEmail)} disabled={sendingEmail}>{t('BookingCommunications:sendEmail')}</button>
                        </div>
                    </div>

                    {registrationLink && !isNullOrEmpty(registrationLink)
                        ? <>
                            <div>
                                <label>{t('BookingCommunications:registrationLink')}</label>
                            </div>
                            <div className='flex flex-center'>
                                <span className='small' style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' } }>{registrationLink}</span>
                                <span className='glyphicon glyphicon-copy' style={({ cursor: 'pointer', padding: '5px', marginLeft: '10px', fontSize: '20px', color: '#337ab7' })} onClick={e => clickHandler(e, () => copyToClipboard(registrationLink))}></span>
                            </div>
                        </>
                        : null
                    }

                    {!isNullOrEmpty(error) ? <div className='alert alert-danger'>{t(error)}</div> : !isNullOrEmpty(message) ? <div className='alert alert-info'>{t(message)}</div> : null}
                </div>
            </div>
        )
    }
}

export default BookingCommunications