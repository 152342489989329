import { ValidationError } from '../../global/types';
import * as api from '../../apiClient';

export interface ActivityFormatGroup {
    id: string;
    venueId: string;
    name: string;
    sequence: number | null;
    webDescription: string;
    webDescriptionBgColour: string;
    webDescriptionTextColour: string;
    imageId: string | null;
    imageUrl: string | null;
    archived: boolean;
}

export interface ActivityFormatGroupsState {
    isActive: boolean;
    isLoading: boolean;
    activityFormatGroups: ActivityFormatGroup[];
    loadError: api.ApiError | null;
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
}

export enum ActivityFormatGroupActionTypes {
    RequestActivityFormatGroups = 'REQUEST_ACTIVITY_FORMAT_GROUPS',
    ReceivedActivityFormatGroups = 'RECEIVE_ACTIVITY_FORMAT_GROUPS',
    SaveActivityFormatGroup = 'SAVE_ACTIVITY_FORMAT_GROUP',
    ActivityFormatGroupSaved = 'ACTIVITY_FORMAT_GROUP_SAVED',
    ActivityFormatGroupSaveFailed = 'ACTIVITY_FORMAT_GROUP_SAVE_FAILED',
    EditActivityFormatGroup = 'EDIT_ACTIVITY_FORMAT_GROUP'
}


export interface LoadActivityFormatGroups {
    type: ActivityFormatGroupActionTypes.RequestActivityFormatGroups;
}


export interface ReceiveActivityFormatGroups {
    type: ActivityFormatGroupActionTypes.ReceivedActivityFormatGroups;
    activityFormatGroups: ActivityFormatGroup[];
    error: api.ApiError | null;
}

export interface SaveActivityFormatGroup {
    type: ActivityFormatGroupActionTypes.SaveActivityFormatGroup;
    isNew: boolean;
    activityFormatGroup: ActivityFormatGroup;
    img: File | null;
}

export interface ActivityFormatGroupSaved {
    type: ActivityFormatGroupActionTypes.ActivityFormatGroupSaved;
    activityFormatGroupId: string;
}

export interface ActivityFormatGroupSaveFailed {
    type: ActivityFormatGroupActionTypes.ActivityFormatGroupSaveFailed;
    error: api.ApiError;
}

export interface EditActivityFormatGroup {
    type: ActivityFormatGroupActionTypes.EditActivityFormatGroup
}