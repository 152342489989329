
import * as React from 'react';
import * as PropTypes from 'prop-types'

import {Customer} from '../../../store/pages/customer/types';

interface RegistrationConfirmationProps {
    customer: Customer;
    finalInstructions: string;
    newRegistration: () => void;
}

class RegistrationConfirmation extends React.Component<RegistrationConfirmationProps, {}> {

    timer: NodeJS.Timeout | null;

    constructor(props: RegistrationConfirmationProps) {
        super(props);

        this.state = { timer: 0 };
        this.timer = null;
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        this.timer = setTimeout(() =>this.props.newRegistration(), 20000);
    }

    componentWillUnmount() {
        this.clearTimer();
    }

    clearTimer = () => {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    }

    newRegistration = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        this.clearTimer();
        this.props.newRegistration();
    }

    render() {
        const t = this.context.t;

        return (
            <div className='registration-form'>
                <div dangerouslySetInnerHTML={{ __html: this.props.finalInstructions }}></div>

                <p/>

                <button type='button' className='btn btn-primary' onClick={this.newRegistration}>{t('Registration:newRegistration')}</button>
            </div>
        );
    }
}

export default RegistrationConfirmation;