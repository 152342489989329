
import * as React from 'react';
import * as PropTypes from 'prop-types'

import SigPad, { PointGroup, Options } from 'signature_pad';
import { debounce } from 'throttle-debounce';
import { clickHandler, isNullOrEmpty } from '../../utils/util';

interface SignaturePadProps {
    id: string;
    width?: number | string;
    height?: number | string;
    labelKey?: string;
    className?: string;
    style?: any;
    options?: Options;
    redrawOnResize?: boolean;
    debounceInterval?: number;
    format?: string;
    signatureChanged: (data: string) => void;
}

interface SignaturePadState {
    canvasWidth: number;
    canvasHeight: number;
}

class SignaturePad extends React.PureComponent<SignaturePadProps, SignaturePadState> {

    _callResizeHandler: any;
    _canvas?: HTMLCanvasElement | null;
    _signaturePad?: SigPad;

    constructor(props: SignaturePadProps) {
        super(props);

        this.state = { canvasWidth: 0, canvasHeight: 0 };

        const debounceInterval = this.props.debounceInterval || 150;
        this._callResizeHandler = debounce(debounceInterval, this.handleResize.bind(this));
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        if (this._canvas) {
            const { width, height, options, format, signatureChanged } = this.props;

            if (!width || !height) {
                this._canvas.style.width = '100%';
            }

            this.scaleCanvas();

            if (!width || !height) {
                window.addEventListener('resize', this._callResizeHandler);
            }

            const signaturePad = new SigPad(this._canvas, options);
            signaturePad.addEventListener("endStroke", () => signatureChanged(signaturePad.toDataURL(format)));
            this._signaturePad = signaturePad;
        }
    }

    componentWillUnmount() {
        const { width, height } = this.props;
        if (!width || !height) {
            window.removeEventListener('resize', this._callResizeHandler);
        }

        if (this._signaturePad)
            this._signaturePad.off();
    }

    isEmpty = () =>  {
        return !this._signaturePad || this._signaturePad.isEmpty();
    }

    clear = () => {
        if (this._signaturePad) {
            this._signaturePad.clear();
            this.props.signatureChanged('');
        }
    }

    fromDataURL = (base64String: string) => {
        if (this._signaturePad)
            this._signaturePad.fromDataURL(base64String);
    }

    toDataURL = (mime: string | undefined) => {
        return this._signaturePad ? this._signaturePad.toDataURL(mime) : undefined;
    }

    fromData = (data: PointGroup[]) => {
        if (this._signaturePad)
            this._signaturePad.fromData(data);
    }

    toData = () => {
        return this._signaturePad ? this._signaturePad.toData() : [];
    }

    off = () => {
        if (this._signaturePad)
            this._signaturePad.off();
    }

    on = () => {
        if (this._signaturePad)
            this._signaturePad.on();
    }

    handleResize = () => this.scaleCanvas();

    scaleCanvas() {
        if (!this._canvas) return;

        const { width, height, redrawOnResize } = this.props;

        const ratio = Math.max(window.devicePixelRatio || 1, 1);

        const numericWidth = typeof width === 'number' ? width as number : undefined;
        const numericHeight = typeof height === 'number' ? height as number : undefined;

        const scaledWidth = (numericWidth || this._canvas.offsetWidth) * ratio;
        const scaledHeight = (numericHeight || this._canvas.offsetHeight) * ratio;

        // Avoid needlessly setting height/width if dimensions haven't changed
        const { canvasWidth, canvasHeight } = this.state;
        if (scaledWidth === canvasWidth && scaledHeight === canvasHeight) return;

        const data = (redrawOnResize && this._signaturePad) ? this._signaturePad.toDataURL() : null;

        this._canvas.width = scaledWidth;
        this._canvas.height = scaledHeight;

        this.setState({ canvasWidth: scaledWidth, canvasHeight: scaledHeight });

        const ctx = this._canvas.getContext('2d');
        if (ctx)
            ctx.scale(ratio, ratio);

        if (redrawOnResize && this._signaturePad && data) {
            this._signaturePad.fromDataURL(data);
        } else if (this._signaturePad) {
            this._signaturePad.clear();
        }
    }

    render() {
        const { id, className, style, labelKey } = this.props;
        const { t } = this.context;

        const argumentedClassName = (className ? className : '') + ' signature-pad'

        const lblText = this.localize(labelKey, t);
        const label = isNullOrEmpty(lblText) ? null : <label htmlFor={id}>{lblText}</label>;

        return (
            <div className='control-wrapper'>
                {label}
                <canvas id={id} className={argumentedClassName} ref={ref => this._canvas = ref} style={style} />
                <div className='mt-15'>
                    <button className='btn btn-default' onClick={e => clickHandler(e, this.clear)}>{t('Global:clear')}</button>
                </div>
            </div>
        );
    }

    localize = (key: string | undefined, t: (key: any, params?: any, comment?: string) => string) => isNullOrEmpty(key) ? '' : t(key);

}

export default SignaturePad;