
import * as React from 'react';
import { clickHandler } from '../../utils/util';

interface PaginationItemProps {
    clicked: () => void;
    val: string;
    selected: boolean;
    disabled: boolean;
}

export const PaginationItem: React.SFC<PaginationItemProps> = (props) => {

    const handleClick = () => {
        if (!props.disabled && !props.selected)
            props.clicked();
    }

    return <li key={props.val} className={props.selected ? 'active' : props.disabled ? 'disabled' : ''}><button className='btn btn-link' onClick={e => clickHandler(e, handleClick)}>{props.val}</button></li>;
}
