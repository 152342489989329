
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as pt from './types'; 
import { IStore } from '../..';
import { Time } from '../../global/types';


export type PromotionActions = pt.LoadPromotions | pt.ReceivePromotions | pt.SavePromotion | pt.PromotionSaved | pt.PromotionSaveFailed | pt.EditPromotion;

export const actionCreators = {
    loadPromotions: () => ({ type: pt.PromotionActionTypes.RequestPromotions }),
    loadPromotionsComplete: (promotions: pt.Promotion[], err: api.ApiError | null) => ({ type: pt.PromotionActionTypes.ReceivedPromotions, promotions: promotions, error: err }),
    editPromotion: () => ({ type: pt.PromotionActionTypes.EditPromotion }),
    savePromotion: (isNew: boolean, promotionId: string | null, promotion: pt.Promotion) => ({ type: pt.PromotionActionTypes.SavePromotion, isNew: isNew, promotionId: promotionId, promotion: promotion }),
    promotionSaved: (promotionId: string) => ({ type: pt.PromotionActionTypes.PromotionSaved, promotionId: promotionId }),
    promotionSaveFailed: (error: api.ApiError) => ({ type: pt.PromotionActionTypes.PromotionSaveFailed, error: error })
}

interface IGetPromotionsResponse {
    promotions: pt.Promotion[];
}

interface ISavePromotionResponse {
    promotionId: string;
}

export const handleClientChange = (store: IStore) => [actionCreators.loadPromotions]


//https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-after-catching-and-processing-an-error-with-emitting-somethi

const loadPromotions = () => Observable.defer(() => api.getJson<IGetPromotionsResponse>('api/v1/promotion/'))
    .map(response => actionCreators.loadPromotionsComplete(response.promotions.map(p => ({ ...p, timeRestrictions: p.timeRestrictions.map(r => ({...r, from: Time.parse(r.from), to: Time.parse(r.to)}))})), null));

export const loadPromotionsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        pt.PromotionActionTypes.RequestPromotions,
        action => loadPromotions(),
        err => actionCreators.loadPromotionsComplete([], err));

export const reloadPromotionsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        pt.PromotionActionTypes.PromotionSaved,
        action => loadPromotions(),
        err => actionCreators.loadPromotionsComplete([], err));

export const savePromotionEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        pt.PromotionActionTypes.SavePromotion,
        action => {
            const stra = action as pt.SavePromotion;
            const { triggerTags, itemTags, itemRestrictionTags, timeRestrictions, ...promotion } = stra.promotion;
            var body = {
                ...promotion,
                id: stra.promotionId || stra.promotion.id,
                triggerTagIds: triggerTags.map(t => t.id),
                itemTagIds: itemTags.map(t => t.id),
                itemRestrictionTagIds: itemRestrictionTags.map(t => t.id),
                timeRestrictions: timeRestrictions.map(r => ({ days: r.days, from: r.from.toString(), to: r.to.toString() }))
            };

            return (stra.isNew ? api.post('api/v1/promotion/', body) : api.put(`api/v1/promotion/${stra.promotionId}`, body))
                .map(response => {

                    let promotionId = stra.promotionId;
                    if (stra.isNew) {
                        const strr = response.response as ISavePromotionResponse;
                        if (strr) {
                            promotionId = strr.promotionId;
                        }
                    }

                    return ({ type: pt.PromotionActionTypes.PromotionSaved, promotionId: promotionId });
                });
        },
        (err: api.ApiError) => actionCreators.promotionSaveFailed(err));
