import { ValidationError, Time } from '../../global/types';
import * as api from '../../apiClient';

export interface BookingEmailScheduleConfig {
    id: string;
    venueId: string;
    name: string;
    clientEmailTemplateId: string;
    clientEmailTemplateName: string;
    sendForManualBookings: boolean;
    sendForWebBookings: boolean;
    sendWhenPaidInFull: boolean;
    sendWhenAnyPayment: boolean;
    numberOfDaysBeforeEvent: number | null;
    timeOfDayToSend: Time | null;
    resourceFilter: string[];
    archived: boolean;
}

export interface BookingEmailScheduleConfigState {
    isLoading: boolean;
    configs: BookingEmailScheduleConfig[];
    loadError: api.ApiError | null;
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
}

export enum BookingEmailScheduleConfigActionTypes {
    RequestBookingEmailScheduleConfigs = 'REQUEST_BOOKING_EMAIL_SCHEDULE_CONFIGS',
    ReceivedBookingEmailScheduleConfigs = 'RECEIVE_BOOKING_EMAIL_SCHEDULE_CONFIGS',
    SaveBookingEmailScheduleConfig = 'SAVE_BOOKING_EMAIL_SCHEDULE_CONFIG',
    BookingEmailScheduleConfigSaved = 'BOOKING_EMAIL_SCHEDULE_CONFIG_SAVED',
    BookingEmailScheduleConfigSaveFailed = 'BOOKING_EMAIL_SCHEDULE_CONFIG_SAVE_FAILED',
    EditBookingEmailScheduleConfig = 'EDIT_BOOKING_EMAIL_SCHEDULE_CONFIG'
}


export interface LoadBookingEmailScheduleConfigs {
    type: BookingEmailScheduleConfigActionTypes.RequestBookingEmailScheduleConfigs;
}

export interface ReceiveBookingEmailScheduleConfigs {
    type: BookingEmailScheduleConfigActionTypes.ReceivedBookingEmailScheduleConfigs;
    configs: BookingEmailScheduleConfig[];
    error: api.ApiError | null;
}

export interface SaveBookingEmailScheduleConfig {
    type: BookingEmailScheduleConfigActionTypes.SaveBookingEmailScheduleConfig;
    isNew: boolean;
    bookingEmailScheduleConfigId?: string;
    config: BookingEmailScheduleConfig;
}

export interface BookingEmailScheduleConfigSaved {
    type: BookingEmailScheduleConfigActionTypes.BookingEmailScheduleConfigSaved;
    bookingEmailScheduleConfigId: string;
}

export interface BookingEmailScheduleConfigSaveFailed {
    type: BookingEmailScheduleConfigActionTypes.BookingEmailScheduleConfigSaveFailed;
    error: api.ApiError;
}

export interface EditBookingEmailScheduleConfig {
    type: BookingEmailScheduleConfigActionTypes.EditBookingEmailScheduleConfig
}
