
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as api from '../../../../../store/apiClient';

import { AjaxResponse } from 'rxjs/ajax';
import { BillPayment } from '../../../../../store/pages/pointOfSale/types';
import { PaymentMethod } from '../../../../../store/pages/paymentMethods/types';
import ReaderSelection from './readerSelection';
import StripeElementsPayment from './stripeElementsPayment';
import { isNullOrEmpty } from '../../../../../utils/util';
import StripeTerminalPayment from './stripeTerminalPayment';


interface ProcessStripePaymentProps {
    venueId: string;
    billId: string;
    payment: BillPayment;
    amount: number;
    paymentMethod: PaymentMethod;
    onSaveError: (saveError: api.ApiError) => void;
    onComplete: (billId: string) => void;
    onCancelled: (billId: string) => void;
    showAlert: (element: JSX.Element) => void;
    clearAlert: () => void;
    logout: () => void;
}

interface ProcessStripePaymentState {
    initialized: boolean;
    paymentAmount: number;
    processing: boolean;
    error: string | null;
    showModeSelection: boolean;
    showCardEntry: boolean;
    showReaderSelection: boolean;
    terminalId: string | null;
}


export default class ProcessStripePayment extends React.Component<ProcessStripePaymentProps, ProcessStripePaymentState> {

    constructor(props: ProcessStripePaymentProps) {
        super(props);

        const { paymentMethod } = props;
        const { settings } = paymentMethod;

        const cardReadersEnabled = settings && settings.filter(s => s.key === 'enable_terminal_readers' && s.unsecuredValue === "true").length === 1;

        this.state = {
            initialized: false,
            paymentAmount: props.amount,
            processing: false,
            showCardEntry: !cardReadersEnabled,
            showModeSelection: cardReadersEnabled,
            showReaderSelection: false,
            terminalId: null,
            error: null
        }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    initiateManualCardEntry = () => {
        this.setState({ showCardEntry: true, showReaderSelection: false });
    }

    initiateTerminalPayment = (terminalId: string) => {
        this.setState({ terminalId: terminalId, showCardEntry: false, showReaderSelection: false });
    }
    
    cancelStripePayment = (paymentGatewayPaymentId: string | null) => {
        const { billId, onCancelled, logout } = this.props;
        if (paymentGatewayPaymentId) {
            api.postWithAuth(`api/v1/paymentGateway/payment/stripe/${paymentGatewayPaymentId}/cancel`, {}, logout)
                .subscribe(resp => onCancelled(billId), (err: api.ApiError) => this.setState({ error: err.message }));
        } else {
            onCancelled(billId);
        }
    }

    paymentFailed = () => {
        const { billId, onCancelled } = this.props;
        onCancelled(billId);
    }

    render() {
        const { t } = this.context;
        const { error, paymentAmount, processing } = this.state;

        return <div className='pos-process-gateway-payment-panel'>
            <h4 className='text-center'>{t('PointOfSale:processingPaymentHeader')}</h4>

            <p />

            <div className='text-center mt-15' style={{ fontSize: '16px' }}>{`${t('PointOfSale:paymentAmount')}: ${t('Global:currencySymbol')}${paymentAmount.toFixed(2)}`}</div>

            <p />

            {this.renderPayment()}

            {processing ? <div className='alert alert-info'>{t('ProcessStripePayment:processing')}</div> : null}
            {error ? <div className='alert alert-danger'>{error}</div> : null}
        </div>
    }

    renderInitializing = () => {
        const { t } = this.context;
        return <div className='row'><div className='col-xs-12'>{t('ProcessStripePayment:loading')}</div></div>
    }

    renderPayment = () => {
        const { showCardEntry, showReaderSelection, terminalId } = this.state;
        const { venueId, billId,payment,amount,paymentMethod,onSaveError,onComplete,logout } = this.props;

        if (showCardEntry) {
            return <StripeElementsPayment
                venueId={venueId}
                billId={billId}
                payment={payment}
                amount={amount}
                paymentMethod={paymentMethod}
                cancelStripePayment={this.cancelStripePayment}
                onSaveError={onSaveError}
                onComplete={onComplete}
                logout={logout}
            />
        }

        if (terminalId) {
            return <StripeTerminalPayment
                venueId={venueId}
                billId={billId}
                payment={payment}
                amount={amount}
                paymentMethod={paymentMethod}
                terminalId={terminalId}
                cancelStripePayment={this.cancelStripePayment}
                onSaveError={onSaveError}
                onComplete={onComplete}
                paymentFailed={this.paymentFailed}
                logout={logout}
            />
        }

        return this.renderSelection()
    }

    renderSelection = () => {
        const { venueId, billId, payment, amount, paymentMethod, logout } = this.props;
        return <ReaderSelection
            venueId={venueId}
            billId={billId}
            payment={payment}
            amount={amount}
            paymentMethod={paymentMethod}
            cancelPayment={() => this.cancelStripePayment(null)}
            initiateTerminalPayment={this.initiateTerminalPayment}
            enterCardManually={this.initiateManualCardEntry}
            logout={logout} />
    }
}