
// https://blog.angularindepth.com/how-to-reduce-action-boilerplate-90dc3d389e2b

import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as it from './types';
import * as lt from '../login/types';
import { IStore } from '../..';

export type CustomerCategoryActions = it.LoadCustomerCategories | it.ReceiveCustomerCategories | it.SaveCustomerCategory | it.CustomerCategorySaved | it.CustomerCategorySaveFailed | it.EditCustomerCategory;

export const actionCreators = {
    loadCustomerCategories: () => ({ type: it.CustomerCategoryActionTypes.RequestCustomerCategories }),
    loadCustomerCategoriesComplete: (customerCategories: it.CustomerCategory[], err: api.ApiError | null) => ({ type: it.CustomerCategoryActionTypes.ReceivedCustomerCategories, customerCategories: customerCategories, error: err }),
    editCustomerCategory: () => ({ type: it.CustomerCategoryActionTypes.EditCustomerCategory }),
    saveCustomerCategory: (isNew: boolean, customerCategory: it.CustomerCategory) => ({ type: it.CustomerCategoryActionTypes.SaveCustomerCategory, isNew: isNew, customerCategory: customerCategory }),
    customerCategorySaved: (customerCategoryId: string) => ({ type: it.CustomerCategoryActionTypes.CustomerCategorySaved, customerCategoryId: customerCategoryId }),
    customerCategorySaveFailed: (error: api.ApiError) => ({ type: it.CustomerCategoryActionTypes.CustomerCategorySaveFailed, error: error })
}

interface IGetCustomerCategoriesResponse {
    customerCategories: it.CustomerCategory[];
}

interface ISaveCustomerCategoryResponse {
    customerCategoryId: string;
}

export const handleClientChange = (store: IStore) => [actionCreators.loadCustomerCategories]

//https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-after-catching-and-processing-an-error-with-emitting-somethi

const loadCustomerCategories = () => Observable.defer(() => api.getJson<IGetCustomerCategoriesResponse>('api/v1/customerCategory/'))
    .map(response => actionCreators.loadCustomerCategoriesComplete(response.customerCategories, null));

export const loadCustomerCategoryEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        it.CustomerCategoryActionTypes.RequestCustomerCategories,
        action => loadCustomerCategories(),
        err => actionCreators.loadCustomerCategoriesComplete([], err));

export const reloadCustomerCategoryEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        it.CustomerCategoryActionTypes.CustomerCategorySaved,
        action => loadCustomerCategories(),
        err => actionCreators.loadCustomerCategoriesComplete([], err));

export const saveCustomerCategoryEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        it.CustomerCategoryActionTypes.SaveCustomerCategory,
        action => {
            const sia = action as it.SaveCustomerCategory;

            return (sia.isNew ? api.post('api/v1/customerCategory/', sia.customerCategory) : api.put(`api/v1/customerCategory/${sia.customerCategory.id}`, sia.customerCategory))
                .map(response => {

                    let customerCategoryId = sia.customerCategory.id;
                    if (sia.isNew) {
                        const sir = response.response as ISaveCustomerCategoryResponse;
                        if (sir) {
                            customerCategoryId = sir.customerCategoryId;
                        }
                    }

                    return ({ type: it.CustomerCategoryActionTypes.CustomerCategorySaved, customerCategory: customerCategoryId });
                });
        },
        (err: api.ApiError) => actionCreators.customerCategorySaveFailed(err));
