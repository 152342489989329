
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';
import * as v from '../../global/validation';

import { clickHandler, formHandler } from '../../../utils/util';

import * as api from '../../../store/apiClient';

interface MigrateClientImagesState {
    clientId: ct.FormValue<number>;
    quantity: ct.FormValue<number>;
    migrating: boolean;
    message: string | null;
}

class MigrateClientImages extends React.Component<{}, MigrateClientImagesState> {

    constructor(props: any) {
        super(props);

        this.state = { clientId: this.validateClientId(0), quantity: this.validateQuantity(100), migrating:false, message: null };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    validateClientId = (val: number) => v.validate(val, 'clientId', [v.numeric, (value: number) => value === 0 ? 'validation:required' : undefined], []);
    validateQuantity = (val: number) => v.validate(val, 'quantity', [v.numeric, (value: number) => value === 0 ? 'validation:required' : undefined], []);

    migrateImages = () => {
        this.setState({ migrating: true, message: 'Migrating images, please wait (it may take a long time)' });

        api.postWithAuth(`api/tools/clientImageMigration/moveToS3/${this.state.clientId.value}?batchSize=${this.state.quantity.value}`, {}, () => {})
            .subscribe(
                resp => {
                    const imagesMigrated = resp.response.numberOfImagesMigrated;
                    const failues = resp.response.failues;
                    this.setState({ message: `Migration complete. ${imagesMigrated} images migrated, ${failues} failed`, migrating: false })
                },
                e => this.setState({ message: e.message, migrating: false })
            );
    }

    render() {
        const { clientId, quantity, migrating, message } = this.state;

        return <section>
            <h1>Migrate client images</h1>

            <form className='data-form' onSubmit={formHandler} autoComplete='off'>

                <ct.NumberBox id='name' labelKey='Global:name' placeholderKey='Global:name' value={clientId} callback={val => this.setState({ clientId: this.validateClientId(val || 0) })} />

                <ct.NumberBox id='quantity' labelKey='Tools:numberOfImagesToMigrate' placeholderKey='Tools:numberOfImagesToMigrate' value={quantity} callback={val => this.setState({ quantity: this.validateQuantity(val || 0) })} />

                <div className='btn-toolbar'>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.migrateImages)} disabled={migrating}>Migrate</button>
                </div>

                {message ? <div className='alert alert-info'>{message}</div> : null}
            </form>
        </section>
    }
}

export default MigrateClientImages;