
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'
import { ApplicationState } from '../../../store';
import { IUser, UserAccessLog } from '../../../store/pages/users/types';
import * as auth from '../../../utils/auth';
import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import * as UserActions from '../../../store/pages/users/actions';
import * as ModalActions from '../../../store/global/modal/actions';
import * as LoginActions from '../../../store/pages/login/actions';
import { ValidationError } from '../../../store/global/types';
import { clickHandler, parseLocalDateTime } from '../../../utils/util';
import * as api from '../../../store/apiClient';
import ApiError from '../../global/apiError';
import Loading from '../../global/loading';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';

interface GetAccessLogResponse {
    accessLog: UserAccessLog[];
}

interface LocalProps {
    isNew: boolean;
    user: IUser | null;
}

interface LocalState {
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
}

interface Actions {
    saveUser: (isNew: boolean, userId: string | null, username: string, emailAddress: string, firstName: string, lastName: string, phoneNumber: string, clientAdmin: boolean, sysAdmin: boolean, password: string | null, mustChangePassword: boolean, deactivated: boolean, sessionTimeout: number | null) => void;
    closeModal: () => void;
    logout: () => void;
}

type UserFormProps = LocalState & Actions & LocalProps;

interface IUserFormState {
    username: ct.FormValue<string>;
    emailAddress: ct.FormValue<string>;
    firstName: ct.FormValue<string>;
    lastName: ct.FormValue<string>;
    phoneNumber: ct.FormValue<string>;
    systemAdmin: ct.FormValue<boolean>;
    clientAdmin: ct.FormValue<boolean>;
    mustChangePassword: ct.FormValue<boolean>;
    password: ct.FormValue<string | null>;
    deactivated: ct.FormValue<boolean>;
    sessionTimeout: ct.FormValue<number | null>;
    loadingAccessHistory: boolean;
    accessLog: UserAccessLog[];
    loadingAccessHistoryError: api.ApiError | null;
    userLoggedOut: boolean;
}

class UserForm extends React.Component<UserFormProps, IUserFormState> {

    constructor(props: UserFormProps) {
        super(props);

        this.state = this.buildStateFromProps(this.props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps(props: UserFormProps): IUserFormState {

        const {isNew, user} = props;

        return {
            username: this.validateUsername((isNew || !user) ? '' : user.userName),
            emailAddress: this.validateEmail((isNew || !user) ? '' : user.emailAddress || ''),
            firstName: this.validateFirstname((isNew || !user) ? '' : user.firstName || ''),
            lastName: this.validateLastname((isNew || !user) ? '' : user.lastName || ''),
            phoneNumber: this.validateTelephone((isNew || !user) ? '' : user.phoneNumber || ''),
            systemAdmin: this.validateSystemAdmin((isNew || !user) ? false : user.systemAdmin || false),
            clientAdmin: this.validateClientAdmin((isNew || !user) ? false : user.clientAdmin || false),
            mustChangePassword: this.validateMustChangePassword((isNew || !user) ? true : user.mustChangePassword),
            password: this.validatePassword(null),
            deactivated: this.validateDeactivated((isNew || !user) ? false : user.deactivated),
            sessionTimeout: this.validateSessionTimeout((isNew || !user) ? null: user.sessionTimeout),
            loadingAccessHistory: false,
            accessLog: [],
            loadingAccessHistoryError: null,
            userLoggedOut: false
        };
    }
    
    componentDidMount() {
        this.loadAccessHistory();
    }

    loadAccessHistory = () => {
        const { isNew, user, logout } = this.props;

        if (!isNew && user) {
            this.setState({ loadingAccessHistory: true });
            api.getWithAuth<GetAccessLogResponse>(`api/v1/user/${user.id}/accessLog`, logout)
                .subscribe(res => {
                    this.setState({ loadingAccessHistory: false, accessLog: res.accessLog.map(l => ({ ...l, created: parseLocalDateTime(l.created), lastRefreshed: parseLocalDateTime(l.lastRefreshed)})), loadingAccessHistoryError: null });
                }, err => {
                        this.setState({ loadingAccessHistory: false, accessLog: [], loadingAccessHistoryError: err });
                });
        }
    }

    componentDidUpdate(prevProps: UserFormProps) {
        // Only update state is user has changed
        const { user: prevUser, saveComplete: prevSaveComplete } = prevProps;
        const { user, saveComplete } = this.props;
        if ((!prevUser && user) || (prevUser && !user) || (prevUser && user && prevUser.id !== user.id)) {
            this.setState(this.buildStateFromProps(this.props), this.loadAccessHistory);
        }

        if (saveComplete && !prevSaveComplete) {
            setTimeout(() => { this.close(); }, 750);
        }
    }

    saveUser = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }
    
    private close = () => {
        this.props.closeModal();
    }

    forceLogout = () => {
        const { isNew, user, logout } = this.props;

        if (!isNew && user) {
            api.putWithAuth(`api/v1/user/${user.id}/revokeAllTokens`, {}, logout)
                .subscribe(_ => {
                    this.setState({ userLoggedOut: true }, () => setTimeout(() => this.setState({ userLoggedOut: false }), 5000));
                }, err => {});
        }
    }

    private save = () => {
        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {
            const { isNew, user, saveUser } = this.props;
            const { username, emailAddress, firstName, lastName, phoneNumber, clientAdmin, systemAdmin, password, mustChangePassword, deactivated, sessionTimeout } = this.state;

            const userId = isNew || !user ? null : user.id;
            saveUser(isNew,
                userId,
                username.value,
                emailAddress.value,
                firstName.value,
                lastName.value,
                phoneNumber.value,
                clientAdmin.value,
                systemAdmin.value,
                password.value,
                mustChangePassword.value,
                deactivated.value,
                sessionTimeout.value
            );
        }
    }

    usernameMustContainAtSymbol: v.Validation = (value: any) => {
        if (typeof (value === 'string')) {
            if (value && value.length > 0) {
                const atSymbolPos = value.indexOf("@");
                if (atSymbolPos < 1 || value.length - atSymbolPos < 4) {
                    return 'validation:usernameRequirementsNotMet';
                }
            }
        }
        return undefined;
    }

    validateUsername = (val: string) => v.validate(val, 'username', [v.required, this.usernameMustContainAtSymbol], this.props.validationErrors);
    validatePassword = (val: string | null) => v.validate(val, 'password', this.props.isNew?[v.required]:[], this.props.validationErrors);
    validateClientAdmin = (val: boolean) => v.validate(val, 'clientAdmin', [], this.props.validationErrors);
    validateSystemAdmin = (val: boolean) => v.validate(val, 'systemAdmin', [], this.props.validationErrors);
    validateEmail = (val: string) => v.validate(val, 'email', [v.required], this.props.validationErrors);
    validateFirstname = (val: string) => v.validate(val, 'firstname', [v.required], this.props.validationErrors);
    validateLastname = (val: string) => v.validate(val, 'lastname', [v.required], this.props.validationErrors);
    validateTelephone = (val: string) => v.validate(val, 'telephone', [], this.props.validationErrors);
    validateMustChangePassword = (val: boolean) => v.validate(val, 'mustChangePassword', [], this.props.validationErrors);
    validateDeactivated = (val: boolean) => v.validate(val, 'deactivated', [], this.props.validationErrors);
    validateSessionTimeout = (val: number | null) => v.validate(val, 'sessionTimeout', [], this.props.validationErrors);

    render() {
        const { t } = this.context;
        const { isNew, saveError, saveComplete } = this.props;
        const { username, password, clientAdmin, systemAdmin, emailAddress, firstName, lastName, phoneNumber, mustChangePassword, deactivated,
            loadingAccessHistory, accessLog, loadingAccessHistoryError, userLoggedOut, sessionTimeout } = this.state;

        let message: any;

        if (saveError) {
            message = <ApiError error={saveError} />;
        } else if (saveComplete) {
            message = (<div className='bg-success'>{t('Global:saveComplete')}</div>);
        } 

        const usernameFld = isNew || auth.isSystemAdmin()
            ? (<ct.TextBox id='username' labelKey='Global:username' placeholderKey='Global:username' hintKey='UserForm:usernameHint' value={username} callback={val => this.setState({ username: this.validateUsername(val) })} />)
            : <h1 className='user_title'>{isNew ? t('UserForm:addUser') : username.value}</h1>;

        const passwordFld = isNew || auth.isClientAdmin()
            ? (<ct.Password id='password' labelKey='Global:password' placeholderKey='Global:password' value={({...password, value:password.value||''})} callback={val => this.setState({ password: this.validatePassword(val) })} />)
            : null;

        const ca = auth.isClientAdmin() ? <ct.Checkbox id='clientAdmin' labelKey='UserForm:clientAdmin' value={clientAdmin} callback={val => this.setState({ clientAdmin: this.validateSystemAdmin(val) })} /> : null;
        const sa = auth.isSystemAdmin() ? <ct.Checkbox id='systemAdmin' labelKey='UserForm:systemAdmin' value={systemAdmin} callback={val => this.setState({ systemAdmin: this.validateSystemAdmin(val) })} /> : null;
        
        return <div className='userPage'>
            <form className='data-form' onSubmit={this.saveUser} autoComplete='off'>
                {usernameFld}
                <ct.Email id='email' labelKey='Global:emailAddress' placeholderKey='Global:emailAddress' value={emailAddress} callback={val => this.setState({ emailAddress: this.validateEmail(val) })} />

                <ct.TextBox id='firstname' labelKey='Global:firstName' placeholderKey='Global:firstName' value={firstName} callback={val => this.setState({ firstName: this.validateFirstname(val) })} />

                <ct.TextBox id='lastname' labelKey='Global:lastName' placeholderKey='Global:lastName' value={lastName} callback={val => this.setState({ lastName:this.validateLastname(val) })} />

                <ct.PhoneNumber id='telephone' labelKey='Global:phoneNumber' placeholderKey='Global:phoneNumber' value={phoneNumber} callback={val => this.setState({ phoneNumber: this.validateTelephone(val) })} />

                {ca}

                {sa}

                {passwordFld}

                <ct.Checkbox id='mustChangePassword' labelKey='UserForm:mustChangePassword' value={mustChangePassword} callback={val => this.setState({ mustChangePassword: this.validateMustChangePassword(val) })} />

                <ct.Checkbox id='deactivated' labelKey='UserForm:deactivated' value={deactivated} callback={val => this.setState({ deactivated: this.validateDeactivated(val) })} />

                <ct.NumberBox id='sessionTimeout' labelKey='UserForm:sessionTimeout' hintKey='UserForm:sessionTimeoutHint' value={sessionTimeout} callback={val => this.setState({ sessionTimeout: this.validateSessionTimeout(val) })} min='5' />

                <p />
                <div className='btn-toolbar'>
                    {!isNew ? <button className='btn btn-warning' onClick={e => clickHandler(e, this.forceLogout)}>{t('UserForm:forceLogOut')}</button> : null}
                </div>

                <p />
                {userLoggedOut ? <div className='bg-success'>{t('UserForm:userLoggedOut')}</div> : null}

                <p />

                {message}

                <p />
                <div className='btn-toolbar'>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)}>{t('Global:save')}</button>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{t('Global:cancel')}</button>
                </div>

                <p />

                <h4>{t('UserForm:accessLog')}</h4>
                {this.renderAccessHistory(loadingAccessHistory, accessLog, loadingAccessHistoryError) }
            </form>
        </div>;
    }

    renderAccessHistory = (loadingAccessHistory: boolean, accessLog: UserAccessLog[], loadingAccessHistoryError: api.ApiError | null) => {
        const { t } = this.context;
        const { timeFormat, dateFormat } = this.props;

        if (loadingAccessHistory) {
            return <Loading />
        }

        if (loadingAccessHistoryError) {
            return <ApiError error={ loadingAccessHistoryError} />
        }

        return <table className='table table-condensed'>
            <thead>
                <tr>
                    <th>{t('UserForm:loginDate')}</th>
                    <th>{t('UserForm:address')}</th>
                    <th>{t('UserForm:userAgent')}</th>
                </tr>
            </thead>
            <tbody>
                {accessLog.map((l, ix) => <tr key={ix}>
                    <td>{l.created.toAbbrDateTimeString(timeFormat, dateFormat, t)}</td>
                    <td>{l.address}</td>
                    <td>{l.userAgent}</td>
                </tr>)}
            </tbody>
        </table>

    }
};

const mapStateToProps = (state: ApplicationState) => ({
    saveComplete: state.users.saveComplete,
    saveError: state.users.saveError,
    validationErrors: state.users.validationErrors,
    timeFormat: state.venues.timeFormat,
    dateFormat: state.venues.dateFormat
});

const mapDispatchToProps = (dispatch: Dispatch) => (
    {
        saveUser: bindActionCreators(UserActions.actionCreators.saveUser, dispatch),
        closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
        logout: bindActionCreators(LoginActions.actionCreators.logout, dispatch)
    }
);

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,           // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(UserForm);
