
import * as React from 'react';
import * as PropTypes from 'prop-types'
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import * as LoginState from '../../../store/pages/login/types';
import * as LoginActions from '../../../store/pages/login/actions';
import LoginForm from './loginForm';
import ChangePasswordForm from './changePasswordForm';

// At runtime, Redux will merge together...
type LoginPageProps =
    LoginState.LoginState                       // ... state we've requested from the Redux store
    & typeof LoginActions.actionCreators        // ... plus action creators we've requested
    & RouteComponentProps<{}>;                 // ... plus incoming routing parameters

class LoginPage extends React.Component<LoginPageProps, {}> {

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        // ensure all tokens are cleared if we get here
        this.props.ensureSignedOut();
    }

    login = (username: string, password: string) => {
        this.props.login(username, password);
    }

    changePassword = (at: string, oldPassword: string, newPassword: string, confPassword: string) => {
        this.props.changeUserPassword(at, oldPassword, newPassword, confPassword);
    }

    render() {
        const body = this.props.mustChangePassword && this.props.at !== null
            ? <ChangePasswordForm at={this.props.at} changePassword={this.changePassword} loginError={this.props.loginError} />
            : <LoginForm loggingIn={this.props.loggingIn} login={this.props.login} loginError={this.props.loginError} />;

        return <div className='login-page'>
                  <div className='login-content'>
                    {body}
                  </div>
               </div>;
    }
};

const matStateToProps = (state: ApplicationState) => state.login;

// Wire up the React component to the Redux store
export default connect(
    matStateToProps,                    // Selects which state properties are merged into the component's props
    LoginActions.actionCreators        // Selects which action creators are merged into the component's props
)(LoginPage);

