
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { Customer } from '../../../store/pages/customer/types';
import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import {upper, isNullOrEmpty } from '../../../utils/util';
import NavButtons from './navButtons';

interface RegistrationReturningCustomerProps {
    customers: Customer[];
    headerText: string;
    birthYear: number;
    birthMonth: number;
    birthDay: number;
    cancel: () => void;
    registerAsNewCustomer: () => void;
    next: (customer: Customer) => void;
}

interface RegistrationReturningCustomerState {
    postalCode: ct.FormValue<string>;
    customer: Customer|null;
}

class RegistrationReturningCustomer extends React.Component<RegistrationReturningCustomerProps, RegistrationReturningCustomerState> {

    constructor(props: RegistrationReturningCustomerProps) {
        super(props);

        this.state = {
            postalCode: this.validatePostalCode(''),
            customer: null
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    matchPostcode: v.Validation = (postcode: string) => {

        if (isNullOrEmpty(postcode) || postcode.length < 4) {
            this.setState({ customer: null });
            return 'validation:required';
        }

        var upperCasePostcode = postcode.toUpperCase().replace(/\s/g, '');

        var filtered = this.props.customers.filter(c => c.postalCode.toUpperCase().replace(/\s/g, '') === upperCasePostcode);

        if (filtered.length > 0) {
            const { birthYear, birthMonth, birthDay } = this.props;
            const matched = filtered[0];
            this.setState({
                customer: {
                    ...matched,
                    birthYear: matched.birthYear > 0 ? matched.birthYear : birthYear,
                    birthMonth: matched.birthMonth > 0 ? matched.birthMonth : birthMonth,
                    birthDay: matched.birthDay > 0 ? matched.birthDay : birthDay
                }
            });
            return undefined;
        } else {
            this.setState({ customer: null });
            return undefined;
        }
    };

    validatePostalCode = (val: string) => v.validate(upper(val), 'postalCode', [this.matchPostcode], []);

    submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const { customer, postalCode } = this.state;

        if (!postalCode.isValid && postalCode.value.length < 4) {
            // TODO: Show error message!
        } else if (customer === null) {
            this.props.registerAsNewCustomer();
        } else {
            this.props.next(customer);
        }
    }

    registerAsNewCustomer = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        this.props.registerAsNewCustomer();
    }

    reSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        this.props.cancel();
    }

    render() {
        const hdr = <div>{isNullOrEmpty(this.props.headerText) ? this.context.t('Registration:welcomeBackMessage') : this.props.headerText}<button type='button' className='btn btn-basic' onClick={e => this.registerAsNewCustomer(e)} style={({ marginLeft: '20px', float: 'right' })}>{this.context.t('Registration:registerAsNewCustomer')}</button></div>;
        const first = this.props.customers[0];
        const { customer, postalCode } = this.state;
        return (
            <form className='registration-form' onSubmit={this.submit} autoComplete='off'>
                <div>
                    <strong>{this.context.t('Registration:welcomeBack{n}', { n: `${first.firstname} ${first.lastname}` })}</strong>
                    <p />
                    {hdr}
                </div>

                <p/>

                <div>{this.context.t('Registration:confirmPostcode')}</div>

                <p/>

                <div>
                    <ct.TextBox key='postalCode' id='postalCode' labelKey='Global:confirmPostcode' placeholderKey='Global:confirmPostcode' value={postalCode} callback={val => this.setState({ postalCode: this.validatePostalCode(val) })} style={({maxWidth: '500px'})}/>
                    {customer === null && postalCode.isValid ? <div style={{ color: '#fcaf32' }}>{this.context.t('Registration:unmatchedPostcode')}</div> : null}
                    <p />
                    <div className='alert alert-info'>{this.context.t('Registration:confirmPasswordNotes')}</div>
                </div>

                <p/>

                <NavButtons t={this.context.t} cancel={this.props.cancel} preventNext={customer === null && this.state.postalCode.value.length < 4} />
            </form>);

    }

}

export default RegistrationReturningCustomer;