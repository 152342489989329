
import * as PropTypes from 'prop-types'
import { clickHandler } from '../../../../utils/util';
import PeriodSelection from './../periodSelection';
import { AnalyticsProductReportType } from './../types';
import { DateFormat, DayOfWeek } from '../../../../store/pages/venues/types';
import { ITranslationContext } from '../../../../translations';
import ComparisonPeriodSelection from './../comparisonPeriodSelection';
import { AnalyticsPeriod, ComparisonPeriod } from '../../../../store/pages/analytics/types';


interface ProductsReportPageHeaderProps {
    firstDayOfWeek: DayOfWeek;
    dateFormat: DateFormat;
    name: string;
    period: AnalyticsPeriod;
    reportType: AnalyticsProductReportType;
    comparisonPeriod: ComparisonPeriod;
    hasUnappliedChanges: boolean;
    updatePeriod: (period: AnalyticsPeriod) => void
    updateComparisonPeriod: (period: ComparisonPeriod) => void
    updateReportType: (reportType: AnalyticsProductReportType) => void;
    refreshReport: () => void;
    save: () => void;
}

const ProductsReportPageHeader = (props: ProductsReportPageHeaderProps, context: ITranslationContext) => {

    const { t } = context;
    const { name, period, comparisonPeriod, reportType, firstDayOfWeek, dateFormat, updatePeriod, updateComparisonPeriod, updateReportType, refreshReport, hasUnappliedChanges, save } = props;

    const reportTypeOptions = Object.keys(AnalyticsProductReportType)
        .filter(k => typeof AnalyticsProductReportType[k as any] === 'number')
        .map(k => ({ key: AnalyticsProductReportType[k as any].toString(), name: t(`AnalyticsProductReportType:${k}`) }));

    return <div className='analytics-report-content-header'>
        <div className='analytics-report-content-header-name'>{name}</div>
        <div className='analytics-report-content-header-period-selection-wrapper'>
            <div className='analytics-report-content-header-period-selections'>
                <PeriodSelection
                    period={period}
                    firstDayOfWeek={firstDayOfWeek}
                    dateFormat={dateFormat}
                    periodChanged={(p) => updatePeriod(p)} />
                <ComparisonPeriodSelection
                    period={period}
                    comparisonPeriod={comparisonPeriod}
                    firstDayOfWeek={firstDayOfWeek}
                    dateFormat={dateFormat}
                    periodChanged={(p) => updateComparisonPeriod(p)} />
                {/*<ct.Select
                    id='reportType'
                    classNames='analytics-report-period-selection'
                    labelKey=''
                    value={({ controlId: 'reportPeriod', isValid: true, hasValidation: false, value: reportType.toString() })}
                    options={reportTypeOptions}
                    callback={val => updateReportType(parseInt(val))}
                    minimal={true} />*/}
            </div>
            <div>
                <button className={`btn btn-${hasUnappliedChanges ? 'primary' : 'default'} btn-sm`} onClick={e => clickHandler(e, refreshReport)} disabled={!hasUnappliedChanges}>{t('Global:apply')}</button>
                <button className='btn btn-info btn-sm' style={{ marginLeft: '6px'}} onClick={e => clickHandler(e, save)}>{t('Global:save')}</button>
            </div>
        </div>
    </div>
}

ProductsReportPageHeader.contextTypes = {
    t: PropTypes.func
}

export default ProductsReportPageHeader