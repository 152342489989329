
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { ITranslationContext } from '../../translations';


interface CurrencyAmountProps {
    amount: number;
    currencySymbol?: string;
}

const CurrencyAmount = (props: CurrencyAmountProps, context: ITranslationContext) => {
    const { t } = context;
    const { amount, currencySymbol} = props;

    return <span>{`${currencySymbol || t('Global:currencySymbol')}${amount.toFixed(2)}`}</span>
}

CurrencyAmount.contextTypes = {
    t: PropTypes.func
}

export default CurrencyAmount