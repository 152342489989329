
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'

import * as ct from '../../../global/controls';
import * as v from '../../../global/validation';
import * as api from '../../../../store/apiClient';
import { ApplicationState } from '../../../../store';
import * as FeeActions from '../../../../store/pages/fees/actions';
import * as ModalActions from '../../../../store/global/modal/actions';
import { TaxRate } from '../../../../store/pages/taxRates/types';
import { clickHandler } from '../../../../utils/util';
import ApiError from '../../../global/apiError';
import { ValidationError } from '../../../../store/global/types';
import { Fee, FeeType } from '../../../../store/pages/fees/types';

interface MappedReduxState {
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
    taxRates: TaxRate[];
}

interface LocalProps {
    isNew: boolean;
    venueId: string;
    fee: Fee | null;
}

interface Actions {
    closeModal: () => void;
    saveFee: (isNew: boolean, feeId: string | null, fee: Fee) => void;
}

type FeeFormProps = MappedReduxState & Actions & LocalProps;

interface FeeFormState {
    name: ct.FormValue<string>;
    feeType: ct.FormValue<FeeType>;
    percentage: ct.FormValue<number>;
    maxAmount: ct.FormValue<number>;
    fixedAmount: ct.FormValue<number>;
    taxRateId: ct.FormValue<string>;
    accountingCategory: ct.FormValue<string>;
    accountingDepartment: ct.FormValue<string>;
    nominalCode: ct.FormValue<string>;
    archived: ct.FormValue<boolean>;
    validationError: string | null;
}

class FeeForm extends React.Component<FeeFormProps, FeeFormState> {

    constructor(props: FeeFormProps) {
        super(props);

        this.state = this.buildStateFromProps(this.props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps(props: FeeFormProps): FeeFormState {

        const { isNew, fee } = props;

        return {
            name: this.validateName((isNew || !fee) ? '' : fee.name),
            feeType: this.validateFeeType((isNew || !fee) ? FeeType.Fixed : fee.feeType),
            percentage: this.validatePercentage((isNew || !fee) ? 0 : Math.floor(fee.percentage * 100)),
            maxAmount: this.validateMaxAmount((isNew || !fee) ? 0 : fee.maxAmount),
            fixedAmount: this.validateFixedAmount((isNew || !fee) ? 0 : fee.fixedAmount),
            taxRateId: this.validateTaxRate((isNew || !fee) ? '' : fee.taxRateId),
            archived: this.validateArchived((isNew || !fee) ? false : fee.archived),
            accountingCategory: this.validateAccountingCategory((isNew || !fee) ? '' : fee.accountingCategory),
            accountingDepartment: this.validateAccountingDepartment((isNew || !fee) ? '' : fee.accountingDepartment),
            nominalCode: this.validateNominalCode((isNew || !fee) ? '' : fee.nominalCode),
            validationError: null
        };
    }

    componentDidUpdate(prevProps: FeeFormProps) {
        // Only update state is resource has changed
        const { fee: prevFee, saveComplete: prevSaveComplete } = prevProps;
        const { fee, saveComplete } = this.props;
        if ((!prevFee && fee) || (prevFee && !fee) || (prevFee && fee && prevFee.id !== fee.id)) {
            this.setState(this.buildStateFromProps(this.props));
        }

        if (saveComplete && !prevSaveComplete) {
            setTimeout(() => { this.close(); }, 750);
        }
    }

    private saveFee = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }

    private close = () => {
        this.props.closeModal();
    }

    private save = () => {
        if (!v.isValid(this.state)) {
            this.setState({ validationError: null });
            // TODO: Show error message!
        } else {
            const feeId = this.props.isNew || !this.props.fee ? null : this.props.fee.id;
            const { isNew, venueId, saveFee } = this.props;
            const { name, feeType, percentage, fixedAmount, maxAmount, taxRateId, accountingCategory, accountingDepartment, nominalCode, archived } = this.state;

            saveFee(isNew,
                feeId,
                {
                    id: feeId || '',
                    venueId: venueId,
                    name: name.value,
                    feeType: feeType.value,
                    percentage: percentage.value === 0 ? 0 : percentage.value / 100.0,
                    fixedAmount: fixedAmount.value,
                    maxAmount: maxAmount.value,
                    taxRateId: taxRateId.value,
                    accountingCategory: accountingCategory.value,
                    accountingDepartment: accountingDepartment.value,
                    nominalCode: nominalCode.value,
                    archived: archived.value
                });

            this.setState({ validationError: null });
        }
    }

    taxRateChanged = (newValue: string) => {
        this.setState({ taxRateId: this.validateTaxRate(newValue) });
    }

    validateName = (val: string) => v.validate(val, 'name', [v.required], this.props.validationErrors);

    validateFeeType = (val: FeeType) => v.validate(val, 'feeType', [v.required], this.props.validationErrors);
    validatePercentage = (val: number) => v.validate(val, 'percentage', [v.required], this.props.validationErrors);
    validateMaxAmount = (val: number) => v.validate(val, 'maxAmount', [v.required], this.props.validationErrors);
    validateFixedAmount = (val: number) => v.validate(val, 'fixedAmount', [v.required], this.props.validationErrors);
    validateTaxRate = (val: string) => v.validate(val, 'taxRate', [v.required], this.props.validationErrors);
    validateArchived = (val: boolean) => v.validate(val, 'archived', [], this.props.validationErrors);
    validateAccountingCategory = (val: string) => v.validate(val, 'accountingCategory', [], this.props.validationErrors);
    validateAccountingDepartment = (val: string) => v.validate(val, 'accountingDepartment', [], this.props.validationErrors);
    validateNominalCode = (val: string) => v.validate(val, 'nominalCode', [], this.props.validationErrors);

    onFeeTypeChanged = (val: string) => {
        const intVal = parseInt(val);
        this.setState(s => ({ feeType: this.validateFeeType(intVal) }))
    }

    render() {

        let message: any;
        const { t } = this.context;
        const { name, feeType, fixedAmount, maxAmount, percentage, taxRateId, accountingCategory, accountingDepartment, nominalCode, archived, validationError } = this.state;
        const { saveError, saveComplete, taxRates, isNew } = this.props;

        if (saveError) {
            message = <ApiError error={saveError} />;
        } else if (validationError) {
            message = (<div className='alert alert-danger'>{validationError}</div>);
        }
        else if (saveComplete) {
            message = (<div className='bg-success'>{t('Global:saveComplete')}</div>);
        }

        const taxRateOptions = [{ key: '', name: t('FeeForm:selectTaxRate') }].concat(taxRates.map(c => ({ key: c.id, name: c.name })));

        const feeTypeOptions = Object.keys(FeeType).filter(key => typeof FeeType[key as any] === 'number').map(key => ({ key: FeeType[key as any].toString(), name: t(`FeeType:${key}`) }));

        return <div className='feeForm'>
            <h2 className='fee_title'>{isNew ? t('FeeForm:addFee') : name.value}</h2>

            <form className='data-form' onSubmit={this.saveFee} autoComplete='off'>
                <ct.TextBox id='name' labelKey='FeeForm:name' placeholderKey='FeeForm:name' value={name} callback={val => this.setState({ name: this.validateName(val) })} />

                <ct.Select id='feeType' labelKey='FeeForm:feeType' value={({ ...feeType, value: feeType.value.toString() })} callback={this.onFeeTypeChanged} options={feeTypeOptions} />

                {
                    feeType.value === FeeType.Fixed
                        ? <div>
                            <ct.NumberBox id='fixedAmount' labelKey='FeeForm:fixedAmount' placeholderKey='FeeForm:fixedAmount' value={fixedAmount} callback={val => this.setState({ fixedAmount: this.validateFixedAmount(val || 1) })} step='1' min='0' />
                        </div>
                        : <div>
                            <ct.NumberBox id='percentage' labelKey='FeeForm:percentage' placeholderKey='FeeForm:percentage' value={percentage} callback={val => this.setState({ percentage: this.validatePercentage(val || 0) })} step='1' min='0' />
                            <ct.NumberBox id='maxAmount' labelKey='FeeForm:maxAmount' placeholderKey='FeeForm:maxAmount' value={maxAmount} callback={val => this.setState({ maxAmount: this.validateMaxAmount(val || 0) })} step='1' min='0' />
                        </div>
                }

                <ct.Select id='taxRate' labelKey='FeeForm:taxRate' value={({ ...taxRateId, value: taxRateId.value.toString() })} callback={this.taxRateChanged} options={taxRateOptions} />

                <ct.TextBox id='accountingCategory' labelKey='ProductForm:accountingCategory' placeholderKey='ProductForm:accountingCategory' value={accountingCategory} callback={val => this.setState({ accountingCategory: this.validateAccountingCategory(val) })} />

                <ct.TextBox id='accountingDepartment' labelKey='ProductForm:accountingDepartment' placeholderKey='ProductForm:accountingDepartment' value={accountingDepartment} callback={val => this.setState({ accountingDepartment: this.validateAccountingDepartment(val) })} />

                <ct.TextBox id='nominalCode' labelKey='ProductForm:nominalCode' placeholderKey='ProductForm:nominalCode' value={nominalCode} callback={val => this.setState({ nominalCode: this.validateNominalCode(val) })} />

                <ct.Checkbox id='archived' labelKey='Global:archive' value={archived} callback={val => this.setState({ archived: this.validateArchived(val) })} />

                {message}

                <p />
                <div className='btn-toolbar'>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)}>{t('Global:save')}</button>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{t('Global:cancel')}</button>
                </div>
            </form>
        </div>;
    }
};


const mapStateToProps = (state: ApplicationState) => ({
    saveComplete: state.fees.saveComplete,
    saveError: state.fees.saveError,
    validationErrors: state.fees.validationErrors,
    taxRates: state.taxRates.taxRates
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    saveFee: bindActionCreators(FeeActions.actionCreators.saveFee, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch)
});

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(FeeForm);
