
// https://blog.angularindepth.com/how-to-reduce-action-boilerplate-90dc3d389e2b

import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as et from './types';
import * as lt from '../login/types';
import { isNullOrEmpty } from '../../../utils/util';
import { IStore } from '../..';

export type EmailActions = et.LoadEmails | et.ReceiveEmails;

export const actionCreators = {
    loadEmails: (pageNumber: number, pageSize: number, to: string, status: et.EmailStatus | -1, fromDate: Date | null, toDate: Date | null) => ({ type: et.EmailActionTypes.RequestEmails, pageNumber: pageNumber, pageSize: pageSize, to: to, status: status, fromDate: fromDate, toDate: toDate }),
    loadEmailsComplete: (emails: et.Email[], page: number, maxPage: number, err: api.ApiError | null) => ({ type: et.EmailActionTypes.ReceivedEmails, emails: emails, page: page, maxPage: maxPage, error: err })
}

interface IGetEmailsResponse {
    emails: et.Email[];
    page: number;
    hasMorePages: boolean;
}

const loadEmails = (pageNumber: number, pageSize: number, to: string, status: et.EmailStatus | -1, fromDate: Date | null, toDate: Date | null) => {

    let url = `api/v1/email/?pageNumber=${pageNumber}&pageSize=${pageSize}`;

    if (!isNullOrEmpty(to)) {
        url = url + `&to=${to}`;
    }

    if (status in et.EmailStatus) {
        url = url + `&status=${status}`;
    }

    if (fromDate) {
        url = url + `&fromDate=${fromDate.toYMDDateString()}`;
    }

    if (toDate) {
        url = url + `&toDate=${toDate.toYMDDateString()}`;
    }

    return Observable.defer(() => api.getJson<IGetEmailsResponse>(url))
        .map(response => actionCreators.loadEmailsComplete(response.emails, response.page, response.hasMorePages ? response.page + 1 : response.page, null));
}

export const handleClientChange = (store: IStore) => {
    if (store.value.emails.isActive) {
        return [() => actionCreators.loadEmails(1, store.value.emails.pageSize, '', -1, null, null)]
    } else {
        return [() => actionCreators.loadEmailsComplete([], 1, 1, null)]
    }
}

export const loadEmailsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        et.EmailActionTypes.RequestEmails,
        action => loadEmails(action.pageNumber, action.pageSize, action.to, action.status, action.fromDate, action.toDate),
        err => actionCreators.loadEmailsComplete([], 1, 1, err));

