
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../../global/controls';
import * as v from '../../../global/validation';

import { ActivityFormat, ActivityFormatLink, ActivityFormatLinkCustomerCategoryMap } from '../../../../store/pages/activityFormats/types';
import { formHandler, clickHandler, isNullOrEmpty } from '../../../../utils/util';
import { Time } from '../../../../store/global/types';
import { ActivityCustomerCategory } from './activityFormatForm';
import { CustomerCategory } from '../../../../store/pages/customerCategories/types';
import { quillToolbarWithImageAndLinks } from '../../../global/quillSettings';
import { Product } from '../../../../store/pages/products/types';

interface AdditionalActivityFormProps {
    parentActivityFormatId: string;
    activityFormats: ActivityFormat[];
    activityCustomerCategories: ActivityCustomerCategory[];
    linkedActivityFormat: ActivityFormatLink;
    customerCategories: CustomerCategory[];
    products: Product[];
    webDescriptionStyle: React.CSSProperties | undefined;
    close: () => void;
    save: (linkedActivityFormat: ActivityFormatLink) => void;
}

interface CustomerCategoryMap {
    id: number;
    originalActivityCustomerCategoryId: string;
    originalActivityCustomerCategoryName: string;
    linkedActivityCustomerCategoryId: string;
    upsellProductId: string | null;
}

interface AdditionalActivityFormState {
    linkedActivityFormatId: ct.FormValue<string | null>;
    offerAsAlternative: ct.FormValue<boolean>;
    offerAsUpsell: ct.FormValue<boolean>;
    minGapBeforeMainActivity: ct.FormValue<Time | null>;
    maxGapBeforeMainActivity: ct.FormValue<Time | null>;
    minGapAfterMainActivity: ct.FormValue<Time | null>;
    maxGapAfterMainActivity: ct.FormValue<Time | null>;
    maxUpsellTimeSlotsBefore: ct.FormValue<number>;
    maxUpsellTimeSlotsAfter: ct.FormValue<number>;
    customerCategoryMap: CustomerCategoryMap[];
    webDescriptionOverride: ct.FormValue<string>;
    errorMessage: string | null;
}

class AdditionalActivityForm extends React.Component<AdditionalActivityFormProps, AdditionalActivityFormState> {

    constructor(props: AdditionalActivityFormProps) {
        super(props);

        this.state = this.buildStateFromProps(this.props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    buildStateFromProps = (props: AdditionalActivityFormProps) => {
        const { linkedActivityFormat } = props;

        const activityformat = props.activityFormats.find(f => f.id === linkedActivityFormat.linkedActivityFormatId);

        return {
            linkedActivityFormatId: this.validateLinkedActivityFormatId(linkedActivityFormat.linkedActivityFormatId),
            offerAsAlternative: this.validateOfferAsAlternative(linkedActivityFormat.offerAsAlternative),
            offerAsUpsell: this.validateOfferAsUpsell(linkedActivityFormat.offerAsUpsell),
            minGapBeforeMainActivity: this.validateMinGapBeforeMainActivity(linkedActivityFormat.minGapBeforeMainActivity),
            maxGapBeforeMainActivity: this.validateMaxGapBeforeMainActivity(linkedActivityFormat.maxGapBeforeMainActivity),
            minGapAfterMainActivity: this.validateMinGapAfterMainActivity(linkedActivityFormat.minGapAfterMainActivity),
            maxGapAfterMainActivity: this.validateMaxGapAfterMainActivity(linkedActivityFormat.maxGapAfterMainActivity),
            customerCategoryMap: this.buildCustomerCategoryMap(activityformat ? activityformat : null, linkedActivityFormat, props.activityCustomerCategories, props.customerCategories),
            webDescriptionOverride: this.validateWebDescriptionOverride(linkedActivityFormat.webDescriptionOverride || ''),
            maxUpsellTimeSlotsBefore: this.validateMaxUpsellTimeSlotsBefore(linkedActivityFormat.maxUpsellTimeSlotsBefore),
            maxUpsellTimeSlotsAfter: this.validateMaxUpsellTimeSlotsAfter(linkedActivityFormat.maxUpsellTimeSlotsAfter),
            errorMessage: null
        };
    }

    save = () => {
        const { t } = this.context;
        const { linkedActivityFormatId, offerAsAlternative, offerAsUpsell, minGapBeforeMainActivity, maxGapBeforeMainActivity,
            minGapAfterMainActivity, maxGapAfterMainActivity, customerCategoryMap, webDescriptionOverride,
            maxUpsellTimeSlotsBefore, maxUpsellTimeSlotsAfter } = this.state;
        const { linkedActivityFormat, save } = this.props;

        if (!v.isValid(this.state) || customerCategoryMap.filter(c => isNullOrEmpty(c.linkedActivityCustomerCategoryId)).length > 0) {
            this.setState({ errorMessage: t('Global:formNotValid') });
            return;
        }

        if (minGapBeforeMainActivity.value && maxGapBeforeMainActivity.value && minGapBeforeMainActivity.value > maxGapBeforeMainActivity.value) {
            this.setState({ errorMessage: t('ActivityFormatAdditionalActivities:minGapBeforeMainActivityAfterMaxGap') });
            return;
        }

        if (minGapAfterMainActivity.value && maxGapAfterMainActivity.value && minGapAfterMainActivity.value > maxGapAfterMainActivity.value) {
            this.setState({ errorMessage: t('ActivityFormatAdditionalActivities:minGapAfterMainActivityAfterMaxGap') });
            return;
        }

        const cleansedWebDescriptionOverride = webDescriptionOverride.value === '<p><br></p>' ? null : webDescriptionOverride.value;

        save({
            key: linkedActivityFormat.key,
            linkedActivityFormatId: linkedActivityFormatId.value || '',
            offerAsAlternative: offerAsAlternative.value,
            offerAsUpsell: offerAsUpsell.value,
            minGapBeforeMainActivity: minGapBeforeMainActivity.value,
            maxGapBeforeMainActivity: maxGapBeforeMainActivity.value,
            minGapAfterMainActivity: minGapAfterMainActivity.value,
            maxGapAfterMainActivity: maxGapAfterMainActivity.value,
            customerCategoryMap: customerCategoryMap,
            webDescriptionOverride: cleansedWebDescriptionOverride,
            maxUpsellTimeSlotsBefore: maxUpsellTimeSlotsBefore.value,
            maxUpsellTimeSlotsAfter: maxUpsellTimeSlotsAfter.value
        });
    }

    validateLinkedActivityFormatId = (val: string | null) => v.validate(val, 'linkedActivityFormatId', [v.required], []);
    validateOfferAsAlternative = (val: boolean) => v.validate(val, 'offerAsAlternative', [], []);
    validateOfferAsUpsell = (val: boolean) => v.validate(val, 'offerAsUpsell', [], []);
    validateUpsellProductId = (val: string | null) => v.validate(val, 'upsellProductId', [], []);
    validateMinGapBeforeMainActivity = (val: Time | null) => v.validate(val, 'minGapBeforeMainActivity', [], []);
    validateMaxGapBeforeMainActivity = (val: Time | null) => v.validate(val, 'maxGapBeforeMainActivity', [], []);
    validateMinGapAfterMainActivity = (val: Time | null) => v.validate(val, 'minGapAfterMainActivity', [], []);
    validateMaxGapAfterMainActivity = (val: Time | null) => v.validate(val, 'maxGapAfterMainActivity', [], []);
    validateWebDescriptionOverride = (val: string) => v.validate(val, 'webDescriptionOverride', [], []);
    validateMaxUpsellTimeSlotsBefore = (val: number) => v.validate(val, 'maxUpsellTimeSlotsBefore', [v.required], []);
    validateMaxUpsellTimeSlotsAfter = (val: number) => v.validate(val, 'maxUpsellTimeSlotsAfter', [v.required], []);

    selectedActivityChanged = (newActivityFormatId: string) => {
        const newActivityFormat = this.props.activityFormats.find(f => f.id === newActivityFormatId);

        this.setState((_, p) => ({
            linkedActivityFormatId: this.validateLinkedActivityFormatId(newActivityFormat ? newActivityFormat.id : null),
            customerCategoryMap: this.buildCustomerCategoryMap(newActivityFormat ? newActivityFormat : null, p.linkedActivityFormat, p.activityCustomerCategories, p.customerCategories),
        }));
    }

    buildCustomerCategoryMap = (activityFormat: ActivityFormat | null, linkedActivityFormat: ActivityFormatLink, activityCustomerCategories: ActivityCustomerCategory[], customerCategories: CustomerCategory[]) => {
        if (activityFormat === null)
            return [];

        return activityCustomerCategories
            .filter(cc => cc.enabled.value)
            .map(cc => {
                const existingMapping = linkedActivityFormat.customerCategoryMap.find(cm => cm.originalActivityCustomerCategoryId === cc.customerCategoryId);
                const cat = customerCategories.find(c => c.id === cc.customerCategoryId);
                return {
                    id: existingMapping ? existingMapping.id : 0,
                    originalActivityCustomerCategoryId: cc.customerCategoryId,
                    originalActivityCustomerCategoryName: cat ? cat.name : '',
                    linkedActivityCustomerCategoryId: existingMapping ? existingMapping.linkedActivityCustomerCategoryId : activityFormat.customerCategories.findIndex(ac => ac.customerCategoryId === cc.customerCategoryId) >= 0 ? cc.customerCategoryId : '',
                    upsellProductId: existingMapping ? existingMapping.upsellProductId : '',
                }
            });
    }

    linkedCustomerCategoryChanged = (originalActivityCustomerCategoryId: string, linkedActivityCustomerCategoryId: string) => {
        this.setState(s => ({ customerCategoryMap: s.customerCategoryMap.map(m => m.originalActivityCustomerCategoryId === originalActivityCustomerCategoryId ? { ...m, linkedActivityCustomerCategoryId: linkedActivityCustomerCategoryId } : m) }))
    }

    upsellProductChanged = (originalActivityCustomerCategoryId: string, upsellProductId: string) => {
        this.setState(s => ({ customerCategoryMap: s.customerCategoryMap.map(m => m.originalActivityCustomerCategoryId === originalActivityCustomerCategoryId ? { ...m, upsellProductId: upsellProductId } : m) }))
    }

    render() {
        const { t } = this.context;
        const { close, parentActivityFormatId, activityFormats, customerCategories, products, webDescriptionStyle } = this.props;
        const { errorMessage, linkedActivityFormatId, offerAsAlternative, offerAsUpsell, minGapBeforeMainActivity, maxGapBeforeMainActivity, minGapAfterMainActivity,
            maxGapAfterMainActivity, customerCategoryMap, webDescriptionOverride, maxUpsellTimeSlotsBefore, maxUpsellTimeSlotsAfter } = this.state;

        const activityformatOptions = [{ key: '', name: t('ActivityFormatAdditionalActivities:selectActivity') }].concat(activityFormats.filter(f => !f.archived).map(f => ({ key: f.id, name: f.name })))

        const selectedActivityformat = activityFormats.find(f => linkedActivityFormatId.value && f.id === linkedActivityFormatId.value);

        const selectedActivityCategories = selectedActivityformat ? customerCategories.filter(c => selectedActivityformat.customerCategories.findIndex(ac => ac.customerCategoryId === c.id) >= 0) : [];
        const selectedActivityCategorySelections = [{ key: '', name: t('Global:selectCategory')}].concat(selectedActivityCategories.map(cc => ({ key: cc.id, name: cc.name })))

        const activityProducts = selectedActivityformat ? selectedActivityformat.products : [];
        const productSelections = [{ key: '', name: t('ActivityFormatAdditionalActivities:standardPrice') }]
            .concat(activityProducts.map(p => {
                const prod = products.filter(prd => prd.id === p.productId)[0];
                return { key: p.productId, name: prod ? prod.name : '' };
            }));

        const controlStyle = ({ minHeight: '10px', margin: '0 8px' });

        return <div>
            <h2>{t('ActivityFormatForm:additionalActivities')}</h2>

            <form className='data-form' onSubmit={e => formHandler(e)} autoComplete='off'>

                <ct.Select id='linkedActivityFormatId' labelKey='ActivityFormatAdditionalActivities:activity' value={{ ...linkedActivityFormatId, value: linkedActivityFormatId.value || '' }} callback={this.selectedActivityChanged} options={activityformatOptions} />

                {linkedActivityFormatId.value && parentActivityFormatId !== linkedActivityFormatId.value ?
                    <section className="panel panel-default">
                        <div className="panel-heading">
                            <h2>{t('ActivityFormatAdditionalActivities:alternateAvailability')}</h2>
                        </div>
                        <div className="panel-body">
                            <div className='alert alert-info'>{t('ActivityFormatAdditionalActivities:alternateActivityDescription')}</div>
                            <ct.Checkbox id='offerAsAlternative' labelKey='ActivityFormatAdditionalActivities:alternateActivity' value={offerAsAlternative} callback={val => this.setState({ offerAsAlternative: this.validateOfferAsAlternative(val) })} />
                        </div>
                    </section>
                    : null}

                {linkedActivityFormatId.value ?
                    <section className="panel panel-default">
                        <div className="panel-heading">
                            <h2>{t('ActivityFormatAdditionalActivities:upsellActivity')}</h2>
                        </div>
                        <div className="panel-body">
                            <div className='alert alert-info'>{t('ActivityFormatAdditionalActivities:upsellActivityDescription')}</div>
                            <ct.Checkbox id='offerAsUpsell' labelKey='ActivityFormatAdditionalActivities:upsellActivity' value={offerAsUpsell} callback={val => this.setState({ offerAsUpsell: this.validateOfferAsUpsell(val) })} />

                            {offerAsUpsell.value
                                ? <div>
                                    <ct.HtmlInput id='webDescriptionOverride' labelKey='ActivityFormatAdditionalActivities:webDescriptionOverride' value={webDescriptionOverride} callback={val => this.setState({ webDescriptionOverride: this.validateWebDescriptionOverride(val)})} modules={quillToolbarWithImageAndLinks} editorStyle={webDescriptionStyle} inlineStyles={true} />

                                    <h3>{t('ActivityFormatAdditionalActivities:upsellActivityEarlierTime')}</h3>
                                    <div className='alert alert-info'>{t('ActivityFormatAdditionalActivities:upsellActivityEarlierTimeDescription')}</div>
                                    <div className='row'>
                                        <div className='col-xs-3'>
                                            <ct.Time id='minGapBeforeMainActivity' labelKey='ActivityFormatForm:minGap' value={minGapBeforeMainActivity} callback={val => this.setState({ minGapBeforeMainActivity: this.validateMinGapBeforeMainActivity(val) })} />
                                        </div>
                                        <div className='col-xs-3'>
                                            <ct.Time id='maxGapBeforeMainActivity' labelKey='ActivityFormatForm:maxGap' value={maxGapBeforeMainActivity} callback={val => this.setState({ maxGapBeforeMainActivity: this.validateMaxGapBeforeMainActivity(val) })} />
                                        </div>
                                        <div className='col-xs-3'>
                                            <ct.NumberBox id='maxUpsellTimeSlotsBefore' labelKey='ActivityFormatForm:maxUpsellTimeSlotsBefore' value={maxUpsellTimeSlotsBefore} callback={val => this.setState({ maxUpsellTimeSlotsBefore: this.validateMaxUpsellTimeSlotsBefore(val || 1) })} min='1' />
                                        </div>
                                    </div>

                                    <h3>{t('ActivityFormatAdditionalActivities:upsellActivityLaterTime')}</h3>
                                    <div className='alert alert-info'>{t('ActivityFormatAdditionalActivities:upsellActivityLaterTimeDescription')}</div>
                                    <div className='row'>
                                        <div className='col-xs-3'>
                                            <ct.Time id='minGapAfterMainActivity' labelKey='ActivityFormatForm:minGap' value={minGapAfterMainActivity} callback={val => this.setState({ minGapAfterMainActivity: this.validateMinGapAfterMainActivity(val) })} />
                                        </div>
                                        <div className='col-xs-3'>
                                            <ct.Time id='maxGapAfterMainActivity' labelKey='ActivityFormatForm:maxGap' value={maxGapAfterMainActivity} callback={val => this.setState({ maxGapAfterMainActivity: this.validateMaxGapAfterMainActivity(val) })} />
                                        </div>
                                        <div className='col-xs-3'>
                                            <ct.NumberBox id='maxUpsellTimeSlotsAfter' labelKey='ActivityFormatForm:maxUpsellTimeSlotsAfter' value={maxUpsellTimeSlotsAfter} callback={val => this.setState({ maxUpsellTimeSlotsAfter: this.validateMaxUpsellTimeSlotsAfter(val || 1) })} min='1' />
                                        </div>
                                    </div>

                                </div>
                                : null}
                            <h3>{t('')}</h3>
                        </div>
                    </section>
                : null}

                {linkedActivityFormatId.value && customerCategoryMap.length > 0 ?
                    <section className="panel panel-default">
                        <div className="panel-heading">
                            <h2>{t('ActivityFormatAdditionalActivities:customerCategories')}</h2>
                        </div>
                        <div className="panel-body">
                            <div className='alert alert-info'>{t('ActivityFormatAdditionalActivities:customerCategoriesDescription')}</div>
                            <table>
                                <tbody>
                                    {customerCategoryMap.map(c => <tr key={`cat_${c.originalActivityCustomerCategoryId}`}>
                                        <td style={{paddingRight: '10px'}}>{c.originalActivityCustomerCategoryName}</td>
                                        <td>{c.originalActivityCustomerCategoryId === c.linkedActivityCustomerCategoryId && selectedActivityCategories.findIndex(sc => sc.id === c.linkedActivityCustomerCategoryId) >= 0
                                            ? <span style={{ margin: '0 8px' }}></span>
                                            : <ct.Select id={`cat_${c.originalActivityCustomerCategoryId}_selection`} labelKey='' value={ct.asFormValue(`cat_${c.originalActivityCustomerCategoryId}_selection`, c.linkedActivityCustomerCategoryId, !isNullOrEmpty(c.linkedActivityCustomerCategoryId), true)} callback={val => this.linkedCustomerCategoryChanged(c.originalActivityCustomerCategoryId, val)} options={selectedActivityCategorySelections} style={controlStyle} />
                                        }</td>
                                        {offerAsUpsell.value
                                            ? <td><ct.Select id={`cat_${c.originalActivityCustomerCategoryId}_price`} labelKey='' value={ct.asFormValue(`cat_${c.originalActivityCustomerCategoryId}_price`, c.upsellProductId || '', true, false)} callback={val => this.upsellProductChanged(c.originalActivityCustomerCategoryId, val)} options={productSelections} style={controlStyle} /></td>
                                            : null}
                                    </tr>)}
                                </tbody>
                            </table>
                        </div>
                    </section>
                    : null}

                {errorMessage ? <div className='alert alert-danger'>{errorMessage}</div> : null}

                <div className='btn-toolbar'>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)}>{t('Global:save')}</button>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, close)}>{t('Global:cancel')}</button>
                </div>
            </form>
        </div>
    }

}

export default AdditionalActivityForm