
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'

import * as api from '../../../store/apiClient';
import { ApplicationState } from '../../../store';
import * as DiaryTypes from '../../../store/pages/diary/types';
import * as DiaryActions from '../../../store/pages/diary/actions';
import * as ModalActions from '../../../store/global/modal/actions';
import * as LoginActions from '../../../store/pages/login/actions';
import * as TaskDefinitionActions from '../../../store/pages/tasks/actions';
import * as ActivityFormatActions from '../../../store/pages/activityFormats/actions';
import * as ProductActions from '../../../store/pages/products/actions';
import * as CustomerCategoryActions from '../../../store/pages/customerCategories/actions';
import { Bill, LoadEventBillsResponse, deserializeBill, BillItem, BillFee } from '../../../store/pages/pointOfSale/types';
import EventForm from './eventForm';
import { Resource } from '../../../store/pages/resources/types';
import { ValidationError } from '../../../store/global/types';
import Loading from '../../global/loading';
import { TaskDefinition } from '../../../store/pages/tasks/types';
import { Venue } from '../../../store/pages/venues/types';
import { ISaveBillResponse } from '../pointOfSale/pointOfSalePanel';
import CreateEvent from './createEvent';
import { ActivityFormat } from '../../../store/pages/activityFormats/types';
import { generateTempId, isNullOrEmpty, mapUtcDate, parseLocalDateTime, parseUtcDate } from '../../../utils/util';
import { Product, ProductPricingMode } from '../../../store/pages/products/types';
import { CustomerCategory } from '../../../store/pages/customerCategories/types';
import { CustomerCategoryCount } from '../../../store/pages/diary/types';
import { EventRouteState } from './types';
import { createNewEvent } from './helpers';
import { LinkedItemQuantities } from '../pointOfSale/types';

interface LocalState {
    venueId: string;
    venue: Venue | null;
    taskDefinitions: TaskDefinition[];
    resources: Resource[];
    activityFormats: ActivityFormat[];
    activityFormatsLoading: boolean;
    products: Product[];
    productsLoading: boolean;
    customerCategories: CustomerCategory[];
    customerCategoriesLoading: boolean;
}

interface Actions {
    loadTaskDefinitions: () => void;
    loadActivityFormats: () => void;
    loadProducts: () => void;
    loadCustomerCategories: () => void;
    logout: () => void;
}

interface EventRouteProps {
    venueId: string;
    eventId: string;
}

// At runtime, Redux will merge together...
type EventPageProps = LocalState & Actions & RouteComponentProps<EventRouteProps, {}, EventRouteState>;

interface EventPageState {
    loading: boolean;
    venueId: string;
    eventId: string;
    eventKey: string;
    isNew: boolean;
    addCustomer: boolean;
    numberOfParticipants: number | null;
    eventSaveComplete: boolean;
    eventSaveError: api.ApiError | null;
    eventValidationErrors: ValidationError[];
    autoSaved: boolean;
    isSaving: boolean;
    event: DiaryTypes.Event | null;
    bills: Bill[];
    registrations: DiaryTypes.EventRegistration[];
    initialBookingId: string | null;
}

class EventPage extends React.Component<EventPageProps, EventPageState> {

    constructor(props: EventPageProps) {
        super(props);

        const bookingId = props.location && props.location.state ? props.location.state.bookingId : null;

        this.state = {
            loading: true,
            isNew: true,
            addCustomer: false,
            venueId: props.match.params.venueId,
            eventId: props.match.params.eventId,
            eventKey: '',
            event: null,
            registrations: [],
            bills: [],
            isSaving: false,
            eventSaveComplete: false,
            eventSaveError: null,
            eventValidationErrors: [],
            autoSaved: false,
            numberOfParticipants: null,
            initialBookingId: bookingId ? bookingId : null
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        const { eventId, venueId } = this.state;

        if (eventId === 'new') {
            const { resource, startTime, endTime } = this.props.location.state;

            if (resource && startTime) {
                this.setState(s => ({ loading: false, isNew: true, event: createNewEvent(resource, startTime, endTime || startTime.addHours(1)), registrations: [] }));
            } else {
                // missing params so can't create event so bail out.
            }
        } else {
            this.reload(venueId, eventId);
        }
    }

    componentDidUpdate(prevProps: EventPageProps) {
        const { venueId, eventId } = this.props.match.params;

        if (eventId !== prevProps.match.params.eventId) {
            this.setState({
                loading: true,
                isNew: false,
                addCustomer: false,
                venueId: venueId,
                eventId: eventId,
                eventKey: '',
                event: null,
                registrations: [],
                bills: [],
                isSaving: false,
                eventSaveComplete: false,
                eventSaveError: null,
                eventValidationErrors: [],
                autoSaved: false,
                numberOfParticipants: null,
            }, () => this.reload(venueId, eventId));
        }
    }

    backToDiary = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();

        this.goBackToDiary();
    }

    goBackToDiary = () => {
        this.props.history.goBack();
    }

    loadEventRegistrations = (eventId: string) => {
        api.getWithAuth<DiaryActions.ILoadEventRegistrationsResponse>(`api/v1/event/${eventId}/registrations`, this.props.logout)
            .subscribe(res => {
                this.setState(s => ({ registrations: res.registrations.map(r => ({ ...r, registrationTime: parseLocalDateTime(r.registrationTime), termsLastAgreed: parseLocalDateTime(r.termsLastAgreed), dateOfBirth: parseLocalDateTime(r.dateOfBirth) })) }));
            }, e => {
                this.setState(s => ({ registrations: [] }))
            });
    }

    loadEventBills = (eventId: string, venueId: string) => {
        api.getWithAuth<LoadEventBillsResponse>(`api/v1/bill/eventbills/${eventId}/${venueId}`, this.props.logout)
            .subscribe(res => {
                if (!res.bills || res.bills.length === 0) {
                    console.log(`WARNING: No bills returned for event ${eventId} venue: ${venueId}`);
                }
                this.setState(s => ({ bills: res.bills.map(b => deserializeBill(b)) }));
            }, e => {
                console.log(`WARNING: Error loading bills for ${eventId} venue: ${venueId}`);
                this.setState(s => ({ bills: [] }))
            });
    }

    // TODO IMPORTANT: Refactor this out (it's duplicated from billPanel)- either use Redux or introduce a service.
    updateBillItem = (bill: Bill, item: BillItem, quantity: number, archived: boolean, unitPrice: number, productId: string, placesToBookPerUnit: number | null, reservationId: string | null, customerCategoryId: string | null, pricingMode: ProductPricingMode, fixedPriceOverride: number | null, productPriceId: string, linkedItemQuantities: LinkedItemQuantities[], completionCallback: () => void) => {
        this.setState({ isSaving: true });

        api.putWithAuth(`api/v1/bill/${bill.id}/item/${item.id}`, {
            productId: productId,
            quantity: quantity,
            archived: archived,
            unitPrice: unitPrice,
            placesToBookPerUnit: placesToBookPerUnit,
            reservationId: reservationId,
            customerCategoryId: customerCategoryId,
            pricingMode: pricingMode,
            fixedPriceOverride: fixedPriceOverride,
            productPriceId: productPriceId,
            linkedItemQuantities: linkedItemQuantities
        }, () => ({ /* TODO: Handle auth error*/ }))
            .subscribe(response => {
                const mpr = response.response as ISaveBillResponse;
                if (mpr) {
                    const savedBill = deserializeBill(mpr.bill);
                    this.setState(prev => {
                        const newBills = prev.bills.map(b => b.id === savedBill.id ? savedBill : b);
                        return {
                            isSaving: false,
                            bills: newBills,
                            event: prev.event ? { ...prev.event, version: prev.event.version + 1, reservations: prev.event.reservations.map(r => ({ ...r, bookedParticipants: this.getReservationBookings(r.id, newBills) })) } : null
                        }
                    });
                    completionCallback();
                }
            }, (err: api.ApiError) => {
                this.setState({ isSaving: false, eventSaveError: err, eventValidationErrors: err.validationErrors });
            });
    }

    updateBillFee = (billId: string, fee: BillFee, callback: (success: boolean, error: api.ApiError | null) => void) => {
        this.setState({ isSaving: true });
        const { logout } = this.props;

        const call = api.putWithAuth(`api/v1/bill/${billId}/fee/${fee.id}`, fee, logout);

        call.subscribe(response => {
            const mpr = response.response as ISaveBillResponse;
            if (mpr) {
                const savedBill = deserializeBill(mpr.bill);
                this.setState(prev => {
                    const newBills = prev.bills.map(b => b.id === savedBill.id ? savedBill : b);
                    return {
                        isSaving: false,
                        bills: newBills,
                        event: prev.event ? { ...prev.event, version: prev.event.version + 1, reservations: prev.event.reservations.map(r => ({ ...r, bookedParticipants: this.getReservationBookings(r.id, newBills) })) } : null
                    }
                }, () => callback(true, null));
            }
        }, (err: api.ApiError) => {
                this.setState({ isSaving: false });
            callback(false, err);
        });
    }

    voidRefund = (billId: string, refundId: string, voidReason: string, callback: (success: boolean, error: api.ApiError | null) => void) => {
        this.setState({ isSaving: true });
        const { logout } = this.props;
        const { venueId } = this.state;

        const call = api.putWithAuth(`api/v1/bill/${billId}/refund/${refundId}/void`, { venueId: venueId, voidReason: voidReason }, logout);

        call.subscribe(response => {
            const mpr = response.response as ISaveBillResponse;
            if (mpr) {
                const savedBill = deserializeBill(mpr.bill);
                this.setState(prev => {
                    const newBills = prev.bills.map(b => b.id === savedBill.id ? savedBill : b);
                    return {
                        isSaving: false,
                        bills: newBills,
                        event: prev.event ? { ...prev.event, version: prev.event.version + 1, reservations: prev.event.reservations.map(r => ({ ...r, bookedParticipants: this.getReservationBookings(r.id, newBills) })) } : null
                    }
                }, () => callback(true, null));
            }
        }, (err: api.ApiError) => {
                this.setState({ isSaving: false });
            callback(false, err);
        });
    }

    replaceDiscount = (billId: string, promotionId: string, amountOverride: number | null, callback: (success: boolean, error: api.ApiError | null) => void) => {
        const { venueId } = this.state;
        const { logout } = this.props;

        api.putWithAuth(`api/v1/bill/${billId}/discount`, { venueId: venueId, PromotionId: promotionId, AmountOverride: amountOverride }, logout).subscribe(response => {
            const mpr = response.response as ISaveBillResponse;
            if (mpr) {
                const savedBill = deserializeBill(mpr.bill);
                this.setState(prev => {
                    const newBills = prev.bills.map(b => b.id === savedBill.id ? savedBill : b);
                    return {
                        isSaving: false,
                        bills: newBills,
                        event: prev.event ? { ...prev.event, version: prev.event.version + 1, reservations: prev.event.reservations.map(r => ({ ...r, bookedParticipants: this.getReservationBookings(r.id, newBills) })) } : null
                    }
                }, () => callback(true, null));
            }
        }, (err: api.ApiError) => {
            callback(false, err);
        });
    }


    removeDiscount = (billId: string, billDiscountId: string, callback: (success: boolean, error: api.ApiError | null) => void) => {
        const { venueId } = this.state;
        const { logout } = this.props;

        api.putWithAuth(`api/v1/bill/${billId}/discount/remove`, { venueId: venueId, billDiscountId: billDiscountId }, logout).subscribe(response => {
            const mpr = response.response as ISaveBillResponse;
            if (mpr) {
                const savedBill = deserializeBill(mpr.bill);
                this.setState(prev => {
                    const newBills = prev.bills.map(b => b.id === savedBill.id ? savedBill : b);
                    return {
                        isSaving: false,
                        bills: newBills,
                        event: prev.event ? { ...prev.event, version: prev.event.version + 1, reservations: prev.event.reservations.map(r => ({ ...r, bookedParticipants: this.getReservationBookings(r.id, newBills) })) } : null
                    }
                }, () => callback(true, null));
            }
        }, (err: api.ApiError) => {
            callback(false, err);
        });
    }

    // TODO IMPORTANT: end of section

    getReservationBookings = (reservationId: string | null, bills: Bill[]): DiaryTypes.CustomerCategoryCount[] => {
        const { customerCategories } = this.props;

        if (!reservationId) {
            return [];
        }
        const bookings = bills.filter(b => !b.bookingCancelled).reduce<CustomerCategoryCount[]>((ttl, b) => b.items.filter(i => i.reservationId === reservationId).reduce<CustomerCategoryCount[]>((acc, i) => {
            const cat = customerCategories.find(c => i.customerCategoryId && c.id === i.customerCategoryId);
            const catName = cat ? cat.name : '';
            const places = i.quantity * (i.placesToBookPerUnit || 1);

            const ix = acc.findIndex(x => x.categoryName === catName);

            if (ix < 0) {
                acc.push({ categoryId: cat ? cat.id : '', categoryName: catName, count: places });
            } else {
                acc[ix] = { categoryId: cat ? cat.id : '', categoryName: catName, count: acc[ix].count + places }
            }
            return acc;
        }, ttl), []);

        return bookings;
    }

    loadEvent = (eventId: string, venueId: string) => {
        api.getWithAuth<DiaryTypes.LoadEventResponse>(`api/v1/venue/${venueId}/event/${eventId}`, this.props.logout)
            .subscribe(res => {
                const e = res.event

                const mappedEvent = this.mapEvent(e);
                this.setState(s => ({ event: mappedEvent, isNew: false, loading: false }));
            }, e => {
                // TODO : Show load error, or go back to diary?
                // this.setState(s => ({ bills: [] }))
            });
    }

    mapEvent = (e: DiaryTypes.Event) => ({
        ...e,
        startTime: parseLocalDateTime(e.startTime),
        endTime: parseLocalDateTime(e.endTime),
        bookings: e.bookings.map(b => ({
            ...b,
            key: b.id,
            createDateTimeInLocalTime: parseLocalDateTime(b.createDateTimeInLocalTime),
            firstEventStartDateTime: parseLocalDateTime(b.firstEventStartDateTime),
            createDateTime: parseLocalDateTime(b.createDateTimeInLocalTime),
            cancelledDateTime: mapUtcDate(b.cancelledDateTime),
            customer: b.customer ? { ...b.customer, key: b.customer.customerId } : null,
            reservations: b.reservations.map(r => ({ ...r, startTime: parseLocalDateTime(r.startTime), endTime: parseLocalDateTime(r.endTime) })),
            notes: b.notes.map(n => ({ ...n, createDateTime: parseUtcDate(n.createDateTime) })),
        })),
        reservations: e.reservations.map(r => ({ ...r, key: r.id || generateTempId(), eventName: e.name, draft: e.draft, colour: e.colour, startTime: parseLocalDateTime(r.startTime), endTime: parseLocalDateTime(r.endTime), depositDueDate: mapUtcDate(r.depositDueDate), paymentDueDate: mapUtcDate(r.paymentDueDate), overduePaymentDate: mapUtcDate(r.overduePaymentDate) })),
        tasks: e.tasks.map(t => ({ ...t, dueDate: mapUtcDate(t.dueDate), reminderDate: mapUtcDate(t.reminderDate) })),
        createDateTime: (e.createDateTime ? parseUtcDate(e.createDateTime) : undefined),
        whenDeleted: mapUtcDate(e.whenDeleted),
        whenCancelled: mapUtcDate(e.whenCancelled),
        version: new Date().getTime()
    });

    saveCurrentEvent = (isNew: boolean, venueId: string, eventId: string | null, event: DiaryTypes.Event, autoSave: boolean, forceReloadBills: boolean, successCallback?: (eventId: string) => void) =>
        this.saveEvent(isNew, venueId, eventId, event, autoSave, mappedEvent => {
            const { bills } = this.state;
            this.setState({ event: mappedEvent, eventId: mappedEvent.id, isNew: false, isSaving: false, eventSaveComplete: true, autoSaved: autoSave });

            // If any bills are missing, load them now
            var missingBillIds = mappedEvent.bookings.map(booking => booking.billId).filter(billId => !bills.find(b => b.id === billId));
            if (forceReloadBills || missingBillIds.length > 0) {
                this.loadEventBills(mappedEvent.id, venueId);
            }

            if (successCallback) successCallback(mappedEvent.id);
        })

    saveEvent = (isNew: boolean, venueId: string, eventId: string | null, event: DiaryTypes.Event, autoSave: boolean, successCallback: (event: DiaryTypes.Event) => void) => {
        const body = {
            ...event,
            startTime: event.startTime.toApiDateTimeString(),
            endTime: event.endTime.toApiDateTimeString(),
            reservations: event.reservations.map(r => ({
                ...r,
                startTime: r.startTime.toApiDateTimeString(),
                endTime: r.endTime.toApiDateTimeString(),
            })),
            venueId: venueId
        };

        var { logout } = this.props;

        this.setState({ isSaving: true, eventSaveComplete: false, eventSaveError: null, eventValidationErrors: [] })

        // ensure 

        return (isNew ? api.postWithAuth(`api/v1/event/`, body, logout) : api.putWithAuth(`api/v1/event/${eventId}`, body, logout))
            .subscribe(response => {
                const ser = response.response as DiaryTypes.SaveEventResponse;
                if (ser) {
                    const mappedEvent = this.mapEvent(ser.event);
                    successCallback(mappedEvent);
                }
            }, (err: api.ApiError) => {
                this.setState({ isSaving: false, eventSaveComplete: true, autoSaved: autoSave, eventSaveError: err, eventValidationErrors: err.validationErrors });
            });
    }

    eventCreated = (eventIds: string[], bookingId: string | null) => {
        const eventId = eventIds[0]; 

        this.setState({ initialBookingId: bookingId, eventId: eventId, addCustomer: false }, () => this.reloadEvent(eventId));
    }

    reloadEvent = (eventIdOverride?: string) => {
        const { venueId } = this.props;
        const { eventId } = this.state;

        if (eventIdOverride && !isNullOrEmpty(eventIdOverride) && eventIdOverride !== eventId) {
            this.props.history.replace(`/diary/${venueId}/event/${eventIdOverride}`)
        } else {
            this.reload(venueId, eventId);
        }
    }

    reload = (venueId: string, eventId: string) => {
        this.loadEvent(eventId, venueId)
        this.loadEventRegistrations(eventId);
        this.loadEventBills(eventId, venueId);
    }

    deleteEvent = (event: DiaryTypes.Event) => {
        var { logout } = this.props;
        this.setState({ isSaving: true, eventSaveComplete: false, eventSaveError: null, eventValidationErrors: [] });
        api.putWithAuth(`api/v1/event/${event.id}/delete`, {}, logout)
            .subscribe(response => this.setState({ event: { ...event, deleted: true }, isSaving: false, eventSaveComplete: true, autoSaved: false }),
                (err: api.ApiError) => {
                    this.setState({ isSaving: false, eventSaveComplete: true, autoSaved: false, eventSaveError: err, eventValidationErrors: err.validationErrors });
                });
    }

    cancelEvent = (event: DiaryTypes.Event, reason: string) => {
        var { logout } = this.props;
        this.setState({ isSaving: true, eventSaveComplete: false, eventSaveError: null, eventValidationErrors: [] });
        api.putWithAuth(`api/v1/event/${event.id}/cancel`, { reason: reason }, logout)
            .subscribe(response => this.setState({ event: { ...event, cancelled: true }, isSaving: false, eventSaveComplete: true, autoSaved: false }),
                (err: api.ApiError) => {
                    this.setState({ isSaving: false, eventSaveComplete: true, autoSaved: false, eventSaveError: err, eventValidationErrors: err.validationErrors });
                });
    }

    reinstateEvent = (event: DiaryTypes.Event) => {
        var { logout } = this.props;
        this.setState({ isSaving: true, eventSaveComplete: false, eventSaveError: null, eventValidationErrors: [] });
        api.putWithAuth(`api/v1/event/${event.id}/reinstate`, { }, logout)
            .subscribe(response => this.setState({ event: { ...event, cancelled: false, deleted: false }, isSaving: false, eventSaveComplete: true, autoSaved: true }), // set to auto save to prevent the event form from being closed
                (err: api.ApiError) => {
                    this.setState({ isSaving: false, eventSaveComplete: true, autoSaved: false, eventSaveError: err, eventValidationErrors: err.validationErrors });
                });
    }

    cancelBooking = (bookingId: string, reason: string) => {
        const { logout } = this.props;
        const { venueId, eventId } = this.state;
        this.setState({ isSaving: true, eventSaveComplete: false, eventSaveError: null, eventValidationErrors: [] });
        return api.putWithAuth(`api/v1/booking/${bookingId}/cancel`, { reason: reason, venueId: venueId, eventId: eventId }, logout)
            .subscribe(response => {
                const ser = response.response as DiaryTypes.SaveEventResponse;
                if (ser) {
                    const mappedEvent = this.mapEvent(ser.event);
                    // set to auto save to prevent the event form from being closed
                    this.setState({ isSaving: false, eventSaveComplete: true, autoSaved: true, event: mappedEvent, isNew: false });
                }
            }, (err: api.ApiError) => {
                this.setState({ isSaving: false, eventSaveComplete: true, autoSaved: false, eventSaveError: err, eventValidationErrors: err.validationErrors });
            });
    }

    reinstateBooking = (bookingId: string) => {
        const { logout } = this.props;
        const { venueId, eventId } = this.state;
        this.setState({ isSaving: true, eventSaveComplete: false, eventSaveError: null, eventValidationErrors: [] });
        return api.putWithAuth(`api/v1/booking/${bookingId}/reinstate`, { venueId: venueId, eventId: eventId }, logout)
            .subscribe(response => {
                const ser = response.response as DiaryTypes.SaveEventResponse;
                if (ser) {
                    const mappedEvent = this.mapEvent(ser.event);
                    // set to auto save to prevent the event form from being closed
                    this.setState({ isSaving: false, eventSaveComplete: true, autoSaved: true, event: mappedEvent, isNew: false });
                }
            }, (err: api.ApiError) => {
                this.setState({ isSaving: false, eventSaveComplete: true, autoSaved: false, eventSaveError: err, eventValidationErrors: err.validationErrors });
            });
    }

    completeWebBooking = (bookingId: string) => {
        const { logout } = this.props;
        const { venueId, eventId } = this.state;
        this.setState({ isSaving: true, eventSaveComplete: false, eventSaveError: null, eventValidationErrors: [] });
        return api.putWithAuth(`api/v1/booking/${bookingId}/completeWebBooking`, { venueId: venueId, eventId: eventId }, logout)
            .subscribe(response => {
                const ser = response.response as DiaryTypes.SaveEventResponse;
                if (ser) {
                    const mappedEvent = this.mapEvent(ser.event);
                    // set tp auto save to prevent the event form from being closed
                    this.setState({ isSaving: false, eventSaveComplete: true, autoSaved: true, event: mappedEvent, isNew: false });
                }
            }, (err: api.ApiError) => {
                this.setState({ isSaving: false, eventSaveComplete: true, autoSaved: false, eventSaveError: err, eventValidationErrors: err.validationErrors });
            });
    }

    flagBooking = (bookingId: string, flagged: boolean) => {
        const { logout } = this.props;
        const { venueId } = this.state;
        this.setState({ isSaving: true, eventSaveComplete: false, eventSaveError: null, eventValidationErrors: [] });

        return api.putWithAuth(`api/v1/booking/${bookingId}/flag`, { venueId: venueId, flagged: flagged }, logout)
            .subscribe(response => {
                // set to auto save to prevent the event form from being closed
                this.setState({ isSaving: false, eventSaveComplete: true, autoSaved: true });
            }, (err: api.ApiError) => {
                this.setState({ isSaving: false, eventSaveComplete: true, autoSaved: false, eventSaveError: err, eventValidationErrors: err.validationErrors });
            });
    }

    onBookingWebsiteChanged = (bookingId: string, publicWebsiteId: number) => {
        const { logout } = this.props;
        const { venueId } = this.state;
        this.setState({ isSaving: true, eventSaveComplete: false, eventSaveError: null, eventValidationErrors: [] });

        return api.putWithAuth(`api/v1/booking/${bookingId}/publicWebsite`, { venueId: venueId, publicWebsiteId: publicWebsiteId }, logout)
            .subscribe(response => {
                // set to auto save to prevent the event form from being closed
                this.setState({ isSaving: false, eventSaveComplete: true, autoSaved: true });
            }, (err: api.ApiError) => {
                this.setState({ isSaving: false, eventSaveComplete: true, autoSaved: false, eventSaveError: err, eventValidationErrors: err.validationErrors });
            });
    }

    cancelPaymentAttempt = (bookingId: string, billPaymentId: string, gatewayPaymentId: string) => {
        const { logout } = this.props;
        this.setState({ isSaving: true });

        api.putWithAuth(`api/v1/bill/${bookingId}/payment/${billPaymentId}/cancelGatewayPayment/${gatewayPaymentId}`, {}, logout)
            .subscribe(response => {
                const mpr = response.response as ISaveBillResponse;
                if (mpr) {
                    const savedBill = deserializeBill(mpr.bill);
                    this.setState(prev => {
                        const newBills = prev.bills.map(b => b.id === savedBill.id ? savedBill : b);
                        return {
                            isSaving: false,
                            bills: newBills,
                            event: prev.event ? { ...prev.event, version: prev.event.version + 1, reservations: prev.event.reservations.map(r => ({ ...r, bookedParticipants: this.getReservationBookings(r.id, newBills) })) } : null
                        }
                    });
                }
            }, (err: api.ApiError) => {
                this.setState({ isSaving: false, eventSaveError: err, eventValidationErrors: err.validationErrors });
            });
    }

    onBillChanged = (bill: Bill) => {
        this.setState(s => ({ bills: s.bills.filter(b => b.id !== bill.id).concat([bill]) }));
    }

    render() {

        const { history, venue } = this.props;
        const { event, bills, eventKey, isNew, addCustomer, loading, registrations, numberOfParticipants, initialBookingId, isSaving, autoSaved, eventSaveComplete, eventSaveError, eventValidationErrors } = this.state;

        if (loading || event === null || !venue) {
            return <Loading />;
        }
        else if (isNew || addCustomer) {
            const reservation = event.reservations[0];
            const cancelAction = () => addCustomer ? this.setState({ addCustomer: false }) : this.goBackToDiary();

            return <CreateEvent
                venue={venue}
                booking={null}
                billItemsToReschedule={[]}
                customerCategoryDefaults={[]}
                bookedReservationIds={[]}
                showSelectCustomer={addCustomer}
                customersToRegister={[]}
                defaultCountryId={venue.countryId}
                reservation={reservation}
                isSaving={isSaving}
                eventCreated={this.eventCreated}
                cancel={cancelAction} />
        }
        return (
            <section className='eventPage'>
                <EventForm
                    history={history}
                    venue={venue}
                    event={event}
                    bills={bills}
                    registrations={registrations}
                    numberOfParticipants={numberOfParticipants}
                    eventKey={eventKey} isNew={isNew}
                    saveEvent={this.saveCurrentEvent}
                    deleteEvent={this.deleteEvent}
                    cancelEvent={this.cancelEvent}
                    reinstateEvent={this.reinstateEvent}
                    cancelBooking={this.cancelBooking}
                    reinstateBooking={this.reinstateBooking}
                    completeWebBooking={this.completeWebBooking}
                    flagBooking={this.flagBooking}
                    onBookingWebsiteChanged={this.onBookingWebsiteChanged}
                    billChanged={this.onBillChanged}
                    onSaved={this.goBackToDiary}
                    close={this.goBackToDiary}
                    isSaving={isSaving}
                    autoSaved={autoSaved}
                    updateBillItem={this.updateBillItem}
                    updateBillFee={this.updateBillFee}
                    voidRefund={this.voidRefund}
                    replaceDiscount={this.replaceDiscount}
                    removeDiscount={this.removeDiscount}
                    eventSaveComplete={eventSaveComplete}
                    eventSaveError={eventSaveError}
                    eventValidationErrors={eventValidationErrors}
                    cancelPaymentAttempt={this.cancelPaymentAttempt}
                    initialBookingId={initialBookingId}
                    onBookingChanged={this.reloadEvent}
                    addBooking={() => this.setState({ addCustomer: true }) }
                />
            </section>);
    }
};

const mapStateToProps = (state: ApplicationState) => {
    const venue = state.venues.venues && state.venues.venues.length > 0 ? state.venues.venues.find(v => v.id === state.venues.selectedVenueId) : null;
    return ({
        venueId: state.venues.selectedVenueId,
        venue: venue ? venue : null,
        taskDefinitions: state.tasks.taskDefinitions,
        resources: state.resources.resources.filter(r => venue && r.venueId === venue.id),
        activityFormats: state.activityFormats.activityFormats,
        activityFormatsLoading: state.activityFormats.isLoading,
        products: state.products.products.filter(x => !x.archived),
        productsLoading: state.products.isLoading,
        customerCategories: state.customerCategories.customerCategories,
        customerCategoriesLoading: state.customerCategories.isLoading
    });
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadTaskDefinitions: bindActionCreators(TaskDefinitionActions.actionCreators.loadTaskDefinitions, dispatch),
    loadActivityFormats: bindActionCreators(ActivityFormatActions.actionCreators.loadActivityFormats, dispatch),
    loadProducts: bindActionCreators(ProductActions.actionCreators.loadProducts, dispatch),
    loadCustomerCategories: bindActionCreators(CustomerCategoryActions.actionCreators.loadCustomerCategories, dispatch),
    logout: bindActionCreators(LoginActions.actionCreators.logout, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(EventPage);

