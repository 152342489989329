
import * as React from 'react';
import { clickHandler } from '../../../utils/util';

interface NavButtonsProps {
    t: (key: string) => string;
    cancel: () => void;
    preventNext: boolean;
    cancelText?: string;
}

const NavButtons = (props: NavButtonsProps) => {
    return (
        <div className='button-group'>
            <div className='row'>
                <div className='col-xs-3'></div>
                <div className='col-xs-6'><button type='submit' className='btn btn-primary full-width' disabled={props.preventNext}>{props.t('Global:next')}</button></div>
                <div className='col-xs-3'></div>
            </div>
            <button type='button' className='btn btn-link text-muted' style={({ float: 'right' })} onClick={e => clickHandler(e, props.cancel)}>{props.cancelText || props.t('Global:restart').toLowerCase()}</button>
        </div>
    );
};

export default NavButtons;