

import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../../global/controls';
import { clickHandler, copyToClipboard } from '../../../../utils/util';
import { ActivityCustomerCategory } from './activityFormatForm';
import { VenuePublicWebsite } from '../../../../store/pages/venues/types';
import PublicLinks from '../../../global/publicLinks';

interface CustomerCategorySelection {
    customerCategoryId: string;
    customerCategoryName: string;
    customerCategoryCode: string;
    quantity: number;
    minQuantity: number;
    maxQuantity: number
}

interface CreateActivityFormatLinkProps {
    activityFormatId: string;
    publicWebsites: VenuePublicWebsite[];
    customerCategories: ActivityCustomerCategory[];
    close: () => void;
}

interface CreateActivityFormatLinkState {
    addDateParameter: boolean;
    selectedDate: moment.Moment | null;
    addQuantities: boolean;
    quantitySelections: CustomerCategorySelection[]
}

class CreateActivityFormatLink extends React.Component<CreateActivityFormatLinkProps, CreateActivityFormatLinkState> {

    constructor(props: CreateActivityFormatLinkProps) {
        super(props);

        this.state = {
            addDateParameter: false,
            selectedDate: null,
            addQuantities: false,
            quantitySelections: props.customerCategories.filter(c => c.enabled.value).map(c => ({ customerCategoryId: c.customerCategoryId, customerCategoryCode: c.categoryCode, customerCategoryName: c.categoryName, quantity: 0, minQuantity: c.minQuantityPerBooking.value, maxQuantity: c.maxQuantityPerBooking.value }))
        }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    render() {
        const { t } = this.context;
        const { publicWebsites, activityFormatId, close } = this.props;
        const { addDateParameter, selectedDate, addQuantities, quantitySelections } = this.state;

        const activityFormatUrl = this.formatUrl(activityFormatId, addDateParameter, selectedDate, addQuantities, quantitySelections);

        return <div>

            <div>
                <label>
                    <input type='checkbox' checked={addDateParameter} onChange={e => this.setState({ addDateParameter: e.currentTarget.checked, selectedDate: null}) } />
                    <span style={{ margin: '0 10px' }}>{t('CreateActivityFormatLink:addDate')}</span>
                </label>

                {addDateParameter ? <ct.DatePicker id='date' labelKey='CreateActivityFormatLink:date' value={ct.asFormValue('date', selectedDate)} callback={val => this.setState({ selectedDate: val }) } /> : null}
            </div>

            <div>
                <label>
                    <input type='checkbox' checked={addQuantities} onChange={e => this.setState({ addQuantities: e.currentTarget.checked })} />
                    <span style={{ margin: '0 10px' }}>{t('CreateActivityFormatLink:addQuantities')}</span>
                </label>

                {addQuantities
                    ? <table className='table table-condensed' style={{ width: 'auto' }}>
                        <tbody>
                            {quantitySelections.map(c => <tr key={c.customerCategoryId}>
                                <td>{c.customerCategoryName}</td>
                                <td><ct.PlainSelect
                                    id={`${c.customerCategoryId}-quantity`}
                                    value={c.quantity.toString()}
                                    options={[0].concat(Array.from(Array(c.maxQuantity + 1).keys()).filter(x => x > 0 && x >= c.minQuantity && x <= c.maxQuantity)).map(x => ({ key: x.toString(), name: x.toString() }))}
                                    callback={val => this.setState(s => ({ quantitySelections: s.quantitySelections.map(qs => ({ ...qs, quantity: qs.customerCategoryId === c.customerCategoryId ? parseInt(val) : qs.quantity })) }))} /></td>
                            </tr>)}
                        </tbody>
                    </table>
                    : null}
            </div>

            <div>
                <div className='panel panel-primary text-center' style={{ fontSize: '16px', padding: '10px' }}>
                    <PublicLinks publicWebsites={publicWebsites} urlPath={activityFormatUrl} />
                </div>
            </div>

            <div className='btn-toolbar'>
                <button className='btn btn-basic' onClick={e => clickHandler(e, close)}>{t('Global:close')}</button>
            </div>
        </div>
    }

    formatUrl = (activityFormatId: string, addDateParameter: boolean, selectedDate: moment.Moment | null, addQuantities: boolean, quantitySelections: CustomerCategorySelection[]) => {

        const query: string[] = [];

        if (addDateParameter && selectedDate) {
            query.push(`d=${selectedDate.format('YYYY-MM-DD')}`);
        }

        if (addQuantities) {
            for (let i = 0; i < quantitySelections.length; i++) {
                if (quantitySelections[i].quantity > 0) query.push(`${quantitySelections[i].customerCategoryCode}=${quantitySelections[i].quantity}`);
            }
        }

        const baseUtl = `book/activity/${activityFormatId}/availability`;
        return query.length > 0 ?`${baseUtl}?${query.join('&')}` : baseUtl;
    }
}

export default CreateActivityFormatLink;