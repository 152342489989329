
// https://blog.angularindepth.com/how-to-reduce-action-boilerplate-90dc3d389e2b

import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as ut from './types'; 
import { IStore } from '../..';

export type UserActions = ut.LoadUsers| ut.ReceiveUsers | ut.SaveUser | ut.UserSaved | ut.UserSaveFailed | ut.EditUser;

export const actionCreators = {
    loadUsers: () => ({ type: ut.UserActionTypes.RequestUsers }),
    loadUsersComplete: (users: ut.IUser[], err: api.ApiError | null) => ({ type: ut.UserActionTypes.ReceivedUsers, users: users, error: err }),
    editUser: () => ({type: ut.UserActionTypes.EditUser}),
    saveUser: (isNew: boolean, userId: string | null, username: string, emailAddress: string, firstName: string, lastName: string, phoneNumber: string, clientAdmin: boolean, sysAdmin: boolean, password: string | null, mustChangePassword: boolean, deactivated: boolean, sessionTimeout: number | null) => (
        { type: ut.UserActionTypes.SaveUser, isNew: isNew, userId: userId, username: username, emailAddress: emailAddress, firstName: firstName, lastName: lastName, phoneNumber: phoneNumber, clientAdmin: clientAdmin, sysAdmin: sysAdmin, password: password, mustChangePassword: mustChangePassword, deactivated: deactivated, sessionTimeout: sessionTimeout }
    ),
    userSaved: (userId: string) => ({ type: ut.UserActionTypes.UserSaved, userId: userId }),
    UserSaveFailed: (error: api.ApiError) => ({ type: ut.UserActionTypes.UserSaveFailed, error: error})
}

interface IGetUsersResponse {
    users: ut.IUser[];
}

interface ISaveUserResponse {
    userId: string;
}

export const handleClientChange = (store: IStore) => [actionCreators.loadUsers]

//https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-after-catching-and-processing-an-error-with-emitting-somethi

const loadUsers = () => Observable.defer(() => api.getJson<IGetUsersResponse>('api/v1/user/'))
    .map(response => actionCreators.loadUsersComplete(response.users, null));

export const loadUsersEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        ut.UserActionTypes.RequestUsers,
        action => loadUsers(),
        err => actionCreators.loadUsersComplete([], err));

export const reloadUsersEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        ut.UserActionTypes.UserSaved,
        action => loadUsers(),
        err => actionCreators.loadUsersComplete([], err));

export const saveUserEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        ut.UserActionTypes.SaveUser,
        action => {
            const sua = action as ut.SaveUser;
            var body = {
                username: sua.username,
                emailAddress: sua.emailAddress,
                firstName: sua.firstName,
                lastName: sua.lastName,
                phoneNumber: sua.phoneNumber,
                clientAdmin: sua.clientAdmin,
                systemAdmin: sua.sysAdmin,
                password: sua.password,
                mustChangePassword: sua.mustChangePassword,
                deactivated: sua.deactivated,
                sessionTimeout: sua.sessionTimeout,
            };

            return (sua.isNew ? api.post('api/v1/user/', body) : api.put(`api/v1/user/${sua.userId}`, body))
                .map(response => {

                    let userId = sua.userId;
                    if (sua.isNew) {
                        const sur = response.response as ISaveUserResponse;
                        if (sur) {
                            userId = sur.userId;
                        }
                    }

                    return ({ type: ut.UserActionTypes.UserSaved, user: userId });
                });
        },
        (err: api.ApiError) => actionCreators.UserSaveFailed(err));
