import { ValidationError } from '../../global/types';
import * as api from '../../apiClient';


export enum ParameterType {
    None = 0,
    Text = 1,
    Number = 2,
    Currency = 3,
    Date = 4
}

// enum suffix used to prevent name collision with TaskState interface below
export enum TaskStateEnum {
    None = 0,
    Created = 1,
    Initialized = 2,
    Queued = 3,
    Processed = 4,
    Failed = 5
}

export enum TaskTrigger {
    Manual = 0,
    EventCreated = 1 << 0,
    EventConfirmed = 1 << 1,
    TermsAgreed = 1 << 2,
    EventCancelled = 1 << 3,
    EventComplete = 1 << 4,
    EventStartDate = 1 << 5
}

export enum TaskEmailRecipient {
    None = 0,
    Organiser = 1 << 0,
    AllParticipants = 1 << 1,
    TaskCreator = 1 << 2,
    EventCreator = 1 << 3
}

export interface ParameterTypeMap {
    [key: string]: ParameterType;
}

export interface TaskDefinition {
    id: string;
    venueId: string;
    name: string;
    systemParameters: ParameterTypeMap;
    userParameters: ParameterTypeMap;
    trigger: TaskTrigger;
    triggerOffset: number | null;
    clientEmailTemplateId: string | null;
    emailRecipients: TaskEmailRecipient;
    recipientAddress: string | null;
    archived: boolean;
}


export interface TaskState {
    isLoading: boolean;
    taskDefinitions: TaskDefinition[];
    loadError: api.ApiError | null;
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
}

export enum TaskDefinitionActionTypes {
    RequestTaskDefinitions = 'REQUEST_TASK_DEFINITIONS',
    ReceivedTaskDefinitions = 'RECEIVE_TASK_DEFINITIONS',
    SaveTaskDefinition = 'SAVE_TASK_DEFINITION',
    TaskDefinitionSaved = 'TASK_DEFINITION_SAVED',
    TaskDefinitionSaveFailed = 'TASK_DEFINITION_SAVE_FAILED',
    EditTaskDefinition = 'EDIT_TASK_DEFINITION'
}


export interface LoadTaskDefinitions {
    type: TaskDefinitionActionTypes.RequestTaskDefinitions;
}

export interface ReceiveTaskDefinitions {
    type: TaskDefinitionActionTypes.ReceivedTaskDefinitions;
    taskDefinitions: TaskDefinition[];
    error: api.ApiError | null;
}

export interface SaveTaskDefinition {
    type: TaskDefinitionActionTypes.SaveTaskDefinition;
    isNew: boolean;
    taskDefinitionId?: string;
    taskDefinition: TaskDefinition;
}

export interface TaskDefinitionSaved {
    type: TaskDefinitionActionTypes.TaskDefinitionSaved;
    taskDefinitionId: string;
}

export interface TaskDefinitionSaveFailed {
    type: TaskDefinitionActionTypes.TaskDefinitionSaveFailed;
    error: api.ApiError;
}

export interface EditTaskDefinition {
    type: TaskDefinitionActionTypes.EditTaskDefinition
}
