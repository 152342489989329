
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { ITranslationContext } from '../../../translations';
import { BillDiscount } from '../../../store/pages/pointOfSale/types';
import { clickHandler, isNullOrEmpty } from '../../../utils/util';

interface BillItemsDiscountRowProps {
    discount: BillDiscount;
    editDiscount?: (item: BillDiscount) => void;
}

const BillItemsDiscountRow = (props: BillItemsDiscountRowProps, context: ITranslationContext) => {
    const { discount, editDiscount } = props;
    const { t } = context;

    return <li key={discount.id}>
        <button className="btn btn-link btn-no-padding full-width" onClick={e => clickHandler(e, () => editDiscount ? editDiscount(discount) : {})}>
            <div className='bill-payment-item'>
                <div className='bill-payment-item-details flex-center'>
                    <span className='label label-primary' style={{ fontSize: '100%' }}>{isNullOrEmpty(discount.promotionCode) ? discount.promotionName : discount.promotionCode}</span>
                </div>
                <div className='bill-payment-item-price'>
                    <span className='bill_item_price'>{`${t('Global:currencySymbol')}${(discount.amount * -1).toFixed(2)}`}</span>
                </div>
            </div>
        </button>
    </li>
}

BillItemsDiscountRow.contextTypes = {
    t: PropTypes.func
}

export default BillItemsDiscountRow;