
import * as React from 'react';

import * as PropTypes from 'prop-types'

import CustomerCategoryForm from './customerCategoryForm';
import Loading from '../../global/loading';
import { clickHandler, isNullOrEmpty } from '../../../utils/util';
import { CustomerCategory } from '../../../store/pages/customerCategories/types';
import { stringComparer } from '../../../utils/comparers';


interface CustomerCategoryListProps {
    venueId: string;
    customerCategories: CustomerCategory[];
    isLoadingCustomerCategories: boolean;
    editCustomerCategory: () => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
}

interface CustomerCategoryState {
    showArchived: boolean;
}

class CustomerCategoryList extends React.Component<CustomerCategoryListProps, CustomerCategoryState> {

    constructor(props: CustomerCategoryListProps) {
        super(props);

        this.state = { showArchived: false }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private editCustomerCategory = (customerCategory: CustomerCategory) => {
        this.showOverlay(customerCategory, false);
    }

    private addCustomerCategory = () => {
        this.showOverlay(null, true);
    }

    private showOverlay = (customerCategory: CustomerCategory | null, addingNew: boolean) => {
        this.props.editCustomerCategory();
        this.props.showModal(<CustomerCategoryForm isNew={addingNew} venueId={this.props.venueId} customerCategory={customerCategory} />, 'CustomerCategoryForm');
    }
    
    isLoading = () => this.props.isLoadingCustomerCategories;

    render() {
        const { showArchived } = this.state;
        const { t } = this.context;
        const body = this.isLoading() ? <Loading /> : this.renderCustomerCategories();

        return <section className='venue-item-list'>
            <header className='section-header'>
                <div className='section-actions'>
                    <label>
                        <input type='checkbox' checked={showArchived} onChange={e => this.setState({ showArchived: e.currentTarget.checked })} />
                        <span style={({ marginLeft: '10px' })}>{t('Global:showArchived')}</span>
                    </label>
                    <button className='btn btn-info' onClick={e => clickHandler(e, this.addCustomerCategory)}>{t('CustomerCategoryList:addCustomerCategory')}</button>
                </div>
            </header>
            <div className='at-panel sub-section-panel-240px'>
                {body}
            </div>
        </section>;
    }
    
    private renderCustomerCategories = () => {
        const { t } = this.context;
        const { showArchived } = this.state;
        const { venueId, customerCategories } = this.props;

        const filteredCategories = customerCategories.filter(c => c.venueId === venueId && (showArchived || !c.archived)).sort((c1, c2) => stringComparer(c1.name, c2.name)).map(c => {
            return (
                <tr key={c.id}>
                    <td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.editCustomerCategory(c))}>{isNullOrEmpty(c.name) ? <span>[{t('Global:edit')}]</span> : c.name}</button></td>
                    <td>{c.code}</td>
                    <td>{c.minAge || c.maxAge ? `${c.minAge ? c.minAge : ''} - ${c.maxAge ? c.maxAge : ''}` : ''}</td>
                    <td>{c.minHeight || c.maxHeight ? `${c.minHeight ? c.minHeight : ''} - ${c.maxHeight ? c.maxHeight : ''}` : ''}</td>
                    <td>{c.timingCode}</td>
                </tr>
            );
        });


        return (
            <table className='table table-condensed'>
                <thead>
                    <tr>
                        <th>{t('CustomerCategoryList:nameHeading')}</th>
                        <th>{t('CustomerCategoryList:codeHeading')}</th>
                        <th>{t('CustomerCategoryList:ageRange')}</th>
                        <th>{t('CustomerCategoryList:heightRange')}</th>
                        <th>{t('CustomerCategoryList:timingCodeHeading')}</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredCategories}
                </tbody>
            </table>
        );
    }
}

export default CustomerCategoryList;
