
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import * as api from '../../../store/apiClient';

import { Bill, BillFee } from '../../../store/pages/pointOfSale/types';
import { clickHandler, round } from '../../../utils/util';
import ApiError from '../../global/apiError';

interface EditBillFeeProps {
    bill: Bill;
    fee: BillFee;
    updateBillFee: (billId: string, fee: BillFee, callback: (success: boolean, error: api.ApiError | null) => void) => void;
    close: () => void;
}

interface EditBillFeeState {
    billTotalExFees: number;

    description: ct.FormValue<string>;
    amount: ct.FormValue<number>;
    saving: boolean;
    error: string | null;
    apiError: api.ApiError | null;
}

export default class EditBillFee extends React.Component<EditBillFeeProps, EditBillFeeState> {

    constructor(props: EditBillFeeProps) {
        super(props);

        const { bill, fee } = props;

        const feeAmount = bill.fees && bill.fees.length > 0 ? bill.fees.reduce((ttl, f) => ttl + f.amount, 0) : 0;

        this.state = {
            billTotalExFees: bill.totalAmount - feeAmount,

            description: this.validateDescription(fee.description),
            amount: this.validateAmount(fee.amount),
            saving: false,
            error: null,
            apiError: null,
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    updateFee = () => {
        const { fee } = this.props;
        if (!v.isValid(this.state)) {
            this.setState({ error: 'Global:formNotValid' });
        } else {
            const { amount, description } = this.state;
            this.applyChanges({ ...fee, amount: amount.value, description: description.value })
        }
    }

    deleteFee = () => this.applyChanges({...this.props.fee, deleted: true })

    applyChanges = (fee: BillFee) => {
        const { updateBillFee } = this.props;
        this.setState({ saving: true, error: null, apiError: null });

        updateBillFee(this.props.bill.id, fee, this.onFeeSaved);
    }

    onFeeSaved = (success: boolean, error: api.ApiError | null) => {
        this.setState({ saving: false, error: null, apiError: error });
        if (success) {
            this.props.close();
        }
    }

    validateDescription = (val: string) => v.validate(val, 'description', [v.required], []);
    validateAmount = (val: number) => v.validate(val, 'amount', [], []);
    validateDeleted = (val: boolean) => v.validate(val, 'deleted', [], []);

    render() {
        const { billTotalExFees, amount, description, saving, error, apiError } = this.state;
        const { t } = this.context;

        return (
            <div style={{flex: '0  1 auto'}}>
                    <h2>{t('PointOfSale:editFee')}</h2>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div>
                                <label>{t('PointOfSale:billTotalExFee')}: {t('Global:currencySymbol')}{billTotalExFees.toFixed(2)}</label>
                            </div>

                            <ct.TextBox id='description' labelKey='Global:description' placeholderKey='Global:description' value={description} callback={val => this.setState({ description: this.validateDescription(val) })} />

                            <ct.NumberBox id='amount' labelKey='PointOfSale:feeAmount' placeholderKey='' value={amount} callback={val => this.setState({ amount: this.validateAmount(round(val || 0, 2)) })} min='0' />

                        </div>

                        <div className='col-xs-12'>
                            {error ? <div className='alert alert-danger'>{error}</div> : null}
                            {apiError ? <ApiError error={apiError} /> : null}
                        </div>
                    </div>
                    <div className='row button-panel'>
                    <button onClick={e => clickHandler(e, this.updateFee)} className='btn btn-primary' disabled={saving}>{t('Global:update')}</button>
                    <button onClick={e => clickHandler(e, this.props.close)} className='btn btn-basic' disabled={saving}>{t('Global:cancel')}</button>
                    <button onClick={e => clickHandler(e, this.deleteFee)} className='btn btn-danger' disabled={saving}>{t('Global:delete')}</button>
                    </div>
            </div>
        );
    }
}