
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ApplicationState } from '../../../store';
import * as api from '../../../store/apiClient';
import * as LoginActions from '../../../store/pages/login/actions';
import { ReportSettings, ReportFilter } from '../../../store/pages/reportDefinitions/types';
import Loading from '../../global/loading';
import { isNullOrEmpty, clickHandler } from '../../../utils/util';
import ReportFilterOption from './reportFilterOption';
import { RunReportResponse, ReportRow, ReportHeader } from '../../../store/pages/reportTypes/types';
import ReportResultTable from './reportResultTable';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';
import ApiError from '../../global/apiError';

interface GetReportSettingsResponse {
    reportSettings: ReportSettings;
}

interface LocalProps { }

interface ViewReportPageRouteProps {
    reportDefinitionId: string;
}

interface ViewReportPageState {
    isLoading: boolean;
    reportName: string;
    countHeading: string;
    filters: ReportFilter[];
    error: api.ApiError | null;
    isLoadingResult: boolean;
    showFilters: boolean;
    headings: ReportHeader[];
    rows: ReportRow[];
    totals: ReportRow;
}

type RoutedLocalProps = LocalProps & RouteComponentProps<ViewReportPageRouteProps>;


interface MappedReduxState {
    venueId: string;
    dateFormat: DateFormat;
    timeFormat: TimeFormat;
}

interface Actions {
    logout: () => void;
}

type ViewReportPageProps = MappedReduxState & Actions & RoutedLocalProps;

class ViewReportPage extends React.Component<ViewReportPageProps, ViewReportPageState> {

    constructor(props: ViewReportPageProps) {
        super(props);

        this.state = { isLoading: true, reportName: '', countHeading: '', error: null, filters: [], showFilters: true, isLoadingResult: false, headings: [], rows: [], totals: {data: []} };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        const { venueId, match} = this.props;
        const reportDefinitionId = match.params.reportDefinitionId;

        // Load report settings
        if (!isNullOrEmpty(venueId)) {
            this.loadReportSettings(venueId, reportDefinitionId);
        }
    }

    componentDidUpdate(prevProps: ViewReportPageProps) {
        const { venueId, match } = this.props;
        const reportDefinitionId = match.params.reportDefinitionId;

        if (venueId !== prevProps.venueId || reportDefinitionId !== prevProps.match.params.reportDefinitionId) {
            this.loadReportSettings(venueId, reportDefinitionId);
        }
    }

    loadReportSettings = (venueId: string, reportDefinitionId: string) => {
        this.setState({ isLoading: true });

        const url = `api/v1/report/settings/${reportDefinitionId}?vid=${venueId}`;
        api.getWithAuth<GetReportSettingsResponse>(url, this.props.logout)
            .subscribe(
                resp => {
                    this.setState({ reportName: resp.reportSettings.reportName, filters: resp.reportSettings.filters, countHeading: resp.reportSettings.countHeading, isLoading: false });
                },
                e => {
                    this.setState({ error: e, isLoading: false });
                }
            );
    }

    buildReportQueryParameters = (filters: ReportFilter[]) => {
        const mappedFilters = filters.filter(f => f.enabled && f.values.length > 0 && f.values.filter(v => !isNullOrEmpty(v)).length > 0).map(f => [f.name, JSON.stringify(f.values)]);
        const encodedFilters = mappedFilters.map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&');
        return encodedFilters;
    }

    runReport = () => {
        const reportDefinitionId = this.props.match.params.reportDefinitionId;
        const { filters } = this.state;
        this.setState({ isLoadingResult: true });

        const encodedFilters = this.buildReportQueryParameters(filters);
        
        const url = `api/v1/report/run/${reportDefinitionId}?${encodedFilters}`;
        api.getWithAuth<RunReportResponse>(url, this.props.logout)
            .subscribe(
                resp => {
                    const { headings, rows, totals } = resp.report;
                    this.setState({ isLoadingResult: false, showFilters: rows.length === 0, headings: headings, rows: rows, totals: totals });
                },
                e => {
                    this.setState({ error: e, isLoadingResult:false, showFilters: true });
                }
            );
    }

    buildExcelUrl = () => {
        const reportDefinitionId = this.props.match.params.reportDefinitionId;
        const { filters } = this.state;

        const encodedFilters = this.buildReportQueryParameters(filters);

        return `api/v1/report/excel/${reportDefinitionId}?${encodedFilters}`;
    }

    updateFilterValue = (filterName: string, values: string[]) => {
        this.setState(prev => ({ filters: prev.filters.map(f => f.name === filterName ? ({ ...f, values: values }) : f) }));
    }

    enableDisableFilter = (filterName: string, enabled: boolean) => {
        this.setState(prev => ({
            filters: prev.filters.map(f => f.name === filterName ? ({
                ...f,
                enabled: enabled,
                values: enabled && (f.values.length === 0 || isNullOrEmpty(f.values[0])) && f.options.length > 0 ? [f.options[0].key] : f.values
            }) : f)
        }));
    }

    toggleFilters = () => this.setState(prev => ({ showFilters: !prev.showFilters }));

    render() {

        const { isLoading, error, reportName, countHeading, filters, headings, rows, totals, showFilters, isLoadingResult } = this.state;

        if (isLoading) {
            return <Loading />
        }

        return (
            <div style={({ marginBottom: '20px'})}>
                <h2>{reportName}</h2>
                <div className='panel panel-info'>
                    <div className='panel-heading'>
                        <h4 style={({ margin: '0', display: 'inline-block' })}>{this.context.t('ReportFilter:FilterHeading')}</h4>
                        <button className='btn btn-xs btn-info pull-right' onClick={e => clickHandler(e, this.toggleFilters)}><span className={`glyphicon glyphicon-${showFilters ? 'menu-up' : 'menu-down'}`} style={({ verticalAlign: 'middle' })}></span></button>
                    </div>
                    {this.renderFilterBody(showFilters, isLoadingResult, filters)}
                </div>
                {isLoadingResult
                    ? <div className='text-center'><Loading /></div>
                    : error
                        ? <div className='text-center'><ApiError error={error} /></div>
                        : headings.length === 0 ? null : <ReportResultTable reportName={reportName} countHeading={countHeading} headings={headings} rows={rows} totals={totals} excelUrl={this.buildExcelUrl()} />}
            </div>
        );
    }

    renderFilterBody = (showFilters: boolean, isLoadingResult: boolean, filters: ReportFilter[]) => {
        if (!showFilters) {
            return null;
        }

        return (
            <>
                <div className='panel-body'>{this.renderFilters(filters)}</div>
                <div style={({ display: 'flex', justifyContent: 'center', marginTop: '-10px', marginBottom: '8px' })}>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.runReport)} disabled={isLoadingResult}>{this.context.t('ViewReportPage:ViewButton')}</button>
                </div>
            </>
        );
    }

    renderFilters = (filter: ReportFilter[]) => {
        return <div className='row'>
            {filter.filter(f => f.selectable).map(f => <div key={f.name} className='col-xs-6 col-md-4'>{this.renderFilter(f)}</div>)}
        </div>;
    }

    renderFilter = (filter: ReportFilter) => <ReportFilterOption
        key={filter.name}
        filter={filter}
        dateFormat={this.props.dateFormat}
        timeFormat={this.props.timeFormat}
        valueChanged={vals => this.updateFilterValue(filter.name, vals)}
        setEnabled={enabled => this.enableDisableFilter(filter.name, enabled)}
    />;
   
}

const matStateToProps = (state: ApplicationState) => ({
    venueId: state.venues.selectedVenueId,
    dateFormat: state.venues.dateFormat,
    timeFormat: state.venues.timeFormat
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    logout: bindActionCreators(LoginActions.actionCreators.logout, dispatch),
});


// Wire up the React component to the Redux store
export default connect(
    matStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps                  // Selects which action creators are merged into the component's props
)(ViewReportPage);
