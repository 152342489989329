
import * as React from 'react';
import * as PropTypes from 'prop-types'
import { Booking, BookingCustomFieldValue } from './types';
import { clickHandler, isNullOrEmpty } from '../../../utils/util';
import BookingExtraFields from './bookingExtraFields';
import { CustomFieldApplication } from '../../../store/pages/activityFormats/types';
import { CustomFieldValue } from '../../../store/pages/diary/types';

interface EditBookingQuestionsProps {
    booking: Booking;
    saveChanges: (booking: Booking, customFields: CustomFieldValue[], complete: (success: boolean, error: string) => void) => void;
    close: () => void;
}

interface EditBookingQuestionsState {
    customFields: BookingCustomFieldValue[];
    isSaving: boolean;
    saveError: string | null;
}

class EditBookingQuestions extends React.Component<EditBookingQuestionsProps, EditBookingQuestionsState> {

    constructor(props: EditBookingQuestionsProps) {
        super(props);

        const { customFields, customFieldDefinitions } = props.booking;

        const mappedFields = customFields.map(f => {
            const definition = customFieldDefinitions.find(d => d.name === f.customFieldName);
            return {
                customFieldName: f.customFieldName,
                type: f.type,
                value: f.value,
                values: definition ? definition.values : [],
                description: definition ? definition.description : '',
                appliesTo: definition ? definition.appliesTo : CustomFieldApplication.None,
                required: definition ? definition.required : false
            };
        })

        this.state = {
            customFields: mappedFields,
            isSaving: false,
            saveError: null
        }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    fieldValueChanged = (fieldName: string, newValue: string) => this.setState(s => ({ customFields: s.customFields.map(f => f.customFieldName === fieldName ? {...f, value:newValue} : f )}))

    saveChanges = () => {
        const { booking, saveChanges, close } = this.props;
        const { customFields } = this.state;

        this.setState({ isSaving: true }, () => saveChanges(
            booking,
            customFields.map(f => ({ customFieldName: f.customFieldName, type: f.type, value: f.value })),
            (success, err) => this.setState({ isSaving: false, saveError: err }, () => {
                if (success)
                    close();
            })));
    }

    render() {
        const { t } = this.context;
        const { close } = this.props;
        const { customFields, isSaving, saveError } = this.state;

        return <div>
            <h2 className="center">{t('EventForm:editBookingQuestions')}</h2>

            <div><BookingExtraFields customFields={customFields} fieldValueChanged={this.fieldValueChanged} /></div>

            {isNullOrEmpty(saveError) ? null : <div className='alert alert-danger'>{saveError}</div>}

            <div className='create-event-actions mt-15'>
                <div className='btn-panel'>
                    <button key='save' className='btn btn-primary' onClick={e => clickHandler(e, () => this.saveChanges(), 'createEvent__create')} disabled={isSaving}>{t('Global:save')}</button>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, () => close())} disabled={isSaving}>{t('Global:cancel')}</button>
                </div>
            </div>
        </div>
    }
}

export default EditBookingQuestions;