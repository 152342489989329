
import * as api from '../../apiClient';

export interface Currency {
    id: number;
    name: string;
    symbol: string;
    decimals: number;
}

export interface CurrencyState {
    isLoading: boolean;
    currencies: Currency[];
    loadError: api.ApiError | null;
}

export enum CurrencyActionTypes {
    RequestCurrencies = 'REQUEST_CURRENCIES',
    ReceivedCurrencies = 'RECEIVE_CURRENCIES',
    RequestCurrenciesError = 'REQUEST_CURRENCIES_ERROR'
}


export interface RequestCurrencies {
    type: CurrencyActionTypes.RequestCurrencies;
}

export interface ReceiveCurrencies {
    type: CurrencyActionTypes.ReceivedCurrencies;
    currencies: Currency[];
}

export interface RequestCurrenciesError {
    type: CurrencyActionTypes.RequestCurrenciesError;
    error: api.ApiError;
}
