
export interface country {
    id: number;
    name: string;
}

export const ukCountryId = 240;

export const allCountries: country[] = [
    { id: 1, name: 'Afghanistan' },
    { id: 2, name: 'Albania' },
    { id: 3, name: 'Algeria' },
    { id: 4, name: 'American Samoa' },
    { id: 5, name: 'Andorra' },
    { id: 6, name: 'Angola' },
    { id: 7, name: 'Anguilla' },
    { id: 8, name: 'Antarctica' },
    { id: 9, name: 'Antigua and Barbuda' },
    { id: 10, name: 'Argentina' },
    { id: 11, name: 'Armenia' },
    { id: 12, name: 'Aruba' },
    { id: 13, name: 'Australia' },
    { id: 14, name: 'Austria' },
    { id: 15, name: 'Azerbaijan' },
    { id: 16, name: 'Bahamas' },
    { id: 17, name: 'Bahrain' },
    { id: 18, name: 'Bangladesh' },
    { id: 19, name: 'Barbados' },
    { id: 20, name: 'Belarus' },
    { id: 21, name: 'Belgium' },
    { id: 22, name: 'Belize' },
    { id: 23, name: 'Benin' },
    { id: 24, name: 'Bermuda' },
    { id: 25, name: 'Bhutan' },
    { id: 26, name: 'Bolivia, Plurinational State of' },
    { id: 27, name: 'Bolivia' },
    { id: 28, name: 'Bosnia and Herzegovina' },
    { id: 29, name: 'Botswana' },
    { id: 30, name: 'Bouvet Island' },
    { id: 31, name: 'Brazil' },
    { id: 32, name: 'British Indian Ocean Territory' },
    { id: 33, name: 'Brunei Darussalam' },
    { id: 34, name: 'Brunei' },
    { id: 35, name: 'Bulgaria' },
    { id: 36, name: 'Burkina Faso' },
    { id: 37, name: 'Burundi' },
    { id: 38, name: 'Cambodia' },
    { id: 39, name: 'Cameroon' },
    { id: 40, name: 'Canada' },
    { id: 41, name: 'Cape Verde' },
    { id: 42, name: 'Cayman Islands' },
    { id: 43, name: 'Central African Republic' },
    { id: 44, name: 'Chad' },
    { id: 45, name: 'Chile' },
    { id: 46, name: 'China' },
    { id: 47, name: 'Christmas Island' },
    { id: 48, name: 'Cocos (Keeling) Islands' },
    { id: 49, name: 'Colombia' },
    { id: 50, name: 'Comoros' },
    { id: 51, name: 'Congo' },
    { id: 52, name: 'Congo, the Democratic Republic of the' },
    { id: 53, name: 'Cook Islands' },
    { id: 54, name: 'Costa Rica' },
    { id: 55, name: 'CÃ´te d\'Ivoire' },
    { id: 56, name: 'Ivory Coast' },
    { id: 57, name: 'Croatia' },
    { id: 58, name: 'Cuba' },
    { id: 59, name: 'Cyprus' },
    { id: 60, name: 'Czech Republic' },
    { id: 61, name: 'Denmark' },
    { id: 62, name: 'Djibouti' },
    { id: 63, name: 'Dominica' },
    { id: 64, name: 'Dominican Republic' },
    { id: 65, name: 'Ecuador' },
    { id: 66, name: 'Egypt' },
    { id: 67, name: 'El Salvador' },
    { id: 68, name: 'Equatorial Guinea' },
    { id: 69, name: 'Eritrea' },
    { id: 70, name: 'Estonia' },
    { id: 71, name: 'Ethiopia' },
    { id: 72, name: 'Falkland Islands (Malvinas)' },
    { id: 73, name: 'Faroe Islands' },
    { id: 74, name: 'Fiji' },
    { id: 75, name: 'Finland' },
    { id: 76, name: 'France' },
    { id: 77, name: 'French Guiana' },
    { id: 78, name: 'French Polynesia' },
    { id: 79, name: 'French Southern Territories' },
    { id: 80, name: 'Gabon' },
    { id: 81, name: 'Gambia' },
    { id: 82, name: 'Georgia' },
    { id: 83, name: 'Germany' },
    { id: 84, name: 'Ghana' },
    { id: 85, name: 'Gibraltar' },
    { id: 86, name: 'Greece' },
    { id: 87, name: 'Greenland' },
    { id: 88, name: 'Grenada' },
    { id: 89, name: 'Guadeloupe' },
    { id: 90, name: 'Guam' },
    { id: 91, name: 'Guatemala' },
    { id: 92, name: 'Guernsey' },
    { id: 93, name: 'Guinea' },
    { id: 94, name: 'Guinea-Bissau' },
    { id: 95, name: 'Guyana' },
    { id: 96, name: 'Haiti' },
    { id: 97, name: 'Heard Island and McDonald Islands' },
    { id: 98, name: 'Holy See (Vatican City State)' },
    { id: 99, name: 'Honduras' },
    { id: 100, name: 'Hong Kong' },
    { id: 101, name: 'Hungary' },
    { id: 102, name: 'Iceland' },
    { id: 103, name: 'India' },
    { id: 104, name: 'Indonesia' },
    { id: 105, name: 'Iran, Islamic Republic of' },
    { id: 106, name: 'Iraq' },
    { id: 107, name: 'Ireland' },
    { id: 108, name: 'Isle of Man' },
    { id: 109, name: 'Israel' },
    { id: 110, name: 'Italy' },
    { id: 111, name: 'Jamaica' },
    { id: 112, name: 'Japan' },
    { id: 113, name: 'Jersey' },
    { id: 114, name: 'Jordan' },
    { id: 115, name: 'Kazakhstan' },
    { id: 116, name: 'Kenya' },
    { id: 117, name: 'Kiribati' },
    { id: 118, name: 'Korea, Democratic People\'s Republic of' },
    { id: 119, name: 'Korea, Republic of' },
    { id: 120, name: 'South Korea' },
    { id: 121, name: 'Kuwait' },
    { id: 122, name: 'Kyrgyzstan' },
    { id: 123, name: 'Lao People\'s Democratic Republic' },
    { id: 124, name: 'Latvia' },
    { id: 125, name: 'Lebanon' },
    { id: 126, name: 'Lesotho' },
    { id: 127, name: 'Liberia' },
    { id: 128, name: 'Libyan Arab Jamahiriya' },
    { id: 129, name: 'Libya' },
    { id: 130, name: 'Liechtenstein' },
    { id: 131, name: 'Lithuania' },
    { id: 132, name: 'Luxembourg' },
    { id: 133, name: 'Macao' },
    { id: 134, name: 'Macedonia, the former Yugoslav Republic of' },
    { id: 135, name: 'Madagascar' },
    { id: 136, name: 'Malawi' },
    { id: 137, name: 'Malaysia' },
    { id: 138, name: 'Maldives' },
    { id: 139, name: 'Mali' },
    { id: 140, name: 'Malta' },
    { id: 141, name: 'Marshall Islands' },
    { id: 142, name: 'Martinique' },
    { id: 143, name: 'Mauritania' },
    { id: 144, name: 'Mauritius' },
    { id: 145, name: 'Mayotte' },
    { id: 146, name: 'Mexico' },
    { id: 147, name: 'Micronesia, Federated States of' },
    { id: 148, name: 'Moldova, Republic of' },
    { id: 149, name: 'Monaco' },
    { id: 150, name: 'Mongolia' },
    { id: 151, name: 'Montenegro' },
    { id: 152, name: 'Montserrat' },
    { id: 153, name: 'Morocco' },
    { id: 154, name: 'Mozambique' },
    { id: 155, name: 'Myanmar' },
    { id: 156, name: 'Burma' },
    { id: 157, name: 'Namibia' },
    { id: 158, name: 'Nauru' },
    { id: 159, name: 'Nepal' },
    { id: 160, name: 'Netherlands' },
    { id: 161, name: 'Netherlands Antilles' },
    { id: 162, name: 'New Caledonia' },
    { id: 163, name: 'New Zealand' },
    { id: 164, name: 'Nicaragua' },
    { id: 165, name: 'Niger' },
    { id: 166, name: 'Nigeria' },
    { id: 167, name: 'Niue' },
    { id: 168, name: 'Norfolk Island' },
    { id: 169, name: 'Northern Mariana Islands' },
    { id: 170, name: 'Norway' },
    { id: 171, name: 'Oman' },
    { id: 172, name: 'Pakistan' },
    { id: 173, name: 'Palau' },
    { id: 174, name: 'Palestinian Territory, Occupied' },
    { id: 175, name: 'Panama' },
    { id: 176, name: 'Papua New Guinea' },
    { id: 177, name: 'Paraguay' },
    { id: 178, name: 'Peru' },
    { id: 179, name: 'Philippines' },
    { id: 180, name: 'Pitcairn' },
    { id: 181, name: 'Poland' },
    { id: 182, name: 'Portugal' },
    { id: 183, name: 'Puerto Rico' },
    { id: 184, name: 'Qatar' },
    { id: 185, name: 'RÃ©union' },
    { id: 186, name: 'Romania' },
    { id: 187, name: 'Russian Federation' },
    { id: 188, name: 'Russia' },
    { id: 189, name: 'Rwanda' },
    { id: 190, name: 'Saint Helena, Ascension and Tristan da Cunha' },
    { id: 191, name: 'Saint Kitts and Nevis' },
    { id: 192, name: 'Saint Lucia' },
    { id: 193, name: 'Saint Pierre and Miquelon' },
    { id: 194, name: 'Saint Vincent and the Grenadines' },
    { id: 195, name: 'Saint Vincent & the Grenadines' },
    { id: 196, name: 'St. Vincent and the Grenadines' },
    { id: 197, name: 'Samoa' },
    { id: 198, name: 'San Marino' },
    { id: 199, name: 'Sao Tome and Principe' },
    { id: 200, name: 'Saudi Arabia' },
    { id: 201, name: 'Senegal' },
    { id: 202, name: 'Serbia' },
    { id: 203, name: 'Seychelles' },
    { id: 204, name: 'Sierra Leone' },
    { id: 205, name: 'Singapore' },
    { id: 206, name: 'Slovakia' },
    { id: 207, name: 'Slovenia' },
    { id: 208, name: 'Solomon Islands' },
    { id: 209, name: 'Somalia' },
    { id: 210, name: 'South Africa' },
    { id: 211, name: 'South Georgia and the South Sandwich Islands' },
    { id: 212, name: 'Spain' },
    { id: 213, name: 'Sri Lanka' },
    { id: 214, name: 'Sudan' },
    { id: 215, name: 'Suriname' },
    { id: 216, name: 'Svalbard and Jan Mayen' },
    { id: 217, name: 'Swaziland' },
    { id: 218, name: 'Sweden' },
    { id: 219, name: 'Switzerland' },
    { id: 220, name: 'Syrian Arab Republic' },
    { id: 221, name: 'Taiwan, Province of China' },
    { id: 222, name: 'Taiwan' },
    { id: 223, name: 'Tajikistan' },
    { id: 224, name: 'Tanzania, United Republic of' },
    { id: 225, name: 'Thailand' },
    { id: 226, name: 'Timor-Leste' },
    { id: 227, name: 'Togo' },
    { id: 228, name: 'Tokelau' },
    { id: 229, name: 'Tonga' },
    { id: 230, name: 'Trinidad and Tobago' },
    { id: 231, name: 'Trinidad & Tobago' },
    { id: 232, name: 'Tunisia' },
    { id: 233, name: 'Turkey' },
    { id: 234, name: 'Turkmenistan' },
    { id: 235, name: 'Turks and Caicos Islands' },
    { id: 236, name: 'Tuvalu' },
    { id: 237, name: 'Uganda' },
    { id: 238, name: 'Ukraine' },
    { id: 239, name: 'United Arab Emirates' },
    { id: 240, name: 'United Kingdom' },
    { id: 241, name: 'United States' },
    { id: 242, name: 'United States Minor Outlying Islands' },
    { id: 243, name: 'Uruguay' },
    { id: 244, name: 'Uzbekistan' },
    { id: 245, name: 'Vanuatu' },
    { id: 246, name: 'Venezuela, Bolivarian Republic of' },
    { id: 247, name: 'Venezuela' },
    { id: 248, name: 'Viet Nam' },
    { id: 249, name: 'Vietnam' },
    { id: 250, name: 'Virgin Islands, British' },
    { id: 251, name: 'Virgin Islands, U.S.' },
    { id: 252, name: 'Wallis and Futuna' },
    { id: 253, name: 'Western Sahara' },
    { id: 254, name: 'Yemen' },
    { id: 255, name: 'Zambia' },
    { id: 256, name: 'Zimbabwe' }
];