
import * as React from 'react';
import * as PropTypes from 'prop-types'
import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import * as api from '../../../store/apiClient';
import ApiError from '../../global/apiError';
import { isNullOrEmpty } from '../../../utils/util';

export interface ChangePasswordFormProps {
    at: string;
    changePassword: (at: string, oldPassword: string, newPassword: string, confPassword: string) => void;
    loginError: api.ApiError | null;
}

interface ChangePasswordFormState {
    error: string | null;
    password: ct.FormValue<string>;
    newPassword: ct.FormValue<string>;
    confPassword: ct.FormValue<string>;
}

class ChangePasswordForm extends React.Component<ChangePasswordFormProps, ChangePasswordFormState> {

    constructor(props: ChangePasswordFormProps) {
        super(props);

        this.state = { error: null, password: this.validatePassword(''), newPassword: this.validateNewPassword(''), confPassword: this.validateConfPassword('') };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    validatePassword = (val: string) => v.validate(val, 'password', [], []);
    validateNewPassword = (val: string) => v.validate(val, 'newPassword', [], []);
    validateConfPassword = (val: string) => v.validate(val, 'confPassword', [], []);

    changePassword = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {

            const pwd = this.state.password.value.trim();
            const newPwd = this.state.newPassword.value.trim();
            const confPwd = this.state.confPassword.value.trim();

            if (pwd === '') {
                this.setState({ error: this.context.t('ChangePassword:enterOldPassword') });
            } if (newPwd === '') {
                this.setState({ error: this.context.t('ChangePassword:enterNewPassword') });
            } else if (confPwd === '') {
                this.setState({ error: this.context.t('ChangePassword:enterConfirmPassword') });
            } else if (newPwd !== confPwd) {
                this.setState({ error: this.context.t('Global:passwordMissmatch') });
            } else {
                this.setState({ error: null });
                this.props.changePassword(this.props.at, pwd, newPwd, confPwd);
            }

            // Change password
        }
    }

    render() {

        return (
            <div className='login cf'>
                <h2 className='login-title'>{this.context.t('ChangePassword:title')}</h2>

                <p>{this.context.t('ChangePassword:mustChangePasswordText')}</p>

                <form className='data-form' onSubmit={this.changePassword} autoComplete='off'>
                    <div className='form-group'>
                        <ct.Password id='password' labelKey='ChangePassword:oldPassword' placeholderKey='Global:password-placeholder' value={({ ...this.state.password, value: this.state.password.value || '' })} callback={val => this.setState({ password: this.validatePassword(val) })} />
                    </div>
                    <div className='form-group'>
                        <ct.Password id='newPassword' labelKey='Global:newPassword' placeholderKey='Global:password-placeholder' value={({ ...this.state.newPassword, value: this.state.newPassword.value || '' })} callback={val => this.setState({ newPassword: this.validateNewPassword(val) })} />
                    </div>
                    <div className='form-group'>
                        <ct.Password id='confPassword' labelKey='Global:passwordConfirmation' placeholderKey='Global:password-placeholder' value={({ ...this.state.confPassword, value: this.state.confPassword.value || '' })} callback={val => this.setState({ confPassword: this.validateConfPassword(val) })} />
                    </div>
                    <ApiError error={isNullOrEmpty(this.state.error) ? this.props.loginError : this.state.error} />
                    <input type='submit' value={this.context.t('ChangePassword:changePassword')} className='btn btn-primary' />
                </form>
            </div>);
    }
}

export default ChangePasswordForm;