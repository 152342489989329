
import { Action } from 'redux';

import { PaymentMethodActionTypes, PaymentMethodState } from './types';
import { PaymentMethodActions } from './actions'
import { createReducer } from '../../reducer';


const unloadedState: PaymentMethodState = { isLoading: false, paymentMethods: [], loadError: null, isSaving: false, saveComplete: false, saveError: null, validationErrors: [], lastSavedId: null, lastSavedIntegrationKey: null };

export const reducer = (state: PaymentMethodState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: PaymentMethodState, incomingAction: Action): PaymentMethodState => {

    const action = incomingAction as PaymentMethodActions;
    switch (action.type) {
        case PaymentMethodActionTypes.RequestPaymentMethods:
            return {
                ...state,
                isLoading: true,
                paymentMethods: state.paymentMethods,
                loadError: null
            };
        case PaymentMethodActionTypes.ReceivedPaymentMethods:
            const ordered = action.paymentMethods ? action.paymentMethods : [];
            ordered.sort((a, b) => a.name.localeCompare(b.name));

            return {
                ...state,
                isLoading: false,
                paymentMethods: ordered,
                loadError: action.error
            };
        case PaymentMethodActionTypes.EditPaymentMethod:
            return {
                ...state,
                isSaving: false,
                saveError: null,
                saveComplete: false,
                lastSavedId: null,
                lastSavedIntegrationKey: null,
                validationErrors: []
            };
        case PaymentMethodActionTypes.SavePaymentMethod:
            return {
                ...state,
                isSaving: true,
                saveError: null,
                saveComplete: false,
                validationErrors: [],
                lastSavedId: null,
                lastSavedIntegrationKey: null
            };
        case PaymentMethodActionTypes.PaymentMethodSaved:
            return {
                ...state,
                isSaving: false,
                saveComplete: true,
                saveError: null,
                validationErrors: [],
                lastSavedId: action.paymentMethodId,
                lastSavedIntegrationKey: action.paymentMethodIntegrationKey
            };
        case PaymentMethodActionTypes.PaymentMethodSaveFailed:
            return {
                ...state,
                isSaving: false,
                saveError: action.error,
                saveComplete: false,
                lastSavedIntegrationKey: null,
                lastSavedId: null,
                validationErrors: action.error && action.error.validationErrors ? action.error.validationErrors : []
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

