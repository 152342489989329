
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as ft from './types'; 
import { IStore } from '../..';


export type AffiliateActions = ft.LoadAffiliates | ft.ReceiveAffiliates | ft.SaveAffiliate | ft.AffiliateSaved | ft.AffiliateSaveFailed | ft.EditAffiliate;

export const actionCreators = {
    loadAffiliates: () => ({ type: ft.AffiliateActionTypes.RequestAffiliates }),
    receivedAffiliates: (affiliates: ft.Affiliate[], err: api.ApiError | null) => ({ type: ft.AffiliateActionTypes.ReceivedAffiliates, affiliates: affiliates, error: err }),
    editAffiliate: () => ({ type: ft.AffiliateActionTypes.EditAffiliate }),
    saveAffiliate: (isNew: boolean, affiliateId: string | null, affiliate: ft.Affiliate) => ({ type: ft.AffiliateActionTypes.SaveAffiliate, isNew: isNew, affiliateId: affiliateId, affiliate: affiliate }),
    affiliateSaved: (affiliateId: string) => ({ type: ft.AffiliateActionTypes.AffiliateSaved, affiliateId: affiliateId }),
    affiliateSaveFailed: (error: api.ApiError) => ({ type: ft.AffiliateActionTypes.AffiliateSaveFailed, error: error }),
}

interface IGetAffiliatesResponse {
    affiliates: ft.Affiliate[];
}

interface ISaveAffiliateResponse {
    affiliateId: string;
}

export const handleClientChange = (store: IStore) => [actionCreators.loadAffiliates]


//https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-after-catching-and-processing-an-error-with-emitting-somethi

const loadAffiliates = () => Observable.defer(() => api.getJson<IGetAffiliatesResponse>(`api/v1/affiliate/`))
    .map(response => actionCreators.receivedAffiliates(response.affiliates, null));

export const loadAffiliatesEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        ft.AffiliateActionTypes.RequestAffiliates,
        action => loadAffiliates(),
        err => actionCreators.receivedAffiliates([], err));

export const reloadAffiliatesEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        ft.AffiliateActionTypes.AffiliateSaved,
        action => loadAffiliates(),
        err => actionCreators.receivedAffiliates([], err));

export const saveAffiliateEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        ft.AffiliateActionTypes.SaveAffiliate,
        action => {
            const spa = action as ft.SaveAffiliate;
            let affiliateId = spa.affiliateId;
            var { id, ...body } = spa.affiliate;

            return (spa.isNew ? api.post('api/v1/affiliate/', body) : api.put(`api/v1/affiliate/${affiliateId}`, body))
                .map(response => {
                    if (spa.isNew) {
                        const strr = response.response as ISaveAffiliateResponse;
                        if (strr) {
                            affiliateId = strr.affiliateId;
                        }
                    }

                    return ({ type: ft.AffiliateActionTypes.AffiliateSaved, affiliateId: affiliateId });
                });
        },
        (err: api.ApiError) => actionCreators.affiliateSaveFailed(err));
