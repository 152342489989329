
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { Link } from 'react-router-dom';

import { ITranslationContext } from '../../../translations';
import { clickHandler, isNullOrEmpty } from '../../../utils/util';
import { Payment} from './types'
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';

interface DuePaymentProps {
    pymt: Payment
    selectedVenueId: string;
    today: Date;
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
    payBill: (pymt: Payment) => void;
}

const DuePayment = (props: DuePaymentProps, context: ITranslationContext) => {
    const { pymt, today, selectedVenueId, payBill, timeFormat, dateFormat } = props;
    const { t } = context;

    const wrapper = (content: any) => <button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => payBill(pymt))}> {content} </button>;
    const diffInDays = pymt.paymentDueDate.daysDifference(today);
    const isOverdue = diffInDays < 0;
    const dueToday = diffInDays === 0;

        return <li key={`${pymt.paymentId}_row1`}>
            <div className='row'>
                <div className='col-md-4 col-xs-4 text-nowrap'>
                    {wrapper(pymt.paymentDueDate.toAbbrDateString(dateFormat, t))}
                    {isOverdue ? <span className='label label-danger' style={({ marginLeft: '8px' })}>{t('PointOfSale:overdue')}</span> : dueToday ? <span className='label label-warning'>{t('PointOfSale:dueToday')}</span> : null}
                </div>
                <div className='col-md-5 col-xs-5'>{wrapper(pymt.paymentDescription)}</div>
                <div className='col-md-3 col-xs-3 text-right'><strong>{t('Global:currencySymbol')}{pymt.paymentAmount.toFixed(2)}</strong></div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='small text-center'>{formatInfo(pymt)}</div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-8 col-xs-8'><Link to={{ pathname: `/diary/${selectedVenueId}/event/${pymt.eventId}`, state: { bookingId: pymt.bookingId } }} >{pymt.eventName}</Link></div>
                <div className='col-md-4 col-xs-4 small text-right'>{pymt.eventDate.toAbbrDateTimeString(timeFormat, dateFormat, t)}</div>
            </div>
        </li>

}


const formatInfo = (payment: Payment) => {
    return [`${payment.customerFirstName} ${payment.customerLastName}`, payment.companyName, payment.customerPhoneNumber, payment.customerEmailAddress]
        .filter(x => !isNullOrEmpty(x))
        .join(' | ');
}

DuePayment.contextTypes = {
    t: PropTypes.func
}

export default DuePayment;