
import * as React from 'react';
import * as PropTypes from 'prop-types'
import * as api from '../../../store/apiClient';
import { BookingSearchResult, BookingSearchBooking } from '../../../store/pages/diary/types';
import DiarySearchResult from './diarySearchResult';
import { isNullOrEmpty, parseLocalDateTime } from '../../../utils/util';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';

interface DiarySearchProps {
    venueId: string;
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
    logout: () => void;
}

interface DiarySearchState {
    term: string
    results: BookingSearchBooking[];
    resultsMessage: string | null;
    showResults: boolean;
}

class DiarySearch extends React.Component<DiarySearchProps, DiarySearchState> {

    timeoutHandle: NodeJS.Timeout | null;
    _popup: React.RefObject<HTMLDivElement>;

    static contextTypes = {
        t: PropTypes.func
    }

    constructor(props: DiarySearchProps) {
        super(props);

        this.timeoutHandle = null;
        this._popup = React.createRef();

        this.state = { term: '', showResults: false, results: [], resultsMessage: null }
    }

    componentWillUnmount() {
        this.disableClickHandler();
    }

    globalClickHandler = (e: Event) => {
        console.log('global click')
        // ignore click event happened inside the dropdown menu
        const path = e.composedPath();
        const pathMatch = path.findIndex(x => x === this._popup.current) >= 0;
        if (pathMatch) {
            return
        }

        // else hide dropdown menu
        this.hideResults()
    }

    enableClickHandler = () => document.addEventListener('click', this.globalClickHandler);
    disableClickHandler = () => document.removeEventListener('click', this.globalClickHandler);
    showResuts = (results: BookingSearchBooking[], message: string | null) => this.setState({ showResults: true, results: results, resultsMessage: message }, this.enableClickHandler);
    hideResults = () => this.setState({ showResults: false }, this.disableClickHandler);

    onValueChanged = (term: string) => {
        this.setState({ term: term });

        if (this.timeoutHandle) clearTimeout(this.timeoutHandle);
        this.timeoutHandle = setTimeout(() => this.doSearch(term), 1250)
    }
     
    doSearch = (term: string) => {
        const { venueId, logout } = this.props;

        if (isNullOrEmpty(term)) {
            this.hideResults();
        } else if (term.length < 3) {
            this.showResuts([], 'DiarySearch:minLengthWarning');
        } else {
            this.showResuts([], 'DiarySearch:searching');
            api.getWithAuth<BookingSearchResult>(`api/v1/booking/${venueId}/search/${term}`, logout)
                .subscribe(result => this.showResuts(result.results.map(r => ({ ...r, bookingStartTime: parseLocalDateTime(r.bookingStartTime) })), result.results.length === 0 ? 'DiarySearch:noResults' : null), e => {
                    console.log(e)
                    this.showResuts([], e.message);
                });
        }
    }


    render() {
        const { term, results, showResults, resultsMessage } = this.state;
        const { t } = this.context;

        return <div className='diary-page-search'>
            <div className='diary-page-search-box'>
                <span className="glyphicon glyphicon-search"></span>
                <input id='term' className='diary-page-search-term form-control' type='text' autoComplete='off' placeholder={t('DiarySearch:term')} value={term} onChange={val => this.onValueChanged(val.target.value)} />
            </div>
            {this.renderResults(showResults, results, resultsMessage)}

        </div>
    }

    renderResults = (showResults: boolean, results: BookingSearchBooking[], message: string | null) => {
        const { t } = this.context;
        const { venueId, timeFormat, dateFormat } = this.props;

        if (!showResults) return null;

        const wrapper = (content: JSX.Element) => <div className='at-panel diary-search-results' ref={this._popup}>{content}</div>

        if (!isNullOrEmpty(message)) return wrapper(<div className='diary-search-result-message'>{t(message)}</div>)

        return wrapper(<ul>{results.map((r, ix) => <DiarySearchResult key={ix.toString()} itemKey={ix.toString()} venueId={venueId} timeFormat={timeFormat} dateFormat={dateFormat} result={r} />)}</ul>)
    }
}

export default DiarySearch