import * as React from 'react';
import {  RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import * as PropTypes from 'prop-types'

import * as LoginState from '../../../store/pages/login/types';
import * as LoginActions from '../../../store/pages/login/actions';
import { clickHandler } from '../../../utils/util';
import DuePayments from './duePayments';
import RecentPayments from './recentPayments';

// At runtime, Redux will merge together...
type HomePageProps =
    LoginState.LoginState                       // ... state we've requested from the Redux store
    & typeof LoginActions.actionCreators        // ... plus action creators we've requested
    & RouteComponentProps<{ }>;                 // ... plus incoming routing parameters

class HomePage extends React.Component<HomePageProps, {}> {

    static contextTypes = {
        t: PropTypes.func
    }

    logOut = () => {
        this.props.logout();
    }

    render() {
        return <div>
            <div className='row'>
                <div className='col-md-12'>
                    <h1 style={({display: 'inline-block', float: 'left'})}>{this.context.t('HomePage:title')}</h1>
                    <button style={({ float: 'right', marginTop: '23px' })} onClick={e => clickHandler(e, this.logOut)}>{this.context.t('Global:logout')}</button>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-6 col-xs-12'>
                    <DuePayments />
                </div>
                <div className='col-md-6 col-xs-12'>
                    <RecentPayments />
                </div>
            </div>
        </div>;
    }
};

const matStateToProps = (state: ApplicationState) => state.login;

// Wire up the React component to the Redux store
export default connect(
    matStateToProps,                    // Selects which state properties are merged into the component's props
    LoginActions.actionCreators                  // Selects which action creators are merged into the component's props
)(HomePage);
