
import * as React from 'react';
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Link, RouteComponentProps } from 'react-router-dom';

import { ApplicationState } from '../../../store';
import * as MembershipActions from '../../../store/pages/memberships/actions';
import * as ModalActions from '../../../store/global/modal/actions';
import * as LoginActions from '../../../store/pages/login/actions';

import * as api from '../../../store/apiClient';
import * as ct from '../../global/controls';
import * as v from '../../global/validation';

import Loading from '../../global/loading';
import { clickHandler, isNullOrEmpty, mapLocalDateTime, parseLocalDateTime } from '../../../utils/util';
import { Membership, MembershipType } from '../../../store/pages/memberships/types';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';
import MembershipTypeForm from './membershipTypeForm';
import Pagination from '../../global/pagination';
import { isClientAdmin } from '../../../utils/auth';

interface MappedReduxState {
    isLoading: boolean;
    isSearching: boolean;
    searchError: api.ApiError | null;
    membershipSearch: string;
    membershipSearchMembershipTypeId: string | null;
    searchPageNumber: number;
    searchPageSize: number;
    searchMaxPage: number;
    memberships: Membership[];
    membershipTypes: MembershipType[];
    timeFormat: TimeFormat;
    dateFormat: DateFormat
}

interface LocalProps {
    membershipTypeId: string;
}

interface Actions {
    searchMemberships: (membershipTypeId: string, search: string, pageNumber: number, pageSize: number) => void;
    loadMembershipTypes: () => void;
    editMembershipType: () => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
    logout: () => void;
}

type MembershipTypeDetailsProps = MappedReduxState & Actions & RouteComponentProps<LocalProps>;


interface MembershipTypeDetailsState {
    membershipType: MembershipType | null;
    searchTerm: ct.FormValue<string>;
}

class MembershipTypeDetails extends React.Component<MembershipTypeDetailsProps, MembershipTypeDetailsState> {

    constructor(props: MembershipTypeDetailsProps) {
        super(props);

        const membershipTypeId = props.match.params.membershipTypeId;
        const membershipType = props.membershipTypes.find(vp => vp.id === membershipTypeId);

        this.state = { membershipType: membershipType ? membershipType : null, searchTerm: this.validateSearchTerm('') }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        const { match, membershipSearchMembershipTypeId } = this.props;

        const membershipTypeId = match.params.membershipTypeId;

        if (membershipTypeId !== membershipSearchMembershipTypeId) {
            this.searchMemberships('', 1, 30);
        } else {
            this.reload();
        }
    }

    componentDidUpdate() {

        const { membershipType } = this.state;
        const newMembershipType = this.props.membershipTypes.find(x => membershipType && x.id === membershipType.id);

        if (membershipType && newMembershipType && newMembershipType.version !== membershipType.version) {
            this.setState({ membershipType: newMembershipType });
        }
    }

    search = () => this.searchMemberships(this.state.searchTerm.value, 1, this.props.searchPageSize);

    searchMemberships = (searchTerm: string, pageNumber: number, pageSize: number) => {
        const { match, searchMemberships } = this.props;
        const membershipTypeId = match.params.membershipTypeId;
        searchMemberships(membershipTypeId, searchTerm, pageNumber, pageSize);
    }

    setPage = (pageNumber: number) => this.searchMemberships(this.state.searchTerm.value, pageNumber, this.props.searchPageSize);

    setPageSize = (pageSize: number) => this.searchMemberships(this.state.searchTerm.value, this.props.searchPageNumber, pageSize);

    reload = () => this.searchMemberships(this.state.searchTerm.value, this.props.searchPageNumber, this.props.searchPageSize);

    editMemberhipType = () => {
        const { editMembershipType, showModal } = this.props;
        const { membershipType } = this.state;

        if (membershipType) {
            editMembershipType();
            showModal(<MembershipTypeForm isNew={false} membershipType={membershipType} />, 'MembershipTypeForm');
        }
    }

    close = () => {
        const { history } = this.props;
        history.push({ pathname: '/memberships/' });
    }

    validateSearchTerm = (val: string) => v.validate(val, 'searchTerm', [], []);

    showMembership = (membershipId: string) => {
        const { timeFormat, dateFormat, showModal, closeModal, logout} = this.props;
        //showModal(<VoucherDetails voucherId={voucherId} timeFormat={timeFormat} dateFormat={dateFormat} close={closeModal} logout={logout} />, 'VoucherDetails', true);
    }

    render() {
        const { memberships, searchMaxPage, searchPageSize, searchPageNumber, isLoading, dateFormat } = this.props;
        const { membershipType, searchTerm } = this.state;
        const { t } = this.context;

        if (!membershipType) {
            return <div>
                <div className='alert alert-danger'>{t('MembershipTypeDetails:membershipTypeNotFound')}</div>
            </div>
        }

        if (isLoading) {
            return <Loading />
        }

        return <div>
            <div className='row'>
                <div className='col-xs-8'>
                    <h1>{membershipType.name}</h1>
                </div>
                {isClientAdmin()
                    ? <div className='col-xs-4 text-right'>
                        <button className='btn btn-primary' style={{ marginTop: '15px' }} onClick={e => clickHandler(e, this.editMemberhipType)}>{t('Global:edit')}</button>
                    </div>
                    : null}
            </div>

            <div className='row'>
                <div className='col-xs-10'>
                    <ct.TextBox id='searchTerm' labelKey='MembershipTypeDetails:search' placeholderKey='MembershipTypeDetails:search' value={searchTerm} callback={val => this.setState({ searchTerm: this.validateSearchTerm(val) })} />
                </div>
                <div className='col-xs-2 text-right'>
                    <button className='btn btn-primary' style={{ marginTop: '15px' }} onClick={e => clickHandler(e, this.search)}>{t('Global:search')}</button>
                </div>
            </div>

            <table className='table table-condensed'>
                <thead>
                    <tr>
                        <th>{t('MembershipTypeDetails:number')}</th>
                        <th>{t('MembershipTypeDetails:purchaseDate')}</th>
                        <th>{t('MembershipTypeDetails:expiry')}</th>
                        <th></th>
                        <th>{t('MembershipTypeDetails:customerName')}</th>
                        <th>{t('MembershipTypeDetails:customerEmail')}</th>
                        <th>{t('MembershipTypeDetails:createdBy')}</th>
                    </tr>
                </thead>
                <tbody>
                    {memberships.sort((m1, m2) => m2.purchaseDate.getTime() - m2.purchaseDate.getTime()).map(m => <tr key={m.id}>
                        <td><Link to={`/membership/${m.id}`} >{m.membershipNumber}</Link></td>
                        <td>{m.purchaseDate.toShortDateString(dateFormat)}</td>
                        <td>{m.expiryDate ? m.expiryDate.toShortDateString(dateFormat) : ''}</td>
                        <td>{this.renderMembershipState(m)}</td>
                        <td>{m.customerFirstname} {m.customerLastname}</td>
                        <td>{m.customerEmail}</td>
                        <td>{m.createdBy}</td>
                    </tr>)}
                </tbody>
            </table>

            <Pagination maxPage={searchMaxPage} pageSize={searchPageSize} pageNumber={searchPageNumber} setPage={this.setPage} setPageSize={this.setPageSize} />

            <div className='btn-toolbar'>
                <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{t('Global:close')}</button>
            </div>
        </div>
    }

    renderMembershipState = (m: Membership) => {
        const { t } = this.context;
        if (m.expiryDate && m.expiryDate.getTime() <= new Date().getTime()) return <label className='label label-danger'>{t('Global:expired')}</label>
        return <label className='label label-success'>{t('Global:active')}</label>
    }
}

const mapStateToProps = (state: ApplicationState) => {
    const venueId = state.venues.selectedVenueId;
    const venue = state.venues.venues.find(v => v.id === venueId);
    return {
        isLoading: state.memberships.isLoading,
        isSearching: state.memberships.isSearching,
        searchError: state.memberships.searchError,
        membershipSearch: state.memberships.membershipSearch,
        membershipSearchMembershipTypeId: state.memberships.membershipSearchMembershipTypeId,
        searchPageNumber: state.memberships.searchPageNumber,
        searchPageSize: state.memberships.searchPageSize,
        searchMaxPage: state.memberships.searchMaxPage,
        memberships: state.memberships.memberships,
        membershipTypes: state.memberships.membershipTypes,
        timeFormat: venue ? venue.timeFormat : TimeFormat.TwentyFourHour,
        dateFormat: venue ? venue.dateFormat : DateFormat.DMY
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    searchMemberships: bindActionCreators(MembershipActions.actionCreators.searchMemberships, dispatch),
    editMembershipType: bindActionCreators(MembershipActions.actionCreators.editMembershipType, dispatch),
    showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
    logout: bindActionCreators(LoginActions.actionCreators.logout, dispatch),
});

    // Wire up the React component to the Redux store
export default connect(
    mapStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(MembershipTypeDetails);
