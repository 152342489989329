
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'
import { ApplicationState } from '../../../../store';
import { Link } from 'react-router-dom';

import * as ModalActions from '../../../../store/global/modal/actions';
import * as ActivityFormatGroupActions from '../../../../store/pages/activityFormatGroups/actions';
import * as ct from '../../../global/controls';
import ActivityFormatGroupForm from './activityFormatGroupForm';

import { ActivityFormatGroup } from '../../../../store/pages/activityFormatGroups/types';
import Loading from '../../../global/loading';
import { clickHandler, isNullOrEmpty } from '../../../../utils/util';

interface LocalProps {
    venueId: string;
}

interface MappedReduxState {
    isLoadingActivityFormatGroups: boolean;
    activityFormatGroups: ActivityFormatGroup[];
}

interface Actions {
    editActivityFormatGroup: () => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
}

type ActivityFormatGroupListProps = MappedReduxState & Actions & LocalProps;


interface ActivityFormatGroupListState {
    showArchived: boolean;
    filter: string;
}

class ActivityFormatGroupList extends React.Component<ActivityFormatGroupListProps, ActivityFormatGroupListState> {

    constructor(props: ActivityFormatGroupListProps) {
        super(props);

        this.state = { showArchived: false, filter: '' }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    isLoading = () => this.props.isLoadingActivityFormatGroups;

    private editActivityFormatGroup = (grp: ActivityFormatGroup) => {
        this.showOverlay(grp, false);
    }

    private addActivityFormatGroup = () => {
        this.showOverlay(null, true);
    }

    private showOverlay = (grp: ActivityFormatGroup | null, addingNew: boolean) => {
        this.props.editActivityFormatGroup();
        this.props.showModal(<ActivityFormatGroupForm isNew={addingNew} venueId={this.props.venueId} activityFormatGroup={grp ? grp : null} />, 'ActivityFormatGroupForm');
    }

    render() {
        const { showArchived, filter } = this.state;
        const { venueId } = this.props;
        const { t } = this.context;
        const body = this.isLoading() ? <Loading /> : this.renderActivityFormatGroupList();

        return <section className='venue-item-list'>
            <header className='section-header'>
                <div className='section-actions'>
                    <label>
                        <input type='checkbox' checked={showArchived} onChange={e => this.setState({ showArchived: e.currentTarget.checked })} />
                        <span style={({ marginLeft: '10px' })}>{t('Global:showArchived')}</span>
                    </label>

                    <button className='btn btn-info' onClick={e => clickHandler(e, this.addActivityFormatGroup)}>{this.context.t('ActivityFormatGroupList:addActivityFormatGroup')}</button>

                    <div className='pull-right'>
                        <label>{t('Global:filter')}</label>
                        <ct.TextBox style={{ display: 'inline-block', marginLeft: '8px'}} id='filter' labelKey='' value={ct.asFormValue('filter', filter)} callback={val => this.setState({filter: val}) } />
                    </div>
                </div>
            </header>
            <div className='at-panel sub-section-panel-240px'>
                {body}
            </div>
        </section>;
    }

    private renderActivityFormatGroupList = () => {

        const { t } = this.context;
        const { venueId, activityFormatGroups } = this.props;
        const { showArchived, filter } = this.state;
        const lowerFilter = filter.toLowerCase();

        const formatGroups = activityFormatGroups
            .filter(g => g.venueId === venueId && (showArchived || !g.archived) && (isNullOrEmpty(filter) || g.name.toLowerCase().includes(lowerFilter)))
            .map(g => (
                <tr key={g.id}>
                    <td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.editActivityFormatGroup(g))}>{g.name}</button></td>
                    <td>{g.sequence}</td>
                </tr>
            )
        );
        
        return (
            <table className='table table-condensed'>
                <thead>
                    <tr>
                        <th>{t('ActivityFormatGroupList:nameHeading')}</th>
                        <th>{t('ActivityFormatGroupList:sequenceHeading')}</th>
                    </tr>
                </thead>
                <tbody>
                    {formatGroups}
                </tbody>
            </table>
        );
    }
}


const mapStateToProps = (state: ApplicationState) => ({
    activityFormatGroups: state.activityFormatGroups.activityFormatGroups,
    isLoadingActivityFormatGroups: state.activityFormatGroups.isLoading
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    editActivityFormatGroup: bindActionCreators(ActivityFormatGroupActions.actionCreators.editActivityFormatGroup, dispatch),
    showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
});

// Wire up the React component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(ActivityFormatGroupList);
