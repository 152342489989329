
import '../../../css/print.css';

import * as React from 'react';
import * as PropTypes from 'prop-types'

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Link } from 'react-router-dom';

import * as api from '../../../store/apiClient';
import * as DiaryActions from '../../../store/pages/diary/actions';
import * as ModalActions from '../../../store/global/modal/actions';

import Datetime from 'react-datetime';
import moment from 'moment';

import ApiError from '../../global/apiError';
import { ApplicationState } from '../../../store';
import { clickHandler, isNullOrEmpty } from '../../../utils/util';
import { DiaryNote, DiaryReservation } from '../../../store/pages/diary/types';
import { ActivityFormat } from '../../../store/pages/activityFormats/types';
import { CustomerCategory } from '../../../store/pages/customerCategories/types';
import { Resource } from '../../../store/pages/resources/types';
import EditDiaryNotes from '../diary/editDiaryNotes';
import { DateFormat, TimeFormat, Venue } from '../../../store/pages/venues/types';
import ResourceFilter from '../../global/resourceFilter';

interface MappedReduxState {
    selectedVenueId: string;
    venue: Venue | null;
    reservations: DiaryReservation[];
    loadError: api.ApiError | null;
    activityFormats: ActivityFormat[];
    customerCategories: CustomerCategory[];
    resources: Resource[];
    notes: DiaryNote | null;
    isSavingDiaryNotes: boolean;
    diaryNoteSaveError: api.ApiError | null;
    hiddenResources: string[];
}

interface MappedReduxActions {
    loadDiaryReservations: (venueId: string, date: Date) => void;
    loadNotes: (venueId: string, date: Date) => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
}

interface LocalProps { }

type DailySummaryPageProps = MappedReduxState & MappedReduxActions & LocalProps

interface DailySummaryPageState {
    selectedDate: moment.Moment;
}

class DailySummaryPage extends React.Component<DailySummaryPageProps, DailySummaryPageState> {

    constructor(props: DailySummaryPageProps) {
        super(props);

        this.state = { selectedDate: moment() };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(previousProps: DailySummaryPageProps) {
        if (this.props.selectedVenueId !== previousProps.selectedVenueId) {
            this.load();
        }
    }

    load = () => {
        const { selectedVenueId, loadDiaryReservations, loadNotes } = this.props;
        const { selectedDate } = this.state;

        const date = selectedDate.toDate();
        loadDiaryReservations(selectedVenueId, date);
        loadNotes(selectedVenueId, date);
    }

    dateChanged = (date: React.ChangeEvent<any> | moment.Moment | string) => {
        if (moment.isMoment(date)) {
            this.setState({ selectedDate: date }, this.load)
        }
    }

    editNotes = (e: React.MouseEvent<HTMLDivElement>) => {
        const { venue, showModal, notes } = this.props;
        const { selectedDate } = this.state;

        e.preventDefault();

        if (venue) {
            const noteText = notes ? notes.text : '';

            showModal(<EditDiaryNotes venue={venue} notes={noteText} date={selectedDate} />, 'EditDiaryNotes')
        }
    }

    print = () => {
        const { t } = this.context;

        const content = document.getElementById("daily-overview-content");
        const printFrame = document.getElementById("ifmcontentstoprint") as HTMLIFrameElement;

        if (!content || !printFrame || !printFrame.contentWindow)
            return;

        let stylesheets: StyleSheet[] = [];

        for (let si = 0; si < document.styleSheets.length; si++) {
            const ss = document.styleSheets[si]
            if (ss && ss.href && ss.type) {
                stylesheets.push(ss);
            }
        }

        const styleSheetLinks = stylesheets.map(s => `<link rel="stylesheet" type="${s.type}" href="${s.href}">`).join('');

        const headstr = `<html><head><title></title>${styleSheetLinks}</head><body>`;
        const footstr = "</body>";

        const pri = printFrame.contentWindow;

        const pageContent = content.innerHTML.replace(t('DiaryPage:notesPlaceholder'), '');

        printFrame.onload = () => pri.print();
        pri.document.open();
        pri.document.write(headstr + pageContent + footstr);
        pri.document.close();
        pri.focus();
    }

    render() {
        const { t } = this.context;

        const { reservations, loadError, activityFormats, resources, customerCategories, selectedVenueId, notes, venue, hiddenResources } = this.props;
        const { selectedDate } = this.state;

        const todayButton = moment().isSame(selectedDate, 'day') ? null : <button className='btn btn-primary' onClick={e => clickHandler(e, () => this.dateChanged(moment()))} style={({ marginLeft: '12px' })} title={t('Global:today')}>{t('Global:today')}</button>;
        const activeReservations = reservations.filter(r => !r.cancelled && !r.deleted && !r.archived && !hiddenResources.includes(r.resourceId)).sort((r1, r2) => r1.startTime.getTime() - r2.startTime.getTime());
        const activeCategoryIds = activeReservations.reduce<string[]>((categories, rsv) => rsv.bookedParticipants.reduce<string[]>((cats, bp) => cats.includes(bp.categoryId) ? cats : cats.concat(bp.categoryId), categories), []);
        const acriveCategories = customerCategories.filter(cc => activeCategoryIds.includes(cc.id));
        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;

        return <div>
            <h1>{t('dailySummaryPage:heading')}</h1>

            <header>
                <div className='daily-summary-page-header'>
                    <Datetime value={selectedDate} onChange={this.dateChanged} timeFormat={false} className='date-picker' closeOnSelect={true} dateFormat={dateFormat === DateFormat.MDY ? "dddd MMMM Do YYYY" : "dddd D MMMM YYYY"} />
                    {todayButton}

                    <div className='pull-right'>
                        <div className="section-actions" style={{ margin: '0' }}>
                            <ResourceFilter />
                            <button className='btn btn-primary' onClick={e => clickHandler(e, this.print)}>Print</button>
                        </div>
                    </div>
                </div>
            </header>

            <div id='daily-overview-content'>
                <div className='cal-notes' onClick={this.editNotes}>{notes && !isNullOrEmpty(notes.text) ? notes.text : <div className='cal-notes-placeholder'>{t('DiaryPage:notesPlaceholder')}</div>}</div>

                <table className='table'>
                    <thead>
                        <tr>
                            <th>{t('dailySummary:dueTimeHeading')}</th>
                            <th>{t('dailySummary:startTimeHeading')}</th>
                            <th colSpan={2}>{t('dailySummary:resourceHeading')}</th>
                            <th>{t('dailySummary:activityHeading')}</th>
                            <th>{t('dailySummary:totalHeading')}</th>
                            {acriveCategories.map(cc => <th key={`${cc.id}_header`}>{cc.name}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            activeReservations.map(r => {
                                const activityFormat = activityFormats.find(af => af.id === r.activityFormatId)
                                const startTimeStyle = !r.actualStartTime ? {} : r.actualStartTime <= new Date(r.startTime.getTime() + 2 * 60000) ? { color: 'green' } : { color: 'darkorange' };
                                const rsc = resources.find(x => x.id == r.resourceId);

                                return <tr key={`${r.id}`}>
                                    <td className='daily-summary-time'>{r.startTime.toShortTimeString(timeFormat)}</td>
                                    <td className='daily-summary-time' style={startTimeStyle}>{r.actualStartTime ? r.actualStartTime.toShortTimeString(timeFormat) : ''}</td>
                                    <td className='nowrap'><div className='text-center' style={rsc ? { backgroundColor: rsc.colour, color: 'white', fontWeight: 'bold', padding: '3px 8px' } : {}}>{r.resourceName}</div></td>
                                    <td>{r.resourceConfigurationName}</td>
                                    <td><Link to={`/diary/${selectedVenueId}/event/${r.eventId}`}>{activityFormat ? activityFormat.name : ''}</Link></td>
                                    <td className='daily-summary-count'>{r.bookedParticipants.reduce((ttl, c) => ttl + c.count, 0)}</td>
                                    {acriveCategories.map(cc => <td key={`${r.id}_${cc.id}`} className='daily-summary-count'>{this.renderCount(r.bookedParticipants.filter(c => c.categoryName === cc.name).reduce((ttl, c) => ttl + c.count, 0))}</td>)}
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>

            {loadError ? <ApiError error={loadError} /> : null}
        </div>
    }

    renderCount = (count: number) => count === 0 ? '' : count;
}

const matStateToProps = (state: ApplicationState) => {

    const selectedVenueId = state.venues.selectedVenueId;
    const venue = state.venues.venues.find(v => v.id === selectedVenueId);
    return {
        selectedVenueId: selectedVenueId,
        venue: venue ? venue : null,
        reservations: state.diary.reservations,
        loadError: state.diary.loadError,
        resources: state.resources.resources.filter(r => !r.archived),
        activityFormats: state.activityFormats.activityFormats.filter(f => f.venueId === selectedVenueId),
        customerCategories: state.customerCategories.customerCategories.filter(cc => cc.venueId === selectedVenueId && !cc.archived).sort((c1, c2) => c1.name === c2.name ? 0  : c1.name < c2.name ? -1 : 1),
        notes: state.diary.diaryNotes[`${state.venues.selectedVenueId}_${state.diary.date.toYMDDateString()}`],
        isSavingDiaryNotes: state.diary.isSavingDiaryNotes,
        diaryNoteSaveError: state.diary.diaryNoteSaveError,
        hiddenResources: state.preferences.hiddenResources
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadDiaryReservations: bindActionCreators(DiaryActions.actionCreators.loadDiaryReservations, dispatch),
    loadNotes: bindActionCreators(DiaryActions.actionCreators.loadDiaryNote, dispatch),
    showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
});

export default connect(
    matStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps        // Selects which action creators are merged into the component's props
)(DailySummaryPage);