
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { ITranslationContext } from '../../translations';
import { VenuePublicWebsite } from '../../store/pages/venues/types';
import { clickHandler, copyToClipboard } from '../../utils/util';

interface PublicLinkProps {
    publicWebsite: VenuePublicWebsite;
    urlPath: string;
}

const PublicLink = (props: PublicLinkProps, context: ITranslationContext) => {
    const { publicWebsite, urlPath } = props;
    const baseUrl = publicWebsite.baseUrl;
    const url = `${baseUrl}/${urlPath}`;

    const [showMsg, setShowMsg] = React.useState(false)

    const copy = React.useCallback((url: string) => {
        copyToClipboard(url)
        setShowMsg(true)
    }, [setShowMsg])

    React.useEffect(() => {
        if (showMsg) {
            let timerFunc = setTimeout(() => setShowMsg(false), 2500);
            return () => clearTimeout(timerFunc);
        }
    }, [showMsg, setShowMsg])

    return <div key={publicWebsite.id}>
                {url}
                <span className='glyphicon glyphicon-copy' style={({ cursor: 'pointer', padding: '5px', marginLeft: '10px', fontSize: '20px', color: '#337ab7' })} onClick={e => clickHandler(e, () => copy(url))}></span>
        {showMsg ? <span style={{ fontSize: '14px', color: '#777' }}>{context.t('Global:copied')}</span> : null}
            </div>
}

PublicLink.contextTypes = {
    t: PropTypes.func
}
export default PublicLink;
