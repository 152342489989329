
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../../../../store';
 
import { quillStandardToolbar } from '../../../global/quillSettings';

import * as api from '../../../../store/apiClient';
import * as TermsActions from '../../../../store/pages/termsAndConditions/actions';
import * as ModalActions from '../../../../store/global/modal/actions';
import * as ct from '../../../global/controls';
import * as v from '../../../global/validation';
import { defaultFormHandler, clickHandler } from '../../../../utils/util';
import ApiError from '../../../global/apiError';

import { WebShopPurchaseTerms } from '../../../../store/pages/termsAndConditions/types';

interface EditWebShopPurchaseTermsState {
    termsAndConditions: ct.FormValue<string>;
    confirmTermsText: ct.FormValue<string>;
}

interface LocalProps {
    venueId: string;
    terms: WebShopPurchaseTerms | null;
}

interface LocalState {
    isSavingWebShopPurchaseTerms: boolean;
    webShopPurchaseTermsSaveError: api.ApiError | null;
}

interface Actions {
    saveWebShopPurchaseTerms: (webShopPurchaseTermsId: string | null, venueId: string, termsAndConditions: string, confirmTermsText: string) => void;
    closeModal: () => void;
}

type EditWebShopPurchaseTermsProps = LocalState & Actions & LocalProps;

class EditWebShopPurchaseTerms extends React.Component<EditWebShopPurchaseTermsProps, EditWebShopPurchaseTermsState> {

    constructor(props: EditWebShopPurchaseTermsProps) {
        super(props);

        const { terms } = props;

        this.state = {
            termsAndConditions: this.validateTerms(terms ? terms.terms : ''),
            confirmTermsText: this.validateConfirmTermsText(terms ? terms.webShopPurchaseConfirmationText : ''),
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    validateConfirmTermsText = (val: string) => v.validate(val, 'confirmTermsText', [v.required], []);
    validateTerms = (val: string) => v.validate(val, 'terms', [], []);

    termsAndConditionsChanged = (val: string) => this.setState({ termsAndConditions: this.validateTerms(val) });

    componentDidUpdate(prevProps: EditWebShopPurchaseTermsProps) {
        const { isSavingWebShopPurchaseTerms: prevIsSavingWebShopPurchaseTerms } = prevProps;
        const { isSavingWebShopPurchaseTerms, webShopPurchaseTermsSaveError, closeModal } = this.props;

        // Close modal overlay if diary note save is complete
        if (prevIsSavingWebShopPurchaseTerms && !isSavingWebShopPurchaseTerms && !webShopPurchaseTermsSaveError) {
            closeModal();
        }
    }

    save = () => {
        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {
            const { venueId, terms, saveWebShopPurchaseTerms } = this.props;
            const { termsAndConditions, confirmTermsText } = this.state;

            const webShopPurchaseTermsId = terms ? terms.id : null;
            saveWebShopPurchaseTerms(
                webShopPurchaseTermsId,
                venueId,
                termsAndConditions.value,
                confirmTermsText.value,
            );
        }
    }

    render() {
        const { closeModal, isSavingWebShopPurchaseTerms, webShopPurchaseTermsSaveError } = this.props;
        const { termsAndConditions, confirmTermsText } = this.state;
        const { t } = this.context;

        const message = (webShopPurchaseTermsSaveError) ? <ApiError error={webShopPurchaseTermsSaveError} /> : null;

        return (
            <form className='data-form' onSubmit={defaultFormHandler} autoComplete='off'>
                <div>

                    <h4>{t('VenueForm:termsAndConditions')}</h4>
                    <ct.HtmlInput id='termsTemplate' labelKey='' value={termsAndConditions} callback={this.termsAndConditionsChanged} modules={quillStandardToolbar} quillClassName='quill-dark' inlineStyles={true} />
                    <ct.TextBox id='confirmTermsText' labelKey='VenueForm:confirmTermsText' placeholderKey='VenueForm:confirmTermsText' helpTextKey='VenueForm:confirmTermsTextHelp' value={confirmTermsText} callback={val => this.setState({ confirmTermsText: this.validateConfirmTermsText(val) })} />

                    {message}

                    <div className='btn-toolbar' style={({ marginTop: '20px' })}>
                        <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)} disabled={isSavingWebShopPurchaseTerms}>{t('Global:save')}</button>
                        <button className='btn btn-basic' onClick={e => clickHandler(e, closeModal)} disabled={isSavingWebShopPurchaseTerms}>{t('Global:cancel')}</button>
                    </div>
                </div>
            </form>
        );
    }
}


const matStateToProps = (state: ApplicationState) => ({
    isSavingWebShopPurchaseTerms: state.termsAndConditions.isSavingWebShopPurchaseTerms,
    webShopPurchaseTermsSaveError: state.termsAndConditions.webShopPurchaseTermsSaveError,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    saveWebShopPurchaseTerms: bindActionCreators(TermsActions.actionCreators.saveWebShopPurchaseTerms, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    matStateToProps,
    mapDispatchToProps
)(EditWebShopPurchaseTerms);
