
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';

import { ITranslationContext } from '../../../translations';
import { EventBooking } from '../../../store/pages/diary/types';


interface BookingConfirmationEmailSelectionProps {
    booking: EventBooking;
    selectedEmailTemplateId: string;
    emailTemplateOptions: ct.SelectOption[];
    templateSelected: (val: string) => void;
}

const BookingConfirmationEmailSelection = (props: BookingConfirmationEmailSelectionProps, context: ITranslationContext) => {
    const { booking, emailTemplateOptions, selectedEmailTemplateId, templateSelected } = props;
    const inlineControlStyle = ({ minHeight: '10px', marginRight: '10px' });

    const customer = booking.customer;
    if (!customer) return null;

    return <tr key={booking.key}>
        <td>{customer.firstname} {customer.lastname}</td>
        <td>{customer.emailAddress}</td>
        <td><ct.Select id={`${booking.key}_template`} labelKey='' value={ct.asFormValue(`${booking.key}_template`, selectedEmailTemplateId)} callback={templateSelected} options={emailTemplateOptions} style={inlineControlStyle} /></td>
    </tr>
}

BookingConfirmationEmailSelection.contextTypes = {
    t: PropTypes.func
}

export default BookingConfirmationEmailSelection;