
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { BookingSearchBooking } from '../../../store/pages/diary/types';
import '../../../utils/extensions';
import { Link } from 'react-router-dom';
import { ITranslationContext } from '../../../translations';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';

interface DiarySearchResultProps {
    venueId: string;
    result: BookingSearchBooking;
    itemKey: string;
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
}

const DiarySearchResult = (props: DiarySearchResultProps, context: ITranslationContext) => {
    return <li key={props.itemKey}>
        <Link to={{ pathname: `/diary/${props.venueId}/event/${props.result.eventId}`, state: { bookingId: props.result.bookingId }}}>
            <div>{props.result.bookingStartTime.toShortDateString(props.dateFormat)} {props.result.bookingStartTime.toShortTimeString(props.timeFormat)} {props.result.eventName} {props.result.bookingCancelled || props.result.eventCancelled ? <label className='label label-danger'>{context.t('Global:cancelled')}</label> : null}</div>
            <div className='small'><strong>{props.result.billNumber}</strong> - {props.result.customerFirstName} {props.result.customerLastName} {props.result.customerCompanyName}</div>
            <div className='small'>{props.result.activityName} - {props.result.resourceName}</div>
        </Link>
    </li>
}

DiarySearchResult.contextTypes = {
    t: PropTypes.func
}

export default DiarySearchResult