
// https://blog.angularindepth.com/how-to-reduce-action-boilerplate-90dc3d389e2b

import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as vt from './types'; 
import { IStore } from '../..';
import { mapUtcDate, parseLocalDateTime } from '../../../utils/util';
import { Time } from '../../global/types';

export type VoucherActions = vt.LoadVoucherProducts | vt.ReceiveVoucherProducts | vt.SaveVoucherProduct | vt.VoucherProductSaved | vt.VoucherProductSaveFailed | vt.EditVoucherProduct | vt.SearchVouchers | vt.VouchersReceived;

export const actionCreators = {
    loadVoucherProducts: () => ({ type: vt.VoucherActionTypes.RequestVoucherProducts }),
    loadVoucherProductsComplete: (voucherProducts: vt.VoucherProduct[], err: api.ApiError | null) => ({ type: vt.VoucherActionTypes.ReceivedVoucherProducts, voucherProducts: voucherProducts, error: err }),
    editVoucherProduct: () => ({ type: vt.VoucherActionTypes.EditVoucherProduct }),
    saveVoucherProduct: (isNew: boolean, voucherProduct: vt.VoucherProduct, productImg: File | null, voucherImg: File | null) => ({ type: vt.VoucherActionTypes.SaveVoucherProduct, isNew: isNew, voucherProduct: voucherProduct, productImg: productImg, voucherImg: voucherImg }),
    voucherProductSaved: (voucherProductId: string) => ({ type: vt.VoucherActionTypes.VoucherProductSaved, voucherProductId: voucherProductId }),
    voucherProductSaveFailed: (error: api.ApiError) => ({ type: vt.VoucherActionTypes.VoucherProductSaveFailed, error: error }),
    searchVouchers: (voucherProductId: string, search: string, pageNumber: number, pageSize: number) => ({ type: vt.VoucherActionTypes.SearchVouchers, voucherProductId: voucherProductId, search: search, pageNumber: pageNumber, pageSize: pageSize }),
    vouchersReceived: (vouchers: vt.Voucher[], maxPage: number, error: api.ApiError | null) => ({ type: vt.VoucherActionTypes.VouchersReceived, vouchers: vouchers, maxPage: maxPage, error: error })
}

interface IGetVoucherProductsResponse {
    voucherProducts: vt.VoucherProduct[];
}

interface ISaveVoucherProductResponse {
    voucherProductId: string;
}

interface ISearchVouchersResponse {
    vouchers: vt.Voucher[];
    page: number;
    hasMorePages: boolean;
}

let counter = 0;

export const handleClientChange = (store: IStore) => [actionCreators.loadVoucherProducts]

//https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-after-catching-and-processing-an-error-with-emitting-somethi

const loadVoucherProducts = () => Observable.defer(() => api.getJson<IGetVoucherProductsResponse>('api/v1/voucher/voucherProducts'))
    .map(response => actionCreators.loadVoucherProductsComplete(response.voucherProducts.map(vp => ({
        ...vp,
        pricing: vp.pricing.map(pr => ({
            ...pr,
            effectiveFrom: parseLocalDateTime(pr.effectiveFrom),
            effectiveTo: pr.effectiveTo ? parseLocalDateTime(pr.effectiveTo) : pr.effectiveTo,
            applyPriceFrom: pr.applyPriceFrom ? Time.parse(pr.applyPriceFrom) : pr.applyPriceFrom,
            applyPriceTo: pr.applyPriceTo ? Time.parse(pr.applyPriceTo) : pr.applyPriceTo,
        }))
    })), null));

export const loadVoucherProductsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        vt.VoucherActionTypes.RequestVoucherProducts,
        action => loadVoucherProducts(),
        err => actionCreators.loadVoucherProductsComplete([], err));

export const reloadVoucherProductsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        vt.VoucherActionTypes.VoucherProductSaved,
        action => loadVoucherProducts(),
        err => actionCreators.loadVoucherProductsComplete([], err));

export const saveVoucherProductEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        vt.VoucherActionTypes.SaveVoucherProduct,
        action => {
            const sa = action as vt.SaveVoucherProduct;

            const productImg = sa.productImg;
            const voucherImg = sa.voucherImg;

            const imageObservables: Observable<string>[] = [
                productImg ? api.uploadFile(productImg, 'api/v1/clientimage').map(res => res.response.imageId) : Observable.of(null),
                voucherImg ? api.uploadFile(voucherImg, 'api/v1/clientimage').map(res => res.response.imageId) : Observable.of(null),
            ];

            const images = Observable.forkJoin(imageObservables);
            return images.flatMap(imgIds => {
                var bodyWithImg = {
                    productId: sa.isNew ? null : sa.voucherProduct.productId,
                    name: sa.voucherProduct.name,
                    description: sa.voucherProduct.description,
                    taxRateId: sa.voucherProduct.taxRateId,
                    accountingDepartment: sa.voucherProduct.accountingDepartment,
                    accountingCategory: sa.voucherProduct.accountingCategory,
                    nominalCode: sa.voucherProduct.nominalCode,
                    redemptionAmount: sa.voucherProduct.redemptionAmount,
                    voucherValidFor: sa.voucherProduct.voucherValidFor,
                    showOnWebShop: sa.voucherProduct.showOnWebShop,
                    allowCustomPrice: sa.voucherProduct.allowCustomPrice,
                    minPrice: sa.voucherProduct.minPrice,
                    maxPrice: sa.voucherProduct.maxPrice,
                    priceIncrement: sa.voucherProduct.priceIncrement,
                    maxQuantity: sa.voucherProduct.maxQuantity,
                    activeFromDate: sa.voucherProduct.activeFromDate,
                    productInformation: sa.voucherProduct.productInformation,
                    canRedeemOnline: sa.voucherProduct.canRedeemOnline,
                    archived: sa.voucherProduct.archived,
                    venueSettings: sa.voucherProduct.venueSettings,
                    tags: sa.voucherProduct.tags,
                    mustBeRedeemedBySingleCustomer: sa.voucherProduct.mustBeRedeemedBySingleCustomer,
                    productRestrictionType: sa.voucherProduct.productRestrictionType,
                    restrictionIds: sa.voucherProduct.restrictionIds,
                    clientEmailTemplateId: sa.voucherProduct.clientEmailTemplateId,
                    productImageId: imgIds[0],
                    voucherImageId: imgIds[1],
                    pricing: sa.voucherProduct.pricing.map(pr => ({
                        ...pr,
                        effectiveFrom: pr.effectiveFrom.toApiDateOnlyString(),
                        effectiveTo: pr.effectiveTo ? pr.effectiveTo.toApiDateOnlyString() : null,
                        applyPriceFrom: pr.applyPriceFrom ? pr.applyPriceFrom.toString() : null,
                        applyPriceTo: pr.applyPriceTo ? pr.applyPriceTo.toString() : null
                    }))
                };

                return (sa.isNew ? api.post('api/v1/voucher/voucherProduct/', bodyWithImg) : api.put(`api/v1/voucher/voucherProduct/${sa.voucherProduct.id}`, { ...bodyWithImg, productId: sa.voucherProduct.productId }))
                    .map(response => {

                        let voucherProductId = sa.voucherProduct.id;
                        if (sa.isNew) {
                            const svr = response.response as ISaveVoucherProductResponse;
                            if (svr) {
                                voucherProductId = svr.voucherProductId;
                            }
                        }

                        return ({ type: vt.VoucherActionTypes.VoucherProductSaved, voucherProductId: voucherProductId });
                    });
            })
        },
        (err: api.ApiError) => actionCreators.voucherProductSaveFailed(err));


const searchVouchers = (pageNumber: number, pageSize: number, voucherProductId: string, search: string) => {
    counter = counter + 1;
    const lastCounter = counter;

    const query = `api/v1/voucher/${voucherProductId}/search?pageNumber=${pageNumber}&pageSize=${pageSize}&search=${search}`;

    return Observable.defer(() => api.getJson<ISearchVouchersResponse>(query))
        .filter(resp => {
            return lastCounter === counter
        })
        .map(response => {
            return actionCreators.vouchersReceived(response.vouchers.map(v => ({
                ...v,
                purchaseDate: new Date(v.purchaseDate),
                expiryDate: mapUtcDate(v.expiryDate),
                activeFromDate: mapUtcDate(v.activeFromDate),
                emailLastSent: mapUtcDate(v.emailLastSent),
            })), response.hasMorePages ? response.page + 1 : response.page, null)
        });
}

export const searchVouchersEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        vt.VoucherActionTypes.SearchVouchers,
        action => searchVouchers(action.pageNumber, action.pageSize, action.voucherProductId, action.search),
        err => actionCreators.vouchersReceived([], 0, err));


