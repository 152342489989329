
import { Action } from 'redux';
import { LocationChangeAction } from 'react-router-redux';

import { MembershipState, MembershipActionTypes } from './types';
import { MembershipActions } from './actions'
import { createReducer } from '../../reducer';

const unloadedState: MembershipState = {
    isActive: false,
    isLoading: false,
    membershipTypes: [],
    loadError: null,
    isSaving: false,
    saveComplete: false,
    saveError: null,
    validationErrors: [],
    isSearching: false,
    membershipSearch: '',
    searchPageNumber: 1,
    searchPageSize: 30,
    searchMaxPage: 1,
    membershipSearchMembershipTypeId: null,
    searchError: null,
    memberships: []
};

export const reducer = (state: MembershipState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: MembershipState, incomingAction: Action): MembershipState => {

    if (incomingAction.type === '@@router/LOCATION_CHANGE') {
        const locationChangeAction = incomingAction as LocationChangeAction;
        if (locationChangeAction) {
            return { ...state, isActive: locationChangeAction.payload.pathname === '/membership' };
        }
    }

    const action = incomingAction as MembershipActions;
    switch (action.type) {
        case MembershipActionTypes.RequestMembershipTypes:
        return {
            ...state,
            isLoading: true,
            membershipTypes: state.membershipTypes,
            loadError: null
        };
        case MembershipActionTypes.ReceivedMembershipTypes:
        return {
            ...state,
            isLoading: false,
            membershipTypes: action.membershipTypes,
            loadError: action.error
        };
        case MembershipActionTypes.EditMembershipType:
        return {
            ...state,
            isSaving: false,
            saveError: null,
            saveComplete: false,
            validationErrors: []
        };
        case MembershipActionTypes.SaveMembershipType:
        return {
            ...state,
            isSaving: true,
            saveError: null,
            saveComplete: false,
            validationErrors: []
            };
        case MembershipActionTypes.MembershipTypeSaved:
        return {
            ...state,
            isSaving: false,
            saveComplete: true,
            saveError: null,
            validationErrors: []
        };
        case MembershipActionTypes.MembershipTypeSaveFailed:
        return {
            ...state,
            isSaving: false,
            saveError: action.error,
            saveComplete: false,
            validationErrors: action.error && action.error.validationErrors ? action.error.validationErrors : []
        };
        case MembershipActionTypes.SearchMemberships:
            return {
                ...state,
                isSearching: true,
                searchError: null,
                membershipSearch: action.search,
                membershipSearchMembershipTypeId: action.membershipTypeId,
                searchPageNumber: action.pageNumber,
                searchPageSize: action.pageSize,
                memberships: []
            };
        case MembershipActionTypes.MembershipsReceived:
            return {
                ...state,
                isSearching: false,
                searchMaxPage: action.maxPage,
                searchError: action.searchError,
                memberships: action.memberships
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

