
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as trt from './types'; 
import * as lt from '../login/types';
import { IStore } from '../..';


export type TaxRateActions = trt.LoadTaxRates | trt.ReceiveTaxRates | trt.SaveTaxRate | trt.TaxRateSaved | trt.TaxRateSaveFailed | trt.EditTaxRate;

export const actionCreators = {
    loadTaxRates: () => ({ type: trt.TaxRateActionTypes.RequestTaxRates }),
    loadTaxRatesComplete: (taxRates: trt.TaxRate[], err: api.ApiError | null) => ({ type: trt.TaxRateActionTypes.ReceivedTaxRates, taxRates: taxRates, error: err }),
    editTaxRate: () => ({ type: trt.TaxRateActionTypes.EditTaxRate }),
    saveTaxRate: (isNew: boolean, taxRateId: string | null, venueId: string, name: string, rate: number) => ({ type: trt.TaxRateActionTypes.SaveTaxRate, isNew: isNew, taxRateId: taxRateId, venueId: venueId, name: name, rate: rate }),
    taxRateSaved: (taxRateId: string) => ({ type: trt.TaxRateActionTypes.TaxRateSaved, taxRateId: taxRateId }),
    taxRateSaveFailed: (error: api.ApiError) => ({ type: trt.TaxRateActionTypes.TaxRateSaveFailed, error: error })
}

interface IGetTaxRatesResponse {
    taxRates: trt.TaxRate[];
}

interface ISaveTaxRateResponse {
    taxRateId: string;
}

export const handleClientChange = (store: IStore) => [actionCreators.loadTaxRates]

//https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-after-catching-and-processing-an-error-with-emitting-somethi

const loadTaxRates = () => Observable.defer(() => api.getJson<IGetTaxRatesResponse>('api/v1/taxRate/'))
    .map(response => actionCreators.loadTaxRatesComplete(response.taxRates, null));

export const loadTaxRatesEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        trt.TaxRateActionTypes.RequestTaxRates,
        action => loadTaxRates(),
        err => actionCreators.loadTaxRatesComplete([], err));

export const reloadTaxRatesEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        trt.TaxRateActionTypes.TaxRateSaved,
        action => loadTaxRates(),
        err => actionCreators.loadTaxRatesComplete([], err));

export const saveTaxRateEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        trt.TaxRateActionTypes.SaveTaxRate,
        action => {
            const stra = action as trt.SaveTaxRate;
            var body = {
                name: stra.name,
                rate: stra.rate,
            };

            return (stra.isNew ? api.post('api/v1/taxRate/', body) : api.put(`api/v1/taxRate/${stra.taxRateId}`, body))
                .map(response => {

                    let taxRateId = stra.taxRateId;
                    if (stra.isNew) {
                        const strr = response.response as ISaveTaxRateResponse;
                        if (strr) {
                            taxRateId = strr.taxRateId;
                        }
                    }

                    return ({ type: trt.TaxRateActionTypes.TaxRateSaved, taxRateId: taxRateId });
                });
        },
        (err: api.ApiError) => actionCreators.taxRateSaveFailed(err));
