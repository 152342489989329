
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as prct from './types';
import { IStore } from '../..';

export type PaymentReminderConfigActions = prct.LoadPaymentReminderConfigs | prct.ReceivePaymentReminderConfigs | prct.SavePaymentReminderConfig | prct.PaymentReminderConfigSaved | prct.PaymentReminderConfigSaveFailed | prct.EditPaymentReminderConfig;

export const actionCreators = {
    loadPaymentReminderConfigs: () => ({ type: prct.PaymentReminderConfigActionTypes.RequestPaymentReminderConfigs }),
    loadPaymentReminderConfigsComplete: (paymentReminderConfigs: prct.PaymentReminderConfig[], err: api.ApiError | null) => ({ type: prct.PaymentReminderConfigActionTypes.ReceivedPaymentReminderConfigs, paymentReminderConfigs: paymentReminderConfigs, error: err }),
    editPaymentReminderConfig: () => ({ type: prct.PaymentReminderConfigActionTypes.EditPaymentReminderConfig }),
    savePaymentReminderConfig: (isNew: boolean, paymentReminderConfigId: string | null, paymentReminderConfig: prct.PaymentReminderConfig) => ({ type: prct.PaymentReminderConfigActionTypes.SavePaymentReminderConfig, isNew: isNew, paymentReminderConfigId: paymentReminderConfigId, paymentReminderConfig: paymentReminderConfig }),
    paymentReminderConfigSaved: (paymentReminderConfigId: string) => ({ type: prct.PaymentReminderConfigActionTypes.PaymentReminderConfigSaved, paymentReminderConfigId: paymentReminderConfigId }),
    paymentReminderConfigSaveFailed: (error: api.ApiError) => ({ type: prct.PaymentReminderConfigActionTypes.PaymentReminderConfigSaveFailed, error: error }),
}

interface IGetPaymentReminderConfigsResponse {
    paymentReminderConfigs: prct.PaymentReminderConfig[];
}

interface ISavePaymentReminderConfigResponse {
    paymentReminderConfigId: string;
}

export const handleClientChange = (store: IStore) => [actionCreators.loadPaymentReminderConfigs]

//https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-prcter-catching-and-processing-an-error-with-emitting-somethi

const loadPaymentReminderConfigs = () => Observable.defer(() => api.getJson<IGetPaymentReminderConfigsResponse>(`api/v1/paymentReminderConfiguration/`))
    .map(response => {
        return actionCreators.loadPaymentReminderConfigsComplete(response.paymentReminderConfigs.map(f => f), null)
    });

export const loadPaymentReminderConfigsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        prct.PaymentReminderConfigActionTypes.RequestPaymentReminderConfigs,
        action => loadPaymentReminderConfigs(),
        err => actionCreators.loadPaymentReminderConfigsComplete([], err));

export const reloadPaymentReminderConfigsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        prct.PaymentReminderConfigActionTypes.PaymentReminderConfigSaved,
        action => loadPaymentReminderConfigs(),
        err => actionCreators.loadPaymentReminderConfigsComplete([], err));

export const savePaymentReminderConfigEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        prct.PaymentReminderConfigActionTypes.SavePaymentReminderConfig,
        action => {
            const spa = action as prct.SavePaymentReminderConfig;
            let paymentReminderConfigId = spa.paymentReminderConfigId;
            var body = ({ ...spa.paymentReminderConfig });

            return (spa.isNew ? api.post('api/v1/paymentReminderConfiguration/', body) : api.put(`api/v1/paymentReminderConfiguration/${paymentReminderConfigId}`, body))
                .map(response => {
                    if (spa.isNew) {
                        const strr = response.response as ISavePaymentReminderConfigResponse;
                        if (strr) {
                            paymentReminderConfigId = strr.paymentReminderConfigId;
                        }
                    }

                    return ({ type: prct.PaymentReminderConfigActionTypes.PaymentReminderConfigSaved, paymentReminderConfigId: paymentReminderConfigId });
                });
        },
        (err: api.ApiError) => actionCreators.paymentReminderConfigSaveFailed(err));
