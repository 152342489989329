
import { Action } from 'redux';

import { ReportTypeActionTypes, ReportTypeState } from './types';
import { ReportTypeActions } from './actions'
import { createReducer } from '../../reducer';


const unloadedState: ReportTypeState = { isLoading: false, reportTypes: [], loadError: null };

export const reducer = (state: ReportTypeState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: ReportTypeState, incomingAction: Action): ReportTypeState => {

    const action = incomingAction as ReportTypeActions;
    switch (action.type) {
        case ReportTypeActionTypes.RequestReportTypes:
            return {
                ...state,
                isLoading: true,
                reportTypes: state.reportTypes,
                loadError: null
            };
        case ReportTypeActionTypes.ReceivedReportTypes:
            const ordered = action.reportTypes ? action.reportTypes : [];

            return {
                ...state,
                isLoading: false,
                reportTypes: ordered,
                loadError: action.error
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

