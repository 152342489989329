
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as dt from '../../../store/pages/diary/types';
import { ITranslationContext } from '../../../translations';
import { clickHandler, isNullOrEmpty } from '../../../utils/util';
import SendEmailOverlay from './sendEmailOverlay';
import { ClientEmailTemplate, EmailType } from '../../../store/pages/emailTemplates/types';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';
import { stringComparer } from '../../../utils/comparers';


interface EventRegistrationProps {
    bk: dt.EventBooking;
    reg: dt.EventRegistration;
    hasResultUrl: boolean;
    dateFormat: DateFormat;
    timeFormat: TimeFormat;
    sendEmail: (reg: dt.EventRegistration) => void;
    viewRegistration: (reg: dt.EventRegistration) => void;
}

const EventRegistration = (props: EventRegistrationProps, context: ITranslationContext) => {
    const { bk, reg, hasResultUrl, dateFormat, timeFormat, sendEmail, viewRegistration } = props;
    const { t } = context;


    return <tr key={`${bk.id}_${reg.registrationId}`}>
        <td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => viewRegistration(reg))} style={{ marginLeft: '15px' }}>{reg.firstname} {reg.lastname} {isNullOrEmpty(reg.nickname) ? null : ` (${reg.nickname})`}</button></td>
        <td>{reg.customerCategories ? reg.customerCategories.map(c => c.customerCategoryCode).join(',') : null}</td>
        <td>{reg.age}</td>
        <td>{reg.watchedVideo ? <i className='glyphicon glyphicon-facetime-video' style={({ color: '#5cb85c' })}></i> : null}</td>
        <td>{reg.emailAddress}</td>
        <td>{reg.phoneNumber}</td>
        <td className='text-center'>{reg.resultEmailSent ? t('registrations:sent') : reg.resultEmailRequested ? t('registrations:requested') : t('Global:no')}</td>
        <td className='text-right'>{reg.registrationTime.toAbbrDateString(dateFormat, t)} {reg.registrationTime.toShortTimeString(timeFormat)}</td>
        <td>{reg.registrationTermsName}</td>
        <td className='text-right'>{reg.termsLastAgreed.toAbbrDateString(dateFormat, t)} {reg.termsLastAgreed.toShortTimeString(timeFormat)}</td>
        <td>{reg.customFields.filter(f => !isNullOrEmpty(f.value)).map((f, ix) => <div key={`${f.customFieldName}_${ix}`}><label>{f.customFieldName}</label>: {f.value}</div>)}</td>
        <td>{props.hasResultUrl ? <button className='btn btn-primary btn-sm' onClick={e => clickHandler(e, () => sendEmail(reg))}>{t('registrations:email')}</button> : null}</td>
    </tr>
}

EventRegistration.contextTypes = {
    t: PropTypes.func
}

export default EventRegistration;


