
import * as gt from '../../store/global/types';
import { ValidationError } from '../../store/global/types';
import * as ct from './controls';

export type Validation = (value: any) => string|undefined;

type Validatior = <T>(value: T, controlId: string, validations?: Validation[], serverErrors?: gt.ValidationError[]) => ct.FormValue<T>;

const UrlSafeValidation = RegExp('^[a-zA-Z0-9_-]*$');

export const validate: Validatior = <T>(value: T, controlId: string, validations?: Validation[], serverErrors?: gt.ValidationError[]) => {

    const filteredServerErrors = serverErrors ? serverErrors.filter(e => e.field.toLowerCase() === controlId.toLowerCase()) : [];
    const hasValidation = ((validations && validations.length > 0) || filteredServerErrors.length > 0);

    if (hasValidation) {
        if (validations) {
            for (let i = 0; i < validations.length; i++) {
                const v = validations[i];
                const result = v(value);
                if (result !== undefined) {
                    return {
                        controlId: controlId,
                        value: value,
                        isValid: false,
                        errorMessageKey: result,
                        hasValidation: true
                    };
                }
            }
        }

        if (filteredServerErrors.length > 0) {
            for (let i = 0; i < filteredServerErrors.length; i++) {
                return {
                    controlId: controlId,
                    value: value,
                    isValid: false,
                    errorMessageKey: filteredServerErrors[i].messageKey,
                    hasValidation: true
                };
            }
        }
    }

    return { controlId: controlId, value: value, isValid: true, hasValidation: hasValidation };
}

export const isValid = (state: any) => {
    for (let prop in state) {
        if (state.hasOwnProperty(prop)) {
            const val = state[prop];

            if (val && val.isValid !== undefined && !val.isValid) {
                return false;
            }
        }
    }

    return true;
}

function isFormValue(val: ct.BaseFormValue | any): val is ct.BaseFormValue {
    const fv = val as ct.BaseFormValue;
    const hasControlIdField = fv && fv.controlId !== undefined;
    return hasControlIdField;
}

export const updateServerErrors = <T, K extends keyof T>(state: T, validationErrors: ValidationError[]): Pick<T, K> => {
    let newState = {};

    for (let key in state) {
        const val = Reflect.get(state as any, key);

        if (isFormValue(val)) {
            var err = validationErrors.filter(e => e.field === val.controlId);
            const hasError = err && err.length > 0;
            newState = { ...newState, [key]: { ...val, errorMessageKey: hasError ? err[0].messageKey : undefined, isValid: !hasError, hasValidation: val.hasValidation || hasError } };
        }
    }

    return newState as Pick<T, K>;
}

export const required: Validation = (value: any) => {
    if (value)
        return undefined;

    if (typeof (value) === 'number' && value === 0)
        return undefined;

    return 'validation:required';
}

export const requiredHtml: Validation = (value: any) => {
    if (typeof (value) === 'string') {

        const paragraphs = value.match(/<p[^>]*>(.*?)<\/p>/g);
        if (!paragraphs || paragraphs.length === 0) {
            return 'validation:required';
        } else {
            var hasValidContent = paragraphs.reduce((valid, match) => {
                const paragraphInvalid = /<p[^>]*>(\s|&nbsp;|<\s?br\s?\/?>)*<\/p>/g.test(match);
                return valid || !paragraphInvalid
            }, false);

            if (hasValidContent) {
                return undefined;
            }
        }
    }

    return 'validation:required';
}

export const requiredNonZero = (value: any) => {
    const isValid = required(value);
    return typeof isValid === 'undefined' && value === 0 ? 'validation:required' : undefined
}

export const numeric: Validation = (value: number) => isNaN(value) ? 'validation:required' : undefined;

export const numericStr: Validation = (value: string) => value && isNaN(parseFloat(value)) ? 'validation:required' : undefined;

export const urlSafeString: Validation = (value: string) => UrlSafeValidation.test(value) ? undefined : 'validation:invalidUrlCharacters'

export const validMoment: Validation = (value: moment.Moment) => !value.isValid() ? 'validation:required' : undefined;

/*eslint-disable no-useless-escape */
export const email: Validation = (value: string) => !value || /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) ? undefined : 'validation:invalidEmail';