
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';

import * as api from '../../../../../store/apiClient';

import { AjaxResponse } from 'rxjs/ajax';
import { BillPayment, GatewayPaymentStatus } from '../../../../../store/pages/pointOfSale/types';
import { PaymentMethod } from '../../../../../store/pages/paymentMethods/types';
import { clickHandler, isNullOrEmpty } from '../../../../../utils/util';
import { StripeReader } from '../../../../../store/pages/paymentGateways/types';
import Loading from '../../../../global/loading';
import ApiError from '../../../../global/apiError';
import CardReader from './cardReader';


interface ListStripeReadersResponse {
    readers: StripeReader[];
}

interface ReaderSelectionProps {
    venueId: string;
    billId: string;
    payment: BillPayment;
    amount: number;
    paymentMethod: PaymentMethod;
    enterCardManually: () => void;
    initiateTerminalPayment: (terminalId: string) => void;
    cancelPayment: () => void;
    logout: () => void;
}

interface ReaderSelectionState {
    loading: boolean;
    readers: StripeReader[];
    loadReadersError: api.ApiError | null;
}

export class ReaderSelection extends React.Component<ReaderSelectionProps, ReaderSelectionState> {

    constructor(props: ReaderSelectionProps) {
        super(props);

        this.state = { loading: true, readers: [], loadReadersError: null };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    loadReaders = (paymentMethod: PaymentMethod) => {
        api.getWithAuth<ListStripeReadersResponse>(`api/v1/stripe/${paymentMethod.id}/listReaders`, this.props.logout)
            .subscribe(
                resp => this.setState({ loading: false, readers: resp.readers, loadReadersError: null }),
                err => this.setState({ loading: false, readers: [], loadReadersError: err }))
    }

    componentDidMount() {
        const { paymentMethod } = this.props;

        this.loadReaders(paymentMethod);
    }

    render() {
        const { enterCardManually, initiateTerminalPayment, cancelPayment } = this.props;
        const { loading, readers, loadReadersError } = this.state;
        const { t } = this.context;

        if (loading) {
            return <Loading />
        }

        if (loadReadersError) {
            return <ApiError error={loadReadersError} />
        }

        return <div className='pos-stripe-payment-method'>
            <label>{t('PointOfSale:selectCardReader')}</label>
            <ul className='pos-stripe-reader-list'>
                {readers.map(r => <CardReader reader={r} select={initiateTerminalPayment} />)}
            </ul>
            <label>{t('Global:or')}</label>
            <button key='enter_card_manually' className='btn btn-primary' onClick={e => clickHandler(e, enterCardManually)}>{t('PointOfSale:enterCardNumberManually')}</button>
            <button key='cancel' className='btn btn-default mt-15' onClick={e => clickHandler(e, cancelPayment)}>{t('Global:cancel')}</button>
        </div>
    }
}

ReaderSelection.contextTypes = {
    t: PropTypes.func
}

export default ReaderSelection;