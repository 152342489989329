
// https://blog.angularindepth.com/how-to-reduce-action-boilerplate-90dc3d389e2b

import { ActionsObservable, combineEpics, Epic, ofType } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import { zip } from 'rxjs';
import { Action } from 'redux';
import { flatMap, repeat } from 'rxjs/operators';

import { IStore } from '../..';
import { LoginActionTypes } from './types';
import { clientDataLoadComplete } from './actions';

import { VenueActionTypes } from '../venues/types';
import { handleClientChange as venueChangeHandler } from '../venues/actions';

import { ResourceActionTypes } from '../resources/types';
import { handleClientChange as resourceChangeHandler } from '../resources/actions';

import { ActivityFormatActionTypes } from '../activityFormats/types';
import { handleClientChange as activityFormatChangeHandler } from '../activityFormats/actions';

import { ActivityFormatGroupActionTypes } from '../activityFormatGroups/types';
import { handleClientChange as activityFormatGroupChangeHandler } from '../activityFormatGroups/actions';

import { TagActionTypes } from '../tags/types';
import { handleClientChange as tagChangeHandler } from '../tags/actions';

import { CustomerCategoryActionTypes } from '../customerCategories/types';
import { handleClientChange as customerCategoryChangeHandler } from '../customerCategories/actions';

import { EmailTemplateActionTypes } from '../emailTemplates/types';
import { handleClientChange as emailTemplateChangeHandler } from '../emailTemplates/actions';

import { FeeActionTypes } from '../fees/types';
import { handleClientChange as feeChangeHandler } from '../fees/actions';

import { ProductCategoryActionTypes } from '../productCategories/types';
import { handleClientChange as productCategoriesChangeHandler } from '../productCategories/actions';

import { TaxRateActionTypes } from '../taxRates/types';
import { handleClientChange as taxRateChangeHandler } from '../taxRates/actions';

import { ProductActionTypes } from '../products/types';
import { handleClientChange as productChangeHandler } from '../products/actions';

import { PaymentGatewayActionTypes } from '../paymentGateways/types';
import { handleClientChange as paymentGatewayChangeHandler } from '../paymentGateways/actions';

import { PaymentMethodActionTypes } from '../paymentMethods/types';
import { handleClientChange as paymentMethodsChangeHandler } from '../paymentMethods/actions';

import { PaymentScheduleActionTypes } from '../paymentSchedules/types';
import { handleClientChange as paymentScheduleChangeHandler } from '../paymentSchedules/actions';

import { PromotionActionTypes } from '../promotions/types';
import { handleClientChange as promotionsChangeHandler } from '../promotions/actions';

import { TermsAndConditionsActionTypes } from '../termsAndConditions/types';
import { handleClientChange as termsAndConditionsChangeHandler } from '../termsAndConditions/actions';

import { CampaignActionTypes } from '../campaigns/types';
import { handleClientChange as campaignChangeHandler } from '../campaigns/actions';

import { AffiliateActionTypes } from '../affiliates/types';
import { handleClientChange as affiliateChangeHandler } from '../affiliates/actions';

import { CustomerActionTypes } from '../customer/types';
import { handleClientChange as customerChangeHandler } from '../customer/actions';

import { DiaryActionTypes } from '../diary/types';
import { handleClientChange as diaryChangeHandler } from '../diary/actions';

import { EmailActionTypes } from '../emails/types';
import { handleClientChange as emailChangeHandler } from '../emails/actions';

import { IntegrationActionTypes } from '../integrations/types';
import { handleClientChange as integrationsChangeHandler } from '../integrations/actions';

import { PaymentReminderConfigActionTypes } from '../paymentReminderConfigurations/types';
import { handleClientChange as paymentReminderConfigurationHandler } from '../paymentReminderConfigurations/actions';

import { PublicWebsiteSettingsActionTypes } from '../publicWebsiteSettings/types';
import { handleClientChange as publicWebsiteSettingsChangeHandler } from '../publicWebsiteSettings/actions';

import { ReportDefinitionActionTypes } from '../reportDefinitions/types';
import { handleClientChange as reportDefintionsChangeHandler } from '../reportDefinitions/actions';

import { ReportTypeActionTypes } from '../reportTypes/types';
import { handleClientChange as reportTypesChangeHandler } from '../reportTypes/actions';

import { BookingEmailScheduleConfigActionTypes } from '../bookingEmailScheduleConfigs/types';
import { handleClientChange as bookingEmailScheduleChangeHandler } from '../bookingEmailScheduleConfigs/actions';

import { VoucherActionTypes } from '../vouchers/types';
import { handleClientChange as vouchersChangeHandler } from '../vouchers/actions';

import { TaskDefinitionActionTypes } from '../tasks/types';
import { handleClientChange as tasksChangeHandler } from '../tasks/actions';

import { UserActionTypes } from '../users/types';
import { handleClientChange as usersChangeHandler } from '../users/actions';

import { MembershipActionTypes } from '../memberships/types';
import { handleClientChange as membershipsChangeHandler } from '../memberships/actions';

import { LeaderboardActionTypes } from '../leaderboards/types';
import { handleClientChange as leaderboardsChangeHandler } from '../leaderboards/actions';


const loadAfter = (action$: ActionsObservable<any>, store: IStore, dependentAction: any, loadActions: (store: IStore) => (() => any)[]) => {
    const cs$ = action$.pipe(ofType(LoginActionTypes.LoadDataForClient));
    const rv$ = action$.pipe(ofType(dependentAction));
    const combined$ = zip(cs$, rv$);

    const actions = loadActions(store);

    return combined$.pipe(flatMap(_ => actions.map(a => a())), repeat());
}

export const handlers: Epic<any, Action<any>, void, any> = combineEpics(
    // 1. venues
    (action$: ActionsObservable<any>, store: any) =>
        action$.ofType(LoginActionTypes.LoadDataForClient)
            .switchMap(action => {
                const actions = venueChangeHandler(store);
                const act = actions[0];
                return Observable.of(act());
            }),

    // 2. Load resources
    (action$: ActionsObservable<any>, store: any) => loadAfter(action$, store, VenueActionTypes.ReceivedVenues, resourceChangeHandler),
    // 3. Load activity formats
    (action$: ActionsObservable<any>, store: any) => loadAfter(action$, store, ResourceActionTypes.ReceivedResources, activityFormatChangeHandler),
    // 4. Load tags
    (action$: ActionsObservable<any>, store: any) => loadAfter(action$, store, ActivityFormatActionTypes.ReceivedActivityFormats, activityFormatGroupChangeHandler),
    // 5. Load tags
    (action$: ActionsObservable<any>, store: any) => loadAfter(action$, store, ActivityFormatGroupActionTypes.ReceivedActivityFormatGroups, tagChangeHandler),
    // 6. Load customer categories
    (action$: ActionsObservable<any>, store: any) => loadAfter(action$, store, TagActionTypes.ReceivedTags, customerCategoryChangeHandler),
    // 7. Load email templates
    (action$: ActionsObservable<any>, store: any) => loadAfter(action$, store, CustomerCategoryActionTypes.ReceivedCustomerCategories, emailTemplateChangeHandler),
    // 8. Load email templates
    (action$: ActionsObservable<any>, store: any) => loadAfter(action$, store, EmailTemplateActionTypes.ReceivedEmailTemplates, feeChangeHandler),
    // 9. Load product categories
    (action$: ActionsObservable<any>, store: any) => loadAfter(action$, store, FeeActionTypes.ReceivedFees, productCategoriesChangeHandler),
    // 10. Load Tax rates
    (action$: ActionsObservable<any>, store: any) => loadAfter(action$, store, ProductCategoryActionTypes.ReceivedProductCategories, taxRateChangeHandler),
    // 11. Load Products
    (action$: ActionsObservable<any>, store: any) => loadAfter(action$, store, TaxRateActionTypes.ReceivedTaxRates, productChangeHandler),
    // 12. Load payment gateways
    (action$: ActionsObservable<any>, store: any) => loadAfter(action$, store, ProductActionTypes.ReceivedProducts, paymentGatewayChangeHandler),
    // 13. Load payment methods
    (action$: ActionsObservable<any>, store: any) => loadAfter(action$, store, PaymentGatewayActionTypes.ReceivedPaymentGateways, paymentMethodsChangeHandler),
    // 14. Load Payment Methods
    (action$: ActionsObservable<any>, store: any) => loadAfter(action$, store, PaymentMethodActionTypes.ReceivedPaymentMethods, paymentScheduleChangeHandler),
    // 15. Load payment schedules
    (action$: ActionsObservable<any>, store: any) => loadAfter(action$, store, PaymentScheduleActionTypes.ReceivedPaymentSchedules, promotionsChangeHandler),
    // 16. Load terms and conditions
    (action$: ActionsObservable<any>, store: any) => loadAfter(action$, store, PromotionActionTypes.ReceivedPromotions, termsAndConditionsChangeHandler),
    // 17. Campaigns
    (action$: ActionsObservable<any>, store: any) => loadAfter(action$, store, TermsAndConditionsActionTypes.ReceivedBookingTerms, campaignChangeHandler),
    // 18. Affiliates
    (action$: ActionsObservable<any>, store: any) => loadAfter(action$, store, CampaignActionTypes.ReceivedCampaigns, affiliateChangeHandler),
    // 19. Customers
    (action$: ActionsObservable<any>, store: any) => loadAfter(action$, store, AffiliateActionTypes.ReceivedAffiliates, customerChangeHandler),
    // 20. Diary
    (action$: ActionsObservable<any>, store: any) => loadAfter(action$, store, CustomerActionTypes.ReceivedCustomers, diaryChangeHandler),
    // 21. Emails
    (action$: ActionsObservable<any>, store: any) => loadAfter(action$, store, DiaryActionTypes.ReceivedDiaryReservations, emailChangeHandler),
    // 22. Integrations
    (action$: ActionsObservable<any>, store: any) => loadAfter(action$, store, EmailActionTypes.ReceivedEmails, integrationsChangeHandler),
    // 23. Payment reminders
    (action$: ActionsObservable<any>, store: any) => loadAfter(action$, store, IntegrationActionTypes.ReceivedIntegrations, paymentReminderConfigurationHandler),
    // 24. Public website Settings
    (action$: ActionsObservable<any>, store: any) => loadAfter(action$, store, PaymentReminderConfigActionTypes.ReceivedPaymentReminderConfigs, publicWebsiteSettingsChangeHandler),
    // 25. Report definitions
    (action$: ActionsObservable<any>, store: any) => loadAfter(action$, store, PublicWebsiteSettingsActionTypes.ReceivedPublicWebsiteSettings, reportDefintionsChangeHandler),
    // 26. Report types
    (action$: ActionsObservable<any>, store: any) => loadAfter(action$, store, ReportDefinitionActionTypes.ReceivedReportDefinitions, reportTypesChangeHandler),
    // 27. Tasks
    (action$: ActionsObservable<any>, store: any) => loadAfter(action$, store, ReportTypeActionTypes.ReceivedReportTypes, bookingEmailScheduleChangeHandler),
    // 28. Vouchers
    (action$: ActionsObservable<any>, store: any) => loadAfter(action$, store, BookingEmailScheduleConfigActionTypes.ReceivedBookingEmailScheduleConfigs, vouchersChangeHandler),
    // 29. Tasks
    (action$: ActionsObservable<any>, store: any) => loadAfter(action$, store, VoucherActionTypes.ReceivedVoucherProducts, tasksChangeHandler),
    // 30. Users
    (action$: ActionsObservable<any>, store: any) => loadAfter(action$, store, TaskDefinitionActionTypes.ReceivedTaskDefinitions, usersChangeHandler),
    // 31. Lederboards
    (action$: ActionsObservable<any>, store: any) => loadAfter(action$, store, UserActionTypes.ReceivedUsers, leaderboardsChangeHandler),
    // 32. Memberships
    (action$: ActionsObservable<any>, store: any) => loadAfter(action$, store, LeaderboardActionTypes.ReceivedLeaderboards, membershipsChangeHandler),

    // Finally 
    (action$: ActionsObservable<any>, store: any) => loadAfter(action$, store, MembershipActionTypes.ReceivedMembershipTypes, clientDataLoadComplete)
)



