
import { Action } from 'redux';

import { NavigationActionTypes, NavigationState } from './types';
import { NavigationActions } from './actions';
import { createReducer } from '../../reducer';

const unloadedState: NavigationState = {
    currentRoute: '',
    lastChange: new Date()
};

export const reducer = (state: NavigationState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: NavigationState, incomingAction: Action): NavigationState => {

    const action = incomingAction as NavigationActions;
    switch (action.type) {
        case NavigationActionTypes.NavigationRouteChanged:
            return {
                ...state,
                currentRoute: action.route,
                lastChange: action.whenRouteChanged
            };
       /* default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action; */
    }

    return state || unloadedState;
};

