
import * as api from '../../apiClient';

export interface Culture {
    name: string;
    displayName: string;
    englishName: string;
    nativeName: string;
}

export interface CultureState {
    isLoading: boolean;
    cultures: Culture[];
    loadError: api.ApiError | null;
}

export enum CultureActionTypes {
    RequestCultures = 'REQUEST_CULTURES',
    ReceivedCultures = 'RECEIVE_CULTURES',
    RequestCulturesError = 'REQUEST_CULTURES_ERROR'
}


export interface RequestCultures {
    type: CultureActionTypes.RequestCultures;
}

export interface ReceiveCultures {
    type: CultureActionTypes.ReceivedCultures;
    cultures: Culture[];
}

export interface RequestCulturesError {
    type: CultureActionTypes.RequestCulturesError;
    error: api.ApiError;
}
