import { ValidationError } from '../../global/types';
import * as api from '../../apiClient';

export interface TaxRate {
    id: string;
    name: string;
    rate: number;
}


export interface TaxRateState {
    isLoading: boolean;
    taxRates: TaxRate[];
    loadError: api.ApiError | null;
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
}

export enum TaxRateActionTypes {
    RequestTaxRates = 'REQUEST_TAX_RATES',
    ReceivedTaxRates = 'RECEIVE_TAX_RATES',
    SaveTaxRate = 'SAVE_TAX_RATE',
    TaxRateSaved = 'TAX_RATE_SAVED',
    TaxRateSaveFailed = 'TAX_RATE_SAVE_FAILED',
    EditTaxRate = 'EDIT_TAX_RATE'
}


export interface LoadTaxRates {
    type: TaxRateActionTypes.RequestTaxRates;
}

export interface ReceiveTaxRates {
    type: TaxRateActionTypes.ReceivedTaxRates;
    taxRates: TaxRate[];
    error: api.ApiError | null;
}

export interface SaveTaxRate {
    type: TaxRateActionTypes.SaveTaxRate;
    isNew: boolean;
    taxRateId?: string;
    name: string;
    rate: number;
}

export interface TaxRateSaved {
    type: TaxRateActionTypes.TaxRateSaved;
    taxRateId: string;
}

export interface TaxRateSaveFailed {
    type: TaxRateActionTypes.TaxRateSaveFailed;
    error: api.ApiError;
}

export interface EditTaxRate {
    type: TaxRateActionTypes.EditTaxRate
}
