
import { Action } from 'redux';
import { LocationChangeAction } from 'react-router-redux';

import { DiaryActionTypes, DiaryState } from './types';
import { DiaryActions } from './actions'
import { mapLocalDateTime, mapUtcDate, parseLocalDateTime, remove } from '../../../utils/util';
import { createReducer } from '../../reducer';

const unloadedState: DiaryState = {
    isLoading: false,
    isActive: false,
    date: new Date(),
    reservations: [],
    openingTimes: [],
    diaryNotes: {},
    loadError: null,
    isSaving: false,
    saveComplete: false,
    autoSaved: false,
    saveError: null,
    validationErrors: [],
    isSavingDiaryNotes: false,
    diaryNoteSaveError: null,
    customerSearchResults: [],
    searchMessageKey: '',
    lastSearchTerm: null,
    searchingCustomers: false,
    activatingTask: false,
    taskActivationError: null,
    taskActivationErrors: [],
    scrollPosition: { top: 0, left: 0 },
    isSavingOpeningTimes: false,
    openingTimesSaveError: null
};

export const reducer = (state: DiaryState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: DiaryState, incomingAction: Action): DiaryState => {

    if (incomingAction.type === '@@router/LOCATION_CHANGE') {
        const locationChangeAction = incomingAction as LocationChangeAction;
        if (locationChangeAction) {
            let path = locationChangeAction.payload.pathname;
            if (!path) {
                // the object we are getting doesn't match the typings, 
                // so this hack is in here to get the correct value from the object
                const pl = locationChangeAction.payload as any;
                if (pl && pl.location && pl.location.pathname) {
                    path = pl.location.pathname
                }
            }
            return { ...state, isActive: path === '/diary' };
        }
    }

    const action = incomingAction as DiaryActions;
    switch (action.type) {
        case DiaryActionTypes.SwitchDate:
            return {
                ...state,
                date: action.date,
                loadError: null
            };
        case DiaryActionTypes.RequestDiaryReservations:
            return {
                ...state,
                isActive: true,
                isLoading: true,
                date: action.date,
                reservations: [],
                loadError: null
            };
        case DiaryActionTypes.ReceivedDiaryReservations:
            return {
                ...state,
                isLoading: false,
                reservations: (action.reservations || []).map(rsv => ({
                    ...rsv,
                    draft: rsv.draft,
                    colour: rsv.colour,
                    startTime: parseLocalDateTime(rsv.startTime),
                    endTime: parseLocalDateTime(rsv.endTime),
                    actualStartTime: mapLocalDateTime(rsv.actualStartTime),
                    depositDueDate: mapUtcDate(rsv.depositDueDate),
                    paymentDueDate: mapUtcDate(rsv.paymentDueDate),
                    overduePaymentDate: mapUtcDate(rsv.overduePaymentDate),
                    version: new Date().getTime()
                })),
                openingTimes: action.openingTimes || [],
                loadError: action.error
            };
        case DiaryActionTypes.LoadDiaryNote:
            return { ...state };
        case DiaryActionTypes.ReceivedDiaryNote:
            const { diaryNotes } = state;
            if (action.note) {
                diaryNotes[`${action.venueId}_${action.date.toYMDDateString()}`] = action.note;
            } else {
                remove(diaryNotes,`${action.venueId}_${action.date.toYMDDateString()}`);
            }

            return {
                ...state,
                diaryNotes,
                loadError: action.error
            };
        case DiaryActionTypes.SearchCustomers:
            return {
                ...state,
                customerSearchResults: [],
                searchMessageKey: '',
                searchingCustomers: true,
                lastSearchTerm: action.search
            };
        case DiaryActionTypes.SearchCustomerResponse:
            return {
                ...state,
                searchMessageKey: action.messageKey,
                customerSearchResults: action.customers.map(c => ({
                    ...c,
                    lastEvent: c.lastEvent ? { ...c.lastEvent, date: parseLocalDateTime(c.lastEvent.date) } : null,
                    lastBooking: c.lastBooking ? { ...c.lastBooking, date: parseLocalDateTime(c.lastBooking.date) } : null
                })),
                searchingCustomers: false
            };
        case DiaryActionTypes.EventChanged:
            return state;
        case DiaryActionTypes.DiaryNoteChanged:
            return state;
        case DiaryActionTypes.SaveDiaryNote:
            return {
                ...state,
                isSavingDiaryNotes: true,
                diaryNoteSaveError: null
            };
        case DiaryActionTypes.DiaryNoteSaved:
            return {
                ...state,
                isSavingDiaryNotes: false,
                diaryNoteSaveError: null
            };
        case DiaryActionTypes.DiaryNoteSaveFailed:
            return {
                ...state,
                isSavingDiaryNotes: false,
                diaryNoteSaveError: action.error
            };
        case DiaryActionTypes.SetScrollPosition:
            return {
                ...state,
                scrollPosition: { top: action.top, left: action.left }
            }
        case DiaryActionTypes.SaveOpeningTimes:
            return {
                ...state,
                isSavingOpeningTimes: true,
                openingTimesSaveError: null
            };
        case DiaryActionTypes.OpeningTimesSaved:
            return {
                ...state,
                isSavingOpeningTimes: false,
                openingTimesSaveError: null
            };
        case DiaryActionTypes.OpeningTimesSaveFailed:
            return {
                ...state,
                isSavingOpeningTimes: false,
                openingTimesSaveError: action.error
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action; 
    }

    return state || unloadedState;
};

