
import { Action } from 'redux';

import { PaymentReminderConfigActionTypes, PaymentReminderConfigState } from './types';
import { PaymentReminderConfigActions } from './actions'
import { createReducer } from '../../reducer';

const unloadedState: PaymentReminderConfigState = { isLoading: false, paymentReminderConfigs: [], loadError: null, isSaving: false, saveComplete: false, saveError: null, validationErrors: [] };

export const reducer = (state: PaymentReminderConfigState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: PaymentReminderConfigState, incomingAction: Action): PaymentReminderConfigState => {

    const action = incomingAction as PaymentReminderConfigActions;
    switch (action.type) {
        case PaymentReminderConfigActionTypes.RequestPaymentReminderConfigs:
            return {
                ...state,
                isLoading: true,
                paymentReminderConfigs: state.paymentReminderConfigs,
                loadError: null
            };
        case PaymentReminderConfigActionTypes.ReceivedPaymentReminderConfigs:
            const ordered = action.paymentReminderConfigs ? action.paymentReminderConfigs : [];
            ordered.sort((a, b) => a.name.localeCompare(b.name));

            return {
                ...state,
                isLoading: false,
                paymentReminderConfigs: ordered,
                loadError: action.error
            };
        case PaymentReminderConfigActionTypes.EditPaymentReminderConfig:
            return {
                ...state,
                isSaving: false,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case PaymentReminderConfigActionTypes.SavePaymentReminderConfig:
            return {
                ...state,
                isSaving: true,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case PaymentReminderConfigActionTypes.PaymentReminderConfigSaved:
            return {
                ...state,
                isSaving: false,
                saveComplete: true,
                saveError: null,
                validationErrors: []
            };
        case PaymentReminderConfigActionTypes.PaymentReminderConfigSaveFailed:
            return {
                ...state,
                isSaving: false,
                saveError: action.error,
                saveComplete: false,
                validationErrors: action.error && action.error.validationErrors ? action.error.validationErrors : []
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

