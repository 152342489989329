
import { Action } from 'redux';

import { TimeZoneActionTypes, TimeZoneState } from './types';
import { TimeZoneActions } from './actions'
import { createReducer } from '../../reducer';


const unloadedState: TimeZoneState = { isLoading: false, timeZones: [], loadError: null };

export const reducer = (state: TimeZoneState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: TimeZoneState, incomingAction: Action): TimeZoneState => {

    const action = incomingAction as TimeZoneActions;
    switch (action.type) {
        case TimeZoneActionTypes.RequestTimeZones:
            return {
                ...state,
                isLoading: true,
                timeZones: state.timeZones,
                loadError: null
            };
        case TimeZoneActionTypes.ReceivedTimeZones:
            const ordered = action.timeZones ? action.timeZones : [];
            ordered.sort((a, b) => a.name.localeCompare(b.name));

            return {
                ...state,
                isLoading: false,
                timeZones: ordered,
                loadError: null
            };
        case TimeZoneActionTypes.RequestTimeZonesError:
            return {
                ...state,
                isLoading: false,
                timeZones: [],
                loadError: action.error
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

