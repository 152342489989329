
import 'quill/dist/quill.snow.css';
import '../../../../css/quill.css';

import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'

import { quillStandardToolbar } from '../../../global/quillSettings';

import * as api from '../../../../store/apiClient';
import ApiError from '../../../global/apiError';
import { ApplicationState } from '../../../../store';
import * as ct from '../../../global/controls';
import * as v from '../../../global/validation';
import { VenuePublicWebsiteSettings } from '../../../../store/pages/publicWebsiteSettings/types';
import * as PublicWebsiteSettingsActions from '../../../../store/pages/publicWebsiteSettings/actions';
import { clickHandler } from '../../../../utils/util';
import { ValidationError } from '../../../../store/global/types';
import { Venue } from '../../../../store/pages/venues/types';

interface LocalProps {
    venueId: string;
    publicWebsiteSettings: VenuePublicWebsiteSettings;
    close: () => void;
}

interface MappedState {
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
    venues: Venue[];
}

interface MappedActions {
    savePublicWebsiteSettings: (publicWebsiteSettings: VenuePublicWebsiteSettings, backgroundImg: File | null, headerImg: File | null) => void;
}

type OnlineRegistrationSettingsFormProps = MappedState & MappedActions & LocalProps;

interface OnlineRegistrationSettingsFormState {
    landingPageContent: ct.FormValue<string>;
    participantsPageHeading: ct.FormValue<string>;
    completionPageContent: ct.FormValue<string>;
    registrationKioskId: ct.FormValue<string>;
    quillStyle: React.CSSProperties | undefined;
}

class OnlineRegistrationSettingsForm extends React.Component<OnlineRegistrationSettingsFormProps, OnlineRegistrationSettingsFormState> {

    constructor(props: OnlineRegistrationSettingsFormProps) {
        super(props);

        this.state = this.buildStateFromProps(this.props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps(props: OnlineRegistrationSettingsFormProps): OnlineRegistrationSettingsFormState {

        const settings = props.publicWebsiteSettings;

        return {
            landingPageContent: this.validateLandingPageContent(settings.onlineRegistration.landingPageContent),
            participantsPageHeading: this.validateParticipantsPageHeading(settings.onlineRegistration.participantsPageHeading),
            completionPageContent: this.validateCompletionPageContent(settings.onlineRegistration.completionPageContent),
            registrationKioskId: this.validateRegistrationKioskId(settings.onlineRegistration.registrationKioskId),
            quillStyle: ({ backgroundColor: settings.panelColour, color: settings.panelTextColour }),
        };
    }

    componentDidUpdate(prevProps: OnlineRegistrationSettingsFormProps) {
        // Only update state if resource has changed
        const { saveComplete: prevSaveComplete, publicWebsiteSettings: prevPublicWebsiteSettings } = prevProps;
        const { publicWebsiteSettings, saveComplete } = this.props;
        const prevOnlineRegistrationSettings = prevPublicWebsiteSettings.onlineRegistration;
        const nextOnlineRegistrationSettings = publicWebsiteSettings.onlineRegistration;

        if (prevOnlineRegistrationSettings.landingPageContent !== nextOnlineRegistrationSettings.landingPageContent
            || prevOnlineRegistrationSettings.completionPageContent !== nextOnlineRegistrationSettings.completionPageContent
            || prevOnlineRegistrationSettings.participantsPageHeading !== nextOnlineRegistrationSettings.participantsPageHeading
            || prevOnlineRegistrationSettings.registrationKioskId !== nextOnlineRegistrationSettings.registrationKioskId) {
            this.setState(this.buildStateFromProps(this.props));
        }

        if (saveComplete && !prevSaveComplete) {
            setTimeout(() => { this.close(); }, 750);
        }
    }

    private savePaymentPageSettings = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }

    private close = () => {
        this.props.close();
    }

    private save = () => {
        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {
            const { publicWebsiteSettings } = this.props;
            const { landingPageContent, participantsPageHeading, completionPageContent, registrationKioskId } = this.state;

            this.props.savePublicWebsiteSettings({
                ...publicWebsiteSettings,
                onlineRegistration: {
                    landingPageContent: landingPageContent.value,
                    participantsPageHeading: participantsPageHeading.value,
                    completionPageContent: completionPageContent.value,
                    registrationKioskId: registrationKioskId.value
                }
            }, null, null);
        }
    }

    validateLandingPageContent = (val: string) => v.validate(val, 'landingPageContent', [v.requiredHtml], []);
    validateParticipantsPageHeading = (val: string) => v.validate(val, 'participantsPageHeading', [], []);
    validateCompletionPageContent = (val: string) => v.validate(val, 'completionPageContent', [v.requiredHtml], []);
    validateRegistrationKioskId = (val: string) => v.validate(val, 'registrationKioskId', [v.required], []);

    landingPageContentChanged = (val: string) => this.setState({ landingPageContent: this.validateLandingPageContent(val) });
    participantsPageHeadingChanged = (val: string) => this.setState({ participantsPageHeading: this.validateParticipantsPageHeading(val) });
    completionPageContentChanged = (val: string) => this.setState({ completionPageContent: this.validateCompletionPageContent(val) });

    onRegistrationKioskChanged = (val: string) => this.setState({ registrationKioskId: this.validateRegistrationKioskId(val) })

    render() {
        const t = this.context.t;
        const { venueId, isSaving, saveError, saveComplete, venues } = this.props;
        const { landingPageContent, participantsPageHeading, completionPageContent, registrationKioskId, quillStyle } = this.state;

        const message = saveError ? <ApiError error={saveError} /> : saveComplete ? <div className='bg-success'>{t('Global:saveComplete')}</div> : null;

        const venue = venues.find(v => v.id === venueId);
        const kiosks = venue ? venue.registrationKiosks : [];

        const registrationKioskOptions = [{ key: '', name: t('OnlineRegistrationSettingsForm:selectRegistrationKiosk') }].concat(kiosks.filter(k => k.registrationKioskId !== null).map(k => ({ key: k.registrationKioskId || '', name: k.kioskName })));

        return <div className='onlineRegistrationSettingsForm'>
            <h2 className='onlineRegistrationSettingsForm-title'>{t('OnlineRegistrationSettingsForm:title')}</h2>

            <div className='row'>
                <div className='col-md-12'>
                    <form className='data-form' onSubmit={this.savePaymentPageSettings} autoComplete='off'>

                        <h4>{t('OnlineRegistrationSettingsForm:landingPageContent')}</h4>
                        <ct.HtmlInput id='landingPageContent' labelKey='' value={landingPageContent} callback={this.landingPageContentChanged} modules={quillStandardToolbar} quillClassName='at-panel-bg' editorStyle={quillStyle} inlineStyles={true} />

                        <h4>{t('OnlineRegistrationSettingsForm:participantsPageHeading')}</h4>
                        <ct.HtmlInput id='participantsPageHeading' labelKey='' value={participantsPageHeading} callback={this.participantsPageHeadingChanged} modules={quillStandardToolbar} quillClassName='at-panel-bg' editorStyle={quillStyle} inlineStyles={true} />

                        <h4>{t('OnlineRegistrationSettingsForm:completionPageContent')}</h4>
                        <ct.HtmlInput id='completionPageContent' labelKey='' value={completionPageContent} callback={this.completionPageContentChanged} modules={quillStandardToolbar} quillClassName='at-panel-bg' editorStyle={quillStyle} inlineStyles={true} />

                        <p />
                        <ct.Select id='registrationKiosk' labelKey='OnlineRegistrationSettingsForm:registrationKiosk' value={registrationKioskId} callback={this.onRegistrationKioskChanged} options={registrationKioskOptions} />

                        {message}

                        <p />
                        <div className='btn-toolbar'>
                            <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)} disabled={isSaving}>{t('Global:save')}</button>
                            <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)} disabled={isSaving}>{t('Global:cancel')}</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>;
    }
}


const mapStateToProps = (state: ApplicationState) => ({
    isSaving: state.publicWebsiteSettings.isSaving,
    saveComplete: state.publicWebsiteSettings.saveComplete,
    saveError: state.publicWebsiteSettings.saveError,
    validationErrors: state.publicWebsiteSettings.validationErrors,
    venues: state.venues.venues,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    savePublicWebsiteSettings: bindActionCreators(PublicWebsiteSettingsActions.actionCreators.savePublicWebsiteSettings, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(OnlineRegistrationSettingsForm);
