
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { ITranslationContext } from '../../../translations';
import { clickHandler } from '../../../utils/util';
import { TimeFormat } from '../../../store/pages/venues/types';
import { IEvent } from './types';
import Flag from '../../icons/flag';
import ReservationCompetitorCounts from './reservationCompetitorCounts';


interface RegistrationEventHeaderProps {
    event: IEvent;
    expanded: boolean;
    timeFormat: TimeFormat;
    toggleExpandCollapse?: () => void;
}

const RegistrationEventHeader = (props: RegistrationEventHeaderProps, context: ITranslationContext) => {
    const { t } = context;
    const { event, expanded, timeFormat, toggleExpandCollapse } = props;
    const sortedReservations = event.reservations.sort((r1, r2) => r2.startTime.getTime() - r1.startTime.getTime());
    const maxBookingRsv = sortedReservations.length > 0 ? sortedReservations[0] : null;
    const outstandingBal = event.bookings.reduce((ttl, b) => ttl + b.outstandingAmount, 0);
    const toggle = toggleExpandCollapse ? toggleExpandCollapse : () => { }
    return <div key={`${event.id}_header`} onClick={e => clickHandler(e, toggle)} className='reception-event-header'>
        <div className='reception-event-time'>{event.startTime.toShortTimeString(timeFormat)}</div>
        <div className='reception-event-name'>{event.name}</div>
        {maxBookingRsv ? <ReservationCompetitorCounts reservation={maxBookingRsv} /> : <div className='reception-counts-filler'></div>}
        <div className='reception-booking-outstanding-amount'>{outstandingBal > 0 ? `${t('Global:currencySymbol')}${outstandingBal.toFixed(2)}` : ''}</div>
        <div className='reception-actions'>{event.flagged ? <Flag width={20} height={20} colour='#d9534f' /> : null}{toggleExpandCollapse ? <span className='reception-item-panel-status pull-right'>{expanded ? '-' : '+'}</span> : null}</div>
    </div>
}

RegistrationEventHeader.contextTypes = {
    t: PropTypes.func
}

export default RegistrationEventHeader;