import { ValidationError, Time, Days } from '../../global/types';
import * as api from '../../apiClient';
import { ProductQuantityUnit } from '../resources/types';

export interface ActivityFormat {
    id: string;
    venueId: string;
    name: string;
    description: string;
    colour: string;
    sequence: number | null;
    minPlacesForExclusive: number;
    webDescription: string;
    webDescriptionBgColour: string;
    webDescriptionTextColour: string;
    webDescriptionPriceTemplate: string | null;
    imageUrl: string | null;
    registrationTermsId: string;
    bookingTermsId: string;
    bookingExtraFields: CustomField[];
    registrationExtraFields: CustomField[];
    resources: ActivityFormatResource[];
    activityFormatGroupIds: string[]
    variations: ActivityFormatVariation[];
    products: ActivityFormatProduct[];
    availability: ActivityFormatAvailability[];
    customerCategories: ActivityFormatCustomerCategory[];
    additionalProducts: ActivityFormatAdditiomalProduct[];
    linkedActivityFormats: ActivityFormatLink[];
    noAvailabilityMessage: string | null;
    showRemainingSpaces: boolean;
    showAlternativeAvailability: boolean;
    arrivalTimeBeforeEventOverride: Time | null;
    snapOnlineBookingsToOverride: number | null;
    applyAvailabilityRulesToExistingReservations: boolean;
    allowMultipleCategoriesPerCustomer: boolean;
    priceTemplate: string | null;
    membershipTypeId: string | null;
    archived: boolean;
}

export enum CustomFieldType {
    Text = 1,
    Number = 2,
    List = 3,
    Transponder = 101,
    CompetitorNumber = 102
}

export enum CustomFieldApplication {
    None = 0,
    PerRegistration = 1,
    PerCategory = 2
}

export interface CustomField {
    name: string;
    type: CustomFieldType;
    description: string;
    required: boolean;
    values: string[];
    adminOnly: boolean;
    appliesTo: CustomFieldApplication;
}

export interface ActivityFormatResource {
    resourceId: string;
    configurationIds: string[];
}

export interface ActivityFormatVariation {
    id: string;
    minParticipants: number;
    maxParticipants: number;
    name: string;
    timingTemplateName: string | null;
    mustBookItemsTogether: boolean;
    compatibleActivityFormatVariations: ActivityFormatVariationCompatibility[];
    schedule: ActivityFormatVariationSchedule[];
}

export interface ActivityFormatVariationCompatibility {
    activityFormatId: string;
    activityFormatVariationId: string;
}

export interface ActivityFormatVariationSchedule {
    id: string;
    sequence: number;
    runningTime: Time;
    minGapBefore: Time;
    minGapAfter: Time;
    minBreakDuration: Time | null;
    maxBreakDuration: Time | null;
    timingTemplateName: string | null;
}

export interface ActivityFormatProduct {
    id: string;
    productId: string;
    enableForWebBookings: boolean;
    customerCategories: string[];
}

export interface ActivityFormatCustomerCategory {
    customerCategoryId: string;
    placesToBook: number;
    minQuantityPerBooking: number;
    maxQuantityPerBooking: number;
    maxOverallQuantity: number;
}

export interface ActivityFormatAdditiomalProduct {
    id: string;
    productId: string;
    minQuantity: number;
    maxQuantity: number;
    defaultQuantity: number;
    quantityUnit: ProductQuantityUnit;
    sequence: number;
}

export interface ActivityFormatLink {
    key: string;
    linkedActivityFormatId: string;
    offerAsAlternative: boolean;
    offerAsUpsell: boolean;
    minGapBeforeMainActivity: Time | null;
    maxGapBeforeMainActivity: Time | null;
    minGapAfterMainActivity: Time | null;
    maxGapAfterMainActivity: Time | null;
    customerCategoryMap: ActivityFormatLinkCustomerCategoryMap[];
    webDescriptionOverride: string | null;
    maxUpsellTimeSlotsBefore: number;
    maxUpsellTimeSlotsAfter: number;
}

export interface ActivityFormatLinkCustomerCategoryMap {
    id: number;
    originalActivityCustomerCategoryId: string;
    linkedActivityCustomerCategoryId: string;
    upsellProductId: string | null;
}

export interface ActivityFormatAvailability {
    key: string;
    id: string;
    days: Days;
    fromTime: Time;
    toTime: Time;
    minParticipants: number;
    maxParticipants: number;
    minDaysBefore: number;
    maxDaysBefore: number;
    startDate: Date | null;
    endDate: Date | null;
}

export interface ActivityFormatDto {
    id: string;
    venueId: string;
    name: string;
    description: string;
    colour: string;
    sequence: number | null;
    minPlacesForExclusive: number;
    webDescription: string;
    webDescriptionBgColour: string;
    webDescriptionPriceTemplate: string | null;
    webDescriptionTextColour: string;
    bookingExtraFields: CustomField[];
    registrationExtraFields: CustomField[];
    registrationTermsId: string;
    bookingTermsId: string;
    resourceIds: string[];
    variations: ActivityFormatVariationDto[];
    products: ActivityFormatProductDto[];
    availability: ActivityFormatAvailability[];
    customerCategories: ActivityFormatCustomerCategory[];
    linkedActivityFormats: ActivityFormatLink[];
    noAvailabilityMessage: string | null;
    showRemainingSpaces: boolean;
    showAlternativeAvailability: boolean;
    arrivalTimeBeforeEventOverride: string;
    snapOnlineBookingsToOverride: number | null;
    applyAvailabilityRulesToExistingReservations: boolean;
    archived: boolean;
}

export interface ActivityFormatVariationDto {
    id: string;
    MinParticipants: number;
    MaxParticipants: number;
    name: string;
    mustBookItemsTogether: boolean;
    schedule: ActivityFormatVariationScheduleDto[];
}


export interface ActivityFormatVariationScheduleDto {
    id: string;
    sequence: number;
    runningTime: string;
    minGapBefore: string;
    minGapAfter: string;
    minBreakDuration: string | null;
    maxBreakDuration: string | null;
}

export interface ActivityFormatProductDto {
    id: string;
    productId: string;
    numberOfPlacesToBook: number;
    daysOfWeek: Days | null;
    fromTime: Time | null;
    toTime: Time | null;
}

export interface ActivityFormatState {
    isLoading: boolean;
    activityFormats: ActivityFormat[];
    loadError: api.ApiError | null;
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
}


export enum ActivityFormatActionTypes {
    RequestActivityFormats = 'REQUEST_ACTIVITY_FORMATS',
    ReceivedActivityFormats = 'RECEIVE_ACTIVITY_FORMATS',
    SaveActivityFormat = 'SAVE_ACTIVITY_FORMAT',
    ActivityFormatSaved = 'ACTIVITY_FORMAT_SAVED',
    ActivityFormatSaveFailed = 'ACTIVITY_FORMAT_SAVE_FAILED',
    EditActivityFormat = 'EDIT_ACTIVITY_FORMAT'
}

export interface LoadActivityFormats {
    type: ActivityFormatActionTypes.RequestActivityFormats;
}

export interface ReceiveActivityFormats {
    type: ActivityFormatActionTypes.ReceivedActivityFormats;
    activityFormats: ActivityFormat[];
    error: api.ApiError | null;
}

export interface SaveActivityFormat {
    type: ActivityFormatActionTypes.SaveActivityFormat;
    isNew: boolean;
    activityFormatId?: string;
    activityFormat: ActivityFormat;
    img: File | null;
}

export interface ActivityFormatSaved {
    type: ActivityFormatActionTypes.ActivityFormatSaved;
    activityFormatId: string;
}

export interface ActivityFormatSaveFailed {
    type: ActivityFormatActionTypes.ActivityFormatSaveFailed;
    error: api.ApiError;
}

export interface EditActivityFormat {
    type: ActivityFormatActionTypes.EditActivityFormat
}


export const dayFromDate = (date: Date) => {
    switch (date.getDay()) {
        case 0: return Days.Sunday
        case 1: return Days.Monday
        case 2: return Days.Tuesday
        case 3: return Days.Wednesday
        case 4: return Days.Thursday
        case 5: return Days.Friday
        case 6: return Days.Saturday
    }

    return Days.None;
}