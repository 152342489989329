
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';
import * as v from '../../global/validation';

import { generateTempId, range } from '../../../utils/util';

import { clickHandler } from '../../../utils/util';
import { CustomerCategoryCustomFieldValue, Registration, RegistrationCustomFieldValue, RegistrationEventSelection } from '../../../store/pages/reception/types';
import { CustomerCategory } from '../../../store/pages/customerCategories/types';
import { IEvent } from './types';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';
import { CustomFieldType } from '../../../store/pages/activityFormats/types';
import { stringComparer } from '../../../utils/comparers';
import { MarketingPreference } from '../../../store/pages/customer/types';

interface EventSelections {
    eventId: string;
    eventStartTime: Date | null;
    eventName: string;
    selected: ct.FormValue<boolean>;
    resourceName: string;
    allowMultipleCategoriesPerCustomer: boolean;
    activityFormatName: string;
    categories: EventCategorySelection[];
}

interface EventCategorySelection {
    id: string | null;
    key: string;
    customerCategoryId: ct.FormValue<string>;
}

interface EditRegistrationProps {
    registration: Registration;
    bookingId: string;
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
    close: () => void;
    unregister: () => void;
    update: (registrationId: string, bookingId: string, dateOfBirth: Date, nickname: string, publicResultsConsent: boolean, marketingPreference: MarketingPreference, resultsPreference: MarketingPreference, eventSelections: RegistrationEventSelection[], customFields: RegistrationCustomFieldValue[], categoryCustomFields: CustomerCategoryCustomFieldValue[]) => void;
    customerCategories: CustomerCategory[];
}

interface EditRegistrationState {
    error: string | null;
    birthYear: ct.FormValue<number>;
    birthMonth: ct.FormValue<number>;
    birthDay: ct.FormValue<number>;
    checkedIn: ct.FormValue<boolean>;
    nickname: ct.FormValue<string>;
    publicResultsConsent: ct.FormValue<boolean>;
    emailResultsPreference: ct.FormValue<boolean>;
    emailMarketingPreference: ct.FormValue<boolean>;
    eventSelections: EventSelections[],
    customFields: RegistrationCustomFieldValue[];
    categoryCustomFields: CustomerCategoryCustomFieldValue[];
}

class EditRegistration extends React.Component<EditRegistrationProps, EditRegistrationState> {

    constructor(props: EditRegistrationProps) {
        super(props);

        const { registration } = this.props;

        const dobState = registration.dateOfBirth ? this.validateDob(registration.dateOfBirth.getFullYear(), registration.dateOfBirth.getMonth()+1, registration.dateOfBirth.getDate()) : this.validateDob(0,0,0);

        const evtSelections: EventSelections[] = registration.registeredForEvents.map(re => {
            return {
                eventId: re.eventId,
                eventStartTime: re.startTime,
                eventName: re.eventName,
                resourceName: re.resourceName,
                activityFormatName: re.activityFormatName,
                allowMultipleCategoriesPerCustomer: re.allowMultipleCategoriesPerCustomer,
                selected: this.validateSelected(re.eventId, !re.removed),
                categories: re && re.customerCategories.length > 0
                    ? re.customerCategories.map(c => ({ id: c.id, key: c.id, customerCategoryId: this.validateCustomerCategory(re.eventId, c.id, c.customerCategoryId) }))
                    : [generateTempId()].map(k => ({ id: null, key: k, customerCategoryId: this.validateCustomerCategory(re.eventId, k, '') }))
            }
        });
        
        this.state = {
            error: null,
            birthYear: dobState.birthYear,
            birthMonth: dobState.birthMonth,
            birthDay: dobState.birthDay,
            checkedIn: this.validateCheckedId(registration.checkedIn),
            nickname: this.validateNickname(registration.nickname || ''),
            publicResultsConsent: this.validatePublicResultsConsent(registration.publicResultsConsent),
            emailResultsPreference: this.validateEmailResultsPreference((registration.resultsPreference & MarketingPreference.Email) == MarketingPreference.Email),
            emailMarketingPreference: this.validateEmailResultsPreference((registration.marketingPreference & MarketingPreference.Email) == MarketingPreference.Email),
            eventSelections: evtSelections,
            customFields: registration.bookings.filter(x => x.bookingId === props.bookingId).flatMap(x => x.customFields),
            categoryCustomFields: registration.categoryCustomFields
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    updateRegistration = () => {
        if (!v.isValid(this.state)) {
            this.setState({ error: 'Global:formInvalid' });
        } else {
            this.setState({ error: null });

            const { registration, bookingId } = this.props;
            const { birthDay, birthMonth, birthYear, nickname, publicResultsConsent, emailResultsPreference, emailMarketingPreference, eventSelections, customFields, categoryCustomFields } = this.state;

            let dob: Date = new Date();
            try {
                dob = new Date(birthYear.value, birthMonth.value - 1, birthDay.value);
            } catch (e) {
                this.setState({ error: 'EditRegistration:InvalidDob' });
                return;
            }

            if (eventSelections.filter(ev => ev.selected.value).length === 0) {
                this.setState({ error: 'EditRegistration:cannotDelesctAllEvents' });
                return;
            }

            if (eventSelections.filter(ev => !ev.selected.isValid || ev.categories.reduce<boolean>((catInvalid, c) => catInvalid || !c.customerCategoryId.isValid, false)).length > 0) {
                this.setState({ error: 'Global:formNotValid' });
                return;
            }

            const marketingPref = emailMarketingPreference.value ? registration.marketingPreference | MarketingPreference.Email : registration.marketingPreference & ~MarketingPreference.Email;
            const resultsPref = emailResultsPreference.value ? registration.resultsPreference | MarketingPreference.Email : registration.resultsPreference & ~MarketingPreference.Email;

            this.props.update(
                registration.id,
                bookingId,
                dob,
                nickname.value,
                publicResultsConsent.value,
                marketingPref,
                resultsPref,
                eventSelections.map(s => ({
                    eventId: s.eventId,
                    removed: !s.selected.value,
                    customerCategories: s.categories.map(c => ({ id: c.id, customerCategoryId: c.customerCategoryId.value }))
                })),
                customFields,
                categoryCustomFields
            );
        }
    }

    setDob = (birthYear: number, birthMonth: number, birthDay: number) => this.setState(this.validateDob(birthYear, birthMonth, birthDay))

    validateDob = (birthYear: number, birthMonth: number, birthDay: number) => {
        const today = new Date();
        return {
            birthYear: ct.asFormValue('birthYear', birthYear, birthYear >= today.getFullYear() - 100 && birthYear <= today.getFullYear(), true),
            birthMonth: ct.asFormValue('birthMonth', birthMonth, birthMonth >= 1 && birthMonth <= 12, true),
            birthDay: ct.asFormValue('birthDay', birthDay, new Date(birthYear, birthMonth - 1, birthDay).getDate() === birthDay, true)
        }
    }

    validateCustomerCategory = (eventId: string, categoryKey: string, val: string) => v.validate(val, `${eventId}_${categoryKey}_customerCategory`, [v.required], []);
    validateSelected = (eventId: string, val: boolean) => v.validate(val, `${eventId}_selected`, [], []);
    validateCheckedId = (val: boolean) => v.validate(val, 'checkedIn', [], []);
    validateNickname = (val: string) => v.validate(val, 'nickname', [], [])
    validatePublicResultsConsent = (val: boolean) => v.validate(val, 'publicResultsConsent', [], []);
    validateEmailResultsPreference = (val: boolean) => v.validate(val, 'emailResultsPreference', [], []);
    validateEmailMarketingPreference = (val: boolean) => v.validate(val, 'emailMarketingPreference', [], []);

    onEventSelectionChanged = (eventId: string, checked: boolean) => {
        this.setState(s => ({ eventSelections: s.eventSelections.map(e => e.eventId === eventId ? ({ ...e, selected: this.validateSelected(eventId, checked)}) : e) }));
    }

    onEventCategoryChanged = (eventId: string, categoryKey: string, category: string) => {
        this.setState(s => ({
            eventSelections: s.eventSelections.map(e => e.eventId === eventId
                ? ({ ...e, categories: e.categories.map(c => c.key === categoryKey ? { ...c, customerCategoryId: this.validateCustomerCategory(eventId, categoryKey, category) } : c) })
                : e)
        }));
    }

    onCustomFieldChanged = (fieldName: string, value: string) => {
        this.setState(s => ({ customFields: s.customFields.map(f => f.customFieldName === fieldName ? ({ ...f, value: value }) : f) }));
    }

    onCategoryCustomFieldChanged = (categoryId: string, fieldName: string, value: string) => {
        this.setState(s => ({ categoryCustomFields: s.categoryCustomFields.map(f => f.customerCategoryId === categoryId && f.customFieldName === fieldName ? ({ ...f, value: value }) : f) }));
    }

    addCustomerCategory = (eventId: string) => this.setState(s => ({
        eventSelections: s.eventSelections.map(e => e.eventId === eventId
            ? { ...e, categories: e.categories.concat([generateTempId()].map(k => ({ id: null, key: k, customerCategoryId: this.validateCustomerCategory(eventId, k, ''), customFields: [] }))) }
            : e)
    }))

    removeCustomerCategory = (eventId: string, categoryKey: string) => this.setState(s => ({ eventSelections: s.eventSelections.map(e => e.eventId === eventId ? { ...e, categories: e.categories.filter(c => c.key !== categoryKey)} : e) }))

    render() {
        const { t } = this.context;
        const { registration, close, unregister } = this.props;
        const { birthDay, birthMonth, birthYear, nickname, publicResultsConsent, emailMarketingPreference, emailResultsPreference, customFields, categoryCustomFields, error } = this.state;

        const dayOptions = range(1, 31).map(d => ({ key: d.toString(), name: d.toString() }))
        const monthOptions = [t('Global:January'), t('Global:February'), t('Global:March'), t('Global:April'), t('Global:May'), t('Global:June'), t('Global:July'), t('Global:August'), t('Global:September'), t('Global:October'), t('Global:November'), t('Global:December')].map((m, ix) => ({ key: (ix + 1).toString(), name: m }))

        const today = new Date();
        const yearOptions = range(today.getFullYear() - 100, today.getFullYear()).reverse().map(y => ({ key: y.toString(), name: y.toString() }))
        const baseStyle = { display: 'inline-block' }

        return <div>
            <h3>{registration.customerFirstname} {registration.customerLastname}</h3>

            <form className='data-form' autoComplete='off'>
                <div>
                    <label>{t('Global:dateOfBirth')}</label>
                    <div>
                        <ct.Select id='birthDay' labelKey='' style={{ ...baseStyle, width: '90px' }} value={{ ...birthDay, value: birthDay.value.toString() }} callback={val => this.setDob(birthYear.value, birthMonth.value, parseInt(val))} options={dayOptions} />
                        <ct.Select id='birthMonth' labelKey='' style={{ ...baseStyle, width: '130px' }} value={{ ...birthMonth, value: birthMonth.value.toString() }} callback={val => this.setDob(birthYear.value, parseInt(val), birthDay.value)} options={monthOptions} />
                        <ct.Select id='birthYear' labelKey='' style={{ ...baseStyle, width: '100px' }} value={{ ...birthYear, value: birthYear.value.toString() }} callback={val => this.setDob(parseInt(val), birthMonth.value, birthDay.value)} options={yearOptions} />
                    </div>
                </div>

                <div>
                    <ct.TextBox id='nickname' labelKey='Global:nickname' placeholderKey='Global:nicknamePlaceholder' value={nickname} callback={val => this.setState({ nickname: this.validateNickname(val) })} />
                    <ct.Checkbox id='publicResultsConsent' labelKey='Global:publicResultsConsent' value={publicResultsConsent} callback={val => this.setState({ publicResultsConsent: this.validatePublicResultsConsent(val) })} />
                    <ct.Checkbox id='emailResultsPreference' labelKey='EventCustomers:emailResults' value={emailResultsPreference} callback={val => this.setState({ emailResultsPreference: this.validateEmailResultsPreference(val) })} />
                    <ct.Checkbox id='emailMarketingPreference' labelKey='EventCustomers:emailMarketing' value={emailMarketingPreference} callback={val => this.setState({ emailMarketingPreference: this.validateEmailMarketingPreference(val) })} />
                </div>

                {this.renderCustomFields(customFields, categoryCustomFields)}

                {this.renderReservations()}

                {error ? <div className='alert alert-danger'>{t(error)}</div> : null}

                <div className='col-md-12 btn-toolbar'>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.updateRegistration)}>{this.context.t('Global:update')}</button>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, close)}>{this.context.t('Global:cancel')}</button>
                    <button className='btn btn-danger' onClick={e => clickHandler(e, unregister)}>{this.context.t('ReceptionPage:unregister')}</button>
                </div>
            </form>
        </div>
    }

    renderCustomFields = (customFields: RegistrationCustomFieldValue[], categoryCustomFields: CustomerCategoryCustomFieldValue[]) => {
        const { t } = this.context;

        if (customFields.length < 1 && categoryCustomFields.length < 1) return null;

        return <div>
            <label>{t('EditRegistration:customFields')}</label>
            <table className='table table-condensed table-borderless'>
                <tbody>
                    {customFields.map(f =>
                        <tr key={f.customFieldName}>
                            <td className='shrink'><label>{f.customFieldName}</label></td>
                            <td className='expand'>{this.renderField(f.customFieldName, f.type, f.values, f.value, val => this.onCustomFieldChanged(f.customFieldName, val)) }</td>
                        </tr>)}
                    {categoryCustomFields.sort((f1, f2) => stringComparer(f1.customerCategoryName, f2.customerCategoryName)).map(f =>
                        <tr key={f.customFieldName}>
                            <td className='shrink'><label>{f.customerCategoryName} {f.customFieldName}</label></td>
                            <td className='expand'>{this.renderField(f.customFieldName, f.type, f.values, f.value, val => this.onCategoryCustomFieldChanged(f.customerCategoryId, f.customFieldName, val))}</td>
                        </tr>)}
                </tbody>
            </table>
        </div>

    }

    renderField = (name: string, type: CustomFieldType, values: string[], value: string, valueChanged: (newValue: string) => void) => {
        switch (type) {
            case CustomFieldType.Number:
                return <input type='number' value={value} onChange={e => this.onCustomFieldChanged(name, e.currentTarget.value)} />
            case CustomFieldType.List:
                return <ct.PlainSelect id={`${name}`} value={value} options={values.map(v => ({ key: v, name: v }))} callback={v => valueChanged(v)} />
            default:
                return <input type='text' value={value} onChange={e => valueChanged(e.currentTarget.value)} />
        }
    }

    renderReservations = () => {
        const { customerCategories, timeFormat, dateFormat } = this.props;
        const { eventSelections } = this.state;
        const { t } = this.context;

        if (eventSelections.length === 0) {
           return null;
       }

        const customerCategoryOptions = [{ key: '', name: t('Global:selectCategory') }].concat(customerCategories.map(c => ({ key: c.id, name: c.name })))
        const sortedEventSelections = eventSelections.filter(es => es.eventStartTime !== null).sort((es1, es2) => (es1.eventStartTime ? es1.eventStartTime.getTime() : 0) - (es2.eventStartTime ? es2.eventStartTime.getTime() : 0))

        const minDate = sortedEventSelections[0].eventStartTime;
        const maxDate = sortedEventSelections[sortedEventSelections.length - 1].eventStartTime;
        const isMultiDay = minDate && maxDate && !minDate.isSameDay(maxDate);

        return <table className='table table-condensed'>
            <tbody>
                {eventSelections.map(es => {
                    const checked = es.selected;

                    return <tr key={es.eventId}>
                        <td><ct.Checkbox id={`${es.eventId}_selected`} labelKey='' value={checked} callback={v => this.onEventSelectionChanged(es.eventId, v)} /></td>
                        <td><div onClick={e => clickHandler(e, () => this.onEventSelectionChanged(es.eventId, !checked))}>{isMultiDay && es.eventStartTime ? es.eventStartTime.toShortDateString(dateFormat) : null} {es.eventStartTime ? es.eventStartTime.toShortTimeString(timeFormat) : null}</div></td>
                        <td><div onClick={e => clickHandler(e, () => this.onEventSelectionChanged(es.eventId, !checked))}>{es.resourceName}</div></td>
                        <td><div onClick={e => clickHandler(e, () => this.onEventSelectionChanged(es.eventId, !checked))}>{es.activityFormatName}</div></td>
                        <td>
                                {es.categories.map((c,ix) => <div className='flex flex-row' key={`cat_${c.key}`}>
                                    <ct.Select id={`${es.eventId}_customerCategory`} labelKey='' classNames='flex-stretch' value={c.customerCategoryId} callback={val => this.onEventCategoryChanged(es.eventId, c.key, val)} options={customerCategoryOptions} minimal={true} />
                                    {es.categories.length > 1 ? <span onClick={e => clickHandler(e, () => this.removeCustomerCategory(es.eventId, c.key))} className='flex-shrink glyphicon glyphicon-trash red' style={({ cursor: 'pointer', padding: '5px' })}></span> : null}
                                </div>)
                                }
                            {es.allowMultipleCategoriesPerCustomer ? <button className='btn btn-link' onClick={e => clickHandler(e, () => this.addCustomerCategory(es.eventId))}>{t('EditRegistration:addCategory')}</button> : null}
                              </td>
                    </tr>
                })}
            </tbody>
        </table>
    }
}

export default EditRegistration