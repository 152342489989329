
import * as api from '../../apiClient';
import { Tag } from '../tags/types';

export interface Customer {
    id: string;
    customerType: CustomerType;
    firstname: string;
    lastname: string;
    birthDay: number;
    birthMonth: number;
    birthYear: number;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    addressLine4: string;
    town: string;
    county: string;
    countryId: number;
    postalCode: string;
    emailAddress: string;
    phoneNumber: string;
    gender: Gender;
    emergencyContactName: string;
    emergencyContactNumber: string;
    resultsPreference: MarketingPreference;
    marketingPreference: MarketingPreference;
    publicResultsConsent: boolean;
    photographUrl: string;
    notes: string;
    nickname: string | null;
}

export enum MarketingPreference {
    None = 0,
    Email = 1,
    Phone = 2,
    EmailAndPhone = 3
}

export enum CustomerType {
    None = 0,
    Competitor = 1,
    Organiser = 2
}

export enum Gender {
    NotProvided = 0,
    Male = 1,
    Female = 2,
    NonBinary = 3,
    Transgender = 4,
    Intersex = 5,
    PreferNotToSay = 6
}

export interface FindCustomerSummary {
    id: string;
    firstname: string;
    lastname: string;
    companyName: string;
    emailAddress: string;
    phoneNumber: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    addressLine4: string;
    town: string;
    county: string;
    countryId: number;
    postalCode: string;
    birthDay: number;
    birthMonth: number;
    birthYear: number;
    age: number | null;
    marketingPreference: MarketingPreference;
    resultsPreference: MarketingPreference;
    publicResultsConsent: boolean;
    customerType: CustomerType,
    lastBooking: VenueDate[];
    lastVisit: VenueDate[];
    numberOfVisits: number;
    created: Date;
    hasActiveMembership: boolean;
    emergencyContactName: string;
    emergencyContactNumber: string;
    tags: Tag[];
    notes: string;
    nickname: string;
    hasPassword: boolean;
}

export interface VenueDate {
    date: Date;
    venueName: string;
}

export interface CustomerState {
    isLoading: boolean;
    nameFilter: string;
    marketingPreferenceFilter: null | MarketingPreference
    venueSelection: string | null;
    lastVisitFrom: Date | null;
    lastVisitTo: Date | null;
    customerCreatedFrom: Date | null;
    customerCreatedTo: Date | null;
    minVisits: number | null;
    tagId: string | null;
    minAge: number | null;
    maxAge: number | null;
    bookedActivityFormatIds: string[];
    participantActivityFormatIds: string[];
    customers: FindCustomerSummary[];
    customerCount: number;
    loadError: api.ApiError | null;
    pageNumber: number;
    maxPage: number;
    pageSize: number;
}

export enum CustomerActionTypes {
    RequestCustomers = 'REQUEST_CUSTOMERS',
    ReceivedCustomers = 'RECEIVE_CUSTOMERS',
}

export interface RequestCustomers {
    type: CustomerActionTypes.RequestCustomers;
    pageNumber: number;
    pageSize: number;
    nameFilter: string;
    marketingPreferenceFilter: MarketingPreference | null;
    venueSelection: string | null;
    lastVisitFrom: Date | null;
    lastVisitTo: Date | null;
    customerCreatedFrom: Date | null;
    customerCreatedTo: Date | null;
    minVisits: number | null;
    tagId: string | null;
    minAge: number | null;
    maxAge: number | null;
    bookedActivityFormatIds: string[];
    participantActivityFormatIds: string[];
}

export interface ReceiveCustomers {
    type: CustomerActionTypes.ReceivedCustomers;
    customers: FindCustomerSummary[];
    customerCount: number;
    maxPage: number;
    error: api.ApiError | null;
}
