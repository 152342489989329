
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'

import moment from 'moment';

import * as ct from '../../../global/controls';
import * as v from '../../../global/validation';
import * as api from '../../../../store/apiClient';
import { ApplicationState } from '../../../../store';
import * as LeaderboardActions from '../../../../store/pages/leaderboards/actions';
import * as ModalActions from '../../../../store/global/modal/actions';
import { clickHandler, isNullOrEmpty } from '../../../../utils/util';
import ApiError from '../../../global/apiError';
import { Interval, IntervalUnit, ValidationError } from '../../../../store/global/types';
import { Leaderboard, TimingSessionType, TimingSettings } from '../../../../store/pages/leaderboards/types';
import { Tag } from '../../../../store/pages/tags/types';
import { Venue } from '../../../../store/pages/venues/types';
import PublicLinks from '../../../global/publicLinks';

interface MappedReduxState {
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
    timingSettings: TimingSettings;
    loadingTimingSettings: boolean;
    tags: Tag[];
    venues: Venue[];
}

interface LocalProps {
    isNew: boolean;
    venueId: string;
    leaderboard: Leaderboard | null;
}

interface Actions {
    closeModal: () => void;
    saveLeaderboard: (isNew: boolean, leaderboardId: string | null, leaderboard: Leaderboard) => void;
    loadTimingSettings: () => void;
}

type LeaderboardFormProps = MappedReduxState & Actions & LocalProps;

interface LeaderboardFormState {
    name: ct.FormValue<string>;
    sequence: ct.FormValue<number>;
    trackName: ct.FormValue<string>;
    kartTypeFilter: ct.FormValue<string>;
    sessionTypeFilter: ct.FormValue<TimingSessionType | null>;
    fromIntervalSelection: ct.FormValue<string>;
    toIntervalSelection: ct.FormValue<string>;
    fromDate: ct.FormValue<moment.Moment | null>;
    toDate: ct.FormValue<moment.Moment | null>;
    fromInterval: ct.FormValue<Interval>;
    toInterval: ct.FormValue<Interval>;
    maxRows: ct.FormValue<number>;
    isPublic: ct.FormValue<boolean>;
    customerTagId: ct.FormValue<string | null>;
    archived: ct.FormValue<boolean>;
    validationError: string | null;
}

class LeaderboardForm extends React.Component<LeaderboardFormProps, LeaderboardFormState> {

    constructor(props: LeaderboardFormProps) {
        super(props);

        this.state = this.buildStateFromProps(this.props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        if (!this.props.loadingTimingSettings && (this.props.timingSettings.settings.length == 0 || this.props.timingSettings.lastUpdate < new Date(new Date().getTime() - (60000 * 60)))) {
            this.props.loadTimingSettings();
        }
    }

    private buildStateFromProps(props: LeaderboardFormProps): LeaderboardFormState {

        const { isNew, leaderboard } = props;

        return {
            name: this.validateName((isNew || !leaderboard) ? '' : leaderboard.name),
            sequence: this.validateSequence((isNew || !leaderboard) ? 1 : leaderboard.sequence),
            kartTypeFilter: this.validateKartTypeFilter((isNew || !leaderboard) ? '' : leaderboard.kartTypeFilter || ''),
            sessionTypeFilter: this.validateSessionTypeFilter((isNew || !leaderboard) ? null : leaderboard.sessionTypeFilter),
            trackName: this.validateTrackName((isNew || !leaderboard) ? '' : leaderboard.trackName),
            fromIntervalSelection: this.validateFromIntervalSelection((isNew || !leaderboard) ? '' : leaderboard.fromDate ? 'DATE' : 'INTERVAL'),
            toIntervalSelection: this.validateToIntervalSelection((isNew || !leaderboard) ? '' : leaderboard.toDate ? 'DATE' : leaderboard.toInterval ? 'INTERVAL' : ''),
            fromDate: this.validateFromDate((isNew || !leaderboard || !leaderboard.fromDate) ? null : moment(leaderboard.fromDate)),
            toDate: this.validateToDate((isNew || !leaderboard || !leaderboard.toDate) ? null : moment(leaderboard.toDate)),
            fromInterval: this.validateFromInterval((isNew || !leaderboard || !leaderboard.fromInterval) ? { unit: IntervalUnit.Month, value: 1 } : leaderboard.fromInterval),
            toInterval: this.validateToInterval((isNew || !leaderboard || !leaderboard.toInterval) ? { unit: IntervalUnit.Month, value: 1 } : leaderboard.toInterval),
            maxRows: this.validateMaxRows((isNew || !leaderboard) ? 20: leaderboard.maxRows),
            isPublic: this.validateIsPublic((isNew || !leaderboard) ? true : leaderboard.isPublic),
            customerTagId: this.validateCustomerTagId((isNew || !leaderboard) ? null : leaderboard.customerTagId),
            archived: this.validateArchived((isNew || !leaderboard) ? false : leaderboard.archived),
            validationError: null
        };
    }

    componentDidUpdate(prevProps: LeaderboardFormProps) {
        // Only update state is resource has changed
        const { leaderboard: prevLeaderboard, saveComplete: prevSaveComplete } = prevProps;
        const { leaderboard, saveComplete } = this.props;
        if ((!prevLeaderboard && leaderboard) || (prevLeaderboard && !leaderboard) || (prevLeaderboard && leaderboard && prevLeaderboard.id !== leaderboard.id)) {
            this.setState(this.buildStateFromProps(this.props));
        }

        if (saveComplete && !prevSaveComplete) {
            setTimeout(() => { this.close(); }, 750);
        }
    }

    private saveLeaderboard = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }

    private close = () => {
        this.props.closeModal();
    }

    private save = () => {
        if (!v.isValid(this.state)) {
            this.setState({ validationError: null });
            // TODO: Show error message!
        } else {
            const leaderboardId = this.props.isNew || !this.props.leaderboard ? null : this.props.leaderboard.id;
            const { isNew, venueId, saveLeaderboard } = this.props;
            const { name, sequence, kartTypeFilter, sessionTypeFilter, trackName, fromIntervalSelection, toIntervalSelection,
                fromDate, toDate, fromInterval, toInterval, maxRows, isPublic, customerTagId, archived } = this.state;



            saveLeaderboard(isNew,
                leaderboardId,
                {
                    id: leaderboardId || '',
                    venueId: venueId,
                    name: name.value,
                    sequence: sequence.value,
                    kartTypeFilter: isNullOrEmpty(kartTypeFilter.value) ? null : kartTypeFilter.value,
                    sessionTypeFilter: sessionTypeFilter.value,
                    trackName: trackName.value,
                    fromDate: fromIntervalSelection.value === 'DATE' && fromDate.value ? fromDate.value.toDate() : null,
                    toDate: toIntervalSelection.value === 'DATE' && toDate.value ? toDate.value.toDate() : null,
                    fromInterval: fromIntervalSelection.value === 'INTERVAL' ? fromInterval.value : null,
                    toInterval: toIntervalSelection.value === 'INTERVAL' ? toInterval.value : null,
                    maxRows: maxRows.value,
                    isPublic: isPublic.value,
                    customerTagId: customerTagId.value,
                    archived: archived.value
                });

            this.setState({ validationError: null });
        }
    }

    validateName = (val: string) => v.validate(val, 'name', [v.required], this.props.validationErrors);

    validateSequence = (val: number) => v.validate(val, 'sequence', [v.required], this.props.validationErrors);
    validateKartTypeFilter = (val: string) => v.validate(val, 'kartTypeFilter', [], this.props.validationErrors);
    validateSessionTypeFilter = (val: TimingSessionType | null) => v.validate(val, 'sessionTypeFilter', [], this.props.validationErrors);
    validateTrackName = (val: string) => v.validate(val, 'trackName', [v.required], this.props.validationErrors);
    validateFromDate = (val: moment.Moment | null) => v.validate(val, 'fromDate', [], this.props.validationErrors);
    validateFromIntervalSelection = (val: string) => v.validate(val, 'fromIntervalSelection', [v.required], this.props.validationErrors);
    validateToIntervalSelection = (val: string) => v.validate(val, 'toIntervalSelection', [], this.props.validationErrors);
    validateToDate = (val: moment.Moment | null) => v.validate(val, 'toDate', [], this.props.validationErrors);
    validateFromInterval = (val: Interval) => v.validate(val, 'fromInterval', [], this.props.validationErrors);
    validateToInterval = (val: Interval) => v.validate(val, 'toInterval', [], this.props.validationErrors);
    validateMaxRows = (val: number) => v.validate(val, 'maxRows', [v.required], this.props.validationErrors);
    validateIsPublic = (val: boolean) => v.validate(val, 'isPublic', [], this.props.validationErrors);
    validateCustomerTagId = (val: string | null) => v.validate(val, 'customerTagId', [], this.props.validationErrors);
    validateArchived = (val: boolean) => v.validate(val, 'archived', [], this.props.validationErrors);

    onSessionTypeFilterChanged = (val: string) => {
        const intVal = isNullOrEmpty(val) ? null : parseInt(val);
        this.setState(s => ({ sessionTypeFilter: this.validateSessionTypeFilter(intVal) }))
    }

    render() {

        let message: any;
        const { t } = this.context;
        const { name, sequence, kartTypeFilter, sessionTypeFilter, trackName, fromIntervalSelection, toIntervalSelection, fromDate, toDate,
            fromInterval, toInterval, maxRows, isPublic, customerTagId, archived, validationError } = this.state;
        const { saveError, saveComplete, venueId, isNew, timingSettings, leaderboard, tags, venues } = this.props;

        if (saveError) {
            message = <ApiError error={saveError} />;
        } else if (validationError) {
            message = (<div className='alert alert-danger'>{validationError}</div>);
        }
        else if (saveComplete) {
            message = (<div className='bg-success'>{t('Global:saveComplete')}</div>);
        }

        const venueTimingSettings = timingSettings.settings.find(s => s.venueId === venueId);

        const sessionTypeOptions = [{ key: '', name: t('Global:all') }].concat(Object.keys(TimingSessionType).filter(key => key !== 'Unknown' && key !== '0' && typeof TimingSessionType[key as any] === 'number').map(key => ({ key: TimingSessionType[key as any].toString(), name: t(`TimingSessionType:${key}`) })));
        const trackNameOptions = venueTimingSettings ? venueTimingSettings.trackNames.map(t => ({ key: t, name: t })) : [];
        const kartTypeFilterOptions = [{ key: '', name: t('Global:all') }].concat(venueTimingSettings ? venueTimingSettings.kartTypes.map(t => ({ key: t, name: t })) : []);
        const fromIntervalOptions = [{ key: '', name: t('LeaderboardForm:selectIntervalOption') }, { key: 'DATE', name: t('LeaderboardForm:specificDate') }, { key: 'INTERVAL', name: t('LeaderboardForm:interval')}];
        const toIntervalOptions = [{ key: '', name: t('LeaderboardForm:noRestriction') }, { key: 'DATE', name: t('LeaderboardForm:specificDate') }, { key: 'INTERVAL', name: t('LeaderboardForm:interval') }];
        const blank = (text: string) => isNullOrEmpty(text) ? '\u00A0' : text;

        const venue = venues.filter(v => v.id === venueId)[0];

        return <div className='leaderboardForm'>
            <h2 className='leaderboard_title'>{isNew ? t('LeaderboardForm:addLeaderboard') : name.value}</h2>

            {(isNew || !leaderboard || !venue) ? null : <p><label>{t('Global:publicUrl')}</label><PublicLinks publicWebsites={venue.publicWebsites} urlPath={`leaderboard/${leaderboard.id}`} /></p>}

            <form className='data-form' onSubmit={this.saveLeaderboard} autoComplete='off'>
                <ct.TextBox id='name' labelKey='Global:name' placeholderKey='Global:name' value={name} callback={val => this.setState({ name: this.validateName(val) })} />
                <ct.NumberBox id='sequence' labelKey='Global:sequence' value={sequence} callback={val => this.setState({ sequence: this.validateSequence(val || 1) })} min='1' />

                <ct.Select id='trackName' labelKey='LeaderboardForm:trackName' value={trackName} callback={val => this.setState({trackName: this.validateTrackName(val)})} options={trackNameOptions} />

                <ct.Select id='kartTypeFilter' labelKey='LeaderboardForm:kartTypeFilter' value={kartTypeFilter} callback={val => this.setState({ kartTypeFilter: this.validateKartTypeFilter(val) })} options={kartTypeFilterOptions} />

                <ct.Select id='sessionTypeFilter' labelKey='LeaderboardForm:sessionTypeFilter' value={({ ...sessionTypeFilter, value: sessionTypeFilter.value ? sessionTypeFilter.value.toString() : '' })} callback={this.onSessionTypeFilterChanged} options={sessionTypeOptions} />

                <ct.Select id='customerTagId' labelKey='LeaderboardForm:customerTag' options={[{ key: '', name: t('') }].concat(tags.filter(t => !t.archived).map(t => ({ key: t.id, name: t.name, data: t })))} value={{ ...customerTagId, value: customerTagId.value ? customerTagId.value : '' }} callback={t => this.setState({ customerTagId: this.validateCustomerTagId(t) })} renderOption={o => o.data && o.data.colour && o.data.name ? <span key={o.key} className='label tag-label' style={({ backgroundColor: o.data.colour })}>{o.name}</span> : <span key={o.key}>{blank(o.name)}</span> } />

                <div>
                    <label>{t('LeaderboardForm:timesFrom')}</label>
                    <div>
                        <ct.Select id='fromIntervalSelection' labelKey='' value={fromIntervalSelection} callback={val => this.setState({ fromIntervalSelection: this.validateFromIntervalSelection(val) })} options={fromIntervalOptions} />
                        {
                            fromIntervalSelection.value === 'DATE'
                                ? <ct.DatePicker id='fromDate' labelKey='' value={fromDate} callback={val => this.setState({ fromDate: this.validateFromDate(val)}) } />
                                : fromIntervalSelection.value === 'INTERVAL'
                                    ? <ct.TimeInterval id='fromInterval' labelKey='' value={fromInterval} callback={val => this.setState({ fromInterval: this.validateFromInterval(val) })} />
                                    : null
                        }                        
                    </div>
                </div>

                <div>
                    <label>{t('LeaderboardForm:timesTo')}</label>
                    <div>
                        <ct.Select id='toIntervalSelection' labelKey='' value={toIntervalSelection} callback={val => this.setState({ toIntervalSelection: this.validateToIntervalSelection(val) })} options={toIntervalOptions} />
                        {
                            toIntervalSelection.value === 'DATE'
                                ? <ct.DatePicker id='toDate' labelKey='' value={toDate} callback={val => this.setState({ toDate: this.validateToDate(val) })} />
                                : toIntervalSelection.value === 'INTERVAL'
                                    ? <ct.TimeInterval id='toInterval' labelKey='' value={toInterval} callback={val => this.setState({ toInterval: this.validateToInterval(val) })} />
                                    : null
                        }
                    </div>
                </div>

                <ct.NumberBox id='maxRows' labelKey='LeaderboardForm:maxRows' value={maxRows} callback={val => this.setState({ maxRows: this.validateMaxRows(val || 1) })} min='1' max='50' />

                <ct.Checkbox id='isPublic' labelKey='LeaderboardForm:isPublic' value={isPublic} callback={val => this.setState({ isPublic: this.validateIsPublic(val) })} />

                <ct.Checkbox id='archived' labelKey='Global:archive' value={archived} callback={val => this.setState({ archived: this.validateArchived(val) })} />

                {message}

                <p />
                <div className='btn-toolbar'>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)}>{t('Global:save')}</button>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{t('Global:cancel')}</button>
                </div>
            </form>
        </div>;
    }
};


const mapStateToProps = (state: ApplicationState) => ({
    saveComplete: state.leaderboards.saveComplete,
    saveError: state.leaderboards.saveError,
    validationErrors: state.leaderboards.validationErrors,
    timingSettings: state.leaderboards.timingSettings,
    loadingTimingSettings: state.leaderboards.loadingTimingSettings,
    tags: state.tags.tags,
    venues: state.venues.venues
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    saveLeaderboard: bindActionCreators(LeaderboardActions.actionCreators.saveLeaderboard, dispatch),
    loadTimingSettings: bindActionCreators(LeaderboardActions.actionCreators.loadTimingSettings, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch)
});

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(LeaderboardForm);
