
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { ITranslationContext } from '../../../translations';
import { MarketingCampaignState } from '../../../store/pages/campaigns/types';

export interface CampaignStateProps {
    state: MarketingCampaignState
}

const CampaignState = (props: CampaignStateProps, context: ITranslationContext) => {
    const { t } = context;
    const { state } = props;

    switch (state) {
        case MarketingCampaignState.Draft:
            return <label className='label label-default'>{t(`MarketingCampaignState:${MarketingCampaignState[state]}`)}</label>
        case MarketingCampaignState.Active:
            return <label className='label label-success'>{t(`MarketingCampaignState:${MarketingCampaignState[state]}`)}</label>
        case MarketingCampaignState.Paused:
            return <label className='label label-warning'>{t(`MarketingCampaignState:${MarketingCampaignState[state]}`)}</label>
        case MarketingCampaignState.Complete:
            return <label className='label label-info'>{t(`MarketingCampaignState:${MarketingCampaignState[state]}`)}</label>
        default:
            return <div className='alert alert-danger'>{t(`Unknown status ${MarketingCampaignState[state]}`)}</div>
    }
}


CampaignState.contextTypes = {
    t: PropTypes.func
}

export default CampaignState;