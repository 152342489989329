
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as api from '../../store/apiClient';
import { isNullOrEmpty } from '../../utils/util';
import { ITranslationContext } from '../../translations';

interface ApiErrorProps {
    error: api.ApiError | string | null;
    className?: string;
}

const ApiError = (props: ApiErrorProps, context: ITranslationContext) => {
    const { error } = props;
    if (error) {
        let msg = null;

        if (typeof error == 'string') {
            msg = <div>error</div>;
        }
        else {
            const msgText = isNullOrEmpty(error.messageKey) ? error.message : context.t(error.messageKey);
            const msgDetails = error.validationErrors && error.validationErrors.length > 0
                ? <ul>{error.validationErrors.map((ve, ix) => <li key={ix}>{context.t(ve.messageKey)}</li>)}</ul>
                : null;

            msg = <div>{msgText}{msgDetails}</div>
        }

        return <div className={`alert alert-danger ${(props.className || '')}`}>{msg}</div>;
    } else {
        return <div className={(props.className || '')}></div>;
    }
};

ApiError.contextTypes = {
    t: PropTypes.func
}

export default ApiError;