
import * as st from './types'; 


export type StatusActions = st.SetConnected | st.SetDisconnected | st.AddError | st.AddMessage | st.SetLastConnectionAttempt;

export const actionCreators = {
    setConnected: () => ({ type: st.StatusActionTypes.SetConnected }),
    setDisconnected: (message: string) => ({ type: st.StatusActionTypes.SetDisconnected, message: message }),
    addError: (error: st.Message) => ({ type: st.StatusActionTypes.AddError, error: error }),
    addMessage: (message: st.Message) => ({ type: st.StatusActionTypes.AddMessage, message: message }),
    setLastConnectionAttempt: (time: Date) => ({ type: st.StatusActionTypes.SetLastConnectionAttempt, time: time }),
}
