
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as api from '../../../store/apiClient';
import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import {getKioskId} from '../../../utils/auth';
import ApiError from '../../global/apiError';

interface RegistrationLoginProps {
    login: (code: string, password: string) => void;
    loginError: api.ApiError | null;
}

interface RegistrationLoginState {
    code: ct.FormValue<string>;
    password: ct.FormValue<string>;
}


class RegistrationLogin extends React.Component<RegistrationLoginProps, RegistrationLoginState> {

    constructor(props: RegistrationLoginProps) {
        super(props);

        this.state = { code: this.validateCode(''), password: this.validatePassword('') };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        const kioskId = getKioskId();
        this.setState({ code: this.validateCode(kioskId) });
    }

    login = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {

            if (this.state.code.value.trim() !== '' && this.state.password.value.trim() !== '') {
                this.props.login(this.state.code.value, this.state.password.value);
            }
        }
    }

    validateCode = (val: string) => v.validate(val.toUpperCase(), 'code', [v.required], []);
    validatePassword = (val: string) => v.validate(val, 'pwd', [v.required], []);

    render() {

        const { loginError } = this.props;
        const { code, password } = this.state;
        const { t } = this.context;

        const errMsg = this.props.loginError ? <ApiError error={loginError} /> : null;

        return (
            <div className='registration-login-wrapper'>
                <h2>Login</h2>

                <form className='data-form' onSubmit={this.login}>
                    <ct.TextBox id='code' labelKey='RegistrationLogin:code' placeholderKey='RegistrationLogin:code' value={code} callback={val => this.setState({ code: this.validateCode(val) })} />

                    <ct.Password id='pwd' labelKey='Global:password' placeholderKey='Global:password' value={password} callback={val => this.setState({ password: this.validatePassword(val) })} />

                    {errMsg}

                    <button type='submit' className='btn btn-primary'>{t('Global:login')}</button>
                </form>
            </div>
        );
    }
}

export default RegistrationLogin;