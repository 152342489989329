
import { Action } from 'redux';

import { ResourceActionTypes, ResourceState } from './types';
import { ResourceActions } from './actions'
import { createReducer } from '../../reducer';


const unloadedState: ResourceState = { isLoading: false, resources: [], loadError: null, isSaving: false, saveComplete: false, saveError: null, validationErrors: [] };

export const reducer = (state: ResourceState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: ResourceState, incomingAction: Action): ResourceState => {

    const action = incomingAction as ResourceActions;
    switch (action.type) {
        case ResourceActionTypes.RequestResources:
            return {
                ...state,
                isLoading: true,
                resources: state.resources,
                loadError: null
            };
        case ResourceActionTypes.ReceivedResources:
            const ordered = action.resources ? action.resources : [];
            ordered.sort((a, b) => a.name.localeCompare(b.name)).map(r => ({...r, additionalProducts: r.additionalProducts.sort((p1,p2) => p1.sequence - p2.sequence)}));

            return {
                ...state,
                isLoading: false,
                resources: ordered,
                loadError: action.error
            };
        case ResourceActionTypes.EditResource:
            return {
                ...state,
                isSaving: false,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case ResourceActionTypes.SaveResource:
            return {
                ...state,
                isSaving: true,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case ResourceActionTypes.ResourceSaved:
            return {
                ...state,
                isSaving: false,
                saveComplete: true,
                saveError: null,
                validationErrors: []
            };
        case ResourceActionTypes.ResourceSaveFailed:
            return {
                ...state,
                isSaving: false,
                saveError: action.error,
                saveComplete: false,
                validationErrors: action.error && action.error.validationErrors ? action.error.validationErrors : []
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

