
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as api from '../../../store/apiClient';

import { clickHandler, mapUtcDate, parseLocalDateTime } from '../../../utils/util';
import CustomerCreditRow from './customerCreditRow';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';

export interface AccountCredit {
    id: string;
    createDateTime: Date;
    creditAmount: number | null;
    debitAmount: number | null;
    balance: number;
    expiry: Date | null;
    void: boolean;
}

interface GetCreditHistoryResult {
    creditHistory: AccountCredit[];
}

interface CustomersDetailsPageProps {
    customerId: string;
    customerFirstName: string;
    customerLastName: string;
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
    close: () => void;
    logout: () => void;
}

interface CustomersDetailsPageState {
    loading: boolean;
    creditDetails: AccountCredit[];
    loadError: api.ApiError | null;
}

class CustomerCreditDetails extends React.Component<CustomersDetailsPageProps, CustomersDetailsPageState> {
    constructor(props: CustomersDetailsPageProps) {
        super(props);

        this.state = { loading: true, creditDetails: [], loadError: null };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    expireCredit = (credit: AccountCredit) => {
        const { customerId, logout } = this.props;
        api.putWithAuth(`api/v1/customer/${customerId}/accountCredit/${credit.id}/expire`, {}, logout)
            .subscribe(resp => this.loadCreditHistory(), err => this.setState({ loadError: err }));
    }

    componentDidMount() {
        this.loadCreditHistory();
    }

    loadCreditHistory = () => {
        const { customerId, logout } = this.props;
        api.getWithAuth<GetCreditHistoryResult>(`api/v1/customer/${customerId}/accountCreditHistory`, logout)
            .subscribe(resp => this.setState({
                loading: false,
                creditDetails: resp.creditHistory.map(h => ({ ...h, createDateTime: parseLocalDateTime(h.createDateTime), expiry: mapUtcDate(h.expiry) })),
                loadError: null
            }), err => this.setState({ loadError: err }));
    }

    render() {
        const { close, customerFirstName, customerLastName, timeFormat, dateFormat } = this.props;
        const { creditDetails } = this.state;
        const { t } = this.context;
        return <div>
            <h2>{t('customerCreditDetails:title')}</h2>
            <div>{customerFirstName} {customerLastName}</div>

            <div className='row row-m-t'>
                <div className='col-xs-12'>
                    <table className='table table-condensed'>
                        <thead>
                            <tr>
                                <th>{t('Global:date')}</th>
                                <th>{t('Global:credit')}</th>
                                <th>{t('Global:debit')}</th>
                                <th>{t('customerCreditDetails:balance')}</th>
                                <th>{t('customerCreditDetails:expiry')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {creditDetails.sort((h1, h2) => h2.createDateTime.getTime() - h1.createDateTime.getTime()).map(h => <CustomerCreditRow key={h.id} history={h} expire={this.expireCredit} timeFormat={timeFormat} dateFormat={dateFormat} />)}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className='row row-m-t'>
                <div className='col-xs-12'>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, close)}>{t('Global:close')}</button>
                </div>
            </div>
        </div>
    }
}

export default CustomerCreditDetails;