
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'
import { ApplicationState } from '../../../../store';
import { ProductCategory, ProductCategoryState } from '../../../../store/pages/productCategories/types';
import * as ct from '../../../global/controls';
import * as v from '../../../global/validation';
import * as ProductCategoryActions from '../../../../store/pages/productCategories/actions';
import * as ModalActions from '../../../../store/global/modal/actions';
import { colours } from '../../../../store/global/types';
import { clickHandler } from '../../../../utils/util';
import ApiError from '../../../global/apiError';

interface LocalProps {
    isNew: boolean;
    venueId: string;
    productCategory: ProductCategory | null;
}

type Actions = typeof ProductCategoryActions.actionCreators & typeof ModalActions.actionCreators

type ProductCategoryFormProps = ProductCategoryState
    & Actions
    & LocalProps;

interface ProductCategoryFormState {
    name: ct.FormValue<string>;
    colour: ct.FormValue<string>;
    sequence: ct.FormValue<number>;
    archived: ct.FormValue<boolean>;
    showOnPointOfSale: ct.FormValue<boolean>;
    //showOnWebShop: ct.FormValue<boolean>;
    reportingPriority: ct.FormValue<number | null>;
}

class ProductCategoryForm extends React.Component<ProductCategoryFormProps, ProductCategoryFormState> {

    constructor(props: ProductCategoryFormProps) {
        super(props);

        this.state = this.buildStateFromProps(this.props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps(props: ProductCategoryFormProps): ProductCategoryFormState {

        const {isNew, productCategory} = props;

        return {
            name: this.validateName((isNew || !productCategory) ? '' : productCategory.name),
            colour: this.validateColour((isNew || !productCategory) ? '' : productCategory.colour || ''),
            sequence: this.validateSequence((isNew || !productCategory) ? 0 : productCategory.sequence),
            archived: ct.asFormValue('archived', isNew || !productCategory || !productCategory.archived ? false : true),
            showOnPointOfSale: ct.asFormValue('showOnPointOfSale', isNew || !productCategory || !productCategory.showOnPointOfSale ? false : true),
            //showOnWebShop: ct.asFormValue('showOnWebShop', isNew || !productCategory || !productCategory.showOnWebShop ? false : false),
            reportingPriority: this.validateReportingPriority((isNew || !productCategory) ? null : productCategory.reportingPriority),
        };
    }

    componentDidUpdate(prevProps: ProductCategoryFormProps) {
        const { productCategory: prevProductCategory, saveComplete: prevSaveComplete} = prevProps;
        const { productCategory, saveComplete  } = this.props;

        // Only update state is tax rate has changed
        if ((!prevProductCategory && productCategory) || (prevProductCategory && !productCategory) || (prevProductCategory && productCategory && prevProductCategory.id !== productCategory.id)) {
            this.setState(this.buildStateFromProps(this.props));
        }

        if (saveComplete && !prevSaveComplete) {
            setTimeout(() => { this.close(); }, 750);
        }
    }

    saveProductCategory = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }
    
    close = () => {
        this.props.closeModal();
    }

    save = () => {
        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {
            const { isNew, productCategory, venueId } = this.props;
            const { name, colour, sequence, archived, showOnPointOfSale, reportingPriority } = this.state;

            const productCategoryId = isNew || !productCategory ? null : productCategory.id;
            this.props.saveProductCategory(isNew,
                productCategoryId,
                venueId,
                name.value,
                colour.value,
                sequence.value,
                archived.value,
                showOnPointOfSale.value,
                false,
                reportingPriority.value
            );
        }
    }

    validateName = (val: string) => v.validate(val, 'name', [v.required], this.props.validationErrors);
    validateColour = (val: string) => v.validate(val, 'colour', [v.required], this.props.validationErrors);
    validateSequence = (val: number) => v.validate(val, 'sequence', [v.numeric], this.props.validationErrors);
    validateReportingPriority = (val: number | null) => v.validate(val, 'reportingPriority', [v.numeric], this.props.validationErrors);

    render() {

        const { t } = this.context;
        const { isNew, saveError, saveComplete } = this.props;
        const { name, colour, sequence, archived, showOnPointOfSale, reportingPriority } = this.state;

        let message: any;

        if (saveError) {
            message = <ApiError error={saveError} />;
        } else if (saveComplete) {
            message = (<div className='bg-success'>{t('Global:saveComplete')}</div>);
        }

        return <div className='productCategoryPage'>
            <h1 className='productCategory_title'>{isNew ? t('ProductCategoryForm:addProductCategory') : t('ProductCategoryForm:editProductCategory')}</h1>

            <form className='data-form' onSubmit={this.saveProductCategory} autoComplete='off'>
                <ct.TextBox id='name' labelKey='Global:name' placeholderKey='Global:name' value={name} callback={val => this.setState({ name: this.validateName(val) })} />

                <ct.Colours colours={colours} selectedColour={colour.value} colourChanged={c => this.setState({ colour: this.validateColour(c) })} />

                <ct.NumberBox id='sequence' labelKey='ProductCategoryForm:sequence' placeholderKey='ProductCategoryForm:sequence' value={sequence} callback={val => this.setState({ sequence: this.validateSequence(val || 0) })} min='0' />

                <ct.Checkbox id='showOnPointOfSale' labelKey='ProductCategoryForm:showOnPointOfSale' value={showOnPointOfSale} callback={val => this.setState({ showOnPointOfSale: ct.asFormValue('showOnPointOfSale', val) })} />

                {/*<ct.Checkbox id='showOnWebShop' labelKey='ProductCategoryForm:showOnWebShop' value={showOnWebShop} callback={val => this.setState({ showOnWebShop: ct.asFormValue('showOnWebShop', val) })} />*/}

                <ct.NumberBox id='reportingPriority' labelKey='ProductCategoryForm:reportingPriority' placeholderKey='ProductCategoryForm:reportingPriority' hintKey='ProductCategoryForm:reportingPriorityHint' value={reportingPriority} callback={val => this.setState({ reportingPriority: this.validateReportingPriority(val) })} min='1' />

                <ct.Checkbox id='archived' labelKey='Global:archive' value={archived} callback={val => this.setState({ archived: ct.asFormValue('archived', val) })} />

                {message}

                <p />
                <div className='btn-toolbar'>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)}>{t('Global:save')}</button> 
                    <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{t('Global:cancel')}</button> 
                </div>
            </form>
        </div>;
    }
};

const mapStateToProps = (state: ApplicationState) => state.productCategories;
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(Object.assign({}, ProductCategoryActions.actionCreators, ModalActions.actionCreators), dispatch);

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,           // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(ProductCategoryForm);
