
import * as React from 'react';
import * as PropTypes from 'prop-types'

import {IntegrationType, Venue} from '../../../store/pages/venues/types';
import { clickHandler, isNullOrEmpty } from '../../../utils/util';

interface LocalProps {
    venue: Venue;
    enableIntegration: (venueId: string, integrationId: string) => void;
    revokeIntegrationToken: (venueId: string, integrationTokenId: string) => void;
}

interface IntegrationPanelState {
    confirmRevokeToken: boolean;
    integrationTokenId: string | null;
}

class IntegrationsPanel extends React.Component<LocalProps, IntegrationPanelState> {

    constructor(props: LocalProps) {
        super(props);

        this.state = { confirmRevokeToken: false, integrationTokenId: null };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    enableIntergation = (integrationId: string) => this.props.enableIntegration(this.props.venue.id, integrationId);

    revokeToken = (integrationTokenId: string) => this.setState({ confirmRevokeToken: true, integrationTokenId: integrationTokenId })

    confirmRevokeToken = () => {
        const { venue, revokeIntegrationToken } = this.props;
        const { integrationTokenId } = this.state;

        if (integrationTokenId) revokeIntegrationToken(venue.id, integrationTokenId);
        this.clearRevokeToken();
    }

    clearRevokeToken = () => this.setState({ confirmRevokeToken: false, integrationTokenId: null })

    render() {
        const { confirmRevokeToken } = this.state;

        return (
            <section className='venue-integrations-panel' style={{ position: 'relative', minHeight: '200px' }}>
                <div className='at-panel'>
                    {this.renderTable()}
                    {confirmRevokeToken ? this.renderConfirmRevokeToken() : null }
                </div>
            </section>);
    }

    renderConfirmRevokeToken = () => {
        const { t } = this.context;
        return <div className='overlay'>
            <div className='overlay-content'>
                <div className='flex flex-center flex-col'>
                    <div>{t('VenueIntegrationsPanel:confirmRevokeTokenMessage')}</div>
                    <div className='btn-toolbar' style={{marginTop: '10px'}}>
                        <button className='btn btn-danger' onClick={e => clickHandler(e, this.confirmRevokeToken)}>{t('VenueIntegrationsPanel:confirmRevokeButtonText')}</button>
                        <button className='btn btn-basic' onClick={e => clickHandler(e, this.clearRevokeToken)}>{t('Global:cancel')}</button>
                    </div>
                </div>
            </div>
        </div>
    }

    renderTable = () => {
        const cellStyle = { verticalAlign: 'middle' }

        return <table className='table table-condensed'>
            <tbody>
                {this.props.venue.integrationTokens.map(t =>
                    <tr key={t.id ? t.id : t.integrationType.toString()}>
                        <td className='shrink' style={cellStyle}><label style={{ margin: '0' }}>{this.context.t(`IntegrationType:${IntegrationType[t.integrationType]}`)}</label></td>
                        <td className='expand' style={cellStyle}>{
                            isNullOrEmpty(t.token)
                                ? <button className='btn btn-link' onClick={e => clickHandler(e, () => this.enableIntergation(t.integrationId))}>{this.context.t('VenueIntegrationsPanel:addIntegration')}</button>
                                : <><span>{t.token}</span><button className='btn btn-link' onClick={e => clickHandler(e, () => this.revokeToken(t.id))}>{this.context.t('VenueIntegrationsPanel:revokeToken')}</button></>
                        }</td>
                    </tr>
                )}
            </tbody>
        </table>
    }
}

// Wire up the React component to the Redux store
export default IntegrationsPanel;
