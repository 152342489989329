
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as pt from './types'; 
import * as vt from '../vouchers/types';
import * as mt from '../memberships/types';
import { IStore } from '../..';
import { parseLocalDateTime } from '../../../utils/util';
import { Time } from '../../global/types';


export type ProductActions = pt.LoadProducts | pt.ReceiveProducts | pt.SaveProduct | pt.ProductSaved | pt.ProductSaveFailed | pt.EditProduct;

export const actionCreators = {
    loadProducts: () => ({ type: pt.ProductActionTypes.RequestProducts }),
    loadProductsComplete: (products: pt.Product[], err: api.ApiError | null) => ({ type: pt.ProductActionTypes.ReceivedProducts, products: products, error: err }),
    editProduct: () => ({ type: pt.ProductActionTypes.EditProduct }),
    saveProduct: (isNew: boolean, productId: string | null, product: pt.Product, productImg: File | null) => ({ type: pt.ProductActionTypes.SaveProduct, isNew: isNew, productId: productId, product: product, productImg: productImg }),
    productSaved: (productId: string) => ({ type: pt.ProductActionTypes.ProductSaved, productId: productId }),
    productSaveFailed: (error: api.ApiError) => ({ type: pt.ProductActionTypes.ProductSaveFailed, error: error }),
}

interface IGetProductsResponse {
    products: pt.Product[];
}

interface ISaveProductResponse {
    productId: string;
}

export const handleClientChange = (store: IStore) => [actionCreators.loadProducts]

//https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-after-catching-and-processing-an-error-with-emitting-somethi

const loadProducts = () => Observable.defer(() => api.getJson<IGetProductsResponse>(`api/v1/product/`))
    .map(response => actionCreators.loadProductsComplete(response.products.map(p => ({
        ...p,
        pricing: p.pricing.map(pr => ({
            ...pr,
            effectiveFrom: parseLocalDateTime(pr.effectiveFrom).datePart(),
            effectiveTo: pr.effectiveTo ? parseLocalDateTime(pr.effectiveTo).datePart() : pr.effectiveTo,
            applyPriceFrom: pr.applyPriceFrom ? Time.parse(pr.applyPriceFrom) : pr.applyPriceFrom,
            applyPriceTo: pr.applyPriceTo ? Time.parse(pr.applyPriceTo) : pr.applyPriceTo,
        }))
    })), null));

export const loadProductsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        pt.ProductActionTypes.RequestProducts,
        action => loadProducts(),
        err => actionCreators.loadProductsComplete([], err));

export const reloadProductsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        pt.ProductActionTypes.ProductSaved,
        action => loadProducts(),
        err => actionCreators.loadProductsComplete([], err));

export const reloadVoucherProductsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        vt.VoucherActionTypes.VoucherProductSaved,
        action => loadProducts(),
        err => actionCreators.loadProductsComplete([], err));

export const reloadMembershipTypeProductsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        mt.MembershipActionTypes.MembershipTypeSaved,
        action => loadProducts(),
        err => actionCreators.loadProductsComplete([], err));

export const saveProductEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        pt.ProductActionTypes.SaveProduct,
        action => {
            const spa = action as pt.SaveProduct;
            let productId = spa.productId;
            var { id, taxRate, tags, ...body } = spa.product;

            const productImg = spa.productImg;

            const imageObservables: Observable<string>[] = [productImg ? api.uploadFile(productImg, 'api/v1/clientimage').map(res => res.response.imageId) : Observable.of(null)];

            const images = Observable.forkJoin(imageObservables);
            return images.flatMap(imgIds => {
                var bodyWithImg = {
                    ...body,
                    productImageId: imgIds[0],
                    tags: tags.map(t => ({ tagId: t.tagId })),
                    pricing: body.pricing.map(pr => ({
                        ...pr,
                        effectiveFrom: pr.effectiveFrom.toApiDateOnlyString(),
                        effectiveTo: pr.effectiveTo ? pr.effectiveTo.toApiDateOnlyString() : null,
                        applyPriceFrom: pr.applyPriceFrom ? pr.applyPriceFrom.toString() : null,
                        applyPriceTo: pr.applyPriceTo ? pr.applyPriceTo.toString() : null
                    }))
                };

                return (spa.isNew ? api.post('api/v1/product/', bodyWithImg) : api.put(`api/v1/product/${productId}`, bodyWithImg))
                    .map(response => {
                        if (spa.isNew) {
                            const strr = response.response as ISaveProductResponse;
                            if (strr) {
                                productId = strr.productId;
                            }
                        }

                        return ({ type: pt.ProductActionTypes.ProductSaved, productId: productId });
                    });
            })
        },
        (err: api.ApiError) => actionCreators.productSaveFailed(err));
