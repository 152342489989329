
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'

import { ApplicationState } from '../../../store';
import * as ct from '../../global/controls';

import * as api from '../../../store/apiClient';
import * as TagActions from '../../../store/pages/tags/actions';
import * as ModalActions from '../../../store/global/modal/actions';
import TagPage from './tagPage';
import Loading from '../../global/loading';
import { clickHandler } from '../../../utils/util';
import ApiError from '../../global/apiError';
import { TagInfo } from '../../../store/pages/tags/types';


interface LocalProps {
    venueId: string;
}

interface LocalState {
    isLoading: boolean;
    tags: TagInfo[];
    loadError: api.ApiError | null;
}

interface Actions {
    loadTags: () => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
}

type TagsPageProps = LocalState & Actions & LocalProps;

interface TagsPageState {
    showArchived: ct.FormValue<boolean>;
}

class TagsPage extends React.Component<TagsPageProps, TagsPageState> {

    constructor(props: TagsPageProps) {
        super(props);

        this.state = { showArchived: ct.asFormValue('showArchived', false) }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private viewTag = (tag: TagInfo) => {
        this.showOverlay(tag);
    }

    private showOverlay = (tag: TagInfo) => {
        this.props.showModal(<TagPage tag={tag} close={this.props.closeModal} />, 'TagPage');
    }

    render() {

        const { isLoading, loadError } = this.props;
        const { showArchived } = this.state;
        const { t } = this.context;

        const body = isLoading ? <Loading /> : this.renderTagsTable(showArchived.value);

        return <section className='tagsPage'>
            <header className='section-header'>
                <div className='page-heading'>
                    <h1 className='tags_title'>{t('TagsPage:title')}</h1>
                </div>
                <div className='section-actions right'>
                    <ct.Checkbox id='showArchived' labelKey='Global:showArchived' value={showArchived} callback={val => this.setState({ showArchived: ct.asFormValue('showArchived', val) })} style={({ display: 'inline-block' })} />
                </div>
            </header>
            {body}
            <ApiError error={loadError} />
        </section>;
    }

    private renderTagsTable(showArchived: boolean) {
        const { tags } = this.props;
        const { t } = this.context;

        const tagRows = tags.filter(t => showArchived || !t.archived).map(tag =>
            <tr key={tag.id}>
                <td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.viewTag(tag))}><span className='label tag-label' style={({ backgroundColor: tag.colour, fontSize: '12px' })}>{tag.name}</span></button></td>
                <td className='text-center'>{tag.customerCount > 0 ? tag.customerCount : ''}</td>
                <td className='text-center'>{tag.productCount > 0 ? tag.productCount : ''}</td>
                <td>{tag.archived ? <span className='label label-danger'>{t('Global:archived')}</span> : null}</td>
            </tr>
        );

        return <table className='table table-condensed'>
            <thead>
                <tr>
                    <th></th>
                    <th className='text-center'>{t('TagsPage:customersHeading')}</th>
                    <th className='text-center'>{t('TagsPage:productsHeading')}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {tagRows}
            </tbody>
        </table>;
    }
};


const matStateToProps = (state: ApplicationState) => ({
    tags: state.tags.tags,
    isLoading: state.tags.isLoading,
})

const mapDispatchToProps = (dispatch: Dispatch) => (
    {
        showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
        closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
        loadTags: bindActionCreators(TagActions.actionCreators.loadTags, dispatch),
    }
);


// Wire up the React component to the Redux store
export default connect(
    matStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps        // Selects which action creators are merged into the component's props
)(TagsPage);

