
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as tzt from './types'; 


export type TimeZoneActions = tzt.RequestTimeZones | tzt.ReceiveTimeZones | tzt.RequestTimeZonesError;

export const actionCreators = {
    loadTimeZones: () => ({ type: tzt.TimeZoneActionTypes.RequestTimeZones}),
    loadTimeZonesError: (err: api.ApiError) => ({ type: tzt.TimeZoneActionTypes.RequestTimeZonesError, error: err }),
}

interface IGetTimeZonesResponse {
    timeZones: tzt.TimeZone[];
}

//https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-after-catching-and-processing-an-error-with-emitting-somethi

const loadTimeZones = () => Observable.defer(() => api.getJson<IGetTimeZonesResponse>('api/v1/localization/timeZones'))
    .map(response => ({ type: tzt.TimeZoneActionTypes.ReceivedTimeZones, timeZones: response.timeZones }));

export const loadTimeZonesEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        tzt.TimeZoneActionTypes.RequestTimeZones,
        action => loadTimeZones(),
        err => actionCreators.loadTimeZonesError(err));
