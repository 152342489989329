
import * as React from 'react';
import * as PropTypes from 'prop-types'
import * as ct from '../../global/controls';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import * as LoginActions from '../../../store/pages/login/actions';
import * as api from '../../../store/apiClient';

import { AuditTrailEntry, BookingNote } from '../../../store/pages/diary/types';
import { isNullOrEmpty, clickHandler, parseUtcDate } from '../../../utils/util';
import { ActivityFormat } from '../../../store/pages/activityFormats/types';
import { Resource } from '../../../store/pages/resources/types';
import Loading from '../../global/loading';
import ApiError from '../../global/apiError';
import AuditLogEntry from '../../global/auditLogEntry';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';
import { ApplicationState } from '../../../store';

export interface ReservationInfo {
    key: string;
    notes: string;
    activityFormatId: string;
    resourceId: string;
    resourceName: string;
    startTime: Date;
}

interface IGetBookingNotesResponse {
    bookingId: string;
    notes: BookingNote[];
    auditLog: AuditTrailEntry[];
}

interface Actions {
    logout: () => void;
}

interface LocalProps {
    bookingId: string;
    reservations: ReservationInfo[];
    activityFormats: ActivityFormat[];
    resources: Resource[];
}

interface MappedReduxState {
    dateFormat: DateFormat;
    timeFormat: TimeFormat;
}


type BookingNotesProps = MappedReduxState & Actions & LocalProps;

interface BookingNotesState {
    notes: BookingNote[];
    auditLog: AuditTrailEntry[];
    isLoading: boolean;
    isSaving: boolean;
    saveError: api.ApiError | null;
    note: ct.FormValue<string>;
    noteId: string | null;
}

class BookingNotes extends React.Component<BookingNotesProps, BookingNotesState> {

    static contextTypes = {
        t: PropTypes.func
    }

    constructor(props: BookingNotesProps) {
        super(props);

        this.state = { isLoading: false, notes: [], auditLog: [], note: ct.asFormValue('note', ''), noteId: null, isSaving: false, saveError: null }
    }

    componentDidMount() {
        this.loadNotes();
    }

    componentDidUpdate(prevProps: BookingNotesProps) {
        if (this.props.bookingId !== prevProps.bookingId) {
            this.loadNotes();
        }
    }

    loadNotes = () => {
        const { bookingId, logout } = this.props;

        if (isNullOrEmpty(bookingId)) return;

        this.setState({ isLoading: true });
        api.getWithAuth<IGetBookingNotesResponse>(`api/v1/booking/${bookingId}/notes`, logout)
            .subscribe(res => this.setState({
                isLoading: false,
                notes: res.notes.map(n => ({ ...n, createDateTime: parseUtcDate(n.createDateTime) })),
                auditLog: res.auditLog.map(a => ({ ...a, time: parseUtcDate(a.time) }))
            }))
    }

    clearNote = () => this.setState({ note: ct.asFormValue('note', ''), noteId: null })

    saveNote = () => {
        const { bookingId } = this.props;
        const { note, noteId } = this.state;

        if (isNullOrEmpty(note.value))
            return;

        this.setState({ isSaving: true, saveError: null });
        api.postWithAuth(`api/v1/booking/${bookingId}/notes`, { noteId: noteId, noteText: note.value }, this.props.logout)
            .subscribe(_ => this.setState({ isSaving: false, saveError: null, note: ct.asFormValue('note', ''), noteId: null}, this.loadNotes), e => this.setState({isSaving: false, saveError: e}));
    }

    editNote = (note: BookingNote) => {
        this.setState({ note: ct.asFormValue('note', note.text), noteId: note.id })
    }

    render() {
        const { activityFormats, resources, reservations, timeFormat, dateFormat } = this.props;
        const { note, noteId, notes, auditLog, isLoading, isSaving, saveError } = this.state;
        const { t } = this.context;

        const activityNotes = reservations.filter(r => !isNullOrEmpty(r.notes)).map(r => {
            const selectedActivityFormat = activityFormats.find(af => af.id === r.activityFormatId);
            const activityFormatName = selectedActivityFormat ? selectedActivityFormat.name : '';
            const resource = resources.find(res => res.id === r.resourceId);
            const style = resource ? { color: resource.colour } : {};
            return (
                <li key={`${r.key}_notes`} className='list-group-item'>
                    <div className='text-center sub-heading' style={style}>{r.startTime.toShortTimeString(timeFormat)} {r.resourceName}</div>
                    <div className='text-center' style={({ borderBottom: 'solid 1px #ccc' })}>{activityFormatName}</div>
                    <div>{r.notes}</div>
                </li>
            )
        });

        if (isLoading) {
            return <Loading />
        } else if (saveError) {
            return <ApiError error={saveError} />
        }

        return (
            <div>
                <div className='section-heading'>{t('BookingNotes:internalNotes')}</div>
                <div className='row'>
                    <div className='col-xs-12'>
                        <ct.TextArea id='notes' labelKey='' rows={4} value={note} noMaxWidth={true} callback={n => this.setState({ note: ct.asFormValue('note', n) })} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xs-12 mv-10'>
                        <button className='btn btn-primary pull-right' onClick={e => clickHandler(e, this.saveNote)} disabled={isSaving || isNullOrEmpty(note.value)}>{t('Global:save')}</button>
                        <button className='btn btn-default pull-right' onClick={e => clickHandler(e, this.clearNote)} disabled={isSaving || isNullOrEmpty(note.value)} style={{marginRight: '8px'} }>{t('Global:clear')}</button>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xs-12'>
                        <ul className='bookng-notes-list'>
                            {notes
                                .filter(n => n.id && n.id !== '')
                                .sort((n1, n2) => n2.createDateTime.getTime() - n1.createDateTime.getTime())
                                .map(n => (
                                    <li key={n.id} className={`booking-note-list-item ${(noteId === n.id ? ' selected' : '')}`} onClick={e => clickHandler(e, () => this.editNote(n))}>
                                        <div>{n.text}</div>
                                        <div className='flex'>
                                            <span className='glyphicon glyphicon-comment text-primary flex-shrink'></span>
                                            <div className='text-right small flex-stretch'>{`${n.createdBy} - ${n.createDateTime.toShortDateString(dateFormat)} ${n.createDateTime.toShortTimeString(timeFormat)}`}</div>
                                        </div>
                                    </li>
                                ))}
                            {auditLog.sort((l1, l2) => l2.time.getTime() - l1.time.getTime()).map(l => <AuditLogEntry key={l.id} logEntry={l} timeFormat={timeFormat} dateFormat={dateFormat} showIcon={true} />)}
                        </ul>
                        {activityNotes.length > 0 ? <div className='col-xs-12'><ul className='list-group'>{activityNotes}</ul></div> : null}
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state: ApplicationState) => ({
    dateFormat: state.venues.dateFormat,
    timeFormat: state.venues.timeFormat
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    logout: bindActionCreators(LoginActions.actionCreators.logout, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingNotes);