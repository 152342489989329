import { ValidationError } from '../../global/types';
import * as api from '../../apiClient';


export interface ProductCategory {
    id: string;
    clientId: number;
    venueId: string;
    name: string;
    colour: string;
    sequence: number;
    archived: boolean;
    showOnPointOfSale: boolean;
    showOnWebShop: boolean;
    reportingPriority: number | null;
}

export interface ProductCategoryState {
    isLoading: boolean;
    productCategories: ProductCategory[];
    loadError: api.ApiError | null;
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
}

export enum ProductCategoryActionTypes {
    RequestProductCategories = 'REQUEST_PRODUCT_CATEGORIES',
    ReceivedProductCategories = 'RECEIVE_PRODUCT_CATEGORIES',
    SaveProductCategory = 'SAVE_PRODUCT_CATEGORY',
    ProductCategorySaved = 'PRODUCT_CATEGORY_SAVED',
    ProductCategorySaveFailed = 'PRODUCT_CATEGORY_SAVE_FAILED',
    EditProductCategory = 'EDIT_PRODUCT_CATEGORY'
}


export interface LoadProductCategories {
    type: ProductCategoryActionTypes.RequestProductCategories;
}


export interface ReceiveProductCategories {
    type: ProductCategoryActionTypes.ReceivedProductCategories;
    productCategories: ProductCategory[];
    error: api.ApiError | null;
}

export interface SaveProductCategory {
    type: ProductCategoryActionTypes.SaveProductCategory;
    isNew: boolean;
    productCategoryId?: string;
    venueId: string;
    name: string;
    colour: string;
    sequence: number;
    archived: boolean;
    showOnPointOfSale: boolean;
    showOnWebShop: boolean;
    reportingPriority: number | null;
}

export interface ProductCategorySaved {
    type: ProductCategoryActionTypes.ProductCategorySaved;
    productCategoryId: string;
}

export interface ProductCategorySaveFailed {
    type: ProductCategoryActionTypes.ProductCategorySaveFailed;
    error: api.ApiError;
}

export interface EditProductCategory {
    type: ProductCategoryActionTypes.EditProductCategory
}
