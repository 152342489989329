
import '../../css/multiImageViewer.css';

import * as React from 'react';

interface MultiImageViewerProps {
    images: string[];
    delayInMiliseconds: number;
    style?: any;
    className?: string;
}

interface MultiImageViewerState {
    currentIndex: number;
    intervalId: number;
    maxIndex: number;
}

class MultiImageViewer extends React.Component<MultiImageViewerProps, MultiImageViewerState> {

    constructor(props: MultiImageViewerProps) {
        super(props);

        this.state = { currentIndex: 0, intervalId: 0, maxIndex: 1 };
    }

    componentDidMount() {
        this.setTimer(this.props.delayInMiliseconds);
    }   

    shouldComponentUpdate(nextProps: MultiImageViewerProps, nextState: MultiImageViewerState) {

        if (this.props.delayInMiliseconds !== nextProps.delayInMiliseconds) {
            return true;
        }

        if (this.state.currentIndex !== nextState.currentIndex) {
            return true;
        }

        if (this.props.images.length !== nextProps.images.length) {
            return true;
        }

        for (let i = 0; i < this.props.images.length; i++) {
            if (this.props.images[i] !== nextProps.images[i]) {
                return true;
            }
        }
        
        return false;
    }

    componentDidUpdate(prevProps: MultiImageViewerProps) {
        const currentDelay = this.props.delayInMiliseconds;
        const prevDelay = prevProps.delayInMiliseconds;

        if (currentDelay !== prevDelay) {
            if (this.state.intervalId > 0) {
                window.clearInterval(this.state.intervalId);
            }

            if (currentDelay > 0) {
                this.setTimer(currentDelay);
            }
        }
    }

    componentWillUnmount() {
        this.clearTimer(this.state.intervalId);
    }

    setTimer = (delayInMiliseconds: number) => {
        const x = window.setInterval(() => { this.goToNextImage() }, delayInMiliseconds);
        this.setState({ intervalId: x });
    }

    clearTimer = (handle: number) => {
        if (handle > 0)
            window.clearInterval(handle);
    }

    goToNextImage = () => this.setState((s, p) => {
        const { currentIndex: ix, maxIndex } = s;
        const { images } = p;

        const newIndex = ix < images.length - 1 ? ix + 1 : 0;
        const newMaxIndex = Math.max(newIndex + 1, maxIndex);

        return ({ currentIndex: newIndex, maxIndex: newMaxIndex });
    });

    render() {

        const { currentIndex, maxIndex } = this.state;
        const { className, style, images: allImages } = this.props;
        const images = allImages.filter((_, ix) => ix <= maxIndex).map((i, ix) => <img className={`${ix === currentIndex ? 'opaque' : ''}`} key={i} src={i} alt={`${ix}`} />);
        const cls = className ? `multi_image_viewer_container ${className}` : 'multi_image_viewer_container';

        return <div className={cls} style={style}>{images}</div>;
    }
}

export default MultiImageViewer;

