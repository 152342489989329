
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Dropzone from 'react-dropzone';

import * as PropTypes from 'prop-types'
import * as api from '../../../../store/apiClient';
import ApiError from '../../../global/apiError';
import { ApplicationState } from '../../../../store';
import * as v from '../../../global/validation';
import * as ct from '../../../global/controls';
import { CookieOptionsType, VenuePublicWebsiteSettings } from '../../../../store/pages/publicWebsiteSettings/types';
import * as PublicWebsiteSettingsActions from '../../../../store/pages/publicWebsiteSettings/actions';
import ColourPicker from '../../../global/colourPicker';
import ResourceLabel from './resourceLabel';
import { clickHandler } from '../../../../utils/util';
import { ValidationError } from '../../../../store/global/types';
import { ImageFile } from '../../../../utils/images';
import { Resource } from '../../../../store/pages/resources/types';

interface ResourceSelection {
    selected: boolean;
    resourceId: string;
    name: string;
    colour: string;
    default: boolean;
}

interface LocalProps {
    venueId: string;
    publicWebsiteSettings: VenuePublicWebsiteSettings;
    resources: Resource[];
    close: () => void;
}

interface MappedState {
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
}

interface MappedActions {
    savePublicWebsiteSettings: (publicWebsiteSettings: VenuePublicWebsiteSettings, backgroundImg: File | null, headerImg: File | null) => void;
}

type GeneralPublicWebsiteSettingsFormProps = MappedState & MappedActions & LocalProps;

interface GeneralPublicWebsiteSettingsFormState {
    publicWebsiteName: ct.FormValue<string>;
    hideOnPublicWebsiteList: ct.FormValue<boolean>;
    siteName: ct.FormValue<string>;
    archived: ct.FormValue<boolean>;
    resourceSelections: ResourceSelection[];
    backgroundImageId: string | null;
    backgroundImage: ImageFile | null;
    panelColour: string;
    panelOpacity: number;
    panelTextColour: string;
    primaryButtonColour: string;
    primaryButtonTextColour: string;
    cookieOptionsType: CookieOptionsType;
    headerImageId: string | null;
    headerImage: ImageFile | null;
}

class GeneralPublicWebsiteSettingsForm extends React.Component<GeneralPublicWebsiteSettingsFormProps, GeneralPublicWebsiteSettingsFormState> {

    constructor(props: GeneralPublicWebsiteSettingsFormProps) {
        super(props);

        this.state = this.buildStateFromProps(this.props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps(props: GeneralPublicWebsiteSettingsFormProps): GeneralPublicWebsiteSettingsFormState {

        const settings = this.props.publicWebsiteSettings;

        return {
            publicWebsiteName: this.validatePublicWebsiteName(settings.publicWebsiteName),
            siteName: this.validateSiteName(settings.siteName),
            hideOnPublicWebsiteList: this.validateHideOnPublicWebsiteList(settings.hideOnPublicWebsiteList),
            archived: ct.asFormValue('archived', settings.archived),
            resourceSelections: props.resources.filter(r => r.venueId === props.venueId && !r.archived).map(r => ({ resourceId: r.id, name: r.name, colour: r.colour, default: settings.resources.findIndex(x => x.resourceId === r.id && x.default) >= 0, selected: settings.resources.findIndex(x => x.resourceId === r.id) >= 0 })),
            backgroundImageId: settings.backgroundImageId,
            backgroundImage: null,
            panelColour: settings.panelColour,
            panelOpacity: settings.panelOpacity,
            panelTextColour: settings.panelTextColour,
            primaryButtonColour: settings.primaryButtonColour,
            primaryButtonTextColour: settings.primaryButtonTextColour,
            cookieOptionsType: settings.cookieOptionsType,
            headerImageId: settings.headerImageId,
            headerImage: null,
        };
    }


    componentDidUpdate(prevProps: GeneralPublicWebsiteSettingsFormProps) {
        // Only update state if resource has changed
        const { saveComplete: prevSaveComplete, publicWebsiteSettings: prevPublicWebsiteSettings } = prevProps;
        const { saveComplete, publicWebsiteSettings } = this.props;

        const prevPayPageSettings = prevPublicWebsiteSettings.paymentPage;
        const nextpayPageSettings = publicWebsiteSettings.paymentPage;

        if (prevPayPageSettings.heading !== nextpayPageSettings.heading
            || prevPayPageSettings.body !== nextpayPageSettings.body
            || prevPayPageSettings.payButtonText !== nextpayPageSettings.payButtonText
            || prevPublicWebsiteSettings.lastUpdatedTimestamp !== publicWebsiteSettings.lastUpdatedTimestamp) {
            this.setState(this.buildStateFromProps(this.props));
        }

        if (!prevSaveComplete && saveComplete) {
            setTimeout(() => { this.close(); }, 750);
        }
    }

    onBackgroundImageDrop = (files: File[]) => {
        const file = files[0];
        this.setState({ backgroundImage: { file: file, preview: URL.createObjectURL(file) } });
    }

    onHeaderImageDrop = (files: File[]) => {
        const file = files[0];
        this.setState({ headerImage: { file: file, preview: URL.createObjectURL(file) } });
    }

    private savePaymentPageSettings = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }

    private close = () => {
        this.props.close();
    }

    private save = () => {
        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {
            const { publicWebsiteSettings } = this.props;
            const { publicWebsiteName, siteName, hideOnPublicWebsiteList, archived, backgroundImage, headerImage, panelColour,
                panelOpacity, panelTextColour,primaryButtonColour, primaryButtonTextColour, cookieOptionsType, resourceSelections } = this.state;

            this.props.savePublicWebsiteSettings({
                ...publicWebsiteSettings,
                publicWebsiteName: publicWebsiteName.value,
                hideOnPublicWebsiteList: hideOnPublicWebsiteList.value,
                siteName: siteName.value,
                archived: archived.value,
                resources: resourceSelections.filter(r => r.selected).map(r => ({ resourceId: r.resourceId, resourceName: r.name, colour: r.colour, default: r.default })),
                panelColour: panelColour,
                panelOpacity: panelOpacity,
                panelTextColour: panelTextColour,
                primaryButtonColour: primaryButtonColour,
                primaryButtonTextColour: primaryButtonTextColour,
                cookieOptionsType: cookieOptionsType,
            }, backgroundImage ? backgroundImage.file : null, headerImage ? headerImage.file : null);
        }
    }

    validatePublicWebsiteName = (val: string) => v.validate(val, 'publicWebsiteName', [v.required], this.props.validationErrors);
    validateSiteName = (val: string) => v.validate(val, 'siteName', [v.required, v.urlSafeString], this.props.validationErrors);
    validateHideOnPublicWebsiteList = (val: boolean) => v.validate(val, 'hideOnPublicWebsiteList', [], this.props.validationErrors);

    resourceSelectionChanged = (resourceId: string, val: boolean) => this.setState(s => ({ resourceSelections: s.resourceSelections.map(r => r.resourceId === resourceId ? { ...r, selected: val } : r) }))
    resourceDefaultSelectionChanged = (resourceId: string, val: boolean) => this.setState(s => ({ resourceSelections: s.resourceSelections.map(r => r.resourceId === resourceId ? { ...r, default: val } : r) }))

    render() {
        const t = this.context.t;
        const { saveError, saveComplete } = this.props;
        const { publicWebsiteName, hideOnPublicWebsiteList, siteName, archived, backgroundImage, backgroundImageId, panelColour, panelOpacity, panelTextColour,
            primaryButtonColour, primaryButtonTextColour, headerImage, headerImageId, cookieOptionsType, resourceSelections } = this.state;

        let backgroundPreviewImg: JSX.Element | null = null;
        const backgroundImageUrl = backgroundImage ? backgroundImage.preview : backgroundImageId !== null ? api.getClientImageThunbUrl(backgroundImageId, 200) : null;
        if (backgroundImageUrl) {
            backgroundPreviewImg = <img src={backgroundImageUrl} className='file-preview' alt='background'></img>;
        }

        let headerPreviewImg: JSX.Element | null = null;
        const headerImageUrl = headerImage ? headerImage.preview : headerImageId !== null ? api.getClientImageThunbUrl(headerImageId, 200) : null;
        if (headerImageUrl) {
            headerPreviewImg = <img src={headerImageUrl} className='file-preview' alt='header'></img>;
        }

        const cookieOptions = Object.keys(CookieOptionsType).filter(key => typeof CookieOptionsType[key as any] === 'number').map(key => ({ key: CookieOptionsType[key as any].toString(), name: t(`CookieOptionsType:${key}`) }));

        const message = saveError ? <ApiError error={saveError} /> : saveComplete ? <div className='bg-success'>{t('Global:saveComplete')}</div> : null;
 
        return <div className='paymentPageForm'>
            <h2 className='paymentPageForm_title'>{t('PublicWebsitePageList:title')}</h2>

            <div className='row'>
                <div className='col-md-12'>
                    <form className='data-form' onSubmit={this.savePaymentPageSettings} autoComplete='off'>

                        <ct.TextBox id='publicWebsiteName' labelKey='PublicWebsiteSettingsForm:publicWebsiteName' value={publicWebsiteName} callback={v => this.setState({ publicWebsiteName: this.validatePublicWebsiteName(v) })} />

                        <ct.Checkbox id='archived' labelKey='Global:archived' value={archived} callback={val => this.setState({ archived: ct.asFormValue('archived', val)}) } />
            
                        <ct.TextBox id='siteName' labelKey='PublicWebsiteSettingsForm:siteName' value={siteName} callback={v => this.setState({ siteName: this.validateSiteName(v) })} />

                        <ct.Checkbox id='hideOnPublicWebsiteList' labelKey='PublicWebsiteSettingsForm:hideOnPublicWebsiteList' value={hideOnPublicWebsiteList} callback={val => this.setState({ hideOnPublicWebsiteList: this.validateHideOnPublicWebsiteList(val) })} />

                        <div className='control-wrapper'>
                            <label>{t('PublicWebsiteSettingsForm:resources')}</label>
                            <table className='table table-borderless table-super-condensed'>
                                <thead>
                                    <tr>
                                        <th>{t('PublicWebsiteSettingsForm:selectResourceHeading')}</th>
                                        <th>{t('PublicWebsiteSettingsForm:resourceNameHeading')}</th>
                                        <th>{t('PublicWebsiteSettingsForm:defaultHeading')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {resourceSelections.map(r => <tr key={r.resourceId}>
                                        <td className='shrink'><ct.Checkbox id={`${r.resourceId}_selection`} labelKey='' value={ct.asFormValue(`${r.resourceId}_selection`, r.selected)} callback={v => this.resourceSelectionChanged(r.resourceId, v) } /></td>
                                        <td className='shrink'><ResourceLabel colour={r.colour} name={r.name} /></td>
                                        <td className='expand'><ct.Checkbox id={`${r.resourceId}_default`} labelKey='' value={ct.asFormValue(`${r.resourceId}_default`, r.default)} callback={v => this.resourceDefaultSelectionChanged(r.resourceId, v)} /></td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </div>

                        <div className='control-wrapper'>
                            <label>{t('PublicWebsiteSettingsForm:backgroundImage')}</label>
                            <div>
                                <Dropzone multiple={false} accept={{ 'image/*': ['.png', '.gif', '.jpeg', '.jpg'] }} onDrop={this.onBackgroundImageDrop}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()} className='file-drop'>
                                        <input {...getInputProps()} />
                                        <p>{t('Global:imageDropText')}</p>
                                    </div>
                                )}
                            </Dropzone>
                            {backgroundPreviewImg}
                            </div>
                        </div>

                        <div className='control-wrapper'>
                            <label>{t('PublicWebsiteSettingsForm:headerImage')}</label>
                            <div>
                                <Dropzone multiple={false} accept={{ 'image/*': ['.png', '.gif', '.jpeg', '.jpg'] }} onDrop={this.onHeaderImageDrop}>
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps()} className='file-drop'>
                                            <input {...getInputProps()} />
                                            <p>{t('Global:imageDropText')}</p>
                                        </div>
                                    )}
                                </Dropzone>
                                {headerPreviewImg}
                            </div>
                            <span>{t('PublicWebsiteSettingsForm:headerImageSizeInfo')}</span>
                        </div>

                        <p />
                        <div>
                            <ColourPicker labelKey='PublicWebsiteSettingsForm:panelBackgroundColour' colourHex={panelColour} onColourChanged={color => this.setState({ panelColour: color})}/>
                            <ct.NumberBox id='panelOpacity' labelKey='PublicWebsiteSettingsForm:panelBackgroundOpacity' placeholderKey='PublicWebsiteSettingsForm:panelBackgroundOpacity' value={ct.asFormValue('panelOpacity', panelOpacity)} min='0' max='100' callback={val => this.setState({ panelOpacity: val || 0 })} />
                            <ColourPicker labelKey='PublicWebsiteSettingsForm:panelTextColour' colourHex={panelTextColour} onColourChanged={color => this.setState({ panelTextColour: color })} />
                            <ColourPicker labelKey='PublicWebsiteSettingsForm:primaryButtonColour' colourHex={primaryButtonColour} onColourChanged={color => this.setState({ primaryButtonColour: color })} />
                            <ColourPicker labelKey='PublicWebsiteSettingsForm:primaryButtonTextColour' colourHex={primaryButtonTextColour} onColourChanged={color => this.setState({ primaryButtonTextColour: color })} />

                            <ct.Select id='cookieOptionsType' labelKey='PublicWebsiteSettingsForm:cookieOptionsType' options={cookieOptions} value={ct.asFormValue('cookieOptionsType', cookieOptionsType.toString())} callback={val => this.setState({ cookieOptionsType: parseInt(val) }) } />
                        </div>

                        {message}

                        <p />
                        <div className='btn-toolbar'>
                            <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)}>{t('Global:save')}</button>
                            <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{t('Global:cancel')}</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>;
    }
}


const mapStateToProps = (state: ApplicationState) => ({
    isSaving: state.publicWebsiteSettings.isSaving,
    saveComplete: state.publicWebsiteSettings.saveComplete,
    saveError: state.publicWebsiteSettings.saveError,
    validationErrors: state.publicWebsiteSettings.validationErrors,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    savePublicWebsiteSettings: bindActionCreators(PublicWebsiteSettingsActions.actionCreators.savePublicWebsiteSettings, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(GeneralPublicWebsiteSettingsForm);
