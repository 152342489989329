
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import StepHeader from './stepHeader';
import {RegistrationField} from '../../../store/pages/venues/types';
import {Customer} from '../../../store/pages/customer/types';
import {MarketingPreference} from '../../../store/pages/customer/types';
import { clickHandler } from '../../../utils/util';
import NavButtons from './navButtons';

interface RegistrationContactPrefsProps {
    customer: Customer;
    registrationFields: RegistrationField;
    requiredRegistrationFields: RegistrationField;
    headerText: string;
    resultsMarketingText: string;
    generalMarketingText: string;
    showStep1: () => void;
    cancel: () => void;
    showConfirmation: (customer: Customer) => void;
}

enum SelectionState {
    NotSet,
    Yes,
    No
}

interface RegistrationContactPrefsState {
    resultsState: SelectionState;
    marketingState: SelectionState;
    emailAddress: ct.FormValue<string>;
    phoneNumber: ct.FormValue<string>;
}

class RegistrationContactPrefs extends React.Component<RegistrationContactPrefsProps, RegistrationContactPrefsState> {

    constructor(props: RegistrationContactPrefsProps) {
        super(props);

        this.state = {
            resultsState: SelectionState.NotSet,
            marketingState: SelectionState.NotSet,
            emailAddress: this.validateEmailAddress(props.customer.emailAddress, false),
            phoneNumber: this.validatePhoneNumber(props.customer.phoneNumber)
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }


    editCustomer = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        this.props.showStep1();
    }

    validatePhoneNumber = (val: string) => v.validate(val, 'phoneNumber', this.props.requiredRegistrationFields & RegistrationField.PhoneNumber ? [v.required] : undefined, []);
    validateEmailAddress = (val: string, forceRequired: boolean) => v.validate(val, 'emailAddress', (this.props.requiredRegistrationFields & RegistrationField.EmailAddress) || forceRequired ? [v.required] : undefined, []);

    validateEmailResults = (val: boolean) => {
        if (this.state && this.state.emailAddress) {
            const required = val || this.state.marketingState === SelectionState.Yes;
            this.setState((prevState) => ({ emailAddress: this.validateEmailAddress(prevState.emailAddress.value, required), resultsState: val ? SelectionState.Yes : SelectionState.No }));
        }
    };

    validateEmailMarketing = (val: boolean) => {
        if (this.state && this.state.emailAddress) {
            const required = val || this.state.resultsState === SelectionState.Yes;
            this.setState((prevState) => ({ emailAddress: this.validateEmailAddress(prevState.emailAddress.value, required), marketingState: val ? SelectionState.Yes : SelectionState.No }));
        }
    };
    
    submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {

            this.props.showConfirmation({
                ...this.props.customer,
                emailAddress: this.state.emailAddress.value,
                phoneNumber: this.state.phoneNumber.value,
                resultsPreference: this.state.resultsState === SelectionState.Yes ? MarketingPreference.Email : MarketingPreference.None,
                marketingPreference: this.state.marketingState === SelectionState.Yes ? MarketingPreference.Email : MarketingPreference.None,
            });
        }
    }

    render() {

        const resultsQuestion = this.renderEmailQuestion();
        const marketingQuestion = this.state.resultsState !== SelectionState.NotSet ? this.renderMarketingQuestion() : null;
        const contactFields = this.state.resultsState !== SelectionState.NotSet && this.state.marketingState !== SelectionState.NotSet ? this.renderFields() : null;

        return (
            <div>
                <form className='registration-form' onSubmit={this.submit} autoComplete='off'>
                    <div style={({ minHeight: '350px' })}>
                        <StepHeader headerText={this.props.headerText} />

                        {resultsQuestion}

                        {marketingQuestion}

                        {contactFields}

                    </div>
                    <NavButtons t={this.context.t} cancel={this.props.cancel} preventNext={!v.isValid(this.state)} />
                </form>
            </div>
        );
    }

    renderEmailQuestion = () => {
        return (
            <div style={({marginTop: '30px', marginBottom: '30px'})}>
                <div>
                    {this.props.resultsMarketingText}
                </div>
                <div className='row'>
                    <div className='col-xs-3 center'><button type='button' className={`btn btn-${this.state.resultsState === SelectionState.Yes ? 'success' : 'primary'}`} onClick={e => clickHandler(e, () => this.validateEmailResults(true))}>{this.context.t('Global:yes')}</button></div>
                    <div className='col-xs-3 center'><button type='button' className={`btn btn-${this.state.resultsState === SelectionState.No ? 'danger' : 'primary'}`} onClick={e => clickHandler(e, () => this.validateEmailResults(false))}>{this.context.t('Global:no')}</button></div>
                </div>
            </div>
        );
    }

    renderMarketingQuestion = () => {

        if (this.state.resultsState === SelectionState.NotSet)
            return null;

        return (
            <div style={({ marginTop: '30px', marginBottom: '30px' })}>
                <div>
                    {this.props.generalMarketingText}
                </div>
                <div className='row'>
                    <div className='col-xs-3 center'><button type='button' className={`btn btn-${this.state.marketingState === SelectionState.Yes ? 'success' : 'primary'}`} onClick={e => clickHandler(e, () => this.validateEmailMarketing(true))}>{this.context.t('Global:yes')}</button></div>
                    <div className='col-xs-3 center'><button type='button' className={`btn btn-${this.state.marketingState === SelectionState.No ? 'danger' : 'primary'}`} onClick={e => clickHandler(e, () => this.validateEmailMarketing(false))}>{this.context.t('Global:no')}</button></div>
                </div>
            </div>
        );
    }

    renderFields = () => {
        if (this.state.resultsState === SelectionState.NotSet || this.state.marketingState === SelectionState.NotSet)
            return null;

        const contactFields: JSX.Element[] = [];
        const emailRequired = this.state.resultsState === SelectionState.Yes || this.state.marketingState === SelectionState.Yes;

        if (emailRequired || this.props.registrationFields & RegistrationField.EmailAddress) {
            contactFields.push(<ct.Email key='emailAddress' id='emailAddress' classNames='col-xs-6' labelKey='Global:emailAddress' placeholderKey='Global:emailAddress' value={this.state.emailAddress} callback={val => this.setState({ emailAddress: this.validateEmailAddress(val, emailRequired)})} />);
        }

        if (this.props.registrationFields & RegistrationField.PhoneNumber) {
            contactFields.push(<ct.PhoneNumber key='phoneNumber' id='phoneNumber' classNames='col-xs-6' labelKey='Global:phoneNumber' placeholderKey='Global:phoneNumber' value={this.state.phoneNumber} callback={val => this.setState({ phoneNumber: this.validatePhoneNumber(val) })} />);
        }

        return (
            <div style={({ marginTop: '30px', marginBottom: '30px' })}>
                <div className='row'>
                    {contactFields}
                </div>
            </div>
        );
    }
}

export default RegistrationContactPrefs;