
export interface UserPreferenceState {
    hiddenResources: string[];
    slotSizeInMinutes: number;
}

export enum UserPreferenceActionTypes {
    SetResourcefilter = 'SET_RESOURCE_FILTER',
    SetDiaryResolution = 'SET_DIARY_RESOLUTION',
}

export interface SetResourcefilter {
    type: UserPreferenceActionTypes.SetResourcefilter;
    venueId: string;
    hiddenResources: string[];
}

export interface SetDiaryResolution {
    type: UserPreferenceActionTypes.SetDiaryResolution;
    slotSizeInMinutes: number;
}