
import * as React from 'react';
import * as PropTypes from 'prop-types'
import { bindActionCreators, Dispatch } from 'redux';
import { Link } from 'react-router-dom'
import { ApplicationState } from '../../store/index';
import { connect } from 'react-redux'
import * as images from '../../utils/images';
import * as apiClient from '../../store/apiClient';
import * as LoginActions from '../../store/pages/login/actions';
import * as VenueActions from '../../store/pages/venues/actions';
import { setLanguage } from 'redux-i18n';
import { Venue } from '../../store/pages/venues/types';
import {ClientInfo} from '../../store/pages/login/types';
import VenueSelection from './venueSelection';
import ConnectionStatus from './connectionStatus';
import * as ct from './controls';
import { AnonymousSubscription } from 'rxjs/Subscription';
import { clickHandler } from '../../utils/util';

interface LocalState {
    isAuthenticated?: boolean;
    isLoggedIn?: boolean;
    userFirstName: string | null;
    userLastName: string | null;
    client?: ClientInfo;
    clients?: ClientInfo[];
    venues: Venue[];
    selectedVenueId: string;
    i18Lang: string;
}
    
interface Actions {
    switchClient?: (clientId: number) => void;
    loadUserClientInfo?: () => void;
    loadVenues: () => void;
    selectVenue: (venueId: string) => void;
    setLanguage: (labg: string) => void;
}

interface LocalProps { }

type HeaderProps = LocalState & Actions & LocalProps;

interface HeaderState {
    requireReload: boolean;
}

class Header extends React.Component<HeaderProps, HeaderState> {
    private versionCheckSubscription?: AnonymousSubscription;

    state = { requireReload: false }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        if (this.props.isLoggedIn && this.props.loadUserClientInfo) {
            this.props.loadUserClientInfo();
        }

        this.versionCheckSubscription = apiClient.versionCheck().subscribe(x => {
            console.log('versionCheckSubscription raised: missmatch: ' + x);
            this.setState({ requireReload: x })
        });
    }

    componentWillUnmount() {
        if (this.versionCheckSubscription) {
            this.versionCheckSubscription.unsubscribe();
        }
    }

    componentDidUpdate() {
        const { selectedVenueId, i18Lang, venues, setLanguage } = this.props;

        const venue = venues.find(v => v.id === selectedVenueId);
        if (venue && venue.culture !== i18Lang) {
            setLanguage(venue.culture);
        }
    }

    switchClient = (clientId: number) => {
        if (this.props.switchClient)
            this.props.switchClient(clientId);
    }

    buildClientHeader = (isAuthenticated: boolean, isLoggedIn: boolean, clients: ClientInfo[] | undefined, client: ClientInfo | undefined | null) => {
        if (!isAuthenticated || !isLoggedIn) {
            return null;
        }

        // eslint-disable-next-line
        const clientList: ct.SelectOption[] = clients ? clients.sort((c1,c2) => c1.name < c2.name ? -1 : c1.name === c2.name ? 0 : 1).map(c => ({ key: c.id.toString(), name: c.name })) : [];
        const selectedClient = client ? client : clients && clients.length > 0 ? clients[0] : null;

        if (clients && clients.length > 1) {
            return <ct.PlainSelect id='clientSelection' className='hdr-selection' options={clientList} value={selectedClient ? selectedClient.id.toString() : ''} buttonClassOverride='btn btn-basic dropdown-toggle' styleOverride={{}} callback={id => this.switchClient(parseInt(id)) } />
        } else {
            return <div className='hdr-client'>{selectedClient ? selectedClient.name : ''}</div>;
        }
    }

    selectVenue = (venue: Venue) => {
        const { selectedVenueId, selectVenue } = this.props;

        if (venue && venue.id !== selectedVenueId) {
            selectVenue(venue ? venue.id : '');
        }
    }

    render() {
        const { isAuthenticated: isAuthenticatedProp, isLoggedIn: isLoggedInProp, client, clients, venues, selectedVenueId, userFirstName, userLastName } = this.props;
        const { requireReload } = this.state;
        const { t } = this.context;
        const isAuthenticated = isAuthenticatedProp ? isAuthenticatedProp : false;
        const isLoggedIn = isLoggedInProp ? isLoggedInProp : false;

        const renderButton = isAuthenticated
            ? (<button type='button' className='navbar-toggle' data-toggle='collapse' data-target='.navbar-collapse'>
                <span className='sr-only'>Toggle navigation</span>
                <span className='icon-bar'></span>
                <span className='icon-bar'></span>
                <span className='icon-bar'></span>
            </button>)
            : null;

        const logoImg = images.getImageUrl('logo');

        const clientHeading = this.buildClientHeader(isAuthenticated, isLoggedIn, clients, client);

        return (<div className='navbar-header navbar-inverse header-top'>
            <div className='pull-right' style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className='app-user'>{userFirstName} {userLastName}</span>
                    <span className='app-version'>V{apiClient.getAppVersion()}</span>
                </div>
                {renderButton}
                {isAuthenticated ? <ConnectionStatus /> : null}
            </div>
            <div className='pull-left'>
                <Link className='navbar-brand' to={'/'}><img className='header-img' src={logoImg} alt='alpha timing'></img></Link>
                {clientHeading}
                <VenueSelection isAuthenticated={isAuthenticated && isLoggedIn} venues={venues} selectedVenueId={selectedVenueId} venueChanged={this.selectVenue} />
            </div>
            {requireReload ? <div className='alert alert-danger header-alert'>{t('Header:versionOutOfDateMessage')} <button className='btn btn-default' style={{marginLeft: '5px'}} onClick={e => clickHandler(e, () => window.location.reload())}>{t('Global:reload')}</button></div> : null }
        </div>);
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    isAuthenticated: state.login.isAuthenticated,
    isLoggedIn: state.login.isLoggedIn,
    userFirstName: state.login.userFirstName,
    userLastName: state.login.userLastName,
    client: state.login.client,
    clients: state.login.clients,
    venues: state.venues.venues,
    selectedVenueId: state.venues.selectedVenueId,
    isSignalrConnected: state.status.isConnected,
    i18Lang: state.i18nState.lang
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    switchClient: bindActionCreators(LoginActions.actionCreators.switchClient, dispatch),
    loadUserClientInfo: bindActionCreators(LoginActions.actionCreators.loadUserClientInfo, dispatch),
    loadVenues: bindActionCreators(VenueActions.actionCreators.loadVenues, dispatch),
    selectVenue: bindActionCreators(VenueActions.actionCreators.selectVenue, dispatch),
    setLanguage: bindActionCreators(setLanguage, dispatch),
});

// Casting to prevent error where used in index.ts that isBusy is mandatory, since it is being provided by Redux.
export default connect(mapStateToProps, mapDispatchToProps)(Header);


