
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';
import * as v from '../../global/validation';

import { BillItem, calculateItemPrice, calculateUnitPrice } from '../../../store/pages/pointOfSale/types';
import NumericPad from '../../global/numericPad';
import { clickHandler, isNullOrEmpty } from '../../../utils/util';
import { ActivityFormatProduct, buildProductKey, getPrices, sortPrices } from '../diary/helpers';
import { ProductPricingMode, Product, ProductType } from '../../../store/pages/products/types';
import { Tag } from '../../../store/pages/tags/types';
import { LinkedItemQuantities } from './types';
import { CustomerCategory } from '../../../store/pages/customerCategories/types';
import { TimeFormat } from '../../../store/pages/venues/types';

interface LinkedItem {
    billItemKey: string;
    customerCategoryId: string | null;
    customerCategoryName: string;
    quantity: number;
}

export interface BillItemReservation {
    reservationId: string;
    resourceId: string;
    resourceName: string;
    startTime: Date;
    activityId: string;
    activityName: string;
    activityProducts: ActivityFormatProduct[];
}

interface EditBillItemProps {
    item: BillItem;
    linkedItems: BillItem[];
    itemName: string;
    customerTags: Tag[];
    canSelectProduct: boolean;
    productSelections: ActivityFormatProduct[];
    customerCategories: CustomerCategory[];
    reservations?: BillItemReservation[];
    products: Product[];
    timeFormat: TimeFormat;
    updateItem: (itemKey: string, quantity: number, unitPrice: number, productId: string, placesToBookPerUnit: number | null, reservationId: string | null, customerCategoryId: string | null, pricingMode: ProductPricingMode, fixedPriceOverride: number | null, productPriceId: string, linkedItemQuantities: LinkedItemQuantities[]) => void;
    cancel: () => void;
    canUpdate: boolean;
    canRemove: boolean;
    removeItem: (itemKey: string) => void;
}

interface EditBillItemState {
    quantity: number;
    unitPriceText: string;
    productKey: ct.FormValue<string>;
    productId: string;
    productPriceId: string;
    reservationId: ct.FormValue<string | null>;
    placesToBookPerUnit: number | null;
    linkedItemQuantities: LinkedItem[];
    validationError: string | null;
}

export default class EditBillItem extends React.Component<EditBillItemProps, EditBillItemState> {

    constructor(props: EditBillItemProps) {
        super(props);

        let productKey = props.item.customerCategoryId ? buildProductKey(props.item.productId, props.item.customerCategoryId) : props.item.productId;
        const products = props.productSelections.filter(p => p.key.startsWith(productKey));

        if (isNullOrEmpty(productKey) && products.length > 1) {
            productKey = products[0].key;
        }

        this.state = {
            quantity: props.item.quantity,
            unitPriceText: props.item.pricingMode === ProductPricingMode.Fixed ? props.item.totalItemPrice.toString() : props.item.unitPrice.toString(),
            productKey: this.validateProductKey(productKey),
            productId: props.item.productId,
            productPriceId: props.item.productPriceId,
            placesToBookPerUnit: props.item.placesToBookPerUnit,
            reservationId: this.validateReservationId(props.item.reservationId, productKey),
            linkedItemQuantities: props.linkedItems.map(li => {
                const cat = props.customerCategories.find(c => c.id === li.customerCategoryId);
                return { billItemKey: li.key, quantity: li.quantity, customerCategoryId: li.customerCategoryId, customerCategoryName: cat ? cat.name : '' }
            }).filter(li => !isNullOrEmpty(li.customerCategoryName)),
            validationError: null
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    validateProductKey = (val: string) => v.validate(val, 'name', [this.validateProductSelection], []);
    validateReservationId = (val: string | null, productKey: string) => v.validate(val, 'name', [v => this.validateReservationSelection(v, productKey)], []);

    validateProductSelection = (productKey: string) => {
        const { canSelectProduct, productSelections } = this.props;

        const isValid = !canSelectProduct || productSelections.length === 0 || productSelections.findIndex(p => p.key === productKey) >= 0;
        return isValid ? undefined : 'validation:required';
    }

    validateReservationSelection = (reservationId: string | null, productKey: string) => {
        const { reservations } = this.props;

        if (!reservations || reservations.length === 0) {
            return undefined;
        }

        const reservationsForProduct = reservations.filter(r => r.activityProducts.findIndex(fp => fp.key === productKey) >= 0);
        const hasSelection = !isNullOrEmpty(reservationId) && reservationsForProduct.findIndex(r => r.reservationId === reservationId) >= 0;
        return hasSelection ? undefined : 'validation:required';
    }

    updateItem = () => {
        if (!v.isValid(this.state)) {
            this.setState({ validationError: 'validation:serverErrors' });
            // TODO: Show error message!
        } else {

            const { updateItem, item, productSelections } = this.props;
            const { quantity, unitPriceText, productKey, placesToBookPerUnit, reservationId, productPriceId, linkedItemQuantities } = this.state;
            const selection = productSelections.find(p => p.key === productKey.value);
            const productId = selection ? selection.product.id : item.productId;
            const catId = selection ? selection.customerCategoryId : item.customerCategoryId;
            const pricingMode = selection ? selection.product.pricingMode : item.pricingMode;
            const fixedPriceOverride = pricingMode === ProductPricingMode.Fixed ? parseFloat(unitPriceText) : null;
            const unitPrice = pricingMode === ProductPricingMode.Fixed ? 0 : parseFloat(unitPriceText);
            updateItem(item.key, quantity, unitPrice, productId, placesToBookPerUnit, reservationId.value, catId, pricingMode, fixedPriceOverride, productPriceId, linkedItemQuantities.map(li => ({ linkedBillItemKey: li.billItemKey, quantity: li.quantity })));
        }
    }

    removeItem = () => this.props.removeItem(this.props.item.key);

    updateQuantity = (newQuantity: number, productId: string, productPriceId: string, unitPrice: string, linkedItemQuantities: LinkedItem[]) => {
        const { item, productSelections } = this.props;
        if (item.pricingMode === ProductPricingMode.Fixed) {
            const prod = productSelections.find(p => p.product.id === productId);
            const totalQuantity = linkedItemQuantities.reduce((ttl, i) => ttl + i.quantity, newQuantity);
            const price = prod ? prod.product.pricing.filter(pr => pr.id === productPriceId)[0] : null;
            const itemPrice = prod && prod.product && prod.product.pricingMode === ProductPricingMode.Fixed && price ? calculateItemPrice(prod.product, price, totalQuantity).toString() : unitPrice;
            return { quantity: newQuantity, unitPriceText: itemPrice, linkedItemQuantities: linkedItemQuantities }
        } else {
            return { quantity: newQuantity, unitPriceText: unitPrice, linkedItemQuantities: linkedItemQuantities }
        }
    }

    incrementQuantity = () => this.setState((prevState) => this.updateQuantity(prevState.quantity + 1, prevState.productId, prevState.productPriceId, prevState.unitPriceText, prevState.linkedItemQuantities));

    decrementQuantity = () => this.setState((prevState) => this.updateQuantity(prevState.quantity - 1, prevState.productId, prevState.productPriceId, prevState.unitPriceText, prevState.linkedItemQuantities));

    clearUnitPrice = () => this.setState({ unitPriceText: '' });

    setUnitPrice = (newValue: string) => this.setState({ unitPriceText: newValue });

    onUnitPriceChanged = (value: string) => this.setState((prevState) => ({ unitPriceText: prevState.unitPriceText + value }));

    splitProductKey = (key: string) => {
        const keyParts = key.split('^');
        const productKey = keyParts[0];
        const priceId = keyParts[1];
        return { productKey, priceId }
    }

    onProductChanged = (value: string) => {
        const { productKey, priceId } = this.splitProductKey(value);
        const selected = this.props.productSelections.find(p => p.key === productKey);
        if (selected) {
            const productPrice = selected.product.pricing.filter(pr => pr.id === priceId)[0];
            this.setState(s => ({
                productKey: this.validateProductKey(selected.key),
                placesToBookPerUnit: selected.placesPerUnit,
                unitPriceText: calculateUnitPrice(selected.product, productPrice, this.state.quantity).toString(),
                productId: selected.product.id,
                productPriceId: priceId,
                reservationId: this.validateReservationId(s.reservationId.value, selected.key)
            }));
        }
    }

    productPriceChanged = (value: string) => {
        const { products } = this.props;
        this.setState(s => {
            const product = products.find(p => p.id === s.productId);
            const productPrice = product ? product.pricing.find(pr => pr.id === value) : null;
            return {
                productPriceId: value,
                unitPriceText: productPrice ? productPrice.unitPrice.toFixed(2) : '0'
            }
        });
    }

    onReservationChanged = (value: string) => {
        this.setState((prevState, props) => {
            const [reservationId, productKey] = value.split('~');

            const newVal = {
                reservationId: this.validateReservationId(reservationId, productKey ? productKey : prevState.productKey.value),
                placesToBookPerUnit: prevState.placesToBookPerUnit,
                productKey: prevState.productKey
            };

            if (isNullOrEmpty(reservationId)) {
                return newVal;
            } else {
                // If selection has changed, re-calculate number of places per unit
                const selectedProduct = props.productSelections.find(p => p.key === productKey ? productKey : prevState.productKey.value);
                return {
                    ...newVal,
                    placesToBookPerUnit: selectedProduct ? selectedProduct.placesPerUnit : prevState.placesToBookPerUnit,
                    productKey: selectedProduct ? this.validateProductKey(productKey) : prevState.productKey
                }
            }
        });
    }

    renderProductSelection = () => {
        const { t } = this.context;
        const { productSelections, item, customerTags } = this.props;
        const { productKey, productPriceId } = this.state;

        const sortedPrices = productSelections
            .flatMap(p => getPrices(p.product, this.state.quantity, item.createDateTimeInLocalTime, item.reservationStartTime, customerTags).map(pr => ({ product: p, price: pr })))
            .sort((p1, p2) => sortPrices(p1.price, p2.price));

        const priceOptions = sortedPrices.map(p => ({ key: `${p.product.key}^${p.price.productPrice.id}`, name: `${p.product.displayName} (${t('Global:currencySymbol')}${p.price.unitPrice.toFixed(2)})` }));

        const renderPrice = (o: ct.SelectOption) => {
            const { productKey, priceId } = this.splitProductKey(o.key);
            const pr = sortedPrices.find(p => p.price.productPrice.id === priceId);
            if (!pr) return <span key={o.key}>{o.name}</span>;

            let elements = [<span>{o.name}</span>]

            if (pr.price.isActive) elements.push(<span key={`${o.key}_active`} className='label label-success' style={({ marginLeft: '6px' })}>{t('Global:active')}</span>);
            if (!isNullOrEmpty(pr.price.tagId)) {
                elements.push(<span key={`${o.key}_${pr.price.tagId}`} className='label tag-label' style={({ backgroundColor: pr.price.tagColour, marginLeft: '6px' })}>{pr.price.tagName}</span>);
            } else if (pr.price.isOverride) {
                elements.push(<span key={`${o.key}_override`} className='label label-warning' style={({ marginLeft: '6px' })}>{t('Global:override')}</span>);
            }

            return elements;
        }

        return <div className='row'>
            <div className='col-xs-12'>
                <div style={({ margin: '0 15px 15px 0' })} className='form-group'>
                    <ct.Select id='product' labelKey='BillItemForm:activityProduct' value={{ ...productKey, value: `${productKey.value}^${productPriceId}` }} callback={this.onProductChanged} options={priceOptions} renderOption={renderPrice} />
                </div>
            </div>
        </div>
    }

    renderReservationSelection = () => {
        const { t } = this.context;
        const { reservations, timeFormat } = this.props;
        const { reservationId, productKey } = this.state;

        if (!reservations || reservations.length === 0) {
            return null;
        }

        const buildKey = (reservationId: string, prodKey: string) => `${reservationId}~${prodKey}`;

        const selections = reservations
            .reduce<ct.SelectOption[]>((items, r) => {
                const matchedProducts = r.activityProducts.filter(fp => fp.key.startsWith(productKey.value));
                if (matchedProducts.length === 1) {
                    items.push({ key: buildKey(r.reservationId, buildProductKey(matchedProducts[0].product.id, matchedProducts[0].customerCategoryId)), name: `${r.startTime.toShortTimeString(timeFormat)} - ${r.resourceName} - ${r.activityName}` })
                } else {
                    for (let pi = 0; pi < matchedProducts.length; pi++) {
                        items.push({ key: buildKey(r.reservationId, buildProductKey(matchedProducts[pi].product.id, matchedProducts[pi].customerCategoryId)), name: `${r.startTime.toShortTimeString(timeFormat)} - ${r.resourceName} - ${r.activityName} - ${r.activityProducts[pi].customerCategoryName}` })
                    }
                }

                return items;
            }, []);

        if (selections.length === 0) {
            return null;
        }

        const selection = buildKey(reservationId.value || '', productKey.value)
        const hasSelection = selections.findIndex(s => s.key === selection) >= 0;

        if (hasSelection && selections.length < 2) return null;

        const options = hasSelection ? selections : [{ key: '-1', name: t('BillItemForm:selectReservation') }].concat(selections);

        return <div className='row'>
            <div className='col-xs-12'>
                <div style={({ margin: '0 auto 15px auto' })} className='form-group'>
                    <ct.Select id='reservation' labelKey='BillItemForm:reservation' value={{ ...reservationId, value: selection }} callback={this.onReservationChanged} options={options} />
                </div>
            </div>
        </div>
    }

    render() {
        const { itemName, canSelectProduct, productSelections, canRemove, canUpdate } = this.props;
        const { quantity } = this.state;
        const { t } = this.context;
        const inlineControlStyle = ({ minHeight: '10px', margin: '0 6px', maxWidth: '90px', display: 'inline-block' });

        return (
            <div>
                <h3 className='text-center'>{itemName}</h3>
                <div className='data-form'>
                    <div className='row'>
                        <div className={`col-xs-${canSelectProduct && productSelections.length > 1 ? 4 : 12} text-${canSelectProduct && productSelections.length > 1 ? 'right' : 'center'}`}>
                            <div style={({ display: 'inline-block', textAlign: 'center' })}>
                                <label style={{ margin: '0' }}>{t('BillItemForm:quantity')}</label>
                                <div className='flex flex-start'>
                                    <button onClick={e => clickHandler(e, canUpdate ? this.decrementQuantity : () => { })} className='btn btn-primary'>-</button>
                                    <ct.IntNumericText id='quantity' labelKey='' placeholderKey='' value={ct.asFormValue('quantity', quantity)} min={1} callback={val => this.setState({ quantity: val || 0 })} style={inlineControlStyle} disabled={!canUpdate} />
                                    <button onClick={e => clickHandler(e, canUpdate ? this.incrementQuantity : () => { })} className='btn btn-primary'>+</button>
                                </div>
                            </div>
                        </div>

                        {canSelectProduct && productSelections.length > 1 ? <div className='col-xs-8 text-left'>{this.renderProductSelection()}</div> : null}
                    </div>
                    {this.renderLinkedItems()}
                    {this.renderReservationSelection()}
                    <div className='row'>
                        <div className='col-xs-12'>
                            {this.renderPrice()}
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-xs-12'>
                            <div className='row button-panel' style={({ maxWidth: '450px', float: 'none', margin: '10px auto' })}>
                                <button onClick={e => clickHandler(e, this.updateItem)} className='btn btn-primary'>{t('Global:update')}</button>
                                {canRemove ? <button onClick={e => clickHandler(e, this.removeItem)} className='btn btn-danger'>{t('Global:remove')}</button> : null}
                                <button onClick={e => clickHandler(e, this.props.cancel)} className='btn btn-basic'>{t('Global:cancel')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderLinkedItems = () => {
        const { linkedItemQuantities } = this.state;
        const { canUpdate } = this.props;

        if (linkedItemQuantities.length < 1) return null;

        const inlineControlStyle = ({ minHeight: '10px', margin: '0 6px', maxWidth: '90px', display: 'inline-block' });

        const updateQuantity = (item: LinkedItem, val: number) => this.setState(s => this.updateQuantity(s.quantity, s.productId, s.productPriceId, s.unitPriceText, s.linkedItemQuantities.map(q => q.billItemKey === item.billItemKey ? { ...q, quantity: val } : q)))

        return linkedItemQuantities.map(i => <div key={i.billItemKey} className='row'>
            <div className='col-xs-4 text-right'>
                <button onClick={e => clickHandler(e, () => updateQuantity(i, i.quantity - 1))} className='btn btn-primary'>-</button>
                <ct.IntNumericText
                    id='quantity'
                    labelKey=''
                    placeholderKey=''
                    value={ct.asFormValue('quantity', i.quantity)} min={1} callback={val => updateQuantity(i, val)}
                    style={inlineControlStyle}
                    disabled={!canUpdate} />
                <button onClick={e => clickHandler(e, () => updateQuantity(i, i.quantity + 1))} className='btn btn-primary'>+</button>
            </div>
            <div className='col-xs-8' style={{ fontSize: '18px'}}>
                {i.customerCategoryName}
            </div>
        </div>)
    }

    renderPrice = () => {
        const { products, customerTags, item, canUpdate } = this.props;
        const { unitPriceText, quantity, productId, productPriceId } = this.state;
        const { t } = this.context;
                
        const product = products.find(p => p.id === productId);
        const activePrice = product ? product.pricing.filter(p => p.effectiveFrom <= new Date() && (p.effectiveTo == null || p.effectiveTo >= new Date()))[0] : null;

        let canChangePrice = true;
        let priceSelection: JSX.Element | null = null;

        if (product) {
            if (product.type === ProductType.Voucher && (!activePrice || product.voucherRedemptionAmount != activePrice.unitPrice)) {
                canChangePrice = false
            }
            else if (product.tags.length > 0) {
                const price = product.pricing.find(t => t.id === productPriceId);
                if (price && price.isOverride) {
                    canChangePrice = false;
                }

                const sortedPrices = getPrices(product, this.state.quantity, item.createDateTimeInLocalTime, item.reservationStartTime, customerTags).sort(sortPrices);
                const priceOptions = sortedPrices.map(p => ({ key: p.productPrice.id, name: `${t('Global:currencySymbol')}${p.unitPrice.toFixed(2)}` }));

                const renderPrice = (o: ct.SelectOption) => {
                    const pr = sortedPrices.find(p => p.productPrice.id === o.key);
                    if (!pr) return o.name;

                    let elements = [<span>{o.name}</span>]

                    if (pr.isActive) elements.push(<span className='label label-success' style={({ marginLeft: '6px' })}>{t('Global:active')}</span>);
                    if (!isNullOrEmpty(pr.tagId)) {
                        elements.push(<span className='label tag-label' style={({ backgroundColor: pr.tagColour, marginLeft: '6px' })}>{pr.tagName}</span>);
                    } else if (pr.isOverride) {
                        elements.push(<span className='label label-warning' style={({ marginLeft: '6px' })}>{t('Global:override')}</span>);
                    }

                    return elements;
                }

                if (priceOptions.length > 1) {
                    priceSelection = <ct.Select id='price' labelKey='Global:price' value={ct.asFormValue('price', productPriceId || '')} callback={val => this.productPriceChanged(val)} options={priceOptions} renderOption={renderPrice} disabled={!canUpdate} />
                }
            }
        }

        return <div style={({ maxWidth: '250px', float: 'none', margin: '0 auto' })}>
            {priceSelection}
            <ct.TextBox id='unitPrice' labelKey={item.pricingMode === ProductPricingMode.Fixed ? 'Global:fixedPrice' : 'Global:unitPrice'} placeholderKey='' value={ct.asFormValue('unitPrice', unitPriceText)} minimal={true} callback={this.setUnitPrice} disabled={!canChangePrice || !canUpdate} />
            {product && product.type === ProductType.Voucher && product.voucherRedemptionAmount && !isNaN(parseFloat(unitPriceText)) ? <div><label>{t('BillItemForm:voucherAmount')}</label> {`${t('Global:currencySymbol')}${this.calculateVoucherAmount(product.voucherRedemptionAmount, activePrice ? activePrice.unitPrice : 0, parseFloat(unitPriceText), quantity).toFixed(2)}`}</div> : null}
            {canChangePrice ? <NumericPad clearValue={canUpdate ? this.clearUnitPrice : () => { }} valueChanged={canUpdate ? this.onUnitPriceChanged : val => { } } /> : null}
        </div>
    }

    calculateVoucherAmount = (redeptionAmount: number, baseUnitPrice: number, unitPrice: number, quantity: number) => {
        return (redeptionAmount === baseUnitPrice ? unitPrice : redeptionAmount) * quantity;
    }
}

