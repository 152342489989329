
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../../../../store';

import * as LeaderboardActions from '../../../../store/pages/leaderboards/actions';
import * as ModalActions from '../../../../store/global/modal/actions';
import * as LoginActions from '../../../../store/pages/login/actions';

import { Leaderboard } from '../../../../store/pages/leaderboards/types';
import LeaderboardForm from './leaderboardForm';
import Loading from '../../../global/loading';
import { clickHandler } from '../../../../utils/util';
import { Interval, IntervalUnit } from '../../../../store/global/types';
import { DateFormat } from '../../../../store/pages/venues/types';
import ViewLeaderboard from './viewLeaderboard';

interface LocalProps {
    venueId: string;
}

interface LocalState {
    leaderboards: Leaderboard[];
    isLoading: boolean;
    dateFormat: DateFormat;
}

interface Actions {
    editLeaderboard: () => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
    logout: () => void;
}

type LeaderboardListProps = LocalState & Actions & LocalProps;

interface LeaderboardListState {
    showArchived: boolean;
}

class LeaderboardList extends React.Component<LeaderboardListProps, LeaderboardListState> {

    constructor(props: LeaderboardListProps) {
        super(props);

        this.state = { showArchived: false }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private editLeaderboard = (leaderboard: Leaderboard) => {
        this.showOverlay(leaderboard, false);
    }

    private addLeaderboard = () => {
        this.showOverlay(null, true);
    }

    viewLeaderbaord = (leaderboardId: string) => {
        const { showModal, closeModal, venueId, dateFormat, logout } = this.props;
        showModal(<ViewLeaderboard venueId={venueId} dateFormat={dateFormat} leaderboardId={leaderboardId} close={closeModal} logout={logout} />, 'ViewLeaderboard');
    }

    private showOverlay = (leaderboard: Leaderboard | null, addingNew: boolean) => {
        const { editLeaderboard, showModal, venueId } = this.props;
        editLeaderboard();
        showModal(<LeaderboardForm isNew={addingNew} venueId={venueId} leaderboard={leaderboard} />, 'LeaderboardForm');
    }
    
    isLoading = () => this.props.isLoading;

    render() {
        const { showArchived } = this.state;
        const { t } = this.context;
        const body = this.isLoading() ? <Loading /> : this.renderLeaderboardList();

        return <section className='venue-item-list'>
            <header className='section-header'>
                <div className='section-actions'>
                    <label>
                        <input type='checkbox' checked={showArchived} onChange={e => this.setState({ showArchived: e.currentTarget.checked })} />
                        <span style={({ marginLeft: '10px' })}>{t('Global:showArchived')}</span>
                    </label>
                    <button className='btn btn-info' onClick={e => clickHandler(e, this.addLeaderboard)}>{t('LeaderboardList:addLeaderboard')}</button>
                </div>
            </header>
            <div className='at-panel sub-section-panel-240px'>
                {body}
            </div>
        </section>;
    }

    renderInterval = (dt: Date | null, interval: Interval | null, dateFormat: DateFormat) => {
        const { t } = this.context;
        if (dt) {
            return dt.toAbbrDateString(dateFormat, t);
        } else if (interval) {
            return `${interval.value} ${t('IntervalUnit:' + IntervalUnit[interval.unit])}`
        }
    }

    private renderLeaderboardList = () => {
        const { t } = this.context;
        const { showArchived } = this.state;
        const { venueId, leaderboards, dateFormat } = this.props;

        const filteredLeaderboards = leaderboards.filter(l => l.venueId === venueId && (showArchived || !l.archived))
            .sort((l1, l2) => l1.sequence === l2.sequence ? l1.name.localeCompare(l2.name) : l1.sequence - l2.sequence)
            .map(l => {
                return (
                    <tr key={l.id}>
                        <td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.editLeaderboard(l))}>{l.name}</button></td>
                        <td>{l.sequence}</td>
                        <td>{t(`Global:${l.isPublic ? 'yes' : 'no'}`)}</td>
                        <td>{l.trackName}</td>
                        <td>{l.kartTypeFilter}</td>
                        <td>{l.sessionTypeFilter}</td>
                        <td>{this.renderInterval(l.fromDate, l.fromInterval, dateFormat)}</td>
                        <td>{this.renderInterval(l.toDate, l.toInterval, dateFormat)}</td>
                        <td><button className='btn btn-primary' onClick={e => clickHandler(e, () => this.viewLeaderbaord(l.id)) }>{t('Global:view')}</button></td>
                    </tr>
                );
        });

        return (
            <table className='table table-condensed'>
                <thead>
                    <tr>
                        <th>{this.context.t('LeaderboardList:nameHeading')}</th>
                        <th>{this.context.t('LeaderboardList:sequenceHeading')}</th>
                        <th>{this.context.t('LeaderboardList:publicHeading')}</th>
                        <th>{this.context.t('LeaderboardList:trackNameHeading')}</th>
                        <th>{this.context.t('LeaderboardList:kartTypeHeading')}</th>
                        <th>{this.context.t('LeaderboardList:sessionTypeHeading')}</th>
                        <th>{this.context.t('LeaderboardList:fromHeading')}</th>
                        <th>{this.context.t('LeaderboardList:toHeading')}</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredLeaderboards}
                </tbody>
            </table>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    leaderboards: state.leaderboards.leaderboards,
    isLoading: state.leaderboards.isLoading,
    dateFormat: state.venues.dateFormat
})

const mapDispatchToProps = (dispatch: Dispatch) => (
    {
        showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
        closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
        editLeaderboard: bindActionCreators(LeaderboardActions.actionCreators.editLeaderboard, dispatch),
        logout: bindActionCreators(LoginActions.actionCreators.logout, dispatch),
    }
);


// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps        // Selects which action creators are merged into the component's props
)(LeaderboardList);

