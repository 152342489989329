
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as api from '../../../../store/apiClient';
import { parseLocalDateTime } from '../../../../utils/util';
import { AnalyticsBucketResolution, AnalyticsPeriod, ComparisonPeriod, RevenueReportSettings, AnalyticsComparisonPeriodSelection, AnalyticsPeriodSelection, AnalyticsReport, ReportSettings, defaultRevenueReportSettings } from '../../../../store/pages/analytics/types';
import RevenueReportPageHeader from './revenueReportPageHeader';
import RevenueReportContent from './revenueReportContent';
import { Venue } from '../../../../store/pages/venues/types';
import { VenueRevenueStats, colours, comparisonColours } from '../types';
import { stringComparer } from '../../../../utils/comparers';

interface IRevnueAnalyticsRespone {
    bucketResolution: AnalyticsBucketResolution;
    venues: VenueRevenueStats[];
}

interface RevenueReportPageProps {
    venue: Venue;
    report: AnalyticsReport;
    logout: () => void;
    save: (reportId: number | null, period: AnalyticsPeriod, comparisonPeriod: ComparisonPeriod, deselectedVenues: string[], settings: any) => void;
}

interface RevenueReportPageState {
    period: AnalyticsPeriod;
    comparisonPeriod: ComparisonPeriod;
    loading: boolean;
    hasResult: boolean;
    hasUnappliedChanges: boolean;
    bucketResolution: AnalyticsBucketResolution;
    settings: RevenueReportSettings;
    deselectedVenues: string[];
    venues: VenueRevenueStats[];
    error: api.ApiError | null;
}

function isRevenueReportSetting(item: ReportSettings): item is RevenueReportSettings {
    return item.type === 'revenue-report';
}

class RevenueReportPage extends React.Component<RevenueReportPageProps, RevenueReportPageState> {
    
    constructor(props: RevenueReportPageProps) {
        super(props);

        this.state = this.buildStateFromProps(props);
    }

    buildStateFromProps = (props: RevenueReportPageProps) => {
        const { report } = props;
        const settings = isRevenueReportSetting(report.settings) ? report.settings : defaultRevenueReportSettings;

        return {
            period: report.period,
            comparisonPeriod: report.comparisonPeriod,
            loading: false,
            hasResult: false,
            hasUnappliedChanges: false,
            bucketResolution: AnalyticsBucketResolution.Day,
            settings: settings,
            venues: [],
            deselectedVenues: report.deselectedVenues,
            error: null,
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidUpdate(prevProps: RevenueReportPageProps) {
        const hasChanges = prevProps.report.period.period !== this.props.report.period.period
            || prevProps.report.period.from !== this.props.report.period.from
            || prevProps.report.period.to !== this.props.report.period.to
            || prevProps.report.comparisonPeriod.period !== this.props.report.comparisonPeriod.period
            || prevProps.report.comparisonPeriod.from !== this.props.report.comparisonPeriod.from
            || prevProps.report.comparisonPeriod.to !== this.props.report.comparisonPeriod.to
            || prevProps.report.settings !== this.props.report.settings
            || prevProps.report.deselectedVenues != this.props.report.deselectedVenues;

        if (hasChanges) {
            this.setState(this.buildStateFromProps(this.props), this.refresh);
        }
    }

    componentDidMount() {
        this.refresh();
    }

    refresh = () => {
        const { logout } = this.props;
        const { period, comparisonPeriod } = this.state;
        this.setState({ loading: true }, () => {
            let uri = `api/v1/analytics/revenue?from=${period.from.toApiDateOnlyString()}&to=${period.to.toApiDateOnlyString()}`;
            if (comparisonPeriod.period !== AnalyticsComparisonPeriodSelection.None) {
                uri = uri + `&comparisonFrom=${comparisonPeriod.from.toApiDateOnlyString()}&comparisonTo=${comparisonPeriod.to.toApiDateOnlyString()}`;
            }
            return api.getWithAuth<IRevnueAnalyticsRespone>(uri, logout)
                .subscribe(res => {
                    const { bucketResolution, venues } = res;
                    this.setState({
                        loading: false,
                        hasResult: true,
                        hasUnappliedChanges: false,
                        bucketResolution: bucketResolution,
                        venues: venues
                            .sort((v1, v2) => stringComparer(v1.venueName, v2.venueName))
                            .map((v, ix) => ({
                                ...v,
                                colour: colours[ix],
                                comparisonColour: comparisonColours[ix],
                                thisPeriodStats: {
                                    ...v.thisPeriodStats,
                                    periodBuckets: v.thisPeriodStats.periodBuckets
                                        .map(b => ({ ...b, date: parseLocalDateTime(b.date) }))
                                        .sort((b1, b2) => b1.date.getTime() - b2.date.getTime())
                                },
                                comparisonPeriodStats: {
                                    ...v.comparisonPeriodStats,
                                    periodBuckets: v.comparisonPeriodStats.periodBuckets
                                        .map(b => ({ ...b, date: parseLocalDateTime(b.date) }))
                                        .sort((b1, b2) => b1.date.getTime() - b2.date.getTime())
                                },
                            })),
                        error: null
                    });
                }, err => this.setState({ loading: false, hasResult: false, error: err }))
        });
    }

    onPeriodChanged = (period: AnalyticsPeriod) => {
        this.setState((s, props) => ({
            period: period,
            hasUnappliedChanges: true,
            comparisonPeriod: s.comparisonPeriod.period === AnalyticsComparisonPeriodSelection.Custom || s.comparisonPeriod.period === AnalyticsComparisonPeriodSelection.None
                ? s.comparisonPeriod
                : ComparisonPeriod.create(period, s.comparisonPeriod.period)
        }));
    }

    toggleVenueSelection = (venueId: string, selected: boolean) => this.setState(s => ({ deselectedVenues: selected ? s.deselectedVenues.filter(v => v !== venueId) : s.deselectedVenues.concat([venueId]) }))

    render() {
        const { venue, report, save } = this.props;
        const { dateFormat, firstDayOfWeek } = venue;
        const { period, comparisonPeriod, loading, hasResult, bucketResolution, settings, venues, deselectedVenues, hasUnappliedChanges, error } = this.state;

        return <section className='analytics-report-content'>
            <RevenueReportPageHeader
                name={report.name}
                period={period}
                comparisonPeriod={comparisonPeriod}
                firstDayOfWeek={firstDayOfWeek}
                dateFormat={dateFormat}
                hasUnappliedChanges={hasUnappliedChanges}
                updatePeriod={this.onPeriodChanged}
                updateComparisonPeriod={p => this.setState({ comparisonPeriod: p, hasUnappliedChanges: true })}
                refreshReport={this.refresh}
                save={() => save(report.id, period, comparisonPeriod, deselectedVenues, settings)} />
            <div className='analytics-report-content-data'>
                <RevenueReportContent
                    loading={loading}
                    hasResult={hasResult}
                    bucketResolution={bucketResolution}
                    settings={settings}
                    updateSettings={newSettings => this.setState({ settings: newSettings })}
                    showComparison={comparisonPeriod.period !== AnalyticsComparisonPeriodSelection.None}
                    venues={venues}
                    deselectedVenues={deselectedVenues}
                    toggleVenueSelection={this.toggleVenueSelection}
                    dateFormat={dateFormat}
                    error={error}
                />
            </div>
        </section>
    }
}

export default RevenueReportPage