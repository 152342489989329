import * as React from 'react';
import * as PropTypes from 'prop-types'

import { ActivityFormatProduct } from '../diary/helpers';
import { clickHandler, isNullOrEmpty } from '../../../utils/util';
import { ITranslationContext } from '../../../translations';
import { TimeFormat } from '../../../store/pages/venues/types';

interface ProductsPanelProps {
    categoryName: string;
    products: ActivityFormatProduct[];
    timeFormat: TimeFormat;
    customerSelectionRequired: boolean;
    productSelected: (productId: string, customerCategoryId: string | null, quantity: number, reservationId: string | null) => void;
}

interface CategoryProducts {
    categoryName: string;
    products: ActivityFormatProduct[];
}

const ProductsPanel = (props: ProductsPanelProps, context: ITranslationContext) => {
    const { products, timeFormat } = props;
    const { t } = context;

    const selectProduct = (product: ActivityFormatProduct, quantity: number) => {
        // Prevent selection if customer selection is required
        if (!props.customerSelectionRequired) {
            props.productSelected(product.product.id, product.customerCategoryId, quantity, product.reservationId);
        }
    }

    const productsByCategory = products.filter(p => !p.product || !p.product.archived).reduce<CategoryProducts[]>((acc, p) => {
        const catName = p.customerCategoryName || '';
        let cat = acc.find(c => c.categoryName === catName);
        if (!cat) {
            cat = { categoryName: catName, products: [] };
            acc.push(cat);
        }

        cat.products.push(p);
        return acc;
    }, [])

    const renderHeading = productsByCategory.length > 1;

    return (
        <>
            {props.customerSelectionRequired ? <div className='alert alert-warning'>{t('ProductsPanel:customerReuired', {categoryName: props.categoryName})}</div> : null}
            {
                productsByCategory.map(c => (
                    <div key={c.categoryName}>
                        {renderHeading && !isNullOrEmpty(c.categoryName) ? <h3>{t('ProductsPanel:customerCategoryHeading', { categoryName: c.categoryName })}</h3> : null}
                        <ul key={`${c.categoryName}_products`} className='list-unstyled pos_product_list'>
                            {c.products.map(p => {
                                const style = p.product.productImageUrl && !isNullOrEmpty(p.product.productImageUrl) ? { backgroundImage: `url(${p.product.productImageUrl}`, backgroundPosition: 'center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' } : { }
                                return <li
                                    className='pos_product_item'
                                    key={p.key}
                                    onClick={e => clickHandler(e, () => selectProduct(p, 1))}
                                    style={style}>
                                    <div className='pos_product_item_text'>{p.reservationTime ? p.reservationTime.toShortTimeString(timeFormat) : null}  {p.displayName}</div>
                                </li>
                            })}
                        </ul>
                    </div>
                ))
            }
        </>
    )
}

ProductsPanel.contextTypes = {
    t: PropTypes.func
}

export default ProductsPanel;