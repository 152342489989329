
import * as React from 'react';
import * as PropTypes from 'prop-types'
import { ITranslationContext } from '../../translations';

const Loading = (props: any, context: ITranslationContext) => (<div>{context.t('Global:loading')}</div>);

Loading.contextTypes = {
    t: PropTypes.func
}

export default Loading;