
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { Tag } from '../../store/pages/tags/types';
import TagListItem from './tagListItem';
import { ITranslationContext } from '../../translations';

interface TagListProps {
    tags: Tag[];
    className?: string;
    showNoTagsMessage?: boolean;
    removeTag?: (tagId: string) => void;
}

const TagList = (props: TagListProps, context: ITranslationContext) => props.tags.length === 0
    ? props.showNoTagsMessage ? <div key='__no_tags__'>{context.t('Global:noTags')}</div> : null
    : <ul key='tag-list' className={`list-unstyled tag-list ${(props.className ? props.className : '')}`}> {dedupeTags(props.tags || []).map(t => <TagListItem key={t.membershipId ? `${t.id}_${t.membershipId}` : t.id} tag={t} removeTag={props.removeTag} />)}</ul>

TagList.contextTypes = {
    t: PropTypes.func
}


export default TagList;

const dedupeTags = (tags: Tag[]) => tags.reduce<Tag[]>((uniqueTags, t) =>
{
    if (uniqueTags.findIndex(ut => ut.id === t.id && t.membershipId === ut.membershipId) < 0)
        uniqueTags.push(t);
    return uniqueTags;
}, [])