
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import * as api from '../../../store/apiClient';

import { clickHandler } from '../../../utils/util';
import { ValidationError } from '../../../store/global/types';
import ValidationSummary from '../../global/validationSummary';

interface ConfirmVoidItemProps {
    headerKey: string;
    confirmButtonKey: string;
    saving: boolean;
    saveError: api.ApiError | null;
    saveValidationErrors: ValidationError[];
    confirm: (reason: string) => void;
    close: () => void;
}

interface ConfirmVoidItemState {
    voidReason: ct.FormValue<string>;
}

export default class ConfirmVoidItem extends React.Component<ConfirmVoidItemProps, ConfirmVoidItemState> {

    constructor(props: ConfirmVoidItemProps) {
        super(props);

        this.state = {
            voidReason: this.validateVoidReason(''),
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    validateVoidReason = (val: string) => {
        return v.validate(val, 'voidReason', [v.required], []);
    }

    confirmVoidPayment = () => {
        const { voidReason } = this.state;
        const { confirm } = this.props;

        if (!voidReason.isValid) {
            // TODO: Show error message!
        } else {
            confirm(voidReason.value);
        }
    }

    render() {
        const { headerKey, confirmButtonKey, close, saving, saveError, saveValidationErrors } = this.props;
        const { voidReason } = this.state;
        const { t } = this.context;

        return (
            <div className='overlay'>
                <div className='overlay-content'>
                    <div style={({ maxWidth: '500px', margin: '0 auto' })}>
                        <div className='row' style={({ marginTop: '80px' })}>
                            <div className='col-xs-12 text-center'>
                                <span style={({ fontSize: '22px' })}>{t(headerKey)}</span>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12'>
                                <ct.TextBox id='voidReason' labelKey='PointOfSale:voidReason' placeholderKey='PointOfSale:voidReason' value={voidReason} callback={val => this.setState({ voidReason: this.validateVoidReason(val) })} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <ValidationSummary error={saveError} keyPrefix='PointOfSale' validationMessages={saveValidationErrors} t={t} />
                            </div>
                        </div>
                        <div className='row' style={({ marginTop: '50px' })}>
                            <div className='form-group col-sm-12 button-panel'>
                                <button className='btn btn-danger' disabled={saving} onClick={e => clickHandler(e, this.confirmVoidPayment)}>{t(confirmButtonKey)}</button>
                                <button onClick={e => clickHandler(e, close)} disabled={saving} className='btn btn-basic'>{t('Global:cancel')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

