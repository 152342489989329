
//import '../../../css/autocomplete.css';

import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';
import * as v from '../../global/validation';

import { isNullOrEmpty, clickHandler } from '../../../utils/util';

import { ApplicationState } from '../../../store';
import * as DiaryActions from '../../../store/pages/diary/actions';

import { CustomerSearchResult } from '../../../store/pages/diary/types';
import TagList from '../../global/tagList';
import { DateFormat } from '../../../store/pages/venues/types';

interface LocalProps {
    customerSelected: (customer: CustomerSearchResult) => void;
    createCustomer: () => void;
    cancel: () => void;
}

interface LocalState {
    customerSearchResults: CustomerSearchResult[];
    searchingCustomers: boolean;
    searchMessageKey: string;
    lastSearchTerm: string | null;
    dateFormat: DateFormat;
}

interface Actions {
    searchCustomers: (search: string) => void;
}

type CustomerSearchProps = LocalState & Actions & LocalProps;

interface CustomerSearchState {
    searchText: ct.FormValue<string>;
    searchDelayed: boolean;
}

class CustomerSearch extends React.Component<CustomerSearchProps, CustomerSearchState> {

    constructor(props: CustomerSearchProps) {
        super(props);

        this.state = { searchText: this.validateSearchText(''), searchDelayed: false};
    }

    static contextTypes = {
        t: PropTypes.func
    }

    validateSearchText = (val: string) => v.validate(val, 'searchText', [], []);

    customerSelected = (e: React.MouseEvent<HTMLTableRowElement>, val: CustomerSearchResult) => {
        e.preventDefault();

        this.props.customerSelected(val);
    }

    searchCustomers = (search: string) => {
        if (this.props.searchingCustomers) {
            this.setState({ searchDelayed: true, searchText: this.validateSearchText(search) })
        } else {
            this.props.searchCustomers(search);
            this.setState({ searchText: this.validateSearchText(search) });
        }
    }

    componentDidUpdate(prevProps: CustomerSearchProps) {
        const { searchDelayed, searchText } = this.state;
        const { searchingCustomers, lastSearchTerm } = this.props;

        if (prevProps.searchingCustomers
            && !searchingCustomers
            && searchDelayed
            && searchText.isValid
            && lastSearchTerm !== searchText.value) {
            this.setState({ searchDelayed: false }, () => this.searchCustomers(searchText.value))
        }
    }

    componentDidMount() {
        this.searchCustomers('');
    }

    render() {
        return (
            <div style={({ display: 'flex', flexDirection: 'column', flex: '1 1 auto' })}>
                <div className='row' style={({ flex: '0 0 auto' })}>
                    <div className='col-md-12'>
                        <ct.TextBox id='searchText' labelKey='CustomerSearch:search' placeholderKey='CustomerSearch:search' value={this.state.searchText} callback={val => this.searchCustomers(val)} autoComplete='off' />
                    </div>
                </div>
                <div className='row' style={({ flex: '1 1 auto', overflow: 'auto' })}>
                    <div className='col-md-12'>
                        {this.renderResults(this.props.customerSearchResults, this.props.searchingCustomers, this.state.searchText.value)}
                    </div>
                </div>
                <div className='row' style={({ flex: '0 0 auto' })}>
                    <div className='col-md-12 btn-toolbar'>
                        <button className='btn btn-success' onClick={e => clickHandler(e, this.props.createCustomer)}>{this.context.t('CustomerSearch:newCustomer')}</button>
                        <button className='btn btn-basic' onClick={e => clickHandler(e, this.props.cancel)}>{this.context.t('Global:cancel')}</button>
                    </div>
                </div>
            </div>);
    }

    renderResults = (searchResults: CustomerSearchResult[], searchingCustomers: boolean, searchText: string) => {

        const { t } = this.context;

        const tableData = this.props.customerSearchResults.length === 0
            ? this.renderNoResults(searchingCustomers, searchText, this.props.searchMessageKey)
            : searchResults.map(c => this.renderCustomerRow(c));

        return (
            <div className='customer-search-results-wrapper'>
                <table className='table table-hover customer-search-table table-condensed'>
                    <thead>
                        <tr key='customer-search-header'>
                            <th>{t('CustomerSearch:nameHeading')}</th>
                            <th>{t('CustomerSearch:emailHeading')}</th>
                            <th>{t('CustomerSearch:phoneHeading')}</th>
                            <th>{t('CustomerSearch:postcodeHeading')}</th>
                            <th>{t('CustomerSearch:lastEventHeading')}</th>
                            <th>{t('CustomerSearch:companyHeading')}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData}
                    </tbody>
                </table>
            </div>
        );
    }

    formatCustomer = (val: CustomerSearchResult | null) => val ? `${val.firstname} ${val.lastname}` : '';

    renderNoResults = (searchingCustomers: boolean, searchText: string, messageOverride: string) => {
        const { t } = this.context;

        const msg = searchingCustomers
            ? <div className='alert alert-info'>{t('CustomerSearch:searching')}</div>
            : !isNullOrEmpty(messageOverride)
                ? <div className='alert alert-info'>{t(messageOverride)}</div>
                : isNullOrEmpty(searchText)
                ? <div className='alert alert-info'>{t('CustomerSearch:enterSearch')}</div>
                : <div className='alert alert-warning'>{t('CustomerSearch:noCustomersFound')}</div>;

        return (
            <tr key='customer-search-message'>
                <td colSpan={5}>{msg}</td>
            </tr>
        );
    }

    renderCustomerRow = (customer: CustomerSearchResult) => {
        return (
            <tr key={customer.customerId} onClick={e => this.customerSelected(e, customer)}>
                <td>{this.formatCustomer(customer)}</td>
                <td>{customer.emailAddress}</td>
                <td>{customer.phoneNumber}</td>
                <td>{customer.postalCode}</td>
                <td>{customer.lastBooking ? <div style={{ marginBottom: '2px' }}>{customer.lastBooking.date.toAbbrDateString(this.props.dateFormat, this.context.t)} {isNullOrEmpty(customer.lastBooking.venueName) ? null : <span className='label label-default'>{customer.lastBooking.venueName}</span>}</div> : ''}</td>
                <td>{customer.companyName}</td>
                <td><TagList tags={customer.tags} /></td>
            </tr>
        );
    }
}


const mapStateToProps = (state: ApplicationState) => ({
    customerSearchResults: state.diary.customerSearchResults,
    searchMessageKey: state.diary.searchMessageKey,
    searchingCustomers: state.diary.searchingCustomers,
    lastSearchTerm: state.diary.lastSearchTerm,
    dateFormat: state.venues.dateFormat
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    searchCustomers: bindActionCreators(DiaryActions.actionCreators.searchCustomers, dispatch),
});


// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(CustomerSearch);