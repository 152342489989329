
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';
import * as v from '../../global/validation';

import { ClientEmailTemplate } from '../../../store/pages/emailTemplates/types';
import { clickHandler, isNullOrEmpty } from '../../../utils/util';

interface SendEmailOverlayProps {
    customerId: string;
    firstname: string;
    lastname: string;
    emailAddress: string;
    phoneNumber: string;
    emailTemplates: ClientEmailTemplate[];
    close: () => void;
    sendEmail: (clientEmailTemplate: ClientEmailTemplate, toEmailAddress: string, customerId: string | null, emailSent: (error: string | null) => void) => void;
}

interface SendEmailOverlayState {
    emailTemplateId: ct.FormValue<string>;
    emailAddress: ct.FormValue<string>;
    error: string | null;
}


export default class SendEmailOverlay extends React.Component<SendEmailOverlayProps, SendEmailOverlayState> {

    constructor(props: SendEmailOverlayProps) {
        super(props);

        this.state = {
            emailTemplateId: this.validateEmailTemplateId(''),
            emailAddress: this.validateEmailAddress(props.emailAddress),
            error: null
        }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    validateEmailTemplateId = (val: string) => v.validate(val, 'emailTemplateId', [v.required], []);
    validateEmailAddress = (val: string) => v.validate(val, 'emailAddress', [v.required], []);

    sendEmail = () => {
        const { customerId, emailTemplates, sendEmail, close } = this.props;
        const { emailTemplateId, emailAddress } = this.state;

        if (!v.isValid(this.state)) {
            this.setState({ error: 'Global:formNotValid' });
            return;
        }

        const et = emailTemplates.find(t => t.clientEmailTemplateId === emailTemplateId.value);
        if (et) {
            sendEmail(et, emailAddress.value, customerId, e => isNullOrEmpty(e) ? close() : this.setState({ error: e }))
        } else {
            this.setState({ error: 'BookingCommunications:selectEmailTemplate' });
        }
    }

    render() {
        const { t } = this.context;
        const { emailTemplates } = this.props;
        const { emailTemplateId, emailAddress, error } = this.state;

        const clientEmailTemplateOptions = emailTemplates.map(et => ({ key: et.clientEmailTemplateId || '', name: et.name || t(`EmailTemplateType:${et.templateType}`) }))

        return <div>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='section-heading'>{t('BookingCommunications:communications')}</div>

                    <div className='row'>
                        <div className='col-xs-12'>
                            <ct.Select id='clientEmailTemplateId' labelKey='BookingCommunications:emailType' value={emailTemplateId} callback={val => this.setState({ emailTemplateId: this.validateEmailTemplateId(val) })} options={clientEmailTemplateOptions} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-xs-12'>
                            <ct.TextBox id='emailAddress' labelKey='BookingCommunications:emailAddress' value={emailAddress} callback={val => this.setState({ emailAddress: this.validateEmailAddress(val) })} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-xs-12'>
                            <button className='btn btn-primary pull-right' onClick={e => clickHandler(e, this.sendEmail)}>{t('BookingCommunications:sendEmail')}</button>
                        </div>
                    </div>

                    {!isNullOrEmpty(error) ? <div className='alert alert-danger'>{t(error)}</div> : null}
                </div>
            </div>
        </div>
    }
}