import { FindCustomerSummary, Gender, MarketingPreference } from "./types";
import * as api from '../../../store/apiClient';
import { PaymentStatus } from "../pointOfSale/types";
import { EmailStatus } from "../emails/types";
import { AuditTrailEntry, CustomFieldValue } from "../diary/types";
import { Tag } from "../tags/types";
import { Observable } from "rxjs";

export interface IFindCustomersResponse {
    customers: FindCustomerSummary[];
    page: number;
    maxPage: number;
}

export interface IGetCustomerDetailsResponse {
    history: CustomerHistory;
}

export interface Customer {
    firstname: string;
    lastname: string;
    companyName: string;
    nickname: string | null;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    addressLine4: string;
    town: string;
    county: string;
    countryId: number;
    postalCode: string;
    emailAddress: string;
    phoneNumber: string;
    gender: Gender;
    marketingPreference: MarketingPreference;
    publicResultsConsent: boolean;
    emergencyContactName: string;
    emergencyContactNumber: string;
    tags: Tag[];
    notes: string;
    birthYear?: number;
    birthMonth?: number;
    birthDay?: number;
}

export interface CustomerHistory extends Customer {
    primaryCustomerId: string;
    birthDay: number;
    birthMonth: number;
    birthYear: number;
    lastEmailMarketingChange: Date | null;
    photographUrl: string | null;
    creditAmount: number;
    resultsPreference: MarketingPreference;
    publicResultsConsent: boolean;
    createDateTime: Date;
    createdBy: string;
    events: CustomerEvent[];
    registrations: CustomerRegistration[];
    payments: CustomerPayment[];
    emails: CustomerEmail[];
    sessionResults: CustomerSessionResult[];
    bills: CustomerBill[]
    memberships: CustomerMembership[];
    tags: Tag[];
    auditLog: AuditTrailEntry[];
    mergeHistory: CustomerHistoryMerge[];
}

export interface CustomerMembership {
    membershipid: string;
    customerId: string;
    membershipNumber: string;
    membershipType: string;
    expiry: Date | null;
    isActive: boolean;
    tagColour: string;
    tagName: string;
    createDateTime: Date;
}

export interface CustomerEvent {
    eventId: string;
    venueId: string;
    customerId: string;
    venueName: string;
    eventName: string;
    eventDate: Date;
    wasCancelled: boolean;
    wasDeleted: boolean;
    isOrganiser: boolean;
    bookingId: string;
    billNumber: string;
    billAmount: number | null;
    registrationId: string | null;
    registrationTime: Date | null;
}

export interface CustomerRegistration {
    registrationId: string;
    customerId: string;
    registrationTime: Date;
    eventId: string | null;
    eventName: string | null;
    eventDate: Date | null;
    cancelled: boolean;
    isWebRegistration: boolean;
    kioskName: string;
    clientIpAddress: string;
    customFields: CustomFieldValue[];
}

export interface CustomerSessionResult {
    customerEventSessionResultId: string;
    customerId: string;
    sessionStartTime: Date | null;
    sessionName: string;
    trackName: string;
    position: number;
    bestLapTime: number;
    averageLapTime: number;
    numberOfLaps: number;
    initialKartNumber: string;
    laps: CustomerHistoryResultLap[];
}

export interface CustomerHistoryResultLap {
    lapNubmer: number;
    lapTime: number;
    sectorTimes: number[];
}

export interface CustomerPayment {
    paymentId: string;
    customerId: string;
    amount: number;
    description: string;
    billNumber: string;
    status: PaymentStatus;
    scheduled: boolean;
    paid: boolean;
    paymentMethod: string | null;
    paymentDueDate: Date | null;
    payentTakenDateTime: Date | null;
    paymentDeviceName: string | null;
    paymentTakenBy: string | null;
    deleted: boolean;
    deletedBy: string | null;
    deletedById: string | null;
    deletedDateTime: Date | null;
    void: boolean;
    voidedBy: string | null;
    voidedById: string | null;
    voidedDateTime: Date | null;
    voidReason: string | null;
    additionalInfo: string[];
}

export interface CustomerEmail {
    emailId: string;
    customerId: string;
    timeQueued: Date;
    timeSent: Date | null;
    toAddress: string;
    templateName: string;
    subject: string;
    status: EmailStatus;
}

export interface CustomerBill {
    billId: string;
    customerId: string;
    billNumber: string;
    totalAmount: number;
    totalTaxAmount: number;
    feeAmount: number;
    paidAmount: number;
    outstandingAmount: boolean;
}

export interface CustomerHistoryMerge {
    id: string;
    customerId: string;
    mergeIndex: number;
    firstname: string;
    lastname: string;
    addressLine1: string;
    postalCode: string;
    emailAddress: string;
    whenMerged: Date;
    mergedBy: string;
}

export const getCustomerDetails = (customerId: string, logout: () => void) => {
    const url = `api/v1/customer/${customerId}/history`;
    return api.getWithAuth<IGetCustomerDetailsResponse>(url, logout)
}

export const updateCustomer = (customerId: string, customer: Customer, photoImg: File | null, logout: () => void) => {
    const url = `api/v1/customer/contact/${customerId}`;
    let body = { ...customer, tags: customer.tags.map(t => t.id) };

    const imageObservables: Observable<string>[] = [];
    imageObservables.push(photoImg ? api.uploadFile(photoImg, 'api/v1/clientimage').map(res => res.response.imageId) : Observable.of(null));
    const images = Observable.forkJoin(imageObservables);

    return images.flatMap(imgIds => {
        const bodyWithImg = { ...body, photoImageId: imgIds[0] }
        return api.putWithAuth(url, bodyWithImg, logout)
    })
}

export const downloadCustomers = (name: string, marketingPreferenceFilter: null | MarketingPreference, reportName: string, venueSelection: string | null, lastVisitFrom: Date | null, lastVisitTo: Date | null, customerCreatedFrom: Date | null, customerCreatedTo: Date | null, minVisits: number | null, tagId: string | null, minAge: number | null, maxAge: number | null, bookedActivityFormatIds: string[], participantActivityFormatIds: string[], callback: (success: boolean, error: string | null) => void) => {

    let query = `api/v1/customer/download?reportName=${reportName}&name=${name}`;

    if (marketingPreferenceFilter) query = query + `&marketingPreference=${marketingPreferenceFilter}`;
    if (venueSelection) query = query + `&venueId=${venueSelection}`;
    if (lastVisitFrom) query = query + `&lastVisitFrom=${lastVisitFrom.toYMDHMDateString()}`
    if (lastVisitTo) query = query + `&lastVisitTo=${lastVisitTo.toYMDHMDateString()}`
    if (customerCreatedFrom) query = query + `&customerCreatedFrom=${customerCreatedFrom.toYMDHMDateString()}`
    if (customerCreatedTo) query = query + `&customerCreatedTo=${customerCreatedTo.toYMDHMDateString()}`
    if (minVisits) query = query + `&minVisits=${minVisits}`
    if (tagId) query = query + `&tag=${tagId}`
    if (minAge) query = query + `&minAge=${minAge}`
    if (maxAge) query = query + `&maxAge=${maxAge}`
    if (bookedActivityFormatIds.length > 0) query = query + bookedActivityFormatIds.map(f => `&baf=${f}`).join('');
    if (participantActivityFormatIds.length > 0) query = query + participantActivityFormatIds.map(f => `&paf=${f}`).join('');

    return api.downloadFile(query, `${reportName}.csv`, callback);
}
