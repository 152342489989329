
import '../../../css/quill.css';
import '../../../css/print.css';

import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'

import * as api from '../../../store/apiClient';
import { ApplicationState } from '../../../store';
import { EventRegistration } from '../../../store/pages/diary/types';
import { TermsAndConditions } from '../../../store/pages/termsAndConditions/types';
import * as TermsAndConditionsActions from '../../../store/pages/termsAndConditions/actions';
import * as DiaryActions from '../../../store/pages/diary/actions';
import { TermsAndConditionsView } from './termsAndConditions';
import { isNullOrEmpty, parseLocalDateTime, substitute } from '../../../utils/util';
import Loading from '../../global/loading';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';

interface IRegistrationsSignatures {
    registrationId: string;
    customerSignatureUrl: string;
    counterSigantorySignatureUrl: string;

}

interface RegistrationEventDetails {
    name: string;
    startTime: Date | null;
}

interface LocalProps {
    event: RegistrationEventDetails;
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
    registrationId: string;
    registration: EventRegistration | null;
    close: () => void;
    logout: () => void;
}

interface LocalState {
    termsAndConditions: TermsAndConditions[];
}

interface Actions {
    loadTermsAndConditions: (ids: string[]) => void;
}

type RegistrationDetailsProps = LocalState & Actions & LocalProps;

interface RegistrationDetailsState {
    customerSignatureUrl: string;
    counterSigantorySignatureUrl: string;
    registration: EventRegistration | null;
}

class RegistrationDetails extends React.Component<RegistrationDetailsProps, RegistrationDetailsState> {

    constructor(props: RegistrationDetailsProps) {
        super(props);

        this.state = { customerSignatureUrl: '', counterSigantorySignatureUrl: '', registration: null}
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        const { registration, registrationId } = this.props;

        if (registration) {
            this.setState({ registration: registration }, () => this.loadDetails(registration));
        } else {
            api.getWithAuth<DiaryActions.ILoadEventRegistrationResponse>(`api/v1/registration/${registrationId}`, this.props.logout)
                .subscribe(res => {
                    const registration = { ...res.registration, registrationTime: parseLocalDateTime(res.registration.registrationTime), dateOfBirth: parseLocalDateTime(res.registration.dateOfBirth) };
                    this.setState({ registration: registration }, () => this.loadDetails(registration));
                }, e => {
                        this.setState({ registration: null })
                });
        }
    }

    loadDetails = (registration: EventRegistration) => {
        const { termsAndConditions, loadTermsAndConditions } = this.props;

        var toLoad: string[] = [];
        var terms = termsAndConditions.find(t => t.id === registration.termsId);
        if (!terms) {
            toLoad.push(registration.termsId);
        }

        if (registration.counterSignatoryTermsId) {
            var counterSignatoryTerms = termsAndConditions.find(t => t.id === registration.counterSignatoryTermsId);
            if (!counterSignatoryTerms) {
                toLoad.push(registration.counterSignatoryTermsId);
            }
        }

        if (toLoad.length > 0) {
            loadTermsAndConditions(toLoad);
        }

        if (registration.hasSignature) {
            api.getWithAuth<IRegistrationsSignatures>(`api/v1/registration/${registration.registrationId}/signatures`, this.props.logout)
                .subscribe(res => {
                    this.setState({ customerSignatureUrl: res.customerSignatureUrl, counterSigantorySignatureUrl: res.counterSigantorySignatureUrl });
                });
        }
    }

    print = (e: React.MouseEvent<HTMLButtonElement>) => {
        const content = document.getElementById("registration-details-wrapper");
        const printFrame = document.getElementById("ifmcontentstoprint") as HTMLIFrameElement;

        e.preventDefault();
        e.stopPropagation();

        if (!content || !printFrame || !printFrame.contentWindow)
            return;

        let stylesheets: StyleSheet[] = [];

        for (let si = 0; si < document.styleSheets.length; si++) {
            const ss = document.styleSheets[si]
            if (ss && ss.href && ss.type) {
                stylesheets.push(ss);
            }
        }

        const styleSheetLinks = stylesheets.map(s => `<link rel="stylesheet" type="${s.type}" href="${s.href}">`).join('');

        const headstr = `<html><head><title></title>${styleSheetLinks}</head><body>`;
        const footstr = "</body>";

        const pri = printFrame.contentWindow;

        printFrame.onload = () => pri.print();
        pri.document.open();
        pri.document.write(headstr + content.innerHTML + footstr);
        pri.document.close();
        pri.focus();
    }

    formatAddress = (reg: EventRegistration) =>
        [reg.addressLine1, reg.addressLine2, reg.addressLine3, reg.addressLine4, reg.town, reg.postalCode]
            .filter(x => !isNullOrEmpty(x))
            .join(',');

    renderCounterSignatoryTerms = (terms: TermsAndConditions | undefined, counterSigantoryFirstname: string, counterSigantoryLastname: string, counterSigantorySignatureUrl: string, customerName: string, t: (val: string) => string) => {
        if (!terms) {
            return null;
        }

        return (
            <>
                <div className='row row-m-t'>
                    <div className='col-xs-12'>
                        <div><label>{t('registrationDetails:counterSignatoryTerms')}</label></div>
                        <TermsAndConditionsView terms={substitute(terms.text, new Map<string, string>([['customer_name', `${counterSigantoryFirstname} ${counterSigantoryLastname}`], ['dependents_name', customerName]]))} />
                    </div>
                </div>
                <div className='row row-m-t'>
                    <div className='col-xs-12'>
                        <div><label>{t('registrationDetails:counterSignedBy')}</label></div>
                        <div>{counterSigantoryFirstname} {counterSigantoryLastname}</div>
                    </div>
                </div>
                {!isNullOrEmpty(counterSigantorySignatureUrl) ? <div className='signature-display'><img src={counterSigantorySignatureUrl} alt='signature' /></div> : null}
            </>
        );
    }

    render() {
        const { t } = this.context;
        const { event, termsAndConditions, timeFormat, dateFormat, close } = this.props;
        const { registration, customerSignatureUrl, counterSigantorySignatureUrl } = this.state;

        if (!registration) {
            return <Loading />
        }

        const terms = termsAndConditions.find(t => t.id === registration.termsId);
        const termsDiv = terms ? <TermsAndConditionsView terms={terms.text} /> : <div>{t('Global:loading')}</div>;

        const counterSignatoryTerms = termsAndConditions.find(t => t.id === registration.counterSignatoryTermsId);

        return <div id='registration-details-wrapper'>
            <div className='row row-m-t'>
                <div className='col-xs-8'>
                    <div><label>{t('registrationDetails:eventName')}</label></div>
                    <div>{event.name}</div>
                </div>
                {
                    event.startTime 
                    ? <div className='col-xs-4'>
                         <div><label>{t('registrationDetails:eventDate')}</label></div>
                      <div>{event.startTime.toLongDateString(dateFormat, t)}</div>
                    </div>
                    : null
                }
            </div>
            <div className='row row-m-t'>
                <div className='col-xs-4'>
                    <div><label>{t('registrationDetails:customerName')}</label></div>
                    <div>{registration.firstname} {registration.lastname}</div>
                </div>
                <div className='col-xs-4'>
                    <div><label>{t('registrationDetails:customerDateOfBirth')}</label></div>
                    <div>{registration.dateOfBirth.toLongDateString(dateFormat, t)} ({registration.age})</div>
                </div>
                <div className='col-xs-4'>
                    <div><label>{t('registrationDetails:registrationTime')}</label></div>
                    <div>{registration.registrationTime.toLongDateString(dateFormat, t)} {registration.registrationTime.toShortTimeString(timeFormat)} <span className="label label-info">{registration.isWebRegistration ? t('Global:webBooking') : registration.kioskName}</span> {!isNullOrEmpty(registration.clientIpAddress) ? `(${registration.clientIpAddress})` : null}</div>
                </div>
            </div>
            <div className='row row-m-t'>
                <div className='col-xs-4'>
                    <div><label>{t('registrationDetails:emailAddress')}</label></div>
                    <div>{registration.emailAddress}</div>
                </div>
                <div className='col-xs-4'>
                    <div><label>{t('registrationDetails:phoneNumber')}</label></div>
                    <div>{registration.phoneNumber}</div>
                </div>
                <div className='col-xs-4'>
                    <div><label>{t('registrationDetails:address')}</label></div>
                    <div>{this.formatAddress(registration)}</div>
                </div>
            </div>

            {registration.emergencyContactName || registration.emergencyContactNumber
                ? <div className='row row-m-t'>
                    <div className='col-xs-4'>
                        <div><label>{t('registrationDetails:emergencyContact')}</label></div>
                        <div>{registration.emergencyContactName} {registration.emergencyContactNumber}</div>
                    </div>
                </div>
                : null}

            {!isNullOrEmpty(registration.registeredByCustomerFirstname) || !isNullOrEmpty(registration.registeredByCustomerFirstname)
                ?
                <div className='row row-m-t'>
                    <div className='col-xs-4'>
                        <div><label>{t('registrationDetails:registeredByName')}</label></div>
                        <div>{registration.registeredByCustomerFirstname} {registration.registeredByCustomerLastname} {registration.registeredByParent ? ` (${t('Global:parent')})` : null}</div>
                    </div>
                    <div className='col-xs-4'>
                        <div><label>{t('registrationDetails:emailAddress')}</label></div>
                        <div>{registration.registeredByCustomerEmail}</div>
                    </div>
                    <div className='col-xs-4'>
                        <div><label>{t('registrationDetails:phoneNumber')}</label></div>
                        <div>{registration.registeredByCustomerPhone}</div>
                    </div>
                </div>
                : null}

            <div className='row row-m-t'>
                {registration.customFields.map(f => (
                    <div className='col-xs-4'>
                        <div><label>{f.customFieldName}</label></div>
                        <div>{f.value}</div>
                    </div>
                    ))}
            </div>

            <div className='row row-m-t'>
                <div className='col-xs-12'>
                    <div><label>{t('registrationDetails:terms')}</label></div>
                    {termsDiv}
                </div>
            </div>
            {!isNullOrEmpty(customerSignatureUrl) ? <div className='signature-display'><img src={customerSignatureUrl} alt='signature' /></div> : null}
            {this.renderCounterSignatoryTerms(counterSignatoryTerms, registration.counterSigantoryFirstname, registration.counterSigantoryLastname, counterSigantorySignatureUrl, `${registration.firstname} ${registration.lastname}`, t)}
            <div className='row row-m-t'>
                <div className='col-xs-12'>
                    <div className='btn-toolbar'>
                        <button className='btn btn-primary' onClick={this.print}>{this.context.t('Global:print')}</button>
                        <button className='btn btn-basic' onClick={close}>{this.context.t('Global:close')}</button>
                    </div>
                </div>
            </div>
        </div>;
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    termsAndConditions: state.termsAndConditions.termsAndConditions,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadTermsAndConditions: bindActionCreators(TermsAndConditionsActions.actionCreators.loadTermsAndConditions, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(RegistrationDetails);
