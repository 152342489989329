
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'
import Dropzone from 'react-dropzone';

import { ApplicationState } from '../../../../store';
import { ClientEmailTemplate, EmailType, EmailTemplate, ClientEmailAttachment, EmailTemplateType } from '../../../../store/pages/emailTemplates/types';

import * as api from '../../../../store/apiClient';
import * as ct from '../../../global/controls';
import * as v from '../../../global/validation';
import * as EmailTemplateActions from '../../../../store/pages/emailTemplates/actions';
import * as ModalActions from '../../../../store/global/modal/actions';
import ValidationSummary from '../../../global/validationSummary';
import { clickHandler, generateTempId, isNullOrEmpty } from '../../../../utils/util';
import { ValidationError } from '../../../../store/global/types';
import { quillToolbarWithImageAndLinks } from '../../../global/quillSettings';
import { DateFormat } from '../../../../store/pages/venues/types';

interface LocalProps {
    isNew: boolean;
    venueId: string;
    allowTypeSelection: boolean;
    template: ClientEmailTemplate;
}

interface LocalState {
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
    selectableEmailTypes: EmailTemplate[];
    dateFormat: DateFormat;
}

interface Actions {
    saveEmailTemplate: (isNew: boolean, venueId: string, clientEmailTemplateId: string | null, emailTemplateId: string | null, emailType: EmailType, name: string, subjectTemplate: string, textTemplate: string, htmlTemplate: string, jsonTemplate: string, templateType: EmailTemplateType, ccEmailAddress: string, bccEmailAddress: string, sendFromEmailAddressOverride: string | null, sendFromEmailNameOverride: string | null, showActivityStartTimes: boolean, archived: boolean, existingAttachmentIds: string[], newAttachments: File[]) => void;
    closeModal: () => void;
}

type EmailTemplateFormProps = LocalState & Actions & LocalProps;

interface EmailTemplateFormState {
    clientEmailTemplateKey: string;
    showEmailTypeSelection: boolean;
    emailType: ct.FormValue<EmailTemplate | null>;
    name: ct.FormValue<string>;
    subjectTemplate: ct.FormValue<string>;
    ccEmailAddress: ct.FormValue<string>;
    bccEmailAddress: ct.FormValue<string>;
    textTemplate: ct.FormValue<string>;
    htmlTemplate: ct.FormValue<string>;
    templateType: EmailTemplateType;
    archived: ct.FormValue<boolean>;
    existingAttachments: ClientEmailAttachment[];
    newAttachments: File[];
    sendFromEmailAddressOverride: ct.FormValue<string>;
    sendFromEmailNameOverride: ct.FormValue<string>;
    showActivityStartTimes: ct.FormValue<boolean>;
    error: string | null;
}

class EmailTemplateForm extends React.Component<EmailTemplateFormProps, EmailTemplateFormState> {

    constructor(props: EmailTemplateFormProps) {
        super(props);

        this.state = this.buildStateFromProps(this.props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps = (props: EmailTemplateFormProps): EmailTemplateFormState => {

        const { isNew, template, allowTypeSelection } = props;

        const showEmailTypeSelection = isNew && allowTypeSelection;

        if (template.templateType === EmailTemplateType.Grapesjs && template.jsonTemplate) {
            const key = `template-${template.clientEmailTemplateId}`;
            const data = { template: template.jsonTemplate, html: template.htmlTemplate }
            const dataStr = JSON.stringify(data);
            sessionStorage.setItem(key, dataStr);
        }

        return {
            clientEmailTemplateKey: (isNew || !template || !template.clientEmailTemplateId) ? generateTempId() : template.clientEmailTemplateId,
            showEmailTypeSelection: showEmailTypeSelection,
            emailType: this.validateEmailType(showEmailTypeSelection ? null : template),
            name: this.validateName((isNew || !template) ? '' : template.name || ''),
            ccEmailAddress: this.validateCcEmailAddress((isNew || !template) ? '' : template.ccEmailAddress || ''),
            bccEmailAddress: this.validateBccEmailAddress((isNew || !template) ? '' : template.bccEmailAddress || ''),
            subjectTemplate: this.validateSubjectTemplate((isNew || !template) ? '' : template.subjectTemplate || ''),
            textTemplate: this.validateTextTemplate((isNew || !template) ? '' : template.textTemplate || ''),
            htmlTemplate: this.validateHtmlTemplate((isNew || !template) ? '' : template.htmlTemplate || ''),
            templateType: isNew || !template ? EmailTemplateType.Grapesjs : template.templateType,
            sendFromEmailAddressOverride: this.validateSendFromEmailAddressOverride((isNew || !template) ? '' : template.sendFromEmailAddressOverride || ''),
            sendFromEmailNameOverride: this.validateSendFromEmailNameOverride((isNew || !template) ? '' : template.sendFromEmailNameOverride || ''),
            showActivityStartTimes: this.validateShowActivityStartTimes(isNew || !template ? true : template.showActivityStartTimes),
            existingAttachments: isNew || !template ? [] : template.attachments,
            archived: ct.asFormValue('archived', !isNew && template && template.archived ? true : false),
            newAttachments: [],
            error: null
        };
    }

    componentDidUpdate(prevProps: EmailTemplateFormProps) {
        const { template: prevTemplate, saveComplete: prevSaveComplete } = prevProps;
        const { template, saveComplete } = this.props;

        // Only update state is template has changed
        if ((!prevTemplate && template) || (prevTemplate && !template) || (prevTemplate && template && prevTemplate.emailType !== template.emailType)) {
            this.setState(this.buildStateFromProps(this.props));
        }

        if (saveComplete && !prevSaveComplete) {
            setTimeout(() => { this.close(); }, 750);
        }
    }

    saveTemplate = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }

    private close = () => {
        this.props.closeModal();
    }

    private save = () => {
        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {
            const { t } = this.context;
            const { isNew, venueId, template } = this.props;
            const { showEmailTypeSelection, emailType, name, subjectTemplate, textTemplate, htmlTemplate, templateType,
                existingAttachments, newAttachments, ccEmailAddress, bccEmailAddress, archived, clientEmailTemplateKey,
                sendFromEmailAddressOverride, sendFromEmailNameOverride, showActivityStartTimes } = this.state;

            let html = htmlTemplate.value;
            let json = '';

            if (templateType === EmailTemplateType.Grapesjs) {

                const editor = document.getElementById("htmlEditor") as HTMLIFrameElement;
                if (!editor) {
                    this.setState({ error: t('unable to access editor iframe')});
                    return;
                }

                const contentWindow = editor.contentWindow as any;
                if (!contentWindow || !contentWindow.saveEditor) {
                    this.setState({ error: t('unable to access save hook in iframe') });
                    return;
                }

                contentWindow.saveEditor();

                const dataStr = sessionStorage.getItem(`template-${clientEmailTemplateKey}`);
                const data = dataStr ? JSON.parse(dataStr) : {};
                html = data && data.html ? data.html : '';
                json = data && data.template ? JSON.stringify(data.template) : '';

                const validTags = emailType && emailType.value && emailType.value.tags ? emailType.value.tags : [];
                const emailTags = html.match(/{{\w*(:[\w|&|&amp;|=|\s|%]*)?}}/g);
                const invalidTags = !emailTags ? [] : emailTags.filter(t => validTags.findIndex(vt => '{{' + vt.key + '}}' === t) < 0);
                if (invalidTags.length > 0) {
                    this.setState({ error: t('EmailTemplateForm:Unknown tags {tags}', { tags: invalidTags.join(',')}) });
                    return;
                }
            }

            const templateId = isNew || !template ? null : template.emailTemplateId;
            const type = showEmailTypeSelection && emailType.value ? emailType.value.emailType : template.emailType;

            this.props.saveEmailTemplate(isNew,
                venueId,
                isNew || !this.props.template ? null : template.clientEmailTemplateId,
                templateId,
                type,
                name.value,
                subjectTemplate.value,
                textTemplate.value,
                html,
                json,
                templateType,
                ccEmailAddress.value,
                bccEmailAddress.value,
                isNullOrEmpty(sendFromEmailAddressOverride.value) ? null : sendFromEmailAddressOverride.value,
                isNullOrEmpty(sendFromEmailNameOverride.value) ? null : sendFromEmailNameOverride.value,
                this.showShowActivityStartTimeSelection(type) && showActivityStartTimes.value,
                archived.value,
                existingAttachments.map(a => a.id),
                newAttachments
            );

            this.setState({ error: null });
        }
    }

    emailTypeChanged = (val: string) => {
        const et = parseInt(val);
        const matchedEmailTemplate = this.props.selectableEmailTypes.find(t => t.emailType === et);
        const emailTemplate = matchedEmailTemplate ? matchedEmailTemplate : null;
        this.setState({ emailType: this.validateEmailType(emailTemplate) });
    }

    validateEmailType = (val: EmailTemplate | null) => {
        const result = v.validate(val, 'emailType', [v.required], this.props.validationErrors);
        return result;
    }
    validateName = (val: string) => v.validate(val, 'name', [v.required], this.props.validationErrors);
    validateCcEmailAddress = (val: string) => v.validate(val, 'ccEmailAddress', [], this.props.validationErrors);
    validateBccEmailAddress = (val: string) => v.validate(val, 'bccEmailAddress', [], this.props.validationErrors);
    validateSubjectTemplate = (val: string) => v.validate(val, 'subjectTemplate', [v.required], this.props.validationErrors);
    validateTextTemplate = (val: string) => v.validate(val, 'textTemplate', [], this.props.validationErrors);
    validateHtmlTemplate = (val: string) => v.validate(val, 'htmlTemplate', [], this.props.validationErrors);
    validateSendFromEmailAddressOverride = (val: string) => v.validate(val, 'sendFromEmailAddressOverride', [v.email], this.props.validationErrors);
    validateSendFromEmailNameOverride = (val: string) => v.validate(val, 'sendFromEmailNameOverride', [], this.props.validationErrors);
    validateShowActivityStartTimes = (val: boolean) => v.validate(val, 'showActivityStartTimes', [], this.props.validationErrors)
    htmlTemplateChanged = (val: string) => {
        const currentVal = this.state.htmlTemplate.value;
        const hasChanges = val.localeCompare(currentVal);
        if (hasChanges !== 0) {
            this.setState({ htmlTemplate: this.validateHtmlTemplate(val) });
        }
    }

    onAttachmentDrop = (files: File[]) => this.setState(prev => ({ newAttachments: prev.newAttachments.concat(files) }));

    removeAttachment = (attachment: ClientEmailAttachment) => this.setState(s => ({ existingAttachments: s.existingAttachments.filter(a => a.id !== attachment.id) }));

    showShowActivityStartTimeSelection = (emailType: EmailType | null) => {
        if (emailType) {
            switch (emailType) {
                case EmailType.GeneralBookingEmail:
                case EmailType.BookingConfirmation:
                case EmailType.PaymentReminder:
                case EmailType.PurchaseConfirmation:
                case EmailType.MembershipPurchaseConfirmation:
                    return true;
            }
        }
        return false;
    }

    render() {

        const { isNew, saveError, validationErrors, saveComplete, selectableEmailTypes, isSaving, dateFormat } = this.props;
        const { showEmailTypeSelection, emailType, existingAttachments, newAttachments, templateType, htmlTemplate,
            sendFromEmailAddressOverride, sendFromEmailNameOverride, showActivityStartTimes, archived, clientEmailTemplateKey, error } = this.state;
        const { t } = this.context;

        const tags = emailType && emailType.value && emailType.value.tags ? emailType.value.tags : [];
        let message: any = null;

        if (isSaving) {
            message = <div className='alert alert-info' style={({ marginTop: '20px' })}>{t('Global:saving')}</div>
        } else if (saveError) {
            message = (<ValidationSummary error={saveError} keyPrefix='EmailTemplateForm' validationMessages={validationErrors} t={this.context.t} />);
        } else if (saveComplete) {
            message = (<div className='bg-success'>{this.context.t('Global:saveComplete')}</div>);
        }

        const emailTypeOptions = [{ key: '-1', name: t(`EmailTemplateForm:selectEmailType`) }].concat(selectableEmailTypes.map(key => ({ key: key.emailType.toString(), name: t(`EmailTemplateType:${EmailType[key.emailType]}`) })));

        return <div className='email_template_page'>
            <h1 className='email_template_title'>{isNew ? this.context.t('EmailTemplateForm:addEmailTemplate') : this.context.t(`EmailTemplateType:${(EmailType[this.props.template.emailType] || '').toString()}`)}</h1>

            <form onSubmit={this.saveTemplate} autoComplete='off'>
                <div className='row'>
                    <div className='col-md-8'>
                        {showEmailTypeSelection ? <ct.Select id='emailType' labelKey='EmailTemplateForm:emailType' value={({ ...emailType, value: (emailType.value ? emailType.value.emailType.toString() : '-1') })} callback={this.emailTypeChanged} options={emailTypeOptions} /> : null}

                        <ct.TextBox id='name' labelKey='Global:name' placeholderKey='Global:name' value={this.state.name} callback={val => this.setState({ name: this.validateName(val) })} />

                        <ct.TextBox id='subjectTemplate' labelKey='EmailTemplateForm:subjectTemplate' placeholderKey='EmailTemplateForm:subjectTemplate' value={this.state.subjectTemplate} callback={val => this.setState({ subjectTemplate: this.validateSubjectTemplate(val) })} />

                        <ct.TextBox id='sendFromEmailAddressOverride' labelKey='EmailTemplateForm:sendFromEmailAddressOverride' hintKey='EmailTemplateForm:sendFromEmailAddressOverrideHint' placeholderKey='EmailTemplateForm:sendFromEmailAddressOverride' value={sendFromEmailAddressOverride} callback={val => this.setState({ sendFromEmailAddressOverride: this.validateSendFromEmailAddressOverride(val) })} />

                        <ct.TextBox id='sendFromEmailNameOverride' labelKey='EmailTemplateForm:sendFromEmailNameOverride' hintKey='EmailTemplateForm:sendFromEmailNameOverrideHint' placeholderKey='EmailTemplateForm:sendFromEmailNameOverride' value={sendFromEmailNameOverride} callback={val => this.setState({ sendFromEmailNameOverride: this.validateSendFromEmailNameOverride(val) })} />

                        <ct.TextBox id='ccEmailAddress' labelKey='EmailTemplateForm:ccEmailAddress' placeholderKey='EmailTemplateForm:ccEmailAddress' value={this.state.ccEmailAddress} callback={val => this.setState({ ccEmailAddress: this.validateCcEmailAddress(val) })} />

                        <ct.TextBox id='bccEmailAddress' labelKey='EmailTemplateForm:bccEmailAddress' placeholderKey='EmailTemplateForm:bccEmailAddress' value={this.state.bccEmailAddress} callback={val => this.setState({ bccEmailAddress: this.validateBccEmailAddress(val) })} />

                        {this.showShowActivityStartTimeSelection(emailType.value ? emailType.value.emailType : null)
                            ? <ct.Checkbox id='showActivityStartTimes' labelKey='EmailTemplateForm:showActivityStartTimes' value={showActivityStartTimes} callback={val => this.setState({ showActivityStartTimes: this.validateShowActivityStartTimes(val) })} />
                            : null
                        }

                        <ct.Checkbox id='archived' labelKey='Global:archive' value={archived} callback={val => this.setState({ archived: ct.asFormValue('archived', val) })} />
                    </div>
                    <div className='col-md-4'>
                        <div className='panel panel-info'>
                            <div className='panel-heading'>
                                <h3 className='panel-title'>{this.context.t('EmailTemplateForm:emailTags')}</h3></div>
                            <div className='panel-body'>
                                <span>{this.context.t('EmailTemplateForm:emailTagsText')}</span>
                                <ul className='plain-list'>
                                    {tags.map(tag => <li key={tag.key}>{`{{${tag.key}}}`} <ct.Help text={this.context.t(`EmailTag:${tag.key}`)} /></li>)}
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='row'>
                    <div className='col-xs-12'>
                        {this.renderEditor(templateType, htmlTemplate, clientEmailTemplateKey)}

                        {message}

                        <p />

                        <div>
                            <h4>{t('EmailTemplateForm:attachments')}</h4>
                            <ul className='list-unstyled'>
                                {existingAttachments.map(a => <li key={a.id}><span className='email-template-page-attachment-name'>{a.fileName}</span><span className='email-template-page-attachment-size'>{(a.sizeInBytes / 1024).toFixed(0)}KB</span><span className='email-template-page-attachment-created'>({a.createDateTime.toShortDateString(dateFormat)})</span><span className='glyphicon glyphicon-trash red' onClick={e => clickHandler(e, () => this.removeAttachment(a))} style={({ cursor: 'pointer', background: 'white', padding: '5px' })}></span></li>)}
                                {newAttachments.map((f, ix) => <li key={`attachment_${ix}`}><span className='email-template-page-attachment-name'>{f.name}</span> <span className='email-template-page-attachment-size'>{(f.size / 1024).toFixed(0)}KB</span></li>)}
                            </ul>
                            <Dropzone multiple={false} onDrop={this.onAttachmentDrop}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()} className='file-drop'>
                                        <input {...getInputProps()} />
                                        <p>{t('Global:imageDropText')}</p>
                                    </div>
                                )}
                            </Dropzone>

                        </div>
                    </div>
                </div>
                {error ? <div className='alert alert-danger'>{error}</div>: null}
                <div className='btn-toolbar'>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)} disabled={isSaving}>{this.context.t('Global:save')}</button>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)} disabled={isSaving}>{this.context.t('Global:cancel')}</button>
                </div>
            </form>
        </div>;
    }

    renderEditor = (templateType: EmailTemplateType, htmlTemplate: ct.FormValue<string>, emailTemplateId: string) => {
        const { t } = this.context;
        const { venueId } = this.props;

        switch (templateType) {
            case EmailTemplateType.Quill:
                return <div>
                    <ct.HtmlInput id='htmlTemplate' labelKey='EmailTemplateForm:htmlTemplate' value={htmlTemplate} callback={this.htmlTemplateChanged} modules={quillToolbarWithImageAndLinks} inlineStyles={true} />
                    <button className='btn btn-link' onClick={e => clickHandler(e, () => this.setState({ templateType: EmailTemplateType.RawHtml }))}>{t('EmailTemplateForm:switchToFullHtml')}</button>
                </div>
            case EmailTemplateType.Grapesjs:
                return <div className='email-template-editor-wrapper'>
                    <iframe id='htmlEditor' title='Template editor' className='email-template-editor-frame' src={`/email/${venueId}/template/${emailTemplateId}`} />
                </div>
            case EmailTemplateType.RawHtml:
                return <div>
                    <ct.TextArea id='htmlTemplate' labelKey='EmailTemplateForm:htmlTemplate' placeholderKey='EmailTemplateForm:htmlTemplate' rows={25} value={htmlTemplate} callback={val => this.setState({ htmlTemplate: this.validateHtmlTemplate(val) })} style={({ marginTop: '15px' })} />
                </div>
            default:
                return <div className='alert alert-danger'>Unknown email template type {EmailTemplateType[templateType]}</div>
        }
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    isSaving: state.emailTemplates.isSaving,
    saveComplete: state.emailTemplates.saveComplete,
    saveError: state.emailTemplates.saveError,
    validationErrors: state.emailTemplates.validationErrors,
    selectableEmailTypes: state.emailTemplates.selectableEmailTypes,
    dateFormat: state.venues.dateFormat
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    saveEmailTemplate: bindActionCreators(EmailTemplateActions.actionCreators.saveEmailTemplate, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch)
});


// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,           // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(EmailTemplateForm);
