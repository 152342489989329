
import * as React from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'

import * as api from '../../../store/apiClient';
import * as ct from '../../global/controls';
import * as v from '../../global/validation';

import { ApplicationState } from '../../../store';
import * as VoucherActions from '../../../store/pages/vouchers/actions';
import * as ModalActions from '../../../store/global/modal/actions';
import * as LoginActions from '../../../store/pages/login/actions';

import { Voucher, VoucherProduct } from '../../../store/pages/vouchers/types';
import { clickHandler } from '../../../utils/util';

import Pagination from '../../global/pagination';
import VoucherProductForm from './voucherProductForm';

import VoucherDetails from './voucherDetails'
import ImportVouchers from './importVouchers'
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';

interface MappedReduxState {
    isSearching: boolean;
    searchError: api.ApiError | null;
    voucherSearch: string;
    voucherSearchProductId: string | null;
    searchPageNumber: number;
    searchPageSize: number;
    searchMaxPage: number;
    vouchers: Voucher[];
    voucherProducts: VoucherProduct[];
    timeFormat: TimeFormat;
    dateFormat: DateFormat
}

interface LocalProps {
    voucherProductId: string;
}

interface Actions {
    searchVouchers: (voucherProductId: string, search: string, pageNumber: number, pageSize: number) => void;
    loadVoucherProducts: () => void;
    editVoucherProduct: () => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
    logout: () => void;
}

type VoucherProductDetailsPageProps = MappedReduxState & Actions & RouteComponentProps<LocalProps>;

interface VoucherProductDetailsPageState {
    voucherProduct: VoucherProduct | null;
    searchTerm: ct.FormValue<string>;
}

class VoucherProductDetailsPage extends React.Component<VoucherProductDetailsPageProps, VoucherProductDetailsPageState> {

    constructor(props: VoucherProductDetailsPageProps) {
        super(props);

        const voucherProductId = props.match.params.voucherProductId;
        const voucherProduct = props.voucherProducts.find(vp => vp.id === voucherProductId);

        this.state = { voucherProduct: voucherProduct ? voucherProduct : null, searchTerm: this.validateSearchTerm('') }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        const { match, voucherSearchProductId } = this.props;

        const voucherProductId = match.params.voucherProductId;

        if (voucherProductId !== voucherSearchProductId) {
            this.searchVouchers('', 1, 30);
        }
    }

    componentDidUpdate() {

        const { voucherProduct } = this.state;
        const newVoucherProduct = this.props.voucherProducts.find(x => voucherProduct && x.id === voucherProduct.id);

        if (voucherProduct && newVoucherProduct && newVoucherProduct.version !== voucherProduct.version) {
            this.setState({ voucherProduct: newVoucherProduct });
        }
    }

    search = () => this.searchVouchers(this.state.searchTerm.value, 1, this.props.searchPageSize);

    searchVouchers = (searchTerm: string, pageNumber: number, pageSize: number) => {
        const { match, searchVouchers } = this.props;
        const voucherProductId = match.params.voucherProductId;
        searchVouchers(voucherProductId, searchTerm, pageNumber, pageSize);
    }

    setPage = (pageNumber: number) => this.searchVouchers(this.state.searchTerm.value, pageNumber, this.props.searchPageSize);

    setPageSize = (pageSize: number) => this.searchVouchers(this.state.searchTerm.value, this.props.searchPageNumber, pageSize);

    reload = () => this.searchVouchers(this.state.searchTerm.value, this.props.searchPageNumber, this.props.searchPageSize);

    editVoucherProduct = () => {
        const { editVoucherProduct, showModal } = this.props;
        const { voucherProduct } = this.state;

        if (voucherProduct) {
            editVoucherProduct();
            showModal(<VoucherProductForm isNew={false} voucherProduct={voucherProduct} />, 'VoucherProductForm');
        }
    }

    importVouchers = () => {
        const { dateFormat, showModal, closeModal, logout } = this.props;
        const { voucherProduct } = this.state;
        if (!voucherProduct) return;

        const close = () => {
            this.reload();
            closeModal();
        }

        showModal(<ImportVouchers voucherProductId={voucherProduct.id} dateFormat={dateFormat} close={close} logout={logout} />, 'ImportVouchers');
    }

    close = () => {
        const { history } = this.props;
        history.push({ pathname: '/vouchers/' });
    }

    showBill = (billId: string) => {
        // showModal(<PointOfSalePanel venue={venue} products={products.filter(p => p.venueId === venue.id || p.type === ProductType.Voucher)} eventProducts={[]} productCategories={productCategories} activityFormats={activityFormats.filter(a => a.venueId === venue.id)} paymentMethods={paymentMethods} customerCategories={customerCategories} fees={fees} taxRates={taxRates} promotions={promotions} booking={null} billInfo={payment.billId} paymentId={payment.paymentId} posSessionComplete={this.billUpdated} vouchers={vouchers} close={closeModal} logout={logout} />, true);
    }

    showVoucher = (voucherId: string) => {
        const { timeFormat, dateFormat, showModal, closeModal, logout } = this.props;
        showModal(<VoucherDetails voucherId={voucherId} timeFormat={timeFormat} dateFormat={dateFormat} close={closeModal} logout={logout} />, 'VoucherDetails', true);
    } 

    validateSearchTerm = (val: string) => v.validate(val, 'searchTerm', [], []);

    render() {
        const { vouchers, searchMaxPage, searchPageSize, searchPageNumber, dateFormat } = this.props;
        const { voucherProduct, searchTerm } = this.state;
        const { t } = this.context;

        if (!voucherProduct) {
            return <div>
                <div className='alert alert-danger'>{t('VoucheDetailsPage:voucherProductNotFound')}</div>
            </div>
        }

        return <div>
            <div className='row'>
                <div className='col-xs-8'>
                    <h1>{voucherProduct.name}</h1>
                </div>
                <div className='col-xs-4 text-right'>
                    <button className='btn btn-info' style={{ marginTop: '15px', marginRight: '10px' }} onClick={e => clickHandler(e, this.importVouchers)}>{t('VoucheDetailsPage:import')}</button>
                    <button className='btn btn-primary' style={{ marginTop: '15px' }} onClick={e => clickHandler(e, this.editVoucherProduct)}>{t('Global:edit')}</button>
                </div>
            </div>

            <div className='row'>
                <div className='col-xs-10'>
                    <ct.TextBox id='searchTerm' labelKey='VoucheDetailsPage:search' placeholderKey='VoucheDetailsPage:search' value={searchTerm} callback={val => this.setState({ searchTerm: this.validateSearchTerm(val) })} />
                </div>
                <div className='col-xs-2 text-right'>
                    <button className='btn btn-primary' style={{ marginTop: '15px' }} onClick={e => clickHandler(e, this.search)}>{t('Global:search')}</button>
                </div>
            </div>

            <table className='table table-condensed'>
                <thead>
                    <tr>
                        <th>{t('VoucheDetailsPage:code')}</th>
                        <th>{t('VoucheDetailsPage:purchaseDate')}</th>
                        <th>{t('VoucheDetailsPage:bill')}</th>
                        <th>{t('VoucheDetailsPage:amount')}</th>
                        <th>{t('VoucheDetailsPage:balance')}</th>
                        <th>{t('VoucheDetailsPage:paid')}</th>
                        <th>{t('VoucheDetailsPage:activeFrom')}</th>
                        <th>{t('VoucheDetailsPage:expiry')}</th>
                        <th>{t('VoucheDetailsPage:emailLastSent')}</th>
                        <th></th>
                        <th colSpan={2} className='text-center'>{t('VoucheDetailsPage:purchasedBy')}</th>
                        <th>{t('VoucheDetailsPage:lastSentToAddress')}</th>
                        <th>{t('VoucheDetailsPage:createdBy')}</th>
                    </tr>
                </thead>
                <tbody>
                    {vouchers.sort((v1, v2) => v2.purchaseDate.getTime() - v1.purchaseDate.getTime()).map(v => <tr key={v.voucherId}>
                        {<td style={{ padding: '0' }}><button className='btn btn-link' onClick={e => clickHandler(e, () => this.showVoucher(v.voucherId))}>{v.voucherCode}</button></td>}
                        <td>{v.purchaseDate.toShortDateString(dateFormat)}</td>
                        {/*<td><button className='btn btn-link' onClick={e => clickHandler(e, () => this.showBill(v.billId))}>{v.billNumber}</button></td>*/}
                        <td>{v.billNumber}</td>
                        <td>{v.redemptionAmount.toFixed(2)}</td>
                        <td>{v.remainingBalance.toFixed(2)}</td>
                        <td>{v.paid ? t('Global:yes') : null}</td>
                        <td>{v.activeFromDate ? v.activeFromDate.toShortDateString(dateFormat) : ''}</td>
                        <td>{v.expiryDate ? v.expiryDate.toShortDateString(dateFormat) : ''}</td>
                        <td>{v.emailLastSent ? v.emailLastSent.toShortDateString(dateFormat) : ''}</td>
                        <td>{this.renderVoucherState(v)}</td>
                        <td>{v.customerFirstname} {v.customerLastname}</td>
                        <td>{v.customerEmail}</td>
                        <td>{v.lastSentToEmail}</td>
                        <td>{v.createdBy}</td>
                    </tr>)}
                </tbody>
            </table>

            <Pagination maxPage={searchMaxPage} pageSize={searchPageSize} pageNumber={searchPageNumber} setPage={this.setPage} setPageSize={this.setPageSize} />

            <div className='btn-toolbar'>
                <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{t('Global:close')}</button>
            </div>
        </div>
    }

    renderVoucherState = (v: Voucher) => {

        const { t } = this.context;
        if (v.revoked) return <label className='label label-danger'>{t('Global:revoked')}</label>
        if (v.hasExpired) return <label className='label label-danger'>{t('Global:expired')}</label>
        if (v.isActive) return <label className='label label-success'>{t('Global:active')}</label>
        return null
    }
}

const mapStateToProps = (state: ApplicationState) => {
    const venueId = state.venues.selectedVenueId;
    const venue = state.venues.venues.find(v => v.id === venueId);
    return {
        isSearching: state.vouchers.isSearching,
        searchError: state.vouchers.searchError,
        voucherSearch: state.vouchers.voucherSearch,
        voucherSearchProductId: state.vouchers.voucherSearchProductId,
        searchPageNumber: state.vouchers.searchPageNumber,
        searchPageSize: state.vouchers.searchPageSize,
        searchMaxPage: state.vouchers.searchMaxPage,
        vouchers: state.vouchers.vouchers,
        voucherProducts: state.vouchers.voucherProducts,
        timeFormat: venue ? venue.timeFormat : TimeFormat.TwentyFourHour,
        dateFormat: venue ? venue.dateFormat : DateFormat.DMY
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    searchVouchers: bindActionCreators(VoucherActions.actionCreators.searchVouchers, dispatch),
    editVoucherProduct: bindActionCreators(VoucherActions.actionCreators.editVoucherProduct, dispatch),
    showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
    logout: bindActionCreators(LoginActions.actionCreators.logout, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(VoucherProductDetailsPage);

