
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import * as VenueActions from '../../../store/pages/venues/actions';
import * as ProductCategoryActions from '../../../store/pages/productCategories/actions';
import * as ProductActions from '../../../store/pages/products/actions';
import * as PaymentMethodActions from '../../../store/pages/paymentMethods/actions';
import * as ActivityFormatActions from '../../../store/pages/activityFormats/actions';
import * as LoginActions from '../../../store/pages/login/actions';
import * as CustomerCategoryActions from '../../../store/pages/customerCategories/actions';
import * as ModalActions from '../../../store/global/modal/actions';

import PointOfSalePanel from './pointOfSalePanel';
import { ApplicationState } from '../../../store/index';
import { Venue } from '../../../store/pages/venues/types';
import { ProductCategory } from '../../../store/pages/productCategories/types';
import { Product, ProductType } from '../../../store/pages/products/types';
import { PaymentMethod } from '../../../store/pages/paymentMethods/types';
import Loading from '../../global/loading';
import BillSelection from './billSelection';
import { ActivityFormat } from '../../../store/pages/activityFormats/types';
import { CustomerCategory } from '../../../store/pages/customerCategories/types';
import { TaxRate } from '../../../store/pages/taxRates/types';
import { Fee } from '../../../store/pages/fees/types';
import { Promotion } from '../../../store/pages/promotions/types';
import { VoucherProduct } from '../../../store/pages/vouchers/types';
import { MembershipType } from '../../../store/pages/memberships/types';


interface LocalProps { }

interface LocalState {
    venues: Venue[];
    isLoadingVenues: boolean;
    selectedVenueId: string;
    productCategories: ProductCategory[];
    isLoadingProductCategories: boolean;
    products: Product[];
    isLoadingProducts: boolean;
    paymentMethods: PaymentMethod[];
    isLoadingPaymentMethods: boolean;
    activityFormats: ActivityFormat[];
    isLoadingActivityFormats: boolean;
    customerCategories: CustomerCategory[];
    isLoadingCustomerCategories: boolean;
    taxRates: TaxRate[];
    taxRatesLoading: boolean;
    fees: Fee[];
    feesLoading: boolean;
    promotions: Promotion[];
    vouchers: VoucherProduct[];
    membershipTypes: MembershipType[];
}

interface Actions {
    loadVenues: () => void;
    loadProductCategories: () => void;
    loadProducts: () => void;
    loadPaymentMethods: () => void;
    loadActivityFormats: () => void;
    loadCustomerCategories: () => void;
    logout: () => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
}

type PointOfSalePageProps = LocalState & Actions & LocalProps;

interface PointOfSalePageState {
    currentBillId: string | null;
    venue: Venue | null;
}

class PointOfSalePage extends React.Component<PointOfSalePageProps, PointOfSalePageState> {

    constructor(props: PointOfSalePageProps) {
        super(props);

        const venue = this.props.venues.find(v => v.id === this.props.selectedVenueId);

        this.state = { currentBillId: null, venue: venue ? venue : null };
    }

    componentDidUpdate(nextProps: PointOfSalePageProps) {
        if ((nextProps.venues && nextProps.venues.length > 0) && (!this.state.venue || this.props.selectedVenueId !== nextProps.selectedVenueId)) {
            this.setState(currentState => {
                const venue = nextProps.venues.find(v => v.id === this.props.selectedVenueId);
                return ({ venue: venue ? venue : null });
            });
        }
    }

    showBill = (billId: string) => {
        this.setState({ currentBillId: billId });
    }

    clearBill = () => {
        this.setState({ currentBillId: null });
    }

    render() {
        const { currentBillId, venue } = this.state;
        const { productCategories, products, paymentMethods, activityFormats, customerCategories, fees, taxRates, promotions, vouchers, membershipTypes, logout, showModal, closeModal } = this.props;

        if (!venue)
            return <Loading />

        return currentBillId !== null
            ? <PointOfSalePanel
                venue={venue}
                productCategories={productCategories}
                products={products.filter(p => p.venueId === venue.id || p.type === ProductType.Voucher || p.type === ProductType.Membership)}
                customerCategories={customerCategories}
                eventProducts={[]}
                activityFormats={activityFormats.filter(a => a.venueId === venue.id)}
                paymentMethods={paymentMethods}
                fees={fees}
                taxRates={taxRates}
                promotions={promotions}
                booking={null}
                billInfo={currentBillId}
                posSessionComplete={this.clearBill}
                vouchers={vouchers}
                membershipTypes={membershipTypes}
                logout={logout} />
            : <BillSelection venueId={venue.id} selectBill={this.showBill} logout={logout} showModal={showModal} closeModal={closeModal} />;
    }
}

const matStateToProps = (state: ApplicationState) => {
    const venueId = state.venues.selectedVenueId;

    return {
        productCategories: state.productCategories.productCategories.filter(c => c.venueId === venueId),
        isLoadingProductCategories: state.productCategories.isLoading,
        products: state.products.products,
        isLoadingProducts: state.products.isLoading,
        venues: state.venues.venues,
        selectedVenueId: state.venues.selectedVenueId,
        isLoadingVenues: state.venues.isLoading,
        paymentMethods: state.paymentMethods.paymentMethods.filter(pm => pm.venueId === venueId && !pm.archived),
        isLoadingPaymentMethods: state.paymentMethods.isLoading,
        activityFormats: state.activityFormats.activityFormats,
        isLoadingActivityFormats: state.activityFormats.isLoading,
        customerCategories: state.customerCategories.customerCategories,
        isLoadingCustomerCategories: state.customerCategories.isLoading,
        taxRates: state.taxRates.taxRates,
        taxRatesLoading: state.taxRates.isLoading,
        fees: state.fees.fees.filter(f => f.venueId === venueId),
        feesLoading: state.fees.isLoading,
        promotions: state.promotions.promotions,
        vouchers: state.vouchers.voucherProducts,
        membershipTypes: state.memberships.membershipTypes
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadVenues: bindActionCreators(VenueActions.actionCreators.loadVenues, dispatch),
    loadProductCategories: bindActionCreators(ProductCategoryActions.actionCreators.loadProductCategories, dispatch),
    loadProducts: bindActionCreators(ProductActions.actionCreators.loadProducts, dispatch),
    loadPaymentMethods: bindActionCreators(PaymentMethodActions.actionCreators.loadPaymentMethods, dispatch),
    loadActivityFormats: bindActionCreators(ActivityFormatActions.actionCreators.loadActivityFormats, dispatch),
    loadCustomerCategories: bindActionCreators(CustomerCategoryActions.actionCreators.loadCustomerCategories, dispatch),
    logout: bindActionCreators(LoginActions.actionCreators.logout, dispatch),
    showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
});


// Wire up the React component to the Redux store
export default connect(
    matStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps        // Selects which action creators are merged into the component's props
)(PointOfSalePage);