
import * as React from 'react';
import * as PropTypes from 'prop-types'
import * as it from '../../../../store/pages/integrations/types';
import * as ct from '../../../global/controls';
import * as v from '../../../global/validation';
import { ValidationError } from '../../../../store/global/types';
import SendgridSettingsForm from './sendgridSettingsForm';

interface TransactionalEmailFormProps {
    isNew: boolean;
    integrationId: string | null;
    implementationType: ct.FormValue<it.IntegrationImplementationType | -1>;
    settings: ct.FormValue<string>;
    validationErrors: ValidationError[];
    valueChanged: (implementationType: ct.FormValue<it.IntegrationImplementationType | -1>, settings: ct.FormValue<string>) => void;
}

class TransactionalEmailForm extends React.Component<TransactionalEmailFormProps, {}> {

    static contextTypes = {
        t: PropTypes.func
    }

    validateImplementationType = (newValue: string) => {
        const val = parseInt(newValue);
        return v.validate(val, 'implementationType', [v.numeric, selection => it.IntegrationImplementationType.hasOwnProperty(selection) ? undefined : 'validation:required'], this.props.validationErrors);
    };

    render() {
        const { t } = this.context;
        const { isNew, implementationType, settings, valueChanged } = this.props;

        const integrationTypeOptions = [{ key: '0', name: t('IntegrationImplType:selectType') }, { key: it.IntegrationImplementationType.EmailSendGrid.toString(), name: t(`IntegrationImplType:${it.IntegrationImplementationType[it.IntegrationImplementationType.EmailSendGrid].toString()}`) }];

        return (
            <div>
                {isNew
                    ? <ct.Select id='implementationType' labelKey='IntegrationForm:implementationType' value={({ ...implementationType, value: implementationType.value.toString() })} callback={val => valueChanged(this.validateImplementationType(val), settings)} options={integrationTypeOptions} />
                    : <div style={{marginBottom: '15px'}}>
                        <label>{t('IntegrationForm:implementationType')}</label>
                        <div>{t(`IntegrationImplType:${it.IntegrationImplementationType[implementationType.value]}`)}</div>
                    </div>
                }

                {this.renderSettings(implementationType, settings)}
            </div>);

    }

    renderSettings = (implementationType: ct.FormValue<it.IntegrationImplementationType | -1>, settings: ct.FormValue<string>) => {
        switch (implementationType.value) {
            case it.IntegrationImplementationType.EmailSendGrid:
                return <SendgridSettingsForm integrationId={this.props.integrationId} settings={this.props.settings} validationErrors={this.props.validationErrors} settingsChanged={(val: ct.FormValue<string>) => this.props.valueChanged(this.props.implementationType, val)} />;
            default:
                return null;

        }
    }
}

export default TransactionalEmailForm;