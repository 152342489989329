
export interface ModalState {
    showOverlay: boolean;
    resetScroll: boolean;
    screenName: string;
    noScroll?: boolean;
    overlayComponent: JSX.Element|null;
}

export enum ModalActionTypes {
    ShowModal = 'SHOW_MODAL',
    CloseModal = 'CLOSE_MODAL'
}

export interface ShowModal {
    type: ModalActionTypes.ShowModal;
    overlayComponent: JSX.Element;
    screenName: string;
    noScroll?: boolean;
}

export interface CloseModal {
    type: ModalActionTypes.CloseModal;
}