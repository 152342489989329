

import * as React from 'react';
import * as PropTypes from 'prop-types'

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { bindActionCreators, Dispatch } from 'redux';

import * as ModalActions from '../../store/global/modal/actions';
import * as UserPreferenceActions from '../../store/global/preferences/actions';

import { Resource } from '../../store/pages/resources/types';
import { clickHandler } from '../../utils/util';

interface ComponentProps {
    selectedVenueId: string;
    hiddenResources: string[];
    resources: Resource[];
}


interface MappedReduxState {
}

interface Actions {
    setResourcefilter: (venueId: string, hiddenResources: string[]) => void;
    closeModal: () => void;
}

type FilterResourcesProps = ComponentProps & MappedReduxState & Actions;

interface FilterResourcesState {
    deselectedResources: string[];
}

class FilterResources extends React.Component<FilterResourcesProps, FilterResourcesState> {

    constructor(props: FilterResourcesProps) {
        super(props);

        this.state = { deselectedResources: props.hiddenResources };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    applyChanges = () => {
        const { setResourcefilter, selectedVenueId, closeModal } = this.props;
        const { deselectedResources } = this.state;

        setResourcefilter(selectedVenueId, deselectedResources)
        closeModal();
    }

    onResourceSelectionChanged = (resourceId: string, checked: boolean) => {
        this.setState(s => ({ deselectedResources: checked ? s.deselectedResources.filter(r => r !== resourceId) : s.deselectedResources.concat(resourceId) }))
    }

    render() {
        const { t } = this.context;
        const { resources, closeModal } = this.props;
        const { deselectedResources } = this.state;

        return (
            <div className='form-group'>
                <h3>{t('FilterResources:selectResourcesToShow')}</h3>
                <ul className='list-unstyled'>
                    {resources.map(res => <li key={res.id}>
                        <input type='checkbox' id={res.id} checked={!deselectedResources.includes(res.id)} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onResourceSelectionChanged(res.id, e.currentTarget.checked)} />
                        <label style={{ marginLeft: '5px', paddingLeft: '3px', borderLeft: `4px solid ${res.colour}` }} htmlFor={res.id}>{res.name}</label>
                    </li>)}
                </ul >

                <div className='btn-toolbar' style={({ marginTop: '20px' })}>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.applyChanges)}>{t('Global:apply')}</button>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, closeModal)}>{t('Global:close')}</button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: ApplicationState): MappedReduxState => ({
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setResourcefilter: bindActionCreators(UserPreferenceActions.actionCreators.setResourcefilter, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(FilterResources);
