import { ApiError } from "../../apiClient";

export interface LoginState {
    username: string;
    userFirstName: string | null;
    userLastName: string | null;
    isAuthenticated: boolean;
    loginError: ApiError | null;
    client: ClientInfo;
    clients: ClientInfo[];
    mustChangePassword: boolean;
    requestingPasswordReset: boolean;
    passwordResetError: ApiError | null;
    resetUserPasswordError: ApiError | null;
    at: string | null;
    loadingClientData: boolean;
    switchingClient: boolean;
    loggingIn: boolean;
    isLoggedIn: boolean;
    lastActivity: Date;
    autoLogoutInterval: number | null;
}

export interface ClientInfo {
    id: number;
    name: string;
}

export interface UserClientInfo {
    client: ClientInfo;
    clients: ClientInfo[];
}

export enum LoginActionTypes {
    Login = 'LOGIN',
    LoginSuccess = 'LOGIN_SUCCESS',
    LoggedIn = 'LOGGED_IN',
    LoggedOut = 'LOGGED_OUT',
    SignedOut = 'SIGNED_OUT',
    LoginError = 'LOGIN_ERROR',
    ChangePassword = 'CHANGE_PASSWORD',
    ChangeUserPassword = 'CHANGE_USER_PASSWORD',
    PasswordChanged = 'PASSWORD_CHANGED',
    AuthorizeError = 'AUTHORIZE_ERROR',
    AccessDenied = 'ACCESS_DENIED',
    AjaxError = 'AJAX_ERROR',
    RefreshToken = 'REFRESH_TOKEN',
    SwitchClient = 'SWITCH_CLIENT',
    ClientSwitched = 'CLIENT_SWITCHED',
    RefreshTokenSuccess = 'REFRESH_TOKEN_SUCCESS',
    RefreshTokenError = 'REFRESH_TOKEN_ERROR',
    LoadUserClientInfo = 'LOAD_USER_CLIENT_INFO',
    ReceiveUserClientInfo = 'RECEIVE_USER_CLIENT_INFO',
    RequestPasswordReset = 'REQUEST_PASSWORD_RESET',
    PasswordReset = 'PASSWORD_RESET',
    PasswordResetError = 'PASSWORD_RESET_ERROR',
    ResetUserPassword = 'RESET_USER_PASSWORD',
    ResetUserPasswordError = 'RESET_USER_PASSWORD_ERROR',
    LoadDataForClient = 'LOAD_DATA_FOR_CLIENT',
    ClientDataLoadComplete = 'CLIENT_DATA_LOAD_COMPLETE'
}

export interface LogIn {
    type: LoginActionTypes.Login;
    username: string;
    password: string;
}

export interface LoginSuccess {
    type: LoginActionTypes.LoginSuccess;
    username: string;
    sessionTimeout: number | null;
    userFirstName: string | null;
    userLastName: string | null;
}

export interface LoggedIn {
    type: LoginActionTypes.LoggedIn;
    forceNaviationToHome: boolean;
}

export interface LoggedOut {
    type: LoginActionTypes.LoggedOut;
    redirect: boolean;
}

export interface SignedOut {
    type: LoginActionTypes.SignedOut;
}

export interface ChangePassword {
    type: LoginActionTypes.ChangePassword;
    username: string;
    at: string;
}

export interface ChangeUserPassword {
    type: LoginActionTypes.ChangeUserPassword;
    username: string;
    at: string;
    oldPassword: string;
    newPassword: string;
    confPassword: string;
}

export interface PasswordChanged {
    type: LoginActionTypes.PasswordChanged;
}

export interface AuthorizeError {
    type: LoginActionTypes.AuthorizeError;
    error: ApiError;
}

export interface AccessDenied {
    type: LoginActionTypes.AccessDenied;
    error: ApiError;
}

export interface AjaxError {
    type: LoginActionTypes.AjaxError;
    status: number;
    response: any;
}

export interface RefreshToken {
    type: LoginActionTypes.RefreshToken;
}

export interface SwitchClient {
    type: LoginActionTypes.SwitchClient;
    clientId: number;
}

export interface ClientSwitched {
    type: LoginActionTypes.ClientSwitched;
    clientId: number;
}

export interface RefreshTokenSuccess {
    type: LoginActionTypes.RefreshToken;
}

export interface RefreshTokenError {
    type: LoginActionTypes.RefreshTokenError;
    err: ApiError;
}

export interface LoadUserClientInfo {
    type: LoginActionTypes.LoadUserClientInfo;
}

export interface ReceiveUserClientInfo {
    type: LoginActionTypes.ReceiveUserClientInfo;
    client: ClientInfo;
    clients: ClientInfo[];
}

export interface RequestPasswordReset {
    type: LoginActionTypes.RequestPasswordReset;
    username: string;
}

export interface PasswordReset {
    type: LoginActionTypes.PasswordReset;
}

export interface PasswordResetError {
    type: LoginActionTypes.PasswordResetError;
    err: ApiError;
}

export interface ResetUserPassword {
    type: LoginActionTypes.ResetUserPassword;
    token: string;
    username: string;
    newPassword: string;
    confPassword: string;
}

export interface ResetUserPasswordError {
    type: LoginActionTypes.ResetUserPasswordError;
    err: ApiError;
}

export interface LoadDataForClient {
    type: LoginActionTypes.LoadDataForClient;
    clientId: number;
}

export interface ClientDataLoadComplete {
    type: LoginActionTypes.ClientDataLoadComplete;
    isSwitchingClientOrLoggingIn: boolean;
}