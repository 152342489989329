

// https://quilljs.com/docs/modules/toolbar/
export const quillStandardToolbar = {
    toolbar: [
        [{ 'size': ['10px', false, '18px', '32px'] }],
        ['bold', 'italic', 'underline'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'align': [] }],
        ['clean']
    ]
}

export const quillToolbarWithImageAndLinks = {
    toolbar: [
        [{ 'size': ['10px', false, '18px', '32px'] }],
        ['bold', 'italic', 'underline'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'align': [] }],
        ['link', 'image'],
        ['clean']
    ]
}

export const quillToolbarSingleLineTextFormatting = {
    toolbar: [
        ['bold', 'italic', 'underline'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        ['clean']
    ],
    keyboard: {
        bindings: {
            enter: {
                key: 13,
                handler: function () {
                    return false;
                }
            }
        }
    }
}