
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as ct from './types'; 


export type CultureActions = ct.RequestCultures | ct.ReceiveCultures | ct.RequestCulturesError;

export const actionCreators = {
    loadCultures: () => ({ type: ct.CultureActionTypes.RequestCultures}),
    loadCulturesError: (err: api.ApiError) => ({ type: ct.CultureActionTypes.RequestCulturesError, error: err }),
}

interface IGetCulturesResponse {
    cultures: ct.Culture[];
}

//https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-after-catching-and-processing-an-error-with-emitting-somethi

const loadCultures = () => Observable.defer(() => api.getJson<IGetCulturesResponse>('api/v1/localization/cultures'))
    .map(response => ({ type: ct.CultureActionTypes.ReceivedCultures, cultures: response.cultures }));

export const loadCulturesEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        ct.CultureActionTypes.RequestCultures,
        action => loadCultures(),
        err => actionCreators.loadCulturesError(err));
