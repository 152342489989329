
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as esc from './types'; 
import { Time } from '../../global/types';
import { IStore } from '../..';


export type TaskDefinitionActions = esc.LoadBookingEmailScheduleConfigs | esc.ReceiveBookingEmailScheduleConfigs | esc.SaveBookingEmailScheduleConfig | esc.BookingEmailScheduleConfigSaved | esc.BookingEmailScheduleConfigSaveFailed | esc.EditBookingEmailScheduleConfig;

export const actionCreators = {
    loadBookingEmailScheduleConfigs: () => ({ type: esc.BookingEmailScheduleConfigActionTypes.RequestBookingEmailScheduleConfigs }),
    receivedBookingEmailScheduleConfigs: (configs: esc.BookingEmailScheduleConfig[], err: api.ApiError | null) => ({ type: esc.BookingEmailScheduleConfigActionTypes.ReceivedBookingEmailScheduleConfigs, configs: configs, error: err }),
    editBookingEmailScheduleConfig: () => ({ type: esc.BookingEmailScheduleConfigActionTypes.EditBookingEmailScheduleConfig }),
    saveBookingEmailScheduleConfig: (isNew: boolean, bookingEmailScheduleConfigId: string | null, config: esc.BookingEmailScheduleConfig) => ({ type: esc.BookingEmailScheduleConfigActionTypes.SaveBookingEmailScheduleConfig, isNew: isNew, bookingEmailScheduleConfigId: bookingEmailScheduleConfigId, config: config }),
    bookingEmailScheduleConfigSaved: (bookingEmailScheduleConfigId: string) => ({ type: esc.BookingEmailScheduleConfigActionTypes.BookingEmailScheduleConfigSaved, bookingEmailScheduleConfigId: bookingEmailScheduleConfigId }),
    bookingEmailScheduleConfigSaveFailed: (error: api.ApiError) => ({ type: esc.BookingEmailScheduleConfigActionTypes.BookingEmailScheduleConfigSaveFailed, error: error })
}

interface IGetBookingEmailScheduleConfigsResponse {
    configs: esc.BookingEmailScheduleConfig[];
}

interface ISaveBookingEmailScheduleConfigResponse {
    bookingEmailScheduleConfigId: string;
}

export const handleClientChange = (store: IStore) => [actionCreators.loadBookingEmailScheduleConfigs]

const mapConfig = (c: any) => {
    const { timeOfDayToSend, ...config } = c;

    return {
        timeOfDayToSend: timeOfDayToSend ? Time.parse(timeOfDayToSend) : null,
        ...config
    }
}

const loadBookingEmailScheduleConfigs = () => Observable.defer(() => api.getJson<IGetBookingEmailScheduleConfigsResponse>('api/v1/bookingEmailScheduleConfig/'))
    .map(response => actionCreators.receivedBookingEmailScheduleConfigs(response.configs.map(c => mapConfig(c)), null));

export const loadBookingEmailScheduleConfigsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        esc.BookingEmailScheduleConfigActionTypes.RequestBookingEmailScheduleConfigs,
        action => loadBookingEmailScheduleConfigs(),
        err => actionCreators.receivedBookingEmailScheduleConfigs([], err));

export const reloadBookingEmailScheduleConfigsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        esc.BookingEmailScheduleConfigActionTypes.BookingEmailScheduleConfigSaved,
        action => loadBookingEmailScheduleConfigs(),
        err => actionCreators.receivedBookingEmailScheduleConfigs([], err));

export const saveBookingEmailScheduleConfigEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        esc.BookingEmailScheduleConfigActionTypes.SaveBookingEmailScheduleConfig,
        action => {
            const stda = action as esc.SaveBookingEmailScheduleConfig;
            var body = { ...stda.config, timeOfDayToSend: stda.config.timeOfDayToSend ? stda.config.timeOfDayToSend.toString() : null };

            return (stda.isNew ? api.post('api/v1/bookingEmailScheduleConfig/', body) : api.put(`api/v1/bookingEmailScheduleConfig/${stda.bookingEmailScheduleConfigId}`, body))
                .map(response => {

                    let bookingEmailScheduleConfigId = stda.bookingEmailScheduleConfigId;
                    if (stda.isNew) {
                        const strr = response.response as ISaveBookingEmailScheduleConfigResponse;
                        if (strr) {
                            bookingEmailScheduleConfigId = strr.bookingEmailScheduleConfigId;
                        }
                    }

                    return ({ type: esc.BookingEmailScheduleConfigActionTypes.BookingEmailScheduleConfigSaved, bookingEmailScheduleConfigId: bookingEmailScheduleConfigId });
                });
        },
        (err: api.ApiError) => actionCreators.bookingEmailScheduleConfigSaveFailed(err));
