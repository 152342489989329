
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { Resource } from '../../../../store/pages/resources/types';
import ResourceForm from './resourceForm';
import Loading from '../../../global/loading';
import { clickHandler } from '../../../../utils/util';


interface ResourceListProps {
    venueId: string;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;

    resources: Resource[];
    isLoadingResources: boolean;
    editResource: () => void;
}

interface ResourceListState {
    showArchived: boolean;
}

class ResourceList extends React.Component<ResourceListProps, ResourceListState> {

    constructor(props: ResourceListProps) {
        super(props);

        this.state = { showArchived: false }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private editResource = (resource: Resource) => {
        this.showOverlay(resource, false);
    }

    private addResource = () => {
        this.showOverlay(null, true);
    }

    private showOverlay = (resource: Resource | null, addingNew: boolean) => {
        this.props.editResource();
        this.props.showModal(<ResourceForm isNew={addingNew} venueId={this.props.venueId} resource={resource} />, 'ResourceForm');
    }

    render() {
        const { showArchived } = this.state;
        const { t } = this.context;
        const body = this.props.isLoadingResources ? <Loading /> : this.renderResourcesList();

        return <section className='venue-item-list'>
            <header className='section-header'>
                <div className='section-actions'>
                    <label>
                        <input type='checkbox' checked={showArchived} onChange={e => this.setState({ showArchived: e.currentTarget.checked })} />
                        <span style={({ marginLeft: '10px' })}>{t('Global:showArchived')}</span>
                    </label>
                    <button className='btn btn-info' onClick={e => clickHandler(e, this.addResource)}>{t('ResourceList:addResource')}</button>
                </div>
            </header>
            <div className='at-panel sub-section-panel-240px'>
                {body}
            </div>
        </section>;
    }
    
    private renderResourcesList = () => {
        const { showArchived } = this.state;
        const { resources, venueId } = this.props;

        resources.sort((a, b) => a.sequence - b.sequence);

        const resourceList = resources.filter(r => r.venueId === venueId && (showArchived || !r.archived)).map(v => {

                const headerStyle = { backgroundColor: v.colour, color: '#FFF' };

                return <li key={v.id} className='card'>
                    <div className='card-content'>
                        <div className='card-header' style={headerStyle}><h2>{v.name}</h2></div>
                        <div className='card-body'></div>
                        <div className='card-footer'>
                            <button className='btn btn-link card-edit' onClick={e => clickHandler(e, () => this.editResource(v))}>{this.context.t('Global:edit')}</button>
                               </div>
                           </div>
                       </li>;
            }
        );

        return <ul className='card-list'>{resourceList}</ul>;
    }

}

export default ResourceList;
