
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';

interface ModalProps {
    showOverlay: boolean;
    resetScroll?: boolean;
    overlayComponent: JSX.Element | null;
    noScroll?: boolean;
}

const Modal = (props: ModalProps) => (
    <div className={`overlay overlay-form ${props.showOverlay ? 'open' : 'close'}`}>
        <div className={`overlay-content ${(props.noScroll ? 'overlay-content-noscroll' : '')}`}>
            {props.overlayComponent}
        </div>
    </div>
);


const mapStateToProps = (state: ApplicationState): ModalProps => ({
    showOverlay: state.modal.showOverlay,
    resetScroll: state.modal.resetScroll,
    overlayComponent: state.modal.overlayComponent,
    noScroll: state.modal.noScroll
});

export default connect(mapStateToProps, {}, null, { pure: false })(Modal);
