
import { Action } from 'redux';

import { StatusActionTypes, StatusState } from './types';
import { StatusActions } from './actions'
import { isNullOrEmpty } from '../../../utils/util';

const unloadedState: StatusState = { isConnected: false, lastConnectionTime: null, lastConnectionError: null, lastErrorMessage: null, lastConnectionAttempt: null, connectionErrors: [], lastMessages: [] };

// Not using createReduced as that clears state when logged out. but we need to retain the state of the signalr connection at all times
export const reducer = (state: StatusState, incomingAction: Action) => reducerHandler(state, incomingAction);

export const reducerHandler = (state: StatusState, incomingAction: Action): StatusState => {

    const action = incomingAction as StatusActions;
    switch (action.type) {
        case StatusActionTypes.SetConnected:
            return {
                ...state,
                isConnected: true,
                lastConnectionTime: new Date(),
                lastConnectionError: null
            };
        case StatusActionTypes.SetDisconnected:
            return {
                ...state,
                isConnected: false,
                lastConnectionError: new Date(),
                lastErrorMessage: isNullOrEmpty(action.message) ? state.lastErrorMessage : action.message,
                connectionErrors: state.connectionErrors.concat([{ time: new Date(), message: action.message }])
            };
        case StatusActionTypes.AddError:
            return {
                ...state,
                connectionErrors: [action.error].concat(state.connectionErrors).filter(m => !isNullOrEmpty(m.message)).filter((m, ix) => ix < 10)
            };
        case StatusActionTypes.AddMessage:
            // Store the last 20 messages, with laest as first entry
            return {
                ...state,
                lastMessages: [action.message].concat(state.lastMessages).filter((m, ix) => ix < 20)
            };
        case StatusActionTypes.SetLastConnectionAttempt:
            // Store the last 20 messages, with laest as first entry
            return {
                ...state,
                lastConnectionAttempt: action.time
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

