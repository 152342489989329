
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as api from '../../../store/apiClient';

import ApiError from '../../global/apiError';

import { clickHandler } from '../../../utils/util';
import { ISendEmailResponse } from './types';

interface SendMembershipEmailOverlayProps {
    venueId: string;
    membershipIds: string[];
    customerName: string;
    customerEmail: string;
    close: () => void;
    logout: () => void;
}

interface SendMembershipEmailOverlayState {
    sendingMemberEmail: boolean;
    membershipEmailSent: boolean;
    membershipEmailError: api.ApiError | string | null;
}

class SendMembershipEmailOverlay extends React.Component<SendMembershipEmailOverlayProps, SendMembershipEmailOverlayState> {

    constructor(props: SendMembershipEmailOverlayProps) {
        super(props);

        this.state = {
            sendingMemberEmail: false,
            membershipEmailSent: false,
            membershipEmailError: null
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        this.sendEmails();
    }

    sendEmails = () => {
        const { t } = this.context;
        const { venueId, membershipIds, logout } = this.props;

        this.setState({ sendingMemberEmail: true, membershipEmailSent: false, membershipEmailError: null }, () => {
            api.postWithAuth(`api/v1/email/sendMembershipEmail`, { venueId: venueId, membershipIds: membershipIds }, logout)
                .subscribe(response => {
                    const ser = response.response as ISendEmailResponse;
                    if (ser && ser.sent) {
                        this.setState(s => ({ sendingMemberEmail: false, membershipEmailSent: true, membershipEmailError: null }));
                    } else {
                        this.setState(s => ({ sendingMemberEmail: false, membershipEmailSent: false, membershipEmailError: ser.error }));
                    }
                }, (err: api.ApiError) => {
                    this.setState(s => ({ sendingMemberEmail: false, membershipEmailSent: false, membershipEmailError: err }));
                })
        });
    }

    render() {
        const { t } = this.context;
        const { customerName, customerEmail } = this.props;
        const { sendingMemberEmail, membershipEmailSent, membershipEmailError } = this.state;

        return <div style={({ width: '100%' })} className='center'>
            <h3>{t('SendMembershipEmailOverlay:heading')}</h3>

            <form className='data-form' onSubmit={e => e.preventDefault()} autoComplete='off'>
                {sendingMemberEmail ? <div className='alert alert-info'>{t('SendMembershipEmailOverlay:sendingMembershipEmail', { customerName: customerName, customerEmail: customerEmail })}</div> : null}

                {membershipEmailSent ? <div className='alert alert-success'>{t('SendMembershipEmailOverlay:emailSent')}</div> : null}

                {membershipEmailError ? <ApiError error={membershipEmailError} /> : null}

                <button onClick={e => clickHandler(e, this.props.close)} className='btn btn-basic' disabled={sendingMemberEmail}>{t('Global:close')}</button>
            </form>
        </div>
    }
}

SendMembershipEmailOverlay.contextTypes = {
    t: PropTypes.func
}

export default SendMembershipEmailOverlay;