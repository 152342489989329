
// https://blog.angularindepth.com/how-to-reduce-action-boilerplate-90dc3d389e2b

import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as ct from './types';

export type ClientActions = ct.LoadClients | ct.ReceiveClients | ct.SaveClient | ct.ClientSaved | ct.ClientSaveFailed | ct.EditClient;

export const actionCreators = {
    loadClients: () => ({ type: ct.ClientActionTypes.RequestClients }),
    receivedClients: (clients: ct.IClient[], err: api.ApiError | null) => ({ type: ct.ClientActionTypes.ReceivedClients, clients: clients, error: err }),
    editClient: () => ({ type: ct.ClientActionTypes.EditClient }),
    saveClient: (isNew: boolean, clientId: number | null, client: ct.IClient, logoImg: File | null) => ({ type: ct.ClientActionTypes.SaveClient, isNew: isNew, clientId: clientId, client: client, logoImg: logoImg }),
    clientSaved: (clientId: number) => ({ type: ct.ClientActionTypes.ClientSaved, clientId: clientId }),
    clientSaveFailed: (error: api.ApiError, validationErrors?: any) => ({ type: ct.ClientActionTypes.ClientSaveFailed, error: error, validationErrors: validationErrors })
}

interface IGetClientsResponse {
    clients: ct.IClient[];
}

interface ISaveClientResponse {
    clientId: number;
}

//https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-after-catching-and-processing-an-error-with-emitting-somethi

const loadClients = () => Observable.defer(() => api.getJson<IGetClientsResponse>('api/v1/client/'))
        .map(response => actionCreators.receivedClients(response.clients, null));

export const loadClientsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        ct.ClientActionTypes.RequestClients,
        action => loadClients(),
        err => actionCreators.receivedClients([], err));

export const reloadClientsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        ct.ClientActionTypes.ClientSaved,
        action => loadClients(),
        err => actionCreators.receivedClients([], err));

const saveClient = (isNew: boolean, clientId: number, client: ct.IClient, logoImg: File | null) => {
    const first = logoImg ? api.uploadFile(logoImg, 'api/v1/clientimage').flatMap(res => {
        return [res.response.imageId];
    }) : Observable.of(null);

    return first.flatMap(imgId => {
        const { logoImageId, ...clientArgs } = client;
        const body = imgId ? { ...clientArgs, logoImageId: imgId } : { ...clientArgs };
        return isNew ? api.post('api/v1/client/', body) : api.put(`api/v1/client/${clientId}`, body);
    });
}

export const saveClientEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        ct.ClientActionTypes.SaveClient,
        action => {
            const sca = action as ct.SaveClient;

            return saveClient(sca.isNew, sca.clientId || 0, sca.client, sca.logoImg)
                .map(response => {

                    let clientId = sca.clientId;
                    if (sca.isNew) {
                        const sur = response.response as ISaveClientResponse;
                        if (sur) {
                            clientId = sur.clientId;
                        }
                    }

                    return ({ type: ct.ClientActionTypes.ClientSaved, clientId: clientId });
                });
        },
        (err: api.ApiError) => actionCreators.clientSaveFailed(err));
