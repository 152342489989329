
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router-dom';

import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import * as api from '../../../store/apiClient';

import * as LoginActions from '../../../store/pages/login/actions';
import * as ModalActions from '../../../store/global/modal/actions';

import { ApplicationState } from '../../../store';
import { clickHandler, formatCurrencyVal, formatPercentage, isNullOrEmpty, parseUtcDate } from '../../../utils/util';
import { MarketingCampaign, MarketingCampaignRun, MarketingCampaignRunStatus, MarketingCampaignState } from '../../../store/pages/campaigns/types';
import ApiError from '../../global/apiError';
import { isSystemAdmin } from '../../../utils/auth';
import Loading from '../../global/loading';
import CampaignState from './campaignState';
import CampaignCompetitors from './campaignCompetitors';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';

enum State {
    None = 0,
    Sending = 1,
    Sent = 2
}

interface GetCustomerCountResponse {
    customerCount: number;
    excludedCustomerCount: number;
}

interface GetRunsResponse {
    runs: MarketingCampaignRun[];
}

interface MappedReduxState {
    campaigns: MarketingCampaign[];
    loadingCampaigns: boolean;
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
}

interface Actions {
    logout: () => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
}

interface CampaignDetailsPageRouteProps {
    campaignId: string;
}

type CampaignDetailsPageProps = MappedReduxState & Actions & RouteComponentProps<CampaignDetailsPageRouteProps>;

interface CampaignDetailsPageState {
    campaign: MarketingCampaign | null;
    sendToAddress: ct.FormValue<string>;
    testCustomerFirstName: ct.FormValue<string>;
    testCustomerLastName: ct.FormValue<string>;
    error: string | null;
    state: State;
    includedCustomerCount: number | null;
    excludedCustomerCount: number | null;
    loadingCustomerCount: boolean;
    getCustomerCountError: api.ApiError | null;
    runs: MarketingCampaignRun[];
    loadingRuns: boolean;
    showDiscardedRuns: boolean;
}

class CampaignDetailsPage extends React.Component<CampaignDetailsPageProps, CampaignDetailsPageState> {

    constructor(props: CampaignDetailsPageProps) {
        super(props);

        this.state = this.buildStateFromProps(props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps(props: CampaignDetailsPageProps): CampaignDetailsPageState {
        const campaignId = props.match.params.campaignId;
        const campaign = props.campaigns.find(c => c.id === campaignId);

        return {
            campaign: campaign ? campaign : null,
            sendToAddress: this.validateSendToAddress(''),
            testCustomerFirstName: this.validateTestCustomerFirstName(''),
            testCustomerLastName: this.validateTestCustomerLastName(''),
            error: null,
            state: State.None,
            includedCustomerCount: null,
            excludedCustomerCount: null,
            loadingCustomerCount: false,
            getCustomerCountError: null,
            runs: [],
            loadingRuns: false,
            showDiscardedRuns: false
        };
    }

    validateSendToAddress = (val: string) => v.validate(val, 'sendToAddress', [v.required], []);
    validateTestCustomerFirstName = (val: string) => v.validate(val, 'testCustomerFirstName', [], []);
    validateTestCustomerLastName = (val: string) => v.validate(val, 'testCustomerLastName', [], []);

    componentDidMount() {
        const { campaign } = this.state;
        if (campaign !== null) {
            this.loadRuns(campaign.id);
            this.getCustomerCount();
        }
    }

    componentDidUpdate(prevProps: CampaignDetailsPageProps, prevState: CampaignDetailsPageState) {
        if (!this.state.campaign && this.props.campaigns.length > 0) {
            const campaign = this.props.campaigns.find(c => c.id === this.props.match.params.campaignId);
            if (campaign) {
                this.setState({ campaign: campaign });
            }
        }

        if (this.state.state === State.Sent && prevState.state !== State.Sent) {
            setTimeout(() => this.setState({state: State.None}), 5000)
        }

        if (this.state.campaign !== null && prevState.campaign === null) {
            this.loadRuns(this.state.campaign.id);
            this.getCustomerCount();
        }
    }

    loadRuns = (campaignId: string) => {
        this.setState({ loadingRuns: true });

        api.getWithAuth<GetRunsResponse>(`api/v1/campaign/${campaignId}/runs`, this.props.logout)
            .subscribe(
                res => this.setState({ loadingRuns: false, runs: res.runs.map(r => ({ ...r, createDateTime: parseUtcDate(r.createDateTime) })) }),
                err => this.setState({ loadingRuns: false, runs: [] }))
    }

    close = () => {
        const { history } = this.props;
        history.push({ pathname: '/campaigns/' });
    }

    editCampaign = () => {
        const { history } = this.props;
        const { campaign } = this.state;

        if (!campaign) return;

        history.push({ pathname: `/campaign/${campaign.id}/edit` });
    }

    changeStatus = (newState: MarketingCampaignState) => {
        const { campaign } = this.state;

        if (!campaign) return;

        api.postWithAuth('api/v1/campaign/changeState', { campaignId: campaign.id, newState: newState }, this.props.logout)
            .subscribe(
                res => this.setState({ error: null, campaign: {...campaign, state: newState} }),
                err => this.setState({ error: err.message, state: State.None }))
    }

    send = () => {
        const { campaign, sendToAddress, testCustomerFirstName, testCustomerLastName } = this.state;
        if (!v.isValid(this.state) || !campaign) {
            this.setState({ error: 'Global:formNotValid', state: State.None  });
        } else {
            //*** hook this up
            this.setState({ error: null, state: State.Sending });

            api.postWithAuth('api/v1/campaign/test', {
                campaignId: campaign.id,
                toEmailAddress: sendToAddress.value,
                customerFirstName: testCustomerFirstName.value,
                customerLastName: testCustomerLastName.value
            }, this.props.logout)
                .subscribe(
                    res => this.setState({ error: null, state: State.Sent }),
                    err => this.setState({ error: err.message, state: State.None }))
        }
    }

    getCustomerCount = () => {
        const { campaign } = this.state;

        if (!campaign) return;

        this.setState({ loadingCustomerCount: true, getCustomerCountError: null })

        api.getWithAuth<GetCustomerCountResponse>(`api/v1/campaign/${campaign.id}/customerCount`, this.props.logout)
            .subscribe(
                resp => this.setState({ includedCustomerCount: resp.customerCount, excludedCustomerCount: resp.excludedCustomerCount, loadingCustomerCount: false, getCustomerCountError: null }),
                err => this.setState({ includedCustomerCount: null, excludedCustomerCount: null, loadingCustomerCount: false, getCustomerCountError: err }))
    }


    viewCustomers = (acceptedMarketing: boolean) => {
        const { match, showModal, closeModal, logout } = this.props;
        const campaignId = match.params.campaignId;
        showModal(<CampaignCompetitors campaignId={campaignId} acceptedMarketing={acceptedMarketing} close={closeModal} logout={logout} />, 'CampaignCompetitors')
    }

    viewIncludedCustomers = () => this.viewCustomers(true);

    viewExcludedCustomers = () => this.viewCustomers(false);

    render() {
        const { t } = this.context;
        const { loadingCampaigns, timeFormat, dateFormat } = this.props;
        const { campaign, sendToAddress, testCustomerFirstName, testCustomerLastName, error, state, includedCustomerCount, excludedCustomerCount, loadingCustomerCount,
            getCustomerCountError, runs, loadingRuns, showDiscardedRuns } = this.state;

        if (loadingCampaigns) {
            return <Loading />
        }

        if (!campaign) {
            return <div>{t('CampaignDetailsPage:campaignNotFound')}</div>
        }

        return <div>
            <h2>{campaign.name} <button className='btn btn-info' onClick={e => clickHandler(e, this.editCampaign)}>{t('Global:edit')}</button></h2>

            <div className='row mt-15'>
                <div className='col-xs-12 col-md-9 col-lg-6'>
                    <div className='panel panel-info'>
                        <div className='panel-heading'>
                            {t('CampaignDetailsPage:customerSegmentDetails')}
                            <button className='btn btn-sm btn-info pull-right' onClick={e => clickHandler(e, this.getCustomerCount)} disabled={loadingCustomerCount} style={{ marginTop: '-5px'}}>
                                <span className='glyphicon glyphicon-refresh'></span>
                            </button>
                        </div>
                        <div className='panel-body'>
                            {includedCustomerCount !== null ? <div className='campaign-customer-count' dangerouslySetInnerHTML={{ __html: t('CampaignDetailsPage:affectedCustomers', { customerCount: `<span>${includedCustomerCount}</span>` }) }}></div> : null}
                            {includedCustomerCount !== null && includedCustomerCount > 0 ? <button className='btn btn-link' onClick={e => clickHandler(e, this.viewIncludedCustomers)} disabled={loadingCustomerCount}>{t('Global:view')}</button> : null}
                            {excludedCustomerCount !== null ? <div className='campaign-excluded-customer-count' dangerouslySetInnerHTML={{ __html: t('CampaignDetailsPage:nonMarketingCustomers', { excludedCustomerCount: excludedCustomerCount }) }}></div> : null}
                            {excludedCustomerCount !== null && excludedCustomerCount > 0 ? <button className='btn btn-link' onClick={e => clickHandler(e, this.viewExcludedCustomers)} disabled={loadingCustomerCount}>{t('Global:view')}</button> : null}
                            {getCustomerCountError ? <ApiError error={getCustomerCountError} /> : null}
                        </div>
                    </div>
                </div>
            </div>

            <div className='row mt-15'>
                <div className='col-xs-12 col-md-9 col-lg-6'>
                    <div className='panel panel-info'>
                        <div className='panel-heading'>{t('CampaignDetailsPage:campaignStatus')}</div>
                        <div className='panel-body'>
                            <label>{t('CampaignDetailsPage:status')}</label> <CampaignState state={campaign.state} />
                            <div className='pull-right' style={{ display: 'inline-block', marginLeft: '15px' }}>{this.renderStatusActions(campaign.state)}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row mt-15'>
                <div className='col-xs-12 col-md-9 col-lg-6'>
                    <div className='panel panel-info'>
                        <div className='panel-heading'>{t('CampaignDetailsPage:testCampaign')}</div>
                        <div className='panel-body'>
                            <ct.Email id='sendToAddress' labelKey='CampaignDetailsPage:sendToAddress' placeholderKey='CampaignDetailsPage:sendToAddress' value={sendToAddress} callback={val => this.setState({ sendToAddress: this.validateSendToAddress(val) })} />

                            <div className='row form-group'>
                                <div className='col-xs-6'>
                                    <ct.TextBox id='testCustomerFirstName' labelKey='Global:firstName' value={testCustomerFirstName} callback={val => this.setState({ testCustomerFirstName: this.validateTestCustomerFirstName(val) })} />
                                </div>
                                <div className='col-xs-6'>
                                    <ct.TextBox id='testCustomerLastName' labelKey='Global:lastName' value={testCustomerLastName} callback={val => this.setState({ testCustomerLastName: this.validateTestCustomerLastName(val) })} />
                                </div>
                            </div>

                            {!isNullOrEmpty(error) ? <div className='alert alert-danger'>{t(error)}</div> : null}
                            {state === State.Sent ? <div className='alert alert-success'>{t('CampaignDetailsPage:emailSent')}</div> : null}

                            <div className='btn-toolbar'>
                                <button className='btn btn-primary' onClick={e => clickHandler(e, this.send)} disabled={state === State.Sending}>{t('CampaignPage:SendTestEmail')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row mt-15'>
                <div className='col-xs-12'>
                    <div className='panel panel-info'>
                        <div className='panel-heading'>
                            <span>{t('CampaignDetailsPage:runs')}</span>
                            {isSystemAdmin() ? <label className='pull-right'>
                                <input type='checkbox' checked={showDiscardedRuns} onChange={e => this.setState({ showDiscardedRuns: e.currentTarget.checked })} />
                                <span style={({ marginLeft: '10px' })}>{t('CampaignDetailsPage:showDiscardedRuns')}</span>
                            </label> : null }
                        </div>
                        <div className='panel-body'>
                            <table className='table table-condensed'>
                                <thead>
                                    <tr>
                                        <th>{t('CampaignDetailsPage:RunDate')}</th>
                                        <th>{t('CampaignDetailsPage:RunStatus')}</th>
                                        <th>{t('CampaignDetailsPage:sentCount')}</th>
                                        <th>{t('CampaignDetailsPage:deliveredCount')}</th>
                                        <th>{t('CampaignDetailsPage:openedCount')}</th>
                                        <th>{t('CampaignDetailsPage:clickedCount')}</th>
                                        <th>{t('CampaignDetailsPage:bouncedCount')}</th>
                                        <th>{t('CampaignDetailsPage:droppedCount')}</th>
                                        <th>{t('CampaignDetailsPage:spamreportedCount')}</th>
                                        <th>{t('CampaignDetailsPage:unsubscribedCount')}</th>
                                        <th colSpan={2} className='center'>{t('CampaignDetailsPage:conversionCount')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loadingRuns
                                        ? <tr key='loading'><td colSpan={3}>{t('CampaignDetailsPage:loadingRuns')}</td></tr>
                                        : runs
                                            .sort((r1, r2) => r2.createDateTime.getTime() - r1.createDateTime.getTime())
                                            .filter(r => showDiscardedRuns || r.state !== MarketingCampaignRunStatus.Discarded)
                                            .map(r => <tr key={r.id}>
                                                <td>{r.createDateTime.toAbbrDateTimeString(timeFormat, dateFormat, t)}</td>
                                                <td>{t(`MarketingCampaignRunStatus:${MarketingCampaignRunStatus[r.state]}`)}</td>
                                                <td><span className='campaign-total'>{r.sent}</span></td>
                                                <td><span className='campaign-total'>{r.delivered}</span> {formatPercentage(r.delivered, r.sent)}</td>
                                                <td><span className='campaign-total'>{r.opened}</span> {formatPercentage(r.opened, r.sent)}</td>
                                                <td><span className='campaign-total'>{r.clicked}</span> {formatPercentage(r.clicked, r.sent)}</td>
                                                <td><span className='campaign-total'>{r.dropped}</span> {formatPercentage(r.dropped, r.sent)}</td>
                                                <td><span className='campaign-total'>{r.bounced}</span> {formatPercentage(r.bounced, r.sent)}</td>
                                                <td><span className='campaign-total'>{r.spamreported}</span> {formatPercentage(r.spamreported, r.sent)}</td>
                                                <td><span className='campaign-total'>{r.unsubscribed}</span> {formatPercentage(r.unsubscribed, r.sent)}</td>
                                                <td><span className='campaign-total'>{r.conversions}</span> {formatPercentage(r.conversions, r.sent)}</td>
                                                <td className='text-right'><span className='campaign-total'>{formatCurrencyVal(r.conversionValue, t)}</span></td>
                                            </tr>)
                                        }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className='btn-toolbar'>
                <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)} disabled={state === State.Sending}>{t('Global:close')}</button>
            </div>
        </div>
    }

    renderStatusActions = (state: MarketingCampaignState) => {
        const { t } = this.context;

        switch (state) {
            case MarketingCampaignState.Draft:
            case MarketingCampaignState.Paused:
                return <button className='btn btn-success' onClick={e => clickHandler(e, () => this.changeStatus(MarketingCampaignState.Active))}>{t('CampaignDetailsPage:activate')}</button>
            case MarketingCampaignState.Active:
                return <button className='btn btn-warning' onClick={e => clickHandler(e, () => this.changeStatus(MarketingCampaignState.Paused))}>{t('CampaignDetailsPage:pause')}</button>
            case MarketingCampaignState.Complete:
                return null;
        }
    }

}


const mapStateToProps = (state: ApplicationState) => {
    const venueId = state.venues.selectedVenueId;
    const venue = state.venues.venues.find(v => v.id === venueId);

    return {
        campaigns: state.campaigns.campaigns,
        loadingCampaigns: state.campaigns.isLoading,
        timeFormat: venue ? venue.timeFormat : TimeFormat.TwentyFourHour
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    logout: bindActionCreators(LoginActions.actionCreators.logout, dispatch),
    showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(CampaignDetailsPage);



