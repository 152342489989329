
import { Action } from 'redux';

import { CustomerActionTypes, CustomerState } from './types';
import { CustomerActions } from './actions'
import { createReducer } from '../../reducer';
import { parseLocalDateTime } from '../../../utils/util';

const unloadedState: CustomerState = {
    isLoading: false,
    customers: [],
    customerCount: -1,
    loadError: null,
    pageSize: 25,
    pageNumber: 1,
    maxPage: 0,
    nameFilter: '',
    marketingPreferenceFilter: null,
    venueSelection: null,
    lastVisitFrom: null,
    lastVisitTo: null,
    customerCreatedFrom: null,
    customerCreatedTo: null,
    minVisits: null,
    tagId: null,
    minAge: null,
    maxAge: null,
    bookedActivityFormatIds: [],
    participantActivityFormatIds: []
};


export const reducer = (state: CustomerState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: CustomerState, incomingAction: Action): CustomerState => {
    const action = incomingAction as CustomerActions;
    switch (action.type) {
        case CustomerActionTypes.RequestCustomers:
            return {
                ...state,
                isLoading: true,
                nameFilter: action.nameFilter,
                marketingPreferenceFilter: action.marketingPreferenceFilter,
                venueSelection: action.venueSelection,
                lastVisitFrom: action.lastVisitFrom,
                lastVisitTo: action.lastVisitTo,
                customerCreatedFrom: action.customerCreatedFrom,
                customerCreatedTo: action.customerCreatedTo,
                minVisits: action.minVisits,
                minAge: action.minAge,
                maxAge: action.maxAge,
                tagId: action.tagId,
                bookedActivityFormatIds: action.bookedActivityFormatIds,
                participantActivityFormatIds: action.participantActivityFormatIds,
                pageNumber: action.pageNumber,
                pageSize: action.pageSize,
                loadError: null
            };
        case CustomerActionTypes.ReceivedCustomers:
            return {
                ...state,
                isLoading: false,
                customers: action.customers.map(c => ({
                    ...c,
                    lastBooking: c.lastBooking.map(lb => ({ ...lb, date: parseLocalDateTime(lb.date)})),
                    lastVisit: c.lastVisit.map(lv => ({ ...lv, date: parseLocalDateTime(lv.date) })),
                    created: parseLocalDateTime(c.created),
                })),
                customerCount: action.customerCount,
                maxPage: action.maxPage,
                pageNumber: action.error ? 1 : state.pageNumber,
                loadError: action.error
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

