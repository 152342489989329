
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as pmt from './types'; 
import * as lt from '../login/types';
import { isNullOrEmpty } from '../../../utils/util';
import { IStore } from '../..';


export type PaymentMethodActions = pmt.LoadPaymentMethods | pmt.ReceivePaymentMethods | pmt.SavePaymentMethod | pmt.PaymentMethodSaved | pmt.PaymentMethodSaveFailed | pmt.EditPaymentMethod;

export const actionCreators = {
    loadPaymentMethods: () => ({ type: pmt.PaymentMethodActionTypes.RequestPaymentMethods }),
    loadPaymentMethodsComplete: (paymentMethods: pmt.PaymentMethod[], err: api.ApiError | null) => ({ type: pmt.PaymentMethodActionTypes.ReceivedPaymentMethods, paymentMethods: paymentMethods, error: err }),
    editPaymentMethod: () => ({ type: pmt.PaymentMethodActionTypes.EditPaymentMethod }),
    savePaymentMethod: (isNew: boolean, paymentMethodId: string | null, venueId: string, paymentMethod: pmt.PaymentMethod) => ({ type: pmt.PaymentMethodActionTypes.SavePaymentMethod, isNew: isNew, paymentMethodId: paymentMethodId, venueId: venueId, paymentMethod: paymentMethod }),
    paymentMethodSaved: (paymentMethodId: string, paymentMethodIntegrationKey: string) => ({ type: pmt.PaymentMethodActionTypes.PaymentMethodSaved, paymentMethodId: paymentMethodId, paymentMethodIntegrationKey: paymentMethodIntegrationKey }),
    paymentMethodSaveFailed: (error: api.ApiError) => ({ type: pmt.PaymentMethodActionTypes.PaymentMethodSaveFailed, error: error })
}

interface IGetPaymentMethodsResponse {
    paymentMethods: pmt.PaymentMethod[];
}

interface ISavePaymentMethodResponse {
    paymentMethodId: string;
    paymentMethodIntegrationKey: string;
}

export const handleClientChange = (store: IStore) => [actionCreators.loadPaymentMethods]


//https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-after-catching-and-processing-an-error-with-emitting-somethi

const loadPaymentMethods = () => Observable.defer(() => api.getJson<IGetPaymentMethodsResponse>('api/v1/paymentMethod/'))
    .map(response =>  actionCreators.loadPaymentMethodsComplete(response.paymentMethods, null));

export const loadPaymentMethodsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        pmt.PaymentMethodActionTypes.RequestPaymentMethods,
        action => loadPaymentMethods(),
        err => actionCreators.loadPaymentMethodsComplete([], err));

export const reloadPaymentMethodsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        pmt.PaymentMethodActionTypes.PaymentMethodSaved,
        action => loadPaymentMethods(),
        err => actionCreators.loadPaymentMethodsComplete([], err));

export const savePaymentMethodEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        pmt.PaymentMethodActionTypes.SavePaymentMethod,
        action => {
            const spma = action as pmt.SavePaymentMethod;
            var pm = { ...spma.paymentMethod };
            var body = {
                ...pm,
                venueId: spma.venueId,
                extraFields: pm.extraFields.map(f => ({ ...f, id: isNullOrEmpty(f.id) ? null : f.id })),
                settings: pm.settings.map(s => ({ key: s.key, value: s.unsecuredValue, delete: s.delete }))
            };

            return (spma.isNew ? api.post('api/v1/paymentMethod/', body) : api.put(`api/v1/paymentMethod/${spma.paymentMethodId}`, body))
                .map(response => {

                    let paymentMethodId = spma.paymentMethodId;
                    let paymentMethodIntegrationKey = spma.paymentMethod.integrationKey;
                    if (spma.isNew) {
                        const strr = response.response as ISavePaymentMethodResponse;
                        if (strr) {
                            paymentMethodId = strr.paymentMethodId;
                            paymentMethodIntegrationKey = strr.paymentMethodIntegrationKey;
                        }
                    }

                    return ({ type: pmt.PaymentMethodActionTypes.PaymentMethodSaved, paymentMethodId: paymentMethodId, paymentMethodIntegrationKey: paymentMethodIntegrationKey });
                });
        },
        (err: api.ApiError) => actionCreators.paymentMethodSaveFailed(err));
