
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'
import { ApplicationState } from '../../../../store';
import * as PromotionActions from '../../../../store/pages/promotions/actions';
import * as ModalActions from '../../../../store/global/modal/actions';
import Loading from '../../../global/loading';
import { clickHandler } from '../../../../utils/util';
import { Promotion, PromotionDiscountType, PromotionItemType, PromotionStatus } from '../../../../store/pages/promotions/types';
import PromotionForm from './promotionForm';
import { Venue } from '../../../../store/pages/venues/types';

interface LocalProps {
    venue: Venue;
}

interface LocalState {
    isLoading: boolean;
    promotions: Promotion[];
}

interface Actions {
    editPromotion: () => void;
    loadPromotions: () => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
}

type PromotionsListProps = LocalState & Actions & LocalProps;

interface PromotionsListState {
    showExpired: boolean;
}

class PromotionsList extends React.Component<PromotionsListProps, PromotionsListState> {

    static contextTypes = {
        t: PropTypes.func
    }

    constructor(props: PromotionsListProps) {
        super(props);

        this.state = { showExpired: false }
    }

    private editPromotion = (user: Promotion) => {
        this.showOverlay(user, false);
    }

    private addPromotion = () => {
        this.showOverlay(null, true);
    }

    copyPromotion = (promotion: Promotion) => {
        const newPromotion = { ...promotion, id: '', name: '', code: '' };
        this.showOverlay(newPromotion, true);
    }

    private showOverlay = (promotion: Promotion | null, addingNew: boolean) => {
        const { dateFormat, timeFormat } = this.props.venue;
        this.props.editPromotion();
        this.props.showModal(<PromotionForm isNew={addingNew} venueId={this.props.venue.id} promotion={promotion ? promotion : null} dateFormat={dateFormat} timeFormat={timeFormat} />, 'PromotionForm');
    }

    formatStatus = (state: PromotionStatus) => {
        const { t } = this.context;
        switch (state) {
            case PromotionStatus.Active:
                return <span className='label label-success'>{t('Global:active')}</span>
            case PromotionStatus.Expired:
            case PromotionStatus.BudgetReached:
                return <span className='label label-danger'>{t('Global:expired')}</span>
            default:
                return null;
        }
    }

    render() {
        const { t } = this.context;
        const { showExpired } = this.state;

        const body = this.props.isLoading ? <Loading /> : this.renderPromotionsTable();

        return <section className='venue-item-list'>
            <header className='section-header'>
                <div className='section-actions'>
                    <button className='btn btn-info' onClick={e => clickHandler(e, this.addPromotion)}>{this.context.t('PromotionsPage:addPromotion')}</button>
                    <label className='pull-right'>
                        <input type='checkbox' checked={showExpired} onChange={e => this.setState({ showExpired: e.currentTarget.checked })} />
                        <span style={({ marginLeft: '10px' })}>{t('Global:showExpired')}</span>
                    </label>
                </div>
            </header>
            <div className='at-panel sub-section-panel-240px'>
                {body}
            </div>
        </section>;
    }

    private renderPromotionsTable() {
        const { t } = this.context;
        const { showExpired } = this.state;
        const { promotions, venue } = this.props;
        const today = new Date()
        const maxExipry = new Date(today.getFullYear(), today.getMonth(), today.getDate()).addHours(-24 * 7);

        const items = promotions.filter(p => p.venueId === venue.id && (showExpired || !p.endDate || p.endDate >= maxExipry)).map(p =>
            <tr key={p.id}>
                <td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.editPromotion(p))}>{p.name}</button></td>
                <td>{p.code}</td>
                <td>{this.formatStatus(p.state)}</td>
                <td>{p.startDate.toShortDateString(venue.dateFormat)}</td>
                <td>{p.endDate ? p.endDate.toShortDateString(venue.dateFormat) : ''}</td>
                <td>{p.validForEventsFrom ? p.validForEventsFrom.toShortDateString(venue.dateFormat) : ''}</td>
                <td>{p.validForEventsUntil ? p.validForEventsUntil.toShortDateString(venue.dateFormat) : ''}</td>
                <td className='text-right'>{p.maximumBudget ? `${t('Global:currencySymbol')}${p.maximumBudget.toFixed(2)}` : ''}</td>
                <td className='text-right'>{`${t('Global:currencySymbol')}${p.totalAmountRedeemed.toFixed(2)}`}</td>
                <td>{t(`PromotionItemType:${PromotionItemType[p.discountItemType]}`)}</td>
                <td className='text-right'>{p.discountType === PromotionDiscountType.Fixed ? `${t('Global:currencySymbol')}${p.discount.toFixed(2)}` : `${p.discount.toFixed(2)}%`}</td>
                <td><button className='btn btn-link' onClick={e => clickHandler(e, () => this.copyPromotion(p))}>{this.context.t('Global:copy')}</button></td>
            </tr>
        );

        return <table className='table table-condensed'>
            <thead>
                <tr>
                    <th>{t('PromotionsPage:nameHeading')}</th>
                    <th>{t('PromotionsPage:codeHeading')}</th>
                    <th></th>
                    <th>{t('PromotionsPage:startDate')}</th>
                    <th>{t('PromotionsPage:endDate')}</th>
                    <th>{t('PromotionsPage:firstEventDate')}</th>
                    <th>{t('PromotionsPage:lastEventDate')}</th>
                    <th className='text-right'>{t('PromotionsPage:budget')}</th>
                    <th className='text-right'>{t('PromotionsPage:redeemed')}</th>
                    <th>{t('PromotionsPage:typeHeading')}</th>
                    <th className='text-right'>{t('Global:amount')}</th>
                </tr>
            </thead>
            <tbody>
                {items}
            </tbody>
        </table>;
    }
}

const matStateToProps = (state: ApplicationState) => ({
    promotions: state.promotions.promotions,
    isLoading: state.promotions.isLoading,
})

const mapDispatchToProps = (dispatch: Dispatch) => (
    {
        showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
        closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
        loadPromotions: bindActionCreators(PromotionActions.actionCreators.loadPromotions, dispatch),
        editPromotion: bindActionCreators(PromotionActions.actionCreators.editPromotion, dispatch),
    }
);


// Wire up the React component to the Redux store
export default connect(
    matStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps        // Selects which action creators are merged into the component's props
)(PromotionsList);

