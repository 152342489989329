
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import StepHeader from './stepHeader';
import {RegistrationField} from '../../../store/pages/venues/types';
import { Customer, Gender } from '../../../store/pages/customer/types';
import NavButtons from './navButtons';
import { upper, properCase, isNullOrEmpty } from '../../../utils/util';

interface MissingFieldsProps {
    customer: Customer;
    requiredRegistrationFields: RegistrationField;
    headerText: string;
    cancel: () => void;
    next: (customer: Customer) => void;
}

interface MissingFieldsState {
    missingFields: RegistrationField;
    addressLine1: ct.FormValue<string>;
    postalCode: ct.FormValue<string>;
    gender: ct.FormValue<Gender>;
    emergencyContactName: ct.FormValue<string>;
    emergencyContactNumber: ct.FormValue<string>;
}

class MissingFields extends React.Component<MissingFieldsProps, MissingFieldsState> {

    constructor(props: MissingFieldsProps) {
        super(props);

        const { customer, requiredRegistrationFields } = props;

        let missingFields: RegistrationField = RegistrationField.None;

        if ((isNullOrEmpty(customer.addressLine1) || isNullOrEmpty(customer.postalCode)))
            missingFields = missingFields | RegistrationField.BasicAddress;

        if (customer.gender === Gender.NotProvided)
            missingFields = missingFields | RegistrationField.Gender;

        if (isNullOrEmpty(customer.emergencyContactName))
            missingFields = missingFields | RegistrationField.EmergencyContactName;

        if (isNullOrEmpty(customer.emergencyContactNumber))
            missingFields = missingFields | RegistrationField.EmergencyContactNumber;

        this.state = {
            missingFields: (missingFields & requiredRegistrationFields),
            addressLine1: this.validateAddressLine1(customer.addressLine1),
            postalCode: this.validatePostalCode(customer.postalCode),
            gender: this.validateGender(customer.gender),
            emergencyContactName: this.validateEmergencyContactName(customer.emergencyContactName),
            emergencyContactNumber: this.validateEmergencyContactNumber(customer.emergencyContactNumber),
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    validateAddressLine1 = (val: string) => v.validate(properCase(val), 'addressLine1', this.props.requiredRegistrationFields & RegistrationField.BasicAddress || this.props.requiredRegistrationFields & RegistrationField.FullAddress ? [v.required] : undefined, []);
    validatePostalCode = (val: string) => v.validate(upper(val), 'postalCode', this.props.requiredRegistrationFields & RegistrationField.BasicAddress || this.props.requiredRegistrationFields & RegistrationField.FullAddress ? [v.required] : undefined, []);
    validateGender = (val: number) => v.validate(val, 'gender', (this.props.requiredRegistrationFields & RegistrationField.Gender) == RegistrationField.Gender ? [v.required] : [], []);
    validateEmergencyContactName = (val: string) => v.validate(properCase(val), 'addressLine4', this.props.requiredRegistrationFields & RegistrationField.EmergencyContactName ? [v.required] : undefined, []);
    validateEmergencyContactNumber = (val: string) => v.validate(properCase(val), 'addressLine4', this.props.requiredRegistrationFields & RegistrationField.EmergencyContactNumber ? [v.required] : undefined, []);

    submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {
            const { addressLine1, postalCode, gender, emergencyContactName, emergencyContactNumber } = this.state;

            this.props.next({
                ...this.props.customer,
                addressLine1: addressLine1.value,
                postalCode: postalCode.value,
                gender: gender.value,
                emergencyContactName: emergencyContactName.value,
                emergencyContactNumber: emergencyContactNumber.value
            });
        }
    }

    render() {
        const { t } = this.context;
        const { missingFields, addressLine1, postalCode, gender, emergencyContactNumber, emergencyContactName } = this.state;
        const { headerText, cancel} = this.props;

        const addressFields: JSX.Element[] = [];

        const genderOptions = Object.keys(Gender).filter(k => typeof Gender[k as any] === 'number').map(k => ({ key: Gender[k as any].toString(), name: t(`Gender:${k}`) }));

        if ((missingFields & RegistrationField.BasicAddress) === RegistrationField.BasicAddress) {
            addressFields.push(<ct.TextBox key='addrLine1' id='addressLine1' classNames='col-xs-6' labelKey='Global:addressLine1' placeholderKey='Global:addressLine1' value={addressLine1} callback={val => this.setState({ addressLine1: this.validateAddressLine1(val) })} />);
        }

        if ((missingFields & RegistrationField.BasicAddress) === RegistrationField.BasicAddress) {
            addressFields.push(<ct.TextBox key='postalCode' id='postalCode' classNames='col-xs-6' labelKey='Global:postalCode' placeholderKey='Global:postalCode' value={postalCode} callback={val => this.setState({ postalCode: this.validatePostalCode(val) })} />);
        }

        if ((missingFields & RegistrationField.Gender) === RegistrationField.Gender) {
            addressFields.push(<ct.Select key='gender' id='gender' classNames='col-xs-6' labelKey='Global:gender' value={{ ...gender, value: gender.value.toString() }} options={genderOptions} callback={val => this.setState({ gender: this.validateGender(parseInt(val)) })} />);
        }

        if ((missingFields & RegistrationField.EmergencyContactName) === RegistrationField.EmergencyContactName) {
            addressFields.push(<ct.TextBox key='emergencyContactName' id='emergencyContactName' classNames='col-xs-6' labelKey='Global:emergencyContactName' placeholderKey='Global:emergencyContactName' value={emergencyContactName} callback={val => this.setState({ emergencyContactName: this.validateEmergencyContactName(val) })} />);
        }

        if ((missingFields & RegistrationField.EmergencyContactNumber) === RegistrationField.EmergencyContactNumber) {
            addressFields.push(<ct.TextBox key='emergencyContactNumber' id='emergencyContactNumber' classNames='col-xs-6' labelKey='Global:emergencyContactNumber' placeholderKey='Global:emergencyContactNumber' value={emergencyContactNumber} callback={val => this.setState({ emergencyContactNumber: this.validateEmergencyContactNumber(val) })} />);
        }

        return (
            <div>
                <form className='registration-form' onSubmit={this.submit} autoComplete='off'>
                    <StepHeader headerText={headerText} />
                    {this.buildAddressForm(addressFields)}

                    <NavButtons t={t} cancel={cancel} preventNext={!v.isValid(this.state)} />
                </form>
            </div>
        );
    }

    buildAddressForm = (addressFields: JSX.Element[]) => {
        const result: JSX.Element[] = [];

        for (let i = 0; i < addressFields.length; i += 2) {
            result.push(
                <div className='row' key={`addr_row_${i}`}>
                    {addressFields[i]}
                    {i < addressFields.length - 1 ? addressFields[i + 1] : null}
                </div>
            );
        }

        return result;
    }
}

export default MissingFields;