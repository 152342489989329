
import '../../../css/print.css';

import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as api from '../../../store/apiClient';
import { ReportRow, ReportHeader, ColumnTextAlignment } from '../../../store/pages/reportTypes/types';
import { isNullOrEmpty } from '../../../utils/util';
import { Link } from 'react-router-dom';

interface ReportResultTableProps {
    reportName: string; 
    countHeading: string;
    headings: ReportHeader[];
    rows: ReportRow[];
    totals: ReportRow;
    excelUrl: string;
}

export default class ReportResultTable extends React.Component<ReportResultTableProps, {}> {

    static contextTypes = {
        t: PropTypes.func
    }

    print = (e: React.MouseEvent<HTMLButtonElement>) => {
        const content = document.getElementById("report-table");
        const printFrame = document.getElementById("ifmcontentstoprint") as HTMLIFrameElement;

        e.preventDefault();
        e.stopPropagation();

        if (!content || !printFrame || !printFrame.contentWindow)
            return;

        let stylesheets: StyleSheet[] = [];

        for (let si = 0; si < document.styleSheets.length; si++) {
            const ss = document.styleSheets[si]
            if (ss && ss.href && ss.type) {
                stylesheets.push(ss);
            }
        }

        const styleSheetLinks = stylesheets.map(s => `<link rel="stylesheet" type="${s.type}" href="${s.href}">`).join('');

        const headstr = `<html><head><title></title>${styleSheetLinks}</head><body>`;
        const footstr = "</body>";

        const pri = printFrame.contentWindow;

        printFrame.onload = () => pri.print();
        pri.document.open();
        pri.document.write(headstr + content.innerHTML + footstr);
        pri.document.close();
        pri.focus();
    }

    exportToExcel = (e: React.MouseEvent<HTMLButtonElement>) => {
        const { excelUrl, reportName } = this.props;
        e.preventDefault();
        e.stopPropagation();

        api.downloadFile(excelUrl, `${reportName}.csv`, (success: boolean, error: string | null) => { });
    }

    mapAlignmentClass = (header: ReportHeader) => {
        if (!header) return 'text-right';

        switch (header.alignment) {
            case ColumnTextAlignment.Left:
                return 'text-left';
            case ColumnTextAlignment.Center:
                return 'text-center';
            case ColumnTextAlignment.Right:
                return 'text-right'
            default:
                return 'text-left';
        }
    }

    render() {
        const { t } = this.context;
        const { reportName, countHeading, headings, rows, totals, excelUrl } = this.props;

        // If we have an extra row in the data it will be the count column, so add a header for it
        // note: this isn't done on the server side as we need to localize the heading
        //const headers = rows.length > 0 && headings.length < rows[0].data.length ? headings.concat({ heading: countHeading, alignment: ColumnTextAlignment.Right, translate: false }) : headings;

        return (
            <section id='report-table'>
                <h2 className='print-only text-center'>{reportName}</h2>
                {this.renderBody(headings, rows, totals, excelUrl)}
            </section>
        );
    }

    renderBody = (headings: ReportHeader[], rows: ReportRow[], totals: ReportRow, excelUrl: string) => {
        const { t } = this.context;

        if (rows.length === 0) {
            return <div className='text-center alert alert-info'>{t('Reports:noResults')}</div>;
        }

        return (
            <>
                <table className='table table-striped table-sm table-condensed'>
                    <thead>
                        {<tr key='header'>{headings.map((h, ix) => <th key={ix} className={this.mapAlignmentClass(headings[ix])}>{h.heading}</th>)}</tr>}
                    </thead>
                    <tbody>
                        {rows.map((r, rix) => <tr key={rix}>{r.data.map((d, ix) => <td key={ix} className={this.mapAlignmentClass(headings[ix])}>{headings[ix].translate ? t(d) : d}</td>)}</tr>)}
                    </tbody>
                    <tfoot>
                        <tr key='totals'>{totals.data.map((f, ix) => <td key={ix} className={this.mapAlignmentClass(headings[ix])}>{isNullOrEmpty(f) ? '' : <div className='report-total'>{f}</div>}</td>)}</tr>
                    </tfoot>
                </table>
                <div className='btn-toolbar btn-toolbar-centered'>
                    <button className='btn btn-primary' onClick={this.print}>{t('Global:print')}</button>
                    <Link to="/reports" className='btn btn-basic'>{this.context.t('Global:close')}</Link>
                    <button className='btn btn-info' onClick={this.exportToExcel}>{t('Global:excel')}</button>
                </div>
            </>
        );
    }
    }
    
