
interface Img {
    [key: string]: string
}

export interface ImageProps {
    width: number;
    height: number;
}

class ImageContainer {
    imgMap: Img = {};

    setImages(images: any) {
        for (let property in images) {
            if (images.hasOwnProperty(property)) {
                this.imgMap[property] = images[property];
            }
        }
    }

    getImageUrl(imgKey: string) {
        const normalisedImgName = imgKey.toLowerCase().replace(/ /g, '');
        const img = this.imgMap[normalisedImgName];
        return img;
    }
}

export interface ImageFile {
    file: File;
    preview: string;
} 

const imageMap = new ImageContainer();

export const initialize = (images: any) => imageMap.setImages(images);

export const getImageUrl = (imgKey: string) => imageMap.getImageUrl(imgKey);


export const getImageProps = (imageFile: File): Promise<ImageProps>  => {
    return new Promise(function (resolve, reject) {
        const img = new Image();
        img.src = URL.createObjectURL(imageFile);

        img.onload = function () {
            resolve({ width: img.width, height: img.height });
        };
    });
}

