
//import 'react-datetime/css/react-datetime.css';
import '../../../css/reactdatetime.css';

import * as React from 'react';
import { connect } from 'react-redux';
import * as H from 'history';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'

import moment from 'moment';

import '../../../utils/extensions';
import { isNullOrEmpty, generateTempId, clickHandler, copyToClipboard } from '../../../utils/util';
import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import { ApplicationState } from '../../../store';
import * as gt from '../../../store/global/types';
import * as api from '../../../store/apiClient';
import { Resource } from '../../../store/pages/resources/types';
import * as ModalActions from '../../../store/global/modal/actions';
import * as LoginActions from '../../../store/pages/login/actions';
import * as ProductActions from '../../../store/pages/products/actions';
import * as ProductCategoryActions from '../../../store/pages/productCategories/actions';
import * as TaxRateActions from '../../../store/pages/taxRates/actions';
import * as ActivityFormatActions from '../../../store/pages/activityFormats/actions';
import * as PaymentMethodActions from '../../../store/pages/paymentMethods/actions';
import * as EmailTemplateActions from '../../../store/pages/emailTemplates/actions';
import * as CustomerCategoryActions from '../../../store/pages/customerCategories/actions';
import { Event, EventBooking, ReservationType, BookingCustomer, CustomFieldValue } from '../../../store/pages/diary/types';
import { EventType } from '../../../store/pages/diary/types';
import { colours } from '../../../store/global/types';
import { EventReservations } from './eventReservations';
import EventCustomers from './eventCustomers';
import { Product, ProductPricingMode, ProductType } from '../../../store/pages/products/types';
import { TaxRate } from '../../../store/pages/taxRates/types';
import { Bill, BillPayment, CreateBillItem, PaymentStatus, BillItem, BillFee } from '../../../store/pages/pointOfSale/types';
import PointOfSalePanel from '../pointOfSale/pointOfSalePanel';
import { ProductCategory } from '../../../store/pages/productCategories/types';
import { ActivityFormat } from '../../../store/pages/activityFormats/types';
import { EventRegistration } from '../../../store/pages/diary/types';
import { PaymentMethod } from '../../../store/pages/paymentMethods/types'
import Loading from '../../global/loading';
import ConfirmationOverlay from './confirmationOverlay';
import ConfirmationCancellationOverlay from './confirmationCancellationOverlay';
import RegistrationDetails from './registrationDetails';
import { EventRegistrations } from './eventRegistrations';
import EditScheduledPayment from '../pointOfSale/editScheduledPayment';
import EditPayment from '../pointOfSale/editPayment';
import ValidationSummary from '../../global/validationSummary';
import { DateFormat, TimeFormat, Venue } from '../../../store/pages/venues/types';
import ReservationDetails from './reservationDetails';
import BookingDetails from './bookingDetails';
import { EmailType, ClientEmailTemplate } from '../../../store/pages/emailTemplates/types';
import { BookingConfirmationEmailsPanel } from './bookingConfirmationEmailsPanel';
import ReservationSummary from './reservationSummary';
import BookingPayment from './bookingPayment';
import { CustomerCategory } from '../../../store/pages/customerCategories/types';
import { getActivityProducts, ActivityFormatProduct, bookingComparer } from './helpers';
import { Fee } from '../../../store/pages/fees/types';
import { Booking, NewBookingReservation, Reservation, ReservationBooking } from './types';
import { Promotion } from '../../../store/pages/promotions/types';
import { LinkedItemQuantities } from '../pointOfSale/types';
import { VoucherProduct } from '../../../store/pages/vouchers/types';
import EditBookingQuestions from './editBookingQuestions';
import { MembershipType } from '../../../store/pages/memberships/types';

interface QueuedSave {
    auto: boolean;
}

enum Tab {
    Details = 1,
    Bookings = 2,
    Registrations = 3
}

interface LocalProps {
    isNew: boolean;
    history: H.History;
    venue: Venue;
    event: Event;
    registrations: EventRegistration[];
    bills: Bill[];
    numberOfParticipants: number | null;
    eventKey: string;
    initialBookingId: string | null;
    eventSaveComplete: boolean;
    eventSaveError: api.ApiError | null;
    eventValidationErrors: gt.ValidationError[];
    autoSaved: boolean;
    isSaving: boolean;
    isLoading: boolean;
    saveEvent: (isNew: boolean, venueId: string, eventId: string | null, event: Event, autoSave: boolean, forceReloadBills: boolean, completionCallback?: (eventId: string) => void) => void;
    deleteEvent: (event: Event) => void;
    cancelEvent: (event: Event, reason: string) => void;
    reinstateEvent: (event: Event) => void;
    cancelBooking: (bookingId: string, reason: string) => void;
    reinstateBooking: (bookingId: string) => void;
    completeWebBooking: (bookingId: string) => void;
    flagBooking: (bookingId: string, flagged: boolean) => void;
    onBookingWebsiteChanged: (bookingId: string, publicWebsiteId: number) => void;
    billChanged: (bill: Bill) => void;
    updateBillItem: (bill: Bill, item: BillItem, quantity: number, archived: boolean, unitPrice: number, productId: string, placesToBookPerUnit: number | null, reservationId: string | null, customerCategoryId: string | null, pricingMode: ProductPricingMode, fixedPriceOverride: number | null, productPriceId: string, linkedItemQuantities: LinkedItemQuantities[], completionCallback: () => void) => void;
    updateBillFee: (billId: string, fee: BillFee, callback: (success: boolean, error: api.ApiError | null) => void) => void;
    voidRefund: (billId: string, refundId: string, voidReason: string, callback: (success: boolean, error: api.ApiError | null) => void) => void;
    replaceDiscount: (billId: string, promotionId: string, amountOverride: number | null, callback: (success: boolean, error: api.ApiError | null) => void) => void;
    removeDiscount: (billId: string, billDiscountId: string, callback: (success: boolean, error: api.ApiError | null) => void) => void;
    cancelPaymentAttempt: (bookingId: string, billPaymentId: string, gatewayPaymentId: string) => void;
    onBookingChanged: (eventIdOverride?: string) => void;
    addBooking: () => void;
    onSaved: () => void;
    close: () => void;
}

interface LocalState {
    resources: Resource[];
    products: Product[];
    productsLoading: boolean;
    productCategories: ProductCategory[];
    productCategoriesLoading: boolean;
    taxRates: TaxRate[];
    taxRatesLoading: boolean;
    fees: Fee[];
    feesLoading: boolean;
    activityFormats: ActivityFormat[];
    activityFormatsLoading: boolean;
    paymentMethods: PaymentMethod[];
    paymentMethodsLoading: boolean;
    emailTemplates: ClientEmailTemplate[];
    emailTemplatesLoading: boolean;
    customerCategories: CustomerCategory[];
    customerCategoriesLoading: boolean;
    promotions: Promotion[];
    vouchers: VoucherProduct[];
    membershipTypes: MembershipType[];
}

//type Actions = typeof da.actionCreators & typeof ModalActions.actionCreators
interface Actions {
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
    loadProducts: () => void;
    loadProductCategories: () => void;
    loadTaxRates: () => void;
    loadActivityFormats: () => void;
    loadPaymentMethods: () => void;
    loadEmailTemplates: () => void;
    loadCustomerCategories: () => void;
    logout: () => void;
}

type EventFormProps = LocalState & Actions & LocalProps;
 
interface EventFormState {
    eventId: string;
    eventName: ct.FormValue<string>;
    selectedTab: Tab;
    type: ct.FormValue<EventType>;
    colour: ct.FormValue<string>;
    startTime: moment.Moment;
    endTime: moment.Moment;
    reservations: Reservation[];
    archived: ct.FormValue<boolean>;
    bookings: Booking[];
    errorMessageKey: string;
    formErrorKey: string;
    showAutoSaveMessage: boolean;
    queuedSave: QueuedSave | null;
    selectEmails: boolean;
    selectedBookingId: string;
}


class EventForm extends React.Component<EventFormProps, EventFormState> {

    saveResultTimeout: NodeJS.Timeout | null;

    constructor(props: EventFormProps) {
        super(props);

        this.saveResultTimeout =  null;

        this.state = this.buildStateFromProps(props, null, props.initialBookingId);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps = (props: EventFormProps, currentState: EventFormState | null, initialBookingId: string | null): EventFormState => {
        // TODO: Need to merge changes as there may have been local changes made whilst changes were being saved
        const { event } = props;
        const selectEmails = this.state && this.state.selectEmails;

        const startTime = event.reservations[0].startTime;
        const endTime = event.reservations[0].endTime;

        let eventStartTime = startTime;
        let eventEndTime = endTime;

        const isNew = isNullOrEmpty(event.id);

        const reservations = event.reservations.map((r, ix) => ({
            ...r,
            key: r.id || generateTempId(),
            startTime: moment(r.startTime),
            endTime: moment(r.endTime),
            archived: this.validateArchived(r.archived),
            showNotes: !isNullOrEmpty(r.notes),
            isNew: r.id === null,
            isValid: true
        }));

        for (let i = 0; i < event.reservations.length; i++) {
            const r = event.reservations[i];
            if (r.startTime < eventStartTime) {
                eventStartTime = r.startTime;
            }
            if (r.endTime < eventEndTime) {
                eventEndTime = r.endTime;
            }
        }

        const errorMessageKey = currentState ? currentState.errorMessageKey : '';
        const formErrorKey = currentState ? currentState.formErrorKey : '';
        const showAutoSaveMessage = currentState ? currentState.showAutoSaveMessage : false;
        const queuedSave = currentState ? currentState.queuedSave : null;

        const filteredBookings = event.bookings.filter(b => !b.cancelled && b.customer).sort(bookingComparer);
        const selectedBooking = filteredBookings.length > 0 && currentState ? this.findCustomerBooking(filteredBookings, currentState.selectedBookingId) : null;
        const selectedBookingId = initialBookingId ? initialBookingId : selectedBooking ? selectedBooking.id : filteredBookings.length > 0 ? filteredBookings[0].id : '';

        return {
            eventId: event.id,
            eventName: this.validateName(event.name),
            selectedTab: isNew ? Tab.Details : currentState ? currentState.selectedTab : Tab.Bookings,
            type: this.validateType(event.type),
            colour: this.validateColour(event.colour),
            startTime: moment(eventStartTime),
            endTime: moment(eventEndTime),
            reservations: reservations,
            archived: this.validateArchived(event.archived),
            bookings: event.bookings.map(b => this.toBooking(b)) || [],
            errorMessageKey: errorMessageKey,
            formErrorKey: formErrorKey,
            showAutoSaveMessage: showAutoSaveMessage,
            queuedSave: queuedSave,
            selectEmails: selectEmails,
            selectedBookingId: selectedBookingId,
        };
    }

    findCustomerBooking = (bookings: EventBooking[], selectedBookingId: string) => {
        return bookings.find(b => b.id === selectedBookingId);
    }

    log = (msg: string) => {
        const { venue } = this.props;
        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        console.log(`${msg} ${new Date().toLongTimeString(timeFormat)}`);
    }

    toBooking = (eventBooking: EventBooking): Booking => {
        return { ...eventBooking, key: eventBooking.customer ? eventBooking.customer.customerId : generateTempId(), confirmDelete: false, bookingReservations: [] }
    }

    private saveEvent = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }

    clearAutoSave = () => {
        this.log('EventForm: clearing auto save');
        this.setState({ showAutoSaveMessage: false });
    }

    sendEmail = (clientEmailTemplate: ClientEmailTemplate, toEmailAddress: string, booking: Booking | null, customerId: string | null, emailSent: (error: string | null) => void) => {

        const clientEmailTemplateId = clientEmailTemplate.clientEmailTemplateId;
        if (!clientEmailTemplateId) {
            emailSent('BookingCommunications:unknownEmailTemplate')
            return;
        }

        switch (clientEmailTemplate.emailType) {
            case EmailType.BookingConfirmation:
                this.sendBookingConfirmation(clientEmailTemplateId, toEmailAddress, booking, emailSent);
                break;
            case EmailType.GeneralBookingEmail:
                this.sendGeneralBookingEmail(clientEmailTemplateId, toEmailAddress, booking, emailSent);
                break;
            case EmailType.BookingRegistrationLink:
                this.sendBookingRegistrationLink(clientEmailTemplateId, toEmailAddress, booking, customerId, emailSent);
                break;
            case EmailType.EventResults:
                this.sendEventResultsEmail(toEmailAddress, customerId, emailSent);
                break;
            case EmailType.General:
                this.sendGeneralEmail(clientEmailTemplateId, toEmailAddress, customerId, emailSent);
                break;
            default:
                emailSent('BookingCommunications:unknownEmailTemplate');
                break;
        }
    }

    sendPaymentLink = (clientEmailTemplateId: string, billPaymentId: string, toEmailAddress: string, emailSent: (error: string | null) => void) => {
        const { venue, logout } = this.props;

        api.postWithAuth(`api/v1/bill/sendPaymentLink`, {
            venueId: venue.id,
            emailTemplateId: clientEmailTemplateId,
            billPaymentId: billPaymentId,
            emailAddressOverride: toEmailAddress
        }, logout)
            .subscribe(_ => emailSent(null), e => emailSent(e.message));
    }

    sendBookingConfirmation = (clientEmailTemplateId: string, toEmailAddress: string, booking: Booking | null, emailSent: (error: string | null) => void) => {
        const { logout } = this.props;

        if (!booking) {
            emailSent('BookingCommunications:unknownBooking')
            return;
        }

        api.postWithAuth(`api/v1/booking/${booking.id}/bookingConfirmationEmail`, {
            clientEmailTemplateId: clientEmailTemplateId,
            emailAddressOverride: toEmailAddress
        }, logout)
            .subscribe(_ => emailSent(null), e => emailSent(e.message));
    }

    sendGeneralBookingEmail = (clientEmailTemplateId: string, toEmailAddress: string, booking: Booking | null, emailSent: (error: string | null) => void) => {
        const { logout } = this.props;

        if (!booking) {
            emailSent('BookingCommunications:unknownBooking')
            return;
        }

        api.postWithAuth(`api/v1/booking/${booking.id}/generalBookingEmail`, {
            clientEmailTemplateId: clientEmailTemplateId,
            emailAddressOverride: toEmailAddress
        }, logout)
            .subscribe(_ => emailSent(null), e => emailSent(e.message));
    }

    sendBookingRegistrationLink = (clientEmailTemplateId: string, toEmailAddress: string, booking: Booking | null, customerId: string | null, emailSent: (error: string | null) => void) => {
        const { venue, logout } = this.props;

        const customer = booking && booking.customer && booking.customer.customerId === customerId ? booking.customer : null;;

        api.postWithAuth(`api/v1/booking/sendRegistrationLink`, {
            venueId: venue.id,
            bookingId: booking ? booking.id : null,
            customerId: customerId,
            emailAddress: toEmailAddress,
            clientEmailTemplateId: clientEmailTemplateId,
            AddEmailToLink: customer && toEmailAddress === customer.emailAddress ? false : true
        }, logout)
            .subscribe(_ => emailSent(null), e => emailSent(e.message));
    }

    sendEventResultsEmail = (toEmailAddress: string, customerId: string | null, emailSent: (error: string | null) => void) => {
        const { event, logout } = this.props;

        api.postWithAuth(`api/v1/event/${event.id}/sendResultsEmail`, {
            customerId: customerId,
            emailAddressOverride: toEmailAddress,
        }, logout)
            .subscribe(_ => emailSent(null), e => emailSent(e.message));
    }

    sendGeneralEmail = (clientEmailTemplateId: string, toEmailAddress: string, customerId: string | null, emailSent: (error: string | null) => void) => {
        const { venue, logout } = this.props;

        //const customer = booking && booking.customer ? booking.customer : null;

        api.postWithAuth(`api/v1/emailtemplate/${clientEmailTemplateId}/send`, {
            venueId: venue.id,
            customerId: customerId,
            emailAddress: toEmailAddress
        }, logout)
            .subscribe(_ => emailSent(null), e => emailSent(e.message));
    }

    //** Fix this: need to update event once update is passed from parent
    //** probably need to move all redux stuff into page rather than form
    //** as it's all messed up doing it in two places
    componentDidUpdate(prevProps: EventFormProps) {
        const { eventSaveComplete: prevEventSaveComplete, event: prevEvent, isSaving: prevIsSaving } = prevProps;
        const { eventSaveComplete, event, isSaving, autoSaved, eventSaveError, eventValidationErrors } = this.props;

        if (eventSaveComplete && !prevEventSaveComplete) {

            if (autoSaved) {
                this.log('EventForm: showing auto save');
                this.setState({ showAutoSaveMessage: true });
                this.saveResultTimeout = setTimeout(() => this.clearAutoSave(), 2500);
            }
            else if (!eventSaveError && (!eventValidationErrors || eventValidationErrors.length === 0)) {
                setTimeout(() => { this.postSave(); }, 750);
                return;
            }
        }

        if (event.version !== prevEvent.version) {
            this.setState(prevState => this.buildStateFromProps(this.props, prevState, null));
        }

        // If we have a queued save, run it now
        if (prevIsSaving && !isSaving) {
            const { queuedSave } = this.state;

            if (queuedSave) {
                this.log(`Running queued save, auto: ${queuedSave.auto}`);
                this.save(queuedSave.auto, false);
                this.setState({ queuedSave: null });
            }
        }
    }

    postSave = () => {
        const { event, emailTemplates, onSaved } = this.props;
        const bookingConfEmails = emailTemplates.filter(t => t.emailType === EmailType.BookingConfirmation)

        const bookingsWithoutEmail = event.bookings.filter(b => !b.cancelled && isNullOrEmpty(b.bookingConfirmationClientEmailTemplateId));
        if (!event.deleted && !event.cancelled && bookingsWithoutEmail.length > 0 && bookingConfEmails.length > 0) {
            this.setState({ selectEmails: true });
        } else {
            onSaved();
        }
    }

    autoSave = (forceReloadBills: boolean, successCallback?: () => void) => {
        this.save(true, forceReloadBills, successCallback);
    }

    private save = (autoSave: boolean, forceReloadBills: boolean, successCallback?: (eventId: string) => void) => {
        // Need to prevent multiple saves at the same time, so if a save is in progress, queue up this save so it can be run when the current save is complete
        const { isSaving } = this.props;
        if (isSaving) {
            this.setState({ queuedSave: { auto: autoSave } });
            this.log(`Save queued [auto: ${autoSave}]`);
        } else {
            if (this.saveResultTimeout) {
                this.log('EventForm: clearing saveResultTimeout');
                clearTimeout(this.saveResultTimeout);
            }

            if (!v.isValid(this.state)) {
                // TODO: Show error message!
            } else if (this.state.reservations.filter(r => isNullOrEmpty(r.activityFormatId)).length > 0) {
                this.setState({ formErrorKey: 'EventForm:selectActivityFormats' });
            } else if (this.state.reservations.filter(r => isNullOrEmpty(r.activityFormatVariationId)).length > 0) {
                this.setState({ formErrorKey: 'EventForm:selectActivityFormatVariations' });
            } else if (this.state.reservations.filter(r => !r.isValid).length > 0) {
                this.setState({ formErrorKey: 'EventForm:correctActivityTimes' });
            } else {
                const { isNew, venue, event, saveEvent } = this.props;
                const eventId = isNew ? null : event.id;
                const { eventName, type, colour, startTime, endTime, reservations, archived, bookings } = this.state;

                const draft = autoSave ? isNew : false;

                saveEvent(isNew,
                    venue.id,
                    eventId,
                    {
                        id: eventId || '',
                        name: eventName.value,
                        type: type.value,
                        colour: colour.value,
                        startTime: startTime.toDate(),
                        endTime: endTime.toDate(),
                        draft: draft,
                        reservations: reservations.map(r => ({ ...r, eventId: eventId || '', eventName: eventName.value, draft: draft, colour: colour.value, startTime: r.startTime.toDate(), endTime: r.endTime.toDate(), archived: r.archived.value })),
                        archived: archived.value,
                        deleted: event.deleted,
                        deletedByUserAccountId: event.deletedByUserAccountId,
                        deletedByUserName: event.deletedByUserName,
                        deletedByUserIpAddress: event.deletedByUserIpAddress,
                        whenDeleted: event.whenDeleted,
                        cancelled: event.cancelled,
                        cancelledByUserAccountId: event.cancelledByUserAccountId,
                        whenCancelled: event.whenCancelled,
                        cancellationReason: event.cancellationReason,
                        cancelledByUserName: event.cancelledByUserName,
                        cancelledByUserIpAddress: event.cancelledByUserIpAddress,
                        resultsUrl: event.resultsUrl,
                        publicEventPageUrl: event.publicEventPageUrl,
                        tasks: [],
                        bookings: bookings,
                        version: event.version
                    }, autoSave, forceReloadBills, successCallback
                );

                this.setState({ formErrorKey: '' });
            }
        }
    }

    typeChanged = (newValue: string) => this.setState({ type: this.validateType(parseInt(newValue)) });

    validateName = (val: string) => v.validate(val, 'name', [v.required], this.props.eventValidationErrors);
    validateType = (val: number) => v.validate(val, 'type', [], this.props.eventValidationErrors);
    validateColour = (val: string) => v.validate(val, 'colour', [v.required], this.props.eventValidationErrors);
    validateArchived = (val: boolean) => v.validate(val, 'archived', [], this.props.eventValidationErrors);
    validateReservationArchvied = (index: number, val: boolean) => v.validate(val, `${index}archived`, [], this.props.eventValidationErrors);

    updateReservation = (reservation: Reservation) => {

        if (reservation.endTime < reservation.startTime) {
            return ({ ...reservation, isValid: false, validationError: 'EventForm:validation:endTimeBeforeStartTime' })
        }

        return ({ ...reservation, isValid: true, validationError: null });
    }

    updateReservations = (stateBuilder: (previousState: EventFormState) => EventFormState, autoSave: boolean, reloadBils: boolean, completeAction?: () => void) => 
        this.setState(prev => stateBuilder(prev), () => {
                if (completeAction) {
                    completeAction();
                }
                if (autoSave) {
                    this.autoSave(reloadBils);
                }
        });
   

    calculateEndTime = (startTime: moment.Moment, duration: Date) => {
        const endTime= moment(startTime);
        endTime.add(moment.duration(duration.getHours(), 'hours'));
        endTime.add(moment.duration(duration.getMinutes(), 'minutes'));
        return endTime;
    }
    
    showNotes = (key: string) =>
        this.setState(prev => ({ reservations: prev.reservations.map(r => r.key === key ? { ...r, showNotes: true } : { ...r }) }));
    
    addReservation = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const { event } = this.props;
        const { reservations, startTime, endTime } = this.state;
        const res = reservations.length > 0 ? reservations[0] : null;
        const lastRes = reservations.length > 0 ? reservations.sort((a,b) => b.endTime.unix() - a.endTime.unix())[0] : null;

        this.editReservation({
            id: null,
            key: generateTempId(),
            eventId: event.id,
            eventName: event.name,
            resourceId: res ? res.resourceId : '',
            resourceConfigurationId: res ? res.resourceConfigurationId : '',
            reservationType: res ? res.reservationType : ReservationType.NonExclusive,
            startTime: lastRes ? lastRes.endTime.clone() : startTime,
            endTime: lastRes ? lastRes.endTime.clone().add(30,'m') : endTime,
            colour: res ? res.colour : '',
            activityFormatId: '',
            activityFormatVariationId: '',
            activityFormatVariationScheduleId: '',
            activityFormatVariationScheduleSequence: 0,
            mustBookItemsTogether: false,
            primaryReservationId: null,
            isMultiScheduleReservation: false,
            maxParticipants: 0,
            bookedParticipants: [],
            membershipTypeId: null,
            membershipTypeLabel: null,
            membershipTypeLabelColour: null,
            notes: '',
            showNotes: false,
            archived: this.validateReservationArchvied(0, false),
            isValid: true
        });
    }
     
    removeReservation = (key: string) => this.setState((prevState) => ({ reservations: prevState.reservations.filter(r => r.key !== key) }), () => {
        this.props.closeModal();
        this.autoSave(false);
    });
     
    saveReservation = (reservationKey: string, reservation: Reservation, reservationSelections: ReservationBooking[]) => {
        const selectedReservations = reservationSelections.filter(r => !isNullOrEmpty(r.productId));
        this.updateReservations(prev => {
            const existingReservation = prev.reservations.find(r => r.key === reservationKey);
            const mappedReservations = existingReservation ? prev.reservations.map(r => r.key === reservationKey ? reservation : r) : prev.reservations.concat(reservation);

            const mappedBookings = selectedReservations && selectedReservations.length > 0
                ? prev.bookings.map(b => ({ ...b, bookingReservations: this.mergeBookingReservations(b.id, b.bookingReservations, selectedReservations) }))
                : prev.bookings;

            const eventStartTime = mappedReservations.map(r => r.startTime).reduce((acc, val) => acc < val ? acc : val);
            const eventEndTime = mappedReservations.map(r => r.endTime).reduce((acc, val) => acc > val ? acc : val);
            return {
                ...prev,
                startTime: eventStartTime,
                endTime: eventEndTime,
                reservations: mappedReservations,
                bookings: mappedBookings
            };
        }, true, selectedReservations.length > 0, () => this.props.closeModal());
    }

    mergeBookingReservations = (bookingId: string, bookingReservations: NewBookingReservation[], selectedReservations: ReservationBooking[]) => {
        let result = bookingReservations;
        const selectedReservationsForBooking = selectedReservations.filter(r => r.bookingId === bookingId);
        for (var i = 0; i < selectedReservationsForBooking.length; i++) {
            const rsv = selectedReservationsForBooking[i];
            const matchIndex = bookingReservations.findIndex(r => r.reservationKey === rsv.reservationKey && ((!isNullOrEmpty(rsv.billItemId) && r.billItemId === rsv.billItemId) || (isNullOrEmpty(rsv.billItemId) && rsv.customerCategoryId === r.customerCategoryId)));

            const bookingRsv = ({ reservationKey: rsv.reservationKey, productKey: rsv.customerCategoryId ? rsv.productKey : rsv.productId, productId: rsv.productId, quantity: rsv.quantity, placesPerUnit: rsv.placesPerUnit, productPriceId: rsv.productPriceId, customerCategoryId: rsv.customerCategoryId, billItemId: rsv.billItemId, unitPrice: rsv.unitPrice });

            if (matchIndex >= 0) {
                result[matchIndex] = bookingRsv
            } else {
                result.push(bookingRsv)
            }
        }

        return result;
    }

    selectBooking = (booking: Booking) => {
        this.setState({ selectedBookingId: booking.id });
    }

    addBooking = () => this.props.addBooking();

    editBooking = (booking: Booking) => {
        const bookings = this.state.bookings.map(c => booking.key === c.key ? booking : c);
        this.setState({ bookings: bookings }, () => this.autoSave(false));
    }

    editBookingQuestions = (booking: Booking) => {
        this.props.showModal(<EditBookingQuestions booking={booking} saveChanges={this.updateBookingQuestions} close={this.props.closeModal}  />, 'EditBookingQuestions')
    }

    updateBookingQuestions = (booking: Booking, customFields: CustomFieldValue[], complete: (success: boolean, error: string) => void) => {
        this.setState(prev => ({
            bookings: prev.bookings.map(b => {
                return b.key === booking.key ? { ...b, customFields: customFields } : b;
            })
        }), () => {
            this.autoSave(false, () => complete(true, ''));
        });
    }

    onBookingCustomerUpdated = (bookingKey: string, customer: BookingCustomer) => {
        this.setState(prev => ({
            bookings: prev.bookings.map(b => {
                return b.key === bookingKey ? { ...b, customer: customer } : b;
            })
        }), () => {
            this.autoSave(false, this.props.closeModal);
        });
    }

    cancelBooking = (bookingId: string, reason: string) => {
        this.props.cancelBooking(bookingId, reason);
    }

    reinstateBooking = (bookingId: string) => {
        this.props.reinstateBooking(bookingId);
    }

    removeBooking = (index: number) => {
        this.setState({ bookings: this.state.bookings.map((c, ix) => ix === index ? { ...c, confirmDelete: true} : c) });
    }

    cancelRemoveBooking = (index: number) => {
        this.setState({ bookings: this.state.bookings.map((c, ix) => ix === index ? { ...c, confirmDelete: false } : c) });
    }

    confirmRemoveBooking = (index: number) => {
        var updatedCustomers = this.state.bookings;
        updatedCustomers.splice(index, 1);

        this.setState({ bookings: updatedCustomers }, () => this.autoSave(false));
    }

    addReservationToBooking = (bookingId: string, newReservations: NewBookingReservation[], successCallback: () => void) => {
        this.setState((prevState) => ({ bookings: prevState.bookings.map(b => b.id === bookingId ? { ...b, bookingReservations: b.bookingReservations.concat(newReservations) } : b), errorMessageKey: '' }), () => {
            this.autoSave(true, successCallback);
        });
    }

    editBill = (booking: Booking, bill: Bill) => this.showPayment(booking, bill.id, [], (b: Bill) => this.billUpdated(b, true));

    addPayment = (booking: Booking | null, bill: Bill) => {
        const { venue, products, productCategories, closeModal, showModal, logout } = this.props;
        const billInfo = bill.id;
        const customerId = booking && booking.customer ? booking.customer.customerId : undefined;

        showModal(<BookingPayment
            venue={venue}
            showPayments={true}
            products={products.filter(p => p.venueId === venue.id || p.type === ProductType.Voucher || p.type === ProductType.Membership)}
            eventProducts={this.buildProducts()}
            productCategories={this.buildProductCategories(productCategories)}
            booking={booking}
            payments={bill.payments}
            billInfo={billInfo}
            customerId={customerId}
            posSessionComplete={this.posSessionComplete}
            close={closeModal}
            logout={logout} />, 'BookingPayment', true);
    }

    buildProductCategories = (categories: ProductCategory[]) => {
        const eventProductCategory: ProductCategory = { id: 'event_specific', clientId: -1, venueId: this.props.venue.id, name: this.context.t('EventForm:eventSpecificProductCategory'), colour: '#337ab7', sequence: 0, archived: false, showOnPointOfSale: true, showOnWebShop: false, reportingPriority: null };
        return [eventProductCategory].concat(categories.filter(c => !c.archived));
    }

    buildProducts = () => {
        const { activityFormats, products, customerCategories } = this.props;
        const { reservations} = this.state;
        const reservationProducts = reservations.reduce<ActivityFormatProduct[]>((acc, reservation) => {
            const af = activityFormats.find(f => f.id === reservation.activityFormatId);
            return af ? acc.concat(getActivityProducts(af, reservation.id, reservation.startTime.toDate(), products, customerCategories).map(p => ({ ...p, reservationId: reservation.id }))) : acc;
        }, []);

        return reservationProducts;
    }

    showPayment = (booking: Booking, billId: string, items: CreateBillItem[], billChanged: (bill: Bill) => void, customerId?: string) => {
        const { venue, products, activityFormats, productCategories, paymentMethods, customerCategories, taxRates, fees, promotions, vouchers, membershipTypes, closeModal, showModal, logout } = this.props;

        const billInfo = isNullOrEmpty(billId) ? items : billId;

        showModal(<PointOfSalePanel venue={venue} products={products.filter(p => p.venueId === venue.id || p.type === ProductType.Voucher || p.type === ProductType.Membership)} customerCategories={customerCategories} eventProducts={this.buildProducts()} activityFormats={activityFormats} productCategories={this.buildProductCategories(productCategories)} paymentMethods={paymentMethods} taxRates={taxRates} fees={fees} promotions={promotions} booking={booking} billInfo={billInfo} customerId={customerId} posSessionComplete={billChanged} vouchers={vouchers} membershipTypes={membershipTypes} logout={logout} />, 'PointOfSalePanel', true);
    }

    makeScheduledPayment = (booking: Booking, bill: Bill, payment: BillPayment) => {
        const { venue, products, activityFormats, productCategories, paymentMethods, customerCategories, taxRates, fees, promotions, vouchers, membershipTypes, closeModal, showModal, logout } = this.props;
        showModal(<PointOfSalePanel venue={venue} products={products.filter(p => p.venueId === venue.id || p.type === ProductType.Voucher || p.type === ProductType.Membership)} eventProducts={this.buildProducts()} activityFormats={activityFormats} customerCategories={customerCategories} productCategories={this.buildProductCategories(productCategories)} paymentMethods={paymentMethods} taxRates={taxRates} fees={fees} promotions={promotions} booking={booking} billInfo={bill.id} paymentId={payment ? payment.id : null} posSessionComplete={this.posSessionComplete} vouchers={vouchers} membershipTypes={membershipTypes} logout={logout} />, 'PointOfSalePanel', true);
    }

    editPayment = (bill: Bill, payment: BillPayment) => {
        const { event, venue, closeModal, showModal } = this.props;
        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;

        if (payment.paid || payment.void || payment.status === PaymentStatus.GatewayInProgress || payment.status === PaymentStatus.GatewayFailed || payment.status === PaymentStatus.GatewayCancelled) {
            showModal(<EditPayment
                venueId={venue.id}
                billId={bill.id}
                payment={payment}
                eventDate={event.startTime}
                timeFormat={timeFormat}
                dateFormat={dateFormat}
                paymentUpdated={this.billUpdated}
                cancelPaymentAttempt={(gatewayPaymentId: string) => this.cancelPaymentAttempt(bill.id, payment.id, gatewayPaymentId)}
                cancel={closeModal} />, 'EditPayment', true);
        } else {
            showModal(<EditScheduledPayment
                billId={bill.id}
                paymentId={payment.id}
                eventDate={event.startTime}
                amount={payment.amount}
                paymentDate={payment.paymentDueDate || new Date()}
                paymentDescription={payment.description || ''}
                timeFormat={timeFormat}
                dateFormat={dateFormat}
                paymentUpdated={b => this.billUpdated(b, true)}
                cancel={closeModal} />, 'EditScheduledPayment', true);
        }
    }

    flagBooking = (bookingId: string, flagged: boolean) => this.setState((prevState) => ({ bookings: prevState.bookings.map(b => b.id === bookingId ? { ...b, flagged: flagged } : b), errorMessageKey: '' }), () => {
        this.props.flagBooking(bookingId, flagged);
    });

    onBookingWebsiteChanged = (bookingId: string, publicWebsiteId: number) => this.setState((prevState) => ({ bookings: prevState.bookings.map(b => b.id === bookingId ? { ...b, publicWebsiteId: publicWebsiteId } : b), errorMessageKey: '' }), () => {
        this.props.onBookingWebsiteChanged(bookingId, publicWebsiteId);
    });

    deleteEvent = (e: React.MouseEvent<HTMLButtonElement>) => {
        const { event, showModal, closeModal } = this.props;
        const { t } = this.context;
        e.preventDefault();
        e.stopPropagation();

        showModal(<ConfirmationOverlay heading={t('EventForm:deleteEventHeading')} message={t('EventForm:deleteEventText', { eventName: event.name })} affirmativeText={t('EventForm:yesDeleteEvent')} negativeText={t('Global:cancel')} confirm={this.confirmDeleteEvent} cancel={closeModal} />, 'ConfirmDeleteEvent');
    }

    confirmDeleteEvent = () => {
        const { event, deleteEvent, closeModal } = this.props;
        deleteEvent(event);
        closeModal();
    }

    cancelEvent = (e: React.MouseEvent<HTMLButtonElement>) => {
        const { event, showModal, closeModal } = this.props;
        const { t } = this.context;

        e.preventDefault();
        e.stopPropagation();

        showModal(<ConfirmationCancellationOverlay heading={t('EventForm:cancelEventHeading')} message={t('EventForm:cancelEventText', { eventName: event.name })} affirmativeText={t('EventForm:yesCancelEvent')} negativeText={t('Global:cancel')} confirm={this.confirmCancelEvent} cancel={closeModal} />, 'ConfirmCancellation');
    }

    reinstateEvent = (e: React.MouseEvent<HTMLButtonElement>) => {
        const { event, reinstateEvent } = this.props;

        e.preventDefault();
        e.stopPropagation();

        reinstateEvent(event);
    }

    confirmCancelEvent = (reason: string) => {
        const { event, cancelEvent, closeModal } = this.props;
        cancelEvent(event, reason);
        closeModal();
    }

    close = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        const { close } = this.props;
        close();
    }

    cancelPaymentAttempt = (billId: string, billPaymentId: string, gatewayPaymentId: string) => {
        this.props.cancelPaymentAttempt(billId, billPaymentId, gatewayPaymentId);
        this.props.closeModal();
    }

    posSessionComplete = (bill: Bill) => this.billUpdated(bill, true);

    billUpdated = (bill: Bill, closeOverlay: boolean) => {
        this.props.billChanged(bill);
        if (closeOverlay) this.props.closeModal();
    }

    viewRegistration = (registration: EventRegistration) => {
        const { venue, showModal, closeModal, logout } = this.props;
        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;

        showModal(<RegistrationDetails event={this.props.event} registrationId={registration.registrationId} registration={registration} timeFormat={timeFormat} dateFormat={dateFormat} close={closeModal} logout={logout} />, 'RegistrationDetails');
    }

    editReservation = (reservation: Reservation) => {
        const { showModal, closeModal, venue, resources, activityFormats, products, customerCategories, membershipTypes, bills } = this.props;
        const { bookings, reservations } = this.state;

        const maxParticipants = bookings.length === 1 ? reservations.reduce((acc, r) => Math.max(r.bookedParticipants.reduce((ttl, cp) => ttl + cp.count, 0), acc), 0) : 0;
        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;

        showModal(<ReservationDetails
            reservation={reservation}
            saveReservation={this.saveReservation}
            deleteReservation={this.removeReservation}
            resources={resources.filter(r => r.venueId === venue.id)}
            activityFormats={activityFormats}
            products={products}
            customerCategories={customerCategories}
            membershipTypes={membershipTypes}
            bookings={bookings}
            bills={bills}
            maxParticipants={maxParticipants}
            timeFormat={timeFormat}
            dateFormat={dateFormat}
            close={closeModal} />, 'ReservationDetails')
    }
     
    findBooking = (bookings: Booking[], selectedCustomerId: string) => {
        const booking = bookings.find(b => b.id === selectedCustomerId);
        return booking ? booking : null;
    }

    render() {
        const { t } = this.context;
        const { event, bills, eventSaveError, eventSaveComplete, autoSaved, isNew, showModal, closeModal, onSaved, isLoading, isSaving,
            eventValidationErrors, emailTemplates, venue } = this.props;
        const { bookings, formErrorKey, eventName, selectedTab, showAutoSaveMessage, reservations } = this.state;

        if (this.state.selectEmails) {
            return <BookingConfirmationEmailsPanel event={event} bookingConfirmationEmailTemplates={emailTemplates.filter(t => t.emailType === EmailType.BookingConfirmation)} close={onSaved} />
        }
        
        let message: any = null;

        if (eventSaveError) {
            message = <ValidationSummary error={eventSaveError} keyPrefix='' validationMessages={eventValidationErrors} t={t} />
        } else if (!isNullOrEmpty(formErrorKey)) {
            message = (<div className='bg-danger'>{t(formErrorKey)}</div>);
        } else if (eventSaveComplete && !autoSaved) {
            message = (<div className='bg-success'>{t('Global:saveComplete')}</div>);
        } else if (eventSaveComplete && autoSaved && showAutoSaveMessage) {
            message = (<div className='bg-info'>{t(isNew ? 'EventForm:autoSaveCompleteNewEvent' : 'EventForm:autoSaveComplete')}</div>);
        }


        const hasPaidPayments = bills.filter(b => b.payments.filter(p => p.paid).length > 0).length > 0;
        const hasActiveBooking = bookings.filter(bk => !bk.cancelled).length > 0;

        const actionButton = this.renderActionButton(isNew, hasPaidPayments, isSaving, event.cancelled, event.deleted, hasActiveBooking)

        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;

        if (isLoading) {
            return <Loading />;
        } else {
            const areReservationsValid = reservations.reduce((valid, r) => valid && !isNullOrEmpty(r.activityFormatId), true);

            return (
                <div className='eventForm'>
                    <form className='event-form-form data-form' onSubmit={this.saveEvent} autoComplete='off'>
                        {!areReservationsValid ? <div className='alert alert-danger mt-15 text-center'><strong>{t('EventForm:missingActivityFormatMessage')}</strong></div> : null}
                        <h3 className='eventForm_title'>
                            {isNew ? t('EventForm:addEvent') : eventName.value}
                            {event.cancelled ? <label className='label label-danger' style={{margin: '0 10px'}}>{t('Global:cancelled')}</label> : null}
                        </h3>

                        <ul className="event-form-tabs nav nav-tabs">
                            <li role="presentation" className={selectedTab === Tab.Details ? 'active' : ''}><a className='btn btn-link' onClick={e => clickHandler(e, () => this.setState({ selectedTab: Tab.Details }))}>{t('EventForm:detailsTab')}</a></li>
                            <li role="presentation" className={selectedTab === Tab.Bookings ? 'active' : ''}><a className='btn btn-link'onClick={e => clickHandler(e, () => this.setState({ selectedTab: Tab.Bookings }))}>{t('EventForm:bookingsTab')}</a></li>
                            <li role="presentation" className={selectedTab === Tab.Registrations ? 'active' : ''}><a className='btn btn-link' onClick={e => clickHandler(e, () => this.setState({ selectedTab: Tab.Registrations }))}>{t('EventForm:registrationsTab')}</a></li>
                        </ul>
                        {this.renderTabContent(selectedTab, areReservationsValid)}

                        {message}

                        <div className='event-form-footer'>
                            <div className='btn-toolbar'>
                                <button className='btn btn-primary' onClick={e => clickHandler(e, () => this.save(false, false))} disabled={isSaving}>{t('Global:save')}</button>
                                {actionButton}
                            </div>

                            <div className='small'>
                                <div>{(event && event.createDateTime ? `${t('Global:createdBy')}: ${event.createdBy} - ${event.createDateTime.toShortDateString(venue.dateFormat)} ${event.createDateTime.toShortTimeString(timeFormat)}` : '')}</div>
                                {event && event.deleted && event.whenDeleted ? <div>{`${t('Global:deletedBy')}: ${event.deletedByUserName} - ${event.whenDeleted.toShortDateString(venue.dateFormat)} ${event.whenDeleted.toShortTimeString(timeFormat)}${(!isNullOrEmpty(event.deletedByUserIpAddress) ? ' [' + event.deletedByUserIpAddress + ']' : '')}`}</div> : null}
                                {event && !event.deleted && event.cancelled && event.whenCancelled ? <div>{`${t('Global:cancelledBy')}: ${event.cancelledByUserName} - ${event.whenCancelled.toShortDateString(venue.dateFormat)} ${event.whenCancelled.toShortTimeString(timeFormat)}${(!isNullOrEmpty(event.cancelledByUserIpAddress) ? ' [' + event.deletedByUserIpAddress + ']' : '')}`} {!isNullOrEmpty(event.cancellationReason) ? ` - ${t(event.cancellationReason)}` : ''}</div> : null}
                            </div>
                        </div>

                    </form>
                </div>);
        }
    }

    renderActionButton = (isNew: boolean, hasPaidPayments: boolean, isSaving: boolean, cancelled: boolean, deleted: boolean, hasActiveBooking: boolean) => {
        const { t } = this.context;

        if (cancelled || deleted) {
            return <button className='btn btn-warning' onClick={this.reinstateEvent} disabled={isSaving}>{t('EventForm:reinstateEvent')}</button>
        }

        if (isNew) {
            return <button className='btn btn-basic' onClick={this.close} disabled={isSaving}>{t('Global:cancel')}</button>
        }

        if (!hasActiveBooking) {
            if (hasPaidPayments) {

                return <button className='btn btn-danger' onClick={this.cancelEvent} disabled={isSaving}>{t('EventForm:cancelEvent')}</button>
            } else {
                return <button className='btn btn-danger' onClick={this.deleteEvent} disabled={isSaving}>{t('EventForm:deleteEvent')}</button>;
            }
        }
    }

    renderTabContent = (selectedTab: Tab, areReservationsValid: boolean) => {
        switch (selectedTab) {
            case Tab.Details:
                return this.renderDetails();
            case Tab.Bookings:
                return this.renderBookings(areReservationsValid);
            case Tab.Registrations:
                return this.renderRegistrations();
            default:
                return null;
        }
    }

    renderDetails = () => {
        const { t } = this.context;
        const { bills, venue, activityFormats, resources, products } = this.props;
        const { eventName, reservations, colour } = this.state;
        const venueActivityFormats = activityFormats.filter(f => f.venueId === venue.id);
        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;

        return <>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='at-panel'>
                        <ct.TextBox id='name' labelKey='EventForm:name' placeholderKey='EventForm:name' value={eventName} callback={val => this.setState({ eventName: this.validateName(val) })} />
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-12'>
                    <div className='at-panel'>
                        <ct.Colours colours={colours} selectedColour={colour.value} colourChanged={c => this.setState({ colour: this.validateColour(c) })} />
                    </div>
                </div>
            </div>

            <div className='row mt-15'>
                <div className='col-md-12'>
                    <div className="at-panel">
                        <label>{t('EventForm:activities')}</label>
                        <EventReservations
                            reservations={reservations}
                            activityFormats={venueActivityFormats}
                            resources={resources}
                            products={products}
                            bills={bills}
                            timeFormat={timeFormat}
                            showNotes={this.showNotes}
                            editReservation={this.editReservation}  t={t} />
                    </div>
                </div>
            </div>
        </>
    }

    calculateItemTotalPrice = (reservation: Reservation) => {
        const { bills } = this.props;
        if (this.props.bills) {
            const totalPrice = bills.reduce((ttl, bill) => ttl + bill.items.reduce((it, item) => item.reservationId === reservation.key ? it + item.totalItemPrice : 0, 0), 0);
            return totalPrice;
        }
        return 0;
    }

    renderBookings = (areReservationsValid: boolean) => {
        const { t } = this.context;
        const { history, event, bills, venue, resources, activityFormats, promotions, numberOfParticipants, showModal, closeModal, isSaving, products,
            customerCategories, emailTemplates, logout, completeWebBooking, onBookingChanged } = this.props;
        const { type, reservations, bookings, selectedBookingId } = this.state;

        // Don't show cancelled bookings without a customer (i.e. abandoned during booking process)
        const filteredBookings = bookings.filter(b => !b.cancelled || b.customer);
        const selectedBooking = this.findBooking(filteredBookings, selectedBookingId);
        const bookingBills = bills.filter(b => selectedBooking && b.bookingId === selectedBooking.id);
        const bookingBill = bookingBills.length > 0 ? bookingBills[0] : undefined;
        
        return (
            <>
                <div className="event-form-reservation-summary at-panel">
                    <ReservationSummary
                        reservations={reservations.map(r => ({
                            key: r.key,
                            resourceId: r.resourceId,
                            resourceConfigurationId: r.resourceConfigurationId,
                            activityFormatId: r.activityFormatId,
                            activityFormatVariationId: r.activityFormatVariationId,
                            membershipTypeId: r.membershipTypeId,
                            membershipTypeLabel: r.membershipTypeLabel,
                            membershipTypeLabelColour: r.membershipTypeLabelColour,
                            reservationType: r.reservationType,
                            bookedParticipants: r.bookedParticipants,
                            maxParticipants: r.maxParticipants,
                            startTime: r.startTime.toDate(),
                            endTime: r.endTime.toDate()
                        }))}
                        resources={resources}
                        activityFormats={activityFormats}
                        venue={venue}
                    />
                </div>
                <div className='event-form-bookings-tab'>
                    <div className='event-form-bookings-tab-customers at-panel-bg at-panel-rounded'>
                        <EventCustomers
                            defaultCountryId={venue.countryId}
                            bookings={filteredBookings}
                            bills={bills}
                            reservations={reservations}
                            resources={resources}
                            activityFormats={activityFormats}
                            products={products}
                            customerCategories={customerCategories}
                            canAddCustomer={v.isValid(this.state)}
                            eventType={type.value}
                            numberOfParticipants={numberOfParticipants || 1}
                            addBooking={this.addBooking}
                            editBooking={this.editBooking}
                            selectedBookingId={selectedBookingId}
                            selectBooking={this.selectBooking}
                            editBookingQuestions={this.editBookingQuestions}
                            removeBooking={this.removeBooking}
                            confirmRemoveBooking={this.confirmRemoveBooking}
                            cancelRemoveBooking={this.cancelRemoveBooking} />
                    </div>
                    <div className='event-form-bookings-tab-booking'>
                        {selectedBooking == null
                            ? <div className='alert alert-info text-center'>{t('EventForm:NoBookingSelected')}</div>
                            : <BookingDetails
                                history={history}
                                venue={venue}
                                isSaving={isSaving}
                                defaultCountryId={venue.countryId}
                                booking={selectedBooking}
                                eventCancelled={event.cancelled}
                                bill={bookingBill}
                                reservations={reservations}
                                promotions={promotions}
                                clientEmailTemplates={emailTemplates}
                                editBill={this.editBill}
                                paymentUpdated={this.billUpdated}
                                onCustomerUpdated={this.onBookingCustomerUpdated}
                                addPayment={(bill: Bill) => this.addPayment(selectedBooking, bill)}
                                editPayment={this.editPayment}
                                updateBillItem={this.props.updateBillItem}
                                updateBillFee={this.props.updateBillFee}
                                voidRefund={this.props.voidRefund}
                                replaceDiscount={this.props.replaceDiscount}
                                removeDiscount={this.props.removeDiscount}
                                makeScheduledPayment={this.makeScheduledPayment}
                                addReservationsToBooking={this.addReservationToBooking}
                                cancelBooking={this.cancelBooking}
                                reinstateBooking={this.reinstateBooking}
                                completeWebBooking={completeWebBooking}
                                flagBooking={this.flagBooking}
                                onBookingWebsiteChanged={this.onBookingWebsiteChanged}
                                sendEmail={this.sendEmail}
                                sendPaymentLink={this.sendPaymentLink}
                                onBookingChanged={onBookingChanged}
                                products={products}
                                activityFormats={activityFormats}
                                resources={resources}
                                customerCategories={customerCategories}
                                hasInvalidReservation={!areReservationsValid}
                                showModal={showModal}
                                closeModal={closeModal}
                                logout={logout} />
                        }
                    </div>
                </div>
            </>
        );
    }

    renderRegistrations = () => {
        const { venue, event, registrations, emailTemplates, showModal, closeModal } = this.props;
        const { bookings } = this.state;
        const { t } = this.context;

        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;

        return <div className='event-form-registrations-tab at-panel'>
            <div className='event-form-registrations-tab-registrations'>
                <EventRegistrations
                    hasResultUrl={!isNullOrEmpty(event.resultsUrl)}
                    registrations={registrations}
                    bookings={bookings}
                    viewRegistration={this.viewRegistration}
                    emailTemplates={emailTemplates}
                    timeFormat={timeFormat}
                    dateFormat={dateFormat}
                    sendEmail={(clientEmailTemplate: ClientEmailTemplate, toEmailAddress: string, customerId: string | null, emailSent: (error: string | null) => void) => this.sendEmail(clientEmailTemplate, toEmailAddress, null, customerId, emailSent)}
                    showModal={showModal}
                    closeModal={closeModal} />
            </div>

            {isNullOrEmpty(event.resultsUrl) && event.startTime < new Date() ? <div className='alert alert-warning text-center'>{t('EventForm:noResultsUrl')}</div> : null}
            <div><label>{t('EventForm:registrationListUrl')}</label> <span>{event.publicEventPageUrl}</span><span className='glyphicon glyphicon-copy' style={({ cursor: 'pointer', padding: '5px', marginLeft: '10px', fontSize: '20px', color: '#337ab7' })} onClick={e => clickHandler(e, () => copyToClipboard(event.publicEventPageUrl))}></span></div>
        </div>
    }
}

const mapStateToProps = (state: ApplicationState) => {

    const isLoading = state.products.isLoading || state.productCategories.isLoading || state.taxRates.isLoading || state.activityFormats.isLoading || state.tasks.isLoading || state.paymentMethods.isLoading;
    const venueId = state.venues.selectedVenueId;

    return {
        resources: state.resources.resources,
        diaryValidationErrors: state.diary.validationErrors,
        activatingTask: state.diary.activatingTask,
        taskActivationError: state.diary.taskActivationError,
        taskActivationErrors: state.diary.taskActivationErrors,
        isLoading: isLoading,
        products: state.products.products.filter(x => !x.archived),
        productsLoading: state.products.isLoading,
        productCategories: state.productCategories.productCategories.filter(c => c.venueId ===  venueId),
        productCategoriesLoading: state.productCategories.isLoading,
        taxRates: state.taxRates.taxRates,
        taxRatesLoading: state.taxRates.isLoading,
        fees: state.fees.fees.filter(f => f.venueId === venueId),
        feesLoading: state.fees.isLoading,
        activityFormats: state.activityFormats.activityFormats,
        activityFormatsLoading: state.activityFormats.isLoading,
        paymentMethods: state.paymentMethods.paymentMethods.filter(x => x.venueId === venueId && !x.archived),
        paymentMethodsLoading: state.paymentMethods.isLoading,
        emailTemplates: state.emailTemplates.emailTemplates.filter(t => !isNullOrEmpty(t.clientEmailTemplateId) && !t.archived && t.venueId === venueId),
        emailTemplatesLoading: state.emailTemplates.isLoading,
        customerCategories: state.customerCategories.customerCategories,
        customerCategoriesLoading: state.customerCategories.isLoading,
        promotions: state.promotions.promotions,
        vouchers: state.vouchers.voucherProducts,
        membershipTypes: state.memberships.membershipTypes
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => (
    {
        showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
        closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
        loadProducts: bindActionCreators(ProductActions.actionCreators.loadProducts, dispatch),
        loadProductCategories: bindActionCreators(ProductCategoryActions.actionCreators.loadProductCategories, dispatch),
        loadTaxRates: bindActionCreators(TaxRateActions.actionCreators.loadTaxRates, dispatch),
        loadActivityFormats: bindActionCreators(ActivityFormatActions.actionCreators.loadActivityFormats, dispatch),
        loadPaymentMethods: bindActionCreators(PaymentMethodActions.actionCreators.loadPaymentMethods, dispatch),
        loadEmailTemplates: bindActionCreators(EmailTemplateActions.actionCreators.loadEmailTemplates, dispatch),
        loadCustomerCategories: bindActionCreators(CustomerCategoryActions.actionCreators.loadCustomerCategories, dispatch),
        logout: bindActionCreators(LoginActions.actionCreators.logout, dispatch)
    }
);

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(EventForm);
