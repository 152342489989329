
import '../../../css/quill.css';

import * as React from 'react';
import * as PropTypes from 'prop-types';
import { DateFormat, RegistrationField, RegistrationSettings } from '../../../store/pages/venues/types';
import * as api from '../../../store/apiClient';
import RegistrationStartPanel from './registrationStartPanel';
import RegistrationStep1 from './registrationStep1';
import RegistrationNewAddress from './registrationAddress';
import MissingFields from './missingFields';
import RegistrationContactPrefs from './registrationContactPrefs';
import RegistrationConfirmation from './registrationConfirmation';
import RegistrationTerms from './registrationTerms';
import RegistrationReturningCustomer from './registrationReturningCustomer';
import { Customer, CustomerType, Gender} from '../../../store/pages/customer/types';
import {MarketingPreference} from '../../../store/pages/customer/types';
import { clickHandler, isNullOrEmpty } from '../../../utils/util';

interface RegistrationWrapperProps {
    at: string;
    rt: string;
    logout: () => void;
    reloadSettings: () => void;
    registrationSettings: RegistrationSettings;
}

enum Step {
    Welcome = 1,
    NameAndDob = 2,
    NewCustomer = 3,
    ExistingCustomer = 4,
    MissingFields = 5,
    ContactPreferences = 6,
    TermsAndConditions = 7,
    Confirmation = 8
}

interface RegistrationWrapperState {
    step: Step;
    at: string;
    errorMessage: string | null;
    months: string[];
    customer: Customer;
    customers: Customer[];
    registering: boolean;
}

class RegistrationWrapper extends React.Component<RegistrationWrapperProps, RegistrationWrapperState> {

    constructor(props: RegistrationWrapperProps) {
        super(props);

        const clearState = this.buildClearState(props.at, props.registrationSettings.venueCountryId);
        this.state = { ...clearState, months: [] };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        const t = this.context.t;
        const months = [t('Global:January'), t('Global:February'), t('Global:March'), t('Global:April'), t('Global:May'), t('Global:June'), t('Global:July'), t('Global:August'), t('Global:September'), t('Global:October'), t('Global:November'), t('Global:December')];

        this.setState({ months: months });
    }

    buildClearState = (at: string, defaultCountryId: number) => {
        return {
            at: at,
            errorMessage: null,
            step: Step.Welcome,
            customer: this.buildEmptyCustomer(defaultCountryId),
            customers: [],
            registering: false
        };
    }

    buildEmptyCustomer = (defaultCountryId?: number, firstName?: string, lastname?: string, birthYear?: number, birthMonth?: number, birthDay?: number ) => {
        var countryId = defaultCountryId || this.props.registrationSettings.venueCountryId;
        return {
            id: '',
            customerType: CustomerType.Competitor,
            firstname: firstName || '',
            lastname: lastname || '',
            birthDay: birthDay ?? 1,
            birthMonth: birthMonth ?? 1,
            birthYear: birthYear ?? 0,
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            addressLine4: '',
            town: '',
            county: '',
            countryId: countryId,
            postalCode: '',
            emailAddress: '',
            phoneNumber: '',
            emergencyContactName: '',
            emergencyContactNumber: '',
            resultsPreference: MarketingPreference.None,
            marketingPreference: MarketingPreference.None,
            publicResultsConsent: true,
            photographUrl: '',
            notes: '',
            nickname: null,
            gender: Gender.NotProvided
        };
    }

    searchForCustomer = (firstName: string, lastName: string, birthDay: number, birthMonth: number, birthYear: number, isRetry: boolean) => {
        api.searchForCustomer(this.state.at, firstName, lastName, birthDay, birthMonth)
            .subscribe(res => {
                const customers = res.customers.map(c => ({
                    ...c,
                    birthYear: c.birthYear > 0 ? c.birthYear : birthYear,
                    birthMonth: c.birthMonth > 0 ? c.birthMonth : birthMonth,
                    birthDay: c.birthDay > 0 ? c.birthDay : birthDay,
                }));

                // First find any existing customers with a postcode.
                const customersWithPostcode = customers.filter(c => !isNullOrEmpty(c.postalCode))

                // If we don't have any customers with a postcode, collect their details as a new csutomer and they will be merged at the end if they are a duplicate
                if (customersWithPostcode.length === 0) {
                    this.showNewCustomer(firstName, lastName, birthDay, birthMonth, birthYear);
                } else {
                    this.setState({
                        step: Step.ExistingCustomer,
                        customer: this.buildEmptyCustomer(this.props.registrationSettings.venueCountryId, firstName, lastName, birthYear, birthMonth, birthDay),
                        customers: customersWithPostcode
                    });
                }
            }, err => {
                if (isRetry) {
                    this.props.logout();
                }
                else if (err.status === 401) {
                    api.refreshToken(this.props.rt, false).subscribe(
                        rr => {
                            this.setState({ at: rr.accessToken });
                            this.searchForCustomer(firstName, lastName, birthDay, birthMonth, birthYear, true);
                        },
                        er => {
                            this.props.logout();
                        }
                    );
                } else {
                    this.props.logout();
                }
            });
    }

    register = (customer: Customer, signatureSvg: string, counterSignature: api.CounterSignature | null, isRetry: boolean = false) => {
        const { registrationSettings, rt, logout } = this.props;
        const { at } = this.state;

        this.setState({ registering: true });
        api.registerCustomer(at, registrationSettings.venueId, registrationSettings.kioskId, customer, registrationSettings.termsAndConditionsId, signatureSvg, counterSignature)
            .subscribe(res => {
                this.setState({ step: Step.Confirmation, registering: false });
            }, err => {
                this.setState({ registering: false });

                if (isRetry) {
                    logout();
                }
                else if (err.status === 401) {
                    api.refreshToken(rt, false).subscribe(
                        rr => {
                            this.setState({ at: rr.accessToken });
                            this.register(customer, signatureSvg, counterSignature, true);
                        },
                        er => {
                            logout();
                        }
                    );
                } else {
                    // handle error
                }
            });
    }

    hasMissingRequiredFields = (customer: Customer) => {
        const { registrationSettings } = this.props;
        if ((registrationSettings.requiredRegistrationFields & RegistrationField.BasicAddress) === RegistrationField.BasicAddress || (registrationSettings.requiredRegistrationFields & RegistrationField.FullAddress) === RegistrationField.FullAddress) {
            if (isNullOrEmpty(customer.addressLine1)) return true;
            if (isNullOrEmpty(customer.postalCode)) return true;
        }

        if ((registrationSettings.requiredRegistrationFields & RegistrationField.Gender) === RegistrationField.Gender) {
            if (customer.gender === Gender.NotProvided) return true;
        }

        if ((registrationSettings.requiredRegistrationFields & RegistrationField.EmergencyContactName) === RegistrationField.EmergencyContactName) {
            if (isNullOrEmpty(customer.emergencyContactName)) return true;
        }

        if ((registrationSettings.requiredRegistrationFields & RegistrationField.EmergencyContactNumber) === RegistrationField.EmergencyContactNumber) {
            if (isNullOrEmpty(customer.emergencyContactNumber)) return true;
        }

        return false;
    }

    showWelcome = () => {
        this.setState((prevState, props) => this.buildClearState(prevState.at, props.registrationSettings.venueCountryId), this.props.reloadSettings);
    }

    showStep1 = () => {
        this.setState({ step: Step.NameAndDob });
    }

    showNewCustomer = (firstName: string, lastName: string, birthDay: number, birthMonth: number, birthYear: number) => {
        var emptyCustomer = this.buildEmptyCustomer();
        this.setState({ step: Step.NewCustomer, customer: { ...emptyCustomer, customerType: CustomerType.Competitor, firstname: firstName, lastname: lastName, birthDay: birthDay, birthMonth: birthMonth, birthYear: birthYear } });
    }

    confirmExistingCustomer = (customer: Customer) => {
        if (this.hasMissingRequiredFields(customer)) {
            this.showMissingRequiredFields(customer);
        } else {
            this.showContactPrefs(customer);
        }
    }

    showMissingRequiredFields = (customer: Customer) => {
        this.setState({ step: Step.MissingFields, customer: customer });
    }

    showContactPrefs = (customer: Customer) => {
        this.setState({ step: Step.ContactPreferences, customer: customer });
    }

    showConfirmation = (customer: Customer) => {
        this.setState({ step: Step.TermsAndConditions, customer: customer });
    }

    render() {
        const { customer, customers, step, months, registering } = this.state;
        const { registrationSettings } = this.props;
        const img = registrationSettings.clientImageId ? <img src={api.getClientImageUrl(registrationSettings.clientImageId || '')} className='registration-header-image' alt='header' /> : null;

        const bd = customer.birthDay;
        const bm = months[customer.birthMonth - 1];

        const standardHeader = customer === null
            ? null
            : (
                <div>
                    <span className='registration-step-title'>{customer.firstname} {customer.lastname}</span>
                    <span className='registration-step-title'>{registrationSettings.dateFormat === DateFormat.MDY ? bm : bd} {registrationSettings.dateFormat === DateFormat.MDY ? bd : bm} {customer.birthYear}</span>
                    <button type='button' className='btn btn-link' onClick={e => clickHandler(e, this.showStep1)}>{this.context.t('Global:edit')}</button>
                </div>
            );

        let body: any = null;
        let stepHeader: any = null;
        switch (step) {
            case Step.Welcome:
                body = <RegistrationStartPanel welcomeText={registrationSettings.welcomeText} startRegistration={this.showStep1} images={registrationSettings.welcomeScreenImageUrls} imageDelayInMiliseconds={registrationSettings.welcomeImageInterval * 1000} />;
                stepHeader = null;
                break;
            case Step.NameAndDob:
                body = <RegistrationStep1
                    registrationFields={registrationSettings.registrationFields}
                    minAge={registrationSettings.minAge}
                    searchForCustomer={(fn, ln, bd, bm, by: number) => this.searchForCustomer(fn, ln, bd, bm, by, false)}
                    headerText={registrationSettings.nameStepHeading}
                    months={months} firstName={customer.firstname}
                    lastName={customer.lastname}
                    birthDay={customer.birthDay}
                    birthMonth={customer.birthMonth}
                    birthYear={customer.birthYear}
                    dateFormat={registrationSettings.dateFormat}
                />;
                stepHeader = null;
                break;
            case Step.NewCustomer:
                body = <RegistrationNewAddress showStep1={this.showStep1}
                    customer={customer}
                    defaultCountryId={registrationSettings.venueCountryId}
                    registrationFields={registrationSettings.registrationFields}
                    requiredRegistrationFields={registrationSettings.requiredRegistrationFields}
                    headerText={registrationSettings.addressStepHeading}
                    cancel={this.showWelcome}
                    next={this.showContactPrefs} />;
                stepHeader = standardHeader;
                break;
            case Step.ExistingCustomer:
                var custs = customers;
                const newCust = () => this.showNewCustomer(customer.firstname, customer.lastname, customer.birthDay, customer.birthMonth, customer.birthYear);
                body = <RegistrationReturningCustomer
                    customers={custs}
                    birthYear={customer.birthYear}
                    birthMonth={customer.birthMonth}
                    birthDay={customer.birthDay}
                    headerText={registrationSettings.returningCustomerStepHeading}
                    cancel={this.showWelcome}
                    next={this.confirmExistingCustomer}
                    registerAsNewCustomer={newCust} />;
                stepHeader = standardHeader;
                break;
            case Step.MissingFields:
                body = <MissingFields
                    customer={customer}
                    headerText={registrationSettings.addressStepHeading}
                    requiredRegistrationFields={registrationSettings.requiredRegistrationFields}
                    cancel={this.showWelcome}
                    next={this.showContactPrefs}
                    />;
                stepHeader = standardHeader;
                break;
            case Step.ContactPreferences:
                body = <RegistrationContactPrefs showStep1={this.showStep1}
                    customer={customer}
                    headerText={registrationSettings.contactPreferencesStepHeading}
                    registrationFields={registrationSettings.registrationFields}
                    requiredRegistrationFields={registrationSettings.requiredRegistrationFields}
                    resultsMarketingText={registrationSettings.resultsMarketingText}
                    generalMarketingText={registrationSettings.generalMarketingText}
                    cancel={this.showWelcome}
                    showConfirmation={this.showConfirmation} />;
                stepHeader = standardHeader;
                break;
            case Step.TermsAndConditions:
                body = <RegistrationTerms customer={customer}
                    register={this.register}
                    registering={registering}
                    terms={registrationSettings.termsAndConditions}
                    confirmTermsText={registrationSettings.confirmTermsText}
                    counterSignatoryTermsId={registrationSettings.counterSignatoryTermsId}
                    counterSignatoryTerms={registrationSettings.counterSignatoryTerms}
                    counterSignatoryConfirmationText={registrationSettings.confirmCounterSignatoryTermsText}
                    SeparateCounterSignatoryTermsRequired={registrationSettings.separateCounterSignatoryTermsRequired}
                    minAgeForSelfRegistration={registrationSettings.minAgeForSelfRegistration}
                    requireSignatures={registrationSettings.requireSignatures}
                    showNickname={(registrationSettings.registrationFields & RegistrationField.Nickname) === RegistrationField.Nickname }
                    cancel={this.showWelcome} />;
                stepHeader = null;
                break;
            case Step.Confirmation:
                stepHeader = null;
                body = <RegistrationConfirmation customer={customer} newRegistration={() => this.showWelcome()} finalInstructions={registrationSettings.finalInstructions} />;
        }

        return (
            <div className='registration-login-wrapper'>
                <div className={`registration-header-${(stepHeader ? 'right' : 'center')}`}>
                    <div className='registration-step-header'>
                        {stepHeader}
                    </div>
                    {img}
                </div>
                {body}
                {step === Step.Welcome && registrationSettings && registrationSettings.kioskName ? <div className='registration-page-kiosk-name'>{registrationSettings.kioskName}</div> : null}
            </div>
        );
    }
}

export default RegistrationWrapper;