
import * as React from 'react';
import * as PropTypes from 'prop-types'
import { IBooking } from './types';
import { ITranslationContext } from '../../../translations';
import { RegistrationEventBookingNote } from '../../../store/pages/reception/types';
import { Link } from 'react-router-dom';
import { clickHandler } from '../../../utils/util';
import CompetitorCounts from './competitorCounts';
import Flag from '../../icons/flag';
import Notes from '../../icons/notes';

interface ReservationBookingHeaderProps {
    booking: IBooking;
    enableSelectBooking: boolean;
    venueId: string;
    eventId: string;
    actions?: React.ReactNode[];
    children?: React.ReactNode;
    showNotes: (notes: RegistrationEventBookingNote[], name: string, billNumber: string) => void;
}

const ReservationBookingHeader = (props: ReservationBookingHeaderProps, context: ITranslationContext) => {
    const { t } = context;
    const { booking, enableSelectBooking, venueId, eventId, showNotes, actions, children } = props;

    return <React.Fragment key={booking.id}>
        <div key={booking.id} className='reception-booking-header'>
            <div className='reception-booking-bill-number'>{enableSelectBooking ? <Link to={{ pathname: `/diary/${venueId}/event/${eventId}`, state: { bookingId: booking.id } }}>{booking.billNumber}</Link> : <span>{booking.billNumber}</span>}</div>
            <div className='reception-booking-customer'>{booking.organiserFirstName} {booking.organiserLastName}</div>
            <div className='reception-booking-notes'>
                {booking.notes.length > 0 ? <><span className='clickable-icon' onClick={e => clickHandler(e, () => showNotes(booking.notes, `${booking.organiserFirstName} ${booking.organiserLastName}`, booking.billNumber))} ><Notes width={20} height={20} colour='#337ab7' /></span><span>{booking.notes.sort((n1, n2) => n2.createDateTime.getTime() - n1.createDateTime.getTime())[0].text}</span></> : null}
            </div>
            {booking.flagged ? <span className='pull-right'><Flag width={20} height={20} colour='#d9534f' /></span> : null}
            <CompetitorCounts bookedParticipants={booking.bookedParticipants} registeredParticipants={booking.registeredParticipants} checkedInParticipants={booking.checkedInParticipants} />
            <div className='reception-booking-outstanding-amount'>{booking.outstandingAmount > 0 ? `${t('Global:currencySymbol')}${booking.outstandingAmount.toFixed(2)}` : ''}</div>
            <div className='reception-booking-actions text-center'>
                {actions}
            </div>
        </div>
        {children}
    </React.Fragment>
}


ReservationBookingHeader.contextTypes = {
    t: PropTypes.func
}

export default ReservationBookingHeader;
