import { ValidationError } from '../../global/types';
import * as api from '../../apiClient';
import { ReportTypes } from '../reportTypes/types';

export enum ReportColumnType {
    String = 1,
    Number = 2,
    Currency = 3,
    Date = 4,
    Time = 5,
    DateTime = 6
}

export enum ColumnTextAlignment {
    Left = 1,
    Center = 2,
    Right = 3
}

export enum SortDirection {
    Ascending = 0,
    Descending = 1
}

export enum ReportPermission {
    None = 0,
    Run = 1,
    Edit = 2
}

export interface ReportDefinitionColumn {
    dataElementKey: string;
    order: number;
    heading: string;
    total: boolean;
    sortOrder: number | null;
    sortDirection: SortDirection;
    summarize: boolean;
}

export interface ReportDefinitionColumnEx extends  ReportDefinitionColumn {
    dataElementName: string;
    columnType: ReportColumnType;
    canTotal: boolean;
    format: string;
    alignment: ColumnTextAlignment;
}

export interface ReportDefinitionFilter {
    key: string;
    defaultOptions: string[];
    selectable: boolean;
}

export interface ReportDefinitionFilterOption {
    name: string;
    options: FilterOption[];
    required: boolean;
}

export interface ReportDefinitionPermission {
    userAccountId: string;
    userAccountName: string;
    userName: string;
    permission: ReportPermission;
}

export interface ReportDefinition {
    id: string;
    name: string;
    description: string;
    countHeading: string;
    reportType: ReportTypes;
    columns: ReportDefinitionColumnEx[];
    filters: ReportDefinitionFilter[];
    filterOptions: ReportDefinitionFilterOption[];
    permissions: ReportDefinitionPermission[];
    canEdit: boolean;
    canRun: boolean;
    archived: boolean;
}

export interface ReportDefinitionState {
    isLoading: boolean;
    reportDefinitions: ReportDefinition[];
    loadError: api.ApiError | null;
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
}

export enum ReportFilterType {
    Text = 1,
    Date = 2,
    Time = 3,
    DateTime = 4,
    DateRange = 5,
    DateTimeRange = 6,
    Boolean = 7,
    List = 8,
    NumericRange = 9,
    MultiSelectList = 10,
}

export interface FilterOption {
    key: string;
    nameKey: string;
    selected: boolean;
}

export interface ReportFilter {
    name: string;
    type: ReportFilterType;
    enabled: boolean;
    selectable: boolean;
    values: string[];
    options: FilterOption[];
}

export interface ReportSettings {
    reportName: string;
    countHeading: string;
    filters: ReportFilter[];
}

export enum ReportDefinitionActionTypes {
    RequestReportDefinitions = 'REQUEST_REPORT_DEFINITIONS',
    ReceivedReportDefinitions = 'RECEIVE_REPORT_DEFINITIONS',
    SaveReportDefinition = 'SAVE_REPORT_DEFINITION',
    ReportDefinitionSaved = 'REPORT_SAVED',
    ReportDefinitionSaveFailed = 'REPORT_SAVE_FAILED',
    EditReportDefinition = 'EDIT_REPORT_DEFINITION'
}

export interface LoadReportDefinitions {
    type: ReportDefinitionActionTypes.RequestReportDefinitions;
}

export interface ReceiveReportDefinitions {
    type: ReportDefinitionActionTypes.ReceivedReportDefinitions;
    reportDefinitions: ReportDefinition[];
    error: api.ApiError | null;
}

export interface SaveReportDefinition {
    type: ReportDefinitionActionTypes.SaveReportDefinition;
    isNew: boolean;
    reportDefinitionId: string | null;
    name: string;
    reportType: ReportTypes;
    description: string;
    countHeading: string;
    columns: ReportDefinitionColumn[];
    filters: ReportDefinitionFilter[];
    permissions: ReportDefinitionPermission[];
    archived: boolean;
}

export interface ReportDefinitionSaved {
    type: ReportDefinitionActionTypes.ReportDefinitionSaved;
    reportDefinitionId: string;
}

export interface ReportDefinitionSaveFailed {
    type: ReportDefinitionActionTypes.ReportDefinitionSaveFailed;
    error: api.ApiError;
}

export interface EditReportDefinition {
    type: ReportDefinitionActionTypes.EditReportDefinition
}
