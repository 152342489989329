
import * as React from 'react';
import * as PropTypes from 'prop-types'
import { RouteComponentProps, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import moment from 'moment';

import * as api from '../../../store/apiClient';
import * as LoginActions from '../../../store/pages/login/actions';
import * as ModalActions from '../../../store/global/modal/actions';
import * as ct from '../../global/controls';

import { ApplicationState } from '../../../store';
import { Membership } from '../../../store/pages/memberships/types';
import Loading from '../../global/loading';
import ApiError from '../../global/apiError';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';
import { clickHandler, mapLocalDateTime, parseLocalDateTime } from '../../../utils/util';
import TagLabel from '../../global/tagLabel';
import TransferMembership from './transferMembership';
import { AuditTrailEntry } from '../../../store/pages/diary/types';
import AuditLogEntry from '../../global/auditLogEntry';
import { isClientAdmin } from '../../../utils/auth';
import SendMembershipEmailOverlay from '../pointOfSale/sendMembershipEmailOverlay';


interface GetMembershipResponse {
    membership: Membership;
    auditTrail: AuditTrailEntry[];
}

interface LocalProps {
}

interface MappedReduxState {
    venueId: string;
    dateFormat: DateFormat;
    timeFormat: TimeFormat;
}

interface ReduxActions {
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
    logout: () => void;
}

interface MembershipDetailsRouteProps {
    membershipId: string;
}

type RoutedLocalProps = LocalProps & RouteComponentProps<MembershipDetailsRouteProps>;

type MembershipDetailsProps = MappedReduxState & ReduxActions & RoutedLocalProps;

interface MembershipDetailsState {
    loading: boolean;
    loadError: api.ApiError | null;
    membership: Membership | null;
    expiryDate: ct.FormValue<moment.Moment | null>;
    expiryChanged: boolean;
    auditTrail: AuditTrailEntry[];
    updatingExpiry: boolean;
    updatingExpiryError: api.ApiError | null;
}

class MembershipDetails extends React.Component<MembershipDetailsProps, MembershipDetailsState> {

    constructor(props: MembershipDetailsProps) {
        super(props);

        this.state = {
            membership: null,
            expiryDate: ct.asFormValue('expiryDate', null),
            expiryChanged: false,
            loading: true,
            loadError: null,
            updatingExpiry: false,
            updatingExpiryError: null,
            auditTrail: []
        }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    membershipId = () => this.props.match && this.props.match.params ? this.props.match.params.membershipId : null;

    componentDidMount() {
        this.loadMembership();
    }

    loadMembership = () => {
        const membershipId = this.membershipId();
        api.getWithAuth<GetMembershipResponse>(`api/v1/membership/${membershipId}`, this.props.logout)
            .subscribe(
                res => this.setState({
                    loading: false,
                    membership: {
                        ...res.membership,
                        purchaseDate: parseLocalDateTime(res.membership.purchaseDate),
                        expiryDate: mapLocalDateTime(res.membership.expiryDate),
                    },
                    expiryDate: ct.asFormValue('expiryDate', res.membership.expiryDate ? moment(mapLocalDateTime(res.membership.expiryDate)) : null),
                    expiryChanged: false,
                    auditTrail: res.auditTrail.map(a => ({ ...a, time: parseLocalDateTime(a.time) })),
                    loadError: null
                }),
                err => this.setState({ loading: false, membership: null, loadError: err }))
    }

    close = () => {
        const { history } = this.props;
        history.goBack();
    }

    resendMembershipEmail = () => {
        const { venueId, showModal, closeModal, logout } = this.props;
        const { membership } = this.state;

        if (!membership) return;

        showModal(<SendMembershipEmailOverlay
            venueId={venueId}
            membershipIds={[membership.id]}
            customerName={`${membership.customerFirstname} ${membership.customerLastname}`}
            customerEmail={membership.customerEmail}
            close={closeModal}
            logout={logout} />, "SendMembershipEmail")
    }

    transferMembership = () => {
        const { membership } = this.state;
        const { showModal, closeModal, dateFormat, logout } = this.props;

        const close = (transferred: boolean) =>
        {
            if (transferred) {
                this.loadMembership();
            }
            closeModal();
        }

        if (membership) { 
            showModal(<TransferMembership
                membership={membership}
                close={close}
                dateFormat={dateFormat}
                logout={logout}
            />, "TransferMembership")
        }
    }

    updateExpiry = () => {
        const { expiryDate } = this.state;
        const membershipId = this.membershipId();
        this.setState({ updatingExpiry: true, updatingExpiryError: null })
        api.putWithAuth(`api/v1/membership/${membershipId}/updateExpiry`, { newExpiry: expiryDate.value ? expiryDate.value.toDate() : null }, this.props.logout)
            .subscribe(
                res => this.setState({ updatingExpiry: false, updatingExpiryError: null}, this.loadMembership),
                err => this.setState({ updatingExpiry: false, updatingExpiryError: err }))
    }

    expiryChanged = (newExpiry: moment.Moment | null, oldExpiry: Date | null) => {
        if (!newExpiry && !oldExpiry) return false;
        if (!newExpiry || !oldExpiry) return true;
        return !newExpiry.isSame(moment(oldExpiry), 'day');
    }

    onExpiryChanged = (newExpiry: moment.Moment | null) => this.setState(s => {
        const hasChanged = s.membership && this.expiryChanged(newExpiry, s.membership.expiryDate) ? true : false
        return { expiryDate: ct.asFormValue('expiryDate', newExpiry), expiryChanged: hasChanged }
    })

    render() {
        const { t } = this.context;
        const { dateFormat, timeFormat } = this.props;
        const { loading, loadError, membership, expiryDate, expiryChanged, updatingExpiry, updatingExpiryError, auditTrail } = this.state;

        var wrapper = (content: JSX.Element) => <section className='panel panel-default mt-15'><div className='panel-body'>{content}</div></section>

        if (loading) {
            return wrapper(<Loading />)
        }

        if (loadError) {
            return wrapper(<ApiError error={loadError} />)
        }

        if (!membership) {
            return wrapper(<div className='alert alert-danger'>{t('MembershipDetails:membershipNotFound')}</div>)
        }

        return <section className='panel panel-default mt-15'>
            <div className='panel-body'>
                <h2>{t(`MembershipDetails:title`, { membershipNumber: membership.membershipNumber })}</h2>

                <div className='row mt-15'>
                    <div className='col-xs-6'>
                        <TagLabel colour={membership.tagColour} name={`${membership.tagName} ${membership.membershipNumber}`} style={{ fontSize: '14px'}} />
                    </div>
                </div>

                <div className='row mt-15'>
                    <div className='col-md-12 col-xs-12 col-sm-12'>
                        <label>{t('MembershipDetails:member')}</label>
                    </div>
                    <div className='col-md-3 col-xs-4 col-sm-4'>
                        <div>{membership.customerFirstname} {membership.customerLastname}</div>
                    </div>
                    <div className='col-md-3 col-xs-4 col-sm-4'>
                        <div>{membership.customerEmail}</div>
                    </div>
                    <div className='col-md-6 col-xs-12 col-sm-4'>
                        <button className='btn btn-primary' onClick={e => clickHandler(e, this.transferMembership)}>{t('MembershipDetails:transfermembership')}</button>
                    </div>
                </div>

                <div className='row mt-15'>
                    <div className='col-md-3 col-xs-4 col-sm-4'>
                        <button className='btn btn-primary' onClick={e => clickHandler(e, this.resendMembershipEmail)}>{t('MembershipDetails:resendEmail')}</button>
                    </div>
                </div>

                <div className='row mt-15'>
                    <div className='col-md-3 col-xs-4'>
                        <label>{t('MembershipDetails:purchaseDate')}</label>
                    </div>
                    <div className='col-md-3 col-xs-8'>
                        <label>{t('MembershipDetails:expiry')}</label>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-3 col-xs-4'>
                        <div>{membership.purchaseDate.toAbbrDateString(dateFormat, t)}</div>
                    </div>
                    {isClientAdmin() ?
                        <>
                            <div className='col-md-3 col-xs-4'>
                                <ct.DatePicker id='expiryDate' labelKey='' value={expiryDate} callback={val => this.onExpiryChanged(val)} dateFormat={dateFormat} />
                            </div>
                            <div className='col-md-3 col-xs-2'>
                                <button className='btn btn-primary' disabled={!expiryChanged || updatingExpiry} onClick={e => clickHandler(e, this.updateExpiry)}>{t('Global:update')}</button>
                            </div>
                        </>
                        : <div className='col-md-3 col-xs-4'>{membership.expiryDate ? membership.expiryDate.toAbbrDateString(dateFormat, t) : null }</div>
                    }
                </div>
                {updatingExpiryError ? <div className='row'>
                    <div className='col-xs-12'>
                        <ApiError error={updatingExpiryError} />
                    </div>
                </div> : null}
                <div className='row'>
                    <div className='col-xs-6'>
                        <div>{formatPurchasedBy(membership)}</div>
                    </div>
                </div>

                <section className='panel panel-default mt-15'>
                    <div className='panel-heading'>
                        <h4 className='no-margin'>{t('customerDetails:auditLog')}</h4>
                    </div>
                    <div className='panel-body'>
                        <ul className='list-unstyled'>
                            {auditTrail.sort((l1, l2) => l1.time.getTime() - l2.time.getTime()).map((l, ix) => <AuditLogEntry key={ix} logEntry={l} timeFormat={timeFormat} dateFormat={dateFormat} />)}
                        </ul>
                    </div>
                </section>

                <div className='btn-toolbar'>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{t('Global:close')}</button>
                </div>
            </div>
        </section>
    }
}


const formatPurchasedBy = (membership: Membership) => {
    if (membership.createdBy === 'Web-Shop') {
        return 'Global:webShop';
    } else if (membership.createdBy === 'Online-booking') {
        return 'Global:onlineBooking';
    }

    return membership.createdBy;
}

const matStateToProps = (state: ApplicationState) => ({
    venueId: state.venues.selectedVenueId,
    timeFormat: state.venues.timeFormat,
    dateFormat: state.venues.dateFormat
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
    logout: bindActionCreators(LoginActions.actionCreators.logout, dispatch),
});

// Wire up the React component to the Redux store
export default connect(matStateToProps, mapDispatchToProps)(MembershipDetails);
