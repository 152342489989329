
import { ActivityFormat, dayFromDate } from "../../../store/pages/activityFormats/types";
import { Product, ProductPricePoint, ProductPrice, ProductPricingMode, ProductFixedPrice } from "../../../store/pages/products/types";
import { CustomerCategory } from "../../../store/pages/customerCategories/types";
import { Tag } from "../../../store/pages/tags/types";
import { generateTempId, isNullOrEmpty } from "../../../utils/util";

import * as DiaryTypes from '../../../store/pages/diary/types';

import { Resource, ResourceConfiguration } from '../../../store/pages/resources/types';
import { colours, Days, Time } from "../../../store/global/types";
import { Bill, BillItem, calculateItemPrice, IProduct } from "../../../store/pages/pointOfSale/types";
import { Booking, Reservation, Map } from "./types";


export interface ActivityFormatProduct {
    key: string;
    product: Product;
    displayName: string;
    placesPerUnit: number;
    customerCategoryId: string | null;
    customerCategoryName: string | null;
    reservationId: string | null;
    reservationTime: Date | null;
}

export interface ProductPriceSelection {
    productPrice: ProductPrice;
    tagId: string;
    unitPrice: number;
    totalPrice: number;
    tagName: string;
    tagColour: string;
    isExistingSelection: boolean;
    isActive: boolean;
    effectiveFrom: Date;
    effectiveTo: Date | null;
    isOverride: boolean;
}

export interface IActivityFormatProduct {
    id: string;
    productId: string;
    product: Product;
    displayName: string;
    placesPerUnit: number;
    customerCategoryId: string | null;
    customerCategoryName: string | null;
    prices: ProductPriceSelection[];
    selectedPrice: string;
}

export const getActivityProducts = (activityFormat: ActivityFormat, reservationId: string | null, reservationTime: Date | null, products: Product[], customerCategories: CustomerCategory[]) =>
    activityFormat.products
        .map(afp => ({ afp: afp, products: products.filter(p => p.id === afp.productId) }))
        .filter(x => x.products.length > 0)
        .sort((p1, p2) => {
            if (p1.products[0].name < p2.products[0].name) {
                return -1;
            } else if (p1.products[0].name > p2.products[0].name) {
                return 1;
            }
            return 0;
        })
        .reduce<ActivityFormatProduct[]>((acc, x) => {
            const product = x.products[0];
            if (x.afp.customerCategories.length === 0) {
                // Customer category id is now required, so ew shouldn't add the product if it doesn't have a customer category
                // acc.push({ key: product.id, displayName: product.name, product: product, placesPerUnit: x.afp.numberOfPlacesToBook, customerCategoryId: null, customerCategoryName: null, reservationId: reservationId, reservationTime: reservationTime })
            } else {
                for (var ci = 0; ci < x.afp.customerCategories.length; ci++) {
                    const ccid = x.afp.customerCategories[ci];
                    const afcc = activityFormat.customerCategories.find(c => c.customerCategoryId === ccid);
                    const cc = customerCategories.find(c => c.id === ccid);
                    acc.push({
                        key: buildProductKey(product.id, ccid),
                        displayName: cc ? `${product.name} - ${cc.name}` : product.name,
                        product: product,
                        placesPerUnit: Math.max(afcc ? afcc.placesToBook : 1, 1),
                        customerCategoryId: ccid,
                        customerCategoryName: cc ? cc.name : null,
                        reservationId: reservationId,
                        reservationTime: reservationTime
                    })
                }
            }
            return acc;
        }, []);

export const GetSortedApplicablePrices = (categoryProducts: IActivityFormatProduct[], quantity: number, eventStartDate: Date) => {
    const now = new Date();

    const filteredProducts = categoryProducts
        .map(p => {
            if (!p.product) return {product: p, price: null};
            
            var applicablePrice = findApplicablePrice(p.product, now, eventStartDate);
            return { product: p, price: applicablePrice };
        })
        .filter(p => {
            if (!p.product || !p.price) return false;
            return p.price && (quantity === 0 || (p.product.product.pricingMode === ProductPricingMode.PerUnit && quantity % p.product.placesPerUnit === 0) || (p.product.product.pricingMode === ProductPricingMode.Fixed && p.price.fixedPricing.filter(fp => fp.minQuantity <= quantity).length > 0))
        });

    const findLowestMax = (fixedPricing: ProductFixedPrice[], quantity: number) => fixedPricing.filter(fp => fp.minQuantity <= quantity).reduce((qty, fp) => qty === 0 || qty > fp.maxQuantity ? fp.maxQuantity : qty, 0);

    const sortedProducts = filteredProducts.length > 0 ? filteredProducts.sort((p1, p2) => {
        if (p1.price && p2.price) {
            if (p1.product.product.pricingMode === ProductPricingMode.Fixed) {
                const p1Max = findLowestMax(p1.price.fixedPricing, quantity);
                const p2Max = findLowestMax(p2.price.fixedPricing, quantity);
                return p1Max - p2Max;
            }
            else {
                return p2.product.placesPerUnit - p1.product.placesPerUnit
            }
        }
        return -1;
    }) : filteredProducts;

    return sortedProducts;
}

export const findApplicablePrice = (product: IProduct, purchaseDate: Date, eventStartDate: Date | null, tagIds?: string[]) => {
    // Keep in sync with PricingService.FindApplicablePrice
    const getPointDate = (pricePoint: ProductPricePoint) => {
        switch (pricePoint) {
            case ProductPricePoint.PurchaseDate:
                return purchaseDate;
            case ProductPricePoint.EventOrPurchaseDate:
                return eventStartDate || purchaseDate
        }
    }

    var possiblePrices = product.pricing
        .filter(p => {
            if (p.archived)
                return false;

            if (p.tagId && tagIds && tagIds.length > 0 && !tagIds?.includes(p.tagId))
                return false;

            var pointDate = getPointDate(p.effectiveDatesPricePoint).datePart();
            if (p.effectiveFrom.datePart() > pointDate || (p.effectiveTo ?? pointDate).datePart() < pointDate)
                return false;

            if (p.applyPriceOnDays != Days.None) {
                var applicableDate = getPointDate(p.appliesOnPricePoint);
                var applicableDay = dayFromDate(applicableDate);
                if ((p.applyPriceOnDays & applicableDay) == 0)
                    return false;

                var applicableTimeOfDay = Time.fromDate(applicableDate);
                if (p.applyPriceFrom && p.applyPriceFrom > applicableTimeOfDay)
                    return false;

                if (p.applyPriceTo && p.applyPriceTo < applicableTimeOfDay)
                    return false;
            }

            return true;
        });


    let priceToUse: ProductPrice | null = null;
    for (let i = 0; i < possiblePrices.length; i++)
    {
        const price = possiblePrices[i];
        if (priceToUse == null) {
            priceToUse = price;
        }
        else {
            if (priceToUse.applyPriceOnDays == Days.None && price.applyPriceOnDays > Days.None) {
                priceToUse = price;
            }
            else if (product.pricingMode == ProductPricingMode.PerUnit && priceToUse.unitPrice > price.unitPrice) {
                priceToUse = price;
            }
            else if (product.pricingMode == ProductPricingMode.Fixed) {
                // How do we compare fixed prices if we don't know the quantity?
            }
        }
    }

    return priceToUse;
}

export const sortPrices = (p1: ProductPriceSelection, p2: ProductPriceSelection) => {
    if (p1.isActive && !p2.isActive) return -1;
    if (p2.isActive && !p1.isActive) return 1;
    if (p1.isOverride && !p2.isOverride) return -1;
    if (p2.isOverride && !p1.isOverride) return 1;
    return p1.unitPrice - p2.unitPrice;
}

const isPriceActive = (pr: ProductPrice, purchaseDate: Date, eventDate: Date | null) => {
    const date = (pr.effectiveDatesPricePoint === ProductPricePoint.EventOrPurchaseDate && eventDate ? eventDate : purchaseDate).datePart();
    const isInEffect = date >= pr.effectiveFrom.datePart() && (!pr.effectiveTo || date.datePart() <= pr.effectiveTo);

    if (!isInEffect || !pr.isOverride) {
        return isInEffect;
    }

    const applicableDate = (pr.appliesOnPricePoint === ProductPricePoint.EventOrPurchaseDate && eventDate ? eventDate : purchaseDate);
    const applicableDay = dayFromDate(applicableDate);
    const applicableTime = Time.fromDate(applicableDate);
    const applicableTimeVal = applicableTime.val();
    const appliesForTime = (!pr.applyPriceFrom || applicableTimeVal >= pr.applyPriceFrom.val()) && (!pr.applyPriceTo || applicableTimeVal <= pr.applyPriceTo.val())

    return (applicableDay & pr.applyPriceOnDays) == applicableDay && appliesForTime;
}

export const getPrices = (product: IProduct, quantity: number, purchaseDate: Date, eventDate: Date | null, customerTags: Tag[]): ProductPriceSelection[] => {

    return product.pricing
        .filter(pr => !pr.archived && (!pr.tagId || customerTags.findIndex(ct => ct.id === pr.tagId) >= 0))
        .map(pr => {
            const tag = customerTags.filter(ct => ct.id === pr.tagId)[0];
            const totalPrice = calculateItemPrice(product, pr, quantity);
            const isActive = isPriceActive(pr, purchaseDate, eventDate);

            return {
                tagId: pr.tagId || '',
                productPrice: pr,
                unitPrice: product.pricingMode === ProductPricingMode.PerUnit ? pr.unitPrice : totalPrice,
                totalPrice: totalPrice,
                tagName: tag ? tag.name : '',
                tagColour: tag ? tag.colour : '',
                isExistingSelection: false,
                isActive: isActive,
                effectiveFrom: pr.effectiveFrom,
                effectiveTo: pr.effectiveTo,
                isOverride: pr.isOverride
            }
        })
        .sort(sortPrices);
}

export const getPricesForProduct = (product: IProduct, quantity: number, purchaseDate: Date, eventDate: Date | null, tags: Tag[], unitPrice: number | null) => {
    let prices = getPrices(product, quantity, purchaseDate, eventDate, tags);

    // If there is an original price, and that price isn't in the list, add it so it can be selected
    if (unitPrice !== null) {
        // Pricing: TODO: How do we handle this?
        //if (prices.findIndex(p => p.unitPrice === unitPrice) < 0) {
        //    const overrideTotalPrice = product.pricingMode === ProductPricingMode.Fixed ? unitPrice : calculateItemPrice(product, productPrice, quantity, unitPrice);
        //    prices = [{ productTagId: 'original_price', tagId: '', productPriceId: productPrice.id, unitPrice: product.pricingMode === ProductPricingMode.PerUnit ? unitPrice : overrideTotalPrice, totalPrice: overrideTotalPrice, tagName: '', tagColour: '', isExistingSelection: true }].concat(prices.map(p => ({ ...p, isExistingSelection: false })));
        //} else {
        prices = prices.map(p => ({ ...p, isExistingSelection: p.unitPrice === unitPrice }));
        //}
    }

    return prices;
}

export const buildProductKey = (productId: string, customerCategoryId: string | null) => !isNullOrEmpty(customerCategoryId) ? `${productId}|${customerCategoryId}` : productId;

export const parseProductKey = (productKey: string) => productKey.split('|');

export const getDefaultResourceConfiguration = (resource: Resource) => resource && resource.configurations && resource.configurations.length > 0 ? resource.configurations[0] : null;

export const createNewEvent = (resource: Resource, startTime: Date, endTime: Date): DiaryTypes.Event => {
    const resourceConfiguration = getDefaultResourceConfiguration(resource);
    const colour = colours[0];
    const draft = true;
    const reservation = createReservation(resource, resourceConfiguration, '', DiaryTypes.ReservationType.NonExclusive, '', '', '', 0, false, startTime, endTime, 0, '', draft, colour, null, false);
    return createNewEventWithReservation('', startTime, endTime, draft, colour, reservation);
}

export const createReservation = (resource: Resource, resourceConfiguration: ResourceConfiguration | null, name: string, type: DiaryTypes.ReservationType, activityFormatId: string,
    activityFormatVariationId: string, variationScheduleId: string, variationScheduleSeq: number, mustBookItemsTogether: boolean, startTime: Date, endTime: Date, maxParticipants: number,
    notes: string, draft: boolean, colour: string, primaryReservationId: string | null, isMultiScheduleReservation: boolean): DiaryTypes.EventReservation => {
    return {
        id: null,
        key: generateTempId(),
        eventId: '',
        eventName: name,
        colour: colour,
        reservationType: type,
        resourceId: resource.id,
        resourceConfigurationId: resourceConfiguration ? resourceConfiguration.id : null,
        activityFormatId: activityFormatId,
        activityFormatVariationId: activityFormatVariationId,
        activityFormatVariationScheduleId: variationScheduleId,
        activityFormatVariationScheduleSequence: variationScheduleSeq,
        mustBookItemsTogether: mustBookItemsTogether,
        maxParticipants: maxParticipants,
        primaryReservationId: primaryReservationId,
        isMultiScheduleReservation: isMultiScheduleReservation,
        bookedParticipants: [],
        depositDueDate: null,
        paymentDueDate: null,
        overduePaymentDate: null,
        startTime: startTime,
        endTime: endTime,
        notes: notes,
        membershipTypeId: null,
        membershipTypeLabel: null,
        membershipTypeLabelColour: null,
        archived: false
    };
}

export const createNewEventWithReservation = (name: string, startTime: Date, endTime: Date, draft: boolean, colour: string, reservation: DiaryTypes.EventReservation): DiaryTypes.Event => {
    return {
        id: '',
        name: name,
        type: DiaryTypes.EventType.PrivateGroup,
        startTime: startTime,
        endTime: endTime,
        draft: draft,
        reservations: [reservation],
        bookings: [],
        tasks: [],
        colour: colour,
        archived: false,
        deleted: false,
        deletedByUserAccountId: null,
        deletedByUserName: null,
        deletedByUserIpAddress: null,
        whenDeleted: null,
        cancelled: false,
        cancelledByUserAccountId: null,
        whenCancelled: null,
        cancellationReason: null,
        cancelledByUserName: null,
        cancelledByUserIpAddress: null,
        resultsUrl: null,
        publicEventPageUrl: '',
        version: new Date().getTime()
    }
}

export const bookingComparer = (b1: DiaryTypes.EventBooking, b2: DiaryTypes.EventBooking) => {
    const cancelledResult = b1.cancelled && !b2.cancelled ? 1 : b1.cancelled && b2.cancelled ? 0 : -1;
    if (cancelledResult !== 0) return cancelledResult;

    const b1Name = b1.customer ? `${b1.customer.firstname} ${b1.customer.lastname}` : '';
    const b2Name = b2.customer ? `${b2.customer.firstname} ${b2.customer.lastname}` : '';
    if (b1Name < b2Name) return - 1;
    if (b1Name > b2Name) return 1;

    return 0;
}

export const buildBookingCustomerInfo = (booking: Booking, bill: Bill | null, reservations: Reservation[], customerCategories: CustomerCategory[]) => {

    const reservationIds = reservations.map(r => r.id);

    const itemsByReservation = bill && !booking.cancelled ? bill.items
        .filter(i => reservationIds.findIndex(reservationId => reservationId === i.reservationId) > -1)
        .reduce<Map<BillItem[]>>((acc, i) => {
            const key = i.reservationId || 'xxx';
            acc[key] = acc[key] ? acc[key].concat(i) : [i];
            return acc;
        }, {})
        : {};

    const competitorCountsByCategory = Object.keys(itemsByReservation).reduce<Map<number>>((acc, x) => {
        const items = itemsByReservation[x];

        const categoryCounts: Map<number> = {};

        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            const category = customerCategories.find(c => c.id === item.customerCategoryId);
            const key = category ? category.code : '';
            const qty = (item.quantity * (item.placesToBookPerUnit || 1));
            categoryCounts[key] = categoryCounts[key] ? categoryCounts[key] + qty : qty;
        }

        for (var ci = 0; ci < customerCategories.length; ci++) {
            const code = customerCategories[ci].code;
            if (categoryCounts[code]) {
                acc[code] = acc[code] ? Math.max(acc[code], categoryCounts[code]) : categoryCounts[code];
            }
        }

        return acc;
    }, {})

    return {
        booking: booking,
        customer: booking.customer,
        outstandingBalance: bill ? bill.outstandingBalance : null,
        totalAmount: bill ? bill.totalAmount : null,
        customerCategoryCounts: competitorCountsByCategory,
        customFields: booking.customFields
    }
}

