
import { Action } from 'redux';
import { LocationChangeAction } from 'react-router-redux';

import { VoucherActionTypes, VoucherState } from './types';
import { VoucherActions } from './actions'
import { createReducer } from '../../reducer';

const unloadedState: VoucherState = {
    isActive: false,
    isLoading: false,
    voucherProducts: [],
    loadError: null,
    isSaving: false,
    saveComplete: false,
    saveError: null,
    validationErrors: [],
    isSearching: false,
    voucherSearch: '',
    searchPageNumber: 1,
    searchPageSize: 30,
    searchMaxPage: 1,
    voucherSearchProductId: null,
    searchError: null,
    vouchers: []
};

export const reducer = (state: VoucherState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: VoucherState, incomingAction: Action): VoucherState => {

    if (incomingAction.type === '@@router/LOCATION_CHANGE') {
        const locationChangeAction = incomingAction as LocationChangeAction;
        if (locationChangeAction) {
            return { ...state, isActive: locationChangeAction.payload.pathname === '/vouchers' };
        }
    }

    const action = incomingAction as VoucherActions;
    switch (action.type) {
        case VoucherActionTypes.RequestVoucherProducts:
        return {
            ...state,
            isLoading: true,
            voucherProducts: state.voucherProducts,
            loadError: null
        };
        case VoucherActionTypes.ReceivedVoucherProducts:
        return {
            ...state,
            isLoading: false,
            voucherProducts: action.voucherProducts,
            loadError: action.error
        };
        case VoucherActionTypes.EditVoucherProduct:
        return {
            ...state,
            isSaving: false,
            saveError: null,
            saveComplete: false,
            validationErrors: []
        };
        case VoucherActionTypes.SaveVoucherProduct:
        return {
            ...state,
            isSaving: true,
            saveError: null,
            saveComplete: false,
            validationErrors: []
        };
        case VoucherActionTypes.VoucherProductSaved:
        return {
            ...state,
            isSaving: false,
            saveComplete: true,
            saveError: null,
            validationErrors: []
        };
        case VoucherActionTypes.VoucherProductSaveFailed:
        return {
            ...state,
            isSaving: false,
            saveError: action.error,
            saveComplete: false,
            validationErrors: action.error && action.error.validationErrors ? action.error.validationErrors : []
        };
        case VoucherActionTypes.SearchVouchers:
            return {
                ...state,
                isSearching: true,
                searchError: null,
                voucherSearch: action.search,
                voucherSearchProductId: action.voucherProductId,
                searchPageNumber: action.pageNumber,
                searchPageSize: action.pageSize,
                vouchers: []
            };
        case VoucherActionTypes.VouchersReceived:
            return {
                ...state,
                isSearching: false,
                searchMaxPage: action.maxPage,
                searchError: action.searchError,
                vouchers: action.vouchers
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

