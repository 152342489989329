
import { Action } from 'redux';

import { CampaignActionTypes, CampaignState } from './types';
import { CampaignActions } from './actions'
import { createReducer } from '../../reducer';
import { mapUtcDate, mapLocalDateTime, parseLocalDateTime } from '../../../utils/util';
import { Time } from '../../global/types';

const unloadedState: CampaignState = { isLoading: false, showComplete: false, campaigns: [], loadError: null, isSaving: false, saveComplete: false, saveError: null, validationErrors: [] };

export const reducer = (state: CampaignState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: CampaignState, incomingAction: Action): CampaignState => {

    const action = incomingAction as CampaignActions;
    switch (action.type) {
        case CampaignActionTypes.RequestCampaigns:
            return {
                ...state,
                isLoading: true,
                campaigns: state.campaigns,
                showComplete: action.showComplete,
                loadError: null
            };
        case CampaignActionTypes.ReceivedCampaigns:
            const ordered = action.campaigns ? action.campaigns.map(c => ({
                ...c,
                startTime: parseLocalDateTime(c.startTime),
                endTime: mapLocalDateTime(c.endTime),
                dailyRunStartTime: Time.parse(c.dailyRunStartTime.toString()),
                dailyRunEndTime: c.dailyRunEndTime ? Time.parse(c.dailyRunEndTime.toString()) : c.dailyRunEndTime,
                nextRunTime: mapLocalDateTime(c.nextRunTime)
            })) : [];
            ordered.sort((a, b) => a.name.localeCompare(b.name));

            return {
                ...state,
                isLoading: false,
                campaigns: ordered,
                loadError: action.error
            };
        case CampaignActionTypes.EditCampaign:
            return {
                ...state,
                isSaving: false,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case CampaignActionTypes.SaveCampaign:
            return {
                ...state,
                isSaving: true,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case CampaignActionTypes.CampaignSaved:
            return {
                ...state,
                isSaving: false,
                saveComplete: true,
                saveError: null,
                validationErrors: []
            };
        case CampaignActionTypes.CampaignSaveFailed:
            return {
                ...state,
                isSaving: false,
                saveError: action.error,
                saveComplete: false,
                validationErrors: action.error && action.error.validationErrors ? action.error.validationErrors : []
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

