
import { Action } from 'redux';

import { ReportDefinitionActionTypes, ReportDefinitionState } from './types';
import { ReportDefinitionActions } from './actions'
import { createReducer } from '../../reducer';


const unloadedState: ReportDefinitionState = { isLoading: false, reportDefinitions: [], loadError: null, isSaving: false, saveComplete: false, saveError: null, validationErrors: [] };

export const reducer = (state: ReportDefinitionState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: ReportDefinitionState, incomingAction: Action): ReportDefinitionState => {

    const action = incomingAction as ReportDefinitionActions;
    switch (action.type) {
        case ReportDefinitionActionTypes.RequestReportDefinitions:
            return {
                ...state,
                isLoading: true,
                reportDefinitions: state.reportDefinitions,
                loadError: null
            };
        case ReportDefinitionActionTypes.ReceivedReportDefinitions:
            const ordered = action.reportDefinitions ? action.reportDefinitions.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)) : [];
            return {
                ...state,
                isLoading: false,
                reportDefinitions: ordered,
                loadError: action.error
            };
        case ReportDefinitionActionTypes.EditReportDefinition:
            return {
                ...state,
                isSaving: false,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case ReportDefinitionActionTypes.SaveReportDefinition:
            return {
                ...state,
                isSaving: true,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case ReportDefinitionActionTypes.ReportDefinitionSaved:
            return {
                ...state,
                isSaving: false,
                saveComplete: true,
                saveError: null,
                validationErrors: []
            };
        case ReportDefinitionActionTypes.ReportDefinitionSaveFailed:
            return {
                ...state,
                isSaving: false,
                saveError: action.error,
                saveComplete: false,
                validationErrors: action.error && action.error.validationErrors ? action.error.validationErrors : []
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

