
// https://blog.angularindepth.com/how-to-reduce-action-boilerplate-90dc3d389e2b

import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as vt from './types';
import * as it from '../integrations/types';
import {Time} from '../../global/types';
import { isNullOrEmpty, mapUtcDate } from '../../../utils/util';
import { IStore } from '../..';
import { VenueRegistrationKiosk } from './types';

export type VenueActions = vt.LoadVenues | vt.ReceiveVenues | vt.SaveVenue | vt.VenueSaved | vt.VenueSaveFailed | vt.EditVenue | vt.SaveRegistrationSettings | vt.SelectVenue | vt.SelectTab | vt.EnableIntegration | vt.RevokeIntegrationToken; 

export const actionCreators = {
    loadVenues: () => ({ type: vt.VenueActionTypes.RequestVenues }),
    loadVenuesComplete: (venues: vt.Venue[], err: api.ApiError | null) => ({ type: vt.VenueActionTypes.ReceivedVenues, venues: venues, error: err }),
    editVenue: () => ({ type: vt.VenueActionTypes.EditVenue }),
    saveVenue: (isNew: boolean, venueId: string | null, venue: vt.Venue, lightLogoImg: File | null, darkLogoImg: File | null) => ({ type: vt.VenueActionTypes.SaveVenue, isNew: isNew, venueId: venueId, venue: venue, lightLogoImg: lightLogoImg, darkLogoImg: darkLogoImg }),
    venueSaved: (venueId: string) => ({ type: vt.VenueActionTypes.VenueSaved, venueId: venueId }),
    venueSaveFailed: (error: api.ApiError) => ({ type: vt.VenueActionTypes.VenueSaveFailed, error: error }),
    saveRegistrationSettings: (registrationKioskId: string | null, venueId: string, kioskName: string, registrationPassword: string | null, registrationImage: File | null, registrationFields: number, requiredRegistrationFields: number, existingWelcomeImages: string[], newWelcomeImages: File[], welcomeText: string, nameStepHeading: string, addressStepHeading: string, returningCustomerSetpHeading: string, contactPreferencesStepHeading: string, welcomeImageInterval: number, finalInstructions: string, resultsMarketingText: string, generalMarketingText: string, requireSignatures: boolean, registrationTermsId: string, requireAddressForChildren: boolean) =>
        ({ type: vt.VenueActionTypes.SaveRegistrationSettings, registrationKioskId, venueId, kioskName, registrationPassword, registrationImage, registrationFields, requiredRegistrationFields, existingWelcomeImages, newWelcomeImages, welcomeText, nameStepHeading, addressStepHeading, returningCustomerSetpHeading, contactPreferencesStepHeading, welcomeImageInterval, finalInstructions, resultsMarketingText, generalMarketingText, requireSignatures: requireSignatures, registrationTermsId: registrationTermsId, requireAddressForChildren: requireAddressForChildren }),
    selectVenue: (venueId: string) => ({type: vt.VenueActionTypes.SelectVenue, venueId: venueId}),
    selectTab: (tabKey: string) => ({ type: vt.VenueActionTypes.SelectTab, tabKey: tabKey }),
    enableIntegration: (venueId: string, integrationId: string) => ({ type: vt.VenueActionTypes.EnableIntegration, venueId: venueId, integrationId: integrationId }),
    revokeIntegrationToken: (venueId: string, integrationTokenId: string) => ({ type: vt.VenueActionTypes.RevokeIntegrationToken, venueId: venueId, integrationTokenId: integrationTokenId }),
}

interface IGetVenuesResponse {
    venues: vt.Venue[];
}

interface ISaveVenueResponse {
    venueId: string;
}

export const handleClientChange = (store: IStore) => [actionCreators.loadVenues]

//https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-after-catching-and-processing-an-error-with-emitting-somethi

const mapVenue = (v: any) => {
    const {
        mondayOpenTime,
        mondayCloseTime,
        tuesdayOpenTime,
        tuesdayCloseTime,
        wednesdayOpenTime,
        wednesdayCloseTime,
        thursdayOpenTime,
        thursdayCloseTime,
        fridayOpenTime,
        fridayCloseTime,
        saturdayOpenTime,
        saturdayCloseTime,
        sundayOpenTime,
        sundayCloseTime,
        webBookingMinTimeFromEventStart,
        ...venue
    } = v;
    return {
        ...venue,
        mondayOpenTime: Time.parse(mondayOpenTime),
        mondayCloseTime: Time.parse(mondayCloseTime),
        tuesdayOpenTime: Time.parse(tuesdayOpenTime),
        tuesdayCloseTime: Time.parse(tuesdayCloseTime),
        wednesdayOpenTime: Time.parse(wednesdayOpenTime),
        wednesdayCloseTime: Time.parse(wednesdayCloseTime),
        thursdayOpenTime: Time.parse(thursdayOpenTime),
        thursdayCloseTime: Time.parse(thursdayCloseTime),
        fridayOpenTime: Time.parse(fridayOpenTime),
        fridayCloseTime: Time.parse(fridayCloseTime),
        saturdayOpenTime: Time.parse(saturdayOpenTime),
        saturdayCloseTime: Time.parse(saturdayCloseTime),
        sundayOpenTime: Time.parse(sundayOpenTime),
        sundayCloseTime: Time.parse(sundayCloseTime),
        webBookingMinTimeFromEventStart: Time.parse(webBookingMinTimeFromEventStart),
        registrationKiosks: venue.registrationKiosks.map((k: VenueRegistrationKiosk) => {
            return { ...k, createDateTime: new Date(k.createDateTime), lastUpdated: mapUtcDate(k.lastUpdated) }
        }),
    };
};

export const loadVenues = () => Observable.defer(() => api.getJson<IGetVenuesResponse>('api/v1/venue/'))
    .mergeMap(response => {
        let currentlySelectedVenue = localStorage.getItem('selectedVenueId');
        const deserializedVenues = response.venues ? response.venues.map(v => mapVenue(v)) : [];
        const activeVenues = deserializedVenues.filter(v => !v.archived);
        const venueMatch = activeVenues.find(v => v.id === currentlySelectedVenue);

        if (venueMatch) {
            currentlySelectedVenue = venueMatch.id;
        } else if (activeVenues.length > 0) {
            currentlySelectedVenue = activeVenues[0].id;
        } else {
            currentlySelectedVenue = '';
        }

        return Observable.of(actionCreators.loadVenuesComplete(deserializedVenues, null), actionCreators.selectVenue(currentlySelectedVenue || ''));
    });

export const loadVenuesEpic = (action$: ActionsObservable<any>) => {
    return epic.create(action$,
        vt.VenueActionTypes.RequestVenues,
        action => {
            return loadVenues()
        },
        err => actionCreators.loadVenuesComplete([], err));
}

export const reloadVenuesEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        vt.VenueActionTypes.VenueSaved,
        action => {
            return loadVenues()
        },
        err => {
            return actionCreators.loadVenuesComplete([], err)
        });

export const handleIntegrationChanged = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        it.IntegrationActionTypes.IntegrationSaved,
        action => loadVenues(),
        err => actionCreators.loadVenuesComplete([], err));

const saveVenue = (isNew: boolean, venueId: string, venue: vt.Venue, lightLogoImgId: string | null, darkLogoImgId: string | null) => {

    const { mondayOpenTime, mondayCloseTime, tuesdayOpenTime, tuesdayCloseTime, wednesdayOpenTime, wednesdayCloseTime, thursdayOpenTime,
        thursdayCloseTime, fridayOpenTime, fridayCloseTime, saturdayOpenTime, saturdayCloseTime, sundayOpenTime, sundayCloseTime,
        webBookingMinTimeFromEventStart, ...venueArgs } = venue;

    const body = {
        ...venueArgs,
        mondayOpenTime: mondayOpenTime.toString(),
        mondayCloseTime: mondayCloseTime.toString(),
        tuesdayOpenTime: tuesdayOpenTime.toString(),
        tuesdayCloseTime: tuesdayCloseTime.toString(),
        wednesdayOpenTime: wednesdayOpenTime.toString(),
        wednesdayCloseTime: wednesdayCloseTime.toString(),
        thursdayOpenTime: thursdayOpenTime.toString(),
        thursdayCloseTime: thursdayCloseTime.toString(),
        fridayOpenTime: fridayOpenTime.toString(),
        fridayCloseTime: fridayCloseTime.toString(),
        saturdayOpenTime: saturdayOpenTime.toString(),
        saturdayCloseTime: saturdayCloseTime.toString(),
        sundayOpenTime: sundayOpenTime.toString(),
        sundayCloseTime: sundayCloseTime.toString(),
        lightLogoImageId: lightLogoImgId,
        darkLogoImageId: darkLogoImgId,
        webBookingMinTimeFromEventStart: webBookingMinTimeFromEventStart.toString()
    }

    return isNew ? api.post('api/v1/venue/', body) : api.put(`api/v1/venue/${venueId}`, body);
}

export const saveVenueEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        vt.VenueActionTypes.SaveVenue,
        action => {
            const sva = action as vt.SaveVenue;
            const { isNew, venueId, venue, lightLogoImg, darkLogoImg } = sva;
            const imageObservables: Observable<string>[] = [];

            imageObservables.push(lightLogoImg ? api.uploadFile(lightLogoImg, 'api/v1/clientimage').map(res => res.response.imageId) : Observable.of(null));
            imageObservables.push(darkLogoImg ? api.uploadFile(darkLogoImg, 'api/v1/clientimage').map(res => res.response.imageId) : Observable.of(null));

            const images = Observable.forkJoin(imageObservables);

            return images.flatMap(imgIds => saveVenue(isNew, venueId || '', venue, imgIds[0], imgIds[1])
                .map(response => {
                    let venueId = sva.venueId;
                    if (isNew) {
                        const svr = response.response as ISaveVenueResponse;
                        if (svr) {
                            venueId = svr.venueId;
                        }
                    }

                    return ({ type: vt.VenueActionTypes.VenueSaved, venueId: venueId });
                })
            )
        },
        (err: api.ApiError) => actionCreators.venueSaveFailed(err));


export const saveRegistrationSettingsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        vt.VenueActionTypes.SaveRegistrationSettings,
        action => {
            const srsa = action as vt.SaveRegistrationSettings;
            const { registrationKioskId, venueId, registrationImage, existingWelcomeImages, newWelcomeImages, ...srsaArgs } = srsa;

            const imageObservables: Observable<string>[] = [];

            imageObservables.push(registrationImage
                ? api.uploadFile(registrationImage, 'api/v1/clientimage').map(res => {
                    return res.response.imageId;
                })
                : Observable.of(null));

            newWelcomeImages.forEach(img => {
                imageObservables.push(api.uploadFile(img, 'api/v1/clientimage').map(res => {
                    return res.response.imageId;
                }));
            });

            const images = Observable.forkJoin(imageObservables);

            return images.flatMap(imgIds => {

                const bgImgId = imgIds[0];
                const welcomeImgIds = existingWelcomeImages.concat(imgIds.filter((i, ix) => ix > 0));

                const body = { ...srsaArgs, registrationImageId: bgImgId, welcomeImgIds: welcomeImgIds };

                return (isNullOrEmpty(registrationKioskId) ? api.post(`api/v1/venue/${venueId}/registrationSettings`, body) : api.put(`api/v1/venue/${venueId}/registrationSettings/${registrationKioskId}`, body))
                .map(response => ({ type: vt.VenueActionTypes.VenueSaved, venueId: venueId }))});
        },
        (err: api.ApiError) => actionCreators.venueSaveFailed(err));


export const enableIntegrationEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        vt.VenueActionTypes.EnableIntegration,
        action => {
            const eia = action as vt.EnableIntegration;
            const { venueId, integrationId } = eia;
            return api.post(`api/v1/venue/${venueId}/integrationToken/${integrationId}`, {})
                .map(response => {
                    return ({ type: vt.VenueActionTypes.VenueSaved, venueId: venueId })
                })
        },
        (err: api.ApiError) => {
            return actionCreators.venueSaveFailed(err)
        });

export const revokeIntegrationToken = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        vt.VenueActionTypes.RevokeIntegrationToken,
        action => {
            const rta = action as vt.RevokeIntegrationToken;
            const { venueId, integrationTokenId } = rta;
            return api.put(`api/v1/venue/${venueId}/integrationToken/${integrationTokenId}/revoke`, {})
                .map(response => ({ type: vt.VenueActionTypes.VenueSaved, venueId: venueId }))
        },
        (err: api.ApiError) => actionCreators.venueSaveFailed(err));
