
import { generateTempId, isNullOrEmpty, mapUtcDate, mapLocalDateTime, parseLocalDateTime, parseUtcDate } from '../../../utils/util';
import { CustomerCategory } from '../customerCategories/types';
import { Product, ProductFixedPrice, ProductPrice, ProductPricingMode } from '../products/types';
import { Tag } from '../tags/types';
import { FeeType, Fee } from '../fees/types';
import { DateFormat, TimeFormat } from '../venues/types';

export enum PaymentStatus {
    None = 0,
    Scheduled = 1,
    Success = 2,
    Failed = 3,
    GatewayInProgress = 4,
    GatewayFailed = 5,
    GatewayCancelled = 6
}

export enum GatewayPaymentStatus {
    None = 0,
    InProgress = 1,
    Success = 2,
    CardReferred = 3,
    CardDeclined = 4,
    DuplicateTransaction = 5,
    Error = 6,
    Cancelled = 7
}

export enum GatewayRefundStatus {
    Unknown = 0,
    Pending = 1,
    Succeeded = 2,
    Failed = 3,
    Cancelled = 4
}

export interface LoadEventBillsResponse {
    bills: Bill[];
}

export interface CreateBillResponse {
    bill: Bill;
}

export interface IUpdatePaymentResponse {
    bill: Bill;
}

export interface CreateBillItem {
    productId: string;
    quantity: number;
    reservationId: string | null;
}

export interface BillTax {
    rate: number;
    rateTotal: number;
    rateTaxTotal: number;
}

export interface Bill {
    id: string;
    venueId: string;
    bookingId: string | null;
    bookingCancelled: boolean,
    eventName: string | null;
    eventDate: Date | null;
    customerId: string | null;
    customerKey: string;
    customerName: string;
    customerEmail: string | null;
    customerTags: Tag[];
    customerAvailableCredit: number;
    customerHasActiveMembership: boolean;
    items: BillItem[];
    payments: BillPayment[];
    refunds: BillRefund[];
    fees: BillFee[];
    discounts: BillDiscount[];
    billNumber: string;
    totalAmount: number;
    outstandingBalance: number;
    paidAmount: number;
    dueAmount: number;
    overdueAmount: number;
    totalTaxAmount: number;
    taxBreakdown: BillTax[];
    depositAmount: number;
    webShopBasket: boolean;
    createDateTime: Date;
    createdBy: string;
}

export interface BillItem {
    key: string;
    id: string;
    productId: string;
    primaryBillItemId: string;
    productName: string;
    description: string;
    unitPrice: number;
    quantity: number;
    reservationId: string | null;
    reservationStartTime: Date | null;
    activityFormatId: string | null;
    vouchers: BillItemVoucher[];
    placesToBookPerUnit: number | null;
    customerCategoryId: string | null;
    pricingMode: ProductPricingMode;
    totalItemPrice: number;
    productPriceId: string;
    productTagName: string | null;
    productTagColour: string | null;
    taxRateId: string;
    taxRateName: string;
    taxRate: number;
    membership: BillItemMembership | null;
    createDateTimeInLocalTime: Date;
}

export interface BillItemVoucher {
    voucherId: string;
    voucherCode: string;
    voucherAmount: number;
    revoked: boolean;
}

export interface BillItemMembership {
    id: string;
    membershipNumber: string;
    tagColour: string;
    tagName: string;
}

export interface BillFee {
    id: string;
    description: string;
    feeId: string;
    feeType: FeeType;
    amount: number;
    percentage: number;
    maxAmount: number;
    taxRate: number;
    taxRateId: string;
    deleted: boolean;
}

export interface BillDiscount {
    id: string;
    billId: string;
    promotionId: string;
    promotionName: string;
    promotionCode: string;
    amount: number;
}

export interface AdditionalPaymentInformation {
    id: string;
    paymentInformationId: string;
    name: string;
    text: string;
}

export interface AdditionalPaymentInfo {
    paymentInformationId: string;
    name: string;
    text: string;
}

export interface BillPayment {
    id: string;
    key: string;
    paymentMethodId: string | null;
    scheduled: boolean;
    status: PaymentStatus;
    paymentMethodName?: string;
    description: string | null;
    isSecurityPayment: boolean;
    amount: number;
    paid: boolean;
    paymentDueDate: Date | null;
    takePaymentAutomatically: boolean;
    customerStoredPaymentMethodId: string | null;
    paidByCustomerId: string | null;
    paidByCustomerName: string | null;
    payentTakenDateTime: Date | null;
    paymentTakenBy: string | null;
    paymentDeviceName: string | null;
    paymentTakenById: string | null;
    receipt: string | null;
    paymentError: string | null;
    deleted: boolean;
    void?: boolean;
    voidReason?: string | null;
    voidedBy?: string | null;
    voidedDateTime?: Date | null;
    createDateTime: Date;
    createdBy: string;
    voucherCode: string | null;
    additionalInfo: AdditionalPaymentInfo[];
    gatewayPayments: BillGatewayPayment[];
}

export interface BillGatewayPayment {
    gatewayPaymentId: string;
    gatewayPaymentStatus: GatewayPaymentStatus;
    gatewayCardType: string;
    gatewayCardIssuer: string;
    gatewayCardLastFour: string;
    gatewayCardHolder: string;
    gatewayResponse: string;
    gatewayClientIp: string;
    gatewayPaymentDate: Date;
    gatewayIssuerPaymentId: string | null;
    gatewayToken: string;
    cardMachinePayment: boolean;
    terminalId: string | null;
    terminalName: string | null;
}

export interface BillRefund {
    id: string;
    billId: string;
    paymentMethodId: string;
    paymentMethodName: string;
    amount: number;
    reason: string;
    refundedBy: string;
    whenRefunded: Date;
    void?: boolean;
    voidReason?: string | null;
    voidedBy?: string | null;
    voidedDateTime?: Date | null;
    gatewayRefundAttempts: BillsGatewayRefund[];
}

export interface BillsGatewayRefund {
    id: string;
    status: GatewayRefundStatus;
    amountRefunded: number;
    transactionDateTime: Date;
    gatewayResponse: string;
    issuerRefundId: string;
    failureReason: string;
}

export enum BillActionTypes {
    BillChanged = 'BILL_CHANGED',
}

export interface BillChanged {
    type: BillActionTypes.BillChanged;
    billId: string;
}

export interface IProduct {
    id: string;
    name: string;
    pricingMode: ProductPricingMode;
    pricing: ProductPrice[];
}

export const formatItemDescription = (item: BillItem, multiDayEvent: boolean, timeFormat: TimeFormat, dateFormat: DateFormat, t: (key: any) => string) => formatItemText(item, multiDayEvent, timeFormat, dateFormat, t, i => i.description);

export const formatItemName = (item: BillItem, customerCategories: CustomerCategory[], multiDayEvent: boolean, timeFormat: TimeFormat, dateFormat: DateFormat, t: (key: any) => string) => formatItemText(item, multiDayEvent, timeFormat, dateFormat, t, i => {
    if (!isNullOrEmpty(i.customerCategoryId)) {
        var cat = customerCategories.find(c => c.id === i.customerCategoryId);
        if (cat) {
            return `${i.description} - ${cat.name}`;
        }
    }
    return i.description;
});

const formatItemText = (item: BillItem, multiDayEvent: boolean, timeFormat: TimeFormat, dateFormat: DateFormat, t: (key: any) => string, textSelector: (item: BillItem) => string) => {
    var text = textSelector(item);
    return item.reservationStartTime ? `${formatItemStartTime(item.reservationStartTime, multiDayEvent, timeFormat, dateFormat, t)} - ${text}` : text;
}

const formatItemStartTime = (date: Date, multiDayEvent: boolean, timeFormat: TimeFormat, dateFormat: DateFormat, t: (key: any) => string) => multiDayEvent ? `${date.toAbbrMonthDayString(dateFormat, t)} ${date.toShortTimeString(timeFormat)}` : `${date.toShortTimeString(timeFormat)}`;


export const sortBillItems = (i1: BillItem, i2: BillItem) => {
    if (i1.reservationStartTime && i2.reservationStartTime) {
        return i1.reservationStartTime.getDate() - i2.reservationStartTime.getDate();
    }

    if (i1.reservationStartTime) return -1;
    if (i2.reservationStartTime) return 1;
    return 0;
}


export const isScheduled = (payment: BillPayment) => !payment.paid && !payment.void && !payment.deleted && payment.scheduled && payment.status !== PaymentStatus.Success;

export const getPaymentDate = (isScheduled: boolean, payment: BillPayment) => {
    if (isScheduled) return payment.paymentDueDate;
    if (payment.void) return payment.voidedDateTime;
    if (payment.gatewayPayments.length > 0 && (payment.status === PaymentStatus.GatewayInProgress || payment.status === PaymentStatus.GatewayFailed || payment.status === PaymentStatus.GatewayCancelled)) {
        return payment.gatewayPayments.reduce<Date>((min, gp, i) => i === 0 || min > gp.gatewayPaymentDate ? gp.gatewayPaymentDate : min, new Date());
    }

    return payment.payentTakenDateTime;
}

export const createScheduledBillPayment = (amount: number, paymentDescription: string | null, dueDate: Date | null, isSecurityPayment: boolean, customerId: string | null): BillPayment => ({
    id: '',
    key: generateTempId(),
    paymentMethodId: null,
    status: PaymentStatus.Scheduled,
    scheduled: true,
    paid: false,
    amount: amount,
    description: paymentDescription,
    isSecurityPayment: isSecurityPayment,
    payentTakenDateTime: null,
    paymentDeviceName: null,
    paymentTakenBy: null,
    paymentTakenById: null,
    paymentDueDate: dueDate,
    paidByCustomerId: customerId,
    paidByCustomerName: null,
    customerStoredPaymentMethodId: null,
    paymentError: null,
    receipt: null,
    takePaymentAutomatically: false,
    createdBy: '',
    createDateTime: new Date(),
    deleted: false,
    voucherCode: null,
    additionalInfo: [],
    gatewayPayments: []
});

export const deserializeBill = (bill: Bill) => ({
    ...bill,
    key: bill.id,
    bookingCancelled: bill.bookingCancelled,
    createDateTime: parseLocalDateTime(bill.createDateTime),
    eventDate: mapLocalDateTime(bill.eventDate),
    items: bill.items.map(i => ({ ...i, reservationStartTime: i.reservationStartTime ? parseLocalDateTime(i.reservationStartTime) : null, createDateTimeInLocalTime: parseLocalDateTime(i.createDateTimeInLocalTime) })),
    payments: bill.payments.map(p => ({
        ...p,
        createDateTime: parseUtcDate(p.createDateTime),
        paymentDueDate: p.paymentDueDate ? parseUtcDate(p.paymentDueDate) : null,
        payentTakenDateTime: p.payentTakenDateTime ? parseUtcDate(p.payentTakenDateTime) : null,
        voidedDateTime: p.voidedDateTime ? parseUtcDate(p.voidedDateTime) : null,
        gatewayPayments: p.gatewayPayments.map(gp => ({ ...gp, gatewayPaymentDate: parseUtcDate(gp.gatewayPaymentDate) }))
    })),
    refunds: bill.refunds.map(r => ({
        ...r,
        whenRefunded: parseUtcDate(r.whenRefunded),
        voidedDateTime: mapUtcDate(r.voidedDateTime),
        gatewayRefundAttempts: r.gatewayRefundAttempts.map(gr => ({ ...gr, transactionDateTime: parseUtcDate(gr.transactionDateTime) }))
    })),
});

export const getBillTotal = (bill: Bill) => bill.totalAmount;

export const calculateItemPrice = (product: IProduct, productPrice: ProductPrice, quantity: number) => {

    // Keep in sync with Alpha.Cloud.Api.Services.PricingService.CalculateItemPrice
    if (product && product.pricingMode === ProductPricingMode.Fixed) {
        return calculateUnitPrice(product, productPrice, quantity);
    } else {
        return quantity * calculateUnitPrice(product, productPrice, quantity);
    }
}

export const calculateUnitPrice = (product: IProduct, productPrice: ProductPrice, quantity: number) => {

    // Keep in sync with Alpha.Cloud.Api.Services.PricingService.CalculateItemPrice
    if (product && product.pricingMode === ProductPricingMode.Fixed) {

        let remainingQuantity = quantity;
        let price = 0;

        const sortedPrices = productPrice.fixedPricing.sort((p1, p2) => p2.maxQuantity - p1.maxQuantity);

        while (remainingQuantity > 0) {
            let fixedPrice: ProductFixedPrice | null = null;
            for (var i = 0; i < sortedPrices.length; i++) {
                const fp = sortedPrices[i];
                if (fixedPrice === null && ((fp.maxQuantity < remainingQuantity) || (fp.maxQuantity >= remainingQuantity && fp.minQuantity <= remainingQuantity))) {
                    fixedPrice = fp;
                }
            }

            if (fixedPrice === null) {
                console.log(`No fixed price configuration for product ${product.id} [${product.name}], quantity ${remainingQuantity}`)
                remainingQuantity = 0;
            } else {

                price = + fixedPrice.price;

                remainingQuantity = Math.max(0, remainingQuantity - fixedPrice.maxQuantity);

                // If the maximum for the fixed price has been exceeded, work out pricing for the remaining quantity
                if (remainingQuantity > 0 && productPrice.unitPrice > 0) {
                    price += remainingQuantity * productPrice.unitPrice;
                    remainingQuantity = 0;
                }
            }
        }

        return price;
    } else {
        return productPrice.unitPrice;
    }
}

export const calculateFeeAmount = (bill: Bill, fee: Fee) => {

    let amount = fee.fixedAmount;

    if (fee.feeType === FeeType.Percentage) {
        amount = (bill.totalAmount) * fee.percentage;
    }

    return fee.maxAmount > 0 ? Math.min(amount, fee.maxAmount) : amount;
}

export const formatPaymentMethodInfo = (payment: BillPayment) => {
    const { gatewayPayments, paymentMethodName } = payment;
    const terminal = (gatewayPayments || [])
        .filter(gp => gp.gatewayPaymentStatus === GatewayPaymentStatus.Success && gp.cardMachinePayment)
        .map(gp => gp.terminalName)[0];

    return !paymentMethodName || isNullOrEmpty(paymentMethodName)
        ? null
        : formatPaymentMethodName(paymentMethodName, !isNullOrEmpty(terminal), terminal);
}

export const formatPaymentMethodName = (paymentMethodName: string, cardMachinePayment: boolean, terminalName: string | null) => {
    return cardMachinePayment && !isNullOrEmpty(terminalName) ? `${paymentMethodName} [${terminalName}]` : paymentMethodName;
}