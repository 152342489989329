
// https://blog.angularindepth.com/how-to-reduce-action-boilerplate-90dc3d389e2b

import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as it from './types';
import * as lt from '../login/types';
import { IStore } from '../..';

export type IntegrationActions = it.LoadIntegrations | it.ReceiveIntegrations | it.SaveIntegration | it.IntegrationSaved | it.IntegrationSaveFailed | it.EditIntegration;

export const actionCreators = {
    loadIntegrations: () => ({ type: it.IntegrationActionTypes.RequestIntegrations }),
    loadIntegrationsComplete: (integrations: it.Integration[], err: api.ApiError | null) => ({ type: it.IntegrationActionTypes.ReceivedIntegrations, integrations: integrations, error: err }),
    editIntegration: () => ({ type: it.IntegrationActionTypes.EditIntegration }),
    saveIntegration: (isNew: boolean, integrationId: string | null, integrationType: it.IntegrationType, name: string, implementationType: it.IntegrationImplementationType, settings: string, archived: boolean) => (
        { type: it.IntegrationActionTypes.SaveIntegration, isNew: isNew, integrationId: integrationId, integrationType: integrationType, name: name, implementationType: implementationType, settings: settings, archived: archived }
    ),
    integrationSaved: (integrationId: string) => ({ type: it.IntegrationActionTypes.IntegrationSaved, integrationId: integrationId }),
    integrationSaveFailed: (error: api.ApiError) => ({ type: it.IntegrationActionTypes.IntegrationSaveFailed, error: error })
}

interface IGetIntegrationsResponse {
    integrations: it.Integration[];
}

interface ISaveIntegrationResponse {
    integrationId: string;
}

export const handleClientChange = (store: IStore) => [actionCreators.loadIntegrations]

//https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-after-catching-and-processing-an-error-with-emitting-somethi

const loadIntegrations = () => Observable.defer(() => api.getJson<IGetIntegrationsResponse>('api/v1/integration/'))
    .map(response => actionCreators.loadIntegrationsComplete(response.integrations, null));

export const loadIntegrationEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        it.IntegrationActionTypes.RequestIntegrations,
        action => loadIntegrations(),
        err => actionCreators.loadIntegrationsComplete([], err));

export const reloadIntegrationEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        it.IntegrationActionTypes.IntegrationSaved,
        action => loadIntegrations(),
        err => actionCreators.loadIntegrationsComplete([], err));

export const saveIntegrationEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        it.IntegrationActionTypes.SaveIntegration,
        action => {
            const sia = action as it.SaveIntegration;
            var body = {
                integrationType: sia.integrationType,
                implementationType: sia.implementationType,
                name: sia.name,
                settings: sia.settings,
                archived: sia.archived
            };

            return (sia.isNew ? api.post('api/v1/integration/', body) : api.put(`api/v1/integration/${sia.integrationId}`, body))
                .map(response => {

                    let integrationId = sia.integrationId;
                    if (sia.isNew) {
                        const sir = response.response as ISaveIntegrationResponse;
                        if (sir) {
                            integrationId = sir.integrationId;
                        }
                    }

                    return ({ type: it.IntegrationActionTypes.IntegrationSaved, integration: integrationId });
                });
        },
        (err: api.ApiError) => actionCreators.integrationSaveFailed(err));
