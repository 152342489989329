
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'
import { BookingEmailScheduleConfig } from '../../../../store/pages/bookingEmailScheduleConfigs/types';
import * as ct from '../../../global/controls';
import * as v from '../../../global/validation';
import { ApplicationState } from '../../../../store';
import * as BookingEmailScheduleConfigActions from '../../../../store/pages/bookingEmailScheduleConfigs/actions';
import * as ModalActions from '../../../../store/global/modal/actions';
import * as EmailTemplateActions from '../../../../store/pages/emailTemplates/actions';
import { ClientEmailTemplate, EmailType } from '../../../../store/pages/emailTemplates/types';
import { ValidationError, Time } from '../../../../store/global/types';
import { clickHandler } from '../../../../utils/util';
import { Resource } from '../../../../store/pages/resources/types';
import * as api from '../../../../store/apiClient';
import ApiError from '../../../global/apiError';
import { Venue } from '../../../../store/pages/venues/types';

const SEND_WHEN_FULLY_PAID = 'FULLY_PAID'
const SEND_WHEN_ANY_PAYMENT = 'ANY_PAYMENT'
const SEND_ON_SCHEDULE = 'ON_SCHEDULE'

interface LocalProps {
    isNew: boolean;
    venue: Venue;
    bookingEmailScheduleConfig: BookingEmailScheduleConfig | null;
}

interface MappedState {
    isLoading: boolean;
    isSaving: boolean;
    saveComplete: boolean;
    emailTemplates: ClientEmailTemplate[];
    isLoadingEmailTemplates: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
    resources: Resource[];
}

interface MappedActions {
    saveBookingEmailScheduleConfig: (isNew: boolean, bookingEmailScheduleConfigId: string | null, bookingEmailScheduleConfig: BookingEmailScheduleConfig) => void;
    loadEmailTemplates: () => void;
    closeModal: () => void;
}

type BookingEmailScheduleConfigFormProps = MappedState & MappedActions & LocalProps;

interface BookingEmailScheduleConfigFormState {
    name: ct.FormValue<string>;
    sendForManualBookings: boolean;
    sendForWebBookings: boolean;
    sendWhenOption: ct.FormValue<string>;
    sendWhenPaidInFull: boolean;
    sendWhenAnyPayment: boolean;
    numberOfDaysBeforeEvent: ct.FormValue<number | null>;
    timeOfDayToSend: ct.FormValue<Time | null>
    archived: ct.FormValue<boolean>;
    clientEmailTemplateId: ct.FormValue<string | null>;
    resourceFilter: string[];
}

const bookingEmailTemplateTypes = [EmailType.BookingRegistrationLink, EmailType.GeneralBookingEmail, EmailType.BookingConfirmation];

class BookingEmailScheduleConfigForm extends React.Component<BookingEmailScheduleConfigFormProps, BookingEmailScheduleConfigFormState> {

    constructor(props: BookingEmailScheduleConfigFormProps) {
        super(props);

        this.state = this.buildStateFromProps(this.props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps(props: BookingEmailScheduleConfigFormProps): BookingEmailScheduleConfigFormState {

        const { isNew, bookingEmailScheduleConfig } = props;

        const sendWhenPaidInFull = !isNew && bookingEmailScheduleConfig && bookingEmailScheduleConfig.sendWhenPaidInFull ? true : false;
        const sendWhenAnyPayment = !isNew && bookingEmailScheduleConfig && bookingEmailScheduleConfig.sendWhenAnyPayment ? true : false;
        const timeOfDayToSend = sendWhenPaidInFull ? null : (bookingEmailScheduleConfig ? bookingEmailScheduleConfig.timeOfDayToSend : null) || new Time(8, 0, 0);
        let sendWhenOption = '';

        if (sendWhenPaidInFull) {
            sendWhenOption = SEND_WHEN_FULLY_PAID
        } else if (sendWhenAnyPayment) {
            sendWhenOption = SEND_WHEN_ANY_PAYMENT
        } else if (bookingEmailScheduleConfig && bookingEmailScheduleConfig.numberOfDaysBeforeEvent) {
            sendWhenOption = SEND_ON_SCHEDULE
        }

        const activeResourceIds = props.resources.filter(r => !r.archived && r.venueId === props.venue.id).map(r => r.id);

        return {
            name: this.validateName((isNew || !bookingEmailScheduleConfig) ? '' : bookingEmailScheduleConfig.name),
            clientEmailTemplateId: this.validateEmailTemplateId((isNew || !bookingEmailScheduleConfig) ? null : bookingEmailScheduleConfig.clientEmailTemplateId),
            sendForManualBookings: !isNew &&  bookingEmailScheduleConfig && bookingEmailScheduleConfig.sendForManualBookings ? true : false,
            sendForWebBookings: !isNew && bookingEmailScheduleConfig && bookingEmailScheduleConfig.sendForWebBookings ? true : false,
            sendWhenOption: ct.asFormValue('sednWhen', sendWhenOption, sendWhenOption !== '', true),
            sendWhenPaidInFull: sendWhenPaidInFull,
            sendWhenAnyPayment: sendWhenAnyPayment,
            numberOfDaysBeforeEvent: this.validateNumberOfDaysBeforeEvent((isNew || !bookingEmailScheduleConfig) ? null : bookingEmailScheduleConfig.numberOfDaysBeforeEvent, sendWhenPaidInFull, sendWhenAnyPayment),
            timeOfDayToSend: this.validateTimeOfDayToSend(timeOfDayToSend),
            resourceFilter: (isNew || !bookingEmailScheduleConfig) ? activeResourceIds : bookingEmailScheduleConfig.resourceFilter,
            archived: this.validateArchived((isNew || !bookingEmailScheduleConfig) ? false : bookingEmailScheduleConfig.archived),
        };
    }

    componentDidUpdate(prevProps: BookingEmailScheduleConfigFormProps) {
        // Only update state if resource has changed
        const { bookingEmailScheduleConfig: prevBookingEmailScheduleConfig, saveComplete: prevSaveComplete } = prevProps;
        const { bookingEmailScheduleConfig, saveComplete} = this.props;

        if ((!prevBookingEmailScheduleConfig && bookingEmailScheduleConfig) || (prevBookingEmailScheduleConfig && !bookingEmailScheduleConfig) || (prevBookingEmailScheduleConfig && bookingEmailScheduleConfig && prevBookingEmailScheduleConfig.id !== bookingEmailScheduleConfig.id)) {
            this.setState(this.buildStateFromProps(this.props));
        }

        if (saveComplete && !prevSaveComplete) {
            setTimeout(() => { this.close(); }, 750);
        }
    }

    private savePaymentReminderConfig = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }

    private close = () => {
        this.props.closeModal();
    }

    private save = () => {
        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {
            const { isNew, bookingEmailScheduleConfig, venue } = this.props;
            const { name, clientEmailTemplateId, sendForManualBookings, sendForWebBookings, sendWhenPaidInFull, sendWhenAnyPayment, numberOfDaysBeforeEvent, timeOfDayToSend, resourceFilter, archived } = this.state;
            const { id: venueId } = venue;

            const bookingEmailScheduleConfigId = isNew || !bookingEmailScheduleConfig ? null : bookingEmailScheduleConfig.id;
            this.props.saveBookingEmailScheduleConfig(isNew,
                bookingEmailScheduleConfigId,
                {
                    id: bookingEmailScheduleConfigId || '',
                    venueId: venueId,
                    name: name.value,
                    clientEmailTemplateId: clientEmailTemplateId.value || '',
                    clientEmailTemplateName: '',
                    sendForManualBookings: sendForManualBookings,
                    sendForWebBookings: sendForWebBookings,
                    sendWhenPaidInFull: sendWhenPaidInFull,
                    sendWhenAnyPayment: sendWhenAnyPayment,
                    numberOfDaysBeforeEvent: sendWhenPaidInFull || sendWhenAnyPayment ? null : numberOfDaysBeforeEvent.value,
                    timeOfDayToSend: sendWhenPaidInFull || sendWhenAnyPayment ? null : timeOfDayToSend.value,
                    resourceFilter: resourceFilter,
                    archived: archived.value,
                }
            );
        }
    }

    emailTemplateChanged = (emailTemplateId: string) => {
        const template = this.props.emailTemplates.find(t => t.clientEmailTemplateId === emailTemplateId);
        this.setState({ clientEmailTemplateId: this.validateEmailTemplateId(template ? emailTemplateId : null) });
    }

    validateName = (val: string) => v.validate(val, 'name', [v.required], this.props.validationErrors);
    validateArchived = (val: boolean) => v.validate(val, 'archived', [], this.props.validationErrors);
    validateEmailTemplateId = (val: string | null) => v.validate(val, 'clientEmailTemplateId', [v.required], this.props.validationErrors);
    validateNumberOfDaysBeforeEvent = (val: number | null, sendWhenPaidInFull: boolean, sendWhenAnyPayment: boolean) => v.validate(val, 'numberOfDaysBeforeEvent', [v => this.requireNumberOfDaysBeforeEvent(v, sendWhenPaidInFull, sendWhenAnyPayment)], this.props.validationErrors);
    validateTimeOfDayToSend = (val: Time | null) => v.validate(val, 'timeOfDayToSend', [], this.props.validationErrors);

    requireNumberOfDaysBeforeEvent = (value: number | null, sendWhenPaidInFull: boolean, sendWhenAnyPayment: boolean) => (sendWhenPaidInFull || sendWhenAnyPayment || (value !== null && value >= 0) ? undefined : 'validation:required');

    onSendWhenChanged = (val: string) => this.setState(s => {
        const sendWhenPaidInFull = val === SEND_WHEN_FULLY_PAID;
        const sendWhenAnyPayment = val === SEND_WHEN_ANY_PAYMENT;
        return {
            sendWhenOption: ct.asFormValue('sednWhen', val, val !== '', true),
            sendWhenPaidInFull: sendWhenPaidInFull,
            sendWhenAnyPayment: sendWhenAnyPayment,
            numberOfDaysBeforeEvent: this.validateNumberOfDaysBeforeEvent(val !== SEND_ON_SCHEDULE ? null : s.numberOfDaysBeforeEvent.value || 0, sendWhenPaidInFull, sendWhenAnyPayment),
            timeOfDayToSend: this.validateTimeOfDayToSend(val !== SEND_ON_SCHEDULE ? null : s.timeOfDayToSend.value || new Time(8, 0, 0))
        }
    });

    resourceStateChanged = (resourceId: string, selected: boolean) => {
        if (selected) {
            this.setState(s => ({ resourceFilter: s.resourceFilter.indexOf(resourceId) >= 0 ? s.resourceFilter : s.resourceFilter.concat([resourceId]) }))
        } else {
            this.setState(s => ({ resourceFilter: s.resourceFilter.filter(r => r !== resourceId) }))
        }
    }

    render() {

        let message: any = null;
        const t = this.context.t;
        const { saveError, saveComplete, isNew, emailTemplates, venue, resources } = this.props;
        const { name, archived, clientEmailTemplateId, sendForManualBookings, sendWhenOption, sendForWebBookings, sendWhenPaidInFull, sendWhenAnyPayment, numberOfDaysBeforeEvent, timeOfDayToSend, resourceFilter } = this.state;
        const { timeFormat } = venue;

        const venueEmailTemplates = emailTemplates.filter(t => t.venueId === venue.id);

        if (saveError) {
            message = <ApiError error={saveError} />;
        } else if (saveComplete) {
            message = (<div className='bg-success'>{t('Global:saveComplete')}</div>);
        }

        const emailTemplateOptions = [{ key: 'no_selection', name: t('BookingEmailScheduleConfigForm:noEmailTemplate') }].concat(venueEmailTemplates.map(t => ({ key: t.clientEmailTemplateId || '', name: t.name })));
        const validEmailTypes = bookingEmailTemplateTypes.map(k => t(`EmailTemplateType:${EmailType[k]}`));
        const sendOptions = [
            { key: '', name: t('BookingEmailScheduleConfigForm:selectOption') },
            { key: SEND_WHEN_FULLY_PAID, name: t('BookingEmailScheduleConfigForm:sendWhenFullyPaidOption') },
            { key: SEND_WHEN_ANY_PAYMENT, name: t('BookingEmailScheduleConfigForm:sendWhenAnyPaymentOption') },
            { key: SEND_ON_SCHEDULE, name: t('BookingEmailScheduleConfigForm:sendOnScheduleOption') }
        ]

        return <div className='paymentReminderConfigForm'>
            <h2 className='paymentReminderConfig_title'>{isNew ? t('BookingEmailScheduleConfigForm:addBookingEmailScheduleConfigForm') : name.value}</h2>

            <form className='data-form' onSubmit={this.savePaymentReminderConfig} autoComplete='off'>
                <ct.TextBox id='name' labelKey='Global:name' placeholderKey='Global:name' value={name} callback={val => this.setState({ name: this.validateName(val) })} />

                <ct.Select id='clientEmailTemplateId' labelKey='BookingEmailScheduleConfigForm:emailTemplate' value={({ ...clientEmailTemplateId, value: clientEmailTemplateId.value || '' })} callback={this.emailTemplateChanged} options={emailTemplateOptions} />

                {venueEmailTemplates.length === 0 ? <div className='alert alert-danger'>{t('BookingEmailScheduleConfigForm:noEmailTemplates', { emailTypeList: validEmailTypes})}</div> : null}

                <ct.Checkbox id='sendForManualBookings' labelKey='BookingEmailScheduleConfigForm:sendForManualBookings' value={ct.asFormValue('sendForManualBookings', sendForManualBookings)} callback={val => this.setState({ sendForManualBookings: val })} />

                <ct.Checkbox id='sendForWebBookings' labelKey='BookingEmailScheduleConfigForm:sendForWebBookings' value={ct.asFormValue('sendForWebBookings', sendForWebBookings)} callback={ val => this.setState({ sendForWebBookings: val })} />

                <div className='form-group control-wrapper'>
                    <div>
                        <ct.Select id='sendWhen' labelKey='BookingEmailScheduleConfigForm:sendWhen' value={sendWhenOption} options={sendOptions} callback={this.onSendWhenChanged } />
                        {sendWhenOption.value !== SEND_ON_SCHEDULE ? null : <ct.NumberBox id='numberOfDaysBeforeEvent' labelKey='BookingEmailScheduleConfigForm:numberOfDaysBeforeEvent' value={numberOfDaysBeforeEvent} callback={val => this.setState({ numberOfDaysBeforeEvent: this.validateNumberOfDaysBeforeEvent(val, sendWhenPaidInFull, sendWhenAnyPayment) })} min='0' disabled={sendWhenPaidInFull} />}
                        {sendWhenOption.value !== SEND_ON_SCHEDULE ? null : <ct.Time id='timeOfDayToSend' labelKey='BookingEmailScheduleConfigForm:timeToSend' value={({ ...timeOfDayToSend, value: timeOfDayToSend.value ? timeOfDayToSend.value : new Time(8, 0, 0) })} timeFormat={timeFormat} callback={val => this.setState({ timeOfDayToSend: this.validateTimeOfDayToSend(val) })} />}
                    </div>
                </div>

                <p />

                <div className='form-group'>
                    <ul className='list-unstyled'>
                        {
                            resources.filter(r => r.venueId === venue.id && !r.archived).map(res => {
                                const selection = resourceFilter.find(rs => rs === res.id);

                                return <li key={res.id}>
                                        <input type='checkbox' id={res.id} checked={(typeof selection !== 'undefined')} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.resourceStateChanged(res.id, e.currentTarget.checked)} />
                                        <label style={{ marginLeft: '5px', paddingLeft: '3px', borderLeft: `4px solid ${res.colour}` }} htmlFor={res.id}>{res.name}</label>
                                    </li>
                            })
                        }
                    </ul>
                </div>

                <p />

                <ct.Checkbox id='archived' labelKey='Global:archive' value={archived} callback={val => this.setState({ archived: this.validateArchived(val) })} />

                {message}

                <p />
                <div className='btn-toolbar'>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)}>{t('Global:save')}</button>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{t('Global:cancel')}</button>
                </div>
            </form>
        </div>;
    }       

    buildResourceStyle(resource: Resource) {
        return { marginLeft: '5px', paddingLeft: '3px', borderLeft: `4px solid ${resource.colour}` };
    }
};


const mapStateToProps = (state: ApplicationState) => ({
    isLoading: state.bookingEmailScheduleConfigs.isLoading,
    isSaving: state.bookingEmailScheduleConfigs.isSaving,
    saveComplete: state.bookingEmailScheduleConfigs.saveComplete,
    saveError: state.bookingEmailScheduleConfigs.saveError,
    validationErrors: state.bookingEmailScheduleConfigs.validationErrors,
    isLoadingEmailTemplates: state.emailTemplates.isLoading,
    emailTemplates: state.emailTemplates.emailTemplates.filter(t => bookingEmailTemplateTypes.includes(t.emailType) && t.clientEmailTemplateId !== null && !t.archived),
    resources: state.resources.resources
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    saveBookingEmailScheduleConfig: bindActionCreators(BookingEmailScheduleConfigActions.actionCreators.saveBookingEmailScheduleConfig, dispatch),
    loadEmailTemplates: bindActionCreators(EmailTemplateActions.actionCreators.loadEmailTemplates, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch)
});

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(BookingEmailScheduleConfigForm);
