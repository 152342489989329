
import { Action } from 'redux';
import { LocationChangeAction } from 'react-router-redux';

import { EmailActionTypes, EmailState } from './types';
import { EmailActions } from './actions'
import { createReducer } from '../../reducer';
import { mapUtcDate, parseUtcDate } from '../../../utils/util';

const unloadedState: EmailState = { isActive: false, isLoading: false, emails: [], pageNumber: 1, pageSize: 25, maxPage: 1, loadError: null };

export const reducer = (state: EmailState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: EmailState, incomingAction: Action): EmailState => {

    if (incomingAction.type === '@@router/LOCATION_CHANGE') {
        const locationChangeAction = incomingAction as LocationChangeAction;
        if (locationChangeAction) {
            return { ...state, isActive: locationChangeAction.payload.pathname === '/emails' };
        }
    }

    const action = incomingAction as EmailActions;
    switch (action.type) {
        case EmailActionTypes.RequestEmails:
            return {
                ...state,
                isLoading: true,
                emails: state.emails,
                pageSize: action.pageSize,
                pageNumber: action.pageNumber,
                loadError: null
            };
        case EmailActionTypes.ReceivedEmails:
            return {
                ...state,
                isLoading: false,
                emails: action.emails.map(e => ({
                    ...e,
                    createDateTime: parseUtcDate(e.createDateTime),
                    timeSent: mapUtcDate(e.timeSent),
                    processed: mapUtcDate(e.processed),
                    deferred: mapUtcDate(e.deferred),
                    delivered: mapUtcDate(e.delivered),
                    open: mapUtcDate(e.open),
                    click: mapUtcDate(e.click),
                    bounce: mapUtcDate(e.bounce),
                    dropped: mapUtcDate(e.dropped),
                    spamreport: mapUtcDate(e.spamreport),
                    unsubscribe: mapUtcDate(e.unsubscribe)
                })),
                pageNumber: action.page,
                maxPage: action.maxPage,
                loadError: action.error
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

