
import * as React from 'react';
import * as PropTypes from 'prop-types'
import 'rxjs';

import * as ct from '../../global/controls';
import { isNullOrEmpty, clickHandler } from '../../../utils/util';
import { Bill, deserializeBill, getBillTotal } from '../../../store/pages/pointOfSale/types';
import { getWithAuth } from '../../../store/apiClient';
import { IFindBillsResponse } from './billSelection';

interface BillSearchProps {
    venueId: string;
    logout: () => void;
    close: () => void;
    viewBill: (billId: string) => void;
}

interface BillSearchState {
    searchTerm: string;
    fromDate: Date | null;
    toDate: Date | null;
    bills: Bill[];
    loadError: string | null;
}

class BillSearch extends React.Component<BillSearchProps, BillSearchState> {

    searchTimeout: NodeJS.Timeout | null;

    constructor(props: BillSearchProps) {
        super(props);

        this.searchTimeout = null;

        this.state = { searchTerm: '', fromDate: null, toDate: null, bills: [], loadError: null };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    search = () => {
        const { venueId, logout } = this.props;
        const { searchTerm } = this.state;
        const { t } = this.context;

        if (isNullOrEmpty(searchTerm)) {
            return;
        } else if (searchTerm.trim().length < 3) {
            this.setState({ bills: [], loadError: t('PointOfSale:searchMinTextWarning') });
        } else {
            getWithAuth<IFindBillsResponse>(`api/v1/bill/${venueId}/search/${searchTerm}`, () => logout)
                .subscribe(result => this.setState({ bills: result.bills.map(b => deserializeBill(b)), loadError: null }), err => this.setState({ loadError: err.message }));
        }
    }

    searchTermChanged = (newTerm: string) => {
        this.setState({ searchTerm: newTerm }, this.onSearchTermChanged);
    }

    onSearchTermChanged = () => {
        if (this.searchTimeout) clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(this.search, 500);
    }

    render() {
        const { close } = this.props;
        const { loadError, searchTerm, bills } = this.state;
        const { t } = this.context;

        let body: JSX.Element;
        if (!isNullOrEmpty(loadError)) {
            body = <div className='alert alert-danger'>{loadError}</div>
        } else if (bills.length === 0) {
            body = <div className='alert alert-info'>{t('PointOfSale:searchBillsNoResults')}</div>;
        } else {
            body = this.renderResults(bills);
        }

        return <section className='bill-search'>
            <header className='section-header'>
                <div className='page-heading'>
                    <h2 className='bill-search-title'>{t('PointOfSale:searchBills')}</h2>
                </div>
            </header>
            <div className='row'>
                <div className='col-xs-12'>
                    <ct.TextBox id='searchTerm' labelKey='PointOfSale:billSearchTerm' placeholderKey='PointOfSale:billSearchTermHint' value={ct.asFormValue('searchTerm', searchTerm)} callback={this.searchTermChanged} />
                </div>
            </div>
            
            {body}

            <div className='btn-toolbar'>
                <button className='btn btn-basic' onClick={close}>{this.context.t('Global:close')}</button>
            </div>
        </section>;
    }

    renderResults = (bills: Bill[]) => {
        const { t } = this.context;
        return <table className='table table-striped table-sm table-condensed'>
            <thead>
                <tr>
                    <th>{t('Global:number')}</th>
                    <th>{t('PointOfSale:customerHeading')}</th>
                    <th className='text-right'>{t('Global:amount')}</th>
                    <th className='text-right'>{t('PointOfSale:outstandingAmountHeading')}</th>
                </tr>
            </thead>
            <tbody>
                {bills.map(b => (
                    <tr key={b.id} onClick={e => clickHandler(e, () => this.props.viewBill(b.id))}>
                        <td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.props.viewBill(b.id))}>{b.billNumber}</button></td>
                        <td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.props.viewBill(b.id))}>{isNullOrEmpty(b.customerName) ? b.customerEmail : b.customerName }</button></td>
                        <td className='text-right'><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.props.viewBill(b.id))}>{t('Global:currencySymbol')}{getBillTotal(b).toFixed(2)}</button></td>
                        <td className='text-right'><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.props.viewBill(b.id))}>{t('Global:currencySymbol')}{b.outstandingBalance.toFixed(2)}</button></td>
                    </tr>
                ))}
            </tbody>
        </table>
    }
}

export default BillSearch;

