
// https://blog.angularindepth.com/how-to-reduce-action-boilerplate-90dc3d389e2b

import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as ett from './types';
import { IStore } from '../..';

export type EmailTemplateActions = ett.LoadEmailTemplates | ett.ReceiveEmailTemplates | ett.SaveEmailTemplate | ett.EmailTemplateSaved | ett.EmailTemplateSaveFailed | ett.EditEmailTemplate | ett.EmailAttachmentSaved;

export const actionCreators = {
    loadEmailTemplates: () => ({ type: ett.EmailTemplateActionTypes.RequestEmailTemplates }),
    loadEmailTemplatesComplete: (emailTemplates: ett.ClientEmailTemplate[], selectableEmailTypes: ett.EmailType[], err: api.ApiError | null) => ({ type: ett.EmailTemplateActionTypes.ReceivedEmailTemplates, emailTemplates: emailTemplates, selectableEmailTypes: selectableEmailTypes, error: err }),
    editEmailTemplate: () => ({ type: ett.EmailTemplateActionTypes.EditEmailTemplate }),
    saveEmailTemplate: (isNew: boolean, venueId: string, clientEmailTemplateId: string | null, emailTemplateId: string | null, emailType: ett.EmailType, name: string, subjectTemplate: string, textTemplate: string, htmlTemplate: string, jsonTemplate: string, templateType: ett.EmailTemplateType, ccEmailAddress: string, bccEmailAddress: string, sendFromEmailAddressOverride: string | null, sendFromEmailNameOverride: string | null, showActivityStartTimes: boolean, archived: boolean, existingAttachmentIds: string[], newAttachments: File[]) => (
        { type: ett.EmailTemplateActionTypes.SaveEmailTemplate, isNew: isNew, venueId: venueId, clientEmailTemplateId: clientEmailTemplateId, emailTemplateId: emailTemplateId, emailType: emailType, name: name, subjectTemplate: subjectTemplate, textTemplate: textTemplate, htmlTemplate: htmlTemplate, jsonTemplate: jsonTemplate, templateType: templateType, ccEmailAddress: ccEmailAddress, bccEmailAddress: bccEmailAddress, sendFromEmailAddressOverride: sendFromEmailAddressOverride, sendFromEmailNameOverride: sendFromEmailNameOverride, showActivityStartTimes: showActivityStartTimes, archived: archived, existingAttachmentIds: existingAttachmentIds, newAttachments: newAttachments }
    ),
    emailTemplateSaved: (emailType: ett.EmailType) => ({ type: ett.EmailTemplateActionTypes.EmailTemplateSaved, emailType: emailType }),
    emailTemplateSaveFailed: (error: api.ApiError) => ({ type: ett.EmailTemplateActionTypes.EmailTemplateSaveFailed, error: error }),
    emailAttachmentSaved: (fileName: string) => ({ type: ett.EmailTemplateActionTypes.EmailAttachmentSaved, fileName: fileName })
}

interface IGetEmailTemplatesResponse {
    emailTemplates: ett.ClientEmailTemplate[];
    selectableEmailTypes: ett.EmailType[];
}

interface ISaveClientEmailTemplateResponse {
    templateId: string;
    clientTemplateId: string;
}

export const handleClientChange = (store: IStore) => [actionCreators.loadEmailTemplates]

//https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-after-catching-and-processing-an-error-with-emitting-somethi

const loadEmailTemplates = () => Observable.defer(() => api.getJson<IGetEmailTemplatesResponse>('api/v1/emailTemplate/'))
    .map(response => actionCreators.loadEmailTemplatesComplete(response.emailTemplates, response.selectableEmailTypes, null));

export const loadEmailTemplateEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        ett.EmailTemplateActionTypes.RequestEmailTemplates,
        action => loadEmailTemplates(),
        err => actionCreators.loadEmailTemplatesComplete([], [], err));

export const reloadEmailTemplateEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        ett.EmailTemplateActionTypes.EmailTemplateSaved,
        action => loadEmailTemplates(),
        err => actionCreators.loadEmailTemplatesComplete([], [], err));

export const saveEmailTemplateEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        ett.EmailTemplateActionTypes.SaveEmailTemplate,
        action => {
            const sceta = action as ett.SaveEmailTemplate;
            var body = {
                venueId: sceta.venueId,
                clientEmailTemplateId: sceta.clientEmailTemplateId,
                emailType: sceta.emailType,
                emailTemplateId: sceta.emailTemplateId,
                name: sceta.name,
                ccEmailAddress: sceta.ccEmailAddress,
                bccEmailAddress: sceta.bccEmailAddress,
                subjectTemplate: sceta.subjectTemplate,
                textTemplate: sceta.textTemplate,
                htmlTemplate: sceta.htmlTemplate,
                jsonTemplate: sceta.jsonTemplate,
                templateType: sceta.templateType,
                sendFromEmailAddressOverride: sceta.sendFromEmailAddressOverride,
                sendFromEmailNameOverride: sceta.sendFromEmailNameOverride,
                showActivityStartTimes: sceta.showActivityStartTimes,
                archived: sceta.archived,
                existingAttachmentIds: sceta.existingAttachmentIds
            };

            var saveTemplate = (sceta.isNew ? api.post('api/v1/emailTemplate/', body) : api.put(`api/v1/emailTemplate/${sceta.emailType}`, body))
                .map(resp => {
                    const body = resp.response as ISaveClientEmailTemplateResponse;
                    return { templateId: body.templateId, clientTemplateId: body.clientTemplateId }
                });

            if (!sceta.newAttachments || sceta.newAttachments.length === 0) {
                return saveTemplate.map(x => {
                    return actionCreators.emailTemplateSaved(sceta.emailType)
                });
            } else {
                return saveTemplate.flatMap(resp => {
                    const attachmentObservables: Observable<ett.EmailAttachmentSaved>[] = [];

                    (sceta.newAttachments || []).forEach(obj => {
                        attachmentObservables.push(api.uploadFile(obj, `api/v1/emailTemplate/${resp.templateId}/attachment`).map(res => {
                            return ({ type: ett.EmailTemplateActionTypes.EmailAttachmentSaved, fileName: obj.name })
                        }));
                    });

                    return Observable.forkJoin(attachmentObservables);
                })
                    .map(x => {
                        return actionCreators.emailTemplateSaved(sceta.emailType)
                    });
            }
        },
        (err: api.ApiError) => actionCreators.emailTemplateSaveFailed(err));
