
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/index';
import { connect } from 'react-redux'

import Connected from '../icons/connected';
import Disconnected from '../icons/disconnected';
import { Message } from '../../store/pages/status/types';
import { clickHandler, isNullOrEmpty } from '../../utils/util';
import { TimeFormat } from '../../store/pages/venues/types';

interface LocalState {
    isSignalrConnected: boolean;
    lastConnectionTime: Date | null;
    lastConnectionError: Date | null;
    lastErrorMessage: string | null;
    lastConnectionAttempt: Date | null;
    connectionErrors: Message[];
    lastMessages: Message[];
}

interface Actions { }

interface LocalProps { }

type ConnectionStatusProps = LocalState & Actions & LocalProps;

interface ConnectionStatusState {
    showPopup: boolean;
}

class ConnectionStatus extends React.Component<ConnectionStatusProps, ConnectionStatusState> {

    _popup: React.RefObject<HTMLDivElement>;

    static contextTypes = {
        t: PropTypes.func
    }

    constructor(props: ConnectionStatusProps) {
        super(props);

        this._popup = React.createRef();
        this.state = { showPopup: false };
    }

    componentWillUnmount() {
        this.disableClickHandler();
    }

    globalClickHandler = (e: Event) => {
        console.log('global click')
        // ignore click event happened inside the dropdown menu
        const path = e.composedPath();
        const pathMatch = path.findIndex(x => x === this._popup.current) >= 0;
        if (pathMatch) {
            return
        }

        // else hide dropdown menu
        this.hidePopup()
    }

    enableClickHandler = () => document.addEventListener('click', this.globalClickHandler);
    disableClickHandler = () => document.removeEventListener('click', this.globalClickHandler);
    showPopup = () => this.setState({ showPopup: true }, this.enableClickHandler);
    hidePopup = () => this.setState({ showPopup: false }, this.disableClickHandler);

    render() {
        const { isSignalrConnected, lastConnectionTime, lastConnectionError, lastErrorMessage, lastConnectionAttempt, lastMessages } = this.props;
        const { showPopup } = this.state;
        const { t } = this.context;

        return <div className='server-connection-status' onClick={e => clickHandler(e, this.showPopup)}>
            {isSignalrConnected ? <Connected width={30} height={30} colour='green' /> : <Disconnected width={30} height={30} colour='red' />}
            {showPopup ? <div className='server-connection-status-popup'>
                <div><label>{t('ConnectionStatus:status')}</label><span className={`label label-${isSignalrConnected ? 'success' : 'danger'}`}>{t(isSignalrConnected ? 'Global:connected' : 'Global:disconnected') }</span></div>
                <div><label>{t('ConnectionStatus:lastConnected')}</label>{lastConnectionTime ? lastConnectionTime.toFullTimeString(TimeFormat.TwentyFourHour) : ''}</div>
                {!isSignalrConnected && !isNullOrEmpty(lastErrorMessage) && lastConnectionError ? <div className='text-danger'><label>{lastConnectionError.toFullTimeString(TimeFormat.TwentyFourHour)}</label>{lastErrorMessage}</div> : null}
                {!isSignalrConnected && lastConnectionAttempt ? <div><label>{t('ConnectionStatus:lastConnectionAttempt')}</label>{lastConnectionAttempt.toFullTimeString(TimeFormat.TwentyFourHour)}</div> : null}
                {lastMessages.map(m => <div><label>{m.time.toFullTimeString(TimeFormat.TwentyFourHour)}</label>{m.message}</div>) }
            </div> : null}
        </div>
    }
}

const mapStateToProps = (state: ApplicationState) => {
    console.log(`Signalr state: ${state.status.isConnected ? 'connected' : 'disonnected'}`);
    return {
        isSignalrConnected: state.status.isConnected,
        lastConnectionTime: state.status.lastConnectionTime,
        lastConnectionError: state.status.lastConnectionError,
        lastConnectionAttempt: state.status.lastConnectionAttempt,
        connectionErrors: state.status.connectionErrors,
        lastErrorMessage: state.status.lastErrorMessage,
        lastMessages: state.status.lastMessages,
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({});

// Casting to prevent error where used in index.ts that isBusy is mandatory, since it is being provided by Redux.
export default connect(mapStateToProps, mapDispatchToProps)(ConnectionStatus);
