
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as pst from './types'; 
import { IStore } from '../..';


export type PaymentScheduleActions = pst.LoadPaymentSchedules | pst.ReceivePaymentSchedules | pst.EditPaymentSchedule | pst.SavePaymentSchedule | pst.PaymentScheduleSaved | pst.PaymentScheduleSaveFailed;

export const actionCreators = {
    loadPaymentSchedules: () => ({ type: pst.PaymentScheduleActionTypes.RequestPaymentSchedules }),
    loadPaymentSchedulesComplete: (paymentSchedules: pst.PaymentSchedule[], err: api.ApiError | null) => ({ type: pst.PaymentScheduleActionTypes.ReceivedPaymentSchedules, paymentSchedules: paymentSchedules, error: err }),
    editPaymentSchedule: () => ({ type: pst.PaymentScheduleActionTypes.EditPaymentSchedule }),
    savePaymentSchedule: (isNew: boolean, paymentScheduleId: string | null, paymentSchedule: pst.PaymentSchedule) => ({ type: pst.PaymentScheduleActionTypes.SavePaymentSchedule, isNew: isNew, paymentScheduleId: paymentScheduleId, paymentSchedule: paymentSchedule }),
    paymentScheduleSaved: (paymentScheduleId: number) => ({ type: pst.PaymentScheduleActionTypes.PaymentScheduleSaved, paymentScheduleId: paymentScheduleId }),
    paymentScheduleSaveFailed: (error: api.ApiError) => ({ type: pst.PaymentScheduleActionTypes.PaymentScheduleSaveFailed, error: error })

}

interface IGetPaymentSchedulesResponse {
    paymentSchedules: pst.PaymentSchedule[];
}

interface ISavePromotionResponse {
    paymentScheduleId: string;
}

export const handleClientChange = (store: IStore) => [actionCreators.loadPaymentSchedules]

//https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-after-catching-and-processing-an-error-with-emitting-somethi

const loadPaymentSchedules = () => Observable.defer(() => api.getJson<IGetPaymentSchedulesResponse>('api/v1/paymentSchedule/'))
    .map(response => actionCreators.loadPaymentSchedulesComplete(response.paymentSchedules, null));

export const loadPaymentSchedulesEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        pst.PaymentScheduleActionTypes.RequestPaymentSchedules,
        action => loadPaymentSchedules(),
        err => actionCreators.loadPaymentSchedulesComplete([], err));


export const reloadPaymentSchedulesEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        pst.PaymentScheduleActionTypes.PaymentScheduleSaved,
        action => loadPaymentSchedules(),
        err => actionCreators.loadPaymentSchedulesComplete([], err));

export const savePaymentScheduleEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        pst.PaymentScheduleActionTypes.SavePaymentSchedule,
        action => {
            const spsa = action as pst.SavePaymentSchedule;
            let paymentScheduleId = spsa.paymentScheduleId;
            const ps = { ...spsa.paymentSchedule };
            const body = { ...ps };

            return (spsa.isNew || !paymentScheduleId ? api.post('api/v1/paymentSchedule/', body) : api.put(`api/v1/paymentSchedule/${paymentScheduleId}`, body))
                .map(response => {
                    if (spsa.isNew) {
                        const spsr = response.response as ISavePromotionResponse;
                        if (spsr) {
                            paymentScheduleId = spsr.paymentScheduleId;
                        }
                    }
                    return ({ type: pst.PaymentScheduleActionTypes.PaymentScheduleSaved, paymentScheduleId: paymentScheduleId });
                });
        },
        (err: api.ApiError) => actionCreators.paymentScheduleSaveFailed(err));

