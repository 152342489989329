
import 'quill/dist/quill.snow.css';
import '../../../css/quill.css';

import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'
import Dropzone from 'react-dropzone';
import { ImageFile } from '../../../utils/images';
import { quillStandardToolbar } from '../../global/quillSettings';

import { VenueRegistrationKiosk } from '../../../store/pages/venues/types';
import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import { ApplicationState } from '../../../store';
import * as VenueActions from '../../../store/pages/venues/actions';
import * as ModalActions from '../../../store/global/modal/actions';
import { getClientImageThunbUrl } from '../../../store/apiClient';
import ImagePreview from './imagePreview';
import { isNullOrEmpty, clickHandler } from '../../../utils/util';
import { ValidationError } from '../../../store/global/types';
import { RegistrationTerms } from '../../../store/pages/termsAndConditions/types';

interface LocalProps {
    venueId: string;
    kiosk: VenueRegistrationKiosk;
}

interface RegKioskSetting {
    key: string;
    id: number;
}

interface MappedReduxState {
    saveComplete: boolean;
    validationErrors: ValidationError[];
    registrationTerms: RegistrationTerms[];
}

interface Actions {
    saveRegistrationSettings: (registrationKioskId: string | null, venueId: string, kioskName: string, registrationPassword: string | null, registrationImage: File | null, registrationFields: number, requiredRegistrationFields: number, existingWelcomeImages: string[], newWelcomeImages: File[], welcomeText: string, nameStepHeading: string, addressStepHeading: string, returningCustomerSetpHeading: string, contactPreferencesStepHeading: string, welcomeImageInterval: number, finalInstructions: string, resultsMarketingText: string, generalMarketingText: string, requireSignatures: boolean, registrationTermsId: string, requireAddressForChildren: boolean) => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
}

type RegistrationSettingsFormProps = MappedReduxState & Actions & LocalProps;

interface RegistrationSettingsFormState {
    showPassword: boolean;
    kioskName: ct.FormValue<string>;
    password: ct.FormValue<string | null>;
    registrationImageId: string | null;
    registrationImage: ImageFile | null;
    registrationCode: string | null;
    registrationFields: number;
    requiredRegistrationFields: number;
    settings: RegKioskSetting[];
    existingWelcomeScreenImages: string[];
    newWelcomeScreenImages: ImageFile[];
    welcomeText: ct.FormValue<string>;
    nameStepHeading: ct.FormValue<string>;
    addressStepHeading: ct.FormValue<string>;
    returningCustomerStepHeading: ct.FormValue<string>;
    contactPreferencesStepHeading: ct.FormValue<string>;
    welcomeImageInterval: ct.FormValue<number>;
    finalInstructions: ct.FormValue<string>;
    resultsMarketingText: ct.FormValue<string>;
    generalMarketingText: ct.FormValue<string>;
    requireSignatures: ct.FormValue<boolean>;
    registrationTermsId: ct.FormValue<string>;
    requireAddressForChildren: ct.FormValue<boolean>;
}

class RegistrationSettingsForm extends React.Component<RegistrationSettingsFormProps, RegistrationSettingsFormState> {

    constructor(props: RegistrationSettingsFormProps) {
        super(props);

        this.state = this.buildStateFromProps(this.props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps(props: RegistrationSettingsFormProps): RegistrationSettingsFormState {
        const { kiosk } = props;
        const { registrationKioskId, kioskName, registrationImageId, registrationCode, registrationFields, requiredRegistrationFields, welcomeScreenImageIds, welcomeText, nameStepHeading
            , addressStepHeading, returningCustomerStepHeading, contactPreferencesStepHeading, welcomeImageInterval, finalInstructions,
            resultsMarketingText, generalMarketingText, requireSignatures, registrationTermsId, requireAddressForChildren } = kiosk;

        const settings = [{ key: 'basicAddress', id: 16 }, { key: 'fullAddress', id: 32 }, { key: 'postcode', id: 64 }, { key: 'emailAddress', id: 128 }, { key: 'phoneNumber', id: 256 }, { key: 'emergencyContactName', id: 512 }, { key: 'emergencyContactNumber', id: 1024 }];

        return {
            kioskName: this.validateKioskName(kioskName),
            registrationImageId,
            registrationCode,
            registrationFields,
            requiredRegistrationFields,
            registrationImage: null,
            showPassword: isNullOrEmpty(registrationKioskId),
            password: this.validatePassword(null, isNullOrEmpty(registrationKioskId)),
            existingWelcomeScreenImages: welcomeScreenImageIds || [],
            newWelcomeScreenImages: [],
            welcomeText: this.validateWelcomeText(welcomeText),
            nameStepHeading: this.validateNameStepHeading(nameStepHeading),
            addressStepHeading: this.validateAddressStepHeading(addressStepHeading),
            returningCustomerStepHeading: this.validateReturningCustomerStepHeading(returningCustomerStepHeading),
            contactPreferencesStepHeading: this.validateContactPreferencesStepHeading(contactPreferencesStepHeading),
            welcomeImageInterval: this.validateWelcomeScreenImageInterval(welcomeImageInterval),
            finalInstructions: this.validateFinalInstructions(finalInstructions),
            resultsMarketingText: this.validateResultsMarketingText(resultsMarketingText),
            generalMarketingText: this.validateGeneralMarketingText(generalMarketingText),
            requireSignatures: this.validateRequireSignatures(requireSignatures),
            registrationTermsId: this.validateRegistrationTermsId(registrationTermsId),
            requireAddressForChildren: this.validateRequireAddressForChildren(requireAddressForChildren),
            settings
        };
    } 

    onImageDrop = (files: File[]) => {
        const file = files[0];
        this.setState({ registrationImage: { file: file, preview: URL.createObjectURL(file) } });
    }

    onWelcomeImageDrop = (files: File[]) => {
        this.setState((prevState) => ({ newWelcomeScreenImages: prevState.newWelcomeScreenImages.concat(files.map(f => ({ file: f, preview: URL.createObjectURL(f) }))) }));
    }

    private saveVenueRegistrationSettings = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }

    changePassword = () => {
        this.setState((prevState) => ({ showPassword: true, password: this.validatePassword(prevState.password.value, true)}));
    }

    setFieldState = (id: number, enabled: boolean) => {
        this.setState(s => {
            let val = s.registrationFields;
            let requiredVal = s.requiredRegistrationFields;

            if (enabled) {
                val |= id;
            } else {
                val &= ~id;
                requiredVal &= ~id;
            }

            return { registrationFields: val, requiredRegistrationFields: requiredVal }
        });
    }

    setFieldRequiredState = (id: number, required: boolean) => {

        let val = this.state.requiredRegistrationFields;

        if (required) {
            val |= id;
        } else {
            val &= ~id;
        }

        this.setState({ requiredRegistrationFields: val });
    }

    private close = () => {
        this.props.closeModal();
    }

    private save = () => {
        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {
            this.props.saveRegistrationSettings(this.props.kiosk.registrationKioskId,
                this.props.venueId,
                this.state.kioskName.value,
                this.state.password.value,
                this.state.registrationImage ? this.state.registrationImage.file : null,
                this.state.registrationFields,
                this.state.requiredRegistrationFields,
                this.state.existingWelcomeScreenImages,
                this.state.newWelcomeScreenImages.map(f => f.file),
                this.state.welcomeText.value,
                this.state.nameStepHeading.value,
                this.state.addressStepHeading.value,
                this.state.returningCustomerStepHeading.value,
                this.state.contactPreferencesStepHeading.value,
                this.state.welcomeImageInterval.value,
                this.state.finalInstructions.value,
                this.state.resultsMarketingText.value,
                this.state.generalMarketingText.value,
                this.state.requireSignatures.value,
                this.state.registrationTermsId.value,
                this.state.requireAddressForChildren.value);
        }
    }

    componentDidUpdate(prevProps: RegistrationSettingsFormProps) {
        const { kiosk: prevKiosk, venueId: prevVenueId, saveComplete: prevSaveComplete } = prevProps;
        const { kiosk, venueId, saveComplete } = this.props;

        // Only update state is venue has changed
        if ((!prevKiosk && kiosk) || (prevKiosk && !kiosk) || (prevVenueId !== venueId) || (prevKiosk && kiosk && prevKiosk.registrationKioskId !== kiosk.registrationKioskId)) {
            this.setState(this.buildStateFromProps(this.props));
        }

        if (saveComplete && !prevSaveComplete) {
            setTimeout(() => { this.close(); }, 750);
        }
    }

    validateKioskName = (val: string) => v.validate(val, 'kioskName', [v.required], this.props.validationErrors);
    validatePassword = (val: string | null, showPassword: boolean) => v.validate(val, 'password', showPassword ? [v.required] : [], this.props.validationErrors);
    validateWelcomeScreenImageInterval = (val: number) => v.validate(val, 'welcomeImageInterval', [v.required], this.props.validationErrors);
    validateNameStepHeading = (val: string) => v.validate(val, 'nameStepHeading', [], this.props.validationErrors);
    validateAddressStepHeading = (val: string) => v.validate(val, 'addressStepHeading', [], this.props.validationErrors);
    validateReturningCustomerStepHeading = (val: string) => v.validate(val, 'returningCustomerStepHeading', [], this.props.validationErrors);
    validateContactPreferencesStepHeading = (val: string) => v.validate(val, 'contactPreferencesStepHeading', [], this.props.validationErrors);
    validateResultsMarketingText = (val: string) => v.validate(val, 'resultsMarketingText', [], this.props.validationErrors);
    validateGeneralMarketingText = (val: string) => v.validate(val, 'generalMarketingText', [], this.props.validationErrors);
    validateRequireSignatures = (val: boolean) => v.validate(val, 'requireSignatures', [], this.props.validationErrors);

    validateWelcomeText = (val: string) => v.validate(val, 'welcomeText', [], []);
    validateFinalInstructions = (val: string) => v.validate(val, 'finalInstructions', [], []);
    validateRegistrationTermsId = (val: string) => v.validate(val, 'registrationTermsId', [v.required], []);
    validateRequireAddressForChildren = (val: boolean) => v.validate(val, 'requireAddressForChildren', [], []);

    asFld = (controlId: string, val: any, isValid: boolean = true, errKey?: string) => ({ controlId: controlId, value: val, isValid: isValid, hasValidation: !isValid, errorMessageKey: errKey });
    isImageFile = (img: string | File): img is File => img.hasOwnProperty('preview');
    welcomeTextChanged = (val: string) => this.setState({ welcomeText: this.validateWelcomeText(val) });

    deleteExistingImage = (img: string) => this.setState((prevState) => ({ existingWelcomeScreenImages: prevState.existingWelcomeScreenImages.filter(i => i !== img) }));
    deleteNewImage = (img: string) => this.setState((prevState) => ({ newWelcomeScreenImages: prevState.newWelcomeScreenImages.filter(i => i.file.name !== img) }));

    finalInstructionsChanged = (val: string) => this.setState({ finalInstructions: this.validateFinalInstructions(val) });

    onRegistrationTermsChanged = (val: string) => this.setState({ registrationTermsId: this.validateRegistrationTermsId(val) })


    render() {
        const { t } = this.context;
        const { registrationTerms, venueId } = this.props;
        const { registrationImage, registrationImageId, existingWelcomeScreenImages, newWelcomeScreenImages, registrationFields, requiredRegistrationFields,
            password, showPassword, registrationTermsId, kioskName, requireSignatures, welcomeText, finalInstructions, nameStepHeading, addressStepHeading,
            returningCustomerStepHeading, contactPreferencesStepHeading, resultsMarketingText, generalMarketingText, welcomeImageInterval, requireAddressForChildren } = this.state;

        let passwordCtrl: JSX.Element | null = null;
        if (showPassword) {
            passwordCtrl = <ct.Password id='password' labelKey='RegistrationSettingsForm:password' placeholderKey='RegistrationSettingsForm:password' value={({ ...password, value: password.value || '' })} callback={val => this.setState((prevState) => ({ password: this.validatePassword(val, prevState.showPassword) }))} />;
        } else {
            passwordCtrl = <button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, this.changePassword)}>{t('RegistrationSettingsForm:changePassword')}</button>;
        }

        let previewImg: JSX.Element | null = null;
        const imageUrl = registrationImage ? registrationImage.preview : registrationImageId !== null ? getClientImageThunbUrl(registrationImageId, 200) : null;
        if (imageUrl) {
            previewImg = <img src={imageUrl} className='file-preview' alt='preview'></img>;
        }

        const registrationTermsOptions = [{ key: '', name: t('General:selectRegistrationTerms') }].concat(registrationTerms.filter(t => t.venueId === venueId).map(t => ({ key: t.id, name: t.name })));

        const welcomeScreenImagePreviews = existingWelcomeScreenImages
            .map((i, ix) => <ImagePreview key={`ei${ix}`} src={getClientImageThunbUrl(i, 200)} delete={k => this.deleteExistingImage(i)} />)
            .concat(newWelcomeScreenImages.map(i => <ImagePreview key={`ni${i}`} src={i.preview || ''} delete={() => this.deleteNewImage(i.file.name)} />));

        const settings = this.state.settings.map(s => (
            <div key={s.key} className='registration-kiosk-setting'>
                <div className='registration-kiosk-setting-name'>{t(`RegistrationSettingsForm:setting_${s.key}`)}</div>
                <div className='registration-kiosk-setting-selection'><ct.Checkbox id={`${s.key}_enabled`} labelKey='' value={this.asFld(`${s.key}_enabled`, (registrationFields & s.id) === s.id)} callback={val => this.setFieldState(s.id, val)} /></div>
                <div className='registration-kiosk-setting-selection'><ct.Checkbox id={`${s.key}_required`} labelKey='' value={this.asFld(`${s.key}_required`, (requiredRegistrationFields & s.id) === s.id)} callback={val => this.setFieldRequiredState(s.id, val)} disabled={(registrationFields & s.id) === 0} /></div>
            </div >
        )).concat([
            <div key='nickname' className='registration-kiosk-setting'>
                <div className='registration-kiosk-setting-name'>{t('RegistrationSettingsForm:setting_nickname')}</div>
                <div className='registration-kiosk-setting-selection'><ct.Checkbox id={'nickname_enabled'} labelKey='' value={this.asFld('nickname_enabled', (registrationFields & 2048) === 2048)} callback={val => this.setFieldState(2048, val)} /></div>
            </div>,
            <div key='gender' className='registration-kiosk-setting'>
                <div className='registration-kiosk-setting-name'>{t('RegistrationSettingsForm:setting_gender')}</div>
                <div className='registration-kiosk-setting-selection'><ct.Checkbox id={'gender_enabled'} labelKey='' value={this.asFld('gender_enabled', (registrationFields & 4096) === 4096)} callback={val => { this.setFieldState(4096, val); this.setFieldRequiredState(4096, val); }} /></div>
            </div >

        ]);

        return (
            <div className='venueForm'>
                <h1 className='venue_title'>{t('RegistrationSettingsForm:title')}</h1>

                <form className='data-form' onSubmit={this.saveVenueRegistrationSettings} autoComplete='off'>

                    {passwordCtrl}

                    <p />
                    <ct.TextBox id='kioskName' labelKey='RegistrationSettingsForm:kioskName' placeholderKey='RegistrationSettingsForm:kioskName' value={kioskName} callback={val => this.setState({ kioskName: this.validateKioskName(val) })} />

                    <p />
                    <label>{t('RegistrationSettingsForm:customerInformation')}</label>
                    <div className='registration-kiosk-settings-list'>
                        <div className='registration-kiosk-setting'>
                            <div className='registration-kiosk-setting-name'></div>
                            <div className='registration-kiosk-setting-selection'><label>{t(`RegistrationSettingsForm:setting_collect`)}</label></div>
                            <div className='registration-kiosk-setting-selection'><label>{t(`RegistrationSettingsForm:setting_required`)}</label></div>
                        </div>
                        {settings}

                        <ct.Checkbox id='requireSignatures' labelKey='RegistrationSettingsForm:requireSignatures' value={requireSignatures} callback={val => this.setState({ requireSignatures: this.validateRequireSignatures(val) })} />
                        <ct.Checkbox id='requireAddressForChildren' labelKey='RegistrationSettingsForm:requireAddressForChildren' value={requireAddressForChildren} callback={val => this.setState({ requireAddressForChildren: this.validateRequireAddressForChildren(val) })} />
                    </div>

                    <p />

                    <label>{t('RegistrationSettingsForm:backgroundImage')}</label>
                    <div>
                        <Dropzone multiple={false} accept={{ 'image/*': ['.png', '.gif', '.jpeg', '.jpg'] }} onDrop={this.onImageDrop}>
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()} className='file-drop'>
                                    <input {...getInputProps()} />
                                    <p>{t('Global:imageDropText')}</p>
                                </div>
                            )}
                        </Dropzone>
                        {previewImg}
                    </div>

                    <h4>{t('RegistrationSettingsForm:welcomeMessage')}</h4>
                    <ct.HtmlInput id='welcomeText' labelKey='' value={welcomeText} callback={this.welcomeTextChanged} modules={quillStandardToolbar} quillClassName='quill-dark' inlineStyles={true} />

                    <ct.TextBox id='nameStepHeading' labelKey='RegistrationSettingsForm:nameStepHeading' placeholderKey='RegistrationSettingsForm:nameStepHeading' value={nameStepHeading} callback={val => this.setState({ nameStepHeading: this.validateNameStepHeading(val) })} />
                    <ct.TextBox id='addressStepHeading' labelKey='RegistrationSettingsForm:addressStepHeading' placeholderKey='RegistrationSettingsForm:addressStepHeading' value={addressStepHeading} callback={val => this.setState({ addressStepHeading: this.validateAddressStepHeading(val) })} />
                    <ct.TextBox id='returningCustomerStepHeading' labelKey='RegistrationSettingsForm:returningCustomerStepHeading' placeholderKey='RegistrationSettingsForm:returningCustomerStepHeading' value={returningCustomerStepHeading} callback={val => this.setState({ returningCustomerStepHeading: this.validateReturningCustomerStepHeading(val) })} />
                    <ct.TextBox id='contactPreferencesStepHeading' labelKey='RegistrationSettingsForm:contactPreferencesStepHeading' placeholderKey='RegistrationSettingsForm:contactPreferencesStepHeading' value={contactPreferencesStepHeading} callback={val => this.setState({ contactPreferencesStepHeading: this.validateContactPreferencesStepHeading(val) })} />


                    <h4>{t('VenueForm:finalInstructions')}</h4>
                    <ct.HtmlInput id='finalInstructions' labelKey='' value={finalInstructions} callback={this.finalInstructionsChanged} modules={quillStandardToolbar} quillClassName='quill-dark' inlineStyles={true} />

                    <ct.TextBox id='resultsMarketingText' labelKey='VenueForm:resultsMarketingText' placeholderKey='VenueForm:resultsMarketingText' value={resultsMarketingText} callback={val => this.setState({ resultsMarketingText: this.validateResultsMarketingText(val) })} />
                    <ct.TextBox id='generalMarketingText' labelKey='VenueForm:generalMarketingText' placeholderKey='VenueForm:generalMarketingText' value={generalMarketingText} callback={val => this.setState({ generalMarketingText: this.validateGeneralMarketingText(val) })} />

                    <ct.Select id='registrationTerms' labelKey='VenueForm:defaultRegistrationTerms' value={registrationTermsId} callback={this.onRegistrationTermsChanged} options={registrationTermsOptions} />

                    <label>{t('RegistrationSettingsForm:welcomeScreenImages') }</label>
                    <div>
                        <Dropzone multiple={false} accept={{ 'image/*': ['.png', '.gif', '.jpeg', '.jpg'] }} onDrop={this.onWelcomeImageDrop}>
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()} className='file-drop'>
                                    <input {...getInputProps()} />
                                    <p>{t('Global:imageDropText')}</p>
                                </div>
                            )}
                        </Dropzone>
                        <div style={({display: 'inline-block'})}>
                            {welcomeScreenImagePreviews}
                        </div>
                    </div>

                    <div>
                        <ct.NumberBox id='welcomeImageInterval' labelKey='RegistrationSettingsForm:welcomeScreenImageInterval' placeholderKey='RegistrationSettingsForm:welcomeScreenImageInterval' value={welcomeImageInterval} callback={val => this.setState({ welcomeImageInterval: this.validateWelcomeScreenImageInterval(val||8) })} min='1' />
                    </div>

                    <p/>
                    <div className='btn-toolbar'>
                        <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)}>{t('Global:save')}</button>
                        <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{t('Global:cancel')}</button>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    saveComplete: state.venues.saveComplete,
    validationErrors: state.venues.validationErrors,
    registrationTerms: state.termsAndConditions.registrationTerms,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    saveRegistrationSettings: bindActionCreators(VenueActions.actionCreators.saveRegistrationSettings, dispatch),
    showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(RegistrationSettingsForm);
