
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as api from '../../../store/apiClient';

import { clickHandler } from '../../../utils/util';
import { CustomerHistoryMerge } from '../../../store/pages/customer/customerApi';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';

interface ConfirmUndoMergeProps {
    merge: CustomerHistoryMerge;
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
    close: (reload: boolean) => void;
    logout: () => void;
}

interface ConfirmUndoMergeState {
    errorMessage: string | null;
    processing: boolean;
    processingError: api.ApiError | null;
}

class ConfirmUndoMerge extends React.Component<ConfirmUndoMergeProps, ConfirmUndoMergeState> {

    constructor(props: ConfirmUndoMergeProps) {
        super(props);

        this.state = { errorMessage: null, processing: false, processingError: null };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    confirm = () => {
        const { merge, close, logout } = this.props;
        this.setState({ processing: true, errorMessage: null, processingError: null });

        api.postWithAuth(`api/v1/customer/merge/${merge.id}/undo`, {}, logout)
            .subscribe(
                _ => this.setState({ processing: false, errorMessage: null }, () => close(true)),
                e => this.setState({ processing: false, processingError: e }));
    }

    render() {
        const { merge, timeFormat, dateFormat, close } = this.props;
        const { t } = this.context;

        return (
            <div>
                <h3>{t('ConfirmUndoMerge:heading')}</h3>
                <div className='row form-group'>
                    <div className='col-md-12'>
                        {t('ConfirmUndoMerge:message')}
                    </div>
                </div>

                <div className='row mt-15'>
                    <div className='col-md-12'>
                        <label>{t('ConfirmUndoMerge:customerName')}</label>
                        <div>{merge.firstname} {merge.lastname}</div>
                    </div>
                </div>
                <div className='row mt-15'>
                    <div className='col-md-12'>
                        <label>{t('ConfirmUndoMerge:email')}</label>
                        <div>{merge.emailAddress}</div>
                    </div>
                </div>
                <div className='row mt-15'>
                    <div className='col-md-12'>
                        <label>{t('ConfirmUndoMerge:mergeTime')}</label>
                        <div>{merge.whenMerged.toAbbrDateTimeString(timeFormat, dateFormat, t)}</div>
                    </div>
                </div>

                <form className='data-form mt-15' onSubmit={e => e.preventDefault()} autoComplete='off'>
                    <div className='row form-group'>
                        <div className='col-md-12 btn-toolbar'>
                            <button className='btn btn-primary' onClick={e => clickHandler(e, this.confirm)}>{t('ConfirmUndoMerge:confirmUndo')}</button>
                            <button className='btn btn-basic' onClick={e => clickHandler(e, () => close(false))}>{t('Global:cancel')}</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default ConfirmUndoMerge;