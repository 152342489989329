
import * as React from 'react';
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { clickHandler } from '../../../utils/util';

import { ApplicationState } from '../../../store';
import { SpecialTagType, Tag } from '../../../store/pages/tags/types';
import ColourPicker from '../../global/colourPicker';
import { colours } from '../../../store/global/types';

import * as TagActions from '../../../store/pages/tags/actions';
import * as api from '../../../store/apiClient';
import * as ct from '../../global/controls';
import * as v from '../../global/validation';

interface MappedReduxState {
    isSaving: boolean;
    saveError: api.ApiError | null;
    saveComplete: boolean;
}

interface Actions {
    saveTag: (isNew: boolean, tagId: string | null, name: string, colour: string, archived: boolean, specialTagType: SpecialTagType) => void;
}

interface ComponentProps {
    tag: Tag;
    close: () => void;
}

type TagPageProps = MappedReduxState & Actions & ComponentProps;

interface TagPageState {
    loading: boolean;
    colour: string;
    archived: ct.FormValue<boolean>;
}


class TagPage extends React.Component<TagPageProps, TagPageState> {

    constructor(props: TagPageProps) {
        super(props);

        this.state = this.buildStateFromProps(props)
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps(props: TagPageProps): TagPageState {

        const { tag } = props;

        return { loading: true, colour: tag.colour, archived: this.validateArchived(tag.archived) };
    }

    componentDidMount() {
        
    }

    componentDidUpdate(prevProps: TagPageProps) {
        // Only update state is tax rate has changed
        const { tag: prevTag, saveComplete: prevSaveComplete } = prevProps;
        const { tag, saveComplete, close } = this.props;

        if ((!prevTag && tag) || (prevTag && !tag) || (prevTag && tag && prevTag.id !== tag.id)) {
            this.setState(this.buildStateFromProps(this.props));
        }

        if (saveComplete && !prevSaveComplete) {
            setTimeout(() => { close(); }, 750);
        }
    }

    validateArchived = (val: boolean) => v.validate(val, 'archived', [], []);

    save = () => {
        const { tag, saveTag } = this.props;
        const { archived, colour } = this.state;

        saveTag(false, tag.id, tag.name, colour, archived.value, tag.specialTagType)
    }

    render() {
        const { tag } = this.props;
        const { loading, colour, archived } = this.state;

        return <div>
            <h2><span className='label tag-label' style={({ backgroundColor: colour })}>{tag.name}</span></h2>

            <form className='data-form' onSubmit={e => e.preventDefault()} autoComplete='off'>

                <div className='row mt-15'>
                    <div className='col-xs-12'>
                        <ColourPicker labelKey='' disableAlpha={true} presetColors={colours} colourHex={colour} onColourChanged={color => this.setState({ colour: color })} />
                    </div>
                </div>

                <div className='row mt-15'>
                    <div className='col-xs-12'>
                        <ct.Checkbox id='archived' labelKey='Global:archived' value={archived} callback={v => this.setState({ archived: this.validateArchived(v) })} />
                    </div>
                </div>

                <div className='row mt-20'>
                    <div className='vol-xs-12'>
                    </div>
                </div>

                <div className='btn-toolbar'>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)}>{this.context.t('Global:update')}</button>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, this.props.close)}>{this.context.t('Global:close')}</button>
                </div>
            </form>
        </div>
    }
}


const mapStateToProps = (state: ApplicationState) => ({
    isSaving: state.tags.isSaving,
    saveComplete: state.tags.saveComplete,
    saveError: state.tags.saveError,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    saveTag: bindActionCreators(TagActions.actionCreators.saveTag, dispatch)
});

// Wire up the React component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(TagPage);
