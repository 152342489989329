
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { ApplicationState, reducers, rootEpic } from './';
import { createEpicMiddleware } from 'redux-observable';
import { signalRMiddleware, initializeSignalR } from './signalrClient';
import { i18nState } from "redux-i18n"

export default function configureStore(history: History, initialState?: ApplicationState) {

    const epicMiddleware = createEpicMiddleware();

    const middleware = [
        epicMiddleware,
        signalRMiddleware,
        /*logger,*/
        routerMiddleware(history)
    ];

    const rootReducer = combineReducers({
        ...reducers,
        i18nState,
        router: connectRouter(history)
    });

    const enhancers = [];
    const windowIfDefined = typeof window === 'undefined' ? null : window as any;
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    var store = createStore(
        rootReducer,
        initialState as any,
        compose(applyMiddleware(...middleware), ...enhancers)
    );

    epicMiddleware.run(rootEpic);
    initializeSignalR(store);

    return store;
}
