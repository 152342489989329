
import { Action } from 'redux';
import { LoginActions } from './pages/login/actions';
import { LoginActionTypes } from './pages/login/types';


export const createReducer = <TState>(state: TState, incomingAction: Action, defaultState: TState, handler: (state: TState, incomingAction: Action) => TState) => {
    const action = incomingAction as LoginActions;

    if (action && action.type === LoginActionTypes.LoggedOut) {
        return defaultState;
    }

    return handler(state, incomingAction);
}