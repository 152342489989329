
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { ITranslationContext } from '../../../../translations';
import { ActivityFormat, ActivityFormatLink } from '../../../../store/pages/activityFormats/types';
import ActivityFormatAdditionalActivity from './activityFormatAdditionalActivity';
import { clickHandler } from '../../../../utils/util';
import { Product } from '../../../../store/pages/products/types';

interface ActivityFormatAdditionalActivitiesProps {
    activityFormats: ActivityFormat[];
    products: Product[];
    linkedActivityFormats: ActivityFormatLink[];
    editLinkedActivityFormat: (linkedActivityFormat: ActivityFormatLink) => void;
    addLinkedActivityFormat: () => void;
    removeLinkedActivityFormat: (key: string) => void;
}


const ActivityFormatAdditionalActivities = (props: ActivityFormatAdditionalActivitiesProps, context: ITranslationContext) => {
    const { t } = context;
    const { linkedActivityFormats, addLinkedActivityFormat } = props;

    return (
        <div>
            {linkedActivityFormats.length > 0 ? renderLinkedActivities(props, context) : null}
            <div><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, addLinkedActivityFormat)}>{t('ActivityFormatForm:addAdditionalActivity')}</button></div>

            {renderErrors(linkedActivityFormats, context)}
        </div>
    )
}

const renderErrors = (linkedActivityFormats: ActivityFormatLink[], context: ITranslationContext) => {
    const { t } = context;

    var upsoldActivities = linkedActivityFormats.filter(a => a.offerAsUpsell);
    var alternativeActivities = linkedActivityFormats.filter(a => a.offerAsAlternative);

    if (upsoldActivities.length > 10 || alternativeActivities.length > 10) {
        return <div className='alert alert-danger'>
            {alternativeActivities.length > 10 ? <div>{t('ActivityFormatAdditionalActivities:maxAlternateActivitiesExceeded')}</div> : null }
            {upsoldActivities.length > 10 ? <div>{t('ActivityFormatAdditionalActivities:maxUpsellActivitiesExceeded')}</div> : null}
        </div>
    }

    return null;
}

const renderLinkedActivities = (props: ActivityFormatAdditionalActivitiesProps, context: ITranslationContext) => {
    const { t } = context;
    const { activityFormats, products, linkedActivityFormats, editLinkedActivityFormat, removeLinkedActivityFormat } = props;

    return (
        <table className="table table-super-condensed table-borderless" style={{ width: 'auto' }}>
            <thead>
                <tr key='top-header'>
                    <th colSpan={3}></th>
                    <th colSpan={2} className='center'>{t('ActivityFormatAdditionalActivities:gapBefore')}</th>
                    <th colSpan={2} className='center'>{t('ActivityFormatAdditionalActivities:gapAfter')}</th>
                    <th colSpan={2} className='center'>{t('ActivityFormatAdditionalActivities:maxUpsellSlots')}</th>
                </tr>
                <tr key='second-header'>
                    <th className='center'>{t('ActivityFormatAdditionalActivities:activity')}</th>
                    <th className='center'>{t('ActivityFormatAdditionalActivities:alternateActivity')}</th>
                    <th className='center'>{t('ActivityFormatAdditionalActivities:upsellActivity')}</th>
                    <th className='center'>{t('ActivityFormatAdditionalActivities:min')}</th>
                    <th className='center'>{t('ActivityFormatAdditionalActivities:max')}</th>
                    <th className='center'>{t('ActivityFormatAdditionalActivities:min')}</th>
                    <th className='center'>{t('ActivityFormatAdditionalActivities:max')}</th>
                    <th className='center'>{t('ActivityFormatAdditionalActivities:maxBefore')}</th>
                    <th className='center'>{t('ActivityFormatAdditionalActivities:maxAfter')}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {
                    linkedActivityFormats.map(la => <ActivityFormatAdditionalActivity
                        key={la.key}
                        activityFormats={activityFormats}
                        linkedActivityFormat={la}
                        editLinkedActivityFormat={editLinkedActivityFormat}
                        removeLinkedActivityFormat={removeLinkedActivityFormat}  />)
                }
            </tbody>
        </table>
    )
}

ActivityFormatAdditionalActivities.contextTypes = {
    t: PropTypes.func
}

export default ActivityFormatAdditionalActivities;
