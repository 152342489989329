
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as ft from './types'; 
import * as lt from '../login/types';
import { IStore } from '../..';


export type FeeActions = ft.LoadFees | ft.ReceiveFees | ft.SaveFee | ft.FeeSaved | ft.FeeSaveFailed | ft.EditFee;

export const actionCreators = {
    loadFees: () => ({ type: ft.FeeActionTypes.RequestFees }),
    loadFeesComplete: (fees: ft.Fee[], err: api.ApiError | null) => ({ type: ft.FeeActionTypes.ReceivedFees, fees: fees, error: err }),
    editFee: () => ({ type: ft.FeeActionTypes.EditFee }),
    saveFee: (isNew: boolean, feeId: string | null, fee: ft.Fee) => ({ type: ft.FeeActionTypes.SaveFee, isNew: isNew, feeId: feeId, fee: fee }),
    feeSaved: (feeId: string) => ({ type: ft.FeeActionTypes.FeeSaved, feeId: feeId }),
    feeSaveFailed: (error: api.ApiError) => ({ type: ft.FeeActionTypes.FeeSaveFailed, error: error }),
}

interface IGetFeesResponse {
    fees: ft.Fee[];
}

interface ISaveFeeResponse {
    feeId: string;
}

export const handleClientChange = (store: IStore) => [actionCreators.loadFees]

//https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-after-catching-and-processing-an-error-with-emitting-somethi

const loadFees = () => Observable.defer(() => api.getJson<IGetFeesResponse>(`api/v1/fee/`))
    .map(response => actionCreators.loadFeesComplete(response.fees, null));

export const loadFeesEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        ft.FeeActionTypes.RequestFees,
        action => loadFees(),
        err => actionCreators.loadFeesComplete([], err));

export const reloadFeesEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        ft.FeeActionTypes.FeeSaved,
        action => loadFees(),
        err => actionCreators.loadFeesComplete([], err));

export const saveFeeEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        ft.FeeActionTypes.SaveFee,
        action => {
            const spa = action as ft.SaveFee;
            let feeId = spa.feeId;
            var { id, ...body } = spa.fee;

            return (spa.isNew ? api.post('api/v1/fee/', body) : api.put(`api/v1/fee/${feeId}`, body))
                .map(response => {
                    if (spa.isNew) {
                        const strr = response.response as ISaveFeeResponse;
                        if (strr) {
                            feeId = strr.feeId;
                        }
                    }

                    return ({ type: ft.FeeActionTypes.FeeSaved, feeId: feeId });
                });
        },
        (err: api.ApiError) => actionCreators.feeSaveFailed(err));
