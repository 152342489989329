
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { ITranslationContext } from '../../../../translations';
import { AfProduct, ActivityCustomerCategory } from './activityFormatForm';
import { Product } from '../../../../store/pages/products/types';
import ActivityFormatProduct from './activityFormatProduct';
import { clickHandler } from '../../../../utils/util';

interface ActivityFormatProductsProps {
    activityFormatProducts: AfProduct[];
    activityCustomerCategories: ActivityCustomerCategory[];
    products: Product[]
    productChanged: (key: string, val: string) => void;
    customerCategoriesChanged: (key: string, categories: string[]) => void
    enableForWebBookingsChanged: (key: string, val: boolean) => void;
    addProduct: () => void;
    removeProduct: (key: string) => void;
}

const ActivityFormatProducts = (props: ActivityFormatProductsProps, context: ITranslationContext) => {
    const { t } = context;
    const { activityFormatProducts, activityCustomerCategories, products, productChanged, customerCategoriesChanged, enableForWebBookingsChanged, addProduct, removeProduct } = props;
    const categories = activityCustomerCategories.filter(c => c.enabled.value);

    var categoriesWithMoreThanOneWebProduct = categories.filter(c => activityFormatProducts.filter(fp => fp.enableForWebBookings.value && fp.customerCategories.includes(c.customerCategoryId)).length > 1);

    return (
        <div className='activity-format-products'>
            <table className='table table-super-condensed table-borderless' style={({ width: 'auto' })}>
                <thead>
                    <tr>
                        <th>{t('ActivityFormatForm:product')}</th>
                        {
                            categories.map(c => <th key={c.customerCategoryId}>{c.categoryName}</th>)
                        }
                        <th className='text-right'>{t('ActivityFormatForm:enableForWeb')}</th>
                        <th className='text-center' colSpan={2}>{t('ActivityFormatForm:pricing')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {activityFormatProducts.map(p => <ActivityFormatProduct key={p.key} activityCustomerCategories={activityCustomerCategories} products={products} product={p} productIdChanged={productChanged} customerCategoriesChanged={customerCategoriesChanged} enableForWebBookingsChanged={enableForWebBookingsChanged} removeProduct={removeProduct} />)}
                </tbody>
            </table>
            <div><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, addProduct)}>{t('ActivityFormatForm:addProduct')}</button></div>

            {categoriesWithMoreThanOneWebProduct.length > 0 ? <div className='alert alert-danger'>{t('ActivityFormatForm:categoriesWithMultipleWebProducts', { categories: categoriesWithMoreThanOneWebProduct.map(c => c.categoryName).join(',') })}</div> : null}
            
        </div>
    );
}

ActivityFormatProducts.contextTypes = {
    t: PropTypes.func
}
export default ActivityFormatProducts;