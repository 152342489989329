
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { ITranslationContext } from '../../../translations';
import { MarketingCampaignFilterGroupType, MarketingCampaignFilterUnit } from '../../../store/pages/campaigns/types';
import { FilterGroup } from './types';
import CampaignFilter from './campaignFilter';
import { clickHandler } from '../../../utils/util';
import { ActivityFormat } from '../../../store/pages/activityFormats/types';
import { Tag } from '../../../store/pages/tags/types';
import { CustomerCategory } from '../../../store/pages/customerCategories/types';
import { Venue } from '../../../store/pages/venues/types';
import { MembershipType } from '../../../store/pages/memberships/types';

export interface CampaignFilterGroupProps {
    group: FilterGroup;
    level: number;
    venues: Venue[];
    selectedVenueIds: string[];
    activityFormats: ActivityFormat[];
    membershipTypes: MembershipType[];
    customerTags: Tag[];
    customerCategories: CustomerCategory[];
    addFilterToGroup: (parentGroupKey: string, type: MarketingCampaignFilterGroupType) => void;
    addSubFilterGroup: (parentGroupKey: string, filterKey: string, type: MarketingCampaignFilterGroupType) => void;
    onFilterTypeChanged: (key: string, val: string) => void;
    onFilterComparisonChanged: (key: string, val: string) => void;
    onfilterValueChanged: (key: string, val: string) => void;
    onfilterValue2Changed: (key: string, val: string) => void;
    onFilterValuesChanged: (key: string, val1: string, val2: string | null) => void;
    onFilterUnitChanged: (key: string, unit: MarketingCampaignFilterUnit) => void;
    removeFilter: (groupKey: string, filterKey: string) => void;
}

const CampaignFilterGroup = (props: CampaignFilterGroupProps, context: ITranslationContext) => {
    const { t } = context;
    const { group, venues, selectedVenueIds, activityFormats, membershipTypes, customerTags, customerCategories } = props;
    const { level, addFilterToGroup, addSubFilterGroup, onFilterTypeChanged, onFilterComparisonChanged, onfilterValueChanged, onfilterValue2Changed, onFilterValuesChanged, onFilterUnitChanged, removeFilter } = props;

    return <div className='campaign-filter-group'>
        {level === 0 ? <div className='campaign-filter-group-left-border'></div> : null}
        <div className='campaign-filter-group-content'>
            {group.filters.map((f, ix) =>
                <CampaignFilter
                    key={f.key}
                    level={level}
                    index={ix}
                    filter={f}
                    parentGroup={group}
                    owningGroupKey={group.key}
                    venues={venues}
                    selectedVenueIds={selectedVenueIds}
                    activityFormats={activityFormats}
                    membershipTypes={membershipTypes}
                    customerTags={customerTags}
                    customerCategories={customerCategories}
                    addFilterToGroup={addFilterToGroup}
                    addSubFilterGroup={addSubFilterGroup}
                    onFilterTypeChanged={onFilterTypeChanged}
                    onFilterComparisonChanged={onFilterComparisonChanged}
                    onfilterValueChanged={onfilterValueChanged}
                    onfilterValue2Changed={onfilterValue2Changed}
                    onFilterValuesChanged={onFilterValuesChanged}
                    onFilterUnitChanged={onFilterUnitChanged}
                    removeFilter={removeFilter}
                />)
            }

            <div key={`${group.key}_actions`} className='flex flex-row'>
                {level === 0
                    ? <button className='btn btn-link' onClick={e => clickHandler(e, () => addFilterToGroup(group.key, MarketingCampaignFilterGroupType.And))}>{t('CampaignPage:addAndFilter')}</button>
                    : level === 1 ? <button className='btn btn-link' onClick={e => clickHandler(e, () => addFilterToGroup(group.key, MarketingCampaignFilterGroupType.Or))}>{t('CampaignPage:addOrFilter')}</button>
                        : null}
            </div>
        </div>
        {/*level === 0 ? <div className='campaign-filter-group-right-border'></div> : null*/}
    </div>
}

CampaignFilterGroup.contextTypes = {
    t: PropTypes.func
}

export default CampaignFilterGroup;