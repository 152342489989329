
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../../global/controls';
import { AfProduct, ActivityCustomerCategory } from './activityFormatForm';
import { Product, ProductFixedPrice, ProductPricingMode } from '../../../../store/pages/products/types';
import { clickHandler } from '../../../../utils/util';
import { ITranslationContext } from '../../../../translations';
import CurrencyAmount from '../../../global/currencyAmount';

interface ActivityFormatProductProps {
    products: Product[];
    activityCustomerCategories: ActivityCustomerCategory[];
    product: AfProduct;
    productIdChanged: (key: string, val: string) => void;
    customerCategoriesChanged: (key: string, categories: string[]) => void;
    enableForWebBookingsChanged: (key: string, val: boolean) => void;
    removeProduct: (key: string) => void;
}

const ActivityFormatProduct = (props: ActivityFormatProductProps, context: ITranslationContext) => {
    const { t } = context;
    const { products, activityCustomerCategories, product, removeProduct, productIdChanged } = props;
    const { key, productId, customerCategories } = product;

    const productChanged = (val: string) => productIdChanged(product.key, val);
    const customerCategoryStateChanged = (customerCategoryId: string, val: boolean) => props.customerCategoriesChanged(key, val ? customerCategories.concat(customerCategoryId) : customerCategories.filter(c => c !== customerCategoryId))
    const enableForWebBookingsChanged = (val: boolean) => props.enableForWebBookingsChanged(product.key, val);

    const inlineControlStyle = ({ minHeight: '10px', marginRight: '10px' });
    const productOptions = [{ key: 'no_product', name: t('ActivityFormatForm:noProduct') }].concat(products.map(p => ({ key: p.id, name: p.name })));
    const prod = products.find(p => p.id === productId.value);

    const selectedCustomerCategories = activityCustomerCategories.filter(c => c.enabled.value);

    const formatPrice = (product: Product | undefined) => {
        if (!product) return <span></span>

        const basePrice = product.pricing.filter(p => p.effectiveFrom <= new Date() && (p.effectiveTo == null || p.effectiveTo >= new Date()) && p.applyPriceOnDays === 0)[0]
        return basePrice ? <CurrencyAmount amount={product.pricingMode === ProductPricingMode.Fixed ? basePrice.fixedPricing.map(p => p.price)[0] : basePrice.unitPrice} /> : null
    }

    return (
        <tr key={key}>
            <td style={({ verticalAlign: 'top' })}><ct.Select id={`${key}_product`} labelKey='' value={({ ...productId, value: productId.value || '' })} callback={productChanged} options={productOptions} style={inlineControlStyle} /></td>
            {
                selectedCustomerCategories.map(c => <td key={`${key}_${c.customerCategoryId}`}>
                    <ct.Checkbox id={`${key}_${c.customerCategoryId}`} labelKey='' value={ct.asFormValue(`${key}_${c.customerCategoryId}`, customerCategories.findIndex(cc => cc === c.customerCategoryId) >= 0)} callback={val => customerCategoryStateChanged(c.customerCategoryId, val)} style={({ ...inlineControlStyle, maxWidth: '100px', minWidth: '50px' })} minimal={true} />
                </td>)
            }
            <td className='text-center' style={({ verticalAlign: 'top' })}><ct.Checkbox id={`${key}_enableForWeb`} labelKey='' value={product.enableForWebBookings} callback={enableForWebBookingsChanged} style={({ ...inlineControlStyle, minWidth: '50px' })} minimal={true} /></td>
            <td>{t(`ProductPricingMode:${ProductPricingMode[prod ? prod.pricingMode : ProductPricingMode.PerUnit]}`)}</td>
            <td><div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>{prod ? formatPrice(prod) : null}</div></td>
            <td><span onClick={e => clickHandler(e, () => removeProduct(product.key))} className='glyphicon glyphicon-trash red' style={({ cursor: 'pointer', padding: '5px' })}></span></td>
        </tr>
    );
}

ActivityFormatProduct.contextTypes = {
    t: PropTypes.func
}

export default ActivityFormatProduct;