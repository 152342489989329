
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'

import { ApplicationState } from '../../../store';
import * as ct from '../../global/controls';

import * as api from '../../../store/apiClient';
import * as AffiliateActions from '../../../store/pages/affiliates/actions';
import * as ModalActions from '../../../store/global/modal/actions';

import { Affiliate } from '../../../store/pages/affiliates/types';

import AffiliateForm from './affiliateForm';
import Loading from '../../global/loading';
import { clickHandler } from '../../../utils/util';
import ApiError from '../../global/apiError';


interface LocalProps { }

interface LocalState {
    affiliates: Affiliate[];
    isLoading: boolean;
    loadError: api.ApiError | null;
}

interface Actions {
    editAffiliate: () => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
}

type RoutedLocalProps = LocalProps & RouteComponentProps<{}>;

type AffiliatePageProps = LocalState & Actions & RoutedLocalProps;

interface AffiliatePageState {
    showArchived: ct.FormValue<boolean>;
}

class AffiliatesPage extends React.Component<AffiliatePageProps, AffiliatePageState> {

    constructor(props: AffiliatePageProps) {
        super(props);

        this.state = { showArchived: ct.asFormValue('showArchived', false) }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private editAffailiate = (affiliate: Affiliate) => {
        this.showOverlay(affiliate, false);
    }

    private addAffailiate = () => {
        this.showOverlay(null, true);
    }

    private showOverlay = (affiliate: Affiliate | null, addingNew: boolean) => {
        this.props.editAffiliate();
        this.props.showModal(<AffiliateForm isNew={addingNew} affiliate={affiliate ? affiliate : null} />, 'AffiliateForm');
    }

    render() {

        const { isLoading, loadError } = this.props;
        const { showArchived } = this.state;
        const { t } = this.context;

        const body = isLoading ? <Loading /> : this.renderAffiliatesTable();

        return <section className='affiliatesPage'>
            <header className='section-header'>
                <div className='page-heading'>
                    <h1 className='affiliates_title'>{t('AffiliatesPage:title')}</h1>
                </div>
                <div className='section-actions right'>
                    <ct.Checkbox id='showArchived' labelKey='Global:showArchived' value={showArchived} callback={val => this.setState({ showArchived: ct.asFormValue('showArchived', val) })} style={({ display: 'inline-block' })} />
                    <button className='btn btn-info' onClick={e => clickHandler(e,  this.addAffailiate)}>{t('AffiliatesPage:addAffiliate')}</button>
                </div>
            </header>
            {body}
            <ApiError error={loadError} />
        </section>;
    }
    
    private renderAffiliatesTable() {
        const { t } = this.context;
        const { showArchived } = this.state;
        const affiliates = this.props.affiliates.filter(a => (showArchived.value || !a.archived)).map(a =>
            <tr key={a.id}>
                <td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.editAffailiate(a))}>{a.name}</button></td>
                <td>{a.code}</td>
                <td>{a.emailAddress}</td>
                {showArchived.value ? <td className='text-center'>{a.archived ? t('Global:yes') : null}</td> : null}
            </tr>
        );

        return <table className='table table-condensed'>
            <thead>
                <tr key='affiliates-header'>
                    <th>{t('AffiliatesPage:nameHeading')}</th>
                    <th>{t('AffiliatesPage:codeHeading')}</th>
                    <th>{t('AffiliatesPage:emailHeading')}</th>
                    {showArchived.value ? <th className='text-center'>{t('Global:archived')}</th> : null}
                </tr>
            </thead>
            <tbody>
                {affiliates}
            </tbody>
        </table>;
    }
};


const matStateToProps = (state: ApplicationState) => ({
    affiliates: state.affiliates.affiliates,
    isLoading: state.affiliates.isLoading,
    loadError: state.affiliates.loadError
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    editAffiliate: bindActionCreators(AffiliateActions.actionCreators.editAffiliate, dispatch),
    showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    matStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps        // Selects which action creators are merged into the component's props
)(AffiliatesPage);

