
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../../global/controls';
import { ITranslationContext } from '../../../../translations';
import ActivityFormatCustomerCategory from './activityFormatCustomerCategory';
import { CustomerCategory } from '../../../../store/pages/customerCategories/types';
import { ActivityCustomerCategory } from './activityFormatForm';


interface ActivityFormatCustomerCategoriesProps {
    customerCategories: CustomerCategory[];
    activityCustomerCategories: ActivityCustomerCategory[];
    enableMultipleCategoriesPerCustomer: boolean;
    allowMultipleCategoriesPerCustomer: ct.FormValue<boolean>;
    customerCategoryEnabledChanged: (customerCategoryId: string, enabled: boolean) => void;
    placesToBookChanged: (customerCategoryId: string, val: number) => void;
    quantitiesChanged: (customerCategoryId: string, min: number, bookingMax: number, totalMax: number) => void;
    allowMultipleCategoriesPerCustomerChanged: (val: boolean) => void
}

const ActivityFormatCustomerCategories = (props: ActivityFormatCustomerCategoriesProps, context: ITranslationContext) => {
    const { t } = context;
    const { activityCustomerCategories, customerCategoryEnabledChanged, placesToBookChanged, quantitiesChanged,
        enableMultipleCategoriesPerCustomer, allowMultipleCategoriesPerCustomer, allowMultipleCategoriesPerCustomerChanged } = props;

    const cellStyle: React.CSSProperties = { padding: '0 6px'}

    return (
        <div className='activity-format-variations'>
            {
                enableMultipleCategoriesPerCustomer
                    ? <ct.Checkbox
                        id='enableMultipleCategoriesPerCustomer'
                        labelKey='ActivityFormatForm:allowMultipleCategoriesPerCustomer'
                        maxWidth={500}
                        value={allowMultipleCategoriesPerCustomer}
                        callback={allowMultipleCategoriesPerCustomerChanged} />
                : null
            }
            <table>
                <thead>
                    <tr key='top_heading'>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th colSpan={2} className='text-center'>{t('ActivityFormatForm:bookingHeading')}</th>
                        <th></th>
                    </tr>
                    <tr key='second_level_heading'>
                        <th></th>
                        <th>{t('ActivityFormatForm:customerCategoryEnabled')}</th>
                        <th>{t('ActivityFormatForm:customerCategoryPlaces')}</th>
                        <th className='text-center'>{t('ActivityFormatForm:minPerBooking')}</th>
                        <th className='text-center'>{t('ActivityFormatForm:maxPerBooking')}</th>
                        <th className='text-center'>{t('ActivityFormatForm:maxOverall')}</th>
                    </tr>
                </thead>
                <tbody>
                    {activityCustomerCategories.map(c => <ActivityFormatCustomerCategory key={c.customerCategoryId} activityCustomerCategory={c} customerCategoryEnabledChanged={customerCategoryEnabledChanged} placesToBookChanged={placesToBookChanged} quantitiesChanged={quantitiesChanged} />)}
                </tbody>
            </table>
            {activityCustomerCategories.reduce((max, c) => Math.max(max, c.placesToBook.value), 0) > 1 ? <div className='alert alert-warning'>{t('ActivityFormatForm:placesToBookGreaterThan1Warning')}</div> : null}
            <div className='alert alert-info'>
                <div>
                    <div><strong>{t('ActivityFormatForm:customerCategoryPlaces')}</strong></div>
                    <div style={{paddingLeft: '15px'}}>{t('ActivityFormatForm:customerCategoryPlacesDescription')}</div>
                </div>
                <div>
                    <div><strong>{t('ActivityFormatForm:bookingHeading')}</strong></div>
                    <div style={{ paddingLeft: '15px' }}>{t('ActivityFormatForm:bookingHeadingDescription')}</div>
                </div>
                <div>
                    <div><strong>{t('ActivityFormatForm:maxOverall')}</strong></div>
                    <div style={{ paddingLeft: '15px' }}>{t('ActivityFormatForm:maxOverallDescription')}</div>
                </div>
            </div>
        </div>
    );
}

ActivityFormatCustomerCategories.contextTypes = {
    t: PropTypes.func
}
export default ActivityFormatCustomerCategories;