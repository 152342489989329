
import * as PropTypes from 'prop-types'
import { ITranslationContext } from "../../translations";
import { clickHandler } from '../../utils/util';

interface ProductsReportDetailsTableProps {
    headerKey: string;
    selected: boolean;
    direction: 1 | -1;
    colSpan?: number | undefined;
    select: () => void;
}

const ClickableHeader = (props: ProductsReportDetailsTableProps, context: ITranslationContext) => {
    const { t } = context;
    const { selected, direction, headerKey, colSpan,  select } = props;

    return <th style={{ cursor: 'pointer' }} onClick={e => clickHandler(e, select)} colSpan={colSpan}>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'center', justifyContent: 'space-around' }}>
            {t(headerKey)}
            {selected ? < span className={direction === 1 ? 'glyphicon glyphicon-arrow-up' : 'glyphicon glyphicon-arrow-down'} style={{ fontSize: '11px', color: 'dodgerblue', float: 'right' }} /> : null}
        </div>
    </th>
}

ClickableHeader.contextTypes = {
    t: PropTypes.func
}

export default ClickableHeader;