import { AnalyticsBucketResolution } from "../../../../store/pages/analytics/types";
import { DateFormat } from "../../../../store/pages/venues/types";
import { monthNameAbbreviations } from "../../../../utils/extensions";


export const formatComparison = (thisPeriodValue: number, comparisonPeriodValue: number | null): JSX.Element => {
    if (comparisonPeriodValue !== null && !isNaN(comparisonPeriodValue) && thisPeriodValue > 0) {
        const percentage = 100 - ((comparisonPeriodValue / thisPeriodValue) * 100);
        const isIncrease = percentage > 0;
        const colourClass = isIncrease ? 'positive' : 'negative'
        return <span className={ colourClass }> { isIncrease? '+': '' }{ percentage.toFixed(2) }% </span>
    }
    return <span></span>
}

export const formatXAxisTick = (val: any, resolution: AnalyticsBucketResolution, dateFormat: DateFormat, t: (val: string) => string) => {
    if (val instanceof Date) {
        return resolution === AnalyticsBucketResolution.Month
            ? `${t(monthNameAbbreviations[val.getMonth()])} ${val.getFullYear()}`
            : val.toAbbrMonthDayString(dateFormat, t)
    }

    return val;
}

export const formatXAxisTickWithDay = (val: any, resolution: AnalyticsBucketResolution, dateFormat: DateFormat, t: (val: string) => string) => {
    if (val instanceof Date) {
        return resolution === AnalyticsBucketResolution.Month
            ? `${t(monthNameAbbreviations[val.getMonth()])} ${val.getFullYear()}`
            : `${val.toAbbrDayString(t)} ${val.toAbbrMonthDayString(dateFormat, t)}`
    }

    return val;
}

export const formatYAxisCurrencyTick = (val: any, culture: string, currency: string) => {
    if (typeof (val) === "number") {
        return val.toLocaleString(culture, { style: 'currency', currency: currency, maximumFractionDigits: 0 })
    }
    return val;
}