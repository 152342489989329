
import '../../../css/reactdatetime.css';
import '../../../css/rangeSlider.css';

import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../../../store';
import * as PropTypes from 'prop-types'

import Datetime from 'react-datetime';
import moment from 'moment';

import * as api from '../../../store/apiClient';

import { isNullOrEmpty, clickHandler } from '../../../utils/util';
import { DateFormat, TimeFormat, Venue } from '../../../store/pages/venues/types';
import { Resource } from '../../../store/pages/resources/types';
import * as ct from '../../global/controls';  
import * as dt from '../../../store/pages/diary/types';
import * as ActivityFormatActions from '../../../store/pages/activityFormats/actions';
import * as VenueActions from '../../../store/pages/venues/actions';
import * as ResourceActions from '../../../store/pages/resources/actions';
import * as DiaryActions from '../../../store/pages/diary/actions';
import * as ModalActions from '../../../store/global/modal/actions';
import * as LoginActions from '../../../store/pages/login/actions';
import * as UserPreferenceActions from '../../../store/global/preferences/actions';
import { IScrollPosition } from '../../global/scrollViewer';
import EditDiaryNotes from './editDiaryNotes';
import EditOpeningTimes from './editOpeningTimes';
import { ActivityFormat } from '../../../store/pages/activityFormats/types';
import DiarySearch from './diarySearch';
import DiaryCalendar from './diaryCalendar';
import ResourceFilter from '../../global/resourceFilter';

interface DiaryPageState {
    showCancelled: boolean;
}

interface LocalProps {
}

type RoutedLocalProps = LocalProps & RouteComponentProps<{}>;

interface LocalState {
    reservations: dt.DiaryReservation[];
    openingTimes: dt.OpeningTimes[];
    venue: Venue | null;
    venues: Venue[];
    selectedVenueId: string;
    slotSizeInMinutes: number;
    resources: Resource[];
    activityFormats: ActivityFormat[];
    activityFormatsLoading: boolean;
    date: moment.Moment;
    scrollPosition: IScrollPosition;
    notes: dt.DiaryNote | null;
    isSavingDiaryNotes: boolean;
    diaryNoteSaveError: api.ApiError | null;
    timeFormat: TimeFormat;
    hiddenResources: string[];
}

interface Actions {
    loadDiaryReservations: (venueId: string, date: Date) => void;
    loadVenues: () => void;
    loadResources: () => void;
    loadActivityFormats: () => void;
    loadNotes: (venueId: string, date: Date) => void;
    switchDate: (date: Date) => void;
    setDiaryResolution: (slotSizeInMinutes: number) => void;
    setScrollPosition: (top: number, left: number) => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
    logout: () => void;
}

// At runtime, Redux will merge together...
type DiaryPageProps = LocalState & Actions & RoutedLocalProps;

class DiaryPage extends React.Component<DiaryPageProps, DiaryPageState> {

    constructor(props: DiaryPageProps) {
        super(props);

       
        this.state = { showCancelled: false };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    loadDiaryReservations = (venue: Venue) => {
        const { loadDiaryReservations, date } = this.props;
        if (venue) {
            loadDiaryReservations(venue.id, date.toDate());
        }
    }

    componentDidUpdate(prevProps: DiaryPageProps) {
        const { venues, selectedVenueId, date, loadNotes } = prevProps;
        const { venues: nextVenues, selectedVenueId: nextSelectedVenueId, date: nextDate, notes: nextNotes, venue } = this.props;

        const venueChanged = nextVenues.length > 0 && (venues.length !== nextVenues.length || selectedVenueId !== nextSelectedVenueId);
        const dateChanged = nextDate.year() !== date.year() || nextDate.month() !== date.month() || nextDate.date() !== date.date();

        if (venueChanged && venue) {
            this.loadDiaryReservations(venue);
        }

        if ((venueChanged || dateChanged) && !isNullOrEmpty(nextSelectedVenueId) && !nextNotes) {
            loadNotes(nextSelectedVenueId, nextDate.toDate());
        }
    }

    componentDidMount() {
        const { venue, date, loadNotes } = this.props;
        if (venue) {
            this.loadDiaryReservations(venue);
            loadNotes(venue.id, date.toDate());
        }
    }

    addReservation = (resource: Resource, startTime: Date, endTime: Date) => {
        const { history, selectedVenueId } = this.props;
        history.push({ pathname: `/diary/${selectedVenueId}/event/new`, state: { resource: resource, startTime: startTime, endTime: endTime } });
    }

    editReservation = (reservationId: string, eventId: string) => {
        const { history, selectedVenueId } = this.props;
        history.push({ pathname: `/diary/${selectedVenueId}/event/${eventId}` });
    }

    dateChanged = (date: React.ChangeEvent<any> | moment.Moment | string) => {

        if (moment.isMoment(date)) {
            this.props.switchDate(date.toDate());
        }
    }

    onSlotSizeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = parseInt(e.target.value);
        if (!isNaN(val)) {
            const { slotSizeInMinutes } = this.props;
            // 25 min slots don't render correctly as we can't hit exact hour points, so need to skip 
            // and go straight to 20 or 30 min slots depnding if we are going up or down
            const newSize = val <= 20 ? val : slotSizeInMinutes === 30 ? 20 : 30;
            this.props.setDiaryResolution(newSize);
        }
    }
     
    goBackOneDay = () => this.dateChanged(this.props.date.clone().subtract(1, 'd'));

    goForwardOneDay = () => this.dateChanged(this.props.date.clone().add(1, 'd'));

    render() {
        const { selectedVenueId, slotSizeInMinutes, date, resources, reservations, openingTimes, activityFormats, scrollPosition, setScrollPosition, venue, hiddenResources, logout } = this.props;
        const { showCancelled } = this.state;
        const { t } = this.context;

        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;

        const todayButton = moment().isSame(date, 'day') ? null : <button className='btn btn-default' onClick={e => clickHandler(e, () => this.dateChanged(moment()))} style={({ marginLeft: '12px' })} title={t('Global:today')}>{t('Global:today')}</button>;

        return <section className='diary-page'>
            <div className='diary-page-content'>
                <div className='diary-page-header'>
                    <div className='diary-page-header-date'>
                        <button onMouseUp={e => clickHandler(e, this.goBackOneDay)} onKeyUp={e => clickHandler(e, this.goBackOneDay)} title={t('DiaryPage:prevDay')}><i className="glyphicon glyphicon-chevron-left"></i></button>
                        <Datetime value={this.props.date} onChange={this.dateChanged} timeFormat={false} className='date-picker' closeOnSelect={true} dateFormat={dateFormat === DateFormat.MDY ? "dddd MMMM Do YYYY" : "dddd D MMMM YYYY"} locale={moment.locale()} />
                        <button onMouseUp={e => clickHandler(e, this.goForwardOneDay)} onKeyUp={e => clickHandler(e, this.goForwardOneDay)} title={t('DiaryPage:nextDay')}><i className="glyphicon glyphicon-chevron-right"></i></button>
                        {todayButton}
                        <div style={({ display: 'inline-block', marginTop: '8px' })}>
                            <input type='range' min='5' max='30' step='5' value={slotSizeInMinutes} onChange={this.onSlotSizeChanged} style={({ display: 'inline-block', width: '100px', marginLeft: '15px' })}></input>
                            <span style={({ margin: '0 10px', display: 'inline-block' })}>{slotSizeInMinutes}m</span>
                        </div>
                        <div style={({ display: 'inline-block', marginTop: '8px' })}><ct.Checkbox id='showCancelled' labelKey='DiaryPage:showCancelled' value={ct.asFormValue('showCancelled', showCancelled)} callback={val => this.setState({ showCancelled: val })} /></div>
                        <button className='btn btn-info btn-sm' style={{ marginLeft: '6px' }} onClick={e => clickHandler(e, this.editOpeningTimes)}>{t('DiaryPage:editOpeningTimes')}</button>
                    </div>
                    <div className="section-actions" style={{ margin: '0', display: 'flex', alignItems: 'center', flex: '1 1 auto' }}>
                         <ResourceFilter />
                        <DiarySearch venueId={selectedVenueId} timeFormat={timeFormat} dateFormat={dateFormat} logout={logout} />
                    </div>
                </div>
                {this.renderDiaryNotes()}
                {venue ?
                    <DiaryCalendar
                        venue={venue}
                        slotSizeInMinutes={slotSizeInMinutes}
                        date={date}
                        showCancelled={showCancelled}
                        resources={resources.filter(r => !hiddenResources.includes(r.id))}
                        openingTimes={openingTimes}
                        reservations={reservations}
                        scrollPosition={scrollPosition}
                        setScrollPosition={setScrollPosition}
                        loadDiaryReservations={this.loadDiaryReservations}
                        addReservation={this.addReservation}
                        editReservation={this.editReservation}
                        logout={logout}
                    />
                    : null}
            </div>
        </section>;
    }
    
    editNotes = (e: React.MouseEvent<HTMLDivElement>) => {
        const { venue, showModal, notes, date } = this.props;
        const noteText = notes ? notes.text : '';

        e.preventDefault();

        if (venue) {
            showModal(<EditDiaryNotes venue={venue} notes={noteText} date={date} />, 'EditDiaryNotes')
        }
    }

    editOpeningTimes = () => {
        const { selectedVenueId, showModal, date } = this.props;
        showModal(<EditOpeningTimes selectedVenueId={selectedVenueId} date={date}  />, 'EditOpeningTimes')
    }

    renderDiaryNotes = () => {
        const { notes } = this.props;
        const { t } = this.context;

        const noteContent = notes && !isNullOrEmpty(notes.text) ? notes.text : <div className='cal-notes-placeholder'>{t('DiaryPage:notesPlaceholder')}</div>; 

        return <div className='cal-notes' onClick={this.editNotes}>{noteContent}</div>;
    }
};

const matStateToProps = (state: ApplicationState) => {
    const venueId = state.venues.selectedVenueId;
    const venue = state.venues.venues.find(v => v.id === venueId);

    return {
        venue: venue ? venue : null,
        reservations: state.diary.reservations,
        openingTimes: state.diary.openingTimes,
        slotSizeInMinutes: state.preferences.slotSizeInMinutes,
        venues: state.venues.venues,
        selectedVenueId: state.venues.selectedVenueId,
        resources: state.resources.resources,
        activityFormats: state.activityFormats.activityFormats,
        activityFormatsLoading: state.activityFormats.isLoading,
        notes: state.diary.diaryNotes[`${state.venues.selectedVenueId}_${state.diary.date.toYMDDateString()}`],
        isSavingDiaryNotes: state.diary.isSavingDiaryNotes,
        diaryNoteSaveError: state.diary.diaryNoteSaveError,
        date: moment(state.diary.date),
        scrollPosition: state.diary.scrollPosition,
        hiddenResources: state.preferences.hiddenResources
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadDiaryReservations: bindActionCreators(DiaryActions.actionCreators.loadDiaryReservations, dispatch),
    loadVenues: bindActionCreators(VenueActions.actionCreators.loadVenues, dispatch),
    loadResources: bindActionCreators(ResourceActions.actionCreators.loadResources, dispatch),
    loadActivityFormats: bindActionCreators(ActivityFormatActions.actionCreators.loadActivityFormats, dispatch),
    loadNotes: bindActionCreators(DiaryActions.actionCreators.loadDiaryNote, dispatch),
    switchDate: bindActionCreators(DiaryActions.actionCreators.switchDate, dispatch),
    setScrollPosition: bindActionCreators(DiaryActions.actionCreators.setScrollPosition, dispatch),
    setDiaryResolution: bindActionCreators(UserPreferenceActions.actionCreators.setDiaryResolution, dispatch),
    showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
    logout: bindActionCreators(LoginActions.actionCreators.logout, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    matStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps                  // Selects which action creators are merged into the component's props
)(DiaryPage);
