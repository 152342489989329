
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../../../../store';
import * as PropTypes from 'prop-types'

import { BookingEmailScheduleConfig } from '../../../../store/pages/bookingEmailScheduleConfigs/types';
import Loading from '../../../global/loading';
import { clickHandler } from '../../../../utils/util';
import { ClientEmailTemplate } from '../../../../store/pages/emailTemplates/types';
import * as ModalActions from '../../../../store/global/modal/actions';
import BookingEmailScheduleConfigForm from './bookingEmailScheduleConfigForm';
import { Venue } from '../../../../store/pages/venues/types';


interface MappedState {
    isLoadingBookingEmailScheduleConfigs: boolean;
    bookingEmailScheduleConfigs: BookingEmailScheduleConfig[];
    isLoadingClientEmailTemplates: boolean;
    clientEmailTemplates: ClientEmailTemplate[];
}

interface MappedActions {
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
}

interface LocalProps {
    venue: Venue;
}

type BookingEmailScheduleConfigListProps = MappedState & MappedActions & LocalProps;

class BookingEmailScheduleConfigList extends React.Component<BookingEmailScheduleConfigListProps, {}> {

    static contextTypes = {
        t: PropTypes.func
    }

    private editBookingEmailScheduleConfig = (bookingEmailScheduleConfig: BookingEmailScheduleConfig) => {
        this.showOverlay(bookingEmailScheduleConfig, false);
    }

    private addBookingEmailScheduleConfig = () => {
        this.showOverlay(null, true);
    }

    private showOverlay = (bookingEmailScheduleConfig: BookingEmailScheduleConfig | null, addingNew: boolean) => {
        this.props.showModal(<BookingEmailScheduleConfigForm isNew={addingNew} venue={this.props.venue} bookingEmailScheduleConfig={bookingEmailScheduleConfig} />, 'BookingEmailSearchConfigForm');
    }

    isLoading = () => this.props.isLoadingBookingEmailScheduleConfigs || this.props.isLoadingClientEmailTemplates;

    render() {
        const body = this.isLoading() ? <Loading /> : this.renderBookingEmailScheduleConfigList();

        return <section>
            <header className='section-header'>
                <h4>{this.context.t('BookingEmailScheduleConfigList:title')}</h4>
            </header>
            <div className='at-panel sub-section-panel-240px'>
                {body}
            </div>
        </section>;
    }

    getSendValue = (timeOffset: number, t: (key: string, args?: any) => string) => {
        if (timeOffset === 0) {
            return t('BookingEmailScheduleConfigList:sendOnDayDue');
        } else if (timeOffset < 0) {
            return t('BookingEmailScheduleConfigList:sendDaysOverdue', { days: Math.abs(timeOffset) });
        } else {
            return t('BookingEmailScheduleConfigList:sendDaysBefore', { days: Math.abs(timeOffset) });
        }
    }

    private renderBookingEmailScheduleConfigList = () => {

        const { t } = this.context;
        const { venue, bookingEmailScheduleConfigs, clientEmailTemplates } = this.props;
        const { id: venueId } = venue;

        const configs = bookingEmailScheduleConfigs.filter(cfg => cfg.venueId === venueId && !cfg.archived).map(cfg => {
            const emailTemplate = clientEmailTemplates.find(e => e.venueId === venueId && e.clientEmailTemplateId === cfg.clientEmailTemplateId);
            return (
                <tr key={cfg.id}>
                    <td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.editBookingEmailScheduleConfig(cfg))}>{cfg.name}</button></td>
                    <td>{emailTemplate ? emailTemplate.name : ''}</td>
                    <td>{cfg.sendForManualBookings ? t('Global:yes') : null}</td>
                    <td>{cfg.sendForWebBookings ? t('Global:yes') : null}</td>
                    <td>{cfg.sendWhenPaidInFull ? t('Global:yes') : null}</td>
                    <td>{cfg.sendWhenAnyPayment ? t('Global:yes') : null }</td>
                    <td>{cfg.numberOfDaysBeforeEvent ? cfg.numberOfDaysBeforeEvent : ''}</td>
                </tr>
            )
        });

        return (
            <div>
                <table className='table table-condensed'>
                    <thead>
                        <tr>
                            <th>{t('Global:name')}</th>
                            <th>{t('BookingEmailScheduleConfigList:emailTypeHeading')}</th>
                            <th>{t('BookingEmailScheduleConfigList:manualEventsHeading')}</th>
                            <th>{t('BookingEmailScheduleConfigList:webEventsHeading')}</th>
                            <th>{t('BookingEmailScheduleConfigList:fullyPaidHeading')}</th>
                            <th>{t('BookingEmailScheduleConfigList:anyPaymentHeading')}</th>
                            <th>{t('BookingEmailScheduleConfigList:daysBeforeEvent')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {configs}
                    </tbody>
                </table>
                <button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, this.addBookingEmailScheduleConfig)}>{this.context.t('BookingEmailScheduleConfigList:addBookingEmailScheduleConfig')}</button>
            </div>
        );
    }
}


const mapStateToProps = (state: ApplicationState) => ({
    isLoadingBookingEmailScheduleConfigs: state.bookingEmailScheduleConfigs.isLoading,
    bookingEmailScheduleConfigs: state.bookingEmailScheduleConfigs.configs,
    isLoadingClientEmailTemplates: state.emailTemplates.isLoading,
    clientEmailTemplates: state.emailTemplates.emailTemplates.filter(t => !t.archived),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch)
});

// Wire up the React component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(BookingEmailScheduleConfigList);
