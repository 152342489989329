
import { Action } from 'redux';

import { ProductCategoryActionTypes, ProductCategoryState } from './types';
import { ProductCategoryActions } from './actions'
import { createReducer } from '../../reducer';


const unloadedState: ProductCategoryState = { isLoading: false, productCategories: [], loadError: null, isSaving: false, saveComplete: false, saveError: null, validationErrors: [] };

export const reducer = (state: ProductCategoryState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: ProductCategoryState, incomingAction: Action): ProductCategoryState => {

    const action = incomingAction as ProductCategoryActions;
    switch (action.type) {
        case ProductCategoryActionTypes.RequestProductCategories:
            return {
                ...state,
                isLoading: true,
                productCategories: state.productCategories,
                loadError: null
            };
        case ProductCategoryActionTypes.ReceivedProductCategories:
            const ordered = action.productCategories ? action.productCategories : [];
            ordered.sort((a, b) => a.sequence - b.sequence);

            return {
                ...state,
                isLoading: false,
                productCategories: ordered,
                loadError: action.error
            };
        case ProductCategoryActionTypes.EditProductCategory:
            return {
                ...state,
                isSaving: false,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case ProductCategoryActionTypes.SaveProductCategory:
            return {
                ...state,
                isSaving: true,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case ProductCategoryActionTypes.ProductCategorySaved:
            return {
                ...state,
                isSaving: false,
                saveComplete: true,
                saveError: null,
                validationErrors: []
            };
        case ProductCategoryActionTypes.ProductCategorySaveFailed:
            return {
                ...state,
                isSaving: false,
                saveError: action.error,
                saveComplete: false,
                validationErrors: action.error && action.error.validationErrors ? action.error.validationErrors : []
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

