
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as dt from '../../../store/pages/diary/types';
import { ITranslationContext } from '../../../translations';
import { clickHandler, isNullOrEmpty } from '../../../utils/util';
import SendEmailOverlay from './sendEmailOverlay';
import EventRegistration from './eventRegistration';
import { ClientEmailTemplate, EmailType } from '../../../store/pages/emailTemplates/types';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';
import { stringComparer } from '../../../utils/comparers';

interface EventRegistrationsProps {
    hasResultUrl: boolean;
    registrations: dt.EventRegistration[];
    bookings: dt.EventBooking[];
    emailTemplates: ClientEmailTemplate[];
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
    viewRegistration: (registration: dt.EventRegistration) => void;
    sendEmail: (clientEmailTemplate: ClientEmailTemplate, toEmailAddress: string, customerId: string | null, emailSent: (error: string | null) => void) => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
}

export const EventRegistrations = (props: EventRegistrationsProps, context: ITranslationContext) => {
    const { t } = context;
    const { timeFormat, dateFormat } = props;

    const sendEmail = (registration: dt.EventRegistration) => {
        props.showModal(<SendEmailOverlay
            customerId={registration.customerId}
            emailAddress={registration.emailAddress}
            firstname={registration.firstname}
            lastname={registration.lastname}
            phoneNumber={registration.phoneNumber}
            emailTemplates={props.emailTemplates.filter(t => t.emailType === EmailType.EventResults)}
            close={props.closeModal}
            sendEmail={props.sendEmail}
        />, 'SendEmail')
    }

    const bookings = props.bookings.filter(bk => !bk.isWebBooking || bk.webBookingComplete)
        .sort((bk1, bk2) => {
            const c1 = bk1.customer ? bk1.customer.firstname : 'zzz';
            const c2 = bk2.customer ? bk2.customer.firstname : 'zzz';
            return stringComparer(c1, c2);
        })
        .map(bk => {
        return <>
            <tr key={bk.id}>
                <td colSpan={11} style={{ color: '#666' }}>{`${bk.billNumber} ${bk.customer ? bk.customer.firstname : ''} ${bk.customer ? bk.customer.lastname : ''}`}{bk.cancelled ? <label className='label label-danger'>{t('Global:cancelled')}</label> : null}</td>
            </tr>
            {props.registrations
                .filter(reg => reg.bookingIds.includes(bk.id))
                .sort((r1, r2) => stringComparer(`${r1.firstname} ${r1.lastname}`, `${r2.firstname} ${r2.lastname}`))
                .map(reg => <EventRegistration key={`${bk.id}_${reg.registrationId}`} bk={bk} reg={reg} dateFormat={dateFormat} timeFormat={timeFormat} hasResultUrl={props.hasResultUrl} sendEmail={sendEmail} viewRegistration={props.viewRegistration} />)
            }
            </>
    })

    return (
        <table className='table table-condensed'>
            <thead>
                <tr key='header'>
                    <th>{t('registrations:name')}</th>
                    <th>{t('registrations:category')}</th>
                    <th>{t('registrations:age')}</th>
                    <th></th>
                    <th>{t('registrations:emailAddress')}</th>
                    <th>{t('registrations:phoneNumber')}</th>
                    <th>{t('registrations:resultEmail')}</th>
                    <th className='text-right'>{t('registrations:registered')}</th>
                    <th>{t('registrations:waiver')}</th>
                    <th className='text-right'>{t('registrations:agreedTerms')}</th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>{bookings}</tbody>
        </table>
    );
}

EventRegistrations.contextTypes = {
    t: PropTypes.func
}