
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'

import * as api from '../../../store/apiClient';
import { ApplicationState } from '../../../store';
import { TaxRate } from '../../../store/pages/taxRates/types';
import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import * as TaxRateActions from '../../../store/pages/taxRates/actions';
import * as ModalActions from '../../../store/global/modal/actions';
import { clickHandler } from '../../../utils/util';
import ApiError from '../../global/apiError';
import { ValidationError } from '../../../store/global/types';

interface LocalProps {
    isNew: boolean;
    venueId: string;
    taxRate: TaxRate | null;
}

interface LocalState {
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
}

interface Actions {
    saveTaxRate: (isNew: boolean, taxRateId: string | null, venueId: string, name: string, rate: number) => void;
    closeModal: () => void;
}

type TaxRateFormProps = Actions & LocalState & LocalProps;

interface TaxRateFormState {
    name: ct.FormValue<string>;
    rate: ct.FormValue<number>;
}

class TaxRateForm extends React.Component<TaxRateFormProps, TaxRateFormState> {

    constructor(props: TaxRateFormProps) {
        super(props);

        this.state = this.buildStateFromProps(this.props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps(props: TaxRateFormProps): TaxRateFormState {

        const { isNew, taxRate } = props;

        return {
            name: this.validateName((isNew || !taxRate) ? '' : taxRate.name),
            rate: this.validateRate((isNew || !taxRate) ? 0 : taxRate.rate || 0)
        };
    }

    componentDidUpdate(prevProps: TaxRateFormProps) {
        // Only update state is tax rate has changed
        const { taxRate: prevTaxRate, saveComplete: prevSaveComplete } = prevProps;
        const { taxRate, saveComplete } = this.props;

        if ((!prevTaxRate && taxRate) || (prevTaxRate && !taxRate) || (prevTaxRate && taxRate && prevTaxRate.id !== taxRate.id)) {
            this.setState(this.buildStateFromProps(this.props));
        }

        if (saveComplete && !prevSaveComplete) {
            setTimeout(() => { this.close(); }, 750);
        }
    }

    saveTaxRate = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }

    close = () => {
        this.props.closeModal();
    }

    save = () => {
        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {
            const { isNew, taxRate, venueId, saveTaxRate } = this.props;
            const { name, rate } = this.state;

            const taxRateId = isNew || !taxRate ? null : taxRate.id;
            saveTaxRate(isNew, taxRateId, venueId, name.value, rate.value);
        }
    }

    validateName = (val: string) => v.validate(val, 'name', [v.required], this.props.validationErrors);
    validateRate = (val: number) => v.validate(val, 'rate', [v.numeric], this.props.validationErrors);


    render() {

        let message: any;

        if (this.props.saveError) {
            message = <ApiError error={this.props.saveError} />;
        } else if (this.props.saveComplete) {
            message = (<div className='bg-success'>{this.context.t('Global:saveComplete')}</div>);
        }

        return <div className='taxRatePage'>
            <h1 className='taxRate_title'>{this.props.isNew ? this.context.t('TaxRateForm:addTaxRate') : this.context.t('TaxRateForm:editTaxRate')}</h1>

            <form className='data-form' onSubmit={this.saveTaxRate} autoComplete='off'>
                <ct.TextBox id='name' labelKey='Global:name' placeholderKey='Global:name' value={this.state.name} callback={val => this.setState({ name: this.validateName(val) })} />

                <ct.NumberBox id='rate' labelKey='TaxRateForm:rate' placeholderKey='TaxRateForm:rate' value={this.state.rate} callback={val => this.setState({ rate: this.validateRate(val || 0) })} step='0.01' min='0' />

                {message}

                <p />
                <div className='btn-toolbar'>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)}>{this.context.t('Global:save')}</button>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{this.context.t('Global:cancel')}</button>
                </div>
            </form>
        </div>;
    }
};

const mapStateToProps = (state: ApplicationState) => ({
    saveComplete: state.taxRates.saveComplete,
    saveError: state.taxRates.saveError,
    validationErrors: state.taxRates.validationErrors
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
    saveTaxRate: bindActionCreators(TaxRateActions.actionCreators.saveTaxRate, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,           // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(TaxRateForm);
