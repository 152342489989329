
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { ITranslationContext } from '../../../translations';
import { BillItem, BillItemVoucher, formatItemName } from '../../../store/pages/pointOfSale/types';
import { clickHandler, isNullOrEmpty } from '../../../utils/util';
import { CustomerCategory } from '../../../store/pages/customerCategories/types';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';
import TagLabel from '../../global/tagLabel';

interface BillItemsRowProps {
    item: BillItem;
    linkedItems: BillItem[];
    customerCategories: CustomerCategory[];
    multiDayEvent: boolean;
    bookingCancelled: boolean;
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
    editBillItem?: (item: BillItem) => void;
    reschedule?: (item: BillItem) => void;
    viewVoucher?: (voucher: BillItemVoucher) => void;
}

const BillItemsRow = (props: BillItemsRowProps, context: ITranslationContext) => {
    const { item: i, bookingCancelled, customerCategories, reschedule, multiDayEvent, timeFormat, dateFormat } = props;
    const { t } = context;

    const formatItemTag = (item: BillItem) => {
        if (isNullOrEmpty(item.productPriceId) || isNullOrEmpty(item.productTagName))
            return;

        const style: React.CSSProperties = !item.productTagColour || isNullOrEmpty(item.productTagColour) ? {} : { backgroundColor: item.productTagColour };
        return <div style={({ fontSize: '14px' })}><label className='label tag-label' style={style}>{item.productTagName}</label></div>
    }

    const editItem = props.editBillItem ? props.editBillItem : (i: BillItem) => { };

    const viewVoucher = props.viewVoucher ? props.viewVoucher: (voucher: BillItemVoucher) => { }

    const renderItemDetails = (item: BillItem, bookingCancelled: boolean) => {
        const text = formatItemName(item, customerCategories, multiDayEvent, timeFormat, dateFormat, t);
        return item.primaryBillItemId || bookingCancelled ? <div style={{ display: 'flex', alignItems: 'center' }}>{text}</div> : <button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => editItem(item))}>{text}</button>
    }

    let action: JSX.Element | null = null;
    if (reschedule && i.primaryBillItemId === null && i.reservationId !== null) {
        action = <div className='bill_item_actions'><button className='btn btn-sm btn-info' style={{ marginRight: '6px' }} onClick={e => clickHandler(e, () => reschedule(i))}>{t('EventCustomers:reschedule')}</button></div>
    }

    const mainItem = <li key={i.key} className='pos_bill_item'>
        <div className='pos_bill_item_qualtity'>{i.quantity}</div>
        <div className='bill_item_name'>{renderItemDetails(i, bookingCancelled)}{formatItemTag(i)}</div>
        {action}
        <div className='bill_item_price'>
            <div className='bill_item_main_price'>{i.primaryBillItemId ? null : `${t('Global:currencySymbol')}${i.totalItemPrice.toFixed(2)}`}</div>
            <div className='bill_item_tax'>{i.primaryBillItemId ? null : `${i.taxRate.toFixed(2)}%`}</div>
        </div>
    </li>

    const revokedItemStyle = { color: '#a94442'}

    const voucherItems = i.vouchers
        ? i.vouchers.map(v => <li key={`${i.key}_v${v.voucherId}`} className='pos_bill_item'>
            <div className='bill_item_name' style={v.revoked ? revokedItemStyle : {}}>
                {viewVoucher ? <button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => viewVoucher(v))} style={v.revoked ? revokedItemStyle : {}}>{v.voucherCode}</button> : v.voucherCode}
                {v.revoked ? <span className='label label-danger label-xs' style={{ marginLeft: '6px' }}>{t('Global:revoked')}</span> : null}
            </div>
            <div className='bill_item_voucher_price' style={v.revoked ? revokedItemStyle : {}}>{`${t('Global:currencySymbol')}${v.voucherAmount ? v.voucherAmount.toFixed(2) : ''}`}</div>
        </li>)
        : null;

    const membershipItem = i.membership ? <li key={`${i.key}_m${i.membership.id}`} className='pos_bill_item'><div className='bill_item_name'><TagLabel name={`${i.membership.tagName} ${i.membership.membershipNumber}`} colour={i.membership.tagColour} /></div></li> : null

    return <>{mainItem}{voucherItems}{membershipItem}</>
}

BillItemsRow.contextTypes = {
    t: PropTypes.func
}

export default BillItemsRow;