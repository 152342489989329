
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as api from '../../../store/apiClient';

import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import ApiError from '../../global/apiError';

import { BillItem, BillItemVoucher } from '../../../store/pages/pointOfSale/types';
import { clickHandler, isNullOrEmpty } from '../../../utils/util';
import { ISendEmailResponse } from './types';

interface VoucherSelection {
    voucherId: string;
    voucherCode: string;
    redemptionAmount: number;
    revoked: boolean;
    selected: boolean;
    sent: boolean;
}

interface SendVouchersFormProps {
    venueId: string;
    voucherItems: BillItem[];
    customerId: string | null;
    customerEmail: string | null;
    close: () => void;
    logout: () => void;
}

interface SendVouchersFormState {
    emailAddress: ct.FormValue<string>;
    voucherSelections: VoucherSelection[];
    sending: boolean;
    error: string | null;
    sendError: api.ApiError | null;
}

class SendVouchersForm extends React.Component<SendVouchersFormProps, SendVouchersFormState> {

    constructor(props: SendVouchersFormProps) {
        super(props);

        this.state = {
            emailAddress: this.validateEmailAddress(props.customerEmail || ''),
            voucherSelections: props.voucherItems.reduce<VoucherSelection[]>((vs, i) => vs.concat(i.vouchers.map(v => ({ voucherId: v.voucherId, voucherCode: v.voucherCode, redemptionAmount: v.voucherAmount, revoked: v.revoked, selected: false, sent: false }))), []),
            sending: false,
            error: '',
            sendError: null
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    validateEmailAddress = (val: string) => v.validate(val, 'emailAddress', [v.required], []);

    send = () => {
        const { emailAddress, voucherSelections } = this.state;

        if (!emailAddress.isValid) {
            this.setState({ error: 'SendVoucherForm:enterEmailAddress', sending: false });
        }

        const voucherIds = voucherSelections.filter(v => v.selected).map(v => v.voucherId);

        this.setState(s => ({ error: '', sending: true, sendError: null, voucherSelections: s.voucherSelections.map(vs => ({...vs, sent: false })) }), () => this.sendEmails(voucherIds));
    }

    sendEmails = (selectedVoucherIds: string[]) => {
        if (selectedVoucherIds.length > 0) {
            this.sendEmail(selectedVoucherIds[0], selectedVoucherIds.filter((v, ix) => ix > 0))
        }
    }

    sendEmail = (voucherId: string, remainingVoucherIds: string[]) => {
        const { venueId, customerId, logout } = this.props;
        const { emailAddress } = this.state;

        api.postWithAuth(`api/v1/voucher/${voucherId}/email`, { venueId: venueId, customerId: customerId, sendTo: emailAddress.value }, logout).subscribe(response => {
            const ser = response.response as ISendEmailResponse;
            if (ser && ser.sent) {
                this.setState(s => ({ voucherSelections: s.voucherSelections.map(v => ({ ...v, sent: v.sent || v.voucherId === voucherId })) }), () => {
                    if (remainingVoucherIds.length === 0) {
                        setTimeout(this.props.close, 750);
                    } else {
                        this.sendEmail(remainingVoucherIds[0], remainingVoucherIds.filter((v, ix) => ix > 0))
                    }
                })
            } else {
                this.setState({ sending: false, error: ser.error });
            }
        }, (err: api.ApiError) => {
            this.setState({ sending: false, sendError: err });
        })
    }

    setVoucherSelectionState = (voucherId: string, checked: boolean) => {
        this.setState(s => ({ voucherSelections: s.voucherSelections.map(vs => ({ ...vs, selected: voucherId === vs.voucherId ? checked : vs.selected })) }))
    }

    render() {
        const { t } = this.context;
        const { voucherSelections, emailAddress, error, sendError, sending } = this.state;

        return <div style={({ width: '100%' })}>
            <h3>{t('SendVouchersForm:heading')}</h3>

            <form className='data-form' onSubmit={e => e.preventDefault()} autoComplete='off'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <ct.Email id='emailAddress' labelKey='Global:emailAddress' placeholderKey='Global:emailAddress' value={emailAddress} callback={val => this.setState({ emailAddress: this.validateEmailAddress(val) })} />
                    </div>
                </div>

                <table className='table table-condensed' style={{ width: 'auto' }}>
                    <tbody>
                    {voucherSelections.map(v => <tr>
                        <td><input id={v.voucherId} type='checkbox' checked={v.selected} onChange={x => this.setVoucherSelectionState(v.voucherId, x.currentTarget.checked)} disabled={v.revoked || sending} /></td>
                        <td><label htmlFor={v.voucherId} className={v.revoked ? 'text-danger' : ''}>{v.voucherCode}</label></td>
                        <td><label htmlFor={v.voucherId} className={v.revoked ? 'text-danger' : ''}>{`${t('Global:currencySymbol')}${v.redemptionAmount ? v.redemptionAmount.toFixed(2) : ''}`}</label></td>
                        <td>{v.revoked ? <span className='label label-danger label-xs'>{t('Global:revoked')}</span> : null}</td>
                        <td>{v.sent ? <span className='label label-success label-xs'>{t('Global:sent')}</span> : null}</td>
                    </tr>
                    )}
                    </tbody>
                </table>

                {!isNullOrEmpty(error) ? <div className='alert alert-danger'>{t(error)}</div> : null}
                {sendError ? <ApiError error={sendError} /> : null}

                <div className='row'>
                    <div className='col-sm-12 button-panel form-group'>
                        <button className='btn btn-warning' onClick={e => clickHandler(e, this.send)} disabled={sending}>{t('Global:send')}</button>
                        <button onClick={e => clickHandler(e, this.props.close)} className='btn btn-basic'>{t('Global:cancel')}</button>
                    </div>
                </div>
            </form>
        </div>
    }
}

SendVouchersForm.contextTypes = {
    t: PropTypes.func
}

export default SendVouchersForm;