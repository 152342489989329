
import * as React from 'react';
import { IconProps } from './iconProps';

const Notes = (props: IconProps) => (
    <svg style={({ width: `${props.width}px`, height: `${props.height}px` })} viewBox='0 0 24 24'>
        <path fill={props.colour} d="M15 3H5A2 2 0 0 0 3 5V19A2 2 0 0 0 5 21H19A2 2 0 0 0 21 19V9L15 3M19 19H5V5H14V10H19M17 14H7V12H17M14 17H7V15H14" />
    </svg>
);

export default Notes;