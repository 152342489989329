
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'

import * as ct from '../../../global/controls';
import { ApplicationState } from '../../../../store';
import * as ProductCategoryActions from '../../../../store/pages/productCategories/actions';
import * as ModalActions from '../../../../store/global/modal/actions';
import Loading from '../../../global/loading';
import { clickHandler } from '../../../../utils/util';
import { ProductCategory } from '../../../../store/pages/productCategories/types';
import ProductCategoryForm from './productCategoryForm';

interface LocalProps {
    venueId: string;
}

interface LocalState {
    isLoading: boolean;
    productCategories: ProductCategory[];
}

interface Actions {
    loadProductCategories: () => void;
    editProductCategory: () => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
}

type ProductCategoryListProps = LocalState & Actions & LocalProps;

interface ProductCategoryListState {
    showArchived: ct.FormValue<boolean>;
}


class ProductCategoryList extends React.Component<ProductCategoryListProps, ProductCategoryListState> {

    constructor(props: ProductCategoryListProps) {
        super(props);

        this.state = { showArchived: ct.asFormValue('showArchived', false) }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private editProductCategory = (user: ProductCategory) => {
        this.showOverlay(user, false);
    }

    private addProductCategory = () => {
        this.showOverlay(null, true);
    }

    private showOverlay = (productCategory: ProductCategory | null, addingNew: boolean) => {
        this.props.editProductCategory();
        this.props.showModal(<ProductCategoryForm isNew={addingNew} venueId={this.props.venueId} productCategory={productCategory ? productCategory : null}  />, 'ProductCategoryForm');
    }

    render() {
        const { showArchived } = this.state;

        const body = this.props.isLoading ? <Loading /> : this.renderProductCategoriesTable();

        return <section className='venue-item-list'>
            <header className='section-header'>
                <div className='section-actions'>
                    <ct.Checkbox id='showArchived' labelKey='Global:showArchived' value={showArchived} callback={val => this.setState({ showArchived: ct.asFormValue('showArchived', val) })} style={({ display: 'inline-block' })} />
                    <button className='btn btn-info' onClick={e => clickHandler(e, this.addProductCategory)}>{this.context.t('ProductCategoriesPage:addProductCategory')}</button>
                </div>
            </header>
            <div className='at-panel sub-section-panel-240px'>
                {body}
            </div>
        </section>;
    }
     
    private renderProductCategoriesTable() {
        const { t } = this.context;
        const { productCategories, venueId } = this.props;
        const { showArchived } = this.state;

        const rates = productCategories.filter(c => c.venueId === venueId && (showArchived.value || !c.archived)).map(c =>
            <tr key={c.id}>
                <td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.editProductCategory(c))}>{c.name}</button></td>
                <td><div style={({ width: '30px', height: '15px', backgroundColor: c.colour })}></div></td>
                <td className='text-center'>{c.showOnPointOfSale ? t('Global:yes') : null}</td>
                {/*<td className='text-center'>{c.showOnWebShop ? t('Global:yes') : null}</td>*/}
                <td className='text-center'>{c.reportingPriority}</td>
                {showArchived.value ? <td className='text-center'>{c.archived ? t('Global:yes') : null}</td> : null}
            </tr>
        );

        return <table className='table table-condensed'>
            <thead>
                <tr>
                    <th>{this.context.t('ProductCategoriesPage:nameHeading')}</th>
                    <th>{this.context.t('ProductCategoriesPage:colourHeading')}</th>
                    <th className='text-center'>{this.context.t('ProductCategoriesPage:showInPointOfSaleHeading')}</th>
                    {/*<th className='text-center'>{this.context.t('ProductCategoriesPage:showOnWebShopHeading')}</th>*/}
                    <th className='text-center'>{this.context.t('ProductCategoriesPage:reportingPriorityHeading')}</th>
                    {showArchived.value ? <th className='text-center'>{t('Global:archived')}</th> : null}
                </tr>
            </thead>
            <tbody>
                {rates}
            </tbody>
        </table>;
    }
}

const matStateToProps = (state: ApplicationState) => ({
    productCategories: state.productCategories.productCategories,
    isLoading: state.productCategories.isLoading,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
        showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
        closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
        loadProductCategories: bindActionCreators(ProductCategoryActions.actionCreators.loadProductCategories, dispatch),
        editProductCategory: bindActionCreators(ProductCategoryActions.actionCreators.editProductCategory, dispatch),
    }
);


// Wire up the React component to the Redux store
export default connect(
    matStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps        // Selects which action creators are merged into the component's props
)(ProductCategoryList);

