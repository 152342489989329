import { ValidationError } from '../../global/types';
import * as api from '../../apiClient';

export interface Fee {
    id: string;
    venueId: string;
    feeType: FeeType;
    name: string;
    percentage: number;
    maxAmount: number;
    fixedAmount: number;
    taxRateId: string;
    accountingCategory: string;
    accountingDepartment: string;
    nominalCode: string;
    archived: boolean;
}

export enum FeeType {
    Fixed = 1,
    Percentage = 2
}

export interface FeeState {
    isLoading: boolean;
    fees: Fee[];
    loadError: api.ApiError | null;
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
}

export enum FeeActionTypes {
    RequestFees = 'REQUEST_FEES',
    ReceivedFees = 'RECEIVE_FEES',
    SaveFee = 'SAVE_FEE',
    FeeSaved = 'FEE_SAVED',
    FeeSaveFailed = 'FEE_SAVE_FAILED',
    EditFee = 'EDIT_FEE'
}

export interface LoadFees {
    type: FeeActionTypes.RequestFees;
}

export interface ReceiveFees {
    type: FeeActionTypes.ReceivedFees;
    fees: Fee[];
    error: api.ApiError | null;
}

export interface SaveFee {
    type: FeeActionTypes.SaveFee;
    isNew: boolean;
    feeId?: string;
    fee: Fee;
}

export interface FeeSaved {
    type: FeeActionTypes.FeeSaved;
    feeId: string;
}

export interface FeeSaveFailed {
    type: FeeActionTypes.FeeSaveFailed;
    error: api.ApiError;
}

export interface EditFee {
    type: FeeActionTypes.EditFee
}
