
import * as React from 'react';
import { clickHandler } from '../../../utils/util';

interface ImagePreviewProps {
    key: React.Key;
    src: string;
    delete?: (key: React.Key) => void;
}

const ImagePreview = (props: ImagePreviewProps) => {

    const delFunc = () => {
        if (props.delete)
            props.delete(props.key);
    }

    const del = props.delete ? <span className='glyphicon glyphicon-trash red' onClick={e => clickHandler(e, delFunc)} style={({position: 'absolute', top: '5px', right: '5px', cursor:'pointer', background: 'white', padding: '5px'})}></span> : null;

    return (
        <div key={props.key} className='file-preview-wrapper'>
            <img src={props.src} className='file-preview' alt='preview' />
            {del}
        </div>
    );
}

export default ImagePreview;