
// https://blog.angularindepth.com/how-to-reduce-action-boilerplate-90dc3d389e2b

import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as ct from './types';
import { IStore } from '../..';
import { isNullOrEmpty } from '../../../utils/util';

export type CustomerActions = ct.RequestCustomers | ct.ReceiveCustomers;

interface IFindCustomersResponse {
    customers: ct.FindCustomerSummary[];
    page: number;
    hasMorePages: boolean;
}

export const actionCreators = {
    loadCustomers: (pageNumber: number, pageSize: number, nameFilter: string, marketingPreferenceFilter: ct.MarketingPreference | null, venueSelection: string | null, lastVisitFrom: Date | null, lastVisitTo: Date | null, customerCreatedFrom: Date | null, customerCreatedTo: Date | null, minVisits: number | null, tagId: string | null, minAge: number | null, maxAge: number | null, bookedActivityFormatIds: string[], participantActivityFormatIds: string[]) => ({ type: ct.CustomerActionTypes.RequestCustomers, pageNumber: pageNumber, pageSize: pageSize, nameFilter: nameFilter, marketingPreferenceFilter: marketingPreferenceFilter, venueSelection: venueSelection, lastVisitFrom: lastVisitFrom, lastVisitTo: lastVisitTo, customerCreatedFrom: customerCreatedFrom, customerCreatedTo: customerCreatedTo, minVisits: minVisits, tagId: tagId, minAge: minAge, maxAge: maxAge, bookedActivityFormatIds: bookedActivityFormatIds, participantActivityFormatIds: participantActivityFormatIds }),
    receviedCustomers: (customers: ct.FindCustomerSummary[], customerCount: number, maxPage: number, err: api.ApiError | null) => ({ type: ct.CustomerActionTypes.ReceivedCustomers, customers: customers, customerCount: customerCount, maxPage: maxPage, error: err }),
}

let counter = 0;

export const handleClientChange = (store: IStore) => {
    if (store.value.customers.customers.length > 0) {
        return [() => actionCreators.loadCustomers(1, store.value.customers.pageSize, store.value.customers.nameFilter, store.value.customers.marketingPreferenceFilter, store.value.customers.venueSelection, store.value.customers.lastVisitFrom, store.value.customers.lastVisitTo, store.value.customers.customerCreatedFrom, store.value.customers.customerCreatedTo, store.value.customers.minVisits, store.value.customers.tagId, store.value.customers.minAge, store.value.customers.maxAge, store.value.customers.bookedActivityFormatIds, store.value.customers.participantActivityFormatIds)];
    } else {
        return [() => actionCreators.receviedCustomers([], -1, 0, null)];
    }
}

const loadCustomers = (pageNumber: number, pageSize: number, nameFilter: string, marketingPreferenceFilter: ct.MarketingPreference | null, venueSelection: string | null, lastVisitFrom: Date | null, lastVisitTo: Date | null, customerCreatedFrom: Date | null, customerCreatedTo: Date | null, minVisits: number | null, tagId: string | null, minAge: number | null, maxAge: number | null, bookedActivityFormatIds: string[], participantActivityFormatIds: string[]) => {

    counter = counter + 1;
    const lastCounter = counter;

    if (isNullOrEmpty(nameFilter)
        && marketingPreferenceFilter === null
        && isNullOrEmpty(venueSelection)
        && lastVisitFrom === null
        && lastVisitTo === null
        && customerCreatedFrom === null
        && customerCreatedTo === null
        && minVisits === null
        && isNullOrEmpty(tagId)
        && minAge === null
        && maxAge === null
        && bookedActivityFormatIds.length == 0
        && participantActivityFormatIds.length == 0)
    {
        return Observable.of(actionCreators.receviedCustomers([], -1, 0, null));
    }

    let query = `api/v1/customer/?pageNumber=${pageNumber}&pageSize=${pageSize}&name=${nameFilter}`;

    if (marketingPreferenceFilter) query = query + `&marketingPreference=${marketingPreferenceFilter}`;
    if (venueSelection) query = query + `&venueId=${venueSelection}`;
    if (lastVisitFrom) query = query + `&lastVisitFrom=${lastVisitFrom.toYMDHMDateString()}`
    if (lastVisitTo) query = query + `&lastVisitTo=${lastVisitTo.toYMDHMDateString()}`
    if (customerCreatedFrom) query = query + `&customerCreatedFrom=${customerCreatedFrom.toYMDHMDateString()}`
    if (customerCreatedTo) query = query + `&customerCreatedTo=${customerCreatedTo.toYMDHMDateString()}`
    if (minVisits) query = query + `&minVisits=${minVisits}`
    if (tagId) query = query + `&tag=${tagId}`
    if (minAge) query = query + `&minAge=${minAge}`
    if (maxAge) query = query + `&maxAge=${maxAge}`
    if (bookedActivityFormatIds.length > 0) query = query + bookedActivityFormatIds.map(f => `&baf=${f}`).join('');
    if (participantActivityFormatIds.length > 0) query = query + participantActivityFormatIds.map(f => `&paf=${f}`).join('');

    return Observable.defer(() => api.getJson<IFindCustomersResponse>(query))
        .filter(resp => {
            return lastCounter === counter
        })
        .map(response => actionCreators.receviedCustomers(response.customers, response.customers.length, response.hasMorePages ? response.page + 1 : response.page, null));
}

export const loadCustomersEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        ct.CustomerActionTypes.RequestCustomers,
        action => loadCustomers(action.pageNumber, action.pageSize, action.nameFilter, action.marketingPreferenceFilter, action.venueSelection, action.lastVisitFrom, action.lastVisitTo, action.customerCreatedFrom, action.customerCreatedTo, action.minVisits, action.tagId, action.minAge, action.maxAge, action.bookedActivityFormatIds, action.participantActivityFormatIds),
        err => actionCreators.receviedCustomers([], -1, 0, err));

