
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../../../store';

import * as ProductActions from '../../../../store/pages/products/actions';

import ProductForm from './productForm';
import Loading from '../../../global/loading';
import { Product } from '../../../../store/pages/products/types';
import { ActivityFormat } from '../../../../store/pages/activityFormats/types';

interface MappedState {
    products: Product[];
    productsLoading: boolean;
    activityFormats: ActivityFormat[];
}

interface Actions {
    loadProducts: () => void;
    editProduct: () => void;
}

interface ProductPageRouteProps {
    venueId: string;
    productId: string;
}

type ProductPageProps = MappedState & Actions & RouteComponentProps<ProductPageRouteProps>;

class ProductPage extends React.Component<ProductPageProps, {}> {
    
    goBackToVenuePage = () => {
        this.props.history.goBack();
    }

    componentDidMount() {
        this.props.editProduct();
    }

    render() {
        const { productsLoading, products, activityFormats, match } = this.props;
        const { venueId, productId } = match.params;

        if (productsLoading) return <Loading />

        const isNew = productId === 'new';
        const product = products.find(p => p.id === productId);
        if (!isNew && !product) return <div>not found</div>

        return <ProductForm isNew={isNew} venueId={venueId} product={product ? product : null} activityFormats={activityFormats} close={this.goBackToVenuePage} />
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return ({
        products: state.products.products,
        productsLoading: state.products.isLoading,
        activityFormats: state.activityFormats.activityFormats
    });
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    editProduct: bindActionCreators(ProductActions.actionCreators.editProduct, dispatch),
    loadProducts: bindActionCreators(ProductActions.actionCreators.loadProducts, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(ProductPage);
