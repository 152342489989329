
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as tdt from './types'; 
import * as lt from '../login/types';
import { IStore } from '../..';


export type TaskDefinitionActions = tdt.LoadTaskDefinitions | tdt.ReceiveTaskDefinitions | tdt.SaveTaskDefinition | tdt.TaskDefinitionSaved | tdt.TaskDefinitionSaveFailed | tdt.EditTaskDefinition;

export const actionCreators = {
    loadTaskDefinitions: () => ({ type: tdt.TaskDefinitionActionTypes.RequestTaskDefinitions }),
    loadTaskDefinitionsComplete: (taskDefinitions: tdt.TaskDefinition[], err: api.ApiError | null) => ({ type: tdt.TaskDefinitionActionTypes.ReceivedTaskDefinitions, taskDefinitions: taskDefinitions, error: err }),
    editTaskDefinition: () => ({ type: tdt.TaskDefinitionActionTypes.EditTaskDefinition }),
    saveTaskDefinition: (isNew: boolean, taskDefinitionId: string | null, taskDefinition: tdt.TaskDefinition) => ({ type: tdt.TaskDefinitionActionTypes.SaveTaskDefinition, isNew: isNew, taskDefinitionId: taskDefinitionId, taskDefinition: taskDefinition }),
    taskDefinitionSaved: (taskDefinitionId: string) => ({ type: tdt.TaskDefinitionActionTypes.TaskDefinitionSaved, taskDefinitionId: taskDefinitionId }),
    taskDefinitionSaveFailed: (error: api.ApiError) => ({ type: tdt.TaskDefinitionActionTypes.TaskDefinitionSaveFailed, error: error })
}

interface IGetTaskDefinitionsResponse {
    taskDefinitions: tdt.TaskDefinition[];
}

interface ISaveTaskDefinitionResponse {
    taskDefinitionId: string;
}

export const handleClientChange = (store: IStore) => [actionCreators.loadTaskDefinitions]

//https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-after-catching-and-processing-an-error-with-emitting-somethi

const loadTaskDefinitions = () => Observable.defer(() => api.getJson<IGetTaskDefinitionsResponse>('api/v1/taskDefinition/'))
    .map(response => actionCreators.loadTaskDefinitionsComplete(response.taskDefinitions, null));

export const loadTaskDefinitionsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        tdt.TaskDefinitionActionTypes.RequestTaskDefinitions,
        action => loadTaskDefinitions(),
        err => actionCreators.loadTaskDefinitionsComplete([], err));

export const reloadTaskDefinitionsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        tdt.TaskDefinitionActionTypes.TaskDefinitionSaved,
        action => loadTaskDefinitions(),
        err => actionCreators.loadTaskDefinitionsComplete([], err));

export const saveTaskDefinitionEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        tdt.TaskDefinitionActionTypes.SaveTaskDefinition,
        action => {
            const stda = action as tdt.SaveTaskDefinition;
            var body = { ...stda.taskDefinition };

            return (stda.isNew ? api.post('api/v1/taskDefinition/', body) : api.put(`api/v1/taskDefinition/${stda.taskDefinitionId}`, body))
                .map(response => {

                    let taskDefinitionId = stda.taskDefinitionId;
                    if (stda.isNew) {
                        const strr = response.response as ISaveTaskDefinitionResponse;
                        if (strr) {
                            taskDefinitionId = strr.taskDefinitionId;
                        }
                    }

                    return ({ type: tdt.TaskDefinitionActionTypes.TaskDefinitionSaved, taskDefinitionId: taskDefinitionId });
                });
        },
        (err: api.ApiError) => actionCreators.taskDefinitionSaveFailed(err));
