
import { Action } from 'redux';
import { LocationChangeAction } from 'react-router-redux';

import { IntegrationActionTypes, IntegrationsState } from './types';
import { IntegrationActions } from './actions'
import { createReducer } from '../../reducer';


const unloadedState: IntegrationsState = { isActive: false, isLoading: false, integrations: [], loadError: null, isSaving: false, saveComplete: false, saveError: null, validationErrors: [] };

export const reducer = (state: IntegrationsState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: IntegrationsState, incomingAction: Action): IntegrationsState => {

    if (incomingAction.type === '@@router/LOCATION_CHANGE') {
        const locationChangeAction = incomingAction as LocationChangeAction;
        if (locationChangeAction) {
            return { ...state, isActive: locationChangeAction.payload.pathname === '/integrations' };
        }
    }

    const action = incomingAction as IntegrationActions;
    switch (action.type) {
        case IntegrationActionTypes.RequestIntegrations:
            return {
                ...state,
                isLoading: true,
                integrations: state.integrations,
                loadError: null
            };
        case IntegrationActionTypes.ReceivedIntegrations:
            return {
                ...state,
                isLoading: false,
                integrations: action.integrations,
                loadError: action.error
            };
        case IntegrationActionTypes.EditIntegration:
            return {
                ...state,
                isSaving: false,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case IntegrationActionTypes.SaveIntegration:
            return {
                ...state,
                isSaving: true,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case IntegrationActionTypes.IntegrationSaved:
            return {
                ...state,
                isSaving: false,
                saveComplete: true,
                saveError: null,
                validationErrors: []
            };
        case IntegrationActionTypes.IntegrationSaveFailed:
            return {
                ...state,
                isSaving: false,
                saveError: action.error,
                saveComplete: false,
                validationErrors: action.error && action.error.validationErrors ? action.error.validationErrors : []
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

