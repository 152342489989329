import { ValidationError } from '../../global/types';
import * as api from '../../apiClient';

export interface Affiliate {
    id: string;
    name: string;
    code: string;
    emailAddress: string;
    archived: boolean;
}

export interface AffiliateState {
    isLoading: boolean;
    affiliates: Affiliate[];
    loadError: api.ApiError | null;
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
}

export enum AffiliateActionTypes {
    RequestAffiliates = 'REQUEST_AFFILIATES',
    ReceivedAffiliates = 'RECEIVE_AFFILIATES',
    SaveAffiliate = 'SAVE_AFFILIATE',
    AffiliateSaved = 'AFFILIATE_SAVED',
    AffiliateSaveFailed = 'AFFILIATE_SAVE_FAILED',
    EditAffiliate = 'EDIT_AFFILIATE'
}

export interface LoadAffiliates {
    type: AffiliateActionTypes.RequestAffiliates;
}

export interface ReceiveAffiliates {
    type: AffiliateActionTypes.ReceivedAffiliates;
    affiliates: Affiliate[];
    error: api.ApiError | null;
}

export interface SaveAffiliate {
    type: AffiliateActionTypes.SaveAffiliate;
    isNew: boolean;
    affiliateId?: string;
    affiliate: Affiliate;
}

export interface AffiliateSaved {
    type: AffiliateActionTypes.AffiliateSaved;
    affiliateId: string;
}

export interface AffiliateSaveFailed {
    type: AffiliateActionTypes.AffiliateSaveFailed;
    error: api.ApiError;
}

export interface EditAffiliate {
    type: AffiliateActionTypes.EditAffiliate
}
