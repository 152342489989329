

import * as React from 'react';
import * as PropTypes from 'prop-types'

import { ITranslationContext } from "../../../translations";
import { clickHandler } from '../../../utils/util';
import { IEvent, IEventReservation } from './types';
import ReservationCompetitorCounts from './reservationCompetitorCounts';
import { ReservationType } from '../../../store/pages/diary/types';
import Flag from '../../icons/flag';
import { TimeFormat } from '../../../store/pages/venues/types';


interface EventReservationProps {
    key: string;
    ix: number;
    reservation: IEventReservation;
    outstandingBal: number;
    expanded: boolean;
    timeFormat: TimeFormat;
    toggleExpandCollapse?: () => void;
}

const EventReservation = (props: EventReservationProps, context: ITranslationContext) => {
    const { key, ix, reservation, outstandingBal, expanded, timeFormat, toggleExpandCollapse } = props;
    const { t } = context;

    const toggle = toggleExpandCollapse ? toggleExpandCollapse : () => { }
    return <div key={key} onClick={e => clickHandler(e, toggle)} className='reception-reservation-header'>
        <div className='reception-reservation-time'>{reservation.startTime.toShortTimeString(timeFormat)}</div>
        <div className='reception-reservation-resource' style={{ borderLeft: `solid 3px ${reservation.resourceColour}` }}>{reservation.resourceShortName}</div>
        <div className='reception-reservation-type'>{t(reservation.reservationType === ReservationType.Exclusive ? 'Global:exclusiveShort' : 'Global:sharedShort')}</div>
        <div className='reception-reservation-name'>{reservation.activityFormat ? reservation.activityFormat.name : ''}</div>
        <ReservationCompetitorCounts reservation={reservation} />
        <div className='reception-booking-outstanding-amount'>{ix === 0 && outstandingBal > 0 ? `${t('Global:currencySymbol')}${outstandingBal.toFixed(2)}` : ''}</div>
        <div className='reception-actions'>{reservation.flagged ? <Flag width={20} height={20} colour='#d9534f' /> : null}{toggleExpandCollapse ? <span className='reception-item-panel-status pull-right'>{expanded ? '-' : '+'}</span> : null}</div>
    </div>
}


EventReservation.contextTypes = {
    t: PropTypes.func
}

export default EventReservation;