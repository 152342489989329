
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'
import { ApplicationState } from '../../../store';

import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import * as api from '../../../store/apiClient';

import { ValidationError } from '../../../store/global/types';

import { Affiliate } from '../../../store/pages/affiliates/types';
import * as AffiliateActions from '../../../store/pages/affiliates/actions';
import * as ModalActions from '../../../store/global/modal/actions';
import { clickHandler, createUUID, formatCompactGuid } from '../../../utils/util';
import ApiError from '../../global/apiError';

interface ComponentProps {
    isNew: boolean;
    affiliate: Affiliate | null;
}

interface MappedReduxState {
    isSaving: boolean;
    saveError: api.ApiError | null;
    saveComplete: boolean;
    validationErrors: ValidationError[];
}

interface Actions {
    closeModal: () => void;
    saveAffiliate: (isNew: boolean, affiliateId: string | null, affiliate: Affiliate) => void;
}
type AffiliateFormProps = MappedReduxState & Actions & ComponentProps;

interface AffiliateFormState {
    name: ct.FormValue<string>;
    code: ct.FormValue<string>;
    emailAddress: ct.FormValue<string>;
    archived: ct.FormValue<boolean>;
}

class AffiliateForm extends React.Component<AffiliateFormProps, AffiliateFormState> {

    constructor(props: AffiliateFormProps) {
        super(props);

        this.state = this.buildStateFromProps(props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps(props: AffiliateFormProps): AffiliateFormState {

        const { isNew, affiliate } = props;

        return {
            name: this.validateName((isNew || !affiliate) ? '' : affiliate.name || ''),
            code: this.validateName((isNew || !affiliate || !affiliate.code) ? formatCompactGuid(createUUID()).toLowerCase() : affiliate.code),
            emailAddress: this.validateName((isNew || !affiliate) ? '' : affiliate.emailAddress || ''),
            archived: ct.asFormValue('archived', isNew || !affiliate || !affiliate.archived ? false : true),
        };
    }

    componentDidUpdate(prevProps: AffiliateFormProps) {
        if (this.props.saveComplete && !prevProps.saveComplete) {
            setTimeout(() => { this.close(); }, 750);
        }
    }

    validateName = (val: string) => v.validate(val, 'name', [v.required], this.props.validationErrors);
    validateCode = (val: string) => v.validate(val, 'code', [v.required], this.props.validationErrors);
    validateEmailAddress = (val: string) => v.validate(val, 'emailAddress', [v.required], this.props.validationErrors);

    saveAffiliate = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }

    private close = () => {
        this.props.closeModal();
    }

    private save = () => {

        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {
            const { isNew, affiliate } = this.props;
            const { name, code, emailAddress, archived } = this.state;
            const affiliateId = isNew || !affiliate ? null : affiliate.id;
            this.props.saveAffiliate(isNew, affiliateId, { id: isNew || !affiliate ? '' : affiliate.id, name: name.value, code: code.value, emailAddress: emailAddress.value, archived: archived.value });
        }
    }

    render() {

        const { name, code, emailAddress, archived } = this.state;
        const { isNew, isSaving, saveError, saveComplete } = this.props;
        const { t } = this.context;   
        let message: any = null;

        if (saveError) {
            message = <ApiError error={saveError} />;
        } else if (saveComplete) {
            message = (<div className='bg-success'>{t('Global:saveComplete')}</div>);
        }

        const saveButtonProps = v.isValid(this.state) ? {} : { 'disabled': true };

        return <div className='affiliateForm'>
            <h1 className='affiliate_title'>{isNew ? t('AffiliateForm:addAffiliate') : t('AffiliateForm:editAffiliate')}</h1>

            <form className='data-form' onSubmit={this.saveAffiliate} autoComplete='off'>

                <ct.TextBox id='name' labelKey='Global:name' placeholderKey='Global:name' value={name} callback={val => this.setState({ name: this.validateName(val) })} />

                <ct.TextBox id='code' labelKey='Global:code' placeholderKey='Global:code' value={code} callback={val => this.setState({ code: this.validateCode(val) })} />

                <ct.TextBox id='emailAddress' labelKey='Global:emailAddress' placeholderKey='Global:emailAddress' value={emailAddress} callback={val => this.setState({ emailAddress: this.validateEmailAddress(val) })} />

                {!isNew ? <ct.Checkbox id='archived' labelKey='Global:archive' value={archived} callback={val => this.setState({ archived: ct.asFormValue('archived', val) })} /> : null}

                {message}

                <p />
                <div className='btn-toolbar'>
                    <button className='btn btn-primary' {...saveButtonProps} onClick={e => clickHandler(e, this.save)}>{t('Global:save')}</button>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{t('Global:cancel')
                    }</button>
                </div>
            </form>
        </div>;
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    isSaving: state.affiliates.isSaving,
    saveComplete: state.affiliates.saveComplete,
    saveError: state.affiliates.saveError,
    validationErrors: state.affiliates.validationErrors
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    saveAffiliate: bindActionCreators(AffiliateActions.actionCreators.saveAffiliate, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
});


// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,           // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(AffiliateForm);
