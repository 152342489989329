
import * as React from 'react';

import * as ct from '../../../global/controls';
import { ActivityCustomerCategory } from './activityFormatForm';

interface ActivityFormatCustomerCategoryProps {
    activityCustomerCategory: ActivityCustomerCategory;
    customerCategoryEnabledChanged: (customerCategoryId: string, enabled: boolean) => void;
    placesToBookChanged: (customerCategoryId: string, val: number) => void;
    quantitiesChanged: (customerCategoryId: string, min: number, bookingMax: number, totalMax: number) => void;
}

export default class ActivityFormatCustomerCategory extends React.Component<ActivityFormatCustomerCategoryProps, {}> {

    customerCategoryEnabledChanged = (val: boolean) => this.props.customerCategoryEnabledChanged(this.props.activityCustomerCategory.customerCategoryId, val);
    placesToBookChanged = (val: number) => this.props.placesToBookChanged(this.props.activityCustomerCategory.customerCategoryId, val && !isNaN(val) ? val : 0);
    minQuantityPerBookingChanged = (val: number) => this.props.quantitiesChanged(this.props.activityCustomerCategory.customerCategoryId, val && !isNaN(val) ? val : 0, this.props.activityCustomerCategory.maxQuantityPerBooking.value, this.props.activityCustomerCategory.maxOverallQuantity.value);
    maxQuantityPerBookingChanged = (val: number) => this.props.quantitiesChanged(this.props.activityCustomerCategory.customerCategoryId, this.props.activityCustomerCategory.minQuantityPerBooking.value, val && !isNaN(val) ? val : 0, this.props.activityCustomerCategory.maxOverallQuantity.value);
    maxOverallQuantityChanged = (val: number) => this.props.quantitiesChanged(this.props.activityCustomerCategory.customerCategoryId, this.props.activityCustomerCategory.minQuantityPerBooking.value, this.props.activityCustomerCategory.maxQuantityPerBooking.value, val && !isNaN(val) ? val : 0);

    render() {
        const { customerCategoryId, categoryName, enabled, placesToBook, minQuantityPerBooking, maxQuantityPerBooking, maxOverallQuantity } = this.props.activityCustomerCategory;
        const inlineControlStyle = ({ minHeight: '10px', marginRight: '10px' });

        return (
            <tr key={customerCategoryId}>
                <td style={({ verticalAlign: 'top' })}>{categoryName}</td>
                <td style={({ verticalAlign: 'top', textAlign: 'center' })}><ct.Checkbox id={`${customerCategoryId}_enabled`} labelKey='' value={enabled} callback={this.customerCategoryEnabledChanged} style={({ ...inlineControlStyle, maxWidth: '100px', minWidth: '50px' })} minimal={true} /></td>
                <td style={({ verticalAlign: 'top' })}><ct.NumberBox id={`${customerCategoryId}_placesToBook`} labelKey='' placeholderKey='' min={enabled.value ? '1' : '0'} value={placesToBook} callback={this.placesToBookChanged} style={({ ...inlineControlStyle, maxWidth: '100px', minWidth: '50px' })} minimal={true} disabled={!enabled.value} /></td>
                <td style={({ verticalAlign: 'top' })}><ct.NumberBox id={`${customerCategoryId}_minQuantityPerBooking`} labelKey='' placeholderKey='' min='0' value={minQuantityPerBooking} callback={this.minQuantityPerBookingChanged} style={({ ...inlineControlStyle, maxWidth: '100px', minWidth: '50px' })} minimal={true} disabled={!enabled.value} /></td>
                <td style={({ verticalAlign: 'top' })}><ct.NumberBox id={`${customerCategoryId}_maxQuantityPerBooking`} labelKey='' placeholderKey='' min='0' value={maxQuantityPerBooking} callback={this.maxQuantityPerBookingChanged} style={({ ...inlineControlStyle, maxWidth: '100px', minWidth: '50px' })} minimal={true} disabled={!enabled.value} /></td>
                <td style={({ verticalAlign: 'top' })}><ct.NumberBox id={`${customerCategoryId}_maxOverallQuantity`} labelKey='' placeholderKey='' min='0' value={maxOverallQuantity} callback={this.maxOverallQuantityChanged} style={({ ...inlineControlStyle, maxWidth: '100px', minWidth: '50px' })} minimal={true} disabled={!enabled.value} /></td>
            </tr>
        );
    }
}