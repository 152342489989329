
//import '../../../css/reactdatetime.css';

import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import * as api from '../../../store/apiClient';

import { PaymentMethod } from '../../../store/pages/paymentMethods/types';
import { clickHandler } from '../../../utils/util';
import { Bill, BillPayment } from '../../../store/pages/pointOfSale/types';
import ApiError from '../../global/apiError';

interface RefundFormProps {
    bill: Bill;
    paymentMethod: PaymentMethod;
    amount: number;
    refund: (paymentMethod: PaymentMethod, amount: number, reason: string, callback: (success: boolean, error: api.ApiError | null) => void) => void;
    cancel: () => void;
}

interface RefundFormState {
    reason: ct.FormValue<string>;
    amountError: string | null;
    error: api.ApiError | null;
    refunding: boolean;
}

export default class RefundForm extends React.Component<RefundFormProps, RefundFormState> {

    constructor(props: RefundFormProps) {
        super(props);

        this.state = {
            reason: this.validateReason(''),
            amountError: null,
            error: null,
            refunding: false
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        const { amount, paymentMethod, bill } = this.props;
        this.validateAmount(amount, paymentMethod, bill.payments);
    }

    validateAmount = (amount: number, paymentMethod: PaymentMethod, payments: BillPayment[]) => {
        const paidAmount = payments.filter(p => p.paid && p.paymentMethodId === paymentMethod.id).reduce((ttl, p) => ttl = ttl + p.amount, 0); 

        if (paymentMethod.limitRefundToPaidAmount && paidAmount < amount) {
            this.setState({ amountError: this.context.t('RefundForm:amountExceedsPaymentTotal', { paymentMethod: paymentMethod.name, paidAmount: paidAmount.toFixed(2) }) });
        }

        return null;
    }

    validateReason = (val: string) => v.validate(val, 'reason', [v.required], []);

    applyRefund = () => {
        const { refund, paymentMethod, amount } = this.props;
        const { reason, amountError } = this.state;

        if (!v.isValid(this.state) || amountError) {
            // TODO: Show error message!
        } else {
            this.setState({ refunding: true}, () => refund(paymentMethod, amount, reason.value, (success: boolean, error: api.ApiError | null) => this.setState({ error: error, refunding: false })));
        }
    }

    render() {
        const { amount, paymentMethod } = this.props;
        const { reason, amountError, error, refunding } = this.state;
        const { t } = this.context;

        return (
            <div style={({ width: '100%' })}>
                <h3>{t('PointOfSale:refund')}</h3>

                <form className='data-form' onSubmit={e => e.preventDefault()}>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <label>{t('PointOfSale:refundAmount')}</label>: <span>{t('Global:currencySymbol')} {amount.toFixed(2)}</span>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-12'>
                            <label>{t('PointOfSale:refundMethod')}</label>: <span>{paymentMethod.name}</span>
                        </div>
                    </div>

                    {amountError ? <div className='row'><div className='alert alert-danger'>{amountError}</div></div> : null}

                    <div className='row'>
                        <div className='col-sm-12'>
                            <ct.TextBox id='reason' labelKey='PointOfSale:refundReason' placeholderKey='PointOfSale:refundReason' value={reason} callback={val => this.setState({ reason: this.validateReason(val) })} />
                        </div>
                    </div>

                    {error ? <ApiError error={error} /> : null}

                    <div className='row'>
                        <div className='col-sm-12 button-panel form-group'>
                            <button className='btn btn-warning' onClick={e => clickHandler(e, this.applyRefund)} disabled={refunding}>{t('PointOfSale:refund')}</button>
                            <button onClick={e => clickHandler(e, this.props.cancel)} className='btn btn-basic'>{t('Global:cancel')}</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}