
import { Action } from 'redux';

import { PaymentScheduleActionTypes, PaymentScheduleState } from './types';
import { PaymentScheduleActions } from './actions'
import { createReducer } from '../../reducer';

const unloadedState: PaymentScheduleState = {
    isLoading: false,
    paymentSchedules: [],
    loadError: null,
    isSaving: false,
    saveComplete: false,
    saveError: null,
    validationErrors: []
};

export const reducer = (state: PaymentScheduleState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: PaymentScheduleState, incomingAction: Action): PaymentScheduleState => {

    const action = incomingAction as PaymentScheduleActions;
    switch (action.type) {
        case PaymentScheduleActionTypes.RequestPaymentSchedules:
            return {
                ...state,
                isLoading: true,
                paymentSchedules: state.paymentSchedules,
                loadError: null
            };
        case PaymentScheduleActionTypes.ReceivedPaymentSchedules:
            const ordered = action.paymentSchedules ? action.paymentSchedules : [];
            ordered.sort((a, b) => a.name.localeCompare(b.name));

            return {
                ...state,
                isLoading: false,
                paymentSchedules: ordered,
                loadError: action.error
            };
        case PaymentScheduleActionTypes.EditPaymentSchedule:
            return {
                ...state,
                isSaving: false,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case PaymentScheduleActionTypes.SavePaymentSchedule:
            return {
                ...state,
                isSaving: true,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case PaymentScheduleActionTypes.PaymentScheduleSaved:
            return {
                ...state,
                isSaving: false,
                saveComplete: true,
                saveError: null,
                validationErrors: []
            };
        case PaymentScheduleActionTypes.PaymentScheduleSaveFailed:
            return {
                ...state,
                isSaving: false,
                saveError: action.error,
                saveComplete: false,
                validationErrors: action.error && action.error.validationErrors ? action.error.validationErrors : []
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

