
import { Action } from 'redux';
import { createReducer } from '../../reducer';
import { getDiaryResolution, UserPreferenceActions } from './actions';
import { UserPreferenceActionTypes, UserPreferenceState } from "./types";

const unloadedState: UserPreferenceState = {
    hiddenResources: [],
    slotSizeInMinutes: getDiaryResolution(),
}

export const reducer = (state: UserPreferenceState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: UserPreferenceState, incomingAction: Action): UserPreferenceState => {

    const action = incomingAction as UserPreferenceActions;
    switch (action.type) {
        case UserPreferenceActionTypes.SetResourcefilter:
            return {
                ...state,
                hiddenResources: action.hiddenResources
            };
        case UserPreferenceActionTypes.SetDiaryResolution:
            return {
                ...state,
                slotSizeInMinutes: action.slotSizeInMinutes
            };
        default:
             // The following line guarantees that every action in the KnownAction union has been covered by a case above
             // eslint-disable-next-line
             const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};