import moment from "moment";
import { isNullOrEmpty } from "../../../utils/util";

export const formatDateValue = function <T>(val: moment.Moment | null, defaultVal: T) {
    const str = val ? val.format('YYYY-MM-DD') : defaultVal;
    return str;
}

export const formatDateTimeValue = function <T>(val: moment.Moment | null, defaultVal: T) {
    const str = val ? val.format('YYYY-MM-DD HH:mm') : defaultVal;
    return str;
}

export const parseDateValue = (val: string | null): moment.Moment | null => {
    // Dates should be stored in YYYY-MM-DD (maybe with time also) so checking the length will exclude numeric values
    // which can occur when changing from an interval type to a date type filter
    return !val || isNullOrEmpty(val) || val.length < 10 ? null : moment(val);
}

export const parseIntValue = (val: string | null): number | null => {
    if (!val) return null;

    const intVal = Number(val);
    return isNaN(intVal) ? null : intVal;
}

export const formatInt = function <T>(val: number | null, defaultVal: T) {
    return val ? val.toString() : defaultVal;
}
