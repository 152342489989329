
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'
import { ApplicationState } from '../../../../store';
import { PaymentAmountType, PaymentIntervalType, PaymentSchedule, PaymentScheduleState } from '../../../../store/pages/paymentSchedules/types';
import * as ct from '../../../global/controls';
import * as v from '../../../global/validation';
import * as PaymentScheduleActions from '../../../../store/pages/paymentSchedules/actions';
import * as ModalActions from '../../../../store/global/modal/actions';
import { clickHandler, generateTempId, isNullOrEmpty } from '../../../../utils/util';
import ApiError from '../../../global/apiError';

interface LocalProps {
    isNew: boolean;
    venueId: string;
    paymentSchedule: PaymentSchedule | null;
}

type Actions = typeof PaymentScheduleActions.actionCreators & typeof ModalActions.actionCreators

type PaymentScheduleFormProps = PaymentScheduleState
    & Actions
    & LocalProps;

interface PaymentSchedulePaymentState {
    id: string | null;
    key: string;
    description: ct.FormValue<string>;
    amount: ct.FormValue<number>;
    amountType: ct.FormValue<PaymentAmountType>;
    dueInterval: ct.FormValue<number>;
    dueIntervalType: ct.FormValue<PaymentIntervalType>;
}

interface PaymentScheduleFormState {
    name: ct.FormValue<string>;
    enableForWebBooking: ct.FormValue<boolean>;
    minimumAmountThreshold: ct.FormValue<number>;
    immediatePaymentAmount: ct.FormValue<number | null>;
    immediatePaymentAmountType: ct.FormValue<PaymentAmountType>;
    finalPaymentDescription: ct.FormValue<string>;
    finalPaymentDueInterval: ct.FormValue<number>;
    finalPaymentDueIntervalType: ct.FormValue<PaymentIntervalType>;
    archived: ct.FormValue<boolean>;
    schedule: PaymentSchedulePaymentState[];
    formError: string | null;
}

class PaymentScheduleForm extends React.Component<PaymentScheduleFormProps, PaymentScheduleFormState> {

    constructor(props: PaymentScheduleFormProps) {
        super(props);

        this.state = this.buildStateFromProps(this.props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps(props: PaymentScheduleFormProps): PaymentScheduleFormState {

        const {isNew, paymentSchedule} = props;

        return {
            name: this.validateName((isNew || !paymentSchedule) ? '' : paymentSchedule.name),
            enableForWebBooking: this.validateEnableForWebBooking((isNew || !paymentSchedule) ? false : paymentSchedule.enableForWebBooking),
            minimumAmountThreshold: this.validateMinimumAmountThreshold((isNew || !paymentSchedule) ? 0 : paymentSchedule.minimumAmountThreshold),
            immediatePaymentAmount: this.validateImmediatePaymentAmount((isNew || !paymentSchedule) ? null : paymentSchedule.immediatePaymentAmount),
            immediatePaymentAmountType: this.validateImmediatePaymentAmountType((isNew || !paymentSchedule) ? PaymentAmountType.FixedAmount : paymentSchedule.immediatePaymentAmountType),
            finalPaymentDescription: this.validateFinalPaymentDescription((isNew || !paymentSchedule) ? '' : paymentSchedule.finalPaymentDescription),
            finalPaymentDueInterval: this.validateFinalPaymentDueInterval((isNew || !paymentSchedule) ? 1 : paymentSchedule.finalPaymentDueInterval),
            finalPaymentDueIntervalType: this.validateFinalPaymentDueIntervalType((isNew || !paymentSchedule) ? PaymentIntervalType.BeforeEventDate : paymentSchedule.finalPaymentDueIntervalType),
            archived: ct.asFormValue('archived', isNew || !paymentSchedule || !paymentSchedule.archived ? false : true),
            schedule: paymentSchedule ? paymentSchedule.intermediatePayments.map(s => this.createSchedule(s.id, s.description, s.amount, s.amountType, s.dueInterval, s.dueIntervalType)) : [],
            formError: null
        };
    }

    createSchedule = (id: string | null, description: string, amount: number, amountType: PaymentAmountType, dueInterval: number, dueIntervalType: PaymentIntervalType) => {
        const key = id ? id : generateTempId();
        return {
            id: id,
            key: key,
            description: this.validateScheduleDescription(key, description),
            amount: this.validateSchedulePaymentAmount(key, amount),
            amountType: this.validateSchedulePaymentAmountType(key, amountType),
            dueInterval: this.validateScheduleDueInterval(key, dueInterval),
            dueIntervalType: this.validateScheduleDueIntervalType(key, dueIntervalType),
        }
    }

    componentDidUpdate(prevProps: PaymentScheduleFormProps) {
        const { paymentSchedule: prevPaymentSchedule, saveComplete: prevSaveComplete} = prevProps;
        const { paymentSchedule, saveComplete  } = this.props;

        // Only update state is tax rate has changed
        if ((!prevPaymentSchedule && paymentSchedule) || (prevPaymentSchedule && !paymentSchedule) || (prevPaymentSchedule && paymentSchedule && prevPaymentSchedule.id !== paymentSchedule.id)) {
            this.setState(this.buildStateFromProps(this.props));
        }

        if (saveComplete && !prevSaveComplete) {
            setTimeout(() => { this.close(); }, 750);
        }
    }

    savePaymentSchedule = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }
    
    close = () => {
        this.props.closeModal();
    }

    save = () => {
        if (!v.isValid(this.state)) {
            this.setState({ formError: 'Global:formNotValid'})
        } else {
            const { isNew, paymentSchedule, venueId } = this.props;
            const { name, enableForWebBooking, minimumAmountThreshold, immediatePaymentAmount, immediatePaymentAmountType, finalPaymentDescription, 
                finalPaymentDueInterval, finalPaymentDueIntervalType, schedule, archived } = this.state;

            const paymentScheduleId = isNew || !paymentSchedule ? null : paymentSchedule.id;
            this.props.savePaymentSchedule(isNew,
                paymentScheduleId,
                {
                    id: paymentScheduleId || '',
                    venueId: venueId,
                    name: name.value,
                    enableForWebBooking: enableForWebBooking.value,
                    minimumAmountThreshold: minimumAmountThreshold.value,
                    immediatePaymentAmount: immediatePaymentAmount.value,
                    immediatePaymentAmountType: immediatePaymentAmountType.value,
                    finalPaymentDescription: finalPaymentDescription.value,
                    finalPaymentDueInterval: finalPaymentDueInterval.value || 0,
                    finalPaymentDueIntervalType: finalPaymentDueIntervalType.value,
                    archived: archived.value,
                    intermediatePayments: schedule.map(s => ({
                        id: s.id || '',
                        description: s.description.value,
                        amount: s.amount.value,
                        amountType: s.amountType.value,
                        dueInterval: s.dueInterval.value,
                        dueIntervalType: s.dueIntervalType.value
                    }))
                }
            );
        }
    }

    validateName = (val: string) => v.validate(val, 'name', [v.required], this.props.validationErrors);
    validateEnableForWebBooking = (val: boolean) => v.validate(val, 'enableForWebBooking', [], this.props.validationErrors);
    validateMinimumAmountThreshold = (val: number) => v.validate(val, 'minimumAmountThreshold', [v.required], this.props.validationErrors);
    validateImmediatePaymentAmount = (val: number | null) => v.validate(val, 'immediatePaymentAmount', [], this.props.validationErrors);
    validateImmediatePaymentAmountType = (val: PaymentAmountType) => v.validate(val, 'immediatePaymentAmountType', [v.required], this.props.validationErrors);
    validateFinalPaymentDescription = (val: string) => v.validate(val, 'finalPaymentDescription', [v.required], this.props.validationErrors);
    validateFinalPaymentDueInterval = (val: number) => v.validate(val, 'finalPaymentDueInterval', [v.required], this.props.validationErrors);
    validateFinalPaymentDueIntervalType = (val: PaymentIntervalType) => v.validate(val, 'finalPaymentDueIntervalType', [v.required], this.props.validationErrors);

    validateScheduleDescription = (scheduleKey: string, val: string) => v.validate(val, `${scheduleKey}_description`, [v.required], this.props.validationErrors);
    validateSchedulePaymentAmount = (scheduleKey: string, val: number) => v.validate(val, `${scheduleKey}_amount`, [v.required], this.props.validationErrors);
    validateSchedulePaymentAmountType = (scheduleKey: string, val: PaymentAmountType) => v.validate(val, `${scheduleKey}_amountType`, [v.required], this.props.validationErrors);
    validateScheduleDueInterval = (scheduleKey: string, val: number) => v.validate(val, `${scheduleKey}_dueInterval`, [v.required], this.props.validationErrors);
    validateScheduleDueIntervalType = (scheduleKey: string, val: PaymentIntervalType) => v.validate(val, `${scheduleKey}_dueIntervalType`, [v.required], this.props.validationErrors);

    updateScheduleDescription = (key: string, val: string) => this.setState(s => ({ schedule: s.schedule.map(sch => sch.key === key ? { ...sch, description: this.validateScheduleDescription(sch.key, val) } : sch) }))
    updateScheduleAmount = (key: string, val: number) => this.setState(s => ({ schedule: s.schedule.map(sch => sch.key === key ? { ...sch, amount: this.validateSchedulePaymentAmount(sch.key, val) } : sch) }))
    updateSchduleAmountType = (key: string, val: PaymentAmountType) => this.setState(s => ({ schedule: s.schedule.map(sch => sch.key === key ? { ...sch, amountType: this.validateSchedulePaymentAmountType(sch.key, val) } : sch) }))
    updateSchdueDueInterval = (key: string, val: number) => this.setState(s => ({ schedule: s.schedule.map(sch => sch.key === key ? { ...sch, dueInterval: this.validateScheduleDueInterval(sch.key, val) } : sch) }))
    updateScheduleDueIntervalType = (key: string, val: PaymentIntervalType) => this.setState(s => ({ schedule: s.schedule.map(sch => sch.key === key ? { ...sch, dueIntervalType: this.validateScheduleDueIntervalType(sch.key, val) } : sch) }))

    removeScheduleItem = (key: string) => this.setState(s => ({ schedule: s.schedule.filter(sch => sch.key !== key) }))
    addScheduleItem = () => this.setState(s => ({ schedule: s.schedule.concat([this.createSchedule(null, '', 0, PaymentAmountType.FixedAmount, 1, PaymentIntervalType.BeforeEventDate)]) }))

    render() {

        const { t } = this.context;
        const { isNew, saveError, saveComplete } = this.props;
        const { name, enableForWebBooking, minimumAmountThreshold, immediatePaymentAmount, immediatePaymentAmountType, finalPaymentDescription,
            finalPaymentDueInterval, finalPaymentDueIntervalType, schedule, archived, formError } = this.state;

        let message: any;

        if (!isNullOrEmpty(formError)) {
            message = (<div className='alert alert-danger'>{t(formError)}</div>);
        } else if (saveError) {
            message = <ApiError error={saveError} />;
        } else if (saveComplete) {
            message = (<div className='bg-success'>{t('Global:saveComplete')}</div>);
        }

        const paymentTypeOptions = [PaymentAmountType.FixedAmount, PaymentAmountType.AmountPerPerson, PaymentAmountType.Percentage].map(pt => ({ key: pt.toString(), name: t(`PaymentAmountType:${PaymentAmountType[pt]}`) }));
        const dueIntervalTypeOptions = [PaymentIntervalType.AfterBookingDate, PaymentIntervalType.BeforeEventDate].map(it => ({ key: it.toString(), name: t(`PaymentIntervalType:${PaymentIntervalType[it]}`) }));

        const numberBoxStyle = { width: '100px' };

        return <div className='paymentSchedulePage'>
            <h1 className='paymentSchedule_title'>{isNew ? t('PaymentScheduleForm:addPaymentSchedule') : t('PaymentScheduleForm:editPaymentSchedule')}</h1>

            <form className='data-form' onSubmit={this.savePaymentSchedule} autoComplete='off'>
                <ct.TextBox id='name' labelKey='Global:name' placeholderKey='Global:name' value={name} callback={val => this.setState({ name: this.validateName(val) })} />

                <ct.Checkbox id='enableForWebBooking' labelKey='PaymentScheduleForm:enableForWebBooking' value={enableForWebBooking} callback={val => this.setState({ enableForWebBooking: this.validateEnableForWebBooking(val) })} />

                <ct.NumberBox id='minimumAmountThreshold' labelKey='PaymentScheduleForm:minimumAmountThreshold' placeholderKey='PaymentScheduleForm:minimumAmountThreshold' value={minimumAmountThreshold} callback={val => this.setState({ minimumAmountThreshold: this.validateMinimumAmountThreshold(val || 0) })} min='0' />

                <label>{t('PaymentScheduleForm:paymentSchedule')}</label>
                <table className='table table-condensed'>
                    <thead>
                        <tr key='header'>
                            <th>{t('PaymentScheduleForm:payment')}</th>
                            <th>{t('PaymentScheduleForm:description')}</th>
                            <th colSpan={2}>{t('PaymentScheduleForm:payment')}</th>
                            <th colSpan={2}>{t('PaymentScheduleForm:due')}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr key='initial_payument'>
                            <td>{t('PaymentScheduleForm:immediatePayment')}</td>
                            <td></td>
                            <td style={numberBoxStyle}><ct.NumberBox id='immediatePaymentAmount' labelKey='' value={immediatePaymentAmount} callback={val => this.setState({ immediatePaymentAmount: this.validateImmediatePaymentAmount(val) })} min='0' /></td>
                            <td><ct.Select id='immediatePaymentAmountType' labelKey='' options={paymentTypeOptions} value={{ ...immediatePaymentAmountType, value: immediatePaymentAmountType.value.toString() }} callback={val => this.setState({ immediatePaymentAmountType: this.validateImmediatePaymentAmountType(parseInt(val)) })} /></td>
                            <td colSpan={2}>{t('PaymentScheduleForm:whenBookingTaken')}</td>
                            <td></td>
                        </tr>

                        {schedule.map(s => <tr key={s.id}>
                            <td></td>
                            <td><ct.TextBox id={`${s.key}_description`} labelKey='' value={s.description} callback={val => this.updateScheduleDescription(s.key, val)} /></td>
                            <td style={numberBoxStyle}><ct.NumberBox id={`${s.key}_amount`} labelKey='' value={s.amount} callback={val => this.updateScheduleAmount(s.key, val || 0)} min='0' /></td>
                            <td><ct.Select id={`${s.key}_amountType`} labelKey='' options={paymentTypeOptions} value={{ ...s.amountType, value: s.amountType.value.toString() }} callback={val => this.updateSchduleAmountType(s.key, parseInt(val))} /></td>
                            <td style={numberBoxStyle}><ct.NumberBox id={`${s.key}_dueInterval`} labelKey='' value={s.dueInterval} callback={val => this.updateSchdueDueInterval(s.key, val || 0)} min='0' /></td>
                            <td><ct.Select id={`${s.key}_dueIntervalType`} labelKey='' options={dueIntervalTypeOptions} value={{ ...s.dueIntervalType, value: s.dueIntervalType.value.toString() }} callback={val => this.updateScheduleDueIntervalType(s.key, parseInt(val))} /></td>
                            <td><span onClick={e => clickHandler(e, () => this.removeScheduleItem(s.key))} className='glyphicon glyphicon-trash red' style={({ cursor: 'pointer', padding: '5px' })}></span></td>
                        </tr>)}

                        <tr key='final_payment'>
                            <td>{t('PaymentScheduleForm:finalPayment')}</td>
                            <td><ct.TextBox id='finalPaymentDescription' labelKey='' value={finalPaymentDescription} callback={val => this.setState({ finalPaymentDescription: this.validateFinalPaymentDescription(val) })} /></td>
                            <td colSpan={2}>{t('PaymentScheduleForm:remainingBalance')}</td>
                            <td style={numberBoxStyle}><ct.NumberBox id='finalPaymentDueInterval' labelKey='' value={finalPaymentDueInterval} callback={val => this.setState({ finalPaymentDueInterval: this.validateFinalPaymentDueInterval(val || 0) })} min='0' /></td>
                            <td><ct.Select id='finalPaymentDueIntervalType' labelKey='' options={dueIntervalTypeOptions} value={{ ...finalPaymentDueIntervalType, value: finalPaymentDueIntervalType.value.toString() }} callback={val => this.setState({ finalPaymentDueIntervalType: this.validateFinalPaymentDueIntervalType(parseInt(val)) })} /></td>
                            <td></td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr key='footer'>
                            <td colSpan={6}>
                                <button className='btn btn-link' onClick={e => clickHandler(e, this.addScheduleItem)}>{t('PaymentScheduleForm:AddScheduleItem')}</button>
                            </td>
                        </tr>
                    </tfoot>
                </table>
                

                <ct.Checkbox id='archived' labelKey='Global:archive' value={archived} callback={val => this.setState({ archived: ct.asFormValue('archived', val) })} />

                {message}

                <p />
                <div className='btn-toolbar'>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)}>{t('Global:save')}</button> 
                    <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{t('Global:cancel')}</button> 
                </div>
            </form>
        </div>;
    }
};

const mapStateToProps = (state: ApplicationState) => state.paymentSchedules;
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(Object.assign({}, PaymentScheduleActions.actionCreators, ModalActions.actionCreators), dispatch);

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,           // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(PaymentScheduleForm);
