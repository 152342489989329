
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as v from '../../global/validation';
import * as api from '../../../store/apiClient';

import { Bill, BillRefund, GatewayRefundStatus } from '../../../store/pages/pointOfSale/types';
import { clickHandler } from '../../../utils/util';
import ApiError from '../../global/apiError';
import ConfirmVoidItem from './confirmVoidItem';
import { ValidationError } from '../../../store/global/types';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';

interface EditBillRefundProps {
    bill: Bill;
    refund: BillRefund;
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
    voidRefund: (billId: string, refundId: string, voidReason: string, callback: (success: boolean, error: api.ApiError | null) => void) => void;
    close: () => void;
}

interface EditBillRefundState {
    confirmVoid: boolean;
    saving: boolean;
    error: string | null;
    saveError: api.ApiError | null;
    saveValidationErrors: ValidationError[];
}

export default class EditBillRefund extends React.Component<EditBillRefundProps, EditBillRefundState> {

    constructor(props: EditBillRefundProps) {
        super(props);

        this.state = {
            confirmVoid: false,
            saving: false,
            error: null,
            saveError: null,
            saveValidationErrors: [],
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    voidRefund = () => this.setState({ confirmVoid: true });

    closeVoidPayment = () => this.setState({ confirmVoid: false });

    confirmVoidRefund = (reason: string) => {
        const { bill, voidRefund, refund } = this.props;

        this.setState({ saving: true, error: null, saveError: null });
        voidRefund(bill.id, refund.id, reason, this.onRefundSaved);
    }

    onRefundSaved = (success: boolean, error: api.ApiError | null) => {
        this.setState({ saving: false, error: null, saveError: error });
        if (success) {
            this.props.close();
        }
    }

    validateReason = (val: string) => v.validate(val, 'reason', [v.required], []);

    render() {
        const { refund, timeFormat, dateFormat } = this.props;
        const { confirmVoid, saving, error, saveError } = this.state;
        const { t } = this.context;

        return (
            <div style={({ width: '100%', position: 'relative' })}>
                <div style={({ maxWidth: '480px', margin: '0 auto' })}>
                    <h3>{t('PointOfSale:editRefund')}</h3>

                    <form className='data-form' onSubmit={e => e.preventDefault()} autoComplete='off'>

                        {this.renderVoidDetails(refund, timeFormat, dateFormat)}

                        <div className='row'>
                            <div className='col-sm-12'>
                                <label>{t('PointOfSale:amountRefunded')}</label>
                                <div>{t('Global:currencySymbol')}{refund.amount.toFixed(2)}</div>
                            </div>
                        </div>

                        <div className='row mt-15'>
                            <div className='col-sm-12'>
                                <label>{t('PointOfSale:refundReason')}</label>
                                <div>{refund.reason}</div>
                            </div>
                        </div>

                        <div className='row mt-15'>
                            <div className='col-sm-12'>
                                <label>{t('PointOfSale:refundMethod')}</label>
                                <div>{refund.paymentMethodName}</div>
                            </div>
                        </div>

                        {refund.gatewayRefundAttempts.length > 0
                            ? <table className='table table-condensed'>
                            <thead>
                                <tr>
                                </tr>
                            </thead>
                                <tbody>
                                {
                                        refund.gatewayRefundAttempts.map(gr => <tr key={gr.id}>
                                            <td className='nowrap'>{gr.transactionDateTime ? gr.transactionDateTime.toAbbrDateTimeString(timeFormat, dateFormat, t) : null}</td>
                                            <td className='nowrap'>{this.renderGatewayRefundStatus(gr.status)}</td>
                                            <td className='nowrap'>{gr.issuerRefundId}</td>
                                            <td className='nowrap'>{t('Global:currencySymbol')}{gr.amountRefunded.toFixed(2)}</td>
                                            <td>{gr.failureReason}</td>
                                    </tr>)
                                }
                                </tbody>
                            </table>
                            : null
                        }

                        <div className='row mt-15'>
                            <div className='col-xs-12'>
                                {error ? <div className='alert alert-danger'>{t(error)}</div> : null}
                                {saveError ? <ApiError error={saveError} /> : null}
                            </div>
                        </div>
                        <div className='row button-panel'>
                            {!refund.void && refund.gatewayRefundAttempts.length === 0 ? <button onClick={e => clickHandler(e, this.voidRefund)} className='btn btn-danger' disabled={saving}>{t('Global:void')}</button> : null}
                            <button onClick={e => clickHandler(e, this.props.close)} className='btn btn-basic' disabled={saving}>{t('Global:close')}</button>
                        </div>
                        {this.renderOverlay(confirmVoid)}
                    </form>
                </div>
            </div>
        );
    }

    renderGatewayRefundStatus = (status: GatewayRefundStatus) => {
        const { t } = this.context;

        let labelType = '';

        switch (status) {
            case GatewayRefundStatus.Pending:
                labelType = 'label-warning';
                break;
            case GatewayRefundStatus.Succeeded:
                labelType = 'label-success';
                break;
            case GatewayRefundStatus.Failed:
                labelType = 'label-danger';
                break;
            case GatewayRefundStatus.Cancelled:
                labelType = 'label-danger';
                break;
        }

        return <span className={`label ${labelType}`}>{t(`GatewayRefundStatus:${GatewayRefundStatus[status]}`)}</span>
    }

    renderVoidDetails = (refund: BillRefund, timeFormat: TimeFormat, dateFormat: DateFormat) => {
        if (!refund.void) {
            return null;
        }

        return <div className='alert alert-danger'>
            {refund.voidedDateTime ? this.renderInfo('PointOfSale:voidedBy', `${refund.voidedBy || ''} - ${refund.voidedDateTime.toLongDateString(dateFormat, this.context.t)} ${refund.voidedDateTime.toShortTimeString(timeFormat)}`) : null}
            {this.renderInfo('PointOfSale:voidReason', refund.voidReason || '')}
        </div>
    }

    renderInfo = (labelKey: string, content: string | React.ReactNode | null) => {
        if (!content) {
            return null;
        }

        return (
            <div className='row'>
                <div className='col-sm-4'>
                    <label>{this.context.t(labelKey)}:</label>
                </div>
                <div className='col-sm-8'>
                    {typeof content === 'string' ? <span className='wrap-text'>{content}</span> : content}
                </div>
            </div>
        );
    };

    renderOverlay = (showOverlay: boolean) => {
        if (showOverlay) {
            const { saving, saveError, saveValidationErrors } = this.state;

            return <ConfirmVoidItem
                headerKey='PointOfSale:confirmVoidRefund'
                confirmButtonKey='PointOfSale:yesVoidRefund'
                confirm={this.confirmVoidRefund}
                close={this.closeVoidPayment}
                saveError={saveError}
                saveValidationErrors={saveValidationErrors}
                saving={saving} />
        } else {
            return null;
        }
    }
}