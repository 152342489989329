
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { Booking, Reservation } from './types';
import EventCustomerListItem from './eventCustomerListItem';
import { Bill } from '../../../store/pages/pointOfSale/types';
import { isNullOrEmpty, clickHandler } from '../../../utils/util';
import { EventType, BookingCustomer } from '../../../store/pages/diary/types';
import { ActivityFormat } from '../../../store/pages/activityFormats/types';
import { Product } from '../../../store/pages/products/types';
import { bookingComparer, buildBookingCustomerInfo } from './helpers';
import { CustomerCategory } from '../../../store/pages/customerCategories/types';
import { Resource } from '../../../store/pages/resources/types';

interface EventCustomersProps {
    defaultCountryId: number;
    bookings: Booking[];
    bills: Bill[];
    reservations: Reservation[];
    resources: Resource[];
    activityFormats: ActivityFormat[];
    products: Product[];
    customerCategories: CustomerCategory[];
    canAddCustomer: boolean;
    eventType: EventType;
    numberOfParticipants: number;
    selectedBookingId: string;
    selectBooking: (booking: Booking) => void;
    editBookingQuestions: (booking: Booking) => void;
    addBooking: () => void;
    editBooking: (booking: Booking) => void;
    removeBooking: (index: number) => void;
    confirmRemoveBooking: (index: number) => void;
    cancelRemoveBooking: (index: number) => void;
}

interface EventCustomersState {
    errorMessageKey: string;
    showCancelled: boolean;
}

class EventCustomers extends React.Component<EventCustomersProps, EventCustomersState> {

    today: Date;

    constructor(props: EventCustomersProps) {
        super(props);

        this.state = { errorMessageKey: '', showCancelled: false };

        this.today = new Date().datePart();
    }

    static contextTypes = {
        t: PropTypes.func
    }

    editBooking = (customer: BookingCustomer) => {
        //this.props.editBooking({ id: '', key: '', billId: '', cancelled: false, notes: '', customer: customer, confirmDelete: false });
        //this.props.closeModal();
    }

    showAddCustomer = () => {
        const { canAddCustomer, addBooking } = this.props;
        if (!canAddCustomer) {
            this.setState({ errorMessageKey: 'EventCustomers:eventNameValidationsError' });
        } else {
            addBooking();
        }
    }

    findBill = (bills: Bill[], billId?: string) => {
        const bill = bills.filter(b => b.id === billId)[0];
        if (!bill) {
            console.log(`WARNING: No bill found for bill id ${billId}.  billd.length : ${bills.length}`);
        }

        return bill;
    }

    showCancelledBookings = () => this.setState({showCancelled: true})

    render() {
        const { t } = this.context;
        const { bookings, canAddCustomer } = this.props;

        const errorMessage = !canAddCustomer && !isNullOrEmpty(this.state.errorMessageKey)
            ? <div className='alert alert-danger'>{t(this.state.errorMessageKey)}</div>
            : null;

        return <>
            {this.renderCustomers(bookings)}

            <div className='form-group text-center'>
                <button className='btn btn-primary' onClick={e => clickHandler(e, this.showAddCustomer)}>{t('EventCustomers:addCustomer')}</button>
            </div>

            {errorMessage}
        </>;
    }

    renderCustomers = (bookings: Booking[]) => {
        const { t } = this.context;
        const { bills, selectedBookingId, reservations, customerCategories, removeBooking, confirmRemoveBooking, cancelRemoveBooking, editBookingQuestions } = this.props;
        const { showCancelled } = this.state;
        const mappedBookings = bookings.map(b => ({ ...b, bill: this.findBill(bills, b.billId) }));
        const cancelledBookings = bookings.filter(b => b.cancelled);
        const activeBookings = mappedBookings.filter(b => !b.cancelled);

        const reservationIds = reservations.map(r => r.id);

        const categories = customerCategories.filter(cc => {
            return mappedBookings
                .filter(c => !c.cancelled)
                .reduce<boolean>((found, b) => {
                    return found || (b.bill && b.bill.items.findIndex(i => reservationIds.indexOf(i.reservationId) >= 0 && i.customerCategoryId === cc.id) >= 0)
                }, false)
        });

        const customerBookings = mappedBookings.map(b => buildBookingCustomerInfo(b, b.bill, reservations, categories));

        return (<table className='table table-condensed'>
            <thead>
                <tr key='header'>
                    <th></th>
                    {categories.map(c => {
                        const count = customerBookings.reduce((ttl, b) => ttl + (b.customerCategoryCounts[c.code] ? b.customerCategoryCounts[c.code] : 0), 0)
                        return <th key={ c.id} className='text-right nowrap'>{`${count > 0 ? count.toFixed(0) : ''} ${c.code}`}</th>
                    })}
                </tr>
            </thead>
            <tbody>
                {customerBookings
                    .filter(b => showCancelled || !b.booking.cancelled)
                    .sort((b1,b2)  => bookingComparer(b1.booking, b2.booking))
                    .map((booking, ix) => <EventCustomerListItem
                        key={booking.booking.key}
                        bookingInfo={booking}
                        index={ix}
                        confirmRemoveBooking={confirmRemoveBooking}
                        cancelRemoveBooking={cancelRemoveBooking}
                        removeBooking={removeBooking}
                        isSelected={booking.booking.id === selectedBookingId}
                        selectBooking={this.props.selectBooking}
                        customerCategories={categories}
                        editBookingQuestions={editBookingQuestions}
                />)}
                {!showCancelled && cancelledBookings.length > 0 ? <tr key='showCancelledBookings'><td colSpan={customerCategories.length + 1}><button className='btn btn-link' onClick={e => clickHandler(e, this.showCancelledBookings)}>{t('EventCustomers:showCancelled', { count: cancelledBookings.length})}</button></td></tr> : null}
            </tbody>
            {activeBookings.length > 1
                ? <tfoot>
                    <tr key='total'>
                        <td colSpan={customerCategories.length + 1} className='text-right'><strong>{t('PointOfSale:total')}: {t('Global:currencySymbol')} {activeBookings.reduce<number>((ttl, b) => ttl + (b.bill ? b.bill.totalAmount : 0), 0).toFixed(2)}</strong></td>
                    </tr>
                    <tr key='outstanding'>
                        <td colSpan={customerCategories.length + 1} className='text-right text-danger' style={({ borderTop: 0, paddingTop: '0' })}><strong>{t('PointOfSale:balance')}: {t('Global:currencySymbol')} {activeBookings.reduce<number>((ttl, b) => ttl + (b.bill ? b.bill.outstandingBalance : 0), 0).toFixed(2)}</strong></td>
                    </tr>
                </tfoot>
                : null
            }
        </table>);
    }
}

export default EventCustomers;