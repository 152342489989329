
import * as bat from './types'; 

export type BillActions = bat.BillChanged;

export const actionCreators = {
    billChanged: (billId: string) => {
        return ({ type: bat.BillActionTypes.BillChanged, billId: billId })
    },
}
