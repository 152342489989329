
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { ITranslationContext } from '../../../translations';
import { Resource } from '../../../store/pages/resources/types';
import { CustomerCategoryCount, ReservationType } from '../../../store/pages/diary/types';
import { ActivityFormat } from '../../../store/pages/activityFormats/types';
import { isNullOrEmpty } from '../../../utils/util';
import TagLabel from '../../global/tagLabel';
import { Venue } from '../../../store/pages/venues/types';

export interface IReservationSummary {
    key: string;
    resourceId: string;
    resourceConfigurationId: string | null;
    activityFormatId: string;
    activityFormatVariationId: string;
    membershipTypeId: string | null;
    membershipTypeLabel: string | null;
    membershipTypeLabelColour: string | null;
    reservationType: ReservationType;
    bookedParticipants: CustomerCategoryCount[];
    maxParticipants: number;
    startTime: Date;
    endTime: Date;
}

interface ReservationSummaryProps {
    reservations: IReservationSummary[];
    resources: Resource[];
    activityFormats: ActivityFormat[];
    venue: Venue;
}


const ReservationSummary = (props: ReservationSummaryProps, context: ITranslationContext) => {
    const { reservations, resources, activityFormats, venue } = props;
    const { t } = context;

    const { timeFormat, dateFormat } = venue;

    return <table>
        <tbody>
            {reservations.map(r => {
                const resource = resources.find(res => res.id === r.resourceId);
                const resourceConfig = resource && resource.configurations ? resource.configurations.find(c => c.id === r.resourceConfigurationId) : null;
                const bookedParticipants = r.bookedParticipants.reduce((ttl, cp) => ttl + cp.count, 0);
                const participants = r.reservationType === ReservationType.Exclusive ? `${bookedParticipants}` : `${bookedParticipants}/${r.maxParticipants}`;

                const selectedActivityFormat = activityFormats.find(af => af.id === r.activityFormatId);
                const activityFormatName = selectedActivityFormat ? selectedActivityFormat.name : '';

                const variation = selectedActivityFormat ? selectedActivityFormat.variations.find(v => v.id === r.activityFormatVariationId) : null;
                const variationDetails = variation && selectedActivityFormat && selectedActivityFormat.variations.length > 1 ? `[${variation.minParticipants} - ${variation.maxParticipants}]` : '';

                const cellStyle = { padding: '0 6px' }

                const isOverBooked = r.reservationType === ReservationType.Exclusive
                    ? variation && bookedParticipants > variation.maxParticipants
                    : bookedParticipants > r.maxParticipants;

                const participantsStyle = isOverBooked ? { fontSize: 'larger', color: '#fff', backgroundColor: '#f7a414', padding: '0 4px' } : { fontSize: 'larger', }

                const overbookedCategories = selectedActivityFormat
                    ? selectedActivityFormat.customerCategories.map(cc => {
                        const booked = r.bookedParticipants.filter(p => p.categoryId === cc.customerCategoryId).reduce((ttl, p) => ({ count: ttl.count + p.count, name: p.categoryName }), { count: 0, name: '' });
                        return { categoryName: booked.name, overbooked: cc.maxOverallQuantity > 0 && booked.count > (cc.maxOverallQuantity * cc.placesToBook) }
                    }).filter(x => x.overbooked)
                    : [];

                const warning = overbookedCategories.length > 0 ? t('Global:warningCategoryOverbooked', { categoryName: overbookedCategories[0].categoryName }) : isOverBooked ? t('Global:warningOverbooked') : '';

                return (
                    <tr key={r.key}>
                        <td className='shrink' style={cellStyle}>{r.startTime.toAbbrDateStringWithDay(dateFormat, t)} {r.startTime.toShortTimeString(timeFormat)} - {r.endTime.toShortTimeString(timeFormat)}</td>
                        <td className='shrink' style={cellStyle}>{resourceConfig ? resourceConfig.name : resource ? resource.name : ''}</td>
                        <td className='expand' style={cellStyle}>{activityFormatName} {variationDetails}</td>
                        <td className='shrink'>{!isNullOrEmpty(warning) ? <span style={{ color: '#f7a414' }}><span className='glyphicon glyphicon-warning-sign' style={{ marginRight: '4px' }}> </span>{warning}</span> : null }</td>
                        <td className='shrink text-right' style={cellStyle}>
                            {r.membershipTypeId && r.membershipTypeLabel && r.membershipTypeLabelColour ? <TagLabel name={r.membershipTypeLabel} colour={r.membershipTypeLabelColour} /> : null}
                            {renderReservatiopnTypeLabel(r.reservationType, t)}
                            <span className='glyphicon glyphicon-user'></span> <span style={participantsStyle}>{participants}</span>
                        </td>
                    </tr>
                );
            })}
        </tbody>
    </table>;
}

const renderReservatiopnTypeLabel = (reservationType: ReservationType, t: (key: any, params?: any, comment?: string) => string) => {
    switch (reservationType) {
        case ReservationType.Exclusive:
            return <span className='label label-default label-xs' style={({marginRight: '4px'})}>{t('Global:exclusiveShort')}</span>
        case ReservationType.NonExclusive:
            return <span className='label label-success label-xs' style={({ marginRight: '4px' })}>{t('Global:sharedShort')}</span>
        default:
            return null;
    }
}

ReservationSummary.contextTypes = {
    t: PropTypes.func
}

export default ReservationSummary;