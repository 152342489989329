
import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types'

import { ApplicationState } from '../../../store';
import {Venue, VenueRegistrationKiosk} from '../../../store/pages/venues/types';
import { ResourceState } from '../../../store/pages/resources/types';
import * as ModalActions from '../../../store/global/modal/actions';
import RegistrationSettingsForm from './registrationSettingsForm';
import { clickHandler } from '../../../utils/util';
import * as auth from '../../../utils/auth';

interface LocalProps {
    venue: Venue
}

type RegistrationPanelProps = ResourceState
    & typeof ModalActions.actionCreators
    & LocalProps;

class RegistrationPanel extends React.Component<RegistrationPanelProps, {}> {

    static contextTypes = {
        t: PropTypes.func
    }

    addKiosk = () => {
        this.edit(this.createEmptyKioskSettings());
    }

    edit = (kiosk: VenueRegistrationKiosk) => {
        this.props.showModal(<RegistrationSettingsForm venueId={this.props.venue.id} kiosk={kiosk} />, 'RegistrationSettingsForm');
    }

    createEmptyKioskSettings = (): VenueRegistrationKiosk => ({
        registrationKioskId: null,
        kioskName: '',
        registrationImageId: null,
        registrationCode: null,
        registrationFields: 0,
        requiredRegistrationFields: 0,
        finalInstructions: '',
        resultsMarketingText: '',
        generalMarketingText: '',
        welcomeImageInterval: 8,
        welcomeScreenImageIds: [],
        welcomeScreenImageUrls: [],
        welcomeText: '',
        nameStepHeading: '',
        addressStepHeading: '',
        returningCustomerStepHeading: '',
        contactPreferencesStepHeading: '',
        enablePhotos: false,
        requireSignatures: false,
        registrationTermsId: '',
        requireAddressForChildren: true,
        createDateTime: new Date(),
        lastUpdated: null
    });

    render() {
        const { venue } = this.props;
        const { t } = this.context;

        const tdStyle = { verticalAlign: 'middle' };

        return (
            <section className='venue-registration-panel'>
                <div className='at-panel'>
                    <table className='table table-condensed'>
                        <thead>
                            <tr>
                                <th>{t('VenueRegistrationPanel:nameHeading')}</th>
                                <th>{t('VenueRegistrationPanel:codeHeading')}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {venue.registrationKiosks
                                .sort((k1, k2) => k1.createDateTime.getTime() - k2.createDateTime.getTime())
                                .map((k, ix) => <tr key={k.registrationKioskId || ix.toFixed(0)}>
                                <td className='shrink' style={tdStyle}>{k.kioskName}</td>
                                <td className='shrink' style={tdStyle}>{k.registrationCode}</td>
                                <td className='expand text-left' style={tdStyle}><button className='btn btn-info btn-sm' onClick={e => clickHandler(e, () => this.edit(k))}>{this.context.t('Global:edit')}</button></td>
                            </tr>)}
                        </tbody>
                    </table>

                    {auth.isSystemAdmin() || venue.registrationKiosks.length === 0 ? <button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, this.addKiosk)}>{this.context.t('VenueRegistrationPanel:addKiosk')}</button> : null}
                </div>
            </section>);
    }
}


const mapStateToProps = (state: ApplicationState) => state.resources;


// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,                    // Selects which state properties are merged into the component's props
    ModalActions.actionCreators,        // Selects which action creators are merged into the component's props
)(RegistrationPanel);
