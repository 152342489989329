
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { Bill, deserializeBill, getBillTotal } from '../../../store/pages/pointOfSale/types';
import { getWithAuth } from '../../../store/apiClient';
import { clickHandler, isNullOrEmpty } from '../../../utils/util';

import BillSearch from './billSearch';

interface BillSelectionProps {
    venueId: string;
    selectBill: (billId: string) => void;
    logout: () => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
}

interface BillSelectionState {
    bills: Bill[];
}

export interface IFindBillsResponse {
    bills: Bill[];
}

interface EventGrouping {
    [key: string]: Bill[];
}

export default class BillSelection extends React.Component<BillSelectionProps, BillSelectionState> {

    constructor(props: BillSelectionProps) {
        super(props);

        this.state = { bills: [] };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        const { venueId } = this.props;
        this. loadBills(venueId);
    }

    componentDidUpdate(prevProps: BillSelectionProps) {
        if (prevProps.venueId !== this.props.venueId) {
            this.loadBills(this.props.venueId);
        }
    }

    loadBills = (venueId: string) => {
        const { logout } = this.props;
        getWithAuth<IFindBillsResponse>(`api/v1/bill/find/${venueId}/${new Date().toYMDDateString()}`, () => logout)
            .subscribe(result => this.setState({ bills: result.bills.map(b => deserializeBill(b)) }), err => { });
    }

    newBill = () => {
        this.props.selectBill('');
    }

    searchCustomer = () => {
        const { venueId, logout, selectBill, showModal, closeModal } = this.props;

        const showBill = (billId: string) => {
            closeModal();
            selectBill(billId);
        }

        showModal(<BillSearch venueId={venueId} logout={logout} viewBill={showBill} close={closeModal} />, 'BillSearch')
    }

    render() {
        const { t } = this.context;
        const { bills } = this.state;
        return (
            <div className='pos-bill-selection'>
                <div className='pos-bill-selection-header'>
                    <div>
                        <button className='btn btn-success btn-block pos-btn pos-selection-btn' onClick={this.newBill}>{t('PointOfSale:newBill')}</button>
                    </div>
                    <div>
                        <button className='btn btn-basic btn-block pos-btn pos-selection-btn' onClick={this.searchCustomer}>{t('PointOfSale:search')}</button>
                    </div>
                </div>

                <div className='pos-bill-selection-content'>
                    <div>
                        <h4 className='text-center bg-primary'>{t('PointOfSale:openBills')}</h4>
                        {this.renderBills(bills.filter(b => b.outstandingBalance > 0 && !b.bookingId && !b.webShopBasket).sort((a, b) => b.createDateTime.getTime() - a.createDateTime.getTime()))}

                        <h4 className='text-center bg-primary'>{t('PointOfSale:recentBills')}</h4>
                        {this.renderBills(bills.filter(b => b.outstandingBalance <= 0 && !b.bookingId && !b.webShopBasket).sort((a, b) => b.createDateTime.getTime() - a.createDateTime.getTime()))}

                        <h4 className='text-center bg-primary'>{t('PointOfSale:webShopBills')}</h4>
                        {this.renderBills(bills.filter(b => b.webShopBasket).sort((a, b) => {
                            const balComparison = b.outstandingBalance - a.outstandingBalance;

                            return balComparison !== 0
                                ? balComparison
                                : b.createDateTime.getTime() - a.createDateTime.getTime();
                        }))}
                    </div>
                    <div>
                        <h4 className='text-center bg-primary'>{t('PointOfSale:eventBills')}</h4>
                        {this.renderEventBills(bills.filter(b => b.bookingId && !b.webShopBasket))}
                    </div>
                </div>
            </div>
        );
    }

    renderBills = (bills: Bill[]) => {
        const { t } = this.context;
        return (
            <table className='table table-striped table-sm table-condensed'>
                <thead>
                    <tr>
                        <th>{t('Global:number')}</th>
                        <th>{t('PointOfSale:customerHeading')}</th>
                        <th className='text-right'>{t('Global:amount')}</th>
                        <th className='text-right'>{t('PointOfSale:outstandingAmountHeading')}</th>
                    </tr>
                </thead>
                <tbody>
                    {bills.map(b => (
                        <tr key={b.id} onClick={e => clickHandler(e, () => this.props.selectBill(b.id))}>
                            <td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.props.selectBill(b.id))}>{b.billNumber}</button></td>
                            <td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.props.selectBill(b.id))}>{isNullOrEmpty(b.customerName) ? b.customerEmail : b.customerName}</button></td>
                            <td className='text-right'><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.props.selectBill(b.id))}>{t('Global:currencySymbol')}{getBillTotal(b).toFixed(2)}</button></td>
                            <td className='text-right'><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.props.selectBill(b.id))}><span className={`${b.outstandingBalance > 0 ? ' text-danger highlight' : ''}`}>{t('Global:currencySymbol')}{b.outstandingBalance.toFixed(2)}</span></button></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    renderEventBills = (bills: Bill[]) => {
        const { t } = this.context;

        const groupedBills: EventGrouping = bills.reduce(function (grouping: EventGrouping, bill) { 
            if (bill.bookingId) {
                (grouping[bill.bookingId] = grouping[bill.bookingId] || []).push(bill);
            }
            return grouping;
        }, {});

        return (
            <table className='table table-striped table-sm table-condensed'>
                <thead>
                    <tr>
                        <th>{t('Global:number')}</th>
                        <th>{t('PointOfSale:customerHeading')}</th>
                        <th className='text-right'>{t('Global:amount')}</th>
                        <th className='text-right'>{t('PointOfSale:outstandingAmountHeading')}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Object.keys(groupedBills)
                            .map(eventId => ({ eventId: eventId, bills: groupedBills[eventId] }))
                            .filter(x => x.bills.length > 0)
                            .map(x => {
                                const eventId = x.eventId;
                                const eventName = x.bills[0].eventName;
                                return [(
                                    <tr key={eventId}>
                                        <td colSpan={4}>{eventName}</td>
                                    </tr>
                                )].concat(x.bills.map(b => (
                                    <tr key={`${eventId}_${b.id}`} onClick={e => clickHandler(e, () => this.props.selectBill(b.id))}>
                                        <td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.props.selectBill(b.id))} style={({ marginLeft: '25px' })}>{b.billNumber}</button></td>
                                        <td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.props.selectBill(b.id))}>{b.customerName}</button></td>
                                        <td className='text-right'><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.props.selectBill(b.id))}>{t('Global:currencySymbol')}{getBillTotal(b).toFixed(2)}</button></td>
                                        <td className='text-right'><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.props.selectBill(b.id))}>{t('Global:currencySymbol')}{b.outstandingBalance.toFixed(2)}</button></td>
                                    </tr>
                                )));
                            })
                    }
                </tbody>
            </table>
        );
    }
}