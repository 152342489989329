
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { Bill, BillItem, isScheduled, getPaymentDate, BillPayment, BillFee, BillRefund, BillDiscount, BillItemVoucher } from '../../../store/pages/pointOfSale/types';
import { ITranslationContext } from '../../../translations';
import { CustomerCategory } from '../../../store/pages/customerCategories/types';
import BillItemsRow from './billItemsRow';
import BillItemsPaymentRow from './billItemsPaymentRow'
import BillItemsRefundRow from './billItemsRefundRow'
import BillFeeRow from './billFeeRow';
import BillItemsDiscountRow from './billItemsDiscountRow';
import { findLinkedBillItems } from './types';
import { clickHandler } from '../../../utils/util';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';

export interface BillItemsPanelProps {
    bill: Bill;
    customerCategories: CustomerCategory[];
    eventReservationIds: string[] | null;
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
    bookingCancelled: boolean;
    editBillItem?: (item: BillItem) => void;
    editBillPayment?: (payment: BillPayment) => void;
    editBillFee?: (fee: BillFee) => void;
    editRefund: (refund: BillRefund) => void;
    editDiscount?: (discount: BillDiscount | null) => void;
    makePayment?: (payment: BillPayment) => void;
    rescheduleItem?: (item: BillItem) => void;
    viewVoucher?: (voucher: BillItemVoucher) => void;
}

export interface BillItemsPanelState {
    showFailedPayments: boolean;
}

class BillItemsPanel extends React.Component<BillItemsPanelProps, BillItemsPanelState> {

    constructor(props: BillItemsPanelProps) {
        super(props);

        this.state = { showFailedPayments: false }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    renderBillItems = (bill: Bill) => {
        const { items } = bill;

        const { eventReservationIds, bookingCancelled, customerCategories, editBillItem, rescheduleItem, viewVoucher, timeFormat, dateFormat } = this.props;
        const { t } = this.context;

        const { mainEventItems, otherEventItems } = items
            .sort((i1, i2) => i1.primaryBillItemId && i2.primaryBillItemId ? 0 : i1.primaryBillItemId ? 1 : -1)
            .reduce<{ mainEventItems: BillItem[], otherEventItems: BillItem[] }>((acc, item) => {
                const { mainEventItems, otherEventItems } = acc;

                if ((eventReservationIds && item.reservationId && eventReservationIds.indexOf(item.reservationId) >= 0)
                    || (item.primaryBillItemId && mainEventItems.findIndex(i => i.id === item.primaryBillItemId) >= 0)) {
                    mainEventItems.push(item);

                    // Move primary bill item and other children of it from other items to main items
                    if (item.primaryBillItemId) {
                        for (var i = otherEventItems.length; i >= 0; i--) {
                            if (i < otherEventItems.length && (otherEventItems[i].id === item.primaryBillItemId || otherEventItems[i].primaryBillItemId === item.primaryBillItemId)) {
                                mainEventItems.push(otherEventItems[i]);
                                otherEventItems.splice(i, 1);
                            }
                        }
                    }
                } else {
                    otherEventItems.push(item)
                }

                return { mainEventItems, otherEventItems }
            }, { mainEventItems: [], otherEventItems: [] })

        const sortedItems = items.sort(this.sortItems);
        const reservationItems = sortedItems.filter(i => i.reservationStartTime);
        const first = reservationItems.length > 0 ? reservationItems[0] : null;
        const last = reservationItems.length > 0 ? reservationItems[Math.max(0, reservationItems.length - 1)] : null;
        const multiDayEvent = reservationItems.length > 1 && first && first.reservationStartTime && last && last.reservationStartTime ? first.reservationStartTime.daysDifference(last.reservationStartTime) !== 0 : false;

        const renderItems = (items: BillItem[]) => {
            if (items.length === 0)
                return [];

            return items.sort(this.sortItems).map(i => <BillItemsRow key={i.key} item={i} bookingCancelled={bookingCancelled} linkedItems={findLinkedBillItems(i, items)} customerCategories={customerCategories} editBillItem={editBillItem} reschedule={rescheduleItem} viewVoucher={viewVoucher} multiDayEvent={multiDayEvent} timeFormat={timeFormat} dateFormat={dateFormat} />);
        }

        const billItems = otherEventItems.length > 0
            ? renderItems(mainEventItems).concat(<li key='other-event-items-sep' className='pos_bill_item_separator'>{t('PointOfSale:itemsFromOtherEvents')}</li>).concat(renderItems(otherEventItems))
            : renderItems(mainEventItems);

        return billItems;
    }

    sortItems = (i1: BillItem, i2: BillItem) => {

        var startTimeComparison = (i1.reservationStartTime ? i1.reservationStartTime.getTime() : 0) - (i2.reservationStartTime ? i2.reservationStartTime.getTime() : 0);
        if (startTimeComparison !== 0) return startTimeComparison;

        const intermediate = i1.primaryBillItemId && i2.primaryBillItemId ? 0 : i1.primaryBillItemId ? 1 : -1

        if (intermediate !== 0) return intermediate;

        if (i1.customerCategoryId && i2.customerCategoryId) {
            if (i1.customerCategoryId < i2.customerCategoryId) return -1;
            if (i1.customerCategoryId > i2.customerCategoryId) return 1;
            return 0;
        }

        if (i1.productId < i2.productId) return -1;
        else if (i1.productId > i2.productId) return 1;
        return 0;
    }

    renderBillFees = (bill: Bill) => {
        const { fees } = bill;
        const { editBillFee } = this.props;

        const renderItems = (items: BillFee[]) => {
            if (items.length === 0)
                return [];

            return fees.map(f => <BillFeeRow key={f.id} fee={f} editBillFee={editBillFee} />);
        }

        return renderItems(fees);
    }

    renderPayments = (bill: Bill) => {
        const { payments, customerId } = bill;
        const { t } = this.context;
        const { showFailedPayments } = this.state;
        const { timeFormat, dateFormat } = this.props;

        if (payments.length === 0)
            return null;

        const paymentsByType = payments.reduce<{ completePayments: BillPayment[], failedPayments: BillPayment[], scheduledPayments: BillPayment[] }>((acc, p) => {
            if (isScheduled(p)) acc.scheduledPayments.push(p);
            else if (p.paid && !p.void) acc.completePayments.push(p)
            else acc.failedPayments.push(p)

            return acc;
        }, { completePayments: [], failedPayments: [], scheduledPayments: [] })


        const completePayments = paymentsByType.completePayments;
        const scheduledPayments = paymentsByType.scheduledPayments;
        const failedPayments = paymentsByType.failedPayments;

        const completedPaymentItems = completePayments.length > 0
            ? [<li key='payments-header' className='pos_bill_item_separator'>{t('PointOfSale:payments')}</li>]
                .concat(completePayments.sort((p1, p2) => {
                    if (p1.payentTakenDateTime && p2.payentTakenDateTime) return p1.payentTakenDateTime.getTime() - p2.payentTakenDateTime.getTime();
                    if (!p1.payentTakenDateTime) return 1;
                    return -1;
                }).map(p => <BillItemsPaymentRow key={p.key} payment={p} billCustomerId={customerId} editPayment={this.props.editBillPayment} makePayment={this.props.makePayment} timeFormat={timeFormat} dateFormat={dateFormat} />))
            : [];

        const scheduledPaymentItems = scheduledPayments.length > 0
            ? [<li key='scheduled-payments-header' className='pos_bill_item_separator'>{t('PointOfSale:scheduledPayments')}</li>]
                .concat(scheduledPayments.sort((p1, p2) => {
                    const p1Date = getPaymentDate(isScheduled(p1), p1);
                    const p2Date = getPaymentDate(isScheduled(p2), p2);
                    if (p1Date && p2Date) return p1Date.getTime() - p2Date.getTime();
                    if (!p1Date) return 1;
                    return -1;
                }).map(p => <BillItemsPaymentRow key={p.key} payment={p} billCustomerId={customerId} editPayment={this.props.editBillPayment} makePayment={this.props.makePayment} timeFormat={timeFormat} dateFormat={dateFormat} />))
            : [];

        const failedPaymentItems = failedPayments.length > 0
            ? [<li key='failed-payments-header' className='pos_bill_item_separator flex flex-row'><div className='text-danger' style={{ flex: '1 1 auto' }}>{t('PointOfSale:failedPayments', { numberOf: failedPayments.length })}</div><div style={{ flex: '0 0 auto' }}><a href='*' onClick={e => clickHandler(e, () => this.setState({showFailedPayments: !showFailedPayments}))}>{t(showFailedPayments ? 'Global:hide' : 'Global:show')}</a></div></li>]
                .concat(showFailedPayments ? failedPayments.sort((p1, p2) => {
                    const p1Date = getPaymentDate(isScheduled(p1), p1);
                    const p2Date = getPaymentDate(isScheduled(p2), p2);
                    if (p1Date && p2Date) return p1Date.getTime() - p2Date.getTime();
                    if (!p1Date) return 1;
                    return -1;
                }).map(p => <BillItemsPaymentRow key={p.key} payment={p} billCustomerId={customerId} editPayment={this.props.editBillPayment} makePayment={this.props.makePayment} timeFormat={timeFormat} dateFormat={dateFormat} />) : [])
            : [];
        return completedPaymentItems.concat(scheduledPaymentItems).concat(failedPaymentItems);
    }

    renderRefunds = (bill: Bill) => {
        const { refunds } = bill;
        const { t } = this.context;
        const { timeFormat, dateFormat } = this.props;

        if (!refunds || refunds.length === 0)
            return [];

        return [<li key='refunds-header' className='pos_bill_item_separator'>{t('PointOfSale:refunds')}</li>].concat(refunds.map(r => (
            <BillItemsRefundRow key={r.id} refund={r} timeFormat={timeFormat} dateFormat={dateFormat} editRefund={this.props.editRefund} />
        )));
    }

    renderDiscounts = (bill: Bill) => {
        const { discounts } = bill;
        const { editDiscount } = this.props;

        if (!discounts || discounts.length === 0)
            return [];

        return discounts.map(d => (
            <BillItemsDiscountRow key={d.id} discount={d} editDiscount={editDiscount} />
        ));
    }

    render() {
        const { bill } = this.props;

        return (
            <ul className='list-unstyled pos_bill_item_list' >
                { this.renderBillItems(bill)}
                { this.renderDiscounts(bill)}
                { this.renderBillFees(bill)}
                { this.renderPayments(bill)}
                { this.renderRefunds(bill)}
            </ul >
        );
    }
}

export default BillItemsPanel;