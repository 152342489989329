
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'
import { ApplicationState } from '../../../../store';

import Loading from '../../../global/loading';

import * as v from '../../../global/validation';

import * as PublicWebsiteSettingsActions from '../../../../store/pages/publicWebsiteSettings/actions';
import * as ModalActions from '../../../../store/global/modal/actions';

import { clickHandler } from '../../../../utils/util';
import { VenuePublicWebsiteSettings, PublicWebsiteAnalyticsSettings, WebsiteAnalyticsProviderType } from '../../../../store/pages/publicWebsiteSettings/types';
import PaymentPageForm from './paymentPageForm';
import GeneralPublicWebsiteSettingsForm from './generalPublicWebsiteSettingsForm';
import OnlineBookingSettingsForm from './onlineBookingSettingsForm';
import OnlineRegistrationSettingsForm from './onlineRegistrationSettingsForm';
import AnalyticsSettingsForm from './analyticsSettingsForm';
import { Resource } from '../../../../store/pages/resources/types';

enum View {
    Default = 0,
    GeneralSettings = 1,
    OnlineBooking = 2,
    Registration = 3,
    Payment = 4,
    Analytics = 5
}

interface LocalProps {
    venueId: string;
    publicWebsiteId: number;
}

interface MappedActions {
    editPublicWebsitePages: () => void;
    closeModel: () => void;
}

interface MappedReduxState {
    isLoadingPublicWebsitePages: boolean;
    publicWebsitePages: VenuePublicWebsiteSettings[];
    resources: Resource[];
}

type PublicWebsitePagesListProps = LocalProps & MappedActions & MappedReduxState;

interface PublicWebsitePagesListState {
    view: View;
    selectedAnalytics?: PublicWebsiteAnalyticsSettings;
}

class PublicWebsitePagesList extends React.Component<PublicWebsitePagesListProps, PublicWebsitePagesListState> {

    constructor(props: PublicWebsitePagesListProps) {
        super(props);

        // If this is a new website, go straight to the general settings page, otherwise show the selection page
        const view = props.publicWebsiteId === 0 ? View.GeneralSettings : View.Default;

        this.state = { view: view };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    closeOverlay = () => this.setState({ view: View.Default, selectedAnalytics: undefined })

    isLoading = () => this.props.isLoadingPublicWebsitePages;

    render() {
        const { t } = this.context;
        const { publicWebsiteId, publicWebsitePages } = this.props;
        const { view, selectedAnalytics } = this.state;

        const publicWebsite = publicWebsitePages.find(x => x.publicWebsiteId === publicWebsiteId)

        if (publicWebsite) {
            if (view === View.GeneralSettings) {
                return <GeneralPublicWebsiteSettingsForm venueId={this.props.venueId} publicWebsiteSettings={publicWebsite} resources={this.props.resources} close={this.closeOverlay} />
            } else if (view === View.OnlineBooking) {
                return <OnlineBookingSettingsForm venueId={this.props.venueId} publicWebsiteSettings={publicWebsite} close={this.closeOverlay} />
            } else if (view === View.Registration) {
                return <OnlineRegistrationSettingsForm venueId={this.props.venueId} publicWebsiteSettings={publicWebsite} close={this.closeOverlay} />
            } else if (view === View.Payment) {
                return <PaymentPageForm venueId={this.props.venueId} publicWebsiteSettings={publicWebsite} close={this.closeOverlay} />;
            } else if (view === View.Analytics && selectedAnalytics) {
                const enabledProviders = publicWebsite.analyticsSettings.filter(s => s.enabled).map(s => s.providerType);
                return <AnalyticsSettingsForm venueId={this.props.venueId} publicWebsiteSettings={publicWebsite} providerType={selectedAnalytics.providerType} enabledProviders={enabledProviders} close={this.closeOverlay} />
            }
        }

        return <section className='venue-item-list'>
            {!publicWebsite || this.isLoading()
                ? <Loading />
                : <>
                    <div className='at-panel'>
                        {this.renderPublicWebsiteList(publicWebsite)}
                    </div>
                    <h4>{t('publicWebsitePages:analytics')}</h4>
                    <div className='at-panel'>
                        {this.renderAnalytics(publicWebsite.analyticsSettings)}
                    </div>
                </>
            }

            <p />
            <div className='btn-toolbar'>
                <button className='btn btn-basic' onClick={e => clickHandler(e, this.props.closeModel)}>{t('Global:close')}</button>
            </div>
        </section>;
    }

    private renderPublicWebsiteList = (publicWebsitePages: VenuePublicWebsiteSettings) => {
        const { t } = this.context;
        const warningIconStyle = { color: '#f9b745', marginRight: '6px' }

        const bookingWarnings: string[] = [];
        if (!publicWebsitePages.onlineBooking.bookingConfirmationEmailTemplateId) bookingWarnings.push(t('publicWebsitePages:NoBookingConfEmailTemplate'))
        if (v.requiredHtml(publicWebsitePages.onlineBooking.completionPageContentTemplate)) bookingWarnings.push(t('publicWebsitePages:NoBookingCompletionPageContent'));

        const registrationWarnings: string[] = [];
        if (v.requiredHtml(publicWebsitePages.onlineRegistration.landingPageContent)) registrationWarnings.push(t('publicWebsitePages:NoRegLandingPageContent'));
        if (v.requiredHtml(publicWebsitePages.onlineRegistration.completionPageContent)) registrationWarnings.push(t('publicWebsitePages:NoRegCompletionPageContent'));

        const pages = [
            <tr key='general_settings'>
                <td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.setState({ view: View.GeneralSettings }))}>{t('publicWebsitePages:generalSettings')}</button></td>
                <td></td>
            </tr>,
            <tr key='online_booking'>
                <td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.setState({ view: View.OnlineBooking }))}>{t('publicWebsitePages:onlineBooking')}</button></td>
                <td>{bookingWarnings.map(msg => <div><span className='glyphicon glyphicon-alert' style={warningIconStyle}></span><span>{msg}</span></div>)}</td>
            </tr>,
            <tr key='online_registration'>
                <td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.setState({ view: View.Registration }))}>{t('publicWebsitePages:onlineRegistration')}</button></td>
                <td>{registrationWarnings.map(msg => <div><span className='glyphicon glyphicon-alert' style={warningIconStyle}></span><span>{msg}</span></div>)}</td>
            </tr>,
            <tr key='payment_page'>
                <td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.setState({ view: View.Payment }))}>{t('publicWebsitePages:payment')}</button></td>
                <td></td>
            </tr>
        ];
        
        return (
            <table className='table table-condensed'>
                <thead>
                    <tr>
                        <th>{t('Global:name')}</th>
                    </tr>
                </thead>
                <tbody>
                    {pages}
                </tbody>
            </table>
        );
    }

    renderAnalytics = (analyticsSettings: PublicWebsiteAnalyticsSettings[]) => {
        const { t } = this.context;

        const enabledProviders = analyticsSettings.filter(s => s.enabled).map(s => s.providerType);

        return <table className='table table-condensed'>
            <thead>
                <tr>
                    <th>{t('Global:type')}</th>
                    <th>{t('Global:enabled')}</th>
                </tr>
            </thead>
            <tbody>
                {analyticsSettings.map(s => <tr key={s.providerType}>
                    <td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.setState({ view:View.Analytics, selectedAnalytics: s }))}>{t(`WebsiteAnalyticsProviderType:${WebsiteAnalyticsProviderType[s.providerType]}`)}</button></td>
                    <td>{t(s.enabled ? 'Global:yes' : 'Global:no')}</td>
                </tr>)}
            </tbody>
        </table>
    }
}


const mapStateToProps = (state: ApplicationState) => ({
    publicWebsitePages: state.publicWebsiteSettings.publicWebsiteSettings,
    isLoadingPublicWebsitePages: state.publicWebsiteSettings.isLoading,
    resources: state.resources.resources,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    editPublicWebsitePages: bindActionCreators(PublicWebsiteSettingsActions.actionCreators.editPublicWebsiteSettings, dispatch),
    closeModel: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(PublicWebsitePagesList);


