
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../../global/controls';

import { ITranslationContext } from '../../../../translations';
import { PaymentGateway } from '../../../../store/pages/paymentGateways/types';
import { Setting } from './paymentMethodForm';
import { isNullOrEmpty } from '../../../../utils/util';

interface WorldpayGatewaySettingsProps {
    gateway: PaymentGateway;
    settings: Setting[];
    settingChanged: (key: string, val: string) => void;
}

const WorldpayGatewaySettings = (props: WorldpayGatewaySettingsProps, context: ITranslationContext) => {
    const { gateway, settings, settingChanged } = props;
    const { t } = context;

    const generalSettings = settings.filter(s => s.key !== 'Mode' && s.key !== 'Payment Url' && s.key !== 'Remote Admin Url' && s.key !== 'use md5 secret' && s.key !== 'md5 secret')

    var modeOptions = [{ key: 'test', name: t('WorldpayGatewayMode:test') }, { key: 'live', name: t('WorldpayGatewayMode:live') }]
    var modeSetting = settings.find(s => s.key === 'Mode');
    var modeSettingKey = modeSetting ? modeSetting.key : 'Mode';
    var modeSettingValue = modeSetting && modeSetting.value ? modeSetting.value : ct.asFormValue(modeSettingKey, 'test');

    var useMd5SecretSetting = settings.find(s => s.key === 'use md5 secret');
    var useMd5SecretSettingKey = useMd5SecretSetting ? useMd5SecretSetting.key : 'use md5 secret';
    var useMd5SecretSettingValue = ct.asFormValue(useMd5SecretSettingKey, useMd5SecretSetting && useMd5SecretSetting.value && "true" === useMd5SecretSetting.value.value ? "true" : "false");

    var md5SecretSettingKey = 'md5 secret';
    var md5SecretSettingValue = settings.find(s => s.key === md5SecretSettingKey) || { key: md5SecretSettingKey, secure: true, hasValue: false, optional: true, value: ct.asFormValue(md5SecretSettingKey, '') };

    return (
        <div className='payment-method-form-gateway-settings'>
            <label>{t('PaymentMethodForm:gatewaySettings', { gatewayName: gateway.name })}</label>
            {generalSettings.map(s => (
                <ct.TextBox key={s.key} id={`setting_${s.key}`} labelKey={s.key} placeholderKey={s.hasValue && s.secure && isNullOrEmpty(s.value.value) ? '******' : s.key} value={({ ...s.value, value: (s.value.value || '') })} callback={val => settingChanged(s.key, val)} />
            ))}

            {modeSetting ? <ct.Select id={`setting_${modeSettingKey}`} key='setting_mode' labelKey={modeSettingKey} options={modeOptions} value={{ ...modeSettingValue, value: modeSettingValue.value || 'test' }} callback={val => settingChanged(modeSettingKey, val)} /> : null}

            <ct.Checkbox id={`setting_${useMd5SecretSettingKey}`} labelKey={useMd5SecretSettingKey} value={{ ...useMd5SecretSettingValue, value: useMd5SecretSettingValue.value === "true" }} callback={val => settingChanged(useMd5SecretSettingKey, val ? "true" : "false") } />

            {useMd5SecretSetting && useMd5SecretSetting.value && useMd5SecretSetting.value.value === "true"
                ? <ct.TextBox id={`setting_${md5SecretSettingKey}`} labelKey={md5SecretSettingKey} hintKey={t('WorldpayGatewaySettings:md5SecretHint')} placeholderKey={md5SecretSettingValue.hasValue && md5SecretSettingValue.secure && isNullOrEmpty(md5SecretSettingValue.value.value) ? '******' : md5SecretSettingValue.key} value={({ ...md5SecretSettingValue.value, value: (md5SecretSettingValue.value.value || '') })} callback={val => settingChanged(md5SecretSettingKey, val)} />
                : null}
        </div>
    )
}

WorldpayGatewaySettings.contextTypes = {
    t: PropTypes.func
}

export default WorldpayGatewaySettings;