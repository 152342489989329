
import * as React from 'react';
import * as PropTypes from 'prop-types'
import moment from 'moment';

import * as ct from '../../../global/controls';
import * as v from '../../../global/validation';

import { formHandler, clickHandler, isNullOrEmpty } from '../../../../utils/util';
import { Time, Days } from '../../../../store/global/types';
import { ProdPrice, ProductFixedPricing } from './types';
import { ProductPricePoint } from '../../../../store/pages/products/types';
import { hasPriceOverlap, validatePricing, validatePricingAppliesOnPricePoint, validatePricingApplyPriceFrom, validatePricingApplyPriceTo, validatePricingArchived, validatePricingEffectiveDatesPricePoint, validatePricingEffectiveFrom, validatePricingEffectiveTo, validatePricingTagId, validatePricingUnitPrice } from './pricingValidation';
import { DateFormat, TimeFormat } from '../../../../store/pages/venues/types';
import { Tag } from '../../../../store/pages/tags/types';

interface ProductPriceOverrideFormProps {
    overridePrice: ProdPrice;
    isFixed: boolean;
    dateFormat: DateFormat;
    timeFormat: TimeFormat;
    tags: Tag[];
    saveChanges: (key: string, newPricing: ProdPrice) => void;
    removePrice: (key: string) => void;
    close: () => void;
}

interface ProductPriceOverrideFormState {
    unitPrice: ct.FormValue<number | null>;
    fixedPricing: ProductFixedPricing[];
    effectiveFrom: ct.FormValue<moment.Moment>;
    effectiveTo: ct.FormValue<moment.Moment | null>;
    effectiveDatesPricePoint: ct.FormValue<ProductPricePoint>;
    tagId: ct.FormValue<string | null>;
    appliesOnPricePoint: ct.FormValue<ProductPricePoint>;
    applyPriceOnDays: Days;
    applyPriceFrom: ct.FormValue<Time | null>;
    applyPriceTo: ct.FormValue<Time | null>;
}

class ProductPriceOverrideForm extends React.Component<ProductPriceOverrideFormProps, ProductPriceOverrideFormState> {

    constructor(props: ProductPriceOverrideFormProps) {
        super(props);

        this.state = this.buildStateFromProps(this.props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps(props: ProductPriceOverrideFormProps): ProductPriceOverrideFormState {
        const { unitPrice, fixedPricing, effectiveFrom, effectiveTo, effectiveDatesPricePoint, tagId, appliesOnPricePoint, applyPriceOnDays, applyPriceFrom, applyPriceTo } = props.overridePrice;

        return {
            unitPrice: unitPrice,
            fixedPricing: fixedPricing,
            effectiveFrom: effectiveFrom,
            effectiveTo: effectiveTo,
            effectiveDatesPricePoint: effectiveDatesPricePoint,
            tagId: tagId,
            appliesOnPricePoint: appliesOnPricePoint,
            applyPriceOnDays: applyPriceOnDays,
            applyPriceFrom: applyPriceFrom,
            applyPriceTo: applyPriceTo
        }
    }

    save = () => {
        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {
            const { effectiveFrom, effectiveTo, effectiveDatesPricePoint, tagId, unitPrice, fixedPricing, appliesOnPricePoint, applyPriceOnDays, applyPriceFrom, applyPriceTo } = this.state;
            const { overridePrice } = this.props;
            this.props.saveChanges(overridePrice.key, {
                ...overridePrice,
                effectiveFrom: effectiveFrom,
                effectiveTo: effectiveTo,
                effectiveDatesPricePoint: effectiveDatesPricePoint,
                tagId: tagId,
                unitPrice: unitPrice,
                fixedPricing: fixedPricing,
                appliesOnPricePoint: appliesOnPricePoint,
                applyPriceOnDays: applyPriceOnDays,
                applyPriceFrom: applyPriceFrom,
                applyPriceTo: applyPriceTo
            })
        }
    }

    setDay = (day: Days, value: boolean) => {
        this.setState(prev => {
            let currentDays = prev.applyPriceOnDays;

            if (value) currentDays |= day;
            else currentDays &= ~day

            return { applyPriceOnDays: currentDays }
        })
    }

    fixedPriceChanged = (index: number, min: number, max: number, price: number) => this.setState(s => ({
        fixedPricing: s.fixedPricing.map((p, ix) => ix === index ? { minQuantity: min, maxQuantity: max, price: price, errorKey: null } : p)
    }));

    addFixedPriceRule = () => this.setState(s => {
        const { fixedPricing } = this.state;
        const lastRule = fixedPricing.length > 0 ? fixedPricing[fixedPricing.length - 1] : { minQuantity: 0, maxQuantity: 0, price: 0, errorKey: null };
        return ({ fixedPricing: s.fixedPricing.concat({ minQuantity: lastRule.maxQuantity + 1, maxQuantity: lastRule.maxQuantity + 1, price: 0, errorKey: null }) })
    });

    removeFixedPrice = (index: number) => this.setState(s => ({ fixedPricing: s.fixedPricing.filter((fp, ix) => ix !== index) }))

    render() {
        const { t } = this.context;
        const { overridePrice, isFixed, tags, dateFormat, timeFormat, close } = this.props;
        const { effectiveFrom, effectiveTo, effectiveDatesPricePoint, tagId, unitPrice, fixedPricing, appliesOnPricePoint, applyPriceOnDays, applyPriceFrom, applyPriceTo } = this.state;

        const pricePointOptions = Object.keys(ProductPricePoint).filter(key => typeof ProductPricePoint[key as any] === 'number').map(key => ({ key: ProductPricePoint[key as any].toString(), name: t(`ProductPricePoint:${key}`) }));
        const tagOptions = [{ key: '', name: t('ProductForm:noTag') }].concat(tags.map(t => ({ key: t.id, name: t.name })));
        const getTagCol = (opt: ct.SelectOption) => tags.filter(t => t.id === opt.key).map(t => t.colour).concat(['transparent'])[0];
        const inlineControlStyle = ({ minHeight: '10px', margin: '2px 0' });

        const fixedPrices = isFixed
            ? fixedPricing.map((p, i) => <tr key={`Price_${i + 1}`}>
                <td>{i === 0 ? <div style={({ ...inlineControlStyle, maxWidth: '140px', minWidth: '50px' })}>1</div> : <ct.NumberBox id={`${i}_min`} labelKey='' placeholderKey='' min='1' step='1' value={ct.asFormValue(`${i}_minQuantity`, p.minQuantity)} callback={val => this.fixedPriceChanged(i, val || 0, p.maxQuantity, p.price)} style={({ ...inlineControlStyle, maxWidth: '140px', minWidth: '50px' })} minimal={true} />}</td>
                <td><ct.NumberBox id={`${i}_max`} labelKey='' placeholderKey='' min={p.minQuantity.toString()} step='1' value={ct.asFormValue(`${i}_maxQuantity`, p.maxQuantity)} callback={val => this.fixedPriceChanged(i, p.minQuantity, val || 0, p.price)} style={({ ...inlineControlStyle, maxWidth: '140px', minWidth: '50px' })} minimal={true} /></td>
                <td><ct.DecimalNumberBox id={`${i}_price`} labelKey='' placeholderKey='' min='0' step='0.01' value={ct.asFormValue(`${i}_price`, p.price)} callback={val => this.fixedPriceChanged(i, p.minQuantity, p.maxQuantity, val || 0)} style={({ ...inlineControlStyle, maxWidth: '140px', minWidth: '50px' })} minimal={true} /></td>
                <td>{i > 0 ? <span onClick={e => clickHandler(e, () => this.removeFixedPrice(i))} className='glyphicon glyphicon-trash red' style={({ cursor: 'pointer', padding: '5px' })}></span> : null}</td>
                <td>{!isNullOrEmpty(p.errorKey) ? <span className='error-message'>{t(p.errorKey)}</span> : null}</td>
            </tr>).concat(<tr key='add-rule'><td colSpan={5}><button className='btn btn-link' onClick={e => clickHandler(e, this.addFixedPriceRule)}>{t('ProductForm:addRule')}</button></td></tr>)
            : [];
        const pricing = fixedPrices.concat([<tr key='standard'></tr>])

        return <div>
            <h2>{t('ProductPriceOverrideForm:heading')}</h2>

            <form className='data-form' onSubmit={e => formHandler(e)} autoComplete='off'>
                <div className='row'>
                    <div className='col-xs-4 col-md-4 col-lg-4'>
                        <ct.Select id={`${overridePrice.key}_effectivePricePoint`} labelKey='ProductForm:applyOverridePriceWhen' value={{ ...effectiveDatesPricePoint, value: effectiveDatesPricePoint.value.toString() }} options={pricePointOptions} callback={val => this.setState({ effectiveDatesPricePoint: validatePricingEffectiveDatesPricePoint(parseInt(val), []) })} />
                    </div>
                    <div className='col-xs-4 col-md-4 col-lg-4'>
                        <ct.DatePicker id={`${overridePrice.key}_effectiveFrom`} labelKey='Global:from' value={effectiveFrom} callback={val => this.setState(s => ({ effectiveFrom: val ? validatePricingEffectiveFrom(val, []) : s.effectiveFrom }))} dateFormat={dateFormat} timeFormat={undefined} />
                    </div>
                    <div className='col-xs-4 col-md-4 col-lg-4'>
                        <ct.DatePicker id={`${overridePrice.key}_effectiveTo`} labelKey='Global:to' value={effectiveTo} callback={val => this.setState({ effectiveTo: validatePricingEffectiveTo(val, []) })} dateFormat={dateFormat} timeFormat={undefined} />
                    </div>
                </div>

                <div className='row'>
                    <div className='col-xs-12'>
                        <table>
                            <thead>
                                <tr>
                                    <th>{isFixed ? t('ProductForm:fromQuantity') : ''}</th>
                                    <th>{isFixed ? t('ProductForm:toQuantity') : ''}</th>
                                    <th>{t(isFixed ? 'Global:price' : 'ProductsPage:unitPrice')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pricing}
                                <tr key='standardPrice'>
                                    <td colSpan={2} style={({ padding: '2px 4px 2px 0' })}><label>{isFixed ? t('ProductForm:additionalItemPrice') : null}</label></td>
                                    <td><ct.DecimalNumberBox id='unitPrice' labelKey='' placeholderKey='' min='0' step='0.01' value={unitPrice} callback={val => this.setState({ unitPrice: validatePricingUnitPrice(val, []) })} style={({ ...inlineControlStyle, maxWidth: '140px', minWidth: '50px' })} minimal={true} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className='row mt-15'>
                    <div className='col-xs-6 col-md-3 col-lg-2'>
                        <ct.Select id={`${overridePrice.key}tag`} labelKey='ProductForm:tag' value={{ ...tagId, value: (tagId.value || '').toString() }} options={tagOptions} callback={val => this.setState({ tagId: validatePricingTagId(val, []) })} renderOption={o => <span key={o.key} className="label tag-label" style={{ backgroundColor: getTagCol(o) }}>{o.name}</span>} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xs-12'>
                        {isFixed ? null : <div className='help-block'>{t('ProductForm:tagsHintText')}</div>}
                    </div>
                </div>

                <div className='row'>
                    <div className='col-xs-12'>
                        <label>{t('ProductForm:dayTimeRestrictions')}</label>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-xs-6 col-md-3 col-lg-3'>
                        <ct.Select id={`${overridePrice.key}_appliesOnPricePoint`} labelKey='ProductForm:restrictOverrideTo' value={{ ...appliesOnPricePoint, value: appliesOnPricePoint.value.toString() }} options={pricePointOptions} callback={val => this.setState({ appliesOnPricePoint: validatePricingAppliesOnPricePoint(parseInt(val), []) })} />
                    </div>

                    <div className='col-xs-6 col-md-3 col-lg-3'>
                        <table className='table table-super-condensed table-borderless' style={({ width: 'auto' })}>
                            <thead>
                                <tr>
                                    <th>{t('Global:Monday2ch')}</th>
                                    <th>{t('Global:Tuesday2ch')}</th>
                                    <th>{t('Global:Wednesday2ch')}</th>
                                    <th>{t('Global:Thursday2ch')}</th>
                                    <th>{t('Global:Friday2ch')}</th>
                                    <th>{t('Global:Saturday2ch')}</th>
                                    <th>{t('Global:Sunday2ch')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><input type='checkbox' checked={(applyPriceOnDays & Days.Monday) === Days.Monday} onChange={x => this.setDay(Days.Monday, x.currentTarget.checked)} /></td>
                                    <td><input type='checkbox' checked={(applyPriceOnDays & Days.Tuesday) === Days.Tuesday} onChange={x => this.setDay(Days.Tuesday, x.currentTarget.checked)} /></td>
                                    <td><input type='checkbox' checked={(applyPriceOnDays & Days.Wednesday) === Days.Wednesday} onChange={x => this.setDay(Days.Wednesday, x.currentTarget.checked)} /></td>
                                    <td><input type='checkbox' checked={(applyPriceOnDays & Days.Thursday) === Days.Thursday} onChange={x => this.setDay(Days.Thursday, x.currentTarget.checked)} /></td>
                                    <td><input type='checkbox' checked={(applyPriceOnDays & Days.Friday) === Days.Friday} onChange={x => this.setDay(Days.Friday, x.currentTarget.checked)} /></td>
                                    <td><input type='checkbox' checked={(applyPriceOnDays & Days.Saturday) === Days.Saturday} onChange={x => this.setDay(Days.Saturday, x.currentTarget.checked)} /></td>
                                    <td><input type='checkbox' checked={(applyPriceOnDays & Days.Sunday) === Days.Sunday} onChange={x => this.setDay(Days.Sunday, x.currentTarget.checked)} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className='col-xs-6 col-md-3 col-lg-3'>
                        <ct.Time id='applyPriceFrom' labelKey='ProductForm:fromTime' value={applyPriceFrom} callback={val => this.setState({ applyPriceFrom: validatePricingApplyPriceFrom(val, []) })} timeFormat={timeFormat} disabled={applyPriceOnDays === Days.None} />
                    </div>
                    <div className='col-xs-6 col-md-3 col-lg-3'>
                        <ct.Time id='applyPriceTo' labelKey='ProductForm:toTime' value={applyPriceTo} callback={val => this.setState({ applyPriceTo: validatePricingApplyPriceTo(val, []) })} timeFormat={timeFormat} disabled={applyPriceOnDays === Days.None} />
                    </div>
                </div>

                <div className='btn-toolbar'>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)}>{t('Global:save')}</button>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, close)}>{t('Global:cancel')}</button>
                </div>
            </form>
        </div>
    }
}

export default ProductPriceOverrideForm;