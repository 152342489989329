
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'

import * as api from '../../../store/apiClient';
import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import { ApplicationState } from '../../../store';
import * as CustomerCategoryActions from '../../../store/pages/customerCategories/actions'
import * as ModalActions from '../../../store/global/modal/actions';
import { ValidationError } from '../../../store/global/types';
import { clickHandler } from '../../../utils/util';
import ApiError from '../../global/apiError';
import { CustomerCategory } from '../../../store/pages/customerCategories/types';


interface LocalProps {
    venueId: string;
    isNew: boolean
    customerCategory: CustomerCategory | null;
}

interface MappedState {
    allCategories: CustomerCategory[];
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
}

interface MappedActions {
    saveCustomerCategory: (isNew: boolean, customerCategory: CustomerCategory) => void;
    closeModal: () => void;
}

type CustomerCategoryFormProps = MappedState & MappedActions & LocalProps;

interface CustomerCategoryFormState {
    name: ct.FormValue<string>;
    pluralisedName: ct.FormValue<string>;
    code: ct.FormValue<string>;
    timingCode: ct.FormValue<string>;
    description: ct.FormValue<string>;
    minAge: ct.FormValue<number | null>;
    maxAge: ct.FormValue<number | null>;
    minHeight: ct.FormValue<number | null>;
    maxHeight: ct.FormValue<number | null>;
    archived: ct.FormValue<boolean>;
}

class CustomerCategoryForm extends React.Component<CustomerCategoryFormProps, CustomerCategoryFormState> {

    constructor(props: CustomerCategoryFormProps) {
        super(props);

        this.state = this.buildStateFromProps(this.props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps(props: CustomerCategoryFormProps): CustomerCategoryFormState {
        const { isNew, customerCategory } = props;

        return {
            name: this.validateName(isNew || !customerCategory ? '' : customerCategory.name),
            description: this.validateDescription(isNew || !customerCategory ? '' : customerCategory.description),
            pluralisedName: this.validatePluralisedName(isNew || !customerCategory ? '' : customerCategory.pluralisedName),
            code: this.validateCode(isNew || !customerCategory ? '' : customerCategory.code),
            timingCode: this.validateTimingCode(isNew || !customerCategory ? '' : customerCategory.timingCode || ''),
            minAge: this.validateMinAge(isNew || !customerCategory ? null : customerCategory.minAge),
            maxAge: this.validateMaxAge(isNew || !customerCategory ? null : customerCategory.maxAge),
            minHeight: this.validateMinHeight(isNew || !customerCategory ? null : customerCategory.minHeight),
            maxHeight: this.validateMaxHeight(isNew || !customerCategory ? null : customerCategory.maxHeight),
            archived: this.validateArchived(isNew || !customerCategory ? false : customerCategory.archived)
        };
    }


    componentDidUpdate(prevProps: CustomerCategoryFormProps) {
        const { saveComplete } = this.props;

        if (!prevProps.saveComplete && saveComplete) {
            setTimeout(() => { this.close(); }, 750);
        }
    }

    private saveCustomerCategory = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }

    private close = () => {
        this.props.closeModal();
    }

    private save = () => {
        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {
            const { venueId, isNew, customerCategory } = this.props;
            const { name, description, pluralisedName, code, timingCode, minAge, maxAge, minHeight, maxHeight, archived } = this.state;

            this.props.saveCustomerCategory(isNew, {
                id: isNew || !customerCategory ? '' : customerCategory.id,
                venueId: venueId,
                name: name.value,
                description: description.value,
                pluralisedName: pluralisedName.value,
                code: code.value,
                timingCode: timingCode.value,
                minAge: minAge.value,
                maxAge: maxAge.value,
                minHeight: minHeight.value,
                maxHeight: maxHeight.value,
                archived: archived.value,
            });
        }
    }

    ensureCodeIsUnique = (code: string) => {
        const { venueId, isNew, customerCategory, allCategories } = this.props;
        const id = isNew || !customerCategory ? '' : customerCategory.id;
        if (allCategories.findIndex(c => c.venueId == venueId && c.code == code && c.id !== id) >= 0)
            return 'CodeMustBeUnique';

        return undefined;
    }

    validateName = (val: string) => v.validate(val, 'name', [v.required], this.props.validationErrors);
    validateDescription = (val: string) => v.validate(val, 'description', [v.required], this.props.validationErrors);
    validatePluralisedName = (val: string) => v.validate(val, 'pluralisedName', [v.required], this.props.validationErrors);
    validateCode = (val: string) => v.validate(val, 'code', [v.required, this.ensureCodeIsUnique], this.props.validationErrors);
    validateTimingCode = (val: string) => v.validate(val, 'timingCode', [], this.props.validationErrors);
    validateMinAge = (val: number | null) => v.validate(val, 'minAge', [], this.props.validationErrors);
    validateMaxAge = (val: number | null) => v.validate(val, 'maxAge', [], this.props.validationErrors);
    validateMinHeight = (val: number | null) => v.validate(val, 'minHeight', [], this.props.validationErrors);
    validateMaxHeight = (val: number | null) => v.validate(val, 'maxHeight', [], this.props.validationErrors);
    validateArchived = (val: boolean) => v.validate(val, 'archived', [], this.props.validationErrors);

    render() {
        const t = this.context.t;
        const { saveError, saveComplete } = this.props;

        const message = saveError ? <ApiError error={saveError} /> : saveComplete ? <div className='bg-success'>{t('Global:saveComplete')}</div> : null;

        return <div className='customer-categories-form'>
            <h2 className='customer-categories-form-title'>{t('CustomerCategoryForm:title')}</h2>

            <div className='row'>
                <div className='col-md-12'>
                    <form className='data-form' onSubmit={this.saveCustomerCategory} autoComplete='off'>
                        <ct.TextBox id='name' labelKey='Global:name' placeholderKey='Global:name' value={this.state.name} callback={val => this.setState({ name: this.validateName(val) })} />

                        <ct.TextBox id='pluralisedName' labelKey='CustomerCategoryForm:pluralisedName' placeholderKey='CustomerCategoryForm:pluralisedName' value={this.state.pluralisedName} callback={val => this.setState({ pluralisedName: this.validatePluralisedName(val) })} />

                        <ct.TextBox id='code' labelKey='Global:code' placeholderKey='Global:code' value={this.state.code} callback={val => this.setState({ code: this.validateCode(val) })} />

                        <ct.TextBox id='timingCode' labelKey='CustomerCategoryForm:timingCode' placeholderKey='CustomerCategoryForm:timingCode' hintKey='CustomerCategoryForm:timingCodeHint' value={this.state.timingCode} callback={val => this.setState({ timingCode: this.validateTimingCode(val) })} />

                        <ct.TextBox id='description' labelKey='Global:description' placeholderKey='Global:description' value={this.state.description} callback={val => this.setState({ description: this.validateDescription(val) })} />

                        <ct.NumberBox id='minAge' labelKey='CustomerCategoryForm:minAge' placeholderKey='CustomerCategoryForm:minAge' hintKey='CustomerCategoryForm:OptionalHint' value={this.state.minAge} callback={val => this.setState({ minAge: this.validateMinAge(val) })} step='1' />

                        <ct.NumberBox id='maxAge' labelKey='CustomerCategoryForm:maxAge' placeholderKey='CustomerCategoryForm:maxAge' hintKey='CustomerCategoryForm:OptionalHint' value={this.state.maxAge} callback={val => this.setState({ maxAge: this.validateMaxAge(val) })} step='1' />

                        <ct.NumberBox id='minHeight' labelKey='CustomerCategoryForm:minHeight' placeholderKey='CustomerCategoryForm:minHeight' hintKey='CustomerCategoryForm:OptionalHint' value={this.state.minHeight} callback={val => this.setState({ minHeight: this.validateMinHeight(val) })} step='1' />

                        <ct.NumberBox id='maxHeight' labelKey='CustomerCategoryForm:maxHeight' placeholderKey='CustomerCategoryForm:maxHeight' hintKey='CustomerCategoryForm:OptionalHint' value={this.state.maxHeight} callback={val => this.setState({ maxHeight: this.validateMaxHeight(val) })} step='1' />

                        <ct.Checkbox id='archived' labelKey='Global:archive' value={this.state.archived} callback={val => this.setState({ archived: this.validateArchived(val) })} />

                        {message}

                        <p />
                        <div className='btn-toolbar'>
                            <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)}>{this.context.t('Global:save')}</button>
                            <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{this.context.t('Global:cancel')}</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>;
    }
}


const mapStateToProps = (state: ApplicationState) => ({
    allCategories: state.customerCategories.customerCategories,
    isSaving: state.customerCategories.isSaving,
    saveComplete: state.customerCategories.saveComplete,
    saveError: state.customerCategories.saveError,
    validationErrors: state.customerCategories.validationErrors,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    saveCustomerCategory: bindActionCreators(CustomerCategoryActions.actionCreators.saveCustomerCategory, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch)
});

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(CustomerCategoryForm);
