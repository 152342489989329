
import * as React from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'
import { ApplicationState } from '../../../store';
import * as ct from '../../global/controls';
import * as api from '../../../store/apiClient';
import * as MembershipsTypes from '../../../store/pages/memberships/types';
import * as MembershipActions from '../../../store/pages/memberships/actions';
import * as ModalActions from '../../../store/global/modal/actions';
import MembershipTypeForm from './membershipTypeForm';
import Loading from '../../global/loading';
import { clickHandler } from '../../../utils/util';
import ApiError from '../../global/apiError';
import { stringComparer } from '../../../utils/comparers';
import { ProductPricingMode } from '../../../store/pages/products/types';
import { findApplicablePrice } from '../diary/helpers';
import { isClientAdmin } from '../../../utils/auth';
import TagLabel from '../../global/tagLabel';


interface LocalProps { }

interface LocalState {
    membershipTypes: MembershipsTypes.MembershipType[];
    isLoading: boolean;
    loadError: api.ApiError | null;
}

interface Actions {
    loadMembershipTypes: () => void;
    editMembershipType: () => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
}

type RoutedLocalProps = LocalProps & RouteComponentProps<{}>;

type MembershipsPageProps = LocalState & Actions & RoutedLocalProps;

interface MembershipsPageState {
    showArchived: boolean;
}

class MembershipsPage extends React.Component<MembershipsPageProps, MembershipsPageState> {

    constructor(props: MembershipsPageProps) {
        super(props);

        this.state = { showArchived: false }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private addMembershipType = () => {
        this.showOverlay(null, true);
    }

    private showOverlay = (membershipType: MembershipsTypes.MembershipType | null, addingNew: boolean) => {
        this.props.editMembershipType();
        this.props.showModal(<MembershipTypeForm isNew={addingNew} membershipType={membershipType} />, 'MembershipTypeForm');
    }

    render() {

        const { t } = this.context;
        const { isLoading, loadError } = this.props;
        const { showArchived } = this.state;
        const body = isLoading ? <Loading /> : this.renderMembershipTypesTable(showArchived);

        return <section className='membershipsPage'>
            <header className='section-header'>
                <div className='page-heading'>
                    <h1 className='memberships_title'>{t('MembershipsPage:title')}</h1>
                </div>
                {isClientAdmin()
                    ? <div className='section-actions right'>
                    <ct.Checkbox id='showArchived' labelKey='Global:showArchived' value={ct.asFormValue('showArchived',showArchived)} callback={val => this.setState({ showArchived: val })} style={({ display: 'inline-block' })} />
                    <button className='btn btn-info' onClick={e => clickHandler(e, this.addMembershipType)}>{t('MembershipsPage:addMembershipType')}</button> 
                    </div>
                    : null}
            </header>
            {body}
            <ApiError error={loadError} />
        </section>;
    }
    
    private renderMembershipTypesTable(showArchived: boolean) {
        const { t } = this.context;

        const membershipTypes = this.props.membershipTypes
            .filter(mt => showArchived || !mt.archived)
            .sort((mt1, mt2) => stringComparer(mt1.name, mt2.name))
            .map(mt => {
                const price = findApplicablePrice({ id: mt.id, name: mt.name, pricingMode: ProductPricingMode.PerUnit, pricing: mt.pricing}, new Date(), null)
                const currentPrice = price ? price.unitPrice : 0;
                return <tr key={mt.id}>
                    <td><Link to={`/memberships/${mt.id}/view`} className='pull-left'>{mt.name}</Link></td>
                    {showArchived ? <td>{mt.archived ? <span className='label label-danger'>{t('Global:archived')}</span> : null}</td> : null}
                    <td><TagLabel colour={mt.tag.tagColour} name={mt.tag.tagName} /></td>
                    <td>{currentPrice.toFixed(2)}</td>
                    <td>{mt.taxRateName}</td>
                </tr>
            });

        return <table className='table table-condensed'>
            <thead>
                <tr>
                    <th>{this.context.t('ProductList:nameHeading')}</th>
                    {showArchived ? <th>{t('Global:archived')}</th> : null}
                    <th></th>
                    <th>{this.context.t('Global:price')}</th>
                    <th>{this.context.t('ProductList:taxRateHeading')}</th>
                </tr>
            </thead>
            <tbody>
                {membershipTypes}
            </tbody>
        </table>;
    }
};

const matStateToProps = (state: ApplicationState) => ({
    membershipTypes: state.memberships.membershipTypes,
    isLoading: state.memberships.isLoading,
    loadError: state.memberships.loadError
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadMembershipTypes: bindActionCreators(MembershipActions.actionCreators.loadMembershipTypes, dispatch),
    editMembershipType: bindActionCreators(MembershipActions.actionCreators.editMembershipType, dispatch),
    showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
});

// Wire up the React component to the Redux store
export default connect(matStateToProps, mapDispatchToProps)(MembershipsPage);

