
import * as PropTypes from 'prop-types'

import { StripeReader } from "../../../../../store/pages/paymentGateways/types";
import { ITranslationContext } from "../../../../../translations";
import { clickHandler } from "../../../../../utils/util";
import { CardReaderIcon } from './readerIcon';

interface CardReaderProps {
    reader: StripeReader;
    select: (terminalId: string) => void;
}

const CardReader = (props: CardReaderProps, context: ITranslationContext) => {
    const { reader, select } = props;
    const { t } = context;

    return <li key={`reader_${reader.id}`}>
        <button key={`reader_${reader.id}`} className={`btn ${reader.status}`} onClick={e => clickHandler(e, () => select(reader.id))}>
            <div>
                <CardReaderIcon />
                <div>{reader.name}</div>
                <div className='pos-stripe-reader-status'>{t(`PointOfSale:readerStatus_${reader.status}`)}</div>
            </div>
        </button>
    </li>
}

CardReader.contextTypes = {
    t: PropTypes.func
}

export default CardReader;