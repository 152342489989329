
import { Action } from 'redux';
import { LocationChangeAction } from 'react-router-redux';

import { LoginActionTypes, LoginState } from './types';
import { LoginActions } from './actions'
import * as auth from '../../../utils/auth'
import { isNullOrEmpty } from '../../../utils/util';

const unloadedState: LoginState = {
    username: '',
    userFirstName: null,
    userLastName: null,
    isAuthenticated: (auth.isUserAuthenticated() || false),
    isLoggedIn: (auth.isUserAuthenticated() || false),
    loginError: null,
    client: { id: 0, name: '' },
    clients: [],
    at: null,
    mustChangePassword: false,
    requestingPasswordReset: false,
    passwordResetError: null,
    resetUserPasswordError: null,
    loadingClientData: false,
    loggingIn: false,
    switchingClient: false,
    lastActivity: new Date(),
    autoLogoutInterval: null
};

export const reducer = (state: LoginState, incomingAction: Action): LoginState => {

    if (incomingAction.type === '@@router/LOCATION_CHANGE') {
        const locationChangeAction = incomingAction as LocationChangeAction;
        if (locationChangeAction) {
            return { ...state, lastActivity: new Date() };
        }
    }

    const action = incomingAction as LoginActions;
    switch (action.type) {
        case LoginActionTypes.Login:
            return {
                ...state,
                username: '',
                userFirstName: null,
                userLastName: null,
                isAuthenticated: false,
                loginError: null,
                at: null,
                mustChangePassword: false,
                requestingPasswordReset: false,
                passwordResetError: null,
                resetUserPasswordError: null,
                loggingIn: true,
                isLoggedIn: false
            };
        case LoginActionTypes.LoginSuccess:
            return {
                ...state,
                username: !isNullOrEmpty(action.username) ? action.username : state.username,
                userFirstName: action.userFirstName,
                userLastName: action.userLastName,
                autoLogoutInterval: action.sessionTimeout,
                lastActivity: new Date(),
            };
        case LoginActionTypes.LoggedIn:
            return {
                ...state,
                isAuthenticated: true,
                loggingIn: false,
                isLoggedIn: true,
                loginError: null,
                mustChangePassword: false,
                requestingPasswordReset: false,
                passwordResetError: null,
                resetUserPasswordError: null
            };
        case LoginActionTypes.LoggedOut:
        case LoginActionTypes.SignedOut:
            return {
                ...state,
                username: '',
                userFirstName: null,
                userLastName: null,
                isAuthenticated: false,
                loginError: null,
                client: { id: 0, name: '' },
                clients: [],
                at: null,
                mustChangePassword: false,
                requestingPasswordReset: false,
                passwordResetError: null,
                resetUserPasswordError: null,
                autoLogoutInterval: null,
                isLoggedIn: false,
                loggingIn: false
            };
        case LoginActionTypes.ChangePassword:
            return {
                ...state,
                username: '',
                userFirstName: null,
                userLastName: null,
                isAuthenticated: false,
                loginError: null,
                client: { id: 0, name: '' },
                clients: [],
                at: action.at,
                mustChangePassword: true,
                requestingPasswordReset: false,
                passwordResetError: null,
                resetUserPasswordError: null,
                loggingIn: false
            };
        case LoginActionTypes.AuthorizeError:
            return {
                ...state,
                isAuthenticated: false,
                loginError: action.error,
                at: null,
                loggingIn: false
            };
        case LoginActionTypes.RefreshTokenError:
            return {
                ...state,
                isAuthenticated: false,
                loginError: action.err,
                at: null,
                mustChangePassword: false,
                isLoggedIn: false,
                loggingIn: false
            };
        case LoginActionTypes.AccessDenied:
            return {
                ...state,
                loggingIn: false
            };
        case LoginActionTypes.SwitchClient:
            return {
                ...state,
                switchingClient: true
            };
        case LoginActionTypes.ClientSwitched:
            return state;
        case LoginActionTypes.LoadUserClientInfo:
            return state;
        case LoginActionTypes.ReceiveUserClientInfo:
            return {
                ...state,
                client: action.client,
                clients: action.clients
            };
        case LoginActionTypes.LoadDataForClient:
            return {
                ...state,
                loadingClientData: true
            }
        case LoginActionTypes.ClientDataLoadComplete:
            return {
                ...state,
                loggingIn: false,
                loadingClientData: false,
                switchingClient: false
            }
        case LoginActionTypes.AjaxError:
            return {
                ...state,
                loggingIn: false,
                loginError: action.response
            };
        case LoginActionTypes.RequestPasswordReset:
            return {
                ...state,
                loggingIn: false,
                requestingPasswordReset: true,
                passwordResetError: null
            }
        case LoginActionTypes.PasswordReset:
            return {
                ...state,
                loggingIn: false,
                requestingPasswordReset: false,
                passwordResetError: null
            }
        case LoginActionTypes.PasswordResetError:
            return {
                ...state,
                loggingIn: false,
                requestingPasswordReset: false,
                passwordResetError: action.err
            }
        case LoginActionTypes.ResetUserPassword:
            return {
                ...state,
                loggingIn: false,
                resetUserPasswordError: null
            };
        case LoginActionTypes.ResetUserPasswordError:
            return {
                ...state,
                loggingIn: false,
                resetUserPasswordError: action.err
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

