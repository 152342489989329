
import * as React from 'react';
import * as PropTypes from 'prop-types'
import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import { clickHandler } from '../../../utils/util';
import { ITranslationContext } from '../../../translations';
import { AnalyticsReport } from '../../../store/pages/analytics/types';

interface SaveReportOverlayProps {
    report: AnalyticsReport;
    close: (saved: boolean) => void;
    save: (report: AnalyticsReport, saveCompleteAction: () => void) => void;
}

const validateName = (val: string) => v.validate(val, 'name', [v.required], []);

const SaveReportOverlay = (props: SaveReportOverlayProps, context: ITranslationContext) => {
    const { t } = context;
    const { report, close, save  } = props;

    const [name, setName] = React.useState<ct.FormValue<string>>(validateName(report.name));
    const [saving, setSaving] = React.useState(false);

    const saveReport = React.useCallback(() =>
    {
        setSaving(true);

        // Save the changes
        save({ ...report, name: name.value }, () => close(true));
    }, [name, setSaving]);

    return <div>
        <form className='data-form' onSubmit={saveReport} autoComplete='off'>
            <ct.TextBox id='name' labelKey='Global:name' placeholderKey='Global:name' value={name} callback={val => setName(validateName(val))} />

            <p />
            <div className='btn-toolbar'>
                <button className='btn btn-primary' onClick={e => clickHandler(e, saveReport)} disabled={saving || !name.isValid}>{t('Global:save')}</button>
                <button className='btn btn-basic' onClick={e => clickHandler(e, () => close(false))}>{t('Global:cancel')}</button>
            </div>
        </form>
    </div>

}


SaveReportOverlay.contextTypes = {
    t: PropTypes.func
}

export default SaveReportOverlay