
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/index';
import { Venue } from '../../store/pages/venues/types';


interface ComponentProps {
    title: string;
    component: any;
}

interface MappedReduxState {
    venues: Venue[];
    selectedVenueId: string | null;
}

type PageProps = ComponentProps & MappedReduxState;

class Page extends React.Component<PageProps, {}> {

    componentDidMount() {
        document.title = this.buildTitle()
    }

    componentDidUpdate() {
        document.title = this.buildTitle()
    }

    buildTitle = () => {
        const { venues, selectedVenueId, title } = this.props;
        if (selectedVenueId) {
            const venue = venues.filter(v => v.id === selectedVenueId);
            if (venue && venue.length > 0) {
                return `Alpha Flow | ${venue[0].name} | ${title}`;
            }
        }

        return title;
    }

    render() {
        const { component, venues, selectedVenueId, ...otherProps } = this.props;
        const PageComponent = component

        return (
            <PageComponent {...otherProps} />
        )
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    venues: state.venues.venues,
    selectedVenueId: state.venues.selectedVenueId,
})

export default connect(mapStateToProps)(Page);
