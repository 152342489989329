
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as pct from './types'; 
import { IStore } from '../..';


export type ReportTypeActions = pct.LoadReportTypes | pct.ReceiveReportTypes;

export const actionCreators = {
    loadReportTypes: () => ({ type: pct.ReportTypeActionTypes.RequestReportTypes }),
    loadReportTypesComplete: (reportTypes: pct.ReportType[] , err: api.ApiError | null) => ({ type: pct.ReportTypeActionTypes.ReceivedReportTypes, reportTypes: reportTypes, error: err }),
}

interface IGetReportTypesResponse {
    reportTypes: pct.ReportType[];
}

export const handleClientChange = (store: IStore) => [actionCreators.loadReportTypes]

//https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-after-catching-and-processing-an-error-with-emitting-somethi

const loadReportTypes = () => Observable.defer(() => api.getJson<IGetReportTypesResponse>('api/v1/report/types/'))
    .map(response => actionCreators.loadReportTypesComplete(response.reportTypes, null));

export const loadReportTypesEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        pct.ReportTypeActionTypes.RequestReportTypes,
        action => loadReportTypes(),
        err => actionCreators.loadReportTypesComplete([], err));
