
import * as React from 'react';

import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import * as PropTypes from 'prop-types'
import moment from 'moment';
import { ReservationType } from '../../../store/pages/diary/types';
import { Reservation, Booking, ReservationBooking } from './types';
import { Resource, buildResourceSelectionId, deconstructResourceSelectionId } from '../../../store/pages/resources/types';
import { ActivityFormat } from '../../../store/pages/activityFormats/types';
import { formHandler, clickHandler, isNullOrEmpty } from '../../../utils/util';
import ReservationDetailsForm from './reservationDetailsForm';
import { Product } from '../../../store/pages/products/types';
import { CustomerCategory } from '../../../store/pages/customerCategories/types';
import { Bill } from '../../../store/pages/pointOfSale/types';
import { buildProductKey, parseProductKey } from './helpers';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';
import { MembershipType } from '../../../store/pages/memberships/types';

interface ReservationDetailsProps {
    reservation: Reservation;
    resources: Resource[];
    products: Product[];
    customerCategories: CustomerCategory[];
    activityFormats: ActivityFormat[];
    membershipTypes: MembershipType[];
    bookings: Booking[];
    bills: Bill[];
    maxParticipants: number;
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
    saveReservation: (reservationKey: string, reservation: Reservation, reservationSelections: ReservationBooking[]) => void;
    close: () => void;
    deleteReservation: (reservationKey: string) => void;
}

interface ReservationDetailsState {
    isSaving: boolean;
    resourceSelectionId: ct.FormValue<string>;
    type: ct.FormValue<ReservationType>;
    times: ct.FormValue<ct.DateRange>;
    activityFormatId: ct.FormValue<string>;
    activityFormatVariationId: ct.FormValue<string>;
    maxParticipants: ct.FormValue<number>;
    notes: ct.FormValue<string>;
    shouldShowNotes: boolean;
    reservationSelections: ReservationBooking[];
    membershipTypeId: ct.FormValue<string>;
    errorKey: string | null;
}

class ReservationDetails extends React.Component<ReservationDetailsProps, ReservationDetailsState> {

    constructor(props: ReservationDetailsProps) {
        super(props);

        this.state = this.buildStateFromProps(this.props, null);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps = (props: ReservationDetailsProps, currentState: ReservationDetailsState | null): ReservationDetailsState => {
        const { reservation, resources, bookings, bills, maxParticipants } = props;

        const matchedResource = resources.find(r => r.id === reservation.resourceId);
        const resource = matchedResource ? matchedResource : resources[0];
        const resourceConfig = resource.configurations.length > 0 ? resource.configurations.find(c => c.id === reservation.resourceConfigurationId) : null;
        const resourceSelectionId = buildResourceSelectionId(resource.id, resourceConfig ? resourceConfig.id : null);

        return {
            isSaving: false,
            resourceSelectionId: this.validateResourceSelection(resourceSelectionId),
            type: this.validateReservationType(reservation.reservationType),
            times: this.validateTimes({ from: moment(reservation.startTime), to: moment(reservation.endTime) }),
            activityFormatId: this.validateActivityFormat(reservation.activityFormatId),
            activityFormatVariationId: this.validateActivityFormatVariation(reservation.activityFormatVariationId),
            maxParticipants: this.validateMaxParticipants(reservation.maxParticipants),
            notes: this.validateNotes(reservation.notes),
            shouldShowNotes: !isNullOrEmpty(reservation.notes),
            reservationSelections: this.createReservationSelections(reservation, bookings, bills, maxParticipants),
            membershipTypeId: this.validateMembershipTypeId(reservation.membershipTypeId || '-1'),
            errorKey: null
        };
    }

    createReservationSelections = (reservation: Reservation, bookings: Booking[], bills: Bill[], maxParticipants: number) => {
        return bookings.filter(b => !b.cancelled).reduce<ReservationBooking[]>((selections, bk) => {

            const billItems = bills.filter(b => b.bookingId === bk.id).map(b => b.items.filter(i => i.reservationId === reservation.id)).reduce((allItems, billItems) => allItems.concat(billItems), []);

            if (billItems.length === 0) {
                return selections.concat([{
                    bookingId: bk.id,
                    reservationKey: reservation.key,
                    customer: bk.customer,
                    currentMaxParticipants: maxParticipants,
                    billItemId: null,
                    productKey: '',
                    productId: '',
                    productPriceId: '',
                    customerCategoryId: '',
                    quantity: maxParticipants,
                    unitPrice: 0,
                    placesPerUnit: 1,
                    numberOfPlaces: 0,
                    createDateTimeInLocalTime: bk.createDateTimeInLocalTime,
                    firstEventStartDateTime: bk.firstEventStartDateTime,
                    tags: bk.customer ? bk.customer.tags : []
                }])
            } else {
                return selections.concat(billItems.filter(i => i.reservationId === reservation.id).map(i => ({
                    bookingId: bk.id,
                    reservationKey: reservation.key,
                    customer: bk.customer,
                    currentMaxParticipants: maxParticipants,
                    billItemId: i.id,
                    productKey: buildProductKey(i.productId, i.customerCategoryId),
                    productId: i.productId,
                    productPriceId: i.productPriceId || '',
                    customerCategoryId: i.customerCategoryId,
                    quantity: i.quantity,
                    unitPrice: i.unitPrice,
                    placesPerUnit: i.placesToBookPerUnit || 1,
                    numberOfPlaces: 0,
                    createDateTimeInLocalTime: bk.createDateTimeInLocalTime,
                    firstEventStartDateTime: bk.firstEventStartDateTime,
                    tags: bk.customer ? bk.customer.tags : []
                })));
            }
        }, []);
    }

    resourceSelectionChanged = (resourceSelectionId: ct.FormValue<string>) => this.setState({ resourceSelectionId: resourceSelectionId });
    typeChanged = (type: ct.FormValue<ReservationType>) => this.setState({ type: type });
    timesChanged = (times: ct.FormValue<ct.DateRange>) => this.setState({ times: times });
    activityFormatChanged = (activityFormatId: ct.FormValue<string>, activityFormatVariationId: ct.FormValue<string>, times: ct.FormValue<ct.DateRange>) => this.setState({ activityFormatId: activityFormatId, activityFormatVariationId: activityFormatVariationId, times: times });
    maxParticipantsChanged = (maxParticipants: ct.FormValue<number>, activityFormatVariationId: ct.FormValue<string>, times: ct.FormValue<ct.DateRange>) => this.setState({ maxParticipants: maxParticipants, activityFormatVariationId: activityFormatVariationId, times: times });
    notesChanged = (notes: ct.FormValue<string>) => this.setState({ notes: notes });
    showNotes = () => this.setState({ shouldShowNotes: true });
   
    validateResourceSelection = (val: string) => v.validate(val, 'resourceSelection', [v.required], []);
    validateReservationType = (val: ReservationType) => v.validate(val, 'reservationType', [v.required], []);
    validateTimes = (val: ct.DateRange) => v.validate(val || moment.utc(), 'startTime', [v.required], []);
    validateActivityFormat = (val: string) => v.validate(val, 'activityFormat', [v.required], []);
    validateActivityFormatVariation = (val: string) => v.validate(val, 'activityFormatVariation', [v.required], []);
    validateMaxParticipants = (val: number) => v.validate(val, 'maxParticipants', [], []);
    validateNotes = (val: string) => v.validate(val, 'notes', [], []);
    validateMembershipTypeId = (val: string) => v.validate(val, 'membershipTypeId', [], []);

    save = () => {
        const { resourceSelectionId, type, times, activityFormatId, activityFormatVariationId, maxParticipants, notes, reservationSelections, membershipTypeId } = this.state;
        const timeRange = times.value;
        if (!v.isValid(this.state) || !timeRange.from || !timeRange.to) {
        } else {
            const { reservation, activityFormats } = this.props;
            const { resourceId, resourceConfigId } = deconstructResourceSelectionId(resourceSelectionId.value);

            if (!this.checkProductSelections()) {
                this.setState({ errorKey: 'ReservationDetails:invalidProducts'});
            } else {

                const activityFormat = activityFormats.find(f => f.id === activityFormatId.value);
                if (!activityFormat) {
                    this.setState({ errorKey: 'ReservationDetails:invalidActivityFormat' });
                    return;
                }

                let variation = activityFormat.variations.find(v => v.id === activityFormatVariationId.value);
                if (!variation) {
                    if (activityFormat.variations.length === 1) {
                        variation = activityFormat.variations[0];
                    } else {
                        this.setState({ errorKey: 'ReservationDetails:invalidActivityFormat' });
                        return;
                    }
                }

                const schedule = variation.schedule.find(s => s.sequence === reservation.activityFormatVariationScheduleSequence);
                if (!schedule) {
                    this.setState({ errorKey: 'ReservationDetails:variationScheuleSequenceMissmatch' });
                    return;
                }

                this.props.saveReservation(reservation.key, {
                    ...reservation,
                    resourceId: resourceId,
                    resourceConfigurationId: resourceConfigId,
                    reservationType: type.value,
                    startTime: timeRange.from,
                    endTime: timeRange.to,
                    activityFormatId: activityFormatId.value,
                    activityFormatVariationId: activityFormatVariationId.value,
                    activityFormatVariationScheduleId: schedule.id,
                    activityFormatVariationScheduleSequence: schedule.sequence,
                    isMultiScheduleReservation: variation.schedule.length > 1,
                    maxParticipants: maxParticipants.value,
                    notes: notes.value,
                    membershipTypeId: membershipTypeId.value === '-1' ? null : membershipTypeId.value
                }, reservationSelections);
            }
        }
    }

    checkProductSelections = () => {
        const { activityFormats } = this.props;
        const { activityFormatId, reservationSelections } = this.state;

        const activityFormat = activityFormats.find(af => af.id === activityFormatId.value);
        if (!activityFormat) return false;

        var selectionsWithInvalidProducts = reservationSelections.filter(s => {
            const [productId, productCustomerCategoryId] = parseProductKey(s.productKey);
            const validProducts = activityFormat.products.filter(p => p.productId === productId && p.customerCategories.findIndex(c => c === productCustomerCategoryId) >= 0);
            return validProducts.length === 0;
        })

        return selectionsWithInvalidProducts.length === 0;
    }

    render() {
        const { reservation, activityFormats, products, customerCategories, resources, membershipTypes, close, deleteReservation, timeFormat, dateFormat } = this.props;
        const { isSaving, reservationSelections, errorKey, ...formState } = this.state;
        const { t } = this.context;

        const bookedParticipants = reservation.bookedParticipants.reduce((ttl, cp) => ttl + cp.count, 0);

        const deleteButton = reservation.id && bookedParticipants === 0 ? <button className='btn btn-danger' onClick={e => clickHandler(e, () => deleteReservation(reservation.key))} disabled={isSaving}>{t('ReservationDetails:deleteReservation')}</button> : null;
        const isNew = reservation.id ? false : true;

        return <div className='reservation-details-form'>
            <form className='data-form' onSubmit={formHandler} autoComplete='off'>

                <ReservationDetailsForm
                    reservationKey={reservation.key}
                    activityFormats={activityFormats}
                    resources={resources.filter(r => r.id === reservation.resourceId || !r.archived)}
                    products={products}
                    customerCategories={customerCategories}
                    membershipTypes={membershipTypes}
                    isNew={isNew}
                    isMultiScheduleReservation={reservation ? reservation.isMultiScheduleReservation : false}
                    resourceSelectionChanged={this.resourceSelectionChanged}
                    typeChanged={this.typeChanged}
                    timesChanged={this.timesChanged}
                    activityFormatChanged={this.activityFormatChanged}
                    maxParticipantsChanged={this.maxParticipantsChanged}
                    notesChanged={this.notesChanged}
                    showNotes={this.showNotes}
                    reservationSelections={reservationSelections}
                    reservationSelectionsChanged={sel => this.setState({ reservationSelections: sel })}
                    membershipTypeSelectionChanged={mt => this.setState({ membershipTypeId: this.validateMembershipTypeId(mt) })}
                    timeFormat={timeFormat}
                    dateFormat={dateFormat}
                    {...formState}
                            />

                {errorKey ?
                    <div className='row mt-15'>
                        <div className='col-md-12'>
                            <div className='alert alert-danger'>{t(errorKey)}</div>
                        </div>
                    </div>
                    : null}

                <div className='row mt-15'>
                    <div className='col-md-12'>
                        <div className='btn-panel'>
                            <button className='btn btn-primary' onClick={e => clickHandler(e, () => this.save())} disabled={isSaving}>{t('Global:save')}</button>
                            <button className='btn btn-basic' onClick={e => clickHandler(e, close)} disabled={isSaving}>{t('Global:cancel')}</button>
                            {deleteButton}
                        </div>
                    </div>
                </div>
            </form>
        </div>;
    }
}

export default ReservationDetails;
