
import * as React from 'react';
import {isNullOrEmpty} from '../../../utils/util';

interface StepHeaderProps {
    headerText: string;
}

const StepHeader = (props: StepHeaderProps) => {

    if (isNullOrEmpty(props.headerText)) {
        return null;
    }

    return (
        <div>
            {props.headerText}
            <p />
        </div>
    );
}

export default StepHeader;