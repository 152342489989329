import 'quill/dist/quill.snow.css';
import '../../../../css/quill.css';

import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'

import { quillToolbarWithImageAndLinks } from '../../../global/quillSettings';

import * as api from '../../../../store/apiClient';
import ApiError from '../../../global/apiError';
import { ApplicationState } from '../../../../store';
import * as ct from '../../../global/controls';
import * as v from '../../../global/validation';
import ColourPicker from '../../../global/colourPicker';
import { VenuePublicWebsiteSettings } from '../../../../store/pages/publicWebsiteSettings/types';
import * as PublicWebsiteSettingsActions from '../../../../store/pages/publicWebsiteSettings/actions';
import * as EmailTemplateActions from '../../../../store/pages/emailTemplates/actions';
import { clickHandler, isNullOrEmpty } from '../../../../utils/util';
import { ValidationError } from '../../../../store/global/types';
import { ClientEmailTemplate, EmailType } from '../../../../store/pages/emailTemplates/types';
import { Fee } from '../../../../store/pages/fees/types';

interface FieldSetting {
    key: string;
    id: number;
}

interface LocalProps {
    venueId: string;
    publicWebsiteSettings: VenuePublicWebsiteSettings;
    close: () => void;
}

interface MappedState {
    bookingConfirmationEmailTemplates: ClientEmailTemplate[];
    emailTemplatesLoading: boolean;
    fees: Fee[];
    feesLoading: boolean;
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
}

interface MappedActions {
    loadEmailTemplates: () => void;
    savePublicWebsiteSettings: (publicWebsiteSettings: VenuePublicWebsiteSettings, backgroundImg: File | null, headerImg: File | null) => void;
}

type OnlineBookingSettingsFormProps = MappedState & MappedActions & LocalProps;

interface OnlineBookingSettingsFormState {
    noAvailabilityMessage: ct.FormValue<string>;
    bookingConfirmationEmailTemplate: ct.FormValue<string>;
    upsellActivitiesPageHeader: ct.FormValue<string>;
    completionPageContentTemplate: ct.FormValue<string>;
    availableSlotButtonColour: string;
    availableSlotButtonTextColour: string;
    unavailableSlotButtonColour: string;
    unavailableSlotButtonTextColour: string;
    alternateAvailabilityMessage: ct.FormValue<string>;
    showArrivalTime: boolean;
    arrivalTimeMessage: ct.FormValue<string>;
    webBookingFeeId: string | null;
    bookingFields: number;
    requiredBookingFields: number;
    bookingBanner: ct.FormValue<string>;
    bookingBannerBackgroundColour: string;
    bookingBannerBorderColour: string;
    fieldSettings: FieldSetting[]; 
    quillStyle: React.CSSProperties | undefined;
    bannerQuillStyle: React.CSSProperties | undefined;
}

class OnlineBookingSettingsForm extends React.Component<OnlineBookingSettingsFormProps, OnlineBookingSettingsFormState> {

    constructor(props: OnlineBookingSettingsFormProps) {
        super(props);

        this.state = this.buildStateFromProps(this.props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps(props: OnlineBookingSettingsFormProps): OnlineBookingSettingsFormState {

        const settings = props.publicWebsiteSettings;

        const fieldSettings = [{ key: 'addressLine1', id: 1 }, { key: 'fullAddress', id: 2 }, { key: 'phoneNumber', id: 4 }];

        const bookingBannerBackgroundColour = settings.onlineBooking.bookingBannerBackgroundColour || '#000000';
        const bookingBannerBorderColour = settings.onlineBooking.bookingBannerBorderColour || '#000000';

        return {
            noAvailabilityMessage: this.validateNoAvailabilityMessage(settings.onlineBooking.noAvailabilityMessage),
            bookingConfirmationEmailTemplate: this.validateBookingConfirmationEmailTemplate(settings.onlineBooking.bookingConfirmationEmailTemplateId || ''),
            upsellActivitiesPageHeader: this.validateUpsellActivitiesPageHeader(settings.onlineBooking.upsellActivitiesPageHeader),
            completionPageContentTemplate: this.validateCompletionPageContent(settings.onlineBooking.completionPageContentTemplate),
            availableSlotButtonColour: isNullOrEmpty(settings.onlineBooking.availableSlotButtonColour) ? '#3997e8' : settings.onlineBooking.availableSlotButtonColour,
            availableSlotButtonTextColour: isNullOrEmpty(settings.onlineBooking.availableSlotButtonTextColour) ? '#ffffff' : settings.onlineBooking.availableSlotButtonTextColour,
            unavailableSlotButtonColour: isNullOrEmpty(settings.onlineBooking.unavailableSlotButtonColour) ? '#999999' : settings.onlineBooking.unavailableSlotButtonColour,
            unavailableSlotButtonTextColour: isNullOrEmpty(settings.onlineBooking.unavailableSlotButtonTextColour) ? '#eeeeee' : settings.onlineBooking.unavailableSlotButtonTextColour,
            alternateAvailabilityMessage: this.validateAlternateAvailabilityMessage(settings.onlineBooking.alternateAvailabilityMessage || ''),
            showArrivalTime: settings.onlineBooking.showArrivalTime,
            arrivalTimeMessage: this.validateArrivalTimeMessage(settings.onlineBooking.arrivalTimeMessage || ''),
            webBookingFeeId: settings.onlineBooking.webBookingFeeId,
            bookingFields: settings.onlineBooking.bookingFields,
            requiredBookingFields: settings.onlineBooking.requiredBookingFields,
            fieldSettings: fieldSettings,
            bookingBanner: this.validateBookingBanner(settings.onlineBooking.bookingBanner || ''),
            bookingBannerBackgroundColour: bookingBannerBackgroundColour,
            bookingBannerBorderColour: bookingBannerBorderColour,
            quillStyle: ({ backgroundColor: settings.panelColour, color: settings.panelTextColour }),
            bannerQuillStyle: ({ backgroundColor: bookingBannerBackgroundColour }),
        };
    }

    componentDidUpdate(prevProps: OnlineBookingSettingsFormProps) {
        // Only update state if resource has changed
        const { saveComplete: prevSaveComplete, publicWebsiteSettings: prevPublicWebsiteSettings } = prevProps;
        const { publicWebsiteSettings, saveComplete } = this.props;
        const prevOnlineBookingSettings = prevPublicWebsiteSettings.onlineBooking;
        const nextOnlineBookingSettings = publicWebsiteSettings.onlineBooking;

        if (prevOnlineBookingSettings.bookingConfirmationEmailTemplateId !== nextOnlineBookingSettings.bookingConfirmationEmailTemplateId
            || prevOnlineBookingSettings.completionPageContentTemplate !== nextOnlineBookingSettings.completionPageContentTemplate
            || prevOnlineBookingSettings.noAvailabilityMessage !== nextOnlineBookingSettings.noAvailabilityMessage
            || prevOnlineBookingSettings.availableSlotButtonColour !== nextOnlineBookingSettings.availableSlotButtonColour
            || prevOnlineBookingSettings.availableSlotButtonTextColour !== nextOnlineBookingSettings.availableSlotButtonTextColour
            || prevOnlineBookingSettings.unavailableSlotButtonColour !== nextOnlineBookingSettings.unavailableSlotButtonColour
            || prevOnlineBookingSettings.unavailableSlotButtonTextColour !== nextOnlineBookingSettings.unavailableSlotButtonTextColour
            || prevOnlineBookingSettings.showArrivalTime !== nextOnlineBookingSettings.showArrivalTime
            || prevOnlineBookingSettings.arrivalTimeMessage !== nextOnlineBookingSettings.arrivalTimeMessage
            || prevOnlineBookingSettings.webBookingFeeId !== nextOnlineBookingSettings.webBookingFeeId
            || prevOnlineBookingSettings.bookingBanner !== nextOnlineBookingSettings.bookingBanner
            || prevOnlineBookingSettings.bookingBannerBackgroundColour !== nextOnlineBookingSettings.bookingBannerBackgroundColour
            || prevOnlineBookingSettings.bookingBannerBorderColour !== nextOnlineBookingSettings.bookingBannerBorderColour) {
            this.setState(this.buildStateFromProps(this.props));
        }

        if (saveComplete && !prevSaveComplete) {
            setTimeout(() => { this.close(); }, 750);
        }
    }

    private savePaymentPageSettings = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }

    private close = () => {
        this.props.close();
    }

    private save = () => {
        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {
            const { publicWebsiteSettings } = this.props;
            const { noAvailabilityMessage, bookingConfirmationEmailTemplate, completionPageContentTemplate, availableSlotButtonColour, availableSlotButtonTextColour,
                unavailableSlotButtonColour, unavailableSlotButtonTextColour, showArrivalTime, arrivalTimeMessage, alternateAvailabilityMessage, upsellActivitiesPageHeader,
                webBookingFeeId, bookingFields, requiredBookingFields, bookingBanner, bookingBannerBackgroundColour, bookingBannerBorderColour, } = this.state;

            const cleansedNoAvailabilityMessage = noAvailabilityMessage.value === '<p><br></p>' ? '' : noAvailabilityMessage.value;
            const cleansedAlternateAvailabilityMessage = alternateAvailabilityMessage.value === '<p><br></p>' ? '' : alternateAvailabilityMessage.value;
            const cleansedBanner = bookingBanner.value === '<p><br></p>' ? '' : bookingBanner.value;

            this.props.savePublicWebsiteSettings({
                ...publicWebsiteSettings,
                onlineBooking: {
                    noAvailabilityMessage: cleansedNoAvailabilityMessage,
                    bookingConfirmationEmailTemplateId: bookingConfirmationEmailTemplate.value,
                    upsellActivitiesPageHeader: upsellActivitiesPageHeader.value,
                    completionPageContentTemplate: completionPageContentTemplate.value,
                    availableSlotButtonColour: availableSlotButtonColour,
                    availableSlotButtonTextColour: availableSlotButtonTextColour,
                    unavailableSlotButtonColour: unavailableSlotButtonColour,
                    unavailableSlotButtonTextColour: unavailableSlotButtonTextColour,
                    alternateAvailabilityMessage: cleansedAlternateAvailabilityMessage,
                    showArrivalTime: showArrivalTime,
                    arrivalTimeMessage: arrivalTimeMessage.value,
                    webBookingFeeId: isNullOrEmpty(webBookingFeeId) ? null : webBookingFeeId,
                    completionPageTags: [],
                    bookingFields: bookingFields,
                    requiredBookingFields: requiredBookingFields,
                    bookingBanner: cleansedBanner,
                    bookingBannerBackgroundColour: bookingBannerBackgroundColour,
                    bookingBannerBorderColour: bookingBannerBorderColour
                }
            }, null, null);
        }
    }

    validateNoAvailabilityMessage = (val: string) => v.validate(val, 'noAvailabilityMessage', [v.required], this.props.validationErrors);
    validateBookingConfirmationEmailTemplate = (val: string) => v.validate(val, 'bookingConfirmationEmailTemplate', [v.required], this.props.validationErrors);
    validateAlternateAvailabilityMessage = (val: string) => v.validate(val, 'alternateAvailabilityMessage', [], this.props.validationErrors);
    validateCompletionPageContent = (val: string) => v.validate(val, 'completionPageContent', [], []);
    validateUpsellActivitiesPageHeader = (val: string) => v.validate(val, 'completionPageContent', [], []);
    validateArrivalTimeMessage = (val: string) => v.validate(val, 'arrivalTimeMessage', [], []);
    validateBookingBanner = (val: string) => v.validate(val, 'bookingBanner', [], []);

    bookingConfirmationEmailTemplateChanged = (val: string) => this.setState({ bookingConfirmationEmailTemplate: this.validateBookingConfirmationEmailTemplate(val) });
    upsellActivitiesPageHeaderChanged = (val: string) => this.setState({ upsellActivitiesPageHeader: this.validateUpsellActivitiesPageHeader(val) });
    completionPageContentChanged = (val: string) => this.setState({ completionPageContentTemplate: this.validateCompletionPageContent(val) });
    bookingBannerChanged = (val: string) => this.setState({ bookingBanner: this.validateBookingBanner(val) }); 
    noAvailabilityMessageChanged = (val: string) => this.setState({ noAvailabilityMessage: this.validateNoAvailabilityMessage(val) }); 
    alternateAvailabilityMessageChanged = (val: string) => this.setState({ alternateAvailabilityMessage: this.validateAlternateAvailabilityMessage(val) }); 

    clearBanner = () => this.setState(s => ({ bookingBanner: this.validateBookingBanner('') }));

    webBookingFeeChanged = (feeId: string) => {
        const { venueId } = this.props;
        const { fees } = this.props;
        const fee = fees.find(f => f.venueId === venueId && f.id === feeId);
        this.setState({ webBookingFeeId: fee ? fee.id : '' });
    }

    asFld = (controlId: string, val: any, isValid: boolean = true, errKey?: string) => ({ controlId: controlId, value: val, isValid: isValid, hasValidation: !isValid, errorMessageKey: errKey });

    setFieldState = (id: number, enabled: boolean) => {

        let val = this.state.bookingFields;
        let requiredVals = this.state.requiredBookingFields;

        if (enabled) {
            val |= id;
        } else {
            val &= ~id;
            requiredVals &= ~id;
        }

        this.setState({ bookingFields: val, requiredBookingFields: requiredVals });
    }

    setFieldRequiredState = (id: number, required: boolean) => {

        let val = this.state.requiredBookingFields;

        if (required) {
            val |= id;
        } else {
            val &= ~id;
        }

        this.setState({ requiredBookingFields: val });
    }

    render() {
        const t = this.context.t;
        const { publicWebsiteSettings, bookingConfirmationEmailTemplates, saveError, saveComplete, fees, venueId } = this.props;
        const { noAvailabilityMessage, bookingConfirmationEmailTemplate, completionPageContentTemplate, availableSlotButtonColour, availableSlotButtonTextColour,
            unavailableSlotButtonColour, unavailableSlotButtonTextColour, showArrivalTime, arrivalTimeMessage, alternateAvailabilityMessage,
            webBookingFeeId, quillStyle, fieldSettings, bookingFields, requiredBookingFields, bookingBanner, bookingBannerBackgroundColour,
            bookingBannerBorderColour, bannerQuillStyle, upsellActivitiesPageHeader } = this.state;

        const message = saveError ? <ApiError error={saveError} /> : saveComplete ? <div className='bg-success'>{t('Global:saveComplete')}</div> : null;

        const templateOptions = bookingConfirmationEmailTemplates.filter(t => t.venueId === venueId).map(t => ({ key: t.clientEmailTemplateId || '', name: t.name || '' })).filter(x => !isNullOrEmpty(x.key) && !isNullOrEmpty(x.name));
        const emailTemplateOptions = [{ key: '', name: t('Global:selectTemplate') }].concat(templateOptions);
        const feeOptions = [{ key: '', name: t('OnlineBookingSettingsForm:noFee') }].concat(fees.filter(f => f.venueId === venueId).map(f => ({ key: f.id, name: f.name })));

        return <div className='onlineBookingSettingsForm'>
            <h2 className='onlineBookingSettingsForm-title'>{t('OnlineBookingSettingsForm:title')}</h2>

            <div className='row'>
                <div className='col-md-12'>
                    <form className='data-form' onSubmit={this.savePaymentPageSettings} autoComplete='off'>

                        <div className='row'>
                            <div className='col-md-12'>
                                <ct.HtmlInput id='noAvailabilityMessage' labelKey='OnlineBookingSettingsForm:noAvailabilityMessage' value={noAvailabilityMessage} callback={this.noAvailabilityMessageChanged} modules={quillToolbarWithImageAndLinks} quillClassName='at-panel-bg' editorStyle={quillStyle} inlineStyles={true} />
                            </div>
                        </div>

                        <p />

                        <ct.Select id='emailTemplate' labelKey='OnlineBookingSettingsForm:bookingConfirmationEmailTemplate' value={bookingConfirmationEmailTemplate} callback={this.bookingConfirmationEmailTemplateChanged} options={emailTemplateOptions} />

                        <h4>{t('OnlineBookingSettingsForm:upsellActivitiesPageHeader')}</h4>
                        <div className='row'>
                            <div className='col-md-12'>
                                <ct.HtmlInput id='upsellActivitiesPageHeader' labelKey='' value={upsellActivitiesPageHeader} callback={this.upsellActivitiesPageHeaderChanged} modules={quillToolbarWithImageAndLinks} quillClassName='at-panel-bg' editorStyle={quillStyle} inlineStyles={true} />
                            </div>
                        </div>

                        <h4>{t('OnlineBookingSettingsForm:completionPageContent')}</h4>
                        <div className='row'>
                            <div className='col-md-8'>
                                <ct.HtmlInput id='completionPageContent' labelKey='' value={completionPageContentTemplate} callback={this.completionPageContentChanged} modules={quillToolbarWithImageAndLinks} quillClassName='at-panel-bg' editorStyle={quillStyle} inlineStyles={true} />
                            </div>
                            <div className='col-md-4'>
                                <div className='panel panel-info'>
                                    <div className='panel-heading'>
                                        <h3 className='panel-title'>{this.context.t('OnlineBookingSettingsForm:completionPageTags')}</h3>
                                    </div>
                                    <div className='panel-body'>
                                        <ul className='plain-list'>
                                            {publicWebsiteSettings.onlineBooking.completionPageTags.map(tag => <li key={tag.key}>{`{{${tag.key}}}`} <ct.Help text={this.context.t(`BookingConfirmationPageTag:${tag.key}`)} /></li>)}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ColourPicker labelKey='OnlineBookingSettingsForm:availableSlotButtonColour' colourHex={availableSlotButtonColour} onColourChanged={color => this.setState({ availableSlotButtonColour: color })} />
                        <ColourPicker labelKey='OnlineBookingSettingsForm:availableSlotButtonTextColour' colourHex={availableSlotButtonTextColour} onColourChanged={color => this.setState({ availableSlotButtonTextColour: color })} />

                        <ColourPicker labelKey='OnlineBookingSettingsForm:unavailableSlotButtonColour' colourHex={unavailableSlotButtonColour} onColourChanged={color => this.setState({ unavailableSlotButtonColour: color })} />
                        <ColourPicker labelKey='OnlineBookingSettingsForm:unavailableSlotButtonTextColour' colourHex={unavailableSlotButtonTextColour} onColourChanged={color => this.setState({ unavailableSlotButtonTextColour: color })} />

                        <ct.Checkbox id='showArrivalTime' labelKey='OnlineBookingSettingsForm:showArrivalTime' value={ct.asFormValue('showArrivalTime', showArrivalTime)} callback={val => this.setState({ showArrivalTime: val })} />

                        <div className='row'>
                            <div className='col-md-12'>
                                <ct.HtmlInput id='alternateAvailabilityMessage' labelKey='OnlineBookingSettingsForm:alternateAvailabilityMessage' value={alternateAvailabilityMessage} callback={this.alternateAvailabilityMessageChanged} modules={quillToolbarWithImageAndLinks} quillClassName='at-panel-bg' editorStyle={quillStyle} inlineStyles={true} />
                            </div>
                        </div>

                        <ct.Select id='webBookingFee' labelKey='OnlineBookingSettingsForm:webBookingFee' value={ct.asFormValue('webBookingFee', webBookingFeeId || '')} callback={this.webBookingFeeChanged} options={feeOptions} />

                        {showArrivalTime
                            ? <ct.TextBox id='arrivalTimeMessage' labelKey='OnlineBookingSettingsForm:arrivalTimeMessage' placeholderKey='' hintKey='OnlineBookingSettingsForm:arrivalTimeMessageHint' value={arrivalTimeMessage} callback={val => this.setState({ arrivalTimeMessage: this.validateArrivalTimeMessage(val) })} />
                            : null}
                        {message}

                        <p />
                        <label>{t('OnlineBookingSettingsForm:optionalCustomerInformation')}</label>
                        <div className='registration-kiosk-settings-list'>
                            <div className='registration-kiosk-setting'>
                                <div className='registration-kiosk-setting-name'></div>
                                <div className='registration-kiosk-setting-selection'><label>{t(`OnlineBookingSettingsForm:setting_collect`)}</label></div>
                                <div className='registration-kiosk-setting-selection'><label>{t(`OnlineBookingSettingsForm:setting_required`)}</label></div>
                            </div>
                            {fieldSettings.map(s => (
                                <div key={s.key} className='registration-kiosk-setting'>
                                    <div className='registration-kiosk-setting-name'>{t(`OnlineBookingSettingsForm:setting_${s.key}`)}</div>
                                    <div className='registration-kiosk-setting-selection'><ct.Checkbox id={`${s.key}_enabled`} labelKey='' value={this.asFld(`${s.key}_enabled`, (bookingFields & s.id) === s.id)} callback={val => this.setFieldState(s.id, val)} /></div>
                                    <div className='registration-kiosk-setting-selection'><ct.Checkbox id={`${s.key}_required`} labelKey='' value={this.asFld(`${s.key}_required`, (requiredBookingFields & s.id) === s.id)} callback={val => this.setFieldRequiredState(s.id, val)} disabled={(bookingFields & s.id) === 0} /></div>
                                </div >
                            ))}
                        </div>
                        <p />
                        <label>{t('OnlineBookingSettingsForm:bookingBanner')}</label>
                        <div className='row'>
                            <div className='col-xs-12'>
                                <ColourPicker labelKey='OnlineBookingSettingsForm:bookingBannerBackground' colourHex={bookingBannerBackgroundColour} onColourChanged={color => this.setState({ bookingBannerBackgroundColour: color, bannerQuillStyle: ({ backgroundColor: color }) })} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-xs-12'>
                                <ColourPicker labelKey='OnlineBookingSettingsForm:bookingBannerBorder' colourHex={bookingBannerBorderColour} onColourChanged={color => this.setState({ bookingBannerBorderColour: color })} />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-xs-12'>
                                <div style={{ maxWidth: '600px' }}>
                                    <ct.HtmlInput id='completionPageContent' labelKey='' value={bookingBanner} callback={this.bookingBannerChanged} modules={quillToolbarWithImageAndLinks} quillClassName='at-panel-bg' editorStyle={bannerQuillStyle} inlineStyles={true} />
                                    <button className='btn btn-basic pull-right' onClick={e => clickHandler(e, this.clearBanner)}>{t('Global:clear')}</button>
                                </div>
                            </div>
                        </div>
                        <p />
                        <p />

                        <div className='btn-toolbar'>
                            <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)}>{t('Global:save')}</button>
                            <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{t('Global:cancel')}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>;
    }
}


const mapStateToProps = (state: ApplicationState) => ({
    bookingConfirmationEmailTemplates: state.emailTemplates.emailTemplates.filter(t => t.emailType === EmailType.BookingConfirmation && !t.archived),
    emailTemplatesLoading: state.emailTemplates.isLoading,
    fees: state.fees.fees,
    feesLoading: state.fees.isLoading,
    isSaving: state.publicWebsiteSettings.isSaving,
    saveComplete: state.publicWebsiteSettings.saveComplete,
    saveError: state.publicWebsiteSettings.saveError,
    validationErrors: state.publicWebsiteSettings.validationErrors,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    savePublicWebsiteSettings: bindActionCreators(PublicWebsiteSettingsActions.actionCreators.savePublicWebsiteSettings, dispatch),
    loadEmailTemplates: bindActionCreators(EmailTemplateActions.actionCreators.loadEmailTemplates, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(OnlineBookingSettingsForm);
