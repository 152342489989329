
import '../../../css/reactdatetime.css';

import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'

import Datetime from 'react-datetime';
import moment from 'moment';

import { ApplicationState } from '../../../store';
import * as api from '../../../store/apiClient';
import * as ActivityFormatActions from '../../../store/pages/activityFormats/actions';
import * as ReceptionActions from '../../../store/pages/reception/actions';
import * as ModalActions from '../../../store/global/modal/actions';
import * as LoginActions from '../../../store/pages/login/actions';
import PointOfSalePanel from '../pointOfSale/pointOfSalePanel';
import FindRegisteredCustomers from './findRegisteredCustomers';
import { Event, ReservationType } from '../../../store/pages/diary/types';
import { IEvent, IBooking } from './types';
import { CustomerCategoryCustomFieldValue, ReceptionView, Registration, RegistrationCustomFieldValue, RegistrationEventBookingNote, RegistrationEventSelection} from '../../../store/pages/reception/types';
import { isNullOrEmpty, clickHandler, generateTempId} from '../../../utils/util';
import Loading from '../../global/loading';
import { ActivityFormat } from '../../../store/pages/activityFormats/types';
import { DateFormat, TimeFormat, Venue } from '../../../store/pages/venues/types';
import EditRegistration from './editRegistration';
import ReceptionPagePrintView from './receptionPagePrintView';
import { CustomerCategory } from '../../../store/pages/customerCategories/types';
import { ClientEmailTemplate, EmailType } from '../../../store/pages/emailTemplates/types';
import { Product, ProductType } from '../../../store/pages/products/types';
import { ProductCategory } from '../../../store/pages/productCategories/types';
import { PaymentMethod } from '../../../store/pages/paymentMethods/types';
import { TaxRate } from '../../../store/pages/taxRates/types';
import { Fee } from '../../../store/pages/fees/types';
import { Promotion } from '../../../store/pages/promotions/types';
import { VoucherProduct } from '../../../store/pages/vouchers/types';
import CreateEvent from '../diary/createEvent';
import { MarketingPreference } from '../../../store/pages/customer/types';
import ResourceFilter from '../../global/resourceFilter';
import { MembershipType } from '../../../store/pages/memberships/types';
import EventsView from './eventsView';
import PeopleView from './peopleView';
import { SelectedRegistration } from '../diary/types';
import BookingNotesOverlay from './bookingNotesOverlay';
import { is } from 'immutable';

interface LocalProps {
}

type RoutedLocalProps = LocalProps & RouteComponentProps<{}>;

interface LocalState {
    selectedDate: Date;
    selectedVenueId: string;
    venue: Venue | null;
    expandedEvents: string[];
    isSaving: boolean;
    isLoadingRegistrations: boolean;
    isLoadingEvents: boolean;
    saveError: api.ApiError | null;
    activityFormats: ActivityFormat[];
    activityFormatsLoading: boolean;
    registrations: Registration[];
    events: IEvent[];
    customerCategories: CustomerCategory[];
    emailTemplates: ClientEmailTemplate[];
    emailTemplatesLoading: boolean;
    products: Product[];
    productsLoading: boolean;
    productCategories: ProductCategory[];
    productCategoriesLoading: boolean;
    paymentMethods: PaymentMethod[];
    paymentMethodsLoading: boolean;
    taxRates: TaxRate[];
    taxRatesLoading: boolean;
    fees: Fee[];
    feesLoading: boolean;
    promotions: Promotion[];
    vouchers: VoucherProduct[];
    membershipTypes: MembershipType[];
    hiddenResources: string[];
    selectedView: ReceptionView;
}

interface Actions {
    switchDate: (date: Date) => void;
    addRegistrationsToBooking: (bookingId: string, registrationIds: string[]) => void;
    unregister: (venueId: string, bookingId: string, registrationId: string) => void;
    checkInCustomers: (venueId: string, registrationIds: string[]) => void;
    undoCheckedInCustomers: (venueId: string, registrationIds: string[]) => void;
    updateRegistration: (venueId: string, registrationId: string, bookingId: string, dateOfBirth: Date, nickname: string, publicResultsConsent: boolean, marketingPreference: MarketingPreference, resultsPreference: MarketingPreference, eventSelections: RegistrationEventSelection[], customFields: RegistrationCustomFieldValue[], categoryCustomFields: CustomerCategoryCustomFieldValue[]) => void;
    loadRegistrations: (venueId: string, date: Date) => void;
    loadEvents: (venueId: string, date: Date) => void;
    expand: (eventId: string) => void;
    collapse: (eventId: string) => void;
    loadActivityFormats: () => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
    logout: () => void;
    setReceptionView: (view: ReceptionView) => void;
}

type ReceptionPageProps = LocalState & Actions & RoutedLocalProps

interface ReceptionPageState {
    selectedKioskIds: string[];
    addBooking: boolean;
    eventToAddTo: IEvent | null;
    selectedRegistrations: SelectedRegistration[]
}

class ReceptionPage extends React.Component<ReceptionPageProps, ReceptionPageState> {

    lastCheckedIndex: number;

    constructor(props: ReceptionPageProps) {
        super(props);

        this.state = { selectedKioskIds: [], addBooking: false, eventToAddTo: null, selectedRegistrations: [] };
        this.lastCheckedIndex = -1;
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidUpdate(prevProps: ReceptionPageProps) {
        const { selectedVenueId, selectedDate, isSaving, saveError, closeModal  } = this.props;
        const { selectedVenueId: prevSelectedVenueId, isSaving: prevIsSaving} = prevProps;

        // Clear selected registrations and re-load registrations if save is complete
        if (prevIsSaving && !isSaving && !saveError) {
            this.lastCheckedIndex = -1;
            this.loadRegistrations(selectedVenueId, selectedDate);
            closeModal();
        } else {
            // if venue id has changed, reload
            if ((selectedVenueId !== prevSelectedVenueId && !isNullOrEmpty(selectedVenueId)) || (prevIsSaving && !isSaving)) {
                this.loadRegistrations(selectedVenueId, selectedDate);
                this.loadEvents(selectedVenueId, selectedDate);
            }
        }
    }

    componentDidMount() {
        const { selectedVenueId, isLoadingEvents, isLoadingRegistrations, registrations } = this.props;

        if (!isNullOrEmpty(selectedVenueId)) {
            if (!isLoadingRegistrations && registrations.length === 0) {
                this.loadRegistrations(selectedVenueId, this.props.selectedDate);
            }

            // TODO: 2019-12-01: always re-load events when page loaded - temp fix to handle sometimes stale data where bookings are missing
            //  Need to work out a proper fix.
            if (!isLoadingEvents /*&& events.length === 0*/) {
                this.loadEvents(selectedVenueId, this.props.selectedDate);
            }
        }
    }

    reload = () => {
        this.reloadRegistrations();
        this.reloadEvents();
    }

    reloadRegistrations = () => this.loadRegistrations(this.props.selectedVenueId, this.props.selectedDate);
    reloadEvents = () => this.loadEvents(this.props.selectedVenueId, this.props.selectedDate);

    loadRegistrations = (venueId: string, date: Date) => {
        if (venueId && venueId.length && venueId.length > 0) {
            this.props.loadRegistrations(venueId, date);
        }
    }

    loadEvents = (venueId: string, date: Date) => {
        if (venueId && venueId.length && venueId.length > 0) {
            this.props.loadEvents(venueId, date);
        }
    }

    findCustomers = (booking: IBooking) => {
        const { venue, emailTemplates, registrations, events, addRegistrationsToBooking, closeModal, logout } = this.props;

        const generalRegistrationLinkEmailTemplates = emailTemplates.filter(t => t.emailType === EmailType.BookingRegistrationLink);
        const register = (selectedCustomers: string[]) => {
            const registrationIds = selectedCustomers/*.map(c => c.lastCurrentRegistrationId || c.lastRegistrationId || '')*/.filter(rid => !isNullOrEmpty(rid));
            addRegistrationsToBooking(booking.id, registrationIds);
            closeModal();
        }

        if (venue) {
            this.props.showModal(<FindRegisteredCustomers
                venue={venue}
                bookingId={booking.id}
                registrationLinkEmailTemplates={generalRegistrationLinkEmailTemplates}
                registrations={registrations}
                events={events}
                registerCustomers={register}
                billNumber={booking.billNumber}
                bookingCustomerName={`${booking.organiserFirstName} ${booking.organiserLastName}`}
                bookingCustomerPhoneNumber={booking.organiserPhoneNumber}
                bookingCustomerPostcode={booking.organiserPostcode}
                cancel={closeModal}
                logout={logout} />, 'FindRegisteredCustomers');
        }
    }

    updateRegistration = (registrationId: string, bookingId: string, dateOfBirth: Date, nickname: string, publicResultsConsent: boolean, marketingPreference: MarketingPreference, resultsPreference: MarketingPreference, eventSelections: RegistrationEventSelection[], customFields: RegistrationCustomFieldValue[], categoryCustomFields: CustomerCategoryCustomFieldValue[]) => {
        const { selectedVenueId } = this.props;

        this.props.updateRegistration(selectedVenueId, registrationId, bookingId, dateOfBirth, nickname, publicResultsConsent, marketingPreference, resultsPreference, eventSelections, customFields, categoryCustomFields);
    }

    editRegistration = (bookingId: string, registrationId: string) => {
        const { venue, selectedVenueId, showModal, closeModal, registrations, customerCategories, unregister  } = this.props;
        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;

        const venueId = venue ? venue.id : '';
        var reg = registrations.find(r => r.id === registrationId);
        if (reg) {
            showModal(<EditRegistration
                registration={reg}
                bookingId={bookingId}
                customerCategories={customerCategories.filter(c => c.venueId === venueId && !c.archived)}
                timeFormat={timeFormat}
                dateFormat={dateFormat}
                update={this.updateRegistration}
                unregister={() => unregister(selectedVenueId, bookingId, registrationId)}
                close={closeModal} />, 'EditRegistration')
        }
    }

    checkIn = (bookingId: string, registrationId: string) => {
        const { checkInCustomers, selectedVenueId } = this.props;
        checkInCustomers(selectedVenueId, [registrationId]);
    }

    undoCheckIn = (bookingId: string, registrationId: string) => {
        const { undoCheckedInCustomers, selectedVenueId } = this.props;
        undoCheckedInCustomers(selectedVenueId, [registrationId]);
    }

    mapEventsToIevents = (events: Event[], registrations: Registration[]) => events.map(e => ({
        id: e.id,
        name: e.name,
        colour: e.colour,
        startTime: e.startTime,
        maxCompetitors: e.reservations.reduce((max, r) => Math.max(max, r.maxParticipants), 0),
        numberOfRegistrations: registrations.filter(r => r.registeredForEvents.filter(er => er.eventId === e.id && !er.removed).length > 0).length
    }));

    dateChanged = (date: React.ChangeEvent<any> | moment.Moment | string) => {
        if (moment.isMoment(date)) {
            this.props.switchDate(date.toDate());
        }
    }

    unregister = (bookingId: string, registrationId: string) => {
        const { venue, unregister } = this.props;
        if (venue) {
            unregister(venue.id, bookingId, registrationId);
        }
    }

    payIndividually = (bookingId: string, billId: string, customerId: string) => this.makePayment(bookingId, billId, customerId, 0);

    payRemaining = (bookingId: string, billId: string, amount: number) => this.makePayment(bookingId, billId, null, amount);

    viewBill = (bookingId: string, billId: string) => {
        const { venue, products, productCategories, paymentMethods, activityFormats, customerCategories, fees, taxRates, promotions, vouchers, membershipTypes, closeModal, showModal, logout } = this.props;

        if (!venue)
            return;

        showModal(<PointOfSalePanel venue={venue} showPayments={false} products={products.filter(p => p.venueId === venue.id || p.type === ProductType.Voucher || p.type == ProductType.Membership)} eventProducts={[]} activityFormats={activityFormats} productCategories={productCategories} paymentMethods={paymentMethods} customerCategories={customerCategories} fees={fees} taxRates={taxRates} promotions={promotions} booking={null} billInfo={billId} posSessionComplete={this.billUpdated} vouchers={vouchers} membershipTypes={membershipTypes} logout={logout} />, 'PointOfSalePanel', true);
    }

    makePayment = (bookingId: string, billId: string, customerId: string | null, amount: number) => {
        const { venue, products, productCategories, paymentMethods, activityFormats, customerCategories, fees, taxRates, promotions, vouchers, membershipTypes, closeModal, showModal, logout } = this.props;

        if (!venue)
            return;

        showModal(<PointOfSalePanel venue={venue} showPayments={true} products={products.filter(p => p.venueId === venue.id || p.type === ProductType.Voucher || p.type == ProductType.Membership)} eventProducts={[]} activityFormats={activityFormats} productCategories={productCategories} paymentMethods={paymentMethods} customerCategories={customerCategories} fees={fees} taxRates={taxRates} promotions={promotions} booking={null} billInfo={billId} paymentAmount={amount} customerId={customerId ? customerId : undefined} posSessionComplete={this.billUpdated} vouchers={vouchers} membershipTypes={membershipTypes} logout={logout} />, 'PointOfSalePanel', true);
    }

    billUpdated = () => {
        const { closeModal } = this.props;
        closeModal();
        this.reloadEvents();
    }

    print = () => {
        const { showModal, closeModal, events, registrations, selectedDate, venue } = this.props;
        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;
        showModal(<ReceptionPagePrintView events={events} registrations={registrations} close={closeModal} date={selectedDate} timeFormat={timeFormat} dateFormat={dateFormat} />, 'PrintReceptionPage')
    }

    showNotes = (notes: RegistrationEventBookingNote[], name: string, billNumber: string) => {
        if (notes.length > 0) {
            this.props.showModal(<BookingNotesOverlay notes={notes} bookingName={name} billNumber={billNumber} close={this.props.closeModal} />, 'booking-notes')
        }
    }

    addBooking = (registrations: SelectedRegistration[]) => this.setState({ addBooking: true, eventToAddTo: null, selectedRegistrations: registrations });

    addBookingToEvent = (event: IEvent) => this.setState({ addBooking: true, eventToAddTo: event });

    eventCreated = (eventIds: string[], bookingId: string | null) => {
        const { selectedRegistrations } = this.state;
        if (bookingId && selectedRegistrations.length > 0) {
            this.props.addRegistrationsToBooking(bookingId, selectedRegistrations.map(r => r.registrationId));
        }
        this.setState({ addBooking: false, eventToAddTo: null, selectedRegistrations: [] }, () => this.reloadEvents());
    }

    render() {
        const { isLoadingEvents, events, venue, selectedDate, selectedView, setReceptionView } = this.props;
        const { t } = this.context;
        const { addBooking, eventToAddTo, selectedRegistrations } = this.state;

        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;
        const date = moment(selectedDate);
        const eventList = isLoadingEvents && events.length === 0
            ? <Loading />
            : selectedView === ReceptionView.Events
                ? this.renderEventsList()
                : this.renderPeople();

        const todayButton = moment().isSame(date, 'day') ? null : <button className='btn btn-primary' onClick={e => clickHandler(e, () => this.dateChanged(moment()))} style={({ marginLeft: '12px' })} title={t('Global:today')}>{t('Global:today')}</button> ;
        const dateWarning = moment().isSame(date, 'day') ? null : <div className='row'><div className='col-xs-12 alert alert-danger'>{t('ReceptionPage:dateWarning{date}', { date: <strong>{date.toDate().toAbbrDateString(dateFormat, t)}</strong> })}</div></div>;

        if (venue && addBooking) {
            const reservation = eventToAddTo
                ? eventToAddTo.reservations.sort((r1, r2) => r1.startTime.getTime() - r2.startTime.getTime())
                    .map(r => ({
                        id: r.reservationId,
                        startTime: r.startTime,
                        activityFormatId: r.activityFormatId,
                        activityFormatVariationId: r.activityFormatVariationId,
                        activityFormatVariationScheduleId: r.activityFormatVariationScheduleId,
                        resourceId: r.resourceId,
                        resourceConfigurationId: r.resourceConfigurationId,
                        reservationType: r.reservationType
                    }))[0]
                : { id: '', key: generateTempId(), startTime: new Date(), activityFormatId: '', activityFormatVariationId: '', activityFormatVariationScheduleId: '', resourceId: '', resourceConfigurationId: '', reservationType:ReservationType.NonExclusive };
            const cancelAction = () => this.setState({ addBooking: false, eventToAddTo: null });
            return <CreateEvent
                venue={venue}
                booking={null}
                billItemsToReschedule={[]}
                customerCategoryDefaults={[]}
                bookedReservationIds={selectedRegistrations.map(r => r.registrationId)}
                showSelectCustomer={false}
                customer={selectedRegistrations.length > 0 ? selectedRegistrations[0] : undefined}
                customersToRegister={selectedRegistrations}
                defaultCountryId={venue.countryId}
                reservation={reservation}
                isSaving={false}
                eventCreated={this.eventCreated}
                cancel={cancelAction} />
        }

        return <section className='reception-page'>
            <div className='reception-page-content'>
                <header className='reception-page-header'>
                    <div>
                        <Datetime value={date} onChange={this.dateChanged} timeFormat={false} className='date-picker' closeOnSelect={true} dateFormat={dateFormat === DateFormat.MDY ? "dddd MMMM Do YYYY" : "dddd D MMMM YYYY"} />
                        {todayButton}
                    </div>
                    <div className='reception-page-header-actions'>
                        <div className='reception-view-selection'>
                            <button type='button' className={`btn btn-sm ${selectedView === ReceptionView.Events ? 'btn-info' : 'btn-basic'}`} onClick={e => clickHandler(e, () => setReceptionView(ReceptionView.Events))}>
                                <span className='glyphicon glyphicon-calendar'></span>
                            </button>
                            <button type='button' className={`btn btn-sm ${selectedView === ReceptionView.People ? 'btn-info' : 'btn-basic'}`} onClick={e => clickHandler(e, () => setReceptionView(ReceptionView.People))}>
                                <span className='glyphicon glyphicon-user'></span>
                            </button>
                        </div>
                        {this.renderKioskFilter(venue)}
                        <ResourceFilter />

                        <button type='button' className='btn btn-sm btn-primary' onClick={e => clickHandler(e, this.print)}>{t('Global:print')}</button>
                        <button type='button' className='btn btn-sm btn-info' onClick={e => clickHandler(e, this.reload)}>
                            <span className='glyphicon glyphicon-refresh'></span>
                        </button>
                    </div>
                </header>
                {dateWarning}
                <div className='reception-wrapper'>
                    {eventList}
                </div>
             </div>
        </section>;
    }

    toggleKioskState = (kioskId: string | null) => {
        if (!kioskId) return null;
        this.setState(prev => {
            const venue = this.props.venue;
            if (!venue) return { selectedKioskIds: prev.selectedKioskIds};

            const selected = prev.selectedKioskIds.length === 0 || prev.selectedKioskIds.indexOf(kioskId) >= 0;
            const filtered = (prev.selectedKioskIds.length === 0
                ? venue.registrationKiosks.map(k => k.registrationKioskId || '').filter(k => !isNullOrEmpty(k))
                : prev.selectedKioskIds).filter(id => id !== kioskId);

            return { selectedKioskIds: selected ? filtered : filtered.concat(kioskId) };
        })
    }

    renderKioskFilter = (venue: Venue | null) => {
        if (!venue) return null;

        const { selectedKioskIds } = this.state;
        const { registrations } = this.props;

        const kiosks = venue.registrationKiosks.map(k => {
            const btnCls = selectedKioskIds.length === 0 || selectedKioskIds.indexOf(k.registrationKioskId || '') >= 0 ? 'success' : 'basic'
            const unallocatedRegistrations = registrations.filter(r => r.bookings.length === 0 && r.kioskId === k.registrationKioskId).length;
            return <button key={k.registrationKioskId} className={`btn btn-xs btn-${btnCls}`} style={({ margin: '0 4px' })} onClick={e => clickHandler(e, () => this.toggleKioskState(k.registrationKioskId))}>{k.kioskName}{unallocatedRegistrations > 0 ? <span className="badge" style={{marginLeft: '5px'}}>{unallocatedRegistrations}</span> : null }</button>
        });

        if (kiosks.length < 2) return null;

        return <div className='reception-kiosk-filter'>{kiosks}</div>
    }

    private renderEventsList() {
        const { venue, events, registrations, addRegistrationsToBooking, expandedEvents, expand, collapse, hiddenResources, showModal, closeModal } = this.props;
        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;

        return <EventsView
            dateFormat={dateFormat}
            timeFormat={timeFormat}
            registrations={registrations}
            events={events}
            expandedEvents={expandedEvents}
            hiddenResources={hiddenResources}
            addRegistrationsToBooking={addRegistrationsToBooking}
            expand={expand}
            collapse={collapse}
            unregister={this.unregister}
            payIndividually={this.payIndividually}
            payRemaining={this.payRemaining}
            viewBill={this.viewBill}
            findCustomers={this.findCustomers}
            editRegistration={this.editRegistration}
            checkIn={this.checkIn}
            undoCheckIn={this.undoCheckIn}
            addBooking={this.addBookingToEvent}
            showNotes={this.showNotes}
            showModal={showModal}
            closeModal={closeModal}
        />
    }

    private renderPeople() {
        const { venue, events, registrations, addRegistrationsToBooking, isSaving, isLoadingRegistrations, isLoadingEvents, logout } = this.props;
        const { selectedKioskIds } = this.state;
        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;

        if (!venue)
            return null;

        return <PeopleView
            dateFormat={dateFormat}
            timeFormat={timeFormat}
            events={events}
            registrations={registrations}
            venue={venue}
            selectedKioskIds={selectedKioskIds}
            processing={isSaving || isLoadingRegistrations || isLoadingEvents}
            addRegistrationsToBooking={addRegistrationsToBooking}
            findCustomers={this.findCustomers}
            addBooking={this.addBooking}
            showNotes={this.showNotes}
            payIndividually={this.payIndividually}
            payRemaining={this.payRemaining}
            editRegistration={this.editRegistration}
            checkIn={this.checkIn}
            undoCheckIn={this.undoCheckIn}
            logout={logout} />
    }
};

const matStateToProps = (state: ApplicationState) => {
    const venueId = state.venues.selectedVenueId;

    const events = state.reception.events.sort((a, b) => a.startTime.getTime() - b.startTime.getTime());
    const registrations = state.reception.registrations ? state.reception.registrations : [];
    const activityFormats = state.activityFormats.activityFormats ? state.activityFormats.activityFormats : [];
    const mappedEvents = events.map(e => ({
        id: e.id,
        venueId: e.venueId,
        name: e.name,
        colour: e.colour,
        startTime: e.startTime,
        flagged: e.flagged,
        allowMultipleCategoriesPerCustomer: e.allowMultipleCategoriesPerCustomer,
        reservations: e.reservations.map(r => ({ ...r, activityFormat: activityFormats.find(af => af.id === r.activityFormatId), maxCompetitors: r.maxParticipants, bookedParticipants: r.bookedParticipants, notes: r.notes })),
        numberOfRegistrations: registrations.filter(r => r.registeredForEvents.filter(er => er.eventId === e.id && !er.removed).length > 0).length,
        outstandingBalance: Math.max(0, (e.totalBilled || 0) - (e.paidAmount || 0)),
        bookings: e.bookings.map(b => ({
            ...b,
            registeredParticipants: b.registeredParticipants ? b.registeredParticipants : [],
            outstandingAmount: b.totalAmount - b.paidAmount
        })),
    }));

    const selectedVenue = state.venues.venues ? state.venues.venues.find(v => v.id === venueId) : null;

    return ({
        selectedDate: state.reception.selectedDate,
        selectedVenueId: state.venues.selectedVenueId,
        venue: selectedVenue ? selectedVenue : null,
        isSaving: state.reception.isSaving,
        isLoadingRegistrations: state.reception.isLoadingRegistrations,
        isLoadingEvents: state.reception.isLoadingEvents,
        saveError: state.reception.saveError,
        activityFormats: activityFormats,
        activityFormatsLoading: state.activityFormats.isLoading,
        registrations: registrations,
        events: mappedEvents,
        customerCategories: state.customerCategories.customerCategories.filter(cc => cc.venueId === venueId),
        emailTemplates: state.emailTemplates.emailTemplates.filter(t => !t.archived && t.venueId === venueId),
        emailTemplatesLoading: state.emailTemplates.isLoading,
        products: state.products.products,
        productsLoading: state.products.isLoading,
        productCategories: state.productCategories.productCategories.filter(c => c.venueId === venueId && !c.archived),
        productCategoriesLoading: state.productCategories.isLoading,
        paymentMethods: state.paymentMethods.paymentMethods.filter(pm => pm.venueId === venueId && !pm.archived),
        paymentMethodsLoading: state.paymentMethods.isLoading,
        taxRates: state.taxRates.taxRates,
        taxRatesLoading: state.taxRates.isLoading,
        fees: state.fees.fees.filter(f => f.venueId === venueId),
        feesLoading: state.fees.isLoading,
        promotions: state.promotions.promotions,
        vouchers: state.vouchers.voucherProducts,
        membershipTypes: state.memberships.membershipTypes,
        expandedEvents: state.reception.expandedEvents,
        hiddenResources: state.preferences.hiddenResources,
        selectedView: state.reception.selectedView
    });
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    switchDate: bindActionCreators(ReceptionActions.actionCreators.switchDate, dispatch),
    loadRegistrations: bindActionCreators(ReceptionActions.actionCreators.loadRegistrations, dispatch),
    addRegistrationsToBooking: bindActionCreators(ReceptionActions.actionCreators.addRegistrationsToBooking, dispatch),
    checkInCustomers: bindActionCreators(ReceptionActions.actionCreators.checkInCustomers, dispatch),
    unregister: bindActionCreators(ReceptionActions.actionCreators.unregister, dispatch),
    undoCheckedInCustomers: bindActionCreators(ReceptionActions.actionCreators.undoCheckedInCustomers, dispatch),
    updateRegistration: bindActionCreators(ReceptionActions.actionCreators.updateRegistration, dispatch),
    loadEvents: bindActionCreators(ReceptionActions.actionCreators.loadEvents, dispatch),
    expand: bindActionCreators(ReceptionActions.actionCreators.expand, dispatch),
    collapse: bindActionCreators(ReceptionActions.actionCreators.collapse, dispatch),
    setReceptionView: bindActionCreators(ReceptionActions.actionCreators.setReceptionView, dispatch),
    loadActivityFormats: bindActionCreators(ActivityFormatActions.actionCreators.loadActivityFormats, dispatch),
    showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
    logout: bindActionCreators(LoginActions.actionCreators.logout, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    matStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps        // Selects which action creators are merged into the component's props
)(ReceptionPage);

