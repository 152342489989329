
import * as React from 'react';

interface TagLabelProps {
    name: string;
    colour: string;
    style?: React.CSSProperties;
}

const TagLabel = (props: TagLabelProps) => <span className='label tag-label' style={props.style ? ({ ...props.style, backgroundColor: props.colour }) : ({ backgroundColor: props.colour })}>{props.name}</span>

export default TagLabel;