
import { DateFormat, TimeFormat } from "../store/pages/venues/types";

const doubleClickHandlers = new Map<string, NodeJS.Timeout>();

export const padl = (val: any, digits: number, padChar: string) => {
    var str = val.toString();

    while (str.length < digits) {
        str = padChar + str;
    }

    return str;
}

export const formatCurrencyVal = (value: number, t: (key: string) => string) => `${t('Global:currencySymbol')}${value.toFixed(2)}`

export const formatDuration = (epocTimeInMs: number) => {
    const isNegative = epocTimeInMs < 0
    const normalizedTime = Math.abs(epocTimeInMs);
    const timeInSeconds = Math.floor(normalizedTime / 1000);
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds - (hours * 3600)) / 60);

    return `${isNegative ? '-' : ''}${hours}:${minutes}`;
}

export const formatPercentage = (value: number, total: number) => {
    if (total == 0)
        return '';

    return `(${((value / total) * 100).toFixed(2)}%)`
}

export const formatBgColour = (colour: string, opacity: number) => {

    const r = parseInt(colour.substr(1, 2), 16);
    const g = parseInt(colour.substr(3, 2), 16);
    const b = parseInt(colour.substr(5, 2), 16);
    
    return `rgba(${r},${g},${b},${opacity})`;
}

export const isNullOrEmpty = (val: string | null | undefined) => !val || val.trim() === '';

export const upper = (val: string) => val ? val.toUpperCase() : '';
export const properCase = (val: string) => val ? val.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()) : '';

export const getAge = (dob: Date) => {
    if (dob.getDate() === 1 && dob.getMonth() === 0 && (dob.getFullYear() === 1901 || dob.getFullYear() === 1)) return 0;
    const ageDifMs = Date.now() - dob.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    const ageVal = Math.abs(ageDate.getUTCFullYear() - 1970);
    return ageVal > 120 ? 0 : ageVal;
}

export interface IdMap<T> {
    [name: string]: T;
}

export const substitue = (text: string, map: IdMap<string>) => {
    var substituted = text.replace(/{{\w*}}/g, (m) => {
        const key = m.replace(/[{}]/g, '');
        const val = map.hasOwnProperty(key) ? map[key] : '';
        return val;
    });
    return substituted;
}

export const generateTempId = () => {
    const time = new Date().getTime().toString();
    const suffix = Math.floor((Math.random() * 1000) + 1).toString();
    return `${time}_${suffix}`;
}

const ensureInRange = (val: number, min: number, max: number) => {
    if (val > max) return max;
    if (val < min) return min;
    return val;
}

export const adjustColour = (colour: string, adjustment: number) => {
    let useHash = false;

    if (colour[0] === '#') {
        colour = colour.slice(1);
        useHash = true;
    }

    var num = parseInt(colour, 16);

    var r = ensureInRange((num >> 16) + adjustment, 0, 255);
    var b = ensureInRange(((num >> 8) & 0x00FF) + adjustment, 0, 255);
    var g = ensureInRange((num & 0x0000FF) + adjustment, 0, 255);

    return (useHash ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

export const round = (value: number, exp: number) =>{
    if (typeof exp === 'undefined' || +exp === 0)
        return Math.round(value);

    value = +value;
    exp = +exp;

    if (isNaN(value) || !(exp % 1 === 0))
        return NaN;

    // Shift
    const valueParts = value.toString().split('e');
    const newValue = Math.round(+(valueParts[0] + 'e' + (valueParts[1] ? (+valueParts[1] + exp) : exp)));

    // Shift back
    const newValueParts = newValue.toString().split('e');
    const result = +(newValueParts[0] + 'e' + (newValueParts[1] ? (+newValueParts[1] - exp) : -exp));

    return result;
}

export const calculatePercentage = (val: number, percentage: number) => {
    return round((val * percentage) / 100, 2);
}

export const parseTimeSpan = (ts: string) => {
    var parts = ts.split(":");
    if (parts.length === 3) {
        var h = parseInt(parts[0]);
        var m = parseInt(parts[1]);

        var seconds = parts[2].split(".");
        var s = parseInt(seconds[0]);
        var ms = seconds.length > 1 ? parseInt(seconds[1]) : 0;

        return new Date(0, 0, 1, h, m, s, ms);               
    }
}

export const formatTimeSpan = (val: Date) => {
    var ts = `${val.getHours()}:${val.getMinutes()}:${val.getSeconds()}`;
    var ms = val.getMilliseconds();
    return ms > 0 ? `${ts}.${ms}` : ts;
}

export const defaultFormHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
}

export const clickHandler = <T = Element>(e: React.MouseEvent<T> | React.KeyboardEvent<T>, handler: () => void, preventDoubleClickKey?: string) => {
    e.preventDefault();
    e.stopPropagation();

    if (preventDoubleClickKey) {
        const timer = doubleClickHandlers.get(preventDoubleClickKey);
        if (timer) {
            clearTimeout(timer);
        }
        doubleClickHandlers.set(preventDoubleClickKey, setTimeout(() => {
            doubleClickHandlers.delete(preventDoubleClickKey);
            handler();
        }, 250));
    } else {
        handler();
    }
}

export const formHandler = (e: React.FormEvent<HTMLFormElement>, handler?: () => void) => {
    e.preventDefault();
    if (handler) {
        handler();
    }
}

export const linkHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, handler: () => void) => {
    e.preventDefault();
    e.stopPropagation();
    handler();
}

export const formatLaptime = (laptime: number) => (laptime / 1000).toFixed(3);


export const digits = (val: number, digits: number) => {
    let str = val.toString();

    for (var i = 1; i < digits; i++) {
        if (val < Math.pow(10, i)) {
            str = '0' + str;
        }
    }

    return str;
}

export const range = (start: number, end: number) => {
    const vals: number[] = [];
    for (let i = start; i <= end; i++) {
        vals.push(i);
    }
    return vals;
}

export const parseUtcDate = (date: string | Date) => {
    if (date instanceof Date) {
        return date;
    }

    return new Date(date);
}

export const parseLocalDateTime = (date: string | Date) => {
    if (date instanceof Date) {
        return date;
    }

    // Dates are passed in local time + time zone offset, and in the UI we need to just show as local time so we can just ignore the tz offset
    const dateWithOffsetMatches = date.match(/([0-9]{4})-([0-9]{2})-([0-9]{2})T([0-9]{2}):([0-9]{2}):([0-9]{2})(\+[0-9]{2}:[0-9]{2})?/)

    if (dateWithOffsetMatches && dateWithOffsetMatches.length >= 7) {
        const year = parseInt(dateWithOffsetMatches[1]);
        const month = parseInt(dateWithOffsetMatches[2]);
        const day = parseInt(dateWithOffsetMatches[3]);
        const hour = parseInt(dateWithOffsetMatches[4]);
        const mins = parseInt(dateWithOffsetMatches[5]);
        const secs = parseInt(dateWithOffsetMatches[6]);
        const dt = new Date(year, month - 1, day, hour, mins, secs);
        return dt;
    }

    return new Date(date);
}

export const mapUtcDate = (date: any | null): Date | null => {
    if (date && typeof date === 'string') {
        return new Date(date);
    } else if (date instanceof Date) {
        return date;
    } else {
        return null;
    }
}

export const mapLocalDateTime = (date: any | null): Date | null => {
    if (date && typeof date === 'string') {
        return parseLocalDateTime(date);
    } else if (date instanceof Date) {
        return date;
    } else {
        return null;
    }
}

export const formatPosition = (position: number, t: (key: any, params?: any) => string) => {
    if (position < 1) return '';

    var stringPosition = position.toString();

    if (endsWith(stringPosition, "1") && position !== 11)
        return t("Global:st", { position: stringPosition});
    else if (endsWith(stringPosition, "2") && position !== 12)
        return t("Global:nd", { position: stringPosition });
    else if (endsWith(stringPosition, "3") && position !== 13)
        return t("Global:rd", { position: stringPosition });

    return t("Global:th", { position: stringPosition });
}

const endsWith = function (str: string, suffix: string) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
}

export const copyToClipboard = (text: string) => {
    // https://stackoverflow.com/questions/49236100/copy-text-from-span-to-clipboard
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();
}

export const createUUID = () => {
    if (crypto && crypto.getRandomValues) {
        return ([1e7] + '-' + 1e3 + '-' + 4e3 + '-' + 8e3 + '-' + 1e11).replace(/[018]/g, (c) => {
            const v = parseInt(c);
            return (v ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> v / 4).toString(16)
        })
    }

    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export const formatCompactGuid = (uuid: string) => {
    const base64 = btoa(uuid);
    const cleansed = base64.replace('+', '-').replace('/', '_');
    return cleansed.length <= 22 ? cleansed : cleansed.substring(0, 22);
}

export const remove = <T extends object>(object: T, keyToRemove: string) => {
    return (Object.keys(object) as Array<keyof T>).reduce((acc, key) => {
        if (key !== keyToRemove) {
            acc[key] = object[key];
        }
        return acc;
    }, {} as T);
};

export const substitute = (text: string, tagMap: Map<string, string>) => {
    return text.replace(/{{\w*(:[\w|&|&amp;|=|\s|%]*)?}}/, (match, p1, p2, p3, offset, string) => {
        const key = match.replace(/[{}]/g, '');
        const replacement = tagMap.get(key) || match;
        return replacement;
    })
}

export const formatTime = (hours: number, minutes: number, format: TimeFormat) => {
    if (format === TimeFormat.TwelveHour) {
        return `${hours > 12 ? hours - 12 : hours}:${padl(minutes, 2, '0')}${hours < 12 ? 'am' : 'pm'}`;
    } else {
        return `${padl(hours, 2, '0')}:${padl(minutes, 2, '0')}`;
    }
}

export const formatMoment = (dt: moment.Moment, dateFormat: DateFormat) => {
    switch (dateFormat) {
        case DateFormat.MDY:
            return dt.format('MM/DD/YYYY');
        default:
            return dt.format('DD/MM/YYYY');
    }
}