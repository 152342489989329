
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { Resource } from '../../../../store/pages/resources/types';
import {ActivityFormatResource } from '../../../../store/pages/activityFormats/types';
import { ITranslationContext } from '../../../../translations';

interface ActivityFormatResourcesProps {
    resources: Resource[];
    resourceSelections: ActivityFormatResource[];
    resourceStateChanged: (resourceId: string, selected: boolean) => void;
    resourceConfigurationStateChanged: (resourceId: string, configId: string, selected: boolean) => void;
    buildResourceStyle: (resource: Resource) => React.CSSProperties;
}

const ActivityFormatResources = (props: ActivityFormatResourcesProps) => {
    const { resources, resourceSelections, resourceStateChanged, resourceConfigurationStateChanged, buildResourceStyle } = props;

    return (
        <div className='form-group'>
            <ul className='list-unstyled'>
                {
                    resources.map(res => {
                        const selection = resourceSelections.find(rs => rs.resourceId === res.id);

                        return [(
                            <li key={res.id}>
                                <input type='checkbox' id={res.id} checked={(typeof selection !== 'undefined')} onChange={(e: React.ChangeEvent<HTMLInputElement>) => resourceStateChanged(res.id, e.currentTarget.checked)} />
                                <label style={buildResourceStyle(res)} htmlFor={res.id}>{res.name}</label>
                            </li>)]
                            .concat(selection ? res.configurations.map(cfg => (
                                <li key={`${res.id}_${cfg.id}`} style={({ marginLeft: '25px' })}>
                                    <input type='checkbox' id={res.id} checked={typeof selection !== 'undefined' && (selection.configurationIds.length === 0 || selection.configurationIds.some(x => x === cfg.id))} onChange={(e: React.ChangeEvent<HTMLInputElement>) => resourceConfigurationStateChanged(res.id, cfg.id, e.currentTarget.checked)} />
                                    <label style={({ marginLeft: '6px' })} htmlFor={cfg.id}>{cfg.name}</label>
                                </li>
                            )) : [])
                    })
                }
            </ul>
        </div>
    );
}

ActivityFormatResources.contextTypes = {
    t: PropTypes.func
}

export default ActivityFormatResources;