
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as ft from './types'; 
import { IStore } from '../..';
import { mapUtcDate } from '../../../utils/util';


export type LeaderboardActions = ft.LoadLeaderboards | ft.ReceiveLeaderboards | ft.SaveLeaderboard | ft.LeaderboardSaved | ft.LeaderboardSaveFailed | ft.EditLeaderboard | ft.LoadTimingSettings | ft.ReceiveTimingSettings;

export const actionCreators = {
    loadLeaderboards: () => ({ type: ft.LeaderboardActionTypes.RequestLeaderboards }),
    loadLeaderboardsComplete: (leaderboards: ft.Leaderboard[], err: api.ApiError | null) => ({ type: ft.LeaderboardActionTypes.ReceivedLeaderboards, leaderboards: leaderboards, error: err }),
    editLeaderboard: () => ({ type: ft.LeaderboardActionTypes.EditLeaderboard }),
    saveLeaderboard: (isNew: boolean, leaderboardId: string | null, leaderboard: ft.Leaderboard) => ({ type: ft.LeaderboardActionTypes.SaveLeaderboard, isNew: isNew, leaderboardId: leaderboardId, leaderboard: leaderboard }),
    leaderboardSaved: (leaderboardId: string) => ({ type: ft.LeaderboardActionTypes.LeaderboardSaved, leaderboardId: leaderboardId }),
    leaderboardSaveFailed: (error: api.ApiError) => ({ type: ft.LeaderboardActionTypes.LeaderboardSaveFailed, error: error }),
    loadTimingSettings: () => ({ type: ft.LeaderboardActionTypes.RequestTimingSettings }),
    loadTimingSettingsComplete: (settings: ft.VenueTimingSettings[], err: api.ApiError | null) => ({ type: ft.LeaderboardActionTypes.ReceivedTimingSettings, settings: settings, error: err }),
}

interface IGetLeaderboardsResponse {
    leaderboards: ft.Leaderboard[];
}

interface ISaveLeaderboardResponse {
    leaderboardId: string;
}

interface IGetTimingSettingsResponse {
    settings: ft.VenueTimingSettings[];
}

export const handleClientChange = (store: IStore) => [actionCreators.loadLeaderboards]

//https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-after-catching-and-processing-an-error-with-emitting-somethi

const loadLeaderboards = () => Observable.defer(() => api.getJson<IGetLeaderboardsResponse>(`api/v1/leaderboard/`))
    .map(response => {
        const leaderboards = response.leaderboards.map(l => ({ ...l, fromDate: mapUtcDate(l.fromDate), toDate: mapUtcDate(l.toDate)}))
        return actionCreators.loadLeaderboardsComplete(leaderboards, null)
    });

export const loadLeaderboardsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        ft.LeaderboardActionTypes.RequestLeaderboards,
        action => loadLeaderboards(),
        err => actionCreators.loadLeaderboardsComplete([], err));

export const reloadLeaderboardsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        ft.LeaderboardActionTypes.LeaderboardSaved,
        action => loadLeaderboards(),
        err => actionCreators.loadLeaderboardsComplete([], err));

export const saveLeaderboardEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        ft.LeaderboardActionTypes.SaveLeaderboard,
        action => {
            const sla = action as ft.SaveLeaderboard;
            let leaderboardId = sla.leaderboardId;
            var { id, ...body } = sla.leaderboard;

            return (sla.isNew ? api.post('api/v1/leaderboard/', body) : api.put(`api/v1/leaderboard/${leaderboardId}`, body))
                .map(response => {
                    if (sla.isNew) {
                        const strr = response.response as ISaveLeaderboardResponse;
                        if (strr) {
                            leaderboardId = strr.leaderboardId;
                        }
                    }

                    return ({ type: ft.LeaderboardActionTypes.LeaderboardSaved, leaderboardId: leaderboardId });
                });
        },
        (err: api.ApiError) => actionCreators.leaderboardSaveFailed(err));

export const loadTimingSettingsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        ft.LeaderboardActionTypes.RequestTimingSettings,
        action => {
            return api.getJson<IGetTimingSettingsResponse>(`api/v1/leaderboard/timingSettings`)
                .map(response => {
                    return actionCreators.loadTimingSettingsComplete(response.settings, null)
                })
        },
        err => {
            return actionCreators.loadTimingSettingsComplete([], err)
        });