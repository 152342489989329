
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';

import { isNullOrEmpty, clickHandler, getAge, parseLocalDateTime } from '../../../utils/util';

import * as api from '../../../store/apiClient';
import { Registration } from '../../../store/pages/reception/types';

import { CustomerSearchResult } from '../../../store/pages/diary/types';
import { ClientEmailTemplate, EmailType } from '../../../store/pages/emailTemplates/types';
import { IEvent } from './types';
import Close from '../../icons/close';
import { TimeFormat, Venue } from '../../../store/pages/venues/types';

interface RegistrationWithEvent extends Registration {
    firstEvent: IEvent | null;
}

interface Selection {
    customerId: string;
    firstname: string;
    lastname: string;
    registrationId: string;
}

interface ICustomerSearchResponse {
    customers: CustomerSearchResult[];
}

interface FindRegisteredCustomersProps {
    venue: Venue;
    bookingId: string;
    billNumber: string;
    bookingCustomerName: string;
    bookingCustomerPhoneNumber: string;
    bookingCustomerPostcode: string;
    registrations: Registration[];
    events: IEvent[];
    registerCustomers: (selectedCustomers: string[]) => void;
    registrationLinkEmailTemplates: ClientEmailTemplate[];
    cancel: () => void;
    logout: () => void;
}

interface FindRegisteredCustomersState {
    searchText: ct.FormValue<string>;
    customerSearchResults: CustomerSearchResult[];
    selectedCustomers: Selection[];
    searchingCustomers: boolean;
    sendingEmail: boolean;
    errorKey: string | null;
    message: string | null;
}

class FindRegisteredCustomers extends React.Component<FindRegisteredCustomersProps, FindRegisteredCustomersState> {

    saveResultTimeout: NodeJS.Timeout | null;
    saveResultVersion: number;

    constructor(props: FindRegisteredCustomersProps) {
        super(props);

        this.saveResultTimeout = null;
        this.saveResultVersion = 1;

        this.state = { searchText: this.validateSearchText(''), customerSearchResults: [], searchingCustomers: false, selectedCustomers: [], sendingEmail: false, errorKey: null, message: null };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    validateSearchText = (val: string) => ct.asFormValue('searchText', val, true, false);

    searchCustomers = (search: string) => {
        const { venue, bookingId } = this.props;
        const { t } = this.context;

        if (this.saveResultTimeout !== null) {
            clearTimeout(this.saveResultTimeout);
        }

        const searchText = this.validateSearchText(search);
        let searchMessage = null;

        this.setState({ searchingCustomers: searchText.isValid, searchText: searchText, message: searchMessage });

        if (searchText.isValid && searchMessage === null) {
            const ver = this.saveResultVersion + 1;
            this.saveResultVersion = ver;

            this.saveResultTimeout = setTimeout(() => {
                api.getWithAuth<ICustomerSearchResponse>(`api/v1/customer/search?searchTerm=${search}&venueId=${venue.id}&bookingId=${bookingId}`, this.props.logout)
                    .takeWhile(() => this.saveResultVersion === ver)
                    .subscribe(resp => {
                        this.setState({ searchingCustomers: false, customerSearchResults: resp.customers.map(c => ({ ...c, lastEvent: c.lastEvent ? { ...c.lastEvent, date: parseLocalDateTime(c.lastEvent.date) } : null, lastBooking: c.lastBooking ? { ...c.lastBooking, date: parseLocalDateTime(c.lastBooking.date) } : null })) });
                    }, e => {
                        this.setState({ searchingCustomers: false, customerSearchResults: [] });
                    });
            }, 500);
        }
    }

    selectCustomer = (customer: CustomerSearchResult) => {
        this.setState(prev => {
            var match = prev.selectedCustomers.find(c => c.customerId === customer.customerId);
            return { selectedCustomers: match ? prev.selectedCustomers : prev.selectedCustomers.concat({ customerId: customer.customerId, firstname: customer.firstname, lastname: customer.lastname, registrationId: customer.lastCurrentRegistrationId || customer.lastRegistrationId || '' }) }
        })
    }

    registerSelected = () => {
        const { registerCustomers } = this.props;
        const { selectedCustomers } = this.state;
        registerCustomers(selectedCustomers.map(c => c.registrationId));
    }

    emailRegistrationLink = (customer: CustomerSearchResult) => {
        const { venue, registrationLinkEmailTemplates, bookingId } = this.props;
        const { t } = this.context;

        // TODO: How to select template????
        const templates = registrationLinkEmailTemplates.filter(t => t.emailType === EmailType.BookingRegistrationLink);

        if (templates.length === 0) {
            this.setState({ errorKey: 'CustomerSearch:error_noGeneralLinkEmailTemplate' });
        } else {
            this.setState({ sendingEmail: true, errorKey: null, message: null });

            const emailAddress = customer.emailAddress;

            api.postWithAuth(`api/v1/booking/sendRegistrationLink`, {
                venueId: venue.id,
                bookingId: bookingId,
                customerId: customer.customerId,
                emailAddress: emailAddress,
                clientEmailTemplateId: templates[0].clientEmailTemplateId,
                AddEmailToLink: true
            }, () => this.setState({ sendingEmail: false, errorKey: 'CustomerSearch:error_failedToSendEmail' }))
                .subscribe(_ => {
                    this.setState({ sendingEmail: false, message: t('CustomerSearch:emailSentTo{emailAddress}', { emailAddress: emailAddress }) })
                }, _ => this.setState({ sendingEmail: false, errorKey: 'CustomerSearch:error_failedToSendEmail' }));
        }
    }

    addSelectedRegistration = (registration: Registration) => {
        this.setState(s => ({
            selectedCustomers: s.selectedCustomers.findIndex(c => c.customerId === registration.customerId) < 0
                ? s.selectedCustomers.concat({ customerId: registration.customerId, firstname: registration.customerFirstname, lastname: registration.customerLastname, registrationId: registration.id })
            : s.selectedCustomers
        }))
    }

    removeRegistration = (customerId: string) => this.setState(s => ({ selectedCustomers: s.selectedCustomers.filter(c => c.customerId !== customerId) }))

    componentDidMount() {
        this.searchCustomers('');
    }
     
    render() {
        const { customerSearchResults, searchingCustomers, searchText, selectedCustomers, errorKey, message } = this.state;
        const { registrations, cancel, billNumber, bookingCustomerName, bookingCustomerPhoneNumber, bookingCustomerPostcode, venue } = this.props;
        const { t } = this.context;
        return (
            <div style={({ display: 'flex', flexDirection: 'column', flex: '1 1 auto', overflow: 'hidden' })}>
                <div className='row' style={({ flex: '0 0 auto' })}>
                    <div className='col-md-12'>
                        <h4 className='text-center'>{[billNumber, bookingCustomerName, bookingCustomerPhoneNumber, bookingCustomerPostcode].filter(x => !isNullOrEmpty(x)).join(' | ')}</h4>
                    </div>
                </div>
                <div className='row' style={({ flex: '0 0 auto' })}>
                    <div className='col-md-12'>
                        <ct.TextBox id='searchText' labelKey='CustomerSearch:search' placeholderKey='CustomerSearch:searchPlaceholder' value={searchText} callback={val => this.searchCustomers(val)} autoComplete='off' />
                    </div>
                </div>
                <div>
                    <div className='row'>
                        {errorKey
                            ? <div className='col-xs-12 alert alert-danger'>{t(errorKey)}</div>
                            : message
                                ? <div className='col-xs-12 alert alert-info'>{message}</div>
                                : isNullOrEmpty(searchText.value) ? this.renderNoResults(searchingCustomers, searchText.value) : null}
                    </div>
                </div>
                <div className='row' style={({ flex: '0 0 auto' })}>
                    <div className='col-xs-4'>
                        <h4>{t('ReceptionPage:registrationQueue')}</h4>
                    </div>
                    <div className='col-xs-6'>
                        <h4>{t('ReceptionPage:searchResults')}</h4>
                    </div>
                    <div className='col-xs-2'>
                        <h4>{t('CustomerSearch:selectedCustomers')}</h4>
                    </div>
                </div>
                <div className='row' style={({ flex: '1 1 auto', overflow: 'hidden' })}>
                    <div className='col-xs-4 scroll-panel'>
                        {this.renderRegistrations(registrations, searchText.value)}
                    </div>
                    <div className='col-xs-6 scroll-panel'>
                        {this.renderResults(customerSearchResults, searchingCustomers, searchText.value, venue)}
                    </div>
                    <div className='col-xs-2 scroll-panel'>
                        {this.renderSelectedCustomers(selectedCustomers)}
                    </div>
                </div>
                <div className='row' style={({ flex: '0 0 auto', marginTop: '8px' })}>
                    <div className='col-md-12 btn-toolbar'>
                        <button className='btn btn-primary' onClick={e => clickHandler(e, this.registerSelected)} disabled={selectedCustomers.length < 1}>{this.context.t('CustomerSearch:addSelected')}</button>
                        <button className='btn btn-basic' onClick={e => clickHandler(e, cancel)}>{this.context.t('Global:cancel')}</button>
                    </div>
                </div>
            </div>);
    }

    sortRegistrations = (r1: RegistrationWithEvent, r2: RegistrationWithEvent) => {
        if (r1.firstEvent === null && r2.firstEvent === null) {
            if (r1.isWebRegistration && !r2.isWebRegistration) return 1;
            if (!r1.isWebRegistration && r2.isWebRegistration) return -1;
            return r2.registrationTime.getTime() - r1.registrationTime.getTime();
        }

        if (r1.firstEvent === null && r2.firstEvent !== null)
            return -1;

        if (r1.firstEvent !== null && r2.firstEvent === null)
            return 1;

        // TODO - order by first event start time
        if (r1.firstEvent && r2.firstEvent) {
            return r1.firstEvent.startTime.getTime() - r2.firstEvent.startTime.getTime()
        }

        return -1;
    }

    renderRegistrations = (registrations: Registration[], searchText: string) => {
        const { t } = this.context;
        const { bookingId, events, venue } = this.props;

        const searchTerms = searchText.toLowerCase().split(' ');
        const filteredRegistrations: RegistrationWithEvent[] = registrations.filter(r => {
            if (r.bookings.findIndex(x => x.bookingId === bookingId) >= 0) return false;

            if (searchTerms.length === 1) return r.customerFirstname.toLowerCase().startsWith(searchTerms[0]) || r.customerLastname.toLowerCase().startsWith(searchTerms[0])
            if (searchTerms.length > 1) return r.customerFirstname.toLowerCase().startsWith(searchTerms[0]) && r.customerLastname.toLowerCase().startsWith(searchTerms[1])
            return searchTerms.length === 0;
        }).map(r => {
            const firstEvent = r.registeredForEvents.filter(re => !re.removed).map(re => re.eventId).reduce<IEvent | null>((re, eid) => {
                const ev = events.find(e => e.id === eid);
                return ev && (!re || re.startTime > ev.startTime) ? ev : re
            }, null);
            return { ...r, firstEvent: firstEvent  }
        })

        const unassignedRegistrations = filteredRegistrations.filter(r => r.bookings.length === 0).sort(this.sortRegistrations);
        const assignedRegistrations = filteredRegistrations.filter(r => r.bookings.length > 0).reduce<Map<string, RegistrationWithEvent[]>>((byEvent, r) => {
            if (r.firstEvent) {
                byEvent.set(r.firstEvent.id, (byEvent.get(r.firstEvent.id)||[]).concat(r))
            }
            return byEvent;
        }, new Map<string, RegistrationWithEvent[]>());

        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;

        return <table className='table table-hover customer-search-table table-condensed'>
            <thead>
                <tr key='customer-search-header'>
                    <th></th>
                    <th>{t('CustomerSearch:nameHeading')}</th>
                    <th>{t('CustomerSearch:ageHeading')}</th>
                    <th>{t('CustomerSearch:kioskHeding')}</th>
                    <th>{t('CustomerSearch:customerCatHeding')}</th>
                </tr>
            </thead>
            <tbody>
                <tr><td colSpan={5} className='text-center' style={{ border: '0 solid #ddd', fontWeight: 'bold' }}>{t('ReceptionPage:unassignedRegistrations')}</td></tr>
                {unassignedRegistrations.map(r => this.renderRegistration(r, {}, timeFormat))}
                {assignedRegistrations.size > 0 ? <tr><td colSpan={5} className='text-center' style={{ border: '0 solid #ddd', fontWeight: 'bold' }}>{t('ReceptionPage:assignedRegistrations')}</td></tr> : null}
                {Array.from(assignedRegistrations.entries())
                    .map(entry => {
                        const [key, entries] = entry
                        return {evt: entries[0].firstEvent, entries}
                    }).sort((e1, e2) => e1.evt && e2.evt ? e1.evt.startTime.getTime() - e2.evt.startTime.getTime() : 1)
                    .map(entry => {
                        const { evt, entries } = entry;
                        if (!evt) return entries.map(r => this.renderRegistration(r, {}, timeFormat));

                        return [<tr><td colSpan={5} style={{ paddingTop: '5px', borderLeft: `solid 5px ${evt.colour}`, backgroundColor: '#efefef' }}>{this.renderEventHeader(evt, timeFormat)}</td></tr>]
                            .concat(entries.map(r => this.renderRegistration(r, { borderLeft: `solid 5px ${evt.colour}` }, timeFormat)))
                    })
                }
            </tbody>
        </table>
    }

    renderEventHeader = (eventInfo: IEvent, timeFormat: TimeFormat) => {
        return <div style={{ fontSize: '12px', color: '#333' }}>{`${eventInfo.startTime.toShortTimeString(timeFormat)} | ${eventInfo.name}`}</div>
    }

    renderRegistration = (registration: RegistrationWithEvent, rowHeaderStyle: React.CSSProperties, timeFormat: TimeFormat) => {
        const { t } = this.context;

        return <tr key={registration.id} onClick={e => clickHandler(e, () => this.addSelectedRegistration(registration))}>
            <td style={rowHeaderStyle}>{registration.isWebRegistration ? t('Global:webBooking') : registration.registrationTime.toShortTimeString(timeFormat)}</td>
            <td>{registration.customerFirstname} {registration.customerLastname}</td>
            <td>{registration.age}</td>
            <td>{registration.kioskName}</td>
            <td>{registration.registeredForEvents.flatMap(e => e.customerCategories).reduce<string[]>((cats, e) => cats.includes(e.customerCategoryName) ? cats : cats.concat(e.customerCategoryName), []).join(" | ")}</td>
        </tr>
    }

    renderResults = (searchResults: CustomerSearchResult[], searchingCustomers: boolean, searchText: string, venue: Venue) => {

        const { t } = this.context;
        
        const tableData = searchResults.length === 0 && !searchingCustomers && !isNullOrEmpty(searchText)
            ? <tr><td colSpan={6}><div className='alert alert-warning'>{t('CustomerSearch:noCustomersFound')}</div></td></tr>
            : searchResults.map(c => this.renderCustomerRow(c, venue));

        return (
            <div className='customer-search-results-wrapper'>
                <table className='table table-hover customer-search-table table-condensed'>
                    <thead>
                        <tr key='customer-search-header'>
                            <th>{t('CustomerSearch:nameHeading')}</th>
                            <th>{t('CustomerSearch:ageHeading')}</th>
                            <th colSpan={2}>{t('CustomerSearch:emailHeading')}</th>
                            <th>{t('CustomerSearch:lastVisitHeading')}</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData}
                    </tbody>
                </table>
            </div>
        );
    }

    renderSelectedCustomers = (selectedCustomers: Selection[]) => {
        return (
            <div className='customer-search-selected-customers-wrapper'>
                <table className='table table-hover customer-search-table table-condensed'>
                    <tbody>
                        {selectedCustomers.map(c => (
                            <tr key={c.customerId}>
                                <td style={{ verticalAlign: 'middle' }}>{this.formatCustomer(c)}</td>
                                <td><button type='button' className='btn btn-plain' style={{ padding: '0', lineHeight: '1' }} onClick={e => clickHandler(e, () => this.removeRegistration(c.customerId))}><Close width={24} height={24} colour='red' /></button></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }

    formatCustomer = (val: CustomerSearchResult | Selection | null) => val ? `${val.firstname} ${val.lastname}` : '';
    formatAge = (val: CustomerSearchResult | null) => {
        if (!val || val.birthYear === 0 || val.birthMonth === 0 || val.birthDay === 0)
            return '';

        const age = getAge(new Date(val.birthYear, val.birthMonth - 1, val.birthDay));
        return age === 0 ? '' : age.toString();
    }

    renderNoResults = (searchingCustomers: boolean, searchText: string) => {
        const { t } = this.context;

        if (searchingCustomers) return <div className='alert alert-info'>{t('CustomerSearch:searching')}</div>;
        if (isNullOrEmpty(searchText)) return <div className='alert alert-info'>{t('CustomerSearch:enterSearch')}</div>;
        return null;
    }



    renderCustomerRow = (customer: CustomerSearchResult, venue: Venue) => {

        let termsWarning: JSX.Element | null = null;
        if (!customer.hasAcceptedLatestTerms) {
            termsWarning = isNullOrEmpty(customer.lastRegistrationId) || venue.requireLatestTerms
                ? <span className='label label-danger'>{this.context.t('CustomerSearch:registrationRequired')}</span >
                : <span className='label label-warning'> {this.context.t('CustomerSearch:registrationNonCurrentWaiver')}</span >
        }

        return (
            <>
                <tr key={customer.customerId}>
                    <td>{this.formatCustomer(customer)}</td>
                    <td>{this.formatAge(customer)}</td>
                    <td colSpan={2} style={({ wordBreak: 'break-all' })}><div>{customer.emailAddress}</div></td>
                    <td>{customer.lastEvent ? customer.lastEvent.date.toShortDateString(venue.dateFormat) : null }</td>                    
                    <td>{isNullOrEmpty(customer.lastCurrentRegistrationId) ? <button className='btn btn-info btn-xs' onClick={e => clickHandler(e, () => this.emailRegistrationLink(customer))}>{this.context.t('CustomerSearch:emailLink')}</button> : null}</td>
                    <td rowSpan={2}>{this.canRegister(customer, venue) ? <button className='btn btn-primary btn-xs' onClick={e => clickHandler(e, () => this.selectCustomer(customer))}>{this.context.t('Global:select')}</button> : null}</td>
                </tr>
                <tr>
                    <td colSpan={3} className='table-second-row'>{[customer.addressLine1, customer.postalCode].filter(x => !isNullOrEmpty(x)).join(',')}</td>
                    <td colSpan={2} className='table-second-row text-right'>{customer.lastRegistrationTerms}</td>
                    <td colSpan={2} className='table-second-row text-right'>{termsWarning}</td>
                </tr>
            </>
        );
    }

    canRegister = (customer: CustomerSearchResult, venue: Venue) => {
        return !isNullOrEmpty(customer.lastCurrentRegistrationId) || (!venue.requireLatestTerms && !isNullOrEmpty(customer.lastRegistrationId));
    }
}

export default FindRegisteredCustomers;