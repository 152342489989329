
import { combineEpics, Epic } from 'redux-observable';
import { i18nState, IreduxI18nState } from 'redux-i18n';

import * as LoginTypes from './pages/login/types';
import * as StatusTypes from './pages/status/types';
import * as ModalTypes from './global/modal/types';
import * as CultureTypes from './global/cultures/types';
import * as CurrencyTypes from './global/currencies/types';
import * as TimeZoneTypes from './global/timeZones/types';
import * as UserTypes from './pages/users/types';
import * as ClientTypes from './pages/clients/types';
import * as CampaignTypes from './pages/campaigns/types';
import * as ResourceTypes from './pages/resources/types';
import * as ReceptionTypes from './pages/reception/types';
import * as VenueTypes from './pages/venues/types';
import * as DiaryTypes from './pages/diary/types';
import * as AffiliateeTypes from './pages/affiliates/types';
import * as IntegrationTypes from './pages/integrations/types';
import * as EmailTemplateTypes from './pages/emailTemplates/types';
import * as EmailTypes from './pages/emails/types';
import * as TaxRateTypes from './pages/taxRates/types';
import * as PromotionTypes from './pages/promotions/types';
import * as TagTypes from './pages/tags/types';
import * as ProductCategoryTypes from './pages/productCategories/types';
import * as ProductTypes from './pages/products/types';
import * as FeeTypes from './pages/fees/types';
import * as ActivityFormatTypes from './pages/activityFormats/types';
import * as ActivityFormatGroupTypes from './pages/activityFormatGroups/types';
import * as TaskTypes from './pages/tasks/types';
import * as PaymentMethodTypes from './pages/paymentMethods/types';
import * as PaymentGatewayTypes from './pages/paymentGateways/types';
import * as PaymentScheduleTypes from './pages/paymentSchedules/types';
import * as TermsAndConditionsActionTypes from './pages/termsAndConditions/types';
import * as ReportTypeActionTypes from './pages/reportTypes/types';
import * as ReportDefinitionActionTypes from './pages/reportDefinitions/types';
import * as PaymentReminderConfigActionTypes from './pages/paymentReminderConfigurations/types';
import * as PublicWebsiteSettingsTypes from './pages/publicWebsiteSettings/types'
import * as CustomerCategoryTypes from './pages/customerCategories/types'
import * as CustomerTypes from './pages/customer/types'
import * as RoutingTypes from './global/routing/types'
import * as BookingEmailScheduleConfigTypes from './pages/bookingEmailScheduleConfigs/types';
import * as VoucherProducts from './pages/vouchers/types';
import * as LeaderboardTypes from './pages/leaderboards/types';
import * as NavigationTypes from './pages/navigation/types';
import * as PreferenceTypes from './global/preferences/types';
import * as MembershipTypes from './pages/memberships/types';

import * as LoginReducers from './pages/login/reducers';
import * as StatusReducers from './pages/status/reducers';
import * as CultureReducers from './global/cultures/reducers';
import * as CurrencyReducers from './global/currencies/reducers';
import * as TimeZoneReducers from './global/timeZones/reducers';
import * as DiaryReducers from './pages/diary/reducers';
import * as ModalReducers from './global/modal/reducers';
import * as UserReducers from './pages/users/reducers';
import * as ClientReducers from './pages/clients/reducers';
import * as CampaignReducers from './pages/campaigns/reducers';
import * as RecourceReducers from './pages/resources/reducers';
import * as ReceptionReducers from './pages/reception/reducers';
import * as VenueReducers from './pages/venues/reducers';
import * as IntegrationReducers from './pages/integrations/reducers';
import * as EmailTemplateReducers from './pages/emailTemplates/reducers';
import * as EmailReducers from './pages/emails/reducers';
import * as TaxRateReducers from './pages/taxRates/reducers';
import * as TagReducers from './pages/tags/reducers';
import * as ProductCategoryReducers from './pages/productCategories/reducers';
import * as ProductReducers from './pages/products/reducers';
import * as PromotionReducers from './pages/promotions/reducers';
import * as FeeReducers from './pages/fees/reducers';
import * as AffiliateReducers from './pages/affiliates/reducers';
import * as ActivityFormatRedeucers from './pages/activityFormats/reducers';
import * as ActivityFormatGroupRedeucers from './pages/activityFormatGroups/reducers';
import * as TaskRedeucers from './pages/tasks/reducers';
import * as PaymentMethodReducers from './pages/paymentMethods/reducers';
import * as PaymentGatewayReducers from './pages/paymentGateways/reducers';
import * as PaymentScheduleReducers from './pages/paymentSchedules/reducers';
import * as TermsAndConditionsReducers from './pages/termsAndConditions/reducers';
import * as ReportTypeReducers from './pages/reportTypes/reducers';
import * as ReportDefinitionReducers from './pages/reportDefinitions/reducers';
import * as PaymentReminderConfigReducers from './pages/paymentReminderConfigurations/reducers';
import * as PublicWebsiteSettingsReducers from './pages/publicWebsiteSettings/reducers'
import * as CustomerCategoryReducers from './pages/customerCategories/reducers'
import * as CustomerReducers from './pages/customer/reducers'
import * as RoutingReducers from './global/routing/reducers'
import * as BookingEmailScheduleConfigReducers from './pages/bookingEmailScheduleConfigs/reducers';
import * as VoucherReducers from './pages/vouchers/reducers';
import * as LeaderboardReducers from './pages/leaderboards/reducers';
import * as NavigationReducers from './pages/navigation/reducers';
import * as PreferenceReducers from './global/preferences/reducers';
import * as MembershipReducers from './pages/memberships/reducers';

import * as LoginActions from './pages/login/actions'
import * as ClientChangeHandlers from './pages/login/clientSwitchEpic'
import * as CultureActions from './global/cultures/actions';
import * as CurrencyActions from './global/currencies/actions';
import * as TimeZoneActions from './global/timeZones/actions';
import * as DiaryActions from './pages/diary/actions'
import * as UserActions from './pages/users/actions'
import * as ClientActions from './pages/clients/actions'
import * as CampaignActions from './pages/campaigns/actions'
import * as ResourceActions from './pages/resources/actions'
import * as ReceptionActions from './pages/reception/actions'
import * as VenueActions from './pages/venues/actions'
import * as IntegrationActions from './pages/integrations/actions'
import * as EmailTemplateActions from './pages/emailTemplates/actions'
import * as EmailActions from './pages/emails/actions'
import * as TaxRateActions from './pages/taxRates/actions'
import * as TagActions from './pages/tags/actions'
import * as ProductCategoryActions from './pages/productCategories/actions'
import * as ProductActions from './pages/products/actions'
import * as FeeActions from './pages/fees/actions'
import * as AffiliateActions from './pages/affiliates/actions'
import * as ActivityFormatActions from './pages/activityFormats/actions';
import * as ActivityFormatGroupActions from './pages/activityFormatGroups/actions';
import * as TaskActions from './pages/tasks/actions';
import * as PaymentMethodActions from './pages/paymentMethods/actions'
import * as PaymentGatewayActions from './pages/paymentGateways/actions'
import * as PaymentScheduleActions from './pages/paymentSchedules/actions'
import * as PromotionActions from './pages/promotions/actions';
import * as TermsAndConditionsActions from './pages/termsAndConditions/actions'
import * as ReportTypeActions from './pages/reportTypes/actions'
import * as ReportDefinitionActions from './pages/reportDefinitions/actions'
import * as PaymentReminderConfigActions from './pages/paymentReminderConfigurations/actions'
import * as PublicWebsiteSettingsAction from './pages/publicWebsiteSettings/actions'
import * as CustomerCategoryActions from './pages/customerCategories/actions'
import * as CustomerActions from './pages/customer/actions'
import * as BookingEmailScheduleConfigActions from './pages/bookingEmailScheduleConfigs/actions';
import * as VoucherActions from './pages/vouchers/actions'
import * as LeaderboardActions from './pages/leaderboards/actions'
import * as NavigationActions from './pages/navigation/actions'
import * as PreferenceActions from './global/preferences/actions'
import * as MembershipActions from './pages/memberships/actions'
import { Action } from 'redux';

export interface IStore {
    value: ApplicationState;
}

// The top-level state object
export interface ApplicationState {
    login: LoginTypes.LoginState;
    status: StatusTypes.StatusState;
    modal: ModalTypes.ModalState;
    cultures: CultureTypes.CultureState;
    currencies: CurrencyTypes.CurrencyState;
    timeZones: TimeZoneTypes.TimeZoneState;
    users: UserTypes.UserState;
    clients: ClientTypes.ClientState;
    campaigns: CampaignTypes.CampaignState;
    venues: VenueTypes.VenueState;
    resources: ResourceTypes.ResourceState;
    reception: ReceptionTypes.ReceptionState;
    diary: DiaryTypes.DiaryState;
    integrations: IntegrationTypes.IntegrationsState;
    emailTemplates: EmailTemplateTypes.EmailTemplatesState;
    emails: EmailTypes.EmailState;
    taxRates: TaxRateTypes.TaxRateState;
    tags: TagTypes.TagState;
    productCategories: ProductCategoryTypes.ProductCategoryState;
    products: ProductTypes.ProductState;
    promotions: PromotionTypes.PromotionState;
    fees: FeeTypes.FeeState;
    affiliates: AffiliateeTypes.AffiliateState;
    activityFormats: ActivityFormatTypes.ActivityFormatState;
    activityFormatGroups: ActivityFormatGroupTypes.ActivityFormatGroupsState;
    tasks: TaskTypes.TaskState;
    paymentMethods: PaymentMethodTypes.PaymentMethodState;
    paymentGateways: PaymentGatewayTypes.PaymentGatewayState;
    paymentSchedules: PaymentScheduleTypes.PaymentScheduleState;
    termsAndConditions: TermsAndConditionsActionTypes.TermsAndConditionsState;
    reportTypes: ReportTypeActionTypes.ReportTypeState;
    reportDefinitions: ReportDefinitionActionTypes.ReportDefinitionState;
    paymentReminderConfigs: PaymentReminderConfigActionTypes.PaymentReminderConfigState;
    publicWebsiteSettings: PublicWebsiteSettingsTypes.PublicWebsiteSettingsState;
    customerCategories: CustomerCategoryTypes.CustomerCategoriesState;
    customers: CustomerTypes.CustomerState;
    routing: RoutingTypes.RoutingState;
    bookingEmailScheduleConfigs: BookingEmailScheduleConfigTypes.BookingEmailScheduleConfigState;
    vouchers: VoucherProducts.VoucherState;
    leaderboards: LeaderboardTypes.LeaderboardState;
    i18nState: IreduxI18nState;
    navigation: NavigationTypes.NavigationState;
    preferences: PreferenceTypes.UserPreferenceState;
    memberships: MembershipTypes.MembershipState;
}


// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    login: LoginReducers.reducer,
    status: StatusReducers.reducer,
    cultures: CultureReducers.reducer,
    currencies: CurrencyReducers.reducer,
    timeZones: TimeZoneReducers.reducer,
    modal: ModalReducers.reducer,
    users: UserReducers.reducer,
    clients: ClientReducers.reducer,
    campaigns: CampaignReducers.reducer,
    venues: VenueReducers.reducer,
    resources: RecourceReducers.reducer,
    reception: ReceptionReducers.reducer,
    diary: DiaryReducers.reducer,
    integrations: IntegrationReducers.reducer,
    emailTemplates: EmailTemplateReducers.reducer,
    emails: EmailReducers.reducer,
    taxRates: TaxRateReducers.reducer,
    tags: TagReducers.reducer,
    productCategories: ProductCategoryReducers.reducer,
    products: ProductReducers.reducer,
    promotions: PromotionReducers.reducer,
    fees: FeeReducers.reducer,
    affiliates: AffiliateReducers.reducer,
    activityFormats: ActivityFormatRedeucers.reducer,
    activityFormatGroups: ActivityFormatGroupRedeucers.reducer,
    tasks: TaskRedeucers.reducer,
    paymentMethods: PaymentMethodReducers.reducer,
    paymentGateways: PaymentGatewayReducers.reducer,
    paymentSchedules: PaymentScheduleReducers.reducer,
    termsAndConditions: TermsAndConditionsReducers.reducer,
    reportTypes: ReportTypeReducers.reducer,
    reportDefinitions: ReportDefinitionReducers.reducer,
    paymentReminderConfigs: PaymentReminderConfigReducers.reducer,
    publicWebsiteSettings: PublicWebsiteSettingsReducers.reducer,
    customerCategories: CustomerCategoryReducers.reducer,
    customers: CustomerReducers.reducer,
    routing: RoutingReducers.reducer,
    bookingEmailScheduleConfigs: BookingEmailScheduleConfigReducers.reducer,
    vouchers: VoucherReducers.reducer,
    leaderboards: LeaderboardReducers.reducer,
    navigation: NavigationReducers.reducer,
    preferences: PreferenceReducers.reducer,
    memberships: MembershipReducers.reducer,
    i18nState
};

export const rootEpic: Epic<any, Action<any>, void, any> = combineEpics(
    ClientChangeHandlers.handlers,
    LoginActions.loginEpic,
//    LoginActions.loginSuccessEpic,
    LoginActions.loggedInEpic,
    LoginActions.loggedOutEpic,
    LoginActions.changeUserPasswordEpic,
    LoginActions.refreshTokenEpic,
    LoginActions.onTokenErrorEpic,
    LoginActions.switchTokenEpic,
    LoginActions.loadUserClientInfoEpic,
    LoginActions.clientAddedEpic,
    LoginActions.resetPasswordEpic,
    LoginActions.resetUserPasswordEpic,
    LoginActions.clientDataLoadCompleteEpic,
    CultureActions.loadCulturesEpic,
    CurrencyActions.loadCurrenciesEpic,
    TimeZoneActions.loadTimeZonesEpic,
    UserActions.loadUsersEpic,
    UserActions.reloadUsersEpic,
    UserActions.saveUserEpic,
    ClientActions.loadClientsEpic,
    ClientActions.reloadClientsEpic,
    ClientActions.saveClientEpic,
    CampaignActions.loadCampaignsEpic,
    CampaignActions.reloadCampaignsEpic,
    CampaignActions.saveCampaignEpic,
    CampaignActions.campaigChangedEpic,
    VenueActions.loadVenuesEpic,
    VenueActions.reloadVenuesEpic,
    VenueActions.handleIntegrationChanged,
    VenueActions.saveVenueEpic,
    VenueActions.saveRegistrationSettingsEpic,
    VenueActions.enableIntegrationEpic,
    VenueActions.revokeIntegrationToken,
    ResourceActions.loadResourcesEpic,
    ResourceActions.reloadResourcesEpic,
    ResourceActions.saveResourceEpic,
    ReceptionActions.switchDateEpic,
    ReceptionActions.loadRegistrationsEpic,
    ReceptionActions.loadEventsEpic,
    ReceptionActions.addRegistrationsToBookingEpic,
    ReceptionActions.checkInCustomersEpic,
    ReceptionActions.undoCheckedInCustomersEpic,
    ReceptionActions.unregisterEpic,
    ReceptionActions.updateRegistrationEpic,
    ReceptionActions.registrationsChangedEpic,
    ReceptionActions.customerCheckedInEpic,
    ReceptionActions.eventsChangedEpic,
    ReceptionActions.billChangedEpic,
    DiaryActions.switchDateEpic,
    DiaryActions.loadReservationsEpic,
    DiaryActions.reloadResourcesEpic,
    DiaryActions.loadDiaryNoteEpic,
    DiaryActions.saveDiaryNoteEpic,
    DiaryActions.reloadDiaryNoteEpic,
    DiaryActions.searchCustomersEpic,
    DiaryActions.registrationsChangedEpic,
    DiaryActions.customerCheckedInEpic,
    DiaryActions.saveOpeningTimesEpic,
    DiaryActions.openingTimesChangedEpic,
    IntegrationActions.loadIntegrationEpic,
    IntegrationActions.reloadIntegrationEpic,
    IntegrationActions.saveIntegrationEpic,
    EmailTemplateActions.loadEmailTemplateEpic,
    EmailActions.loadEmailsEpic,
    EmailTemplateActions.reloadEmailTemplateEpic,
    EmailTemplateActions.saveEmailTemplateEpic,
    TaxRateActions.loadTaxRatesEpic,
    TaxRateActions.reloadTaxRatesEpic,
    TaxRateActions.saveTaxRateEpic,
    TagActions.loadTagsEpic,
    TagActions.reloadTagsEpic,
    TagActions.saveTagEpic,
    TagActions.tagChangedEpic,
    ProductCategoryActions.loadProductCategoriesEpic,
    ProductCategoryActions.reloadProductCategoriesEpic,
    ProductCategoryActions.saveProductCategoryEpic,
    ProductActions.loadProductsEpic,
    ProductActions.reloadProductsEpic,
    ProductActions.reloadVoucherProductsEpic,
    ProductActions.reloadMembershipTypeProductsEpic,
    ProductActions.saveProductEpic,
    PromotionActions.loadPromotionsEpic,
    PromotionActions.reloadPromotionsEpic,
    PromotionActions.savePromotionEpic,
    FeeActions.loadFeesEpic,
    FeeActions.reloadFeesEpic,
    FeeActions.saveFeeEpic,
    AffiliateActions.loadAffiliatesEpic,
    AffiliateActions.reloadAffiliatesEpic,
    AffiliateActions.saveAffiliateEpic,
    ActivityFormatActions.loadActivityFormatsEpic,
    ActivityFormatActions.reloadActivityFormatsEpic,
    ActivityFormatActions.customerCategoriesChangedEpic,
    ActivityFormatActions.saveActivityFormatEpic,
    ActivityFormatGroupActions.loadActivityFormatGroupEpic,
    ActivityFormatGroupActions.reloadActivityFormatGroupEpic,
    ActivityFormatGroupActions.saveActivityFormatGroupEpic,
    TaskActions.loadTaskDefinitionsEpic,
    TaskActions.reloadTaskDefinitionsEpic,
    TaskActions.saveTaskDefinitionEpic,
    PaymentMethodActions.loadPaymentMethodsEpic,
    PaymentMethodActions.reloadPaymentMethodsEpic,
    PaymentMethodActions.savePaymentMethodEpic,
    PaymentGatewayActions.loadPaymentGatewaysEpic,
    PaymentGatewayActions.savePaymentGatewayEpic,
    PaymentScheduleActions.loadPaymentSchedulesEpic,
    PaymentScheduleActions.savePaymentScheduleEpic,
    PaymentScheduleActions.reloadPaymentSchedulesEpic,
    TermsAndConditionsActions.loadTermsAndConditionsEpic,
    TermsAndConditionsActions.loadRegistrationTermsEpic,
    TermsAndConditionsActions.loadBookingTermsEpic,
    TermsAndConditionsActions.saveRegistrationTermsEpic,
    TermsAndConditionsActions.saveBookingTermsEpic,
    TermsAndConditionsActions.reloadRegistrationTermsEpic,
    TermsAndConditionsActions.reloadBookingTermsEpic,
    TermsAndConditionsActions.loadRegistrationVideosEpic,
    TermsAndConditionsActions.loadWebShopPurchaseTermsEpic,
    TermsAndConditionsActions.saveWebShopPurchaseTermsEpic,
    TermsAndConditionsActions.reloadWebShopPurchaseTermsEpic,
    ReportTypeActions.loadReportTypesEpic,
    ReportDefinitionActions.loadReportDefinitionsEpic,
    ReportDefinitionActions.reloadReportDefinitionsEpic,
    ReportDefinitionActions.saveReportDefinitionEpic,
    PaymentReminderConfigActions.loadPaymentReminderConfigsEpic,
    PaymentReminderConfigActions.reloadPaymentReminderConfigsEpic,
    PaymentReminderConfigActions.savePaymentReminderConfigEpic,
    PublicWebsiteSettingsAction.loadPublicWebsiteSettingsEpic,
    PublicWebsiteSettingsAction.reloadPublicWebsiteSettingsEpic,
    PublicWebsiteSettingsAction.savePublicWebsiteSettingsEpic,
    CustomerCategoryActions.loadCustomerCategoryEpic,
    CustomerCategoryActions.reloadCustomerCategoryEpic,
    CustomerCategoryActions.saveCustomerCategoryEpic,
    CustomerActions.loadCustomersEpic,
    BookingEmailScheduleConfigActions.loadBookingEmailScheduleConfigsEpic,
    BookingEmailScheduleConfigActions.reloadBookingEmailScheduleConfigsEpic,
    BookingEmailScheduleConfigActions.saveBookingEmailScheduleConfigEpic,
    VoucherActions.loadVoucherProductsEpic,
    VoucherActions.reloadVoucherProductsEpic,
    VoucherActions.saveVoucherProductEpic,
    VoucherActions.searchVouchersEpic,
    LeaderboardActions.loadLeaderboardsEpic,
    LeaderboardActions.reloadLeaderboardsEpic,
    LeaderboardActions.saveLeaderboardEpic,
    LeaderboardActions.loadTimingSettingsEpic,
    NavigationActions.routeChangedEpic,
    MembershipActions.loadMembershipTypesEpic,
    MembershipActions.reloadMembershipTypesEpic,
    MembershipActions.saveMembershipTypeEpic,
    MembershipActions.searchMembershipsEpic,
    PreferenceActions.venueChangedEpic
);
