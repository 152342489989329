
import { Action } from 'redux';

import { CultureActionTypes, CultureState } from './types';
import { CultureActions } from './actions'
import { createReducer } from '../../reducer';


const unloadedState: CultureState = { isLoading: false, cultures: [], loadError: null };

export const reducer = (state: CultureState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: CultureState, incomingAction: Action): CultureState => {

    const action = incomingAction as CultureActions;
    switch (action.type) {
        case CultureActionTypes.RequestCultures:
            return {
                ...state,
                isLoading: true,
                cultures: state.cultures,
                loadError: null
            };
        case CultureActionTypes.ReceivedCultures:
            const ordered = action.cultures ? action.cultures : [];
            ordered.sort((a, b) => a.name.localeCompare(b.name));

            return {
                ...state,
                isLoading: false,
                cultures: ordered,
                loadError: null
            };
        case CultureActionTypes.RequestCulturesError:
            return {
                ...state,
                isLoading: false,
                cultures: [],
                loadError: action.error
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action; 
    }

    return state || unloadedState;
};

