import { ValidationError } from '../../global/types';
import * as api from '../../apiClient';

export interface CustomerCategory {
    id: string;
    venueId: string;
    name: string;
    description: string;
    pluralisedName: string;
    code: string;
    timingCode: string | null;
    minAge: number | null;
    maxAge: number | null;
    minHeight: number | null;
    maxHeight: number | null;
    archived: boolean;
}

export interface CustomerCategoriesState {
    isActive: boolean;
    isLoading: boolean;
    customerCategories: CustomerCategory[];
    loadError: api.ApiError | null;
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
}

export enum CustomerCategoryActionTypes {
    RequestCustomerCategories = 'REQUEST_CUSTOMER_CATEGORIES',
    ReceivedCustomerCategories = 'RECEIVE_CUSTOMER_CATEGORIES',
    SaveCustomerCategory = 'SAVE_CUSTOMER_CATEGORY',
    CustomerCategorySaved = 'CUSTOMER_CATEGORY_SAVED',
    CustomerCategorySaveFailed = 'CUSTOMER_CATEGORY_SAVE_FAILED',
    EditCustomerCategory = 'EDIT_CUSTOMER_CATEGORY'
}


export interface LoadCustomerCategories {
    type: CustomerCategoryActionTypes.RequestCustomerCategories;
}


export interface ReceiveCustomerCategories {
    type: CustomerCategoryActionTypes.ReceivedCustomerCategories;
    customerCategories: CustomerCategory[];
    error: api.ApiError | null;
}

export interface SaveCustomerCategory {
    type: CustomerCategoryActionTypes.SaveCustomerCategory;
    isNew: boolean;
    customerCategory: CustomerCategory;
}

export interface CustomerCategorySaved {
    type: CustomerCategoryActionTypes.CustomerCategorySaved;
    customerCategoryId: string;
}

export interface CustomerCategorySaveFailed {
    type: CustomerCategoryActionTypes.CustomerCategorySaveFailed;
    error: api.ApiError;
}

export interface EditCustomerCategory {
    type: CustomerCategoryActionTypes.EditCustomerCategory
}