
import * as PropTypes from 'prop-types'
import * as ct from '../../global/controls';
import moment from 'moment';
import { ITranslationContext } from '../../../translations';
import { DateFormat, DayOfWeek} from '../../../store/pages/venues/types';
import { AnalyticsComparisonPeriodSelection, AnalyticsPeriod, ComparisonPeriod } from '../../../store/pages/analytics/types';
import { formatPosition } from '../../../utils/util';
import { monthNameAbbreviations } from '../../../utils/extensions';


interface ComparisonPeriodSelectionProps {
    firstDayOfWeek: DayOfWeek;
    period: AnalyticsPeriod;
    comparisonPeriod: ComparisonPeriod;
    dateFormat: DateFormat;
    periodChanged: (period: ComparisonPeriod) => void;
}

const ComparisonPeriodSelection = (props: ComparisonPeriodSelectionProps, context: ITranslationContext) => {
    const { t } = context;
    const { period, comparisonPeriod, dateFormat, periodChanged } = props;
    const options = Object.keys(AnalyticsComparisonPeriodSelection)
        .filter(k => typeof AnalyticsComparisonPeriodSelection[k as any] === 'number')
        .map(k => ({ key: AnalyticsComparisonPeriodSelection[k as any].toString(), name: t(`AnalyticsComparisonPeriodSelection:${k}`) }));

    const onPeriodSelected = (selectedPeriodKey: string) => {
        const periodSelection = parseInt(selectedPeriodKey);
        const newComparisonPeriod = periodSelection === AnalyticsComparisonPeriodSelection.Custom
            ? ComparisonPeriod.custom(new Date(), new Date())
            : ComparisonPeriod.create(period, periodSelection);

        periodChanged(newComparisonPeriod)
    }

    return <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ct.Select
            id='reportComparisonPeriod'
            classNames='analytics-report-period-selection'
            labelKey=''
            value={({ controlId: 'reportComparisonPeriod', isValid: true, hasValidation: false, value: comparisonPeriod.period.toString() })}
            options={options}
            renderSelectedOption={o => `${o.name} ${formatPeriod(parseInt(o.key), comparisonPeriod.from, comparisonPeriod.to, dateFormat, t)}`}
            callback={onPeriodSelected}
            minimal={true} />
        {comparisonPeriod.period === AnalyticsComparisonPeriodSelection.Custom
            ? <ct.DateRangePicker
                id='reportComparisonPeriodDateSelection'
                labelKey=''
                value={({ controlId: 'reportComparisonPeriodDateSelection', isValid: true, hasValidation: false, value: { from: moment(comparisonPeriod.from), to: moment(comparisonPeriod.to)  } }) }
                callback={val => periodChanged(ComparisonPeriod.custom(val.from ? val.from.toDate() : new Date(), val.to ? val.to.toDate() : new Date()))}
                timeFormat={false}
                minimal={true} />
            : null}
    </div>
}

const formatPeriod = (period: AnalyticsComparisonPeriodSelection, from: Date, to: Date, dateFormat: DateFormat, t: (val: string) => string) => {
    if (period === AnalyticsComparisonPeriodSelection.Custom || period === AnalyticsComparisonPeriodSelection.None) {
            return "";
    }

    return `(${formatDatePeriod(from, to, dateFormat, t)})`;
}

const formatDatePeriod = (from: Date, to: Date, dateFormat: DateFormat, t: (val: string) => string) => {
    if (from.getTime() == to.getTime())
        return from.toAbbrDateString(dateFormat, t);
    if (from.getFullYear() === to.getFullYear()) {
        if (from.getMonth() === to.getMonth()) {
            return dateFormat == DateFormat.MDY
                ? `${t(monthNameAbbreviations[from.getMonth()])} ${formatPosition(from.getDate(), t)} - ${formatPosition(to.getDate(), t)} ${from.getFullYear()}`
                : `${from.getDate()} - ${to.getDate()} ${t(monthNameAbbreviations[from.getMonth()])} ${from.getFullYear()}`
        } else {
            return dateFormat == DateFormat.MDY
                ? `${t(monthNameAbbreviations[from.getMonth()])} ${formatPosition(from.getDate(), t)} - ${t(monthNameAbbreviations[to.getMonth()])} ${formatPosition(to.getDate(), t)} ${from.getFullYear()}`
                : `${from.getDate()} ${t(monthNameAbbreviations[from.getMonth()])} - ${to.getDate()} ${t(monthNameAbbreviations[to.getMonth()])} ${from.getFullYear()}`
        }
    } else {
        return `${from.toAbbrDateString(dateFormat, t)} - ${to.toAbbrDateString(dateFormat, t)}`;
    }
}

ComparisonPeriodSelection.contextTypes = {
    t: PropTypes.func
}

export default ComparisonPeriodSelection;