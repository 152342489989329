
import '../../../css/print.css';

import * as React from 'react';
import * as PropTypes from 'prop-types'

import { IEvent, IEventReservation } from './types';
import { Registration } from '../../../store/pages/reception/types';
import { stringComparer } from '../../../utils/comparers';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';

interface ReceptionPagePrintViewProps {
    date: Date;
    events: IEvent[];
    registrations: Registration[];
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
    close: () => void;
}


class ReceptionPagePrintView extends React.Component<ReceptionPagePrintViewProps, {}> {

    static contextTypes = {
        t: PropTypes.func
    }

    print = () => {
        const content = document.getElementById("print-wrapper");
        const printFrame = document.getElementById("ifmcontentstoprint") as HTMLIFrameElement;

        if (!content || !printFrame || !printFrame.contentWindow)
            return;

        let stylesheets: StyleSheet[] = [];
        let inlineStylesheets: CSSStyleSheet[] = [];

        for (let si = 0; si < document.styleSheets.length; si++) {
            const ss = document.styleSheets[si]
            if (ss && ss.type) {
                if (ss.href) {
                    stylesheets.push(ss);
                } else {
                    inlineStylesheets.push(ss);
                }
            }
        }

        const styleSheetLinks = stylesheets.map(s => `<link rel="stylesheet" type="${s.type}" href="${s.href}">`).join('');

        const inlineSheets = inlineStylesheets.map(s => `<style>${Array.from(s.cssRules).map(r => r.cssText).join('')}</style>`).join('');

        const headstr = `<html><head><title></title>${styleSheetLinks}${inlineSheets}</head><body>`;
        const footstr = "</body>";

        const pri = printFrame.contentWindow;

        const pageContent = content.innerHTML;

        printFrame.onload = () => pri.print();
        pri.document.open();
        pri.document.write(headstr + pageContent + footstr);
        pri.document.close();
        pri.focus();
    }

    render() {
        const { t } = this.context;
        const { events, date, timeFormat, dateFormat, close } = this.props;

        const rows = events.map(e => [e.reservations.sort((r1, r2) => r1.startTime.getTime() - r2.startTime.getTime()).map(rsv => this.renderReservation(e, rsv, timeFormat))].concat(this.renderRegistrations(e)))

        return <div>
            <div id='print-wrapper'>
                <div className='reception-print-wrapper'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th className='text-center' colSpan={4}>{date.toLongDateString(dateFormat, t)}</th>
                            </tr>
                        </thead>
                        <tbody>{rows}</tbody>
                    </table>
                </div>
            </div>

            <div className='btn-toolbar'>
                <button className='btn btn-primary' onClick={this.print}>{t('Global:print')}</button>
                <button className='btn btn-basic' onClick={close}>{t('Global:close')}</button>
            </div>
        </div>
    }

    renderReservation = (evt: IEvent, rsv: IEventReservation, timeFormat: TimeFormat) => <tr key={rsv.reservationId}><th className='shrink' style={{ paddingTop: '15px', fontWeight: 'bold' }}>{rsv.startTime.toShortTimeString(timeFormat)}</th><th colSpan={3} style={{ paddingTop: '15px', fontWeight: 'bold' }}>{rsv.resourceShortName} | {rsv.activityFormat ? rsv.activityFormat.name : ''}</th></tr>

    renderRegistrations = (evt: IEvent) => {
        const { registrations } = this.props;

        const reservationRegistrations = registrations.filter(r => {
            const registeredForEvent = r.registeredForEvents.filter(re => re.eventId === evt.id && !re.removed).length > 0;
            return registeredForEvent;
        });
        
        const rows = reservationRegistrations.sort((r1, r2) => stringComparer(r1.customerFirstname, r2.customerFirstname)).map(reg => {
            const eventRegistrations = reg.registeredForEvents.filter(re => re.eventId === evt.id);
            const category = eventRegistrations.flatMap(e => e.customerCategories).reduce<string[]>((cats, e) => cats.includes(e.customerCategoryName) ? cats : cats.concat(e.customerCategoryName), []).join(" | ");
            const activityFormatName = eventRegistrations.length > 0 ? eventRegistrations[0].activityFormatName : '';

            return <tr key={`${evt.id}_${reg.id}`} className='reception-print-data-row'>
                <td className='shrink reception-print-data'></td>
                <td className='shrink reception-print-data'>{reg.customerFirstname} {reg.customerLastname}</td>
                <td className='shrink reception-print-data'>{reg.age}</td>
                <td className='shrink reception-print-data'>{category}</td>
                <td className='expand reception-print-data'>{activityFormatName}</td>
            </tr>
        })

        return rows;
    }
}

export default ReceptionPagePrintView;
