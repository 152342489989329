
import * as React from 'react';

interface TermsAndConditionsViewProps {
    terms: string;
}

export const TermsAndConditionsView = (props: TermsAndConditionsViewProps) => {
    return (
        <div className='quill quill-dark'>
            <div className='quill quill-dark ql-editor'>
                <div dangerouslySetInnerHTML={{ __html: props.terms }}></div>
            </div>
        </div>
    );
}