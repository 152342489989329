
import '../../../css/reactdatetime.css';

import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as api from '../../../store/apiClient';

import { isNullOrEmpty, clickHandler } from '../../../utils/util';
import { Bill, BillPayment, AdditionalPaymentInfo, GatewayPaymentStatus, BillGatewayPayment, IUpdatePaymentResponse, deserializeBill, formatPaymentMethodName, formatPaymentMethodInfo } from '../../../store/pages/pointOfSale/types';
import { ValidationError } from '../../../store/global/types';
import ValidationSummary from '../../global/validationSummary';
import ConfirmVoidItem from './confirmVoidItem';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';

interface EditPaymentProps {
    venueId: string;
    billId: string;
    payment: BillPayment;
    eventDate: Date | null;
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
    paymentUpdated: (bill: Bill, closeOverlay: boolean) => void;
    cancel: () => void;
    cancelPaymentAttempt: (gatewayPaymentId: string) => void;
}

interface EditPaymentState {
    confirmVoid: boolean;
    saving: boolean;
    saveError: api.ApiError | null;
    saveValidationErrors: ValidationError[];
}

export default class EditPayment extends React.Component<EditPaymentProps, EditPaymentState> {

    today: Date;

    constructor(props: EditPaymentProps) {
        super(props);

        this.today = new Date().datePart();

        this.state = {
            confirmVoid: false,
            saving: false,
            saveError: null,
            saveValidationErrors: []
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    voidPayment = () => {
        this.setState({ confirmVoid: true });
    }

    closeVoidPayment = () => {
        this.setState({ confirmVoid: false });
    }

    confirmVoidPayment = (payment: BillPayment, reason: string) => {
        const { venueId, billId } = this.props;

        this.setState({ saving: true });

        api.putWithAuth(`api/v1/bill/${billId}/payment/${payment.id}/void`, { venueId: venueId, voidReason: reason }, () => ({ /* TODO: Handle auth error*/ }))
            .subscribe(response => {
                const mpr = response.response as IUpdatePaymentResponse;
                if (mpr) {
                    const mappedBill = deserializeBill(mpr.bill);
                    this.paymentUpdated(mappedBill);
                }
            }, (err: api.ApiError) => {
                this.setState({ saving: false, saveError: err, saveValidationErrors: err.validationErrors });
            });
    }

    reinstateVoidPayment = () => {
        const { venueId, billId, payment } = this.props;

        this.setState({ saving: true });

        api.putWithAuth(`api/v1/bill/${billId}/payment/${payment.id}/unvoid`, { venueId: venueId }, () => ({ /* TODO: Handle auth error*/ }))
            .subscribe(response => {
                const mpr = response.response as IUpdatePaymentResponse;
                if (mpr) {
                    const mappedBill = deserializeBill(mpr.bill);
                    this.paymentUpdated(mappedBill);
                }
            }, (err: api.ApiError) => {
                this.setState({ saving: false, saveError: err, saveValidationErrors: err.validationErrors });
            });
    }

    paymentUpdated = (bill: Bill) => this.props.paymentUpdated(bill, true);

    printReceipt = () => {
        const { venueId, payment } = this.props;
        const { t } = this.context;
        api.downloadFile(`api/reports/PaymentReceipt?venueId=${venueId}&paymentId=${payment.id}`, `${t('PointOfSale:paymentReceipt')}.pdf`, (success: boolean, error: string | null) => { });
    }

    render() {
        const { eventDate, payment, timeFormat, dateFormat } = this.props;
        const {  confirmVoid, saveError, saveValidationErrors } = this.state;
        const { t } = this.context;

        return (
            <div style={({ width: '100%', position: 'relative' })}>
                <div style={({ maxWidth: '480px', margin: '0 auto' })}>
                    <h3>{t('PointOfSale:editPayment')}</h3>

                    <form className='data-form' onSubmit={e => e.preventDefault()} autoComplete='off'>

                        {this.renderVoidDetails(payment, timeFormat, dateFormat)}

                        {eventDate ? this.renderInfo('PointOfSale:eventDate', `${eventDate.toLongDateString(dateFormat, t)} ${eventDate.toShortTimeString(timeFormat)}`) : null}

                        {this.renderInfo('PointOfSale:total', `${t('Global:currencySymbol')} ${payment.amount.toFixed(2)}`)}

                        {this.renderPaidDate(payment.payentTakenDateTime, timeFormat, dateFormat)}

                        {this.renderPaidBy(payment.paymentTakenBy)}

                        {this.renderPaymentMethod(payment)}

                        {this.renderPaymentDescription(payment.description)}

                        <p/>
                        {this.renderGatewayPaymentAttemps(payment.gatewayPayments, timeFormat, dateFormat)}
                       
                        <div className='row'>
                            <div className='form-group col-sm-12 button-panel'>
                                {!payment.void ? <button className='btn btn-danger' onClick={e => clickHandler(e, this.voidPayment)}>{t('Global:void')}</button> : null}
                                {payment.void ? <button className='btn btn-warning' onClick={e => clickHandler(e, this.reinstateVoidPayment)}>{t('PointOfSale:unvoidPayment')}</button> : null}
                                {payment.paid ? <button onClick={e => clickHandler(e, this.printReceipt)} className='btn btn-info'>{t('PointOfSale:printReceipt')}</button> : null}
                                <button onClick={e => clickHandler(e, this.props.cancel)} className='btn btn-basic'>{t('Global:cancel')}</button>
                            </div>
                        </div>
                        <div className='row'>
                            <ValidationSummary error={saveError} keyPrefix='PointOfSale' validationMessages={saveValidationErrors} t={t} />
                        </div>
                    </form>
                </div>
                {this.renderOverlay(confirmVoid)}
            </div>
        );
    }

    renderGatewayPaymentAttemps = (gatewayPayments: BillGatewayPayment[], timeFormat: TimeFormat, dateFormat: DateFormat) => {
        const { t } = this.context;

        if (gatewayPayments.length < 1)
            return null;

        var sortedPayments = gatewayPayments.sort((p1, p2) => p1.gatewayPaymentDate.getTime() - p2.gatewayPaymentDate.getTime());

        return <>
            <div className='row'>
                <div className='col-sm-12'>
                    {t('PointOfSale:paymentAttempts')}
                </div>
            </div>
            <div className='row'>
                <div className='col-sm-12'>
                    {sortedPayments.map(gp => {
                        const paymentDetails = this.renderPaymentAttemptAction(gp)
                        const paymentInfo = <span className={`text-${this.mapGatewayPaymentStatusTextColour(gp.gatewayPaymentStatus)}`}>
                            {gp.gatewayPaymentDate.toAbbrDateString(dateFormat, t)} {gp.gatewayPaymentDate.toShortTimeString(timeFormat)} {t(`GatewayPaymentStatus:${GatewayPaymentStatus[gp.gatewayPaymentStatus]}`)}
                        </span>

                        return <div className='row' key={gp.gatewayPaymentId}>
                            <div className='col-xs-12'>
                            {paymentDetails
                                ? <details>
                                        <summary style={{display: 'list-item'}}>
                                        {paymentInfo}
                                    </summary>
                                    {paymentDetails}
                                </details>
                                : <div>{paymentInfo}</div>
                            }
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </>
    }

    mapGatewayPaymentStatusTextColour = (status: GatewayPaymentStatus) => {
        switch (status) {
            case GatewayPaymentStatus.CardDeclined:
            case GatewayPaymentStatus.CardReferred:
            case GatewayPaymentStatus.DuplicateTransaction:
            case GatewayPaymentStatus.Error:
            case GatewayPaymentStatus.Cancelled:
                return 'danger';
            case GatewayPaymentStatus.Success:
                return 'success';
            case GatewayPaymentStatus.InProgress:
                return 'info';
            default:
                return 'muted';
        }
    }

    renderPaymentAttemptAction = (gp: BillGatewayPayment) => {
        const { t } = this.context;
        switch (gp.gatewayPaymentStatus) {
            case GatewayPaymentStatus.InProgress:
                return <button className='btn btn-sm btn-info' onClick={e => clickHandler(e, () => this.props.cancelPaymentAttempt(gp.gatewayPaymentId))} >{t('Global:cancel')}</button>;

            case GatewayPaymentStatus.CardDeclined:
            case GatewayPaymentStatus.CardReferred:
            case GatewayPaymentStatus.DuplicateTransaction:
            case GatewayPaymentStatus.Error:
            case GatewayPaymentStatus.Cancelled:
            case GatewayPaymentStatus.Success:
                return this.renderPaymentAttempDetails(gp)
            default:
                return null;
        }
    }

    renderPaymentAttempDetails = (gp: BillGatewayPayment) => {
        const { t } = this.context;

        const items: {key: string, data: string}[] = [];
        items.push({ key: 'PointOfSale:gatewayPaymentStatus', data: t(`GatewayPaymentStatus:${GatewayPaymentStatus[gp.gatewayPaymentStatus]}`) })

        if (!isNullOrEmpty(gp.gatewayCardType)) items.push({ key: 'PointOfSale:gatewayCardType', data: gp.gatewayCardType })
        if (gp.cardMachinePayment && gp.terminalName) items.push({ key: 'PointOfSale:terminal', data: gp.terminalName })
        if (!isNullOrEmpty(gp.gatewayCardIssuer)) items.push({ key: 'PointOfSale:gatewayCardIssuer', data: gp.gatewayCardIssuer })
        if (!isNullOrEmpty(gp.gatewayCardHolder)) items.push({ key: 'PointOfSale:gatewayCardHolder', data: gp.gatewayCardHolder })
        if (!isNullOrEmpty(gp.gatewayCardLastFour)) items.push({ key: 'PointOfSale:gatewayCardLast4Digits', data: gp.gatewayCardLastFour })
        if (!isNullOrEmpty(gp.gatewayResponse)) items.push({ key: 'PointOfSale:gatewayResponse', data: gp.gatewayResponse })
        if (!isNullOrEmpty(gp.gatewayClientIp)) items.push({ key: 'PointOfSale:gatewayClientIp', data: gp.gatewayClientIp })
        if (!isNullOrEmpty(gp.gatewayIssuerPaymentId)) items.push({ key: 'PointOfSale:gatewayIssuerPaymentId', data: gp.gatewayIssuerPaymentId || '' })
        if (!isNullOrEmpty(gp.gatewayToken)) items.push({ key: 'PointOfSale:gatewayToken', data: gp.gatewayToken || '' })

        return <dl style={{margin: '0 15px', backgroundColor: '#f6f6f6', padding: '6px 10px'}}>
            {items.map(i => <React.Fragment key={i.key}>
                <dt>{t(i.key)}</dt>
                <dd className='wrap-text'>{i.data}</dd>
            </React.Fragment>)}
        </dl>
    }

    renderInfo = (labelKey: string, content: string | React.ReactNode | null) => {
        if (!content) {
            return null;
        }

        return (
            <div className='row'>
                <div className='col-sm-4'>
                    <label>{this.context.t(labelKey)}:</label>
                </div>
                <div className='col-sm-8'>
                    {typeof content === 'string' ? <span className='wrap-text'>{content}</span> : content}
                </div>
            </div>
        );
    };

    renderPaidDate = (payentTakenDateTime: Date | null, timeFormat: TimeFormat, dateFormat: DateFormat) => payentTakenDateTime ? this.renderInfo('PointOfSale:paymentDate', `${payentTakenDateTime.toLongDateString(dateFormat, this.context.t)} ${payentTakenDateTime.toShortTimeString(timeFormat)}`) : null;

    renderPaidBy = (paidBy: string | null) => paidBy ? this.renderInfo('PointOfSale:paidBy', paidBy) : null;

    renderPaymentMethod = (payment: BillPayment) => {
        const { additionalInfo, paymentMethodName } = payment;

        if (!paymentMethodName || isNullOrEmpty(paymentMethodName)) return null;

        const paymentMethodText = formatPaymentMethodInfo(payment);
        return this.renderInfo('PointOfSale:paymentMethod', additionalInfo && additionalInfo.length > 0 ? <><div>{paymentMethodText}</div>{this.renderAdditionalInfo(additionalInfo)}</> : paymentMethodText);
    }

    renderAdditionalInfo = (additionalInfo: AdditionalPaymentInfo[]) => additionalInfo.map(i => <div key={i.paymentInformationId}><span className='label label-primary'>{i.name}: {i.text}</span></div>);

    renderPaymentDescription = (desc: string | null) => desc ? this.renderInfo('Global:description', desc) : null;

    renderVoidDetails = (payment: BillPayment, timeFormat: TimeFormat, dateFormat: DateFormat) => {
        if (!payment.void) {
            return null;
        }

        return <div className='alert alert-danger'>
            {payment.voidedDateTime ? this.renderInfo('PointOfSale:voidedBy', `${payment.voidedBy || ''} - ${payment.voidedDateTime.toLongDateString(dateFormat, this.context.t)} ${payment.voidedDateTime.toShortTimeString(timeFormat)}`) : null}
            {this.renderInfo('PointOfSale:voidReason', payment.voidReason||'')}
        </div>
    }

    renderOverlay = (showOverlay: boolean) => {
        if (showOverlay) {
            const { payment } = this.props;
            const { saving, saveError, saveValidationErrors } = this.state;

            return <ConfirmVoidItem
                headerKey='PointOfSale:confirmVoidPayment'
                confirmButtonKey='PointOfSale:yesVoidPayment'
                confirm={reason => this.confirmVoidPayment(payment, reason)}
                close={this.closeVoidPayment}
                saveError={saveError}
                saveValidationErrors={saveValidationErrors}
                saving={saving} />
        } else {
            return null;
        }
    }
}