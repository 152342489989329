
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'
import { ApplicationState } from '../../../store';
import * as api from '../../../store/apiClient';
import * as UserState from '../../../store/pages/users/types';
import * as UserActions from '../../../store/pages/users/actions';
import * as ModalActions from '../../../store/global/modal/actions';
import UserForm from './userForm';
import Loading from '../../global/loading';
import { clickHandler } from '../../../utils/util';
import ApiError from '../../global/apiError';
import { stringComparer } from '../../../utils/comparers';


interface LocalProps { }

interface LocalState {
    users: UserState.IUser[];
    isLoading: boolean;
    loadError: api.ApiError | null;
}

interface Actions {
    loadUsers: () => void;
    editUser: () => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
}

type RoutedLocalProps = LocalProps & RouteComponentProps<{}>;

type UsersPageProps = LocalState & Actions & RoutedLocalProps;

class UsersPage extends React.Component<UsersPageProps, {}> {
    static contextTypes = {
        t: PropTypes.func
    }

    private editUser = (user: UserState.IUser) => {
        this.showOverlay(user, false);
    }

    private addUser = () => {
        this.showOverlay(null, true);
    }

    private showOverlay = (user: UserState.IUser | null, addingNew: boolean) => {
        this.props.editUser();
        this.props.showModal(<UserForm isNew={addingNew} user={user ? user : null} />, 'UserForm');
    }

    render() {

        const { t } = this.context;
        const { isLoading, loadError} = this.props;
        const body = isLoading ? <Loading /> : this.renderUsersTable();

        return <section className='usersPage'>
            <header className='section-header'>
                <div className='page-heading'>
                    <h1 className='users_title'>{t('UsersPage:title')}</h1>
                </div>
                <div className='section-actions right'>
                    <button className='btn btn-info' onClick={e => clickHandler(e, this.addUser)}>{t('UsersPage:addUser')}</button>
                </div>
            </header>
            {body}
            <ApiError error={loadError} />
        </section>;
    }
    
    private renderUsersTable() {
        const { t } = this.context;

        const users = this.props.users
            .sort((u1, u2) => u1.clientId == u2.clientId ? stringComparer(u1.userName, u2.userName) : u2.clientId - u1.clientId)
            .map(u =>
            <tr key={u.id}>
                <td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.editUser(u))}>{u.userName}</button></td>
                <td>{u.emailAddress}</td>
                <td>{u.firstName}</td>
                <td>{u.lastName}</td>
                <td>{u.phoneNumber}</td>
                <td>{t(u.systemAdmin ? 'Global:yes' : 'Global:no')}</td>
                <td>{t(u.clientAdmin ? 'Global:yes' : 'Global:no')}</td>
                <td>{u.deactivated ? <span className='label label-danger'>{t('UserForm:deactivated')}</span> : null}</td>
            </tr>
        );

        return <table className='table table-condensed'>
            <thead>
                <tr>
                    <th>{t('Global:name')}</th>
                    <th>{t('UsersPage:emailHeading')}</th>
                    <th colSpan={2}>{t('UsersPage:name')}</th>
                    <th>{t('UsersPage:phoneHeading')}</th>
                    <th>{t('UsersPage:sysAdminHeading')}</th>
                    <th>{t('UsersPage:clientAdminHeading')}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {users}
            </tbody>
        </table>;
    }
};

const matStateToProps = (state: ApplicationState) => ({
    users: state.users.users,
    isLoading: state.users.isLoading,
    loadError: state.users.loadError
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadUsers: bindActionCreators(UserActions.actionCreators.loadUsers, dispatch),
    editUser: bindActionCreators(UserActions.actionCreators.editUser, dispatch),
    showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
});

// Wire up the React component to the Redux store
export default connect(matStateToProps, mapDispatchToProps)(UsersPage);

