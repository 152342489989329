
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'

import * as ct from '../../../global/controls';
import { ApplicationState } from '../../../../store';
import * as PaymentScheduleActions from '../../../../store/pages/paymentSchedules/actions';
import * as ModalActions from '../../../../store/global/modal/actions';
import Loading from '../../../global/loading';
import { clickHandler } from '../../../../utils/util';
import { PaymentAmountType, PaymentSchedule } from '../../../../store/pages/paymentSchedules/types';
import PaymentScheduleForm from './paymentScheduleForm';

interface LocalProps {
    venueId: string;
}

interface LocalState {
    isLoading: boolean;
    paymentSchedules: PaymentSchedule[];
}

interface Actions {
    editPaymentSchedule: () => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
}

type PaymentScheduleListProps = LocalState & Actions & LocalProps;

interface PaymentScheduleListState {
    showArchived: ct.FormValue<boolean>;
}


class PaymentScheduleList extends React.Component<PaymentScheduleListProps, PaymentScheduleListState> {

    constructor(props: PaymentScheduleListProps) {
        super(props);

        this.state = { showArchived: ct.asFormValue('showArchived', false) }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private editPaymentSchedule = (user: PaymentSchedule) => {
        this.showOverlay(user, false);
    }

    private addPaymentSchedule = () => {
        this.showOverlay(null, true);
    }

    private showOverlay = (paymentSchedule: PaymentSchedule | null, addingNew: boolean) => {
        this.props.editPaymentSchedule();
        this.props.showModal(<PaymentScheduleForm isNew={addingNew} venueId={this.props.venueId} paymentSchedule={paymentSchedule ? paymentSchedule : null}  />, 'PaymentScheduleForm');
    }

    render() {
        const { showArchived } = this.state;

        const body = this.props.isLoading ? <Loading /> : this.renderPaymentSchedulesTable();

        return <section className='venue-item-list'>
            <header className='section-header'>
                <div className='section-actions'>
                    <ct.Checkbox id='showArchived' labelKey='Global:showArchived' value={showArchived} callback={val => this.setState({ showArchived: ct.asFormValue('showArchived', val) })} style={({ display: 'inline-block' })} />
                    <button className='btn btn-info' onClick={e => clickHandler(e, this.addPaymentSchedule)}>{this.context.t('PaymentSchedulesPage:addPaymentSchedule')}</button>
                </div>
            </header>
            <div className='at-panel sub-section-panel-240px'>
                {body}
            </div>
        </section>;
    }
     
    private renderPaymentSchedulesTable() {
        const { t } = this.context;
        const { paymentSchedules, venueId } = this.props;
        const { showArchived } = this.state;

        const schedules = paymentSchedules.filter(ps => ps.venueId === venueId && (showArchived.value || !ps.archived))
            .map(ps =>
                <tr key={ps.id}>
                    <td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.editPaymentSchedule(ps))}>{ps.name}</button></td>
                    <td className='text-center'>{ps.enableForWebBooking ? t('Global:yes') : null}</td>
                    <td className='text-center'>{ps.minimumAmountThreshold}</td>
                    <td className='text-center'>{this.formatPaymentAmount(ps.immediatePaymentAmount, ps.immediatePaymentAmountType)}</td>
                    {showArchived.value ? <td className='text-center'>{ps.archived ? t('Global:yes') : null}</td> : null}
            </tr>
        );

        return <table className='table table-condensed'>
            <thead>
                <tr>
                    <th>{this.context.t('PaymentSchedulesPage:nameHeading')}</th>
                    <th>{this.context.t('PaymentSchedulesPage:enableForWebBookingHeading')}</th>
                    <th className='text-center'>{this.context.t('PaymentSchedulesPage:minimumAmountThresholdHeading')}</th>
                    <th className='text-center'>{this.context.t('PaymentSchedulesPage:immediatePaymentAmountHeading')}</th>
                    {showArchived.value ? <th className='text-center'>{t('Global:archived')}</th> : null}
                </tr>
            </thead>
            <tbody>
                {schedules}
            </tbody>
        </table>;
    }

    formatPaymentAmount = (paymentAmount: number | null, paymentAmountType: PaymentAmountType) => {
        const { t } = this.context;

        if (!paymentAmount) {
            return '';
        }

        switch (paymentAmountType) {
            case PaymentAmountType.FixedAmount:
                return `${t('Global:currencySymbol')}${paymentAmount.toFixed(2)}`;
            case PaymentAmountType.Percentage:
                return `${paymentAmount.toFixed(2)}%`
            case PaymentAmountType.AmountPerPerson:
                return `${t('Global:currencySymbol')}${paymentAmount.toFixed(2)} pp`;
            default:
                return paymentAmount.toFixed(2);
        }
    }
}

const matStateToProps = (state: ApplicationState) => ({
    paymentSchedules: state.paymentSchedules.paymentSchedules,
    isLoading: state.paymentSchedules.isLoading,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
        showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
        closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
        editPaymentSchedule: bindActionCreators(PaymentScheduleActions.actionCreators.editPaymentSchedule, dispatch),
    }
);


// Wire up the React component to the Redux store
export default connect(
    matStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps        // Selects which action creators are merged into the component's props
)(PaymentScheduleList);

