
import { Action, } from 'redux';
import { LocationChangeAction } from 'react-router-redux';

import { VenueActionTypes, VenueState, Venue, DateFormat, TimeFormat } from './types';
import { VenueActions } from './actions'
import {isNullOrEmpty} from '../../../utils/util';
import { createReducer } from '../../reducer';
import moment from 'moment';


const unloadedState: VenueState = {
    isActive: false,
    isLoading: false,
    venues: [],
    loadError: null,
    isSaving: false,
    saveComplete: false,
    saveError: null,
    validationErrors: [],
    selectedVenueId: '',
    selectedTabKey: '',
    dateFormat: DateFormat.DMY,
    timeFormat: TimeFormat.TwentyFourHour
};

export const reducer = (state: VenueState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

const setLocale = (venue: Venue) => {
    moment.updateLocale('en', {
        week: {
            dow: venue.firstDayOfWeek, // Monday is the first day of the week.
            doy: 4  // Used to determine first week of the year.
        }
    });
}

export const reducerHandler = (state: VenueState, incomingAction: Action): VenueState => {

    if (incomingAction.type === '@@router/LOCATION_CHANGE') {
        const locationChangeAction = incomingAction as LocationChangeAction;
        if (locationChangeAction) {
            return { ...state, isActive: locationChangeAction.payload.pathname === '/venues' || locationChangeAction.payload.pathname === '/diary' };
        }
    }

    const action = incomingAction as VenueActions;
    switch (action.type) {
        case VenueActionTypes.RequestVenues:
            return {
                ...state,
                isLoading: true,
                venues: state.venues,
                loadError: null
            };
        case VenueActionTypes.ReceivedVenues:
            return {
                ...state,
                isLoading: false,
                venues: action.venues,
                loadError: action.error
            };
        case VenueActionTypes.EditVenue:
            return {
                ...state,
                isSaving: false,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case VenueActionTypes.SaveVenue:
            return {
                ...state,
                isSaving: true,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case VenueActionTypes.VenueSaved:
            return {
                ...state,
                isSaving: false,
                saveComplete: true,
                saveError: null,
                validationErrors: []
            };
        case VenueActionTypes.VenueSaveFailed:
            return {
                ...state,
                isSaving: false,
                saveError: action.error,
                saveComplete: false,
                validationErrors: action.error && action.error.validationErrors ? action.error.validationErrors : []
            };
        case VenueActionTypes.SaveRegistrationSettings:
            return {
                ...state,
                isSaving: true,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case VenueActionTypes.SelectVenue:
            const venueId = action.venueId;

            if (venueId && venueId.length > 0) {
                localStorage.setItem('selectedVenueId', venueId);
            }

            const venue = state.venues.find(v => v.id === venueId);
           
            if (venue) {
                console.log(`setting locale for venue ${venueId}`)
                setLocale(venue);
            } else {
                console.log(`skipping setLocale - venue ${venueId} not founbd`)
            }

            return {
                ...state,
                selectedVenueId: venueId,
                dateFormat: venue ? venue.dateFormat : state.dateFormat,
                timeFormat: venue ? venue.timeFormat : state.timeFormat,
            };
        case VenueActionTypes.SelectTab:
            return { ...state, selectedTabKey: action.tabKey };

        case VenueActionTypes.EnableIntegration:
            return { ...state }
        case VenueActionTypes.RevokeIntegrationToken:
            return { ...state }
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

