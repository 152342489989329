
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'
import moment from 'moment';

import { Resource, ProductQuantityUnit } from '../../../../store/pages/resources/types';
import * as ct from '../../../global/controls';
import * as v from '../../../global/validation';
import { ApplicationState } from '../../../../store';
import * as ra from '../../../../store/pages/resources/actions';
import * as ta from '../../../../store/pages/tasks/actions';
import * as pa from '../../../../store/pages/products/actions';
import * as ModalActions from '../../../../store/global/modal/actions';
import { colours, Days, Time } from '../../../../store/global/types';
import { ValidationError } from '../../../../store/global/types';
import { TaskDefinition } from '../../../../store/pages/tasks/types';
import { TaskTrigger } from '../../../../store/pages/tasks/types';
import { TaskEmailRecipient } from '../../../../store/pages/tasks/types';
import { generateTempId, clickHandler } from '../../../../utils/util';
import * as api from '../../../../store/apiClient';
import ApiError from '../../../global/apiError';
import { Product } from '../../../../store/pages/products/types';

interface LocalProps {
    isNew: boolean;
    venueId: string;
    resource: Resource | null;
}

interface LocalState {
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
    isTaskDefinitionsLoading: boolean;
    taskDefinitions: TaskDefinition[];
    isProductsLoading: boolean;
    products: Product[];
    allResources: Resource[]
}

interface Actions {
    closeModal: () => void;
    saveResource: (isNew: boolean, resourceId: string | null, resource: Resource) => void;
    loadTaskDefinitions: () => void;
    loadProducts: () => void;
}

type ResourceFormProps = LocalState
    & Actions
    & LocalProps;

interface ResourceFormState {
    name: ct.FormValue<string>;
    shortName: ct.FormValue<string>;
    colour: string;
    sequence: ct.FormValue<number>;
    arrivalTimeBeforeEvent: ct.FormValue<Time>;
    snapOnlineBookingsTo: ct.FormValue<number>;
    numberOfLanes: ct.FormValue<number>;
    maxLaneCapacity: ct.FormValue<number | null>;
    allowMixedGroupsInLane: ct.FormValue<boolean>;
    archived: ct.FormValue<boolean>;
    taskDefinitionIds: string[];
    configurations: ResourceConfig[];
    additionalProducts: AdditionalProduct[];
    overlappedResourceIds: string[];
    breaks: Break[];
    errorMessage: string | null;
}

interface ResourceConfig {
    key: string;
    id: string;
    name: ct.FormValue<string>;
    code: ct.FormValue<string>;
    archived: boolean;
}

interface AdditionalProduct {
    key: string;
    id: string;
    productId: ct.FormValue<string>;
    minQuantity: ct.FormValue<number>;
    maxQuantity: ct.FormValue<number>;
    defaultQuantity: ct.FormValue<number>;
    quantityUnit: ct.FormValue<ProductQuantityUnit>;
    sequence: ct.FormValue<number>;
}

interface Break {
    key: string;
    id: string;
    days: Days;
    text: ct.FormValue<string>;
    startTime: ct.FormValue<Time>;
    duration: ct.FormValue<Time>;
    activeFrom: ct.FormValue<moment.Moment | null>;
    activeTo: ct.FormValue<moment.Moment | null>;
    archived: boolean;
}

class ResourceForm extends React.Component<ResourceFormProps, ResourceFormState> {

    constructor(props: ResourceFormProps) {
        super(props);

        this.state = this.buildStateFromProps(this.props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    buildStateFromProps(props: ResourceFormProps): ResourceFormState {

        const { isNew, resource } = props;

        return {
            name: this.validateName((isNew || !resource) ? '' : resource.name),
            shortName: this.validateName((isNew || !resource) ? '' : resource.shortName),
            colour: (isNew || !resource) ? colours[0] : resource.colour,
            sequence: this.validateSequence((isNew || !resource) ? 0 : resource.sequence),
            arrivalTimeBeforeEvent: this.validateArrivalTimeBeforeEvent((isNew || !resource) ? new Time(0, 30, 0) : resource.arrivalTimeBeforeEvent),
            snapOnlineBookingsTo: this.validateSnapOnlineBookingsTo((isNew || !resource) ? 20 : resource.snapOnlineBookingsTo),
            numberOfLanes: this.validateNumberOfLanes((isNew || !resource) ? 1 : resource.numberOfLanes),
            maxLaneCapacity: this.validateMaxPerLane((isNew || !resource) ? null : resource.maxLaneCapacity),
            allowMixedGroupsInLane: this.validateAllowMixedGroups((isNew || !resource) ? true : resource.allowMixedGroupsInLane),
            archived: this.validateArchived((isNew || !resource) ? false : resource.archived),
            overlappedResourceIds: (isNew || !resource) ? [] : resource.overlappedResourceIds,
            taskDefinitionIds: (isNew || !resource) ? [] : resource.taskDefinitionIds,
            configurations: (isNew || !resource || !resource.configurations) ? [] : resource.configurations.map(c => ({
                id: c.id,
                key: c.id,
                archived: c.archived,
                name: this.validateConfigurationName(c.name),
                code: this.validateConfigurationCode(c.code),
            })),
            additionalProducts: (isNew || !resource || !resource.additionalProducts) ? [] : resource.additionalProducts.map(p => ({ id: p.id, key: p.id, productId: this.validateAdditionalProductProduct(p.productId), minQuantity: this.validateAdditionalProductMinQty(p.minQuantity), maxQuantity: this.validateAdditionalProductMaxQty(p.maxQuantity), defaultQuantity: this.validateAdditionalProductDftQty(p.defaultQuantity), quantityUnit: this.validateAdditionalProductUnit(p.quantityUnit), sequence: this.validateAdditionalProductSequence(p.sequence) })),
            breaks: (isNew || !resource || !resource.additionalProducts) ? [] : resource.breaks.map(b => ({ id: b.id, key: b.id, text: this.validateBreakText(b.text), startTime: this.validateBreakStartTime(b.startTime), duration: this.validateBreakDuration(b.duration), days: b.days, activeFrom: this.validateBreakActiveFrom(moment(b.activeFrom)), activeTo: this.validateBreakActiveTo(moment(b.activeFrom), moment(b.activeTo)), archived: b.archived })),
            errorMessage: null
        };
    }

    componentDidUpdate(prevProps: ResourceFormProps) {
        const { resource: prevResource, saveComplete: prevSaveComplete } = prevProps;
        const { resource, saveComplete } = this.props;

        // Only update state is resource has changed
        if ((!prevResource && resource) || (prevResource && !resource) || (prevResource && resource && prevResource.id !== resource.id)) {
            this.setState(this.buildStateFromProps(this.props));
        }

        if (saveComplete && !prevSaveComplete) {
            setTimeout(() => { this.close(); }, 750);
        }
    }

    saveResource = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }

    close = () => {
        this.props.closeModal();
    }

    save = () => {
        if (!v.isValid(this.state)
            || this.state.additionalProducts.reduce((prev: boolean, ap) => (prev || !v.isValid(ap)), false)) {
            this.setState({ errorMessage: this.context.t('Global:formNotValid') });
        } else {
            const { isNew, resource, venueId, saveResource } = this.props;
            const { name, shortName, colour, sequence, arrivalTimeBeforeEvent, snapOnlineBookingsTo,
                numberOfLanes, maxLaneCapacity, allowMixedGroupsInLane, archived, taskDefinitionIds,
                configurations, additionalProducts, breaks, overlappedResourceIds } = this.state;

            if (breaks.filter(b => !b.activeFrom.isValid || !b.activeTo.isValid).length > 0) {
                this.setState({ errorMessage: this.context.t('Global:formNotValid') });
                return;
            }

            const resourceId = isNew || !resource ? null : resource.id;
            saveResource(isNew,
                resourceId,
                {
                    id: resourceId || '',
                    venueId: venueId,
                    name: name.value,
                    shortName: shortName.value,
                    colour: colour,
                    sequence: sequence.value,
                    arrivalTimeBeforeEvent: arrivalTimeBeforeEvent.value,
                    snapOnlineBookingsTo: snapOnlineBookingsTo.value,
                    numberOfLanes: numberOfLanes.value,
                    maxLaneCapacity: maxLaneCapacity.value,
                    allowMixedGroupsInLane: allowMixedGroupsInLane.value,
                    archived: archived.value,
                    overlappedResourceIds: overlappedResourceIds,
                    taskDefinitionIds: taskDefinitionIds,
                    configurations: configurations.map(c => ({
                        ...c,
                        name: c.name.value,
                        code: c.code.value,
                    })),
                    additionalProducts: additionalProducts.map(p => ({
                        id: p.id,
                        productId: p.productId.value,
                        minQuantity: p.minQuantity.value,
                        maxQuantity: p.maxQuantity.value,
                        defaultQuantity: p.defaultQuantity.value,
                        quantityUnit: p.quantityUnit.value,
                        sequence: p.sequence.value
                    })),
                    breaks: breaks.map(b => ({
                        id: b.id,
                        days: b.days,
                        text: b.text.value,
                        startTime: b.startTime.value,
                        duration: b.duration.value,
                        activeFrom: b.activeFrom.value ? b.activeFrom.value.toDate() : new Date(),
                        activeTo: b.activeTo.value ? b.activeTo.value.toDate() : new Date(),
                        archived: b.archived
                    }))
                }
            );

            this.setState({ errorMessage: null });
        }
    }

    addConfiguration = () => {
        var conf = { key: generateTempId(), id: '', name: this.validateConfigurationName(''), code: this.validateConfigurationCode(''), archived: false };
        this.setState(current => {
            return ({ configurations: current.configurations.concat(conf) })
        });
    }

    removeConfiguration = (key: string) => {
        this.setState(current => ({ configurations: current.configurations.filter(c => c.key !== key) }));
    }

    addAdditionalProduct = () => {
        this.setState(current => {
            var nextSeq = current.additionalProducts.length < 1 ? 1 : current.additionalProducts.reduce((max, p) => Math.max(max, p.sequence.value + 1), 1)
            var prod = { key: generateTempId(), id: '', productId: this.validateAdditionalProductProduct(''), minQuantity: this.validateAdditionalProductMinQty(0), maxQuantity: this.validateAdditionalProductMaxQty(1), defaultQuantity: this.validateAdditionalProductDftQty(0), quantityUnit: this.validateAdditionalProductUnit(ProductQuantityUnit.PerBooking), sequence: this.validateAdditionalProductSequence(nextSeq) };
            return ({ additionalProducts: current.additionalProducts.concat(prod) })
        });    }

    removeAdditionalProduct = (key: string) => {
        this.setState(current => ({ additionalProducts: current.additionalProducts.filter(p => p.key !== key) }));
    }

    addBreak = () => {
        this.setState(s => ({ breaks: s.breaks.concat({ key: generateTempId(), id: '', text: this.validateBreakText(''), startTime: this.validateBreakStartTime(new Time(12, 0, 0)), duration: this.validateBreakDuration(new Time(0, 20, 0)), days: Days.None, activeFrom: this.validateBreakActiveFrom(moment()), activeTo: this.validateBreakActiveTo(moment(), moment().add(1, 'M')), archived: false }) }))
    }

    productChanged = (key: string, val: string) => this.setState(prev => ({ additionalProducts: prev.additionalProducts.map(p => p.key === key ? { ...p, productId: this.validateAdditionalProductProduct(val) } : p) }));
    onProductMinQuantityChanged = (key: string, val: number) => this.setState(prev => ({ additionalProducts: prev.additionalProducts.map(p => p.key === key ? { ...p, minQuantity: this.validateAdditionalProductMinQty(val) } : p) }));
    onProductMaxQuantityChanged = (key: string, val: number) => this.setState(prev => ({ additionalProducts: prev.additionalProducts.map(p => p.key === key ? { ...p, maxQuantity: this.validateAdditionalProductMaxQty(val) } : p) }));
    onProductDefaultQuantityChanged = (key: string, val: number) => this.setState(prev => ({ additionalProducts: prev.additionalProducts.map(p => p.key === key ? { ...p, defaultQuantity: this.validateAdditionalProductDftQty(val) } : p) }));
    onProductQuantityUnitChanged = (key: string, val: string) => this.setState(prev => ({ additionalProducts: prev.additionalProducts.map(p => p.key === key ? { ...p, quantityUnit: this.validateAdditionalProductUnit(parseInt(val)) } : p) }));
    onProductSequenceChanged = (key: string, val: number) => this.setState(prev => ({ additionalProducts: prev.additionalProducts.map(p => p.key === key ? { ...p, sequence: this.validateAdditionalProductSequence(val) } : p.sequence.value >= val ? { ...p, sequence: this.validateAdditionalProductSequence(p.sequence.value + 1) } : p) }));

    validateName = (val: string) => v.validate(val, 'name', [v.required], this.props.validationErrors);
    validateShortName = (val: string) => v.validate(val, 'shortName', [v.required], this.props.validationErrors);
    validateSequence = (val: number) => v.validate(val, 'sequence', [v.numeric], this.props.validationErrors);
    validateArchived = (val: boolean) => v.validate(val, 'archived', [], this.props.validationErrors);
    validateArrivalTimeBeforeEvent = (val: Time) => v.validate(val, 'arrivalTimeBeforeEvent', [v.required], this.props.validationErrors);
    validateSnapOnlineBookingsTo = (val: number) => v.validate(val, 'snapOnlineBookingsTo', [v.numeric], this.props.validationErrors);
    validateNumberOfLanes = (val: number) => v.validate(val, 'numerOfLanes', [v.required], []);
    validateMaxPerLane = (val: number | null) => v.validate(val, 'maxPerLane', [], []);
    validateAllowMixedGroups = (val: boolean) => v.validate(val, 'allowMixedGroups', [], []);
    validateConfigurationName = (val: string) => v.validate(val, 'configurationName', [v.required], []);
    validateConfigurationCode = (val: string) => v.validate(val, 'configurationCode', [v.required], []);
    validateAdditionalProductProduct = (val: string) => v.validate(val, 'additionalProductId', [v.required, this.validateAdditionalProduct], []);
    validateAdditionalProductMinQty = (val: number) => v.validate(val, 'additionalProductMinQuantity', [], []);
    validateAdditionalProductMaxQty = (val: number) => v.validate(val, 'additionalProductMaxQuantity', [v.required], []);
    validateAdditionalProductDftQty = (val: number) => v.validate(val, 'additionalProductDefaultQuantity', [], []);
    validateAdditionalProductUnit = (val: ProductQuantityUnit) => v.validate(val, 'additionalProductQuantityUnit', [v.required], []);
    validateAdditionalProductSequence = (val: number) => v.validate(val, 'additionalProductSequence', [v.required], []);

    validateAdditionalProduct = (val: string) => this.props.products.findIndex(p => p.id === val && !p.archived) >= 0 ? undefined : 'validation:required';

    validateBreakText = (val: string) => v.validate(val, 'text', [v.required], this.props.validationErrors);
    validateBreakStartTime = (val: Time) => v.validate(val, 'startTime', [v.required], this.props.validationErrors);
    validateBreakDuration = (val: Time) => v.validate(val, 'duration', [v.required], this.props.validationErrors);
    validateBreakActiveFrom = (val: moment.Moment | null) => v.validate(val, 'activeFrom', [v.required], this.props.validationErrors);
    validateBreakActiveTo = (from: moment.Moment | null, val: moment.Moment | null) => v.validate(val, 'activeTo', [v.required, val => from && val && from >= val ? 'ResourceForm:startAfterEndValidation' : undefined], this.props.validationErrors);

    taskChanged = (taskDefinitionId: string, selected: boolean) => {

        let taskDefinitions = this.state.taskDefinitionIds.filter(t => t !== taskDefinitionId);
        if (selected) {
            taskDefinitions = taskDefinitions.concat([taskDefinitionId]);
        }

        this.setState({ taskDefinitionIds: taskDefinitions });
    }

    onConfigurationNameChanged = (key: string, name: string) => {
        this.setState(prev => ({ configurations: prev.configurations.map(c => c.key === key ? ({ ...c, name: this.validateConfigurationName(name) }) : c) }));
    }

    onConfigurationCodeChanged = (key: string, code: string) => {
        this.setState(prev => ({ configurations: prev.configurations.map(c => c.key === key ? ({ ...c, code: this.validateConfigurationCode(code) }) : c) }));
    }

    overlappedResourceSelectionChanged = (resourceId: string, selected: boolean) => {
        if (selected) {
            this.setState(s => ({ overlappedResourceIds: s.overlappedResourceIds.filter(r => r !== resourceId).concat(resourceId) }))
        } else {
            this.setState(s => ({ overlappedResourceIds: s.overlappedResourceIds.filter(r => r !== resourceId) }))
        }
    }

    onNumberOfLanesChanged = (val: number | null) => {
        const newValue = val || 1;
        this.setState(s => ({
            numberOfLanes: this.validateNumberOfLanes(newValue),
            maxLaneCapacity: newValue < 2 ? this.validateMaxPerLane(null) : s.maxLaneCapacity,
            allowMixedGroupsInLane: newValue < 2 ? this.validateAllowMixedGroups(true) : s.allowMixedGroupsInLane }));
    }

    render() {

        let message: any = null;
        const { t } = this.context;
        const { saveError, saveComplete, isNew, taskDefinitions } = this.props;
        const { name, shortName, sequence, colour, arrivalTimeBeforeEvent, snapOnlineBookingsTo,
            numberOfLanes, maxLaneCapacity, allowMixedGroupsInLane, archived, taskDefinitionIds,
            additionalProducts, overlappedResourceIds, errorMessage } = this.state;

        if (saveError) {
            message = <ApiError error={saveError} />;
        } else if (errorMessage) {
            message = (<div className='bg-danger'>{errorMessage}</div>);
        } else if (saveComplete) {
            message = (<div className='bg-success'>{t('Global:saveComplete')}</div>);
        }

        return <div className='resourceForm'>
            <h2 className='resource_title'>{isNew ? t('ResourceForm:addResource') : name.value}</h2>

            <form className='data-form' onSubmit={this.saveResource} autoComplete='off'>
                <ct.TextBox id='name' labelKey='ResourceForm:name' placeholderKey='ResourceForm:name' value={name} callback={val => this.setState({ name: this.validateName(val) })} />

                <ct.TextBox id='shortName' labelKey='ResourceForm:shortName' placeholderKey='ResourceForm:shortName' value={shortName} callback={val => this.setState({ shortName: this.validateShortName(val) })} maxLength={15} />

                {this.renderConfigurations(this.state.configurations)}

                <ct.NumberBox id='sequence' labelKey='ResourceForm:sequence' placeholderKey='ResourceForm:sequence' value={sequence} callback={val => this.setState({ sequence: this.validateSequence(val || 0) })} min='1' />

                <ct.Time id='arrivalTimeBeforeEvent' labelKey='ResourceForm:arrivalTimeBeforeEvent' value={arrivalTimeBeforeEvent} callback={val => this.setState({ arrivalTimeBeforeEvent: this.validateArrivalTimeBeforeEvent(val ? val : Time.zero()) })} style={({marginLeft:'0'})} />

                <ct.NumberBox id='snapOnlineBookingsTo' labelKey='ResourceForm:snapOnlineBookingsTo' placeholderKey='ResourceForm:snapOnlineBookingsTo' hintKey='ResourceForm:snapOnlineBookingsToHint' value={snapOnlineBookingsTo} callback={val => this.setState({ snapOnlineBookingsTo: this.validateSnapOnlineBookingsTo(val || 0) })} min='1' />

                <div style={{ display: 'flex' }}>
                    <ct.NumberBox id='numberOfLanes' labelKey='ResourceForm:numberOfLanes' placeholderKey='' value={numberOfLanes} callback={this.onNumberOfLanesChanged} min='1' style={{ maxWidth: '150px' }} />
                    {numberOfLanes.value > 1
                        ? <>
                            <ct.NumberBox id='maxLaneCapacity' labelKey='ResourceForm:maxLaneCapacity' placeholderKey='' value={maxLaneCapacity} callback={val => this.setState({ maxLaneCapacity: this.validateMaxPerLane(val) })} disabled={numberOfLanes.value < 2} style={{ maxWidth: '150px', marginLeft: '10px' }} />
                            <ct.Checkbox id='allowMixedGroupsInLane' labelKey='ResourceForm:allowMixedGroupsInLane' placeholderKey='' value={allowMixedGroupsInLane} callback={val => this.setState({ allowMixedGroupsInLane: this.validateAllowMixedGroups(val) })} disabled={numberOfLanes.value < 2} style={{ marginTop: '25px', marginLeft: '10px' }} />
                        </>
                        : null}
                </div>

                {this.renderOverlappedResources(overlappedResourceIds) }

                {this.renderAdditionalProducts(additionalProducts)}

                {this.renderBreaks(this.state.breaks)}

                <ct.Colours colours={colours} selectedColour={colour} colourChanged={c => this.setState({ colour: c })} />
                               
                <ct.Checkbox id='archived' labelKey='Global:archive' value={archived} callback={val => this.setState({ archived: this.validateArchived(val) })} />

                {this.renderTasks(taskDefinitions, taskDefinitionIds)}

                {message}

                <p />
                <div className='btn-toolbar'>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)}>{t('Global:save')}</button>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{t('Global:cancel')}</button>
                </div>
            </form>
        </div>;
    }

    renderConfigurations = (configurations: ResourceConfig[]) => {
        const { t } = this.context;
        const tableTextboxStyle = ({ minHeight: '10px' });
        const deleteStyle = ({ marginTop: '8px', marginLeft: '10px', color: '#a94442' });
        const tbl = configurations.length > 0 ? (
            <table>
                <thead>
                    <tr>
                        <th>{t('ResourceForm:configNameHeading')}</th>
                        <th>{t('ResourceForm:configCodeHeading')}</th>
                    </tr>
                </thead>
                <tbody>
                    {configurations.map(c => <tr>
                        <td style={({ verticalAlign: 'top' })}><ct.TextBox id={`${c.key}_name`} labelKey='' placeholderKey='ResourceForm:configNameLabel' value={c.name} callback={val => this.onConfigurationNameChanged(c.key, val)} style={tableTextboxStyle} /></td>
                        <td style={({ verticalAlign: 'top' })}><ct.TextBox id={`${c.key}_code`} labelKey='' placeholderKey='ResourceForm:configCodeLabel' value={c.code} callback={val => this.onConfigurationCodeChanged(c.key, val)} style={tableTextboxStyle} /></td>
                        <td style={({ verticalAlign: 'top' })}><span onClick={e => clickHandler(e, () => this.removeConfiguration(c.key))} className='glyphicon glyphicon-trash' style={deleteStyle}></span></td>
                    </tr>)}
                </tbody>
            </table>
        ) : null;

        return (<div className=''>
            <label>{t('ResourceForm:configurations')}</label>
            {tbl}
            <div><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, this.addConfiguration)}>{t('ResourceForm:addConfiguration')}</button></div>
        </div>);
    }

    renderOverlappedResources = (overlappedResourceIds: string[]) => {
        const { t } = this.context;
        const { resource, allResources, venueId } = this.props;

        const resourceId = resource ? resource.id : '';
        const selectableResources = allResources.filter(r => r.venueId === venueId && r.id !== resourceId);

        return <div className='mb-15'>
            <label>{t('ResourceForm:overlappedResources')}</label>
            <ul className='list-unstyled'>
                {
                    selectableResources.map(res => {
                        const selected = overlappedResourceIds.includes(res.id);

                        return <li key={res.id}>
                            <input type='checkbox' id={res.id} checked={selected} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.overlappedResourceSelectionChanged(res.id, e.currentTarget.checked)} />
                            <label style={this.buildResourceStyle(res)} htmlFor={res.id}>{res.name}</label>
                        </li>
                    })
                }
            </ul>
        </div>
    }

    buildResourceStyle(resource: Resource) {
        return { marginLeft: '5px', paddingLeft: '3px', borderLeft: `4px solid ${resource.colour}` };
    }

    renderAdditionalProducts = (additionalProducts: AdditionalProduct[]) => {
        const { t } = this.context;
        const { products, venueId } = this.props;

        const activeVeneProducts = products.filter(p => p.venueId === venueId && !p.archived);

        const controlStyle = ({ minHeight: '10px' });
        const tableTextboxStyle = ({ ...controlStyle, maxWidth: '95px' });
        const deleteStyle = ({ marginTop: '8px', marginLeft: '10px', color: '#a94442' });
        const productOptions = [{ key: '', name: t('ResourceForm:selectProduct') }].concat(activeVeneProducts.map(p => ({ key: p.id, name: p.name  })));
        const unitOptions = [{ key: ProductQuantityUnit.PerBooking.toString(), name: t('ProductQuantityUnit:PerBooking') }, { key: ProductQuantityUnit.PerParticipant.toString(), name: t('ProductQuantityUnit:PerParticipant')}];
        const tbl = additionalProducts.length > 0 ? (
            <table>
                <thead>
                    <tr>
                        <th>{t('ResourceForm:additionalProductsProduct')}</th>
                        <th>{t('ResourceForm:additionalProductsMin')}</th>
                        <th>{t('ResourceForm:additionalProductsMax')}</th>
                        <th>{t('ResourceForm:additionalProductsDefault')}</th>
                        <th>{t('ResourceForm:additionalProductsUnit')}</th>
                        <th>{t('ResourceForm:sequence')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {additionalProducts.map(p => <tr>
                        <td style={({ verticalAlign: 'top' })}><ct.Select id={`${p.key}_product`} labelKey='' value={p.productId} callback={val => this.productChanged(p.key, val)} options={productOptions} style={controlStyle} /></td>
                        <td style={({ verticalAlign: 'top' })}><ct.NumberBox id={`${p.key}_minQuantity`} labelKey='' placeholderKey='' value={p.minQuantity} callback={val => this.onProductMinQuantityChanged(p.key, val || 0)} style={tableTextboxStyle} min='0' /></td>
                        <td style={({ verticalAlign: 'top' })}><ct.NumberBox id={`${p.key}_maxQuantity`} labelKey='' placeholderKey='' value={p.maxQuantity} callback={val => this.onProductMaxQuantityChanged(p.key, val || 1)} style={tableTextboxStyle} min='1' /></td>
                        <td style={({ verticalAlign: 'top' })}><ct.NumberBox id={`${p.key}_defaultQuantity`} labelKey='' placeholderKey='' value={p.defaultQuantity} callback={val => this.onProductDefaultQuantityChanged(p.key, val || 0)} style={tableTextboxStyle} min='0' /></td>
                        <td style={({ verticalAlign: 'top' })}><ct.Select id={`${p.key}_unit`} labelKey='' value={({ ...p.quantityUnit, value: p.quantityUnit.value.toString() })} callback={val => this.onProductQuantityUnitChanged(p.key, val)} options={unitOptions} style={controlStyle} /></td>
                        <td style={({ verticalAlign: 'top' })}><ct.NumberBox id={`${p.key}_sequence`} labelKey='' placeholderKey='' value={p.sequence} callback={val => this.onProductSequenceChanged(p.key, val || 1)} style={tableTextboxStyle} min='1' /></td>
                        <td style={({ verticalAlign: 'top' })}><span className='glyphicon glyphicon-trash' onClick={e => clickHandler(e, () => this.removeAdditionalProduct(p.key))} style={deleteStyle}></span></td>
                    </tr>)}
                </tbody>
            </table>
        ) : null;

        return (<div className='mb-15'>
            <label>{t('ResourceForm:additionalProducts')}</label>
            {tbl}
            <div><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, this.addAdditionalProduct)}>{t('ResourceForm:addProducts')}</button></div>
        </div>);
    }

    renderBreaks = (breaks: Break[]) => {
        const { t } = this.context;

        const controlStyle = ({ minHeight: '10px', marginRight: '4px' });
        const deleteStyle = ({ marginTop: '8px', marginLeft: '10px', color: '#a94442' });

        const onValueChanged = (key: string, update: (b: Break) => Break) => this.setState(s => ({ breaks: s.breaks.map(b => b.key === key ? update(b) : b) }))
        const onTextChanged = (key: string, text: string) => onValueChanged(key, b => ({ ...b, text: this.validateBreakText(text) }))
        const onDayChanged = (key: string, days: Days, enabled: boolean) => onValueChanged(key, b => ({ ...b, days: enabled ? b.days | days : b.days & ~days }))
        const onStartTimeChanged = (key: string, time: Time) => onValueChanged(key, b => ({ ...b, startTime: this.validateBreakStartTime(time) }))
        const onDurationChanged = (key: string, duration: Time) => onValueChanged(key, b => ({ ...b, duration: this.validateBreakDuration(duration) }))
        const onActiveFromChanged = (key: string, start: moment.Moment | null) => onValueChanged(key, b => ({ ...b, activeFrom: this.validateBreakActiveFrom(start) }))
        const onActiveToChanged = (key: string, to: moment.Moment | null) => onValueChanged(key, b => ({ ...b, activeTo: this.validateBreakActiveTo(b.activeFrom.value, to) }))
        const archive = (key: string) => onValueChanged(key, b => ({ ...b, archived: true }))

        const activeBreaks = breaks.filter(b => !b.archived);
        const tbl = activeBreaks.length > 0 ? (
            <table>
                <thead>
                    <tr>
                        <th>{t('ResourceForm:breakTextHeader')}</th>
                        <th>{t('Global:Monday2ch')}</th>
                        <th>{t('Global:Tuesday2ch')}</th>
                        <th>{t('Global:Wednesday2ch')}</th>
                        <th>{t('Global:Thursday2ch')}</th>
                        <th>{t('Global:Friday2ch')}</th>
                        <th>{t('Global:Saturday2ch')}</th>
                        <th>{t('Global:Sunday2ch')}</th>
                        <th>{t('ResourceForm:breakStartTimeHeader')}</th>
                        <th>{t('ResourceForm:breakDurationHeader')}</th>
                        <th>{t('ResourceForm:breakActiveFromHeader')}</th>
                        <th>{t('ResourceForm:breakActiveToHeader')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {activeBreaks.map(b => <tr>
                        <td style={({ verticalAlign: 'top' })}><ct.TextBox id={`${b.key}_text`} labelKey='' value={b.text} callback={val => onTextChanged(b.key, val)} style={controlStyle} /></td>
                        <td style={({ verticalAlign: 'top' })}><ct.Checkbox id={`${b.key}_monday`} labelKey='' placeholderKey='' value={ct.asFormValue(`${b.key}_monday`, (b.days & Days.Monday) == Days.Monday)} callback={val => onDayChanged(b.key, Days.Monday, val)} style={controlStyle} /></td>
                        <td style={({ verticalAlign: 'top' })}><ct.Checkbox id={`${b.key}_tuesday`} labelKey='' placeholderKey='' value={ct.asFormValue(`${b.key}_tuesday`, (b.days & Days.Tuesday) == Days.Tuesday)} callback={val => onDayChanged(b.key, Days.Tuesday, val)} style={controlStyle} /></td>
                        <td style={({ verticalAlign: 'top' })}><ct.Checkbox id={`${b.key}_wednesday`} labelKey='' placeholderKey='' value={ct.asFormValue(`${b.key}_wednesday`, (b.days & Days.Wednesday) == Days.Wednesday)} callback={val => onDayChanged(b.key, Days.Wednesday, val)} style={controlStyle} /></td>
                        <td style={({ verticalAlign: 'top' })}><ct.Checkbox id={`${b.key}_thursday`} labelKey='' placeholderKey='' value={ct.asFormValue(`${b.key}_thursday`, (b.days & Days.Thursday) == Days.Thursday)} callback={val => onDayChanged(b.key, Days.Thursday, val)} style={controlStyle} /></td>
                        <td style={({ verticalAlign: 'top' })}><ct.Checkbox id={`${b.key}_friday`} labelKey='' placeholderKey='' value={ct.asFormValue(`${b.key}_friday`, (b.days & Days.Friday) == Days.Friday)} callback={val => onDayChanged(b.key, Days.Friday, val)} style={controlStyle} /></td>
                        <td style={({ verticalAlign: 'top' })}><ct.Checkbox id={`${b.key}_saturday`} labelKey='' placeholderKey='' value={ct.asFormValue(`${b.key}_saturday`, (b.days & Days.Saturday) == Days.Saturday)} callback={val => onDayChanged(b.key, Days.Saturday, val)} style={controlStyle} /></td>
                        <td style={({ verticalAlign: 'top' })}><ct.Checkbox id={`${b.key}_sunday`} labelKey='' placeholderKey='' value={ct.asFormValue(`${b.key}_sunday`, (b.days & Days.Sunday) == Days.Sunday)} callback={val => onDayChanged(b.key, Days.Sunday, val)} style={controlStyle} /></td>

                        <td style={({ verticalAlign: 'top' })}><ct.Time id={`${b.key}_startTime`} labelKey='' value={b.startTime} callback={val => onStartTimeChanged(b.key, val || new Time(9, 0, 0))} style={controlStyle} /></td>
                        <td style={({ verticalAlign: 'top' })}><ct.Time id={`${b.key}_duration`} labelKey='' value={b.duration} callback={val => onDurationChanged(b.key, val || new Time(0, 20, 0))} style={controlStyle} /></td>
                        <td style={({ verticalAlign: 'top' })}><ct.DatePicker id={`${b.key}_activeFrom`} labelKey='' value={b.activeFrom} callback={val => onActiveFromChanged(b.key, val)} style={controlStyle} /></td>
                        <td style={({ verticalAlign: 'top' })}><ct.DatePicker id={`${b.key}_activeTo`} labelKey='' value={b.activeTo} callback={val => onActiveToChanged(b.key, val)} style={controlStyle} /></td>
                        <td style={({ verticalAlign: 'top' })}><span className='glyphicon glyphicon-trash' onClick={e => clickHandler(e, () => archive(b.key))} style={deleteStyle}></span></td>
                    </tr>)}
                </tbody>
            </table>
        ) : null;

        return (<div className='mb-15'>
            <label>{t('ResourceForm:breaks')}</label>
            {tbl}
            <div><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, this.addBreak)}>{t('ResourceForm:addBreak')}</button></div>
        </div>);
    }

    renderTasks(taskDefinitions: TaskDefinition[], taskDefinitionIds: string[]) {
        const { t } = this.context;

        return (
            <div className='form-group'>
                <label>{this.context.t('ResourceForm:tasks')}</label>
                <ul className='list-unstyled'>
                    {taskDefinitions.map(td => (
                        <li key={td.id}>
                            <input type='checkbox' id={td.id} checked={taskDefinitionIds.indexOf(td.id) >= 0} onChange={e => this.taskChanged(td.id, e.currentTarget.checked)} />
                            <label style={this.buildTaskStyle()} htmlFor={td.id}>{td.name} - {t(`TaskTrigger:${TaskTrigger[td.trigger]}`)} - {t(`TaskEmailRecipient:${TaskEmailRecipient[td.emailRecipients]}`)}</label>
                        </li>))}
                </ul>
            </div>
        );
    }

    buildTaskStyle() {
        return { marginLeft: '5px', paddingLeft: '3px' };
    }
};


const mapStateToProps = (state: ApplicationState) => ({
    saveComplete: state.resources.saveComplete,
    saveError: state.resources.saveError,
    validationErrors: state.resources.validationErrors,
    isTaskDefinitionsLoading: state.tasks.isLoading,
    taskDefinitions: state.tasks.taskDefinitions,
    products: state.products.products,
    allResources: state.resources.resources,
    isProductsLoading: state.products.isLoading
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
    saveResource: bindActionCreators(ra.actionCreators.saveResource, dispatch),
    loadTaskDefinitions: bindActionCreators(ta.actionCreators.loadTaskDefinitions, dispatch),
    loadProducts: bindActionCreators(pa.actionCreators.loadProducts, dispatch),
});


// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(ResourceForm);
