
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { ITranslationContext } from '../../../translations';
import { BillRefund } from '../../../store/pages/pointOfSale/types';
import { clickHandler } from '../../../utils/util';
import { CalculateRefundLabelState, CalculateRefundState, CombinedRefundState } from './types';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';

interface BillItemsRefundRowProps {
    refund: BillRefund;
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
    editRefund: (item: BillRefund) => void;
}

const BillItemsRefundRow = (props: BillItemsRefundRowProps, context: ITranslationContext) => {
    const { refund, timeFormat, dateFormat, editRefund } = props;
    const { t } = context;

    const amountStyle: React.CSSProperties = refund.void ? { color: '#bbb' } : {};

    const refundState = CalculateRefundState(refund.gatewayRefundAttempts);
    const refundLabelState = CalculateRefundLabelState(refundState);

    return <li key={refund.id} className={`pos_bill_refund ${refund.void ? ' bg-danger' : 'bg-warning'}`}>
        <button className="btn btn-link btn-no-padding full-width" onClick={e => clickHandler(e, () => editRefund(refund))}>
            <div className='bill-payment-item'>
                <div className='bill-payment-item-details'>
                    <span>{refund.whenRefunded.toAbbrDateString(dateFormat, t)} {refund.whenRefunded.toShortTimeString(timeFormat)}</span>
                    <span className='bill-payment-item-name'>{refund.reason}</span>
                    {refund.void ? <div className='text-danger'><span className='label label-danger'>{t('PointOfSale:paymentVoid')}</span> <strong>{refund.voidReason}</strong></div> : null}
                    <div><span className={`label label-${refundLabelState}`}>{t(`CombinedRefundState:${CombinedRefundState[refundState]}`)}</span></div>
                </div>
                <div className='bill-payment-item-price'>
                    <span className='bill_item_price' style={amountStyle}>{`${t('Global:currencySymbol')}${refund.amount.toFixed(2)}`}</span>
                </div>
            </div>
            <div className='text-left'>{refund.paymentMethodName} {t('Global:by')} {refund.refundedBy}</div>
        </button>
    </li>
}

BillItemsRefundRow.contextTypes = {
    t: PropTypes.func
}

export default BillItemsRefundRow;