
import { Action } from 'redux';
import { LocationChangeAction } from 'react-router-redux';

import { UserActionTypes, UserState } from './types';
import { UserActions } from './actions'
import { createReducer } from '../../reducer';


const unloadedState: UserState = { isActive: false, isLoading: false, users: [], loadError: null, isSaving: false, saveComplete: false, saveError: null, validationErrors: []};

export const reducer = (state: UserState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: UserState, incomingAction: Action): UserState => {

    if (incomingAction.type === '@@router/LOCATION_CHANGE') {
        const locationChangeAction = incomingAction as LocationChangeAction;
        if (locationChangeAction) {
            return { ...state, isActive: locationChangeAction.payload.pathname === '/users' };
        }
    }

    const action = incomingAction as UserActions;
    switch (action.type) {
    case UserActionTypes.RequestUsers:
        return {
            ...state,
            isLoading: true,
            users: state.users,
            loadError: null
        };
    case UserActionTypes.ReceivedUsers:
        return {
            ...state,
            isLoading: false,
            users: action.users,
            loadError: action.error
        };
    case UserActionTypes.EditUser:
        return {
            ...state,
            isSaving: false,
            saveError: null,
            saveComplete: false,
            validationErrors: []
        };
    case UserActionTypes.SaveUser:
        return {
            ...state,
            isSaving: true,
            saveError: null,
            saveComplete: false,
            validationErrors: []
        };
    case UserActionTypes.UserSaved:
        return {
            ...state,
            isSaving: false,
            saveComplete: true,
            saveError: null,
            validationErrors: []
        };
    case UserActionTypes.UserSaveFailed:
        return {
            ...state,
            isSaving: false,
            saveError: action.error,
            saveComplete: false,
            validationErrors: action.error && action.error.validationErrors ? action.error.validationErrors : []
        };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

