import { Days, Time, ValidationError } from '../../global/types';
import * as api from '../../apiClient';
import { Tag } from '../tags/types';

export enum PromotionCriteriaType {
    None = 0,
    Participant = 1,
    Activity = 2,
}

export enum PromotionItemType {
    None = 0,
    OverallBillAmount = 1,
    Participant = 2,
    Activity = 3,
    Item = 4
}

export enum PromotionDiscountType {
    None = 0,
    Percentage = 1,
    Fixed = 2
}

export enum PromotionStatus {
    Pending = 1,
    Active = 2,
    Expired = 3,
    BudgetReached = 4
}

export enum PromotionCustomerRestriction {
    None = 0,
    BookingIntheLast = 1,
    ParticipatedInTheLast = 2,
    Tag = 3
}

export enum PromotionCustomerRestrictionPeriod {
    Day = 1,
    Month = 2,
    Year = 3
}

export interface PromotionTimeRestriction {
    days: Days;
    from: Time;
    to: Time;
}

export interface Promotion {
    id: string;
    venueId: string;
    name: string;
    code: string;
    state: PromotionStatus;
    availableForWebBookings: boolean;
    startDate: Date;
    endDate: Date | null;
    validForEventsFrom: Date | null;
    validForEventsUntil: Date | null;
    maximumBudget: number | null;
    totalAmountRedeemed: number;
    criteriaType: PromotionCriteriaType;
    criteriaQuantity: number;
    discountItemType: PromotionItemType;
    quantityToDiscount: number;
    maxQuantityToDiscount: number;
    maxNumberOfTimesToApply: number;
    discountType: PromotionDiscountType;
    discount: number;
    accountingDepartment: string;
    accountingCategory: string;
    nominalCode: string;
    triggerTags: Tag[];
    itemTags: Tag[];
    itemRestrictionTags: Tag[];
    timeRestrictions: PromotionTimeRestriction[];
    maxRedemptionsPerCustomer: number | null;
    customerRestriction: PromotionCustomerRestriction;
    customerRestrictionPeriod: PromotionCustomerRestrictionPeriod;
    customerRestrictionPeriodValue: number | null;
    customerRestrictionTagId: string | null;
    waiveBookingFee: boolean;
}

export interface PromotionState {
    isLoading: boolean;
    promotions: Promotion[];
    loadError: api.ApiError | null;
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
}

export enum PromotionActionTypes {
    RequestPromotions = 'REQUEST_PROMOTIONS',
    ReceivedPromotions = 'RECEIVE_PROMOTIONS',
    SavePromotion = 'SAVE_PROMOTION',
    PromotionSaved = 'PROMOTION_SAVED',
    PromotionSaveFailed = 'PROMOTION_SAVE_FAILED',
    EditPromotion = 'EDIT_PROMOTION'
}

export interface LoadPromotions {
    type: PromotionActionTypes.RequestPromotions;
}

export interface ReceivePromotions {
    type: PromotionActionTypes.ReceivedPromotions;
    promotions: Promotion[];
    error: api.ApiError | null;
}

export interface SavePromotion {
    type: PromotionActionTypes.SavePromotion;
    isNew: boolean;
    promotionId?: string;
    promotion: Promotion;
}

export interface PromotionSaved {
    type: PromotionActionTypes.PromotionSaved;
    promotionId: string;
}

export interface PromotionSaveFailed {
    type: PromotionActionTypes.PromotionSaveFailed;
    error: api.ApiError;
}

export interface EditPromotion {
    type: PromotionActionTypes.EditPromotion
}
