

import * as React from 'react';
import * as PropTypes from 'prop-types'

import MultiImageViewer from './../../global/multiImageViewer';
import { clickHandler } from '../../../utils/util';

interface RegistrationStartPanelProps {
    welcomeText: string;
    images: string[];
    imageDelayInMiliseconds: number;
    startRegistration: () => void;
}

class RegistrationStartPanel extends React.Component<RegistrationStartPanelProps, {}> {
    
    static contextTypes = {
        t: PropTypes.func
    }

    render() {
        const { t } = this.context;
        const { images, imageDelayInMiliseconds, welcomeText, startRegistration } = this.props;

        const imageViewers = this.props.images && images.length > 0
            ? <MultiImageViewer images={images} delayInMiliseconds={imageDelayInMiliseconds} />
            : null;

        return <div className='registration-form'>
            <div dangerouslySetInnerHTML={{ __html: welcomeText }}></div>
            <p />
            <div className="row">
                <div className="col-xs-3"></div>
                <div className="col-xs-6">
                    <button type='button' className='btn btn-primary full-width' onClick={e => clickHandler(e, startRegistration)}>{t('Registration:startRegistration')}</button>
                </div>
                <div className="col-xs-3"></div>
            </div>
            <p />
            {imageViewers}
            </div>;
    }
}

export default RegistrationStartPanel;