
import { Action } from 'redux';

import { LeaderboardActionTypes, LeaderboardState } from './types';
import { LeaderboardActions } from './actions'
import { createReducer } from '../../reducer';
import { stat } from 'node:fs/promises';


const unloadedState: LeaderboardState = { isLoading: false, leaderboards: [], loadError: null, isSaving: false, saveComplete: false, saveError: null, validationErrors: [], timingSettings: { lastUpdate: new Date(), settings: [] }, loadingTimingSettings: false };

export const reducer = (state: LeaderboardState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: LeaderboardState, incomingAction: Action): LeaderboardState => {

    const action = incomingAction as LeaderboardActions;
    switch (action.type) {
        case LeaderboardActionTypes.RequestLeaderboards:
            return {
                ...state,
                isLoading: true,
                leaderboards: state.leaderboards,
                loadError: null
            };
        case LeaderboardActionTypes.ReceivedLeaderboards:
            const ordered = action.leaderboards ? action.leaderboards : [];
            ordered.sort((a, b) => a.name.localeCompare(b.name));

            return {
                ...state,
                isLoading: false,
                leaderboards: ordered,
                loadError: action.error
            };
        case LeaderboardActionTypes.EditLeaderboard:
            return {
                ...state,
                isSaving: false,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case LeaderboardActionTypes.SaveLeaderboard:
            return {
                ...state,
                isSaving: true,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case LeaderboardActionTypes.LeaderboardSaved:
            return {
                ...state,
                isSaving: false,
                saveComplete: true,
                saveError: null,
                validationErrors: []
            };
        case LeaderboardActionTypes.LeaderboardSaveFailed:
            return {
                ...state,
                isSaving: false,
                saveError: action.error,
                saveComplete: false,
                validationErrors: action.error && action.error.validationErrors ? action.error.validationErrors : []
            };
        case LeaderboardActionTypes.RequestTimingSettings:
            return {
                ...state,
                loadingTimingSettings: true
            };
        case LeaderboardActionTypes.ReceivedTimingSettings:
            return {
                ...state,
                loadingTimingSettings: false,
                timingSettings: { lastUpdate: new Date(), settings: action.settings },
            }
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

