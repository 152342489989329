
import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'

import * as api from '../../../store/apiClient';
import { ApplicationState } from '../../../store';
import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import * as ModalActions from '../../../store/global/modal/actions';
import * as ReportTypeActions from '../../../store/pages/reportTypes/actions';
import * as ReportDefinitionActions from '../../../store/pages/reportDefinitions/actions';
import * as LoginActions from '../../../store/pages/login/actions';
import { ValidationError } from '../../../store/global/types';
import { clickHandler, isNullOrEmpty } from '../../../utils/util';
import ApiError from '../../global/apiError';
import { ReportDefinition, ReportDefinitionColumn, ReportFilterType, FilterOption, ReportDefinitionFilter, SortDirection, ReportDefinitionPermission, ReportPermission } from '../../../store/pages/reportDefinitions/types';
import { ReportType, ReportTypes, ReportDataElement } from '../../../store/pages/reportTypes/types';
import ReportColumnSettings from './reportColumnSettings';
import Loading from '../../global/loading';
import { stringComparer } from '../../../utils/comparers';

interface GetFilterOptionsResponse {
    filterDefaults: ReportFilter[];
}

interface ReportColumn {
    order: number;
    dataElementKey: string;
    heading: string;
    total: boolean;
    sortOrder: number | null;
    sortDirection: SortDirection;
    summarize: boolean;
}

interface ReportFilter {
    name: string;
    type: ReportFilterType;
    defaultOptions: string[];
    options: FilterOption[];
    selectable: boolean;
    required: boolean;
}

interface LocalProps {
}

interface ReportDefinitionFormRouteProps {
    reportDefinitionId: string;
}

type RoutedLocalProps = LocalProps & RouteComponentProps<ReportDefinitionFormRouteProps>;

interface MappedReduxState {
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
    reportTypes: ReportType[];
    isLoadingReportTypes: boolean;
    reportDefinitions: ReportDefinition[];
    isLoadingReportDefinitions: boolean;
}

interface Actions {
    loadReportTypes: () => void;
    loadReportDefinitions: () => void;
    editReportDefinition: () => void;
    saveReportDefinition: (isNew: boolean, reportDefinitionId: string | null, name: string, reportType: ReportTypes, description: string, countHeading: string, columns: ReportDefinitionColumn[], filters: ReportDefinitionFilter[], permissions: ReportDefinitionPermission[], archived: boolean) => void;
    logout: () => void;
}

type ReportDefinitionFormProps = MappedReduxState & Actions & RoutedLocalProps;

interface ReportDefinitionFormState {
    isNew: boolean;
    isLoading: boolean;
    reportDefinition: ReportDefinition | null;
    name: ct.FormValue<string>;
    description: ct.FormValue<string>;
    countHeading: ct.FormValue<string>;
    reportType: ct.FormValue<ReportTypes>;
    archived: ct.FormValue<boolean>;
    reportTypeConfirmed: boolean;
    columns: ReportColumn[];
    filters: ReportFilter[];
    permissions: ReportDefinitionPermission[];
    validationMessage: string | null;
    copyFromReportId: string;
}

class ReportDefinitionForm extends React.Component<ReportDefinitionFormProps, ReportDefinitionFormState> {

    constructor(props: ReportDefinitionFormProps) {
        super(props);

        this.state = this.buildStateFromProps(this.props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps(props: ReportDefinitionFormProps): ReportDefinitionFormState {
        const isNew = props.location.pathname.endsWith('/add');
        const rd = isNew || !props.reportDefinitions ? null : props.reportDefinitions.find(x => x.id === props.match.params.reportDefinitionId);
        const reportDefinition = !isNew && rd ? rd : null;


        const selectedReportType = props.reportTypes.find(t => reportDefinition && t.reportType === reportDefinition.reportType);
        const dataElements = selectedReportType ? selectedReportType.dataElements : [];

        return {
            isNew: isNew,
            isLoading: !isNew && reportDefinition !== null,
            reportDefinition: reportDefinition,
            name: this.validateName((isNew || !reportDefinition) ? '' : reportDefinition.name),
            reportType: this.validateReportType((isNew || !reportDefinition) ? ReportTypes.Payments : reportDefinition.reportType),
            description: this.validateDescription((isNew || !reportDefinition) ? '' : reportDefinition.description),
            countHeading: this.validateCountHeading((isNew || !reportDefinition) ? '' : reportDefinition.countHeading),
            archived: this.validateArchived(!isNew && reportDefinition && reportDefinition.archived ? true : false),
            reportTypeConfirmed: !isNew,
            columns: (isNew || !reportDefinition)
                ? [this.createNewColumn(0)]
                : reportDefinition.columns
                    .filter(c => dataElements.findIndex(e => e.key === c.dataElementKey) >= 0)
                    .map(c => ({ order: c.order, dataElementKey: c.dataElementKey, heading: c.heading, total: c.total, sortOrder: c.sortOrder, sortDirection: c.sortDirection, summarize: c.summarize })),
            filters: [],
            permissions: reportDefinition ? reportDefinition.permissions : [],
            validationMessage: null,
            copyFromReportId: ''
        };
    }

    mapFilters = (reportFilters: ReportFilter[], reportDefinition: ReportDefinition) => {
        return reportFilters.map(f => {
            const definitionFilter = reportDefinition.filters.find(df => df.key === f.name);
            const options = f.options.map(o => {
                const reportSelection = definitionFilter && definitionFilter.defaultOptions.includes(o.key);
                return { ...o, selected: reportSelection ? reportSelection : o.selected }
            });
            return {
                name: f.name,
                type: f.type,
                defaultOptions: definitionFilter ? definitionFilter.defaultOptions : [],
                options: options,
                selectable: definitionFilter ? definitionFilter.selectable : false,
                required: f.required
            }
        });
    }

    componentDidMount() {
        const { editReportDefinition } = this.props;

        editReportDefinition();

        const { isNew, reportType } = this.state;
        if (!this.state.isNew) {
            if (!isNew && reportType) {
                this.loadFilterOptions(reportType.value);
            }
        }
    }

    componentDidUpdate(prevProps: ReportDefinitionFormProps) {
        const { isNew, reportDefinition } = this.state;
        const { reportDefinitions, reportTypes, isLoadingReportDefinitions, saveComplete } = this.props;

        if (saveComplete && !prevProps.saveComplete) {
            setTimeout(() => { this.close(); }, 750);
        } else {
            if (!isNew && !reportDefinition && !isLoadingReportDefinitions && prevProps.isLoadingReportDefinitions && reportDefinitions && reportDefinitions.length > 0) {
                this.buildStateFromProps(this.props);
            }

            if (prevProps.reportTypes.length !== reportTypes.length && reportDefinition) {
                this.setState(s => ({ filters: this.mapFilters(s.filters, reportDefinition) }))
            }
        }
    }

    saveReportDefinition = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }

    close = () => {
        this.props.history.replace('/reports');
    }

    confirmReportType = () => {
        const { name, reportType } = this.state;
        if (!name.isValid || !reportType.isValid) {
            return;
        }

        this.setState((s, props) => {
            const copyFrom = props.reportDefinitions.find(d => d.id === s.copyFromReportId && d.reportType === s.reportType.value);
            if (copyFrom) {
                const selectedReportType = props.reportTypes.find(t => copyFrom && t.reportType === copyFrom.reportType);
                const dataElements = selectedReportType ? selectedReportType.dataElements : [];
                return {
                    reportTypeConfirmed: true,
                    countHeading: this.validateCountHeading(copyFrom.countHeading),
                    archived: this.validateArchived(false),
                    columns: copyFrom.columns
                            .filter(c => dataElements.findIndex(e => e.key === c.dataElementKey) >= 0)
                            .map(c => ({ order: c.order, dataElementKey: c.dataElementKey, heading: c.heading, total: c.total, sortOrder: c.sortOrder, sortDirection: c.sortDirection, summarize: c.summarize })),
                    //filters: [],
                }
            } else {
                return {
                    reportTypeConfirmed: true,
                    countHeading: s.countHeading,
                    archived: this.validateArchived(false),
                    columns: s.columns
                }
            }
        }, () => this.loadFilterOptions(reportType.value));

        this.loadFilterOptions(reportType.value);
    }

    loadFilterOptions = (reportType: ReportTypes) => {
        const url = `api/v1/report/${reportType}/filterOptions`;
        api.getWithAuth<GetFilterOptionsResponse>(url, this.props.logout)
            .subscribe(
                resp => {
                    this.setState((s, props) => {
                        const { reportDefinitions, match } = props;
                        const reportDefnId = !reportDefinitions ? null : s.isNew ? s.copyFromReportId : match.params.reportDefinitionId;
                        const rd = reportDefnId ? reportDefinitions.find(x => x.id === reportDefnId) : null;
                        return { filters: rd ? this.mapFilters(resp.filterDefaults, rd) : resp.filterDefaults, isLoading: false }
                    });
                },
                e => {
                    this.setState({ filters: [], isLoading: false});
                }
            );
    }

    save = () => {
        if (!v.isValid(this.state)) {
            this.setState({ validationMessage: 'Global:formNotValid' });
        } else {
            const { isNew, reportDefinition, name, reportType, description, countHeading, columns, filters, permissions, archived } = this.state;
            const { saveReportDefinition } = this.props;

            const validColumns = columns.filter(c => !isNullOrEmpty(c.dataElementKey) && !isNullOrEmpty(c.heading));

            if (validColumns.length === 0) {
                this.setState({ validationMessage: 'ReportDefinitionForm:noColumnsSelected' });
            } else {
                const reportDefinitionId = isNew || !reportDefinition ? null : reportDefinition.id;

                saveReportDefinition(
                    isNew,
                    reportDefinitionId,
                    name.value,
                    reportType.value,
                    description.value,
                    countHeading.value,
                    validColumns
                        .map(c => ({
                            dataElementKey: c.dataElementKey,
                            order: c.order,
                            heading: c.heading,
                            total: c.total,
                            sortOrder: c.sortOrder,
                            sortDirection: c.sortDirection,
                            summarize: c.summarize
                        })),
                    filters.map(f => ({ key: f.name, selectable: f.selectable, defaultOptions: f.defaultOptions })),
                    permissions,
                    archived.value
                );
            }
        }
    }

    reportTypeChanged = (newValue: string) => this.setState({ reportType: this.validateReportType(parseInt(newValue)), copyFromReportId: '' });

    validateName = (val: string) => v.validate(val, 'name', [v.required], this.props.validationErrors);
    validateDescription = (val: string) => v.validate(val, 'description', [v.required], this.props.validationErrors);
    validateCountHeading = (val: string) => v.validate(val, 'countHeading', [], this.props.validationErrors);
    validateReportType = (val: number) => v.validate(val, 'type', [], this.props.validationErrors);
    validateArchived = (val: boolean) => v.validate(val, 'archived', [], this.props.validationErrors);

    columnChanged = (index: number, dataElementKey: string, heading: string, total: boolean, sortOrder: number | null, sortDirection: SortDirection, summarize: boolean) =>
        this.setState(prev => ({ columns: prev.columns.map(c => c.order === index ? { ...c, dataElementKey: dataElementKey, heading: heading, total: total, sortOrder: sortOrder, sortDirection: sortDirection, summarize: summarize } : c) }));

    updateFilterSelectable = (name: string, checked: boolean) =>
        this.setState(prev => {
            const mappedFilters = prev.filters.map(f => f.name === name ? { ...f, selectable: checked } : f);
            return ({ filters: mappedFilters })
        });

    updateFilterDefaultOptions = (name: string, val: string) => 
        this.setState(prev => ({ filters: prev.filters.map(f => f.name === name ? { ...f, defaultOptions: [val] } : f) }));

    updateFilterSelection = (name: string, itemKey: string, selected: boolean) =>
        this.setState(s => {
            const newFilters = s.filters.map(f => {
                if (f.name === name) {
                    const newOptions = f.options.map(o => ({ ...o, selected: o.key === itemKey ? selected : o.selected })); // selected ? f.options.concat(itemKey) : f.defaultOptions.filter(x => x !== itemKey);
                    return { ...f, options: newOptions, defaultOptions: newOptions.filter(o => o.selected).map(o => o.key) }
                } else {
                    return f;
                }
            });
            return { filters: newFilters }
        });

    addColumn = () => this.setState(prev => {
        const seq = prev.columns.reduce((pv, nv) => Math.max(pv, nv.order), 0);
        return { columns: prev.columns.concat([this.createNewColumn(seq)]) };
    });

    removeColumn = (index: number) => this.setState(prev => ({ columns: prev.columns.filter(c => c.order !== index).sort(c => c.order).map((c, ix) => ({ ...c, order: ix + 1 })) }));

    moveUp = (colOrder: number) => this.setState(prev => {
        const cols = prev.columns.sort((c1, c2) => c1.order - c2.order).reduce<ReportColumn[]>((newCols, col, ix) => {
            if (col.order === colOrder && ix > 0) {
                newCols.splice(ix - 1, 0, col);
            } else {
                newCols.push(col);
            }

            return newCols;
        }, []);

        const updatedCols = cols.map((c, ix) => ({ ...c, order: ix + 1 }));
        return { columns: updatedCols };
    });

    moveDown = (colOrder: number) => this.setState(prev => {
        const cols = prev.columns.sort((c1, c2) => c1.order - c2.order).reduce<ReportColumn[]>((newCols, col, ix) => {
            if (newCols.length > 0 && col.order === colOrder + 1) {
                newCols.splice(ix - 1, 0, col);
            } else {
                newCols.push(col);
            }

            return newCols;
        }, []);

        const updatedCols = cols.map((c, ix) => ({ ...c, order: ix + 1 }));
        return { columns: updatedCols };
    });

    createNewColumn = (maxSequence: number) => ({ order: maxSequence + 1, dataElementKey: '', heading: '', total: false, sortOrder: null, sortDirection: SortDirection.Ascending, summarize: false });

    render() {

        const { t } = this.context;
        const { saveError, saveComplete, reportTypes, reportDefinitions, isLoadingReportTypes, isLoadingReportDefinitions } = this.props;
        const { isNew, name, description, countHeading, reportType, reportTypeConfirmed, columns, filters, permissions, validationMessage, archived, isLoading, copyFromReportId } = this.state;

        if (isLoadingReportTypes || isLoadingReportDefinitions || isLoading) {
            return <Loading />;
        }

        let message: any;

        if (this.props.saveError) {
            message = <ApiError error={saveError} />;
        } else if (saveComplete) {
            message = (<div className='bg-success'>{t('Global:saveComplete')}</div>);
        }

        const selectedReportType = reportTypes.find(t => t.reportType === reportType.value);
        const dataElements = selectedReportType ? selectedReportType.dataElements : [];
        const currentReportType = selectedReportType ? selectedReportType.reportType : ReportTypes.None;
        const validationError = isNullOrEmpty(validationMessage) ? null : <div className='alert alert-danger'>{t(validationMessage)}</div>;

        const reportCopyOptions = [({key: '', name: t('ReportDefinitionForm:noReportSelected')})].concat(reportDefinitions.filter(d => d.reportType === reportType.value).map(d => ({ key: d.id.toString(), name: d.name })))

        return <div>
            <h2>{t(isNew ? 'ReportDefinitionForm:newReport' : 'ReportDefinitionForm:editReport')}</h2>

            <form className='data-form' onSubmit={this.saveReportDefinition} autoComplete='off'>

                {this.renderReportTypes(reportTypeConfirmed, reportType)}

                <ct.TextBox id='name' labelKey='Global:name' placeholderKey='Global:name' value={name} callback={val => this.setState({ name: this.validateName(val) })} />

                <ct.TextBox id='description' labelKey='Global:description' placeholderKey='Global:description' value={description} callback={val => this.setState({ description: this.validateDescription(val) })} />

                {reportTypeConfirmed ? null : <ct.Select id='copyFrom' labelKey='ReportDefinitionForm:copyFrom' value={ct.asFormValue('copyFrom', copyFromReportId)} callback={val => this.setState({ copyFromReportId : val })} options={reportCopyOptions} /> }

                {reportTypeConfirmed ? <ct.TextBox id='countHeading' labelKey='ReportDefinitionForm:countHeading' placeholderKey='ReportDefinitionForm:countHeading' value={countHeading} callback={val => this.setState({ countHeading: this.validateCountHeading(val) })} /> : null }

                {reportTypeConfirmed ? this.renderColumns(columns, dataElements, currentReportType) : null}

                {reportTypeConfirmed ? this.renderFilters(filters) : null}

                {reportTypeConfirmed ? <ct.Checkbox id='archived' labelKey='Global:archive' value={archived} callback={val => this.setState({ archived: this.validateArchived(val) })} /> : null}

                {reportTypeConfirmed ? this.renderReportPermissions(permissions) : null}

                {message}

                <p />
                {validationError}

                <div className='btn-toolbar' style={({marginBottom: '20px'})}>
                    {this.renderButtons(reportTypeConfirmed, t)}
                </div>
            </form>
        </div>;
    }

    renderColumns = (columns: ReportColumn[], dataElements: ReportDataElement[], reportType: ReportTypes) => {
        const { t } = this.context;
        return (
            <section>
                <label>{this.context.t('ReportDefinitionForm:columns')}</label>
                <table className='table table-condensed table-borderless' style={({ maxWidth: '700px' })}>
                    <thead>
                        <tr key={'header'}>
                            <th className='text-center'>{t('ReportDefinitionForm:indexHeading')}</th>
                            <th className='text-center'>{t('ReportDefinitionForm:elementHeading')}</th>
                            <th className='text-center'>{t('ReportDefinitionForm:headingHeading')}</th>
                            <th className='text-center'>{t('ReportDefinitionForm:totalHeading')}</th>
                            <th className='text-center'>{t('ReportDefinitionForm:summarize')}</th>
                            <th className='text-center'>{t('ReportDefinitionForm:sortHeading')}</th>
                            <th className='text-center'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {columns.map(c => <ReportColumnSettings key={c.order} reportType={reportType} index={c.order} dataElementKey={c.dataElementKey} heading={c.heading} total={c.total} sortOrder={c.sortOrder} sortDirection={c.sortDirection} summarize={c.summarize} dataElements={dataElements} valuesChanged={this.columnChanged} removeColumn={this.removeColumn} moveUp={this.moveUp} moveDown={this.moveDown} />)}
                    </tbody>
                </table>
                <button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, this.addColumn)}>{t('ReportDefinitionForm:addColumn')}</button>
            </section>
        );
    }

    renderFilters = (filters: ReportFilter[]) => {
        const { t } = this.context;
        const cellStyle = { verticalAlign: 'middle' };
        const rowStyle = { height: '40px' };

        return (
            <section>
                <label>{this.context.t('ReportDefinitionForm:filters')}</label>
                <table className='table table-condensed table-borderless' style={({maxWidth: '400px'})}>
                    <thead>
                        <tr key={'filter_header'}>
                            <th>{t('ReportDefinitionForm:filterNameHeading')}</th>
                            <th className='text-center'>{t('ReportDefinitionForm:filterUserSelectableHeading')}</th>
                            <th>{t('ReportDefinitionForm:filterDefaultsHeading')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filters.map(f => <tr key={`filter_${f.name}`} style={rowStyle}>
                            <td>{t(`ReportFilter:${f.name}`)}</td>
                            <td className='text-center' style={cellStyle}><input type='checkbox' id={`${f.name}_selectable`} checked={f.selectable || f.required} disabled={f.required} onChange={e => this.updateFilterSelectable(f.name, e.currentTarget.checked)} /></td>
                            <td>{this.renderFilterOptions(f)}</td>
                        </tr>)}
                    </tbody>
                </table>
            </section>
        );
    }

    renderFilterOptions = (filter: ReportFilter) => {
        const { t } = this.context;
        if (filter.type === ReportFilterType.List && filter.options.length > 0) {
            const selectOptions = [<option key='no-selection' value=''>{t('ReportFilter:noDefault')}</option>].concat(filter.options.map(o => <option key={o.key} value={o.key}>{t(o.nameKey)}</option>));
            return <select className='form-control input-sm' onChange={e => this.updateFilterDefaultOptions(filter.name, e.currentTarget.value)} value={filter.defaultOptions.length > 0 ? filter.defaultOptions[0] : ''}>{selectOptions}</select>;
        } else if (filter.type === ReportFilterType.MultiSelectList && filter.options.length > 0) {
            const options = filter.options.map(o => ({ key: o.key, name: t(o.nameKey), selected: o.selected }));
            return <ct.MultiSelect id={filter.name} labelKey='' options={options} callback={(key, selected) => this.updateFilterSelection(filter.name, key, selected)} />
        }else if (filter.type === ReportFilterType.Boolean) {
            var selectOptions = [<option key='no-selection' value=''>{t('ReportFilter:noDefault')}</option>, <option key='yes' value='yes'>{t('ReportFilter:Yes')}</option>, <option key='no' value='no'>{t('ReportFilter:No')}</option>];
            return <select className='form-control input-sm' onChange={e => this.updateFilterDefaultOptions(filter.name, e.currentTarget.value)} value={filter.defaultOptions.length > 0 ? filter.defaultOptions[0] : ''}>{selectOptions}</select>;
        }

        return null;
    }

    renderButtons = (reportTypeConfirmed: boolean, t: (key: string) => string) => {
        const primaryButton = reportTypeConfirmed
            ? <button key='primaryButton' className='btn btn-primary' onClick={e => clickHandler(e, this.save)}>{t('Global:save')}</button>
            : <button key='primaryButton' className='btn btn-primary' onClick={e => clickHandler(e, this.confirmReportType)}>{t('Global:next')}</button>;

        const cancelButton = <Link key='cancelButton' className='btn btn-basic' to={`/reports`}>{t('Global:cancel')}</Link>;

        return <>
            {primaryButton}
            {cancelButton}
        </>;
    }

    renderReportTypes = (reportTypeConfirmed: boolean, selectedReportType: ct.FormValue<ReportTypes>) => {
        const { t } = this.context;

        if (reportTypeConfirmed) {
            return (
                <div className="control-wrapper">
                    <label>{t('EventForm:resourceType')}</label>
                    <div style={({ fontSize: '18px', marginBottom: '20px' })}>{t(`ReportTypes:${ReportTypes[selectedReportType.value]}`)}</div>
                </div>);
        }

        const options = this.props.reportTypes.map(rt => ({ key: rt.reportType.toString(), name: t(`ReportTypes:${ReportTypes[rt.reportType]}`) }));
        return <ct.Select id='type' labelKey='EventForm:resourceType' value={({ ...selectedReportType, value: selectedReportType.value.toString() })} callback={this.reportTypeChanged} options={options} />
    }

    mapPermission = (existingPermission: ReportPermission, permission: ReportPermission, selected: boolean) => {
        if (selected) {
            if (permission & ReportPermission.Edit) {
                return existingPermission | permission | ReportPermission.Run;
            } else {
                return existingPermission | permission;
            }
        } else {
            if (permission & ReportPermission.Run) {
                // If turning off view, also turn off edit
                return (existingPermission & ~(permission)) & ~ReportPermission.Edit;
            } else {
                return existingPermission & ~(permission);
            }
        }
    }

    onUserPermissionChanged = (userAccountId: string, permission: ReportPermission, selected: boolean) => {
        this.setState(s => ({
            permissions: s.permissions.map(p => p.userAccountId === userAccountId ? { ...p, permission: this.mapPermission(p.permission, permission, selected) } : p)
        }))
    }

    renderReportPermissions = (permissions: ReportDefinitionPermission[]) => {
        const { t } = this.context;
        const cellStyle = { verticalAlign: 'middle' };
        const rowStyle = { height: '40px' };

        return (
            <section>
                <label>{this.context.t('ReportDefinitionForm:permissions')}</label>
                <table className='table table-condensed table-borderless' style={({ maxWidth: '400px' })}>
                    <thead>
                        <tr key={'filter_header'}>
                            <th colSpan={2}>{t('ReportDefinitionForm:userNameHeading')}</th>
                            <th className='nowrap'>{t('ReportDefinitionForm:runReportHeading')}</th>
                            <th className='nowrap'>{t('ReportDefinitionForm:editReportHeading')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {permissions.sort((p1, p2) => stringComparer(p1.userName, p2.userName)).map(p => <tr key={`permission_${p.userAccountId}`} style={rowStyle}>
                            <td>{p.userAccountName}</td>
                            <td className='nowrap'>{p.userName}</td>
                            <td className='text-center'><input type='checkbox' id={`${p.userAccountId}_run`} checked={(p.permission & ReportPermission.Run) === ReportPermission.Run} onChange={e => this.onUserPermissionChanged(p.userAccountId, ReportPermission.Run, e.currentTarget.checked)} /></td>
                            <td className='text-center'><input type='checkbox' id={`${p.userAccountId}_edit`} checked={(p.permission & ReportPermission.Edit) === ReportPermission.Edit} onChange={e => this.onUserPermissionChanged(p.userAccountId, ReportPermission.Edit, e.currentTarget.checked)} /></td>
                        </tr>)}
                    </tbody>
                </table>
            </section>
        );
    }
};


const matStateToProps = (state: ApplicationState) => ({
    isSaving: state.reportDefinitions.isSaving,
    saveComplete: state.reportDefinitions.saveComplete,
    saveError: state.reportDefinitions.saveError,
    validationErrors: state.reportDefinitions.validationErrors,
    reportTypes: state.reportTypes.reportTypes,
    isLoadingReportTypes: state.reportTypes.isLoading,
    reportDefinitions: state.reportDefinitions.reportDefinitions,
    isLoadingReportDefinitions: state.reportDefinitions.isLoading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadReportTypes: bindActionCreators(ReportTypeActions.actionCreators.loadReportTypes, dispatch),
    loadReportDefinitions: bindActionCreators(ReportDefinitionActions.actionCreators.loadReportDefinitions, dispatch),
    saveReportDefinition: bindActionCreators(ReportDefinitionActions.actionCreators.saveReportDefinition, dispatch),
    editReportDefinition: bindActionCreators(ReportDefinitionActions.actionCreators.editReportDefinition, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
    logout: bindActionCreators(LoginActions.actionCreators.logout, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    matStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps                  // Selects which action creators are merged into the component's props
)(ReportDefinitionForm);
