
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { DateFormat, RegistrationField } from '../../../store/pages/venues/types';
import StepHeader from './stepHeader';
import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import {getAge, properCase } from '../../../utils/util';
import NavButtons from './navButtons';

interface RegistrationStep1Props {
    firstName: string;
    lastName: string;
    birthDay: number;
    birthMonth: number;
    birthYear: number;
    months: string[];
    headerText: string;
    registrationFields: RegistrationField;
    minAge: number | null;
    dateFormat: DateFormat;
    searchForCustomer: (firstName: string, lastName: string, birthDay: number, birthMonth: number, birthYear: number) => void;
}

interface RegistrationStep1State {
    searching: boolean;
    days: number[];
    years: number[];
    firstName: ct.FormValue<string>;
    lastName: ct.FormValue<string>;
    birthDay: ct.FormValue<number>;
    birthMonth: ct.FormValue<number>;
    birthYear: ct.FormValue<number>;
    errorMessage: string | null;
}

class RegistrationStep1 extends React.Component<RegistrationStep1Props, RegistrationStep1State> {

    constructor(props: RegistrationStep1Props) {
        super(props);

        var years = this.generateYears();

        this.state = {
            searching: false,
            days: [],
            years: years,
            firstName: this.validateFirstname(props.firstName),
            lastName: this.validateLastname(props.lastName),
            birthDay: this.validateBirthDay(props.birthDay),
            birthMonth: this.validateBirthMonth(props.birthMonth),
            birthYear: this.validateBirthYear(props.birthYear === 0 ? years[0] : props.birthYear),
            errorMessage: null
        };
    }

    buildClearState = () => {
        return {
            searching: false,
            firstName: this.validateFirstname(''),
            lastName: this.validateLastname(''),
            birthDay: this.validateBirthDay(1),
            birthMonth: this.validateBirthMonth(1),
            birthYear: this.validateBirthYear(this.state.years[0]),
            errorMessage: null
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    generateYears = () => {
        const years: number[] = [];
        for (let y = new Date().getFullYear(); y >= 1900; y--) {
            years.push(y);
        }

        return years;
    }

    componentDidMount() {
        const days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
        this.setState({ days: days });
    }


    onBirthDayChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({ birthDay: this.validateBirthDay(parseInt(e.currentTarget.value))});
    }

    onBirthMonthChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
        var month = parseInt(e.currentTarget.value) + 1;
        this.setState({ birthMonth: this.validateBirthMonth(month) });
    }

    onBirthYearChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
        var year = parseInt(e.currentTarget.value);
        this.setState({ birthYear: this.validateBirthYear(year) });
    }

    reset = () => {
        var clearState = this.buildClearState();
        this.setState({ ...clearState});
    }

    submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {
            const { firstName, lastName, birthDay, birthMonth, birthYear } = this.state;
            const { minAge, searchForCustomer } = this.props;

            if (minAge) {
                const age = getAge(new Date(birthYear.value, birthMonth.value - 1, birthDay.value));
                if (age < minAge) {
                    this.setState({ errorMessage: this.context.t('registrations:minAgeNotMet', { age: age, minAge: minAge }) });
                    return;
                }
            }

            this.setState({ searching: true, errorMessage: null }, () => searchForCustomer(firstName.value, lastName.value, birthDay.value, birthMonth.value, birthYear.value));
        }
    }

    validateFirstname = (val: string) => v.validate(properCase(val), 'firstname', [v.required], []);
    validateLastname = (val: string) => v.validate(properCase(val), 'lastname', [v.required], []);
    validateBirthDay = (val: number) => v.validate(val, 'birthDay', [], []);
    validateBirthMonth = (val: number) => v.validate(val, 'birthMonth', [], []);
    validateBirthYear = (val: number) => v.validate(val, 'birthYear', this.props.registrationFields & RegistrationField.YearOfBirth ? [v.required]:[], []);

    render() {

        const { t } = this.context;
        const { searching, days, firstName, lastName, birthMonth, birthDay, birthYear, years, errorMessage } = this.state;
        const { headerText, months, dateFormat } = this.props;

        const birthDayOptions = days.map(d => <option key={d} value={d}>{d}</option>);
        const monthOptions = months.map((m, ix) => <option key={ix} value={ix}>{m}</option>);

        const birthDaySelection = (
            <div style={{ width: '80px', display: 'inline-block', marginRight: '10px' }}>
                <select className='form-control' id='birthDay' onChange={e => this.onBirthDayChanged(e)} value={birthDay.value.toString()}>{birthDayOptions}</select>
            </div>)
        const birthMonthSelection = (
            <div style={{ width: '200px', display: 'inline-block', marginRight: '10px' }}>
                <select className='form-control' id='birthMonth' onChange={e => this.onBirthMonthChanged(e)} value={Math.max(0, birthMonth.value - 1)}>{monthOptions}</select>
            </div>)

        return (
            <div>
                <form className='registration-form' onSubmit={this.submit} autoComplete='off'>

                    <StepHeader headerText={headerText} />

                    <ct.TextBox id='firstname' labelKey='Global:firstName' placeholderKey='Global:firstName' value={firstName} callback={val => this.setState({ firstName: this.validateFirstname(val) })} />

                    <ct.TextBox id='lastname' labelKey='Global:lastName' placeholderKey='Global:lastName' value={lastName} callback={val => this.setState({ lastName: this.validateLastname(val) })} />

                    <label>{t('Global:dateOfBirth')}</label>
                        <div>
                            <div className='form-group' style={({display: 'inline-block'})}>
                                {dateFormat === DateFormat.MDY ? birthMonthSelection : birthDaySelection}
                            </div>
                            <div className='form-group' style={({ display: 'inline-block' })}>
                                {dateFormat === DateFormat.MDY ? birthDaySelection : birthMonthSelection}
                            </div>
                            <div className='form-group' style={({ display: 'inline-block' })}>
                                <div style={{ width: '100px', display: 'inline-block' }}>
                                    <select className='form-control' id='birthYear' onChange={e => this.onBirthYearChanged(e)} value={birthYear.value ? birthYear.value : undefined}>
                                        {years.map(y => <option key={y} value={y}>{y}</option>)}
                                    </select>
                                </div>
                            </div>
                        </div>
 
                    <p />

                    {errorMessage ? <div className='alert alert-danger'>{errorMessage}</div> : null }

                    <NavButtons t={t} cancel={this.reset} preventNext={!v.isValid(this.state) || searching} cancelText={t('Global:reset')} />
                </form>
            </div>
        );
    }
}

export default RegistrationStep1;