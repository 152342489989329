
import * as React from 'react';
import * as PropTypes from 'prop-types'
import { clickHandler, isNullOrEmpty } from '../../../utils/util';
import { ApplicationState } from '../../../store';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import * as LoginActions from '../../../store/pages/login/actions';
import * as ModalActions from '../../../store/global/modal/actions';
import * as api from '../../../store/apiClient';
import Loading from '../../global/loading';
import EditCustomerBookingNotes from './editCustomerBookingNotes';

interface IGetCustomerBookingNotesResponse {
    customerBookingNotes: string;
}

interface Actions {
    logout: () => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
}

interface LocalProps {
    bookingId: string;
}

type CustomerBookingNotesProps = Actions & LocalProps;

interface CustomerBookingNotesState {
    customerBookingNotes: string;
    isLoading: boolean;
    isSaving: boolean;
    saveError: api.ApiError | null;
}

class CustomerBookingNotes extends React.Component<CustomerBookingNotesProps, CustomerBookingNotesState> {

    static contextTypes = {
        t: PropTypes.func
    }

    constructor(props: CustomerBookingNotesProps) {
        super(props);

        this.state = { isLoading: false, customerBookingNotes: '', isSaving: false, saveError: null }
    }

    componentDidMount() {
        this.loadNotes();
    }

    componentDidUpdate(prevProps: CustomerBookingNotesProps) {
        if (this.props.bookingId !== prevProps.bookingId) {
            this.loadNotes();
        }
    }

    loadNotes = () => {
        const { bookingId, logout } = this.props;

        if (isNullOrEmpty(bookingId)) return;

        this.setState({ isLoading: true });
        api.getWithAuth<IGetCustomerBookingNotesResponse>(`api/v1/booking/${bookingId}/customerNotes`, logout)
            .subscribe(res => this.setState({
                isLoading: false,
                customerBookingNotes: res.customerBookingNotes
            }))
    }

    saveNote = (newNotes: string, saveFailed: (error: api.ApiError | null) => void) => {
        const { bookingId, closeModal } = this.props;

        this.setState({ isSaving: true, saveError: null });
        api.postWithAuth(`api/v1/booking/${bookingId}/customerNotes`, { noteText: newNotes }, this.props.logout)
            .subscribe(
                _ => this.setState({ isSaving: false, saveError: null, customerBookingNotes: newNotes }, closeModal)
                , e => this.setState({ isSaving: false, saveError: e }, () => saveFailed(e)));
    }

    editNotes = () => {
        const { closeModal } = this.props;
        const {customerBookingNotes } = this.state;
        this.props.showModal(<EditCustomerBookingNotes customerBookingNotes={customerBookingNotes} save={this.saveNote} close={closeModal} />, "EditCustomerBookingNotes")
    }

    render() {
        const { t } = this.context;
        const { customerBookingNotes, isLoading } = this.state;

        return <div>
            <div className='section-heading'>
                {t('CustomerBookingNotes:notes')}
                <button className='btn btn-primary btn-sm pull-right' style={{ padding: '0 8px'}} onClick={e => clickHandler(e, this.editNotes)}>{t('Global:edit')}</button>
            </div>
            <div className='row'>
                <div className='col-xs-12'>
                    {isLoading
                        ? <Loading />
                        : <div id='customerNotes' className='custom-booking-notes-view' dangerouslySetInnerHTML={{ __html: customerBookingNotes || '' }}></div>
                    }
                </div>
            </div>
        </div>
    }
}


const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    logout: bindActionCreators(LoginActions.actionCreators.logout, dispatch),
    showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerBookingNotes);
