
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { bindActionCreators, Dispatch } from 'redux';

import * as ModalActions from '../../store/global/modal/actions';
import {  } from '../../store/global/preferences/types';
import { clickHandler } from '../../utils/util';
import { Resource } from '../../store/pages/resources/types';
import FilterResources from './filterResources';

interface MappedReduxState {
    hiddenResources: string[];
    selectedVenueId: string;
    resources: Resource[];
}

interface Actions {
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
}

type ResourceFilterProps = MappedReduxState & Actions;


const ResourceFilter = (props: ResourceFilterProps) => {
    const { resources, selectedVenueId, hiddenResources, showModal } = props;

    var activeVenueResources = resources.filter(r => !r.archived && r.venueId == selectedVenueId);
    var activeFilteredResources = hiddenResources.filter(hr => activeVenueResources.findIndex(r => r.id === hr) >= 0);
    var totalActiveResources = activeVenueResources.length;
    var visibleResources = totalActiveResources - activeFilteredResources.length;

    return (
        <button className='btn btn-sm btn-primary' style={{ position: 'relative' }} onClick={e => clickHandler(e, () => showModal(<FilterResources hiddenResources={hiddenResources} selectedVenueId={selectedVenueId} resources={activeVenueResources} />, 'Filter Resources'))}>
            <span className='glyphicon glyphicon-filter'></span>
            {activeFilteredResources.length > 0 ? <div className="notification" role="status">{visibleResources}/{totalActiveResources}</div> : null}
        </button>
    )
}

const mapStateToProps = (state: ApplicationState): MappedReduxState => ({
    hiddenResources: state.preferences.hiddenResources,
    selectedVenueId: state.venues.selectedVenueId,
    resources: state.resources.resources
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(ResourceFilter);