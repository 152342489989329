
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as pt from './types';
import * as epic from '../../epic';
import * as vt from '../../pages/venues/types';
import * as dt from './types';

const resourceFilterKey = 'hidden_resources'
const diaryResolutionStorageKey = 'diaryResolution'

export type UserPreferenceActions = pt.SetResourcefilter | pt.SetDiaryResolution

export const getHiddenResources = (venueId: string) => {
    const val = localStorage.getItem(`${venueId}_${resourceFilterKey}`);
    return val ? val.split('|') : [];
}

export const getDiaryResolution = () => {
    const defaultSlotSize = 15;
    const val = localStorage.getItem(diaryResolutionStorageKey);
    const intVal = val ? parseInt(val) : defaultSlotSize;
    return isNaN(intVal) ? defaultSlotSize : intVal;
}

export const actionCreators = {
    setResourcefilter: (venueId: string, hiddenResources: string[]) => {
        localStorage.setItem(`${venueId}_${resourceFilterKey}`, hiddenResources.join('|'));
        return { type: pt.UserPreferenceActionTypes.SetResourcefilter, venueId: venueId, hiddenResources: hiddenResources };
    },
    setDiaryResolution: (slotSizeInMinutes: number) => {
        localStorage.setItem(diaryResolutionStorageKey, slotSizeInMinutes.toString());
        return { type: pt.UserPreferenceActionTypes.SetDiaryResolution, slotSizeInMinutes: slotSizeInMinutes };
    },
}

export const venueChangedEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        vt.VenueActionTypes.SelectVenue,
        action => {
            const venueId = action.venueId;
            var filter = getHiddenResources(venueId);
            return Observable.of(actionCreators.setResourcefilter(venueId, filter));
        },
        err => {
            return { type: pt.UserPreferenceActionTypes.SetResourcefilter, venueId: '', hiddenResources: [] };
        });
