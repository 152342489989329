
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as rdt from './types'; 
import * as lt from '../login/types';
import { ReportTypes } from '../reportTypes/types';
import { IStore } from '../..';

export type ReportDefinitionActions = rdt.LoadReportDefinitions | rdt.ReceiveReportDefinitions | rdt.SaveReportDefinition | rdt.ReportDefinitionSaved | rdt.ReportDefinitionSaveFailed | rdt.EditReportDefinition;

export const actionCreators = {
    loadReportDefinitions: () => ({ type: rdt.ReportDefinitionActionTypes.RequestReportDefinitions }),
    loadReportDefinitionsComplete: (reportDefinitions: rdt.ReportDefinition[], err: api.ApiError | null) => ({ type: rdt.ReportDefinitionActionTypes.ReceivedReportDefinitions, reportDefinitions: reportDefinitions, error: err }),
    editReportDefinition: () => ({ type: rdt.ReportDefinitionActionTypes.EditReportDefinition }),
    saveReportDefinition: (isNew: boolean, reportDefinitionId: string | null, name: string, reportType: ReportTypes, description: string, countHeading: string, columns: rdt.ReportDefinitionColumn[], filters: rdt.ReportDefinitionFilter[], permissions: rdt.ReportDefinitionPermission[], archived: boolean) => ({ type: rdt.ReportDefinitionActionTypes.SaveReportDefinition, isNew: isNew, reportDefinitionId: reportDefinitionId, name: name, reportType: reportType, description: description, countHeading: countHeading, columns: columns, filters: filters, permissions: permissions, archived: archived}),
    reportDefinitionSaved: (reportDefinitionId: string) => ({ type: rdt.ReportDefinitionActionTypes.ReportDefinitionSaved, reportDefinitionId: reportDefinitionId }),
    reportDefinitionSaveFailed: (error: api.ApiError) => ({ type: rdt.ReportDefinitionActionTypes.ReportDefinitionSaveFailed, error: error })
}

interface IGetReportDefinitionsResponse {
    reportDefinitions: rdt.ReportDefinition[];
}

interface ISaveReportDefinitionResponse {
    reportDefinitionId: string;
}

export const handleClientChange = (store: IStore) => [actionCreators.loadReportDefinitions]

//https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-after-catching-and-processing-an-error-with-emitting-somethi

const loadReportDefinitions = () => Observable.defer(() => api.getJson<IGetReportDefinitionsResponse>('api/v1/report/definitions/'))
    .map(response => actionCreators.loadReportDefinitionsComplete(response.reportDefinitions, null));

export const loadReportDefinitionsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        rdt.ReportDefinitionActionTypes.RequestReportDefinitions,
        action => loadReportDefinitions(),
        err => actionCreators.loadReportDefinitionsComplete([], err));

export const reloadReportDefinitionsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        rdt.ReportDefinitionActionTypes.ReportDefinitionSaved,
        action => loadReportDefinitions(),
        err => actionCreators.loadReportDefinitionsComplete([], err));

export const saveReportDefinitionEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        rdt.ReportDefinitionActionTypes.SaveReportDefinition,
        action => {
            const srda = action as rdt.SaveReportDefinition;
            var body = {
                name: srda.name,
                reportType: srda.reportType,
                description: srda.description,
                countHeading: srda.countHeading,
                columns: srda.columns,
                filters: srda.filters,
                permissions: srda.permissions,
                archived: srda.archived
            };

            return (srda.isNew ? api.post('api/v1/report/definition/', body) : api.put(`api/v1/report/definition/${srda.reportDefinitionId}`, body))
                .map(response => {

                    let reportDefinitionId = srda.reportDefinitionId;
                    if (srda.isNew) {
                        const strr = response.response as ISaveReportDefinitionResponse;
                        if (strr) {
                            reportDefinitionId = strr.reportDefinitionId;
                        }
                    }

                    return ({ type: rdt.ReportDefinitionActionTypes.ReportDefinitionSaved, reportDefinitionId: reportDefinitionId });
                });
        },
        (err: api.ApiError) => actionCreators.reportDefinitionSaveFailed(err));
