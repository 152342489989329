
export interface Message {
    time: Date;
    message: string;
}

export interface StatusState {
    isConnected: boolean;
    lastConnectionTime: Date | null;
    lastConnectionError: Date | null;
    lastErrorMessage: string | null;
    lastConnectionAttempt: Date | null;
    connectionErrors: Message[];
    lastMessages: Message[];
}

export enum StatusActionTypes {
    SetConnected = 'SET_CONNECTED',
    SetDisconnected = 'SET_DISCONNECTED',
    AddError = 'ADD_ERROR',
    AddMessage = 'ADD_MESSAGE',
    SetLastConnectionAttempt = 'SET_LAST_CONNECTION_ATTEMPT'
}

export interface SetConnected {
    type: StatusActionTypes.SetConnected;
}

export interface SetDisconnected {
    type: StatusActionTypes.SetDisconnected;
    message: string;
}

export interface AddError {
    type: StatusActionTypes.AddError;
    error: Message;
}

export interface AddMessage {
    type: StatusActionTypes.AddMessage;
    message: Message;
}

export interface SetLastConnectionAttempt {
    type: StatusActionTypes.SetLastConnectionAttempt;
    time: Date;
}