
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { ITranslationContext } from '../../translations';
import { MarketingPreference } from '../../store/pages/customer/types';

interface MarketingPreferencesProps {
    preference: MarketingPreference;
    customerId: string;
}

const MarketingPreferences = (props: MarketingPreferencesProps, context: ITranslationContext) => {
    const { preference, customerId} = props;
    const { t } = context;

    if (preference === MarketingPreference.None) {
        return <span key={`${customerId}_noMarketing`} className='label label-danger' style={({ marginLeft: '6px' })}>{t('Global:noMarketing')}</span>;
    }

    const prefs = [];
    if ((preference & MarketingPreference.Email) === MarketingPreference.Email) {
        prefs.push(<span key={`${customerId}_emailMarketing`} className='label label-success' style={({ marginLeft: '6px' })}>{t('Global:emailMarketing')}</span>)
    }

    if ((preference & MarketingPreference.Phone) === MarketingPreference.Phone) {
        prefs.push(<span key={`${customerId}_phoneMarketing`} className='label label-success' style={({ marginLeft: '6px' })}>{t('Global:phoneMarketing')}</span>)
    }

    return <>{prefs}</>;
}

MarketingPreferences.contextTypes = {
    t: PropTypes.func
}

export default MarketingPreferences;
