
import * as React from 'react';
import * as PropTypes from 'prop-types'
import { Link, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import * as api from '../../../store/apiClient';
import * as LoginState from '../../../store/pages/login/types';
import * as LoginActions from '../../../store/pages/login/actions';
import * as v from '../../global/validation';
import * as ct from '../../global/controls';
import ApiError from '../../global/apiError';

type ForgottenPasswordPageProps =
    LoginState.LoginState                       // ... state we've requested from the Redux store
    & typeof LoginActions.actionCreators        // ... plus action creators we've requested
    & RouteComponentProps<{}>;                 // ... plus incoming routing parameters

interface ForgottenPasswordPageState {
    username: ct.FormValue<string>;
    showResetMessage: boolean;
    error: api.ApiError | null;
}

class ForgottenPasswordPage extends React.Component<ForgottenPasswordPageProps, ForgottenPasswordPageState> {

    constructor(props: ForgottenPasswordPageProps) {
        super(props);

        this.state = { error: null, username: this.validateUsername(''), showResetMessage: false };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    validateUsername = (val: string) => v.validate(val, 'username', [v.required], []);

    forgotPassword = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {
            this.setState({ error: null, showResetMessage: false });
            this.props.requestPasswordReset(this.state.username.value);
        }
    }

    componentDidUpdate(prevProps: ForgottenPasswordPageProps) {
        if (prevProps.requestingPasswordReset && !this.props.requestingPasswordReset) {
            this.setState({ showResetMessage: true, username: this.validateUsername('') });
        }
    }

    render() {

        const { t } = this.context;
        const { passwordResetError } = this.props;
        const { showResetMessage, username, error } = this.state;

        let message: any = null;
        if (passwordResetError) {
            message = <ApiError error={passwordResetError} />;
        }

        const body = showResetMessage ?
            <div>
                <div className='alert alert-success'>{t('ForgottenPassword:resetSuccessMessage')}</div>;
                 <div className='row mt-15'>
                    <div className='col-xs-12'>
                        <Link className='btn btn-basic' to={'/login'} style={({ float: 'right' })}>{t('Global:close')}</Link>
                    </div>
                </div>
            </div>
            : <form className='data-form' onSubmit={this.forgotPassword} autoComplete='off'>
                <p>{t('ForgottenPassword:forgottenPasswordText')}</p>

                <ct.TextBox id='username' labelKey='Global:username' placeholderKey='Global:username-placeholder' value={username} callback={val => this.setState({ username: this.validateUsername(val) })} />

                <ApiError error={error} className='error-placeholder' />

                {message}

                <div>
                    <input type='submit' value={t('ForgottenPassword:sendInstructions')} className='btn btn-primary' />
                    <Link className='btn btn-basic' to={'/login'} style={({ float: 'right' })}>{t('Global:cancel')}</Link>
                </div>
            </form>;


        return (
            <div className='login-page'>
                <div className='login-content'>
                    <div className='login cf'>
                        <h2 className='login-title'>{t('ForgottenPassword:title')}</h2>

                        {body}

                    </div>
                </div>
            </div>);
    }
}


const mapStateToProps = (state: ApplicationState) => state.login;

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,                    // Selects which state properties are merged into the component's props
    LoginActions.actionCreators        // Selects which action creators are merged into the component's props
)(ForgottenPasswordPage);
