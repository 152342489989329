
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';

import { ReservationBooking } from './types';
import { ITranslationContext } from '../../../translations';
import { ActivityFormatProduct, buildProductKey, getPrices, parseProductKey } from './helpers';
import { isNullOrEmpty } from '../../../utils/util';
import { CustomerCategory } from '../../../store/pages/customerCategories/types';

interface ReservationBookingSelectionsProps {
    products: ActivityFormatProduct[];
    reservationSelections: ReservationBooking[];
    selectionsChanged: (reservationSelections: ReservationBooking[]) => void;
    customerCategories: CustomerCategory[];
}

const ReservationBookingSelections = (props: ReservationBookingSelectionsProps, context: ITranslationContext) => {
    const { products, reservationSelections, selectionsChanged } = props;
    const { t } = context;

    const productChanged = (bookingId: string, productKey: string, customerCategoryId: string | null, billItemId: string | null) => 
        selectionsChanged(reservationSelections.map(s => {
            if (s.bookingId !== bookingId || (customerCategoryId && s.customerCategoryId !== customerCategoryId) || (!isNullOrEmpty(billItemId) && s.billItemId !== billItemId)) {
                return s;
            }

            const [productId, productCustomerCategoryId] = parseProductKey(productKey);

            const prod = products.find(p => p.product.id === productId);
            const cust = s.customer;
            const placesToBook = prod ? prod.placesPerUnit : 1;
            const prices = prod && cust ? getPrices(prod.product, s.quantity, s.createDateTimeInLocalTime, s.firstEventStartDateTime, cust.tags) : [];
            const productPriceId = prices.length > 0 ? prices[0].productPrice.id : '';
            const unitPrice = prices.length > 0 ? prices[0].unitPrice : /*prod ? prod.product.unitPrice :*/ 0;

            return {
                ...s,
                productId: productId,
                productKey: productKey,
                placesPerUnit: placesToBook,
                productPriceId: productPriceId,
                unitPrice: unitPrice,
                customerCategoryId: productCustomerCategoryId || s.customerCategoryId
            }
        }))

    const productPriceChanged = (bookingId: string, productPriceId: string) => {
        selectionsChanged(reservationSelections.map(s => {
            if (s.bookingId !== bookingId) {
                return s;
            }

            return { ...s, productPriceId: productPriceId }
        }))
    }

    const numberOfParticipantsChanged = (bookingId: string, val: number | null) =>
        selectionsChanged(reservationSelections.map(s => s.bookingId === bookingId ? { ...s, quantity: val || 1 } : s));

    // Need to multiply out product selections x customer categories
    const productOptions = products.filter(p => p.placesPerUnit > 0).map(p => ({ key: buildProductKey(p.product.id, p.customerCategoryId), name: `${p.product.name} - ${p.customerCategoryName}` }));
    if (productOptions.length < 1)
        return null;

    const renderSelections = () => {
        const inlineControlStyle = ({ minHeight: '10px', marginRight: '10px' });
        const cellStyle = ({ verticalAlign: 'top' });
        const textCellStyle = ({ ...cellStyle, paddingTop: '12px' });

        return reservationSelections.map(s => {
            const selectedProduct = products.find(p => p.product.id === s.productId);
            const places = selectedProduct ? s.quantity * selectedProduct.placesPerUnit : 0;
            const cust = s.customer;
            const priceOptions = selectedProduct ? getPrices(selectedProduct.product, s.quantity, s.createDateTimeInLocalTime, s.firstEventStartDateTime, cust ? cust.tags : []).map(p => ({ key: p.productPrice.id, name: `${t('Global:currencySymbol')}${p.unitPrice.toFixed(2)} ${isNullOrEmpty(p.tagName) ? '' : ' - ' + p.tagName}` })) : [];
            const cat = props.customerCategories.find(c => c.id === s.customerCategoryId);

            return <tr key={s.bookingId}>
                <td style={textCellStyle}>{cust ? cust.firstname : ''} {cust ? cust.lastname : ''}</td>
                <td style={textCellStyle}>{cat ? cat.name : ''}</td>
                <td style={cellStyle}><ct.Select id={`${s.bookingId}_product`} labelKey='' value={ct.asFormValue(`${s.bookingId}_product`, s.productKey)} callback={val => productChanged(s.bookingId, val, s.customerCategoryId, s.billItemId)} options={[{ key: '', name: t('Global:none')}].concat(productOptions)} style={inlineControlStyle} /></td>
                <td style={cellStyle}><ct.Select id={`${s.bookingId}_price`} labelKey='' value={ct.asFormValue(`${s.bookingId}_price`, s.productPriceId)} callback={val => productPriceChanged(s.bookingId, val)} options={priceOptions} style={inlineControlStyle} /></td>
                <td style={cellStyle}><ct.NumberBox id={`${s.bookingId}_numberOfPlacesToBook`} labelKey='' placeholderKey='' min='0' value={ct.asFormValue(`${s.bookingId}_numberOfPlacesToBook`, s.quantity)} callback={val => numberOfParticipantsChanged(s.bookingId, val)} style={({ ...inlineControlStyle, maxWidth: '100px', minWidth: '50px' })} minimal={true} /></td>
                <td style={textCellStyle}>{places}</td>
            </tr>
        });
    }

    return <div>
        <div className='row' style={({ flex: '0 0 auto' })}>
            <div className='col-md-12'>
                <table className='table table-condensed'>
                    <thead>
                        <tr>
                            <th colSpan={2}>{t('ReservationBookingSelection:customer')}</th>
                            <th colSpan={2}>{t('ReservationBookingSelection:product')}</th>
                            <th>{t('ReservationBookingSelection:quantity')}</th>
                            <th>{t('ReservationBookingSelection:places')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderSelections()}
                    </tbody>
                </table>
            </div>
        </div>
    </div>;
}


ReservationBookingSelections.contextTypes = {
    t: PropTypes.func
}

export default ReservationBookingSelections;