

export interface accessToken {
    token: string;
    expired: boolean;
    c: number;
    st: number | null;
}

export interface accessTokenData {
    token: string;
    expiry: Date;
    c: number;
    st: number | null;
}

export const isUserAuthenticated = (): boolean => {
    const at = getAccessTokenFromStorage();
    const rt = getRefreshToken();
    const hasTokens = at !== null && rt !== null;
    return hasTokens;
};

export const getAccessToken = (): accessToken => {
    const tokenStr = getAccessTokenFromStorage();

    if (tokenStr === null)
        return { token: "", expired: true, c: 0, st: null };

    const token = JSON.parse(tokenStr) as accessTokenData;
    const isExpired = token.expiry.valueOf() < new Date().valueOf();

    return { token: token.token, expired: isExpired, c: token.c, st: token.st };
}

export const storeAccessToken = (token: string, validFor: number, c: number, st: number | null) => {

    var expiry = new Date(new Date().valueOf() + (validFor * 1000));

    var at = JSON.stringify({ token: token, expiry: expiry, c: c, st: st });
    sessionStorage.setItem('accessToken', at);

    // remove legacy token stored in local storage
    localStorage.removeItem('accessToken');
}

const getAccessTokenFromStorage = () => sessionStorage.getItem('accessToken');

export const getRefreshToken = () => localStorage.getItem('refreshToken');

export const storeRefreshToken = (token: string) => localStorage.setItem('refreshToken', token);

export const signOut = () => {
    sessionStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('clientIdOverride');
}

export const hasPermission = (permission: number) => {
    const at = getAccessToken();
    return ((at.c * -1) & permission) === permission;
}

export const isClientAdmin = () => hasPermission(2);

export const isSystemAdmin = () => hasPermission(8);

export const getKioskId = () => localStorage.getItem('kioskId') || '';

export const saveKioskId = (kioskId: string) => localStorage.setItem('kioskId', kioskId);
