
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as api from '../../../../store/apiClient';

import { DiaryReservation, EventBookingReservation, EventReservation, ReservationType } from '../../../../store/pages/diary/types';
import { Bill, BillItem, deserializeBill } from '../../../../store/pages/pointOfSale/types';
import { Booking, ReservationBillItem } from './../types';
import { generateTempId, isNullOrEmpty, mapLocalDateTime, mapUtcDate, parseLocalDateTime } from '../../../../utils/util';
import { IGetDiaryReservationsResponse } from '../../../../store/pages/diary/actions';
import { Resource } from '../../../../store/pages/resources/types';


interface LoadBookingResponse {
    booking: Booking;
    bill: Bill;
}

export const loadBooking = (bookingId: string, venueId: string, notAuthAction: () => void): Observable<LoadBookingResponse> => {
    return api.getWithAuth<LoadBookingResponse>(`api/v1/booking/${venueId}/${bookingId}`, notAuthAction)
        .pipe(map(res => {
            const booking = {
                ...res.booking,
                firstEventStartDateTime: parseLocalDateTime(res.booking.firstEventStartDateTime),
                createDateTimeInLocalTime: parseLocalDateTime(res.booking.createDateTimeInLocalTime),
                cancelledDateTime: mapUtcDate(res.booking.cancelledDateTime),
                reservations: res.booking.reservations.map(r => ({ ...r, startTime: parseLocalDateTime(r.startTime), endTime: parseLocalDateTime(r.endTime) }))
            }

            const bill = deserializeBill(res.bill);

            return { booking: booking, bill: bill }
        }));
}

export const loadReservations = (venueId: string, date: Date, notAuthAction: () => void): Observable<DiaryReservation[]> => {
    return api.getWithAuth<IGetDiaryReservationsResponse>(`api/v1/venue/${venueId}/diaryReservations?from=${date.toYMDDateString()}&to=${date.toYMDDateString()}`, notAuthAction)
        .pipe(map(res =>
            res.reservations.map(r => ({
                ...r,
                key: r.id || generateTempId(),
                startTime: parseLocalDateTime(r.startTime),
                endTime: parseLocalDateTime(r.endTime),
                actualStartTime: mapLocalDateTime(r.actualStartTime),
                depositDueDate: mapUtcDate(r.depositDueDate),
                paymentDueDate: mapUtcDate(r.paymentDueDate),
                overduePaymentDate: mapUtcDate(r.overduePaymentDate)
            }))
        ));
}

export const mapBookingReservations = (billItems: BillItem[], bookingReservations: EventBookingReservation[]) => {
    return billItems
        .filter(i => !isNullOrEmpty(i.reservationId))
        .reduce<ReservationBillItem[]>((acc, i) => {
            const ix = acc.findIndex(x => x.reservationId === i.reservationId);
            if (ix >= 0) {
                acc[ix].billItems.push(i);
            }
            else {
                const rsv = bookingReservations.find(r => r.id === i.reservationId);
                if (rsv) acc.push({ reservationId: i.reservationId || '', reservation: rsv, billItems: [i], targetReservationKey: null, targetReservationStartTime: null });
            }
            return acc;
        }, [])
}

export const mapDiaryReservationToEventReservation = (reservation: DiaryReservation, eventId: string) : EventReservation => {
    return {
        id: reservation.id,
        key: reservation.id || generateTempId(),
        eventId: eventId,
        eventName: reservation.eventName,
        colour: reservation.colour,
        reservationType: reservation.reservationType,
        resourceId: reservation.resourceId,
        resourceConfigurationId: reservation.resourceConfigurationId,
        activityFormatId: reservation.activityFormatId,
        activityFormatVariationId: reservation.activityFormatVariationId,
        activityFormatVariationScheduleId: reservation.activityFormatVariationScheduleId,
        activityFormatVariationScheduleSequence: reservation.activityFormatVariationScheduleSequence,
        mustBookItemsTogether: reservation.mustBookItemsTogether,
        primaryReservationId: reservation.primaryReservationId,
        isMultiScheduleReservation: reservation.isMultiScheduleReservation,
        startTime: reservation.startTime,
        endTime: reservation.endTime,
        maxParticipants: reservation.maxParticipants,
        bookedParticipants: reservation.bookedParticipants,
        membershipTypeId: reservation.membershipTypeId,
        membershipTypeLabel: reservation.membershipTypeLabel,
        membershipTypeLabelColour: reservation.membershipTypeLabelColour,
        notes: '',
        archived: false
    }
}

//export const createReservation = (resource: Resource, activityFormatId: string, activityFormatVariationId: string, schedule: ActivityFormatVariationSchedule, startTime: Date, endTime: Date, mustBookItemsTogether: boolean) => {
//    return {
//        id: null,
//        key: generateTempId(),
//        eventId: '',
//        eventName: '',
//        colour: colours[0],
//        type: ReservationType.NonExclusive,
//        maxParticipants: 0,
//        bookedParticipants: [],
//        resourceId: resource.id,
//        resourceConfigurationId: resource && resource.configurations && resource.configurations.length > 0 ? resource.configurations[0].id : null,
//        activityFormatId: activityFormatId,
//        activityFormatVariationId: activityFormatVariationId,
//        activityFormatVariationScheduleId: schedule.id,
//        activityFormatVariationScheduleSequence: schedule.sequence,
//        mustBookItemsTogether: mustBookItemsTogether,
//        primaryReservationId: reservation.primaryReservationId,
//        isMultiScheduleReservation: reservation.isMultiScheduleReservation,
//        startTime: startTime,
//        endTime: endTime,
//        notes: '',
//        archived: false
//    }
//}

export const copyReservation = (resource: Resource, toCopy: ReservationBillItem, startTime: Date, endTime: Date) => {
    return {
        id: null,
        key: generateTempId(),
        eventId: '',
        eventName: toCopy.reservation.eventName,
        colour: toCopy.reservation.colour,
        reservationType: toCopy.reservation.reservationType,
        maxParticipants: toCopy.reservation.maxParticipants,
        bookedParticipants: [],
        resourceId: resource.id,
        resourceConfigurationId: resource && resource.configurations && resource.configurations.length > 0 ? resource.configurations[0].id : null,
        activityFormatId: toCopy.reservation.activityFormatId,
        activityFormatVariationId: toCopy.reservation.activityFormatVariationId,
        activityFormatVariationScheduleId: toCopy.reservation.activityFormatVariationScheduleId,
        activityFormatVariationScheduleSequence: toCopy.reservation.activityFormatVariationScheduleSequence,
        mustBookItemsTogether: toCopy.reservation.mustBookItemsTogether,
        primaryReservationId: toCopy.reservation.primaryReservationId,
        isMultiScheduleReservation: toCopy.reservation.isMultiScheduleReservation,
        startTime: startTime,
        endTime: endTime,
        notes: toCopy.reservation.notes,
        archived: false
    }
}
