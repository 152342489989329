
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { CustomFieldApplication, CustomFieldType } from '../../../../store/pages/activityFormats/types';

import { ICustomField } from './activityFormatForm';
import { isNullOrEmpty, clickHandler } from '../../../../utils/util';

export enum FieldUsage {
    Booking,
    Registration
}

interface ActivityFormatExtraFieldsProps {
    extraFields: ICustomField[];
    fieldUsage: FieldUsage;
    allowMultipleCategoriesPerCustomer: boolean,
    onFieldsChanged: (extraFields: ICustomField[]) => void;
}

interface FieldError {
    name: string;
    errorKey: string;
}

export default class ActivityFormatExtraFields extends React.Component<ActivityFormatExtraFieldsProps, {}> {

    static contextTypes = {
        t: PropTypes.func
    }

    valueChanged = (key: string, setter: (f: ICustomField) => ICustomField) => 
        this.props.onFieldsChanged(this.props.extraFields.map(f => f.key === key ? setter(f) : f));

    nameChanged = (key: string, value: string) => this.valueChanged(key, f => ({ ...f, name: value}))

    valuesChanged = (key: string, value: string) => this.valueChanged(key, f => ({ ...f, values: value.split(',') }))

    descriptionChanged = (key: string, value: string) => this.valueChanged(key, f => ({ ...f, description: value }))

    typeChanged = (key: string, value: string) => {
        // map value
        const intVal = parseInt(value);
        const val = CustomFieldType[intVal];
        if (val)
            this.valueChanged(key, f => ({ ...f, type: intVal }))
    }

    appliesToChanged = (key: string, value: string) => {
        // map value
        const intVal = parseInt(value);
        const val = CustomFieldType[intVal];
        if (val)
            this.valueChanged(key, f => ({ ...f, appliesTo: intVal }))
    }

    requiredChanged = (key: string, checked: boolean) => this.valueChanged(key, f => ({ ...f, required: checked }))

    customerCanEnterChanged = (key: string, checked: boolean) => this.valueChanged(key, f => ({ ...f, adminOnly: !checked }))

    removeField = (key: string) => {
        this.props.onFieldsChanged(this.props.extraFields.filter(f => f.key !== key));
    }

    render() {
        const { t } = this.context;
        const { extraFields, fieldUsage, allowMultipleCategoriesPerCustomer } = this.props;
        const inlineControlStyle = ({ minHeight: '10px', margin: '0 0 3px 0' });
        const cellStyle = ({ paddingRight: '10px'})

        const typeOptions = Object.keys(CustomFieldType)
            .filter(k => typeof CustomFieldType[k as any] === 'number' && (fieldUsage === FieldUsage.Registration || parseInt(CustomFieldType[k as any]) < 100))
            .map(k => <option key={CustomFieldType[k as any]} value={CustomFieldType[k as any]}>{t(`CustomFieldType:${k}`)}</option>);

        const appliesToOptions = Object.keys(CustomFieldApplication)
            .filter(k => typeof CustomFieldApplication[k as any] === 'number' && parseInt(CustomFieldApplication[k as any]) > 0)
            .map(k => <option key={CustomFieldApplication[k as any]} value={CustomFieldApplication[k as any]}>{t(`CustomFieldApplication:${k}`)}</option>);

        const invalidFields = extraFields.filter(f => (!isNullOrEmpty(f.name) && isNullOrEmpty(f.description)) || (isNullOrEmpty(f.name) && !isNullOrEmpty(f.description)));
        const errors = invalidFields.reduce<FieldError[]>((acc, f) => {
            if (isNullOrEmpty(f.description))
                acc.push({ name: f.name, errorKey: 'ActivityFormatForm:descriptionRequired' });
            if (isNullOrEmpty(f.name))
                acc.push({ name: f.name, errorKey: 'ActivityFormatForm:nameRequired' });
            return acc;
        }, []);

        var duplicateNames = extraFields.filter(f => !isNullOrEmpty(f.name)).reduce((names, f) => {
            names.set(f.name, (names.get(f.name) || 0) + 1)
            return names;
        }, new Map<string, number>());

        if (Array.from(duplicateNames).filter(e => e[1] > 1).length > 0) {
            errors.push({ name: '', errorKey: 'ActivityFormatForm:allFieldNamesMustBeUnique' })
        }

        const showValuesColumn = extraFields.reduce((acc, f) => acc || f.type === CustomFieldType.List, false);

        return (
            <div style={({ margin: '10px 0 15px 0' })}>
                <div>{t('ActivityFormatExtraFields:listHint')}</div>
                <table>
                    <thead>
                        <tr>
                            <th>{t('Global:name')}</th>
                            <th>{t('Global:description')}</th>
                            <th>{t('Global:type')}</th>
                            {showValuesColumn ? <th>{t('ActivityFormatExtraFields:optionsHeading')}</th> : null}
                            <th>{t('Global:required')}</th>
                            <th style={{padding: '0 10px'}}>{t('ActivityFormatExtraFields:customerCanEnter')}</th>
                            {allowMultipleCategoriesPerCustomer && fieldUsage === FieldUsage.Registration ? <th>{t('ActivityFormatExtraFields:appliesTo')}</th> : null}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {extraFields.map((f, ix) => (
                            <tr key={f.key}>
                                <td style={cellStyle}><input id={`${f.key}_name`} type='text' value={f.name} className='form-control' onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.nameChanged(f.key, e.currentTarget.value)} style={inlineControlStyle} /></td>
                                <td style={cellStyle} className={isNullOrEmpty(f.description) && !isNullOrEmpty(f.name) ? 'has-error' : ''}><textarea id={`${f.key}_description`} value={f.description} className='form-control' onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => this.descriptionChanged(f.key, e.currentTarget.value)} rows={2} style={({ ...inlineControlStyle, width: '250px' })} /></td>
                                <td style={cellStyle}><select id={`${f.key}_type`} className='form-control' onChange={e => this.typeChanged(f.key, e.currentTarget.value)} value={f.type} style={inlineControlStyle}>{typeOptions}</select></td>
                                {showValuesColumn ? <td>{f.type === CustomFieldType.List ? <input id={`${f.key}_values`} type='text' value={(f.values || []).join(',')} className='form-control' onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.valuesChanged(f.key, e.currentTarget.value)} style={({ ...inlineControlStyle, width: '250px' })} /> : null}</td> : null}
                                <td className='text-center'><input id={`${f.key}_required`} type='checkbox' checked={f.required} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.requiredChanged(f.key, e.currentTarget.checked)} style={inlineControlStyle} /></td>
                                <td className='text-center'><input id={`${f.key}_customerCanEnter`} type='checkbox' checked={!f.adminOnly} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.customerCanEnterChanged(f.key, e.currentTarget.checked)} style={inlineControlStyle} /></td>
                                {allowMultipleCategoriesPerCustomer && fieldUsage === FieldUsage.Registration ? <td style={cellStyle}><select id={`${f.key}_appliesTo`} className='form-control' onChange={e => this.appliesToChanged(f.key, e.currentTarget.value)} value={f.appliesTo} style={inlineControlStyle}>{appliesToOptions}</select></td> : null }
                                <td><span onClick={e => clickHandler(e, () => this.removeField(f.key))} className='glyphicon glyphicon-trash red' style={({ cursor: 'pointer', padding: '5px' })}></span></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {errors.length > 0 ? <div className='alert alert-danger'>{errors.map(e => <div>{`${(isNullOrEmpty(e.name) ? '' : e.name + ': ')}`}{t(e.errorKey)}</div>)}</div> : null}
            </div>
        );
    }
}