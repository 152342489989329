
import * as React from 'react';
import * as PropTypes from 'prop-types'
import moment from 'moment';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../../../store';

import * as DiaryActions from '../../../store/pages/diary/actions';
import * as ModalActions from '../../../store/global/modal/actions';
import * as api from '../../../store/apiClient';
import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import { clickHandler } from '../../../utils/util';
import ApiError from '../../global/apiError';
import { Venue } from '../../../store/pages/venues/types';

interface EditDiaryNotesState {
    notes: ct.FormValue<string>;
}

interface LocalProps {
    venue: Venue;
    date: moment.Moment;
    notes: string;
}

interface LocalState {
    isSavingDiaryNotes: boolean;
    diaryNoteSaveError: api.ApiError | null;
}

interface Actions {
    saveNotes: (venueId: string, date: Date, text: string) => void;
    closeModal: () => void;
}

type EditDiaryNotesProps = LocalState & Actions & LocalProps;

class EditDiaryNotes extends React.Component<EditDiaryNotesProps, EditDiaryNotesState> {

    constructor(props: EditDiaryNotesProps) {
        super(props);

        this.state = { notes: this.validateNotes(props.notes) };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    validateNotes = (val: string) => v.validate(val, 'notes', [], []);

    saveNotes = (newNotes: string) => {
        const { saveNotes, venue, date } = this.props;
        saveNotes(venue.id, date.toDate(), newNotes);
    }

    save = () => {
        const { notes } = this.state;
        this.saveNotes(notes.value);
    }

    componentDidUpdate(prevProps: EditDiaryNotesProps) {
        const { isSavingDiaryNotes: prevIsSavingDiaryNotes } = prevProps;
        const { isSavingDiaryNotes: nextIsSavingDiaryNotes, diaryNoteSaveError, closeModal } = this.props;

        // Close modal overlay if diary note save is complete
        if (prevIsSavingDiaryNotes && !nextIsSavingDiaryNotes && !diaryNoteSaveError) {
            closeModal();
        }
    }

    render() {
        const { venue, date, closeModal, isSavingDiaryNotes, diaryNoteSaveError } = this.props;
        const { notes } = this.state;
        const { t } = this.context;

        const message = (diaryNoteSaveError) ? <ApiError error={diaryNoteSaveError} /> : null;

        return (
            <div>
                <h3>{t('EditDiaryNotes:heading', { date: date.toDate().toAbbrDateString(venue.dateFormat, t) })}</h3>
                <div>
                    <ct.TextArea id='notes' labelKey='EditDiaryNotes:notesLabel' placeholderKey='EditDiaryNotes:notesLabel' rows={15} value={notes} callback={val => this.setState({ notes: this.validateNotes(val) })} style={({ marginTop: '15px' })} disabled={isSavingDiaryNotes} />
                </div>

                {message}

                <div className='btn-toolbar' style={({ marginTop: '20px' })}>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)} disabled={isSavingDiaryNotes}>{t('Global:save')}</button>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, closeModal)} disabled={isSavingDiaryNotes}>{t('Global:cancel')}</button>
                </div>
            </div>
        );
    }
}


const matStateToProps = (state: ApplicationState) => ({
    isSavingDiaryNotes: state.diary.isSavingDiaryNotes,
    diaryNoteSaveError: state.diary.diaryNoteSaveError,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    saveNotes: bindActionCreators(DiaryActions.actionCreators.saveDiaryNote, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    matStateToProps,
    mapDispatchToProps
)(EditDiaryNotes);
