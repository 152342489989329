
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { ITranslationContext } from '../../../../translations';
import { ActivityFormat, ActivityFormatLink } from '../../../../store/pages/activityFormats/types';
import { clickHandler } from '../../../../utils/util';

interface ActivityFormatAdditionalActivityProps {
    activityFormats: ActivityFormat[];
    linkedActivityFormat: ActivityFormatLink;
    editLinkedActivityFormat: (linkedActivityFormat: ActivityFormatLink) => void;
    removeLinkedActivityFormat: (key: string) => void;
}

const ActivityFormatAdditionalActivity = (props: ActivityFormatAdditionalActivityProps, context: ITranslationContext) => {
    const { t } = context;
    const { activityFormats, linkedActivityFormat, editLinkedActivityFormat, removeLinkedActivityFormat } = props;

    const { linkedActivityFormatId, offerAsAlternative, offerAsUpsell, minGapBeforeMainActivity,
        maxGapBeforeMainActivity, minGapAfterMainActivity, maxGapAfterMainActivity, maxUpsellTimeSlotsBefore, maxUpsellTimeSlotsAfter } = linkedActivityFormat;

    const deleteStyle = ({ marginTop: '8px', marginLeft: '10px', color: '#a94442' });

    const lnkActivityformat = activityFormats.find(af => af.id === linkedActivityFormatId);

    return <tr key={linkedActivityFormat.key} onClick={e => clickHandler(e, () => editLinkedActivityFormat(linkedActivityFormat))}>
        <td className='btn-link btn-no-padding'>{lnkActivityformat ? lnkActivityformat.name : ''}</td>
        <td className='btn-link btn-no-padding text-center'>{offerAsAlternative ? t('Global:yes') : ''}</td>
        <td className='btn-link btn-no-padding text-center'>{offerAsUpsell ? t('Global:yes') : ''}</td>
        <td className='btn-link btn-no-padding text-center'>{minGapBeforeMainActivity ? minGapBeforeMainActivity.toShortTimeString() : ''}</td>
        <td className='btn-link btn-no-padding text-center'>{maxGapBeforeMainActivity ? maxGapBeforeMainActivity.toShortTimeString() : ''}</td>
        <td className='btn-link btn-no-padding text-center'>{minGapAfterMainActivity ? minGapAfterMainActivity.toShortTimeString() : ''}</td>
        <td className='btn-link btn-no-padding text-center'>{maxGapAfterMainActivity ? maxGapAfterMainActivity.toShortTimeString() : ''}</td>
        <td className='btn-link btn-no-padding text-center'>{maxUpsellTimeSlotsBefore}</td>
        <td className='btn-link btn-no-padding text-center'>{maxUpsellTimeSlotsAfter}</td>
        <td style={({ verticalAlign: 'top' })}><span className='glyphicon glyphicon-trash red' onClick={e => clickHandler(e, () => removeLinkedActivityFormat(linkedActivityFormat.key))} style={deleteStyle}></span></td>
    </tr>
}

ActivityFormatAdditionalActivity.contextTypes = {
    t: PropTypes.func
}

export default ActivityFormatAdditionalActivity;