
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'
import moment from 'moment';
import Datetime, { ViewMode } from 'react-datetime';

import * as api from '../../../../store/apiClient';
import { ApplicationState } from '../../../../store';
import { Promotion, PromotionCriteriaType, PromotionCustomerRestriction, PromotionCustomerRestrictionPeriod, PromotionDiscountType, PromotionItemType, PromotionStatus, PromotionTimeRestriction } from '../../../../store/pages/promotions/types';
import * as ct from '../../../global/controls';
import * as v from '../../../global/validation';
import * as PromotionActions from '../../../../store/pages/promotions/actions';
import * as ModalActions from '../../../../store/global/modal/actions';
import TagSelection from '../../../global/tagSelection';
import { clickHandler, isNullOrEmpty } from '../../../../utils/util';
import ApiError from '../../../global/apiError';
import { Days, Time, ValidationError } from '../../../../store/global/types';
import { SpecialTagType, Tag, TagInfo } from '../../../../store/pages/tags/types';
import { Product, ProductPricingMode, ProductType } from '../../../../store/pages/products/types';
import { DateFormat, TimeFormat } from '../../../../store/pages/venues/types';
import { mapTimeFormat } from '../../../global/controls';

interface LocalProps {
    isNew: boolean;
    venueId: string;
    promotion: Promotion | null;
    dateFormat: DateFormat;
    timeFormat: TimeFormat;
}

interface MappedReduxState {
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
    products: Product[];
    tags: TagInfo[];
}

interface MappedReduxActions {
    savePromotion: (isNew: boolean, promotionId: string | null, promotion: Promotion) => void;
    closeModal: () => void;
}

type PromotionFormProps = MappedReduxActions & MappedReduxState & LocalProps;

interface PromotionFormState {
    name: ct.FormValue<string>;
    code: ct.FormValue<string>;
    availableForWebBookings: ct.FormValue<boolean>;
    startDate: ct.FormValue<moment.Moment>;
    endDate: ct.FormValue<moment.Moment | null>;
    validForEventsFrom: ct.FormValue<moment.Moment | null>;
    validForEventsUntil: ct.FormValue<moment.Moment | null>;
    maximumBudget: ct.FormValue<number | null>;
    criteriaType: ct.FormValue<PromotionCriteriaType>;
    criteriaQuantity: ct.FormValue<number>;
    discountItemType: ct.FormValue<PromotionItemType>;
    quantityToDiscount: ct.FormValue<number>;
    maxQuantityToDiscount: ct.FormValue<number>;
    maxNumberOfTimesToApply: ct.FormValue<number>;
    discountType: ct.FormValue<PromotionDiscountType>;
    discount: ct.FormValue<number | null>;
    accountingDepartment: ct.FormValue<string>;
    accountingCategory: ct.FormValue<string>;
    nominalCode: ct.FormValue<string>;
    triggerTags: Tag[];
    itemTags: Tag[];
    itemRestrictionTags: Tag[];
    timeRestrictions: PromotionTimeRestriction[];
    maxRedemptionsPerCustomer: ct.FormValue<number | null>;
    customerRestriction: ct.FormValue<PromotionCustomerRestriction>;
    customerRestrictionPeriod: ct.FormValue<PromotionCustomerRestrictionPeriod>;
    customerRestrictionPeriodValue: ct.FormValue<number | null>;
    customerRestrictionTagId: ct.FormValue<string | null>;
    waiveBookingFee: ct.FormValue<boolean>;
    errorMessageKey: string | null;
}

class PromotionForm extends React.Component<PromotionFormProps, PromotionFormState> {

    constructor(props: PromotionFormProps) {
        super(props);

        this.state = this.buildStateFromProps(this.props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps(props: PromotionFormProps): PromotionFormState {

        const { promotion } = props;

        const availableForWebBookings = !promotion ? false : promotion.availableForWebBookings;
        const discountType = !promotion ? PromotionDiscountType.Fixed : promotion.discountType;
        const cusRestriction = !promotion ? PromotionCustomerRestriction.None : promotion.customerRestriction;

        return {
            name: this.validateName(!promotion ? '' : promotion.name),
            code: this.validateCode(!promotion ? '' : promotion.code, availableForWebBookings),
            availableForWebBookings: this.validateAvailableForWebBookings(availableForWebBookings),
            startDate: this.validateStartDate(!promotion ? moment() : moment(promotion.startDate)),
            endDate: this.validateEndDate((!promotion || !promotion.endDate) ? null : moment(promotion.endDate)),
            validForEventsFrom: this.validateValidForEventsFrom((!promotion || !promotion.validForEventsFrom) ? null : moment(promotion.validForEventsFrom), false),
            validForEventsUntil: this.validateValidForEventsUntil((!promotion || !promotion.validForEventsUntil) ? null : moment(promotion.validForEventsUntil), false),
            maximumBudget: this.validateMaximumBudget(!promotion ? null : promotion.maximumBudget),
            criteriaType: this.validateCriteriaType(!promotion ? PromotionCriteriaType.None : promotion.criteriaType),
            criteriaQuantity: this.validateCriteriaQuantity(!promotion ? 1 : promotion.criteriaQuantity + promotion.quantityToDiscount),
            discountItemType: this.validateDiscountItemType(!promotion ? PromotionItemType.OverallBillAmount : promotion.discountItemType),
            quantityToDiscount: this.validateQuantityToDiscount(!promotion ? 1 : promotion.quantityToDiscount),
            maxQuantityToDiscount: this.validateMaxQuantityToDiscount(!promotion ? 1 : promotion.maxQuantityToDiscount),
            maxNumberOfTimesToApply: this.validateMaxNumberOfTimesToApply(!promotion ? 1 : promotion.maxNumberOfTimesToApply),
            discountType: this.validateDiscountType(discountType),
            discount: this.validateDiscount(!promotion ? null : promotion.discount, availableForWebBookings, discountType),
            accountingDepartment: this.validateAccountingDepartment(!promotion ? '' : promotion.accountingDepartment),
            accountingCategory: this.validateAccountingCategory(!promotion ? '' : promotion.accountingCategory),
            nominalCode: this.validateNominalCode(!promotion ? '' : promotion.nominalCode),
            triggerTags: !promotion ? [] : promotion.triggerTags,
            itemTags: !promotion ? [] : promotion.itemTags,
            itemRestrictionTags: !promotion ? [] : promotion.itemRestrictionTags,
            maxRedemptionsPerCustomer: this.validateMaxRedemptionsPerCustomer(!promotion ? null : promotion.maxRedemptionsPerCustomer),
            customerRestriction: this.validateCustomerRestriction(cusRestriction),
            customerRestrictionPeriod: this.validateCustomerRestrictionPeriod(!promotion ? PromotionCustomerRestrictionPeriod.Day : promotion.customerRestrictionPeriod, cusRestriction),
            customerRestrictionPeriodValue: this.validateCustomerRestrictionPeriodValue(!promotion ? null : promotion.customerRestrictionPeriodValue, cusRestriction),
            customerRestrictionTagId: this.validateCustomerRestrictionTag(!promotion ? null : promotion.customerRestrictionTagId, cusRestriction),
            timeRestrictions: !promotion ? [] : promotion.timeRestrictions,
            waiveBookingFee: this.validateWaiveBookingFee(!promotion ? false : promotion.waiveBookingFee),
            errorMessageKey: null
        };
    }

    componentDidUpdate(prevProps: PromotionFormProps) {
        // Only update state is tax rate has changed
        const { promotion: prevPromotion, saveComplete: prevSaveComplete, isSaving: prevIsSaving } = prevProps;
        const { promotion, saveComplete, isSaving, validationErrors } = this.props;

        if ((!prevPromotion && promotion) || (prevPromotion && !promotion) || (prevPromotion && promotion && prevPromotion.id !== promotion.id)) {
            this.setState(this.buildStateFromProps(this.props));
        }

        if (saveComplete && !prevSaveComplete) {
            setTimeout(() => { this.close(); }, 750);
        } else if (prevIsSaving && !isSaving) {
            this.setState(s => {
                const newState = v.updateServerErrors(s, validationErrors);
                return newState;
            });
        }
    }

    savePromotion = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }

    close = () => {
        this.props.closeModal();
    }

    save = () => {
        if (!v.isValid(this.state)) {
            this.setState({ errorMessageKey: 'Global:formNotValid' })
        } else {
            this.setState({ errorMessageKey: null }, () => {

                const { isNew, promotion, venueId, savePromotion } = this.props;
                const { name, code, availableForWebBookings, startDate, endDate, validForEventsUntil, maximumBudget, criteriaType, criteriaQuantity, discountItemType,
                    quantityToDiscount, maxQuantityToDiscount, maxNumberOfTimesToApply, discountType, discount, accountingDepartment, accountingCategory, nominalCode,
                    triggerTags, itemTags, itemRestrictionTags, maxRedemptionsPerCustomer, timeRestrictions, customerRestriction, customerRestrictionPeriod,
                    customerRestrictionPeriodValue, customerRestrictionTagId, validForEventsFrom, waiveBookingFee } = this.state;

                const promotionId = isNew || !promotion ? null : promotion.id;
                savePromotion(isNew, promotionId, {
                    id: promotionId || '',
                    venueId: venueId,
                    name: name.value,
                    code: code.value,
                    availableForWebBookings: availableForWebBookings.value,
                    startDate: startDate.value.toDate(),
                    endDate: endDate.value ? endDate.value.toDate() : null,
                    validForEventsFrom: validForEventsFrom.value ? validForEventsFrom.value.toDate() : null,
                    validForEventsUntil: validForEventsUntil.value ? validForEventsUntil.value.toDate() : null,
                    maximumBudget: maximumBudget.value ? maximumBudget.value : null,
                    criteriaType: criteriaType.value,
                    criteriaQuantity: criteriaQuantity.value - quantityToDiscount.value,
                    discountItemType: discountItemType.value,
                    quantityToDiscount: quantityToDiscount.value,
                    maxQuantityToDiscount: maxQuantityToDiscount.value,
                    maxNumberOfTimesToApply: maxNumberOfTimesToApply.value,
                    discountType: discountType.value,
                    discount: discount.value || 0,
                    accountingDepartment: accountingDepartment.value,
                    accountingCategory: accountingCategory.value,
                    nominalCode: nominalCode.value,
                    itemTags: itemTags,
                    itemRestrictionTags: itemRestrictionTags,
                    triggerTags: triggerTags,
                    state: PromotionStatus.Active,
                    totalAmountRedeemed: 0,
                    maxRedemptionsPerCustomer: maxRedemptionsPerCustomer.value,
                    customerRestriction: customerRestriction.value,
                    customerRestrictionPeriod: customerRestrictionPeriod.value,
                    customerRestrictionPeriodValue: customerRestrictionPeriodValue.value,
                    customerRestrictionTagId: customerRestrictionTagId.value,
                    timeRestrictions: timeRestrictions,
                    waiveBookingFee: waiveBookingFee.value
                });
            });
        }
    }

    validateName = (val: string) => v.validate(val, 'name', [v.required], []);
    validateCode = (val: string, availableOnWeb: boolean) => v.validate(val, 'code', availableOnWeb ? [v.required] : [], []);
    validateAvailableForWebBookings = (val: boolean) => v.validate(val, 'availableForWebBookings', [], []);
    validateStartDate = (val: moment.Moment) => v.validate(val, 'startDate', [v.required], []);
    validateEndDate = (val: moment.Moment | null) => v.validate(val, 'endDate', [], []);
    validateValidForEventsFrom = (val: moment.Moment | null, dateOverlap: boolean) => v.validate(val, 'validForEventsFrom', dateOverlap ? [() => ''] : [], []);
    validateValidForEventsUntil = (val: moment.Moment | null, dateOverlap: boolean) => v.validate(val, 'validForEventsUntil', dateOverlap ? [() => ''] : [], []);
    validateMaximumBudget = (val: number | null) => v.validate(val, 'maximumBudget', [], []);
    validateCriteriaType = (val: PromotionCriteriaType) => v.validate(val, 'criteriaType', [], []);
    validateCriteriaQuantity = (val: number) => v.validate(val, 'criteriaQuantity', [], []);
    validateDiscountItemType = (val: PromotionItemType) => v.validate(val, 'discountItemType', [], []);
    validateQuantityToDiscount = (val: number) => v.validate(val, 'quantityToDiscount', [], []);
    validateMaxQuantityToDiscount = (val: number) => v.validate(val, 'maxQuantityToDiscount', [], []);
    validateMaxNumberOfTimesToApply = (val: number) => v.validate(val, 'maxNumberOfTimesToApply', [], []);
    validateDiscountType = (val: PromotionDiscountType) => v.validate(val, 'discountType', [], []);
    validateDiscount = (val: number | null, webEnabled: boolean, discountType: PromotionDiscountType) => v.validate(val, 'discount', [v.required, v.numeric, () => this.checkDiscountRequired(val, webEnabled, discountType)], []);
    validateAccountingDepartment = (val: string) => v.validate(val, 'accountingDepartment', [], []);
    validateAccountingCategory = (val: string) => v.validate(val, 'accountingCategory', [], []);
    validateNominalCode = (val: string) => v.validate(val, 'nominalCode', [], []);
    validateMaxRedemptionsPerCustomer = (val: number | null) => v.validate(val, 'maxRedemptionsPerCustomer', [], []);
    validateCustomerRestriction = (val: PromotionCustomerRestriction) => v.validate(val, 'customerRestriction', [], []);
    validateWaiveBookingFee = (val: boolean) => v.validate(val, 'waiveBookingFee', [], []);
    validateCustomerRestrictionPeriod = (val: PromotionCustomerRestrictionPeriod, cusRestriction: PromotionCustomerRestriction) => {
        const validation = cusRestriction === PromotionCustomerRestriction.ParticipatedInTheLast || cusRestriction === PromotionCustomerRestriction.BookingIntheLast ? [v.required] : [];
        return v.validate(val, 'customerRestrictionPeriod', validation, []);
    }
    validateCustomerRestrictionPeriodValue = (val: number | null, cusRestriction: PromotionCustomerRestriction) => {
        const validation = cusRestriction === PromotionCustomerRestriction.ParticipatedInTheLast || cusRestriction === PromotionCustomerRestriction.BookingIntheLast ? [v.required] : [];
        return v.validate(val, 'customerRestrictionPeriodValue', validation, []);
    }
    validateCustomerRestrictionTag = (val: string | null, cusRestriction: PromotionCustomerRestriction) => {
        const validation = cusRestriction === PromotionCustomerRestriction.Tag ? [v.required] : [];
        return v.validate(val, 'customerRestrictionTagId', validation, []);
    }

    checkDiscountRequired = (value: any, availableForWebBookings: boolean, discountType: PromotionDiscountType) => {
        if (availableForWebBookings && value === 0) {
            return 'PromotionForm:discountRequiredForWebBooking'
        }
        else if (discountType === PromotionDiscountType.Percentage && value > 100) {
            return 'PromotionForm:percentageGreaterThanZero'
        } else {
            return undefined;
        }
    }

    startDateChanged = (date: moment.Moment) => this.setState({ startDate: this.validateStartDate(date) });
    endDateChanged = (date: moment.Moment | null) => this.setState({ endDate: this.validateEndDate(date) });

    validForEventsDatesChanged = (fromEventDate: moment.Moment | null, toEventDate: moment.Moment | null) =>
        this.setState({
            validForEventsFrom: this.validateValidForEventsFrom(fromEventDate, fromEventDate && toEventDate && fromEventDate > toEventDate ? true : false),
            validForEventsUntil: this.validateValidForEventsUntil(toEventDate, fromEventDate && toEventDate && fromEventDate > toEventDate ? true : false)
        });

    onAvailableForWebBookingsChanged = (val: boolean) => this.setState(s => ({
        availableForWebBookings: this.validateAvailableForWebBookings(val),
        code: this.validateCode(s.code.value, val),
        discount: this.validateDiscount(s.discount.value, val, s.discountType.value)
    }))

    onCriteriaTypeChanged = (val: string) => {
        const intVal = parseInt(val);
        if (isNaN(intVal))
            return;

        this.setState({ criteriaType: this.validateCriteriaType(intVal) });
    }

    onPromotionItemTypeChanged = (val: string) => {
        const intVal = parseInt(val);
        if (isNaN(intVal))
            return;

        this.setState(s => ({
            discountItemType: this.validateDiscountItemType(intVal),
            quantityToDiscount: intVal === PromotionItemType.OverallBillAmount ? this.validateQuantityToDiscount(1) : s.quantityToDiscount,
            maxQuantityToDiscount: intVal === PromotionItemType.OverallBillAmount ? this.validateMaxQuantityToDiscount(1) : s.maxQuantityToDiscount,
        }));
    }

    onPromotionDiscountTypeChanged = (val: string) => {
        const intVal = parseInt(val);
        if (isNaN(intVal))
            return;

        this.setState({ discountType: this.validateDiscountType(intVal) });
    }

    toggleTimeRestrictionDay = (index: number, day: Days, selected: boolean) => this.setState(s => ({ timeRestrictions: s.timeRestrictions.map((r, ix) => ix === index ? { ...r, days: selected ? r.days |= day : r.days &= ~day } : r ) }))
    onTimeRestrictionFromChanged = (index: number, time: moment.Moment | string) => this.setState(s => ({ timeRestrictions: s.timeRestrictions.map((r, ix) => ix === index && moment.isMoment(time) ? { ...r, from: new Time(time.hour(), time.minute(), time.second()) } : r) }))
    onTimeRestrictionToChanged = (index: number, time: moment.Moment | string) => this.setState(s => ({ timeRestrictions: s.timeRestrictions.map((r, ix) => ix === index && moment.isMoment(time) ? { ...r, to: new Time(time.hour(), time.minute(), time.second()) } : r) }))
    removeTimeRestriction = (index: number) => this.setState(s => ({ timeRestrictions: s.timeRestrictions.filter((r, ix) => ix !== index) }))

    onCustomerRestrictionChanged = (val: string) => {
        const intVal = parseInt(val);
        if (isNaN(intVal))
            return;

        this.setState(s => ({
            customerRestriction: this.validateCustomerRestriction(intVal),
            customerRestrictionPeriodValue: this.validateCustomerRestrictionPeriodValue(s.customerRestrictionPeriodValue.value, intVal),
            customerRestrictionPeriod: this.validateCustomerRestrictionPeriod(s.customerRestrictionPeriod.value, intVal),
            customerRestrictionTagId: this.validateCustomerRestrictionTag(s.customerRestrictionTagId.value, intVal)
        }));
    }

    onCustomerPeriodRestrictionChanged = (val: number) => {
        this.setState(s => ({customerRestrictionPeriodValue: this.validateCustomerRestrictionPeriodValue(val, s.customerRestriction.value)}))
    }

    onCustomerRestrictionPeriodChanged = (val: string) => {
        const intVal = parseInt(val);
        if (isNaN(intVal))
            return;
        this.setState(s => ({ customerRestrictionPeriod: this.validateCustomerRestrictionPeriod(intVal, s.customerRestriction.value) }));
    }

    onCustomerRestrictionTagChanged = (val: string | null) => {
        this.setState(s => ({ customerRestrictionTagId: this.validateCustomerRestrictionTag(isNullOrEmpty(val) ? null : val, s.customerRestriction.value) }));
    }

    render() {
        const { name, code, availableForWebBookings, startDate, endDate, validForEventsUntil, maximumBudget, criteriaType, criteriaQuantity, discountItemType,
            quantityToDiscount, maxQuantityToDiscount, maxNumberOfTimesToApply, discountType, discount, accountingDepartment, accountingCategory, nominalCode,
            triggerTags, itemTags, itemRestrictionTags, maxRedemptionsPerCustomer, timeRestrictions, errorMessageKey, customerRestriction,
            customerRestrictionPeriod, customerRestrictionPeriodValue, customerRestrictionTagId, validForEventsFrom, waiveBookingFee } = this.state;
        const { venueId, products, tags, isNew, saveError, saveComplete, dateFormat, timeFormat } = this.props;
        const { t } = this.context;

        let message: any;

        if (saveError) {
            message = <ApiError error={saveError} />;
        } else if (errorMessageKey) {
            message = (<div className='alert alert-danger'>{t(errorMessageKey)}</div>);
        } else if (saveComplete) {
            message = (<div className='bg-success'>{t('Global:saveComplete')}</div>);
        }

        const criteriaTypeOptions = [PromotionCriteriaType.None, PromotionCriteriaType.Participant, PromotionCriteriaType.Activity].map(k => ({ key: k.toString(), name: t(`PromotionCriteriaType:${PromotionCriteriaType[k]}`) }));
        const promotionItemTypeOptions = [PromotionItemType.OverallBillAmount, PromotionItemType.Participant, PromotionItemType.Activity, PromotionItemType.Item].map(k => ({ key: k.toString(), name: t(`PromotionItemType:${PromotionItemType[k]}`) }));
        const promotionDiscountTypeOptions = [PromotionDiscountType.Fixed, PromotionDiscountType.Percentage].map(k => ({ key: k.toString(), name: t(`PromotionDiscountType:${PromotionDiscountType[k]}`) }));
        const disableValidForEventsUntil = endDate.value === null;
        const productTagIds = products.filter(p => p.venueId === venueId || p.type == ProductType.Voucher).reduce<string[]>((prdTags, p) => prdTags.concat(p.tags.map(t => t.tagId).filter(t => prdTags.indexOf(t) < 0)), []);

        const selectedCriteriaTypeName = PromotionCriteriaType[criteriaType.value];
        const criteriaQuantityHintKey = criteriaType.value === PromotionCriteriaType.None ? '' : `PromotionForm:criteriaQuantityHint_${selectedCriteriaTypeName}`;

        const quantityToDiscountLabel = `PromotionForm:quantityToDiscount_${PromotionItemType[discountItemType.value]}`;
        const maxQuantityToDiscountLabel = `PromotionForm:maxQuantityToDiscount_${PromotionItemType[discountItemType.value]}`;
        const maxNumberOfTimesToApplyLabel = `PromotionForm:maxNumberOfTimesToApply_${PromotionItemType[discountItemType.value]}`;

        const dayStyle = { width: '30px', verticalAlign: 'middle' };
        return <div className='promotionPage'>
            <h1 className='promotion_title'>{isNew ? this.context.t('PromotionForm:addPromotion') : t('PromotionForm:editPromotion')}</h1>

            <form className='data-form' onSubmit={this.savePromotion} autoComplete='off'>
                <ct.TextBox id='name' labelKey='Global:name' placeholderKey='Global:name' value={name} callback={val => this.setState({ name: this.validateName(val) })} />
                <ct.TextBox id='code' labelKey='Global:code' placeholderKey='Global:code' value={code} callback={val => this.setState({ code: this.validateCode(val, availableForWebBookings.value) })} />
                <ct.Checkbox id='availableForWebBookings' labelKey='PromotionForm:availableForWebBookings' hintKey='PromotionForm:availableForWebBookingshelpText'  value={availableForWebBookings} callback={this.onAvailableForWebBookingsChanged} />
                <ct.Checkbox id='waiveBookingFee' labelKey='PromotionForm:waiveBookingFee' hintKey='PromotionForm:waiveBookingFee' value={waiveBookingFee} callback={val => this.setState({ waiveBookingFee: this.validateWaiveBookingFee(val)})} />

                <ct.DatePicker id='startDate' labelKey='PromotionForm:startDate' value={startDate} callback={this.startDateChanged} dateFormat={dateFormat} timeFormat={timeFormat} />
                <ct.DatePicker id='endDate' labelKey='PromotionForm:endDate' value={endDate} callback={this.endDateChanged} dateFormat={dateFormat} timeFormat={timeFormat} />
                <ct.DatePicker id='validForEventsFrom' labelKey='PromotionForm:validForEventsFrom' value={validForEventsFrom} dateFormat={dateFormat} timeFormat={timeFormat} callback={val => this.validForEventsDatesChanged(val, validForEventsUntil.value)} disabled={disableValidForEventsUntil} />
                <ct.DatePicker id='validForEventsUntil' labelKey='PromotionForm:validForEventsUntil' value={validForEventsUntil} dateFormat={dateFormat} timeFormat={timeFormat} callback={val => this.validForEventsDatesChanged(validForEventsUntil.value, val)} disabled={disableValidForEventsUntil} />

                <ct.NumberBox id='maximumBudget' labelKey='PromotionForm:maximumBudget' placeholderKey='PromotionForm:maximumBudget' hintKey='PromotionForm:maximumBudgetHint' value={maximumBudget} callback={val => this.setState({ maximumBudget: this.validateMaximumBudget(val) })} step='1' min='0' />

                <ct.NumberBox id='maxRedemptionsPerCustomer' labelKey='PromotionForm:maxRedemptionsPerCustomer' placeholderKey='PromotionForm:maxRedemptionsPerCustomer' value={maxRedemptionsPerCustomer} callback={val => this.setState({ maxRedemptionsPerCustomer: this.validateMaxRedemptionsPerCustomer(val && val < 1 ? null : val) })} step='1' min='0' />

                <label>{t('PromotionForm:customerRestrictions')}</label>
                {this.renderCustomerRestrictions(customerRestriction, customerRestrictionPeriod, customerRestrictionPeriodValue, customerRestrictionTagId, tags)}

                <label>{t('PromotionForm:timeRestrictions')}</label>
                <table className='table table-condensed' style={{ width: 'auto' }}>
                    <thead>
                        <tr>
                            <th>{t('Global:Monday2ch')}</th>
                            <th>{t('Global:Tuesday2ch')}</th>
                            <th>{t('Global:Wednesday2ch')}</th>
                            <th>{t('Global:Thursday2ch')}</th>
                            <th>{t('Global:Friday2ch')}</th>
                            <th>{t('Global:Saturday2ch')}</th>
                            <th>{t('Global:Sunday2ch')}</th>
                            <th>{t('PromotionForm:fromTime')}</th>
                            <th>{t('PromotionForm:toTime')}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {timeRestrictions.map((r, ix) => <tr>
                            <td className='btn-link btn-no-padding text-center' style={dayStyle}><input type='checkbox' checked={(r.days & Days.Monday) === Days.Monday} onChange={x => this.toggleTimeRestrictionDay(ix, Days.Monday, x.currentTarget.checked)} /></td>
                            <td className='btn-link btn-no-padding text-center' style={dayStyle}><input type='checkbox' checked={(r.days & Days.Tuesday) === Days.Tuesday} onChange={x => this.toggleTimeRestrictionDay(ix, Days.Tuesday, x.currentTarget.checked)} /></td>
                            <td className='btn-link btn-no-padding text-center' style={dayStyle}><input type='checkbox' checked={(r.days & Days.Wednesday) === Days.Wednesday} onChange={x => this.toggleTimeRestrictionDay(ix, Days.Wednesday, x.currentTarget.checked)} /></td>
                            <td className='btn-link btn-no-padding text-center' style={dayStyle}><input type='checkbox' checked={(r.days & Days.Thursday) === Days.Thursday} onChange={x => this.toggleTimeRestrictionDay(ix, Days.Thursday, x.currentTarget.checked)} /></td>
                            <td className='btn-link btn-no-padding text-center' style={dayStyle}><input type='checkbox' checked={(r.days & Days.Friday) === Days.Friday} onChange={x => this.toggleTimeRestrictionDay(ix, Days.Friday, x.currentTarget.checked)} /></td>
                            <td className='btn-link btn-no-padding text-center' style={dayStyle}><input type='checkbox' checked={(r.days & Days.Saturday) === Days.Saturday} onChange={x => this.toggleTimeRestrictionDay(ix, Days.Saturday, x.currentTarget.checked)} /></td>
                            <td className='btn-link btn-no-padding text-center' style={dayStyle}><input type='checkbox' checked={(r.days & Days.Sunday) === Days.Sunday} onChange={x => this.toggleTimeRestrictionDay(ix, Days.Sunday, x.currentTarget.checked)} /></td>
                            <td className='btn-link btn-no-padding text-center'><Datetime value={moment({ hour: r.from.getHours(), minute: r.from.getMinutes() })} onChange={val => this.onTimeRestrictionFromChanged(ix, val)} dateFormat={false} className='rdt-inline' timeFormat={mapTimeFormat(timeFormat)} /></td>
                            <td className='btn-link btn-no-padding text-center'><Datetime value={moment({ hour: r.to.getHours(), minute: r.to.getMinutes() })} onChange={val => this.onTimeRestrictionToChanged(ix, val)} dateFormat={false} className='rdt-inline' timeFormat={mapTimeFormat(timeFormat)} /></td>
                            <td><span className='glyphicon glyphicon-trash red' onClick={e => clickHandler(e, () => this.removeTimeRestriction(ix))} style={({ cursor: 'pointer', padding: '5px' })}></span></td>
                        </tr>)}
                    </tbody>
                </table>
                <div>
                    <button className='btn btn-link' onClick={e => clickHandler(e, () => this.setState(s => ({ timeRestrictions: s.timeRestrictions.concat({ days: Days.None, from: new Time(0, 0, 0), to: new Time(23, 59, 0) }) })))}>{t('Global:add')}</button>
                </div>

                <div className='data-form-section'>
                    <h4>{t('PromotionForm:requirementsSectionHeader')}</h4>
                    <div>{t('PromotionForm:requirementsSectionDescription')}</div>

                    <ct.Select id='criteriaType' labelKey='PromotionForm:criteriaType' value={ct.asFormValue('criteriaType', criteriaType.value.toString())} callback={this.onCriteriaTypeChanged} options={criteriaTypeOptions} />

                    {criteriaType.value !== PromotionCriteriaType.None
                        ? <>
                            <ct.NumberBox id='criteriaQuantity' labelKey={`PromotionForm:criteriaQuantity_${selectedCriteriaTypeName}`} placeholderKey={`PromotionForm:criteriaQuantity_${selectedCriteriaTypeName}`} hintKey={criteriaQuantityHintKey} value={criteriaQuantity} callback={val => this.setState({ criteriaQuantity: this.validateCriteriaQuantity(val || 1) })} step='1' min='1' />
                            <div className='row'>
                                <div className='col-xs-12'>
                                    <label>{t('PromotionForm:triggerTags')}</label>
                                    <TagSelection selectedTags={triggerTags} canCreateTag={false} specialTagType={SpecialTagType.None} tagAdded={(tag: Tag) => this.setState(s => ({ triggerTags: s.triggerTags.concat(tag) }))} removeTag={tagId => this.setState(s => ({ triggerTags: s.triggerTags.filter(t => t.id !== tagId) }))} tagFilter={t => productTagIds.indexOf(t.id) >= 0} />
                                </div>
                            </div>
                        </>
                        : null
                    }

                </div>

                <div className='data-form-section'>
                    <h4>{t('PromotionForm:itemsSectionHeader')}</h4>
                    <div>{t('PromotionForm:itemsSectionDescription')}</div>
                    <ct.Select id='itemType' labelKey='PromotionForm:promotionItemType' value={ct.asFormValue('type', discountItemType.value.toString())} callback={this.onPromotionItemTypeChanged} options={promotionItemTypeOptions} />

                    {discountItemType.value !== PromotionItemType.OverallBillAmount && discountItemType.value !== PromotionItemType.None
                        ? <>
                            <ct.NumberBox id='quantityToDiscount' labelKey={quantityToDiscountLabel} placeholderKey={quantityToDiscountLabel} value={quantityToDiscount} callback={val => this.setState({ quantityToDiscount: this.validateQuantityToDiscount(val || 1) })} step='1' min='1' />
                            <ct.NumberBox id='maxQuantityToDiscount' labelKey={maxQuantityToDiscountLabel} placeholderKey={maxQuantityToDiscountLabel} value={maxQuantityToDiscount} callback={val => this.setState({ maxQuantityToDiscount: this.validateMaxQuantityToDiscount(val || 1) })} step='1' min='1' />
                            <ct.NumberBox id='maxNumberOfTimesToApply' labelKey={maxNumberOfTimesToApplyLabel} placeholderKey={maxNumberOfTimesToApplyLabel} value={maxNumberOfTimesToApply} callback={val => this.setState({ maxNumberOfTimesToApply: this.validateMaxNumberOfTimesToApply(val || 1) })} step='1' min='1' />
                        </>
                        : null}

                    <div className='row'>
                        <div className='col-xs-12'>
                            <label>{t('PromotionForm:itemTags')}</label>
                            <TagSelection selectedTags={itemTags} canCreateTag={false} specialTagType={SpecialTagType.None} tagAdded={(tag: Tag) => this.setState(s => ({ itemTags: s.itemTags.concat(tag) }))} removeTag={tagId => this.setState(s => ({ itemTags: s.itemTags.filter(t => t.id !== tagId) }))} tagFilter={t => productTagIds.indexOf(t.id) >= 0} />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-xs-12'>
                            <label>{t('PromotionForm:itemRestrictionTags')}</label>
                            <TagSelection selectedTags={itemRestrictionTags} canCreateTag={false} specialTagType={SpecialTagType.None} tagAdded={(tag: Tag) => this.setState(s => ({ itemRestrictionTags: s.itemRestrictionTags.concat(tag) }))} removeTag={tagId => this.setState(s => ({ itemRestrictionTags: s.itemRestrictionTags.filter(t => t.id !== tagId) }))} tagFilter={t => productTagIds.indexOf(t.id) >= 0} />
                        </div>
                    </div>

                </div>

                <ct.Select id='discountType' labelKey='PromotionForm:discountType' value={ct.asFormValue('discountType', discountType.value.toString())} callback={this.onPromotionDiscountTypeChanged} options={promotionDiscountTypeOptions} />

                <ct.NumberBox id='discount' labelKey='PromotionForm:discount' labelContent={<span style={{ marginLeft: '6px' }}>{t(discountType.value === PromotionDiscountType.Fixed ? 'Global:amount' : 'PromotionForm:percentage')}</span>} placeholderKey='PromotionForm:discount' value={discount} callback={val => this.setState({ discount: this.validateDiscount(val, availableForWebBookings.value, discountType.value) })} step='1' min='0' max={discountType.value === PromotionDiscountType.Percentage ? '100' : undefined} />

                <ct.TextBox id='accountingCategory' labelKey='PromotionForm:accountingCategory' placeholderKey='PromotionForm:accountingCategory' value={accountingCategory} callback={val => this.setState({ accountingCategory: this.validateAccountingCategory(val) })} />

                <ct.TextBox id='accountingDepartment' labelKey='PromotionForm:accountingDepartment' placeholderKey='PromotionForm:accountingDepartment' value={accountingDepartment} callback={val => this.setState({ accountingDepartment: this.validateAccountingDepartment(val) })} />

                <ct.TextBox id='nominalCode' labelKey='PromotionForm:nominalCode' placeholderKey='PromotionForm:nominalCode' value={nominalCode} callback={val => this.setState({ nominalCode: this.validateNominalCode(val) })} />

                {message}

                <p />
                <div className='btn-toolbar'>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)}>{this.context.t('Global:save')}</button>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{this.context.t('Global:cancel')}</button>
                </div>
                <p />
            </form>
        </div>;
    }

    renderCustomerRestrictions = (customerRestriction: ct.FormValue<PromotionCustomerRestriction>, customerRestrictionPeriod: ct.FormValue<PromotionCustomerRestrictionPeriod>, customerRestrictionPeriodValue: ct.FormValue<number | null>, customerRestrictionTagId: ct.FormValue<string | null>, tags: TagInfo[]) => {
        const { t } = this.context;

        const customerRestrictionPeriodOptions = [PromotionCustomerRestrictionPeriod.Day, PromotionCustomerRestrictionPeriod.Month, PromotionCustomerRestrictionPeriod.Year].map(k => ({ key: k.toString(), name: t(`PromotionCustomerRestrictionPeriod:${PromotionCustomerRestrictionPeriod[k]}`) }));
        const customerRestrictionOptions = [PromotionCustomerRestriction.None, PromotionCustomerRestriction.ParticipatedInTheLast, PromotionCustomerRestriction.BookingIntheLast, PromotionCustomerRestriction.Tag].map(k => ({ key: k.toString(), name: t(`PromotionCustomerRestriction:${PromotionCustomerRestriction[k]}`) }));

        let content: JSX.Element | null = null;

        if (customerRestriction.value === PromotionCustomerRestriction.BookingIntheLast || customerRestriction.value === PromotionCustomerRestriction.ParticipatedInTheLast) {
            content = <>
                <span className='promotion-page-customer-restriction-text'>{t('PromotionForm:inTheLast')}</span>
                <ct.NumberBox id='customerRestrictionPeriodValue' classNames='promotion-page-customer-restriction-value' labelKey='' value={customerRestrictionPeriodValue} callback={e => this.onCustomerPeriodRestrictionChanged(e || 0)} minimal={true} />
                <ct.Select classNames='promotion-page-customer-restriction-value-selection' id='customerRestrictionPeriod' labelKey='' value={{ ...customerRestrictionPeriod, value: customerRestrictionPeriod.value.toString() }} options={customerRestrictionPeriodOptions} callback={this.onCustomerRestrictionPeriodChanged} minimal={true} />
            </>
        }

        if (customerRestriction.value === PromotionCustomerRestriction.Tag) {
            const customerTags = tags.filter(t => t.customerCount > 0)
            const customerTagOptions = [{ key: '', name: t('Global:selectTag'), data: '' }].concat(customerTags.map(t => ({ key: t.id, name: t.name, data: t.colour })))
            content = <ct.Select id='customerRestrictionTag' classNames='promotion-page-customer-restriction-value-selection' labelKey='' value={{ ...customerRestrictionTagId, value: customerRestrictionTagId.value || '' }} callback={this.onCustomerRestrictionTagChanged} options={customerTagOptions} renderOption={o => isNullOrEmpty(o.data) ? <span key={o.key}>{o.name}</span> : <span key={o.key} className='label tag-label' style={({ backgroundColor: o.data })} >{o.name}</span>} minimal={true} />
        }

        return <div className='promotion-page-customer-restriction'>
            <ct.Select id='customerRestriction' classNames='promotion-page-customer-restriction-selection' labelKey='' value={{ ...customerRestriction, value: customerRestriction.value.toString() }} callback={this.onCustomerRestrictionChanged} options={customerRestrictionOptions} minimal={true} />
            {content}
        </div>
    }
};

const mapStateToProps = (state: ApplicationState) => ({
    isSaving: state.promotions.isSaving,
    saveComplete: state.promotions.saveComplete,
    saveError: state.promotions.saveError,
    validationErrors: state.promotions.validationErrors,
    products: state.products.products,
    tags: state.tags.tags
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
    savePromotion: bindActionCreators(PromotionActions.actionCreators.savePromotion, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,           // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(PromotionForm);
