
import * as React from 'react';
import { clickHandler } from '../../../utils/util';


interface ConfirmationOverlayProps {
    heading: string;
    message: string;
    affirmativeText: string;
    negativeText: string;
    confirm: () => void;
    cancel: () => void;
}

const ConfirmationOverlay = (props: ConfirmationOverlayProps) => {
    const { heading, message, affirmativeText, negativeText, confirm, cancel } = props;

    return (
        <div>
            <h3>{heading}</h3>
            <div>
                {message}
            </div>

            <div className='btn-toolbar' style={({marginTop: '20px'})}>
                <button className='btn btn-primary' onClick={e => clickHandler(e, confirm)}>{affirmativeText}</button>
                <button className='btn btn-basic' onClick={e => clickHandler(e, cancel)}>{negativeText}</button>
            </div>
        </div>
    );
}

export default ConfirmationOverlay;