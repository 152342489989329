
import * as React from 'react';

interface ResourceLabelProps {
    colour: string;
    name: string;
}

const ResourceLabel = (props: ResourceLabelProps) => {
    const { colour, name } = props;

    return <span style={{ backgroundColor: colour, color: '#fff', margin: '0 3px', padding: '0 5px' }}>{name}</span>
}

export default ResourceLabel;