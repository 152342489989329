
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as aft from './types'; 
import * as cct from '../customerCategories/types';
import { isNullOrEmpty, mapLocalDateTime  } from '../../../utils/util';
import { Time } from '../../global/types';
import { IStore } from '../..';


export type ActivityFormatActions = aft.LoadActivityFormats | aft.ReceiveActivityFormats | aft.SaveActivityFormat | aft.ActivityFormatSaved | aft.ActivityFormatSaveFailed | aft.EditActivityFormat;

export const actionCreators = {
    loadActivityFormats: () => ({ type: aft.ActivityFormatActionTypes.RequestActivityFormats }),
    receivedActivityFormats: (activityFormats: aft.ActivityFormat[], err: api.ApiError | null) => ({ type: aft.ActivityFormatActionTypes.ReceivedActivityFormats, activityFormats: activityFormats, error: err }),
    editActivityFormat: () => ({ type: aft.ActivityFormatActionTypes.EditActivityFormat }),
    saveActivityFormat: (isNew: boolean, activityFormatId: string | null, activityFormat: aft.ActivityFormat, img: File | null) => ({ type: aft.ActivityFormatActionTypes.SaveActivityFormat, isNew: isNew, activityFormatId: activityFormatId, activityFormat: activityFormat, img: img }),
    activityFormatSaved: (activityFormatId: string) => ({ type: aft.ActivityFormatActionTypes.ActivityFormatSaved, activityFormatId: activityFormatId }),
    activityFormatSaveFailed: (error: api.ApiError) => ({ type: aft.ActivityFormatActionTypes.ActivityFormatSaveFailed, error: error }),
}

interface IGetActivityFormatsResponse {
    activityFormats: aft.ActivityFormatDto[];
}

interface ISaveActivityFormatResponse {
    activityFormatId: string;
}

export const handleClientChange = (store: IStore) => [actionCreators.loadActivityFormats]


//https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-after-catching-and-processing-an-error-with-emitting-somethi

const loadActivityFormats = () => Observable.defer(() => api.getJson<IGetActivityFormatsResponse>(`api/v1/activityFormat/`))
    .map(response => ({
        type: aft.ActivityFormatActionTypes.ReceivedActivityFormats,
        activityFormats: response.activityFormats.map(f => ({
            ...f,
            arrivalTimeBeforeEventOverride: f.arrivalTimeBeforeEventOverride ? Time.parse(f.arrivalTimeBeforeEventOverride) : null,
            variations: f.variations.map(v => ({
                ...v,
                schedule: v.schedule.map(s => ({
                    ...s,
                    runningTime: Time.parse(s.runningTime),
                    minGapBefore: Time.parse(s.minGapBefore),
                    minGapAfter: Time.parse(s.minGapAfter),
                    minBreakDuration: s.minBreakDuration ? Time.parse(s.minBreakDuration) : null,
                    maxBreakDuration: s.maxBreakDuration ? Time.parse(s.maxBreakDuration) : null,
                }))
            })), availability: f.availability.map(a => ({
                ...a,
                key: a.id,
                fromTime: Time.parse(a.fromTime.toString()),
                toTime: Time.parse(a.toTime.toString()),
                startDate: mapLocalDateTime(a.startDate),
                endDate: mapLocalDateTime(a.endDate),
            })),
            linkedActivityFormats: f.linkedActivityFormats.map(la => ({
                ...la,
                minGapBeforeMainActivity: la.minGapBeforeMainActivity ? Time.parse(la.minGapBeforeMainActivity) : null,
                maxGapBeforeMainActivity: la.maxGapBeforeMainActivity ? Time.parse(la.maxGapBeforeMainActivity) : null,
                minGapAfterMainActivity: la.minGapAfterMainActivity ? Time.parse(la.minGapAfterMainActivity) : null,
                maxGapAfterMainActivity: la.maxGapAfterMainActivity ? Time.parse(la.maxGapAfterMainActivity) : null,
            }))
        }))
    }));

export const loadActivityFormatsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        aft.ActivityFormatActionTypes.RequestActivityFormats,
        action => loadActivityFormats(),
        err => actionCreators.receivedActivityFormats([], err));

export const reloadActivityFormatsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        aft.ActivityFormatActionTypes.ActivityFormatSaved,
        action => loadActivityFormats(),
        err => actionCreators.receivedActivityFormats([], err));

export const customerCategoriesChangedEpic = (action$: ActionsObservable<any>, store: any) =>
    action$.ofType(cct.CustomerCategoryActionTypes.CustomerCategorySaved)
        .switchMap(action => {
            return Observable.of(actionCreators.loadActivityFormats());
        });

export const saveActivityFormatEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        aft.ActivityFormatActionTypes.SaveActivityFormat,
        action => {
            const spa = action as aft.SaveActivityFormat;
            let activityFormatId = spa.activityFormatId;

            var body = ({
                ...spa.activityFormat,
                arrivalTimeBeforeEventOverride: spa.activityFormat.arrivalTimeBeforeEventOverride ? spa.activityFormat.arrivalTimeBeforeEventOverride.toString() : null,
                variations: spa.activityFormat.variations.map(v => ({
                    ...v,
                    schedule: v.schedule.map(s => ({
                        ...s, 
                        id: isNullOrEmpty(s.id) ? null : s.id,
                        runningTime: s.runningTime.toString(),
                        minGapBefore: s.minGapBefore.toString(),
                        minGapAfter: s.minGapAfter.toString(),
                        minBreakDuration: s.minBreakDuration ? s.minBreakDuration.toString() : null,
                        maxBreakDuration: s.maxBreakDuration ? s.maxBreakDuration.toString() : null,
                    }))
                })),
                availability: spa.activityFormat.availability.map(a => ({
                    ...a,
                    fromTime: a.fromTime.toString(),
                    toTime: a.toTime.toString(),
                    startDate: a.startDate ? a.startDate.toYMDDateString() : null,
                    endDate: a.endDate ? a.endDate.toYMDDateString() : null
                })),
                linkedActivityFormats: spa.activityFormat.linkedActivityFormats.map(la => ({
                    ...la,
                    minGapBeforeMainActivity: la.minGapBeforeMainActivity ? la.minGapBeforeMainActivity.toString() : null,
                    maxGapBeforeMainActivity: la.maxGapBeforeMainActivity ? la.maxGapBeforeMainActivity.toString() : null,
                    minGapAfterMainActivity: la.minGapAfterMainActivity ? la.minGapAfterMainActivity.toString() : null,
                    maxGapAfterMainActivity: la.maxGapAfterMainActivity ? la.maxGapAfterMainActivity.toString() : null,
                }))
            });

            const img = spa.img;

            const imageObservables: Observable<string>[] = [img ? api.uploadFile(img, 'api/v1/clientimage').map(res => res.response.imageId) : Observable.of(null)];

            const images = Observable.forkJoin(imageObservables);
            return images.flatMap(imgIds => {
                var bodyWithImg = {
                    ...body,
                    imageId: imgIds[0]
                };

                return (spa.isNew ? api.post('api/v1/activityFormat/', bodyWithImg) : api.put(`api/v1/activityFormat/${activityFormatId}`, bodyWithImg))
                    .map(response => {
                        if (spa.isNew) {
                            const strr = response.response as ISaveActivityFormatResponse;
                            if (strr) {
                                activityFormatId = strr.activityFormatId;
                            }
                        }

                        return ({ type: aft.ActivityFormatActionTypes.ActivityFormatSaved, activityFormatId: activityFormatId });
                    });
            });
        },
        (err: api.ApiError) => actionCreators.activityFormatSaveFailed(err));
