
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { ActivityFormatGroup } from '../../../../store/pages/activityFormatGroups/types';
import { ITranslationContext } from '../../../../translations';
import { stringComparer } from '../../../../utils/comparers';

interface ActivityFormatGroupsProps {
    groups: ActivityFormatGroup[];
    selectedActivityFormatGroupIds: string[];
    activityFormatGroupSelectionChanged: (activityFormatGroupId: string, selected: boolean) => void;
}

const ActivityFormatGroups = (props: ActivityFormatGroupsProps, context: ITranslationContext) => {
    const { groups, selectedActivityFormatGroupIds, activityFormatGroupSelectionChanged } = props;

    return (
        <div className='form-group'>
            <ul className='list-unstyled'>
                {
                    groups
                        .filter(g => !g.archived)
                        .sort((g1, g2) => stringComparer(g1.name, g2.name))
                        .map(grp => {
                            const selected = selectedActivityFormatGroupIds.includes(grp.id);

                            return (
                                <li key={grp.id}>
                                    <input type='checkbox' id={grp.id} checked={selected} onChange={(e: React.ChangeEvent<HTMLInputElement>) => activityFormatGroupSelectionChanged(grp.id, e.currentTarget.checked)} />
                                    <label htmlFor={grp.id} style={{ marginLeft: '8px' }}>{grp.name}</label>
                                </li>)
                        })
                }
            </ul>
        </div>
    );
}

ActivityFormatGroups.contextTypes = {
    t: PropTypes.func
}

export default ActivityFormatGroups;