
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { ITranslationContext } from '../../../../translations';
import { clickHandler } from '../../../../utils/util';
import { Days, Time } from '../../../../store/global/types';
import { ProdPrice } from './types';
import { DateFormat, TimeFormat } from '../../../../store/pages/venues/types';
import { Tag } from '../../../../store/pages/tags/types';
import { ProductPricePoint } from '../../../../store/pages/products/types';

interface ProductPriceOverridesProps {
    overridePrices: ProdPrice[];
    tags: Tag[];
    isFixed: boolean;
    dateFormat: DateFormat;
    timeFormat: TimeFormat;
    editPrice: (key: string) => void;
    addPriceOverride: () => void;
    removePrice: (key: string) => void;
}

const ProductPriceOverrides = (props: ProductPriceOverridesProps, context: ITranslationContext) => {
    const { t } = context;
    const { overridePrices, tags, isFixed, dateFormat, timeFormat, editPrice, addPriceOverride, removePrice } = props;
    const dayStyle = { width: '30px' }; 

    return (<>
        <label>{t('ProductForm:pricingOverrides')}</label>
        <table className='table table-super-condensed table-borderless' style={({ width: 'auto', margin: '0 0 15px 0' })}>
            {overridePrices.length > 0
                ? <thead>
                    <tr>
                        <th colSpan={4}>{t('ProductForm:applyPriceWhen')}</th>
                        {isFixed ? <th colSpan={2}></th> : null}
                        <th></th>
                        <th colSpan={9} className='center'>{t('ProductForm:dayTimeRestrictions')}</th>
                    </tr>
                    <tr>
                        <th>{t('ProductForm:priceEffectivePricePoint')}</th>
                        <th>{t('ProductForm:priceEffectiveFrom')}</th>
                        <th>{t('ProductForm:priceEffectiveTo')}</th>
                        <th>{t('ProductForm:tag')}</th>
                        {isFixed ? <>
                            <th>{isFixed ? t('ProductForm:fromQuantity') : ''}</th>
                            <th>{isFixed ? t('ProductForm:toQuantity') : ''}</th>
                        </> : null
                        }
                        <th>{t('Global:price')}</th>
                        <th></th>
                        <th colSpan={7} className='center'>{t('Global:days')}</th>
                        <th>{t('Global:from')}</th>
                        <th>{t('Global:to')}</th>
                    </tr>
                </thead>
                : null}
            <tbody>
                {overridePrices.map(p => {
                    const tag = tags.find(t => t.id === p.tagId.value);
                    return (<tr key={p.key} onClick={e => clickHandler(e, () => editPrice(p.key))}>
                        <td className='btn-link btn-no-padding text-center'>{t(`ProductPricePoint:${ProductPricePoint[p.effectiveDatesPricePoint.value]}`)}</td>
                        <td className='btn-link btn-no-padding text-center'>{p.effectiveFrom.value.toDate().toShortDateString(dateFormat)}</td>
                        <td className='btn-link btn-no-padding text-center'>{p.effectiveTo.value ? p.effectiveTo.value.toDate().toShortDateString(dateFormat) : null}</td>
                        <td className='btn-link btn-no-padding text-center'>{tag ? <span className="label tag-label" style={{ backgroundColor: tag.colour }}>{tag.name}</span> : null}</td>
                        {isFixed ? <>
                            <td className='btn-link btn-no-padding text-center'>{p.fixedPricing[0].minQuantity}</td>
                            <td className='btn-link btn-no-padding text-center'>{p.fixedPricing[0].maxQuantity}</td>
                        </> : null}
                        <td className='btn-link btn-no-padding text-center'>{isFixed ? p.fixedPricing[0].price.toFixed(2) : p.unitPrice.value ? p.unitPrice.value.toFixed(2) : null}</td>
                        <td className='btn-link btn-no-padding text-center'>{t(`ProductPricePoint:${ProductPricePoint[p.appliesOnPricePoint.value]}`)}</td>
                        <td className='btn-link btn-no-padding text-center' style={dayStyle}>{(p.applyPriceOnDays & Days.Monday) === Days.Monday ? t('Global:Monday2ch') : null}</td>
                        <td className='btn-link btn-no-padding text-center' style={dayStyle}>{(p.applyPriceOnDays & Days.Tuesday) === Days.Tuesday ? t('Global:Tuesday2ch') : null}</td>
                        <td className='btn-link btn-no-padding text-center' style={dayStyle}>{(p.applyPriceOnDays & Days.Wednesday) === Days.Wednesday ? t('Global:Wednesday2ch') : null}</td>
                        <td className='btn-link btn-no-padding text-center' style={dayStyle}>{(p.applyPriceOnDays & Days.Thursday) === Days.Thursday ? t('Global:Thursday2ch') : null}</td>
                        <td className='btn-link btn-no-padding text-center' style={dayStyle}>{(p.applyPriceOnDays & Days.Friday) === Days.Friday ? t('Global:Friday2ch') : null}</td>
                        <td className='btn-link btn-no-padding text-center' style={dayStyle}>{(p.applyPriceOnDays & Days.Saturday) === Days.Saturday ? t('Global:Saturday2ch') : null}</td>
                        <td className='btn-link btn-no-padding text-center' style={dayStyle}>{(p.applyPriceOnDays & Days.Sunday) === Days.Sunday ? t('Global:Sunday2ch') : null}</td>
                        <td className='btn-link btn-no-padding text-center'>{p.applyPriceFrom.value ? p.applyPriceFrom.value.toShortTimeString(timeFormat) : null}</td>
                        <td className='btn-link btn-no-padding text-center'>{p.applyPriceTo.value ? p.applyPriceTo.value.toShortTimeString(timeFormat) : null}</td>
                    </tr>)
                })}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan={isFixed ? 17 : 15}>
                        <button className='btn btn-primary' onClick={e => clickHandler(e, addPriceOverride)}>{t('ProductForm:addPriceOverride')}</button>
                    </td>
                </tr>
            </tfoot>
        </table>
    </>
    );
}

ProductPriceOverrides.contextTypes = {
    t: PropTypes.func
}

export default ProductPriceOverrides;