
// https://blog.angularindepth.com/how-to-reduce-action-boilerplate-90dc3d389e2b

import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as rt from './types';
import { Time } from '../../global/types';
import { IStore } from '../..';

export type ResourceActions = rt.LoadResources | rt.ReceiveResources | rt.SaveResource | rt.ResourceSaved | rt.ResourceSaveFailed | rt.EditResource;

export const actionCreators = {
    loadResources: () => ({ type: rt.ResourceActionTypes.RequestResources }),
    loadResourcesComplete: (resources: rt.Resource[], err: api.ApiError | null) => ({ type: rt.ResourceActionTypes.ReceivedResources, resources: resources, error: err }),
    editResource: () => ({ type: rt.ResourceActionTypes.EditResource }),
    saveResource: (isNew: boolean, resourceId: string | null, resource: rt.Resource) => ({ type: rt.ResourceActionTypes.SaveResource, isNew: isNew, resourceId: resourceId, resource: resource }),
    resourceSaved: (resourceId: string) => ({ type: rt.ResourceActionTypes.ResourceSaved, resourceId: resourceId }),
    resourceSaveFailed: (error: api.ApiError) => ({ type: rt.ResourceActionTypes.ResourceSaveFailed, error: error })
}

interface IGetResourcesResponse {
    resources: rt.Resource[];
}

interface ISaveResourceResponse {
    resourceId: string;
}

export const handleClientChange = (store: IStore) => [actionCreators.loadResources]

const mapResource = (resource: rt.Resource) => ({
    ...resource, arrivalTimeBeforeEvent: Time.parse(resource.arrivalTimeBeforeEvent.toString()), breaks: resource.breaks.map(b => ({
        ...b,
        startTime: Time.parse(b.startTime),
        duration: Time.parse(b.duration),
        activeFrom: new Date(b.activeFrom),
        activeTo: new Date(b.activeTo)
    }))
})


//https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-after-catching-and-processing-an-error-with-emitting-somethi

const loadResources = () => Observable.defer(() => api.getJson<IGetResourcesResponse>('api/v1/resource/'))
    .map(response => actionCreators.loadResourcesComplete(response.resources.map(r => mapResource(r)), null));

export const loadResourcesEpic = (action$: ActionsObservable<any>) => {
    return epic.create(action$,
        rt.ResourceActionTypes.RequestResources,
        action => {
            return loadResources()
        },
        err => actionCreators.loadResourcesComplete([], err));
}

export const reloadResourcesEpic = (action$: ActionsObservable<any>, store: any) =>
    epic.create(action$,
        rt.ResourceActionTypes.ResourceSaved,
        action => loadResources(),
        err => actionCreators.loadResourcesComplete([], err));

export const saveResourceEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        rt.ResourceActionTypes.SaveResource,
        action => {
            const sra = action as rt.SaveResource;
            var body = {
                ...sra.resource,
                arrivalTimeBeforeEvent: sra.resource.arrivalTimeBeforeEvent.toString(),
                breaks: sra.resource.breaks.map(b => ({
                    ...b,
                    startTime: b.startTime.toString(),
                    duration: b.duration.toString(),
                    activeFrom: b.activeFrom.toYMDDateString(),
                    activeTo: b.activeTo.toYMDDateString()
                }))
            };

            return (sra.isNew ? api.post('api/v1/resource/', body) : api.put(`api/v1/resource/${sra.resourceId}`, body))
                .map(response => {

                    let resourceId = sra.resourceId;
                    if (sra.isNew) {
                        const srr = response.response as ISaveResourceResponse;
                        if (srr) {
                            resourceId = srr.resourceId;
                        }
                    }

                    return ({ type: rt.ResourceActionTypes.ResourceSaved, resourceId: resourceId });
                });
        },
        (err: api.ApiError) => actionCreators.resourceSaveFailed(err));
