
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../../global/controls';

import { ITranslationContext } from '../../../../translations';
import { PaymentGateway } from '../../../../store/pages/paymentGateways/types';
import { Setting } from './paymentMethodForm';
import { isNullOrEmpty } from '../../../../utils/util';

interface GeneralGatewaySettingsProps {
    gateway: PaymentGateway;
    settings: Setting[];
    settingChanged: (key: string, val: string) => void;
}

const GeneralGatewaySettings = (props: GeneralGatewaySettingsProps, context: ITranslationContext) => {
    const { gateway, settings, settingChanged } = props;
    const { t } = context;

    return (
        <div className='payment-method-form-gateway-settings'>
            <label>{t('PaymentMethodForm:gatewaySettings', { gatewayName: gateway.name })}</label>
            {settings.map(s => (
                <ct.TextBox key={s.key} id={`setting_${s.key}`} labelKey={s.key} placeholderKey={s.hasValue && s.secure && isNullOrEmpty(s.value.value) ? '******' : s.key} value={({ ...s.value, value: (s.value.value || '') })} callback={val => settingChanged(s.key, val)} />
            ))}
        </div>
    )
}

GeneralGatewaySettings.contextTypes = {
    t: PropTypes.func
}

export default GeneralGatewaySettings;