
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as api from '../../../store/apiClient';

import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import ApiError from '../../global/apiError';

import { BillItemVoucher } from '../../../store/pages/pointOfSale/types';
import { clickHandler, isNullOrEmpty } from '../../../utils/util';

interface SendVoucherFormProps {
    venueId: string;
    voucher: BillItemVoucher;
    customerId: string | null;
    customerEmail: string | null;
    close: () => void;
    logout: () => void;
}

interface SendVoucherFormState {
    emailAddress: ct.FormValue<string>;
    sending: boolean;
    error: string | null;
    sendError: api.ApiError | null;
}


interface ISendVoucherEmailResponse {
    sent: boolean;
    error: string | null;
}

class SendVoucherForm extends React.Component<SendVoucherFormProps, SendVoucherFormState> {

    constructor(props: SendVoucherFormProps) {
        super(props);

        this.state = { emailAddress: this.validateEmailAddress(props.customerEmail || ''), sending: false, error: '', sendError: null };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    validateEmailAddress = (val: string) => v.validate(val, 'emailAddress', [v.required], []);

    send = () => {
        const { voucher, venueId, customerId, logout } = this.props;
        const { emailAddress } = this.state;

        if (!emailAddress.isValid) {
            this.setState({ error: 'SendVoucherForm:enterEmailAddress', sending: false });
        }

        this.setState({ error: '', sending: true, sendError: null }, () =>
            api.postWithAuth(`api/v1/voucher/${voucher.voucherId}/email`, { venueId: venueId, customerId: customerId, sendTo: emailAddress.value }, logout).subscribe(response => {
                const ser = response.response as ISendVoucherEmailResponse;
                if (ser && ser.sent) {
                    this.props.close();
                } else {
                    this.setState({ sending: false, error: ser.error });
                }
            }, (err: api.ApiError) => {
                this.setState({ sending: false, sendError: err });
            })
        );
    }

    render() {
        const { t } = this.context;
        const { voucher } = this.props;
        const { emailAddress, error, sendError } = this.state;

        return <div style={({ width: '100%' })}>
            <h3>{t('SendVoucherForm:heading')} {voucher.voucherCode}</h3>

            <form className='data-form' onSubmit={e => e.preventDefault()} autoComplete='off'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <ct.Email id='emailAddress' labelKey='Global:emailAddress' placeholderKey='Global:emailAddress' value={emailAddress} callback={val => this.setState({ emailAddress: this.validateEmailAddress(val) })} />
                    </div>
                </div>

                {!isNullOrEmpty(error) ? <div className='alert alert-danger'>{t(error)}</div> : null}
                {sendError ? <ApiError error={sendError} /> : null}

                <div className='row'>
                    <div className='col-sm-12 button-panel form-group'>
                        <button className='btn btn-warning' onClick={e => clickHandler(e, this.send)}>{t('Global:send')}</button>
                        <button onClick={e => clickHandler(e, this.props.close)} className='btn btn-basic'>{t('Global:cancel')}</button>
                    </div>
                </div>
            </form>
        </div>
    }
}

SendVoucherForm.contextTypes = {
    t: PropTypes.func
}

export default SendVoucherForm;