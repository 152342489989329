
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { SketchPicker, ColorResult } from 'react-color';
import { isNullOrEmpty } from '../../utils/util';

export interface ColourPickerProps {
    labelKey: string;
    colourHex: string;
    presetColors?: string[];
    disableAlpha?: boolean;
    style?: React.CSSProperties;
    onColourChanged: (hex: string) => void;
}

interface ColourPickerState {
    displayColorPicker: boolean;
}

class ColourPicker extends React.Component<ColourPickerProps, ColourPickerState> {

    pickerRef = React.createRef<HTMLDivElement>();   
    pickerDropdownRef = React.createRef<HTMLDivElement>();   

    constructor(props: ColourPickerProps) {
        super(props);

        this.state = { displayColorPicker: false } 
    }

    static contextTypes = {
        t: PropTypes.func
    }

    handleClick = () => this.setState({ displayColorPicker: !this.state.displayColorPicker })

    handleClose = () => this.setState({ displayColorPicker: false })

    handleChange = (color: ColorResult) => this.props.onColourChanged(color.hex)

    render() {
        const { t } = this.context;
        const { labelKey, colourHex, style } = this.props;

        const swatch = { padding: '5px', background: '#fff', borderRadius: '1px', boxShadow: '0 0 0 1px rgba(0,0,0,.1)', display: 'inline-block', cursor: 'pointer' }
        const color = { width: '36px', height: '14px', borderRadius: '2px', backgroundColor: colourHex }

        return (
            <div className='control-wrapper' style={style}>
                {isNullOrEmpty(labelKey) ? null : <label>{t(labelKey)}</label>}
                <div ref={this.pickerRef}>
                    <div style={swatch} onClick={this.handleClick}>
                        <div style={color} />
                    </div>
                    {this.renderPicker()}
                </div>
            </div>
        )
    }

    renderPicker = () => {
        const { colourHex, presetColors, disableAlpha } = this.props;

        if (!this.state.displayColorPicker) {
            return null;
        }

        const wrapper = this.pickerRef.current
        if (!wrapper) return;

        const pickerBounds = wrapper.getBoundingClientRect();
        let appContent: HTMLElement | null = null;
        let e: HTMLElement | null = wrapper;

        while (appContent == null && e) {
            if (e.id === 'app-content-container') {
                appContent = e;
            }

            e = e.parentElement;
        }

        const appContentBounds = appContent ? appContent.getBoundingClientRect() : null;

        const leftPos = appContentBounds && pickerBounds.left > appContentBounds.left + 120
            ? appContentBounds && pickerBounds.right > appContentBounds.right - 120 ? -200 : -100
            : 0;

        // todo: calculate lower position and scroll if off bottom of screen
        if (appContent && appContent.offsetHeight < pickerBounds.bottom + 300) {
            console.log(`picker bottom ${pickerBounds.bottom + 200} < ${appContent.offsetHeight} - scrolling required`)
            setTimeout(() => { if (this.pickerDropdownRef && this.pickerDropdownRef.current) this.pickerDropdownRef.current.scrollIntoView() })
        }

        return <div ref={this.pickerDropdownRef} style={({ position: 'absolute', zIndex: 2, left: `${leftPos}px` })}>
            <div style={({ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px' })} onClick={this.handleClose} />
            <SketchPicker color={colourHex} disableAlpha={disableAlpha} presetColors={presetColors} onChangeComplete={this.handleChange} />
        </div>
    }
}

export default ColourPicker;