
import * as React from 'react';
import * as PropTypes from 'prop-types'

interface Props { }

interface Context {
    t: (key: string) => string;
}

const DownloadsPage = (props: Props, context: Context) => {

    const t = context.t;

    return (
        <div>
            <h1>{t('Downloads:heading')}</h1>
            <h3>{t('Downloads:installers')}</h3>
            <ul className='list-unstyled'>
                <li style={({ margin: '20px 0', fontSize: '16pt' })}><a href='installers/Alpha.Kiosk.apk'>{t('Downloads:androidKioskInstaller')}</a></li>
                <li style={({ margin: '20px 0', fontSize: '16pt' })}><a href='installers/Alpha.Aures.TillService.Installer.msi'>{t('Downloads:auresTillServiceInstaller')}</a></li>
            </ul>
        </div>
    );
}

DownloadsPage.contextTypes = {
    t: PropTypes.func
}

export default DownloadsPage;