
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { clickHandler } from '../../../utils/util';
import { createNewEvent } from '../diary/helpers';

import * as DiaryActions from '../../../store/pages/diary/actions';
import * as LoginActions from '../../../store/pages/login/actions';
import * as UserPreferenceActions from '../../../store/global/preferences/actions';

import { DateFormat, Venue } from '../../../store/pages/venues/types';
import { ApplicationState } from '../../../store';

import Datetime from 'react-datetime';
import moment from 'moment';

import * as dt from '../../../store/pages/diary/types';
import { Resource } from '../../../store/pages/resources/types';
import { ActivityFormat } from '../../../store/pages/activityFormats/types';
import { IScrollPosition } from '../../global/scrollViewer';

import DiaryCalendar from '../diary/diaryCalendar';
import CreateEvent from '../diary/createEvent';
import { ICustomer, IReservation } from '../diary/types';

enum Mode {
    Diary = 1,
    AddBooking = 2
}

interface LocalProps {
    venue: Venue;
    customer: ICustomer;
    close: (bookingCreated: boolean) => void;
}

interface LocalReduxState {
    reservations: dt.DiaryReservation[];
    openingTimes: dt.OpeningTimes[];
    venues: Venue[];
    selectedVenueId: string;
    slotSizeInMinutes: number;
    resources: Resource[];
    activityFormats: ActivityFormat[];
    date: moment.Moment;
    scrollPosition: IScrollPosition;
    notes: dt.DiaryNote | null;
}

interface ReduxActions {
    loadDiaryReservations: (venueId: string, date: Date) => void;
    switchDate: (date: Date) => void;
    setDiaryResolution: (slotSizeInMinutes: number) => void;
    setScrollPosition: (top: number, left: number) => void;
    logout: () => void;
}

// At runtime, Redux will merge together...
type CustomerNewBookingProps = LocalReduxState & ReduxActions & LocalProps;

interface CustomerNewBookingState {
    mode: Mode;
    reservation: IReservation | null;
}


class CustomerNewBooking extends React.Component<CustomerNewBookingProps, CustomerNewBookingState> {

    constructor(props: CustomerNewBookingProps) {
        super(props);

        this.state = { mode: Mode.Diary, reservation: null };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    loadDiaryReservations = (venue: Venue) => {
        const { loadDiaryReservations, date } = this.props;
        if (venue) {
            loadDiaryReservations(venue.id, date.toDate());
        }
    }

    componentDidUpdate(prevProps: CustomerNewBookingProps) {
        const { venues, selectedVenueId } = prevProps;
        const { venues: nextVenues, selectedVenueId: nextSelectedVenueId} = this.props;

        const venueChanged = nextVenues.length > 0 && (venues.length !== nextVenues.length || selectedVenueId !== nextSelectedVenueId);

        if (venueChanged) {
            const venue = nextVenues.find(v => v.id === nextSelectedVenueId) || nextVenues[0];
            this.loadDiaryReservations(venue);
        }
    }

    componentDidMount() {
        if (this.props.venue) {
            this.loadDiaryReservations(this.props.venue);
        }
    }

    addReservation = (resource: Resource, startTime: Date, endTime: Date) => {
        const evt = createNewEvent(resource, startTime, endTime);
        this.setState({ mode: Mode.AddBooking, reservation: evt.reservations[0] })
    }

    editReservation = (reservationId: string, eventId: string) => 
        this.setState((s, props) => {
            const rsv = props.reservations.find(r => r.id === reservationId);
            return rsv ? { mode: Mode.AddBooking, reservation: rsv } : s
        })

    dateChanged = (date: React.ChangeEvent<any> | moment.Moment | string) => {

        if (moment.isMoment(date)) {
            this.props.switchDate(date.toDate());
        }
    }

    onSlotSizeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = parseInt(e.target.value);
        if (!isNaN(val)) {
            const { slotSizeInMinutes } = this.props;
            // 25 min slots don't render correctly as we can't hit exact hour points, so need to skip 
            // and go straight to 20 or 30 min slots depnding if we are going up or down
            const newSize = val <= 20 ? val : slotSizeInMinutes === 30 ? 20 : 30;
            this.props.setDiaryResolution(newSize);
        }
    }

    goBackOneDay = () => this.dateChanged(this.props.date.clone().subtract(1, 'd'));

    goForwardOneDay = () => this.dateChanged(this.props.date.clone().add(1, 'd'));

    render() {
        const { mode, reservation } = this.state;
        const { t } = this.context;

        return <div className='customer-new-booking'>
            {mode === Mode.Diary || !reservation ? this.renderCaledar() : this.renderCreateEvent(reservation)}
        </div>
    }

    renderCaledar = () => {
        const { venue, slotSizeInMinutes, resources, activityFormats, reservations, openingTimes, scrollPosition, setScrollPosition, date, notes, logout } = this.props;
        const { t } = this.context;

        const todayButton = moment().isSame(date, 'day') ? null : <button className='btn btn-default' onClick={e => clickHandler(e, () => this.dateChanged(moment()))} style={({ marginLeft: '12px' })} title={t('Global:today')}>{t('Global:today')}</button>;
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;

        return <>
            <div className='customer-new-booking-header'>
                <button onClick={e => clickHandler(e, this.goBackOneDay)} title={t('DiaryPage:prevDay')}><i className="glyphicon glyphicon-chevron-left"></i></button>
                <Datetime value={this.props.date} onChange={this.dateChanged} timeFormat={false} className='date-picker' closeOnSelect={true} dateFormat={dateFormat === DateFormat.MDY ? "dddd MMMM Do YYYY" : "dddd D MMMM YYYY"} />
                <button onClick={e => clickHandler(e, this.goForwardOneDay)} title={t('DiaryPage:nextDay')}><i className="glyphicon glyphicon-chevron-right"></i></button>
                {todayButton}
                <div style={({ display: 'inline-block', marginTop: '8px' })}>
                    <input type='range' min='5' max='30' step='5' value={slotSizeInMinutes} onChange={this.onSlotSizeChanged} style={({ display: 'inline-block', width: '100px', marginLeft: '15px' })}></input>
                    <span style={({ margin: '0 10px', display: 'inline-block' })}>{slotSizeInMinutes}m</span>
                </div>
            </div>
            <div className='cal-notes'>{notes ? notes.text : ''}</div>
            <div className='customer-new-booking-calendar'>
                <DiaryCalendar
                    venue={venue}
                    slotSizeInMinutes={slotSizeInMinutes}
                    date={date}
                    showCancelled={false}
                    resources={resources}
                    openingTimes={openingTimes}
                    reservations={reservations}
                    scrollPosition={scrollPosition}
                    setScrollPosition={setScrollPosition}
                    loadDiaryReservations={this.loadDiaryReservations}
                    addReservation={this.addReservation}
                    editReservation={this.editReservation}
                    logout={logout}
                />
            </div>
            <div className='customer-new-booking-footer'>
                <div className='text-center'><button className='btn btn-primary' onClick={e => clickHandler(e, () =>  this.props.close(false))}>{t('Global:close')}</button></div>
            </div>
        </>
    }

    renderCreateEvent = (reservation: IReservation) => {
        const { venue, customer, close } = this.props;

        return <div className='create-event-scroll-wrapper'><CreateEvent
            venue={venue}
            booking={null}
            billItemsToReschedule={[]}
            customerCategoryDefaults={[]}
            bookedReservationIds={[]}
            showSelectCustomer={false}
            customersToRegister={[]}
            customer={customer}
            defaultCountryId={venue.countryId}
            reservation={reservation}
            isSaving={false}
            eventCreated={() => close(true)}
            cancel={() => close(false)} /></div>
    }

}

const matStateToProps = (state: ApplicationState) => ({
    reservations: state.diary.reservations,
    openingTimes: state.diary.openingTimes,
    slotSizeInMinutes: state.preferences.slotSizeInMinutes,
    venues: state.venues.venues,
    selectedVenueId: state.venues.selectedVenueId,
    resources: state.resources.resources,
    activityFormats: state.activityFormats.activityFormats,
    date: moment(state.diary.date),
    scrollPosition: state.diary.scrollPosition,
    notes: state.diary.diaryNotes[`${state.venues.selectedVenueId}_${state.diary.date.toYMDDateString()}`],
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadDiaryReservations: bindActionCreators(DiaryActions.actionCreators.loadDiaryReservations, dispatch),
    switchDate: bindActionCreators(DiaryActions.actionCreators.switchDate, dispatch),
    setScrollPosition: bindActionCreators(DiaryActions.actionCreators.setScrollPosition, dispatch),
    setDiaryResolution: bindActionCreators(UserPreferenceActions.actionCreators.setDiaryResolution, dispatch),
    logout: bindActionCreators(LoginActions.actionCreators.logout, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    matStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps                  // Selects which action creators are merged into the component's props
)(CustomerNewBooking);
