
import * as React from 'react';
import * as PropTypes from 'prop-types'
import * as ct from '../../../global/controls';
import * as v from '../../../global/validation';
import { ValidationError } from '../../../../store/global/types';
import { isNullOrEmpty } from '../../../../utils/util';

interface SendgridSettingsFormProps {
    integrationId: string | null;
    settings: ct.FormValue<string>;
    validationErrors: ValidationError[];
    settingsChanged: (newSettings: ct.FormValue<string>) => void;
}

interface SendgridSettingsFormState {
    apiKey: ct.FormValue<string>;
    webhookPublicKey: ct.FormValue<string>;
}

class SendgridSettingsForm extends React.Component<SendgridSettingsFormProps, SendgridSettingsFormState> {

    constructor(props: SendgridSettingsFormProps) {
        super(props);

        this.state = this.buildStateFromProps(props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    buildStateFromProps = (props: SendgridSettingsFormProps) => {

        let apiKey = '';
        let webhookPublicKey = '';

        try {
            const settings = JSON.parse(props.settings.value);
            apiKey = settings.apiKey || '';
            webhookPublicKey = settings.webhookPublicKey || '';
        } catch (ex) { }
        
        const newState: any = {};

        if (!this.state || !this.state.apiKey || this.state.apiKey.value !== apiKey || this.state.webhookPublicKey.value != webhookPublicKey) {
            newState.apiKey = this.validateApiKey(apiKey);
            newState.webhookPublicKey = this.validateWebhookPublicKey(webhookPublicKey);
        }

        return newState;
    }

    componentDidMount() {
        this.stateChanged(this.state);
    }

    componentDidUpdate() {
        const newState = this.buildStateFromProps(this.props);

        if (newState.hasOwnProperty('apiKey') || newState.hasOwnProperty('fromEmailAddress') || newState.hasOwnProperty('fromEmailName')) {
            this.setState({ ...newState }, () => this.stateChanged(this.state));
        }
    }

    stateChanged = (newState: SendgridSettingsFormState) => {
        const settings = JSON.stringify({ apiKey: newState.apiKey.value, webhookPublicKey: newState.webhookPublicKey.value });
        this.props.settingsChanged({ controlId: 'settings', value: settings, isValid: newState.apiKey.isValid && newState.webhookPublicKey.isValid, hasValidation: true });
    }

    validateApiKey = (val: string) => v.validate(val, 'apiKey', [v.required], this.props.validationErrors);
    validateWebhookPublicKey = (val: string) => v.validate(val, 'webhookPublicKey', [v.required], this.props.validationErrors);

    render() {
        const { t } = this.context;
        const { integrationId } = this.props;
        return (
            <div>
                {!isNullOrEmpty(integrationId)
                    ? <div>
                        <label>{t('sendgridSettings:integrationId')}</label>
                        <div>{integrationId}</div>
                    </div>
                    : null
                }
                <ct.Password id='apiKey' labelKey='sendgridSettings:apiKey' placeholderKey='sendgridSettings:apiKey' value={this.state.apiKey} callback={val => this.stateChanged({ ...this.state, apiKey: this.validateApiKey(val) })} />
                <ct.Password id='webhookPublicKey' labelKey='sendgridSettings:webhookPublicKey' placeholderKey='sendgridSettings:webhookPublicKey' value={this.state.webhookPublicKey} callback={val => this.stateChanged({ ...this.state, webhookPublicKey: this.validateWebhookPublicKey(val) })} />
            </div>
        );
    }
}

export default SendgridSettingsForm;


