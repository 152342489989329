
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'

import { ActivityFormat, ActivityFormatResource, ActivityFormatAvailability, CustomField, CustomFieldType, ActivityFormatVariationCompatibility, CustomFieldApplication, ActivityFormatLink } from '../../../../store/pages/activityFormats/types';
import * as ct from '../../../global/controls';
import * as v from '../../../global/validation';
import { ApplicationState } from '../../../../store';
import * as ModalActions from '../../../../store/global/modal/actions';
import * as ActivityFormatActions from '../../../../store/pages/activityFormats/actions';
import { Product } from '../../../../store/pages/products/types';
import { ValidationError, colours, Time, Days } from '../../../../store/global/types';
import { generateTempId, isNullOrEmpty, clickHandler } from '../../../../utils/util';
import { Resource, ProductQuantityUnit } from '../../../../store/pages/resources/types';
import * as api from '../../../../store/apiClient';
import ApiError from '../../../global/apiError';
import ActivityFormatResources from './activityFormatResources';
import ActivityFormatGroups from './activityFormatGroups';
import ActivityFormatProducts from './activityFormatProducts';
import ActivityFormatVariations from './activityFormatVariations';
import OnLineAvailability from './onLineAvailability';
import OnLineAvailabilityForm from './onLineAvailabilityForm';
import ActivityFormatCustomerCategories from './activityFormatCustomerCategories';
import ActivityFormatAdditionalProducts from './activityFormatAdditionalProducts';
import CreateActivityFormatLink from './createActivityFormatLink';
import { CustomerCategory } from '../../../../store/pages/customerCategories/types';
import Loading from '../../../global/loading';
import { quillToolbarSingleLineTextFormatting, quillToolbarWithImageAndLinks } from '../../../global/quillSettings';
import ColourPicker from '../../../global/colourPicker';
import { ImageFile, getImageProps } from '../../../../utils/images';
import Dropzone from 'react-dropzone';
import { RegistrationTerms, BookingTerms } from '../../../../store/pages/termsAndConditions/types';
import ActivityFormatExtraFields, { FieldUsage } from './activityFormatExtraFields';
import { DateFormat, TimeFormat, Venue } from '../../../../store/pages/venues/types';
import { ActivityFormatGroup } from '../../../../store/pages/activityFormatGroups/types';
import ActivityFormatAdditionalActivities from './activityFormatAdditionalActivities';
import AdditionalActivityForm from './additionalActivityForm';
import { MembershipType } from '../../../../store/pages/memberships/types';

export interface ICustomField extends CustomField {
    key: string;
}

export interface Variation {
    id: string;
    key: string;
    minParticipants: ct.FormValue<number>;
    maxParticipants: ct.FormValue<number>;
    name: ct.FormValue<string>;
    mustBookItemsTogether: ct.FormValue<boolean>;
    timingTemplateName: ct.FormValue<string>;
    schedule: VariationSchedule[];
    compatibleActivityFormatVariations: ActivityFormatVariationCompatibility[];
}

export interface VariationSchedule {
    id: string;
    isNew: boolean;
    sequence: number;
    runningTime: ct.FormValue<Time>;
    minGapBefore: ct.FormValue<Time>;
    minGapAfter: ct.FormValue<Time>;
    minBreakDuration: ct.FormValue<Time | null>;
    maxBreakDuration: ct.FormValue<Time | null>;
    timingTemplateName: ct.FormValue<string>;
}

export interface ActivityCustomerCategory {
    customerCategoryId: string;
    categoryName: string;
    categoryCode: string;
    enabled: ct.FormValue<boolean>;
    placesToBook: ct.FormValue<number>;
    minQuantityPerBooking: ct.FormValue<number>;
    maxQuantityPerBooking: ct.FormValue<number>;
    maxOverallQuantity: ct.FormValue<number>;
}

export interface AfProduct {
    id: string;
    key: string;
    productId: ct.FormValue<string>;
    enableForWebBookings: ct.FormValue<boolean>;
    customerCategories: string[];
}

export interface AdditionalProduct {
    key: string;
    id: string;
    productId: ct.FormValue<string>;
    minQuantity: ct.FormValue<number>;
    maxQuantity: ct.FormValue<number>;
    defaultQuantity: ct.FormValue<number>;
    quantityUnit: ct.FormValue<ProductQuantityUnit>;
    sequence: ct.FormValue<number>;
}

interface LocalProps {
    isNew: boolean;
    venueId: string;
    activityFormat: ActivityFormat | null;
    close: () => void;
}

interface MappedState {
    isLoading: boolean;
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    enableMultipleCategoriesPerCustomer: boolean;
    venues: Venue[];
    products: Product[];
    isLoadingProducts: boolean;
    activityFormats: ActivityFormat[];
    activityFormatGroups: ActivityFormatGroup[];
    validationErrors: ValidationError[];
    isLoadingResources: boolean;
    resources: Resource[];
    customerCategories: CustomerCategory[];
    isLoadingCustomerCategories: boolean;
    registrationTerms: RegistrationTerms[];
    bookingTerms: BookingTerms[];
    dateFormat: DateFormat;
    timeFormat: TimeFormat;
    membershipTypes: MembershipType[];
}

interface MappedActions {
    editActivityFormat: () => void;
    saveActivityFormat: (isNew: boolean, activityFormatId: string | null, activityFormat: ActivityFormat, img: File | null) => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
}

type ActivityFormatFormProps = MappedState & MappedActions & LocalProps;

interface ActivityFormatFormState {
    venue: Venue | undefined;
    name: ct.FormValue<string>;
    description: ct.FormValue<string>;
    websiteDescription: ct.FormValue<string>;
    webDescriptionBgColour: string;
    webDescriptionTextColour: string;
    webDescriptionPriceTemplate: ct.FormValue<string>;
    colour: ct.FormValue<string>;
    sequence: ct.FormValue<number | null>;
    archived: ct.FormValue<boolean>;
    minPlacesForExclusive: ct.FormValue<number>;
    resourceSelections: ActivityFormatResource[];
    selectedGroupIds: string[];
    variations: Variation[];
    activityCustomerCategories: ActivityCustomerCategory[];
    linkedActivityFormats: ActivityFormatLink[];
    products: AfProduct[];
    availability: ActivityFormatAvailability[];
    additionalProducts: AdditionalProduct[];
    errorMessage: string | null;
    webDescriptionStyle: React.CSSProperties | undefined;
    registrationTermsId: ct.FormValue<string>;
    bookingTermsId: ct.FormValue<string>;
    bookingExtraFields: ct.FormValue<ICustomField[]>;
    registrationExtraFields: ct.FormValue<ICustomField[]>;
    noAvailabilityMessage: ct.FormValue<string>;
    arrivalTimeBeforeEventOverride: ct.FormValue<Time | null>;
    snapOnlineBookingsToOverride: ct.FormValue<number | null>;
    showRemainingSpaces: boolean;
    showAlternativeAvailability: boolean;
    applyAvailabilityRulesToExistingReservations: ct.FormValue<boolean>;
    allowMultipleCategoriesPerCustomer: ct.FormValue<boolean>;
    priceTemplate: ct.FormValue<string>;
    membershipTypeId: ct.FormValue<string | null>;
    imageUrl: string | null;
    image: ImageFile | null;
    imageError: string | null;
}

class ActivityFormatForm extends React.Component<ActivityFormatFormProps, ActivityFormatFormState> {

    constructor(props: ActivityFormatFormProps) {
        super(props);

        this.state = this.buildStateFromProps(this.props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps(props: ActivityFormatFormProps): ActivityFormatFormState {

        const { isNew, venueId, venues, activityFormat, customerCategories } = props;

        const activeCustomerCategories = customerCategories.filter(c => !c.archived && c.venueId === venueId);
        const activityCustomerCategories = activeCustomerCategories.map(c => {

            const activityCustomerCategory = activityFormat ? activityFormat.customerCategories.find(x => x.customerCategoryId === c.id) : undefined;
            const bkMin = activityCustomerCategory ? activityCustomerCategory.minQuantityPerBooking : 0;
            const bkMax = activityCustomerCategory ? activityCustomerCategory.maxQuantityPerBooking : 0;
            const overallMax = activityCustomerCategory ? activityCustomerCategory.maxOverallQuantity : 0;
            const enabled = activityCustomerCategory ? true : false;

            return {
                customerCategoryId: c.id,
                categoryName: c.name,
                categoryCode: c.code,
                enabled: this.validateCustomerCategoryEnabled(c.id, enabled),
                placesToBook: this.validateCategoryPlacesToBook(c.id, activityCustomerCategory ? activityCustomerCategory.placesToBook : 0),
                minQuantityPerBooking: this.validateCategoryMinQuantityPerBooking(c.id, bkMin),
                maxQuantityPerBooking: this.validateCategoryMaxQuantityPerBooking(c.id, bkMax, bkMin, enabled),
                maxOverallQuantity: this.validateCategoryMaxOverallQuantity(c.id, overallMax, bkMax, enabled),
            }
        });

        const webDescriptionBgColour = (isNew || !activityFormat || !activityFormat.webDescriptionBgColour) ? '#000000' : activityFormat.webDescriptionBgColour;
        const webDescriptionTextColour = (isNew || !activityFormat || !activityFormat.webDescriptionTextColour) ? '#ffffff' : activityFormat.webDescriptionTextColour;

        return {
            venue: venues.find(v => v.id === venueId),
            name: this.validateName((isNew || !activityFormat) ? '' : activityFormat.name),
            description: this.validateDescription((isNew || !activityFormat) ? '' : activityFormat.description),
            colour: this.validateColour((isNew || !activityFormat) ? colours[0] : activityFormat.colour),
            sequence: this.validateSequence((isNew || !activityFormat) ? 0 : activityFormat.sequence),
            archived: this.validateArchived((isNew || !activityFormat) ? false : activityFormat.archived),
            minPlacesForExclusive: this.validateMinPlacesForExclusive((isNew || !activityFormat) ? 1 : activityFormat.minPlacesForExclusive),
            websiteDescription: this.validateWebsiteDescription((isNew || !activityFormat || !activityFormat.webDescription) ? '' : activityFormat.webDescription),
            webDescriptionBgColour: webDescriptionBgColour,
            webDescriptionTextColour: webDescriptionTextColour,
            webDescriptionPriceTemplate: this.validateWebDescriptionPriceTemplate((isNew || !activityFormat) ? '' : activityFormat.webDescriptionPriceTemplate || ''),
            resourceSelections: (isNew || !activityFormat) ? [] : activityFormat.resources,
            selectedGroupIds: (isNew || !activityFormat) ? [] : activityFormat.activityFormatGroupIds,
            variations: (isNew || !activityFormat) ? [this.createVariation()] : activityFormat.variations.map(variation => (
                {
                    id: variation.id,
                    key: variation.id,
                    name: this.validateVariationName(variation.id, variation.name),
                    minParticipants: this.validateVariationMinParticipants(variation.id, variation.minParticipants),
                    maxParticipants: this.validateVariationMaxParticipants(variation.id, variation.maxParticipants),
                    timingTemplateName: this.validateTimingTemplateName(variation.id, variation.timingTemplateName || ''),
                    mustBookItemsTogether: this.validateMustBookItemsTogether(variation.id, variation.mustBookItemsTogether),
                    compatibleActivityFormatVariations: variation.compatibleActivityFormatVariations,
                    schedule: variation.schedule.map((s, ix) => ({
                        id: s.id,
                        isNew: false,
                        sequence: s.sequence,
                        runningTime: this.validateVariationRunningTime(variation.id, s.id, s.runningTime),
                        minGapBefore: this.validateMinGapBefore(variation.id, s.id, s.minGapBefore ? s.minGapBefore : Time.zero()),
                        minGapAfter: this.validateMinGapAfter(variation.id, s.id, s.minGapAfter ? s.minGapAfter : Time.zero()),
                        minBreakDuration: this.validateMinBreakDuration(variation.id, s.id, ix, s.minBreakDuration),
                        maxBreakDuration: this.validateMaxBreakDuration(variation.id, s.id, ix, s.maxBreakDuration),
                        timingTemplateName: this.validateScheduleTimingTemplateName(variation.id, s.id, s.timingTemplateName || ''),
                    }))
                })),
            activityCustomerCategories,
            products: (isNew || !activityFormat) ? [this.createProduct(activityCustomerCategories)] : activityFormat.products.map(p => (
                {
                    id: p.id,
                    key: p.id,
                    productId: this.validateVariationProductId(p.id, p.productId),
                    enableForWebBookings: ct.asFormValue('enableForWebBookings', p.enableForWebBookings),
                    customerCategories: p.customerCategories
                })),
            availability: (isNew || !activityFormat) ? [] : activityFormat.availability,
            registrationTermsId: this.validateRegistrationTermsId(activityFormat ? activityFormat.registrationTermsId : ''),
            bookingTermsId: this.validateBookingTermsId(activityFormat ? activityFormat.bookingTermsId : ''),
            webDescriptionStyle: this.buildWebDescriptionStyle(webDescriptionBgColour, webDescriptionTextColour),
            bookingExtraFields: this.validateCustomFields((isNew || !activityFormat) ? [] : activityFormat.bookingExtraFields.map((f,ix) => ({...f, key: `${generateTempId()}_${ix}`}))),
            registrationExtraFields: this.validateCustomFields((isNew || !activityFormat) ? [] : activityFormat.registrationExtraFields.map((f, ix) => ({ ...f, key: `${generateTempId()}_${ix}` }))),
            additionalProducts: (isNew || !activityFormat || !activityFormat.additionalProducts) ? [] : activityFormat.additionalProducts.map(p => ({
                id: p.id,
                key: p.id,
                productId: this.validateAdditionalProductProduct(p.productId),
                minQuantity: this.validateAdditionalProductMinQty(p.minQuantity),
                maxQuantity: this.validateAdditionalProductMaxQty(p.maxQuantity),
                defaultQuantity: this.validateAdditionalProductDftQty(p.defaultQuantity),
                quantityUnit: this.validateAdditionalProductUnit(p.quantityUnit),
                sequence: this.validateAdditionalProductSequence(p.sequence)
            })),
            linkedActivityFormats: (isNew || !activityFormat || !activityFormat.linkedActivityFormats) ? [] : activityFormat.linkedActivityFormats,
            noAvailabilityMessage: this.validateNoAvailabilityMessage(activityFormat ? activityFormat.noAvailabilityMessage || '' : ''),
            showRemainingSpaces: activityFormat ? activityFormat.showRemainingSpaces : true,
            showAlternativeAvailability: activityFormat ? activityFormat.showAlternativeAvailability : true,
            arrivalTimeBeforeEventOverride: this.validateArrivalTimeOverride(activityFormat ? activityFormat.arrivalTimeBeforeEventOverride : null),
            snapOnlineBookingsToOverride: this.validateSnapOnlineBookingsToOverride(activityFormat ? activityFormat.snapOnlineBookingsToOverride : null),
            applyAvailabilityRulesToExistingReservations: this.validateApplyAvailabilityRulesToExistingReservations(activityFormat && activityFormat.applyAvailabilityRulesToExistingReservations ? 'ALL' : 'NEW'),
            allowMultipleCategoriesPerCustomer: this.validateAllowMultipleCategoriesPerCustomer(activityFormat && activityFormat.allowMultipleCategoriesPerCustomer ? true : false),
            priceTemplate: this.validatePriceTemplate(activityFormat && activityFormat.priceTemplate ? activityFormat.priceTemplate : ''),
            membershipTypeId: this.validateMembershipTypeId(activityFormat && activityFormat.membershipTypeId ? activityFormat.membershipTypeId : null),
            errorMessage: null,
            imageUrl: (isNew || !activityFormat) ? null : activityFormat.imageUrl,
            image: null,
            imageError: null
        };
    }

    buildWebDescriptionStyle = (webDescriptionBgColour: string, webDescriptionTextColour: string): React.CSSProperties => ({ backgroundColor: webDescriptionBgColour, color: webDescriptionTextColour });

    componentDidMount() {
        this.props.editActivityFormat();
    }

    componentDidUpdate(prevProps: ActivityFormatFormProps) {
        // Only update state is resource has changed
        const { activityFormat: prevActivityFormat, customerCategories: prevCustomerCategories, saveComplete: prevSaveComplete } = prevProps;
        const { activityFormat, customerCategories, saveComplete } = this.props;
        if ((!prevActivityFormat && activityFormat) || (prevActivityFormat && !activityFormat) || (prevActivityFormat && activityFormat && prevActivityFormat.id !== activityFormat.id)) {
            this.setState(this.buildStateFromProps(this.props));
        } else if (prevCustomerCategories.length !== customerCategories.length) {
            this.setState(this.buildStateFromProps(this.props));
        }

        if (saveComplete && !prevSaveComplete) {
            setTimeout(() => { this.close(); }, 750);
        }
    }

    private saveActivityFormat = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }

    private close = () => {
        this.props.close();
    }

    private save = () => {
        const { t } = this.context;

        if (!v.isValid(this.state)
            || this.state.variations.reduce((prev: boolean, variation) => (prev || !v.isValid(variation)), false)
            || this.state.products.reduce((prev: boolean, prod) => (prev || !v.isValid(prod)), false)
            || this.state.activityCustomerCategories.reduce((prev: boolean, cat) => (prev || !v.isValid(cat)), false)
            || this.state.additionalProducts.reduce((prev: boolean, ap) => (prev || !v.isValid(ap)), false)) {
            this.setState({ errorMessage: t('Global:formNotValid') });
        } else {
            const { isNew, activityFormat, venueId } = this.props;
            const { name, description, colour, resourceSelections, archived, sequence, minPlacesForExclusive, variations, products,
                availability, activityCustomerCategories, websiteDescription, webDescriptionBgColour, webDescriptionTextColour,
                registrationTermsId, bookingTermsId, image, bookingExtraFields, registrationExtraFields, additionalProducts, noAvailabilityMessage,
                showRemainingSpaces, showAlternativeAvailability, arrivalTimeBeforeEventOverride, snapOnlineBookingsToOverride,
                applyAvailabilityRulesToExistingReservations, selectedGroupIds, allowMultipleCategoriesPerCustomer, priceTemplate,
                membershipTypeId, webDescriptionPriceTemplate, linkedActivityFormats } = this.state;

            const enabledCustomerCategories = activityCustomerCategories.filter(c => c.enabled.value);
            if (enabledCustomerCategories.length === 0) {
                this.setState({ errorMessage: t('ActivityFormatForm:SelectCustomerCategory') });
                return;
            }

            if (products.length === 0) {
                this.setState({ errorMessage: t('ActivityFormatForm:NoProductSelected') });
                return;
            }

            if (products.filter(p => p.customerCategories.length === 0).length > 0) {
                this.setState({ errorMessage: t('ActivityFormatForm:AllProductsMustHaveCustomerCategory') });
                return;
            }

            if (variations.filter(v => v.schedule.filter(s => s.runningTime.value.getHours() === 0 && s.runningTime.value.getMinutes() === 0).length > 0).length > 0) {
                this.setState({ errorMessage: t('ActivityFormatForm:AllSchedulesMustHaveARunningTime') });
                return;
            }

            var alternativeActivities = linkedActivityFormats.filter(a => a.offerAsAlternative);
            if (alternativeActivities.length > 10) {
                this.setState({ errorMessage: t('ActivityFormatAdditionalActivities:maxAlternateActivitiesExceeded') });
                return;
            }

            var upsoldActivities = linkedActivityFormats.filter(a => a.offerAsUpsell);
            if (upsoldActivities.length > 10) {
                this.setState({ errorMessage: t('ActivityFormatAdditionalActivities:maxUpsellActivitiesExceeded') });
                return;
            }

            if (activityCustomerCategories.filter(c => c.enabled.value && products.filter(fp => fp.enableForWebBookings.value && fp.customerCategories.includes(c.customerCategoryId)).length > 1).length > 0) {
                this.setState({ errorMessage: t('Global:formNotValid') });
                return;
            }

            const cleansedWebDescriptionPriceTemplate = webDescriptionPriceTemplate.value === '<p><br></p>' ? '' : webDescriptionPriceTemplate.value.replace('<p>', '').replace('</p>', '');
            const cleansedNoAvailabilityMessage = noAvailabilityMessage.value === '<p><br></p>' ? '' : noAvailabilityMessage.value;

            const activityFormatId = isNew || !activityFormat ? null : activityFormat.id;
            this.setState({ errorMessage: null })

            const activityFormatToSave: ActivityFormat = {
                id: activityFormatId || '',
                venueId: venueId,
                name: name.value,
                description: description.value,
                colour: colour.value,
                resources: resourceSelections,
                sequence: sequence.value,
                archived: archived.value,
                minPlacesForExclusive: minPlacesForExclusive.value,
                webDescription: websiteDescription.value,
                webDescriptionBgColour: webDescriptionBgColour,
                webDescriptionTextColour: webDescriptionTextColour,
                webDescriptionPriceTemplate: cleansedWebDescriptionPriceTemplate,
                imageUrl: null,
                registrationTermsId: registrationTermsId.value,
                bookingTermsId: bookingTermsId.value,
                bookingExtraFields: bookingExtraFields.value.filter(f => !isNullOrEmpty(f.name)).map(f => { const { key, ...fld } = f; return fld }),
                registrationExtraFields: registrationExtraFields.value.filter(f => !isNullOrEmpty(f.name)).map(f => { const { key, ...fld } = f; return fld }),
                arrivalTimeBeforeEventOverride: arrivalTimeBeforeEventOverride.value,
                snapOnlineBookingsToOverride: snapOnlineBookingsToOverride.value,
                applyAvailabilityRulesToExistingReservations: applyAvailabilityRulesToExistingReservations.value,
                allowMultipleCategoriesPerCustomer: allowMultipleCategoriesPerCustomer.value,
                priceTemplate: priceTemplate.value,
                membershipTypeId: membershipTypeId.value === '-1' ? null : membershipTypeId.value,
                variations: variations.map(x => ({
                    id: x.id,
                    minParticipants: x.minParticipants.value,
                    maxParticipants: x.maxParticipants.value,
                    name: x.name.value,
                    timingTemplateName: x.mustBookItemsTogether.value ?  x.timingTemplateName.value : null,
                    mustBookItemsTogether: x.mustBookItemsTogether.value,
                    compatibleActivityFormatVariations: x.compatibleActivityFormatVariations,
                    schedule: x.schedule.sort((s1, s2) => s1.sequence - s2.sequence).map((s, ix) => {
                        var sch = {
                            id: s.isNew ? '' : s.id,
                            sequence: ix + 1,
                            runningTime: s.runningTime.value,
                            minGapBefore: s.minGapBefore.value,
                            minGapAfter: s.minGapAfter.value,
                            minBreakDuration: s.minBreakDuration.value,
                            maxBreakDuration: s.maxBreakDuration.value,
                            timingTemplateName: x.mustBookItemsTogether.value ? null : s.timingTemplateName.value
                        }
                        return sch;
                    })
                })),
                products: products.map(x => ({
                    id: x.id,
                    productId: x.productId.value,
                    enableForWebBookings: x.enableForWebBookings.value,
                    customerCategories: x.customerCategories
                })),
                linkedActivityFormats: linkedActivityFormats,
                noAvailabilityMessage: cleansedNoAvailabilityMessage,
                showRemainingSpaces: showRemainingSpaces,
                showAlternativeAvailability: showAlternativeAvailability,
                availability: availability,
                customerCategories: enabledCustomerCategories.map(c => ({
                    customerCategoryId: c.customerCategoryId,
                    placesToBook: c.placesToBook.value,
                    minQuantityPerBooking: c.minQuantityPerBooking.value,
                    maxQuantityPerBooking: c.maxQuantityPerBooking.value,
                    maxOverallQuantity: c.maxOverallQuantity.value
                })),
                activityFormatGroupIds: selectedGroupIds,
                additionalProducts: additionalProducts.map(p => ({
                    id: p.id,
                    productId: p.productId.value,
                    minQuantity: p.minQuantity.value,
                    maxQuantity: p.maxQuantity.value,
                    defaultQuantity: p.defaultQuantity.value,
                    quantityUnit: p.quantityUnit.value,
                    sequence: p.sequence.value
                }))
            };

            this.props.saveActivityFormat(isNew, activityFormatId, activityFormatToSave, image ? image.file : null);
        }
    }

    resourceStateChanged = (resourceId: string, selected: boolean) => {
        this.setState(prev => {
            const filteredResources = prev.resourceSelections.filter(x => x.resourceId !== resourceId);

            const res = this.props.resources.find(r => r.id === resourceId);
            const configs = selected && res ? res.configurations.map(c => c.id) : [];

            return { resourceSelections: selected ? filteredResources.concat([{ resourceId: resourceId, configurationIds: configs }]) : filteredResources };
        });
    }

    resourceConfigurationStateChanged = (resourceId: string, configId: string, selected: boolean) => {
        this.setState(prev => {
            const filtered = prev.resourceSelections.map(r => {
                if (r.resourceId === resourceId) {
                    let { configurationIds } = r;

                    if (configurationIds.length === 0) {
                        var res = this.props.resources.find(r => r.id === resourceId);
                        configurationIds = res ? res.configurations.map(c => c.id) : [];
                    }

                    if (selected) {
                        configurationIds = configurationIds.concat([configId]);
                    } else if (configurationIds.length > 1) {
                        // Only filter if at least one selection
                        configurationIds = configurationIds.filter(c => c !== configId);
                    }

                    return { ...r, configurationIds: configurationIds };
                } else {
                    return r;
                }
            });

            return { resourceSelections: filtered };
        });
    }

    groupSelectionChanged = (activityFormatGroupId: string, selected: boolean) => {
        this.setState(s => ({ selectedGroupIds: selected ? s.selectedGroupIds.concat(activityFormatGroupId): s.selectedGroupIds.filter(g => g !== activityFormatGroupId) }))
    }


    minPlacesForExclusiveChanged = (val: number) => this.setState({ minPlacesForExclusive: this.validateMinPlacesForExclusive(val && !isNaN(val) ? val : 1 )});
    websiteDescriptionChanged = (val: string) => this.setState({ websiteDescription: this.validateWebsiteDescription(val) });
    noAvailabilityMessageChanged = (val: string) => this.setState({ noAvailabilityMessage: this.validateNoAvailabilityMessage(val) });
    webDescriptionPriceTemplateChanged = (val: string) => this.setState({ webDescriptionPriceTemplate: this.validateWebDescriptionPriceTemplate(val) });

    validateName = (val: string) => v.validate(val, 'name', [v.required], this.props.validationErrors);

    validateDescription = (val: string) => v.validate(val, 'description', [v.required], this.props.validationErrors);
    validateWebsiteDescription = (val: string) => v.validate(val, 'websiteDescription', [], this.props.validationErrors);
    validateColour = (val: string) => v.validate(val, 'description', [v.required], this.props.validationErrors);
    validateArchived = (val: boolean) => v.validate(val, 'archived', [], this.props.validationErrors);
    validateSequence = (val: number | null) => v.validate(val, 'sequence', [], this.props.validationErrors);
    validateMinPlacesForExclusive = (val: number) => v.validate(val, 'minPlacesForExclusive', [], this.props.validationErrors);
    validateRegistrationTermsId = (val: string) => v.validate(val, 'registrationTerms', [v.required], this.props.validationErrors);
    validateBookingTermsId = (val: string) => v.validate(val, 'bookingTerms', [v.required], this.props.validationErrors);
    validateCustomFields = (fields: ICustomField[]): ct.FormValue<ICustomField[]> => {
        const hasBlankValue = fields.filter(f => isNullOrEmpty(f.name) && isNullOrEmpty(f.description)).length > 0;
        const mappedFields = hasBlankValue ? fields : fields.concat([{ key: generateTempId(), name: '', description: '', type: CustomFieldType.Text, required: false, values: [], adminOnly: false, appliesTo: CustomFieldApplication.PerRegistration }]);
        return { controlId: 'mappedFields', value: mappedFields, isValid: mappedFields.filter(f => (!isNullOrEmpty(f.name) && isNullOrEmpty(f.description)) || (isNullOrEmpty(f.name) && !isNullOrEmpty(f.description))).length === 0 && this.ensureUniqueFieldNames(mappedFields), hasValidation: true };
    }

    ensureUniqueFieldNames = (fields: ICustomField[]) => {
        var allFieldsUnique =  Array.from(fields.filter(f => !isNullOrEmpty(f.name)).reduce((names, f) => {
            names.set(f.name, (names.get(f.name) || 0) + 1)
            return names;
        }, new Map<string, number>())).filter(e => e[1] > 1).length === 0;
        return allFieldsUnique;
    }

    productRequired = (value: any) => (value && value !== 'no_product' && this.props.products.findIndex(p => p.id === value && !p.archived) >= 0 ? undefined : 'validation:required');

    validateVariationProductId = (key: string, val: string) => v.validate(val, `${key}_product`, [this.productRequired], this.props.validationErrors);
    validateVariationMaxParticipants = (key: string, val: number) => v.validate(val, `${key}_maxParticipants`, [], this.props.validationErrors);
    validateVariationMinParticipants = (key: string, val: number) => v.validate(val, `${key}_minParticipants`, [], this.props.validationErrors);
    validateVariationName = (key: string, val: string) => v.validate(val, `${key}_name`, [], this.props.validationErrors);
    validateVariationRunningTime = (key: string, scheduleId: string, val: Time) => v.validate(val, `${key}_${scheduleId}_runningTime`, [], this.props.validationErrors);
    validateMinGapBefore = (key: string, scheduleId: string, val: Time) => v.validate(val, `${key}_${scheduleId}_minGapBefore`, [], this.props.validationErrors);
    validateMinGapAfter = (key: string, scheduleId: string, val: Time) => v.validate(val, `${key}_${scheduleId}_minGapAfter`, [], this.props.validationErrors);
    validateMinBreakDuration = (key: string, scheduleId: string, index: number, val: Time | null) => v.validate(val, `${key}_${scheduleId}_minBreakDuration`, index > 0 ? [v => this.validateTime(v, true)] : [], this.props.validationErrors);
    validateMaxBreakDuration = (key: string, scheduleId: string, index: number, val: Time | null) => v.validate(val, `${key}_${scheduleId}_maxBreakDuration`, index > 0 ? [v => this.validateTime(v, false)] : [], this.props.validationErrors);
    validateScheduleTimingTemplateName = (key: string, scheduleId: string, val: string) => v.validate(val, `${key}_${scheduleId}_timingTemplateName`, [], this.props.validationErrors);

    validateTimingTemplateName = (key: string, val: string) => v.validate(val, `${key}_validateTimingTemplateName`, [], this.props.validationErrors);
    validateMustBookItemsTogether = (key: string, val: boolean) => v.validate(val, `${key}_mustBookItemsTogether`, [], this.props.validationErrors);

    validateCustomerCategoryEnabled = (key: string, val: boolean) => v.validate(val, `${key}_enabled`, [], this.props.validationErrors);
    validateCategoryPlacesToBook = (key: string, val: number) => v.validate(val, `${key}_placesToBook`, [], this.props.validationErrors);
    validateCategoryMinQuantityPerBooking = (key: string, val: number) => v.validate(val, `${key}_minQuantityPerBooking`, [], this.props.validationErrors);
    validateCategoryMaxQuantityPerBooking = (key: string, val: number, min: number, enabled: boolean) => v.validate(val, `${key}_maxQuantityPerBooking`, [v => this.ensureMaxGreaterThanMin(val, min, 'ActivityFormatForm:bookingMaxLessThanMin'), v => this.ensureMaxGreaterThanZero(val, enabled, 'ActivityFormatForm:bookingMaxZero')], this.props.validationErrors);
    validateCategoryMaxOverallQuantity = (key: string, val: number, bookingMax: number, enabled: boolean) => v.validate(val, `${key}_maxOverallQuantity`, [v => this.ensureMaxGreaterThanMin(val, bookingMax, 'ActivityFormatForm:overallMaxLessThanBookingMax'), v => this.ensureMaxGreaterThanZero(val, enabled, 'ActivityFormatForm:overallMaxZero')], this.props.validationErrors);

    validateAdditionalProductProduct = (val: string) => v.validate(val, 'additionalProductId', [v.required, this.validateAdditionalProduct], []);
    validateAdditionalProductMinQty = (val: number) => v.validate(val, 'additionalProductMinQuantity', [], []);
    validateAdditionalProductMaxQty = (val: number) => v.validate(val, 'additionalProductMaxQuantity', [v.required], []);
    validateAdditionalProductDftQty = (val: number) => v.validate(val, 'additionalProductDefaultQuantity', [], []);
    validateAdditionalProductUnit = (val: ProductQuantityUnit) => v.validate(val, 'additionalProductQuantityUnit', [v.required], []);
    validateAdditionalProductSequence = (val: number) => v.validate(val, 'additionalProductSequence', [v.required], []);
    validateNoAvailabilityMessage = (val: string) => v.validate(val, 'noAvailabilityMessage', [], []);
    validateArrivalTimeOverride = (val: Time | null) => v.validate(val, 'arrivalTimeBeforeEventOverride', [], []);
    validateSnapOnlineBookingsToOverride = (val: number | null) => v.validate(val, 'snapOnlineBookingsToOverride', [], []);
    validateApplyAvailabilityRulesToExistingReservations = (val: string) => v.validate(val === 'ALL' ? true : false, 'applyAvailabilityRulesToExistingReservations', [], []);
    validateAdditionalProduct = (val: string) => this.props.products.findIndex(p => p.id === val && !p.archived) >= 0 ? undefined : 'validation:required';
    validateAllowMultipleCategoriesPerCustomer = (val: boolean) => v.validate(val, 'allowMultipleCategoriesPerCustomer', [], []);
    validatePriceTemplate = (val: string) => v.validate(val, 'priceTemplate', [this.priceTemplateValidation], []);
    validateMembershipTypeId = (val: string | null) => v.validate(val, 'membershipTypeId', [], []);
    validateWebDescriptionPriceTemplate = (val: string) => v.validate(val, 'webDescriptionPriceTemplate', [this.webDescriptionPriceTemplateValidation], []);

    ensureMaxGreaterThanMin = (max: number, min: number, msg: string) => max < min ? msg : undefined;
    ensureMaxGreaterThanZero = (val: number, enabled: boolean, msg: string) => enabled && val < 1 ? msg : undefined;

    validateTime = (val: Time | null, canBeZero: boolean) => {
        if (val !== null && (canBeZero || !val.isZero())) {
            return undefined;
        }
        return 'validation:required';
    }

    priceTemplateValidation: v.Validation = (value: string) => this.valiatePriceTemplateTags(value, 'ActivityFormatForm:priceTemplateTotalPriceTagError');
    webDescriptionPriceTemplateValidation: v.Validation = (value: string) => this.valiatePriceTemplateTags(value, 'ActivityFormatForm:webDescriptionPriceTemplateTagError');

    valiatePriceTemplateTags = (value: string, errorKey: string) => {
        if (isNullOrEmpty(value))
            return undefined;

        const validTags = ['min_price_pp', 'avg_price_pp','total_price'];
        const tags = value.match(/{{\w*(:[\w|&|&amp;|=|\s|%]*)?}}/g);
        const invalidTags = tags ? tags.filter(t => validTags.findIndex(vt => '{{' + vt + '}}' === t) < 0) : [];
        if (invalidTags.length > 0) {
            return errorKey;
        }
    }

    onRegistrationTermsChanged = (val: string) => this.setState({ registrationTermsId: this.validateRegistrationTermsId(val) })
    onBookingTermsChanged = (val: string) => this.setState({ bookingTermsId: this.validateBookingTermsId(val) })
    onMembershipTypeIdChanged = (val: string) => this.setState({ membershipTypeId: this.validateMembershipTypeId(val) })

    addAdditionalProduct = () => {
        this.setState(current => {
            var nextSeq = current.additionalProducts.length < 1 ? 1 : current.additionalProducts.reduce((max, p) => Math.max(max, p.sequence.value + 1), 1)
            var prod = { key: generateTempId(), id: '', productId: this.validateAdditionalProductProduct(''), minQuantity: this.validateAdditionalProductMinQty(0), maxQuantity: this.validateAdditionalProductMaxQty(1), defaultQuantity: this.validateAdditionalProductDftQty(0), quantityUnit: this.validateAdditionalProductUnit(ProductQuantityUnit.PerBooking), sequence: this.validateAdditionalProductSequence(nextSeq) };
            return ({ additionalProducts: current.additionalProducts.concat(prod) })
        });
    }

    removeAdditionalProduct = (key: string) => {
        this.setState(current => ({ additionalProducts: current.additionalProducts.filter(p => p.key !== key) }));
    }

    linkedActivityFormatSelectionChanged = (key: string, linkedActivityFormat: ActivityFormatLink) => {
        this.setState(s => ({ linkedActivityFormats: s.linkedActivityFormats.map(a => a.key === key ? linkedActivityFormat : a) }));
    }

    addLinkedActivityFormat = () => this.editAdditionalActivity(this.createAdditionalActivity());

    createAdditionalActivity = (): ActivityFormatLink => {
        var key = generateTempId();
        return {
            key: key,
            linkedActivityFormatId: '',
            offerAsAlternative: false,
            offerAsUpsell: false,
            minGapBeforeMainActivity: null,
            maxGapBeforeMainActivity: null,
            minGapAfterMainActivity: null,
            maxGapAfterMainActivity: null,
            webDescriptionOverride: null,
            maxUpsellTimeSlotsBefore: 1,
            maxUpsellTimeSlotsAfter: 1,
            customerCategoryMap: []
        }
    }

    removeLinkedActivityFormat = (key: string) => this.setState(s => ({ linkedActivityFormats: s.linkedActivityFormats.filter(la => la.key !== key) }))

    editAdditionalActivity = (linkedActivityFormat: ActivityFormatLink) => {

        const { venueId, activityFormat, products, showModal } = this.props;
        const { webDescriptionStyle } = this.state;

        showModal(<AdditionalActivityForm
            parentActivityFormatId={activityFormat ? activityFormat.id : ''}
            activityFormats={this.props.activityFormats.filter(f => f.venueId === venueId && !f.archived)}
            activityCustomerCategories={this.state.activityCustomerCategories}
            customerCategories={this.props.customerCategories.filter(c => c.venueId === venueId && !c.archived)}
            products={products}
            linkedActivityFormat={linkedActivityFormat}
            webDescriptionStyle={webDescriptionStyle}
            save={this.addOrUpdateAdditionalActivity}
            close={this.props.closeModal} />, 'AdditionalActivityForm');
    }

    addOrUpdateAdditionalActivity = (linkedActivityFormat: ActivityFormatLink) => {
        this.setState(prev => {
            const isNew = prev.linkedActivityFormats.findIndex(a => a.key === linkedActivityFormat.key) < 0;
            const newlinks = isNew ? prev.linkedActivityFormats.concat(linkedActivityFormat) : prev.linkedActivityFormats.map(a => a.key === linkedActivityFormat.key ? linkedActivityFormat : a);
            return { linkedActivityFormats: newlinks }
        }, this.props.closeModal)
    }

    showCreateLink = () => {
        const { activityFormat, closeModal } = this.props;
        const { activityCustomerCategories, resourceSelections, venue } = this.state;

        if (activityFormat && venue) {
            this.props.showModal(<CreateActivityFormatLink
                activityFormatId={activityFormat.id}
                customerCategories={activityCustomerCategories.filter(c => c.enabled)}
                publicWebsites={venue.publicWebsites.filter(pw => pw.resourceIds.filter(r => resourceSelections.filter(rs => rs.resourceId === r).length > 0).length > 0)}
                close={closeModal} />, 'CreateActivityFormatLink');
        }
    }

    onImageDrop = (files: File[]) => {
        const file = files[0];

        getImageProps(file).then(img => {
            if (img.width < 400 || img.width > 800) {
                this.setState({ imageError: 'ActivityFormatForm:imageWidth' });
            } else if (img.height > 800) {
                this.setState({ imageError: 'ActivityFormatForm:imageHeight' });
            } else {
                this.setState({ imageError: null, image: { file: file, preview: URL.createObjectURL(file) } });
            }
        });
    }

    render() {

        let message: any = null;
        const t = this.context.t;
        const { activityFormat, saveError, saveComplete, isNew, customerCategories: allCustomerCategories, products, activityFormats, activityFormatGroups, resources, venueId, isLoading,
            isLoadingCustomerCategories, isLoadingProducts, isLoadingResources, registrationTerms, bookingTerms, enableMultipleCategoriesPerCustomer, membershipTypes } = this.props;
        const { name, description, colour, resourceSelections, activityCustomerCategories, variations, availability, sequence, archived, minPlacesForExclusive, websiteDescription, webDescriptionBgColour,
            webDescriptionTextColour, webDescriptionStyle, products: afProducts, errorMessage, image, imageError, imageUrl, registrationTermsId, bookingTermsId, bookingExtraFields, registrationExtraFields,
            additionalProducts, noAvailabilityMessage, showAlternativeAvailability, showRemainingSpaces, arrivalTimeBeforeEventOverride, snapOnlineBookingsToOverride, membershipTypeId,
            applyAvailabilityRulesToExistingReservations, selectedGroupIds, allowMultipleCategoriesPerCustomer, priceTemplate, venue, webDescriptionPriceTemplate, linkedActivityFormats } = this.state;

        if (isLoading || isLoadingCustomerCategories || isLoadingProducts || isLoadingResources) {
            return <Loading />
        }

        const venueCustomerCategories = allCustomerCategories.filter(c => c.venueId === venueId && !c.archived);

        if (venueCustomerCategories.length === 0) {
            return <div className='row mt-15' style={({ padding: '10px 50px' })}>
                <div className='col-xs-12 alert alert-danger'>{t('ActivityFormatForm:noCustomerCategories')}</div>
                <div className='btn-toolbar'>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{t('Global:close')}</button>
                </div>
            </div>
        }

        const registrationTermsOptions = [{ key: '', name: t('General:selectRegistrationTerms') }].concat(registrationTerms.filter(t => t.venueId === venueId).map(t => ({ key: t.id, name: t.name })));
        const bookingTermsOptions = [{ key: '', name: t('ActivityFormatForm:selectBookingTerms') }].concat(bookingTerms.filter(t => t.venueId === venueId).map(t => ({ key: t.id, name: t.name })));
        const membershipTypeOptions = [{ key: '-1', name: t('ActivityFormatForm:noMembershipRestriction') }].concat(membershipTypes.filter(mt => !mt.archived).map(mt => ({ key: mt.id, name: mt.name })));
        if (saveError) {
            message = <ApiError error={saveError} />;
        } else if (errorMessage) {
            message = (<div className='bg-danger'>{errorMessage}</div>);
        }
        else if (saveComplete) {
            message = (<div className='bg-success'>{t('Global:saveComplete')}</div>);
        }

        let previewImg: JSX.Element | null = null;
        const previewImageUrl = image ? image.preview : imageUrl !== null ? imageUrl : null;
        if (previewImageUrl) {
            previewImg = <img src={previewImageUrl} className='file-preview' alt='preview' style={({ width: '100px' })}></img>;
        }

        const activityProducts = activityFormats.reduce<string[]>((acc, af) => acc.concat(af.products.map(p => p.productId)), []);
        const nonActivityProducts = products.filter(p => activityProducts.indexOf(p.id) < 0);

        const availabilityRuleVal = { ...applyAvailabilityRulesToExistingReservations, value: applyAvailabilityRulesToExistingReservations.value ? 'ALL' : 'NEW' };
        const availabilityRuleOptions = [{ key: 'NEW', name: t('ActivityFormatForm:applyToNewReservations') }, { key: 'ALL', name: t('ActivityFormatForm:applyToAllReservations') }]
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;

        return <div className='activity-format-form'>
            <h2 className='activity-format-title'>{isNew ? t('ActivityFormatForm:addActivityFormat') : name.value}</h2>

            <form className='data-form' onSubmit={this.saveActivityFormat} autoComplete='off'>
                <section className='form-panel'>
                    <div className='row'>
                        <div className='col-xs-6'>
                            <h4 className='form-section-heading'>{t('ActivityFormatForm:generalSettings')}</h4>
                        </div>
                        <div className='col-xs-6'>
                            <button className='byn btn-link' onClick={e => clickHandler(e, this.showCreateLink)}>{t('ActivityFormatForm:createLink')}</button>
                        </div>
                    </div>
                    <ct.TextBox id='name' labelKey='ActivityFormatForm:name' placeholderKey='ActivityFormatForm:name' value={name} callback={val => this.setState({ name: this.validateName(val) })} />
                    <ct.TextBox id='description' labelKey='ActivityFormatForm:description' placeholderKey='ActivityFormatForm:description' value={description} callback={val => this.setState({ description: this.validateDescription(val) })} />
                    <ct.Colours colours={colours} selectedColour={colour.value} colourChanged={c => this.setState({ colour: this.validateColour(c) })} />
                    <ct.NumberBox id='sequence' labelKey='ActivityFormatForm:sequence' placeholderKey='' min='1' value={sequence} callback={val => this.setState({ sequence: this.validateSequence(val) })} />
                    <ct.NumberBox id='minPlacesForExclusive' labelKey='ActivityFormatForm:minPlacesForExclusive' placeholderKey='' min='1' value={minPlacesForExclusive} callback={this.minPlacesForExclusiveChanged} />
                    <ct.Select id='registrationTerms' labelKey='ActivityFormatForm:registrationTerms' value={registrationTermsId} callback={this.onRegistrationTermsChanged} options={registrationTermsOptions} />
                    <ct.Select id='bookingTerms' labelKey='ActivityFormatForm:bookingTerms' value={bookingTermsId} callback={this.onBookingTermsChanged} options={bookingTermsOptions} />
                    <ct.Time id='arrivalTimeBeforeEventOverride' labelKey='ActivityFormatForm:arrivalTimeBeforeEventOverride' value={arrivalTimeBeforeEventOverride} callback={val => this.setState({ arrivalTimeBeforeEventOverride: this.validateArrivalTimeOverride(val) })} />
                    <ct.NumberBox id='snapOnlineBookingsToOverride' labelKey='ActivityFormatForm:snapOnlineBookingsToOverride' value={snapOnlineBookingsToOverride} callback={val => this.setState({ snapOnlineBookingsToOverride: this.validateSnapOnlineBookingsToOverride(val) })} />
                    <ct.Select id='membershipTypeId' labelKey='ActivityFormatForm:membershipTypeId' value={{ ...membershipTypeId, value: membershipTypeId.value || '-1' }} callback={this.onMembershipTypeIdChanged} options={membershipTypeOptions} />
                    <ct.Checkbox id='archived' labelKey='Global:archive' value={archived} callback={val => this.setState({ archived: this.validateArchived(val) })} />
                </section>

                <section className='form-panel'>
                    <h4 className='form-section-heading'>{t('ActivityFormatForm:variations')}</h4>
                    <ActivityFormatVariations
                        variations={variations}
                        activityFormatId={activityFormat ? activityFormat.id : ''}
                        activityFormats={activityFormats.filter(a => a.venueId === venueId && !a.archived)}
                        maxParticipantsChanged={this.maxParticipantsChanged}
                        minParticipantsChanged={this.minParticipantsChanged}
                        nameChanged={this.nameChanged}
                        runningTimeChanged={this.runningTimeChanged}
                        minGapBeforeChanged={this.minGapBeforeChanged}
                        minGapAfterChanged={this.minGapAfterChanged}
                        minBreakDurationChanged={this.minBreakDurationChanged}
                        maxBreakDurationChanged={this.maxBreakDurationChanged}
                        scheduleTimingTemplateNameChanged={this.scheduleTimingTemplateNameChanged}
                        mustBookItemsTogetherChanged={this.mustBookItemsTogetherChanged}
                        toggleCompatibleActivityFormatVariation={this.toggleCompatibleActivityFormatVariation}
                        timingTemplateNameChanged={this.timingTemplateNameChanged}
                        addVariation={this.addVariation}
                        removeVariation={this.removeVariation}
                        addSchedule={this.addSchedule}
                        removeSchedule={this.removeSchedule}
                    />
                </section>

                <section className='form-panel'>
                    <h4 className='form-section-heading'>{t('ActivityFormatForm:customerCategories')}</h4>
                    <ActivityFormatCustomerCategories
                        activityCustomerCategories={activityCustomerCategories}
                        customerCategories={venueCustomerCategories}
                        enableMultipleCategoriesPerCustomer={enableMultipleCategoriesPerCustomer}
                        allowMultipleCategoriesPerCustomer={allowMultipleCategoriesPerCustomer}
                        customerCategoryEnabledChanged={this.customerCategoryEnabledChanged}
                        placesToBookChanged={this.customerCategoryPlacesToBookChanged}
                        allowMultipleCategoriesPerCustomerChanged={this.allowMultipleCategoriesPerCustomerChanged}
                        quantitiesChanged={this.customerQuantitiesChanged} />
                </section>

                <section className='form-panel'>
                    <h4 className='form-section-heading'>{t('ActivityFormatForm:pricing')}</h4>
                    <ActivityFormatProducts activityFormatProducts={afProducts} activityCustomerCategories={activityCustomerCategories} products={products.filter(p => p.venueId === venueId)} productChanged={this.productChanged} customerCategoriesChanged={this.customerCategoriesChanged} enableForWebBookingsChanged={this.enableForWebBookingsChanged} addProduct={this.addProduct} removeProduct={this.removeProduct} />
                </section>

                <section className='form-panel'>
                    <h4 className='form-section-heading'>{t('ActivityFormatForm:bookingExtraFields')}</h4>
                    <ActivityFormatExtraFields extraFields={bookingExtraFields.value} fieldUsage={FieldUsage.Booking} allowMultipleCategoriesPerCustomer={allowMultipleCategoriesPerCustomer.value} onFieldsChanged={fields => this.setState({ bookingExtraFields: this.validateCustomFields(fields) })} />
                </section>

                <section className='form-panel'>
                    <h4 className='form-section-heading'>{t('ActivityFormatForm:registrationExtraFields')}</h4>
                    <ActivityFormatExtraFields extraFields={registrationExtraFields.value} fieldUsage={FieldUsage.Registration} allowMultipleCategoriesPerCustomer={allowMultipleCategoriesPerCustomer.value} onFieldsChanged={fields => this.setState({ registrationExtraFields: this.validateCustomFields(fields) })} />
                </section>

                <section className='form-panel'>
                    <h4 className='form-section-heading'>{t('ActivityFormatForm:additionalProducts')}</h4>
                    <ActivityFormatAdditionalProducts
                        venueId={venueId}
                        products={nonActivityProducts}
                        additionalProducts={additionalProducts}
                        additionalProductChanged={this.additionalProductChanged}
                        onProductMinQuantityChanged={this.onProductMinQuantityChanged}
                        onProductMaxQuantityChanged={this.onProductMaxQuantityChanged}
                        onProductDefaultQuantityChanged={this.onProductDefaultQuantityChanged}
                        onProductQuantityUnitChanged={this.onProductQuantityUnitChanged}
                        onProductSequenceChanged={this.onProductSequenceChanged}
                        removeAdditionalProduct={this.removeAdditionalProduct}
                        addAdditionalProduct={this.addAdditionalProduct} />
                </section>

                <section className='form-panel'>
                    <h4 className='form-section-heading'>{t('ActivityFormatForm:additionalActivities')}</h4>
                    <ActivityFormatAdditionalActivities
                        activityFormats={activityFormats}
                        products={products}
                        linkedActivityFormats={linkedActivityFormats}
                        editLinkedActivityFormat={this.editAdditionalActivity}
                        addLinkedActivityFormat={this.addLinkedActivityFormat}
                        removeLinkedActivityFormat={this.removeLinkedActivityFormat} />
                </section>

                <section className='form-panel'>
                    <h4 className='form-section-heading'>{t('ActivityFormatForm:resources')}</h4>
                    <ActivityFormatResources resources={resources.filter(r => r.venueId === venueId && !r.archived)} resourceSelections={resourceSelections} buildResourceStyle={this.buildResourceStyle} resourceStateChanged={this.resourceStateChanged} resourceConfigurationStateChanged={this.resourceConfigurationStateChanged} />
                </section>

                <section className='form-panel'>
                    <h4 className='form-section-heading'>{t('ActivityFormatForm:activityFormatGroups')}</h4>
                    <ActivityFormatGroups
                        groups={activityFormatGroups.filter(r => r.venueId === venueId && !r.archived)}
                        selectedActivityFormatGroupIds={selectedGroupIds}
                        activityFormatGroupSelectionChanged={this.groupSelectionChanged}/>
                </section>

                <section className='form-panel'>
                    <h4 className='form-section-heading'>{t('ActivityFormatForm:availability')}</h4>
                    <ct.Select id='applyAvailabilityRulesToExistingReservations' labelKey='ActivityFormatForm:applyAvailabilityRulesToExistingReservations' value={availabilityRuleVal} callback={val => this.setState({ applyAvailabilityRulesToExistingReservations: this.validateApplyAvailabilityRulesToExistingReservations(val)}) } options={availabilityRuleOptions} />
                    <OnLineAvailability availability={availability} addAvailability={this.addAvailability} editAvailability={this.editAvailability} removeAvailability={this.removeAvailability} dateFormat={dateFormat} />
                </section>

                <section className='form-panel'>
                    <h4 className='form-section-heading'>{t('ActivityFormatForm:noAvailabilityMessage')}</h4>
                    <label>{t('ActivityFormatForm:noAvailabilityMessageLbl') }</label>

                    <div className='row'>
                        <div className='col-md-12'>
                            <ct.HtmlInput id='noAvailabilityMessage' labelKey='ActivityFormatForm:noAvailabilityMessage' value={noAvailabilityMessage} callback={this.noAvailabilityMessageChanged} modules={quillToolbarWithImageAndLinks} quillClassName='at-panel-bg' editorStyle={webDescriptionStyle} inlineStyles={true} />
                        </div>
                    </div>

                    <ct.Checkbox id='showAlternativeAvailability' labelKey='ActivityFormatForm:showAlternativeAvailability' value={ct.asFormValue('showAlternativeAvailability', showAlternativeAvailability)} callback={val => this.setState({ showAlternativeAvailability: val })} />
                    <ct.Checkbox id='showRemainingSpaces' labelKey='ActivityFormatForm:showRemainingSpaces' value={ct.asFormValue('showRemainingSpaces', showRemainingSpaces)} callback={val => this.setState({ showRemainingSpaces: val })} />
                    <ct.TextBox id='priceTemplate' labelKey='ActivityFormatForm:priceTemplate' placeholderKey='ActivityFormatForm:priceTemplate' value={priceTemplate} callback={val => this.setState({ priceTemplate: this.validatePriceTemplate(val) })} />
                    <div className='alert alert-info'>
                        <p>{t('ActivityFormatForm:slotPriceTemplateTags')}</p>
                        <div><strong>{'{{min_price_pp}}'}</strong> - {t('ActivityFormatForm:priceTemplateMinPricePPTag')}</div>
                        <div><strong>{'{{avg_price_pp}}'}</strong> - {t('ActivityFormatForm:priceTemplateAvgPricePPTag')}</div>
                        <div><strong>{'{{total_price}}'}</strong> - {t('ActivityFormatForm:priceTemplateTotalPriceTag')}</div>
                    </div>
                </section>

                <section className='form-panel'>
                    <h4 className='form-section-heading'>{t('ActivityFormatForm:webDescription')}</h4>
                    <ColourPicker labelKey='ActivityFormatForm:websiteDescriptionBackgroundColour' colourHex={webDescriptionBgColour} onColourChanged={color => this.setState(s => ({ webDescriptionBgColour: color, webDescriptionStyle: this.buildWebDescriptionStyle(color, s.webDescriptionTextColour) }))} />
                    <ColourPicker labelKey='ActivityFormatForm:websiteDescriptionTextColour' colourHex={webDescriptionTextColour} onColourChanged={color => this.setState(s => ({ webDescriptionTextColour: color, webDescriptionStyle: this.buildWebDescriptionStyle(s.webDescriptionBgColour, color) }))} />
                    <ct.HtmlInput id='htmlTemplate' labelKey='' value={websiteDescription} callback={this.websiteDescriptionChanged} modules={quillToolbarWithImageAndLinks} editorStyle={webDescriptionStyle} inlineStyles={true} />

                    <p />

                    <ct.HtmlInput id='webDescriptionPriceTemplate' labelKey='ActivityFormatForm:webDescriptionPriceTemplate' value={webDescriptionPriceTemplate} callback={this.webDescriptionPriceTemplateChanged} modules={quillToolbarSingleLineTextFormatting} editorStyle={{ ...webDescriptionStyle, maxHeight: '50px' }} inlineStyles={true} />

                    <p />

                    <div className='alert alert-info'>
                        <p>{t('ActivityFormatForm:activityPriceTemplateTags')}</p>
                        <div><strong>{'{{min_price_pp}}'}</strong> - {t('ActivityFormatForm:priceTemplateMinPricePPTag')}</div>
                        <div><strong>{'{{avg_price_pp}}'}</strong> - {t('ActivityFormatForm:priceTemplateAvgPricePPTag')}</div>
                        <div><strong>{'{{total_price}}'}</strong> - {t('ActivityFormatForm:priceTemplateTotalPriceTag')}</div>
                    </div>

                    <label>{t('ActivityFormatForm:activityFormatImage')}</label>
                    <div>
                        <Dropzone multiple={false} accept={{ 'image/*': ['.png', '.gif', '.jpeg', '.jpg'] }} onDrop={this.onImageDrop}>
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()} className='file-drop'>
                                    <input {...getInputProps()} />
                                    <p>{t('Global:imageDropText')}</p>
                                </div>
                            )}
                        </Dropzone>
                        {previewImg}
                        {isNullOrEmpty(imageError) ? null : <div className='alert alert-danger'>{t(imageError)}</div>}
                    </div>
                </section>

                {message}

                <p />
                <div className='btn-toolbar'>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)}>{t('Global:save')}</button>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{t('Global:cancel')}</button>
                </div>
            </form>
        </div>;
    }

    minParticipantsChanged = (key: string, val: number) => this.setState(prev => ({ variations: prev.variations.map(v => v.key !== key ? v : { ...v, minParticipants: this.validateVariationMinParticipants(key, val)} ) }));
    maxParticipantsChanged = (key: string, val: number) => this.setState(prev => ({ variations: prev.variations.map(v => v.key !== key ? v : { ...v, maxParticipants: this.validateVariationMaxParticipants(key, val) }) }));
    nameChanged = (key: string, val: string) => this.setState(prev => ({ variations: prev.variations.map(v => v.key !== key ? v : { ...v, name: this.validateVariationName(key, val) }) }));

    runningTimeChanged = (key: string, scheduleId: string, val: Time) => this.setState(prev => ({ variations: prev.variations.map(v => v.key !== key ? v : { ...v, schedule: v.schedule.map(s => s.id === scheduleId ? { ...s, runningTime: this.validateVariationRunningTime(key, scheduleId, val) } : s) }) }));
    minGapBeforeChanged = (key: string, scheduleId: string, val: Time) => this.setState(prev => ({ variations: prev.variations.map(v => v.key !== key ? v : { ...v, schedule: v.schedule.map(s => s.id === scheduleId ? { ...s, minGapBefore: this.validateMinGapBefore(key, scheduleId, val) } : s) }) }));
    minGapAfterChanged = (key: string, scheduleId: string, val: Time) => this.setState(prev => ({ variations: prev.variations.map(v => v.key !== key ? v : { ...v, schedule: v.schedule.map(s => s.id === scheduleId ? { ...s, minGapAfter: this.validateMinGapAfter(key, scheduleId, val) } : s) }) }));
    minBreakDurationChanged = (key: string, scheduleId: string, index: number, val: Time | null) => this.setState(prev => ({ variations: prev.variations.map(v => v.key !== key ? v : { ...v, schedule: v.schedule.map(s => s.id === scheduleId ? { ...s, minBreakDuration: this.validateMinBreakDuration(key, scheduleId, index, val) } : s) }) }));
    maxBreakDurationChanged = (key: string, scheduleId: string, index: number, val: Time | null) => this.setState(prev => ({ variations: prev.variations.map(v => v.key !== key ? v : { ...v, schedule: v.schedule.map(s => s.id === scheduleId ? { ...s, maxBreakDuration: this.validateMaxBreakDuration(key, scheduleId, index, val) } : s) }) }));
    scheduleTimingTemplateNameChanged = (key: string, scheduleId: string, val: string) => this.setState(prev => ({ variations: prev.variations.map(v => v.key !== key ? v : { ...v, schedule: v.schedule.map(s => s.id === scheduleId ? { ...s, timingTemplateName: this.validateScheduleTimingTemplateName(key, scheduleId, val) } : s) }) }));

    timingTemplateNameChanged = (key: string, val: string) => this.setState(prev => ({ variations: prev.variations.map(v => v.key !== key ? v : { ...v, timingTemplateName: this.validateTimingTemplateName(key, val) }) }));
    mustBookItemsTogetherChanged = (key: string, val: boolean) => this.setState(prev => ({ variations: prev.variations.map(v => v.key !== key ? v : { ...v, mustBookItemsTogether: this.validateMustBookItemsTogether(key, val) }) }));
    toggleCompatibleActivityFormatVariation = (key: string, activityFormatId: string, activityFormatVariationId: string, selected: boolean) =>
        this.setState(prev => ({
            variations: prev.variations.map(v => v.key !== key ? v : {
                ...v,
                compatibleActivityFormatVariations: selected
                    ? (v.compatibleActivityFormatVariations || []).concat({ activityFormatId: activityFormatId, activityFormatVariationId: activityFormatVariationId })
                    : (v.compatibleActivityFormatVariations || []).filter(c => c.activityFormatVariationId !== activityFormatVariationId)
            })
        }));

    productChanged = (key: string, val: string) => this.setState(prev => ({ products: prev.products.map(v => v.key !== key ? v : { ...v, productId: this.validateVariationProductId(key, val) }) }));
    customerCategoriesChanged = (key: string, val: string[]) => this.setState(prev => ({ products: prev.products.map(p => p.key !== key ? p : { ...p, customerCategories: val }) }));
    enableForWebBookingsChanged = (key: string, val: boolean) => this.setState(prev => ({ products: prev.products.map(v => v.key !== key ? v : { ...v, enableForWebBookings: ct.asFormValue('enableForWebBookings', val) }) }));

    additionalProductChanged = (key: string, val: string) => this.setState(prev => ({ additionalProducts: prev.additionalProducts.map(p => p.key === key ? { ...p, productId: this.validateAdditionalProductProduct(val) } : p) }));
    onProductMinQuantityChanged = (key: string, val: number) => this.setState(prev => ({ additionalProducts: prev.additionalProducts.map(p => p.key === key ? { ...p, minQuantity: this.validateAdditionalProductMinQty(val) } : p) }));
    onProductMaxQuantityChanged = (key: string, val: number) => this.setState(prev => ({ additionalProducts: prev.additionalProducts.map(p => p.key === key ? { ...p, maxQuantity: this.validateAdditionalProductMaxQty(val) } : p) }));
    onProductDefaultQuantityChanged = (key: string, val: number) => this.setState(prev => ({ additionalProducts: prev.additionalProducts.map(p => p.key === key ? { ...p, defaultQuantity: this.validateAdditionalProductDftQty(val) } : p) }));
    onProductQuantityUnitChanged = (key: string, val: string) => this.setState(prev => ({ additionalProducts: prev.additionalProducts.map(p => p.key === key ? { ...p, quantityUnit: this.validateAdditionalProductUnit(parseInt(val)) } : p) }));
    onProductSequenceChanged = (key: string, val: number) => this.setState(prev => ({ additionalProducts: prev.additionalProducts.map(p => p.key === key ? { ...p, sequence: this.validateAdditionalProductSequence(val) } : p.sequence.value >= val ? { ...p, sequence: this.validateAdditionalProductSequence(p.sequence.value + 1) } : p) }));


    customerCategoryEnabledChanged = (customerCategoryId: string, val: boolean) => this.setState(prev => {
        const cactegories = prev.activityCustomerCategories.map(c => c.customerCategoryId !== customerCategoryId ? c : { ...c, enabled: this.validateCustomerCategoryEnabled(customerCategoryId, val), placesToBook: !val || c.placesToBook.value === 0 ? this.validateCategoryPlacesToBook(c.customerCategoryId, val ? 1 : 0) : c.placesToBook });
        const selectedCategories = cactegories.filter(c => c.enabled.value);
        // If only one category has been selected, make sure all products have that one category, otherwise make sure all product's categories are selected, removing ones that have been deselected.
        const products = selectedCategories.length === 1
            ? prev.products.map(p => ({ ...p, customerCategories: selectedCategories.map(c => c.customerCategoryId) }))
            : prev.products.map(p => ({ ...p, customerCategories: p.customerCategories.filter(c => selectedCategories.findIndex(sc => sc.customerCategoryId === c) >= 0) }));
        return { activityCustomerCategories: cactegories, products: products };
    });

    customerQuantitiesChanged = (customerCategoryId: string, min: number, bookingMax: number, totalMax: number) =>
        this.setState(prev => ({
            activityCustomerCategories: prev.activityCustomerCategories.map(c => c.customerCategoryId !== customerCategoryId ? c : {
                ...c,
                minQuantityPerBooking: this.validateCategoryMinQuantityPerBooking(customerCategoryId, min),
                maxQuantityPerBooking: this.validateCategoryMaxQuantityPerBooking(customerCategoryId, bookingMax, min, c.enabled.value),
                maxOverallQuantity: this.validateCategoryMaxOverallQuantity(customerCategoryId, Math.max(totalMax, bookingMax), bookingMax, c.enabled.value)
            })
        }));

    customerCategoryPlacesToBookChanged = (customerCategoryId: string, val: number) => this.setState(prev => ({ activityCustomerCategories: prev.activityCustomerCategories.map(c => c.customerCategoryId !== customerCategoryId ? c : { ...c, placesToBook: this.validateCategoryPlacesToBook(customerCategoryId, val) }) }));

    allowMultipleCategoriesPerCustomerChanged = (val: boolean) => this.setState({allowMultipleCategoriesPerCustomer: this.validateAllowMultipleCategoriesPerCustomer(val)});

    createVariation = () => {
        var key = generateTempId();
        var scheduleId = generateTempId();
        return {
            id: '',
            key: key,
            minParticipants: this.validateVariationMinParticipants(key, 1),
            maxParticipants: this.validateVariationMaxParticipants(key, 1),
            name: this.validateVariationName(key, ''),
            timingTemplateName: this.validateTimingTemplateName(key, ''),
            mustBookItemsTogether: this.validateMustBookItemsTogether(key, true),
            schedule: [this.createSchedule(key, scheduleId, 0)],
            compatibleActivityFormatVariations: []
        }
    };

    createSchedule = (variationKey: string, scheduleId: string, index: number) => ({
        id: scheduleId,
        isNew: true,
        sequence: index + 1,
        runningTime: this.validateVariationRunningTime(variationKey, scheduleId, Time.zero()),
        minGapBefore: this.validateMinGapBefore(variationKey, scheduleId, Time.zero()),
        minGapAfter: this.validateMinGapAfter(variationKey, scheduleId, Time.zero()),
        minBreakDuration: this.validateMinBreakDuration(variationKey, scheduleId, index, null),
        maxBreakDuration: this.validateMaxBreakDuration(variationKey, scheduleId, index, null),
        timingTemplateName: this.validateScheduleTimingTemplateName(variationKey, scheduleId, '')
    })

    createProduct = (activityCustomerCategories: ActivityCustomerCategory[]) => {
        var key = generateTempId();
        const selectedCustomerCategories = activityCustomerCategories.filter(c => c.enabled);
        const productCustomerCategories = selectedCustomerCategories.length === 1 ? selectedCustomerCategories.map(c => c.customerCategoryId) : [];
        return {
            id: '',
            key: key,
            productId: this.validateVariationProductId(key, 'no_product'),
            enableForWebBookings: ct.asFormValue('enableForWebBookings', true),
            customerCategories: productCustomerCategories
        }
    };

    createAvailability = (): ActivityFormatAvailability => {
        var key = generateTempId();
        return { id: '', key: key, days: Days.None, fromTime: new Time(9, 0, 0), toTime: new Time(20, 0, 0), maxDaysBefore: 30, minDaysBefore: 0, minParticipants: 1, maxParticipants:1, startDate: null, endDate: null }
    }

    addVariation = () => this.setState(prev => ({ variations: prev.variations.concat([this.createVariation()]) }));

    removeVariation = (key: string) => this.setState(prev => ({ variations: prev.variations.filter(v => v.key !== key) }));

    addSchedule = (variationKey: string) => this.setState(prev => ({ variations: prev.variations.map(v => v.key === variationKey ? { ...v, schedule: v.schedule.concat(this.createSchedule(variationKey, generateTempId(), v.schedule.length)) } : v) }));

    removeSchedule = (variationKey: string, scheduleId: string) => this.setState(prev => ({ variations: prev.variations.map(v => v.key === variationKey ? { ...v, schedule: v.schedule.filter(s => s.id !== scheduleId) } : v) }));

    addProduct = () => this.setState(prev => ({ products: prev.products.concat([this.createProduct(this.state.activityCustomerCategories)]) }));

    removeProduct = (key: string) => this.setState(prev => ({ products: prev.products.filter(p => p.key !== key) }));

    addAvailability = () => this.editOnlineAvailability(this.createAvailability());

    editAvailability = (key: string) => {
        var availability = this.state.availability.find(a => a.key === key);
        if (availability) this.editOnlineAvailability(availability);
    }

    removeAvailability = (key: string) => this.setState(prev => ({ availability: prev.availability.filter(p => p.key !== key) }));

    editOnlineAvailability = (availability: ActivityFormatAvailability) => this.props.showModal(<OnLineAvailabilityForm
        availability={availability}
        dateFormat={this.props.dateFormat}
        timeFormat={this.props.timeFormat}
        save={this.addOrUpdateAvailability}
        close={this.props.closeModal} />, 'OnlineAvailabilityForm');

    addOrUpdateAvailability = (availability: ActivityFormatAvailability) => {
        this.setState(prev => {
            const isNew = prev.availability.findIndex(a => a.key === availability.key) < 0;
            const newAvailability = isNew ? prev.availability.concat(availability) : prev.availability.map(a => a.key === availability.key ? availability : a);
            return {availability: newAvailability}
        }, this.props.closeModal)
    }

    buildResourceStyle(resource: Resource) {
        return { marginLeft: '5px', paddingLeft: '3px', borderLeft: `4px solid ${resource.colour}` };
    }
};


const mapStateToProps = (state: ApplicationState) => {
    const venue = state.venues.venues.find(v => v.id === state.venues.selectedVenueId);
    const enableMultipleCategoriesPerCustomer = venue ? venue.enableMultipleCategoriesPerCustomer : false;

    return {
        isLoading: state.activityFormats.isLoading,
        isSaving: state.activityFormats.isSaving,
        saveComplete: state.activityFormats.saveComplete,
        saveError: state.activityFormats.saveError,
        validationErrors: state.activityFormats.validationErrors,
        isLoadingProducts: state.products.isLoading,
        enableMultipleCategoriesPerCustomer: enableMultipleCategoriesPerCustomer,
        venues: state.venues.venues,
        products: state.products.products.filter(p => !p.archived),
        activityFormats: state.activityFormats.activityFormats,
        activityFormatGroups: state.activityFormatGroups.activityFormatGroups,
        resources: state.resources.resources,
        isLoadingResources: state.resources.isLoading,
        customerCategories: state.customerCategories.customerCategories,
        isLoadingCustomerCategories: state.customerCategories.isLoading,
        registrationTerms: state.termsAndConditions.registrationTerms,
        bookingTerms: state.termsAndConditions.bookingTerms,
        dateFormat: venue ? venue.dateFormat : state.venues.dateFormat,
        timeFormat: venue ? venue.timeFormat : state.venues.timeFormat,
        membershipTypes: state.memberships.membershipTypes
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
    editActivityFormat: bindActionCreators(ActivityFormatActions.actionCreators.editActivityFormat, dispatch),
    saveActivityFormat: bindActionCreators(ActivityFormatActions.actionCreators.saveActivityFormat, dispatch)
});

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(ActivityFormatForm);
