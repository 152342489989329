import { Days, Time, ValidationError } from '../../global/types';
import * as api from '../../apiClient';

export interface Product {
    id: string;
    venueId: string | null;
    type: ProductType;
    categoryIds: string[];
    name: string;
    description: string;
    taxRateId: string;
    taxRate: number;
    taxRateName: string;
    accountingCategory: string;
    accountingDepartment: string;
    nominalCode: string;
    pricingMode: ProductPricingMode;
    isActivityProduct: boolean;
    productImageUrl: string | null;
    archived: boolean;
    pricing: ProductPrice[];
    tags: ProductTag[];
    voucherRedemptionAmount: number | null;
}

export interface ProductPrice {
    id: string;
    isOverride: boolean;
    unitPrice: number;
    fixedPricing: ProductFixedPrice[];
    effectiveFrom: Date;
    effectiveTo: Date | null;
    effectiveDatesPricePoint: ProductPricePoint;
    tagId: string | null;
    appliesOnPricePoint: ProductPricePoint;
    applyPriceOnDays: Days;
    applyPriceFrom: Time | null;
    applyPriceTo: Time | null;
    archived: boolean;
}

export enum ProductPricingMode {
    PerUnit = 1,
    Fixed = 2
}

export enum ProductPricePoint {
    PurchaseDate = 0,
    EventOrPurchaseDate = 1
}

export interface ProductFixedPrice {
    minQuantity: number;
    maxQuantity: number;
    price: number;
}

export enum ProductType {
    Standard = 1,
    Voucher = 2,
    Membership = 3
}

export interface ProductTag {
    productTagId: string;
    tagId: string;
    name: string;
    colour: string;
    archived: boolean;
}

export interface ProductState {
    isLoading: boolean;
    products: Product[];
    loadError: api.ApiError | null;
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
}

export enum ProductActionTypes {
    RequestProducts = 'REQUEST_PRODUCTS',
    ReceivedProducts = 'RECEIVE_PRODUCTS',
    SaveProduct = 'SAVE_PRODUCT',
    ProductSaved = 'PRODUCT_SAVED',
    ProductSaveFailed = 'PRODUCT_SAVE_FAILED',
    EditProduct = 'EDIT_PRODUCT'
}

export interface LoadProducts {
    type: ProductActionTypes.RequestProducts;
}

export interface ReceiveProducts {
    type: ProductActionTypes.ReceivedProducts;
    products: Product[];
    error: api.ApiError | null;
}

export interface SaveProduct {
    type: ProductActionTypes.SaveProduct;
    isNew: boolean;
    productId?: string;
    product: Product;
    productImg: File | null;
}

export interface ProductSaved {
    type: ProductActionTypes.ProductSaved;
    productId: string;
}

export interface ProductSaveFailed {
    type: ProductActionTypes.ProductSaveFailed;
    error: api.ApiError;
}

export interface EditProduct {
    type: ProductActionTypes.EditProduct
}
