
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Link } from 'react-router-dom';
import * as PropTypes from 'prop-types'
import moment from 'moment';

import * as ct from '../../global/controls';

import * as api from '../../../store/apiClient';
import { ApplicationState } from '../../../store';
import Loading from '../../global/loading';
import Pagination from '../../global/pagination';
import CustomerMergeForm from './customerMergeForm';
import * as CustomerActions from '../../../store/pages/customer/actions'
import * as ModalActions from '../../../store/global/modal/actions';
import * as LoginActions from '../../../store/pages/login/actions';
import * as customerApi from '../../../store/pages/customer/customerApi';
import { clickHandler, formHandler, isNullOrEmpty } from '../../../utils/util';

import { FindCustomerSummary, MarketingPreference } from '../../../store/pages/customer/types';
import { CustomersFilter } from './customersFilter';
import MarketingPreferences from '../../global/marketingPreferences';
import { DateFormat, Venue } from '../../../store/pages/venues/types';
import { DateRange } from '../../global/controls';
import { Tag } from '../../../store/pages/tags/types';
import { ActivityFormat } from '../../../store/pages/activityFormats/types';
import ApiError from '../../global/apiError'

interface VenueCount {
    venueId: string;
    venueName: string;
    count: number;
}

interface IGetCustomerStatsRespone {
    totalCustomers: number;
    visitsThisMonth: VenueCount[];
    visitsThisMonthLastYear: VenueCount[];
    visitsLastMonth: VenueCount[];
    visitsLastMonthLastYear: VenueCount[];
}

interface VenueStats {
    venueId: string;
    venueName: string;
    thisMonth: number;
    thisMonthLastYear: number;
    lastMonth: number;
    lastMonthLastYear: number;
}

interface LocalProps { }

interface LocalState {
    isLoading: boolean;
    nameFilter: string;
    marketingPreferenceFilter: null | MarketingPreference
    venueSelection: string | null;
    lastVisitFrom: Date | null;
    lastVisitTo: Date | null;
    customerCreatedFrom: Date | null;
    customerCreatedTo: Date | null;
    minVisits: number | null;
    tagId: string | null;
    minAge: number | null;
    maxAge: number | null;
    bookedActivityFormatIds: string[];
    participantActivityFormatIds: string[];
    customers: FindCustomerSummary[];
    customerCount: number;
    loadError: api.ApiError | null;
    pageNumber: number;
    maxPage: number;
    pageSize: number;
    venues: Venue[];
    tags: Tag[];
    activityFormats: ActivityFormat[];
    dateFormat: DateFormat;
}

interface Actions {
    loadCustomers: (pageNumber: number, pageSize: number, nameFilter: string, marketingPreferenceFilter: MarketingPreference | null, venueSelection: string | null, lastVisitFrom: Date | null, lastVisitTo: Date | null, customerCreatedFrom: Date | null, customerCreatedTo: Date | null, minVisits: number | null, tagId: string | null, minAge: number | null, maxAge: number | null, bookedActivityFormatIds: string[], participantActivityFormatIds: string[]) => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
    logout: () => void;
}

interface CustomersPageState {
    totalCount: number | null;
    visitsThisMonth: VenueCount[];
    visitsThisMonthLastYear: VenueCount[];
    visitsLastMonth: VenueCount[];
    visitsLastMonthLastYear: VenueCount[];
    showAdvancedFilter: boolean;
    nameFilter: string;
    marketingPreferenceFilter: null | MarketingPreference
    venueSelection: string | null;
    lastVisit: DateRange;
    customerCreated: DateRange;
    minVisits: number | null;
    selectedTagId: string | null;
    minAge: number | null;
    maxAge: number | null;
    bookedActivityFormatIds: string[];
    participantActivityFormatIds: string[];
    downloading: boolean;
    selectedCustomerIds: string[];
}

type CustomersPageProps = LocalState & Actions & LocalProps;

class CustomersPage extends React.Component<CustomersPageProps, CustomersPageState> {

    constructor(props: CustomersPageProps) {
        super(props);

        this.state = {
            totalCount: null,
            visitsThisMonth: [],
            visitsThisMonthLastYear: [],
            visitsLastMonth: [],
            visitsLastMonthLastYear: [],
            showAdvancedFilter: false,
            nameFilter: props.nameFilter,
            marketingPreferenceFilter: props.marketingPreferenceFilter,
            venueSelection: props.venueSelection,
            lastVisit: { from: props.lastVisitFrom ? moment(props.lastVisitFrom) : null, to: props.lastVisitTo ? moment(props.lastVisitTo) : null },
            customerCreated: { from: props.customerCreatedFrom ? moment(props.customerCreatedFrom) : null, to: props.customerCreatedTo ? moment(props.customerCreatedTo) : null },
            minVisits: props.minVisits,
            selectedTagId: props.tagId,
            minAge: props.minAge,
            maxAge: props.maxAge,
            bookedActivityFormatIds: props.bookedActivityFormatIds,
            participantActivityFormatIds: props.participantActivityFormatIds,
            downloading: false,
            selectedCustomerIds: []
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        this.loadStats();
    }

    componentDidUpdate(prevProps: CustomersPageProps) {
        if (this.props.isLoading && !prevProps.isLoading) {
            this.setState({ selectedCustomerIds: [] })
        }
    }

    loadStats = () => {
        api.getJson<IGetCustomerStatsRespone>('api/v1/customer/stats')
            .subscribe(res => {
                const { totalCustomers, visitsThisMonth, visitsThisMonthLastYear, visitsLastMonth, visitsLastMonthLastYear } = res;
                this.setState({
                    totalCount: totalCustomers,
                    visitsThisMonth: visitsThisMonth,
                    visitsThisMonthLastYear: visitsThisMonthLastYear,
                    visitsLastMonth: visitsLastMonth,
                    visitsLastMonthLastYear: visitsLastMonthLastYear
                });
            }, err => { });
    }

    toggleCustomerSelection = (customerId: string, selected: boolean) => this.setState(s => ({ selectedCustomerIds: selected ? s.selectedCustomerIds.concat(customerId) : s.selectedCustomerIds.filter(c => c !== customerId) }))

    setPage = (pageNumber: number) => this.loadCustomers(pageNumber, this.props.pageSize, this.state.nameFilter, this.state.marketingPreferenceFilter, this.state.venueSelection, this.state.lastVisit, this.state.customerCreated, this.state.minVisits, this.state.selectedTagId, this.state.minAge, this.state.maxAge, this.state.bookedActivityFormatIds, this.state.participantActivityFormatIds);

    setPageSize = (pageSize: number) => this.loadCustomers(this.props.pageNumber, pageSize, this.state.nameFilter, this.state.marketingPreferenceFilter, this.state.venueSelection, this.state.lastVisit, this.state.customerCreated, this.state.minVisits, this.state.selectedTagId, this.state.minAge, this.state.maxAge, this.state.bookedActivityFormatIds, this.state.participantActivityFormatIds);

    reloadCustomers = () => this.loadCustomers(this.props.pageNumber, this.props.pageSize, this.state.nameFilter, this.state.marketingPreferenceFilter, this.state.venueSelection, this.state.lastVisit, this.state.customerCreated, this.state.minVisits, this.state.selectedTagId, this.state.minAge, this.state.maxAge, this.state.bookedActivityFormatIds, this.state.participantActivityFormatIds);

    toggleAdvancedFilter = () => this.setState(state => ({ showAdvancedFilter: !state.showAdvancedFilter }));

    search = () => this.loadCustomers(1, this.props.pageSize, this.state.nameFilter, this.state.marketingPreferenceFilter, this.state.venueSelection, this.state.lastVisit, this.state.customerCreated, this.state.minVisits, this.state.selectedTagId, this.state.minAge, this.state.maxAge, this.state.bookedActivityFormatIds, this.state.participantActivityFormatIds);

    clearSearch = () => this.applyFilter('', null, null, { from: null, to: null }, { from: null, to: null }, null, null, null, null, [], []);

    applyFilter = (nameFilter: string, marketingPreferenceFilter: null | MarketingPreference, venueSelection: string | null, lastVisit: DateRange, customerCreated: DateRange, minVisits: number | null, tagId: string | null, minAge: number | null, maxAge: number | null, bookedActivityFormats: string[], participantActivityFormats: string[]) => {
        this.setState({
            nameFilter: nameFilter,
            marketingPreferenceFilter: marketingPreferenceFilter,
            venueSelection: venueSelection,
            lastVisit: lastVisit,
            customerCreated: customerCreated,
            minVisits: minVisits,
            selectedTagId: tagId,
            minAge: minAge,
            maxAge: maxAge,
            bookedActivityFormatIds: bookedActivityFormats,
            participantActivityFormatIds: participantActivityFormats
        }, () => this.loadCustomers(1, this.props.pageSize, nameFilter, marketingPreferenceFilter, venueSelection, lastVisit, customerCreated, minVisits, tagId, minAge, maxAge, bookedActivityFormats, participantActivityFormats));
    }

    loadCustomers = (pageNumber: number, pageSize: number, name: string, marketingPreferenceFilter: null | MarketingPreference, venueSelection: string | null, lastVisit: DateRange, customerCreated: DateRange, minVisits: number | null, tagId: string | null, minAge: number | null, maxAge: number | null, bookedActivityFormats: string[], participantActivityFormats: string[]) => {
        const { loadCustomers } = this.props;
        loadCustomers(pageNumber, pageSize, name, marketingPreferenceFilter, venueSelection, lastVisit.from ? lastVisit.from.toDate() : null, lastVisit.to ? lastVisit.to.toDate() : null, customerCreated.from ? customerCreated.from.toDate() : null, customerCreated.to ? customerCreated.to.toDate() : null, minVisits, tagId, minAge, maxAge, bookedActivityFormats, participantActivityFormats);
    }

    exportToExcel = () => {
        const { nameFilter, marketingPreferenceFilter, venueSelection, lastVisit, minVisits, customerCreated, selectedTagId, minAge, maxAge, bookedActivityFormatIds, participantActivityFormatIds } = this.state;
        const reportName = this.context.t('CustomersPage:customersExcelReportName');

        this.setState({ downloading: true }, () => customerApi.downloadCustomers(nameFilter, marketingPreferenceFilter, reportName, venueSelection, lastVisit.from ? lastVisit.from.toDate() : null, lastVisit.to ? lastVisit.to.toDate() : null, customerCreated.from ? customerCreated.from.toDate() : null, customerCreated.to ? customerCreated.to.toDate() : null, minVisits, selectedTagId, minAge, maxAge, bookedActivityFormatIds, participantActivityFormatIds, (success: boolean, error: string | null) => this.setState({ downloading: false })));
    }

    mergeSelectedCustomers = () => {
        const { customers, dateFormat, showModal, closeModal, logout } = this.props;
        const { selectedCustomerIds } = this.state;

        const selectedCustomers = customers.filter(c => selectedCustomerIds.includes(c.id))
        const defaultCountry = this.props.venues[0].countryId;

        const close = (reload: boolean) => {
            closeModal();
            if (reload) {
                this.reloadCustomers();
            }
        }

        showModal(<CustomerMergeForm customers={selectedCustomers} defaultCountryId={defaultCountry} dateFormat={dateFormat} close={close} logout={logout}  />, 'CustomerMergeForm');
    }

    render() {
        const { t } = this.context;
        const { isLoading, venues, tags, activityFormats } = this.props;
        const { totalCount, visitsThisMonth, visitsThisMonthLastYear, visitsLastMonth, visitsLastMonthLastYear, nameFilter, marketingPreferenceFilter, venueSelection,
            lastVisit, customerCreated, selectedTagId, minAge, maxAge, showAdvancedFilter, minVisits, bookedActivityFormatIds, participantActivityFormatIds } = this.state;

        const body = isLoading ? <Loading /> : this.renderResultsTable();

        const venueStats = visitsThisMonth.map(v => ({
            venueId: v.venueId,
            venueName: v.venueName,
            thisMonth: v.count,
            thisMonthLastYear: visitsThisMonthLastYear.filter(x => x.venueId === v.venueId).reduce((ttl, x) => ttl + x.count, 0),
            lastMonth: visitsLastMonth.filter(x => x.venueId === v.venueId).reduce((ttl, x) => ttl + x.count, 0),
            lastMonthLastYear: visitsLastMonthLastYear.filter(x => x.venueId === v.venueId).reduce((ttl, x) => ttl + x.count, 0),
        })).filter(x => x.thisMonth + x.thisMonthLastYear + x.lastMonth + x.lastMonthLastYear > 0);

        const countStyle = { fontSize: '24px' }

        return <section className='customersPage'>
            <header className='section-header'>
                <div className='page-heading'>
                    <h1 className='customers_title'>{t('CustomersPage:title')}</h1>
                </div>
            </header>
            <div className='clearfix' />

            <form onSubmit={e => formHandler(e, this.search)}>
                <div className='row row-flex'>
                    <div className='col-xs-4 flex'>
                        <div className='panel panel-primary text-center flex-stretch'>
                            <div className="panel-heading"><h4 className='no-margin'>{t('CustomersPage:totalCustomers')}</h4></div>
                            <div className="panel-body" style={countStyle}>{totalCount ? totalCount.toLocaleString() : ''}</div>
                        </div>
                    </div>
                    <div className='col-xs-4 flex'>
                        <div className='panel panel-primary text-center flex-stretch'>
                            <div className="panel-heading"><h4 className='no-margin'>{t('CustomersPage:visitsThisMonth')}</h4></div>
                            <div className="panel-body">
                                {this.renderCounts(venueStats, s => s.thisMonth, s => s.thisMonthLastYear)}
                            </div>
                        </div>
                    </div>
                    <div className='col-xs-4 flex'>
                        <div className='panel panel-primary text-center flex-stretch'>
                            <div className="panel-heading"><h4 className='no-margin'>{t('CustomersPage:visitsLastMonth')}</h4></div>
                            <div className="panel-body">
                                {this.renderCounts(venueStats, s => s.lastMonth, s => s.lastMonthLastYear)}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='clearfix' />

                {showAdvancedFilter
                    ? <CustomersFilter
                        show={true}
                        nameFilter={nameFilter}
                        marketingPreference={marketingPreferenceFilter}
                        venueSelection={venueSelection}
                        lastVisit={lastVisit}
                        customerCreated={customerCreated}
                        selectedTagId={selectedTagId}
                        minAge={minAge}
                        maxAge={maxAge}
                        minimumVisits={minVisits}
                        bookedActivityFormatIds={bookedActivityFormatIds}
                        participantActivityFormatIds={participantActivityFormatIds}
                        applyFilter={this.applyFilter}
                        venues={venues}
                        tags={tags}
                        activityFormats={activityFormats} />
                    : <div className='flex flex-nowrap flex-center'>
                        <div className='flex-stretch'><ct.TextBox id='name' labelKey='customerDetails:nameSearchLabel' placeholderKey='customerDetails:nameSearchLabel' value={ct.asFormValue('name', nameFilter)} callback={val => this.setState({ nameFilter: val })} /></div>
                        <input type='submit' className='btn btn-primary flex-shrink ml-10' value={t('Global:search')} />
                        <button className='btn btn-info flex-shrink ml-10' onClick={e => clickHandler(e, this.toggleAdvancedFilter)}>{t('CustomersPage:advancedSearch')}</button>
                        <button className='btn btn-warning flex-shrink ml-10' onClick={e => clickHandler(e, this.clearSearch)}>{t('Global:clear')}</button>
                    </div>
                }
            </form>
            {body}
        </section>;
    }

    renderCounts = (stats: VenueStats[], thisYearSelector: (s: VenueStats) => number, lastYearSelector: (s: VenueStats) => number) => {
        const countStyle = { fontSize: '24px' }
        const lyCountStyle = { fontSize: '20px' }
        const cellStyle = { verticalAlign: 'middle', padding: '0' }

        if (stats.length === 0) return null;

        return <table className='table table-condensed table-borderless' style={{ margin: '0' }}>
            <thead>
                <tr>
                    <th style={cellStyle}></th>
                    <th style={cellStyle} className='text-center'>{new Date().getFullYear()}</th>
                    <th style={cellStyle} className='text-center'>{new Date().getFullYear() - 1}</th>
                </tr>
            </thead>
            <tbody>
                {stats.map(v => <tr key={v.venueId}>
                    <td style={cellStyle}>{stats.length > 1 ? v.venueName : ''}</td>
                    <td style={cellStyle}><span style={countStyle}>{thisYearSelector(v).toLocaleString()}</span></td>
                    <td style={cellStyle}><span style={lyCountStyle}>({lastYearSelector(v).toLocaleString()})</span></td>
                </tr>)}
            </tbody>
        </table>
    }

    renderResultsTable = () => {
        const { t } = this.context;
        const { selectedCustomerIds, downloading } = this.state;
        const { customers, customerCount, maxPage, pageSize, pageNumber, isLoading, loadError, dateFormat } = this.props;

        if (loadError) {
            return <ApiError error={loadError} />
        }

        if (customers.length === 0 && !isLoading) {
            return <div className='alert alert-info'>{t(customerCount < 0 ? 'CustomersPage:enterSearchTerm' : 'CustomersPage:noCustomersFound')}</div>
        }

        const mapTags = (customer: FindCustomerSummary) => {
            const tags = customer.tags.map(t => <span key={t.membershipId ? `${t.id}_${t.membershipId}` : t.id} className='label' style={({ backgroundColor: t.colour, margin: '0 2px' })}>{t.name}</span>);
            if (customer.hasActiveMembership) {
                tags.push(<span key={'__member__'} className='label label-info'>{t('Global:member')}</span>)
            }
            return tags;
        }

        const rows = customers.map(cust =>
            <tr key={cust.id}>
                <td><input type='checkbox' id={cust.id} checked={selectedCustomerIds.includes(cust.id)} onChange={e => this.toggleCustomerSelection(cust.id, e.currentTarget.checked)} /></td>
                <td><Link className='btn btn-link btn-no-padding' to={`/customer/${cust.id}`}>{cust.firstname} {cust.lastname}</Link></td>
                <td>{cust.companyName}</td>
                <td>{cust.emailAddress}</td>
                <td>{cust.postalCode}</td>
                <td>{cust.phoneNumber}</td>
                <td>{cust.age}</td>
                <td>{cust.lastBooking ? cust.lastBooking.map(d => <div key={d.venueName} style={{ marginBottom: '2px' }}>{d.date.toAbbrDateString(dateFormat, t)} {isNullOrEmpty(d.venueName) ? null : <span className='label label-default'>{d.venueName}</span>}</div>) : ''}</td>
                <td>{cust.lastVisit ? cust.lastVisit.map(d => <div key={d.venueName} style={{ marginBottom: '2px' }}>{d.date.toAbbrDateString(dateFormat, t)} {isNullOrEmpty(d.venueName) ? null : <span className='label label-default'>{d.venueName}</span>}</div>) : ''}</td>
                <td>{cust.numberOfVisits}</td>
                <td>{cust.created.toShortDateString(dateFormat)}</td>
                <td>{mapTags(cust)}</td>
                <td><MarketingPreferences customerId={cust.id} preference={cust.marketingPreference} /></td>
            </tr>
        );

        return <div className='panel'>
            <div className='table-responsive'>
                <table className='table table-striped table-sm table-condensed'>
                    <thead>
                        <tr key='customer-templates-header'>
                            <th></th>
                            <th>{t('CustomersPage:nameHeading')}</th>
                            <th>{t('CustomersPage:companyHeading')}</th>
                            <th>{t('CustomersPage:emailAddressHeading')}</th>
                            <th>{t('CustomersPage:postcodeHeading')}</th>
                            <th>{t('CustomersPage:phoneNumberHeading')}</th>
                            <th>{t('CustomersPage:age')}</th>
                            <th>{t('CustomersPage:lastBookingHeading')}</th>
                            <th>{t('CustomersPage:lastEventHeading')}</th>
                            <th>{t('CustomersPage:numberOfEventsHeading')}</th>
                            <th>{t('CustomersPage:created')}</th>
                            <th>{t('Global:tags')}</th>
                            <th>{t('CustomersPage:marketing')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>
            <div className='panel-footer'>
                {customers.length > 0 ? <Pagination maxPage={maxPage} pageSize={pageSize} pageNumber={pageNumber} setPage={this.setPage} setPageSize={this.setPageSize} /> : null}
                <div className='text-center'>
                    {selectedCustomerIds.length < 2 ? null : <button className='btn btn-primary mh-10' onClick={e => clickHandler(e, this.mergeSelectedCustomers)}>{t('CustomersPage:merge')}</button>}
                    {isLoading || (customers && customers.length < 1) ? null : <button className='btn btn-info mh-10' onClick={e => clickHandler(e, this.exportToExcel)} disabled={downloading}>{t('Global:excel')}</button>}
                </div>
                {downloading ? <div className='alert alert-info mt-15'>{t('CustomersPage:downloading')}</div> : null}
            </div>
        </div>
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return {
        isLoading: state.customers.isLoading,
        nameFilter: state.customers.nameFilter,
        marketingPreferenceFilter: state.customers.marketingPreferenceFilter,
        venueSelection: state.customers.venueSelection,
        lastVisitFrom: state.customers.lastVisitFrom,
        lastVisitTo: state.customers.lastVisitTo,
        minVisits: state.customers.minVisits,
        tagId: state.customers.tagId,
        minAge: state.customers.minAge,
        maxAge: state.customers.maxAge,
        bookedActivityFormatIds: state.customers.bookedActivityFormatIds,
        participantActivityFormatIds: state.customers.participantActivityFormatIds,
        customers: state.customers.customers,
        customerCount: state.customers.customerCount,
        loadError: state.customers.loadError,
        pageNumber: state.customers.pageNumber,
        maxPage: state.customers.maxPage,
        pageSize: state.customers.pageSize,
        venues: state.venues.venues,
        tags: state.tags.tags.filter(t => !t.archived),
        activityFormats: state.activityFormats.activityFormats,
        dateFormat: state.venues.dateFormat
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadCustomers: bindActionCreators(CustomerActions.actionCreators.loadCustomers, dispatch),
    showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
    logout: bindActionCreators(LoginActions.actionCreators.logout, dispatch),
});

// Wire up the React component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(CustomersPage);

