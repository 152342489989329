
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';
import { ITranslationContext } from '../../../translations';

import { ReservationType } from '../../../store/pages/diary/types';

interface ReservationTypePanelProps {
    reservationType: ReservationType;
    maxParticipants: ct.FormValue<number>;
    reservationTypeChanged: (reservationType: ReservationType) => void;
    maxParticipantsChanged: (val: number) => void;
}

const ReservationTypePanel = (props: ReservationTypePanelProps, context: ITranslationContext) => {
    const { reservationType, maxParticipants, reservationTypeChanged, maxParticipantsChanged } = props;
    const { t } = context;

    let content = null;
    switch (reservationType) {
        case ReservationType.Exclusive:
            content = (
                <div className='form-group reservation-type-button alert-danger'>
                    {t('ReservationDetailsPage:exclusiveBookingDescription')}
                </div>
            );
            break;
        case ReservationType.NonExclusive:
            content = (
                <>
                    <div className='form-group reservation-type-button alert-success'>
                        {t('ReservationDetailsPage:publicBookingDescription')}
                    </div>
                    <div>
                        <ct.NumberBox id='maxParticipants' labelKey={'ReservationDetailsPage:maxParticipants'} placeholderKey={''} value={maxParticipants} callback={maxParticipantsChanged} />
                    </div>
                </>
                );
            break;
        default:
            return null;
    }

    return <div className='row mt-15'>
        <div className='col-md-12'>
            <ct.Toggle
                id='ts1'
                callback={checked => reservationTypeChanged(checked ? ReservationType.NonExclusive : ReservationType.Exclusive)}
                value={reservationType === ReservationType.NonExclusive}
                width={170}
                offLabelKey='Global:exclusive'
                onLabelKey='Global:shared' />
            {content}
        </div>
    </div>
};

ReservationTypePanel.contextTypes = {
    t: PropTypes.func
}

export default ReservationTypePanel;