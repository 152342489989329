
import { Action } from 'redux';

import { ProductActionTypes, ProductState } from './types';
import { ProductActions } from './actions'
import { createReducer } from '../../reducer';


const unloadedState: ProductState = { isLoading: false, products: [], loadError: null, isSaving: false, saveComplete: false, saveError: null, validationErrors: [] };

export const reducer = (state: ProductState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: ProductState, incomingAction: Action): ProductState => {

    const action = incomingAction as ProductActions;
    switch (action.type) {
        case ProductActionTypes.RequestProducts:
            return {
                ...state,
                isLoading: true,
                products: state.products,
                loadError: null
            };
        case ProductActionTypes.ReceivedProducts:
            const ordered = action.products ? action.products : [];
            ordered.sort((a, b) => a.name.localeCompare(b.name));

            return {
                ...state,
                isLoading: false,
                products: ordered,
                loadError: action.error
            };
        case ProductActionTypes.EditProduct:
            return {
                ...state,
                isSaving: false,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case ProductActionTypes.SaveProduct:
            return {
                ...state,
                isSaving: true,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case ProductActionTypes.ProductSaved:
            return {
                ...state,
                isSaving: false,
                saveComplete: true,
                saveError: null,
                validationErrors: []
            };
        case ProductActionTypes.ProductSaveFailed:
            return {
                ...state,
                isSaving: false,
                saveError: action.error,
                saveComplete: false,
                validationErrors: action.error && action.error.validationErrors ? action.error.validationErrors : []
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

