
import * as PropTypes from 'prop-types'

import { CustomerCategoryCount } from '../../../store/pages/diary/types';
import ReservationInfoLabel from '../diary/reservationInfoLabel';

interface CompetitorCountsProps {
    bookedParticipants: CustomerCategoryCount[];
    registeredParticipants: CustomerCategoryCount[];
    checkedInParticipants: CustomerCategoryCount[];
    maxParticipants?: number;
}

const CompetitorCounts = (props: CompetitorCountsProps) => {
    const { bookedParticipants, registeredParticipants, checkedInParticipants, maxParticipants } = props;
    const booked = bookedParticipants.reduce((ttl, c) => ttl + c.count, 0);
    const registered = registeredParticipants.reduce((ttl, c) => ttl + c.count, 0);
    const checkedIn = checkedInParticipants.reduce((ttl, c) => ttl + c.count, 0);

    const registeredCls = registered >= booked ? 'reception-event-count-complete' : '';
    const checkedInCls = checkedIn >= booked ? 'reception-event-count-complete' : '';

    return booked > 0
        ? <>
            <div key='booked' className='reception-reservation-booked'><ReservationInfoLabel quantity={maxParticipants ? booked : undefined} totalQuantity={maxParticipants ? maxParticipants : booked} icon='user' showWhenComplete={false} className='' /></div>
            <div key='registered' className='reception-reservation-registered'><ReservationInfoLabel quantity={registered || 0} totalQuantity={booked} icon='list-alt' showWhenComplete={false} className={registeredCls} /></div>
            <div key='checkedIn' className='reception-reservation-checked-in'><ReservationInfoLabel quantity={checkedIn || 0} totalQuantity={booked} icon='check' showWhenComplete={false} className={checkedInCls} /></div>
        </>
        : <div className='reception-counts-filler'></div>
}


CompetitorCounts.contextTypes = {
    t: PropTypes.func
}

export default CompetitorCounts;