
import * as React from 'react';
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import * as ct from '../../global/controls';
import * as api from '../../../store/apiClient';

import { ApplicationState } from '../../../store';

import { DateFormat, Venue } from '../../../store/pages/venues/types';
import { ProductCategory } from '../../../store/pages/productCategories/types';
import { Product } from '../../../store/pages/products/types';
import { CustomerCategory } from '../../../store/pages/customerCategories/types';
import { Bill, BillPayment } from '../../../store/pages/pointOfSale/types';
import { ActivityFormatProduct } from './helpers';
import { ActivityFormat } from '../../../store/pages/activityFormats/types';
import { PaymentMethod } from '../../../store/pages/paymentMethods/types'
import { Fee } from '../../../store/pages/fees/types';
import { TaxRate } from '../../../store/pages/taxRates/types';
import { Promotion } from '../../../store/pages/promotions/types';
import { VoucherProduct } from '../../../store/pages/vouchers/types';
import { Booking } from './types';
import PointOfSalePanel, { BillInfo } from '../pointOfSale/pointOfSalePanel';
import { PaymentSchedule } from '../../../store/pages/paymentSchedules/types';
import { clickHandler, parseUtcDate } from '../../../utils/util';
import ApiError from '../../global/apiError';
import { MembershipType } from '../../../store/pages/memberships/types';

interface GetPaymentsForScheduleResponse {
    paymentSchedule: BillPaymentSchedule;
}

interface BillPaymentSchedule {
    balanceDueImmediately: number | null;
    paymentScheduleId: string;
    futurePayments: BillFuturePayment[];
}

interface BillFuturePayment {
    description: string;
    amount: number;
    dueDate: Date;
}

interface LocalProps {
    venue: Venue;
    productCategories: ProductCategory[];
    products: Product[];
    eventProducts: ActivityFormatProduct[];
    booking: Booking | null;
    payments: BillPayment[];
    customerId?: string;
    billInfo: BillInfo;
    paymentId?: string | null;
    showPayments?: boolean;
    paymentAmount?: number;
    dateFormat: DateFormat;
    posSessionComplete: (bill: Bill) => void;
    close: (reloadBill: boolean) => void;
    logout: () => void;
}

interface MappedReduxState {
    paymentSchedules: PaymentSchedule[];
    activityFormats: ActivityFormat[];
    paymentMethods: PaymentMethod[];
    fees: Fee[];
    taxRates: TaxRate[];
    promotions: Promotion[];
    vouchers: VoucherProduct[];
    membershipTypes: MembershipType[];
    customerCategories: CustomerCategory[];
}

interface Actions {

}

type BookingPaymentProps = MappedReduxState & Actions & LocalProps;

interface BookingPaymentState {
    showScheduleSelection: boolean;
    selectedScheduleId: string | null;
    loadingSchedule: boolean;
    loadScheduleError: api.ApiError | null;
    canCreatePayments: boolean;
    paymentSchedule: BillPaymentSchedule | null;
    paymentAmount: number | null;
}


class BookingPayment extends React.Component<BookingPaymentProps, BookingPaymentState> {

    static contextTypes = {
        t: PropTypes.func
    }

    constructor(props: BookingPaymentProps) {
        super(props);

        this.state = {
            showScheduleSelection: props.paymentSchedules.length > 0 && props.payments.length === 0,
            selectedScheduleId: null,
            loadingSchedule: false,
            loadScheduleError: null,
            canCreatePayments: false,
            paymentSchedule: null,
            paymentAmount: null
        }
    }

    loadSchedule = (scheduleId: string | null) => {
        if (!scheduleId) return;

        const { billInfo } = this.props;
        const billId = typeof billInfo === "string" ? billInfo : null;

        if (!billInfo) return;

        this.setState({ loadingSchedule: true, loadScheduleError: null }, () => {
            api.getWithAuth<GetPaymentsForScheduleResponse>(`api/v1/paymentSchedule/${scheduleId}/payments/${billId}`, this.props.logout)
                .subscribe(
                    resp => this.setState({
                        loadingSchedule: false,
                        loadScheduleError: null,
                        paymentSchedule: { ...resp.paymentSchedule, futurePayments: resp.paymentSchedule.futurePayments.map(p => ({ ...p, dueDate: parseUtcDate(p.dueDate)})) },
                        canCreatePayments: (resp.paymentSchedule.balanceDueImmediately && resp.paymentSchedule.balanceDueImmediately > 0) || resp.paymentSchedule.futurePayments.length > 0
                    }),
                    err => this.setState({ loadingSchedule: false, canCreatePayments: false, loadScheduleError: err, paymentSchedule: null }))
        })
    }

    createPayments = () => {
        const { selectedScheduleId } = this.state;
        const { billInfo } = this.props;

        const billId = typeof billInfo === "string" ? billInfo : null;

        if (!selectedScheduleId || !billInfo) return;

        this.setState({ loadingSchedule: true, loadScheduleError: null }, () => {
            api.postWithAuth(`api/v1/paymentSchedule/${selectedScheduleId}/payments`, { billId: billId }, this.props.logout)
                .subscribe(
                    resp => this.setState({
                        loadingSchedule: false,
                        loadScheduleError: null,
                    }, () => this.takeImmediatePayment(resp.response.balanceDueImmediately)),
                    err => this.setState({ loadingSchedule: false, loadScheduleError: err }))
        })

    }

    takeImmediatePayment = (balanceDueImmediately: number | null) => {
        if (balanceDueImmediately) {
            this.setState({ showScheduleSelection: false, paymentAmount: balanceDueImmediately })
        } else {
            this.props.close(true);
        }
    }

    selectedSchduleChanged = (scheduleId: string | null) => {
        const { paymentSchedules } = this.props;

        const schedule = paymentSchedules.filter(s => s.id === scheduleId)[0];
        const id = schedule ? schedule.id : null;
        this.setState({ selectedScheduleId: id, canCreatePayments: false }, () => this.loadSchedule(id))
    }

    render() {
        const { activityFormats, venue, products, eventProducts, productCategories, paymentMethods, taxRates, fees, promotions, customerCategories,
            booking, billInfo, customerId, posSessionComplete, vouchers, membershipTypes, paymentSchedules, close, logout  } = this.props;

        const { showScheduleSelection, selectedScheduleId, canCreatePayments, loadScheduleError, loadingSchedule, paymentSchedule, paymentAmount } = this.state;
        const { t } = this.context;

        if (showScheduleSelection) {

            const paymentScheduleOptions = [{key: '', name: t('PointOfSale:selectPaymentSchedule')}].concat(paymentSchedules.map(s => ({ key: s.id, name: s.name })));

            return <div className='flex flex-center flex-col'>
                <h3 className='text-center'>{t('PointOfSale:paymentSchedule')}</h3>

                <div className='data-form'>
                    <ct.Select id='selectedScheduleId' labelKey='PointOfSale:paymentSchedule' value={ct.asFormValue('selectedScheduleId', selectedScheduleId ? selectedScheduleId : '')} callback={this.selectedSchduleChanged} options={paymentScheduleOptions} style={{ minWidth: '250px' }} disabled={loadingSchedule} />
                </div>

                {loadScheduleError ? <ApiError error={loadScheduleError} /> : null }

                {paymentSchedule ? this.renderSchedule(paymentSchedule) : null }

                <div className='btn-toolbar mt-15'>
                    <button className='btn btn-primary' disabled={loadingSchedule || !canCreatePayments} onClick={e => clickHandler(e, this.createPayments)} style={{ margin: '0 20px' }}>{t('PointOfSale:createScheduledPayments')}</button>
                    <button className='btn btn-info' onClick={e => clickHandler(e, () => this.setState({ showScheduleSelection: false, paymentAmount: null }))} style={{ margin: '0 20px' }}>{t('PointOfSale:manualPayment')}</button>
                </div>
            </div>
        } else {
            return <PointOfSalePanel
                venue={venue}
                showPayments={true}
                paymentAmount={paymentAmount ? paymentAmount : undefined}
                products={products}
                eventProducts={eventProducts}
                activityFormats={activityFormats}
                productCategories={productCategories}
                paymentMethods={paymentMethods}
                taxRates={taxRates}
                fees={fees}
                promotions={promotions}
                customerCategories={customerCategories}
                booking={booking}
                billInfo={billInfo}
                customerId={customerId}
                posSessionComplete={posSessionComplete}
                vouchers={vouchers}
                membershipTypes={membershipTypes}
                logout={logout}
            />
        }
    }

    renderSchedule = (paymentSchedule: BillPaymentSchedule) => {
        const { t } = this.context;
        const { dateFormat } = this.props;

        return <div>
            <div>
                <label>{t('PointOfSale:balanceDueNow')}</label>
                <div className='pos_main_total'>{t('Global:currencySymbol')}{(paymentSchedule.balanceDueImmediately ? paymentSchedule.balanceDueImmediately : 0).toFixed(2)}</div>
            </div>

            <div className='mt-15'>
                <label>{t('PointOfSale:scheduledPayments')}</label>
                <table className='table'>
                    <tbody>
                        {paymentSchedule.futurePayments.map(fp => <tr>
                            <td>{fp.dueDate.toAbbrDateString(dateFormat, t)}</td>
                            <td>{fp.description}</td>
                            <td>{t('Global:currencySymbol')}{fp.amount.toFixed(2)}</td>
                        </tr>)}
                    </tbody>
                </table>
            </div>

        </div>
    }
}

const mapStateToProps = (state: ApplicationState) => {
    const venueId = state.venues.selectedVenueId;

    return {
        paymentSchedules: state.paymentSchedules.paymentSchedules.filter(s => s.venueId === venueId && !s.archived),
        activityFormats: state.activityFormats.activityFormats,
        paymentMethods: state.paymentMethods.paymentMethods.filter(x => x.venueId === venueId && !x.archived),
        taxRates: state.taxRates.taxRates,
        fees: state.fees.fees.filter(f => f.venueId === venueId),
        promotions: state.promotions.promotions,
        vouchers: state.vouchers.voucherProducts,
        membershipTypes: state.memberships.membershipTypes,
        customerCategories: state.customerCategories.customerCategories,
        dateFormat: state.venues.dateFormat
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => ({});

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(BookingPayment);
