
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { quillStandardToolbar } from '../../global/quillSettings';

import * as api from '../../../store/apiClient';
import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import { clickHandler } from '../../../utils/util';
import ApiError from '../../global/apiError';

interface EditCustomerBookingNotesState {
    notes: ct.FormValue<string>;
    isSaving: boolean;
    saveError: api.ApiError | null;
}

interface EditCustomerBookingNotesProps {
    customerBookingNotes: string;
    save: (customerBookingNotes: string, saveFailed: (error: api.ApiError | null) => void) => void;
    close: () => void;
}

class EditCustomerBookingNotes extends React.Component<EditCustomerBookingNotesProps, EditCustomerBookingNotesState> {

    constructor(props: EditCustomerBookingNotesProps) {
        super(props);

        this.state = { notes: this.validateNotes(props.customerBookingNotes), isSaving: false, saveError: null };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    save = () => {
        const { notes } = this.state;
        const { save } = this.props;

        this.setState({ isSaving: true, saveError: null }, () => save(notes.value, (err) => this.setState({isSaving: false, saveError: err })))
    }

    validateNotes = (val: string) => v.validate(val, 'notes', [], []);

    render() {
        const { close } = this.props;
        const { notes, isSaving, saveError } = this.state;
        const { t } = this.context;

        const message = (saveError) ? <ApiError error={saveError} /> : null;

        return (
            <div>
                <h3>{t('EditCustomerBookingNotes:heading')}</h3>
                <div>
                    <ct.HtmlInput id='notes' labelKey='EditCustomerBookingNotes:notes' value={notes} callback={(val: string) => this.setState({ notes: this.validateNotes(val) })} modules={quillStandardToolbar} quillClassName='at-panel-bg' inlineStyles={true} />
                </div>

                {message}

                <div className='btn-toolbar' style={({ marginTop: '20px' })}>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)} disabled={isSaving}>{t('Global:save')}</button>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, close)} disabled={isSaving}>{t('Global:cancel')}</button>
                </div>
            </div>
        );
    }
}

export default EditCustomerBookingNotes;
