

import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';

import { ITranslationContext } from '../../../translations';
import { Venue } from '../../../store/pages/venues/types';
import { clickHandler } from '../../../utils/util';

interface CampaignVenueSelectionProps {
    venues: Venue[];
    selectedVenueIds: string[];
    venueSelectionChanged: (venueId: string, selected: boolean) => void;

}

const CampaignVenueSelection = (props: CampaignVenueSelectionProps, context: ITranslationContext) => {
    const { t } = context;
    const { venues, selectedVenueIds, venueSelectionChanged } = props;

    const sortedVeues = venues.sort((v1, v2) => v1.name.localeCompare(v2.name));

    const renderVenueSelections = () => {
        return <ul className='campaign-selected-venues'>{
            selectedVenueIds.length === 0
                ? [<li key='all_venues' style={{ backgroundColor: '#337ab7', color: '#FFF' }}>{t('CampaignPage:allVenues')}</li>]
                : sortedVeues.filter(v => selectedVenueIds.indexOf(v.id) >= 0).map(v => <li key={v.id}><span>{v.name}</span><span onClick={e => clickHandler(e, () => venueSelectionChanged(v.id, false))} className='glyphicon glyphicon-trash red' style={({ cursor: 'pointer', padding: '5px', marginLeft: '4px' })}></span></li>)}</ul>
    }

    const venueSelections = [{ key: '', name: t('CampaignPage:selectVenueToAdd') }].concat(sortedVeues.map(v => ({ key: v.id, name: v.archived ? `${v.name} (${t('Global:archived')})` : v.name })));

    return <div className='form-group'>
        <ct.PlainSelect id='venue-selections' value={''} options={venueSelections} callback={v => venueSelectionChanged(v, true)} />
        {renderVenueSelections()}
    </div>
}



CampaignVenueSelection.contextTypes = {
    t: PropTypes.func
}

export default CampaignVenueSelection;