
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as ct from './types'; 
import { IStore } from '../..';


export type CampaignActions = ct.LoadCampaigns | ct.ReceiveCampaigns | ct.SaveCampaign | ct.CampaignSaved | ct.CampaignSaveFailed | ct.EditCampaign;

export const actionCreators = {
    loadCampaigns: (showComplete: boolean) => ({ type: ct.CampaignActionTypes.RequestCampaigns, showComplete: showComplete }),
    receivedCampaigns: (campaigns: ct.MarketingCampaign[], err: api.ApiError | null) => ({ type: ct.CampaignActionTypes.ReceivedCampaigns, campaigns: campaigns, error: err }),
    editCampaign: () => ({ type: ct.CampaignActionTypes.EditCampaign }),
    saveCampaign: (isNew: boolean, campaignId: string | null, campaign: ct.MarketingCampaign) => ({ type: ct.CampaignActionTypes.SaveCampaign, isNew: isNew, campaignId: campaignId, campaign: campaign }),
    campaignSaved: (campaignId: string) => ({ type: ct.CampaignActionTypes.CampaignSaved, campaignId: campaignId }),
    campaignSaveFailed: (error: api.ApiError) => ({ type: ct.CampaignActionTypes.CampaignSaveFailed, error: error }),
    campaigChanged: () => ({ type: ct.CampaignActionTypes.CampaigChanged })
}

interface IGetCampaignsResponse {
    campaigns: ct.MarketingCampaign[];
}

interface ISaveCampaignResponse {
    campaignId: string;
}

// When client changed, just clear the campaigns.  They will be re-loaded when the campaigns page is loaded
export const handleClientChange = (store: IStore) => [() => actionCreators.receivedCampaigns([], null)]

//https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-after-catching-and-processing-an-error-with-emitting-somethi

const loadCampaigns = (showComplete: boolean) => Observable.defer(() => api.getJson<IGetCampaignsResponse>(`api/v1/campaign/?showComplete=${showComplete}`))
    .map(response => actionCreators.receivedCampaigns(response.campaigns, null));

export const loadCampaignsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        ct.CampaignActionTypes.RequestCampaigns,
        action => loadCampaigns(action.showComplete),
        err => actionCreators.receivedCampaigns([], err));

export const reloadCampaignsEpic = (action$: ActionsObservable<any>, store: any) =>
    epic.create(action$,
        ct.CampaignActionTypes.CampaignSaved,
        action => loadCampaigns(store.value.campaigns.showComplete),
        err => actionCreators.receivedCampaigns([], err));

export const campaigChangedEpic = (action$: ActionsObservable<any>, store: any) =>
    action$.ofType(ct.CampaignActionTypes.CampaigChanged)
        .switchMap(action => {
            return Observable.of(actionCreators.loadCampaigns(store.value.campaigns.showComplete));
        });


export const saveCampaignEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        ct.CampaignActionTypes.SaveCampaign,
        action => {
            const stra = action as ct.SaveCampaign;
            const { campaign } = stra;
            var body = {
                ...campaign,
                id: stra.campaignId || stra.campaign.id,
                dailyRunStartTime: stra.campaign.dailyRunStartTime.toString(),
                dailyRunEndTime: stra.campaign.dailyRunEndTime ? stra.campaign.dailyRunEndTime.toString() : null,
            };

            return (stra.isNew ? api.post('api/v1/campaign/', body) : api.put(`api/v1/campaign/${stra.campaignId}`, body))
                .map(response => {

                    let campaignId = stra.campaignId;
                    if (stra.isNew) {
                        const strr = response.response as ISaveCampaignResponse;
                        if (strr) {
                            campaignId = strr.campaignId;
                        }
                    }

                    return ({ type: ct.CampaignActionTypes.CampaignSaved, campaignId: campaignId });
                });
        },
        (err: api.ApiError) => actionCreators.campaignSaveFailed(err));
