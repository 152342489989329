
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';

import { Event, EventBooking, WebBookingStatus } from '../../../store/pages/diary/types';
import { ClientEmailTemplate } from '../../../store/pages/emailTemplates/types';
import * as api from '../../../store/apiClient';
import { isNullOrEmpty } from '../../../utils/util';
import BookingConfirmationEmailSelection from './bookingConfirmationEmailSelection';
import { ITranslationContext } from '../../../translations';
import { stringComparer } from '../../../utils/comparers';

interface BookingEmailsPanelProps {
    event: Event;
    bookingConfirmationEmailTemplates: ClientEmailTemplate[];
    close: () => void;
}

interface BookingEmailTemplateSelection {
    bookingId: string;
    emailTemplateId: string;
    booking: EventBooking;
}

interface BookingConfirmationEmailsPanelState {
    bookings: EventBooking[];
    templateSelections: BookingEmailTemplateSelection[];
    templateOptions: ct.SelectOption[];
    saving: boolean;
    error: string | null;
}

export class BookingConfirmationEmailsPanel extends React.Component<BookingEmailsPanelProps, BookingConfirmationEmailsPanelState> {

    constructor(props: BookingEmailsPanelProps, context: ITranslationContext) {
        super(props);

        const { t } = context;
        const bookings = props.event.bookings.filter(b => !b.cancelled && isNullOrEmpty(b.bookingConfirmationClientEmailTemplateId) && b.customer !== null && (!b.isWebBooking || b.webBookingStatus === WebBookingStatus.Complete));
        const templateOptions = props.bookingConfirmationEmailTemplates.sort((t1, t2) => t1.emailType === t2.emailType ? stringComparer(t1.name || '', t2.name || '') : t1.emailType - t2.emailType).map(t => ({ key: t.clientEmailTemplateId || '', name: t.name || '' })).filter(x => !isNullOrEmpty(x.key) && !isNullOrEmpty(x.name));
        const emailTemplateOptions = (templateOptions.length === 1 ? templateOptions : [{ key: '', name: t('Global:selectTemplate') }].concat(templateOptions)).concat({ key: 'x', name: t('BookingConfirmationEmails:dontSendEmail') });
        const templateSelections = bookings.map(b => {
            const template = emailTemplateOptions.find(t => t.key === b.bookingConfirmationClientEmailTemplateId || '');
            return { bookingId: b.id, booking: b, emailTemplateId: template ? template.key : emailTemplateOptions[0].key };
        });

        this.state = { bookings: bookings, templateOptions: emailTemplateOptions, templateSelections: templateSelections, saving: false, error: null }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    templateSelected = (bookingId: string, id: string) => {
        this.setState(prev => ({
            templateSelections: prev.templateSelections.map(x => {
                if (x.bookingId === bookingId) {
                    return { ...x, emailTemplateId: id }
                } else {
                    return x
                }
            })
        }));
    }

    sendEmails = async () => {
        const { templateSelections } = this.state;
        const { close } = this.props;

        var emailsToSend = templateSelections.filter(x => !isNullOrEmpty(x.emailTemplateId) && x.emailTemplateId !== 'x')

        let error = '';

        if (emailsToSend.length > 0) {
            this.setState({ saving: true, error: null });

            for (let i = 0; i < emailsToSend.length; i++) {
                if (isNullOrEmpty(error)) {
                    try {
                        const result = await api.postWithAuth(`api/v1/booking/${emailsToSend[i].bookingId}/bookingConfirmationEmail`, { clientEmailTemplateId: emailsToSend[i].emailTemplateId }, () => { }).first().toPromise();
                        if (result.status >= 400) {
                            error = 'BookingConfirmationEmails:sendEmailFailed'
                        }
                    } catch (e) {
                        error = 'BookingConfirmationEmails:sendEmailFailed'
                    }
                }
            }
        }

        if (!isNullOrEmpty(error)) {
            this.setState({ error: error });
        } else {
            close();
        }
    }

    render() {
        const { t } = this.context;
        const { event } = this.props;
        const { templateSelections, templateOptions, saving, error } = this.state;

        return <div>
            <h1>{t('BookingConfirmationEmails:heading')}</h1>

            <table className='table table-condensed'>
                <thead>
                    <tr key='header'>
                        <th>{t('BookingConfirmationEmails:customerHeading')}</th>
                        <th>{t('BookingConfirmationEmails:emailAddressHeading')}</th>
                        <th>{t('BookingConfirmationEmails:templateHeading')}</th>
                    </tr>
                </thead>
                <tbody>
                    {templateSelections.map(ts => {
                        const selectedEmailTemplate = ts ? ts.emailTemplateId : templateOptions[0].key;
                        return <BookingConfirmationEmailSelection key={ts.bookingId} booking={ts.booking} selectedEmailTemplateId={selectedEmailTemplate} emailTemplateOptions={templateOptions} templateSelected={val => this.templateSelected(ts.bookingId, val)} />
                    })}
                </tbody>
            </table>

            <div className='btn-toolbar'>
                <button className='btn btn-primary' disabled={saving} onClick={this.sendEmails} >{t('BookingConfirmationEmails:sendSelected')}</button>
            </div>

            {!isNullOrEmpty(error) ? <div className='alert alert-danger' style={({ marginTop: '20px' })}>{t(error)}</div> : null}
        </div>
    }
}