
import * as React from 'react';

import * as ct from '../../global/controls';

import { CustomFieldType } from '../../../store/pages/activityFormats/types';


interface BookingExtraFieldProps {
    type: CustomFieldType;
    value: string;
    name: string;
    values: string[];
    valueChanged: (fieldName: string, val: string) => void;
}

const BookingExtraField = (props: BookingExtraFieldProps) => {
    const { type, value, name, values, valueChanged } = props;

    switch (type) {
        case CustomFieldType.Number:
            return <input type='number' value={value} onChange={e => valueChanged(name, e.currentTarget.value)} />
        case CustomFieldType.List:
            return <ct.PlainSelect id={`${name}`} value={value} options={values.map(v => ({ key: v, name: v }))} callback={v => valueChanged(name, v)} />
        default:
            return <input type='text' value={value} onChange={e => valueChanged(name, e.currentTarget.value)} />
    }
}

export default BookingExtraField;