
import { Action } from 'redux';

import { TaskDefinitionActionTypes, TaskState} from './types';
import { TaskDefinitionActions } from './actions'
import { createReducer } from '../../reducer';


const unloadedState: TaskState = { isLoading: false, taskDefinitions: [], loadError: null, isSaving: false, saveComplete: false, saveError: null, validationErrors: [] };

export const reducer = (state: TaskState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: TaskState, incomingAction: Action): TaskState => {

    const action = incomingAction as TaskDefinitionActions;
    switch (action.type) {
        case TaskDefinitionActionTypes.RequestTaskDefinitions:
            return {
                ...state,
                isLoading: true,
                taskDefinitions: state.taskDefinitions,
                loadError: null
            };
        case TaskDefinitionActionTypes.ReceivedTaskDefinitions:
            const ordered = action.taskDefinitions ? action.taskDefinitions : [];
            ordered.sort((a, b) => a.name.localeCompare(b.name));

            return {
                ...state,
                isLoading: false,
                taskDefinitions: ordered,
                loadError: action.error
            };
        case TaskDefinitionActionTypes.EditTaskDefinition:
            return {
                ...state,
                isSaving: false,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case TaskDefinitionActionTypes.SaveTaskDefinition:
            return {
                ...state,
                isSaving: true,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case TaskDefinitionActionTypes.TaskDefinitionSaved:
            return {
                ...state,
                isSaving: false,
                saveComplete: true,
                saveError: null,
                validationErrors: []
            };
        case TaskDefinitionActionTypes.TaskDefinitionSaveFailed:
            return {
                ...state,
                isSaving: false,
                saveError: action.error,
                saveComplete: false,
                validationErrors: action.error && action.error.validationErrors ? action.error.validationErrors : []
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

