
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'
import { ApplicationState } from '../../../../store';
import * as PaymentMethodActions from '../../../../store/pages/paymentMethods/actions';
import * as ModalActions from '../../../../store/global/modal/actions';
import Loading from '../../../global/loading';
import { clickHandler } from '../../../../utils/util';
import { PaymentMethod, PaymentMethodType } from '../../../../store/pages/paymentMethods/types';
import PaymentMethodForm from './paymentMethodForm';

interface LocalProps {
    venueId: string;
    pageRoot: string;
}

interface LocalState {
    isLoading: boolean;
    paymentMethods: PaymentMethod[];
}

interface Actions {
    editPaymentMethod: () => void;
    loadPaymentMethods: () => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
}

type PaymentMethodListProps = LocalState & Actions & LocalProps;

interface PaymentMethodsListState {
    showArchived: boolean;
}

class PaymentMethodsList extends React.Component<PaymentMethodListProps, PaymentMethodsListState> {

    constructor(props: PaymentMethodListProps) {
        super(props);

        this.state = { showArchived: false }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private editPaymentMethod = (user: PaymentMethod) => {
        this.showOverlay(user, false);
    }

    private addPaymentMethod = () => {
        this.showOverlay(null, true);
    }

    private showOverlay = (paymentMethod: PaymentMethod | null, addingNew: boolean) => {
        this.props.editPaymentMethod();
        this.props.showModal(<PaymentMethodForm isNew={addingNew} venueId={this.props.venueId} paymentMethod={paymentMethod ? paymentMethod : null} pageRoot={this.props.pageRoot} />, 'PaymentMethodForm');
    }

    render() {
        const { showArchived } = this.state;
        const { t } = this.context;
        const body = this.props.isLoading ? <Loading /> : this.renderPaymentMethodsTable();

        return <section className='venue-item-list'>
            <header className='section-header'>
                <div className='section-actions'>
                    <label>
                        <input type='checkbox' checked={showArchived} onChange={e => this.setState({ showArchived: e.currentTarget.checked })} />
                        <span style={({ marginLeft: '10px' })}>{t('Global:showArchived')}</span>
                    </label>
                    <button className='btn btn-info' onClick={e => clickHandler(e, this.addPaymentMethod)}>{this.context.t('PaymentMethodsPage:addPaymentMethod')}</button>
                </div>
            </header>
            <div className='at-panel sub-section-panel-240px'>
                {body}
            </div>
        </section>;
    }

    private renderPaymentMethodsTable() {
        const { paymentMethods, venueId } = this.props;
        const { showArchived } = this.state;
        const { t } = this.context;

        const rates = paymentMethods.filter(pm => pm.venueId === venueId && (showArchived || !pm.archived)).map(pm =>
            <tr key={pm.id}>
                <td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.editPaymentMethod(pm))}>{pm.name}</button></td>
                <td>{pm.pointOfSaleText}</td>
                <td>{pm.publicPaymentName}</td>
                <td>{t(`PaymentMethodType:${PaymentMethodType[pm.type]}`)}</td>
                <td>{pm.allowCustomerWebPayment ? t('Global:yes') : ''}</td>
                <td>{pm.allowCustomerWebPayment ? pm.webPaymentButtonText : ''}</td>
                <td>{pm.archived ? <span className='label label-danger'>{t('Global:archived')}</span> : null}</td>
            </tr>
        );

        return <table className='table table-condensed'>
            <thead>
                <tr>
                    <th>{t('PaymentMethodsPage:nameHeading')}</th>
                    <th>{t('PaymentMethodsPage:posTextHeading')}</th>
                    <th>{t('PaymentMethodsPage:publicName')}</th>
                    <th>{t('PaymentMethodsPage:typeHeading')}</th>
                    <th>{t('PaymentMethodsPage:webEnabled')}</th>
                    <th>{t('PaymentMethodsPage:webButtonText')}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {rates}
            </tbody>
        </table>;
    }
}

const matStateToProps = (state: ApplicationState) => ({
    paymentMethods: state.paymentMethods.paymentMethods,
    isLoading: state.paymentMethods.isLoading,
})

const mapDispatchToProps = (dispatch: Dispatch) => (
    {
        showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
        closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
        loadPaymentMethods: bindActionCreators(PaymentMethodActions.actionCreators.loadPaymentMethods, dispatch),
        editPaymentMethod: bindActionCreators(PaymentMethodActions.actionCreators.editPaymentMethod, dispatch),
    }
);


// Wire up the React component to the Redux store
export default connect(
    matStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps        // Selects which action creators are merged into the component's props
)(PaymentMethodsList);

