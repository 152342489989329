
import * as React from 'react';
import * as PropTypes from 'prop-types'
import { ReportTypes, ReportDataElement } from '../../../store/pages/reportTypes/types';
import { isNullOrEmpty, linkHandler } from '../../../utils/util';
import { SortDirection } from '../../../store/pages/reportDefinitions/types';

interface ReportColumnSettingsProps {
    reportType: ReportTypes;
    index: number;
    dataElementKey: string;
    heading: string;
    total: boolean;
    sortOrder: number | null;
    sortDirection: SortDirection;
    summarize: boolean;
    dataElements: ReportDataElement[];
    valuesChanged: (index: number, dataElementKey: string, heading: string, total: boolean, sortOrder: number | null, sortDirection: SortDirection, summarize: boolean) => void;
    removeColumn: (index: number) => void;
    moveUp: (index: number) => void;
    moveDown: (index: number) => void;
}

export default class ReportColumnSettings extends React.Component<ReportColumnSettingsProps, {}> {

    static contextTypes = {
        t: PropTypes.func
    }

    onDataElementChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { reportType, index, dataElementKey: prevDataElementKey, heading: prevHeading, total: prevTotal, sortOrder: prevSortOrder, sortDirection: prevSortDirection, summarize: prevSummarize, dataElements } = this.props;
        const { t } = this.context;
        const selectedKey = e.currentTarget.value;

        const dataElement = dataElements.find(e => e.key === selectedKey);
        const prevDataElement = dataElements.find(e => e.key === prevDataElementKey);

        const newHeading = dataElement && (isNullOrEmpty(prevHeading) || (prevDataElement && prevHeading === prevDataElement.name)) ? t(`${ReportTypes[reportType]}:${dataElement.name}`) : prevHeading;

        this.props.valuesChanged(index, selectedKey, newHeading, prevTotal, prevSortOrder, prevSortDirection, prevSummarize);
    }

    onHeadingChanged = (e: React.ChangeEvent<HTMLInputElement>) => this.props.valuesChanged(this.props.index, this.props.dataElementKey, e.currentTarget.value, this.props.total, this.props.sortOrder, this.props.sortDirection, this.props.summarize);

    onTotalChanged = (e: React.ChangeEvent<HTMLInputElement>) => this.props.valuesChanged(this.props.index, this.props.dataElementKey, this.props.heading, e.currentTarget.checked, this.props.sortOrder, this.props.sortDirection, this.props.summarize);

    onSummarizeChanged = (e: React.ChangeEvent<HTMLInputElement>) => this.props.valuesChanged(this.props.index, this.props.dataElementKey, this.props.heading, this.props.total, this.props.sortOrder, this.props.sortDirection, e.currentTarget.checked);

    onSortOrderChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value;
        let coercedVal;
        if (isNullOrEmpty(val)) {
            coercedVal = null;
        } else {
            const parsedVal = parseInt(val, 10);
            coercedVal = isNaN(parsedVal) ? null : parsedVal;
        }

        this.props.valuesChanged(this.props.index, this.props.dataElementKey, this.props.heading, this.props.total, coercedVal, this.props.sortDirection, this.props.summarize);
    }

    onSortDirectionChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const dirVal = parseInt(e.currentTarget.value);
        const dir = isNaN(dirVal) ? SortDirection.Ascending : dirVal;
        this.props.valuesChanged(this.props.index, this.props.dataElementKey, this.props.heading, this.props.total, this.props.sortOrder, dir, this.props.summarize);
    }

    removeColumn = () => this.props.removeColumn(this.props.index);
    moveUp = () => this.props.moveUp(this.props.index);
    moveDown = () => this.props.moveDown(this.props.index);

    render() {
        const { t } = this.context;
        const { index, dataElementKey, heading, total, sortOrder, sortDirection, summarize, dataElements, reportType } = this.props;
        const dataElement = dataElements.find(e => e.key === dataElementKey);
        const canTotal = dataElement && dataElement.canTotal;
        const dataElementOptions = [<option key={-1} value={-1}>{t('ReportDefinitionForm:selectColumn')}</option>].concat(dataElements.map(e => <option key={e.key} value={e.key}>{t(`${ReportTypes[reportType]}:${e.name}`)}</option>));
        const sortDirectionOptions = Object.keys(SortDirection).filter(key => {
            return typeof SortDirection[key as any] === 'number'
        }).map(key => {
            const sd = SortDirection[key as any];
            return <option key={sd} value={sd}>{t(`SortDirection:${key}`)}</option>
        });
        const cellStyle = { verticalAlign: 'middle' };

        return (
            <tr>
                <td style={cellStyle}>{index}</td>
                <td><select className='form-control input-sm' onChange={this.onDataElementChanged} value={dataElementKey}>{dataElementOptions}</select></td>
                <td><input className='form-control input-sm' type='text' value={heading} onChange={this.onHeadingChanged} /></td>
                <td style={cellStyle} className='text-center'>{canTotal ? <input type='checkbox' checked={total} onChange={this.onTotalChanged} /> : null}</td>
                <td style={cellStyle} className='text-center'><input type='checkbox' checked={summarize} onChange={this.onSummarizeChanged} /></td>
                <td><input className='form-control input-sm' type='number' value={sortOrder ? sortOrder.toFixed(0) : ''} onChange={this.onSortOrderChanged} /></td>
                <td><select className='form-control input-sm' onChange={this.onSortDirectionChanged} value={sortDirection} disabled={!sortOrder}>{sortDirectionOptions}</select></td>
                <td style={cellStyle} className='text-nowrap'>
                    <a onClick={e => linkHandler(e, this.removeColumn)}><span className='glyphicon glyphicon-remove glyph-button' style={({ color: '#a94442' })}></span></a>
                    <a onClick={e => linkHandler(e, this.moveUp)}><span className='glyphicon glyphicon-arrow-up glyph-button'></span></a>
                    <a onClick={e => linkHandler(e, this.moveDown)}><span className='glyphicon glyphicon-arrow-down glyph-button'></span></a>
                </td>
            </tr>
        );
    }
}