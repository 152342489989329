import { ValidationError } from '../../global/types';
import * as api from '../../apiClient';

//**** TODO: Add email template support to UI
//
//    + Add system flag to clients
//    + restrict 'system' email to system account

export enum EmailType {
    General = 0,
    ForgottenPassword = 1,
    EventResults = 2,
    BookingConfirmation = 3,
    PaymentReminder = 4,
    CustomerLogin = 5,
    BookingRegistrationLink = 6,
    GeneralRegistrationLink = 7,
    MarketingCampaign = 8,
    GeneralBookingEmail = 9,
    Voucher = 10,
    PurchaseConfirmation = 11,
    MembershipPurchaseConfirmation = 12
}

export interface EmailTag {
    key: string;
    description: string;
}

export interface EmailTemplate {
    emailType: EmailType;
    tags: EmailTag[];
}

export enum EmailTemplateType {
    Quill = 1,
    RawHtml = 2,
    Grapesjs = 3
}

export interface ClientEmailTemplate extends EmailTemplate  {
    venueId: string | null;
    clientEmailTemplateId: string | null;
    emailTemplateId: string | null;
    name: string | null;
    ccEmailAddress: string | null;
    bccEmailAddress: string | null;
    subjectTemplate: string | null;
    textTemplate: string | null;
    htmlTemplate: string | null;
    jsonTemplate: string | null;
    sendFromEmailAddressOverride: string | null;
    sendFromEmailNameOverride: string | null;
    templateType: EmailTemplateType;
    showActivityStartTimes: boolean;
    createdBy: string | null;
    createDateTime: Date | null;
    archived: boolean;
    attachments: ClientEmailAttachment[];
}

export interface ClientEmailAttachment {
    id: string;
    fileName: string;
    createDateTime: Date;
    sizeInBytes: number;
}

export interface EmailTemplatesState {
    isActive: boolean;
    isLoading: boolean;
    emailTemplates: ClientEmailTemplate[];
    selectableEmailTypes: EmailTemplate[];
    loadError: api.ApiError | null;
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
}

export enum EmailTemplateActionTypes {
    RequestEmailTemplates = 'REQUEST_CLIENT_EMAIL_TEMPLATES',
    ReceivedEmailTemplates = 'RECEIVE_CLIENT_EMAIL_TEMPLATES',
    SaveEmailTemplate = 'SAVE_CLIENT_EMAIL_TEMPLATES',
    EmailTemplateSaved = 'CLIENT_EMAIL_TEMPLATES_SAVED',
    EmailTemplateSaveFailed = 'CLIENT_EMAIL_TEMPLATES_SAVE_FAILED',
    EditEmailTemplate = 'EDIT_CLIENT_EMAIL_TEMPLATES',
    EmailAttachmentSaved = 'EMAIL_ATTACHMENT_SAVED'
}

export interface LoadEmailTemplates {
    type: EmailTemplateActionTypes.RequestEmailTemplates;
}

export interface ReceiveEmailTemplates {
    type: EmailTemplateActionTypes.ReceivedEmailTemplates;
    emailTemplates: ClientEmailTemplate[];
    selectableEmailTypes: EmailTemplate[];
    error: api.ApiError | null;
}

export interface SaveEmailTemplate {
    type: EmailTemplateActionTypes.SaveEmailTemplate;
    isNew: boolean;
    venueId: string;
    clientEmailTemplateId: string | null;
    emailType: EmailType;
    emailTemplateId: string | null;
    name: string | null;
    ccEmailAddress: string;
    bccEmailAddress: string;
    subjectTemplate: string | null;
    textTemplate: string | null;
    htmlTemplate: string | null;
    jsonTemplate: string | null;
    sendFromEmailAddressOverride: string | null;
    sendFromEmailNameOverride: string | null;
    templateType: EmailTemplateType;
    createdBy: string | null;
    createDateTime: Date | null;
    showActivityStartTimes: boolean;
    archived: boolean;
    existingAttachmentIds: string[];
    newAttachments: File[];
}

export interface EmailTemplateSaved {
    type: EmailTemplateActionTypes.EmailTemplateSaved;
    clientEmailTemplateId: string;
}

export interface EmailTemplateSaveFailed {
    type: EmailTemplateActionTypes.EmailTemplateSaveFailed;
    error: api.ApiError;
}

export interface EditEmailTemplate {
    type: EmailTemplateActionTypes.EditEmailTemplate
}

export interface EmailAttachmentSaved {
    type: EmailTemplateActionTypes.EmailAttachmentSaved;
    fileName: string;
}