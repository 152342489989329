
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { Bill } from '../../../store/pages/pointOfSale/types';
import { ITranslationContext } from '../../../translations';
import { isNullOrEmpty, clickHandler } from '../../../utils/util';


export interface BillHeadingProps {
    bill: Bill;
    hideCustomer?: boolean; 
    selectCustomer?: () => void;
    editBill?: () => void;
}

const BillHeading = (props: BillHeadingProps, context: ITranslationContext) => {

    const { bill, hideCustomer, selectCustomer, editBill } = props;
    const { t } = context;

    const custInfo = hideCustomer ? null : <div>{isNullOrEmpty(bill.customerName) ? t('Global:noCustomer') : `${t('PointOfSale:customerHeading')}: ${bill.customerName}`}</div>
    const billInfo = <div>
        <div className='pull-left'>{isNullOrEmpty(bill.billNumber) ? t('PointOfSale:newBill') : `${t('PointOfSale:billNumberHeading')}: ${bill.billNumber}`}</div>
        <div className='pull-right label label-warning'>{bill.customerAvailableCredit > 0 ? `${t('PointOfSale:creditAbbr')} ${t('Global:currencySymbol')}${bill.customerAvailableCredit.toFixed(2)}` : null }</div>
        </div>

    const action = selectCustomer && isNullOrEmpty(bill.customerName)
        ? <button className='btn btn-info pull-right' onClick={e => clickHandler(e, selectCustomer)} style={({ flex: '0 0 auto' })}>{t('PointOfSale:addCustomer')}</button>
        : editBill ? <button className='btn btn-primary pull-right' style={{marginLeft: '5px' }} onClick={e => clickHandler(e, editBill)}>{t('Global:editBill')}</button> : null;

    return <div className='pos_bill_heading' style={({ flex: '0 0 auto', display: 'flex', flexDirection: 'row' })}>
        <div style={({ flex: '1 1 auto', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' })}>
            {custInfo}
            {billInfo}
        </div>
        {action}
    </div>;
}

BillHeading.contextTypes = {
    t: PropTypes.func
}

export default BillHeading;