import { DayOfWeek } from "../venues/types";

export enum AnalyticsReportType {
    Revenue = 1,
    Products = 2
}


export enum AnalyticsBucketResolution {
    Day = 1,
    Week = 2,
    Month = 3
}

export const defaultRevenueReportSettings: RevenueReportSettings = { type: 'revenue-report', graphGrossValues: true, tableGrossValues: false, tableNetValues: false, graphBookings: false, tableBookings: false, graphOtherBills: false, tableOtherBills: false, graphAvgGrossOrderValue: false, tableAvgGrossOrderValue: false, graphAvgNetOrderValue: false, tableAvgNetOrderValue: false };

export interface RevenueReportSettings {
    type: 'revenue-report';
    graphGrossValues: boolean;
    tableGrossValues: boolean;
    tableNetValues: boolean;
    graphBookings: boolean;
    tableBookings: boolean;
    graphOtherBills: boolean;
    tableOtherBills: boolean;
    graphAvgGrossOrderValue: boolean;
    tableAvgGrossOrderValue: boolean;
    graphAvgNetOrderValue: boolean;
    tableAvgNetOrderValue: boolean;
}

export const defaultAllProductsReportSettings: AllProductsReportSettings = {type: 'all-products-report', graphGrossValues: true, tableGrossValues: false, tableNetValues: false, graphDiscount: false, tableDiscount: false, graphQuantity: false, tableQuantity: false, graphBills: false, tableBills: false, graphEvents: false, tableEvents: false, graphSessions: false, tableSessions: false, graphAvgGrossOrderValue: false, tableAvgGrossOrderValue: false, graphAvgNetOrderValue: false, tableAvgNetOrderValue: false }

export interface AllProductsReportSettings {
    type: 'all-products-report',
    graphGrossValues: boolean;
    tableGrossValues: boolean;
    tableNetValues: boolean;
    graphDiscount: boolean;
    tableDiscount: boolean;
    graphQuantity: boolean;
    tableQuantity: boolean;
    graphBills: boolean;
    tableBills: boolean;
    graphAvgGrossOrderValue: boolean;
    tableAvgGrossOrderValue: boolean;
    graphAvgNetOrderValue: boolean;
    tableAvgNetOrderValue: boolean;
    graphEvents: boolean;
    tableEvents: boolean;
    graphSessions: boolean;
    tableSessions: boolean;
}

export type ReportSettings = RevenueReportSettings | AllProductsReportSettings;

export interface AnalyticsReport {
    id: number | null;
    name: string;
    type: AnalyticsReportType;
    period: AnalyticsPeriod;
    comparisonPeriod: ComparisonPeriod;
    deselectedVenues: string[];
    settings: ReportSettings;
}

export enum AnalyticsPeriodSelection {
    Today,
    Yesterday,
    WeekToDate,
    MonthToDate,
    QuarterToDate,
    YearToDate,
    Last12Months,
    LastWeek,
    LastMonth,
    LastQuarter,
    LastYear,
    Custom
}

export enum AnalyticsComparisonPeriodSelection {
    None,
    PreviousPeriod,
    ThisPeriodLastYear,
    Custom
}

export enum PublicWebStepCategory {
    None = 0,
    Booking = 1,
    Registration = 2,
    Payment = 4,
    All = 0b11111111111111111111111111111111
}

export enum PublicWebStep {
    None = 0,
    SiteHome = 1,
    Privacy = 2,
    Error = 3,
    ProductSelection = 110,
    CheckAvailability = 120,
    GetAvailability = 125,
    SelectAvailability = 130,
    AdditionalProducts = 140,
    SelectAdditionalProducts = 145,
    AdditionalFields = 150,
    SaveAdditionalFields = 155,
    BookingSummary = 160,
    ConfirmBookingSummary = 165,
    CustomerEmail = 170,
    CheckCustomerEmail = 175,
    ExistingCustomer = 180,
    SelectExistingCustomer = 185,
    SendLoginLink = 190,
    LoginWithCode = 195,
    NewCustomer = 200,
    SubmitNewCustomer = 205,
    BookingPayment = 220,
    InitiatePayment = 225,
    BookingPayWithPaymentsense = 240,
    RetryPaymentsensePayment = 241,
    CompleteBookingPaymentsensePayment = 245,
    BookingPayWithLibertyPay = 246,
    RetryLibertyPayPayment = 247,
    CompleteBookingLibertyPayPayment = 248,
    PayBookingWithStripe = 250,
    RetryBookingStripePayment = 251,
    HandleBookingStripePaymentResponse = 255,
    CheckForBookingStripePaymentResponse = 256,
    ViewBookingTerms = 260,
    BookingComplete = 280,
    BookingExpired = 299,
    RestartBooking = 300,
    Payment = 350,
    PaymentsensePayment = 360,
    PaymentsenseResponse = 362,
    LibertyPayPayment = 363,
    LibertyPayResponse = 364,
    StripePayment = 370,
    StripePaymentResponse = 372,
    StartRegistration = 500,
    RegistrationCheckEmail = 510,
    RegistrationExistingCustomer = 520,
    RegistrationExistingCustomerSelection = 525,
    RegistrationSendLoginLink = 530,
    RegistrationLoginWithCode = 540,
    RegistrationNewCustomer = 550,
    RegistrationCreateNewCustomer = 555,
    RegistrationCustomerDetails = 560,
    RegistrationSaveCustomerDetails = 565,
    RegistrationContactPreferences = 570,
    RegistrationUpdateContactPreferences = 575,
    RegistrationConfirmParticipants = 580,
    RegistrationUpdateParticipants = 585,
    RegistrationSelectChildren = 590,
    RegistrationRegisterChildren = 595,
    RegistrationBriefing = 600,
    RegistrationConfirmBriefing = 605,
    RegistrationTermsAndConditions = 610,
    RegistrationConfirmTermsAndConditions = 615,
    RegistrationComplete = 620
}


export class AnalyticsPeriod {

    static create(period: AnalyticsPeriodSelection, firstDayOfWeek: DayOfWeek) {
        const today = new Date();
        let from: Date = today;
        let to: Date = today;

        switch (period) {
            case AnalyticsPeriodSelection.Today:
                from = today;
                to = today;
                break;
            case AnalyticsPeriodSelection.Yesterday:
                from.setDate(today.getDate() - 1);
                to = from;
                break;
            case AnalyticsPeriodSelection.WeekToDate:
                from = setToFirstDayOfWeek(new Date(), firstDayOfWeek);
                to = today;
                break;
            case AnalyticsPeriodSelection.MonthToDate:
                from = new Date(today.getFullYear(), today.getMonth(), 1);
                to = today;
                break;
            case AnalyticsPeriodSelection.QuarterToDate:
                from = setToFirstDayOfQuarter(new Date());
                to = today;
                break;
            case AnalyticsPeriodSelection.YearToDate:
                from = new Date(today.getFullYear(), 0, 1);
                to = today;
                break;
            case AnalyticsPeriodSelection.Last12Months:
                from = new Date(today.getFullYear() - 1, today.getMonth(), 1);
                to = today;
                break;
            case AnalyticsPeriodSelection.LastWeek:
                const firstDayThisWeek = setToFirstDayOfWeek(new Date(), firstDayOfWeek);
                firstDayThisWeek.setDate(firstDayThisWeek.getDate() - 7);
                from = firstDayThisWeek;
                to = new Date(firstDayThisWeek.getTime());
                to.setDate(to.getDate() + 6)
                break;
            case AnalyticsPeriodSelection.LastMonth:
                const firstDayThisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                from = new Date(firstDayThisMonth.getFullYear(), firstDayThisMonth.getMonth(), firstDayThisMonth.getDate());
                from.setMonth(firstDayThisMonth.getMonth() - 1);
                to = firstDayThisMonth
                to.setDate(firstDayThisMonth.getDate() - 1);
                break;
            case AnalyticsPeriodSelection.LastQuarter:
                to = setToFirstDayOfQuarter(new Date());
                to.setDate(to.getDate() - 1);
                from = setToFirstDayOfQuarter(new Date(to.getTime()));
                break;
            case AnalyticsPeriodSelection.LastYear:
                from = new Date(today.getFullYear() - 1, 0, 1);
                to = new Date(today.getFullYear() - 1, 12, 31);
                break;
            case AnalyticsPeriodSelection.Custom:
                throw new Error('Cannot call create for Custom AnalyticsPeriodSelection.  Call custom instead');
        }

        return new this(period, from, to);
    }

    static custom(from: Date, to: Date) {
        return new this(AnalyticsPeriodSelection.Custom, from, to)
    }

    constructor(public period: AnalyticsPeriodSelection, public from: Date, public to: Date) {
    }
}

export class ComparisonPeriod {
    static create(periodToCompareTo: AnalyticsPeriod, period: AnalyticsComparisonPeriodSelection) {
        let from = new Date(periodToCompareTo.from.getTime());
        let to = new Date(periodToCompareTo.to.getTime());

        switch (period) {
            case AnalyticsComparisonPeriodSelection.PreviousPeriod:
                switch (periodToCompareTo.period) {
                    case AnalyticsPeriodSelection.Today:
                    case AnalyticsPeriodSelection.Yesterday:
                        from.setDate(from.getDate() - 1);
                        to.setDate(to.getDate() - 1);
                        break;
                    case AnalyticsPeriodSelection.WeekToDate:
                        from.setDate(from.getDate() - 7);
                        to.setDate(to.getDate() - 7);
                        break;
                    case AnalyticsPeriodSelection.MonthToDate:
                        from.setMonth(from.getMonth() - 1);
                        to.setMonth(to.getMonth() - 1);
                        break;
                    case AnalyticsPeriodSelection.QuarterToDate:
                        const originalPeriodDays = to.daysDifference(from);
                        from.setDate(from.getDate() - 1)
                        from = setToFirstDayOfQuarter(from);
                        to = new Date(from.getTime());
                        to.setDate(to.getDate() + originalPeriodDays);
                        break;
                    case AnalyticsPeriodSelection.YearToDate:
                        from = new Date(from.getFullYear() - 1, 0, 1);
                        to.setFullYear(to.getFullYear() - 1);
                        break;
                    case AnalyticsPeriodSelection.Last12Months:
                        from.setFullYear(from.getFullYear() - 1);
                        to.setFullYear(to.getFullYear() - 1);
                        break;
                    case AnalyticsPeriodSelection.LastWeek:
                        from.setDate(from.getDate() - 7);
                        to.setDate(to.getDate() - 7);
                        break;
                    case AnalyticsPeriodSelection.LastMonth:
                        from.setMonth(from.getMonth() - 1);
                        to.setMonth(to.getMonth() - 1);
                        break;
                    case AnalyticsPeriodSelection.LastQuarter:
                        to = setToFirstDayOfQuarter(new Date());
                        to.setDate(to.getDate() - 1);
                        from = setToFirstDayOfQuarter(new Date(to.getTime()));
                        break;
                    case AnalyticsPeriodSelection.LastYear:
                        to = new Date(from.getTime());
                        to.setDate(to.getDate() - 1)
                        from.setFullYear(from.getFullYear() - 1);
                        break;
                    case AnalyticsPeriodSelection.Custom:
                        const originalPeriodDays2 = to.daysDifference(from);
                        to = new Date(from.getTime());
                        to.setDate(to.getDate() - 1);
                        from = new Date(to.getTime());
                        from.setDate(from.getDate() - originalPeriodDays2);
                }
                break;
            case AnalyticsComparisonPeriodSelection.ThisPeriodLastYear:
                from.setFullYear(from.getFullYear() - 1);
                to.setFullYear(to.getFullYear() - 1);
                break;
            case AnalyticsComparisonPeriodSelection.Custom:
                // What to do here?
                from.setFullYear(from.getFullYear() - 1);
                to.setFullYear(to.getFullYear() - 1);
                break;
        }
        return new this(period, from, to);
    }

    static custom(from: Date, to: Date) {
        return new this(AnalyticsComparisonPeriodSelection.Custom, from, to)
    }

    constructor(public period: AnalyticsComparisonPeriodSelection, public from: Date, public to: Date) {
    }
}


const setToFirstDayOfWeek = (date: Date, firstDayOfWeek: DayOfWeek): Date => {
    const dayOfWeekDiff = date.getDay() - firstDayOfWeek;
    const daysToSubtract = (dayOfWeekDiff >= 0 ? dayOfWeekDiff : dayOfWeekDiff + 7);
    date.setDate(date.getDate() - daysToSubtract);
    return date;
}

const setToFirstDayOfQuarter = (date: Date): Date => {
    const monthOffset = date.getMonth() % 3;
    date.setMonth(date.getMonth() - monthOffset);
    date.setDate(1);
    return date;
}