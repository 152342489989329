
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as tt from './types'; 
import { IStore } from '../..';
import { SpecialTagType } from './types';


export type TagActions = tt.LoadTags | tt.ReceiveTags | tt.SaveTag | tt.TagSaved | tt.TagSaveFailed | tt.TagChanged;

export const actionCreators = {
    loadTags: () => ({ type: tt.TagActionTypes.RequestTags }),
    loadTagsComplete: (tags: tt.Tag[], err: api.ApiError | null) => ({ type: tt.TagActionTypes.ReceivedTags, tags: tags, error: err }),
    saveTag: (isNew: boolean, tagId: string | null, name: string, colour: string, archived: boolean, specialTagType: SpecialTagType) => ({ type: tt.TagActionTypes.SaveTag, isNew: isNew, tagId: tagId, name: name, colour: colour, archived: archived, specialTagType: specialTagType }),
    tagSaved: (tag: tt.Tag) => ({ type: tt.TagActionTypes.TagSaved, tag: tag }),
    tagSaveFailed: (error: api.ApiError) => ({ type: tt.TagActionTypes.TagSaveFailed, error: error }),
    tagChanged: (tagId: string) => ({ type: tt.TagActionTypes.TagChanged, tagId: tagId }),
}

interface IGetTagsResponse {
    tags: tt.TagInfo[];
}

interface ISaveTagResponse {
    tagId: string;
}

export const handleClientChange = (store: IStore) => [actionCreators.loadTags]

//https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-after-catching-and-processing-an-error-with-emitting-somethi

const loadTags = () => Observable.defer(() => api.getJson<IGetTagsResponse>('api/v1/tag/'))
    .map(response => actionCreators.loadTagsComplete(response.tags, null));

export const loadTagsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        tt.TagActionTypes.RequestTags,
        action => loadTags(),
        err => actionCreators.loadTagsComplete([], err));

export const reloadTagsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        tt.TagActionTypes.TagSaved,
        action => loadTags(),
        err => actionCreators.loadTagsComplete([], err));

export const tagChangedEpic = (action$: ActionsObservable<any>, store: any) => 
    epic.create(action$,
        tt.TagActionTypes.TagChanged,
        action => loadTags(),
        err => actionCreators.loadTagsComplete([], err));

export const saveTagEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        tt.TagActionTypes.SaveTag,
        action => {
            const stra = action as tt.SaveTag;
            var body = {
                name: stra.name,
                colour: stra.colour,
                archived: stra.archived,
                specialTagType: stra.specialTagType
            };

            return (stra.isNew ? api.post('api/v1/tag/', body) : api.put(`api/v1/tag/${stra.tagId}`, body))
                .map(response => {

                    let tagId = stra.tagId;
                    if (stra.isNew) {
                        const strr = response.response as ISaveTagResponse;
                        if (strr) {
                            tagId = strr.tagId;
                        }
                    }

                    return actionCreators.tagSaved({ id: tagId || '', name: stra.name, colour: stra.colour, archived: stra.archived, specialTagType: stra.specialTagType });
                });
        },
        (err: api.ApiError) => actionCreators.tagSaveFailed(err));
