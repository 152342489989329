
import { Action } from 'redux';

import { PaymentGatewayActionTypes, PaymentGatewayState } from './types';
import { PaymentGatewayActions } from './actions'
import { createReducer } from '../../reducer';

const unloadedState: PaymentGatewayState = { isLoading: false, paymentGateways: [], loadError: null, isSaving: false, saveComplete: false, saveError: null, validationErrors: [] };

export const reducer = (state: PaymentGatewayState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: PaymentGatewayState, incomingAction: Action): PaymentGatewayState => {

    const action = incomingAction as PaymentGatewayActions;
    switch (action.type) {
        case PaymentGatewayActionTypes.RequestPaymentGateways:
            return {
                ...state,
                isLoading: true,
                paymentGateways: state.paymentGateways,
                loadError: null
            };
        case PaymentGatewayActionTypes.ReceivedPaymentGateways:
            const ordered = action.paymentGateways ? action.paymentGateways : [];
            ordered.sort((a, b) => a.name.localeCompare(b.name));

            return {
                ...state,
                isLoading: false,
                paymentGateways: ordered,
                loadError: action.error
            };
        case PaymentGatewayActionTypes.SavePaymentGateway:
            return {
                ...state,
                isSaving: true,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case PaymentGatewayActionTypes.PaymentGatewaySaved:
            return {
                ...state,
                isSaving: false,
                saveComplete: true,
                saveError: null,
                validationErrors: []
            };
        case PaymentGatewayActionTypes.PaymentGatewaySaveFailed:
            return {
                ...state,
                isSaving: false,
                saveError: action.error,
                saveComplete: false,
                validationErrors: action.error && action.error.validationErrors ? action.error.validationErrors : []
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

