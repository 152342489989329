
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { Bill, BillItem, BillPayment, BillFee, BillRefund, BillDiscount, BillItemVoucher } from '../../../store/pages/pointOfSale/types';
import BillItemsPanel from './billItemsPanel';
import BillTotals from './billTotals';
import BillHeading from './billHeading';
import { CustomerCategory } from '../../../store/pages/customerCategories/types';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';

interface BillPanelProps {
    bill: Bill;
    bookingCancelled: boolean;
    customerCategories: CustomerCategory[];
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
    editBillItem?: (item: BillItem) => void;
    editPayment?: (p: BillPayment) => void;
    editBillFee?: (fee: BillFee) => void;
    makePayment?: (p: BillPayment) => void;
    editRefund: (refund: BillRefund) => void;
    editDiscount?: (discount: BillDiscount | null) => void;
    selectCustomer?: () => void;
    viewVoucher?: (voucher: BillItemVoucher) => void;
}

interface BillPanelState {
    tillConnectionMessage: string
}

export default class BillPanel extends React.Component<BillPanelProps, BillPanelState> {

    constructor(props: BillPanelProps) {
        super(props);

        this.state = { tillConnectionMessage: 'checking connection....' }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    render() {
        const { bill, bookingCancelled, selectCustomer, customerCategories, timeFormat, dateFormat } = this.props;
        return (
            <div style={({ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', flex: '1 1 100%', overflow: 'hidden' })}>
                <BillHeading bill={bill} selectCustomer={selectCustomer} />
                <BillItemsPanel
                    bill={bill}
                    customerCategories={customerCategories}
                    bookingCancelled={bookingCancelled}
                    timeFormat={timeFormat}
                    dateFormat={dateFormat}
                    editBillItem={this.props.editBillItem}
                    editBillPayment={this.props.editPayment}
                    editBillFee={this.props.editBillFee}
                    editRefund={this.props.editRefund}
                    makePayment={this.props.makePayment}
                    editDiscount={this.props.editDiscount}
                    eventReservationIds={null}
                    viewVoucher={this.props.viewVoucher}
                />
                <div>
                    <BillTotals bill={bill} />
                </div>
            </div>
        );
    }
}