
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as auth from '../../../utils/auth';
import { ITranslationContext } from '../../../translations';
import { Link } from 'react-router-dom';

interface AdminToolsPageProps {}

const AdminToolsPage = (props: AdminToolsPageProps, context: ITranslationContext) => {
    const { t } = context;

    let body: JSX.Element | null = null;

    const sysAdmin = auth.isSystemAdmin();
    if (!sysAdmin) {
        body = <div className='alert alert-danger'>{t('ApiError:unauthorized')}</div>
    } else {
        body = <ul>
            <li key='client_images'><Link className='btn btn-link btn-no-padding' to={`/admintools/migrateClientImages`}>Migrate client images</Link></li>
            </ul>
    }

    return <div>
        <h1>Admin tools</h1>
        {body}
    </div>
}

AdminToolsPage.contextTypes = {
    t: PropTypes.func
}

export default AdminToolsPage;