
import { Action } from 'redux';

import { FeeActionTypes, FeeState } from './types';
import { FeeActions } from './actions'
import { createReducer } from '../../reducer';


const unloadedState: FeeState = { isLoading: false, fees: [], loadError: null, isSaving: false, saveComplete: false, saveError: null, validationErrors: [] };

export const reducer = (state: FeeState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: FeeState, incomingAction: Action): FeeState => {

    const action = incomingAction as FeeActions;
    switch (action.type) {
        case FeeActionTypes.RequestFees:
            return {
                ...state,
                isLoading: true,
                fees: state.fees,
                loadError: null
            };
        case FeeActionTypes.ReceivedFees:
            const ordered = action.fees ? action.fees : [];
            ordered.sort((a, b) => a.name.localeCompare(b.name));

            return {
                ...state,
                isLoading: false,
                fees: ordered,
                loadError: action.error
            };
        case FeeActionTypes.EditFee:
            return {
                ...state,
                isSaving: false,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case FeeActionTypes.SaveFee:
            return {
                ...state,
                isSaving: true,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case FeeActionTypes.FeeSaved:
            return {
                ...state,
                isSaving: false,
                saveComplete: true,
                saveError: null,
                validationErrors: []
            };
        case FeeActionTypes.FeeSaveFailed:
            return {
                ...state,
                isSaving: false,
                saveError: action.error,
                saveComplete: false,
                validationErrors: action.error && action.error.validationErrors ? action.error.validationErrors : []
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

