
import { Action } from 'redux';
import { LocationChangeAction } from 'react-router-redux';

import { CustomerCategoryActionTypes, CustomerCategoriesState } from './types';
import { CustomerCategoryActions } from './actions'
import { createReducer } from '../../reducer';


const unloadedState: CustomerCategoriesState = { isActive: false, isLoading: false, customerCategories: [], loadError: null, isSaving: false, saveComplete: false, saveError: null, validationErrors: [] };

export const reducer = (state: CustomerCategoriesState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: CustomerCategoriesState, incomingAction: Action): CustomerCategoriesState => {

    if (incomingAction.type === '@@router/LOCATION_CHANGE') {
        const locationChangeAction = incomingAction as LocationChangeAction;
        if (locationChangeAction) {
            return { ...state, isActive: locationChangeAction.payload.pathname === '/customerCategories' };
        }
    }

    const action = incomingAction as CustomerCategoryActions;
    switch (action.type) {
        case CustomerCategoryActionTypes.RequestCustomerCategories:
            return {
                ...state,
                isLoading: true,
                customerCategories: state.customerCategories,
                loadError: null
            };
        case CustomerCategoryActionTypes.ReceivedCustomerCategories:
            return {
                ...state,
                isLoading: false,
                customerCategories: action.customerCategories,
                loadError: action.error
            };
        case CustomerCategoryActionTypes.EditCustomerCategory:
            return {
                ...state,
                isSaving: false,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case CustomerCategoryActionTypes.SaveCustomerCategory:
            return {
                ...state,
                isSaving: true,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case CustomerCategoryActionTypes.CustomerCategorySaved:
            return {
                ...state,
                isSaving: false,
                saveComplete: true,
                saveError: null,
                validationErrors: []
            };
        case CustomerCategoryActionTypes.CustomerCategorySaveFailed:
            return {
                ...state,
                isSaving: false,
                saveError: action.error,
                saveComplete: false,
                validationErrors: action.error && action.error.validationErrors ? action.error.validationErrors : []
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

