
import { Action } from 'redux';

import { AffiliateActionTypes, AffiliateState } from './types';
import { AffiliateActions } from './actions'
import { createReducer } from '../../reducer';


const unloadedState: AffiliateState = { isLoading: false, affiliates: [], loadError: null, isSaving: false, saveComplete: false, saveError: null, validationErrors: [] };

export const reducer = (state: AffiliateState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: AffiliateState, incomingAction: Action): AffiliateState => {

    const action = incomingAction as AffiliateActions;
    switch (action.type) {
        case AffiliateActionTypes.RequestAffiliates:
            return {
                ...state,
                isLoading: true,
                affiliates: state.affiliates,
                loadError: null
            };
        case AffiliateActionTypes.ReceivedAffiliates:
            const ordered = action.affiliates ? action.affiliates : [];
            ordered.sort((a, b) => a.name.localeCompare(b.name));

            return {
                ...state,
                isLoading: false,
                affiliates: ordered,
                loadError: action.error
            };
        case AffiliateActionTypes.EditAffiliate:
            return {
                ...state,
                isSaving: false,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case AffiliateActionTypes.SaveAffiliate:
            return {
                ...state,
                isSaving: true,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case AffiliateActionTypes.AffiliateSaved:
            return {
                ...state,
                isSaving: false,
                saveComplete: true,
                saveError: null,
                validationErrors: []
            };
        case AffiliateActionTypes.AffiliateSaveFailed:
            return {
                ...state,
                isSaving: false,
                saveError: action.error,
                saveComplete: false,
                validationErrors: action.error && action.error.validationErrors ? action.error.validationErrors : []
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

