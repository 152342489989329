
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../../global/controls';

import { ProductQuantityUnit } from '../../../../store/pages/resources/types';
import { Product } from '../../../../store/pages/products/types';
import { ITranslationContext } from '../../../../translations';
import { AdditionalProduct } from './activityFormatForm';
import { clickHandler } from '../../../../utils/util';

interface ActivityFormatAdditionalProductsProps {
    venueId: string;
    products: Product[];
    additionalProducts: AdditionalProduct[]
    additionalProductChanged: (key: string, val: string) => void;
    onProductMinQuantityChanged: (key: string, val: number) => void;
    onProductMaxQuantityChanged: (key: string, val: number) => void;
    onProductDefaultQuantityChanged: (key: string, val: number) => void;
    onProductQuantityUnitChanged: (key: string, val: string) => void;
    onProductSequenceChanged: (key: string, val: number) => void;
    removeAdditionalProduct: (key: string) => void;
    addAdditionalProduct: () => void;
}

const ActivityFormatAdditionalProducts = (props: ActivityFormatAdditionalProductsProps, context: ITranslationContext) => {
    const { t } = context;
    const { products, venueId, additionalProducts, additionalProductChanged, onProductMinQuantityChanged, onProductMaxQuantityChanged,
        onProductDefaultQuantityChanged, onProductQuantityUnitChanged, onProductSequenceChanged, removeAdditionalProduct, addAdditionalProduct } = props;

    if (additionalProducts.length === 0) {
        return <div><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, addAdditionalProduct)}>{t('ResourceForm:addProducts')}</button></div>
    }

    const activeVeneProducts = products.filter(p => p.venueId === venueId && !p.archived);

    const controlStyle = ({ minHeight: '10px' });
    const tableTextboxStyle = ({ ...controlStyle, maxWidth: '95px', minWidth: '50px' });
    const deleteStyle = ({ marginTop: '8px', marginLeft: '10px', color: '#a94442' });
    const productOptions = [{ key: '', name: t('ResourceForm:selectProduct') }].concat(activeVeneProducts.map(p => ({ key: p.id, name: p.name })));
    const unitOptions = [{ key: ProductQuantityUnit.PerBooking.toString(), name: t('ProductQuantityUnit:PerBooking') }, { key: ProductQuantityUnit.PerParticipant.toString(), name: t('ProductQuantityUnit:PerParticipant') }];
    
    return (
        <div>
            <table className='table table-super-condensed table-borderless' style={({ width: 'auto' })}>
                <thead>
                    <tr>
                        <th>{t('ResourceForm:additionalProductsProduct')}</th>
                        <th>{t('ResourceForm:additionalProductsMin')}</th>
                        <th>{t('ResourceForm:additionalProductsMax')}</th>
                        <th>{t('ResourceForm:additionalProductsDefault')}</th>
                        <th>{t('ResourceForm:additionalProductsUnit')}</th>
                        <th>{t('ResourceForm:sequence')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {additionalProducts.map(p => <tr key={p.id}>
                        <td style={({ verticalAlign: 'top' })}><ct.Select id={`${p.key}_product`} labelKey='' value={p.productId} callback={val => additionalProductChanged(p.key, val)} options={productOptions} style={controlStyle} /></td>
                        <td style={({ verticalAlign: 'top' })}><ct.NumberBox id={`${p.key}_minQuantity`} labelKey='' placeholderKey='' value={p.minQuantity} callback={val => onProductMinQuantityChanged(p.key, val || 0)} style={tableTextboxStyle} min='0' /></td>
                        <td style={({ verticalAlign: 'top' })}><ct.NumberBox id={`${p.key}_maxQuantity`} labelKey='' placeholderKey='' value={p.maxQuantity} callback={val => onProductMaxQuantityChanged(p.key, val || 1)} style={tableTextboxStyle} min='1' /></td>
                        <td style={({ verticalAlign: 'top' })}><ct.NumberBox id={`${p.key}_defaultQuantity`} labelKey='' placeholderKey='' value={p.defaultQuantity} callback={val => onProductDefaultQuantityChanged(p.key, val || 0)} style={tableTextboxStyle} min='0' /></td>
                        <td style={({ verticalAlign: 'top' })}><ct.Select id={`${p.key}_unit`} labelKey='' value={({ ...p.quantityUnit, value: p.quantityUnit.value.toString() })} callback={val => onProductQuantityUnitChanged(p.key, val)} options={unitOptions} style={controlStyle} /></td>
                        <td style={({ verticalAlign: 'top' })}><ct.NumberBox id={`${p.key}sequence`} labelKey='' placeholderKey='' value={p.sequence} callback={val => onProductSequenceChanged(p.key, val || 0)} style={tableTextboxStyle} min='1' /></td>
                        <td style={({ verticalAlign: 'top' })}><span className='glyphicon glyphicon-trash red' onClick={e => clickHandler(e, () => removeAdditionalProduct(p.key))} style={deleteStyle}></span></td>
                    </tr>)}
                </tbody>
            </table>
            <div><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, addAdditionalProduct)}>{t('ResourceForm:addProducts')}</button></div>
        </div>
    );
}

ActivityFormatAdditionalProducts.contextTypes = {
    t: PropTypes.func
}

export default ActivityFormatAdditionalProducts;