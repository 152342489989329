
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';
import * as v from '../../global/validation';

import { clickHandler, isNullOrEmpty } from '../../../utils/util';
import { MarketingPreference } from '../../../store/pages/customer/types';
import { Venue } from '../../../store/pages/venues/types';
import { DateRange } from '../../global/controls';
import { Tag } from '../../../store/pages/tags/types';
import { ActivityFormat } from '../../../store/pages/activityFormats/types';
import { stringComparer } from '../../../utils/comparers';

interface CustomersFilterProps {
    show: boolean;
    nameFilter: string;
    venues: Venue[];
    tags: Tag[];
    activityFormats: ActivityFormat[];
    marketingPreference: MarketingPreference | null;
    venueSelection: string | null;
    lastVisit: DateRange;
    customerCreated: DateRange;
    selectedTagId: string | null;
    minAge: number | null;
    maxAge: number | null;
    minimumVisits: number | null;
    bookedActivityFormatIds: string[];
    participantActivityFormatIds: string[];
    applyFilter: (name: string, marketingPreference: null | MarketingPreference, venueSelection: string | null, lastVisit: DateRange, customerCreated: DateRange, minVisits: number | null, tagId: string | null, minAge: number | null, maxAge: number | null, bookedActivityFormats: string[], participantActivityFormats: string[]) => void;
}

interface CustomersFilterState {
    name: ct.FormValue<string>;
    venueSelection: ct.FormValue<string>;
    marketingPreference: ct.FormValue<MarketingPreference | null>;
    lastVisit: ct.FormValue<DateRange>;
    customerCreated: ct.FormValue<DateRange>;
    selectedTagId: ct.FormValue<string>;
    minimumVisits: ct.FormValue<number | null>;
    ageRange: ct.FormValue<ct.NumberRange>;
    selectedBookedActivityFormats: string[];
    selectedParticipantActivityFormats: string[];
}

export class CustomersFilter extends React.Component<CustomersFilterProps, CustomersFilterState> {

    searchTimeout: NodeJS.Timeout | null;

    constructor(props: CustomersFilterProps) {
        super(props);

        this.searchTimeout = null;

        this.state = {
            name: this.validateName(props.nameFilter),
            marketingPreference: this.validateMarketingPreference(props.marketingPreference),
            venueSelection: ct.asFormValue('venueSelection', props.venueSelection || ''),
            lastVisit: ct.asFormValue('lastVisit', props.lastVisit),
            customerCreated: ct.asFormValue('customerCreated', props.customerCreated),
            minimumVisits: this.validateMinimumVisits(props.minimumVisits),
            selectedTagId: this.validateSelectedTagId(props.selectedTagId || ''),
            ageRange: this.validateAge({ from: props.minAge, to: props.maxAge }),
            selectedBookedActivityFormats: props.bookedActivityFormatIds,
            selectedParticipantActivityFormats: props.participantActivityFormatIds
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidUpdate(prevProps: CustomersFilterProps) {
        const { nameFilter, marketingPreference, selectedTagId } = this.props;
        const { nameFilter: oldNameFilter, marketingPreference: oldMarketingPreference, selectedTagId: oldTagId } = prevProps;

        if (nameFilter !== oldNameFilter || marketingPreference !== oldMarketingPreference || selectedTagId !== oldTagId) {
            this.setState({ name: this.validateName(nameFilter), marketingPreference: this.validateMarketingPreference(marketingPreference), selectedTagId: this.validateSelectedTagId(selectedTagId || '') });
        }
    }

    onSearchTermChanged = (immediate: boolean) => {
        //if (this.searchTimeout) clearTimeout(this.searchTimeout);

        //this.searchTimeout = setTimeout(() => this.applyFilter(), immediate ? 1 : 1000);
    }

    applyFilter = () => {
        const { applyFilter} = this.props;
        const { name, marketingPreference, venueSelection, lastVisit, customerCreated, minimumVisits, selectedTagId, ageRange, selectedBookedActivityFormats, selectedParticipantActivityFormats } = this.state;

        applyFilter(name.value, marketingPreference.value, venueSelection.value, lastVisit.value, customerCreated.value, minimumVisits.value, isNullOrEmpty(selectedTagId.value) ? null : selectedTagId.value, ageRange.value.from, ageRange.value.to, selectedBookedActivityFormats, selectedParticipantActivityFormats);
    }

    clearFilter = () => {
        this.setState({
            name: this.validateName(''),
            marketingPreference: this.validateMarketingPreference(null),
            venueSelection: ct.asFormValue('venueSelection', ''),
            lastVisit: ct.asFormValue('lastVisit', { from: null, to: null }),
            customerCreated: ct.asFormValue('customerCreated', { from: null, to: null }),
            minimumVisits: this.validateMinimumVisits(null),
            selectedTagId: this.validateSelectedTagId(''),
            ageRange: this.validateAge({ from: null, to: null}),
            selectedBookedActivityFormats: [],
            selectedParticipantActivityFormats: []
        });
        this.props.applyFilter('', null, null, { from: null, to: null }, { from: null, to: null }, null, null, null, null, [], []);
    }

    marketingPreferenceChanged = (val: string) => {
        const et = parseInt(val);
        const preference = isNaN(et) || et < 0 ? null : et;
        this.setState({ marketingPreference: this.validateMarketingPreference(preference) }, () => this.onSearchTermChanged(true));
    }

    nameChanged = (name: string) => this.setState({ name: this.validateName(name) }, () => this.onSearchTermChanged(false));

    venueSelectionChanged = (val: string) => this.setState({ venueSelection: ct.asFormValue('venueSelection', val) }, () => this.onSearchTermChanged(true));

    lastVisitChanged = (val: DateRange) => this.setState({ lastVisit: ct.asFormValue('lastVisit', val) }, () => this.onSearchTermChanged(true));

    minimumVisitsChanged = (val: number | null) => this.setState({ minimumVisits: this.validateMinimumVisits(val) }, () => this.onSearchTermChanged(true));

    customerCreatedChanged = (val: DateRange) => this.setState({ customerCreated: ct.asFormValue('customerCreated', val) }, () => this.onSearchTermChanged(true));

    selectedTagChanged = (val: string) => this.setState({ selectedTagId: this.validateSelectedTagId(val) }, () => this.onSearchTermChanged(true));

    ageRangeChanged = (val: ct.NumberRange) => this.setState({ ageRange: this.validateAge(val) }, () => this.onSearchTermChanged(true));

    validateName = (val: string) => v.validate(val, 'name', [], []);
    validateMarketingPreference = (val: MarketingPreference | null) => v.validate(val, 'marketingPreference', [], []);
    validateSelectedTagId = (val: string) => v.validate(val, 'selectedTagId', [], []);
    validateMinimumVisits = (val: number | null) => v.validate(val, 'minimumVisits', [], []);
    validateAge = (val: ct.NumberRange) => v.validate(val, 'minAge', [this.ageValidator], []);

    ageValidator = (val: ct.NumberRange) => {
        if (val.from && val.to && val.from > val.to) return 'customerDetails:toAgeLessThanFromAge';
        return undefined;
    }

    selectedBookedActivityChanged = (activityFormatId: string, selected: boolean) => {
        this.setState(s => ({ selectedBookedActivityFormats: selected ? s.selectedBookedActivityFormats.concat(activityFormatId) : s.selectedBookedActivityFormats.filter(f => f !== activityFormatId) }))
    }

    selectedParticipantsActivityChanged = (activityFormatId: string, selected: boolean) => {
        this.setState(s => ({ selectedParticipantActivityFormats: selected ? s.selectedParticipantActivityFormats.concat(activityFormatId) : s.selectedParticipantActivityFormats.filter(f => f !== activityFormatId) }))
    }

    render() {
        if (!this.props.show) {
            return null;
        }

        const { t } = this.context;
        const { venues, tags, activityFormats } = this.props;
        const { name, marketingPreference, venueSelection, lastVisit, customerCreated, minimumVisits, selectedTagId, ageRange, selectedBookedActivityFormats, selectedParticipantActivityFormats } = this.state;

        const marketingPreferenceOptions = [{ key: '-1', name: t('Global:any') }].concat([MarketingPreference.Email, MarketingPreference.Phone].map(k => ({ key: k.toString(), name: t(`MarketingPreference:${MarketingPreference[k]}`) })));
        const venueOptions = [{ key: '', name: t('customerDetails:allVenues') }].concat(venues.filter(v => !v.archived).map(v => ({ key: v.id, name: v.name })))
        const tagOptions = [{ key: '', name: t('customerDetails:noTagSelected') }].concat(tags.map(t => ({ key: t.id, name: t.name, data: t })))

        const renderTag = (o: ct.SelectOption) => {
            if (o.data && o.data.colour) {
                return <span key={o.key} className="label" style={{ backgroundColor: o.data.colour, margin: '0px 2px' }}> {o.name}</span>
            } else {
                return <span key={o.key}>{o.name}</span>
            }
        }

        var bookedActivityOptions = activityFormats.filter(f => !f.archived).sort((f1, f2) => stringComparer(f1.name, f2.name)).map(f => ({ key: f.id, name: f.name, selected: selectedBookedActivityFormats.includes(f.id) }));
        var participantActivityOptions = activityFormats.filter(f => !f.archived).sort((f1, f2) => stringComparer(f1.name, f2.name)).map(f => ({ key: f.id, name: f.name, selected: selectedParticipantActivityFormats.includes(f.id) }));

        return <div className="panel panel-primary">
            <div className='panel-body'>
                <div className='row'>
                    <div className='col-xs-12 col-md-6 col-lg-6'>
                        <ct.TextBox id='name' labelKey='customerDetails:nameSearchLabel' placeholderKey='customerDetails:nameSearchLabel' value={name} callback={this.nameChanged} />
                    </div>
                    <div className='col-xs-6 col-md-3 col-lg-3'>
                        <ct.Select id='marketingPreference' labelKey='customerDetails:marketingPreference' value={({ ...marketingPreference, value: marketingPreference.value ? marketingPreference.value.toString() : '-1' })} callback={this.marketingPreferenceChanged} options={marketingPreferenceOptions} />
                    </div>
                    <div className='col-xs-6 col-md-3 col-lg-3'>
                        <ct.Select id='venue' labelKey='customerDetails:venue' value={venueSelection} callback={this.venueSelectionChanged} options={venueOptions} />
                    </div>
                    <div className='col-xs-6 col-md-2 col-lg-2'>
                        <ct.Select id='tag' labelKey='customerDetails:tag' value={selectedTagId} callback={this.selectedTagChanged} options={tagOptions} renderOption={renderTag} />
                    </div>
                    <div className='col-xs-6 col-md-4 col-lg-4'>
                        <ct.DateRangePicker id='lastVisit' labelKey='customerDetails:lastVisit' value={lastVisit} callback={this.lastVisitChanged} dateFormat={'D/MM/YYYY'} timeFormat={'HH:mm'} />
                    </div>
                    <div className='col-xs-6 col-md-2 col-lg-2'>
                        <ct.NumberBox id='minVisits' labelKey='customerDetails:minVisits' value={minimumVisits} callback={this.minimumVisitsChanged} />
                    </div>
                    <div className='col-xs-6 col-md-4 col-lg-4'>
                        <ct.DateRangePicker id='customerCreated' labelKey='customerDetails:customerCreated' value={customerCreated} callback={this.customerCreatedChanged} dateFormat={'D/MM/YYYY'} />
                    </div>
                    <div className='col-xs-6 col-md-4 col-lg-4'>
                        <ct.NumericRange id='customerCreated' labelKey='customerDetails:ageRange' value={ageRange} callback={this.ageRangeChanged} />
                    </div>
                    <div className='col-xs-6 col-md-4 col-lg-4'>
                        <ct.MultiSelect id='bookedActivityFilter' labelKey='customerDetails:bookedActivityFilter' options={bookedActivityOptions} callback={this.selectedBookedActivityChanged} />
                    </div>
                    <div className='col-xs-6 col-md-4 col-lg-4'>
                        <ct.MultiSelect id='participantActivityFilter' labelKey='customerDetails:participantActivityFilter' options={participantActivityOptions} callback={this.selectedParticipantsActivityChanged} />
                    </div>
                </div>
                <div className='customer-filter-actions text-center'>
                    <button className='btn btn-success' onClick={e => clickHandler(e, this.applyFilter)}>{t('Global:apply')}</button>
                    <button className='btn btn-warning' onClick={e => clickHandler(e, this.clearFilter)}>{t('Global:clear')}</button>
                </div>
            </div>
        </div>;
    }
}