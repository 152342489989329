
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'

import { ApplicationState } from '../../../store';
import * as ct from '../../global/controls';

import * as api from '../../../store/apiClient';
import * as TaxRateActions from '../../../store/pages/taxRates/actions';
import * as ModalActions from '../../../store/global/modal/actions';
import TaxRateForm from './taxRateForm';
import Loading from '../../global/loading';
import { clickHandler } from '../../../utils/util';
import ApiError from '../../global/apiError';
import { TaxRate } from '../../../store/pages/taxRates/types';


interface LocalProps {
    venueId: string;
}

interface LocalState {
    isLoading: boolean;
    taxRates: TaxRate[];
    loadError: api.ApiError | null;
}

interface Actions {
    editTaxRate: () => void;
    loadTaxRates: () => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
}

type TaxRatesPageProps = LocalState & Actions & LocalProps;

interface TaxRatesPageState {
    showArchived: ct.FormValue<boolean>;
}

class TaxRatesPage extends React.Component<TaxRatesPageProps, TaxRatesPageState> {

    constructor(props: TaxRatesPageProps) {
        super(props);

        this.state = { showArchived: ct.asFormValue('showArchived', false) }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private editTaxRate = (user: TaxRate) => {
        this.showOverlay(user, false);
    }

    private addTaxRate = () => {
        this.showOverlay(null, true);
    }

    private showOverlay = (taxRate: TaxRate | null, addingNew: boolean) => {
        this.props.editTaxRate();
        this.props.showModal(<TaxRateForm isNew={addingNew} venueId={this.props.venueId} taxRate={taxRate ? taxRate : null} />, 'TaxRateForm');
    }

    render() {

        const { isLoading, loadError } = this.props;
        const { showArchived } = this.state;
        const { t } = this.context;

        const body = isLoading ? <Loading /> : this.renderTaxRatesTable();

        return <section className='taxRatesPage'>
            <header className='section-header'>
                <div className='page-heading'>
                    <h1 className='taxRates_title'>{t('TaxRatesPage:title')}</h1>
                </div>
                <div className='section-actions right'>
                    <ct.Checkbox id='showArchived' labelKey='Global:showArchived' value={showArchived} callback={val => this.setState({ showArchived: ct.asFormValue('showArchived', val) })} style={({ display: 'inline-block' })} />
                    <button className='btn btn-info' onClick={e => clickHandler(e, this.addTaxRate)}>{t('TaxRatesPage:addTaxRate')}</button>
                </div>
            </header>
            {body}
            <ApiError error={loadError} />
        </section>;
    }

    private renderTaxRatesTable() {
        const { taxRates } = this.props;

        const rates = taxRates.map(r =>
            <tr key={r.id}>
                <td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.editTaxRate(r))}>{r.name}</button></td>
                <td>{`${r.rate}%`}</td>
            </tr>
        );

        return <table className='table table-condensed'>
            <thead>
                <tr>
                    <th>{this.context.t('TaxRatesPage:nameHeading')}</th>
                    <th>{this.context.t('TaxRatesPage:rateHeading')}</th>
                </tr>
            </thead>
            <tbody>
                {rates}
            </tbody>
        </table>;
    }
};


const matStateToProps = (state: ApplicationState) => ({
    taxRates: state.taxRates.taxRates,
    isLoading: state.taxRates.isLoading,
})

const mapDispatchToProps = (dispatch: Dispatch) => (
    {
        showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
        closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
        loadTaxRates: bindActionCreators(TaxRateActions.actionCreators.loadTaxRates, dispatch),
        editTaxRate: bindActionCreators(TaxRateActions.actionCreators.editTaxRate, dispatch),
    }
);


// Wire up the React component to the Redux store
export default connect(
    matStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps        // Selects which action creators are merged into the component's props
)(TaxRatesPage);

