
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';
import * as api from '../../../store/apiClient';

import { DateFormat, TimeFormat, Venue } from '../../../store/pages/venues/types';
import { Registration, RegistrationEventBookingNote } from '../../../store/pages/reception/types';
import { IBooking, IEvent, IEventReservation } from './types';
import { CustomerCategoryCount, CustomerSearchResult, ReservationType } from '../../../store/pages/diary/types';
import { clickHandler, generateTempId, getAge, isNullOrEmpty, parseLocalDateTime } from '../../../utils/util';
import ReservationInfoLabel from '../diary/reservationInfoLabel';
import { stringComparer } from '../../../utils/comparers';
import Close from '../../icons/close';
import { Tag } from '../../../store/pages/tags/types';
import { SelectedRegistration } from '../diary/types';
import CreateEvent from '../diary/createEvent';
import RegistrationEventHeader from './registrationEventHeader';
import EventReservations from './eventReservations';
import ReservationBookingHeader from './reservationBookingHeader';
import BookingRegistration from './bookingRegistration';


interface CompleteAddToBookingProps {
    bookingId: string;
    events: IEvent[];
    registrations: Registration[];
    processing: boolean;
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
    showNotes: (notes: RegistrationEventBookingNote[], name: string, billNumber: string) => void;
    payIndividually: (bookingId: string, billId: string, customerId: string) => void;
    payRemaining: (bookingId: string, billId: string, amount: number) => void;
    editRegistration: (bookingId: string, registrationId: string) => void;
    checkIn: (bookingId: string, registrationId: string) => void;
    undoCheckIn: (bookingId: string, registrationId: string) => void;
    close: () => void;
}

interface CompleteAddToBookingState {

}

class CompleteAddToBooking extends React.Component<CompleteAddToBookingProps, CompleteAddToBookingState> {

    constructor(props: CompleteAddToBookingProps) {
        super(props);

        this.state = { };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    toggleCheckedIn = (bookingId: string, reg: Registration) => reg.checkedIn ? this.props.undoCheckIn(bookingId, reg.id) : this.props.checkIn(bookingId, reg.id)

    render() {
        const { bookingId, events, registrations, processing, timeFormat, dateFormat, close, showNotes, payRemaining, payIndividually, editRegistration } = this.props;
        const { t } = this.context;

        const eventsForBooking = events
            .map(e => ({ ...e, bookings: e.bookings.filter(bk => bk.id === bookingId) }))
            .filter(e => e.bookings.length > 0);

        return <div className='flex flex-col flex-stretch' style={{ overflow: 'hidden' }}>
            <h3 className='text-center flex-shrink'>{t('ReceptionPage:customersRegistered')}</h3>

            {processing || eventsForBooking.length === 0
                ? <div className='alert alert-info text-center'>{t('ReceptionPage:registeringMessage')}</div>
                : <ul className='list-unstyled flex-stretch' style={{ overflow: 'auto', padding: '6px' }}>
                    {eventsForBooking.map(event => <li>
                        <div className='reception-event' style={{ borderLeft: `solid 5px ${event.colour}`, borderBottom: `solid 1px ${event.colour}`, borderRight: `solid 5px ${event.colour}` }}>
                            <div className='reception-event-heading'>
                                {event.reservations.length > 1 ? <RegistrationEventHeader event={event} timeFormat={timeFormat} expanded={true} /> : null}
                                <EventReservations event={event} expanded={true} timeFormat={timeFormat} />
                            </div>
                            {event.bookings
                                .sort((b1, b2) => stringComparer(`${b1.organiserFirstName} ${b1.organiserLastName}`, `${b2.organiserFirstName} ${b2.organiserLastName}`))
                                .map(b => {

                                    const bookingRegistrations = registrations.filter(r => {
                                        const ix = r.bookings.findIndex(x => x.bookingId === b.id);
                                        const wasRemoved = r.registeredForEvents.findIndex(e => e.eventId === event.id && e.removed) >= 0;
                                        return ix >= 0 && !wasRemoved
                                    });

                                    return <ReservationBookingHeader
                                        booking={b}
                                        enableSelectBooking={false}
                                        venueId={event.venueId}
                                        eventId={event.id}
                                        showNotes={showNotes}
                                        actions={[<button className='btn btn-primary btn-sm' onClick={e => clickHandler(e, () => payRemaining(b.id, b.billId, b.outstandingAmount))} style={{ padding: '1px 10px' }}>{t('Global:payAll')}</button>]}>
                                        {bookingRegistrations
                                            .sort((r1, r2) => stringComparer(`${r1.customerFirstname} ${r1.customerLastname}`, `${r2.customerFirstname} ${r2.customerLastname}`))
                                            .map(r => <BookingRegistration booking={b} reg={r} eventId={event.id} dateFormat={dateFormat} timeFormat={timeFormat} editRegistration={editRegistration} payIndividually={payIndividually} toggleCheckedIn={this.toggleCheckedIn} />)
                                        }
                                    </ReservationBookingHeader>
                                }
                                )}
                        </div>
                    </li>)}
                </ul>}

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                <button className='btn btn-primary' onClick={e => clickHandler(e, close)} disabled={processing}>{t('Global:done')}</button>
            </div>

        </div>
    }
}

CompleteAddToBooking.contextTypes = {
    t: PropTypes.func
}

export default CompleteAddToBooking;