
import * as PropTypes from 'prop-types'

import { ITranslationContext } from '../../../../translations';
import { clickHandler } from '../../../../utils/util';
import ActivityFormatVariationListItem from './activityFormatVariationListItem';
import { Variation } from './activityFormatForm';
import { Time } from '../../../../store/global/types';
import { ActivityFormat } from '../../../../store/pages/activityFormats/types';

interface ActivityFormatVariationsProps {
    activityFormatId: string;
    variations: Variation[];
    activityFormats: ActivityFormat[];
    minParticipantsChanged: (key: string, val: number) => void;
    maxParticipantsChanged: (key: string, val: number) => void;
    nameChanged: (key: string, val: string) => void;
    runningTimeChanged: (key: string, scheduleId: string, val: Time) => void;
    minGapBeforeChanged: (key: string, scheduleId: string, val: Time) => void;
    minGapAfterChanged: (key: string, scheduleId: string, val: Time) => void;
    minBreakDurationChanged: (key: string, scheduleId: string, index: number, val: Time | null) => void;
    maxBreakDurationChanged: (key: string, scheduleId: string, index: number, val: Time | null) => void;
    scheduleTimingTemplateNameChanged: (key: string, scheduleId: string, value: string) => void;
    mustBookItemsTogetherChanged: (key: string, val: boolean) => void;
    toggleCompatibleActivityFormatVariation: (key: string, activityFormatId: string, activityFormatVariationId: string, selected: boolean) => void;
    timingTemplateNameChanged: (key: string, val: string) => void;
    addVariation: () => void;
    addSchedule: (key: string) => void;
    removeSchedule: (variationKey: string, scheduleId: string) => void;
    removeVariation: (key: string) => void;
}

const ActivityFormatVariations = (props: ActivityFormatVariationsProps, context: ITranslationContext) => {
    const { t } = context;
    const { addVariation } = props;
    return (
        <div className='activity-format-variations'>
            {renderAsList(props)}
            <div><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, addVariation)}>{t('ActivityFormatForm:addVariation')}</button></div>
        </div>
    );
}

const renderAsList = (props: ActivityFormatVariationsProps) => {
    const { activityFormatId, variations, activityFormats, minParticipantsChanged, maxParticipantsChanged, nameChanged, runningTimeChanged, minGapBeforeChanged,
        minGapAfterChanged, timingTemplateNameChanged, mustBookItemsTogetherChanged, toggleCompatibleActivityFormatVariation, minBreakDurationChanged,
        maxBreakDurationChanged, scheduleTimingTemplateNameChanged, removeVariation, addSchedule, removeSchedule } = props;
    const canRemoveVariation = variations.length > 1;

    return <ul className='list-unstyled'>
        {variations.sort((v1, v2) => v1.minParticipants.value - v2.minParticipants.value).map(v => <ActivityFormatVariationListItem
            key={v.key}
            activityFormatId={activityFormatId}
            variation={v}
            activityFormats={activityFormats}
            minParticipantsChanged={minParticipantsChanged}
            maxParticipantsChanged={maxParticipantsChanged}
            nameChanged={nameChanged}
            runningTimeChanged={runningTimeChanged}
            minGapBeforeChanged={minGapBeforeChanged}
            minGapAfterChanged={minGapAfterChanged}
            minBreakDurationChanged={minBreakDurationChanged}
            maxBreakDurationChanged={maxBreakDurationChanged}
            scheduleTimingTemplateNameChanged={scheduleTimingTemplateNameChanged}
            mustBookItemsTogetherChanged={mustBookItemsTogetherChanged}
            toggleCompatibleActivityFormatVariation={toggleCompatibleActivityFormatVariation}
            timingTemplateNameChanged={timingTemplateNameChanged}
            removeVariation={removeVariation}
            addSchedule={addSchedule}
            removeSchedule={removeSchedule}
            canRemove={canRemoveVariation} />)}
    </ul>
}


ActivityFormatVariations.contextTypes = {
    t: PropTypes.func
}
export default ActivityFormatVariations;