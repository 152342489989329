
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { Fee, FeeType } from '../../../../store/pages/fees/types';
import FeeForm from './feeForm';
import { TaxRate } from '../../../../store/pages/taxRates/types';
import Loading from '../../../global/loading';
import { clickHandler } from '../../../../utils/util';


interface FeeListProps {
    venueId: string;
    fees: Fee[];
    taxRates: TaxRate[];
    isLoadingFees: boolean;
    isLoadingTaxRates: boolean;
    editFee: () => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
}

interface FeeListState {
    showArchived: boolean;
}

class FeeList extends React.Component<FeeListProps, FeeListState> {

    constructor(props: FeeListProps) {
        super(props);

        this.state = { showArchived: false }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private editFee = (fee: Fee) => {
        this.showOverlay(fee, false);
    }

    private addFee = () => {
        this.showOverlay(null, true);
    }

    private showOverlay = (fee: Fee | null, addingNew: boolean) => {
        const { editFee, showModal, venueId } = this.props;
        editFee();
        showModal(<FeeForm isNew={addingNew} venueId={venueId} fee={fee} />, 'FeeForm');
    }
    
    isLoading = () => this.props.isLoadingFees || this.props.isLoadingTaxRates;

    render() {
        const { showArchived } = this.state;
        const { t } = this.context;
        const body = this.isLoading() ? <Loading /> : this.renderFeeList();

        return <section className='venue-item-list'>
            <header className='section-header'>
                <div className='section-actions'>
                    <label>
                        <input type='checkbox' checked={showArchived} onChange={e => this.setState({ showArchived: e.currentTarget.checked })} />
                        <span style={({ marginLeft: '10px' })}>{t('Global:showArchived')}</span>
                    </label>
                    <button className='btn btn-info' onClick={e => clickHandler(e, this.addFee)}>{t('FeeList:addFee')}</button>
                </div>
            </header>
            <div className='at-panel sub-section-panel-240px'>
                {body}
            </div>
        </section>;
    }
    
    private renderFeeList = () => {
        const { t } = this.context;
        const { showArchived } = this.state;
        const { venueId, fees, taxRates } = this.props;

        const filteredFees = fees.filter(f => f.venueId === venueId && (showArchived || !f.archived)).map(f => {
            var taxRate = taxRates.find(tr => tr.id === f.taxRateId);
            return (
                <tr key={f.id}>
                    <td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.editFee(f))}>{f.name}</button></td>
                    <td>{t(`FeeType:${FeeType[f.feeType]}`)}</td>
                    <td>{f.feeType === FeeType.Fixed ? f.fixedAmount.toFixed(2) : ''}</td>
                    <td>{f.feeType === FeeType.Percentage ? `${Math.floor(f.percentage * 100)}%` : ''}</td>
                    <td>{f.feeType === FeeType.Percentage ? f.maxAmount.toFixed(2) : ''}</td>
                    <td>{taxRate ? taxRate.name : ''}</td>
                </tr>
            );
        });

        return (
            <table className='table table-condensed'>
                <thead>
                    <tr>
                        <th>{this.context.t('FeeList:nameHeading')}</th>
                        <th>{this.context.t('FeeList:feeTypeHeading')}</th>
                        <th>{this.context.t('FeeList:fixedAmountHeading')}</th>
                        <th>{this.context.t('FeeList:percentageHeading')}</th>
                        <th>{this.context.t('FeeList:maxAmountHeading')}</th>
                        <th>{this.context.t('FeeList:taxRateHeading')}</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredFees}
                </tbody>
            </table>
        );
    }
}

// Wire up the React component to the Redux store
export default FeeList;
