
import * as React from 'react';
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import * as TagActions from '../../store/pages/tags/actions';
import * as ct from './controls';
import * as api from '../../store/apiClient';

import { colours, ValidationError } from '../../store/global/types';

import { SpecialTagType, Tag } from '../../store/pages/tags/types';
import { ApplicationState } from '../../store';
import ColourPicker from './colourPicker';
import { clickHandler, isNullOrEmpty } from '../../utils/util';
import ApiError from './apiError';
import TagList from './tagList';


interface ComponentProps {
    selectedTags: Tag[];
    canCreateTag: boolean;
    canAddTag?: boolean;
    addTagLabel?: string;
    tagFilter?: (t: Tag) => boolean;
    isSaving: boolean;
    saveComplete: boolean;
    specialTagType: SpecialTagType;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
    tagAdded: (tag: Tag) => void;
    removeTag?: (tagId: string) => void;
}

interface MappedReduxState {
    tags: Tag[];
}

interface Actions {
    saveTag: (isNew: boolean, tagId: string | null, name: string, colour: string, archived: boolean, specialTagType: SpecialTagType) => void;
}

interface TagSelectionState {
    showAddTag: boolean;
    selectedTag: Tag | null;
    newTagName: string;
    newTagColour: string;
    errorKey: string | null;
}

type TagSelectionProps = MappedReduxState & Actions & ComponentProps;

class TagSelection extends React.Component<TagSelectionProps, TagSelectionState> {

    _popup: React.RefObject<HTMLDivElement>;

    constructor(props: TagSelectionProps) {
        super(props);

        this._popup = React.createRef();
        this.state = { showAddTag: false, selectedTag: null, newTagName: '', newTagColour: colours[0], errorKey: null };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    selectedTagChanged = (selection: string) => {
        const tag = this.props.tags.find(t => t.id === selection);
        if (tag) {
            this.setState({ selectedTag: tag });
        }
    }

    AddExistingTag = () => {
        const { selectedTag } = this.state;
        const { tagAdded } = this.props;

        if (selectedTag) {
            tagAdded(selectedTag);
        }
        this.hideAddTag();
    }

    AddNewTag = () => {
        const { tags, saveTag, specialTagType } = this.props;
        const { newTagName, newTagColour } = this.state;
        const existing = tags.find(t => t.name === newTagName);
        if (existing) {
            this.setState({ errorKey: 'Global:duplicateTag' });
        } else {
            this.setState({ errorKey: null });
            saveTag(true, null, newTagName, newTagColour, false, specialTagType);
        }
    }

    enableClickHandler = () => document.addEventListener('click', this.globalClickHandler);
    disableClickHandler = () => document.removeEventListener('click', this.globalClickHandler);
    showAddTag = () => this.setState({ showAddTag: true, selectedTag: null, newTagName: '' }, this.enableClickHandler);
    hideAddTag = () => this.setState({ showAddTag: false, selectedTag: null, newTagName: '' }, this.disableClickHandler);

    componentDidUpdate(prevProps: TagSelectionProps) {
        const { newTagName, newTagColour } = this.state;
        const { isSaving, tags, tagAdded } = this.props;

        if (prevProps.isSaving && !isSaving) {
            const newTags = tags.filter(t => prevProps.tags.findIndex(x => x.id === t.id) < 0 && t.name === newTagName && t.colour === newTagColour);
            if (newTags.length === 1) {
                tagAdded(newTags[0]);
                this.hideAddTag();
            }
        }
    }

    componentWillUnmount() {
        this.disableClickHandler();
    }

    globalClickHandler = (e: Event) => {
        console.log('global click')
        // ignore click event happened inside the dropdown menu
        const path = e.composedPath();
        const pathMatch = path.findIndex(x => x === this._popup.current) >= 0;
        if (pathMatch) {
            return
        }

        // else hide dropdown menu
        this.hideAddTag()
    }

    render() {

        const { selectedTags, tags, saveError, canCreateTag, tagFilter, removeTag, addTagLabel, canAddTag } = this.props;
        const { showAddTag, selectedTag, newTagName, newTagColour, errorKey } = this.state;
        const { t } = this.context;

        let error = null;
        if (!isNullOrEmpty(errorKey)) {
            error = <div className='alert alert-danger'>{t(errorKey, { tagName: newTagName })}</div>
        } else if (saveError) {
            error = <ApiError error={this.props.saveError} />
        }

        const tagOptions = [{ key: '', name: t('Global:selectTag') }].concat(tags.filter(t => !tagFilter || tagFilter(t)).filter(t => selectedTags.findIndex(x => x.id === t.id) < 0).map(t => ({ key: t.id, name: t.name })));
        const filteredTags = selectedTags.map(t => {
            const tg = tags.find(x => x.id === t.id);
            return tg ? tg : { id: '', name: '', colour: '', archived: false, specialTagType: SpecialTagType.None }
        }).filter(t => !isNullOrEmpty(t.id));

        const getTagCol = (opt: ct.SelectOption) => tags.filter(t => t.id === opt.key).map(t => t.colour).concat(['transparent'])[0];

        return <div>
            <TagList tags={filteredTags} showNoTagsMessage={true} removeTag={removeTag} />

            <div className='row'>
                <div className='col-xs-12'>
                    {typeof canAddTag === 'undefined' || canAddTag ? <button className='btn btn-link' onClick={e => clickHandler(e, this.showAddTag)}>{t(addTagLabel || 'Global:addTag')}</button> : null}
                </div>
            </div>
            {showAddTag ? <div className='at-panel add-tag-popup' style={({ position: 'absolute', zIndex: 2 })} ref={this._popup}>
                <div className='flex flex-row flex-center'>
                    <ct.Select id='name' labelKey='Global:addTag' classNames='flex-stretch' value={ct.asFormValue('name', selectedTag ? selectedTag.id : '')} callback={this.selectedTagChanged} options={tagOptions} renderOption={o => <span key={o.key} className="label tag-label" style={{ backgroundColor: getTagCol(o) }}>{o.name}</span>} />
                    <button className='btn btn-primary flex-shrink' style={({ margin: '0 6px' })} onClick={e => clickHandler(e, this.AddExistingTag)} disabled={selectedTag ? false : true}>{t('Global:add')}</button>
                </div>
                {canCreateTag
                    ? <div className='flex flex-row flex-center'>
                        <ct.TextBox id='newTagName' labelKey='Global:newTagName' placeholderKey='Global:newTagName' classNames='flex-stretch' value={ct.asFormValue('newTagName', newTagName)} callback={val => this.setState({ newTagName: val })} />
                        <ColourPicker labelKey='' style={({ margin: '0 6px' })} disableAlpha={true} presetColors={colours} colourHex={newTagColour} onColourChanged={color => this.setState({ newTagColour: color })} />
                        <button className='btn btn-primary flex-shrink' style={({ margin: '0 6px' })} onClick={e => clickHandler(e, this.AddNewTag)} disabled={isNullOrEmpty(newTagName)}>{t('Global:add')}</button>
                    </div>
                    : null
                }
                {error}
            </div> : null}
        </div>
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    tags: state.tags.tags.filter(t => !t.archived),
    isSaving: state.tags.isSaving,
    saveComplete: state.tags.saveComplete,
    saveError: state.tags.saveError,
    validationErrors: state.tags.validationErrors
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    saveTag: bindActionCreators(TagActions.actionCreators.saveTag, dispatch)
});

// Wire up the React component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(TagSelection);