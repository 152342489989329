
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'
import { ApplicationState } from '../../../../store';
import * as ett from '../../../../store/pages/emailTemplates/types';
import * as EmailTemplateActions from '../../../../store/pages/emailTemplates/actions';
import * as ModalActions from '../../../../store/global/modal/actions';
import EmailTemplateForm from './emailTemplateForm';
import SendTestEmail from './sendTestEmail';
import Loading from '../../../global/loading';
import { ClientEmailTemplate } from '../../../../store/pages/emailTemplates/types';
import { clickHandler, isNullOrEmpty } from '../../../../utils/util';
import { stringComparer } from '../../../../utils/comparers';

interface LocalProps {
    venueId: string;
}

interface LocalState {
    emailTemplates: ett.ClientEmailTemplate[];
    isLoading: boolean;
}

interface Actions {
    loadEmailTemplates: () => void;
    editEmailTemplate: () => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
}

type EmailTemplatesListProps = LocalState & Actions & LocalProps;

interface EmailTemplatesListState {
    showArchived: boolean;
}

class EmailTemplatesList extends React.Component<EmailTemplatesListProps, EmailTemplatesListState> {

    constructor(props: EmailTemplatesListProps) {
        super(props);

        this.state = { showArchived: false }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private editEmailTemplate = (emailTemplate: ett.ClientEmailTemplate) => {
        this.showOverlay(emailTemplate, emailTemplate.clientEmailTemplateId === null, false);
    }

    private showOverlay = (emailTemplate: ett.ClientEmailTemplate, addingNew: boolean, allowTypeSelection: boolean) => {
        const { venueId, editEmailTemplate, showModal } = this.props;
        editEmailTemplate();
        showModal(<EmailTemplateForm isNew={addingNew} venueId={venueId} template={emailTemplate} allowTypeSelection={allowTypeSelection} />, 'EmailTemplateForm');
    }

    private addTemplate = () => {
        const { venueId } = this.props;
        this.showOverlay({
            venueId: venueId,
            clientEmailTemplateId: null,
            emailType: ett.EmailType.General,
            tags: [],
            emailTemplateId: null,
            name: null,
            ccEmailAddress: '',
            bccEmailAddress: '',
            subjectTemplate: null,
            textTemplate: null,
            htmlTemplate: null,
            jsonTemplate: null,
            sendFromEmailAddressOverride: null,
            sendFromEmailNameOverride: null,
            templateType: ett.EmailTemplateType.Grapesjs,
            createdBy: null,
            createDateTime: null,
            showActivityStartTimes: true,
            archived: false,
            attachments: []
        },
            true,
            true);
    }

    sendTestEmail = (emailTemplate: ClientEmailTemplate) => {
        const { venueId, showModal, closeModal } = this.props;
        showModal(<SendTestEmail venueId={venueId} emailTemplate={emailTemplate} close={closeModal} />, 'SendTestEmail');
    }

    render() {
        const { t } = this.context;
        const { showArchived } = this.state;
        const { venueId, isLoading, emailTemplates } = this.props;
        const body = isLoading ? <Loading /> : this.renderEmailTemplatesTable(emailTemplates);

        return <section className='emailTemplatesPage'>
            <header className='section-header'>
                <div className='page-heading'>
                    <h1 className='emailTemplates_title'>{t('EmailTemplatesPage:title')}</h1>
                </div>
                <div className='section-actions right'>
                    <label>
                        <input type='checkbox' checked={showArchived} onChange={e => this.setState({ showArchived: e.currentTarget.checked })} />
                        <span style={({ marginLeft: '10px' })}>{t('Global:showArchived')}</span>
                    </label>
                    <button className='btn btn-info' onClick={e => clickHandler(e, this.addTemplate)}>{t('EmailTemplatesPage:addEmailTemplate')}</button>
                </div>
            </header>
            {body}
        </section>;
    }
    
    getTemplateName = (ct: ett.ClientEmailTemplate) => {
        if (!isNullOrEmpty(ct.name)
            && (ct.emailType === ett.EmailType.General
            || ct.emailType === ett.EmailType.BookingConfirmation
            || ct.emailType === ett.EmailType.GeneralBookingEmail
            || ct.emailType === ett.EmailType.PaymentReminder
            || ct.emailType === ett.EmailType.BookingRegistrationLink
            || ct.emailType === ett.EmailType.CustomerLogin
            || ct.emailType === ett.EmailType.Voucher)) {
            return ct.name ? ct.name.trim() : ct.name;
        }

        return this.context.t(`EmailTemplateType:${(ett.EmailType[ct.emailType] || '').toString()}`);
    }

    private renderEmailTemplatesTable(emailTemplates: ClientEmailTemplate[]) {
        const { t } = this.context;
        const { showArchived } = this.state;
        const { venueId } = this.props;

        const templates = emailTemplates.filter(t => {
            return (showArchived || !t.archived) && (!t.venueId || t.venueId === venueId);
        }).sort((t1, t2) => stringComparer(this.getTemplateName(t1), this.getTemplateName(t2)))
            .map(et =>
            <tr key={et.clientEmailTemplateId || et.emailType}>
                <td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.editEmailTemplate(et))}>{this.getTemplateName(et)}</button></td>
                {showArchived ? <td>{et.archived ? <span className='label label-danger'>{t('Global:archived')}</span> : null}</td> : null}
                <td>{t(`EmailTemplateType:${(ett.EmailType[et.emailType] || '').toString()}`)}</td>
                <td>{et.subjectTemplate || ''}</td>
                <td>{et.clientEmailTemplateId ? <button className='btn btn-info' onClick={e => clickHandler(e, () => this.sendTestEmail(et))}>{t('EmailTemplatesPage:SendTest')}</button> : null}</td>
            </tr>
        );
 
        return <table className='table table-condensed'>
            <thead>
                <tr key='email-templates-header'>
                    <th>{t('EmailTemplatesPage:nameHeading')}</th>
                    {showArchived ? <th>{t('Global:archived')}</th> : null}
                    <th>{t('EmailTemplatesPage:emailTypeHeading')}</th>
                    <th>{t('EmailTemplatesPage:subjectHeading')}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {templates}
            </tbody>
        </table>;
    }
};

const mapStateToProps = (state: ApplicationState) => ({
    emailTemplates: state.emailTemplates.emailTemplates,
    isLoading: state.emailTemplates.isLoading
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadEmailTemplates: bindActionCreators(EmailTemplateActions.actionCreators.loadEmailTemplates, dispatch),
    editEmailTemplate: bindActionCreators(EmailTemplateActions.actionCreators.editEmailTemplate, dispatch),
    showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
});

// Wire up the React component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplatesList);

