
import * as React from 'react';
import { Link } from 'react-router-dom'
import * as PropTypes from 'prop-types'
import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import * as api from '../../../store/apiClient';
import ApiError from '../../global/apiError';

interface LoginFormProps {
    loggingIn: boolean;
    login: (username: string, password: string) => void;
    loginError: api.ApiError | null;
}

interface LoginFormState {
    username: ct.FormValue<string>;
    password: ct.FormValue<string>;
}

class LoginForm extends React.Component<LoginFormProps, LoginFormState> {

    constructor(props: LoginFormProps) {
        super(props);

        this.state = { username: this.validateUsername(''), password: this.validatePassword('') };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    validateUsername = (val: string) => v.validate(val, 'username', [], []);
    validatePassword = (val: string) => v.validate(val, 'password', [], []);

    logIn = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {

            if (this.state.username.isValid && this.state.password.isValid) {
                const username = this.state.username.value;
                const password = this.state.password.value;
                this.props.login(username, password);
            }
        }
    }

    render() {
        const { t } = this.context;

        return (
            <div className='login cf'>
                <div>
                    <h2 className='login-title'>Login</h2>

                    <form className='data-form' onSubmit={this.logIn}>
                        <div className='form-group'>
                            <ct.TextBox id='username' labelKey='Global:username' placeholderKey='Global:username-placeholder' value={this.state.username} callback={val => this.setState({ username: this.validateUsername(val) })} />
                        </div>
                        <div className='form-group'>
                            <ct.Password id='password' labelKey='Global:password' placeholderKey='Global:password-placeholder' value={({ ...this.state.password, value: this.state.password.value || '' })} callback={val => this.setState({ password: this.validatePassword(val) })} />
                        </div>
                        <ApiError error={this.props.loginError} className='error-placeholder' />

                        {this.props.loggingIn ? <div className='alert alert-info'>{t('LoginForm:loggingIn')}</div> : null}
                        <div>
                            <input type='submit' value={t('Global:login')} className='btn btn-primary' disabled={this.props.loggingIn} />
                            <Link className='navbar-brand' to={'/login/forgottenPassword'} style={({ float: 'right' })}>{t('loginForm:forgottenPassword')}</Link>
                        </div>
                    </form>
                </div>
            </div>);
    }
}

export default LoginForm;