
import { Action } from 'redux';

import { PublicWebsiteSettingsActionTypes, PublicWebsiteSettingsState } from './types';
import { PublicWebsiteSettingsActions } from './actions'
import { createReducer } from '../../reducer';

const unloadedState: PublicWebsiteSettingsState = { isLoading: false, publicWebsiteSettings: [], loadError: null, isSaving: false, saveComplete: false, saveError: null, validationErrors: [] };

export const reducer = (state: PublicWebsiteSettingsState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: PublicWebsiteSettingsState, incomingAction: Action): PublicWebsiteSettingsState => {

    const action = incomingAction as PublicWebsiteSettingsActions;
    switch (action.type) {
        case PublicWebsiteSettingsActionTypes.RequestPublicWebsiteSettings:
            return {
                ...state,
                isLoading: true,
                publicWebsiteSettings: state.publicWebsiteSettings,
                loadError: null
            };
        case PublicWebsiteSettingsActionTypes.ReceivedPublicWebsiteSettings:
            return {
                ...state,
                isLoading: false,
                publicWebsiteSettings: action.publicWebsiteSettings,
                loadError: action.error
            };
        case PublicWebsiteSettingsActionTypes.EditPublicWebsiteSettings:
            return {
                ...state,
                isSaving: false,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case PublicWebsiteSettingsActionTypes.SavePublicWebsiteSettings:
            return {
                ...state,
                isSaving: true,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case PublicWebsiteSettingsActionTypes.PublicWebsiteSettingsSaved:
            return {
                ...state,
                isSaving: false,
                saveComplete: true,
                saveError: null,
                validationErrors: []
            };
        case PublicWebsiteSettingsActionTypes.PublicWebsiteSettingsSaveFailed:
            return {
                ...state,
                isSaving: false,
                saveError: action.error,
                saveComplete: false,
                validationErrors: action.error && action.error.validationErrors ? action.error.validationErrors : []
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

