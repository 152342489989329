
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { ITranslationContext } from '../../../translations';

import { CustomerSessionResult } from '../../../store/pages/customer/customerApi';

import { formatLaptime } from '../../../utils/util';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';

interface SessionResultDetailsProps {
    customerFirstName: string;
    customerLastName: string;
    customerSessionResult: CustomerSessionResult;
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
    close: () => void;
}

const SessionResultDetails = (props: SessionResultDetailsProps, context: ITranslationContext) => {

    const { t } = context;

    const numberOfSectors = props.customerSessionResult.laps ? props.customerSessionResult.laps.reduce((maxSector, l) => Math.max(maxSector, l.sectorTimes ? l.sectorTimes.length : 0), 0) : 0;

    return <div>
        <h2>{t('sessionResultDetails:heading', { customerName: `${props.customerFirstName} ${props.customerFirstName}` }) }</h2>

        <div className='row row-m-t'>
            <div className='col-xs-6'>
                <div><label className='no-margin'>{t('sessionResultDetails:sessionName')}</label></div>
                <div>{props.customerSessionResult.sessionName}</div>
            </div>
            <div className='col-xs-6'>
                <div><label className='no-margin'>{t('sessionResultDetails:sessionDate')}</label></div>
                <div>{props.customerSessionResult.sessionStartTime ? props.customerSessionResult.sessionStartTime.toAbbrDateTimeString(props.timeFormat, props.dateFormat, t) : ''}</div>
            </div>
        </div>

        <div className='row row-m-t'>
            <div className='col-xs-6'>
                <div><label className='no-margin'>{t('sessionResultDetails:track')}</label></div>
                <div>{props.customerSessionResult.trackName}</div>
            </div>
            <div className='col-xs-6'>
                <div><label className='no-margin'>{t('sessionResultDetails:kartNumber')}</label></div>
                <div>{props.customerSessionResult.initialKartNumber}</div>
            </div>
        </div>

        <div className='row row-m-t'>
            <div className='col-xs-3'>
                <div><label className='no-margin'>{t('sessionResultDetails:position')}</label></div>
                <div>{props.customerSessionResult.position}</div>
            </div>
            <div className='col-xs-3'>
                <div><label className='no-margin'>{t('sessionResultDetails:laps')}</label></div>
                <div>{props.customerSessionResult.numberOfLaps}</div>
            </div>
            <div className='col-xs-3'>
                <div><label className='no-margin'>{t('sessionResultDetails:bestLap')}</label></div>
                <div>{formatLaptime(props.customerSessionResult.bestLapTime)}</div>
            </div>
            <div className='col-xs-3'>
                <div><label className='no-margin'>{t('sessionResultDetails:averageLap')}</label></div>
                <div>{formatLaptime(props.customerSessionResult.averageLapTime)}</div>
            </div>
        </div>
        <div className='row row-m-t'>
            <div className='col-xs-3'>
                <table className='table table-condensed'>
                    <thead>
                        <tr>
                            <th>{t('sessionResultDetails:lapNumber')}</th>
                            <th>{t('sessionResultDetails:lapTime')}</th>
                            {Array.from({ length: numberOfSectors }, (_, key) => key + 1).map(sn => <th>{t('sessionResultDetails:sectorHeading', { sector: sn })}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {props.customerSessionResult.laps.map(l => <tr key={l.lapNubmer}>
                            <td>{l.lapNubmer}</td>
                            <td>{formatLaptime(l.lapTime)}</td>
                            {l.sectorTimes ? l.sectorTimes.map(st => <td>{formatLaptime(st)}</td>) : null}
                        </tr>)}
                    </tbody>
                </table>
            </div>
        </div>

        <div className='row row-m-t'>
            <div className='col-xs-12'><button className='btn btn-primary' onClick={props.close}>{t('Global:close')}</button></div>
        </div>

    </div>
};

SessionResultDetails.contextTypes = {
    t: PropTypes.func
}

export default SessionResultDetails;