
import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'
import { ApplicationState } from '../../../store';
import * as ReportTypeActions from '../../../store/pages/reportTypes/actions';
import * as ReportDefinitionActions from '../../../store/pages/reportDefinitions/actions';
import Loading from '../../global/loading';
import { ReportType, ReportTypes } from '../../../store/pages/reportTypes/types';
import { ReportDefinition } from '../../../store/pages/reportDefinitions/types';

interface LocalProps { }

type RoutedLocalProps = LocalProps & RouteComponentProps<{}>;

interface LocalState {
    reportTypes: ReportType[];
    isLoadingReportTypes: boolean;
    reportDefinitions: ReportDefinition[];
    isLoadingReportDefinitions: boolean;
}

interface Actions {
    loadReportTypes: () => void;
    loadReportDefinitions: () => void;
    editReportDefinition: () => void;
}

// At runtime, Redux will merge together...
type ReportsPageProps = LocalState & Actions & RoutedLocalProps;

interface ReportsPageState {
    showArchived: boolean;
}

class ReportsPage extends React.Component<ReportsPageProps, ReportsPageState> {

    static contextTypes = {
        t: PropTypes.func
    }

    constructor(props: ReportsPageProps) {
        super(props);

        this.state = { showArchived: false }
    }

    render() {
        const { isLoadingReportTypes, isLoadingReportDefinitions } = this.props;
        const { showArchived } = this.state;
        const { t } = this.context;

        const body = isLoadingReportTypes || isLoadingReportDefinitions ? <Loading /> : this.renderReports();

        return <section className='productCategoriesPage'>
            <header className='section-header'>
                <div className='page-heading'>
                    <h1 className='productCategories_title'>{t('ReportsPage:title')}</h1>
                </div>
                <div className='section-actions right'>
                    <label>
                        <input type='checkbox' checked={showArchived} onChange={e => this.setState({ showArchived: e.currentTarget.checked })} />
                        <span style={({ marginLeft: '10px' })}>{t('Global:showArchived')}</span>
                    </label>
                    <Link className='btn btn-info' to={'/reports/add'}>{t('ReportsPage:addReport')}</Link>
                </div>
            </header>
            {body}
        </section>;
    }

    private renderReports() {
        const { showArchived } = this.state;

        const reports = this.props.reportDefinitions.filter(r => showArchived || !r.archived).map(r =>
            <tr key={r.id}>
                <td>{r.canEdit ? <Link to={`/reports/${r.id}/edit`}>{r.name}</Link> : <span>{r.name}</span>}</td>
                <td>{this.context.t(`ReportTypes:${ReportTypes[r.reportType]}`)}</td>
                <td>{r.description}</td>
                <td>{r.canRun ? <Link className='btn btn-primary' to={`/reports/${r.id}/view`}>{this.context.t('Global:view')}</Link> : null}</td>
            </tr>
        );

        return <table className='table table-condensed'>
            <thead>
                <tr>
                    <th>{this.context.t('Global:name')}</th>
                    <th>{this.context.t('ReportsPage:reportTypeHeading')}</th>
                    <th>{this.context.t('Global:description')}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {reports}
            </tbody>
        </table>;
    }
};


const matStateToProps = (state: ApplicationState) => ({
    reportTypes: state.reportTypes.reportTypes,
    isLoadingReportTypes: state.reportTypes.isLoading,
    reportDefinitions: state.reportDefinitions.reportDefinitions,
    isLoadingReportDefinitions: state.reportDefinitions.isLoading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadReportTypes: bindActionCreators(ReportTypeActions.actionCreators.loadReportTypes, dispatch),
    loadReportDefinitions: bindActionCreators(ReportDefinitionActions.actionCreators.loadReportDefinitions, dispatch),
    editReportDefinition: bindActionCreators(ReportDefinitionActions.actionCreators.editReportDefinition, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    matStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps                  // Selects which action creators are merged into the component's props
)(ReportsPage);
