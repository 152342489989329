
import * as React from 'react';
import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import { clickHandler } from '../../../utils/util';

interface ConfirmationCancellationOverlayProps {
    heading: string;
    message: string;
    affirmativeText: string;
    negativeText: string;
    confirm: (reason: string) => void;
    cancel: () => void;
}

interface ConfirmationCancellationOverlayState {
    reason: ct.FormValue<string>;
}

class ConfirmationCancellationOverlay extends React.Component<ConfirmationCancellationOverlayProps, ConfirmationCancellationOverlayState> {

    constructor(props: ConfirmationCancellationOverlayProps) {
        super(props);

        this.state = { reason: this.validateReason('') };
    }

    confirm = () => {
        if (v.isValid(this.state)) {
            this.props.confirm(this.state.reason.value);
        }
    }

    validateReason = (val: string) => v.validate(val, 'cancellationReason', [v.required], []);

    render() {
        const { heading, message, affirmativeText, negativeText, cancel } = this.props;
        const { reason } = this.state;

        return (
            <div>
                <h3>{heading}</h3>
                <div className='row form-group'>
                    <div className='col-md-12'>
                        {message}
                    </div>
                </div>

                <form className='data-form' onSubmit={e => e.preventDefault()} autoComplete='off'>
                    <div className='row form-group'>
                        <div className='col-md-12'>
                            <ct.TextBox id='cancellationReason' labelKey='EventForm:cancellationReason' placeholderKey='EventForm:cancellationReason' value={reason} callback={val => this.setState({ reason: this.validateReason(val) })} />
                        </div>
                    </div>
                    <div className='row form-group'>
                        <div className='col-md-12 btn-toolbar'>
                            <button className='btn btn-primary' onClick={e => clickHandler(e, this.confirm)}>{affirmativeText}</button>
                            <button className='btn btn-basic' onClick={e => clickHandler(e, cancel)}>{negativeText}</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default ConfirmationCancellationOverlay;