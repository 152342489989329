
import { BillItem, BillsGatewayRefund, GatewayRefundStatus } from "../../../store/pages/pointOfSale/types"

export interface ICreateContactResponse {
    customerId: string;
}

export interface LinkedItemQuantities {
    linkedBillItemKey: string;
    quantity: number;
}

export enum CombinedRefundState {
    Unknown = 0,
    Pending = 1,
    Succeeded = 2,
    PartiallySucceeded = 3,
    Failed = 4,
    Cancelled = 5
}

export interface PosProductCategory {
    id: string;
    name: string;
    colour: string;
    isEventCategory: boolean;
    isVoucherCategory: boolean;
    isMembershipCategory: boolean;
}

export interface ISendEmailResponse {
    sent: boolean;
    error: string | null;
}

export const findLinkedBillItems = (item: BillItem, allItems: BillItem[]) =>  allItems.filter(li => li.primaryBillItemId === item.id && li.reservationId === item.reservationId && item.id !== li.id && li.customerCategoryId !== item.customerCategoryId)

export const CalculateRefundState = (gatewayRefundAttempts: BillsGatewayRefund[]) => {
    const pending = 1;
    const succeeded = 2;
    const failed = 4;
    const cancelled = 8;

    let state = 0;

    for (let i = 0; i < gatewayRefundAttempts.length; i++) {
        const r = gatewayRefundAttempts[i];
        if (r.status === GatewayRefundStatus.Succeeded)
            state = state |= succeeded;
        else if (r.status === GatewayRefundStatus.Failed)
            state = state |= failed;
        else if (r.status === GatewayRefundStatus.Cancelled)
            state = state |= cancelled;
        else if (r.status === GatewayRefundStatus.Pending)
            state = state |= pending;
    }

    if (state === succeeded) return CombinedRefundState.Succeeded;
    if (state === pending) return CombinedRefundState.Pending;
    if (state === failed) return CombinedRefundState.Failed;
    if (state === cancelled) return CombinedRefundState.Cancelled;
    if ((state & succeeded) === succeeded) return CombinedRefundState.PartiallySucceeded;
    return CombinedRefundState.Unknown;
}

export const CalculateRefundLabelState = (state: CombinedRefundState) => {
    switch (state) {
        case CombinedRefundState.Succeeded:
            return 'success'
        case CombinedRefundState.Cancelled:
        case CombinedRefundState.Failed:
            return 'danger'
        default:
            return 'warning'
    }
}
