
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../../../../store';
 
import { quillStandardToolbar } from '../../../global/quillSettings';

import * as api from '../../../../store/apiClient';
import * as TermsActions from '../../../../store/pages/termsAndConditions/actions';
import * as ModalActions from '../../../../store/global/modal/actions';
import * as ct from '../../../global/controls';
import * as v from '../../../global/validation';
import { defaultFormHandler, clickHandler } from '../../../../utils/util';
import ApiError from '../../../global/apiError';

import { BookingTerms } from '../../../../store/pages/termsAndConditions/types';

interface EditBookingTermsState {
    name: ct.FormValue<string>;
    isFallbackTerms: boolean;
    termsAndConditions: ct.FormValue<string>;
    confirmTermsText: ct.FormValue<string>;
    canSelectDefault: boolean;
}

interface LocalProps {
    venueId: string;
    terms: BookingTerms | null;
}

interface LocalState {
    isSavingBookingTerms: boolean;
    bookingTermsSaveError: api.ApiError | null;
}

interface Actions {
    saveBookingTerms: (bookingTermsId: string | null, venueId: string, name: string, isFallbackTerms: boolean, termsAndConditions: string, confirmTermsText: string) => void;
    closeModal: () => void;
}

type EditBookingTermsProps = LocalState & Actions & LocalProps;

class EditBookingTerms extends React.Component<EditBookingTermsProps, EditBookingTermsState> {

    constructor(props: EditBookingTermsProps) {
        super(props);

        const { terms } = props;

        this.state = {
            name: this.validateName(terms ? terms.name : ''),
            isFallbackTerms: terms && terms.isFallbackTerms ? true : false,
            termsAndConditions: this.validateTerms(terms ? terms.terms : ''),
            confirmTermsText: this.validateConfirmTermsText(terms ? terms.bookingConfirmationText : ''),
            canSelectDefault : !terms || !terms.isFallbackTerms ? true : false
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    validateName = (val: string) => v.validate(val, 'name', [v.required], []);
    validateConfirmTermsText = (val: string) => v.validate(val, 'confirmTermsText', [v.required], []);
    validateTerms = (val: string) => v.validate(val, 'terms', [], []);

    termsAndConditionsChanged = (val: string) => this.setState({ termsAndConditions: this.validateTerms(val) });

    componentDidUpdate(prevProps: EditBookingTermsProps) {
        const { isSavingBookingTerms: prevIsSavingBookingTerms } = prevProps;
        const { isSavingBookingTerms, bookingTermsSaveError, closeModal } = this.props;

        // Close modal overlay if diary note save is complete
        if (prevIsSavingBookingTerms && !isSavingBookingTerms && !bookingTermsSaveError) {
            closeModal();
        }
    }

    save = () => {
        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {
            const { venueId, terms, saveBookingTerms } = this.props;
            const { name, isFallbackTerms, termsAndConditions, confirmTermsText } = this.state;

            const bookingTermsId = terms ? terms.id : null;
            saveBookingTerms(
                bookingTermsId,
                venueId,
                name.value,
                isFallbackTerms,
                termsAndConditions.value,
                confirmTermsText.value,
            );
        }
    }

    render() {
        const { closeModal, isSavingBookingTerms, bookingTermsSaveError } = this.props;
        const { name, isFallbackTerms, termsAndConditions, confirmTermsText, canSelectDefault } = this.state;
        const { t } = this.context;

        const message = (bookingTermsSaveError) ? <ApiError error={bookingTermsSaveError} /> : null;

        return (
            <form className='data-form' onSubmit={defaultFormHandler} autoComplete='off'>
                <div>

                    <ct.TextBox id='name' labelKey='Global:name' placeholderKey='Global:name' value={name} callback={val => this.setState({ name: this.validateName(val) })} />

                    <ct.Checkbox id='isFallbackTerms' labelKey='VenueForm:defaultTerms' value={ct.asFormValue('isFallbackTerms', isFallbackTerms)} disabled={!canSelectDefault} callback={val => this.setState({ isFallbackTerms: val })} />

                    <h4>{t('VenueForm:termsAndConditions')}</h4>
                    <ct.HtmlInput id='termsTemplate' labelKey='' value={termsAndConditions} callback={this.termsAndConditionsChanged} modules={quillStandardToolbar} quillClassName='quill-dark' inlineStyles={true} />
                    <ct.TextBox id='confirmTermsText' labelKey='VenueForm:confirmTermsText' placeholderKey='VenueForm:confirmTermsText' helpTextKey='VenueForm:confirmTermsTextHelp' value={confirmTermsText} callback={val => this.setState({ confirmTermsText: this.validateConfirmTermsText(val) })} />

                    {message}

                    <div className='btn-toolbar' style={({ marginTop: '20px' })}>
                        <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)} disabled={isSavingBookingTerms}>{t('Global:save')}</button>
                        <button className='btn btn-basic' onClick={e => clickHandler(e, closeModal)} disabled={isSavingBookingTerms}>{t('Global:cancel')}</button>
                    </div>
                </div>
            </form>
        );
    }
}


const matStateToProps = (state: ApplicationState) => ({
    isSavingBookingTerms: state.termsAndConditions.isSavingBookingTerms,
    bookingTermsSaveError: state.termsAndConditions.bookingTermsSaveError,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    saveBookingTerms: bindActionCreators(TermsActions.actionCreators.saveBookingTerms, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    matStateToProps,
    mapDispatchToProps
)(EditBookingTerms);
