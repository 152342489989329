
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'

import * as api from '../../../store/apiClient';
import * as VenueActions from '../../../store/pages/venues/actions';
import * as LoginActions from '../../../store/pages/login/actions';
import * as ModalActions from '../../../store/global/modal/actions';
import * as ProductActions from '../../../store/pages/products/actions';
import * as ProductCategoryActions from '../../../store/pages/productCategories/actions';
import * as PaymentMethodActions from '../../../store/pages/paymentMethods/actions';
import * as ActivityFormatActions from '../../../store/pages/activityFormats/actions';
import * as CustomerCategoryActions from '../../../store/pages/customerCategories/actions';

import { ApplicationState } from '../../../store';
import { isNullOrEmpty, parseLocalDateTime } from '../../../utils/util';
import Loading from '../../global/loading';
import Pagination from '../../global/pagination';
import PointOfSalePanel from '../pointOfSale/pointOfSalePanel';
import DuePayment from './duePayment'
import { DateFormat, TimeFormat, Venue } from '../../../store/pages/venues/types';
import { Product, ProductType } from '../../../store/pages/products/types';
import { ProductCategory } from '../../../store/pages/productCategories/types';
import { PaymentMethod } from '../../../store/pages/paymentMethods/types';
import { ActivityFormat } from '../../../store/pages/activityFormats/types';
import { CustomerCategory } from '../../../store/pages/customerCategories/types';
import { TaxRate } from '../../../store/pages/taxRates/types';
import { Fee } from '../../../store/pages/fees/types';
import { Promotion } from '../../../store/pages/promotions/types';
import { VoucherProduct } from '../../../store/pages/vouchers/types';
import { Payment } from './types'
import { MembershipType } from '../../../store/pages/memberships/types';

interface LocalProps { }

interface LocalState {
    selectedVenueId: string;
    venue?: Venue;
    products: Product[];
    productsLoading: boolean;
    productCategories: ProductCategory[];
    productCategoriesLoading: boolean;
    paymentMethods: PaymentMethod[];
    paymentMethodsLoading: boolean;
    activityFormats: ActivityFormat[];
    activityFormatsLoading: boolean;
    customerCategories: CustomerCategory[];
    customerCategoriesLoading: boolean;
    taxRates: TaxRate[];
    taxRatesLoading: boolean;
    fees: Fee[];
    feesLoading: boolean;
    promotions: Promotion[];
    vouchers: VoucherProduct[];
    membershipTypes: MembershipType[];
}

interface Actions {
    loadVenues: () => void;
    logout: () => void;
    loadProducts: () => void;
    loadProductCategories: () => void;
    loadPaymentMethods: () => void;
    loadActivityFormats: () => void;
    loadCustomerCategories: () => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
}

type DuePaymentsProps = LocalState & Actions & LocalProps;

interface DuePaymentsResult {
    duePayments: Payment[];
    currentPage: number;
    maxPage: number;
}

interface DuePaymentsState {
    isLoading: boolean;
    payments: Payment[];
    pageNumber: number;
    maxPage: number;
    pageSize: number;
    error: string | null;
}

class DuePayments extends React.Component<DuePaymentsProps, DuePaymentsState> {
    constructor(props: DuePaymentsProps) {
        super(props);

        this.state = { isLoading: false, pageNumber: 1, pageSize: 25, maxPage: 0, payments: [], error: null };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        const { selectedVenueId } = this.props;

        if (!isNullOrEmpty(selectedVenueId)) {
            const { pageNumber, pageSize } = this.state;
            this.loadPayments(pageNumber, pageSize);
        }
    }

    componentDidUpdate(prevProps: DuePaymentsProps) {
        if (prevProps.selectedVenueId !== this.props.selectedVenueId) {
            const { pageNumber, pageSize } = this.state;
            this.loadPayments(pageNumber, pageSize);
        }
    }

    setPage = (pageNumber: number) => this.loadPayments(pageNumber, this.state.pageSize);

    setPageSize = (pageSize: number) => this.loadPayments(this.state.pageNumber, pageSize);

    loadPayments = (pageNumber: number, pageSize: number) => {
        this.setState({ isLoading: true, pageNumber: pageNumber, pageSize: pageSize });

        const { selectedVenueId, logout } = this.props;

        const url = `api/v1/payments/${selectedVenueId}/due/?pageNumber=${pageNumber}&pageSize=${pageSize}`;
        api.getWithAuth<DuePaymentsResult>(url, logout)
            .subscribe(
                resp => {
                    const response = resp as DuePaymentsResult;
                    this.setState((s, p) => {
                        // When re-loading the page for a client with multiple venues, this function can be called twice in sucession.
                        // We need to make sure that we only take the result for the most recently requested venue
                        return p.selectedVenueId === selectedVenueId
                            ? { payments: response.duePayments.map(p => ({ ...p, eventDate: parseLocalDateTime(p.eventDate), paymentDueDate: parseLocalDateTime(p.paymentDueDate) })), maxPage: response.maxPage, isLoading: false }
                            : { payments: s.payments, maxPage: s.maxPage, isLoading: s.isLoading };
                    });
                },
                e => {
                    // TODO: Handle refresh token error & logout
                    this.setState({ error: e.toString(), payments: [], maxPage: 0, pageSize: 0, pageNumber: 0, isLoading: false });
                }
            );
    }

    billUpdated = () => {
        this.props.closeModal();
        this.loadPayments(this.state.pageNumber, this.state.pageSize);
    }

    payBill = (payment: Payment) => {
        const { venue, products, productCategories, paymentMethods, activityFormats, customerCategories, fees, taxRates, promotions, vouchers, membershipTypes, closeModal, showModal, logout } = this.props;

        if (!venue)
            return;

        showModal(<PointOfSalePanel venue={venue} products={products.filter(p => p.venueId === venue.id || p.type === ProductType.Voucher || p.type === ProductType.Membership)} eventProducts={[]} productCategories={productCategories} activityFormats={activityFormats.filter(a => a.venueId === venue.id)} paymentMethods={paymentMethods} customerCategories={customerCategories} fees={fees} taxRates={taxRates} promotions={promotions} booking={null} billInfo={payment.billId} paymentId={payment.paymentId} posSessionComplete={this.billUpdated} vouchers={vouchers} membershipTypes={membershipTypes} logout={logout} />, 'PointOfSalePanel', true);
    }

    render() {
        const { t } = this.context;
        const { maxPage, pageSize, pageNumber} = this.state;

        return (
            <div className='sub-section-wrapper'>
                <section className='due-payments-list'>
                    <header className='section-header'>
                        <div className='page-heading'>
                            <h3 className='due-payments-title'>{t('DuePayments:heading')}</h3>
                        </div>
                    </header>
                    <div className='sub-section-panel sub-section-panel-240px'>
                        {this.state.isLoading ? <Loading /> : this.renderPayments()}
                        <Pagination maxPage={maxPage} pageSize={pageSize} pageNumber={pageNumber} setPage={this.setPage} setPageSize={this.setPageSize} />
                    </div>
                </section>
            </div>
        );
    }
       

    renderPayments = () => {
        const { selectedVenueId, venue } = this.props;

        const today = new Date().datePart();
        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;

        const payments = this.state.payments.map((pymt: Payment) => {
            return <DuePayment key={pymt.paymentId} pymt={pymt} selectedVenueId={selectedVenueId} today={today} timeFormat={timeFormat} dateFormat={dateFormat} payBill={this.payBill} />
        });

        return <ul className='list-unstyled list-separator'>
            {payments}
        </ul>;
    }
}


const matStateToProps = (state: ApplicationState) => {
    const venueId = state.venues.selectedVenueId;
    return {
        selectedVenueId: state.venues.selectedVenueId,
        venue: state.venues.venues.find(v => v.id === state.venues.selectedVenueId),
        products: state.products.products.filter(x => !x.archived),
        productsLoading: state.products.isLoading,
        productCategories: state.productCategories.productCategories.filter(c => c.venueId === venueId),
        productCategoriesLoading: state.productCategories.isLoading,
        paymentMethods: state.paymentMethods.paymentMethods.filter(x => x.venueId === venueId && !x.archived),
        paymentMethodsLoading: state.paymentMethods.isLoading,
        activityFormats: state.activityFormats.activityFormats,
        activityFormatsLoading: state.activityFormats.isLoading,
        customerCategories: state.customerCategories.customerCategories,
        customerCategoriesLoading: state.customerCategories.isLoading,
        taxRates: state.taxRates.taxRates,
        taxRatesLoading: state.taxRates.isLoading,
        fees: state.fees.fees.filter(f => f.venueId === venueId),
        feesLoading: state.fees.isLoading,
        promotions: state.promotions.promotions,
        vouchers: state.vouchers.voucherProducts,
        membershipTypes: state.memberships.membershipTypes
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadVenues: bindActionCreators(VenueActions.actionCreators.loadVenues, dispatch),
    logout: bindActionCreators(LoginActions.actionCreators.logout, dispatch),
    loadProducts: bindActionCreators(ProductActions.actionCreators.loadProducts, dispatch),
    loadProductCategories: bindActionCreators(ProductCategoryActions.actionCreators.loadProductCategories, dispatch),
    loadPaymentMethods: bindActionCreators(PaymentMethodActions.actionCreators.loadPaymentMethods, dispatch),
    loadActivityFormats: bindActionCreators(ActivityFormatActions.actionCreators.loadActivityFormats, dispatch),
    loadCustomerCategories: bindActionCreators(CustomerCategoryActions.actionCreators.loadCustomerCategories, dispatch),
    showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
});

export default connect(
    matStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps        // Selects which action creators are merged into the component's props
)(DuePayments);
