
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import moment from 'moment';
import { ReservationType } from '../../../store/pages/diary/types';
import { ActivityFormat } from '../../../store/pages/activityFormats/types';
import { isNullOrEmpty, clickHandler, formatMoment } from '../../../utils/util';
import { Resource, buildResourceSelectionId, deconstructResourceSelectionId } from '../../../store/pages/resources/types';
import { ITranslationContext } from '../../../translations';
import { ReservationBooking } from './types';
import ReservationBookingSelections from './reservationBookingSelections';
import { Product } from '../../../store/pages/products/types';
import { getActivityProducts } from './helpers';
import { CustomerCategory } from '../../../store/pages/customerCategories/types';
import ReservationTypePanel from './reservationTypePanel';
import { Time } from '../../../store/global/types';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';
import { MembershipType } from '../../../store/pages/memberships/types';
import { stringComparer } from '../../../utils/comparers';

interface ReservationDetailsProps {
    reservationKey: string;
    resources: Resource[];
    activityFormats: ActivityFormat[];
    products: Product[];
    customerCategories: CustomerCategory[];
    membershipTypes: MembershipType[];
    isNew: boolean;
    isMultiScheduleReservation: boolean;
    resourceSelectionId: ct.FormValue<string>;
    resourceSelectionChanged: (resourceSelectionId: ct.FormValue<string>) => void;
    type: ct.FormValue<ReservationType>;
    typeChanged: (type: ct.FormValue<ReservationType>) => void;
    times: ct.FormValue<ct.DateRange>;
    timesChanged: (times: ct.FormValue<ct.DateRange>) => void;
    activityFormatId: ct.FormValue<string>;
    activityFormatVariationId: ct.FormValue<string>;
    activityFormatChanged: (activityFormatId: ct.FormValue<string>, activityFormatVariationId: ct.FormValue<string>, times: ct.FormValue<ct.DateRange>) => void;
    maxParticipants: ct.FormValue<number>;
    maxParticipantsChanged: (maxParticipants: ct.FormValue<number>, activityFormatVariationId: ct.FormValue<string>, times: ct.FormValue<ct.DateRange>) => void;
    reservationSelections: ReservationBooking[];
    reservationSelectionsChanged: (reservationSelections: ReservationBooking[]) => void;
    notes: ct.FormValue<string>;
    shouldShowNotes: boolean;
    showNotes: () => void;
    notesChanged: (val: ct.FormValue<string>) => void;
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
    membershipTypeId: ct.FormValue<string>;
    membershipTypeSelectionChanged: (mt: string) => void;
}

const ReservationDetailsForm = (props: ReservationDetailsProps, context: ITranslationContext) => {

    const onResourceChanged = (resourceSelectionId: string) => {
        const { resourceId, resourceConfigId } = deconstructResourceSelectionId(resourceSelectionId);

        const resource = props.resources.find(r => r.id === resourceId);

        if (resource) {
            const resourceConfigurationId = !isNullOrEmpty(resourceConfigId)
                ? resourceConfigId
                : resource.configurations && resource.configurations.length > 0 ? resource.configurations[0].id : null;
            props.resourceSelectionChanged(validateResourceSelection(buildResourceSelectionId(resourceId, resourceConfigurationId)));
        }
    }

    const findVariation = (variationId: string, activityFormat?: ActivityFormat) => {
        if (activityFormat && activityFormat.variations.length > 0) {
            const variation = activityFormat.variations.find(v => v.id === variationId);
            return variation ? variation : activityFormat.variations[0];
        }
        return null;
    }

    const onActivityFormatChanged = (val: string) => {
        const { activityFormats } = props;
        const activityFormat = activityFormats.find(f => f.id === val);

        const variation = findVariation(props.activityFormatVariationId.value, activityFormat);
        var variationId = variation ? variation.id : '';

        const firstSchedule = variation ? variation.schedule[0] : null;

        props.activityFormatChanged(validateActivityFormat(activityFormat ? activityFormat.id : ''),
            validateActivityFormatVariation(variationId),
            validateTimes(calculateEndTime(props.times.value, firstSchedule ? firstSchedule.runningTime : null)));
    }

    const onActivityFormatVariationChanged = (val: string) => {
        const activityFormatId = props.activityFormatId.value;
        const activityFormat = props.activityFormats.find(f => f.id === activityFormatId);
        const variation = findVariation(val, activityFormat);
        var variationId = variation ? variation.id : '';

        const firstSchedule = variation ? variation.schedule[0] : null;

        props.activityFormatChanged(validateActivityFormat(activityFormatId), validateActivityFormatVariation(variationId), validateTimes(calculateEndTime(props.times.value, firstSchedule ? firstSchedule.runningTime : null)));
    }

    const onReservationTypeChanged = (val: ReservationType) => props.typeChanged(validateReservationType(val));

    const onMaxParticipantsChanged = (val: number) => {
        const { activityFormats } = props;
        const newVal = validateMaxParticipants(val);
        const { times, activityFormatId, activityFormatVariationId } = props;
        const activityFormat = activityFormats.find(f => f.id === activityFormatId.value);
        const formatVariation = activityFormat && activityFormat.variations.length > 0 ? activityFormat.variations.find(v => v.id === activityFormatVariationId.value) : null;

        let newActivityFormatVariationId = props.activityFormatVariationId;
        let newTimes = props.times;

        // If number of participants is not valid for current activity, try to select a more relevant one
        if (newVal.isValid && activityFormat && formatVariation && (val < formatVariation.minParticipants || val > formatVariation.maxParticipants)) {
            const possibleVariations = activityFormat.variations.filter(v => v.minParticipants <= val && v.maxParticipants >= val).sort((a, b) => a.minParticipants = b.minParticipants);
            if (possibleVariations.length > 0) {
                const newActivityFormatVariation = possibleVariations[0];
                newActivityFormatVariationId = validateActivityFormatVariation(newActivityFormatVariation.id);
                const firstSchedule = newActivityFormatVariation ? newActivityFormatVariation.schedule[0] : null;

                newTimes = validateTimes(calculateEndTime(times.value, firstSchedule ? firstSchedule.runningTime : null));
            }
        }

        props.maxParticipantsChanged(newVal, newActivityFormatVariationId, newTimes);
    }

    const onTimesChanged = (value: ct.DateRange) => {
        let { from: newStart, to: newEnd } = value;
        if (newStart && props.times.value.from) {
            // If the start time has changed but the end time hasn't then adjust the end based on the change to start
            if (newEnd && props.times.value.to && newEnd === props.times.value.to) {
                newEnd = newEnd.subtract(props.times.value.from.diff(newStart));
            }
        }

        props.timesChanged(validateTimes({ from: newStart, to: newEnd }));
    }

    const onNotesChanged = (notes: string) => props.notesChanged(validateNotes(notes));

    const calculateEndTime = (currentTimes: ct.DateRange, duration: Time | null) => {
        const { from } = currentTimes;
        if (!from || !duration) {
            return currentTimes;
        }

        const endTime = moment(from);
        endTime.add(moment.duration(duration.getHours(), 'hours'));
        endTime.add(moment.duration(duration.getMinutes(), 'minutes'));
        return { from: from, to: endTime };
    }

    const validateResourceSelection = (val: string) => v.validate(val, 'resourceSelection', [v.required], []);
    const validateReservationType = (val: ReservationType) => v.validate(val, 'reservationType', [v.required], []);
    const validateTimes = (val: ct.DateRange) => v.validate(val || moment.utc(), 'startTime', [v.required, () => val && val.from && val.to && val.from > val.to ? 'ReservationDetailsPage:dateRangeOutOfOrder' : undefined], []);
    const validateActivityFormat = (val: string) => v.validate(val, 'activityFormat', [v.required], []);
    const validateActivityFormatVariation = (val: string) => v.validate(val, 'activityFormatVariation', [v.required], []);
    const validateMaxParticipants = (val: number) => v.validate(val, 'maxParticipants', [], []);
    const validateNotes = (val: string) => v.validate(val, 'notes', [], []);

    const renderResource = () => {
        const { isNew, resourceSelectionId, resources } = props;

        // If this is an existing reservation, don't allow the resource to be changed
        const resourceSelections = resources.filter(r => !r.archived).reduce<ct.SelectOption[]>((selections, r) => selections.concat(r.configurations && r.configurations.length > 0 ? r.configurations.map(c => ({ key: buildResourceSelectionId(r.id, c.id), name: c.name })) : [{ key: r.id, name: r.shortName }]), []);
        const selectedResource = resourceSelections.find(r => r.key === resourceSelectionId.value);

        const getBgCol = (opt: ct.SelectOption) => resources.filter(r => r.id === opt.key).map(r => r.colour).concat(['transparent'])[0];

        if (!isNew && selectedResource) {
            return <h4>{selectedResource.name}</h4>
        } else {
            return <ct.Select
                id='resourceSelection'
                labelKey='ReservationDetailsPage:resource'
                value={resourceSelectionId}
                callback={onResourceChanged}
                options={resourceSelections}
                renderOption={o => <div key={o.key}><div style={({ width: '8px', backgroundColor: getBgCol(o), marginRight: '6px', display: 'inline-block' })}>&nbsp;</div>{o.name}</div>}
                />;
        }
    }

    const filterActivities = (activityFormats: ActivityFormat[], resourceId: string, resourceConfigId: string, activityFormatId: string) =>
        activityFormats.filter(f => f.id === activityFormatId || (f.resources.some(x => x.resourceId === resourceId && (x.configurationIds.length === 0 || x.configurationIds.some(c => c === resourceConfigId)) && (!f.archived))));

    const renderActivityFormat = () => {

        const { t } = context;
        const { isNew, isMultiScheduleReservation, activityFormats, resourceSelectionId, activityFormatId, activityFormatVariationId } = props;
        const { resourceId, resourceConfigId } = deconstructResourceSelectionId(resourceSelectionId.value);

        const filteredActivities = filterActivities(activityFormats, resourceId, resourceConfigId, activityFormatId.value);
        const sortedActivities = filteredActivities.sort((a, b) => {
            const aName = a.name.trim();
            const bName = b.name.trim();
            if (aName > bName) return 1;
            else if (bName > aName) return -1;
            else return 0;
        });

        const activityFormatOptions = [{ key: '', name: t('EventReservations.selectActivityFormat') }].concat(sortedActivities.map(f => ({ key: f.id, name: f.name })));
        const activityFormat = filteredActivities.find(f => f.id === activityFormatId.value);
        const showVariations = activityFormat && activityFormat.variations.length > 1;
        const getActivityColour = (opt: ct.SelectOption) => filteredActivities.filter(af => af.id === opt.key).map(af => af.colour).concat(['transparent'])[0];
        const variation = activityFormat ? activityFormat.variations.find(v => v.id === activityFormatVariationId.value) : null;

        if (!isNew && isMultiScheduleReservation) {
            return <div>
                <div className='alert alert-warning'>{t('ReservationDetailsPage:cantChangeMultiScheduleActivity')}</div>
                <div>
                    <label>{t('ReservationDetailsPage:activityFormat')}</label>
                    <div>{activityFormat ? activityFormat.name : ''}{showVariations && variation ? ` - [${variation.minParticipants} - ${variation.maxParticipants}]` : ''}</div>
                </div>
            </div>
        }      

        return <div className='row' style={({ maxWidth: '430px' })}>
            <div className={`col-md-${showVariations ? 6 : 12}`}>
                <ct.Select
                    id='activityFormat'
                    labelKey='ReservationDetailsPage:activityFormat'
                    value={({ ...activityFormatId, value: activityFormatId.value.toString() })}
                    callback={onActivityFormatChanged}
                    options={activityFormatOptions}
                    renderOption={o => <div key={o.key}><div style={({ width: '8px', backgroundColor: getActivityColour(o), marginRight: '6px', display: 'inline-block' })}>&nbsp;</div>{o.name}</div>}
                    />
            </div>
            {showVariations && activityFormat ? renderActivityFormatVariations(activityFormat, activityFormatVariationId) : null}
        </div>
    }

    const renderActivityFormatVariations = (activityFormat: ActivityFormat, activityFormatVariationId: ct.FormValue<string>) => {
        const options = activityFormat.variations.map(v => ({ key: v.id, name: `${v.minParticipants} - ${v.maxParticipants}` }));
        return <div className='col-md-6'>
            <ct.Select id='activityFormat' labelKey='ReservationDetailsPage:activityFormatVariation' value={({ ...activityFormatVariationId, value: activityFormatVariationId.value.toString() })} callback={onActivityFormatVariationChanged} options={options} />
        </div>
    }

    const renderTimes = () => {
        const { isNew, isMultiScheduleReservation, times, timeFormat, dateFormat } = props;
        const { t } = context;

        if (!isNew && isMultiScheduleReservation) {
            return <div>
                <label>{t('ReservationDetailsPage:date')}</label>
                <div>{times.value.from ? formatMoment(times.value.from, dateFormat) : null} {times.value.from && times.value.to && times.value.from.format("YYYY-MM-DD") !== times.value.to.format("YYYY-MM-DD") ? ` - ${formatMoment(times.value.to, dateFormat)}`: null}</div>
                <ct.DateRangePicker id='dateRange' labelKey='ReservationDetailsPage:dateRange' value={times} dateFormat={false} timeFormat={timeFormat} viewMode='time' callback={onTimesChanged} />
            </div>
        }

        return <ct.DateRangePicker id='dateRange' labelKey='ReservationDetailsPage:dateRange' value={times} dateFormat={dateFormat} timeFormat={timeFormat} viewMode='time' callback={onTimesChanged} />;
    }

    const renderMembershipTypes = () => {
        const { t } = context;
        const { membershipTypeId, membershipTypes, membershipTypeSelectionChanged } = props;
        const membershipTypeOptions = [{ key: '-1', name: t('ReservationDetailsPage:noMemberRestriction')}].concat(membershipTypes.filter(mt => !mt.archived).sort((m1,m2) => stringComparer(m1.name, m2.name)).map(mt => ({ key: mt.id, name: mt.name })))
        return <ct.Select id='membershipTypeId' labelKey='ReservationDetailsPage:memberRestriction' value={membershipTypeId} options={membershipTypeOptions} callback={membershipTypeSelectionChanged} />
    }

    const renderNotes = () => {
        const { notes, shouldShowNotes, showNotes } = props;
        const { t } = context;
        if (shouldShowNotes) {
            return <ct.TextArea id='notes' labelKey='ReservationDetailsPage:notes' placeholderKey='ReservationDetailsPage:notes' rows={4} value={notes} callback={onNotesChanged} />;
        } else {
            return <button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, showNotes)}>{t('EventReservations:addActivityNotes')}</button>
        }
    }

    const renderFormFields = () => {
        return <>
            <div className='row'>
                <div className='col-md-12'>
                    {renderActivityFormat()}
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    {renderTimes()}
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    {renderMembershipTypes()}
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    {renderNotes()}
                </div>
            </div>
        </>
    }

    const renderReservationType = () => {
        const { type, maxParticipants } = props;
        return <ReservationTypePanel reservationType={type.value} maxParticipants={maxParticipants} reservationTypeChanged={onReservationTypeChanged} maxParticipantsChanged={onMaxParticipantsChanged} />
    }

    const renderBookings = () => {
        const { reservationKey, reservationSelections, activityFormats, products, customerCategories, activityFormatId, reservationSelectionsChanged } = props;

        const activityFormat = activityFormats.find(f => f.id === activityFormatId.value);
        const mappedProducts = activityFormat ? getActivityProducts(activityFormat, null, null, products, customerCategories) : [];
        const filteredSelections = reservationSelections.filter(s => s.reservationKey === reservationKey);

        return <ReservationBookingSelections reservationSelections={filteredSelections} products={mappedProducts} customerCategories={customerCategories} selectionsChanged={reservationSelectionsChanged} />
    }

    const showBookings = () => props.reservationSelections.length > 0 && !props.isMultiScheduleReservation

    return <div className='reservation-details-form'>
        <div className='row'>
            <div className='col-md-12'>
                {renderResource()}
            </div>
        </div>

        {renderFormFields()}

        {renderReservationType()}

        {showBookings() ? renderBookings() : null}
    </div>
}

ReservationDetailsForm.contextTypes = {
    t: PropTypes.func
}

export default ReservationDetailsForm;
