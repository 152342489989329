
import * as React from 'react';
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as api from '../../../store/apiClient';
import * as analyticsApi from '../../../store/pages/analytics/api';

import * as LoginActions from '../../../store/pages/login/actions';
import * as ModalActions from '../../../store/global/modal/actions';

import { ApplicationState } from '../../../store';
import ReportSelection from './reportSelection';
import { AnalyticsComparisonPeriodSelection, AnalyticsPeriod, AnalyticsPeriodSelection, AnalyticsReport, AnalyticsReportType, ComparisonPeriod, defaultAllProductsReportSettings, defaultRevenueReportSettings} from '../../../store/pages/analytics/types';
import RevenueReportPage from './revenue/revenueReportPage';
import { Venue } from '../../../store/pages/venues/types';
import ProductsReportPage from './products/productsReportPage';
import SaveReportOverlay from './saveReportOverlay';

interface LocalProps { }

interface MappedReduxState {
    venueId: string;
    venue: Venue | undefined;
}

interface MappedReduxActions {
    logout: () => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
}

type AnalyticsPageProps = MappedReduxState & MappedReduxActions & LocalProps;

interface AnalyticsPageState {
    reports: AnalyticsReport[];
    selectedReport: AnalyticsReport | null;
    loading: boolean;
    loadError: api.ApiError | null;
    saving: boolean;
    saveError: api.ApiError | null;
}

class AnalyticsPage extends React.Component<AnalyticsPageProps, AnalyticsPageState> {
    constructor(props: AnalyticsPageProps) {
        super(props);

        this.state = { selectedReport: null, reports: [], loading: false, loadError: null, saving: false, saveError: null };
    }

    static contextTypes = {
        t: PropTypes.func
    }    

    componentDidMount() {
        this.loadReports();
    }

    loadReports = () => {
        const { logout } = this.props;
        this.setState({ loading: true, loadError: null }, () => {
            analyticsApi.loadReports(logout)
                .subscribe(r => this.setState({ reports: r, loading: false, loadError: null }), err => this.setState({ loading: false, loadError: err }))
        });
    }

    confirmSaveReport = (report: AnalyticsReport, saveCompleteAction: () => void) => {
        const { logout } = this.props;
        this.setState({ saving: true, saveError: null }, () => {
            analyticsApi.saveAnalyticsReport(report, logout)
                .subscribe(() => this.setState({ saving: false, saveError: null, selectedReport: report }, () => { saveCompleteAction(); this.loadReports(); }), err => this.setState({ saving: false, saveError: err }));
        })
    }

    saveReport = (reportId: number | null, reportType: AnalyticsReportType, period: AnalyticsPeriod, comparisonPeriod: ComparisonPeriod, deselectedVenues: string[], settings: any) => {
        const { selectedReport } = this.state;

        if (selectedReport === null) {
            // no current report!
        } else if (selectedReport.id !== reportId || selectedReport.type !== reportType) {
            // not saving the current report!
        } else {
            this.props.showModal(<SaveReportOverlay report={{ ...selectedReport, period: period, comparisonPeriod: comparisonPeriod, deselectedVenues: deselectedVenues, settings: settings }} save={this.confirmSaveReport} close={this.props.closeModal} />, 'Save analytics report')
        }
    }

    addReport = (analyticsReportType: AnalyticsReportType) => {
        const { venue } = this.props;
        let report: AnalyticsReport | null = null;

        if (!venue) {
            report = null;
        } else {
            const defaultPeriod = AnalyticsPeriod.create(AnalyticsPeriodSelection.YearToDate, venue.firstDayOfWeek);
            const defaultComparisonPeriod = ComparisonPeriod.create(defaultPeriod, AnalyticsComparisonPeriodSelection.PreviousPeriod);
            switch (analyticsReportType) {
                case AnalyticsReportType.Revenue:
                    report = {
                        id: null,
                        name: '',
                        deselectedVenues: [],
                        settings: defaultRevenueReportSettings,
                        type: AnalyticsReportType.Revenue,
                        period: defaultPeriod,
                        comparisonPeriod: defaultComparisonPeriod
                    };
                    break;
                case AnalyticsReportType.Products:
                    report = {
                        id: null,
                        name: '',
                        deselectedVenues: [],
                        settings: defaultAllProductsReportSettings,
                        type: AnalyticsReportType.Products,
                        period: defaultPeriod,
                        comparisonPeriod: defaultComparisonPeriod
                    };
                    break;
            }
        }
        this.setState({ selectedReport: report  })
    }
    
    render() {
        const { venue, venueId, logout } = this.props;
        const { reports, loading, selectedReport } = this.state;
        const { t } = this.context;

        let content = null;
        if (!selectedReport || !venue) {
        } else {
            switch (selectedReport.type) {
                case AnalyticsReportType.Revenue:
                    content = <RevenueReportPage report={selectedReport} venue={venue} logout={logout} save={(id, period, comparison, deselectedVenues, settings) => this.saveReport(id, selectedReport.type, period, comparison, deselectedVenues, settings)} />
                    break;
                case AnalyticsReportType.Products:
                    content = <ProductsReportPage report={selectedReport} venue={venue} logout={logout} save={(id, period, comparison, deselectedVenues, settings) => this.saveReport(id, selectedReport.type, period, comparison, deselectedVenues, settings)} />
                    break;
                default:
                    content = <div className='alert alert-danger'>{t('AnalyticsPage:unknownReportType', { reportType: AnalyticsReportType[selectedReport.type] })}</div>
                    break;
            }
        }

        return <div className='analytics-page-content'>
            <div className='analytics-page-report-selection'><ReportSelection venueId={venueId} reports={reports} loading={loading} addReport={this.addReport} viewReport={r => this.setState({ selectedReport: r})} /></div>
            <div className='analytics-page-report-content'>{content}</div>
        </div>
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    venueId: state.venues.selectedVenueId,
    venue: state.venues.venues.filter(v => v.id === state.venues.selectedVenueId)[0]
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    logout: bindActionCreators(LoginActions.actionCreators.logout, dispatch),
    showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
});

// Wire up the React component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsPage);

