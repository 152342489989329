
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../../global/controls';

import { Product, ProductPricingMode, ProductType } from '../../../../store/pages/products/types';
import ProductForm from './productForm';
import { ProductCategory } from '../../../../store/pages/productCategories/types';
import { TaxRate } from '../../../../store/pages/taxRates/types';
import Loading from '../../../global/loading';
import { clickHandler, isNullOrEmpty } from '../../../../utils/util';
import { ActivityFormat } from '../../../../store/pages/activityFormats/types';
import { Link } from 'react-router-dom';


interface ProductListProps {
    venueId: string;
    products: Product[];
    categories: ProductCategory[];
    activityFormats: ActivityFormat[];
    taxRates: TaxRate[];
    isLoadingProducts: boolean;
    isLoadingProductCategories: boolean;
    isLoadingTaxRates: boolean;
    editProduct: () => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
}

interface ProductListState {
    showArchived: boolean;
    filter: string;
}

class ProductList extends React.Component<ProductListProps, ProductListState> {

    constructor(props: ProductListProps) {
        super(props);

        this.state = { showArchived: false, filter: '' }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    //private editProduct = (product: Product) => {
    //    this.showOverlay(product, false);
    //}

    //private addProduct = () => {
    //    this.showOverlay(null, true);
    //}

    //private showOverlay = (product: Product | null, addingNew: boolean) => {
    //    const { editProduct, showModal, venueId, activityFormats } = this.props;
    //    editProduct();
    //    showModal(<ProductForm isNew={addingNew} venueId={venueId} product={product} activityFormats={activityFormats} />, 'ProductForm');
    //}
    
    isLoading = () => this.props.isLoadingProducts || this.props.isLoadingProductCategories || this.props.isLoadingTaxRates;

    render() {
        const { venueId } = this.props;
        const { showArchived, filter } = this.state;
        const { t } = this.context;
        const body = this.isLoading() ? <Loading /> : this.renderProductList();

        return <section className='venue-item-list'>
            <header className='section-header'>
                <div className='section-actions'>
                    <label>
                        <input type='checkbox' checked={showArchived} onChange={e => this.setState({ showArchived: e.currentTarget.checked })} />
                        <span style={({ marginLeft: '10px' })}>{t('Global:showArchived')}</span>
                    </label>
                    <Link to={`/venue/${venueId}/product/new`} className='btn btn-info'>{this.context.t('ProductList:addProduct')}</Link>
                    {/*<button className='btn btn-info' onClick={e => clickHandler(e, this.addProduct)}>{t('ProductList:addProduct')}</button>*/}

                    <div className='pull-right'>
                        <label>{t('Global:filter')}</label>
                        <ct.TextBox style={{ display: 'inline-block', marginLeft: '8px' }} id='filter' labelKey='' value={ct.asFormValue('filter', filter)} callback={val => this.setState({ filter: val })} />
                    </div>
                </div>
            </header>
            <div className='at-panel sub-section-panel-240px'>
                {body}
            </div>
        </section>;
    }
    
    private renderProductList = () => {
        const { t } = this.context;
        const { showArchived, filter } = this.state;
        const { venueId, products, categories } = this.props;
        const lowerFilter = filter.toLowerCase();

        const filteredProducts = products
            .filter(p => p.type === ProductType.Standard && p.venueId === venueId && (showArchived || !p.archived) && (isNullOrEmpty(filter) || p.name.toLowerCase().includes(lowerFilter)))
            .map(p => {
                const basePrice = p.pricing.filter(p => p.effectiveFrom <= new Date() && (p.effectiveTo == null || p.effectiveTo >= new Date()) && p.applyPriceOnDays === 0)[0]
            return (
                <tr key={p.id}>
                    <td><Link to={`/venue/${venueId}/product/${p.id}`} className='btn btn-link btn-no-padding'>{isNullOrEmpty(p.name) ? <span>[{t('Global:edit')}]</span> : p.name}</Link></td>
                    {showArchived ? <td>{p.archived ? <span className='label label-danger'>{t('Global:archived')}</span> : null}</td> : null}
                    <td>{basePrice ? p.pricingMode === ProductPricingMode.Fixed ? basePrice.fixedPricing.map(p => p.price.toFixed(2))[0] : basePrice.unitPrice.toFixed(2) : null}</td>
                    <td>{t(`ProductPricingMode:${ProductPricingMode[p.pricingMode]}`)}</td>
                    <td>{p.taxRateName}</td>
                    <td><ul className='list-unstyled list-no-margin'>{categories.filter(c => c.venueId === venueId && p.categoryIds.indexOf(c.id) >= 0).map(c => <li key={c.id} style={this.buildCategoryStyle(c)}>{c.name}</li>)}</ul></td>
                    <td>{p.nominalCode}</td>
                    <td>{p.accountingCategory}</td>
                    <td>{p.accountingDepartment}</td>
                </tr>
            );
        });

        return (
            <table className='table table-condensed'>
                <thead>
                    <tr>
                        <th>{this.context.t('ProductList:nameHeading')}</th>
                        {showArchived ? <th>{t('Global:archived')}</th> : null}
                        <th>{this.context.t('Global:price')}</th>
                        <th>{this.context.t('ProductList:pricingModeHeading')}</th>
                        <th>{this.context.t('ProductList:taxRateHeading')}</th>
                        <th>{this.context.t('ProductList:categoryHeading')}</th>
                        <th>{this.context.t('ProductList:nominalCodeHeading')}</th>
                        <th>{this.context.t('ProductList:accountingCategoryHeading')}</th>
                        <th>{this.context.t('ProductList:accountingDepartmentHeading')}</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredProducts}
                </tbody>
            </table>
        );
    }

    buildCategoryStyle(category: ProductCategory) {
        return { display: 'inline-block', marginLeft: '5px', borderLeft: `4px solid ${category.colour}`, paddingLeft: '2px' };
    }

}

// Wire up the React component to the Redux store
export default ProductList;
