
import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch} from 'redux';
import * as PropTypes from 'prop-types'
import { ApplicationState } from '../../../store';
import { Venue } from '../../../store/pages/venues/types';
import * as CultureActions from '../../../store/global/cultures/actions';
import * as CurrencyActions from '../../../store/global/currencies/actions';
import * as VenueActions from '../../../store/pages/venues/actions';
import * as ModalActions from '../../../store/global/modal/actions';
import VenueForm from './venueForm';
import Loading from '../../global/loading';
import { clickHandler } from '../../../utils/util';
import { stringComparer } from '../../../utils/comparers';
import { Culture } from '../../../store/global/cultures/types';
import { Currency } from '../../../store/global/currencies/types';
import * as auth from '../../../utils/auth';

interface LocalProps { }

type RoutedLocalProps = LocalProps & RouteComponentProps<{}>;

interface MappedReduxState {
    isLoadingVenues: boolean;
    venues: Venue[];
    isLoadingCultures: boolean;
    cultures: Culture[];
    currencies: Currency[];
    isLoadingCurrencies: boolean;
}

interface Actions {
    loadVenues: () => void;
    editVenue: () => void;
    loadCultures: () => void;
    loadCurrencies: () => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
}

interface VenuesPageState {
    showArchived: boolean;
}

// At runtime, Redux will merge together...
type VenuesPageProps = MappedReduxState & Actions & RoutedLocalProps;

class VenuesPage extends React.Component<VenuesPageProps, VenuesPageState> {

    constructor(props: VenuesPageProps) {
        super(props);

        this.state = { showArchived: false }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        const { isLoadingCultures, cultures, loadCultures, currencies, loadCurrencies, isLoadingCurrencies } = this.props;
        if ((!cultures || cultures.length === 0) && !isLoadingCultures) {
            loadCultures();
        }

        if ((!currencies || currencies.length === 0) && !isLoadingCurrencies) {
            loadCurrencies();
        }
    }
    
    render() {
        const { showArchived } = this.state;
        const { t } = this.context;

        const body = this.props.isLoadingVenues ? <Loading /> : this.renderVenuesTable();

        return <section className='venuesPage'>
            <header className='section-header'>
                <div className='page-heading'>
                    <h1 className='venues_title'>{t('VenuesPage:title')}</h1>
                </div>
                <div className='section-actions right'>
                    <label>
                        <input type='checkbox' checked={showArchived} onChange={e => this.setState({ showArchived: e.currentTarget.checked })} />
                        <span style={({ marginLeft: '10px' })}>{t('Global:showArchived')}</span>
                    </label>
                    {auth.isSystemAdmin() ? <button className='btn btn-info' onClick={e => clickHandler(e, this.addVenue)}>{t('VenuesPage:addVenue')}</button> : null}
                </div>
            </header>
            {body}
        </section>;
    }

    private editVenue = (venue: Venue) => {
        this.showOverlay(venue, false);
    }

    private addVenue = () => {
        this.showOverlay(null, true);
    }

    private showOverlay = (venue: Venue|null, addingNew: boolean) => {
        this.props.editVenue();
        this.props.showModal(<VenueForm isNew={addingNew} venue={venue} />, 'VenueForm');
    }

    private renderVenuesTable = () => {
        const { showArchived } = this.state;

        const venues = this.props.venues.filter(v => showArchived || !v.archived).sort((v1, v2) => stringComparer(v1.name,v2.name)).map(v =>
            <li key={v.id} className='card'>
                <div className='card-content'>
                    <div className='card-header'>
                        <Link to={`/venue/${v.id}`} className='pull-left'><h2>{v.name}</h2></Link>
                        {v.archived ? <span className='pull-right label label-danger'>{this.context.t('Global:archived')}</span> : null}
                    </div>
                    <div className='card-body'></div>
                    <div className='card-footer'>
                        <button className='btn btn-link card-edit' onClick={e => clickHandler(e, () => this.editVenue(v))}>{this.context.t('Global:edit')}</button>
                    </div>
                </div>
            </li>
        );

        return <ul className='card-list'>{venues}</ul>;
    }
};


const matStateToProps = (state: ApplicationState) => {
    return ({
        venues: state.venues.venues,
        isLoadingVenues: state.venues.isLoading,
        cultures: state.cultures.cultures,
        isLoadingCultures: state.cultures.isLoading,
        currencies: state.currencies.currencies,
        isLoadingCurrencies: state.currencies.isLoading
    });
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadVenues: bindActionCreators(VenueActions.actionCreators.loadVenues, dispatch),
    editVenue: bindActionCreators(VenueActions.actionCreators.editVenue, dispatch),
    loadCultures: bindActionCreators(CultureActions.actionCreators.loadCultures, dispatch),
    loadCurrencies: bindActionCreators(CurrencyActions.actionCreators.loadCurrencies, dispatch),
    showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    matStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps                  // Selects which action creators are merged into the component's props
)(VenuesPage);
