
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as tct from './types';
import { TermsAndConditions } from './types';
import RegistrationTerms from '../../../components/pages/registrationKiosk/registrationTerms';
import { switchMap } from 'rxjs/operators';
import { isNullOrEmpty } from '../../../utils/util';
import { IStore } from '../..';



export type TermsAndConditionsActions = tct.LoadTermsAndConditions | tct.ReceivedTermsAndConditions | tct.LoadRegistrationTerms
    | tct.ReceivedRegistrationTerms | tct.LoadBookingTerms | tct.ReceivedBookingTerms | tct.SaveRegistrationTerms
    | tct.RegistrationTermsSaved | tct.RegistrationTermsSaveFailed | tct.SaveBookingTerms | tct.BookingTermsSaved | tct.BookingTermsSaveFailed
    | tct.LoadWebShopPurchaseTerms | tct.ReceivedWebShopPurchaseTerms | tct.SaveWebShopPurchaseTerms | tct.WebShopPurchaseTermsSaved | tct.WebShopPurchaseTermsSaveFailed
    | tct.LoadRegistrationVideos | tct.ReceivedRegistrationVideos;

export const actionCreators = {
    loadTermsAndConditions: (ids: string[]) => ({ type: tct.TermsAndConditionsActionTypes.LoadTermsAndConditions, ids: ids }),
    loadTermsAndConditionsComplete: (termsAndConditions: TermsAndConditions[], error: api.ApiError | null) => ({ type: tct.TermsAndConditionsActionTypes.ReceivedTermsAndConditions, termsAndConditions: termsAndConditions, error: error }),
    loadRegistrationTerms: () => ({ type: tct.TermsAndConditionsActionTypes.LoadRegistrationTerms }),
    loadRegistrationTermsComplete: (terms: RegistrationTerms[], error: api.ApiError | null) => ({ type: tct.TermsAndConditionsActionTypes.ReceivedRegistrationTerms, registrationTerms: terms, error: error }),
    loadBookingTerms: () => ({ type: tct.TermsAndConditionsActionTypes.LoadBookingTerms }),
    loadBookingTermsComplete: (terms: tct.BookingTerms[], error: api.ApiError | null) => ({ type: tct.TermsAndConditionsActionTypes.ReceivedBookingTerms, bookingTerms: terms, error: error }),
    saveRegistrationTerms: (registrationTermsId: string | null, venueId: string, name: string, isFallbackTerms: boolean, termsAndConditions: string, confirmTermsText: string, counterSignatoryTerms: string, separateCounterSignatoryTermsRequired: boolean, confirmCounterSignatoryTermsText: string, briefingPageContent: string, briefingPageConfirmationText: string, registrationVideoId: string | null, removeVideo: boolean) =>
        ({ type: tct.TermsAndConditionsActionTypes.SaveRegistrationTerms, registrationTermsId: registrationTermsId, venueId: venueId, name: name, isFallbackTerms: isFallbackTerms, termsAndConditions: termsAndConditions, confirmTermsText: confirmTermsText, separateCounterSignatoryTermsRequired: separateCounterSignatoryTermsRequired, counterSignatoryTerms: counterSignatoryTerms, confirmCounterSignatoryTermsText: confirmCounterSignatoryTermsText, briefingPageContent: briefingPageContent, briefingPageConfirmationText: briefingPageConfirmationText, registrationVideoId: registrationVideoId, removeVideo: removeVideo }),
    registrationTermsSaved: (venueId: string) => ({ type: tct.TermsAndConditionsActionTypes.RegistrationTermsSaved, venueId: venueId }),
    registrationTermsSaveFailed: (error: api.ApiError) => ({ type: tct.TermsAndConditionsActionTypes.RegistrationTermsSaveFailed, error: error }),
    saveBookingTerms: (bookingTermsId: string | null, venueId: string, name: string, isFallbackTerms: boolean, termsAndConditions: string, confirmTermsText: string) => ({ type: tct.TermsAndConditionsActionTypes.SaveBookingTerms, bookingTermsId: bookingTermsId, venueId: venueId, name: name, isFallbackTerms: isFallbackTerms, termsAndConditions: termsAndConditions, confirmTermsText: confirmTermsText }),
    bookingTermsSaved: (venueId: string) => ({ type: tct.TermsAndConditionsActionTypes.BookingTermsSaved, venueId: venueId }),
    bookingTermsSaveFailed: (error: api.ApiError) => ({ type: tct.TermsAndConditionsActionTypes.BookingTermsSaveFailed, error: error }),
    loadWebShopPurchaseTerms: () => ({ type: tct.TermsAndConditionsActionTypes.LoadWebShopPurchaseTerms }),
    loadWebShopPurchaseTermsComplete: (terms: tct.WebShopPurchaseTerms[], error: api.ApiError | null) => ({ type: tct.TermsAndConditionsActionTypes.ReceivedWebShopPurchaseTerms, webShopPurchaseTerms: terms, error: error }),
    saveWebShopPurchaseTerms: (webShopPurchaseTermsId: string | null, venueId: string, termsAndConditions: string, confirmTermsText: string) => ({ type: tct.TermsAndConditionsActionTypes.SaveWebShopPurchaseTerms, webShopPurchaseTermsId: webShopPurchaseTermsId, venueId: venueId, termsAndConditions: termsAndConditions, confirmTermsText: confirmTermsText }),
    webShopPurchaseTermsSaved: (venueId: string) => ({ type: tct.TermsAndConditionsActionTypes.WebShopPurchaseTermsSaved, venueId: venueId }),
    webShopPurchaseTermsSaveFailed: (error: api.ApiError) => ({ type: tct.TermsAndConditionsActionTypes.WebShopPurchaseTermsSaveFailed, error: error }),
    loadRegistrationVideos: (venueId: string) => ({ type: tct.TermsAndConditionsActionTypes.LoadRegistrationVideos, venueId: venueId }),
    loadRegistrationVideosComplete: (registrationVideos: tct.RegistrationVideo[], error: api.ApiError | null) => ({ type: tct.TermsAndConditionsActionTypes.ReceivedRegistrationVideos, registrationVideos: registrationVideos, error: error }),
}

interface ILoadTermsAndConditionsResponse {
    termsAndConditions: tct.TermsAndConditions[];
}

interface ILoadRegistrationTermsResponse {
    terms: RegistrationTerms[];
}

interface ILoadBookingTermsResponse {
    terms: tct.BookingTerms[];
}

interface ILoadRegistrationVideosResponse {
    registrationVideos: tct.RegistrationVideo[];
}

interface ILoadWebShopPurchaseTermsResponse {
    terms: tct.WebShopPurchaseTerms[];
}

export const handleClientChange = (store: IStore) => [actionCreators.loadRegistrationTerms, actionCreators.loadBookingTerms, actionCreators.loadWebShopPurchaseTerms]

const loadTermsAndConditions = (ids: string[]) => Observable.defer(() => api.getJson<ILoadTermsAndConditionsResponse>(`api/v1/termsAndConditions/find?${ids.map(i => `ids=${i}`).join("&")}`))
    .map(response => actionCreators.loadTermsAndConditionsComplete(response.termsAndConditions, null));


const loadRegistrationTerms = () => Observable.defer(() => api.getJson<ILoadRegistrationTermsResponse>('api/v1/termsAndConditions/registration'))
    .map(response => actionCreators.loadRegistrationTermsComplete(response.terms, null));

const loadBookingTerms = () => Observable.defer(() => api.getJson<ILoadBookingTermsResponse>('api/v1/termsAndConditions/booking'))
    .map(response => actionCreators.loadBookingTermsComplete(response.terms, null));

const loadWebShopPurchaseTerms = () => Observable.defer(() => api.getJson<ILoadWebShopPurchaseTermsResponse>('api/v1/termsAndConditions/webShopPurchase'))
    .map(response => actionCreators.loadWebShopPurchaseTermsComplete(response.terms, null));

const loadRegistrationVideos = (venueId: string) => Observable.defer(() => api.getJson<ILoadRegistrationVideosResponse>(`api/v1/termsAndConditions/${venueId}/registrationVideos`))
    .map(response => actionCreators.loadRegistrationVideosComplete(response.registrationVideos, null));

export const reloadRegistrationTermsEpic = (action$: ActionsObservable<any>) =>
    action$.ofType(tct.TermsAndConditionsActionTypes.RegistrationTermsSaved)
        .pipe(switchMap(_ => Observable.of(actionCreators.loadRegistrationTerms())));

export const reloadBookingTermsEpic = (action$: ActionsObservable<any>) =>
    action$.ofType(tct.TermsAndConditionsActionTypes.BookingTermsSaved)
        .pipe(switchMap(_ => Observable.of(actionCreators.loadBookingTerms())));

export const reloadWebShopPurchaseTermsEpic = (action$: ActionsObservable<any>) =>
    action$.ofType(tct.TermsAndConditionsActionTypes.WebShopPurchaseTermsSaved)
        .pipe(switchMap(_ => Observable.of(actionCreators.loadWebShopPurchaseTerms())));

export const loadTermsAndConditionsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        tct.TermsAndConditionsActionTypes.LoadTermsAndConditions,
        action => loadTermsAndConditions(action.ids),
        err => actionCreators.loadTermsAndConditionsComplete([], err));

export const loadRegistrationTermsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        tct.TermsAndConditionsActionTypes.LoadRegistrationTerms,
        action => loadRegistrationTerms(),
        err => actionCreators.loadRegistrationTermsComplete([], err));

export const loadBookingTermsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        tct.TermsAndConditionsActionTypes.LoadBookingTerms,
        action => loadBookingTerms(),
        err => actionCreators.loadBookingTermsComplete([], err));

export const loadWebShopPurchaseTermsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        tct.TermsAndConditionsActionTypes.LoadWebShopPurchaseTerms,
        action => loadWebShopPurchaseTerms(),
        err => actionCreators.loadWebShopPurchaseTermsComplete([], err));

export const loadRegistrationVideosEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        tct.TermsAndConditionsActionTypes.LoadRegistrationVideos,
        action => loadRegistrationVideos(action.venueId),
        err => actionCreators.loadRegistrationVideosComplete([], err));

export const saveRegistrationTermsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        tct.TermsAndConditionsActionTypes.SaveRegistrationTerms,
        action => {
            const sdn = action as tct.SaveRegistrationTerms;
            const { type, registrationTermsId, ...body  } = sdn;

            return (isNullOrEmpty(registrationTermsId) ? api.post(`api/v1/termsandconditions/registration`, body) : api.put(`api/v1/termsandconditions/registration/${registrationTermsId}`, body))
                .map(response => actionCreators.registrationTermsSaved(body.venueId));
        },
        (err: api.ApiError) => actionCreators.registrationTermsSaveFailed(err));


export const saveBookingTermsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        tct.TermsAndConditionsActionTypes.SaveBookingTerms,
        action => {
            const sdn = action as tct.SaveBookingTerms;
            const { type, bookingTermsId, ...body } = sdn;

            return (isNullOrEmpty(bookingTermsId) ? api.post(`api/v1/termsandconditions/booking`, body) : api.put(`api/v1/termsandconditions/booking/${bookingTermsId}`, body))
                .map(response => actionCreators.bookingTermsSaved(body.venueId));
        },
        (err: api.ApiError) => actionCreators.bookingTermsSaveFailed(err));

export const saveWebShopPurchaseTermsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        tct.TermsAndConditionsActionTypes.SaveWebShopPurchaseTerms,
        action => {
            const sdn = action as tct.SaveWebShopPurchaseTerms;
            const { type, webShopPurchaseTermsId, ...body } = sdn;

            return (isNullOrEmpty(webShopPurchaseTermsId) ? api.post(`api/v1/termsandconditions/webShopPurchase`, body) : api.put(`api/v1/termsandconditions/webShopPurchase/${webShopPurchaseTermsId}`, body))
                .map(response => actionCreators.webShopPurchaseTermsSaved(body.venueId));
        },
        (err: api.ApiError) => actionCreators.webShopPurchaseTermsSaveFailed(err));
