
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import * as api from '../../../store/apiClient';

import { Bill } from '../../../store/pages/pointOfSale/types';
import { Promotion, PromotionDiscountType, PromotionStatus } from '../../../store/pages/promotions/types';
import { clickHandler, isNullOrEmpty } from '../../../utils/util';
import ApiError from '../../global/apiError';
import { isNull } from 'lodash';
import Loading from '../../global/loading';
import Op from 'quill-delta/dist/Op';

interface CalculateDiscountResponse {
    discountAmount: number;
}

interface MemberDiscount {
    promotionId: string;
    promotionName: string;
    discount: number;
    tagName: string;
    tagColour: string;
}

interface FindMemberDiscountsResponse {
    memberDiscounts: MemberDiscount[];
}

interface AddBillDiscountProps {
    bill: Bill;
    promotions: Promotion[];
    isMember: boolean;
    addDiscount: (billId: string, promotionId: string, amountOverride: number | null, callback: (success: boolean, error: api.ApiError | null) => void) => void;
    removeDiscount: (billId: string, billDiscountId: string, callback: (success: boolean, error: api.ApiError | null) => void) => void;
    close: () => void;
    logout: () => void;
}

interface AddBillDiscountState {
    isNew: boolean;
    loading: boolean;
    billDiscountId: string | null;
    selectedPromotionId: ct.FormValue<string>;
    amount: ct.FormValue<number>;
    billTotalExDiscounts: number;
    discountAmount: number | null;
    saving: boolean;
    error: string | null;
    apiError: api.ApiError | null;
    calculateDiscountError: string | null;
    memberDiscounts: MemberDiscount[]
}

export default class AddBillDiscount extends React.Component<AddBillDiscountProps, AddBillDiscountState> {

    constructor(props: AddBillDiscountProps) {
        super(props);

        const { bill } = props;

        const discountAmount = bill.discounts && bill.discounts.length > 0 ? bill.discounts.reduce((ttl, f) => ttl + f.amount, 0) : 0;
        const discount = bill.discounts && bill.discounts.length > 0 ? bill.discounts[0] : null;

        this.state = {
            isNew: !discount,
            loading: false,
            billDiscountId: discount ? discount.id : null,
            billTotalExDiscounts: bill.totalAmount + discountAmount,
            selectedPromotionId: this.validatePromotionId(discount ? discount.promotionId : ''),
            amount: this.validateAmount(discount ? discount.amount : 0),
            discountAmount: discount ? discount.amount : null,
            saving: false,
            error: null,
            apiError: null,
            calculateDiscountError: null,
            memberDiscounts: []
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        const { bill, isMember, logout } = this.props;

        if (isMember) {
            this.setState({ loading: true }, () => this.findMemberDiscounts(bill, logout));
        }
    }

    findMemberDiscounts = (bill: Bill, logout: () => void ) => {
        api.getWithAuth<FindMemberDiscountsResponse>(`api/v1/bill/discount/${bill.venueId}/${bill.id}/memberDiscounts`, logout)
            .subscribe(
                res => {
                    const sortedPromotions = res.memberDiscounts.sort((d1, d2) => d2.discount - d1.discount);
                    return this.setState(s => ({
                        loading: false,
                        memberDiscounts: sortedPromotions,
                        discountAmount: sortedPromotions.length > 0 ? sortedPromotions[0].discount : s.discountAmount,
                        selectedPromotionId: sortedPromotions.length > 0 ? this.validatePromotionId(sortedPromotions[0].promotionId) : s.selectedPromotionId
                    }))
                },
                (err: api.ApiError) => {
                    if (err && err.status) {
                        if (err.validationErrors && err.validationErrors.length > 0) {
                            this.setState({ loading: false })
                        } else {
                            this.setState({ loading: false })
                        }
                    }
                })

    }

    addPromotion = () => {
        const { promotions } = this.props;
        const { amount, selectedPromotionId } = this.state;

        const promotion = promotions.find(p => p.id === selectedPromotionId.value);
        if (!promotion) {
            this.setState({ error: 'PointOfSale:noPromotionSelected' });
        } else {
            this.setState({ saving: true, error: null, apiError: null });
            this.props.addDiscount(this.props.bill.id, selectedPromotionId.value, promotion.discount === 0 ? amount.value : null, this.onDiscountSaved);
        }
    }

    removePromotion = () => {
        this.setState({ saving: true, error: null, apiError: null });
        this.props.removeDiscount(this.props.bill.id, this.state.billDiscountId || '', this.onDiscountSaved);
    }

    onDiscountSaved = (success: boolean, error: api.ApiError | null) => {
        this.setState({ saving: false, error: null, apiError: error });
        if (success) {
            this.props.close();
        }
    }

    amountMustBeGreaterThanZero: v.Validation = (value: number) => isNaN(value) ? 'PointOfSale:amountCannotBeZero' : undefined;

    validatePromotionId = (val: string) => v.validate(val, 'promotionId', [], []);
    validateAmount = (val: number) => v.validate(val, 'amount', [this.amountMustBeGreaterThanZero], []);

    recalculateDiscountAmount = (amountOverride: number | null) => {
        const { bill, logout } = this.props;
        const { selectedPromotionId } = this.state;

        const promotionId = selectedPromotionId.value;
        if (!promotionId || isNullOrEmpty(promotionId)) return;

        api.getWithAuth<CalculateDiscountResponse>(`api/v1/bill/discount/${bill.venueId}/${bill.id}/${promotionId}${amountOverride ? '/' + amountOverride : ''}`, logout)
            .subscribe(
                res => this.setState({ discountAmount: res.discountAmount, calculateDiscountError: null }),
                (err: api.ApiError) => {
                    if (err && err.status) {
                        if (err.validationErrors && err.validationErrors.length > 0) {
                            this.setState({ calculateDiscountError: err.validationErrors[0].messageKey, discountAmount: null })
                        } else {
                            this.setState({ calculateDiscountError: err.message, discountAmount: null })
                        }
                    }
                })
    }

    onPromotionChanged = (val: string) => {
        const { promotions } = this.props;
        var promotion = promotions.find(p => p.id === val);
        this.setState({
            selectedPromotionId: this.validatePromotionId(promotion ? promotion.id : ''),
            amount: this.validateAmount(promotion ? promotion.discount: 0),
        }, () => this.recalculateDiscountAmount(null));
    }

    onAmountChanged = (val: number | null) => this.setState({ amount: this.validateAmount(val || 0), discountAmount: null }, () => this.recalculateDiscountAmount(val));

    render() {
        const { promotions } = this.props;
        const { isNew, memberDiscounts, selectedPromotionId, amount, billTotalExDiscounts, discountAmount, saving, error, apiError, calculateDiscountError, loading } = this.state;
        const { t } = this.context;

        const promotionSelections = memberDiscounts.map(md => ({ id: md.promotionId, name: md.promotionName, tagColour: md.tagColour, tagName: md.tagName }))
            .concat(promotions.filter(p => p.state === PromotionStatus.Active && memberDiscounts.findIndex(md => md.promotionId === p.id) < 0).map(p => ({ id: p.id, name: p.name, tagName: '', tagColour: '' })))

        const promotionOptions = [{ key: '', name: t('PointOfSale:selectPromotion') }].concat(promotionSelections.map(p => ({ key: p.id.toString(), name: p.name })));
        const promotion = promotions.find(p => p.id === selectedPromotionId.value);

        const promotionVal = promotion && promotion.discount > 0 ? { ...amount, value: promotion.discount } : amount;

        const renderTag = (option: ct.SelectOption) => {
            const promotion = promotionSelections.find(s => s.id === option.key);
            if (promotion && !isNullOrEmpty(promotion.tagName) && !isNullOrEmpty(promotion.tagColour)) {
                return <><span key={option.key}>{option.name}</span><span key={`${option.key}_promotion`} className="label tag-label" style={{ backgroundColor: promotion.tagColour, marginLeft: '6px' }}>{promotion.tagName}</span></>
            }

            return <span key={option.key}>{option.name}</span>;
        }

        if (loading) {
            return <Loading />
        }

        return (
            <div className='flex flex-center flex-col'>
                <div style={{ flex: '1 1 auto', maxWidth: '400px'}}>
                    <h2>{t(isNew ? 'PointOfSale:addDiscount' : 'PointOfSale:editDiscount')}</h2>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <ct.Select id='discount' labelKey='PointOfSale:discount' value={selectedPromotionId} callback={this.onPromotionChanged} options={promotionOptions} renderOption={o => renderTag(o)} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <ct.NumberBox id='amount' labelKey={promotion && promotion.discountType === PromotionDiscountType.Percentage ? 'PointOfSale:discountPercentage' : 'PointOfSale:discountAmount'} placeholderKey='' value={promotionVal} callback={this.onAmountChanged} min='0' step='0.01' disabled={promotion && promotion.discount > 0} />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-12'>
                            <table className='table table-borderless table-condensed pos_main_total'>
                                <tbody>
                                    <tr key='bill_amount'>
                                        <td>{t('PointOfSale:originalBillAmount')}</td>
                                        <td className='text-right'>{t('Global:currencySymbol')}{billTotalExDiscounts.toFixed(2)}</td>
                                    </tr>
                                    {discountAmount
                                        ? <tr key='discount_amount'>
                                            <td>{t('PointOfSale:discountAmount')}</td>
                                            <td className='text-right'>{t('Global:currencySymbol')}{discountAmount.toFixed(2)}</td>
                                        </tr>
                                        : null
                                    }
                                </tbody>
                            </table>
                            {calculateDiscountError ? <div className='alert alert-danger'>{t(calculateDiscountError)}</div> : null}
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-xs-12'>
                            {error ? <div className='alert alert-danger'>{error}</div> : null}
                            {apiError ? <ApiError error={apiError} /> : null}
                        </div>
                    </div>

                    <div className='row button-panel'>
                        <button onClick={e => clickHandler(e, this.addPromotion)} className='btn btn-primary' disabled={saving || !discountAmount ? true : false}>{t(isNew ? 'Global:add' : 'Global:update')}</button>
                        {isNew ? null : <button onClick={e => clickHandler(e, this.removePromotion)} className='btn btn-danger' disabled={saving}>{t('Global:remove')}</button> }
                        <button onClick={e => clickHandler(e, this.props.close)} className='btn btn-basic' disabled={saving}>{t('Global:cancel')}</button>
                    </div>
                </div>
            </div>
        );
    }
}