
export interface ITranslationContext {
    t: (key: any, params?: any, comment?: string) => string;   
}

// https://github.com/APSL/redux-i18n


export const translations = {
    "es": {
        "Translate this text": "Traduce este texto",
        "Hello {n}!": "Hola {n}!",
    },
    "en-GB": {
        'Global:currencySymbol': '£',
        'Global:decimalSymbol': '.',
        'Global:clearCharacter': 'C',
        'Global:all': 'All',
        'Global:logout': 'Log out',
        'Global:login': 'Login',
        'Global:save': 'Save',
        'Global:update': 'Update',
        'Global:remove': 'Remove',
        'Global:create': 'Create',
        'Global:copy': 'Copy',
        'Global:change': 'Change',
        'Global:new': 'New',
        'Global:saving': 'Saving, please wait...',
        'Global:saveComplete': 'Save complete',
        'Global:next': 'Next',
        'Global:loading': 'Loading...',
        'Global:yes': 'Yes',
        'Global:no': 'No',
        'Global:from': 'from',
        'Global:to': 'to',
        'Global:or': 'or',
        'Global:by': 'by',
        'Global:of': 'of',
        'Global:add': 'Add',
        'Global:edit': 'Edit',
        'Global:search': 'Search',
        'Global:send': 'Send',
        'Global:sent': 'Sent',
        'Global:reset': 'Reset',
        'Global:retry': 'Retry',
        'Global:finish': 'Finish',
        'Global:cancel': 'Cancel',
        'Global:cancelled': 'Cancelled',
        'Global:delete': 'Delete',
        'Global:deleted': 'Deleted',
        'Global:enabled': 'Enabled',
        'Global:active': 'Active',
        'Global:expired': 'Expired',
        'Global:revoked': 'Revoked',
        'Global:days': 'Days',
        'Global:void': 'Void',
        'Global:view': 'View',
        'Global:show': 'Show',
        'Global:hide': 'Hide',
        'Global:min': 'Min',
        'Global:max': 'Max',
        'Global:confirmRemove': 'Confirm remove',
        'Global:confirmCancel': 'Confirm cancel',
        'Global:close': 'Close',
        'Global:closed': 'Closed',
        'Global:restart': 'Restart',
        'Global:done': 'Done',
        'Global:number': 'Number',
        'Global:count': 'Count',
        'Global:complete': 'Complete',
        'Global:settings': 'Settings',
        'Global:any': 'Any',
        'Global:filter': 'Filter',
        'Global:apply': 'Apply',
        'Global:clear': 'Clear',
        'Global:connected': 'Connected',
        'Global:disconnected': 'Disconnected',
        'Global:none': 'None',
        'Global:default': 'Default',
        'Global:before': 'Before',
        'Global:after': 'After',
        'Global:select': 'Select',
        'Global:member': 'Member',
        'Global:date': 'Date',
        'Global:credit': 'Credit',
        'Global:debit': 'Debit',
        'Global:nickname': 'Nickname',
        'Global:nicknamePlaceholder': 'Nickname (optional)',
        'Global:username': 'username',
        'Global:username-placeholder': 'enter you username',
        'Global:password': 'password',
        'Global:newPassword': 'new password',
        'Global:passwordConfirmation': 'Retype to confirm',
        'Global:password-placeholder': 'enter password',
        'Global:passwordMissmatch': 'Passwords do not match',
        'Global:addBill': 'add bill',
        'Global:editBill': 'edit bill',
        'Global:notes': 'Notes',
        'Global:print': 'Print',
        'Global:excel': 'Save as Excel',
        'Global:due': 'Due',
        'Global:overdue': 'Overdue',
        'Global:places': 'places',
        'Global:addressLine1': 'Address line 1',
        'Global:addressLine2': 'Address line 2',
        'Global:addressLine3': 'Address line 3',
        'Global:addressLine4': 'Address line 4',
        'Global:town': 'Town',
        'Global:county': 'County',
        'Global:country': 'Country',
        'Global:postalCode': 'Postcode',
        'Global:gender': 'Gender',
        'Global:confirmPostcode': 'Confirm postcode',
        'Global:emailAddress': 'Email address',
        'Global:sendEmailsFromAddress': 'Email address to send emails from',
        'Global:sendEmailsFromName': 'Name to send emails from',
        'Global:phoneNumber': 'Phone number',
        'Global:websiteAddress': 'Website address',
        'Global:emergencyContactName': 'Emergency contact name',
        'Global:emergencyContactNumber': 'Emergency contact number',
        'Global:archive': 'Archive',
        'Global:archived': 'Archived',
        'Global:showArchived': 'Show archived',
        'Global:showComplete': 'Show complete',
        'Global:showExpired': 'Show expired',
        'Global:noTags': 'No tags',
        'Global:addTag': 'Add tag',
        'Global:selectTag': '<select tag>',
        'Global:newTagName': 'Create tag',
        'Global:newTagColour': 'Tag colour',
        'Global:selectCategory': '<select category>',
        'Global:duplicateTag': 'Tag {tagName} already exists',
        'Global:name': 'Name',
        'Global:code': 'Code',
        'Global:sequence': 'Sequence',
        'Global:required': 'Required',
        'Global:description': 'Description',
        'Global:type': 'Type',
        'Global:companyName': 'Company name',
        'Global:firstName': 'First name',
        'Global:lastName': 'Last name',
        'Global:dateOfBirth': 'Date of birth',
        'Global:age': 'Age',
        'Global:organiser': 'Orgainser',
        'Global:pay': 'Pay',
        'Global:payAll': 'Pay all',
        'Global:amount': 'Amount',
        'Global:viewBill': 'View bill',
        'Global:outstanding': 'Outstanding',
        'Global:birthday': 'Birthday',
        'Global:createdBy': 'Created by',
        'Global:created': 'Created',
        'Global:deletedBy': 'Deleted by',
        'Global:cancelledBy': 'Cancelled by',
        'Global:marketing': 'Marketing',
        'Global:noMarketing': 'No marketing',
        'Global:emailMarketing': 'Email',
        'Global:phoneMarketing': 'Phone',
        'Global:results': 'Results',
        'Global:exclusiveShort': 'Exclusive',
        'Global:exclusive': 'Exclusive activity',
        'Global:sharedShort': 'Shared',
        'Global:shared': 'Shared activity',
        'Global:bookings': 'Bookings',
        'Global:unitPrice': 'Unit price',
        'Global:fixedPrice': 'Fixed price',
        'Global:price': 'Price',
        'Global:POS': 'POS',
        'Global:WEBSHOP': 'Web shop',
        'Global:PricePerPersonAbbr': 'Price PP',
        'Global:formNotValid': 'Please correct errors and re-try',
        'Global:preview': 'Preview',
        'Global:unconfirmedWebBooking': 'Unconfirmed web booking',
        'Global:webBooking': 'Web',
        'Global:inProgress': 'In progress',
        'Global:selectTemplate': '<select email template>',
        'Global:selectColourError': 'Please select a colour',
        'Global:parent': 'Parent',
        'Global:uploadFailed': 'Upload failed',
        'Global:move': 'Move',
        'Global:customer': 'Customer',
        'Global:noCustomer': 'No customer',
        'Global:warningOverbooked': 'Capacity exceeded',
        'Global:warningCategoryOverbooked': '{categoryName} Capacity exceeded',
        'Global:publicUrl': 'Public url',
        'Global:publicResultsConsent': 'Show on public results and leaderboards',
        'Global:video': 'Video',
        'Global:auditLog': 'Change log',
        'Global:tags': 'Tags',
        'Global:copied': 'Copied',
        'Global:editQuestions': 'Edit questions',
        'Global:override': 'Override',
        'Global:reload': 'Reload',
        'Global:webShop': 'Web shop',
        'Global:onlineBooking': 'Online booking',
        'Global:imageWrongSize': 'Image must be a maximum of {w} x {h} pixels. Please re-size the image and try again',
        'Global:online': 'online',
        'Global:totals': 'Totals',

        'Global:st': '{position}st',
        'Global:nd': '{position}nd',
        'Global:rd': '{position}rd',
        'Global:th': '{position}th',

        'Global:today': 'Today',
        'Global:January': 'January',
        'Global:February': 'February',
        'Global:March': 'March',
        'Global:April': 'April',
        'Global:May': 'May',
        'Global:June': 'June',
        'Global:July': 'July',
        'Global:August': 'August',
        'Global:September': 'September',
        'Global:October': 'October',
        'Global:November': 'November',
        'Global:December': 'December',

        'Global:JanAbbr': 'Jan',
        'Global:FebAbbr': 'Feb',
        'Global:MarAbbr': 'Mar',
        'Global:AprAbbr': 'Apr',
        'Global:MayAbbr': 'May',
        'Global:JunAbbr': 'Jun',
        'Global:JulAbbr': 'Jul',
        'Global:AugAbbr': 'Aug',
        'Global:SepAbbr': 'Sep',
        'Global:OctAbbr': 'Oct',
        'Global:NovAbbr': 'Nov',
        'Global:DecAbbr': 'Dec',

        'Global:Monday': 'Monday',
        'Global:Monday2ch': 'Mo',
        'Global:Monday3ch': 'Mon',
        'Global:Tuesday': 'Tuesday',
        'Global:Tuesday2ch': 'Tu',
        'Global:Tuesday3ch': 'Tue',
        'Global:Wednesday': 'Wednesday',
        'Global:Wednesday2ch': 'We',
        'Global:Wednesday3ch': 'Wed',
        'Global:Thursday': 'Thursday',
        'Global:Thursday2ch': 'Th',
        'Global:Thursday3ch': 'Thu',
        'Global:Friday': 'Friday',
        'Global:Friday2ch': 'Fr',
        'Global:Friday3ch': 'Fri',
        'Global:Saturday': 'Saturday',
        'Global:Saturday2ch': 'Sa',
        'Global:Saturday3ch': 'Sat',
        'Global:Sunday': 'Sunday',
        'Global:Sunday2ch': 'Su',
        'Global:Sunday3ch': 'Sun',
        'Global:Closed': 'Closed',
        'General:selectRegistrationTerms': '<Select registration waiver>',

        'Global:Previous': 'Previous',
        'Global:Next': 'Next',

        'Global:imageDropText': 'Drop an image or click to select a file to upload.',

        'Nav:Home': 'Home',
        'Nav:Users': 'Users',
        'Nav:Clients': 'Clients',
        'Nav:Reception': 'Reception',
        'Nav:Venues': 'Venue settings',
        'Nav:Diary': 'Diary',
        'Nav:Integrations': 'Integrations',
        'Nav:EmailTemplates': 'Email templates',
        'Nav:Customers': 'Customers',
        'Nav:Campaigns': 'Marketing campaigns',
        'Nav:Emails': 'Emails',
        'Nav:TaxRates': 'Tax rates',
        'Nav:ProductCategories': 'Product categories',
        'Nav:PointOfSale': 'Point of sale',
        'Nav:Tasks': 'Tasks',
        'Nav:PaymentMethods': 'Payment methods',
        'Nav:Reports': 'Reports',
        'Nav:AdminTools': 'Admin tools',
        'Nav:Analytics': 'Analytics',
        'Nav:DailySummary': 'Daily overview',
        'Nav:Affiliates': 'Affiliates',
        'Nav:Vouchers': 'Vouchers',
        'Nav:Tags': 'Tags',
        'Nav:Memberships': 'Memberships',

        // enums
        'eventType:Public': 'Public',
        'eventType:PrivateGroup': 'Private group',

        'AnalyticsReportType:Revenue': 'Revenue',
        'AnalyticsReportType:Products': 'Products',

        'AnalyticsPeriodSelection:Today': 'Today',
        'AnalyticsPeriodSelection:Yesterday': 'Yesterday',
        'AnalyticsPeriodSelection:WeekToDate': 'Week to date',
        'AnalyticsPeriodSelection:MonthToDate': 'Month to date',
        'AnalyticsPeriodSelection:QuarterToDate': 'Quarter to date',
        'AnalyticsPeriodSelection:YearToDate': 'Year to date',
        'AnalyticsPeriodSelection:Last12Months':'Last 12 months',
        'AnalyticsPeriodSelection:LastWeek': 'Last week',
        'AnalyticsPeriodSelection:LastMonth': 'Last month',
        'AnalyticsPeriodSelection:LastQuarter': 'Last quarter',
        'AnalyticsPeriodSelection:LastYear': 'Last year',
        'AnalyticsPeriodSelection:Custom': 'Custom',

        'AnalyticsComparisonPeriodSelection:None':'No comparison',
        'AnalyticsComparisonPeriodSelection:PreviousPeriod': 'Previous period',
        'AnalyticsComparisonPeriodSelection:ThisPeriodLastYear': 'Same period last year',
        'AnalyticsComparisonPeriodSelection:Custom': 'Custom',

        'AnalyticsProductReportType:AllProducts':'All products',
        'AnalyticsProductReportType:ProductsByNominalCode': 'Products by nominal code',
        'AnalyticsProductReportType:ProductsByAcocuntingDept': 'Products by accounting department',
        'AnalyticsProductReportType:ProductsByAccountingCat': 'Products by accounting category',
        'AnalyticsProductReportType:BestSellingByQuantity': 'Top selling products by quantity',
        'AnalyticsProductReportType:BestSellingByRevenue': 'Top selling products by revenue',
        'AnalyticsProductReportType:LowestSellingByQuantity': 'Lowest selling products by quantity',
        'AnalyticsProductReportType:LowestSellingByRevenue': 'Lowest selling products by revenue',

        'CloseTimeBehaviour:StartBeforeCloseTime': 'START before close time',
        'CloseTimeBehaviour:EndBeforeCloseTime': 'END before close time',

        'PaymentType:Cash': 'Cash',
        'PaymentType:Card': 'Card',
        'PaymentType:Voucher': 'Voucher',
        'PaymentType:Account': 'Account',

        'TaskTrigger:Manual': 'Manual',
        'TaskTrigger:EventCreated': 'Event created',
        'TaskTrigger:EventConfirmed': 'Event confirmed',
        'TaskTrigger:TermsAgreed': 'Terms agreed',
        'TaskTrigger:EventCancelled': 'Event cancelled',
        'TaskTrigger:EventComplete': 'Event complete',
        'TaskTrigger:EventStartDate': 'Event start date',

        'ParameterType:Text': 'Text',
        'ParameterType:Number': 'Number',
        'ParameterType:Currency': 'Currency',
        'ParameterType:Date': 'Date',

        'TaskEmailRecipient:None': 'None',
        'TaskEmailRecipient:Organiser': 'Organiser',
        'TaskEmailRecipient:AllParticipants': 'All participants',
        'TaskEmailRecipient:TaskCreator': 'Task creator',
        'TaskEmailRecipient:EventCreator': 'Event creator',

        'PaymentMethodType:None': 'General',
        'PaymentMethodType:Cash': 'Cash',
        'PaymentMethodType:ManualCard': 'ManualCard',
        'PaymentMethodType:Bacs': 'BACS',
        'PaymentMethodType:Gateway': 'Gateway',
        'PaymentMethodType:CustomerCredit': 'Customer Credit',
        'PaymentMethodType:Voucher': 'Voucher',

        'PaymentGateway:Paymentsense': 'Paymentsense',
        'PaymentGateway:Stripe': 'Stripe',
        'PaymentGateway:LibertyPay': 'Liberty Pay',
        'PaymentGateway:WorldpayBg350': 'Worldpay (BG350)',


        'CustomFieldType:Text': 'Text',
        'CustomFieldType:Number': 'Number',
        'CustomFieldType:List': 'List',
        'CustomFieldType:Transponder': 'Transponder Number',
        'CustomFieldType:CompetitorNumber': 'Customer Number',

        'CustomFieldApplication:PerRegistration': 'Overall registration',
        'CustomFieldApplication:PerCategory': 'Each category',

        'DayOfWeek:Sunday': 'Sunday',
        'DayOfWeek:Monday': 'Monday',
        'DayOfWeek:Tuesday': 'Tuesday',
        'DayOfWeek:Wednesday': 'Wednesday',
        'DayOfWeek:Thursday': 'Thursday',
        'DayOfWeek:Friday': 'Friday',
        'DayOfWeek:Saturday': 'Saturday',

        'EmailStatus:all': 'Any status',
        'EmailStatus:Created': 'Created',
        'EmailStatus:Queued': 'Queued',
        'EmailStatus:Processing': 'Sending',
        'EmailStatus:Sent': 'Sent',
        'EmailStatus:Failed': 'Failed',
        'EmailStatus:Cancelled': 'Cancelled',

        'EmailEventType:Created': 'Created',
        'EmailEventType:Queued': 'Queued',
        'EmailEventType:Processing': 'Processing',
        'EmailEventType:Sent': 'Sent',
        'EmailEventType:Failed': 'Failed',
        'EmailEventType:Processed': 'Processed',
        'EmailEventType:Deferred': 'Deferred',
        'EmailEventType:Delivered': 'Delivered',
        'EmailEventType:Open': 'Opened',
        'EmailEventType:Click': 'Clicked',
        'EmailEventType:Bounced': 'Bounced',
        'EmailEventType:Dropped': 'Dropped',
        'EmailEventType:Spamreport': 'Span report',
        'EmailEventType:Unsubscribe': 'Unsubscribed',

        'EmailTemplateType:ForgottenPassword': 'Forgotten password',
        'EmailTemplateType:EventResults': 'Event results',
        'EmailTemplateType:BookingConfirmation': 'Booking confirmation',
        'EmailTemplateType:General': 'General email',
        'EmailTemplateType:PaymentReminder': 'Payment reminder',
        'EmailTemplateType:CustomerLogin': 'Customer login code',
        'EmailTemplateType:BookingRegistrationLink': 'Booking registration link',
        'EmailTemplateType:GeneralRegistrationLink': 'General registration link',
        'EmailTemplateType:MarketingCampaign': 'Marketing campaign',
        'EmailTemplateType:GeneralBookingEmail': 'General booking email',
        'EmailTemplateType:Voucher': 'Voucher email',
        'EmailTemplateType:PurchaseConfirmation': 'Web shop purchase confirmation',
        'EmailTemplateType:MembershipPurchaseConfirmation': 'Membership purchase confirmation',

        'EventStatus:Draft': 'Draft',
        'EventStatus:Provisional': 'Provisional',
        'EventStatus:Confirmed': 'Confirmed',
        'EventStatus:Cancelled': 'Cancelled',

        'FeeType:Fixed': 'Fixed amount',
        'FeeType:Percentage': 'Percentage amount',

        'IntegrationType:Timing': 'Timing',
        'IntegrationType:TransactionalEmail': 'Transactional email',
        'IntegrationType:MarketingEmail': 'Marketing email',
        'IntegrationType:ViewPointVideo': 'Viewpoint Video',

        'IntervalUnit:Year': 'Year',
        'IntervalUnit:Month': 'Month',
        'IntervalUnit:Day': 'Day',
        'IntervalUnit:Hour': 'Hour',
        'IntervalUnit:Minute': 'Minute',

        'TaskStateEnum:None': 'Unknown',
        'TaskStateEnum:Created': 'Initialized',
        'TaskStateEnum:Initialized': 'Initialized',
        'TaskStateEnum:Queued': 'Queued',
        'TaskStateEnum:Processed': 'Processed',
        'TaskStateEnum:Failed': 'Failed',

        'MarketingCampaignRunInterval:Manual': 'Manual',
        'MarketingCampaignRunInterval:Hourly': 'Hourly',
        'MarketingCampaignRunInterval:Daily': 'Daily',
        'MarketingCampaignRunInterval:Weekly': 'Weekly',
        'MarketingCampaignRunInterval:Monthly': 'Monthly',
        'MarketingCampaignRunInterval:BiMonthly': 'Bi-monthly',
        'MarketingCampaignRunInterval:Quarterly': 'Quarterly',

        'MarketingCampaignState:Draft': 'Draft',
        'MarketingCampaignState:Active': 'Active',
        'MarketingCampaignState:Paused': 'Paused',
        'MarketingCampaignState:Complete': 'Complete',

        'MarketingCampaignFilterType:Age': 'Age',
        'MarketingCampaignFilterType:Birthday': 'Birthday',
        'MarketingCampaignFilterType:BookedActivityType': 'Booked activity type',
        'MarketingCampaignFilterType:LastVisitDate': 'Date of last event',
        'MarketingCampaignFilterType:LastBookingEventDate': 'Last booking event date',
        'MarketingCampaignFilterType:CustomerTag': 'Customer tag',
        'MarketingCampaignFilterType:CustomerCategory': 'Customer category',
        'MarketingCampaignFilterType:AbandonedWebBooking': 'Abandoned web booking creation date',
        'MarketingCampaignFilterType:LastBookingCreationDate': 'Last booking creation date',
        'MarketingCampaignFilterType:PostalCode': 'Postcode',
        'MarketingCampaignFilterType:MembershipType': 'Membership type',

        'MarketingCampaignFilterComparison:EqualTo': 'Equal to',
        'MarketingCampaignFilterComparison:LessThan': 'Less than',
        'MarketingCampaignFilterComparison:GreaterThan': 'Greater than',
        'MarketingCampaignFilterComparison:In': 'One of',
        'MarketingCampaignFilterComparison:Between': 'Between',
        'MarketingCampaignFilterComparison:InTheNext': 'In the next',
        'MarketingCampaignFilterComparison:InTheLast': 'In the last',
        'MarketingCampaignFilterComparison:NotInTheLast': 'Not in the last',

        'MarketingCampaignFilterUnit:None': 'None',
        'MarketingCampaignFilterUnit:Hour': 'Hours',
        'MarketingCampaignFilterUnit:Day': 'Days',
        'MarketingCampaignFilterUnit:Week': 'Weeks',
        'MarketingCampaignFilterUnit:Month': 'Month',

        'MarketingCampaignFilterGroupType:None': 'And',
        'MarketingCampaignFilterGroupType:And': 'And',
        'MarketingCampaignFilterGroupType:Or': 'Or',

        'MarketingCampaignRunStatus:Created': 'Created',
        'MarketingCampaignRunStatus:Processing': 'Processing',
        'MarketingCampaignRunStatus:Discarded': 'Discarded',
        'MarketingCampaignRunStatus:Processed': 'Processed',

        'DayOfMonth:None': '<Select when to run>',
        'DayOfMonth:FirstDay': 'First day of month',
        'DayOfMonth:LastDay': 'Last day of month',
        'DayOfMonth:FirstWeekDay': 'First week day of month',
        'DayOfMonth:LastWeekDay': 'Last week day of month',
        'DayOfMonth:SpecificDay': 'Selected day',

        'Days:None': '<Select day>',
        'Days:Monday': 'Monday',
        'Days:Tuesday': 'Tuesday',
        'Days:Wednesday': 'Wednesday',
        'Days:Thursday': 'Thursday',
        'Days:Friday': 'Friday',
        'Days:Saturday': 'Saturday',
        'Days:Sunday': 'Sunday',

        'ProductPricingMode:PerUnit': 'Standard',
        'ProductPricingMode:Fixed': 'Fixed',

        'PaymentStatus:None': 'None',
        'PaymentStatus:Scheduled': 'Scheduled',
        'PaymentStatus:Success': 'Succeeded',
        'PaymentStatus:Failed': 'Failed',
        'PaymentStatus:GatewayInProgress': 'Online card payment in progress',
        'PaymentStatus:GatewayFailed': 'Card payment failed',
        'PaymentStatus:GatewayCancelled': 'Card payment cancelled',

        'GatewayPaymentStatus:None': 'None',
        'GatewayPaymentStatus:InProgress': 'In progress',
        'GatewayPaymentStatus:Success': 'Succeeded',
        'GatewayPaymentStatus:CardReferred': 'Failed - card referred',
        'GatewayPaymentStatus:CardDeclined': 'Failed - card declined',
        'GatewayPaymentStatus:DuplicateTransaction': 'Failed - duplicate transaction',
        'GatewayPaymentStatus:Error': 'Failed - Errored',
        'GatewayPaymentStatus:Cancelled': 'Cancelled',

        'WebsiteAnalyticsProviderType:GoogleAnalytics': 'Google Analytics',
        'WebsiteAnalyticsProviderType:FacebookPixel': 'Meta (Facebook) Pixel',
        'WebsiteAnalyticsProviderType:Useproof': 'Useproof',
        'WebsiteAnalyticsProviderType:GoogleGtag': 'Google GTag',
        'WebsiteAnalyticsProviderType:TikTok': 'TikTok Analytics',
        'WebsiteAnalyticsProviderType:GoogleTagManager': 'Google tag manager',

        'PublicWebStep:SiteHome': 'Home page',
        'PublicWebStep:Privacy': 'Privacy',
        'PublicWebStep:Error': 'Error page',
        'PublicWebStep:ProductSelection': 'Product selection',
        'PublicWebStep:CheckAvailability': 'Availability',
        'PublicWebStep:GetAvailability': 'Load availability',
        'PublicWebStep:SelectAvailability': 'Select slot',
        'PublicWebStep:AdditionalProducts': 'Additional products',
        'PublicWebStep:SelectAdditionalProducts': 'Confirm additional products',
        'PublicWebStep:AdditionalFields': 'Additional information',
        'PublicWebStep:SaveAdditionalFields': 'Save additional information',
        'PublicWebStep:BookingSummary': 'Booking summary',
        'PublicWebStep:ConfirmBookingSummary': 'Confirm booking summary',
        'PublicWebStep:CustomerEmail': 'Customer email',
        'PublicWebStep:CheckCustomerEmail': 'Verify email',
        'PublicWebStep:ExistingCustomer': 'Existing customer',
        'PublicWebStep:SelectExistingCustomer': 'Select existing customer',
        'PublicWebStep:SendLoginLink': 'Send login link',
        'PublicWebStep:LoginWithCode': 'Login with code',
        'PublicWebStep:NewCustomer': 'New customer details',
        'PublicWebStep:SubmitNewCustomer': 'Save new customder details',
        'PublicWebStep:BookingPayment': 'Booking payment',
        'PublicWebStep:InitiatePayment': 'Start booking payment',
        'PublicWebStep:BookingPayWithPaymentsense': 'Pay with paymentsense',
        'PublicWebStep:RetryPaymentsensePayment': 'Retry paymentsense payment',
        'PublicWebStep:CompleteBookingPaymentsensePayment': 'Complete paymentsense payment',

        'PublicWebStep:BookingPayWithLibertyPay': 'Pay with Liberty Pay',
        'PublicWebStep:RetryLibertyPayPayment': 'Retry Liberty Pay payment',
        'PublicWebStep:CompleteBookingLibertyPayPayment': 'Complete Liberty Pay payment',

        'PublicWebStep:PayBookingWithStripe': 'Pay with stripe',
        'PublicWebStep:RetryBookingStripePayment': 'Retry stripe payment',
        'PublicWebStep:HandleBookingStripePaymentResponse': 'Handle stripe payment',
        'PublicWebStep:CheckForBookingStripePaymentResponse': 'Complete stripe payment',
        'PublicWebStep:ViewBookingTerms': 'Booking terms',
        'PublicWebStep:BookingComplete': 'Booking complete',
        'PublicWebStep:BookingExpired': 'Booking expired',
        'PublicWebStep:RestartBooking': 'Restart booking',
        'PublicWebStep:Payment': 'Pay scheduled payment',
        'PublicWebStep:PaymentsensePayment': 'Pay with paymentsense',
        'PublicWebStep:PaymentsenseResponse': 'Handle paymentsense response',
        'PublicWebStep:LibertyPayPayment': 'Pay with Liberty Pay',
        'PublicWebStep:LibertyPayResponse': 'Handle Liberty Pay response',
        'PublicWebStep:StripePayment': 'Pay with stripe',
        'PublicWebStep:StripePaymentResponse': 'Handle stripe response',
        'PublicWebStep:StartRegistration': 'Start registration',
        'PublicWebStep:RegistrationCheckEmail': 'Check email',
        'PublicWebStep:RegistrationExistingCustomer': 'Existing customer',
        'PublicWebStep:RegistrationExistingCustomerSelection': 'Select customer',
        'PublicWebStep:RegistrationSendLoginLink': 'Send login link',
        'PublicWebStep:RegistrationLoginWithCode': 'Login with code',
        'PublicWebStep:RegistrationNewCustomer': 'New customer details',
        'PublicWebStep:RegistrationCreateNewCustomer': 'Save new customer',
        'PublicWebStep:RegistrationCustomerDetails': 'Customer details',
        'PublicWebStep:RegistrationSaveCustomerDetails': 'Save customer details',
        'PublicWebStep:RegistrationContactPreferences': 'Contact preferences',
        'PublicWebStep:RegistrationUpdateContactPreferences': 'Update contact preferences',
        'PublicWebStep:RegistrationConfirmParticipants': 'Chose participants',
        'PublicWebStep:RegistrationUpdateParticipants': 'Select participants',
        'PublicWebStep:RegistrationSelectChildren': 'Select children',
        'PublicWebStep:RegistrationRegisterChildren': 'Save children',
        'PublicWebStep:RegistrationBriefing': 'Briefing video',
        'PublicWebStep:RegistrationConfirmBriefing': 'Confirm briefing video',
        'PublicWebStep:RegistrationTermsAndConditions': 'Terms and conditions',
        'PublicWebStep:RegistrationConfirmTermsAndConditions': 'Confirm terms',
        'PublicWebStep:RegistrationComplete': 'Registration complete',

        'PublicWebStepCategory:None': 'None',
        'PublicWebStepCategory:Booking': 'Booking',
        'PublicWebStepCategory:Registration': 'Registration',
        'PublicWebStepCategory:Payment': 'Payment',

        'PromotionItemType:OverallBillAmount': 'Overall amount',
        'PromotionItemType:Participant': 'Participant',
        'PromotionItemType:Activity': 'Activity',
        'PromotionItemType:Item': 'Bill item',

        'PromotionCriteriaType:None': 'No restriction on participants or activities',
        'PromotionCriteriaType:Participant': 'Restrict based on number of participants',
        'PromotionCriteriaType:Activity': 'Restrict based on number of activities',

        'PromotionDiscountType:Fixed': 'Fixed amount',
        'PromotionDiscountType:Percentage': 'Percentage amount',

        'ProductPricePoint:PurchaseDate': 'Purchase date',
        'ProductPricePoint:EventOrPurchaseDate': 'Event/purchase date',

        'TimeOffsetSelection:DaysBefore': 'Days before due',
        'TimeOffsetSelection:DaysOverdue': 'Days overdue',
        'TimeOffsetSelection:DaysAfterCreation': 'Days after created',

        'MarketingPreference:None': 'None',
        'MarketingPreference:Email': 'Email',
        'MarketingPreference:Phone': 'Phone',
        'MarketingPreference:EmailAndPhone': 'Email & phone',

        'Gender:NotProvided': 'Not provided',
        'Gender:Male': 'Male',
        'Gender:Female': 'Female',
        'Gender:NonBinary': 'Non-binary',
        'Gender:Transgender': 'Transgender',
        'Gender:Intersex': 'Intersex',
        'Gender:PreferNotToSay': 'Prefer not to say',

        'SortDirection:Ascending': 'Ascending',
        'SortDirection:Descending': 'Descending',

        'ReservationType:NonExclusive': 'Non-exclusive',
        'ReservationType:Exclusive': 'Exclusive',

        'ProductQuantityUnit:PerBooking': 'Per booking',
        'ProductQuantityUnit:PerParticipant': 'Per participant',

        'WebBookingStatus:PaymentFailed': 'Payment failed',
        'WebBookingStatus:PaymentStarted': 'Payment in progress',
        'WebBookingStatus:ExistingCustomerAdded': 'Existing customer',
        'WebBookingStatus:NewCustomerAdded': 'New customer',
        'WebBookingStatus:Created': 'Created',
        'WebBookingStatus:Complete': 'Complete',

        'GatewayRefundStatus:Pending': 'Pending',
        'GatewayRefundStatus:Succeeded': 'Succeeded',
        'GatewayRefundStatus:Failed': 'Failed',
        'GatewayRefundStatus:Cancelled': 'Cancelled',

        'CombinedRefundState:Unknown': 'Unknown',
        'CombinedRefundState:Pending': 'Pending',
        'CombinedRefundState:Succeeded': 'Succeeded',
        'CombinedRefundState:PartiallySucceeded': 'Partial',
        'CombinedRefundState:Failed': 'Failed',
        'CombinedRefundState:Cancelled': 'Cancelled',

        'TimingSessionType:Unknown': 'Unknown',
        'TimingSessionType:Practice': 'Practice',
        'TimingSessionType:Qualifying': 'Qualifying',
        'TimingSessionType:Race': 'Race',
        'TimingSessionType:TimeTrial': 'TimeTrial',

        'ReportPermission:None': 'No access',
        'ReportPermission:Run': 'View report',
        'ReportPermission:Edit': 'Edit report settings',

        'CookieOptionsType:Banner': 'Banner',
        'CookieOptionsType:Overlay': 'Overlay',

        'PromotionCustomerRestriction:None': 'No restriction',
        'PromotionCustomerRestriction:BookingIntheLast': 'Customers who booked an event',
        'PromotionCustomerRestriction:ParticipatedInTheLast': 'Customers who attended an event',
        'PromotionCustomerRestriction:Tag': 'Customers with tag',

        'PromotionCustomerRestrictionPeriod:Day': 'Days',
        'PromotionCustomerRestrictionPeriod:Month': 'Months',
        'PromotionCustomerRestrictionPeriod:Year': 'Years',

        'VoucherProductRestrictionType:None': 'No restrictions',
        'VoucherProductRestrictionType:Categories': 'Selected product categories',
        'VoucherProductRestrictionType:Tags': 'Selected product tags',
        'VoucherProductRestrictionType:IndividualSelections': 'Selected products',

        'MembershipExpiryType:None': 'No expiry (lifetime membership)',
        'MembershipExpiryType:Fixed': 'Fixed expiry date',
        'MembershipExpiryType:Annual': 'End of calendar year',
        'MembershipExpiryType:Rolling': 'Interval (from date of purchase)',

        'TimeFormat:TwentyFourHour': '24H',
        'TimeFormat:TwelveHour': '12H',

        'DateFormat:DMY': 'D/M/Y',
        'DateFormat:MDY': 'M/D/Y',

        'HomePage:title': 'Home',

        'Header:versionOutOfDateMessage': 'A new app version is available.  Please reload the page',

        'AnalyticsPage:unknownReportType': 'Unknown report type {reportType}',

        'RevenueReportContent:grossTotal': 'Gross total',
        'RevenueReportContent:netRevenue': 'Net total',
        'RevenueReportContent:netTotal': 'Net total',
        'RevenueReportContent:bookings': 'Bookings',
        'RevenueReportContent:otherBills': 'Other bills',
        'RevenueReportContent:grossRevenue': 'Gross revenue',
        'RevenueReportContent:averageGrossOrderValue': 'Avg gross bill value',
        'RevenueReportContent:averageNetOrderValue': 'Avg net bill value',

        'RevenueReportDataTable:grossValues': 'Gross revenue',
        'RevenueReportDataTable:netValues': 'Net revenue',
        'RevenueReportDataTable:bookings': 'Number of bookings',
        'RevenueReportDataTable:otherBills': 'Number of other bills',
        'RevenueReportDataTable:thisPeriod': 'This period',
        'RevenueReportDataTable:comparedTo': 'Compared to',

        'AllProductsReportContent:grossTotal': 'Gross total',
        'AllProductsReportContent:grossRevenue':'Gross revenue',
        'AllProductsReportContent:netTotal': 'Net total',
        'AllProductsReportContent:netRevenue': 'Net revenue',
        'AllProductsReportContent:quantity': 'Total quantity',
        'AllProductsReportContent:bills': 'Total bills',
        'AllProductsReportContent:events': 'Total events',
        'AllProductsReportContent:sessions': 'Total sessions',
        'AllProductsReportContent:discountTotal': 'Total discount',
        'AllProductsReportContent:discount': 'Discounts',
        'AllProductsReportContent:averageGrossOrderValue': 'Avg gross bill value',
        'AllProductsReportContent:averageNetOrderValue': 'Avg net bill value',

        'AllProductsReportDataTable:grossValues': 'Gross revenue',
        'AllProductsReportDataTable:netValues': 'Net revenue',
        'AllProductsReportDataTable:bookings': 'Number of bookings',
        'AllProductsReportDataTable:quantity': 'Total quantity',
        'AllProductsReportDataTable:bills': 'Total bills',
        'AllProductsReportDataTable:events': 'Total events',
        'AllProductsReportDataTable:sessions': 'Total sessions',
        'AllProductsReportDataTable:thisPeriod': 'This period',
        'AllProductsReportDataTable:comparedTo': 'Compared to',
        'AllProductsReportDataTable:discount': 'Total discount',
        'AllProductsReportDataTable:averageGrossOrderValue': 'Avg gross bill value',
        'AllProductsReportDataTable:averageNetOrderValue': 'Avg net bill value',

        'CampaignsPage:title': 'Marketing Campaigns',
        'CampaignsPage:addCampaign': 'Create campaign',
        'CampaignsPage:nameHeading': 'Name',
        'CampaignsPage:runIntervalHeading': 'Run interval',
        'CampaignsPage:statusHeading': 'Status',
        'CampaignsPage:startHeading': 'Start date',
        'CampaignsPage:endHeading': 'End date',
        'CampaignsPage:nextRunHeading': 'Next run',
        'CampaignsPage:sentHeading': 'Sent',
        'CampaignsPage:openedHeading': 'Opened',
        'CampaignsPage:clickedHeading': 'Clicked',
        'CampaignsPage:bouncedHeading': 'Bounced',
        'CampaignsPage:testCampaign': 'Test',

        'CampaignPage:addCampaign': 'Create campaign',
        'CampaignPage:name': 'Name',
        'CampaignPage:invalidCampaignState': 'A campaign can only be changed if it is in draft or paused state',
        'CampaignPage:runInterval': 'Run interval',
        'CampaignPage:daysOfWeekToRun': 'Days of week to run',
        'CampaignPage:dayOfWeekToRun': 'Day of week to run',
        'CampaignPage:dayOfMonthToRun': 'Run on',
        'CampaignPage:dayOfMonthValue': 'Day',
        'CampaignPage:dailyRunStartTime': 'Run from',
        'CampaignPage:dailyRunEndTime': 'to',
        'CampaignPage:dailyRunTime': 'Time of day to run',
        'CampaignPage:campaignState': 'Status',
        'CampaignPage:startDate': 'Start date',
        'CampaignPage:endDate': 'End date (leave blank to run indefinitely)',
        'CampaignPage:leaveEndDateBlank': 'Leave blank to run indefinitely',
        'CampaignPage:venueSelection': 'Apply to venue(s)',
        'CampaignPage:customerFilters': 'Customer filters',
        'CampaignPage:selectFilterType': '<select filter>',
        'CampaignPage:filterTypeNotSelected': 'Please select the value to filter on',
        'CampaignPage:addAndFilter': '+ AND',
        'CampaignPage:addOrFilter': '+ OR',
        'CampaignPage:correctFilterErrors': 'There are errors in the filters section.  Please correct, then re-save',
        'CampaignPage:SendTestEmail': 'Send test email',
        'CampaignPage:anyActivity': 'Any activity',
        'CampaignPage:anyMembershipType': 'Any membership type',
        'CampaignPage:minIntervalBetweenResends': 'Email a customer a maximum of once every',
        'CampaignPage:selectTag': 'Select tag',
        'CampaignPage:selectCategory': 'Select category',
        'CampaignPage:selectVenueToAdd': 'Select venue to add',
        'CampaignPage:allVenues': 'Applies to all venues',
        'CampaignPage:emailTemplate': 'Email template',
        'CampaignPage:endTimeBeforeStartTime': 'End time must be after start time',
        'CampaignPage:saparateWithCommas': '(Separate with commas)',
        'CampaignPage:selectDaysToRun': 'Please select the day(s) of the week to run.',

        'CampaignDetailsPage:campaignNotFound': 'Campaign could not be found',
        'CampaignDetailsPage:campaignStatus': 'Campaign status',
        'CampaignDetailsPage:customerSegmentDetails': 'Customer segment',
        'CampaignDetailsPage:getCustomerCount': 'Get customer count',
        'CampaignDetailsPage:status': 'Status',
        'CampaignDetailsPage:sendToAddress': 'Address to send to',
        'CampaignDetailsPage:affectedCustomers': 'This campaign will be sent to {customerCount} customers',
        'CampaignDetailsPage:nonMarketingCustomers': '{excludedCustomerCount} matching customers declined marketing',
        'CampaignDetailsPage:testCampaign': 'Send test email',
        'CampaignDetailsPage:emailSent': 'Test email sent',
        'CampaignDetailsPage:runs': 'Runs',
        'CampaignDetailsPage:RunDate': 'Date of run',
        'CampaignDetailsPage:RunStatus': 'Status',
        'CampaignDetailsPage:RunEmailCount': 'Number of customers emailed',

        'CampaignDetailsPage:sentCount': 'Sent',
        'CampaignDetailsPage:deliveredCount': 'Delivered',
        'CampaignDetailsPage:openedCount': 'Opened',
        'CampaignDetailsPage:clickedCount': 'Clicked',
        'CampaignDetailsPage:bouncedCount': 'Bounced',
        'CampaignDetailsPage:droppedCount': 'Dropped',
        'CampaignDetailsPage:spamreportedCount': 'Spam',
        'CampaignDetailsPage:unsubscribedCount': 'unsubscribes',
        'CampaignDetailsPage:conversionCount': 'Conversions',


        'CampaignDetailsPage:loadingRuns': 'Loading...',
        'CampaignDetailsPage:showDiscardedRuns': 'Show discarded?',

        'CampaignDetailsPage:activate': 'Activate',
        'CampaignDetailsPage:pause': 'Pause',
        'CampaignDetailsPage:restart': 'Restart',

        'CampaignCompetitors:searchLabel': 'Enter customer name or email address',

        'DiaryPage:title': 'Diary',
        'DiaryPage:nextDay': 'Next day',
        'DiaryPage:prevDay': 'Previous day',
        'DiaryPage:notesPlaceholder': 'Click to add notes',
        'DiaryPage:showCancelled': 'Show cancelled',
        'DiaryPage:openTimes': 'Opening hours',
        'DiaryPage:editOpeningTimes': 'Edit hours',

        'DiarySearch:term': 'Enter search term',
        'DiarySearch:noResults': 'No results found.  Search works on customer name, company name, bill number, customer postcode or phone number',
        'DiarySearch:searching': 'Searching.....',
        'DiarySearch:minLengthWarning': 'Please neter at least 3 characters',

        'FilterResources:selectResourcesToShow': 'Select resources to show',

        'Downloads:heading': 'Downloads',
        'Downloads:installers': 'Installers',
        'Downloads:androidKioskInstaller': 'Android kiosk app',
        'Downloads:auresTillServiceInstaller': 'Aures till service',

        'DuePayments:heading': 'Due Payments',

        'dailySummaryPage:heading': 'Daily activity overview',
        'dailySummary:dueTimeHeading': 'Due time',
        'dailySummary:startTimeHeading': 'Start time',
        'dailySummary:resourceHeading': 'Resource',
        'dailySummary:activityHeading': 'Activity',
        'dailySummary:totalHeading': 'Total participants',

        'EditDiaryNotes:heading': 'Notes for {date}',
        'EditDiaryNotes:notesLabel': 'Notes',

        'EditOpeningTimes:heading': 'Opening times for {date}',
        'EditOpeningTimes:standardHours': 'Standard hours',
        'EditOpeningTimes:open': 'Open',
        'EditOpeningTimes:close': 'Close',
        'EditOpeningTimes:message': 'Message',
        'EditOpeningTimes:resourceHeading': 'Resource',

        'loginForm:forgottenPassword': 'I\'ve forgotten my password',
        'LoginForm:invalidCredentials': 'Username or password is invalid',
        'LoginForm:loggingIn': 'Logging in, please wait...',

        'AnalyticsPage:heading': 'Analytics',
        'Analytics:dateRange': 'Date range',
        'Analytics:category': 'Category',
        'Analytics:noResults': 'No results found',

        'AffiliatesPage:title': 'Affiliates',
        'AffiliatesPage:addAffiliate': 'Add affiliate',
        'AffiliatesPage:nameHeading': 'Name',
        'AffiliatesPage:codeHeading': 'Code',
        'AffiliatesPage:emailHeading': 'Email address',


        'AffiliateForm:addAffiliate': 'Create affiliate',
        'AffiliateForm:editAffiliate': 'Edit affiliate',

        'UsersPage:title': 'Users',
        'UsersPage:addUser': 'add user',
        'UsersPage:emailHeading': 'Email',
        'UsersPage:phoneHeading': 'Phone number',
        'UsersPage:sysAdminHeading': 'Sys admim',
        'UsersPage:clientAdminHeading': 'Client admin',
        'UsersPage:name': 'Name',

        'UserForm:addUser': 'Add User',
        'UserForm:usernameHint': 'We recommend using the user\'s email address. The username must contain an @ symbol followed by at least 3 characters',
        'UserForm:clientAdmin': 'client admin',
        'UserForm:systemAdmin': 'system admin',
        'UserForm:mustChangePassword': 'must change password',
        'UserForm:deactivated': 'Deactivated',
        'UserForm:accessLog': 'Access log',
        'UserForm:loginDate': 'Login time',
        'UserForm:address': 'User ip address',
        'UserForm:userAgent': 'Browser type',
        'UserForm:forceLogOut': 'Force logout',
        'UserForm:userLoggedOut': 'All user tokens revoked.  Note: it may take up to 15 minutes for all active sessions to be deactivated',
        'UserForm:sessionTimeout': 'Session timeout in minutes.  Leave blank for no timeout',
        'UserForm:sessionTimeoutHint': 'User will be automatically logged out if their session is inactive for more than this value',

        'ClientsPage:title': 'Clients',
        'ClientsPage:addClient': 'Add client',
        'ClientsPage:GatewayFees': 'Gateway fees',

        'ClientForm:addClient': 'Add client',
        'ClientForm:registeredName': 'Registered company name',
        'ClientForm:companyNumber': 'Registered company number',
        'ClientForm:chargeSalesTax': 'Charge sales tax',
        'ClientForm:taxNumber': 'Tax (VAT) registration number',
        'ClientForm:contactFirstName': 'Contact first name',
        'ClientForm:contactLastName': 'Contact last name',
        'ClientForm:contactEmailAddress': 'Contact email address',
        'ClientForm:billingEmailAddress': 'Billing email address',
        'ClientForm:billingAddressLine1': 'Billing address line 1',
        'ClientForm:billingAddressLine2': 'Billing address line 2',
        'ClientForm:billingAddressLine3': 'Billing address line 3',
        'ClientForm:billingAddressLine4': 'Billing address line 4',
        'ClientForm:billingTown': 'Billing town',
        'ClientForm:billingCounty': 'Billing county',
        'ClientForm:billingCountry': 'Billing country',
        'ClientForm:billingPostalCode': 'Billing post code',
        'ClientForm:logo': 'Logo',
        'ClientForm:enableMultipleCategoriesPerCustomer': 'Enable multiple categories per customer',
        'ClientForm:gatewayFees': 'Payment gateway fees',

        'CustomersPage:title': 'Customers',
        'CustomersPage:nameHeading': 'Name',
        'CustomersPage:companyHeading': 'Company',
        'CustomersPage:emailAddressHeading': 'Email',
        'CustomersPage:postcodeHeading': 'Postcode',
        'CustomersPage:phoneNumberHeading': 'Phone number',
        'CustomersPage:lastBookingHeading': 'Last booking',
        'CustomersPage:lastEventHeading': 'Last event',
        'CustomersPage:numberOfEventsHeading': 'Events',
        'CustomersPage:created': 'Created',
        'CustomersPage:marketing': 'Marketing',
        'CustomersPage:customersExcelReportName': 'customers',
        'CustomersPage:downloading': 'Preparing export.  This may take some time so please be patient....',
        'CustomersPage:totalCustomers': 'Total customers',
        'CustomersPage:visitsThisMonth': 'Unique visitors this month',
        'CustomersPage:visitsLastMonth': 'Unique visitors last month',
        'CustomersPage:advancedSearch': 'Advanced search',
        'CustomersPage:enterSearchTerm': 'Please enter search term in the box above and click search',
        'CustomersPage:noCustomersFound': 'No customers found',
        'CustomersPage:merge': 'Merge selected',
        'CustomersPage:age': 'Age',

        'customerDetails:companyName': 'Company',
        'customerDetails:dateOfBirth': 'Date of Birth',
        'customerDetails:address': 'Address',
        'customerDetails:phoneNumber': 'Phone number',
        'customerDetails:emailAddress': 'Email address',
        'customerDetails:nameSearchLabel': 'Customer name, email address, company name or postcode',
        'customerDetails:marketingPreference': 'Marketing Preference',
        'customerDetails:resultsPreference': 'Results preference',
        'customerDetails:venue': 'Venue',
        'customerDetails:lastVisit': 'Last visit',
        'customerDetails:minVisits': 'Minimum visits',
        'customerDetails:customerCreated': 'Customer created',
        'customerDetails:emergency contact': 'Emergency contact',
        'customerDetails:notes': 'Notes',
        'customerDetails:credit': 'Account credit',
        'customerDetails:allVenues': 'All venues',
        'customerDetails:tag': 'Tag',
        'customerDetails:noTagSelected': '<no tag selected>',
        'customerDetails:bookedActivityFilter': 'Booked activities',
        'customerDetails:participantActivityFilter': 'Participated in activities',

        'customerDetails:memberships': 'Memberships',
        'customerDetails:membership': 'Membership',
        'customerDetails:membershipNumber': 'Number',
        'customerDetails:membershipType': 'Type',
        'customerDetails:purchaseDate': 'Purchased',
        'customerDetails:expiryDate': 'Expires',
        'customerDetails:noMemberships': 'No memberships',


        'customerDetails:events': 'Events',
        'customerDetails:noEvents': 'No events',
        'customerDetails:eventNameHeading': 'Name',
        'customerDetails:venueHeading': 'Venue',
        'customerDetails:eventDate': 'Date',
        'customerDetails:eventOrganiser': ' ',
        'customerDetails:eventBillAmount': 'Bill amount',
        'customerDetails:eventBillNumber': 'Bill number',

        'customerDetails:noRegistrations': 'No registrations',
        'customerDetails:registrations': 'Registrations',
        'customerDetails:registrationTime': 'Registration time',
        'customerDetails:registrationEvent': 'Event',
        'customerDetails:registrationEventDate': 'Event date',
        'customerDetails:payments': 'Payments',
        'customerDetails:refunds': 'Refunds',
        'customerDetails:noPayments': 'No payments',
        'customerDetails:paymentDescriptionHeading': 'Description',
        'customerDetails:paymentAmountHeading': 'Amount',
        'customerDetails:billNumberHeading': 'Bill Number',
        'customerDetails:paymentStatusHeading': 'Status',
        'customerDetails:paymentMethodHeading': 'Payment Method',
        'customerDetails:paymentInfoHeading': 'Info',
        'customerDetails:emails': 'Emails',
        'customerDetails:noEmails': 'No emails',
        'customerDetails:emailTimeHeading': 'Time',
        'customerDetails:emailToHeading': 'To',
        'customerDetails:emalTemplateNameHeading': 'Type',
        'customerDetails:emailSubjectHeading': 'Subject',
        'customerDetails:emailStatusHeading': 'Status',
        'customerDetails:emailSentHeading': 'Sent',
        'customerDetails:results': 'Results',
        'customerDetails:resultsDate': 'Date',
        'customerDetails:resultsTrack': 'Track',
        'customerDetails:resultsSession': 'Session',
        'customerDetails:resultsPosition': 'Pos',
        'customerDetails:resultsLaps': 'Laps',
        'customerDetails:resultsBestLap': 'Best lap',
        'customerDetails:kartNumber': 'Kart',
        'customerDetails:resultsAverageLap': 'Avg lap',
        'customerDetails:noResults': 'No results',
        'customerDetails:newBooking': 'New booking',
        'customerDetails:auditLog': 'Audit log',
        'customerDetails:mergeHistory': 'Merge history',
        'customerDetails:undoMerge': 'Undo merge',
        'customerDetails:toAgeLessThanFromAge': 'To age cannot be less than from age',
        'customerDetails:ageRange': 'Age',

        'CustomerMergeForm:heading': 'Merge customers',
        'CustomerMergeForm:name': 'Name',
        'CustomerMergeForm:email': 'Email',
        'CustomerMergeForm:phone': 'Phone',
        'CustomerMergeForm:created': 'Created',
        'CustomerMergeForm:lastBooking': 'Last booking',
        'CustomerMergeForm:lastEvent': 'Last event',
        'CustomerMergeForm:selectPrimaryCustomer': 'Please select the customer you wish to merge TO',

        'ConfirmUndoMerge:heading': 'Confirm undo merge',
        'ConfirmUndoMerge:message': 'Please confirm you wan to undo this customer merge',
        'ConfirmUndoMerge:confirmUndo': 'Yes, undo merge',
        'ConfirmUndoMerge:customerName': 'Customer',
        'ConfirmUndoMerge:email': 'Email address',
        'ConfirmUndoMerge:mergeTime': 'Merged',


        'customerCreditDetails:title': 'Customer account credit',
        'customerCreditDetails:expiry': 'Expiry',
        'customerCreditDetails:balance': 'Balance',
        'customerCreditDetails:expire': 'Expire',
        'customerCreditDetails:confirmExpire': 'Confirm',

        'sessionResultDetails:heading': '{customerName} results',
        'sessionResultDetails:sessionName': 'Session',
        'sessionResultDetails:sessionDate': 'Start time',
        'sessionResultDetails:track': 'Track',
        'sessionResultDetails:position': 'Position',
        'sessionResultDetails:laps': 'Number of Laps',
        'sessionResultDetails:kartNumber': 'Kart',
        'sessionResultDetails:bestLap': 'Best laptime',
        'sessionResultDetails:averageLap': 'Average laptime',
        'sessionResultDetails:lapNumber': 'Lap No.',
        'sessionResultDetails:lapTime': 'Lap time',
        'sessionResultDetails:sectorHeading': 'S{sector}',

        'ChangePassword:title': 'Change password',
        'ChangePassword:mustChangePasswordText': 'Your password needs to be changed.  Please enter a new password',
        'ChangePassword:oldPassword': 'Old password',
        'ChangePassword:enterOldPassword': 'Enter old password',
        'ChangePassword:enterNewPassword': 'Enter new password',
        'ChangePassword:enterConfirmPassword': 'Enter confirm password',
        'ChangePassword:changePassword': 'Change password',

        'ConnectionStatus:status': 'Status:',
        'ConnectionStatus:lastConnected': 'Last connected:',
        'ConnectionStatus:lastConnectionAttempt': 'Last connection attempt:',

        'ResourceList:title': 'Resources',
        'ResourceList:addResource': 'Add resources',

        'ResourceForm:addResource': 'Add resource',
        'ResourceForm:resourceType': 'Resource type',
        'ResourceForm:name': 'Name',
        'ResourceForm:shortName': 'Short name',
        'ResourceForm:colour': 'Colour',
        'ResourceForm:sequence': 'Sequence',
        'ResourceForm:tasks': 'Tasks',
        'ResourceForm:configurations': 'Configurations',
        'ResourceForm:configNameHeading': 'Name',
        'ResourceForm:configCodeHeading': 'Code',

        'ResourceForm:numberOfLanes': 'Number of lanes',
        'ResourceForm:maxLaneCapacity': 'Max per lane',
        'ResourceForm:allowMixedGroupsInLane': 'Allow mixed groups in lane',

        'ResourceForm:addConfiguration': 'Add configuration',
        'ResourceForm:configNameLabel': 'Name',
        'ResourceForm:configCodeLabel': 'Code',
        'ResourceForm:arrivalTimeBeforeEvent': 'Arrival time before event start (HH:MM)',
        'ResourceForm:snapOnlineBookingsTo': 'Align web bookings every (x) minutes after opening time',
        'ResourceForm:snapOnlineBookingsToHint': 'E.g. if 20, bookings will be allowed every 20m, starting at the opening time. If  set to 20m and the venue opens at 9am the slots will be 9:00, 9:20, 9:40 etc.  If set to 15m and the the venue opens at 9:30 the slots will be 9:30, 9:45, 10:00 etc.',
        'ResourceForm:selectProduct': '<select product>',
        'ResourceForm:additionalProducts': 'Additional products',
        'ResourceForm:addProducts': 'Add product',
        'ResourceForm:additionalProductsProduct': 'Product',
        'ResourceForm:additionalProductsMin': 'Min qty',
        'ResourceForm:additionalProductsMax': 'Max qty',
        'ResourceForm:additionalProductsDefault': 'Default qty',
        'ResourceForm:additionalProductsUnit': 'Unit',
        'ResourceForm:breaks': 'Breaks',
        'ResourceForm:addBreak': 'Add break',
        'ResourceForm:startAfterEndValidation': 'End date cannot be before start date',
        'ResourceForm:overlappedResources': 'Overlapped resources',
        'ResourceForm:breakTextHeader': 'Text',
        'ResourceForm:breakStartTimeHeader': 'Start time',
        'ResourceForm:breakDurationHeader': 'Duration',
        'ResourceForm:breakActiveFromHeader': 'Start',
        'ResourceForm:breakActiveToHeader': 'End',

        'ResourceType:Circuit': 'Circuit',
        'ResourceType:Room': 'Room',

        'EventPage:backToDiary': 'back to diary',
        'EventPage:addActivity': 'Add activity',
        'EventPage:editBill': 'Edit bill',

        'VenuesPage:title': 'Venues',
        'VenuesPage:addVenue': 'Add venue',
        'VenuesPage:nameHeading': 'Name',
        'VenuesPage:venueNotFound': 'Venue not found',
        'VenuesPage:resourcesTabHeading': 'Resources',
        'VenuesPage:customerCategoriesTabHeading': 'Customer categories',
        'VenuesPage:taxRatesTabHeading': 'Tax rates',
        'VenuesPage:productCategoriesTabHeading': 'ProductCategories',
        'VenuesPage:productsTabHeading': 'Products',
        'VenuesPage:activityFormatsTabHeading': 'Activity formats',
        'VenuesPage:activityFormatGroupsTabHeading': 'Activity format groups',
        'VenuesPage:paymentMethodsTabHeading': 'Payment methods',
        'VenuesPage:paymentSchedulesTabHeading': 'Payment schedules',
        'VenuesPage:emailSchedulesTabHeading': 'Email schedules',
        'VenuesPage:termsTabHeading': 'Terms',
        'VenuesPage:emailTemplatesHeading': 'Email templates',
        'VenuesPage:websiteSettingsTabHeading': 'Website settings',
        'VenuesPage:kiosksTabHeading': 'Kiosks',
        'VenuesPage:integrationTokensTabHeading': 'Integration tokens',
        'VenuesPage:feesTabHeading': 'Fees',
        'VenuesPage:promotionsTabHeading': 'Promotions',
        'VenuesPage:leaderboardsTabHeading': 'Leaderboards',

        'VenueForm:addVenue': 'Add venue',
        'VenueForm:name': 'Name',
        'VenueForm:salesTaxNumber': 'Tax (VAT) registration number',
        'VenueForm:culture': 'Culture',
        'VenueForm:currency': 'Currency',
        'VenueForm:firstDayOfWeek': 'First day of week',
        'VenueForm:timeFormat': 'Time format',
        'VenueForm:dateFormat': 'Date format',
        'VenueForm:timeZone': 'Time zone',
        'VenueForm:openingHours': 'Standard opening hours',
        'VenueForm:termsAndConditions': 'Terms and conditions',
        'VenueForm:waiver': 'Waiver',
        'VenueForm:countersignatoryTerms': 'Counter signatory waiver',
        'VenueForm:finalInstructions': 'Final instructions',
        'VenueForm:confirmTermsText': 'Confirm terms & conditions text',
        'VenueForm:confirmWaiverText': 'Confirm waiver text',
        'VenueForm:confirmTermsTextHelp': 'The text that appears next to the confirm term and conditions tick box',
        'VenueForm:resultsMarketingText': 'Results marketing permission text',
        'VenueForm:generalMarketingText': 'General marketing permission text',
        'VenueForm:minAgeWarningThreshold': 'Warn if age less than',
        'VenueForm:minAge': 'Minimum age for participation',
        'VenueForm:minAgeForSelfRegistration': 'Require countersignature if age less than',
        'VenueForm:confirmCounterSignatoryWaiverText': 'Confirm counter signatory waiver text',
        'VenueForm:separateCounterSignatoryTermsRequired': 'Use additional counter signatory waiver',
        'VenueForm:confirmCounterSignatoryTermsTextHelp': 'The text that appears next to the confirm counter signatory waiver tick box',
        'VenueForm:publicWebsiteName': 'Public website name',
        'VenueForm:publicWebsiteNameHint': 'this will form part of the address of your public pages.  Note: it must be unique across all of our clients',
        'VenueForm:webBookingExpiry': 'Web booking expiry in minutes',
        'VenueForm:webBookingFailedPaymentTimeout': 'Web booking expiry (with failed payment) in minutes',
        'VenueForm:webBookingFailedPaymentTimeoutHint': 'Leave blank to not auto-cancel bookings with failed payments',
        'VenueForm:webBookingIncompletePaymentTimeout': 'Web booking expiry (with incomplete payment) in minutes',
        'VenueForm:webBookingIncompletePaymentTimeoutHint': 'Leave blank to not auto-cancel bookings with incomplete paymFactivitysales:ents',
        'VenueForm:webBookingMinTimeFromEventStart': 'Minimum time before event start time that a web booking can be made',
        'VenueForm:defaultTerms': 'Default terms',
        'VenueForm:defaultWaiver': 'Default waiver',
        'VenueForm:lightLogo': 'Light logo',
        'VenueForm:darkLogo': 'Dark logo',
        'VenueForm:noExpiry': 'No expiry',
        'VenueForm:requireLatestTerms': 'Require latest waiver to manually register a customer',
        'VenueForm:defaultRegistrationTerms': 'Default waiver',
        'VenueForm:closeTimeBehaviour': 'Last available slot should',

        'VenueForm:confirmTermsTextTagsText': 'You can use these tags in the Confirm terms & conditions text',
        'VenueForm:confirmCounterSignatoryTermsTextTagsText': 'You can use these tags in the Confirm counter signatory terms & conditions text',

        'VenueFormTag:customer_name': 'The registrering customer\'s name',
        'VenueFormTag:dependents_name': 'The name(s) of the dependents being registered',

        'validation:required': 'Required value',
        'validation:notFound': 'Not found',
        'validation:serverErrors': 'Please correct errors and try again',
        'validation:insufficientPermission': 'You do not have permission to perform this action',
        'validation:duplicateUsername': 'This username is already taken.  Please choose a different one',
        'validation:usernameRequirementsNotMet': 'The username must contain an @ symbol, followed by at least 3 characters.  Usernames don\'t have to be valid email addresses, but must be linked to your venue.  You can either use your domain after the @ symbol, or an abbeviation of your venue name.',
        'validation:dateInPast': 'Date cannot be in past',
        'validation:taskAlreadyActivated': 'The task has already been activated',
        'validation:invalidUrlCharacters': 'Text can only contain letters, numbers and the characters _ and -',
        'validation:invalidEmail': 'Please enter a valid email address',

        'EventForm:addEvent': 'Add event',
        'EventForm:editEvent': 'Edit event',
        'EventForm:detailsTab': 'Details',
        'EventForm:bookingsTab': 'Bookings',
        'EventForm:registrationsTab': 'Registrations',
        'EventForm:registrationListUrl': 'Public registration list URL',
        'EventForm:NoBookingSelected': 'No booking selected',
        'EventForm:name': 'Event name',
        'EventForm:participants': 'Participants',
        'EventForm:resourceType': 'Type',
        'EventForm:startTime': 'Start time',
        'EventForm:endTime': 'End time',
        'EventForm:details': 'Event details',
        'EventForm:activities': 'Activities',
        'EventForm:customer': 'Customer',
        'EventForm:customers': 'Customers',
        'EventForm:contactName': 'Contact name',
        'EventForm:contactNamePlaceholder': 'Search for a customer',
        'EventForm:selectCustomer': 'Select customer',
        'EventForm:changeCustomer': 'Change customer',
        'EventForm:bills': 'Billing',
        'EventForm:maxParticipants': 'Max participants',
        'EventForm:generalNotes': 'General booking notes',
        'EventForm:selectActivityFormats': 'Please select the format for each activity',
        'EventForm:tasks': 'Tasks',
        'EventForm:registrations': 'Registrations',
        'EventForm:autoSaveComplete': 'Changes auto-saved',
        'EventForm:autoSaveCompleteNewEvent': 'Changes auto-saved as draft',
        'EventForm:eventSpecificProductCategory': 'Event',
        'EventForm:deleteEvent': 'Delete event',
        'EventForm:deleteEventHeading': 'Confirm delete event',
        'EventForm:deleteEventText': 'Are you sure you want to delete event {eventName}',
        'EventForm:yesDeleteEvent': 'Yes, delete event',
        'EventForm:cancelEvent': 'Cancel event',
        'EventForm:reinstateEvent': 'Reinstate event',
        'EventForm:cancelEventHeading': 'Confirm cancel event',
        'EventForm:cancelEventText': 'Are you sure you want to cancel event {eventName}',
        'EventForm:yesCancelEvent': 'Yes, cancel event',
        'EventForm:cancellationReason': 'Cancellation reason',
        'EventForm:selectActivityFormatVariations': 'Please select activity format',
        'EventForm:validation:endTimeBeforeStartTime': 'End time cannot be before start time',
        'EventForm:correctActivityTimes': 'Please correct activity times before saving',
        'EventForm:selectType': 'Please select whether event is exclusive or non-exclusive',
        'EventForm:missingActivityFormatMessage': 'One or more reservations has a missing or invalid activity format.  Please correct in the details tab.',
        'EventForm:noResultsUrl': 'Results for this event have not been published online.  If the session has already been run, please ensure timing has been ended for this session.',
        'EventForm:editBookingQuestions': 'Edit booking questions',

        'BookingConfirmationEmails:heading': 'Booking confirmation email',
        'BookingConfirmationEmails:customerHeading': 'Customer',
        'BookingConfirmationEmails:emailAddressHeading': 'Email address',
        'BookingConfirmationEmails:templateHeading': 'Email template',
        'BookingConfirmationEmails:dontSendEmail': 'Don\'t send email',
        'BookingConfirmationEmails:sendSelected': 'Send selected emails',
        'BookingConfirmationEmails:sendEmailFailed': 'Faild to send email',

        'EventReservations.selectActivityFormat': '<select format>',
        'EventReservations.format': 'format',
        'EventReservations.maxParticipants': 'Par',
        'EventReservations.notes': 'Activity notes',
        'EventReservations:addActivityNotes': '+ Add activity notes',

        'CreateEvent:notAllSlotsAvailable': 'One or more items could not be allocated.  Please either select a new time, or allocate manually',
        'CreateEvent:timesOutOfSequence': 'Time must be later than previous item',
        'CreateEvent:onlyNewReservationsWhenNoCustomer': 'Only new reservations can be created when no customer is selected',
        'CreateEvent:noEventSelected': 'No item selected.  Click on an schedule item above to view details and to change time or add to existing reservation',
        'CreateEvent:existingReservationDetails': 'Booking will be added to existing reservation',
        'CreateEvent:newReservationDetails': 'New reservation will be created',
        'CreateEvent:remainingSpaces': 'places available',
        'CreateEvent:insufficientSpaceForBooking': 'WARNING: There is not enough free space at the selected time',
        'CreateEvent:overlappingReservation': 'There is already an existing reservation at the selected time',
        'CreateEvent:selectCustomerQuantities': 'Please select required quantities before checking availability',
        'CreateEvent:selectActibityFormat': 'Please select the activity format for this reservation',
        'CreateEvent:selectActibityFormatVariation': 'Please select the activity format schedule for this reservation',
        'CreateEvent:selectStartTime': 'Plese select the start time for this booking',
        'CreateEvent:clickCheckAvailability': 'Please click the check availability button to confirm availability for this booking',
        'CreateEvent:endTimeBeforeStart': 'End time cannot be before start time',
        'CreateEvent:alreadyBooked': 'This customer already has a booking at this time.  Please update their booking rather than adding a new one',
        'CreateEvent:customerNotMemberWarning': 'WARNING: this customer does not have a membership required for this activity',

        'BookingExtraFields:customFields': 'Booking questions',

        'ReservationDetailsPage:resource': 'Resource',
        'ReservationDetailsPage:activityDetails': 'Activity details',
        'ReservationDetailsPage:bookingDetails': 'Booking details',
        'ReservationDetailsPage:bookingQuantities': 'Booking quantities',
        'ReservationDetailsPage:noCustomerSelected': 'Select customer to add a booking to this event.  If no customer is selected an empty event will be created',
        'ReservationDetailsPage:reservationType': 'Reservation type',
        'ReservationDetailsPage:activityFormat': 'Activity',
        'ReservationDetailsPage:maxParticipants': 'Max participants',
        'ReservationDetailsPage:numberOfParticipants': 'Number of participants',
        'ReservationDetailsPage:activityFormatVariation': 'Schedule',
        'ReservationDetailsPage:date': 'Date',
        'ReservationDetailsPage:dateRange': 'Time',
        'ReservationDetailsPage:startTime': 'Start time',
        'ReservationDetails:deleteReservation': 'Delete reservation',
        'ReservationDetailsPage:notes': 'Notes for this activity',
        'ReservationDetailsPage:exclusiveBookingDescription': 'Based on the number of participants, this has been set as an exclusive activity.  Other groups will not be permitted to join the activity at the same time',
        'ReservationDetailsPage:switchToPublic': 'Switch to public',
        'ReservationDetailsPage:publicBookingDescription': 'Based on the number of participants, this has been set as a shared activity.  Other groups will be permitted to join this activity',
        'ReservationDetailsPage:switchToExclusive': 'Switch to exclusive',
        'ReservationDetailsPage:cantChangeMultiScheduleActivity': 'This reservation is part of a multi-item schedule and cannot be changed',
        'ReservationDetails:invalidProducts': 'One or more products are not valid for this activity.  Please update product selections',
        'ReservationDetails:invalidActivityFormat': 'The selected activity format is not valid',
        'ReservationDetails:variationScheuleSequenceMissmatch': 'The selected activity format does not have a matching schedule item',
        'ReservationDetails:checkAvailability': 'Check availability',
        'ReservationDetailsPage:dateRangeOutOfOrder': 'End time must be after start time',
        'ReservationDetailsPage:eventName': 'Event name',
        'ReservationDetailsPage:noMemberRestriction': '<No member restriction>',
        'ReservationDetailsPage:memberRestriction': 'Membership restriction',

        'EventCustomers:addCustomer': 'Add customer',
        'EventCustomers:isOrganiser': 'Event organiser?',
        'EventCustomers:emailMarketing': 'Agreed to email marketing?',
        'EventCustomers:emailResults': 'Agreed to email results?',
        'EventCustomers:eventNameValidationsError': 'Please correct errors before adding customer',
        'EventCustomers:payments': 'Payments',
        'EventCustomers:refunds': 'Refunds',
        'EventCustomers:duePayments': 'Outstanding payments',
        'EventCustomers:bill': 'bill',
        'EventCustomerForm:enterDetails': 'Please enter customer details',
        'EventCustomers:addSessions': 'Add sessions',
        'EventCustomers:reschedule': 'Reschedule',
        'EventCustomers:cancelBooking': 'Cancel booking',
        'EventCustomers:reinstateBooking': 'Reinstate',
        'EventCustomers:clickToSelectCustomer': 'Click to select customer',
        'EventCustomers:completeBooking': 'Complete web booking',
        'EventCustomers:noBookingSelected': 'No booking selected',
        'EventCustomers:showCancelled': 'Show {count} cancelled bookings',
        'EventCustomers:MissingReservationLinks': 'No bill items are linked to the reservation.  Click on the relevant items above to add the links.',
        'EventCustomerForm:customerPhoto': 'Customer photo',
        'EventCustomerForm:gender': 'Gender',

        'BookingCommunications:communications': 'Communications',
        'BookingCommunications:emailType': 'Email type',
        'BookingCommunications:emailAddress': 'Email address',
        'BookingCommunications:sendEmail': 'Send email',
        'BookingCommunications:registrationLink': 'Registration link',
        'BookingCommunications:selectEmailTemplate': 'Please select an email template',
        'BookingCommunications:enterEmailAddress': 'Please enter the email address to send to',
        'BookingCommunications:emailSent': 'Email sent successfully',
        'BookingCommunications:noRegistrationLink': 'Unable to generate registration link for this booking.  Make sure the bill has an activity product',
        'BookingCommunications:paymentLink': 'Payment link',

        'BookingNotes:internalNotes': 'Internal notes / history',

        'CustomerBookingNotes:notes' : 'Notes for customer',

        'EditCustomerBookingNotes:heading': 'Edit notes for customer',
        'EditCustomerBookingNotes:notes': 'Notes',

        'CancelBooking:heading': 'Confirm cancel booking',
        'CancelBooking:totalPaid': 'Total paid',
        'CancelBooking:outstandingBalance': 'Outstanding balance',
        'CancelBooking:cancellationReason': 'Cancellation reason',

        'BookingReservationSelection:time': 'Time',
        'BookingReservationSelection:activity': 'Activity',
        'BookingReservationSelection:product': 'Product',
        'BookingReservationSelection:productTagId': 'Price',
        'BookingReservationSelection:price': 'Price',
        'BookingReservationSelection:quantity': 'Qty',
        'BookingReservationSelection:places': 'Places',
        'BookingReservationSelection:addBooking': 'Add booking',
        'BookingReservationSelection:addToBooking': 'Add to booking',
        'BookingReservationSelection:moveToBooking': 'Move to booking',
        'BookingReservationSelection:noProductsFound': 'No suitable products found for this activity.  Please check activity format configuration',
        'BookingReservationSelection:customerCategory': 'Category',
        'BookingReservationSelection:noProductSelected': 'Please select product',
        'BookingReservationSelection:missingActivityFormatProductCategorySelections': 'This activity format is misconfigured.  Please edit this avtivity format in venue settings and tick the relevant customer categories in the products section',


        'ReservationBookingSelection:customer': 'Customer',
        'ReservationBookingSelection:maxParticipants': 'Participants',
        'ReservationBookingSelection:product': 'Product',
        'ReservationBookingSelection:quantity': 'Qty',
        'ReservationBookingSelection:places': 'Places',

        'AddEventToBooking:resource': 'Resource',
        'AddEventToBooking:selectResource': 'Please select a resource',
        'AddEventToBooking:selectReservation': 'Click on an existing event to add this booking to, or click on a spare slot in the diary to create a new event to add this booking to',

        'RescheduleBooking:selectReservation': 'Please select the reservation to reschedule',
        'RescheduleBooking:rescheduled': 'Rescheduled',
        'RescheduleBooking:pending': 'Pending',


        'registrations:name': 'Name',
        'registrations:age': 'Age',
        'registrations:category': 'Cat',
        'registrations:emailAddress': 'Email',
        'registrations:phoneNumber': 'Phone No',
        'registrations:resultEmail': 'Send results',
        'registrations:registered': 'Registered',
        'registrations:agreedTerms': 'Terms agreed',
        'registrations:sent': 'Sent',
        'registrations:requested': 'Requested',
        'registrations:email': 'Email',
        'registrations:minAgeNotMet': 'Your age {age} is below the minimum {minAge}',
        'registrations:waiver': 'Waiver',

        'registrationDetails:eventName': 'Event name',
        'registrationDetails:eventDate': 'Event date',
        'registrationDetails:customerName': 'Customer name',
        'registrationDetails:customerDateOfBirth': 'Date of Birth',
        'registrationDetails:terms': 'Agreed waiver',
        'registrationDetails:emailAddress': 'Email',
        'registrationDetails:phoneNumber': 'Phone No',
        'registrationDetails:address': 'Address',
        'registrationDetails:registrationTime': 'Time registered',
        'registrationDetails:counterSignatoryTerms': 'Countersigned waiver',
        'registrationDetails:counterSignedBy': 'Countersigned by',
        'registrationDetails:registeredByName': 'Registered by',
        'registrationDetails:emergencyContact': 'Emergency contact',

        'ReportTypes:Payments': 'Payments',
        'ReportTypes:ProductSales': 'Product Sales',
        'ReportTypes:ActivitySales': 'Activity Sales',
        'ReportTypes:Bookings': 'Bookings',
        'ReportTypes:DeletedEvents': 'Deleted Events',
        'ReportTypes:BillSummary': 'Bill Summary',
        'ReportTypes:Vouchers': 'Vouchers',
        'ReportTypes:Customers': 'Customers',
        'ReportTypes:WebAnalysis': 'Web Booking Analysis',
        'ReportTypes:Registrations': 'Registrations',

        'CustomerSearch:nameHeading': 'Name',
        'CustomerSearch:ageHeading': 'Age',
        'CustomerSearch:emailHeading': 'Email',
        'CustomerSearch:phoneHeading': 'Phone',
        'CustomerSearch:postcodeHeading': 'Postcode',
        'CustomerSearch:lastEventHeading': 'Last event',
        'CustomerSearch:companyHeading': 'Company',
        'CustomerSearch:addressHeading': 'Address',
        'CustomerSearch:registerHeading': 'Registration',
        'CustomerSearch:kioskHeding': 'Kiosk',
        'CustomerSearch:customerCatHeding': 'Cat',
        'CustomerSearch:lastVisitHeading': 'Last visit',
        'CustomerSearch:addSelected': 'Add selected',

        'CustomerSearch:search': 'Search',
        'CustomerSearch:searchPlaceholder': 'Search (enter at least 3 characters)',
        'CustomerSearch:searchHint': 'Enter at least 3 characters to search',
        'CustomerSearch:enterSearch': 'Enter name to search for - partial names e.g. joh smi (for john smith) also work',
        'CustomerSearch:searching': 'Searching....',
        'CustomerSearch:noCustomersFound': 'No customers found',
        'CustomerSearch:newCustomer': 'New customer',
        'CustomerSearch:registrationRequired': 'No waiver',
        'CustomerSearch:registrationNonCurrentWaiver': 'Old waiver',
        'CustomerSearch:emailLink': 'Email link',
        'CustomerSearch:selectedCustomers': 'Selected customers',
        'CustomerSearch:error_noGeneralLinkEmailTemplate': 'No General registration link email template found.',
        'CustomerSearch:error_failedToSendEmail': 'Failed to send email',
        'CustomerSearch:emailSentTo{emailAddress}': 'Email sent to {emailAddress}',
        'CustomerSearch:minThreeCharacters': 'Please enter at least 3 characters',

        'EventBills:selectCustomerMessage': 'Select a customer to view their bill',
        'EventBills:total': 'Total',
        'EventBills:paid': 'Paid',
        'EventBills:places': 'Places',

        'EventBill:itemHeader': 'Item',
        'EventBill:taxRateHeader': 'Tax rate',
        'EventBill:quantityHeader': 'Quantity',
        'EventBill:discountHeader': 'Disc',
        'EventBill:subTotalheader': 'Sub total',
        'EventBill:addBillItem': 'Add item',
        'EventBill:newBill': 'New bill',
        'EventBill:paymentDue': 'Payment due',
        'EventBill:createNewBill{n}': 'Create bill for {n}',

        'BillItemForm:activityProduct': 'Activity product',
        'BillItemForm:quantity': 'Quantity',
        'BillItemForm:taxRate': 'Tax rate',
        'BillItemForm:selectProduct': '<select product>',
        'BillItemForm:selectTaxRate': '<select tax rate>',
        'BillItemForm:reservation': 'Reservation',
        'BillItemForm:selectReservation': '<select reservation>',
        'BillItemForm:voucherAmount': 'Voucher amount',

        'EmailTemplatesPage:title': 'Email templates',
        'EmailTemplatesPage:emailTypeHeading': 'Type',
        'EmailTemplatesPage:nameHeading': 'Name',
        'EmailTemplatesPage:subjectHeading': 'Subject',
        'EmailTemplatesPage:addEmailTemplate': 'Add email template',
        'EmailTemplatesPage:SendTest': 'Send test',

        'EmailTemplateForm:subjectTemplate': 'Subject',
        'EmailTemplateForm:emailType': 'Email type',
        'EmailTemplateForm:textTemplate': 'Text body',
        'EmailTemplateForm:htmlTemplate': 'Html body',
        'EmailTemplateForm:emailTags': 'Email tags',
        'EmailTemplateForm:switchToFullHtml': 'Switch to HTML input',
        'EmailTemplateForm:emailTagsText': 'Use these tags in the subject, text body and html body to insert data into your templates',
        'EmailTemplateForm:addEmailTemplate': 'Add email template',
        'EmailTemplateForm:selectEmailType': '<select email type>',
        'EmailTemplateForm:attachments': 'Attachments',
        'EmailTemplateForm:ccEmailAddress': 'CC email address',
        'EmailTemplateForm:bccEmailAddress': 'BCC email address',
        'EmailTemplateForm:loadingTemplate': 'Loading email template editor...',
        'EmailTemplateForm:Unknown tags {tags}': 'Unknown tags {tags}',
        'EmailTemplateForm:sendFromEmailAddressOverride': 'Send from email address override',
        'EmailTemplateForm:sendFromEmailAddressOverrideHint': 'Leave blank to use the venue\'s standard send from email address',
        'EmailTemplateForm:sendFromEmailNameOverride': 'Send emails from name override',
        'EmailTemplateForm:sendFromEmailNameOverrideHint': 'Leave blank to use the venue\'s standard send from email from name',
        'EmailTemplateForm:showActivityStartTimes': 'Show activity start times',

        'SendTestEmail:title': 'Send test email',
        'SendTestEmail:sendTo': 'Send to',
        'SendTestEmail:tagValues': 'Tag values',
        'SendTestEmail:sendingEmail': 'Sending email',
        'SendTestEmail:emailSent': 'Email sent',

        'EmailTag:company_name': 'The name of your company',
        'EmailTag:user_username': 'The user\'s username',
        'EmailTag:user_first_name': 'The user\'s first name',
        'EmailTag:user_last_name': 'The user\'s last name',
        'EmailTag:user_full_name': 'The user\'s full name',
        'EmailTag:reset_password_link': 'The reset password link',
        'EmailTag:customer_first_name': 'The customer\'s first name',
        'EmailTag:customer_last_name': 'The customer\'s last name',
        'EmailTag:customer_full_name': 'The customer\'s full name',
        'EmailTag:customer_phone_number': 'The customer\'s phone number',
        'EmailTag:customer_email': 'The customer\'s email address',
        'EmailTag:event_name': 'The name of the event',
        'EmailTag:activity_name': 'The name of the first activity',
        'EmailTag:activity_description': 'The description of the first activity',
        'EmailTag:activity_image': 'The image for the first activity. To set width and height add :w=100&h=70 before closing }} (replace 100 with desired width and 70 with desired height). E.g. {{activity_image:w=200&h=120}}',
        'EmailTag:results_link': 'The link to view their results. To set the text for the link add :text=the text the closing }} (e.g. {{results_link:text=click to view your results}})',
        'EmailTag:plain_results_url': 'The unformatted URL to view their results. NOTE: Only use as the href on an a tag where advanced formatting is required',

        'EmailTag:event_date': 'The date of the event',
        'EmailTag:event_start_time': 'The start time of the event',
        'EmailTag:event_arrival_time': 'The arrival time of the event',
        'EmailTag:bill_total': 'The total bill amount',
        'EmailTag:bill_total_ex_tax': 'The total bill amount Excluding Tax',
        'EmailTag:bill_number': 'The bill reference number',
        'EmailTag:tax_total': 'The total tax amount',
        'EmailTag:remaining_balance': 'The remaining balance amount',
        'EmailTag:product_list': 'A list of the products for the booking',
        'EmailTag:payments': 'A list of the settled payments',
        'EmailTag:payment_schedule': 'The scheduled payments for the booking',
        'EmailTag:registration_link': 'A link to register participants for the event. To set the text for the link add :text=the text the closing }} (e.g. {{registration_link:text=click to check in for your event}})',
        'EmailTag:plain_registration_url': 'An unformatted URL to register participants for the event. NOTE: Only use as the href on an a tag where advanced formatting is required',
        'EmailTag:total_participants':'The total number of booked participants',
        'EmailTag:participants_by_category': 'A breakdown of booked participants by category',
        'EmailTag:todays_date': 'The current date',
        'EmailTag:notes_for_customer': 'Notes for customer',

        'EmailTag:voucher_name': 'The voucher product name',
        'EmailTag:voucher_code': 'The voucher code',
        'EmailTag:voucher_description': 'The voucher description',
        'EmailTag:voucher_redemption_amount': 'The redemption amount of the voucher',
        'EmailTag:voucher_expiry': 'The expiry date of the voucher',
        'EmailTag:voucher_image_url': 'The url of the voucher image',
        'EmailTag:voucher_image': 'The voucher image',

        'MarketingEmailTag:first_name': 'The customer\'s first name',
        'MarketingEmailTag:last_name': 'The customer\'s last name',
        'MarketingEmailTag:full_name': 'The customer\'s full name',

        'PaymentAmountType:FixedAmount': 'Fixed amount',
        'PaymentAmountType:Percentage': 'Percentage of bill total',
        'PaymentAmountType:AmountPerPerson': 'Fixed amount per person',

        'PaymentIntervalType:AfterBookingDate': 'Days after booking made',
        'PaymentIntervalType:BeforeEventDate': 'Days before activity date',

        'EmailsPage:title': 'Emails',
        'EmailsPage:timeHeading': 'Time',
        'EmailsPage:addressHeading': 'To',
        'EmailsPage:templateHeading': 'Type',
        'EmailsPage:subjectHeading': 'Subject',
        'EmailsPage:typeHeading': 'Type',
        'EmailsPage:statusHeading': 'Status',
        'EmailsPage:timeSentHeading': 'Sent',

        'EmailDetailsPage:to': 'To',
        'EmailDetailsPage:status': 'Status',
        'EmailDetailsPage:createdBy': 'Created by',
        'EmailDetailsPage:subject': 'Subject',
        'EmailDetailsPage:customer': 'Customer',
        'EmailDetailsPage:latestEmailAddress': 'Customer\'s current email address',
        'EmailDetailsPage:resendToNewAddress': 'Re-send to this address',
        'EmailDetailsPage:textBody': 'Text body',
        'EmailDetailsPage:htmlBody': 'Html body',
        'EmailDetailsPage:emailType': 'Type',
        'EmailDetailsPage:template': 'Template',
        'EmailDetailsPage:processedDateTime': 'Time processed',
        'EmailDetailsPage:processingError': 'Processing error',
        'EmailDetailsPage:sentUsing': 'Sent using',
        'EmailDetailsPage:apiResponse': 'Response',
        'EmailDetailsPage:sendEmail': 'Send',
        'EmailDetailsPage:fromDate': 'From Date',
        'EmailDetailsPage:toDate': 'To Date',
        'EmailDetailsPage:toEmail': 'Send to email address',
        'EmailDetailsPage:attachments': 'Attachments',
        'EmailDetailsPage:resendTo': 'Resend to',
        'EmailDetailsPage:events': 'Events',


        'VenueRegistrationPanel:title': 'Registration kiosks',
        'VenueRegistrationPanel:registrationcode': 'Code',
        'VenueRegistrationPanel:notConfigured': 'Registration kiosk has not been configured.  Click here to begin setup',
        'VenueRegistrationPanel:nameHeading': 'Name',
        'VenueRegistrationPanel:codeHeading': 'Code',
        'VenueRegistrationPanel:addKiosk': 'Add kiosk',

        'VenueIntegrationsPanel:title': 'Integrations',
        'VenueIntegrationsPanel:timingToken': 'Timing token',
        'VenueIntegrationsPanel:addIntegration': 'Add integration',
        'VenueIntegrationsPanel:revokeToken': 'Revoke token',
        'VenueIntegrationsPanel:confirmRevokeTokenMessage': 'Are you sure you want to revoke this token?  If revoked all future access using this token will be denied.',
        'VenueIntegrationsPanel:confirmRevokeButtonText': 'Yes, revoke',

        'RegistrationSettingsForm:title': 'Registration kiosk settings',
        'RegistrationSettingsForm:changePassword': 'change password',
        'RegistrationSettingsForm:password': 'Password',

        'RegistrationSettingsForm:setting_collect': 'Collect',
        'RegistrationSettingsForm:setting_required': 'Required',
        'RegistrationSettingsForm:customerInformation': 'Customer information',
        'RegistrationSettingsForm:setting_firstName': 'First name',
        'RegistrationSettingsForm:setting_lastName': 'Last name',
        'RegistrationSettingsForm:setting_dateOfBirth': 'Date of birth',
        'RegistrationSettingsForm:setting_yearOfBirth': 'Birth year',
        'RegistrationSettingsForm:setting_basicAddress': 'Basic address',
        'RegistrationSettingsForm:setting_fullAddress': 'Full address',
        'RegistrationSettingsForm:setting_postcode': 'Postcode',
        'RegistrationSettingsForm:setting_emailAddress': 'Email address',
        'RegistrationSettingsForm:setting_phoneNumber': 'Phone number',
        'RegistrationSettingsForm:setting_emergencyContactName': 'Emergency contact name',
        'RegistrationSettingsForm:setting_emergencyContactNumber': 'Emergency contact number',
        'RegistrationSettingsForm:setting_nickname': 'Nickname',
        'RegistrationSettingsForm:setting_gender': 'Gender',
        'RegistrationSettingsForm:welcomeMessage': 'Welcome message',
        'RegistrationSettingsForm:welcomeScreenImages': 'Welcome screen images',
        'RegistrationSettingsForm:backgroundImage': 'Background image',
        'RegistrationSettingsForm:welcomeScreenImageInterval': 'Change image every n seconds',
        'RegistrationSettingsForm:nameStepHeading': 'Name page header text',
        'RegistrationSettingsForm:addressStepHeading': 'Address page header text',
        'RegistrationSettingsForm:returningCustomerStepHeading': 'Returning customer page header text',
        'RegistrationSettingsForm:contactPreferencesStepHeading': 'Contact preferences page header text',
        'RegistrationSettingsForm:kioskName': 'Kiosk name',
        'RegistrationSettingsForm:requireSignatures': 'Require signatures',
        'RegistrationSettingsForm:requireAddressForChildren': 'Require address for children',

        'Registration:newRegistration': 'Next customer',
        'Registration:startRegistration': 'Start registration',
        'Registration:welcomeBack{n}': 'Welcome {n}',
        'Registration:welcomeBackMessage': 'It looks like you have visited us before.',
        'Registration:confirmPostcode': 'Please type in your postcode to confirm we have the right person.',
        'Registration:unmatchedPostcode': 'We can\'t find an account with this postcode.  Click next and we\'ll create a new one for you.',
        'Registration:confirmPasswordNotes': 'Note: If you have moved since your last visit, please enter your old postcode, then inform a member of our staff who will update your details.',
        'Registration:registerAsNewCustomer': 'This isn\'t me',
        'Registration:restart': 'cancel',
        'Registration:counterSignatoryFirstName': 'Parent / Guardian first name',
        'Registration:counterSignatoryLastName': 'Parent / Guardian last name',
        'Registration:signature': 'Please sign in the box below',
        'Registration:nicknameHint': 'Optional nickname to use on results.  Leave blank to use your full name',

        'RegistrationLogin:code': 'Registration code',

        'ReceptionPage:title': 'Registrations',
        'ReceptionPage:registrationsHeading': 'Registrations',
        'ReceptionPage:eventsHeading': 'Events',
        'ReceptionPage:bookingsHeading': 'Bookings',
        'ReceptionPage:registrationTimeColHeading': 'Time',
        'ReceptionPage:eventsColHeading': 'Event',
        'ReceptionPage:addToEvent': 'Add to event',
        'ReceptionPage:checkin': 'Check in',
        'ReceptionPage:undoCheckin': 'Undo check in',
        'ReceptionPage:searchCustomers': 'Find customer',
        'ReceptionPage:addToSelectedBooking': 'Add to selected booking',
        'ReceptionPage:selectBooking': 'Please select or create a booking',
        'ReceptionPage:selectEvent': 'Please select an event',
        'ReceptionPage:addToEventTitle': 'Add customers to event',
        'ReceptionPage:customersHeading': 'Customers',
        'ReceptionPage:addPayment': 'Payment',
        'ReceptionPage:registeredCustomers': 'Registered customers',
        'ReceptionPage:dateWarning{date}': 'WARNING: You are showing events and registrations for {date}',
        'ReceptionPage:createBooking': 'Create booking',
        'ReceptionPage:payOutstanding': 'Pay outstanding',
        'ReceptionPage:payIndividually': 'Pay individually',
        'ReceptionPage:showingAllKiosks': 'All kiosks',
        'ReceptionPage:kiosks': 'Showing registrations for',
        'ReceptionPage:filterKiosks': 'Filter kiosks',
        'ReceptionPage:addToEventTitle{outstandingAmount}': '{outstandingAmount} outstanding',
        'ReceptionPage:unassignedRegistrations': 'Unassigned registrations',
        'ReceptionPage:assignedRegistrations': 'Assigned registrations',
        'ReceptionPage:booked': 'Booked',
        'ReceptionPage:registered': 'Registered',
        'ReceptionPage:checkedId': 'Checked-in',
        'ReceptionPage:noBookings': 'No bookings found',
        'ReceptionPage:registerDriver': '+ Register person for booking {billNumber}',
        'ReceptionPage:registerOrganiser': 'Register {organiserName}',
        'ReceptionPage:unregister': 'Unregister',
        'ReceptionPage:registrationQueue': 'Registration queue',
        'ReceptionPage:emptyQueueMessage': 'Queue is empty',
        'ReceptionPage:findCustomers': 'Search customers',
        'ReceptionPage:searchResults': 'Search results',
        'ReceptionPage:noRegistrations': 'This booking does not yet have any registrations',
        'ReceptionPage:addBooking':'Add booking',
        'ReceptionPage:bookingNotesHeading': 'Notes for booking {billNumber} - {name}',
        'ReceptionPage:noEvents': 'No bookings found',
        'ReceptionPage:addToBooking': 'Add selected to booking',
        'ReceptionPage:createNewBooking': 'Add new booking',
        'ReceptionPage:customersToRegister': 'Customers to register',
        'ReceptionPage:noCustomersSelected': 'No customers selected',
        'ReceptionPage:addToExistingBooking': 'Add to existing booking',
        'ReceptionPage:addToNewBooking': 'Add to new booking',
        'ReceptionPage:customersRegistered': 'Complete customer registration',
        'ReceptionPage:registeringMessage': 'Registering customers, please wait...',

        'EditRegistration:customerCategory': 'Customer category',
        'EditRegistration:InvalidDob': 'Date of birth is not valid',
        'EditRegistration:cannotDelesctAllEvents': 'At least ome session must be selected.  To remove completely, please click unregister',
        'EditRegistration:addCategory': 'Add customer category',
        'EditRegistration:customFields': 'Custom fields',

        'ReceptionKioskFilter:header': 'Select kiosks',

        'IntegrationsPage:title': 'Integrations',
        'IntegrationsPage:addIntegration': 'Add integration',
        'IntegrationsPage:nameHeading': 'Name',
        'IntegrationsPage:typeHeading': 'Type',
        'IntegrationsPage:providerHeading': 'Provider',

        'IntegrationForm:addIntegration': 'Add integration',
        'IntegrationForm:integrationType': 'Integration type',

        'TransactionalEmail:selectIntegrationType': '<Select integration type>',
        'TransactionalEmail:TransactionalEmail': 'Transactional email',

        'IntegrationImplType:selectType': '<Select email provider>',
        'IntegrationImplType:EmailSendGrid': 'Send grid',
        'IntegrationForm:implementationType': 'Provider',

        'PaymentGatewayForm:globalSettings': 'Global settings',

        'sendgridSettings:apiKey': 'API Key',
        'sendgridSettings:webhookPublicKey': 'Webhook public key',

        'ForgottenPassword:title': 'Forogtten password',
        'ForgottenPassword:forgottenPasswordText': 'To re-set your password, enter your username below and click send re-set instructions',
        'ForgottenPassword:sendInstructions': 'Send re-set instructions',
        'ForgottenPassword:resetSuccessMessage': 'We have sent a reset email to your registered email address containing reset instructions.',

        'ResetPassword:title': 'Reset password',
        'ResetPassword:resetPasswordText': 'Please confirm your username then type your new password below.  You will need to enter the password a second time to confirm you have typed it correctly',
        'ResetPassword:username': 'Please confirm your username',
        'ResetPassword:invalidUrl': 'The reset link is not valid.  Please ensure that the full link from your email appears in the browser address bar',
        'ResetPassword:resetPassword': 'Reset password',
        'ResetPassword:invalidToken': 'Your reset token is invalid.  Please click cancel and request a new forgotten password email.',
        'ResetPassword:resetError': 'An error occurred resetting your password.  Please re-try.  If the problem persists, please click cancel and request a new forgotten password email.',

        'TaxRatesPage:title': 'Tax rates',
        'TaxRatesPage:addTaxRate': 'Add tax rate',
        'TaxRatesPage:nameHeading': 'Name',
        'TaxRatesPage:rateHeading': '% Rate',

        'TaxRateForm:addTaxRate': 'Add tax rate',
        'TaxRateForm:editTaxRate': 'Edit tax rate',
        'TaxRateForm:rate': '% Rate',

        'ProductCategoriesPage:title': 'Product categories',
        'ProductCategoriesPage:addProductCategory': 'Add product category',
        'ProductCategoriesPage:nameHeading': 'Name',
        'ProductCategoriesPage:colourHeading': 'Colour',
        'ProductCategoriesPage:reportingPriorityHeading': 'Reporting priority',
        'ProductCategoriesPage:showInPointOfSaleHeading': 'Show on POS',
        'ProductCategoriesPage:showOnWebShopHeading': 'Show on web shop',

        'ProductCategoryForm:addProductCategory': 'New product category',
        'ProductCategoryForm:editProductCategory': 'Edit product category',
        'ProductCategoryForm:sequence': 'Sequence',
        'ProductCategoryForm:showOnPointOfSale': 'Show on Point of sale',
        'ProductCategoryForm:showOnWebShop': 'Show on web shop',
        'ProductCategoryForm:reportingPriority': 'Reporting priority',
        'ProductCategoryForm:reportingPriorityHint': 'When a product with multiple categories appears on a report, it will be counted in the category with the lowest priority',

        'PromotionsPage:addPromotion': 'Add promotion',
        'PromotionsPage:nameHeading': 'Name',
        'PromotionsPage:codeHeading': 'Code',
        'PromotionsPage:typeHeading': 'Type',
        'PromotionsPage:startDate': 'Start',
        'PromotionsPage:endDate': 'End',
        'PromotionsPage:firstEventDate': 'First event date',
        'PromotionsPage:lastEventDate': 'Last event date',
        'PromotionsPage:budget': 'Budget',
        'PromotionsPage:redeemed': 'Redeemed',

        'PromotionForm:addPromotion': 'Add promotion',
        'PromotionForm:editPromotion': 'Edit promotion',
        'PromotionForm:availableForWebBookings': 'Enable for web bookings',
        'PromotionForm:availableForWebBookingshelpText': 'Leave unticked for membership only promotions.',
        'PromotionForm:waiveBookingFee': 'Waive online booking fee when promotion applied',
        'PromotionForm:startDate': 'Promotion start date',
        'PromotionForm:endDate': 'End date (leave blank for no end)',
        'PromotionForm:validForEventsFrom': 'Date of first booking',
        'PromotionForm:validForEventsUntil': 'Date of last booking',
        'PromotionForm:maximumBudget': 'Promotion budget (leave blank for no limit)',
        'PromotionForm:maximumBudgetHint': 'If a budget amount it entered, the promotion will end once the total discounted amount across all bookings reaches this value',
        'PromotionForm:maxRedemptionsPerCustomer': 'Maximum use per customer',
        'PromotionForm:maxRedemptionsPerCustomerHint': 'The maximum number of times each customer can use the promotion code.  Leave blank for no limit.',
        'PromotionForm:requirementsSectionHeader': 'Requirements',
        'PromotionForm:requirementsSectionDescription': 'Select the requirements for a promotion to apply to a booking',
        'PromotionForm:criteriaType': 'Restriction type',
        'PromotionForm:criteriaQuantity_None': 'Quantity',
        'PromotionForm:criteriaQuantity_Participant': 'Number of people',
        'PromotionForm:criteriaQuantity_Activity': 'Number of activities',
        'PromotionForm:criteriaQuantityHint_Participant': 'E.g. to discount the third driver on a booking, enter 3',
        'PromotionForm:criteriaQuantityHint_Activity': 'E.g. to discount a second session, enter 2',
        'PromotionForm:itemsSectionHeader': 'Discount details',
        'PromotionForm:itemsSectionDescription': 'Select what items the discount applies to and set limits on the quantity and number of items that can be discounted',
        'PromotionForm:promotionItemType': 'Apply discounts to',
        'PromotionForm:quantityToDiscount_OverallBillAmount': 'Quantity to discount',
        'PromotionForm:quantityToDiscount_Participant': 'Number of participants to discount per activity',
        'PromotionForm:quantityToDiscount_Activity': 'Number of activities to discount',
        'PromotionForm:quantityToDiscount_Item': 'Item quantity to discount',
        'PromotionForm:maxQuantityToDiscount_OverallBillAmount': 'Maximum quantity to discount',
        'PromotionForm:maxQuantityToDiscount_Participant': 'Maximum number of participants to discount',
        'PromotionForm:maxQuantityToDiscount_Activity': 'Maximum number of participants to discount per activity',
        'PromotionForm:maxQuantityToDiscount_Item': 'Maximum quantity to discount per item',
        'PromotionForm:maxNumberOfTimesToApply_OverallBillAmount': 'Maximum quantity to discount',
        'PromotionForm:maxNumberOfTimesToApply_Participant': 'Maximum number of activities to apply discount to',
        'PromotionForm:maxNumberOfTimesToApply_Activity': 'Maximum number of activities to apply discount to',
        'PromotionForm:maxNumberOfTimesToApply_Item': 'Maximum number of items to discount',
        'PromotionForm:discountType': 'Discount type',
        'PromotionForm:discount': 'Discount',
        'PromotionForm:accountingCategory': 'Accounting category',
        'PromotionForm:accountingDepartment': 'Accounting Department',
        'PromotionForm:nominalCode': 'Nominal code',
        'PromotionForm:percentage': 'percentage',
        'PromotionForm:discountRequiredForWebBooking': 'Discount amount must be greater than zero for web promotions',
        'PromotionForm:percentageGreaterThanZero': 'Percentage discount cannot be greater than 100',
        'PromotionForm:triggerTags': 'Only allow promotion if bill contains tag(s)',
        'PromotionForm:itemTags': 'Only apply discount to bill items with tag(s)',
        'PromotionForm:itemRestrictionTags': 'Do not apply discount to bill items with tag(s)',
        'PromotionForm:timeRestrictions': 'Time restrictions',
        'PromotionForm:fromTime': 'From',
        'PromotionForm:toTime': 'To',

        'PromotionForm:customerRestrictions':'Customer restrictions',
        'PromotionForm:customerRestriction': 'Restrict to customers',
        'PromotionForm:inTheLast': 'in the last',

        'ActivityFormatList:title': 'Activity formats',
        'ActivityFormatList:addActivityFormat': 'Add activity format',
        'ActivityFormatList:productHeading': 'Product',
        'ActivityFormatList:nameHeading': 'Name',
        'ActivityFormatList:sequenceHeading': 'Sequence',
        'ActivityFormatList:maxParticipantsHeading': 'Max Participants',
        'ActivityFormatList:resources': 'Resources',
        'ActivityFormatList:NoResourcesSelected': 'No resource(s) selected',
        'ActivityFormatList:NoCustomerCategories': 'No customer categories have been selected',
        'ActivityFormatList:ActivityHasNoProduct': 'No product has been selected',
        'ActivityFormatList:ProductWithNoCustomerCategory': 'Product {productName} has no customer category selection',
        'ActivityFormatList:linkedActivityMissingCategoryMappings': 'One or more additional / alternative activities have unmapped customer categories',

        'ActivityFormatForm:addActivityFormat': 'Add activity format',
        'ActivityFormatForm:name': 'Name',
        'ActivityFormatForm:description': 'Description',
        'ActivityFormatForm:sequence': 'Sequence',
        'ActivityFormatForm:webDescription': 'Website description',
        'ActivityFormatForm:websiteDescriptionBackgroundColour': 'Background colour',
        'ActivityFormatForm:websiteDescriptionTextColour': 'Text colour',
        'ActivityFormatForm:webDescriptionPriceTemplate': 'Web description price template',
        'ActivityFormatForm:minPlacesForExclusive': 'Min places for exclusive booking',
        'ActivityFormatForm:pricing': 'Pricing',
        'ActivityFormatForm:product': 'Product',
        'ActivityFormatForm:noProduct': 'Select product',
        'ActivityFormatForm:participants': 'Participants',
        'ActivityFormatForm:minParticipants': 'Min participants',
        'ActivityFormatForm:maxParticipants': 'Max participants',
        'ActivityFormatForm:maxParticipantsHint': 'Leave blank for no limit',
        'ActivityFormatForm:variationName': 'Name',
        'ActivityFormatForm:variationRunningTime': 'Run time',
        'ActivityFormatForm:resources': 'Apply to resources',
        'ActivityFormatForm:numberOfPlaces': 'Places per booking',
        'ActivityFormatForm:addVariation': '+ Add schedule',
        'ActivityFormatForm:schedule': 'Schedule',
        'ActivityFormatForm:addSchedule': 'Add schedule item',
        'ActivityFormatForm:addProduct': '+ Add',
        'ActivityFormatForm:variations': 'Format schedules',
        'ActivityFormatForm:products': 'Products',
        'ActivityFormatForm:availability': 'On-line booking availability',
        'ActivityFormatForm:noCustomerCategories': 'No customer categories found.  Please set up customer categories before adding an activity format.',
        'ActivityFormatForm:customerCategories': 'Customer categories',
        'ActivityFormatForm:customerCategoryEnabled': 'Enabled',
        'ActivityFormatForm:customerCategoryPlaces': 'Places to book',
        'ActivityFormatForm:bookingHeading': 'Totals per booking',
        'ActivityFormatForm:minPerBooking': 'Min',
        'ActivityFormatForm:maxPerBooking': 'Max',
        'ActivityFormatForm:maxOverall': 'Overall Event Max',
        'ActivityFormatForm:addCustomerCategory': 'Add customer category',
        'ActivityFormatForm:minTime': 'Min gaps (in minutes)',
        'ActivityFormatForm:minGapBefore': 'Min gap before',
        'ActivityFormatForm:minGapAfter': 'Min gap after',
        'ActivityFormatForm:minBreakDuration': 'Min break length',
        'ActivityFormatForm:maxBreakDuration': 'Max break length',
        'ActivityFormatForm:mustBookItemsTogether': 'All items must be booked together',
        'ActivityFormatForm:compatibleActivityFormats': 'Compatible activity formats',
        'ActivityFormatForm:compatibleActivityFormatsHint': 'A list of activity formats that can be booked into one or more of this activities sessions',
        'ActivityFormatForm:activityFormatImage': 'Image',
        'ActivityFormatForm:imageWidth': 'The image must be a between 400px and 800px wide',
        'ActivityFormatForm:imageHeight': 'The image must be a maximum of 800px high',
        'ActivityFormatForm:registrationTerms': 'Registration Waiver',
        'ActivityFormatForm:bookingTerms': 'Booking terms',
        'ActivityFormatForm:selectBookingTerms': '<Select booking terms>',
        'ActivityFormatForm:generalSettings': 'General settings',
        'ActivityFormatForm:descriptionRequired': 'Please enter a description',
        'ActivityFormatForm:nameRequired': 'All fields must have a unique name',
        'ActivityFormatForm:allFieldNamesMustBeUnique': 'Field names must be unique',
        'ActivityFormatForm:noAvailabilityMessageLbl': 'Enter a specific no availability message for this activity, or leave blank to use the standard message',
        'ActivityFormatForm:noAvailabilityMessage': 'No availability message override',
        'ActivityFormatForm:showAlternativeAvailability': 'Show alternative dates with availability',
        'ActivityFormatForm:showRemainingSpaces': 'Show remaining spaces on booking slot',
        'ActivityFormatForm:timingTemplate': 'Timing template name',
        'ActivityFormatForm:bookingMaxLessThanMin': 'Booking Max cannot be less than Booking Min',
        'ActivityFormatForm:overallMaxLessThanBookingMax': 'Overall Max cannot be less than Booking Max',
        'ActivityFormatForm:bookingMaxZero': 'Booking max cannot be zero',
        'ActivityFormatForm:overallMaxZero': 'Overall max cannot be zero',
        'ActivityFormatForm:activityFormatGroups': 'Activity format groups',
        'ActivityFormatForm:priceTemplate': 'Price label template',
        'ActivityFormatForm:slotPriceTemplateTags': 'You can customise the way the price is displayed next to each avaiable slot. Leave this box blank to use the default formatting. You can add one or more of the following tags in the template to show the calculated price',
        'ActivityFormatForm:activityPriceTemplateTags': 'You can customise the way the price is displayed when selecting an activity. Leave this box blank to use the default formatting. You can add one or more of the following tags in the template to show the calculated price',
        'ActivityFormatForm:priceTemplateMinPricePPTag': 'Minimum price per person',
        'ActivityFormatForm:priceTemplateAvgPricePPTag': 'Average price per person',
        'ActivityFormatForm:priceTemplateTotalPriceTag': 'Total price (taking into account the number of people on the booking)',
        'ActivityFormatForm:priceTemplateTotalPriceTagError': 'The pricing template contains an invalid tag',
        'ActivityFormatForm:addAdditionalActivity': 'Add additional / alternative activity',
        'ActivityFormatForm:categoriesWithMultipleWebProducts': 'Each category can only have one web booking price.  Affected categories: {categories}',

        'ActivityFormatForm:customerCategoryPlacesDescription': 'The number of places to reserve per person booked.  This should almost always be 1, but can be increased if you want to reduce total capacity for a session when a particular category is booked.  For example, if a family session normally has a capacity of 10 but the total should be reduced by 2 for each cadet booked, set this to 2 for the cadet category.',
        'ActivityFormatForm:bookingHeadingDescription': 'The minimum and maximum number of people for that category per booking.',
        'ActivityFormatForm:maxOverallDescription': 'The total maximum number of people for that category across all bookings for an event.',
        'ActivityFormatForm:placesToBookGreaterThan1Warning': 'WARNING: One or more categories has places to book greater than 1.  This will reduce the total capacity for a booking - are you sure this is what you want to do?',

        'ActivityFormatForm:bookingExtraFields': 'Booking extra fields',
        'ActivityFormatForm:registrationExtraFields': 'Registration extra fields',

        'ActivityFormatForm:SelectCustomerCategory': 'Please select at least one customer category',
        'ActivityFormatForm:AllProductsMustHaveCustomerCategory': 'All products must have at least one customer category selected',
        'ActivityFormatForm:AllSchedulesMustHaveARunningTime': 'All schedules must have a running time greater than 0',
        'ActivityFormatForm:NoProductSelected': 'Please add at least one product in pricing section',
        'ActivityFormatForm:additionalProducts': 'Additional products',
        'ActivityFormatForm:additionalActivities': 'Additional / alternative activities',
        'ActivityFormatForm:enableForWeb': 'Web booking price',
        'ActivityFormatForm:arrivalTimeBeforeEventOverride': 'Override arrival time before event start (HH:MM) - leave blank to use resource default',
        'ActivityFormatForm:snapOnlineBookingsToOverride': 'Override align web bookings every (x) minutes after opening time - leave blank to use resource default',
        'ActivityFormatForm:applyAvailabilityRulesToExistingReservations': 'Availability rules apply to',
        'ActivityFormatForm:applyToNewReservations': 'Only bookings for new reservations',
        'ActivityFormatForm:applyToAllReservations': 'Bookings for new and existing reservations',
        'ActivityFormatForm:createLink': 'Create link to this activity',
        'ActivityFormatForm:allowMultipleCategoriesPerCustomer': 'Allow a customer to be registered against more than one category',
        'ActivityFormatForm:membershipTypeId': 'Restrict bookings to customers with membership',
        'ActivityFormatForm:noMembershipRestriction': 'No membership restriction',
        'ActivityFormatForm:maxUpsellTimeSlotsBefore': 'Max number of slots to offer',
        'ActivityFormatForm:maxUpsellTimeSlotsAfter': 'Max number of slots to offer',

        'ActivityFormatAvailability:days': 'Days',
        'ActivityFormatAvailability:times': 'Times',
        'ActivityFormatAvailability:participants': 'Participants',
        'ActivityFormatAvailability:daysBefore': 'Days before event',
        'ActivityFormatAvailability:addAvailability': 'Add availability rule',
        'ActivityFormatAvailability:start': 'Start',
        'ActivityFormatAvailability:end': 'End',

        'ActivityFormatExtraFields:optionsHeading': 'Options',
        'ActivityFormatExtraFields:listHint': 'For List items, enter options separated by commas',
        'ActivityFormatExtraFields:appliesTo': 'Collect for',
        'ActivityFormatExtraFields:customerCanEnter': 'Entered by customer',

        'CreateActivityFormatLink:addDate': 'Add pre-selected date',
        'CreateActivityFormatLink:date': 'Date',

        'CreateActivityFormatLink:addQuantities': 'Add default customer quantities',

        'ActivityFormatGroupList:nameHeading': 'Name',
        'ActivityFormatGroupList:sequenceHeading': 'Sequence',
        'ActivityFormatGroupList:addActivityFormatGroup': 'Add activity format group',

        'ActivityFormatGroupForm:addActivityFormatGroup': 'Add activity format group',
        'ActivityFormatGroupForm:editActivityFormatGroup': 'Edit activity format group',
        'ActivityFormatGroupForm:groupId': 'Group ID',
        'ActivityFormatAdditionalActivities:selectActivity': '<select activity>',
        'ActivityFormatAdditionalActivities:activity': 'Activity',
        'ActivityFormatAdditionalActivities:alternateAvailability': 'Alternative activities',
        'ActivityFormatAdditionalActivities:alternateActivity': 'Offer as alternative',
        'ActivityFormatAdditionalActivities:alternateActivityDescription': 'If there is no availability for the original activity online and this activity does have availability, it will be shown as an alternative.',
        'ActivityFormatAdditionalActivities:upsellActivity': 'Offer as upsell',
        'ActivityFormatAdditionalActivities:upsellActivityDescription': 'Once a customer has selected a time for the original activity, you can offer this activity as an upsell, as long as it has availability for the selected day and number of participants',
        'ActivityFormatAdditionalActivities:upsellActivityEarlierTime': 'Upselling before the original activity',
        'ActivityFormatAdditionalActivities:upsellActivityEarlierTimeDescription': 'Please specify the minimum and maximum time gap between this activity and the start of the original activity.  If you don\'t want the customer to be able to add this activity before the original activity, leave the two boxes blank',
        'ActivityFormatAdditionalActivities:upsellActivityLaterTime': 'Upselling after the original activity',
        'ActivityFormatAdditionalActivities:upsellActivityLaterTimeDescription':'Please specify the minimum and maximum time gap between the end of the original activity and the start of this activity.  If you don\'t want the customer to be able to add this activity after the original activity, leave the two boxes blank',
        'ActivityFormatForm:minGap':'Min gap',
        'ActivityFormatForm:maxGap': 'Max gap',
        'ActivityFormatAdditionalActivities:upsellProduct': 'Upsell product',
        'ActivityFormatAdditionalActivities:webDescriptionOverride': 'Web description override',
        'ActivityFormatAdditionalActivities:maxAlternateActivitiesExceeded': 'The number of alternative activities selected exceeds the maximum of 10',
        'ActivityFormatAdditionalActivities:maxUpsellActivitiesExceeded': 'The number of activities to upsell exceeds the maximum of 10',
        'ActivityFormatAdditionalActivities:defaultproduct': 'default product',
        'ActivityFormatAdditionalActivities:gapBefore': 'Gap before activity',
        'ActivityFormatAdditionalActivities:gapAfter': 'Gap after activity',
        'ActivityFormatAdditionalActivities:min': 'min',
        'ActivityFormatAdditionalActivities:max': 'max',
        'ActivityFormatAdditionalActivities:customerCategories': 'Customer Categories',
        'ActivityFormatAdditionalActivities:customerCategoriesDescription': 'Some of the customer categories on the selected activity do not match the categories on the original activity.  Please select the customer categorie(s) to use when checking availability',
        'ActivityFormatAdditionalActivities:standardPrice': 'Use standard price',
        'ActivityFormatAdditionalActivities:maxUpsellSlots': 'Max slots to offer',
        'ActivityFormatAdditionalActivities:maxBefore': 'Before',
        'ActivityFormatAdditionalActivities:maxAfter':'After',
        'ActivityFormatAdditionalActivities:minGapBeforeMainActivityAfterMaxGap': 'Min gap before activity must be less than max gap before activity',
        'ActivityFormatAdditionalActivities:minGapAfterMainActivityAfterMaxGap': 'Min gap after activity must be less than max gap after activity',

        'OnlineAvailabilityForm:fromTime': 'From',
        'OnlineAvailabilityForm:toTime': 'To',
        'OnlineAvailabilityForm:minParticipants': 'Min participants',
        'OnlineAvailabilityForm:maxParticipants': 'Max participants',
        'OnlineAvailabilityForm:minDaysBefore': 'Min days before event',
        'OnlineAvailabilityForm:maxDaysBefore': 'Max days before event',
        'OnlineAvailabilityForm:startDate': 'Start date',
        'OnlineAvailabilityForm:endDate': 'End date',
        'OnlineAvailabilityForm:leaveStartDateBlank': 'Leave blank to start immediately',
        'OnlineAvailabilityForm:leaveEndDateBlank': 'Leave blank to run indefinitely',
        'OnlineAvailabilityForm:endDateBeforeStartDate': 'End date must be after start date',

        'PaymentReminderConfigList:title': 'Payment reminders',
        'PaymentReminderConfigList:addPaymentReminderConfig': 'Add payment reminder',
        'PaymentReminderConfigList:nameHeading': 'Name',
        'PaymentReminderConfigList:emailTemplateHeading': 'Email template',
        'PaymentReminderConfigList:applyToHeading': 'Applies to',
        'PaymentReminderConfigList:sendHeading': 'Send',
        'PaymentReminderConfigList:sendOnDayDue': 'On date due',
        'PaymentReminderConfigList:sendDaysBefore': '{days} days before due',
        'PaymentReminderConfigList:sendDaysOverdue': '{days} days overdue',

        'BookingEmailScheduleConfigList:title': 'Email schedules',
        'BookingEmailScheduleConfigList:emailTypeHeading': 'Email type',
        'BookingEmailScheduleConfigList:webEventsHeading': 'Web events',
        'BookingEmailScheduleConfigList:manualEventsHeading': 'Manual events',
        'BookingEmailScheduleConfigList:fullyPaidHeading': 'Fully paid',
        'BookingEmailScheduleConfigList:anyPaymentHeading': 'First payment',
        'BookingEmailScheduleConfigList:daysBeforeEvent': 'Days before',
        'BookingEmailScheduleConfigList:addBookingEmailScheduleConfig': 'Add email schedule',

        'BookingEmailScheduleConfigForm:noEmailTemplates': 'No booking email templates found ({emailTypeList})',
        'BookingEmailScheduleConfigForm:noEmailTemplate': 'Select the email template to use',
        'BookingEmailScheduleConfigForm:addBookingEmailScheduleConfigForm': 'Add booking email schedule',
        'BookingEmailScheduleConfigForm:emailTemplate': 'Email template',
        'BookingEmailScheduleConfigForm:sendForManualBookings': 'Send for manual bookings',
        'BookingEmailScheduleConfigForm:sendForWebBookings': 'Send for web bookings',
        'BookingEmailScheduleConfigForm:sendWhen': 'Send when',
        'BookingEmailScheduleConfigForm:selectOption': '<select option>',
        'BookingEmailScheduleConfigForm:sendWhenFullyPaidOption': 'Booking is paid in full',
        'BookingEmailScheduleConfigForm:sendWhenAnyPaymentOption': 'First payment is made',
        'BookingEmailScheduleConfigForm:sendOnScheduleOption': 'Time before event',
        'BookingEmailScheduleConfigForm:numberOfDaysBeforeEvent': 'Days before event',
        'BookingEmailScheduleConfigForm:timeToSend': 'Time of day to send email',

        'PaymentReminderConfigForm:addPaymentReminderConfig': 'Add payment reminder',
        'PaymentReminderConfigForm:name': 'Name',
        'PaymentReminderConfigForm:emailTemplate': 'Email template',
        'PaymentReminderConfigForm:noEmailTemplate': '<select email template>',
        'PaymentReminderConfigForm:noEmailTemplates': 'No payment reminder templates have been configured.  Please configure at least one template before adding a reminder',
        'PaymentReminderConfigForm:paymentTypes': 'Apply to',
        'PaymentReminderConfigForm:AllPayments': 'All payments',
        'PaymentReminderConfigForm:Deposit': 'Only deposits',
        'PaymentReminderConfigForm:NonDeposit': 'Only non-deposits',
        'PaymentReminderConfigForm:timeOffset': 'Send reminder',
        'PaymentReminderConfigForm:queueEmailImmediately': 'auto send email',
        'PaymentReminderConfigForm:queueEmailImmediatelyHelp': 'If unticked, the emails will be created, but will not be sent automatically.  You will need to review them in the email module and confirm before the email is sent',

        'CustomerCategoryList:title': 'Customer categories',
        'CustomerCategoryList:addCustomerCategory': 'Add customer category',
        'CustomerCategoryList:nameHeading': 'Name',
        'CustomerCategoryList:codeHeading': 'Code',
        'CustomerCategoryList:timingCodeHeading': 'Timing code',
        'CustomerCategoryList:ageRange': 'Age range',
        'CustomerCategoryList:heightRange': 'Height range',

        'CustomerCategoryForm:title': 'Customer category',
        'CustomerCategoryForm:timingCode': 'Timing code',
        'CustomerCategoryForm:timingCodeHint': 'This code must match the kart type code used in the timing',
        'CustomerCategoryForm:pluralisedName': 'Pluralised name',
        'CustomerCategoryForm:minAge': 'Minimum age',
        'CustomerCategoryForm:OptionalHint': 'Leave blank for no limit',
        'CustomerCategoryForm:maxAge': 'Maximum age',
        'CustomerCategoryForm:minHeight': 'Minimum height',
        'CustomerCategoryForm:maxHeight': 'Maximum height',

        'ProductList:title': 'Products',
        'ProductList:addProduct': 'Add product',
        'ProductList:categoryHeading': 'Categories',
        'ProductList:nominalCodeHeading': 'Nominal Code',
        'ProductList:accountingCategoryHeading': 'Accounting Category',
        'ProductList:accountingDepartmentHeading': 'Accounting Dept.',
        'ProductList:nameHeading': 'Name',
        'ProductList:taxRateHeading': 'Tax rate',
        'ProductList:pricingModeHeading': 'Pricing mode',

        'ProductForm:addProduct': 'Add product',
        'ProductForm:addPrice': 'Add price',
        'ProductForm:addPriceOverride': 'Add price override',
        'ProductForm:name': 'Name',
        'ProductForm:categories': 'Categories',
        'ProductForm:accountingCategory': 'Accounting category',
        'ProductForm:accountingDepartment': 'Accounting Department',
        'ProductForm:nominalCode': 'Nominal code',
        'ProductForm:taxRate': 'Tax rate',
        'ProductForm:pricing': 'Pricing',
        'ProductForm:standardPricing': 'Standard Pricing',
        'ProductForm:pricingOverrides': 'Pricing overrides',
        'ProductForm:fromQuantity': 'Min Qty',
        'ProductForm:toQuantity': 'Max Qty',
        'ProductForm:standardUnitPrice': 'Standard unit price',
        'ProductForm:additionalItemPrice': 'Additional price per item',
        'ProductForm:selectTaxRate': '<select tax rate>',
        'ProductForm:pricingMode': 'Pricing mode',
        'ProductForm:productImage': 'Product image (max 160px x 160px)',
        'ProductForm:cannotArchiveAsLinkedRoActivityFormats': 'Cannot archive this product as it is lined to activity formats [{linkedActivityFormats}].  Please either remove the product from the activity format(s), or archive the activity format(s)',
        'ProductForm:tagsHintText': 'Add a tag to this price to only apply this price to customers with the selected tag.  Leave blank to apply to all customers.',
        'ProductForm:addRule': 'Add rule',
        'ProductForm:maxMustBeGreaterThanMin': 'Max cannot be less then min',
        'ProductForm:priceMustBeGreaterThanZero': 'Price must be greater than zero',
        'ProductForm:quantitiesCannotOverlap': 'Rule quantities cannot overlap',
        'ProductForm:standardPriceEffectiveDatesPricePoint': 'Pricing effective from/to dates apply based on',
        'ProductForm:priceEffectiveFrom':'Effective from',
        'ProductForm:priceEffectiveTo': 'Effective to',
        'ProductForm:priceEffectivePricePoint': 'Applied to',
        'ProductForm:applyPriceWhen': 'Apply price when',
        'ProductForm:applyOverridePriceWhen': 'Override applies when',
        'ProductForm:dayTimeRestrictions': 'Day / time restrictions',
        'ProductForm:restrictOverrideTo': 'Restrict override to purchases when',
        'ProductForm:fromTime': 'From time',
        'ProductForm:toTime': 'To time',
        'ProductForm:tag': 'Tag',
        'ProductForm:noTag': 'No tag',
        'ProductForm:nonPricingTags': 'Non-pricing tags',
        'ProductForm:standardPricesCannotOverlap': 'Standard prices cannot overlap',    
        'ProductForm:standardPricesCannotHaveGaps': 'There cannot be gaps between standard prices',
        'ProductForm:lastPriceMustHaveABlankEffectiveToDate': 'One standard price must have an empty effective to date',
        'ProductForm:standardPricesMustStartOnOrBeforeToday': 'Standard prices must start no later than today',
        'ProductForm:fixedPriceMaxCannotBeZero': 'Fix price maxinum quantity cannot be zero',
        'ProductForm:fixedPriceMaxCanotBeLessThanMin': 'Fix price maxinum quantity cannot be less than the minimum quantity',
        'ProductForm:productMustHaveAPrice': 'Product must have a price',

        'ProductPriceOverrideForm:heading': 'Price override settings',

        'PointOfSale:customerHeading': 'Customer',
        'PointOfSale:eventHeading': 'Event',
        'PointOfSale:billNumberHeading': 'Bill No',
        'PointOfSale:addCustomer': 'Select customer',
        'PointOfSale:newBill': 'New bill',
        'PointOfSale:search': 'Search',
        'PointOfSale:openBills': 'Open bills',
        'PointOfSale:recentBills': 'Recent bills',
        'PointOfSale:eventBills': 'Event bills',
        'PointOfSale:webShopBills': 'Web shop bills',
        'PointOfSale:outstandingAmountHeading': 'Outstanding',
        'PointOfSale:unvoidPayment': 'Unvoid',
        'PointOfSale:printReceipt': 'Receipt',
        'PointOfSale:paymentReceipt': 'PaymentReceipt',
        'PointOfSale:open': 'Open',

        'PointOfSale:total': 'Total',
        'PointOfSale:balance': 'Balance',
        'PointOfSale:tax': 'Tax',
        'PointOfSale:fee': 'Fee',
        'PointOfSale:creditAbbr': 'CR',
        'PointOfSale:paymentAmount': 'Payment amount',
        'PointOfSale:editPercentage': '{type} percentage',
        'PointOfSale:editAmount': '{type} amount',
        'PointOfSale:discount': 'Discount',
        'PointOfSale:memberDiscount': 'Member discount',
        'PointOfSale:deposit': 'Deposit',
        'PointOfSale:print': 'Print',
        'PointOfSale:paid': 'Paid',
        'PointOfSale:payments': 'Payments',
        'PointOfSale:scheduledPayments': 'Scheduled payments',
        'PointOfSale:failedPayments': '{numberOf} Failed / VOID payments',
        'PointOfSale:refunds': 'Refunds',
        'PointOfSale:totalPaid': 'Total paid',
        'PointOfSale:due': 'Due',
        'PointOfSale:overdue': 'Overdue',
        'PointOfSale:dueToday': 'Due today',
        'PointOfSale:charged': 'Charged',
        'PointOfSale:changeDue': 'Change due',
        'PointOfSale:takePayment': 'Payment',
        'PointOfSale:selectFieldError': 'Please click on box to update before typing value',
        'PointOfSale:schedulePayment': 'Schedule payment',
        'PointOfSale:editSchedulePayment': 'Edit scheduled payment',
        'PointOfSale:editPayment': 'Edit payment',
        'PointOfSale:schedule': 'Schedule',
        'PointOfSale:paymentMethod': 'Payment method',
        'PointOfSale:dueDate': 'Payment due date',
        'PointOfSale:paymentDate': 'Date paid',
        'PointOfSale:paidBy': 'Payment taken by',
        'PointOfSale:selectPaymentType': '<select payment type>',
        'PointOfSale:eventDate': 'Event date',
        'PointOfSale:validation:dateAfterEventDate': 'Date cannot be after event date',
        'PointOfSale:EnterPaymentAmount': 'Enter the payment amount',
        'PointOfSale:EnterRefundAmount': 'Enter the refund amount',
        'PointOfSale:NonZeroAmount': 'Amount must be greater than zero',
        'PointOfSale:confirmDeletePayment': 'Are you sure you want to delete this payment?',
        'PointOfSale:AmountBelowGatewayMinimum': 'Payment amount is below the minimum for this payment method ({minAmount})',
        'PointOfSale:makePayment': 'Make payment',
        'PointOfSale:paymentInfo': 'Payment information',
        'PointOfSale:confirmVoidPayment': 'Confirm void payment',
        'PointOfSale:confirmVoidRefund': 'Confirm void refund',
        'PointOfSale:voidReason': 'Void reason',
        'PointOfSale:yesVoidPayment': 'Yes, void payment',
        'PointOfSale:yesVoidRefund': 'Yes, void refund',
        'PointOfSale:paymentVoid': 'VOID',
        'PointOfSale:voidedBy': 'Voided by',
        'PointOfSale:refund': 'Refund',
        'PointOfSale:refundAmount': 'Refund amount',
        'PointOfSale:refundMethod': 'Refund method',
        'PointOfSale:refundReason': 'Refund reason',
        'PointOfSale:amountRefunded': 'Amount refunded',
        'PointOfSale:editRefund': 'Edit refund',
        'PointOfSale:itemsFromOtherEvents': 'Additional items / events',
        'PointOfSale:addFee': 'Add fee',
        'PointOfSale:editFee': 'Edit fee',
        'PointOfSale:billTotalExFee': 'Bill total (excluding fees)',
        'PointOfSale:selectFee': '<select fee>',
        'PointOfSale:feeAmount': 'Fee amount',
        'PointOfSale:noFeeSelected': 'Please select the fee to apply',
        'PointOfSale:unknownTaxRate': 'Unalbe to find tax rate for this fee',
        'PointOfSale:selectPromotion': '<select promotion>',
        'PointOfSale:discountType': 'Discount type',

        'PointOfSale:paymentAttempts': 'Payment attempts',
        'PointOfSale:gatewayPaymentStatus': 'Payment status',
        'PointOfSale:gatewayCardType': 'Card type',
        'PointOfSale:gatewayCardIssuer': 'Card issuer',
        'PointOfSale:gatewayCardLast4Digits': 'Last 4 digits',
        'PointOfSale:gatewayCardHolder': 'Card holder',
        'PointOfSale:gatewayResponse': 'Response',
        'PointOfSale:gatewayClientIp': 'Payment IP address',
        'PointOfSale:gatewayIssuerPaymentId': 'Issuer payment id',
        'PointOfSale:gatewayToken': 'Token',
        'PointOfSale:terminal': 'Terminal',

        'PointOfSale:processingPaymentHeader': 'Processing payment',
        'PointOfSale:processingPaymentText': 'A new window has been opened to process the payment.  Please follow instructions in that window',

        'PointOfSale:searchBills': 'Search bills',
        'PointOfSale:billSearchTerm': 'Search term',
        'PointOfSale:billSearchTermHint': 'Enter bill number or customer name',
        'PointOfSale:searchBillsNoResults': 'No bills found',
        'PointOfSale:gatewayReference': 'Refund reference',
        'PointOfSale:gatewayRefundStatus': 'Refund status',
        'PointOfSale:gatewayTime': 'Refund time',
        'PointOfSale:discountPercentage': 'Discount percentage',
        'PointOfSale:discountAmount': 'Discount Amount',
        'PointOfSale:originalBillAmount': 'Original bill amount',
        'PointOfSale:addDiscount': 'Add discount',
        'PointOfSale:editDiscount': 'Edit discount',

        'PointOfSale:voucherSpecificProductCategory': 'Vouchers',
        'PointOfSale:membershipSpecificProductCategory': 'Memberships',
        'PointOfSale:voucherCode': 'Voucher code',
        'PointOfSale:validateVoucher': 'Validate voucher',
        'PointOfSale:redeemVoucher': 'Redeem voucher',
        'PointOfSale:remainingBalance': 'Remaining balance',
        'PointOfSale:invalidVoucherCode': 'Voucher code is not valid',
        'PointOfSale:voucherExpired': 'This voucher has expired',
        'PointOfSale:voucherRevoked': 'This voucher has been revoked',
        'PointOfSale:voucherFullyRedeemed': 'This voucher has been fully redeemed',
        'PointOfSale:paymentAmountExceedsVoucherBalance': 'The remaining voucher balance is less then the payment amount',
        'PointOfSale:amountExceedsRemainingBalance': 'Payment amount is more than remaining voucher balance',

        'PointOfSale:nonDiscountableProducts':'Based on the voucher restrictions, this voucher is not valid for items on this bill.',
        'PointOfSale:customerConflict': 'This voucher cannot be shared between customers. It has already been redeemed by [{customerName}].',

        'PointOfSale:sendVouchers': 'Send vouchers',
        'PointOfSale:sendMemberships': 'Send memberships',

        'PointOfSale:paymentSchedule': 'Payment schedule',
        'PointOfSale:createScheduledPayments':'Create payments',
        'PointOfSale:manualPayment': 'Add payments manually',
        'PointOfSale:selectPaymentSchedule': '<select schedule>',
        'PointOfSale:balanceDueNow': 'Balance due now',
        'PointOfSale:searchMinTextWarning': 'Please neter at least 3 characters',

        'PointOfSale:PopupBlockedHeader': 'Popup blocked by your browser',
        'PointOfSale:PopupBlockedMessage': 'Due to the way the integration with your card provider works, we need to collect the card details in a separate window.  Your browser has blocked us opening up a new window.  Please change your browser settings to allow popups for this site, then click retry',

        'PointOfSale:enterCardNumberManually': 'Enter card number manually',
        'PointOfSale:selectCardReader': 'Select a card reader',
        'PointOfSale:readerStatus_offline': 'Offline',
        'PointOfSale:readerStatus_online': 'Online',

        'SendMembershipEmailOverlay:heading': 'Send memberships',
        'SendMembershipEmailOverlay:sendingMembershipEmail': 'Sending membership email to {customerName} {customerEmail}',
        'SendMembershipEmailOverlay:emailSent': 'Email sent successfully',

        'PaymentPageForm:dataTags': 'Tags',
        'PaymentPageForm:dataTagsText': 'Use these tags in the page content to insert data into the page',
        'PaymentPageForm:noTermsRequired': 'No terms required',
        'PaymentPageForm:termsAndConditions': 'Terms and conditions (optional)',

        'PaymentPageTag:payment_description': 'The payment description',
        'PaymentPageTag:payment_amount': 'The payment amount',

        'ProcessStripePayment:loading': 'Loading, please wait',
        'ProcessStripePayment:pay': 'Make payment',
        'ProcessStripePayment:processing': 'Processing payment, please wait',
        'ProcessStripePayment:failed': 'Payment failed.',
        'ProcessStripePayment:processingReaderPayment': 'Processing payment, please follow instructions on card reader',

        'ProductsPanel:customerCategoryHeading': '{categoryName} products',
        'ProductsPanel:customerReuired': '{categoryName} require a customer - please select a customer before adding a product',

        'ProductsPage:existingTag': 'Select existing tag',
        'ProductsPage:createTag': 'OR create a new tag',
        'ProductsPage:addTag': 'Add tag',
        'ProductsPage:newTagName': 'Tag name',
        'ProductsPage:unitPrice': 'Unit price',

        'PublicWebsitePageList:title': 'Public website page settings',
        'publicWebsitePages:generalSettings': 'General settings',
        'publicWebsitePages:onlineBooking': 'Online booking',
        'publicWebsitePages:onlineRegistration': 'Online registration',
        'publicWebsitePages:payment': 'Payments page',
        'publicWebsitePages:analytics': 'Analytics',

        'publicWebsitePages:NoBookingConfEmailTemplate': 'Missing confirmation email template',
        'publicWebsitePages:NoBookingCompletionPageContent': 'Missing confirmation page content',

        'publicWebsitePages:NoRegLandingPageContent': 'Missing landing page content',
        'publicWebsitePages:NoRegCompletionPageContent': 'Missing confirmation page content',

        'PublicWebsitesList:website': 'Website',
        'PublicWebsitesList:defaultResources': 'Default resources',
        'PublicWebsitesList:otherResources': 'Other resources',
        'PublicWebsitesList:addWebsite': '+ Add website',
        'PublicWebsitesList:overlappingResourceWarning': 'WARNING: We strongly recommend that resources are assigned to only one website.  If resources are mixed across websites, registration and payment links for that resource may be generated to the wrong website',
        'PublicWebsitesList:nonDefaultResource': 'One or more resources do not have a default website.  Please make sure all resources have a default',

        'PublicWebsiteSettingsForm:publicWebsiteName': 'Name',
        'PublicWebsiteSettingsForm:siteName': 'Site name',
        'PublicWebsiteSettingsForm:backgroundImage': 'Background image',
        'PublicWebsiteSettingsForm:headerImage': 'Header image',
        'PublicWebsiteSettingsForm:resources': 'Resources',
        'PublicWebsiteSettingsForm:headerImageSizeInfo': 'Recommended header image settings: 900px wide, 120px high, jpeg format',
        'PublicWebsiteSettingsForm:panelBackgroundColour': 'Panel background colour',
        'PublicWebsiteSettingsForm:panelBackgroundOpacity': 'Panel background opacity',
        'PublicWebsiteSettingsForm:primaryButtonColour': 'Primary button colour',
        'PublicWebsiteSettingsForm:primaryButtonTextColour': 'Primary button text colour',
        'PublicWebsiteSettingsForm:panelTextColour': 'Panel text colour',
        'PublicWebsiteSettingsForm:cookieOptionsType': 'Show cookie consent as',
        'PublicWebsiteSettingsForm:hideOnPublicWebsiteList': 'Hide on list of websites',

        'PublicWebsiteSettingsForm:selectResourceHeading': 'Select',
        'PublicWebsiteSettingsForm:resourceNameHeading': 'Name',
        'PublicWebsiteSettingsForm:defaultHeading': 'Default website for resource',

        'OnlineBookingSettingsForm:title': 'Online booking',
        'OnlineBookingSettingsForm:noAvailabilityMessage': 'No availability message',
        'OnlineBookingSettingsForm:bookingConfirmationEmailTemplate': 'Booking confirmation email template',
        'OnlineBookingSettingsForm:upsellActivitiesPageHeader': 'Upsell activities page message',
        'OnlineBookingSettingsForm:completionPageContent': 'Booking confirmation page content',
        'OnlineBookingSettingsForm:availableSlotButtonColour': 'Available slot button colour',
        'OnlineBookingSettingsForm:availableSlotButtonTextColour': 'Available slot button text colour',
        'OnlineBookingSettingsForm:unavailableSlotButtonColour': 'Unavailable slot button colour',
        'OnlineBookingSettingsForm:unavailableSlotButtonTextColour': 'Unavailable slot button text colour',
        'OnlineBookingSettingsForm:alternateAvailabilityMessage': 'Message to show above alternative availability buttons',
        'OnlineBookingSettingsForm:showArrivalTime': 'Show arrival time',
        'OnlineBookingSettingsForm:webBookingTermsAndConditions': 'Web booking terms and conditions',
        'OnlineBookingSettingsForm:confirmWebBookingTermsText': 'Web booking terms & conditions text',
        'OnlineBookingSettingsForm:confirmWebBookingTermsTextHelp': 'The text that appears next to the web booking terms and conditions tick box',
        'OnlineBookingSettingsForm:arrivalTimeMessage': 'Arrival time message',
        'OnlineBookingSettingsForm:arrivalTimeMessageHint': 'You can use tag {{arrival_time_mins}} to insert resource arrival time',
        'OnlineBookingSettingsForm:webBookingFee': 'Online booking fee',
        'OnlineBookingSettingsForm:noFee': 'No booking fee',
        'OnlineBookingSettingsForm:optionalCustomerInformation': 'Optional customer information to collect',
        'OnlineBookingSettingsForm:setting_collect': 'Collect',
        'OnlineBookingSettingsForm:setting_required': 'Required',
        'OnlineBookingSettingsForm:setting_addressLine1': 'Address line 1',
        'OnlineBookingSettingsForm:setting_fullAddress': 'Full address',
        'OnlineBookingSettingsForm:setting_phoneNumber': 'Phone number',
        'OnlineBookingSettingsForm:bookingBanner': 'Booking page banner',
        'OnlineBookingSettingsForm:bookingBannerBackground': 'Banner background colour',
        'OnlineBookingSettingsForm:bookingBannerBorder': 'Banner border colour',

        'OnlineBookingSettingsForm:completionPageTags': 'Completion page tags',

        'WebsiteAnalyticsForm:trackConversions': 'Track conversions',
        'WebsiteAnalyticsForm:incompatibleProvider': 'This provider cannot be used if {incompatibleProvider} is active.  Please disable {incompatibleProvider} before setting up this provider.',

        'WebAnalyticsSettingKey:Tracking ID':'Tracking ID',
        'WebAnalyticsSettingKey:Primary Domain': 'Primary Domain',
        'WebAnalyticsSettingKey:Pixel ID': 'Pixel ID',
        'WebAnalyticsSettingKey:Account ID': 'Account ID',
        'WebAnalyticsSettingKey:Webshop purchase webhook URL': 'Webshop purchase webhook URL',
        'WebAnalyticsSettingKey:Booking webhook URL': 'Booking webhook URL',
        'WebAnalyticsSettingKey:Measuerment ID': 'Measurement ID',
        'WebAnalyticsSettingKey:Conversion Label': 'Conversion Label',
        'WebAnalyticsSettingKey:Pixel Name': 'Pixel Name',
        'WebAnalyticsSettingKey:Disable Page Views': 'Disable Page View Tracking',
        'WebAnalyticsSettingKey:Use Enhanced Ecommerce': 'Use Enhanced Ecommerce',

        'BookingConfirmationPageTag:event_name': 'The name of the event',
        'BookingConfirmationPageTag:event_date': 'The date of the event',
        'BookingConfirmationPageTag:event_start_time': 'The start time of the event',
        'BookingConfirmationPageTag:event_arrival_time': 'The arrival time of the event',
        'BookingConfirmationPageTag:activity_name': 'The name of the first activity',
        'BookingConfirmationPageTag:customer_first_name': 'The customer\'s first name',
        'BookingConfirmationPageTag:customer_last_name': 'The customer\'s last name',
        'BookingConfirmationPageTag:activity_description': 'The description of the first activity',
        'BookingConfirmationPageTag:registration_link': 'A link to register participants for the event. To set the text for the link add :text=the text the closing }} (e.g. {{registration_link:text=click to check in for your event}})',
        'BookingConfirmationPageTag:plain_registration_url': 'An unformatted URL to register participants for the event. NOTE: Only use as the href on an a tag where advanced formatting is required',
        'BookingConfirmationPageTag:plain_registration_short_link': 'An unformatted short link to register participants for the event. NOTE: Only use as the href on an a tag where advanced formatting is required',


        'OnlineRegistrationSettingsForm:title': 'Online registration',
        'OnlineRegistrationSettingsForm:landingPageContent': 'Landing page content',
        'OnlineRegistrationSettingsForm:participantsPageHeading': 'Participant page heading',
        'OnlineRegistrationSettingsForm:completionPageContent': 'Registration confirmation page content',
        'OnlineRegistrationSettingsForm:briefingPageContent': 'Briefing page content (appears before video)',
        'OnlineRegistrationSettingsForm:briefingPageConfirmationText': 'Booking video confirmation text',
        'OnlineRegistrationSettingsForm:registrationVideo': 'Registration video',
        'OnlineRegistrationSettingsForm:uploading': 'Uploading {percent}%',
        'OnlineRegistrationSettingsForm:videoTooLarge': 'The video file is too big ({size}MB).  The maximum file size is {max}MB',
        'OnlineRegistrationSettingsForm:processingUpload': 'Processing upload.....',
        'OnlineRegistrationSettingsForm:noVideo': '<no video>',
        'OnlineRegistrationSettingsForm:defaultRegistrationTerms': 'Default waiver',
        'OnlineRegistrationSettingsForm:registrationKiosk': 'Take registration settings from Kiosk',
        'OnlineRegistrationSettingsForm:selectRegistrationKiosk': 'Select kiosk to use for registration settings',

        'PaymentPageForm:heading': 'Page heading',
        'PaymentPageForm:body': 'Page content',
        'PaymentPageForm:payButtonText': 'Pay button text',

        'RefundForm:amountExceedsPaymentTotal': 'Refund cannot be greater than the total amount paid using {paymentMethod} (£{paidAmount})',

        'TasksPage:title': 'Tasks',
        'TasksPage:addTask': 'New task',
        'TasksPage:nameHeading': 'Name',
        'TasksPage:triggerHeading': 'Trigger',
        'TasksPage:emailTempateHeading': 'Email template',
        'TasksPage:reminderEmailTemplateHeading': 'Reminder email template',

        'TaskList:title': 'Tasks',
        'TaskList:addTaskDefinition': 'Add task',
        'TaskList:nameHeading': 'Name',

        'TaskForm:addTask': 'Add task',
        'TaskForm:name': 'Name',
        'TaskForm:taskTrigger': 'Activate task when',
        'TaskForm:taskTriggerOffset': 'Number of days before/after activation event',
        'TaskForm:emailRecipient': 'Send email to',
        'TaskForm:clientEmailTemplateId': 'Email template',
        'TaskForm:recipientAddress': 'Specific recipient address(es) - separate with ;',
        'TaskForm:noEmailTemplate': '<select email template>',
        'TaskForm:taskData': 'Task data',
        'TaskForm:validationNegativeOffset': 'Task offset cannot be negative when using Event created activation.',

        'PaymentMethodsPage:title': 'Payment Methods',
        'PaymentMethodsPage:addPaymentMethod': 'Add payment method',
        'PaymentMethodsPage:nameHeading': 'Name',
        'PaymentMethodsPage:posTextHeading': 'POS text',
        'PaymentMethodsPage:typeHeading': 'Type',
        'PaymentMethodsPage:paymentGateways': 'Gateway settings',
        'PaymentMethodsPage:publicName': 'Public name',
        'PaymentMethodsPage:webEnabled': 'Web enabled',
        'PaymentMethodsPage:webButtonText':'Web button text',

        'PaymentMethodForm:addPaymentMethod': 'Add payment method',
        'PaymentMethodForm:editPaymentMethod': 'Edit payment method',
        'PaymentMethodForm:paymentMethodType': 'Type',
        'PaymentMethodForm:paymentGatewayType': 'Gateway type',
        'PaymentMethodForm:pointOfSaleText': 'Point of sale text',
        'PaymentMethodForm:openCashDrawer': 'Open cash drawer',
        'PaymentMethodForm:eventPaymentsOnly': 'Only show for event booking payments',
        'PaymentMethodForm:additionalInformation': 'Additional information',
        'PaymentMethodForm:canRefund': 'Allow refunds',
        'PaymentMethodForm:limitRefundToPaidAmount': 'Limit refund amount to total payment amount for this payment method',
        'PaymentMethodForm:allowCustomerWebPayment': 'Enable for web payment',
        'PaymentMethodForm:gatewaySettings': '{gatewayName} settings',
        'PaymentMethodForm:publicPaymentName': 'Public payment method name',
        'PaymentMethodForm:webPaymentButtonText': 'Web payment button text',


        'PaymentMethodForm:connectWithStripeText': 'Please click the button below to link your stripe account with Alpha.',
        'PaymentMethodForm:connectWithStripe': 'Connect with stripe',
        'PaymentMethodForm:stripeConnected': 'Your account is connected with Stripe',
        'PaymentMethodForm:orEnterClientId': 'Or enter your stripe id (if known)',
        'PaymentMethodForm:saveBeforeStrpeConnectMessage': 'Please save payment method before connecting to stripe',
        'PaymentMethodForm:connectingToStripeHeader': 'Connect to stripe',
        'PaymentMethodForm:connectingToStripeText': 'A new window has been opened.  You will need to either create a new strripe account or log into your account in that window.  This will then authroize us to connect with your account to process payments.',
        'PaymentMethodForm:missingConfiguration': 'Unable to connect with stripe - client_id is missing from gateway configuration',
        'PaymentMethodForm:saveBeforeConnectingStripe': 'Please save any changes before connecting with stripe',
        'PaymentMethodForm:testMode': 'Test mode',
        'PaymentMethodForm:enableCardReaders': 'Enable card readers',

        'PaymentSchedulesPage:addPaymentSchedule':'Add payment schedule',
        'PaymentSchedulesPage:nameHeading': 'Name',
        'PaymentSchedulesPage:enableForWebBookingHeading': 'Web',
        'PaymentSchedulesPage:minimumAmountThresholdHeading': 'Min Amount',
        'PaymentSchedulesPage:immediatePaymentAmountHeading': 'Immediate amount',

        'PaymentScheduleForm:addPaymentSchedule': 'Add payment schedule',
        'PaymentScheduleForm:editPaymentSchedule': 'Edit payment schedule',
        'PaymentScheduleForm:paymentSchedule': 'Schedule',
        'PaymentScheduleForm:enableForWebBooking': 'Use for web bookings?',
        'PaymentScheduleForm:minimumAmountThreshold': 'Only apply for bills amounts over',
        'PaymentScheduleForm:immediatePayment': 'Initial payment',
        'PaymentScheduleForm:finalPayment': 'Final payment',
        'PaymentScheduleForm:payment': 'Payment',
        'PaymentScheduleForm:description': 'Description',
        'PaymentScheduleForm:due': 'Due',
        'PaymentScheduleForm:whenBookingTaken': 'When booking made',
        'PaymentScheduleForm:remainingBalance': 'Remaining balance',
        'PaymentScheduleForm:AddScheduleItem': '+ Add schedule payment',

        'DuePayments:dueHeading': 'Due date',
        'DuePayments:descriptionHeading': 'Description',
        'DuePayments:companyHeading': 'Company',
        'DuePayments:nameHeading': 'Customer',
        'DuePayments:phoneHeading': 'Phone number',
        'DuePayments:emailheading': 'Email',
        'DuePayments:eventNameHeading': 'Event name',
        'DuePayments:eventDateHeading': 'Event date',

        'RecentPayments:heading': 'Recent payments',
        'RecentPayment.voucher': 'Voucher',
        'RecentPayment.membership':'Membership',

        'TaskConfirmationPanel:trigger': 'Trigger',
        'TaskConfirmationPanel:due': 'Due',
        'TaskConfirmationPanel:parameters': 'Data',
        'TaskConfirmationPanel:viewEmail': 'View email',
        'TaskConfirmationPanel:activateTask': 'Activate',

        'ReportsPage:title': 'Reports',
        'ReportsPage:addReport': 'New report',
        'ReportsPage:reportTypeHeading': 'Report type',

        'ReportDefinitionForm:columns': 'Report columns',
        'ReportDefinitionForm:filters': 'Report filters',
        'ReportDefinitionForm:newReport': 'New report',
        'ReportDefinitionForm:editReport': 'Edit report',
        'ReportDefinitionForm:indexHeading': 'Col',
        'ReportDefinitionForm:elementHeading': 'Data',
        'ReportDefinitionForm:headingHeading': 'Heading',
        'ReportDefinitionForm:totalHeading': 'Total',
        'ReportDefinitionForm:summarize': 'Summarize',
        'ReportDefinitionForm:sortHeading': 'Sort',
        'ReportDefinitionForm:addColumn': 'Add column',
        'ReportDefinitionForm:selectColumn': '<select element>',
        'ReportDefinitionForm:countHeading': 'Heading for count column',
        'ReportDefinitionForm:copyFrom': 'Copy from report (leave blank to create a report from scratch)',
        'ReportDefinitionForm:noReportSelected': '<No report selected>',

        'ReportDefinitionForm:filterNameHeading': 'Name',
        'ReportDefinitionForm:filterUserSelectableHeading': 'Selectable',
        'ReportDefinitionForm:filterDefaultsHeading': 'Value',
        'ReportDefinitionForm:noColumnsSelected': 'Please select one or more columns before saving',
        'ReportDefinitionForm:permissions': 'Permissions',
        'ReportDefinitionForm:userNameHeading': 'User',
        'ReportDefinitionForm:runReportHeading': 'Run report',
        'ReportDefinitionForm:editReportHeading': 'Edit report',

        'ViewReportPage:ViewButton': 'View report',

        'ApiError:unableToConnect': 'Unable to connect to server.  Please check your internet connection and retry',
        'ApiError:unauthorized': 'You do not have the permission to perform this action',
        'ApiError:unprocessableEntity': 'Please correct the errors and re-try',
        'ApiError:serverError': 'There was an error processing your request',

        // Report data elements
        'Payments:Payment ID': 'Payment ID',
        'Payments:Venue': 'Venue',
        'Payments:Payment Method': 'Payment Method',
        'Payments:Payment Status': 'Payment Status',
        'Payments:Description': 'Description',
        'Payments:Payment Date Time': 'Payment Date Time',
        'Payments:Payment Date Time (UTC)': 'Payment Date Time (UTC)',
        'Payments:Payment Date': 'Payment Date',
        'Payments:Payment Month': 'Payment Month',
        'Payments:Payment Week': 'Payment Week',
        'Payments:Payment Due Date': 'Payment Due Date',
        'Payments:Payment Taken By': 'Payment Taken By',
        'Payments:Payment Receipt': 'Payment Receipt',
        'Payments:Payment Void': 'Payment Void',
        'Payments:Amount': 'Amount',
        'Payments:Void Reason': 'Void Reason',
        'Payments:Voided By': 'Voided By',
        'Payments:Void Date': 'Void Date',
        'Payments:Payment Deleted': 'Payment Deleted',
        'Payments:Deleted By': 'Deleted By',
        'Payments:Deletion Date': 'Deletion Date',
        'Payments:Device Name': 'Device Name',
        'Payments:Terminal Name': 'Terminal name',
        'Payments:Event Name': 'Event Name',
        'Payments:Event Date Time': 'Event Date Time',
        'Payments:Event Date': 'Event Date',
        'Payments:Event Month': 'Event Month',
        'Payments:Event Week': 'Event Week',

        'Payments:Event Or Bill Date Time': 'Event Or Bill Date Time',
        'Payments:Event Or Bill Date': 'Event Or Bill Date',
        'Payments:Event Or Bill Month': 'Event Or Bill Month',
        'Payments:Event Or Bill Week': 'Event Or Bill Week',
        'Payments:Bill Number': 'Bill Number',
        'Payments:Transaction Id': 'Transaction Id',
        'Payments:Net Bill Total Amount': 'Net Bill Total Amount',
        'Payments:Gross Bill Total Amount': 'Gross Bill Total Amount',
        'Payments:Bill Discount Amount': 'Bill Discount Amount',
        'Payments:Bill Tax Amount': 'Bill Tax Amount',
        'Payments:Bill Fee Amount': 'Bill Fee Amount',
        'Payments:Customer Name': 'Customer Name',
        'Payments:Customer Email': 'Customer Email',
        'Payments:Payment Due Week': 'Payment due week',
        'Payments:Payment Due Month': 'Payment due month',
        'Payments:Company Name': 'Company Name',
        'Payments:Customer Address': 'Customer Address',
        'Payments:Additional Info': 'Additional Info',
        'Payments:Activities': 'Activities',
        'Payments:Resources': 'Resources',
        'Payments:Products': 'Products',
        'Payments:Voucher Purchase Date': 'Voucher Purchase Date',
        'Payments:Voucher Purchase Year': 'Voucher Purchase Year',
        'Payments:Voucher Purchase Month': 'Voucher Purchase Month',
        'Payments:Voucher Product': 'Voucher product',
        'Payments:Processing Fee Amount': 'Processing Fee',
        'Payments:Net Payment Amount': 'Net Payment Amount',
        'Payments:Platform Fee Amount': 'Platform Fee',
        'Payments:Payout Amount': 'Payout Amount',
        'Payments:Deposit': 'Deposit',
        'Payments:Primary Activity': 'Primary Activity',
        'Payments:Primary Activity Nominal Code': 'Primary Activity Nominal Code',
        'Payments:Primary Activity Accounting Category':'Primary Activity Accounting Category',
        'Payments:Primary Activity Accounting Department': 'Primary Activity Accounting Department',

        'ProductSales:Venue': 'Venue',
        'ProductSales:Product Name': 'Product Name',
        'ProductSales:Product Description': 'Description',
        'ProductSales:Quantity': 'Quantity',
        'ProductSales:Item Total': 'Gross Item Total',
        'ProductSales:Net Item Total': 'Net Item Total',
        'ProductSales:Item Total Tax': 'Item Total Tax ',
        'ProductSales:Item Description': 'Item Description',
        'ProductSales:Unit Price': 'Unit price',
        'ProductSales:Sold By': 'Sold By',
        'ProductSales:Device Name': 'Device',
        'ProductSales:Bill Number': 'Bill Number',
        'ProductSales:Customer Name': 'Customer Name',
        'ProductSales:Company Name': 'Company Name',
        'ProductSales:Total Bill Amount': 'Gross Bill Total',
        'ProductSales:Net Total Bill Amount': 'Net Bill Total',
        'ProductSales:Bill Discount Amount': 'Discount',
        'ProductSales:Item Tax Rate': 'Tax rate',
        'ProductSales:Bill Tax Amount': 'Tax',
        'ProductSales:Bill Fee Amount': 'Fee',
        'ProductSales:Total Paid': 'Total Paid',
        'ProductSales:Payment Date Time': 'Payment Date Time',
        'ProductSales:Payment Date': 'Payment Date',
        'ProductSales:Payment Month': 'Payment Month',
        'ProductSales:Payment Week': 'Payment Week',
        'ProductSales:Bill Date Time': 'Bill Date Time',
        'ProductSales:Bill Date': 'Bill Date',
        'ProductSales:Bill Month': 'Bill Month',
        'ProductSales:Bill Week': 'Bill Week',
        'ProductSales:Accounting Category': 'Accounting Category',
        'ProductSales:Accounting Department': 'Accounting Department',
        'ProductSales:Nominal Code': 'Nominal Code',
        'ProductSales:Web Shop': 'Web Shop',
        'ProductSales:Event Date': 'Event date',
        'ProductSales:Event Month': 'Event month',
        'ProductSales:Promotion': 'Promotion name',
        'ProductSales:Outstanding Amount': 'Outstanding Amount',

        'Reports:noResults': 'No results found',

        'ActivitySales:Event Date Time': 'Event Date Time',
        'ActivitySales:Event Date': 'Event Date',
        'ActivitySales:Event Month': 'Event Month',
        'ActivitySales:Event Week': 'Event Week',
        'ActivitySales:Booking Taken Date Time': 'Booking Taken Date Time',
        'ActivitySales:Booking Taken Date': 'Booking Taken Date',
        'ActivitySales:Booking Taken Month': 'Booking Taken Month',
        'ActivitySales:Booking Taken Week': 'Booking Taken Week',
        'ActivitySales:Venue': 'Venue',
        'ActivitySales:Resource': 'Resource',
        'ActivitySales:Activity Id': 'Activity Id',
        'ActivitySales:Activity Name': 'Activity Name',
        'ActivitySales:Activity Variation': 'Activity Schedule',
        'ActivitySales:Number of Events': 'Number of Events',
        'ActivitySales:Number of Participants': 'Number of Participants',
        'ActivitySales:Activity Amount': 'Activity Amount',
        'ActivitySales:Booking Amount': 'Booking Amount',
        'ActivitySales:Web Booking': 'Web Booking',
        'ActivitySales:Payment Method': 'Payment Method',
        'ActivitySales:Bill Number': 'Bill Number',
        'ActivitySales:Bill Total Paid':'Bill Total Paid',
        'ActivitySales:Bill Discount Amount':'Bill Discount Amount',
        'ActivitySales:Total Bill Amount': 'Total Bill Amount',
        'ActivitySales:Bill Tax Amount': 'Bill Tax Amount',
        'ActivitySales:Bill Fee Amount': 'Bill Fee Amount',
        'ActivitySales:Last Payment DateTime': 'Last Payment DateTime',
        'ActivitySales:Outstanding Bill Amount': 'Outstanding Bill Amount',
        'ActivitySales:Customer First Name': 'Customer First Name',
        'ActivitySales:Customer Last Name':'Customer Last Name',
        'ActivitySales:Customer Full Name': 'Customer Full Name',

        'Bookings:Event Date Time': 'Event Date Time',
        'Bookings:Event Date': 'Event Date',
        'Bookings:Event Month': 'Event Month',
        'Bookings:Event Week': 'Event Week',
        'Bookings:Booking Taken Date Time': 'Booking Taken Date Time',
        'Bookings:Booking Taken Date': 'Booking Taken Date',
        'Bookings:Booking Taken Month': 'Booking Taken Month',
        'Bookings:Booking Taken Week': 'Booking Taken Week',
        'Bookings:Booking Taken hour': 'Booking Taken Hour',
        'Bookings:Venue': 'Venue',
        'Bookings:Resource': 'Resource',
        'Bookings:Resource Configuration': 'Resource Configuration',
        'Bookings:Event Bill': 'Event Bill',
        'Bookings:Bill Number': 'Bill Number',
        'Bookings:Activity Name': 'Activity Name',
        'Bookings:Activity Id': 'Activity Id',
        'Bookings:Affiliate': 'Affiliate',
        'Bookings:Customer Name': 'Customer Name',
        'Bookings:Company Name': 'Company Name',
        'Bookings:Customer Email':'Email Address',
        'Bookings:Customer Phone':'Phone Number',
        'Bookings:Phone Marketing': 'Agreed to Phone Marketing',
        'Bookings:Email Marketing': 'Agreed to Email Marketing',
        'Bookings:Total Amount': 'Total Amount',
        'Bookings:Total Paid': 'Total Paid',
        'Bookings:Total Outstanding': 'Total Outstanding',
        'Bookings:Web Booking': 'Web Booking',
        'Bookings:Registrations': 'Number of Registrations',
        'Bookings:TotalParticipants': 'Number of participants',
        'Bookings:Cancelled': 'Cancelled',
        'Bookings:Cancelled By': 'Cancelled by',
        'Bookings:Cancellation Reason': 'Cancellation Reason',
        'Bookings:Web Booking Status': 'Web Booking Status',
        'Bookings:Referrer': 'Referrer',
        'Bookings:Discount Amount':'Discount Amount',
        'Bookings:Promotion': 'Promotion',
        'Bookings:Taken By':'Booked by',

        'DeletedEvents:Start Time': 'Start Time',
        'DeletedEvents:End Time': 'End Time',
        'DeletedEvents:Venue': 'Venue',
        'DeletedEvents:Resource': 'Resource',
        'DeletedEvents:Resource Configuration': 'Resource Configuration',
        'DeletedEvents:Activity Name': 'Activity Name',
        'DeletedEvents:Time Deleted': 'Time Deleted',
        'DeletedEvents:Deleted by': 'Deleted by',
        'DeletedEvents:Reason': 'Reason',
        'DeletedEvents:Ip Address': 'Ip Address',

        'BillSummary:Venue': 'Venue',
        'BillSummary:Date Created': 'Date Created',
        'BillSummary:Month Created': 'Month Created',
        'BillSummary:Week Created': 'Week Created',
        'BillSummary:Event Date': 'Event Date',
        'BillSummary:Event Month': 'Event Month',
        'BillSummary:Event Week': 'Event Week',
        'BillSummary:Booking Cancelled': 'Booking Cancelled',
        'BillSummary:Bill Type': 'Bill Type',
        'BillSummary:Bill Number': 'Bill Number',
        'BillSummary:Created By': 'Created By',
        'BillSummary:Customer Name': 'Customer Name',
        'BillSummary:Customer Email': 'Customer Email',
        'BillSummary:Customer Phone': 'Customer Phone',
        'BillSummary:Company': 'Company',
        'BillSummary:Net Total Amount': 'Net Total Amount',
        'BillSummary:Gross Total Amount': 'Gross Total Amount',
        'BillSummary:Tax Amount': 'Tax Amount',
        'BillSummary:Fee Amount': 'Fee Amount',
        'BillSummary:Discount Amount': 'Discount Amount',
        'BillSummary:Void Item Amount': 'Void Item Amount',
        'BillSummary:Amount Paid': 'Amount Paid',
        'BillSummary:Amount Refunded': 'Amount Refunded',
        'BillSummary:Outstanding Balance': 'Outstanding Balance',
        'BillSummary:Promotion Name': 'Promotion name',

        'Vouchers:Voucher Code': 'Voucher Code',
        'Vouchers:Purchase Date Time': 'Purchase Date Time',
        'Vouchers:Purchase Date': 'Purchase Date',
        'Vouchers:Purchase Month': 'Purchase Month',
        'Vouchers:Purchase Week': 'Purchase Week',
        'Vouchers:Purchase Price': 'Purchase Price',
        'Vouchers:Redemption Amount': 'Redemption Amount',
        'Vouchers:Remaining Balance': 'Remaining Balance',
        'Vouchers:Total Redeemed': 'Total Redeemed',
        'Vouchers:Expiry Date Time': 'Expiry Date Time',
        'Vouchers:Expiry Date': 'Expiry Date',
        'Vouchers:Expiry Month': 'Expiry Month',
        'Vouchers:Expiry Week': 'Expiry Week',
        'Vouchers:Active From Date Time': 'Active From Date Time',
        'Vouchers:Active From Date': 'Active From Date',
        'Vouchers:Active From Month': 'Active From Month',
        'Vouchers:Active From Week': 'Active From Week',
        'Vouchers:Product': 'Product',
        'Vouchers:Revoked': 'Revoked',
        'Vouchers:Revocation Reason': 'Revocation Reason',
        'Vouchers:Revoked By': 'Revoked By',
        'Vouchers:Revocation Date Time': 'Revocation Date Time',
        'Vouchers:Redeemble online': 'Redeemble online',
        'Vouchers:Email address': 'Email address',
        'Vouchers:Customer Name': 'Customer Name',
        'Vouchers:Bill Number': 'Bill number',

        'Customers:First Name': 'First Name',
        'Customers:Last Name': 'Last Name',
        'Customers:Company Name': 'Company Name',
        'Customers:Age': 'Age',
        'Customers:Age Group':'Age group',
        'Customers:Address Line 1': 'Address Line 1',
        'Customers:Address Line 2': 'Address Line 2',
        'Customers:Address Line 3': 'Address Line 3',
        'Customers:Address Line 4': 'Address Line 4',
        'Customers:Town': 'Town',
        'Customers:County': 'County',
        'Customers:Country': 'Country',
        'Customers:Postal Code': 'Postal Code',
        'Customers:Email Address': 'Email Address',
        'Customers:Phone Number': 'Phone Number',
        'Customers:Emergency Contact Name': 'Emergency Contact Name',
        'Customers:Emergency Contact Number': 'Emergency Contact Number',
        'Customers:Last Visit': 'Last Visit',
        'Customers:Last Event': 'Last Event',
        'Customers:Last Activity Name':'Last Activity Name',
        'Customers:Number Of Sessions': 'Number Of Sessions',
        'Customers:Number Of Events': 'Number Of Events',
        'Customers:Gender': 'Gender',

        'WebAnalysis:Event Date Time': 'Event Date Time',
        'WebAnalysis:Event Date': 'Event Date',
        'WebAnalysis:Event Month': 'Event Month',
        'WebAnalysis:Event Week': 'Event Week',
        'WebAnalysis:Booking Taken Date Time': 'Booking Taken Date Time',
        'WebAnalysis:Booking Taken Date': 'Booking Taken Date',
        'WebAnalysis:Booking Taken Month': 'Booking Taken Month',
        'WebAnalysis:Booking Taken Week': 'Booking Taken Week',
        'WebAnalysis:Booking Taken Day Of Week': 'Booking Taken Day of Week',
        'WebAnalysis:Booking Taken Hour Of Day': 'Booking Taken Hour of Day',
        'WebAnalysis:Venue': 'Venue',
        'WebAnalysis:Resource': 'Resource',
        'WebAnalysis:Resource Configuration': 'Resource Configuration',
        'WebAnalysis:Event Bill': 'Event Bill',
        'WebAnalysis:Bill Number': 'Bill Number',
        'WebAnalysis:Activity Name': 'Activity Name',
        'WebAnalysis:Activity Id': 'Activity Id',
        'WebAnalysis:Affiliate': 'Affiliate',
        'WebAnalysis:Customer Name': 'Customer Name',
        'WebAnalysis:Company Name': 'Company Name',
        'WebAnalysis:Total Amount': 'Total Amount',
        'WebAnalysis:Total Paid': 'Total Paid',
        'WebAnalysis:Total Outstanding': 'Total Outstanding',
        'WebAnalysis:Web Booking': 'Web Booking',
        'WebAnalysis:Cancelled': 'Cancelled',
        'WebAnalysis:Cancelled By': 'Cancelled by',
        'WebAnalysis:Cancellation Reason': 'Cancellation Reason',
        'WebAnalysis:Web Booking Status': 'Web Booking Status',
        'WebAnalysis:Booked Via': 'Booked Via',
        'WebAnalysis:Booked Via Args': 'Booked Via Args',

        'Registrations:Customer Name': 'Customer Name',
        'Registrations:Customer Email': 'Customer Email',
        'Registrations:Customer Phone': 'Customer Phone',
        'Registrations:Phone Marketing': 'Phone Marketing',
        'Registrations:Email Marketing': 'Email Marketing',
        'Registrations:Registration Date Time': 'Registration Date Time',
        'Registrations:Registration Date': 'Registration Date',
        'Registrations:Registration Month': 'Registration Month',
        'Registrations:Registration Week': 'Registration Week',
        'Registrations:Event Date Time': 'Event Date Time',
        'Registrations:Event Date': 'Event Date',
        'Registrations:Event Month': 'Event Month',
        'Registrations:Event Week': 'Event Week',
        'Registrations:Bill Number': 'Bill Number',
        'Registrations:Web Registration': 'Web Registration',
        'Registrations:Waiver Name': 'Waiver Name',
        'Registrations:Watched Video': 'Watched Video',
        'Registrations:Nickname': 'Nickname',
        'Registrations:Age On Event Date': 'Age On Event Date',
        'Registrations:Counter Signatory Name': 'Counter Signatory Name',
        'Registrations:Kiosk': 'Kiosk',
        'Registrations:Categories': 'Categories',

        'FeeList:addFee': 'Add fee',
        'FeeList:nameHeading': 'Name',
        'FeeList:feeTypeHeading': 'Type',
        'FeeList:fixedAmountHeading': 'Fixed Amount',
        'FeeList:percentageHeading': 'Percentage',
        'FeeList:maxAmountHeading': 'Maximum amount',
        'FeeList:taxRateHeading': 'Tax rate',

        'FeeForm:addFee': 'Add fee',
        'FeeForm:selectTaxRate': '<select tax rate>',
        'FeeForm:name': 'Name',
        'FeeForm:feeType': 'Type',
        'FeeForm:fixedAmount': 'Fixed fee amount',
        'FeeForm:percentage': 'Fee percentage',
        'FeeForm:maxAmount': 'Maximum fee amount (leave 0 for no maximum)',
        'FeeForm:taxRate': 'Tax rate',

        'ReportFilter:noDefault': '<no default>',
        'ReportFilter:FilterHeading': 'Filters',
        'ReportFilter:Venue': 'Venue',
        'ReportFilter:Resource': 'Resource',
        'ReportFilter:PaymentMethod': 'Payment Method',
        'ReportFilter:PaymentTakenBy': 'Payment Taken By',
        'ReportFilter:PaymentDate': 'Payment Taken Date',
        'ReportFilter:UTCPaymentDate': 'Payment Taken Date (UTC)',
        'ReportFilter:EventDate': 'Event Date',
        'ReportFilter:EventOrBillDate': 'Event Date (or bill date if non event bill)',
        'ReportFilter:BookingTakenDate': 'Booking Taken Date',
        'ReportFilter:BookingTakenHour': 'Booking Taken Hour',
        'ReportFilter:Void': 'Void payments',
        'ReportFilter:VoidDate': 'Payment void date',
        'ReportFilter:PaymentStatus': 'Payment Status',
        'ReportFilter:Paid': 'Paid',
        'ReportFilter:BillDate': 'Bill Date',
        'ReportFilter:ProductCategory': 'Product Category',
        'ReportFilter:ProductCategoryName': 'Product Category Name',
        'ReportFilter:DeviceName': 'Device Name',
        'ReportFilter:AccountingCategory': 'Accounting Category',
        'ReportFilter:AccountingDepartment': 'Accounting Department',
        'ReportFilter:NominalCode': 'Nominal Code',
        'ReportFilter:ActivityFormatName': 'Activity Format Name',
        'ReportFilter:CreationDate': 'Creation date',
        'ReportFilter:EventBillsOnly': 'Event bills only',
        'ReportFilter:ActivityName': 'Activity name',
        'ReportFilter:WebBooking': 'Web booking',
        'ReportFilter:WebBookingState': 'Web booking status',
        'ReportFilter:StartTime': 'Start time',
        'ReportFilter:TimeDeleted': 'Time deleted',
        'ReportFilter:BillCreated': 'Bill created',
        'ReportFilter:BillType': 'Bill type',
        'ReportFilter:BillFullyPaid': 'Fully paid',
        'ReportFilter:BillPaymentStatus': 'Payment status',
        'ReportFilter:FullyPaid': 'Fully paid',
        'ReportFilter:ExcludeCancelledBookings': 'Exclude cancelled bookings',
        'ReportFilter:Yes': 'Yes',
        'ReportFilter:No': 'No',
        'ReportFilter:Affiliate': 'Affiliate',
        'ReportFilter:WebShopPurchase': 'Web shop purchase',
        'ReportFilter:PurchaseDate': 'Purchase date',
        'ReportFilter:ExpiryDate': 'Expiry date',
        'ReportFilter:ActiveFromDate': 'Active from date',
        'ReportFilter:Revoked': 'Revoked',
        'ReportFilter:Product': 'Product',
        'ReportFilter:RedemptionAmount': 'Redemption amount',
        'ReportFilter:RemainingBalance': 'Remaining balance',
        'ReportFilter:Cancelled': 'Cancelled',
        'ReportFilter:NotCancelled': 'Not cancelled',
        'ReportFilter:OnlyCancelled': 'Only cancelled',
        'ReportFilter:BothCancelledAndNot': 'Both cancelled and not cancelled',
        'ReportFilter:NotRefunded': 'Not refunded',
        'ReportFilter:OnlyRefunded': 'Only refunded',
        'ReportFilter:BothRefundedAndNot': 'Both refunded and not refunded',
        'ReportFilter:Refunded': 'Refunded',
        'ReportFilter:FeeAmount':'Fee amount',
        'ReportFilter:BookingPaymentStatus': 'Payment status',
        'ReportFilter:AnyWebBookingState': 'Any',
        'ReportFilter:IncompleteWebBookings': 'Only incomplete',
        'ReportFilter:CompleteWebBookings':'Only complete',
        'ReportFilter:BookingUnpaid': 'Unpaid',
        'ReportFilter:BookingPartPaid': 'Part Paid',
        'ReportFilter:BookingUnpaidAndPartPaid':'Unpaid or part paid',
        'ReportFilter:BookingFullyPaid': 'Fully Paid',
        'ReportFilter:Promotion': 'Promotion',
        'ReportFilter:RegistrationDate': 'Registration date',
        'ReportFilter:WebRegistration': 'Web Registration',
        'ReportFilter:BillNumber': 'Bill Number',
        'ReportFilter:IncludeFees': 'Include fees',
        'ReportFilter:Unpaid':'Unpaid',
        'ReportFilter:OnlyPartPaid': 'Only part paid',
        'ReportFilter:PartOrFullyPaid': 'Part or fully paid',
        'ReportFilter:OnlyFullyPaid': 'Only fully paid',

        'BillType:GENERAL': 'General',
        'BillType:EVENT': 'Event',

        'TermsAndConditionsList:title': 'Terms and conditions',
        'TermsAndConditionsList:waiver': 'Waiver',
        'TermsAndConditionsList:type': 'Type',
        'TermsAndConditionsList:RegistrationHeader': 'Registration waiver',
        'TermsAndConditionsList:BookingHeader': 'Booking terms',
        'TermsAndConditionsList:addRegistrationTerms': 'Add registration waiver',
        'TermsAndConditionsList:addBooingTerms': 'Add booking terms',
        'TermsAndConditionsList:WebShopPurchaseHeader': 'Web shop purchase terms',
        'TermsAndConditionsList:addWehShopPurchaseTerms': 'Add web shop purchase terms',
        'TermsAndConditionsList:webShopPurchaseTerms': 'Web shop purchase terms',

        'SendRegistrationLinkOptions:EmailOptions': 'Send registration link',
        'SendRegistrationLinkOptions:emailAddress': 'Email address',
        'SendRegistrationLinkOptions:emailTemplate': 'Template',
        'SendRegistrationLinkOptions:sendEmail': 'Send email',
        'SendRegistrationLinkOptions:sendFaliled': 'send failed',

        'Tools:numberOfImagesToMigrate': 'Number of rows to migrate',

        'VouchersPage:title': 'Vouchers',
        'VouchersPage:addVoucherProduct': 'Add voucher product',
        'VouchersPage:nameHeading': 'Name',
        'VouchersPage:taxRateHeading': 'Tax rate',

        'VoucheDetailsPage:purchaseDate': 'Purchase date',
        'VoucheDetailsPage:code': 'Code',
        'VoucheDetailsPage:bill': 'Bill',
        'VoucheDetailsPage:amount': 'Amount',
        'VoucheDetailsPage:balance': 'Balance',
        'VoucheDetailsPage:expiry': 'Expiry',
        'VoucheDetailsPage:activeFrom': 'Valid from',
        'VoucheDetailsPage:purchasedBy': 'Purchased by',
        'VoucheDetailsPage:search': 'Search term (bill number, voucher code or customer email address)',
        'VoucheDetailsPage:import': 'Import',
        'VoucheDetailsPage:lastSentToAddress': 'Last sent to',
        'VoucheDetailsPage:paid': 'Paid',
        'VoucheDetailsPage:createdBy': 'Created by',
        'VoucheDetailsPage:emailLastSent': 'Emailed',

        'VoucherProductForm:addVoucherProduct': 'Add voucher',
        'VoucherProductForm:unitPrice': 'Purchase Price',
        'VoucherProductForm:redemptionAmount': 'Redemption value',
        'VoucherProductForm:voucherValidFor': 'Vouchers valid for (from the date of purchase, or earliest redemption date whichever is latest)',
        'VoucherProductForm:taxRate': 'Purchase tax rate',
        'VoucherProductForm:accountingCategory': 'Accounting category',
        'VoucherProductForm:accountingDepartment': 'Accounting Department',
        'VoucherProductForm:nominalCode': 'Nominal code',
        'VoucherProductForm:productImage': 'Product image (max 640px x 640px)',
        'VoucherProductForm:voucherImage': 'Voucher image (to send to customer - max 640px x 640px)',
        'VoucherProductForm:selectTaxRate': '<select tax rate>',
        'VoucherProductForm:showOnWebShop': 'Show on web shop',
        'VoucherProductForm:allowCustomPrice': 'Allow customer to chose voucher value',
        'VoucherProductForm:minPrice': 'Minimum voucher value',
        'VoucherProductForm:maxPrice': 'Maximum voucher value',
        'VoucherProductForm:priceIncrement': 'Price increment',
        'VoucherProductForm:webshopOptions': 'Web shop options',
        'VoucherProductForm:maxQuantity': 'Maximum purchase quantity',
        'VoucherProductForm:VenueSettingsHeader': 'Venue sales settings',
        'VoucherProductForm:activeFrom': 'Earliest voucher redemption date',
        'VoucherProductForm:productInformation': 'Product information (shown on web shop)',
        'VoucherProductForm:canRedeemOnline': 'Allow voucher to be redeemed online',
        'VoucherProductForm:VenueHeader': 'Venue',
        'VoucherProductForm:SellOnPointOfSaleHeader': 'Sell on POS',
        'VoucherProductForm:SellOnWebShopHeader': 'Sell on web shop',
        'VoucherProductForm:voucherEmailTemplate': 'Voucher email template',
        'VoucherProductForm:voucherRestrictionOptions': 'Voucher Restriction Options',
        'VoucherProductForm:mustBeRedeemedBySingleCustomer': 'Voucher can only be redeemed by a single customer',
        'VoucherProductForm:productRestrictionType': 'Voucher product restrictions',
        'VoucherProductForm:restrictedProductCategories': 'Select categories this voucher is restricted to',
        'VoucherProductForm:venueCategories': '{venueName} categories',
        'VoucherProductForm:restrictedProductTags': 'Select tags this voucher is restriced to',
        'VoucherProductForm:restrictedProducts': 'Select products this voucher is restriced to',
        'VoucherProductForm:venueProducts': '{venueName} products',
        'VoucherProductForm:promotions': 'Discount promotions',
        'VoucherProductForm:selectPromotion': 'Select promotion',
        'VoucherProductForm:addPromotion': 'Add',
        'VoucherProductForm:voucherMustHaveAPrice': 'Voucher must have a price',

        'VoucherDetails:purchaseDate': 'Purchase date',
        'VoucherDetails:expiryDate': 'Expiry date',
        'VoucherDetails:activeFromDate': 'Earliest voucher redemption date',
        'VoucherDetails:redemptionAmount': 'Redemption amount',
        'VoucherDetails:remainingBalance': 'Remaining balance',
        'VoucherDetails:purchasedBy': 'Purchased by',
        'VoucherDetails:billNumber': 'Bill number',
        'VoucherDetails:revoke': 'Revoke',
        'VoucherDetails:confirmRevocation': 'Confirm revocation',
        'VoucherDetails:revocationReason': 'Revocation reason',
        'VoucherDetails:revoked': 'Revoked',
        'VoucherDetails:revokedBy': 'RevokedBy',
        'VoucherDetails:redemptions': 'Redemptions',
        'VoucherDetails:dateHeader': 'Date',
        'VoucherDetails:amountHeading': 'Amount',
        'VoucherDetails:billHeading': 'Bill',
        'VoucherDetails:venueHeading': 'Venue',

        'ImportVouchers:heading': 'Voucher import',
        'ImportVouchers:voucherFile': 'File to import',
        'ImportVouchers:validate': 'Validate vouchers',

        'ImportVouchers:codeHeading': 'Code',
        'ImportVouchers:amountHeading': 'Voucher amount',
        'ImportVouchers:balanceHeading': 'Remaining balance',
        'ImportVouchers:expiryHeading': 'Expiry',
        'ImportVouchers:processingValidation': 'Validating file',
        'ImportVouchers:duplicateCode':'This voucher code has already been used',
        'ImportVouchers:invalidAmount': 'Not a valid amount',
        'ImportVouchers:invalidDate': 'Not a valid date',
        'ImportVouchers:import': 'Import',
        'ImportVouchers:importIgnoringErrors': 'Import (ignoring invalid vouchers)',
        'ImportVouchers:instructions': 'The import file must be in csv format with four columns: {sb}code, amount, remaining balance and expiry date (in yyyy-mm-dd format){eb}.',
        'ImportVouchers:instructions2': 'We recommend importing a maximum of 500 vouchers at a time.',

        'SendVoucherForm:heading': 'Email voucher',
        'SendVoucherForm:enterEmailAddress': 'Please enter the email address to send the voucher to',

        'SendVouchersForm:heading': 'Email vouchers',

        'Incomplete_web_booking_incomplete_payment': 'Incomplete web booking (incomplete payment)',
        'Incomplete_web_booking_failed_payment': 'Incomplete web booking (payment failed)',
        'Incomplete_web_booking': 'Incomplete web booking',
        'customer_restarted_booking': 'Customer restarted booking',

        'LeaderboardList:addLeaderboard': 'Add leaderboard',
        'LeaderboardList:nameHeading': 'Name',
        'LeaderboardList:publicHeading':'Public',
        'LeaderboardList:sequenceHeading': 'Sequence',
        'LeaderboardList:trackNameHeading': 'Track name',
        'LeaderboardList:kartTypeHeading': 'Kart type',
        'LeaderboardList:sessionTypeHeading': 'Session type',
        'LeaderboardList:fromHeading': 'From',
        'LeaderboardList:toHeading': 'To',

        'LeaderboardForm:addLeaderboard':'Add leaderboard',
        'LeaderboardForm:name': 'Name',
        'LeaderboardForm:trackName': 'Track name',
        'LeaderboardForm:kartTypeFilter': 'Kart Type',
        'LeaderboardForm:sessionTypeFilter': 'Session type',
        'LeaderboardForm:maxRows': 'Max rows to show',
        'LeaderboardForm:timesFrom': 'Show times from',
        'LeaderboardForm:timesTo': 'Show times to',
        'LeaderboardForm:selectIntervalOption': '<Select option>',
        'LeaderboardForm:specificDate': 'Specific date',
        'LeaderboardForm:interval': 'Time interval',
        'LeaderboardForm:noRestriction': 'No restriction',
        'LeaderboardForm:isPublic': 'Public',
        'LeaderboardForm:customerTag': 'Customer tag',

        'Leaderbaord:confirmInvalidateCompetitorLaptimeHeader':'Confirm remove laptime',
        'Leaderbaord:confirmInvalidateCompetitorLaptimeMessage': 'Are you sure you want to remove time {time} for {name}',
        'Leaderbaord:confirmInvalidateCompetitorLaptimeAction': 'Yes, remove',

        'TagsPage:title': 'Tags',
        'TagsPage:customersHeading': 'Customers',
        'TagsPage:productsHeading': 'Products',

        'AuditEntryType:BookingCreated': 'Booking created',
        'AuditEntryType:BillCreated': 'Bill created',
        'AuditEntryType:BillItemCreated': 'Bill item created',
        'AuditEntryType:ReservationCreated': 'Reservation created',
        'AuditEntryType:ReservationStartTimeChanged': 'Reservation start time changed',
        'AuditEntryType:ReservationDurationChanged': 'Reservation duration changed',
        'AuditEntryType:ReservationActivityChanged': 'Reservation activity changed',
        'AuditEntryType:ReservationMaxParticipantsChanged': 'Max participants changed',
        'AuditEntryType:ReservationTypeChanged': 'Reservation type changed',
        'AuditEntryType:BookingCancelled': 'Booking cancelled',
        'AuditEntryType:BookingReinstated': 'Booking reinstated',
        'AuditEntryType:BookingPublicWebsiteChanged': 'Website changed',
        'AuditEntryType:ReservationMembershipTypeChanged': 'Restricted membership type changed',
        'AuditEntryType:BillItemProductChanged': 'Product changed',
        'AuditEntryType:BillItemPriceChanged': 'Price changed: {entityText}',
        'AuditEntryType:BillItemQuantityChanged': 'Quantity changed: {entityText}',
        'AuditEntryType:BillItemProductTagChanged': 'Product tag changed',
        'AuditEntryType:BillItemReservationChanged': 'Reservation changed',
        'AuditEntryType:BillItemArchived': 'Bill item archived: {entityText}',
        'AuditEntryType:BillItemRescheduled': 'Bill item rescheduled: {entityText}',
        'AuditEntryType:BillItemTaxRateChanged': 'Tax rate changed: {entityText}',
        'AuditEntryType:BillItemReinstated': 'Billitem reinstated: {entityText}',
        'AuditEntryType:VoucherChanged': 'Voucher changed',
        'AuditEntryType:VoucherRedemptionAmountChanged': 'Voucher redemption amount changed',
        'AuditEntryType:VoucherExpiryChanged': 'Voucher expiry changed',
        'AuditEntryType:VoucherImported': 'Voucher imported',
        'AuditEntryType:CustomerProfileNameChanged': 'Name changed',
        'AuditEntryType:CustomerAddressChanged': 'Address changed',
        'AuditEntryType:CustomerContactPreferencesChanged': 'Contact preferences changed',
        'AuditEntryType:CustomerContactDetailsChanged': 'Contact details changed',
        'AuditEntryType:CustomerEmergencyContactChanged': 'Emergency contact changed',
        'AuditEntryType:CustomerBirthdayChanged': 'Date of birth changed',
        'AuditEntryType:CustomerPublicResultsPreferencesChanged': 'Public results preference changed',
        'AuditEntryType:CustomerGenderChanged': 'Gender changed',
        'AuditEntryType:BillDiscountApplied': 'Discount applied',
        'AuditEntryType:BillDiscountRemoved': 'Discount removed',

        'AuditEntryType:MembershipCreated': 'Membership created',
        'AuditEntryType:MembershipAssignToCustomer': 'Membership customer assigned',
        'AuditEntryType:MembershipTransferred': 'Membership transferred',
        'AuditEntryType:MembershipExpiryChanged': 'Membership expiry changed',

        'AuditEntryType:EventNameChanged': 'Event name changed',

        'MembershipsPage:title': 'Membership Types',
        'MembershipsPage:addMembershipType': 'Add membership type',

        'MembershipTypeForm:addMembershipType': 'Add membership type',
        'MembershipTypeForm:membershipEmailTemplate': 'Purchase confirmation email template',
        'MembershipTypeForm:webshopOptions': 'Web shop options',
        'MembershipTypeForm:showOnWebShop': 'Sell on web shop',
        'MembershipTypeForm:membershipNumberOptions': 'Membership numbering options',
        'MembershipTypeForm:numberCustomPrefix': 'Number prefix (leave blank for no prefix)',
        'MembershipTypeForm:numberOfDigitsToPad': 'Number of digits to pad number to',
        'MembershipTypeForm:expiryOptions': 'Expiry options',
        'MembershipTypeForm:expiryType': 'Expiry type',
        'MembershipTypeForm:expiryDate': 'Fixed expiry date',
        'MembershipTypeForm:expiryInterval': 'Membership valid for (from date of purchase)',
        'MembershipTypeForm:useAtVenues': 'Select the venues this membership applies to',
        'MembershipTypeForm:tagName': 'Tag name',
        'MembershipTypeForm:tagSettings': 'Tag settings',
        'MembershipTypeForm:accountingSettings': 'Accounting settings',
        'MembershipTypeForm:noVenuesSelected': 'Please select at least one venue',
        'MembershipTypeForm:selectTag': 'Please select a tag',
        'MembershipTypeForm:membershipImage': 'Membership image (max 640px x 640px)',
        'MembershipTypeForm:missingProductImage': 'Please select a product image',
        'MembershipTypeForm:missingMembershipImage': 'Please select a membership image',

        'MembershipTypeDetails:search':'Search term (membership number or customer name, email address or postcode)',
        'MembershipTypeDetails:number':'Membership number',
        'MembershipTypeDetails:purchaseDate': 'Purchase date',
        'MembershipTypeDetails:expiry': 'Expiry',
        'MembershipTypeDetails:customerName': 'Customer',
        'MembershipTypeDetails:customerEmail': 'Customer email',
        'MembershipTypeDetails:createdBy': 'Created by',
        'MembershipTypeDetails:membershipTypeNotFound': 'Membership type not found',

        'MembershipDetails:title': 'Membership {membershipNumber} details',
        'MembershipDetails:member': 'Member',
        'MembershipDetails:membershipNotFound': 'Membership not found',
        'MembershipDetails:transfermembership': 'Transfer membership',
        'MembershipDetails:resendEmail': 'Resend email',
        'MembershipDetails:transfer': 'Transfer',
        'MembershipDetails:purchaseDate': 'Purchase date',
        'MembershipDetails:expiry': 'Expiry date',

        'WorldpayGatewayMode:test': 'Test',
        'WorldpayGatewayMode:live': 'Live',
        'WorldpayGatewaySettings:md5SecretHint': 'In admin portal, ensure that SignatureFields is set to amount:cartId:currency:instId',
    },
    "en-US": {
        'Global:currencySymbol': '$',
        'Global:postalCode': 'Zip code',
        'Global:confirmPostcode': 'Confirm Zip code',
        'Global:county': 'State',
        'DiarySearch:noResults': 'No results found.  Search works on customer name, company name, bill number, customer Zip code or phone number',
        'ClientForm:billingPostalCode': 'Billing Zip code',
        'ClientForm:billingCounty': 'Billing state',
        'Customers:County': 'State',
        'CustomersPage:postcodeHeading': 'Zip code',
        'CustomerSearch:postcodeHeading': 'Zip code',
        'RegistrationSettingsForm:setting_postcode': 'Zip code',
        'Registration:confirmPostcode': 'Please type in your Zip code to confirm we have the right person.',
        'Registration:unmatchedPostcode': 'We can\'t find an account with this Zip code.  Click next and we\'ll create a new one for you.',
        'Registration:confirmPasswordNotes': 'Note: If you have moved since your last visit, please enter your old Zip code, then inform a member of our staff who will update your details.',
        'Customers:Postal Code': 'Zip code',
        'customerDetails:nameSearchLabel': 'Customer name, email address, company name or zip code',
        'MarketingCampaignFilterType:PostalCode': 'Zip code',

    }
}