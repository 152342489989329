
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../../global/controls';
import * as v from '../../../global/validation';

import * as api from '../../../../store/apiClient';

import { ClientEmailTemplate } from '../../../../store/pages/emailTemplates/types';
import { clickHandler, isNullOrEmpty } from '../../../../utils/util';
import ApiError from '../../../global/apiError';


interface SendTestEmailProps {
    venueId: string;
    emailTemplate: ClientEmailTemplate;
    close: () => void;
}

interface SendTestEmailState {
    sendTo: ct.FormValue<string>;
    tagMap: Map<string, ct.FormValue<string>>;
    error: string | null;
    isSending: boolean;
    sent: boolean;
    sendError: api.ApiError | null;
}

class SendTestEmail extends React.Component<SendTestEmailProps, SendTestEmailState> {

    constructor(props: SendTestEmailProps) {
        super(props);

        var tagMap = new Map<string, ct.FormValue<string>>();
        for (let i = 0; i < props.emailTemplate.tags.length; i++) {
            const tag = props.emailTemplate.tags[i];
            tagMap.set(tag.key, ct.asFormValue(tag.key, '', false, true, 'validation:required'))
        }

        this.state = { sendTo: this.validateSendTo(''), tagMap: tagMap, error: null, isSending: false, sent: false, sendError: null }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    validateSendTo = (val: string) => v.validate(val, 'sendTo', [v.required], []);

    updateTagValue = (key: string, val: string) => {
        this.setState(s => {
            const map = s.tagMap;
            map.set(key, ct.asFormValue(key, val, !isNullOrEmpty(val), true, isNullOrEmpty(val) ? 'validation:required' : undefined))
            return { tagMap: map }
        })
    }

    sendEmail = () => {
        const { t } = this.context;
        if (!v.isValid(this.state)) {
            this.setState({ error: t('Global:formNotValid') })
        } else {
            const { venueId, emailTemplate, close} = this.props;
            const { sendTo, tagMap} = this.state;

            this.setState({ isSending: true, sent: false, sendError: null });

            const tags = Array.from(tagMap.entries()).map(x => ({ key: x[0], value: x[1].value }))

            api.post(`api/v1/email/${venueId}/sendTest/${emailTemplate.clientEmailTemplateId}`, { emailType: emailTemplate.emailType, sendTo: sendTo.value, tags: tags })
                .take(1)
                .subscribe(
                    _ => this.setState({ isSending: false, sent: true }, () => setTimeout(close, 750)),
                    e => this.setState({ isSending: false, sendError: e }));
        }
    }

    render() {
        const { t } = this.context;
        const { close, emailTemplate } = this.props;
        const { sendTo, tagMap, error, isSending, sent, sendError } = this.state;

        return <div className='send_test_email_page'>
            <h1>{t('SendTestEmail:title')} - {emailTemplate.name}</h1>

            <form className='data-form' onSubmit={this.sendEmail}>

                <ct.TextBox id='sendTo' labelKey='SendTestEmail:sendTo' value={sendTo} callback={v => this.setState({ sendTo: this.validateSendTo(v)}) } />

                <h4>{t('SendTestEmail:tagValues')}</h4>

                {Array.from(tagMap.entries()).map(t => <ct.TextBox id={t[0]} labelKey={t[0]} value={t[1]} callback={v => this.updateTagValue(t[0], v) } />)}

                {error ? <div className='alert alert-danger'>{error}</div> : null}

                <div className='btn-toolbar'>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.sendEmail)} disabled={isSending}>{this.context.t('Global:send')}</button>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, close)} disabled={isSending}>{this.context.t('Global:cancel')}</button>
                </div>

                {this.renderInfo(isSending, sent, sendError)}
            </form>
        </div>
    }

    renderInfo = (isSending: boolean, sent: boolean, sendError: api.ApiError | null) => {
        const { t } = this.context;
        if (isSending) {
            return <div className='alert alert-info'>{t('SendTestEmail:sendingEmail')}</div>
        } else if (sendError) {
            return <ApiError error={sendError} />
        } else if (sent) {
            return <div className='alert alert-info'>{t('SendTestEmail:emailSent')}</div>
        }

        return null;
    }
}

export default SendTestEmail