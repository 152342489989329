
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import { Action } from 'redux';
import * as lt from './pages/login/types';
import * as la from './pages/login/actions';
import { ApiError } from './apiClient';

export const create = <TAction extends Action>(action$: ActionsObservable<any>, actionType: any, handler: (action: any) => Observable<TAction>, error: (resp: ApiError, action: any) => Action, filter?: (value: any, index: number) => boolean) => {
    let obs : Observable<any> = action$
        .ofType(actionType);

    if (filter) {
        obs = obs.filter(filter);
    }

    return obs.mergeMap((action) => handler(action)
        .catch((err: ApiError) => {
            if (err.status === 401) {
                return action$.ofType(lt.LoginActionTypes.RefreshTokenSuccess)
                    .takeUntil(action$.ofType(lt.LoginActionTypes.RefreshTokenError))
                    .take(1)
                    .mergeMap(() => handler(action))
                    .merge(Observable.of(la.actionCreators.refreshToken()));
            }

            return ActionsObservable.of(error(err, action));
        }));
}

export const createDebounced = <TAction extends Action>(action$: ActionsObservable<any>, actionType: any, debounceTime: number, handler: (action: any) => Observable<TAction>, error: (resp: ApiError, action: any) => Action, filter?: (value: any, index: number) => boolean) => {
    return action$
        .ofType(actionType)
        .filter(filter ? filter : () => true)
        .debounceTime(debounceTime)
        .switchMap((action) => handler(action)
        .catch((err: ApiError) => {
            if (err.status === 401) {
                return action$.ofType(lt.LoginActionTypes.RefreshTokenSuccess)
                    .takeUntil(action$.ofType(lt.LoginActionTypes.RefreshTokenError))
                    .take(1)
                    .mergeMap(() => handler(action))
                    .merge(Observable.of(la.actionCreators.refreshToken()));
            }

            return ActionsObservable.of(error(err, action));
        }));
}