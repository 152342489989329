

import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'

import { ApplicationState } from '../../../store';
import * as VenueState from '../../../store/pages/venues/types';
import * as VenueActions from '../../../store/pages/venues/actions';
import * as ModalActions from '../../../store/global/modal/actions';
import * as ResourceActions from '../../../store/pages/resources/actions';
import * as ProductActions from '../../../store/pages/products/actions';
import * as FeeActions from '../../../store/pages/fees/actions';
import * as ActivityFormatActions from '../../../store/pages/activityFormats/actions';
import * as PaymentReminderConfigActions from '../../../store/pages/paymentReminderConfigurations/actions';
import * as PublicWebsiteSettingsActions from '../../../store/pages/publicWebsiteSettings/actions';

import VenueForm from './venueForm';
import ResourceList from './resources/resourceList';
import ProductList from './products/productList';
import FeeList from './fees/feeList';
import CustomerCategoryList from './customerCategoryList';
import ActivityFormatGroupList from './activityFormatGroups/activityFormatGroupList';
import ActivityFormatList from './activityFormat/activityFormatList';
import EmailSchedulesPanel from './emailSchedules/emailSchedulesPanel';
import EmailTemplatesList from './emailTemplates/emailTemplatesList';
import RegistrationPanel from './registrationPanel';
import IntegrationsPanel from './integrationsPanel';
import ProductCategoryList from './productCategories/productCategoryList';
import PromotionsList from './promotions/promotionsList';

import { Product } from '../../../store/pages/products/types';
import { ProductCategory } from '../../../store/pages/productCategories/types';
import { TaxRate } from '../../../store/pages/taxRates/types';
import { ActivityFormat } from '../../../store/pages/activityFormats/types';
import { PaymentReminderConfig } from '../../../store/pages/paymentReminderConfigurations/types';
import { Resource } from '../../../store/pages/resources/types';
import { clickHandler, isNullOrEmpty } from '../../../utils/util';
import { ClientEmailTemplate, EmailType } from '../../../store/pages/emailTemplates/types';
import { VenuePublicWebsiteSettings } from '../../../store/pages/publicWebsiteSettings/types';
import PublicWebsitesList from './publicWebsiteSettings/publicWebsitesList';
import { CustomerCategory } from '../../../store/pages/customerCategories/types';
import { RegistrationTerms, BookingTerms, WebShopPurchaseTerms } from '../../../store/pages/termsAndConditions/types';
import TermsAndConditionsList from './termsAndConditions/termsAndConditionsList';
import PaymentMethodsList from './paymentMethods/paymentMethodsList';
import PaymentScheduleList from './paymentSchedules/paymentScheduleList';
import { Fee } from '../../../store/pages/fees/types';
import LeaderboardList from './leaderboards/leaderboardList';
import { MembershipType } from '../../../store/pages/memberships/types';

interface VenueRouteProps {
    venueId: string;
}

interface Tab {
    key: string;
    labelKey: string;
    component: (venue: VenueState.Venue) => JSX.Element;
}

interface LocalState {
    venues: VenueState.Venue[];
    isLoadingVenues: boolean;
    resources: Resource[];
    isLoadingResources: boolean;
    products: Product[];
    isLoadingProducts: boolean;
    editProduct: () => void;
    productCategories: ProductCategory[];
    isLoadingProductCategories: boolean;
    taxRates: TaxRate[];
    isLoadingTaxRates: boolean;
    activityFormats: ActivityFormat[];
    isLoadingActivityFormats: boolean;
    paymentReminderConfigs: PaymentReminderConfig[];
    isLoadingPaymentReminderConfigs: boolean;
    paymentReminderEmailTemplates: ClientEmailTemplate[],
    isLoadingPaymentReminderEmailTemplates: boolean
    publicWebsitePages: VenuePublicWebsiteSettings[],
    isLoadingPublicWebsitePages: boolean
    customerCategories: CustomerCategory[],
    isLoadingCustomerCategories: boolean;
    registrationTerms: RegistrationTerms[];
    isLoadingRegistrationTerms: boolean;
    bookingTerms: BookingTerms[];
    isLoadingBookingTerms: boolean;
    webShopPurchaseTerms: WebShopPurchaseTerms[];
    isLoadingWebShopPurchaseTerms: boolean;
    fees: Fee[];
    isLoadingFees: boolean;
    selectedTabKey: string;
    currentRoute: string;
    previousRoute: string;
    membershipTypes: MembershipType[];
}

interface MappedActions {
    editVenue: () => void;
    showModal: (component: JSX.Element, screenName: string) => void;
    closeModal: () => void;
    editResource: () => void;
    editActivityFormat: () => void;
    editPaymentReminderConfig: () => void;
    editPublicWebsiteSettings: () => void;
    editCustomerCategory: () => void;
    editFee: () => void;
    selectTab: (tabKey: string) => void;
    enableIntegration: (venueId: string, integrationId: string) => void;
    revokeIntegrationToken: (venueId: string, integrationTokenId: string) => void;
}

// At runtime, Redux will merge together...
type VenueDetailPageProps = LocalState & MappedActions & RouteComponentProps<VenueRouteProps>;

interface VenueDetailPageState {
    venue?: VenueState.Venue;
    tabs: Tab[];
    selectedTab: string;
    pageRoot: string;
}

class VenueDetailPage extends React.Component<VenueDetailPageProps, VenueDetailPageState> {

    constructor(props: VenueDetailPageProps) {
        super(props);

        const path = props.location.pathname;
        const pageUrl = window.location.href;
        const pageRoot = pageUrl.replace(path, '');

        const inistalState = this.buildStateFromProps(this.props);
        const tabs = this.buildTabs();
        const selectedTab = !isNullOrEmpty(props.selectedTabKey) && props.previousRoute.startsWith(props.currentRoute) ? props.selectedTabKey : tabs[0].key;
        this.state = { ...inistalState, tabs: tabs, selectedTab: selectedTab, pageRoot: pageRoot };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    buildTabs = (): Tab[] => [
        { key: 'resources', labelKey: 'VenuesPage:resourcesTabHeading', component: this.renderResourcesTab },
        { key: 'customerCategories', labelKey: 'VenuesPage:customerCategoriesTabHeading', component: this.renderCustomerCategories },
        { key: 'productCategories', labelKey: 'VenuesPage:productCategoriesTabHeading', component: this.renderProductCategories },
        { key: 'products', labelKey: 'VenuesPage:productsTabHeading', component: this.renderProducts },
        { key: 'fees', labelKey: 'VenuesPage:feesTabHeading', component: this.renderFees },
        { key: 'activityFormatGroups', labelKey: 'VenuesPage:activityFormatGroupsTabHeading', component: this.renderActivityFormatGroups },
        { key: 'activityFormats', labelKey: 'VenuesPage:activityFormatsTabHeading', component: this.renderActivityFormats },
        { key: 'paymentMethods', labelKey: 'VenuesPage:paymentMethodsTabHeading', component: this.renderPaymentMethods },
        { key: 'paymentSchedules', labelKey: 'VenuesPage:paymentSchedulesTabHeading', component: this.renderPaymentSchedules },
        { key: 'emailTemplates', labelKey: 'VenuesPage:emailTemplatesHeading', component: this.renderEmailTemplates },
        { key: 'emailSchedules', labelKey: 'VenuesPage:emailSchedulesTabHeading', component: this.renderEmailSchedules },
        { key: 'terms', labelKey: 'VenuesPage:termsTabHeading', component: this.renderTerms },
        { key: 'promotions', labelKey: 'VenuesPage:promotionsTabHeading', component: this.renderPromotions },
        { key: 'websiteSettings', labelKey: 'VenuesPage:websiteSettingsTabHeading', component: this.renderWebsiteSettings },
        { key: 'leaderboards', labelKey: 'VenuesPage:leaderboardsTabHeading', component: this.renderLeaderboards },
        { key: 'kiosks', labelKey: 'VenuesPage:kiosksTabHeading', component: this.renderKiosks },
        { key: 'integrations', labelKey: 'VenuesPage:integrationTokensTabHeading', component: this.renderIntegrationTokens },
    ]
        
    private buildStateFromProps(props: VenueDetailPageProps) {

        const venueId = props.match.params.venueId;
        const venue = props.venues.find(v => v.id === venueId);

        return { venue: venue };
    }

    editVenue = () => {
        this.props.editVenue();
        this.props.showModal(<VenueForm isNew={false} venue={this.state.venue ? this.state.venue : null} />, 'VenueForm');
    }

    componentDidUpdate(prevProps: VenueDetailPageProps) {
        const { venues } = this.props;

        const venueId = this.props.match.params.venueId

        if (this.venueUpdateRequired(prevProps.venues, venues, venueId)) {
            this.setState(this.buildStateFromProps(this.props));
        }
    }

    venueUpdateRequired = (prevVenues: VenueState.Venue[], newVenues: VenueState.Venue[], selectedVenueId: string) => {
        if ((!prevVenues && newVenues) || prevVenues.length !== newVenues.length)
            return true;

        const prevVenue = prevVenues.find(v => v.id === selectedVenueId);
        const newVenue = newVenues.find(v => v.id === selectedVenueId);

        if ((!prevVenue && newVenue) || (prevVenue && !newVenue)) return true;

        if (prevVenue && newVenue) {
            if (prevVenue.lastUpdated !== newVenue.lastUpdated) {
                return true;
            }

            if (prevVenue.integrationTokens.length !== newVenue.integrationTokens.length) {
                return true;
            }

            if (prevVenue.integrationTokens.filter((t, ix) => {
                const newToken = newVenue.integrationTokens[ix];
                return newToken.id !== t.id || newToken.integrationId !== t.integrationId || newToken.integrationType !== t.integrationType || newToken.token !== t.token;
            }).length > 0) {
                return true;
            }

            if (prevVenue.registrationKiosks.length !== newVenue.registrationKiosks.length) {
                return true;
            }

            // Check if any of the kisks have changed
            if (prevVenue.registrationKiosks.filter(pk => {
                    const k = newVenue.registrationKiosks.find(x => x.registrationKioskId === pk.registrationKioskId);
                    const pkLastUpdate = pk.lastUpdated ? pk.lastUpdated.getTime() : 0;
                    const lastUpdated = k && k.lastUpdated ? k.lastUpdated.getTime() : 0;
                    return k && lastUpdated > pkLastUpdate;
                }).length > 0) {
                return true;
            }
        }

        return false;
    }

    renderResourcesTab = (venue: VenueState.Venue) => {
        const {  resources, isLoadingResources, editResource, showModal} = this.props;
        const venueId = venue.id
        return <ResourceList venueId={venueId} resources={resources.filter(r => r.venueId === venueId)} isLoadingResources={isLoadingResources} editResource={editResource} showModal={showModal} />
    }

    renderCustomerCategories = (venue: VenueState.Venue) => {
        const {  customerCategories, isLoadingCustomerCategories, editCustomerCategory, showModal } = this.props;
        const venueId = venue.id
        return <CustomerCategoryList venueId={venueId} customerCategories={customerCategories} isLoadingCustomerCategories={isLoadingCustomerCategories} editCustomerCategory={editCustomerCategory} showModal={showModal} />
    }

    renderProductCategories = (venue: VenueState.Venue) => {
        return <ProductCategoryList venueId={venue.id} />
    }

    renderProducts = (venue: VenueState.Venue) => {
        const { products, productCategories, taxRates, activityFormats, editProduct, isLoadingProducts, isLoadingProductCategories, isLoadingTaxRates, showModal } = this.props;
        const venueId = venue.id
        return <ProductList venueId={venueId} products={products} categories={productCategories.filter(c => c.venueId === venueId)} taxRates={taxRates} activityFormats={activityFormats.filter(af => af.venueId === venueId)} editProduct={editProduct} showModal={showModal} isLoadingProducts={isLoadingProducts} isLoadingProductCategories={isLoadingProductCategories} isLoadingTaxRates={isLoadingTaxRates} />
    }

    renderFees = (venue: VenueState.Venue) => {
        const { fees, taxRates, editFee, isLoadingFees, isLoadingTaxRates, showModal } = this.props;
        const venueId = venue.id
        return <FeeList venueId={venueId} fees={fees} taxRates={taxRates} editFee={editFee} showModal={showModal} isLoadingFees={isLoadingFees} isLoadingTaxRates={isLoadingTaxRates} />
    }

    renderActivityFormatGroups = (venue: VenueState.Venue) => {
        const venueId = venue.id
        return <ActivityFormatGroupList venueId={venueId} />
    }

    renderActivityFormats = (venue: VenueState.Venue) => {
        const { resources, isLoadingResources, activityFormats, isLoadingActivityFormats, products, isLoadingProducts, editActivityFormat, membershipTypes } = this.props;
        const venueId = venue.id
        return <ActivityFormatList
            venueId={venueId}
            activityFormats={activityFormats.filter(af => af.venueId === venueId)}
            isLoadingActivityFormats={isLoadingActivityFormats}
            products={products}
            isLoadingProducts={isLoadingProducts}
            resources={resources.filter(r => r.venueId === venueId && !r.archived)}
            isLoadingResources={isLoadingResources}
            membershipTypes={membershipTypes}
            editActivityFormat={editActivityFormat} />
    }

    renderPaymentMethods = (venue: VenueState.Venue) => {
        const venueId = venue.id
        const { pageRoot } = this.state;
        return <PaymentMethodsList venueId={venueId} pageRoot={ pageRoot} />
    }

    renderPaymentSchedules = (venue: VenueState.Venue) => {
        const venueId = venue.id
        return <PaymentScheduleList venueId={venueId} />
    }

    renderEmailTemplates = (venue: VenueState.Venue) => {
        const venueId = venue.id
        return <EmailTemplatesList venueId={venueId} />
    }

    renderEmailSchedules = (venue: VenueState.Venue) => {
        const {  paymentReminderConfigs, isLoadingPaymentReminderConfigs, paymentReminderEmailTemplates, isLoadingPaymentReminderEmailTemplates, editPaymentReminderConfig, showModal } = this.props;
        const venueId = venue.id
        const venueEmailTemplates = paymentReminderEmailTemplates.filter(t => t.venueId === venueId);
        return <EmailSchedulesPanel venue={venue} paymentReminderConfigs={paymentReminderConfigs} isLoadingPaymentReminderConfigs={isLoadingPaymentReminderConfigs} paymentReminderEmailTemplates={venueEmailTemplates} isLoadingPaymentReminderEmailTemplates={isLoadingPaymentReminderEmailTemplates} editPaymentReminderConfig={editPaymentReminderConfig} showModal={showModal} />
    }

    renderTerms = (venue: VenueState.Venue) => {
        const { registrationTerms, isLoadingRegistrationTerms, bookingTerms, isLoadingBookingTerms, webShopPurchaseTerms, isLoadingWebShopPurchaseTerms, showModal } = this.props;
        return <TermsAndConditionsList
            venueId={venue.id}
            registrationTerms={registrationTerms.filter(t => t.venueId === venue.id)}
            isLoadingRegistrationTerms={isLoadingRegistrationTerms}
            bookingTerms={bookingTerms.filter(t => t.venueId === venue.id)}
            isLoadingBookingTerms={isLoadingBookingTerms}
            webShopPurchaseTerms={webShopPurchaseTerms}
            isLoadingWebShopPurchaseTerms={isLoadingWebShopPurchaseTerms}
            showModal={showModal} />
    }

    renderPromotions = (venue: VenueState.Venue) => {
        return <PromotionsList venue={venue} />
    }

    renderLeaderboards = (venue: VenueState.Venue) => {
        return <LeaderboardList venueId={venue.id} />
    }

    renderWebsiteSettings = (venue: VenueState.Venue) => {
        const { isLoadingPublicWebsitePages, publicWebsitePages, editPublicWebsiteSettings, resources, showModal, closeModal } = this.props;
        const venueId = venue.id;
        const venuePublicWebsitePages = publicWebsitePages.filter(p => p.venueId === venueId);

        return <PublicWebsitesList
            venueId={venueId}
            isLoadingPublicWebsitePages={isLoadingPublicWebsitePages}
            publicWebsitePages={venuePublicWebsitePages}
            editPublicWebsitePages={editPublicWebsiteSettings}
            resources={resources}
            showModal={showModal}
            closeModal={closeModal} />
    }

    renderKiosks = (venue: VenueState.Venue) => {
        return <RegistrationPanel venue={venue} />
    }

    renderIntegrationTokens = (venue: VenueState.Venue) => <IntegrationsPanel venue={venue} enableIntegration={this.props.enableIntegration} revokeIntegrationToken={this.props.revokeIntegrationToken} />

    selectTab = (tabKey: string) => this.setState({ selectedTab: tabKey }, () => this.props.selectTab(tabKey));

    render() {

        const { t } = this.context;
        const { venue, tabs, selectedTab } = this.state;

        const title = venue ? venue.name  :'';

        const currentTab = tabs.find(tab => tab.key === selectedTab);

        const body = !venue
            ? <div>{t('VenuesPage:venueNotFound')}</div>
            : <div className='tabs'>
                <ul className='tab-headers'>
                    {tabs.map(tab => <li key={tab.key} className={tab.key === selectedTab ? 'tab selected' : 'tab'} onClick={e => clickHandler(e, () => this.selectTab(tab.key))}>{ t(tab.labelKey)}</li>)}
                </ul>
                <div className='tab-content'>
                    {currentTab ? currentTab.component(venue) : null}
                </div>
            </div>

        return <section className='venues-page'>
            <header className='section-header'>
                <div className='page-heading'>
                    <h1 className='venues_title'>{title}</h1>
                </div>
                <div className='section-actions right'>
                    <button className='btn btn-info' onClick={e => clickHandler(e, this.editVenue)}>{this.context.t('Global:edit')}</button>
                </div>
            </header>
            {body}
        </section>;
    }
}

const matStateToProps = (state: ApplicationState) => {
    return {
        isLoadingVenues: state.venues.isLoading,
        venues: state.venues.venues,
        isLoadingResources: state.resources.isLoading,
        resources: state.resources.resources,
        isLoadingProducts: state.products.isLoading,
        products: state.products.products,
        isLoadingProductCategories: state.productCategories.isLoading,
        productCategories: state.productCategories.productCategories.filter(c => !c.archived),
        isLoadingTaxRates: state.taxRates.isLoading,
        taxRates: state.taxRates.taxRates,
        activityFormats: state.activityFormats.activityFormats,
        isLoadingActivityFormats: state.activityFormats.isLoading,
        paymentReminderConfigs: state.paymentReminderConfigs.paymentReminderConfigs,
        isLoadingPaymentReminderConfigs: state.paymentReminderConfigs.isLoading,
        isLoadingpaymentReminderEmailTemplates: state.emailTemplates.isLoading,
        paymentReminderEmailTemplates: state.emailTemplates.emailTemplates.filter(t => t.emailType === EmailType.PaymentReminder && t.clientEmailTemplateId !== null && !t.archived),
        publicWebsitePages: state.publicWebsiteSettings.publicWebsiteSettings,
        isLoadingPublicWebsitePages: state.publicWebsiteSettings.isLoading,
        customerCategories: state.customerCategories.customerCategories,
        isLoadingCustomerCategories: state.customerCategories.isLoading,
        registrationTerms: state.termsAndConditions.registrationTerms,
        isLoadingRegistrationTerms: state.termsAndConditions.isLoadingRegistrationTerms,
        bookingTerms: state.termsAndConditions.bookingTerms,
        isLoadingBookingTerms: state.termsAndConditions.isLoadingBookingTerms,
        webShopPurchaseTerms: state.termsAndConditions.webShopPurchaseTerms,
        isLoadingWebShopPurchaseTerms: state.termsAndConditions.isLoadingWebShopPurchaseTerms,
        fees: state.fees.fees,
        isLoadingFees: state.fees.isLoading,
        selectedTabKey: state.venues.selectedTabKey,
        currentRoute: state.routing.currentRoute,
        previousRoute: state.routing.previousRoute,
        membershipTypes: state.memberships.membershipTypes
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    editVenue: bindActionCreators(VenueActions.actionCreators.editVenue, dispatch),
    showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
    editResource: bindActionCreators(ResourceActions.actionCreators.editResource, dispatch),
    editProduct: bindActionCreators(ProductActions.actionCreators.editProduct, dispatch),
    editActivityFormat: bindActionCreators(ActivityFormatActions.actionCreators.editActivityFormat, dispatch),
    editPaymentReminderConfig: bindActionCreators(PaymentReminderConfigActions.actionCreators.editPaymentReminderConfig, dispatch),
    editPublicWebsiteSettings: bindActionCreators(PublicWebsiteSettingsActions.actionCreators.editPublicWebsiteSettings, dispatch),
    editCustomerCategory: bindActionCreators(PublicWebsiteSettingsActions.actionCreators.editPublicWebsiteSettings, dispatch),
    editFee: bindActionCreators(FeeActions.actionCreators.editFee, dispatch),
    selectTab: bindActionCreators(VenueActions.actionCreators.selectTab, dispatch),
    enableIntegration: bindActionCreators(VenueActions.actionCreators.enableIntegration, dispatch),
    revokeIntegrationToken: bindActionCreators(VenueActions.actionCreators.revokeIntegrationToken, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    matStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps        // Selects which action creators are merged into the component's props
)(VenueDetailPage);

