
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';


import * as PropTypes from 'prop-types'
import * as api from '../../../../store/apiClient';
import ApiError from '../../../global/apiError';
import * as ct from '../../../global/controls';
import * as v from '../../../global/validation';

import { ApplicationState } from '../../../../store';
import { VenuePublicWebsiteSettings, WebsiteAnalyticsProviderType, PublicWebsiteAnalyticsArgs } from '../../../../store/pages/publicWebsiteSettings/types';
import { ValidationError } from '../../../../store/global/types';
import * as PublicWebsiteSettingsActions from '../../../../store/pages/publicWebsiteSettings/actions';
import { clickHandler, formHandler } from '../../../../utils/util';

interface Setting {
    key: string;
    val: ct.FormValue<string>;
}

interface LocalProps {
    venueId: string;
    publicWebsiteSettings: VenuePublicWebsiteSettings;
    providerType: WebsiteAnalyticsProviderType;
    enabledProviders: WebsiteAnalyticsProviderType[];
    close: () => void;
}

interface MappedState {
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
}

interface MappedActions {
    savePublicWebsiteSettings: (publicWebsiteSettings: VenuePublicWebsiteSettings, backgroundImg: File | null, headerImg: File | null) => void;
}

type AnalyticsSettingsFormProps = MappedState & MappedActions & LocalProps;

interface AnalyticsSettingsFormState {
    canTrackConversions: boolean;
    enabled: ct.FormValue<boolean>;
    trackConversions: ct.FormValue<boolean>;
    args: Setting[];
    incompatibleProvider: WebsiteAnalyticsProviderType | null;
}

class AnalyticsSettingsForm extends React.Component<AnalyticsSettingsFormProps, AnalyticsSettingsFormState> {

    constructor(props: AnalyticsSettingsFormProps) {
        super(props);

        this.state = this.buildStateFromProps(this.props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps(props: AnalyticsSettingsFormProps): AnalyticsSettingsFormState {
        const settings = props.publicWebsiteSettings.analyticsSettings.find(s => s.providerType === props.providerType);

        let incompatibleProvider: WebsiteAnalyticsProviderType | null = null;

        if (props.providerType === WebsiteAnalyticsProviderType.GoogleAnalytics && (props.enabledProviders.includes(WebsiteAnalyticsProviderType.GoogleGtag))) {
            incompatibleProvider = WebsiteAnalyticsProviderType.GoogleGtag;
        } else if (props.providerType === WebsiteAnalyticsProviderType.GoogleAnalytics && props.enabledProviders.includes(WebsiteAnalyticsProviderType.GoogleTagManager)) {
            incompatibleProvider = WebsiteAnalyticsProviderType.GoogleTagManager;
        } else if (props.providerType === WebsiteAnalyticsProviderType.GoogleGtag && props.enabledProviders.includes(WebsiteAnalyticsProviderType.GoogleAnalytics)) {
            incompatibleProvider = WebsiteAnalyticsProviderType.GoogleAnalytics;
        } else if (props.providerType === WebsiteAnalyticsProviderType.GoogleGtag && props.enabledProviders.includes(WebsiteAnalyticsProviderType.GoogleTagManager)) {
            incompatibleProvider = WebsiteAnalyticsProviderType.GoogleTagManager;
        } else if (props.providerType === WebsiteAnalyticsProviderType.GoogleTagManager && props.enabledProviders.includes(WebsiteAnalyticsProviderType.GoogleAnalytics)) {
            incompatibleProvider = WebsiteAnalyticsProviderType.GoogleAnalytics;
        } else if (props.providerType === WebsiteAnalyticsProviderType.GoogleTagManager && props.enabledProviders.includes(WebsiteAnalyticsProviderType.GoogleGtag)) {
            incompatibleProvider = WebsiteAnalyticsProviderType.GoogleGtag;
        }

        return {
            canTrackConversions: settings && settings.canTrackConversions ? true : false,
            enabled: ct.asFormValue('enabled', settings && settings.enabled ? true : false),
            trackConversions: ct.asFormValue('trackConversions', settings && settings.trackConversions ? true : false),
            args: settings ? Object.keys(settings.args).map(s => ({ key: s, val: ct.asFormValue('setting', settings.args[s]) })) : [],
            incompatibleProvider: incompatibleProvider
        };
    }


    componentDidUpdate(prevProps: AnalyticsSettingsFormProps) {
        // Only update state if resource has changed
        const { saveComplete: prevSaveComplete } = prevProps;
        const { publicWebsiteSettings, saveComplete } = this.props;

        const prevPayPageSettings = prevProps.publicWebsiteSettings.paymentPage;
        const nextpayPageSettings = publicWebsiteSettings.paymentPage;

        if (prevPayPageSettings.heading !== nextpayPageSettings.heading || prevPayPageSettings.body !== nextpayPageSettings.body || prevPayPageSettings.payButtonText !== nextpayPageSettings.payButtonText) {
            this.setState(this.buildStateFromProps(this.props));
        }

        if (saveComplete && !prevSaveComplete) {
            setTimeout(() => { this.close(); }, 750);
        }
    }

    private close = () => {
        this.props.close();
    }

    private save = () => {
        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {
            const { publicWebsiteSettings, providerType, savePublicWebsiteSettings } = this.props;
            const { enabled, args, trackConversions } = this.state;

            var newArgs: PublicWebsiteAnalyticsArgs = {};

            for (var i = 0; i < args.length; i++) {
                newArgs[args[i].key] = args[i].val.value;
            }

            savePublicWebsiteSettings({
                ...publicWebsiteSettings,
                analyticsSettings: publicWebsiteSettings.analyticsSettings.map(s => s.providerType === providerType ? { ...s, enabled: enabled.value, args: newArgs, trackConversions: trackConversions.value } : s)
            }, null, null);
        }
    }

    argValueChanged = (key: string, val: string) => this.setState(prev => ({ args: prev.args.map(a => a.key === key ? { ...a, val: ct.asFormValue('setting', val) } : a) }) )

    render() {
        const { t } = this.context;
        const { providerType, saveError, saveComplete } = this.props;
        const { enabled, args, trackConversions, canTrackConversions, incompatibleProvider } = this.state;

        const message = saveError ? <ApiError error={saveError} /> : saveComplete ? <div className='bg-success'>{t('Global:saveComplete')}</div> : null;

        return <div className='paymentPageForm'>
            <h2 className='paymentPageForm_title'>{`${t('WebsiteAnalyticsProviderType:' + WebsiteAnalyticsProviderType[providerType])} ${t('Global:settings')}`}</h2>

            <div className='row'>
                <div className='col-md-12'>
                    <form className='data-form' onSubmit={formHandler} autoComplete='off'>

                        <ct.Checkbox id='enabled' labelKey='Global:enabled' value={enabled} callback={val => this.setState({ enabled: ct.asFormValue('enabled', val) })} />

                        {incompatibleProvider ? <div className='alert alert-danger'>{t('WebsiteAnalyticsForm:incompatibleProvider', { incompatibleProvider: t(`WebsiteAnalyticsProviderType:${WebsiteAnalyticsProviderType[incompatibleProvider]}`) })}</div> : null}

                        {enabled.value && canTrackConversions ? <ct.Checkbox id='trackConversions' labelKey='WebsiteAnalyticsForm:trackConversions' value={trackConversions} callback={val => this.setState({ trackConversions: ct.asFormValue('trackConversions', val) })} /> : null}

                        { enabled.value ? this.renderArgs(args) : null}

                        {message}

                        <p />
                        <div className='btn-toolbar'>
                            <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)}>{t('Global:save')}</button>
                            <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{t('Global:cancel')}</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    }

    renderArgs = (args: Setting[]) => {
        const { t } = this.context;
        const inlineControlStyle = ({ minHeight: '10px', marginRight: '10px' });

        return <table className='analytics-settings-table'>
            <tbody>
                {args.map(a => <tr key={a.key}>
                    <td className='shrink'>{t(`WebAnalyticsSettingKey:${a.key}`)}</td>
                    <td className='expand'>
                        {a.key === 'Use Enhanced Ecommerce' || a.key === 'Disable Page Views'
                            ? <ct.Checkbox id={a.key} labelKey='' placeholderKey='' value={{ ...a.val, value: (a.val.value || '').toLowerCase() === 'true' }} callback={val => this.argValueChanged(a.key, val ? 'True' : 'False')} style={inlineControlStyle} />
                            : <ct.TextBox id={a.key} labelKey='' placeholderKey='' value={a.val} callback={val => this.argValueChanged(a.key, val)} style={inlineControlStyle} />
                        }
                    </td>
                </tr>)}
            </tbody>
        </table>
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    isSaving: state.publicWebsiteSettings.isSaving,
    saveComplete: state.publicWebsiteSettings.saveComplete,
    saveError: state.publicWebsiteSettings.saveError,
    validationErrors: state.publicWebsiteSettings.validationErrors,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    savePublicWebsiteSettings: bindActionCreators(PublicWebsiteSettingsActions.actionCreators.savePublicWebsiteSettings, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(AnalyticsSettingsForm);
