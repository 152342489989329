
import { Action } from 'redux';

import { ClientActionTypes, ClientState } from './types';
import { ClientActions } from './actions'
import { createReducer } from '../../reducer';

const unloadedState: ClientState =
{ isLoading: false, clients: [], loadError: null, isSaving: false, saveComplete: false, saveError: null, validationErrors: [] };


export const reducer = (state: ClientState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: ClientState, incomingAction: Action): ClientState => {
    const action = incomingAction as ClientActions;
    switch (action.type) {
        case ClientActionTypes.RequestClients:
            return {
                ...state,
                isLoading: true,
                clients: state.clients,
                loadError: null
            };
        case ClientActionTypes.ReceivedClients:
            return {
                ...state,
                isLoading: false,
                clients: action.clients,
                loadError: action.error
            };
        case ClientActionTypes.EditClient:
            return {
                ...state,
                isSaving: false,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case ClientActionTypes.SaveClient:
            return {
                ...state,
                isSaving: true,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case ClientActionTypes.ClientSaved:
            return {
                ...state,
                isSaving: false,
                saveComplete: true,
                saveError: null,
                validationErrors: []
            };
        case ClientActionTypes.ClientSaveFailed:
            return {
                ...state,
                isSaving: false,
                saveError: action.error,
                saveComplete: false,
                validationErrors: action.error && action.error.validationErrors ? action.error.validationErrors : []
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

