
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { PaginationItem } from './paginationItem';
import { ITranslationContext } from '../../translations';

interface PaginationProps {
    pageNumber: number;
    pageSize: number;
    maxPage: number;
    setPageSize: (pageSize: number) => void;
    setPage: (pageNumber: number) => void;
}

const Pagination = (props: PaginationProps, context: ITranslationContext) => {

    const { t } = context;

    var min = Math.max(1, props.pageNumber - 2);
    var max = Math.min(props.maxPage, props.pageNumber + 2);

    var items = [<PaginationItem key={'prev'} val={t('Global:Previous')} selected={false} disabled={props.pageNumber === 1} clicked={() => props.setPage(Math.max(1, props.pageNumber - 1))} />];

    for (let i = min; i <= max; i++) {
        items.push(<PaginationItem key={i} val={i.toString()} selected={i === props.pageNumber} disabled={false}  clicked={() => props.setPage(i)} />)
    }

    items.push(<PaginationItem key={'next'} val={t('Global:Next')} selected={false} disabled={props.pageNumber === props.maxPage}  clicked={() => props.setPage(Math.min(max, props.pageNumber + 1))} />)

    return <div style={({ margin: '0 auto', textAlign: 'center' })}><ul className="pagination">{items}</ul></div>;
};

Pagination.contextTypes = {
    t: PropTypes.func
}

export default Pagination;
