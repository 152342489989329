
import * as React from 'react';
import * as PropTypes from 'prop-types'
import * as rt from '../../../store/pages/reportDefinitions/types';
import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import moment from 'moment';
import { ITranslationContext } from '../../../translations';
import { parseFilterDate, formatFilterDate, formatFilterDateTime, parseFilterDateTime, formatNumber, parseNumericFilter } from '../../../store/pages/reportTypes/types';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';

interface ReportFilterProps {
    filter: rt.ReportFilter;
    valueChanged: (newValues: string[]) => void;
    setEnabled: (enabled: boolean) => void
    dateFormat: DateFormat;
    timeFormat: TimeFormat;
}

const ReportFilterOption = (props: ReportFilterProps, context: ITranslationContext) => {
    const { name, values, type, options, enabled } = props.filter;
    
    const firstValue = values && values.length > 0 ? values[0] : '';
    const secondValue = values && values.length > 1 ? values[1] : '';

    const toggleState = (event: React.ChangeEvent<HTMLInputElement>) => props.setEnabled(event.currentTarget.checked);

    const validateDate = (val: moment.Moment) => v.validate(val, props.filter.name, [], []);
    const validateDateRange = (val: ct.DateRange) => v.validate(val, props.filter.name, [], []);
    const validateText = (val: string) => v.validate(val, props.filter.name, [], []);
    const validateNumericRange = (val: ct.NumberRange) => v.validate(val, props.filter.name, [numericRangeValidator], [])

    const numericRangeValidator = (value: any) =>
    {
        const { from, to } = value;

        if (!from && !to) return undefined;

        var fromVal = typeof from === 'number' && !isNaN(from) ? from : null;
        var toVal = typeof to === 'number' && !isNaN(to) ? to : null;

        if (fromVal === null || toVal === null) return undefined;

        if (fromVal > toVal) return 'validation:fromGreaterThanTo';

        return undefined;
    }

    const renderBoolean = () => {
        var selectOptions = [{ key: 'yes', name: context.t('ReportFilter:Yes') }, { key: 'no', name: context.t('ReportFilter:No') }];
        return renderSelect(selectOptions, validateText(firstValue), val => props.valueChanged([val]));
    }

    const renderDate = () => <ct.DatePicker id={name} labelKey={`ReportFilter:${name}`} labelContent={renderLabel(enabled)} labelStyle={buildLabelStyle()} value={validateDate(parseFilterDate(firstValue))} dateFormat={props.dateFormat} callback={val => props.valueChanged([formatFilterDate(val)])} disabled={!enabled} />;
    const renderDateTime = () => <ct.DatePicker id={name} labelKey={`ReportFilter:${name}`} labelContent={renderLabel(enabled)} labelStyle={buildLabelStyle()} value={validateDate(parseFilterDate(firstValue))} dateFormat={props.dateFormat} callback={val => props.valueChanged([formatFilterDate(val)])} disabled={!enabled} />;

    const renderDateRange = () => {
        return <ct.DateRangePicker id={name} labelKey={`ReportFilter:${name}`} labelContent={renderLabel(enabled)} labelStyle={buildLabelStyle()} value={validateDateRange({ from: parseFilterDate(firstValue), to: parseFilterDate(secondValue) })} disabled={!enabled} dateFormat={props.dateFormat} timeFormat={false} callback={val => props.valueChanged([formatFilterDate(val.from), formatFilterDate(val.to)])} />;
    }

    const renderNumericRange = () => {
        return <ct.NumericRange id={name} labelKey={`ReportFilter:${name}`} labelContent={renderLabel(enabled)} labelStyle={buildLabelStyle()} value={validateNumericRange({ from: parseNumericFilter(firstValue), to: parseNumericFilter(secondValue) })} disabled={!enabled} callback={val => props.valueChanged([formatNumber(val.from), formatNumber(val.to)])} />;
    }

    const renderDateTimeRange = () => {
        return <ct.DateRangePicker id={name} labelKey={`ReportFilter:${name}`} labelContent={renderLabel(enabled)} labelStyle={buildLabelStyle()} value={validateDateRange({ from: parseFilterDateTime(firstValue), to: parseFilterDateTime(secondValue) })} disabled={!enabled} dateFormat={props.dateFormat} timeFormat={props.timeFormat} callback={val => props.valueChanged([formatFilterDateTime(val.from), formatFilterDateTime(val.to)])} />;
    }

    const renderText = () => <ct.TextBox id={name} labelKey={`ReportFilter:${name}`} labelContent={renderLabel(enabled)} labelStyle={buildLabelStyle()} placeholderKey={`ReportFilter:${name}`} value={validateText(firstValue)} disabled={!enabled} callback={val => props.valueChanged([val])} />;

    const renderList = () => {
        var listOptions = options.map(o => ({ key: o.key, name: context.t(o.nameKey) }));
        return renderSelect(listOptions, validateText(firstValue), val => props.valueChanged([val]));
    }

    const renderMultiSelectList = () => {
        var listOptions = options.map(o => ({ key: o.key, name: context.t(o.nameKey), selected: values.includes(o.key) }));

        const selectionChanged = (key: string, selected: boolean) => {
            const newSelections = selected ? values.concat(key) : values.filter(o => o !== key);
            return props.valueChanged(newSelections)
        }

        return <ct.MultiSelect id={name} labelKey={`ReportFilter:${name}`} labelContent={renderLabel(enabled)} options={listOptions} disabled={!enabled} callback={selectionChanged} />
    }

    const renderSelect = (listOptions: ct.SelectOption[], selectedValue: ct.FormValue<string>, valueChanged: (val: string) => void) =>
        <ct.Select id={name} labelKey={`ReportFilter:${name}`} labelContent={renderLabel(enabled)} labelStyle={buildLabelStyle()} value={selectedValue} disabled={!enabled} callback={valueChanged} options={listOptions} />;

    // *** fix this => can't have the text inside the input - need to make them separate
    const renderLabel = (enabled: boolean) => <input type='checkbox' className='pull-right' checked={enabled} onChange={toggleState} />

    const buildLabelStyle = () => ({ width: '100%' });

    switch (type) {
        case rt.ReportFilterType.Boolean:
            return renderBoolean();
        case rt.ReportFilterType.Date:
            return renderDate();
        case rt.ReportFilterType.DateTime:
            return renderDateTime();
        case rt.ReportFilterType.DateRange:
            return renderDateRange();
        case rt.ReportFilterType.DateTimeRange:
            return renderDateTimeRange();
        case rt.ReportFilterType.Text:
            return renderText();
        case rt.ReportFilterType.List:
            return renderList();
        case rt.ReportFilterType.NumericRange:
            return renderNumericRange();
        case rt.ReportFilterType.MultiSelectList:
            return renderMultiSelectList();
        default:
            return <div>Unsupported type {rt.ReportFilterType[type]}</div>;
    }
}

ReportFilterOption.contextTypes = {
    t: PropTypes.func
}

export default ReportFilterOption;