
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as api from '../../../store/apiClient';

import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import { PaymentMethod } from '../../../store/pages/paymentMethods/types';
import { clickHandler, isNullOrEmpty } from '../../../utils/util';
import ApiError from '../../global/apiError';
import { Bill } from '../../../store/pages/pointOfSale/types';

interface ValidateVoucherResponse {
    isValidCode: boolean;
    code: string;
    name: string;
    hasExpired: boolean;
    isRevoked: boolean;
    nonDiscountableProducts: boolean;
    customerConflict: boolean;
    originalCustomerName: string;
    remainingBalance: number;
    error: string;
    warning: string;
}

interface ValidateVoucherForPaymentProps {
    venueId: string;
    paymentMethod: PaymentMethod;
    amount: number;
    billId: string;
    redeemVoucher: (voucherCode: string, amount: number, completionCallback: (success: boolean, bill: Bill | null, error: api.ApiError | null) => void) => void;
    cancel: () => void;
    logout: () => void;
}

interface ValidateVoucherForPaymentState {
    voucherCode: ct.FormValue<string>;
    paymentAmount: ct.FormValue<number>;
    voucher: ValidateVoucherResponse | null;
    errorKey: string | null;
    warningMessage: string | null;
    apiError: api.ApiError | null;
}

export default class ValidateVoucherForPayment extends React.Component<ValidateVoucherForPaymentProps, ValidateVoucherForPaymentState> {

    constructor(props: ValidateVoucherForPaymentProps) {
        super(props);

        this.state = {
            voucherCode: this.validateVoucherCode(''),
            paymentAmount: this.validateAmount(props.amount),
            voucher: null,
            errorKey: null,
            warningMessage: null,
            apiError: null
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }


    completePayment = () => {
        const { voucherCode, paymentAmount, voucher, errorKey } = this.state;

        const compressedVoucherCode = this.compressVoucherCode(voucherCode.value);

        if (!voucherCode.isValid || !paymentAmount.isValid) {
            // show error
        } else if (voucher == null || !voucher.isValidCode || voucher.code !== compressedVoucherCode) {
            this.setState({ errorKey: 'PointOfSale:invalidVoucherCode', warningMessage: null })
        } else if (paymentAmount.value > voucher.remainingBalance) {
            this.setState({ errorKey: 'PointOfSale:amountExceedsRemainingBalance', warningMessage: null })
        } else if (!isNullOrEmpty(voucher.error)) {
            this.setState({ errorKey: voucher.error, warningMessage: null })
        } else {
            this.props.redeemVoucher(compressedVoucherCode, paymentAmount.value, (success: boolean, bill: Bill | null, error: api.ApiError | null) => {
                if (!success) {
                    this.setState({ apiError: error, warningMessage: null })
                }
            });
        }
    }

    validateVoucher = () => {
        const { t } = this.context;
        const { voucherCode, paymentAmount } = this.state;
        const { venueId, billId, logout } = this.props;
        api.postWithAuth(`api/v1/voucher/validate`, {
            code: this.compressVoucherCode(voucherCode.value),
            paymentAmount: paymentAmount.value,
            billId: billId,
            venueId: venueId
        }, () => logout)
            .subscribe(response => {
                const vr = response.response as ValidateVoucherResponse;
                if (vr) {
                    let errorKey: string | null = null;
                    let warningMessage: string | null = null;

                    if (!vr.isValidCode) errorKey = 'PointOfSale:invalidVoucherCode';
                    else if (vr.hasExpired) errorKey = 'PointOfSale:voucherExpired';
                    else if (vr.remainingBalance === 0) errorKey = 'PointOfSale:voucherFullyRedeemed';
                    else if (vr.remainingBalance <paymentAmount.value) errorKey = 'PointOfSale:paymentAmountExceedsVoucherBalance';
                    else if (vr.isRevoked) errorKey = 'PointOfSale:voucherRevoked';
                    else if (vr.nonDiscountableProducts) warningMessage = t('PointOfSale:nonDiscountableProducts');
                    else if (vr.customerConflict) warningMessage = t('PointOfSale:customerConflict', {customerName: vr.originalCustomerName});

                    this.setState({ errorKey: errorKey, apiError: null, warningMessage: warningMessage, voucher: vr });
                }
            }, (err: api.ApiError) => {
                this.setState({ voucher: null, errorKey: null, warningMessage: null, apiError: err });
            });
    }

    compressVoucherCode = (code: string) => code.replace(/\s/g, '')

    formatVoucherCode = (code: string) => code.toUpperCase().replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();

    validateVoucherCode = (val: string) => v.validate(val, 'voucherCode', [v.required], []);
    validateAmount = (val: number) => v.validate(val, 'amount', [v.required], []);

    render() {
        const { t } = this.context;
        const { voucherCode, paymentAmount, voucher, errorKey, warningMessage, apiError } = this.state;

        const compressedCode = this.compressVoucherCode(voucherCode.value);
        return (
            <div style={({ maxWidth: '480px', flex: '1 1 auto', margin: '0 auto' })}>
                <h3>{t('PointOfSale:paymentInfo')}</h3>

                <form className='data-form' onSubmit={e => e.preventDefault()} autoComplete='off'>

                    <div className='row'>
                        <div className='col-sm-12'>
                            <ct.TextBox id='voucherCode' labelKey='PointOfSale:voucherCode' placeholderKey='PointOfSale:voucherCode' value={voucherCode} callback={val => this.setState({ voucherCode: this.validateVoucherCode(this.formatVoucherCode(val)) })} />

                            {voucher && voucher.isValidCode
                                ?
                                <div>
                                    <div style={{ fontSize: '18px', fontWeight: 'bold' }}>{voucher.name}</div>
                                    <div><label>{t('PointOfSale:remainingBalance')}</label> <span style={{ fontSize: '18px', fontWeight: 'bold' }}>{`${t('Global:currencySymbol')}${voucher.remainingBalance}`}</span></div>
                                </div> : null}

                            <ct.NumberBox id='amount' labelKey='PointOfSale:paymentAmount' placeholderKey='' value={paymentAmount} callback={val => this.setState({ paymentAmount: this.validateAmount(val || 0) })} min='0' />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-12'>
                            {apiError
                                ? <ApiError error={apiError} />
                                : errorKey ? <div className='alert alert-danger'>{t(errorKey)}</div>
                                    : warningMessage ? <div className='alert alert-warning'>{warningMessage}</div>
                                    : voucher && !isNullOrEmpty(voucher.warning) ? <div className='alert alert-warning'>{t(voucher.warning)}</div> : null}
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-12 button-panel'>
                            {!voucher || !voucher.isValidCode || voucher.code !== compressedCode || !isNullOrEmpty(errorKey)
                                ? <button className='btn btn-primary' onClick={e => clickHandler(e, this.validateVoucher)}>{t('PointOfSale:validateVoucher')}</button>
                                : <button className='btn btn-primary' onClick={e => clickHandler(e, this.completePayment)}>{t('PointOfSale:redeemVoucher')}</button>
                            }
                            <button onClick={e => clickHandler(e, this.props.cancel)} className='btn btn-basic'>{t('Global:cancel')}</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

}