
import { Action } from 'redux';

import { PromotionActionTypes, PromotionState } from './types';
import { PromotionActions } from './actions'
import { createReducer } from '../../reducer';
import { mapUtcDate, parseLocalDateTime } from '../../../utils/util';

const unloadedState: PromotionState = { isLoading: false, promotions: [], loadError: null, isSaving: false, saveComplete: false, saveError: null, validationErrors: [] };

export const reducer = (state: PromotionState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: PromotionState, incomingAction: Action): PromotionState => {

    const action = incomingAction as PromotionActions;
    switch (action.type) {
        case PromotionActionTypes.RequestPromotions:
            return {
                ...state,
                isLoading: true,
                promotions: state.promotions,
                loadError: null
            };
        case PromotionActionTypes.ReceivedPromotions:
            const ordered = action.promotions ? action.promotions.map(p => ({
                ...p,
                startDate: parseLocalDateTime(p.startDate),
                endDate: mapUtcDate(p.endDate),
                validForEventsFrom: mapUtcDate(p.validForEventsFrom),
                validForEventsUntil: mapUtcDate(p.validForEventsUntil)
            })) : [];
            ordered.sort((a, b) => a.name.localeCompare(b.name));

            return {
                ...state,
                isLoading: false,
                promotions: ordered,
                loadError: action.error
            };
        case PromotionActionTypes.EditPromotion:
            return {
                ...state,
                isSaving: false,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case PromotionActionTypes.SavePromotion:
            return {
                ...state,
                isSaving: true,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case PromotionActionTypes.PromotionSaved:
            return {
                ...state,
                isSaving: false,
                saveComplete: true,
                saveError: null,
                validationErrors: []
            };
        case PromotionActionTypes.PromotionSaveFailed:
            return {
                ...state,
                isSaving: false,
                saveError: action.error,
                saveComplete: false,
                validationErrors: action.error && action.error.validationErrors ? action.error.validationErrors : []
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

