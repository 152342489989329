
// https://blog.angularindepth.com/how-to-reduce-action-boilerplate-90dc3d389e2b

import { ActionsObservable } from 'redux-observable';
import { AjaxError, Observable } from 'rxjs/Rx';
import { push, RouterAction } from 'react-router-redux';
import * as api from '../../apiClient';
import * as epic from '../../epic';
import * as lt from './types';
import * as ct from '../clients/types';
import * as auth from '../../../utils/auth'
import { flatMap, mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { IStore } from '../..';
import { getAccessToken } from '../../../utils/auth';

export type LoginActions = lt.LogIn | lt.LoginSuccess | lt.LoggedIn | lt.LoggedOut | lt.SignedOut | lt.ChangePassword | lt.AuthorizeError | lt.AccessDenied | lt.AjaxError | lt.SwitchClient
    | lt.ClientSwitched | lt.LoadUserClientInfo | lt.ReceiveUserClientInfo | lt.RefreshTokenError | lt.RequestPasswordReset | lt.PasswordReset | lt.PasswordResetError
    | lt.ResetUserPassword | lt.ResetUserPasswordError | lt.LoadDataForClient | lt.ClientDataLoadComplete;

export interface IAuthorizeResult {
    accessToken: string;
    refreshToken: string;
    mustChangePassword: boolean;
    validFor: number;
    st: number | null;
}

const logout = (redirect: boolean) => {
    return redirect ? push('/login') : { type: lt.LoginActionTypes.SignedOut };
};

const onLoggedIn = (forceNaviationToHome: boolean) => {
    if (forceNaviationToHome) {
        return push('/');
    }
    else {
        return ({ type: 'dummy' })
    }
}

export const actionCreators = {
    login: (username: string, password: string) => ({ type: lt.LoginActionTypes.Login, username: username, password: password }),
    logout: () => ({ type: lt.LoginActionTypes.LoggedOut, redirect: true }),
    ensureSignedOut: () => ({ type: lt.LoginActionTypes.LoggedOut, redirect: false }),
    loginSuccess: (username: string, sessionTimeout: number | null, userFirstName: string | null, userLastName: string | null) => ({ type: lt.LoginActionTypes.LoginSuccess, username: username, sessionTimeout: sessionTimeout, userFirstName: userFirstName, userLastName: userLastName }),
    loginComplete: (forceNaviationToHome: boolean) => ({ type: lt.LoginActionTypes.LoggedIn, forceNaviationToHome: forceNaviationToHome }),
    changePassword: (at: string) => ({ type: lt.LoginActionTypes.ChangePassword, at: at }),
    changeUserPassword: (at: string, oldPassword: string, newPassword: string, confPassword: string) => ({ type: lt.LoginActionTypes.ChangeUserPassword, at: at, oldPassword: oldPassword, newPassword: newPassword, confPassword: confPassword }),
    refreshToken: () => ({ type: lt.LoginActionTypes.RefreshToken }),
    refreshTokenSuccess: () => ({ type: lt.LoginActionTypes.RefreshTokenSuccess }),
    refreshTokenError: (err: api.ApiError) => ({ type: lt.LoginActionTypes.RefreshTokenError, error: err }),
    authorizeFailure: (error: api.ApiError): lt.AuthorizeError => ({ type: lt.LoginActionTypes.AuthorizeError, error: error }),
    accessDenied: (error: api.ApiError): lt.AccessDenied => ({ type: lt.LoginActionTypes.AccessDenied, error: error }),
    ajaxError: (status: number, response: any): lt.AjaxError => ({ type: lt.LoginActionTypes.AjaxError, status: status, response: response }),
    switchClient: (clientId: number) => ({ type: lt.LoginActionTypes.SwitchClient, clientId: clientId }),
    clientSwitched: (clientId: number) => ({ type: lt.LoginActionTypes.ClientSwitched, clientId: clientId }),
    loadUserClientInfo: () => ({ type: lt.LoginActionTypes.LoadUserClientInfo }),
    receivedUserClientInfo: (clientInfo: lt.UserClientInfo) => ({ type: lt.LoginActionTypes.ReceiveUserClientInfo, client: clientInfo.client, clients: clientInfo.clients }),
    requestPasswordReset: (username: string) => ({ type: lt.LoginActionTypes.RequestPasswordReset, username: username }),
    passwordReset: () => ({ type: lt.LoginActionTypes.PasswordReset }),
    passwordResetFailed: (err: api.ApiError) => ({ type: lt.LoginActionTypes.PasswordResetError, err: err }),
    resetUserPassword: (token: string, username: string, newPassword: string, confPassword: string) => ({ type: lt.LoginActionTypes.ResetUserPassword, token: token, username: username, newPassword: newPassword, confPassword: confPassword }),
    resetUserPasswordFailed: (err: api.ApiError): lt.ResetUserPasswordError => ({ type: lt.LoginActionTypes.ResetUserPasswordError, err: err }),
    loadDataForClient: (clientId: number) => ({ type: lt.LoginActionTypes.LoadDataForClient, clientId: clientId }),
    clientDataLoadComplete: (isSwitchingClientOrLoggingIn: boolean) => ({ type: lt.LoginActionTypes.ClientDataLoadComplete, isSwitchingClientOrLoggingIn: isSwitchingClientOrLoggingIn })
}

export const clientDataLoadComplete = (store: IStore) => [() => actionCreators.clientDataLoadComplete((store.value.login.loggingIn && !store.value.login.isLoggedIn) || store.value.login.switchingClient)]

export const loginEpic = (action$: ActionsObservable<any>) =>
    action$
        .ofType(lt.LoginActionTypes.Login)
        .pipe(mergeMap(action => api.authenticate(action.username, action.password)
            .pipe(flatMap(response => {
                return response.mustChangePassword
                    ? ActionsObservable.of(actionCreators.changePassword(response.at))
                    : ActionsObservable.of(actionCreators.loginSuccess(action.username, response.sessionTimeout, response.userFirstName, response.userLastName), actionCreators.loadUserClientInfo());
            }),
            catchError((error: api.ApiError): ActionsObservable<lt.AuthorizeError | lt.AccessDenied | lt.AjaxError> => {
                if (error.status === 401) {
                    return ActionsObservable.of(actionCreators.authorizeFailure({
                        status: error.status,
                        message: error.message,
                        unauthenticated: true,
                        messageKey: 'LoginForm:invalidCredentials',
                        validationErrors: error.validationErrors }));
                }
                if (error.status === 403) {
                    return ActionsObservable.of(actionCreators.accessDenied(error));
                }

                return ActionsObservable.of(actionCreators.ajaxError(error.status, error.message));
            }))
        ));

export const loggedInEpic = (action$: ActionsObservable<any>) => action$.ofType(lt.LoginActionTypes.LoggedIn)
    .pipe(mergeMap(action => of(onLoggedIn(action.forceNaviationToHome)) ));

export const loggedOutEpic = (action$: ActionsObservable<any>) =>
    action$
        .ofType(lt.LoginActionTypes.LoggedOut)
        .pipe(mergeMap(action => {
            var token = auth.getRefreshToken();

            if (token === null || token.trim().length < 1) {
                return ActionsObservable.of(logout(action.redirect));
            } else {
                auth.signOut();
                return api.logout(token)
                    .pipe(map(_ => {
                        return logout(action.redirect);
                    }), catchError((error: AjaxError): ActionsObservable<any> => {
                        return ActionsObservable.of(logout(action.redirect));
                    }))
            }
        }));
 
export const clientDataLoadCompleteEpic = (action$: ActionsObservable<any>) => action$.ofType(lt.LoginActionTypes.ClientDataLoadComplete)
    .pipe(flatMap(action => {
        return of(actionCreators.clientSwitched(action.clientId), actionCreators.loginComplete(action.isSwitchingClientOrLoggingIn))
    }));

export const changeUserPasswordEpic = (action$: ActionsObservable<any>) =>
    action$
        .ofType(lt.LoginActionTypes.ChangeUserPassword)
        .pipe(mergeMap(action => api.changeUserPassword(action.at, action.oldPassword, action.newPassword, action.confPassword)
            .pipe(map(response => {
                return response.mustChangePassword ? actionCreators.changePassword(response.at) : actionCreators.loadUserClientInfo();
            }), catchError((error: AjaxError): ActionsObservable<lt.AuthorizeError | lt.AccessDenied | lt.AjaxError> => {
                if (error.status === 401) {
                    return ActionsObservable.of(actionCreators.authorizeFailure(error.response.error));
                }
                if (error.status === 403) {
                    return ActionsObservable.of(actionCreators.accessDenied(error.response));
                }

                return ActionsObservable.of(actionCreators.ajaxError(error.status, error.response));
            }))
        ));

export const resetUserPasswordEpic = (action$: ActionsObservable<any>) =>
    action$
        .ofType(lt.LoginActionTypes.ResetUserPassword)
        .pipe(mergeMap(action => api.resetUserPassword(action.token, action.username, action.newPassword, action.confPassword)
            .pipe(map(response => {
                return response.mustChangePassword ? actionCreators.changePassword(response.at) : actionCreators.loadUserClientInfo();
            }),
                catchError((error: AjaxError): ActionsObservable<lt.ResetUserPasswordError> =>
                ActionsObservable.of(actionCreators.resetUserPasswordFailed({
                    status: error.status,
                    message: error.message,
                    unauthenticated: true,
                    messageKey: error.status === 401 ? 'ResetPassword:invalidToken' : 'ResetPassword:resetError',
                    validationErrors: []
                }))
            ))
        ));

export const refreshTokenEpic = (action$: ActionsObservable<any>) =>
    action$.ofType(lt.LoginActionTypes.RefreshToken)
        .pipe(switchMap(() => {
            return api.refreshToken(null)
                .pipe(map(_ => actionCreators.refreshTokenSuccess()),
                catchError((err: AjaxError) => of(actionCreators.refreshTokenError(err.response))));
        }));

export const onTokenErrorEpic = (action$: ActionsObservable<any>) =>
    action$.ofType(lt.LoginActionTypes.RefreshTokenError)
        .pipe(switchMap(_ => of(actionCreators.logout())));

export const switchTokenEpic = (action$: ActionsObservable<any>) =>
    action$.ofType(lt.LoginActionTypes.SwitchClient)
        .pipe(switchMap(action => {
            const { clientId } = action;

            return api.switchClient(clientId)
                .pipe(switchMap(_ => of(actionCreators.loadUserClientInfo())),
                catchError((err: AjaxError) => of(
                    actionCreators.refreshTokenError(err.response)
                )));
        }));

const loadUserClients = (userFirstName: string | null, userLastName: string | null) => Observable.defer(() => api.getJson<lt.UserClientInfo>('api/v1/user/clients'))
    .pipe(switchMap(data => {
        const { st } = getAccessToken();
        return of(actionCreators.loginSuccess('', st, userFirstName, userLastName), actionCreators.receivedUserClientInfo(data), actionCreators.loadDataForClient(data.client.id));
    }));

export const loadUserClientInfoEpic = (action$: ActionsObservable<any>, store: IStore) =>
    epic.create(action$,
        lt.LoginActionTypes.LoadUserClientInfo,
        _ => {
            return loadUserClients(store.value.login.userFirstName, store.value.login.userLastName);
        },
        _ => actionCreators.logout());

export const clientAddedEpic = (action$: ActionsObservable<any>) =>
    action$.ofType(ct.ClientActionTypes.ClientSaved)
        .pipe(switchMap(_ => of(actionCreators.loadUserClientInfo())));

export const resetPasswordEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        lt.LoginActionTypes.RequestPasswordReset,
        action => resetPassword(action.username),
        err => err.status === 403 ? actionCreators.passwordReset() : actionCreators.passwordResetFailed(err));

const resetPassword = (username: string) => Observable.defer(() => api.resetPassword(username).pipe(map(_ => actionCreators.passwordReset())))
