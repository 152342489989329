
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as api from '../../../../store/apiClient';

import { BillPayment } from '../../../../store/pages/pointOfSale/types';
import { PaymentMethod } from '../../../../store/pages/paymentMethods/types';
import { isNullOrEmpty } from '../../../../utils/util';
import ExternalWindowCoordinator from '../../../global/externalWindowCoordinator';
import { AjaxResponse } from 'rxjs/ajax';


interface ProcessPaymentsensePaymentProps {
    venueId: string;
    billId: string;
    payment: BillPayment;
    amount: number;
    paymentMethod: PaymentMethod;
    onSaveError: (saveError: api.ApiError) => void;
    onComplete: (billId: string) => void;
    showAlert: (element: JSX.Element) => void;
    clearAlert: () => void;
}

interface ProcessPaymentsensePaymentState {
    processPayment: boolean;
    token: string;
    billId: string;
    payment: BillPayment | null
}

interface ICreateGatewayPaymentResponse {
    token: string;
}

export default class ProcessPaymentsensePayment extends React.Component<ProcessPaymentsensePaymentProps, ProcessPaymentsensePaymentState> {

    static contextTypes = {
        t: PropTypes.func
    }

    constructor(props: ProcessPaymentsensePaymentProps) {
        super(props);

        this.state = { processPayment: false, token: '', billId: '', payment: null };
    }

    componentDidMount() {
        const { billId, payment, amount, paymentMethod } = this.props;
        this.initiateGatewayPayment(billId, payment, amount, paymentMethod);
    }

    initiateGatewayPayment = (billId: string, payment: BillPayment, amount: number, paymentMethod: PaymentMethod) => {
        const body = {
            venueId: this.props.venueId,
            billId: billId,
            billPaymentId: payment.id,
            paymentMethodId: paymentMethod.id,
            description: payment.description,
            isSecurityPayment: payment.isSecurityPayment,
            amount: amount,
            additionalInfo: payment.additionalInfo
        };

        const responseHaqndler = (response: AjaxResponse) => {
            const resp = response.response as ICreateGatewayPaymentResponse;
            if (resp) {
                const token = resp.token;
                this.handOverToGatewayProcessor(token, billId, payment);
            }
        }

        const errorHandler = (err: api.ApiError) => {
            this.props.onSaveError(err);
        };

        if (isNullOrEmpty(payment.id)) {
            api.postWithAuth(`api/v1/paymentGateway/payment`, body, () => ({ /* TODO: Handle auth error*/ })).subscribe(responseHaqndler, errorHandler);
        } else {
            api.putWithAuth(`api/v1/paymentGateway/payment`, body, () => ({ /* TODO: Handle auth error*/ })).subscribe(responseHaqndler, errorHandler);
        }
    }

    retryGatewayPayment = (token: string, billId: string, payment: BillPayment) => {
        this.props.clearAlert();
        this.handOverToGatewayProcessor(token, billId, payment);
    }

    handOverToGatewayProcessor = (token: string, billId: string, payment: BillPayment) => this.setState({ processPayment: true, token: token, billId: billId, payment: payment});

    render() {
        const { onComplete } = this.props;
        const { processPayment, token, billId, payment } = this.state;

        const body = this.renderBody();

        return processPayment && payment
            ? <ExternalWindowCoordinator
                urlToOpen={`/payment/paymentsense?token=${token}`}
                windowName='payment'
                content={body}
                popupBlockedMessageKey='PointOfSale:PopupBlockedMessage'
                retryAction={() => this.retryGatewayPayment(token, billId, payment)}
                completionCallback={() => onComplete(billId)}
            />
            : body;
    }

    renderBody = () => {
        const { t } = this.context;
        return <div className='pos-process-gateway-payment-panel'>
            <h4 className='text-center'>{t('PointOfSale:processingPaymentHeader')}</h4>
            <div className='text-center'>{t('PointOfSale:processingPaymentText')}</div>
        </div>
    }
}