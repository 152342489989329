
import { Action } from 'redux';
import { RouterAction } from 'react-router-redux';
import { RoutingState } from './types';
import { createReducer } from '../../reducer';

const unloadedState: RoutingState = { previousRoute: '', currentRoute: '' };

export const reducer = (state: RoutingState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: RoutingState, incomingAction: Action): RoutingState => {

    // Always clear the modal when the route changes
    if (incomingAction.type === '@@router/LOCATION_CHANGE') {
        const routerAction = incomingAction as RouterAction;
        const payload = routerAction.payload as any;
        const newRoute = payload && payload.location && payload.location.pathname ? payload.location.pathname : '';
        return { previousRoute: state.currentRoute, currentRoute: newRoute }
    }

    return state || unloadedState;
};



