
import * as React from 'react';
import * as PropTypes from 'prop-types'
import { clickHandler } from '../../utils/util';

interface NumericPadProps {
    valueChanged: (value: string) => void;
    clearValue: () => void;
}

export default class NumericPad extends React.Component<NumericPadProps, {}> {

    static contextTypes = {
        t: PropTypes.func
    }


    render() {

        const { t } = this.context;

        return (
            <div className='numeric-pad-wrapper'>
                <button className='btn btn-basic' onClick={e => clickHandler(e, () => this.props.valueChanged('1'))}>{1}</button>
                <button className='btn btn-basic' onClick={e => clickHandler(e, () => this.props.valueChanged('2'))}>{2}</button>
                <button className='btn btn-basic' onClick={e => clickHandler(e, () => this.props.valueChanged('3'))}>{3}</button>
                <button className='btn btn-basic' onClick={e => clickHandler(e, () => this.props.valueChanged('4'))}>{4}</button>
                <button className='btn btn-basic' onClick={e => clickHandler(e, () => this.props.valueChanged('5'))}>{5}</button>
                <button className='btn btn-basic' onClick={e => clickHandler(e, () => this.props.valueChanged('6'))}>{6}</button>
                <button className='btn btn-basic' onClick={e => clickHandler(e, () => this.props.valueChanged('7'))}>{7}</button>
                <button className='btn btn-basic' onClick={e => clickHandler(e, () => this.props.valueChanged('8'))}>{8}</button>
                <button className='btn btn-basic' onClick={e => clickHandler(e, () => this.props.valueChanged('9'))}>{9}</button>
                <button className='btn btn-basic' onClick={e => clickHandler(e, () => this.props.valueChanged(t('Global:decimalSymbol')))}>{t('Global:decimalSymbol')}</button>
                <button className='btn btn-basic' onClick={e => clickHandler(e, () => this.props.valueChanged('0'))}>{0}</button>
                <button className='btn btn-basic' onClick={e => clickHandler(e, () => this.props.clearValue())}>{t('Global:clearCharacter')}</button>
            </div>);
    }
}

