
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import * as PropTypes from 'prop-types'
import 'rxjs';
import { Link } from 'react-router-dom';

import * as ModalActions from '../../../store/global/modal/actions';
import * as LoginActions from '../../../store/pages/login/actions';
import { ApplicationState } from '../../../store';
import * as customerApi from '../../../store/pages/customer/customerApi';
import { PaymentStatus } from '../../../store/pages/pointOfSale/types';
import Loading from '../../global/loading';
import { isNullOrEmpty, mapUtcDate, mapLocalDateTime, formatLaptime, clickHandler, parseLocalDateTime, parseUtcDate } from '../../../utils/util';
import MarketingPreferences from '../../global/marketingPreferences';
import { EmailStatus } from '../../../store/pages/emails/types';
import { CustomerSessionResult, CustomerPayment, CustomerEmail, CustomerRegistration, CustomerEvent, CustomerHistory, CustomerHistoryMerge, CustomerMembership } from '../../../store/pages/customer/customerApi';
import EventCustomerForm, { CustomerDetails } from '../diary/eventCustomerForm';
import RegistrationDetails from '../diary/registrationDetails';
import TagList from '../../global/tagList';
import SessionResultDetails from './sessionResultDetails';
import CustomerCreditDetails from './customerCreditDetails';
import CustomerNewBooking from './customerNewBooking';
import { DateFormat, TimeFormat, Venue } from '../../../store/pages/venues/types';
import { ICustomer } from '../diary/types';
import AuditLogEntry from '../../global/auditLogEntry';
import ConfirmUndoMerge from './confirmUndoMerge';
import { Gender } from '../../../store/pages/customer/types';
import TagLabel from '../../global/tagLabel';
import { stringComparer } from '../../../utils/comparers';
import { EmailDetailsPage } from '../emails/emailDetailsPage';

interface LocalState {
    selectedVenueId: string;
    defaultCountryId: number;
    isMultiVenue: boolean;
    venue: Venue | null | undefined;
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
}

interface Actions {
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
    logout: () => void;
}

interface CustomersDetailsPageRouteProps {
    customerId: string;
}

type CustomersDetailsPageProps = LocalState & Actions & RouteComponentProps<CustomersDetailsPageRouteProps>;

interface CustomersDetailsPageState {
    loading: boolean;
    customerId: string;
    history: CustomerHistory | null;
    loadError: string | null;
}

class CustomersDetailsPage extends React.Component<CustomersDetailsPageProps, CustomersDetailsPageState> {
    constructor(props: CustomersDetailsPageProps) {
        super(props);

        this.state = { loading: true, customerId: props.match.params.customerId, history: null, loadError: null };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        this.loadCustomer();  
    }

    loadCustomer = () => {
        const { logout } = this.props;
        const { customerId } = this.state;
        customerApi.getCustomerDetails(customerId, logout).subscribe(this.fetchSuccess, this.fetchError);
    }

    close = () => this.props.history.goBack();

    editCustomer = () => {
        const { history, customerId } = this.state;
        const { defaultCountryId } = this.props;
        if (!history) return;

        const customer = { ...history, key: customerId, customerId: customerId, isOrganiser: false, hasActiveMembership: history.memberships && history.memberships.filter(m => !m.expiry || m.expiry > new Date()).length > 0 };

            this.props.showModal(<EventCustomerForm
                add={false}
                customer={customer}
                requirePhoneNumber={false}
                showIsOrganiser={false}
                canChangeDob={true}
                canAddPhoto={true}
                defaultCountryId={defaultCountryId}
                cancel={this.props.closeModal}
                addCustomer={() => { }}
                updateCustomer={this.updateCustomer} />, 'EventCustomerForm');
    }

    updateCustomer = (customerDetails: CustomerDetails, photoImg: File | null) => {
        const { logout, closeModal } = this.props;
        const { customerId, ...customer } = customerDetails;
        customerApi.updateCustomer(customerId, customer, photoImg, logout)
            .subscribe(_ => {
                closeModal();
                this.loadCustomer();
            });
    }

    fetchSuccess = (resp: customerApi.IGetCustomerDetailsResponse) => {
        const { history } = resp;
        this.setState({
            loading: false,
            history: {
                ...history,
                lastEmailMarketingChange: history.lastEmailMarketingChange ? parseLocalDateTime(history.lastEmailMarketingChange) : null,
                createDateTime: parseUtcDate(history.createDateTime),
                events: history.events.map(e => ({ ...e, eventDate: parseLocalDateTime(e.eventDate) })),
                registrations: history.registrations.map(e => ({ ...e, registrationTime: parseLocalDateTime(e.registrationTime), eventDate: mapLocalDateTime(e.eventDate) })),
                payments: history.payments.map(p => ({
                    ...p,
                    deletedDateTime: mapUtcDate(p.deletedDateTime),
                    payentTakenDateTime: mapLocalDateTime(p.payentTakenDateTime),
                    paymentDueDate: mapLocalDateTime(p.paymentDueDate),
                    voidedDateTime: mapUtcDate(p.voidedDateTime)
                })),
                emails: history.emails.map(e => ({ ...e, timeQueued: parseLocalDateTime(e.timeQueued), timeSent: mapUtcDate(e.timeSent) })),
                sessionResults: history.sessionResults.map(r => ({ ...r, sessionStartTime: mapUtcDate(r.sessionStartTime) })),
                memberships: history.memberships.map(m => ({ ...m, createDateTime: parseUtcDate (m.createDateTime), expiry : mapUtcDate(m.expiry)})),
                auditLog: history.auditLog.map(l => ({ ...l, time: parseLocalDateTime(l.time) })),
                mergeHistory: history.mergeHistory.sort((c1, c2) => {
                    if (c1.customerId === history.primaryCustomerId) return -1;
                    if (c2.customerId === history.primaryCustomerId) return 1;
                    return stringComparer(c1.customerId, c2.customerId);
                }).map((mh, ix) => ({ ...mh, whenMerged: parseUtcDate(mh.whenMerged), mergeIndex: ix + 1 }))
            }
        });
    }

    fetchError = (e: any) => {
        this.setState({ loading: false, loadError: 'Unable to load customer details' });
    }

    viewRegistration = (registration: CustomerRegistration) => {
        const { showModal, closeModal, logout, dateFormat, timeFormat } = this.props;
        const { eventName } = registration;

        showModal(<RegistrationDetails
            event={({ name: eventName || '', startTime: registration.eventDate })}
            timeFormat={timeFormat}
            dateFormat={dateFormat}
            registrationId={registration.registrationId}
            registration={null}
            close={closeModal}
            logout={logout} />, 'RegistrationDetails');
    }

    viewEmailDetail = (emailId: string) => {
        const { timeFormat, dateFormat, showModal, closeModal } = this.props;
        showModal(<EmailDetailsPage emailId={emailId} timeFormat={timeFormat} dateFormat={dateFormat} close={closeModal} />, 'EmailDetails');
    }

    showResultsDetail = (results: CustomerSessionResult) => {
        const { timeFormat, dateFormat, showModal, closeModal } = this.props;
        const { history } = this.state;

        if (history) {
            showModal(<SessionResultDetails
                customerFirstName={history.firstname}
                customerLastName={history.lastname}
                customerSessionResult={results}
                timeFormat={timeFormat}
                dateFormat={dateFormat}
                close={closeModal} />, 'SessionResultDetails');
        }
    }

    viewCredit = () => {
        const { timeFormat, dateFormat, showModal, closeModal, logout } = this.props;
        const { history, customerId } = this.state;

        if (history) {
            showModal(<CustomerCreditDetails
                customerId={customerId}
                customerFirstName={history.firstname}
                customerLastName={history.lastname}
                timeFormat={timeFormat}
                dateFormat={dateFormat}
                close={closeModal}
                logout={logout} />, 'CustomerCreditDetails')
        }
    }

    newBooking = () => {
        const { showModal, closeModal, venue } = this.props;
        const { customerId, history } = this.state;

        const done = (bookingCreated: boolean) => {
            if (bookingCreated) {
                this.loadCustomer();
            }
            closeModal();
        }

        if (venue && history) {
            const customer: ICustomer = {
                customerId: customerId,
                firstname: history.firstname,
                lastname: history.lastname,
                companyName: history.companyName,
                emailAddress: history.emailAddress,
                phoneNumber: history.phoneNumber,
                tags: history.tags
            };

            showModal(<CustomerNewBooking
                venue={venue}
                customer={customer}
                close={done}
            />, 'CustomerNewBooking');
        }
    }

    undoMerge = (merge: CustomerHistoryMerge) => {
        const { timeFormat, dateFormat, showModal, closeModal, logout } = this.props;
        const close = (reload: boolean) => {
            if (reload) {
                this.loadCustomer();
            }

            closeModal();
        }

        showModal(<ConfirmUndoMerge merge={merge} timeFormat={timeFormat} dateFormat={dateFormat} close={close} logout={logout} />, 'ConfirmUndoMerge')
    }

    render() {
        const { customerId, loading, loadError, history } = this.state;
        const { venue } = this.props;
        const { t } = this.context;

        if (loading) {
            return <Loading />
        } else if (!isNullOrEmpty(loadError) || !history) {
            return <div className='alert alert-danger'>{t('customerDetails:loadError')}</div>
        }

        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;

        return <div>
            <h1>{history.firstname} {history.lastname}</h1>
            <section className='panel panel-default'>
                <div className="panel-body">
                    <div className='row'>
                        <div className='col-xs-3 col-md-2'>
                            <div><label className='no-margin'>{t('customerDetails:dateOfBirth')}</label></div>
                            <div>{history.birthYear > 0 && history.birthMonth > 0 && history.birthDay > 0 ? new Date(history.birthYear, history.birthMonth - 1, history.birthDay).toAbbrDateString(dateFormat, t) : null}</div>
                        </div>
                        <div className='col-xs-3 col-md-2'>
                            <div><label className='no-margin'>{t('Global:gender')}</label></div>
                            <div>{t(`Gender:${Gender[history.gender]}`)}</div>
                        </div>
                        <div className='col-xs-6 col-md-4'>
                            <label className='no-margin'>{t('Global:nickname')}</label>
                            <div>{history.nickname}</div>
                        </div>
                        <div className='col-xs-6 col-md-4'>
                            <div className='pull-left'>
                                {history.photographUrl ? <img src={history.photographUrl} style={{ maxWidth: '160px'}} /> : null }
                            </div>

                            <div className='pull-right'><button className='btn btn-primary' onClick={e => clickHandler(e, this.editCustomer)}>{t('Global:edit')}</button></div>
                        </div>
                    </div>
                    <div className='row row-m-t'>
                        <div className='col-xs-6 col-md-4'>
                            <div><label className='no-margin'>{t('customerDetails:address')}</label></div>
                            <div>{this.buildAddress(history)}</div>
                        </div>
                        <div className='col-xs-6 col-md-4'>
                            <label className='no-margin'>{t('customerDetails:companyName')}</label>
                            <div>{history.companyName}</div>
                        </div>
                        <div className='col-xs-6 col-md-4'>
                            <div><label className='no-margin'>{t('customerDetails:emergency contact')}</label></div>
                            <div>{history.emergencyContactName} {history.emergencyContactNumber}</div>
                        </div>
                    </div>
                    <div className='row row-m-t'>
                        <div className='col-xs-6 col-md-4'>
                            <div><label className='no-margin'>{t('customerDetails:phoneNumber')}</label></div>
                            <div>{history.phoneNumber}</div>
                        </div>
                        <div className='col-xs-6 col-md-4'>
                            <div><label className='no-margin'>{t('customerDetails:emailAddress')}</label></div>
                            <div>{(isNullOrEmpty(history.emailAddress) ? null : <a href={`mailto:${history.emailAddress}`}>{history.emailAddress}</a> )}</div>
                        </div>
                        <div className='col-xs-6 col-md-4'>
                            <div><label className='no-margin'>{t('Global:tags')}</label></div>
                            <TagList tags={history.tags} />
                        </div>
                    </div>
                    <div className='row row-m-t'>
                        <div className='col-xs-6 col-md-4'>
                            <div><label className='no-margin'>{t('customerDetails:marketingPreference')}</label></div>
                            <div><MarketingPreferences preference={history.marketingPreference} customerId={customerId} /></div>
                        </div>
                        <div className='col-xs-6 col-md-4'>
                            <div><label className='no-margin'>{t('customerDetails:resultsPreference')}</label></div>
                            <div><MarketingPreferences preference={history.resultsPreference} customerId={customerId} /></div>
                        </div>
                        <div className='col-xs-6 col-md-4'>
                            <div><label className='no-margin'>{t('Global:publicResultsConsent')}</label></div>
                            <div>{history.publicResultsConsent ? <span className='label label-success'>{t('Global:yes')}</span> : <span className='label label-danger'>{t('Global:no')}</span>}</div>
                        </div>
                    </div>
                    <div className='row row-m-t'>
                        <div className='col-xs-12'>
                            <div><label className='no-margin'>{t('customerDetails:credit')}</label></div>
                            <div className='formatted-text lead'>{`${t('Global:currencySymbol')}${history.creditAmount.toFixed(2)}`}<button className='btn btn-link' onClick={e => clickHandler(e, this.viewCredit)}>{t('Global:view')}</button></div>
                        </div>
                    </div>
                    <div className='row row-m-t'>
                        <div className='col-xs-12'>
                            <div><label className='no-margin'>{t('customerDetails:notes')}</label></div>
                            <pre className='formatted-text'>{history.notes}</pre>
                        </div>
                    </div>
                </div>
            </section>

            <section className='panel panel-default'>
                <div className='panel-heading'>
                    <h4 className='no-margin'>{t('customerDetails:memberships')}</h4>
                </div>
                <div className='panel-body'>
                    {this.renderMemberships(history.memberships)}
                </div>
            </section>

            <section className='panel panel-default'>
                <div className='panel-heading'>
                    <div className='row'>
                        <div className='col-xs-8'>
                            <h4 className='no-margin'>{t('customerDetails:events')}</h4>
                        </div>
                        <div className='col-xs-4 text-right'>
                            <button className='btn btn-primary' onClick={e => clickHandler(e, this.newBooking)}>{t('customerDetails:newBooking')}</button>
                        </div>
                    </div>
                </div>
                <div className='panel-body'>
                    {this.renderEvents(history.events)}
                </div>
            </section>

            <section className='panel panel-default'>
                <div className='panel-heading'>
                    <h4 className='no-margin'>{t('customerDetails:registrations')}</h4>
                </div>
                <div className='panel-body'>
                    {this.renderRegistrations(history.registrations)}
                </div>
            </section>

            <section className='panel panel-default'>
                <div className='panel-heading'>
                    <h4 className='no-margin'>{t('customerDetails:results')}</h4>
                </div>
                <div className='panel-body'>
                    {this.renderSessionResults(history.sessionResults)}
                </div>
            </section>

            <section className='panel panel-default'>
                <div className='panel-heading'>
                    <h4 className='no-margin'>{t('customerDetails:payments')}</h4>
                </div>
                <div className='panel-body'>
                    {this.renderPayments(history.payments)}
                </div>
            </section>

            <section className='panel panel-default'>
                <div className='panel-heading'>
                    <h4 className='no-margin'>{t('customerDetails:emails')}</h4>
                </div>
                <div className='panel-body'>
                    {this.renderEmails(history.emails)}
                </div>
            </section>

            <section className='panel panel-default'>
                <div className='panel-heading'>
                    <h4 className='no-margin'>{t('customerDetails:mergeHistory')}</h4>
                </div>
                <div className='panel-body'>
                    <table className='table table-condensed'>
                        <tbody>
                            {history.mergeHistory.map(mh => <tr key={mh.id}>
                                <td><span className='label label-default'>{mh.mergeIndex}</span></td>
                                <td>{mh.whenMerged.toAbbrDateString(dateFormat, t)}</td>
                                <td>{`${mh.firstname} ${mh.lastname}`}</td>
                                <td>{`${mh.addressLine1} ${mh.postalCode}`}</td>
                                <td>{mh.emailAddress}</td>
                                <td>{mh.mergedBy}</td>
                                <td className='text-right'><button className='btn btn-warning btn-sm' onClick={e => clickHandler(e, () => this.undoMerge(mh))}>{t('customerDetails:undoMerge')}</button></td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>
            </section>

            <section className='panel panel-default'>
                <div className='panel-heading'>
                    <h4 className='no-margin'>{t('customerDetails:auditLog')}</h4>
                </div>
                <div className='panel-body'>
                    <ul className='list-unstyled'>
                        {history.auditLog.sort((l1, l2) => l1.time.getTime() - l2.time.getTime()).map((l, ix) => <AuditLogEntry key={ix} logEntry={l} timeFormat={timeFormat} dateFormat={dateFormat} />)}
                    </ul>
                </div>
            </section>

            <div className='row row-m-t'>
                <div className='col-xs-12'>
                    <div className='small'>{t('Global:createdBy')} {history.createdBy} - {history.createDateTime.toShortDateString(dateFormat)} {history.createDateTime.toShortTimeString(timeFormat)}</div>
                </div>
            </div>


            <div className='row row-m-t'>
                <div className='col-xs-12'><button className='btn btn-primary' onClick={this.close}>{t('Global:close')}</button></div>
            </div>

            <div className='row row-m-t'></div>
        </div>
    }

    buildAddress = (history: CustomerHistory) => {
        const addressParts = [history.addressLine1, history.addressLine2, history.addressLine3, history.addressLine4, history.town, history.county, history.postalCode];
        return addressParts.filter(x => !isNullOrEmpty(x)).join(", ");
    }

    getMergeLabel = (customerId: string) => {
        const { history } = this.state;
        if (!history) return null;

        const matches = history.mergeHistory.filter(h => h.customerId === customerId);
        return matches.length > 0 ? <span className='label label-default'>{matches[0].mergeIndex}</span> : null
    }

    renderMemberships = (memberships: CustomerMembership[]) => {
        const { t } = this.context;
        const { venue } = this.props

        if (memberships.length === 0) {
            return <div>{t('customerDetails:noMemberships')}</div>
        }

        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;

        return (
            <table className='table table-condensed'>
                <thead>
                    <tr>
                        <th>{t('customerDetails:membership')}</th>
                        <th>{t('customerDetails:membershipNumber')}</th>
                        <th>{t('customerDetails:membershipType')}</th>
                        <th className='text-right'>{t('customerDetails:purchaseDate')}</th>
                        <th className='text-right'>{t('customerDetails:expiryDate')}</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {memberships.map(m => (
                        <tr key={m.membershipid}>
                            <td className='text-left'><TagLabel colour={m.tagColour} name={`${m.tagName} ${m.membershipNumber}`} /></td>
                            <td className='text-left'><Link to={`/membership/${m.membershipid}`} >{m.membershipNumber}</Link></td>
                            <td className='text-left'>{m.membershipType}</td>
                            <td className='text-right'>{m.createDateTime.toAbbrDateString(dateFormat, t)}</td>
                            <td className='text-right'>{m.expiry ? m.expiry.toAbbrDateString(dateFormat, t) : null}</td>
                            <td>{!m.isActive ? <label className='label label-danger'>{t('Global:expired')}</label> : null}</td>
                            <td>{this.getMergeLabel(m.customerId)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

        );
    }

    renderEvents = (events: CustomerEvent[]) => {
        const { t } = this.context;
        const { selectedVenueId, isMultiVenue, venue } = this.props

        if (events.length === 0) {
            return <div>{t('customerDetails:noEvents')}</div>
        }

        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;

        return (
            <table className='table table-condensed'>
                <thead>
                    <tr>
                        <th>{t('customerDetails:eventNameHeading')}</th>
                        {isMultiVenue ? <th>{t('customerDetails:venueHeading')}</th> : null}
                        <th className='text-right'>{t('customerDetails:eventDate')}</th>
                        <th></th>
                        <th className='text-center'>{t('customerDetails:eventOrganiser')}</th>
                        <th className='text-center'>{t('customerDetails:eventBillNumber')}</th>
                        <th className='text-right'>{t('customerDetails:eventBillAmount')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {events.map(e => (
                        <tr key={e.eventId}>
                            <td>{e.venueId === selectedVenueId ? <Link to={{ pathname: `/diary/${e.venueId}/event/${e.eventId}`, state: { bookingId: e.bookingId }}}>{(isNullOrEmpty(e.eventName) ? t('Global:view') : e.eventName)}</Link> : e.eventName}</td>
                            {isMultiVenue ? <td>{e.venueName}</td> : null}
                            <td className='text-right'>{e.eventDate.toAbbrDateString(dateFormat, t)} {e.eventDate.toShortTimeString(timeFormat)}</td>
                            <td>{e.wasCancelled || e.wasDeleted ? <label className='label label-danger'>{t(`Global:${e.wasCancelled ? 'cancelled' : 'deleted'}`)}</label> : null}</td>
                            <td className='text-center'>{e.isOrganiser ? <span className='label label-success'>{t('Global:organiser')}</span> : null}</td>
                            <td className='text-center'>{e.billNumber}</td>
                            <td className='text-right'>{e.billAmount ? <span>{`${t('Global:currencySymbol')}${e.billAmount.toFixed(2)}`}</span> : null}</td>
                            <td>{this.getMergeLabel(e.customerId) }</td>
                        </tr>
                        ))}
                </tbody>
            </table>
            
            );
    }

    renderRegistrations = (registrations: CustomerRegistration[]) => {
        const { t } = this.context;
        const { venue } = this.props;

        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;

        if (registrations.length === 0) {
            return <div>{t('customerDetails:noRegistrations')}</div>
        }

        const groupedRegistrations = registrations.reduce<Map<string, CustomerRegistration[]>>((grp, reg) => {
            const registrations = grp.get(reg.registrationId) || [];
            registrations.push(reg);
            grp.set(reg.registrationId, registrations);
            return grp;
        }, new Map<string, CustomerRegistration[]>());

        return (
            <table className='table table-condensed'>
                <thead>
                    <tr>
                        <th>{t('customerDetails:registrationTime')}</th>
                        <th>{t('customerDetails:registrationEvent')}</th>
                        <th className='text-right'>{t('customerDetails:registrationEventDate')}</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.from(groupedRegistrations.values()).map(registrationGroup => {
                        const r = registrationGroup[0];
                        return <tr key={`${r.eventId}_${r.registrationId}`}>
                            <td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.viewRegistration(r))}>{r.registrationTime.toAbbrDateString(dateFormat, t)} {r.registrationTime.toShortTimeString(timeFormat)}</button></td>
                            <td>{registrationGroup.map(reg => <div>{reg.eventName ? reg.eventName : ''}</div>)}</td>
                            <td className='text-right'>{registrationGroup.map(reg => <div>{reg.eventDate ? reg.eventDate.toAbbrDateTimeString(timeFormat, dateFormat, t) : ''}</div>)}</td>
                            <td>{r.cancelled ? <span className='label label-danger'>{t('Global:cancelled')}</span> : null}</td>
                            <td>{r.customFields.map(f => <div key={f.customFieldName}><label>{f.customFieldName}</label>: {f.value}</div>)}</td>
                            <td>{this.getMergeLabel(r.customerId)}</td>
                        </tr>
                    })}
                </tbody>
            </table>

        );
    }

    renderPayments = (payments: CustomerPayment[]) => {
        const { t } = this.context;

        if (payments.length === 0) {
            return <div>{t('customerDetails:noPayments')}</div>
        }

        return (
            <table className='table table-condensed'>
                <thead>
                    <tr>
                        <th>{t('customerDetails:billNumberHeading')}</th>
                        <th>{t('customerDetails:paymentDescriptionHeading')}</th>
                        <th className='text-right'>{t('customerDetails:paymentAmountHeading')}</th>
                        <th>{t('customerDetails:paymentStatusHeading')}</th>
                        <th>{t('customerDetails:paymentMethodHeading')}</th>
                        <th className='text-right'>{t('customerDetails:paymentInfoHeading')}</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {payments.sort((a, b) => {
                        const aDate = a.payentTakenDateTime || a.voidedDateTime || a.paymentDueDate;
                        const bDate = b.payentTakenDateTime || b.voidedDateTime || b.paymentDueDate;
                        return (!aDate && !bDate)
                            ? 0
                            : !bDate || (aDate && aDate < bDate)
                                ? 1 : -1;
                    }).map(p => (
                        <tr key={p.paymentId}>
                            <td>{p.billNumber}</td>
                            <td>{p.description}</td>
                            <td className='text-right'>{<span>{`${t('Global:currencySymbol')}${p.amount.toFixed(2)}`}</span>}</td>
                            <td>{this.renderPaymentStatus(p)}</td>
                            <td>{!isNullOrEmpty(p.paymentMethod) ? p.paymentMethod : null}</td>
                            <td className='text-right'>{this.renderPaymentInfo(p)}</td>
                            <td className='text-right'>{this.renderPaymentAdditionalInfo(p)}</td>
                            <td>{this.getMergeLabel(p.customerId)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    renderPaymentStatus = (payment: CustomerPayment) => {
        const { t } = this.context;

        if (payment.status === PaymentStatus.None) {
            return null;
        } 

        let cls = '';

        switch (payment.status) {
            case PaymentStatus.Failed:
            case PaymentStatus.GatewayInProgress:
                cls = 'text-danger';
                break;
            case PaymentStatus.Success:
                cls = 'text-success';
                break;
        }

        return <span className={cls}>{t(`PaymentStatus:${PaymentStatus[payment.status]}`)}</span>
    }

    renderEmails = (emails: CustomerEmail[]) => {
        const { t } = this.context;
        const { venue } = this.props;

        if (emails.length === 0) {
            return <div>{t('customerDetails:noEmails')}</div>
        }

        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;

        return (
            <table className='table table-condensed'>
                <thead>
                    <tr>
                        <th>{t('customerDetails:emailTimeHeading')}</th>
                        <th>{t('customerDetails:emailToHeading')}</th>
                        <th>{t('customerDetails:emalTemplateNameHeading')}</th>
                        <th>{t('customerDetails:emailSubjectHeading')}</th>
                        <th>{t('customerDetails:emailStatusHeading')}</th>
                        <th>{t('customerDetails:emailSentHeading')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {emails.sort((e1, e2) => e2.timeQueued.getTime() - e1.timeQueued.getTime()).map(e => (
                        <tr key={e.emailId}>
                            <td><button className='btn btn-link btn-no-padding' onClick={ev => clickHandler(ev, () => this.viewEmailDetail(e.emailId))}>{e.timeQueued.toAbbrDateString(dateFormat, t)} {e.timeQueued.toShortTimeString(timeFormat)}</button></td>
                            <td>{e.toAddress}</td>
                            <td>{e.templateName}</td>
                            <td>{e.subject}</td>
                            <td><span className={this.buildStatusLabelClass(e.status)}>{t(`EmailStatus:${EmailStatus[e.status]}`)}</span></td>
                            <td>{e.timeSent ? `${e.timeSent.toAbbrDateString(dateFormat, t)} ${e.timeSent.toShortTimeString(timeFormat)}` : ''}</td>
                            <td>{this.getMergeLabel(e.customerId)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

        );
    }

    renderPaymentInfo = (payment: CustomerPayment) => {
        const { t } = this.context;
        const { venue } = this.props;
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;

        if (payment.deleted) {
            return <div><span className='label label-danger'>{t('Global:deleted')}</span> {payment.deletedDateTime ? payment.deletedDateTime.toAbbrDateString(dateFormat, t) : ''}</div>
        }

        if (payment.void) {
            return <div><span className='label label-danger'>{t('PointOfSale:paymentVoid')}</span> {payment.voidedDateTime ? payment.voidedDateTime.toAbbrDateString(dateFormat, t) : ''}</div>
        }

        if (payment.paid) {
            return <div><span className='label label-success'>{t('PointOfSale:paid')}</span> {payment.payentTakenDateTime ? payment.payentTakenDateTime.toAbbrDateString(dateFormat, t) : ''}</div>
        }

        if (payment.scheduled && payment.status !== PaymentStatus.Success) {
            if (payment.paymentDueDate && payment.paymentDueDate < new Date()) {
                return <div><span className='label label-danger'>{t('PointOfSale:overdue')}</span> {payment.paymentDueDate ? payment.paymentDueDate.toAbbrDateString(dateFormat, t) : ''}</div>
            } else {
                return <div><span className='label label-info'>{t('PointOfSale:due')}</span> {payment.paymentDueDate ? payment.paymentDueDate.toAbbrDateString(dateFormat, t) : ''}</div>
            }
        }
    }

    renderPaymentAdditionalInfo = (payment: CustomerPayment) => payment.additionalInfo ? payment.additionalInfo.map(i => <span className='label label-info'>{i}</span>) : ''

    renderSessionResults = (results: CustomerSessionResult[]) => {
        const { t } = this.context;
        const { venue } = this.props;

        if (results.length === 0) {
            return <div>{t('customerDetails:noResults')}</div>
        }

        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;

        return (
            <table className='table table-condensed'>
                <thead>
                    <tr>
                        <th>{t('customerDetails:resultsDate')}</th>
                        <th>{t('customerDetails:resultsTrack')}</th>
                        <th>{t('customerDetails:resultsSession')}</th>
                        <th className='text-right'>{t('customerDetails:resultsPosition')}</th>
                        <th className='text-right'>{t('customerDetails:resultsLaps')}</th>
                        <th className='text-right'>{t('customerDetails:kartNumber')}</th>
                        <th className='text-right'>{t('customerDetails:resultsBestLap')}</th>
                        <th className='text-right'>{t('customerDetails:resultsAverageLap')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {results.map((r, ix) => (
                        <tr key={ix}>
                            <td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.showResultsDetail(r))}>{r.sessionStartTime ? r.sessionStartTime.toAbbrDateTimeString(timeFormat, dateFormat, t) : ''}</button></td>
                            <td>{r.trackName}</td>
                            <td>{r.sessionName}</td>
                            <td className='text-right'>{r.position}</td>
                            <td className='text-right'>{r.numberOfLaps}</td>
                            <td className='text-right'>{r.initialKartNumber}</td>
                            <td className='text-right'>{formatLaptime(r.bestLapTime)}</td>
                            <td className='text-right'>{r.averageLapTime ? formatLaptime(r.averageLapTime) : null}</td>
                            <td>{this.getMergeLabel(r.customerId)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    buildStatusLabelClass = (status: EmailStatus) => {
        switch (status) {
            case EmailStatus.Created:
                return 'label label-default';
            case EmailStatus.Queued:
                return 'label label-warning';
            case EmailStatus.Processing:
                return 'label label-info';
            case EmailStatus.Sent:
                return 'label label-success';
            case EmailStatus.Failed:
                return 'label label-danger';
            case EmailStatus.Cancelled:
                return 'label label-default';
        }
    }
}

const matStateToProps = (state: ApplicationState) => {
    const venue = state.venues.venues.find(v => v.id === state.venues.selectedVenueId);
    return {
        venue: venue,
        selectedVenueId: state.venues.selectedVenueId,
        defaultCountryId: venue ? venue.countryId : 240,
        isMultiVenue: state.venues.venues.length > 1,
        timeFormat: state.venues.timeFormat,
        dateFormat: state.venues.dateFormat
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
    logout: bindActionCreators(LoginActions.actionCreators.logout, dispatch),
});

export default connect(matStateToProps, mapDispatchToProps)(CustomersDetailsPage);

