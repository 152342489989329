
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { ITranslationContext } from '../../translations';
import { VenuePublicWebsite } from '../../store/pages/venues/types';
import PublicLink from './publicLink';

interface PublicsLinkProps {
    publicWebsites: VenuePublicWebsite[];
    urlPath: string;
}

const publicLinks = (props: PublicsLinkProps, context: ITranslationContext) => {
    return <>{props.publicWebsites
        .map(pw => <PublicLink publicWebsite={pw} urlPath={props.urlPath} />)
    }
    </>
}

publicLinks.contextTypes = {
    t: PropTypes.func
}
export default publicLinks;