
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { ActivityFormat } from '../../../store/pages/activityFormats/types';
import * as dt from '../../../store/pages/diary/types';
import { formatBgColour, clickHandler } from '../../../utils/util';
import DiaryReservationGap, { GapPosition } from './diaryReservationGap'
import ReservationInfoLabel from './reservationInfoLabel'
import Flag from '../../icons/flag';
import { Venue } from '../../../store/pages/venues/types';
import { MembershipType } from '../../../store/pages/memberships/types';
import TagLabel from '../../global/tagLabel';

interface DiaryReservationProps {
    slotHeight: number;
    venue: Venue;
    date: Date;
    startTime: number;
    slotSizeInMinutes: number;
    eventStatus: dt.EventStatus;
    outstandingAmount: number;
    paymentDueDate: Date | null;
    overdueAmount: number;
    overdueDate: Date | null;
    outstandingDepositAmount: number;
    depositDueDate: Date | null;
    reservation: dt.DiaryReservation;
    activityFormat?: ActivityFormat;
    width: string;
    left: string;
    maxEndTime: number;
    reservationSelected: (reservationId: string, eventId: string) => void;
}

class DiaryReservation extends React.Component<DiaryReservationProps, {}> {

    static contextTypes = {
        t: PropTypes.func
    }

    // Need to prevent propagation, otherwise the resource component will pick up the mouseup and 
    // open a new event rather than editing this one
    cancelEvent = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    }

    renderStatusLabel = (status: dt.EventStatus, draft: boolean, deleted: boolean, cancelled: boolean, archived: boolean, reservationType: dt.ReservationType) => {
        const { t } = this.context;
        if (status === dt.EventStatus.Cancelled || cancelled) {
            return <span className="label label-danger pull-right">{t('EventStatus:Cancelled')}</span>;
        } else if (deleted || archived) {
            return <span className="label label-danger pull-right">{t('Global:deleted')}</span>;
        } else if (draft && reservationType === dt.ReservationType.Exclusive) {
            return <span className="label label-default pull-right">{t('EventStatus:Draft')}</span>;
        } else if (status === dt.EventStatus.Provisional && reservationType === dt.ReservationType.Exclusive) {
            return <span className="label label-default pull-right">{t('EventStatus:Provisional')}</span>;
        } else if (status === dt.EventStatus.Confirmed && reservationType === dt.ReservationType.Exclusive) {
            return <span className="label label-success pull-right">{t('EventStatus:Confirmed')}</span>;
        }

        return null;
    }

    renderPaymentDate = (date: Date | null) => {
        const { t } = this.context;
        const { venue } = this.props;

        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

        if (!date) {
            return null;
        }

        const lblCls = `label label-${date < today ? 'danger' : 'info'}`;
        return <span className={lblCls}>{date.toAbbrDateString(venue.dateFormat, t)}</span>;
    }

    renderAmounts = (outstandingAmount: number, paymentDueDate: Date | null, overdueAmount: number, overdueDate: Date | null, outstandingDepositAmount: number, depositDueDate: Date | null) => {
        const { t } = this.context;

        if (outstandingDepositAmount > 0) {
            return <div className='small text-info'><span>{t('PointOfSale:deposit')} {t('Global:currencySymbol')}{outstandingDepositAmount.toFixed(2)}</span> {this.renderPaymentDate(depositDueDate)}</div>
        }

        if (overdueAmount > 0) {
            return <div className='small text-info'><span>{t('PointOfSale:overdue')} {t('Global:currencySymbol')}{overdueAmount.toFixed(2)}</span> {this.renderPaymentDate(overdueDate)}</div>
        }

        if (outstandingAmount > 0) {
            return <div className='small text-info'><span>{t('Global:due')} {t('Global:currencySymbol')}{outstandingAmount.toFixed(2)}</span> {this.renderPaymentDate(paymentDueDate)}</div>
        }

        return null;

        //t('Global:currencySymbol')
        //outstandingAmount.toFixed(2)
        //t('DiaryReservation:outstanding')
    }

    renderActivity = (activityFormat?: ActivityFormat) => {
        const activity = activityFormat ? <div>{activityFormat.name}</div> : null;

        return <div className='small text-info'>
            {activity}
        </div>;
    }

    reservationSelected = (reservation: dt.IReservation) => {
        if (reservation.notSelectable) return;
        this.props.reservationSelected(reservation.id || '', reservation.eventId)
    }

    adornReservation = (reservation: dt.IReservation, pixelsPerMinute: number, left: string, width: string, reservationTop: number, reservationHeight: number, content: JSX.Element) => {
        if (reservation.minGapBefore === 0 && reservation.minGapAfter === 0)
            return content;

        const gapBefore = reservation.minGapBefore && reservation.minGapBefore > 0 ? <DiaryReservationGap position={GapPosition.Before} gapInMinutes={reservation.minGapBefore} pixelsPerMinute={pixelsPerMinute} left={left} width={width} reservationTop={reservationTop} reservationHeight={reservationHeight} /> : null
        const gapAfter = reservation.minGapAfter && reservation.minGapAfter > 0 ? <DiaryReservationGap position={GapPosition.After} gapInMinutes={reservation.minGapAfter} pixelsPerMinute={pixelsPerMinute} left={left} width={width} reservationTop={reservationTop} reservationHeight={reservationHeight} /> : null

        return <>
            {gapBefore}
            {content}
            {gapAfter}
        </>
    }

    render() {
        const { slotSizeInMinutes, slotHeight, date, startTime, width, left, reservation, activityFormat, eventStatus, outstandingAmount, paymentDueDate, overdueAmount,
            overdueDate, outstandingDepositAmount, depositDueDate, maxEndTime } = this.props;

        const startTimeInMins = Math.max(dt.getReservationStartTimeInMinutes(reservation, date), startTime * 60);
        const endTimeInMins = Math.min(dt.getReservationEndTimeInMinutes(reservation, date), maxEndTime * 60);
        const pixelsPerMinute = slotHeight / slotSizeInMinutes;
        const top = (startTimeInMins * pixelsPerMinute) - (startTime * 60 * pixelsPerMinute);
        const height = Math.max(10, (endTimeInMins - startTimeInMins)) * (slotHeight / slotSizeInMinutes); // Ensure resevation has a height
        const style = { top: `${top}px`, left: left, width: width, height: `${height}px`, borderLeftColor: reservation.colour, background: reservation.draft ? '#d8d8d8' : 'white' }

        const statusLabel = this.renderStatusLabel(eventStatus, reservation.draft, reservation.deleted, reservation.cancelled, reservation.archived, reservation.reservationType);
        const flag = reservation.flagged ? <span className='pull-right'><Flag width={20} height={20} colour='#d9534f' /></span> : null;
        const memberRestriction = reservation.membershipTypeLabel && reservation.membershipTypeLabelColour ? <span className='pull-right'><TagLabel colour={reservation.membershipTypeLabelColour} name={reservation.membershipTypeLabel} /></span> : null;

        const eventName = reservation.resourceConfigurationCode ? `${reservation.resourceConfigurationCode} - ${reservation.eventName}` : reservation.eventName;

        const bookedParticipants = reservation.bookedParticipants.reduce((ttl, cp) => ttl + cp.count, 0);
        const confirmedParticipants = reservation.confirmedParticipants.reduce((ttl, cp) => ttl + cp.count, 0);
        const unconfirmedParticipants = reservation.unconfirmedParticipants.reduce((ttl, cp) => ttl + cp.count, 0);
        const registeredParticipants = reservation.registeredParticipants ? reservation.registeredParticipants.reduce((ttl, cp) => ttl + cp.count, 0) : 0;
        const checkedInParticipants = reservation.checkedInParticipants ? reservation.checkedInParticipants.reduce((ttl, cp) => ttl + cp.count, 0) : 0;

        const colour = reservation.notSelectable ? '#cccccc' : reservation.colour;

        if (reservation.reservationType === dt.ReservationType.NonExclusive) {
            const confirmedPercentage = confirmedParticipants === 0 ? 0 : (confirmedParticipants / reservation.maxParticipants * 100);
            const unconfirmedPercentage = unconfirmedParticipants === 0 ? 0 : (unconfirmedParticipants / reservation.maxParticipants * 100);
            const startColour = formatBgColour(colour, 0.6);
            const toColour = formatBgColour(colour, 0.2);

            const confirmedDiv = confirmedParticipants > 0 ? <div style={({ backgroundColor: startColour, position: 'absolute', left: '0px', top: '0px', bottom: '0px', width: `${confirmedPercentage}%` })}></div> : null;
            const unconfirmedPercentageDiv = unconfirmedParticipants > 0 ? <div style={({ background: `repeating-linear-gradient(-55deg, ${toColour}, ${toColour} 10px, ${startColour} 10px, ${startColour} 20px)`, position: 'absolute', left: `${confirmedPercentage}%`, top: '0px', bottom: '0px', width: `${unconfirmedPercentage}%` })}></div> : null;
            const outerStyle = { ...style}

            return this.adornReservation(reservation, pixelsPerMinute, left, width, top, height, <div className='cal-reservation' style={outerStyle} onClick={e => clickHandler(e, () => this.reservationSelected(reservation))} onMouseDown={this.cancelEvent}>
                <div className='cal-reservation-content' style={({ background: toColour, cursor: reservation.notSelectable ? 'not-allowed' : 'pointer' })}>
                    {confirmedDiv}
                    {unconfirmedPercentageDiv}
                    <div className='cal-reservation-heading'>
                        {statusLabel}
                        {flag}
                        {memberRestriction}
                        <span className='cal-reservation-text'>{eventName}</span>
                        <div className='cal-reservation-counts'>
                            <ReservationInfoLabel quantity={bookedParticipants} totalQuantity={reservation.maxParticipants} icon='user' showWhenComplete={false} />
                            <ReservationInfoLabel quantity={registeredParticipants || 0} totalQuantity={bookedParticipants} icon='list-alt' showWhenComplete={true} />
                            <ReservationInfoLabel quantity={checkedInParticipants || 0} totalQuantity={bookedParticipants} icon='check' showWhenComplete={true} />
                        </div>
                    </div>
                </div>
            </div>)
        } else {
            return this.adornReservation(reservation, pixelsPerMinute, left, width, top, height, 
                <div className='cal-reservation' style={style} onClick={e => clickHandler(e, () => this.reservationSelected(reservation))} onMouseDown={this.cancelEvent}>
                    <div className='cal-reservation-content' style={({ cursor: reservation.notSelectable ? 'not-allowed' : 'pointer' })}>
                        <div className='cal-reservation-heading'>
                            {statusLabel}
                            {flag}
                            <span className='cal-reservation-text'>{eventName}</span>
                            <div className='cal-reservation-counts'>
                                <ReservationInfoLabel totalQuantity={bookedParticipants} icon='user' showWhenComplete={false} />
                                <ReservationInfoLabel quantity={registeredParticipants || 0} totalQuantity={bookedParticipants} icon='list-alt' showWhenComplete={true} />
                                <ReservationInfoLabel quantity={checkedInParticipants || 0} totalQuantity={bookedParticipants} icon='check' showWhenComplete={true} />
                            </div>
                        </div>

                        {this.renderActivity(activityFormat)}

                        <div>{this.renderAmounts(outstandingAmount, paymentDueDate, overdueAmount, overdueDate, outstandingDepositAmount, depositDueDate)}</div>
                    </div>
                </div>
            )
        }
    }
}

export default DiaryReservation;