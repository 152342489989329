
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'

import { ApplicationState } from '../../../store';
import * as ct from '../../global/controls';

import * as api from '../../../store/apiClient';
import * as it from '../../../store/pages/integrations/types';
import * as IntegrationActions from '../../../store/pages/integrations/actions';
import * as ModalActions from '../../../store/global/modal/actions';
import IntegrationForm from './integrationForm';
import Loading from '../../global/loading';
import { clickHandler } from '../../../utils/util';
import ApiError from '../../global/apiError';


interface LocalProps { }

interface LocalState {
    integrations: it.Integration[];
    isLoading: boolean;
    loadError: api.ApiError | null;
}

interface Actions {
    loadIntegrations: () => void;
    editIntegration: () => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
}

type RoutedLocalProps = LocalProps & RouteComponentProps<{}>;

type IntegrationsPageProps = LocalState & Actions & RoutedLocalProps;

interface IntegrationsPageState {
    showArchived: ct.FormValue<boolean>;
}

class IntegrationsPage extends React.Component<IntegrationsPageProps, IntegrationsPageState> {

    constructor(props: IntegrationsPageProps) {
        super(props);

        this.state = { showArchived: ct.asFormValue('showArchived', false) }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private editIntegration = (integration: it.Integration) => {
        this.showOverlay(integration, false);
    }

    private addIntegration = () => {
        this.showOverlay(null, true);
    }

    private showOverlay = (integration: it.Integration | null, addingNew: boolean) => {
        this.props.editIntegration();
        this.props.showModal(<IntegrationForm isNew={addingNew} integration={integration ? integration : null} />, 'IntegrationForm');
    }

    render() {

        const { isLoading, loadError } = this.props;
        const { showArchived } = this.state;
        const { t } = this.context;

        const body = isLoading ? <Loading /> : this.renderIntegrationsTable();

        return <section className='integrationsPage'>
            <header className='section-header'>
                <div className='page-heading'>
                    <h1 className='integrations_title'>{t('IntegrationsPage:title')}</h1>
                </div>
                <div className='section-actions right'>
                    <ct.Checkbox id='showArchived' labelKey='Global:showArchived' value={showArchived} callback={val => this.setState({ showArchived: ct.asFormValue('showArchived', val) })} style={({ display: 'inline-block' })} />
                    <button className='btn btn-info' onClick={e => clickHandler(e,  this.addIntegration)}>{t('IntegrationsPage:addIntegration')}</button>
                </div>
            </header>
            {body}
            <ApiError error={loadError} />
        </section>;
    }
    
    private renderIntegrationsTable() {
        const { t } = this.context;
        const { showArchived } = this.state;
        const integrations = this.props.integrations.filter(i => (showArchived.value || !i.archived)).map(i =>
            <tr key={i.id}>
                <td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.editIntegration(i))}>{i.name}</button></td>
                <td>{t(`IntegrationType:${(it.IntegrationType[i.integrationType] || '').toString()}`)}</td>
                <td>{i.implementationType === it.IntegrationImplementationType.None ? '' : t(`IntegrationImplType:${(it.IntegrationImplementationType[i.implementationType] || '').toString()}`)}</td>
                {showArchived.value ? <td className='text-center'>{i.archived ? t('Global:yes') : null}</td> : null}
            </tr>
        );

        return <table className='table table-condensed'>
            <thead>
                <tr key='integrations-header'>
                    <th>{t('IntegrationsPage:nameHeading')}</th>
                    <th>{t('IntegrationsPage:typeHeading')}</th>
                    <th>{t('IntegrationsPage:providerHeading')}</th>
                    {showArchived.value ? <th className='text-center'>{t('Global:archived')}</th> : null}
                </tr>
            </thead>
            <tbody>
                {integrations}
            </tbody>
        </table>;
    }
};


const matStateToProps = (state: ApplicationState) => ({
    integrations: state.integrations.integrations,
    isLoading: state.integrations.isLoading,
    loadError: state.integrations.loadError
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadIntegrations: bindActionCreators(IntegrationActions.actionCreators.loadIntegrations, dispatch),
    editIntegration: bindActionCreators(IntegrationActions.actionCreators.editIntegration, dispatch),
    showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    matStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps        // Selects which action creators are merged into the component's props
)(IntegrationsPage);

