import { ValidationError } from '../../global/types';
import * as api from '../../apiClient';

export enum SpecialTagType {
    None = 0,
    Membership = 1
}

export interface Tag {
    id: string;
    name: string;
    colour: string;
    specialTagType: SpecialTagType;
    membershipTypeId?: string | null;
    membershipId?: string | null;
    archived: boolean;
}

export interface TagInfo extends Tag {
    customerCount: number;
    productCount: number;
}

export interface TagState {
    isLoading: boolean;
    tags: TagInfo[];
    loadError: api.ApiError | null;
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
}

export enum TagActionTypes {
    RequestTags = 'REQUEST_TAGS',
    ReceivedTags = 'RECEIVE_TAGS',
    SaveTag = 'SAVE_TAG',
    TagSaved = 'TAG_SAVED',
    TagSaveFailed = 'TAG_SAVE_FAILED',
    TagChanged = 'TAG_CHANGED'
}

export interface LoadTags {
    type: TagActionTypes.RequestTags;
}

export interface ReceiveTags {
    type: TagActionTypes.ReceivedTags;
    tags: TagInfo[];
    error: api.ApiError | null;
}

export interface SaveTag {
    type: TagActionTypes.SaveTag;
    isNew: boolean;
    tagId?: string;
    name: string;
    colour: string;
    specialTagType: SpecialTagType;
    archived: boolean;
}

export interface TagSaved {
    type: TagActionTypes.TagSaved;
    tag: Tag;
}

export interface TagChanged {
    type: TagActionTypes.TagChanged;
    tagId: string;
}

export interface TagSaveFailed {
    type: TagActionTypes.TagSaveFailed;
    error: api.ApiError;
}
