
// https://blog.angularindepth.com/how-to-reduce-action-boilerplate-90dc3d389e2b

import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as dt from './types';
import * as rt from '../reception/types';
import { generateTempId, isNullOrEmpty, parseLocalDateTime } from '../../../utils/util';
import { Time } from '../../global/types';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { IStore } from '../..';

export type DiaryActions = dt.SwitchDate | dt.RequestEvents | dt.ReceivedEvents | dt.LoadDiaryNote | dt.ReceivedDiaryNote
    | dt.DiaryNoteChanged | dt.SaveDiaryNote | dt.DiaryNoteSaved | dt.DiaryNoteSaveFailed | dt.EventChanged | dt.SearchCustomers | dt.SearchCustomerResponse
    | dt.SetScrollPosition | dt.SaveOpeningTimes | dt.OpeningTimesSaved | dt.OpeningTimesSaveFailed;

export const actionCreators = {
    switchDate: (date: Date) => ({ type: dt.DiaryActionTypes.SwitchDate, date: date }),
    loadDiaryReservations: (venueId: string, date: Date) => ({ type: dt.DiaryActionTypes.RequestDiaryReservations, venueId: venueId, date: date }),
    loadDiaryReservationsComplete: (reservations: dt.DiaryReservation[], openingTimes: dt.OpeningTimes[], err: api.ApiError | null) => ({ type: dt.DiaryActionTypes.ReceivedDiaryReservations, reservations: reservations, openingTimes: openingTimes, error: err }),
    loadDiaryNote: (venueId: string, date: Date) => ({ type: dt.DiaryActionTypes.LoadDiaryNote, venueId: venueId, date: date }),
    loadDiaryNoteComplete: (venueId: string, date: Date, note: dt.DiaryNote | null, error: api.ApiError | null) => ({ type: dt.DiaryActionTypes.ReceivedDiaryNote, venueId: venueId, date: date, note: note, error: error }),
    eventChanged: (eventId: string, eventDate: Date | null) => ({ type: dt.DiaryActionTypes.EventChanged, eventId: eventId, eventDate: eventDate }),
    saveDiaryNote: (venueId: string, date: Date, text: string) => ({ type: dt.DiaryActionTypes.SaveDiaryNote, venueId: venueId, date: date, noteText: text }),
    diaryNoteSaved: (venueId: string, date: Date) => ({ type: dt.DiaryActionTypes.DiaryNoteSaved, venueId: venueId, date: date }),
    diaryNoteSaveFailed: (error: api.ApiError) => ({ type: dt.DiaryActionTypes.DiaryNoteSaveFailed, error: error }),
    diaryNoteChanged: (venueId: string, date: Date) => ({ type: dt.DiaryActionTypes.DiaryNoteChanged, venueId: venueId, date: date }),
    searchCustomers: (search: string) => ({ type: dt.DiaryActionTypes.SearchCustomers, search: search }),
    searchCustomersResponse: (customers: dt.CustomerSearchResult[], messageKey: string) => ({ type: dt.DiaryActionTypes.SearchCustomerResponse, customers: customers, messageKey: messageKey }),
    setScrollPosition: (top: number, left: number) => ({ type: dt.DiaryActionTypes.SetScrollPosition, left: left, top: top }),
    saveOpeningTimes: (venueId: string, date: Date, openingTimes: dt.OpeningTimes) => ({ type: dt.DiaryActionTypes.SaveOpeningTimes, venueId: venueId, date: date, openingTimes: openingTimes }),
    openingTimesSaved: (venueId: string, date: Date) => ({ type: dt.DiaryActionTypes.OpeningTimesSaved, venueId: venueId, date: date }),
    openingTimesSaveFailed: (error: api.ApiError) => ({ type: dt.DiaryActionTypes.OpeningTimesSaveFailed, error: error }),
    openingTimesChanged: (venueId: string, date: Date) => ({ type: dt.DiaryActionTypes.OpeningTimesChanged, venueId: venueId, date: date }),
}

export interface IGetEventsResponse {
    events: dt.Event[];
}

export interface IGetDiaryReservationsResponse {
    reservations: dt.DiaryReservation[];
    openingTimes: dt.OpeningTimes[];
}

interface ISaveEventResponse {
    eventId: string;
}

export interface ICustomerSearchResponse {
    customers: dt.CustomerSearchResult[];
}

interface ILoadDiaryNoteResponse {
    note: dt.DiaryNote;
}

export interface ILoadEventRegistrationsResponse {
    registrations: dt.EventRegistration[];
}

export interface ILoadEventRegistrationResponse {
    registration: dt.EventRegistration;
}

export const handleClientChange = (store: IStore) => {
    return [() => {
        if (store.value.diary.isActive) {
            return actionCreators.loadDiaryReservations(store.value.venues.selectedVenueId, store.value.diary.date)
        } else {
            return actionCreators.loadDiaryReservationsComplete([], [], null)
        }
    }];
}

//https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-after-catching-and-processing-an-error-with-emitting-somethi

const mapOpeningTimes = (times: any[]) => times.map(t => ({ ...t, date: parseLocalDateTime(t.date), open: Time.parse(t.open), close: Time.parse(t.close), resourceOverrides: t.resourceOverrides.map((r: any) => ({ ...r, open: Time.parse(r.open), close: Time.parse(r.close)})) }))

const loadDiaryReservations = (venueId: string, date: Date) => Observable.defer(() => api.getJson<IGetDiaryReservationsResponse>(`api/v1/venue/${venueId}/diaryReservations?from=${date.toYMDDateString()}&to=${date.toYMDDateString()}`))
    .map(response => actionCreators.loadDiaryReservationsComplete(response.reservations.map(r => ({...r, key: r.id || generateTempId() })), mapOpeningTimes(response.openingTimes), null));

export const switchDateEpic = (action$: ActionsObservable<any>, store: any) =>
    action$.ofType(dt.DiaryActionTypes.SwitchDate)
        .filter(() => {
            return store.value.venues.selectedVenueId && store.value.venues.selectedVenueId.length > 0
        })
        .switchMap(action => {
            return Observable.of(actionCreators.loadDiaryReservations(store.value.venues.selectedVenueId, action.date))
        });

export const loadReservationsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        dt.DiaryActionTypes.RequestDiaryReservations,
        action => loadDiaryReservations(action.venueId, action.date),
        err => actionCreators.loadDiaryReservationsComplete([], [], err));

export const reloadResourcesEpic = (action$: ActionsObservable<any>, store: any) =>
    epic.createDebounced(action$,
        dt.DiaryActionTypes.EventChanged,
        2000,
        action => {
            return loadDiaryReservations(store.value.venues.selectedVenueId, store.value.diary.date)
        },
        err => actionCreators.loadDiaryReservationsComplete([], [], err),
        (val, ix) => {
            return !val.eventDate || val.eventDate.isSameDay(store.value.diary.date)
        }
    );

export const loadDiaryNoteEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        dt.DiaryActionTypes.LoadDiaryNote,
        action => {
            const { venueId, date } = action;
            return Observable.defer(() => api.getJson<ILoadDiaryNoteResponse>(`api/v1/venue/${action.venueId}/diaryNotes/${action.date.toYMDDateString()}`))
                .map(response => actionCreators.loadDiaryNoteComplete(venueId, date, response.note, null));
        },
        (err, action) => actionCreators.loadDiaryNoteComplete(action.venueId, action.date, null, err));

export const reloadDiaryNoteEpic = (action$: ActionsObservable<any>, store: any) =>
    action$.ofType(dt.DiaryActionTypes.DiaryNoteChanged)
        .switchMap(action => {
            return Observable.of(actionCreators.loadDiaryNote(action.venueId, action.date));
        });

export const saveDiaryNoteEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        dt.DiaryActionTypes.SaveDiaryNote,
        action => {
            const sdn = action as dt.SaveDiaryNote;
            const { venueId, date } = sdn;
            var body = { noteText: sdn.noteText };

            return api.post(`api/v1/venue/${venueId}/diaryNotes/${date.toYMDDateString()}`, body)
                .map(response => actionCreators.diaryNoteSaved(venueId, date));
        },
        (err: api.ApiError) => actionCreators.diaryNoteSaveFailed(err));

export const saveOpeningTimesEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        dt.DiaryActionTypes.SaveOpeningTimes,
        action => {
            const sot = action as dt.SaveOpeningTimes;
            const { venueId, date, openingTimes } = sot;
            var body = {
                openingTimes: {
                    ...openingTimes,
                    date: openingTimes.date.toYMDDateString(),
                    open: openingTimes.open.toString(),
                    close: openingTimes.close.toString(),
                    resourceOverrides: openingTimes.resourceOverrides.map(ro => ({ ...ro, open: ro.open.toString(), close: ro.close.toString() }))
                }
            };

            return api.post(`api/v1/venue/${venueId}/openingTimes/${date.toYMDDateString()}`, body)
                .pipe(switchMap(data => of(actionCreators.openingTimesSaved(venueId, date), actionCreators.openingTimesChanged(venueId, date))));
        },
        (err: api.ApiError) => actionCreators.openingTimesSaveFailed(err));

export const openingTimesChangedEpic = (action$: ActionsObservable<any>, store: any) =>
    action$.ofType(dt.DiaryActionTypes.OpeningTimesChanged)
        .filter(action => {
            return store.value.venues.selectedVenueId === action.venueId && store.value.diary.date.isSameDay(action.date)
        })
        .switchMap(action => {
            return loadDiaryReservations(action.venueId, action.date);
        });

const searchCust = (search: string) => Observable.defer(() => api.getJson<ICustomerSearchResponse>(`api/v1/customer/search?searchTerm=${search}`))
    .map(response => actionCreators.searchCustomersResponse(response.customers, ''));

export const searchCustomersEpic = (action$: ActionsObservable<any>) =>
    epic.createDebounced(action$,
        dt.DiaryActionTypes.SearchCustomers,
        1000,
        action => isNullOrEmpty(action.search)
            ? Observable.from([1]).map(() => actionCreators.searchCustomersResponse([], ''))
            : action.search.length < 3 ? Observable.from([1]).map(() => actionCreators.searchCustomersResponse([], 'CustomerSearch:minThreeCharacters')): searchCust(action.search),
        err => actionCreators.searchCustomersResponse([], err.message));

export const registrationsChangedEpic = (action$: ActionsObservable<any>, store: any) =>
    epic.createDebounced(action$,
        rt.ReceptionActionTypes.RegistrationsChanged,
        2000, 
        action => Observable.of(actionCreators.loadDiaryReservations(store.value.venues.selectedVenueId, store.value.diary.date)),
        err => ({ type: 'IGNORE' }),
        () => store.value.diary.isActive);

export const customerCheckedInEpic = (action$: ActionsObservable<any>, store: any) =>
    epic.createDebounced(action$,
        rt.ReceptionActionTypes.CustomerCheckedIn,
        2000,
        action => Observable.of(actionCreators.loadDiaryReservations(store.value.venues.selectedVenueId, store.value.diary.date)),
        err => ({ type: 'IGNORE' }),
        () => store.value.diary.isActive);
