
import * as React from 'react';
import * as PropTypes from 'prop-types'

import Loading from '../../../global/loading';
import { clickHandler } from '../../../../utils/util';
import { RegistrationTerms, BookingTerms, WebShopPurchaseTerms } from '../../../../store/pages/termsAndConditions/types';
import EditRegistrationTerms from './editRegistrationTerms';
import EditBookingTerms from './editBookingTerms';
import EditWebShopPurchaseTerms from './editWebShopPurchaseTerms';

interface TermsAndConditionsListProps {
    venueId: string;
    registrationTerms: RegistrationTerms[];
    isLoadingRegistrationTerms: boolean;
    bookingTerms: BookingTerms[];
    isLoadingBookingTerms: boolean;
    webShopPurchaseTerms: WebShopPurchaseTerms[];
    isLoadingWebShopPurchaseTerms: boolean;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
}

class TermsAndConditionsList extends React.Component<TermsAndConditionsListProps, {}> {

    static contextTypes = {
        t: PropTypes.func
    }

    editRegistrationSettings = (terms: RegistrationTerms | null) => this.showOverlay(<EditRegistrationTerms venueId={this.props.venueId} terms={terms} />, 'EditRegistrationTerms');
    editBookingSettings = (terms: BookingTerms | null) => this.showOverlay(<EditBookingTerms venueId={this.props.venueId} terms={terms} />, 'EditBookingTerms');
    editWebShopPurchaseTermsSettings = (terms: WebShopPurchaseTerms | null) => this.showOverlay(<EditWebShopPurchaseTerms venueId={this.props.venueId} terms={terms} />, 'EditWebShopPurchaseTerms');
        
    showOverlay = (component: JSX.Element, screenName: string) => {
        this.props.showModal(component, screenName);
    }

    isLoading = () => this.props.isLoadingRegistrationTerms || this.props.isLoadingBookingTerms;

    render() {
        const body = this.isLoading() ? <Loading /> : <>{this.renderRegistrationTerms(this.props.registrationTerms)}{this.renderBookingTerms(this.props.bookingTerms)}{this.renderWebShopPurchaseTerms(this.props.webShopPurchaseTerms)}</>

        return <section className='venue-item-list'>
            <div className='at-panel'>
                {body}
            </div>
        </section>;
    }

    private renderRegistrationTerms = (registrationTerms: RegistrationTerms[]) => {
        const { t } = this.context;

        return <>
            <h4>{t('TermsAndConditionsList:RegistrationHeader')}</h4>
            <table className='table table-condensed'>
                <thead>
                    <tr>
                        <th>{t('Global:name')}</th>
                        <th>{t('Global:video')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {registrationTerms.map(rt => <tr key={`reg_${rt.id}`}>
                        <td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.editRegistrationSettings(rt))}>{rt.name}</button></td>
                        <td>{rt.registrationVideoName}</td>
                        <td className='text-right'>{rt.isFallbackTerms ? <span className='label label-info'>{t('Global:default')}</span> : null}</td>
                    </tr>)}
                </tbody>
            </table>
            <button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.editRegistrationSettings(null))}>{this.context.t('TermsAndConditionsList:addRegistrationTerms')}</button>
        </>
    }

    private renderBookingTerms = (registrationTerms: BookingTerms[]) => {
        const { t } = this.context;

        return <>
            <h4>{t('TermsAndConditionsList:BookingHeader')}</h4>
            <table className='table table-condensed'>
                <tbody>
                    {registrationTerms.map(bt => <tr key={`booking_${bt.id}`}><td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.editBookingSettings(bt))}>{bt.name}</button></td><td className='text-right'>{bt.isFallbackTerms ? <span className='label label-info'>{t('Global:default')}</span> : null}</td></tr>)}
                </tbody>
            </table>
            <button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.editBookingSettings(null))}>{this.context.t('TermsAndConditionsList:addBooingTerms')}</button>
        </>
    }

    private renderWebShopPurchaseTerms = (webShopPurchaseTerms: WebShopPurchaseTerms[]) => {
        const { t } = this.context;

        return <>
            <h4>{t('TermsAndConditionsList:WebShopPurchaseHeader')}</h4>
            <table className='table table-condensed'>
                <tbody>
                    {webShopPurchaseTerms.map(wsp => <tr key={`webShopPurchase_${wsp.id}`}><td><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.editWebShopPurchaseTermsSettings(wsp))}>{t('TermsAndConditionsList:webShopPurchaseTerms')}</button></td></tr>)}
                </tbody>
            </table>
            {webShopPurchaseTerms.length === 0 ? <button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => this.editWebShopPurchaseTermsSettings(null))}>{this.context.t('TermsAndConditionsList:addWehShopPurchaseTerms')}</button> : null}
        </>
    }
}

export default TermsAndConditionsList;
