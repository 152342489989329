
import * as React from 'react';
import * as PropTypes from 'prop-types'
import 'rxjs';

import * as api from '../../../store/apiClient';
import * as et from '../../../store/pages/emails/types';
import { EmailType } from '../../../store/pages/emailTemplates/types';
import { EmailStatus, EmailEventType } from '../../../store/pages/emails/types';
import Loading from '../../global/loading';
import { clickHandler, isNullOrEmpty, mapUtcDate, parseUtcDate } from '../../../utils/util';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';

interface EmailAttachment {
    id: string;
    fileName: string;
    sizeInBytes: number;
}

interface EmailEvent {
    type: EmailEventType;
    timestamp: Date;
    emailAddress: string | null;
}

interface Status {
    text: string;
    class: string;
    date: Date;
}

interface EmailDetails {
    id: string;
    templateName: string;
    emailAddress: string;
    subject: string;
    htmlBody: string;
    textBody: string;
    emailType: EmailType;
    status: EmailStatus;
    processedDateTime: Date | null;
    processingError: string;
    timeSent: Date | null;
    customerFirstName: string | null;
    customerLastName: string | null;
    customerLatestEmailAddress: string | null;
    sentUsing: string;
    canSend: boolean;
    canResendToDifferentAddress: boolean;
    createdBy: string;
    attachments: EmailAttachment[];
    events: EmailEvent[]
}

interface IGetEmailDetailsResponse {
    email: EmailDetails;
}

interface EmailDetailsPageProps {
    emailId: string;
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
    close: () => void;
}

interface EmailDetailsPageState {
    isLoading: boolean;
    error: string | null;
    emailDetails: EmailDetails | null;
    sent: boolean;
    sending: boolean;
} 


// How to load the email data (redux or fetch)?

export class EmailDetailsPage extends React.Component<EmailDetailsPageProps, EmailDetailsPageState> {

    constructor(props: EmailDetailsPageProps) {
        super(props);

        this.state = { isLoading: true, error: null, emailDetails: null, sent: false, sending: false };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        api.getJson<IGetEmailDetailsResponse>(`api/v1/email/${this.props.emailId}`)
            .take(1)
            .subscribe(this.fetchSuccess, this.fetchError);
    }

    fetchSuccess = (resp: IGetEmailDetailsResponse) => {
        const { email } = resp;
        this.setState({
            isLoading: false,
            error: null,
            emailDetails: {
                ...email,
                processedDateTime: mapUtcDate(email.processedDateTime),
                timeSent: mapUtcDate(email.timeSent),
                events: email.events.map(ev => ({ ...ev, timestamp: parseUtcDate(ev.timestamp)}))
            }
        });
    }

    fetchError = (e: any) => {
        this.setState({ isLoading: false, error: 'Unable to load email details' });
    }

    send = (email: EmailDetails) => {
        if (!this.state.sent) {
            this.setState({ sending: true });

            api.put(`api/v1/email/${email.id}/send`, {})
                .take(1)
                .subscribe(_ => this.setState({ sending: false, sent: true }), e => this.setState({ sending: false }));
        }
    }

    sendTo = (emailId: string, address: string | null) => {
        if (address) {
            this.setState({ sending: true });

            api.put(`api/v1/email/${emailId}/sendTo/${address}`, {})
                .take(1)
                .subscribe(_ => this.setState({ sending: false, sent: true }), e => this.setState({ sending: false }));
        }
    }

    render() {
        const { isLoading, error, emailDetails, sent, sending } = this.state;
        const { timeFormat, dateFormat, close } = this.props;
        const { t } = this.context;

        let body: JSX.Element | null = null;
        if (isLoading) {
            body = <Loading />;
        } else if (error) {
            body = (<div>
                <h3>{error}</h3>
            </div>)
        } else if (!emailDetails) {
            body = <div>{t('EmailDetailsPage:emailNotFound')}</div>
        } else {
            body = (<div>
                <dl>
                    <dt>{t('EmailDetailsPage:to')}</dt>
                    <dd>{emailDetails.emailAddress}</dd>
                </dl>
                {
                    emailDetails.customerFirstName || emailDetails.customerLastName
                        ? <dl>
                            <dt>{t('EmailDetailsPage:customer')}</dt>
                            <dd>{emailDetails.customerFirstName} {emailDetails.customerLastName}</dd>
                        </dl>
                        : null
                }
                {
                    emailDetails.customerLatestEmailAddress && emailDetails.customerLatestEmailAddress !== emailDetails.emailAddress
                        ? <dl>
                            <dt>{t('EmailDetailsPage:latestEmailAddress')}</dt>
                            <dd>
                                {emailDetails.customerLatestEmailAddress}
                                {emailDetails.canResendToDifferentAddress ? <button className='btn btn-primary' onClick={e => clickHandler(e, () => this.sendTo(emailDetails.id, emailDetails.customerLatestEmailAddress))} style={({ marginLeft: '15px' })} disabled={sending}>{t('EmailDetailsPage:resendToNewAddress')}</button> : null}
                            </dd>
                        </dl>
                        : null
                }
                <dl>
                    <dt>{t('EmailDetailsPage:status')}</dt>
                    <dd>
                        {this.renderStatus(emailDetails, sending, sent)}
                    </dd>
                </dl>
                <dl>
                    <dt>{t('EmailDetailsPage:createdBy')}</dt>
                    <dd>
                        {emailDetails.createdBy}
                    </dd>
                </dl>
                <dl>
                    <dt>{t('EmailDetailsPage:template')}</dt>
                    <dd>{emailDetails.templateName}</dd>
                </dl>
                <dl>
                    <dt>{t('EmailDetailsPage:subject')}</dt>
                    <dd>{emailDetails.subject}</dd>
                </dl>
                <dl>
                    <dt>{t('EmailDetailsPage:htmlBody')}</dt>
                    <dd><iframe srcDoc={emailDetails.htmlBody} contentEditable={false} style={({ width: '100%', minHeight: '250px' })} title='email details' /></dd>
                </dl>
                {!isNullOrEmpty(emailDetails.textBody)
                    ? <dl>
                        <dt>{t('EmailDetailsPage:textBody')}</dt>
                        <dd><textarea value={emailDetails.textBody} readOnly={true} style={({ width: '100%', minHeight: '250px' })} /></dd>
                    </dl>
                    : null
                }
                <dl>
                    <dt>{t('EmailDetailsPage:attachments')}</dt>
                    <ul className='list-unstyled'>
                        {emailDetails.attachments ? emailDetails.attachments.map(a => <li key={a.id}><span className='email-template-page-attachment-name'>{a.fileName}</span><span className='email-template-page-attachment-size'>{(a.sizeInBytes / 1024).toFixed(0)}KB</span></li>) : null}
                    </ul>
                </dl>
                <dl>
                    <dt>{t('EmailDetailsPage:emailType')}</dt>
                    <dd>{t(`EmailTemplateType:${EmailType[emailDetails.emailType]}`)}</dd>
                </dl>
                <dl>
                    <dt>{t('EmailDetailsPage:events')}</dt>
                    <dd>
                        <table className='table table-condensed'>
                            <tbody>
                                {emailDetails.events.sort((e1, e2) => e1.timestamp.getTime() - e2.timestamp.getTime()).map((ev, ix) => <tr key={ix}>
                                    <td>{t(`EmailEventType:${EmailEventType[ev.type]}`)}</td>
                                    <td>{ev.timestamp.toAbbrDateTimeString(timeFormat, dateFormat, t)}</td>
                                    <td>{ev.emailAddress}</td>
                                </tr>)}
                            </tbody>
                        </table>
                    </dd>
                </dl>
                <dl>
                    <dt>{t('EmailDetailsPage:processedDateTime')}</dt>
                    <dd>{emailDetails.processedDateTime ? emailDetails.processedDateTime.toAbbrDateTimeString(timeFormat, dateFormat, t) : ''}</dd>
                </dl>
                <dl>
                    <dt>{t('EmailDetailsPage:processingError')}</dt>
                    <dd>{emailDetails.processingError}</dd>
                </dl>
                <dl>
                    <dt>{t('EmailDetailsPage:sentUsing')}</dt>
                    <dd>{emailDetails.sentUsing}</dd>
                </dl>
            </div>);
        }

        return (
            <div>
                {body}
                <div className='btn-toolbar'>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, close)}>{t('Global:close')}</button>
                </div>
            </div>
        );
    }

    renderStatus = (email: EmailDetails, sending: boolean, sent: boolean) => {
        const { t } = this.context;
        const { timeFormat, dateFormat } = this.props;

        const status = this.buildStatus(email);

        return <>
            <span className={status.class}>{status.text}</span>
            {status.date ? <span style={({ marginLeft: '15px' })}>{status.date.toAbbrDateTimeString(timeFormat, dateFormat, t)}</span> : null}
            {email.canSend ? <button className='btn btn-primary' onClick={e => clickHandler(e, () => this.send(email))} style={({ marginLeft: '15px' })} disabled={sending || sent}>{t('EmailDetailsPage:sendEmail')}</button> : null}
            {email.events && email.events.findIndex(e => e.type === EmailEventType.Spamreport && (!e.emailAddress || isNullOrEmpty(e.emailAddress) || e.emailAddress.toLowerCase() == email.emailAddress.toLowerCase())) >= 0 ? <span className='label label-danger'>{t('EventEventType:Spamreport')}</span> : null}
        </>
    }

    buildStatus = (email: EmailDetails) => {
        const { t } = this.context;

        if (email.events && email.events.length > 0) {
            var orderedEvents = email.events.filter(ev => (!ev.emailAddress || isNullOrEmpty(ev.emailAddress) || ev.emailAddress.toLowerCase() == email.emailAddress.toLowerCase())).sort((e1,e2) => e2.timestamp.getTime() - e1.timestamp.getTime())

            if (orderedEvents.length > 0) {
                var lastEvent = orderedEvents[0];

                if (lastEvent.type === et.EmailEventType.Open) return { text: t('EmailEventType:Open'), class: 'label label-success', date: lastEvent.timestamp }
                if (lastEvent.type === et.EmailEventType.Delivered) return { text: t('EmailEventType:Delivered'), class: 'label label-success', date: lastEvent.timestamp }
                if (lastEvent.type === et.EmailEventType.Deferred) return { text: t('EmailEventType:Deferred'), class: 'label label-warning', date: lastEvent.timestamp }
                if (lastEvent.type === et.EmailEventType.Dropped) return { text: t('EmailEventType:Dropped'), class: 'label label-warning', date: lastEvent.timestamp }
                if (lastEvent.type === et.EmailEventType.Bounced) return { text: t('EmailEventType:Bounced'), class: 'label label-warning', date: lastEvent.timestamp }
                if (lastEvent.type === et.EmailEventType.Processed) return { text: t('EmailEventType:Processed'), class: 'label label-info', date: lastEvent.timestamp }
            }
        }

        if (email.status === et.EmailStatus.Failed) {
            return { text: t(`EmailStatus:${et.EmailStatus[email.status]}`), class: 'label label-danger', date: email.timeSent }
        } else if (email.status === et.EmailStatus.Sent) {
            return { text: t(`EmailStatus:${et.EmailStatus[email.status]}`), class: 'label label-info', date: email.timeSent }
        } else if (email.status === et.EmailStatus.Processing) {
            return { text: t(`EmailStatus:${et.EmailStatus[email.status]}`), class: 'label label-info', date: email.timeSent }
        } else if (email.status === et.EmailStatus.Queued) {
            return { text: t(`EmailStatus:${et.EmailStatus[email.status]}`), class: 'label label-info', date: email.timeSent }
        } else {
            return { text: t(`EmailStatus:${et.EmailStatus[email.status]}`), class: 'label label-default', date: email.timeSent }
        }
    }
}