
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as pgt from './types'; 
import * as lt from '../login/types';
import { IStore } from '../..';


export type PaymentGatewayActions = pgt.LoadPaymentGateways | pgt.ReceivePaymentGateways | pgt.SavePaymentGateway | pgt.PaymentGatewaySaved | pgt.PaymentGatewaySaveFailed;

export const actionCreators = {
    loadPaymentGateways: () => ({ type: pgt.PaymentGatewayActionTypes.RequestPaymentGateways }),
    loadPaymentGatewaysComplete: (paymentGateways: pgt.PaymentGateway[], err: api.ApiError | null) => ({ type: pgt.PaymentGatewayActionTypes.ReceivedPaymentGateways, paymentGateways: paymentGateways, error: err }),
    savePaymentGateway: (paymentGatewayId: number, paymentGateway: pgt.PaymentGateway) => ({ type: pgt.PaymentGatewayActionTypes.SavePaymentGateway, paymentGatewayId: paymentGatewayId, paymentGateway: paymentGateway }),
    paymentGatewaySaved: (paymentGatewayId: number) => ({ type: pgt.PaymentGatewayActionTypes.PaymentGatewaySaved, paymentGatewayId: paymentGatewayId }),
    paymentGatewaySaveFailed: (error: api.ApiError) => ({ type: pgt.PaymentGatewayActionTypes.PaymentGatewaySaveFailed, error: error })

}

interface IGetPaymentGatewaysResponse {
    paymentGateways: pgt.PaymentGateway[];
}

export const handleClientChange = (store: IStore) => [actionCreators.loadPaymentGateways]


//https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-after-catching-and-processing-an-error-with-emitting-somethi

const loadPaymentGateways = () => Observable.defer(() => api.getJson<IGetPaymentGatewaysResponse>('api/v1/paymentGateway/'))
    .map(response => actionCreators.loadPaymentGatewaysComplete(response.paymentGateways, null));

export const loadPaymentGatewaysEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        pgt.PaymentGatewayActionTypes.RequestPaymentGateways,
        action => loadPaymentGateways(),
        err => actionCreators.loadPaymentGatewaysComplete([], err));

export const savePaymentGatewayEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        pgt.PaymentGatewayActionTypes.SavePaymentGateway,
        action => {
            const spga = action as pgt.SavePaymentGateway;
            var pg = { ...spga.paymentGateway };
            var body = {
                ...pg,
                globalSettings: pg.globalSettings.map(s => ({ key: s.key, value: s.unsecuredValue, delete: s.delete }))
            };

            return (api.put(`api/v1/paymentGateway/${spga.paymentGatewayId}`, body))
                .map(response => {
                    return ({ type: pgt.PaymentGatewayActionTypes.PaymentGatewaySaved, paymentGatewayId: spga.paymentGatewayId });
                });
        },
        (err: api.ApiError) => actionCreators.paymentGatewaySaveFailed(err));

