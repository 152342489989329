
import { ValidationError } from '../../global/types';
import * as api from '../../apiClient';
import { EmailTag } from '../emailTemplates/types';

export interface PaymentPageSettings {
    heading: string;
    body: string;
    payButtonText: string;
    bodyDataTags: EmailTag[];
    termsAndConditionsId: string | null;
}

export enum CookieOptionsType {
    Banner = 1,
    Overlay = 2
}

export interface OnlineBookingSettings {
    noAvailabilityMessage: string;
    bookingConfirmationEmailTemplateId: string | null;
    upsellActivitiesPageHeader: string;
    completionPageContentTemplate: string;
    availableSlotButtonColour: string;
    availableSlotButtonTextColour: string;
    unavailableSlotButtonColour: string;
    unavailableSlotButtonTextColour: string;
    alternateAvailabilityMessage: string;
    showArrivalTime: boolean;
    arrivalTimeMessage: string | null;
    webBookingFeeId: string | null;
    completionPageTags: EmailTag[];
    bookingFields: number;
    requiredBookingFields: number;
    bookingBanner: string | null;
    bookingBannerBackgroundColour: string | null;
    bookingBannerBorderColour: string | null;
}

export interface OnlineRegistrationSettings {
    landingPageContent: string;
    participantsPageHeading: string;
    completionPageContent: string;
    registrationKioskId: string;
}

export interface VenuePublicWebsiteSettings {
    venueId: string;
    publicWebsiteId: number;
    publicWebsiteName: string;
    hideOnPublicWebsiteList: boolean;
    archived: boolean;
    resources: VenuePublicWebsiteResource[];
    siteName: string;
    backgroundImageId: string | null;
    panelColour: string;
    panelOpacity: number;
    panelTextColour: string;
    primaryButtonColour: string;
    primaryButtonTextColour: string;
    cookieOptionsType: CookieOptionsType;
    headerImageId: string | null;
    paymentPage: PaymentPageSettings;
    onlineBooking: OnlineBookingSettings;
    onlineRegistration: OnlineRegistrationSettings;
    analyticsSettings: PublicWebsiteAnalyticsSettings[];
    lastUpdatedTimestamp: number;
}

export interface VenuePublicWebsiteResource {
    resourceId: string;
    resourceName: string;
    colour: string;
    default: boolean;
}

export interface PublicWebsiteSettingsState {
    isLoading: boolean;
    publicWebsiteSettings: VenuePublicWebsiteSettings[];
    loadError: api.ApiError | null;
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
}

export enum WebsiteAnalyticsProviderType {
    GoogleAnalytics = 1,
    FacebookPixel = 2,
    Useproof = 3,
    GoogleGtag = 4,
    TikTok = 5,
    GoogleTagManager = 6
}

export interface PublicWebsiteAnalyticsArgs {
    [key: string]: string;
}

export interface PublicWebsiteAnalyticsSettings {
    providerType: WebsiteAnalyticsProviderType;
    canTrackConversions: boolean;
    argKeys: string;
    enabled: boolean;
    trackConversions: boolean;
    args: PublicWebsiteAnalyticsArgs;
}

export enum PublicWebsiteSettingsActionTypes {
    RequestPublicWebsiteSettings = 'REQUEST_PUBLIC_WEBSITE_SETTINGS',
    ReceivedPublicWebsiteSettings = 'RECEIVE_PUBLIC_WEBSITE_SETTINGS',
    SavePublicWebsiteSettings = 'SAVE_PUBLIC_WEBSITE_SETTINGS',
    PublicWebsiteSettingsSaved = 'REPORT_SAVED',
    PublicWebsiteSettingsSaveFailed = 'REPORT_SAVE_FAILED',
    EditPublicWebsiteSettings = 'EDIT_PUBLIC_WEBSITE_SETTINGS'
}

export interface LoadPublicWebsiteSettings {
    type: PublicWebsiteSettingsActionTypes.RequestPublicWebsiteSettings;
}

export interface ReceivePublicWebsiteSettings {
    type: PublicWebsiteSettingsActionTypes.ReceivedPublicWebsiteSettings;
    publicWebsiteSettings: VenuePublicWebsiteSettings[];
    error: api.ApiError | null;
}

export interface SavePublicWebsiteSettings {
    type: PublicWebsiteSettingsActionTypes.SavePublicWebsiteSettings;
    publicWebsiteSettings: VenuePublicWebsiteSettings;
    backgroundImg: File | null;
    headerImg: File | null;
}

export interface PublicWebsiteSettingsSaved {
    type: PublicWebsiteSettingsActionTypes.PublicWebsiteSettingsSaved;
    venueId: string;
}

export interface PublicWebsiteSettingsSaveFailed {
    type: PublicWebsiteSettingsActionTypes.PublicWebsiteSettingsSaveFailed;
    error: api.ApiError;
}

export interface EditPublicWebsiteSettings {
    type: PublicWebsiteSettingsActionTypes.EditPublicWebsiteSettings
}