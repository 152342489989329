

import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as api from '../../../store/apiClient';

import { clickHandler, isNullOrEmpty } from '../../../utils/util';
import ApiError from '../../global/apiError';
import { DateFormat } from '../../../store/pages/venues/types';

interface VoucherImport {
    code: string;
    duplicateCode: boolean;
    voucherAmount: string;
    parsedVoucherAmount: number | null;
    voucherAmountValid: boolean;
    remainingBalance: string;
    parsedRemainingBalance: number | null;
    remainingBalanceValid: boolean;
    expiryDate: string;
    parsedExpiryDate: Date | null;
    expiryDateValid: boolean;
}

interface ImportVouchersProps {
    voucherProductId: string;
    dateFormat: DateFormat;
    close: () => void;
    logout: () => void;
}

interface ImportVouchersState {
    selecteFile: File | null;
    validating: boolean;
    validated: boolean;
    videoUploadProgress: number | null;
    validationError: string | null;
    vouchers: VoucherImport[];
    importing: boolean;
    importError: api.ApiError | null;
}

class ImportVouchers extends React.Component<ImportVouchersProps, ImportVouchersState> {

    constructor(props: ImportVouchersProps) {
        super(props);

        this.state = {
            selecteFile: null,
            validating: false,
            validated: false,
            videoUploadProgress: null,
            validationError: null,
            vouchers: [],
            importing: false,
            importError: null
        }
    }

    close = () => this.props.close();

    static contextTypes = {
        t: PropTypes.func
    }

    onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = event;

        if (!target || !target.files || target.files.length < 1) return;

        const file = target.files[0];
        if (!file) return;

        this.setState({ selecteFile: file, validated: false });
    }

    validateFile = () => {
        this.setState({ validating: true, validated: false, videoUploadProgress: 0, validationError: null, vouchers: [] }, this.uploadFileToServer)
    }

    uploadFileToServer = () => {
        const { selecteFile } = this.state;
        const { voucherProductId } = this.props;
        const { t } = this.context;

        if (!selecteFile) return;

        api.uploadFile(selecteFile, `api/v1/voucher/${voucherProductId}/upload`, progress => {
            this.setState({ videoUploadProgress: progress })
        })
            .subscribe(result => {
                var vouchers = result.response.vouchers;
                this.setState({ videoUploadProgress: null, validating: false, validated: result.response.ok, vouchers: vouchers })

            }, e => this.setState({ validationError: t('Global:uploadFailed'), videoUploadProgress: null, validating: false, validated: false }));

    }

    isVoucherValid = (v: VoucherImport) => !v.duplicateCode && v.voucherAmountValid && v.remainingBalanceValid && v.expiryDateValid

    import = () => {
        const { vouchers } = this.state;
        const { voucherProductId, close, logout } = this.props;

        this.setState({ importing: true, importError: null }, () => {
            api.postWithAuth(`api/v1/voucher/${voucherProductId}/import`, { vouchers: vouchers.filter(this.isVoucherValid) }, logout)
                .subscribe(
                    result => this.setState({ importError: null, importing: false }, () => setTimeout(close, 750)),
                    e => this.setState({ importError: e, importing: false }));
        });
    }

    render() {
        const { t } = this.context;
        const { vouchers, validationError, validating, validated, videoUploadProgress, importing, importError, selecteFile } = this.state;

        const errors = vouchers.filter(v => !this.isVoucherValid(v)).length;

        return <div>
            <h3>{t('ImportVouchers:heading')}</h3>

            <div className='alert alert-info'>
                <div dangerouslySetInnerHTML={{ __html: t('ImportVouchers:instructions', { sb: '<strong>', eb: '</strong>' }) }}/>
                <div>{t('ImportVouchers:instructions2')}</div>
            </div>
            <div className='flex'>
                <div className='flex-shrink'>
                    <label htmlFor='fileName'>{t('ImportVouchers:voucherFile')}</label>
                    <input type='file' id='fileName' name='fileName' accept=".csv" onChange={this.onFileSelected}  />
                </div>
                <div className='flex-stretch'><button className='btn btn-primary' onClick={e => clickHandler(e, this.validateFile)} disabled={selecteFile === null}>{t('ImportVouchers:validate')}</button></div>
            </div>

            {this.renderProgress(validating, videoUploadProgress)}

            {this.renderError(validationError, importError) }

            {this.renderVouchers(vouchers) }

            <div className='btn-toolbar mt-15'>
                <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)} disabled={importing}>{t('Global:close')}</button>
                {validated ? <button className='btn btn-primary' onClick={e => clickHandler(e, this.import)} disabled={importing}>{t(errors > 0 ? 'ImportVouchers:importIgnoringErrors' : 'ImportVouchers:import')}</button> : null}
            </div>
        </div>
    }

    renderProgress = (validating: boolean, videoUploadProgress: number | null) => {
        const { t } = this.context;

        if (!validating) return null;

        let label = '';
        const progress = videoUploadProgress || 100;

        if (progress < 100) {
            label = `${progress.toFixed(0)}%`;
        } else {
            label = t('ImportVouchers:processingValidation')
        }

        return <div className="progress" style={{margin: '20px 0'}}>
            <div className='progress-bar' role='progressbar' aria-valuenow={progress} aria-valuemin={0} aria-valuemax={100} style={{ width: `${progress}%` }}>
                {label}
            </div>
        </div>
    }

    renderError = (validationError: string | null, importError: api.ApiError | null) => {
        if (isNullOrEmpty(validationError)) {
            return importError ? <ApiError error={importError} /> : null;
        }

        return <div className='alert alert-danger' style={{ margin: '20px 0' }}>{validationError}</div>
    }

    renderVouchers = (vouchers: VoucherImport[]) => {
        const { t } = this.context;
        const { dateFormat } = this.props;

        if (vouchers.length === 0) return null;

        const error = (msg: string) => <span className='help-icon'><span className='glyphicon glyphicon-exclamation-sign text-danger' style={{ marginLeft: '8px' }}></span><span className='help-text'>{msg}</span></span>
        const errorCellStyle = { backgroundColor: '#f2dede' }

        return <table className='table table-condensed mt-15'>
            <thead>
                <tr>
                    <th>{t('ImportVouchers:codeHeading') }</th>
                    <th>{t('ImportVouchers:amountHeading')}</th>
                    <th>{t('ImportVouchers:balanceHeading')}</th>
                    <th>{t('ImportVouchers:expiryHeading')}</th>
                </tr>
            </thead>
            <tbody>
                {vouchers.map((v, ix) => <tr key={ix}>
                    <td style={v.duplicateCode ? errorCellStyle : {}}>{v.code}{v.duplicateCode ? error(t('ImportVouchers:duplicateCode')) : null}</td>
                    <td style={!v.voucherAmountValid || !v.parsedVoucherAmount ? errorCellStyle : {}}>{v.voucherAmountValid && v.parsedVoucherAmount ? v.parsedVoucherAmount.toFixed(2) : v.voucherAmount}{!v.voucherAmountValid || !v.parsedVoucherAmount ? error(t('ImportVouchers:invalidAmount')) : null}</td>
                    <td style={!v.remainingBalanceValid || !v.remainingBalance ? errorCellStyle : {}}>{v.remainingBalanceValid && v.parsedRemainingBalance ? v.parsedRemainingBalance.toFixed(2) : v.remainingBalance}{!v.remainingBalanceValid || !v.remainingBalance ? error(t('ImportVouchers:invalidAmount')) : null}</td>
                    <td style={!v.expiryDateValid || !v.parsedExpiryDate ? errorCellStyle : {}}>{v.expiryDateValid && v.parsedExpiryDate ? new Date(v.parsedExpiryDate).toAbbrDateString(dateFormat, t) : v.expiryDate}{!v.expiryDateValid || !v.parsedExpiryDate ? error(t('ImportVouchers:invalidDate')) : null}</td>
                </tr>)}
            </tbody>
        </table>
    }
}

export default ImportVouchers;