
//import '../../../css/reactdatetime.css';

import * as React from 'react';
import * as PropTypes from 'prop-types'
import moment from 'moment';

import * as ct from '../../global/controls';
import * as v from '../../global/validation';

import { PaymentMethod } from '../../../store/pages/paymentMethods/types';
import { clickHandler } from '../../../utils/util';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';

interface SchedulePaymentProps {
    amount: number;
    eventDate: Date;
    paymentDescription: string | null;
    isSecurityPayment: boolean;
    paymentMethods: PaymentMethod[];
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
    schedule: (description: string, dueDate: Date, isSecurityPayment: boolean) => void;
    cancel: () => void;
}

interface SchedulePaymentState {
    description: ct.FormValue<string>;
    dueDate: ct.FormValue<moment.Moment>;
}

export default class SchedulePayment extends React.Component<SchedulePaymentProps, SchedulePaymentState> {

    today: moment.Moment;

    constructor(props: SchedulePaymentProps) {
        super(props);

        this.today = moment().startOf('day');

        this.state = {
            description: this.validateDescription(props.paymentDescription || ''),
            dueDate: this.validateDueDate(this.today)
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    createPayment = () => {
        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {
            const { isSecurityPayment, schedule } = this.props;
            const { description, dueDate } = this.state;

            schedule(description.value, dueDate.value.toDate(), isSecurityPayment);
        }
    }

    dueDateChanged = (date: moment.Moment) => {
        // strip time from date
        var dateVal = date.clone().startOf('day');
        this.setState({ dueDate: this.validateDueDate(dateVal) });
    }

    validateInRange = (date: moment.Moment) => {

        const eventTime = moment(this.props.eventDate).startOf('day');
        const today = this.today.clone().startOf('day');
        const dateDay = date.clone().startOf('day');

        if (eventTime < today && dateDay < eventTime) {
            return 'PointOfSale:validation:dateBeforeEventDate';
        } else if (eventTime >= today && dateDay < today) {
            return 'validation:dateInPast';
        } else if (dateDay > eventTime) {
            return 'PointOfSale:validation:dateAfterEventDate';
        }

        return undefined;
    }

    validateDescription = (val: string) => v.validate(val, 'description', [v.required], []);
    validatePaymentMethod = (val: string) => v.validate(val, 'paymentMethod', [v.required], []);
    validateDueDate = (val: moment.Moment) => v.validate(val, 'dueDate', [v.required, this.validateInRange], []);

    render() {
        const { eventDate, timeFormat, dateFormat } = this.props;
        const { description, dueDate } = this.state;
        const { t } = this.context;

        return (
            <div style={({ maxWidth: '480px' })}>
                <h3>{t('PointOfSale:schedulePayment')}</h3>

                <form className='data-form' onSubmit={e => e.preventDefault()} autoComplete='off'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <label>{t('PointOfSale:eventDate')}</label>: <span>{eventDate.toLongDateString(dateFormat, t)} {eventDate.toShortTimeString(timeFormat)}</span>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-12'>
                            <ct.TextBox id='description' labelKey='Global:description' placeholderKey='Global:description' value={description} callback={val => this.setState({ description: this.validateDescription(val) })} />
                        </div>

                        <div className='col-sm-12'>
                            <ct.DatePicker id='dueDate' labelKey='PointOfSale:dueDate' value={dueDate} callback={this.dueDateChanged} />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-12 button-panel'>
                            <button className='btn btn-primary' onClick={e => clickHandler(e, this.createPayment)}>{t('PointOfSale:schedule')}</button>
                            <button onClick={e => clickHandler(e, this.props.cancel)} className='btn btn-basic'>{t('Global:cancel')}</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}