
import { Action } from 'redux';

import { ActivityFormatActionTypes, ActivityFormatState } from './types';
import { ActivityFormatActions } from './actions'
import { createReducer } from '../../reducer';

const unloadedState: ActivityFormatState = { isLoading: false, activityFormats: [], loadError: null, isSaving: false, saveComplete: false, saveError: null, validationErrors: [] };

export const reducer = (state: ActivityFormatState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: ActivityFormatState, incomingAction: Action): ActivityFormatState => {

    const action = incomingAction as ActivityFormatActions;
    switch (action.type) {
        case ActivityFormatActionTypes.RequestActivityFormats:
            return {
                ...state,
                isLoading: true,
                activityFormats: state.activityFormats,
                loadError: null
            };
        case ActivityFormatActionTypes.ReceivedActivityFormats:
            const ordered = action.activityFormats ? action.activityFormats : [];
            ordered.sort((a, b) => a.name.localeCompare(b.name)).map(af => ({...af, additionalProducts: af.additionalProducts.sort((p1, p2) => p1.sequence - p2.sequence)}));

            return {
                ...state,
                isLoading: false,
                activityFormats: ordered,
                loadError: action.error
            };
        case ActivityFormatActionTypes.EditActivityFormat:
            return {
                ...state,
                isSaving: false,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case ActivityFormatActionTypes.SaveActivityFormat:
            return {
                ...state,
                isSaving: true,
                saveError: null,
                saveComplete: false,
                validationErrors: []
            };
        case ActivityFormatActionTypes.ActivityFormatSaved:
            return {
                ...state,
                isSaving: false,
                saveComplete: true,
                saveError: null,
                validationErrors: []
            };
        case ActivityFormatActionTypes.ActivityFormatSaveFailed:
            return {
                ...state,
                isSaving: false,
                saveError: action.error,
                saveComplete: false,
                validationErrors: action.error && action.error.validationErrors ? action.error.validationErrors : []
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

