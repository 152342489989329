
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { ITranslationContext } from '../../../translations';
import { BillFee } from '../../../store/pages/pointOfSale/types';
import { clickHandler } from '../../../utils/util';

interface BillFeeRowProps {
    fee: BillFee;
    editBillFee?: (fee: BillFee) => void;
}

const BillFeesRow = (props: BillFeeRowProps, context: ITranslationContext) => {
    const { fee } = props;
    const { t } = context;

    const editFee = props.editBillFee ? props.editBillFee : (f: BillFee) => { };

    return <li key={fee.id} className='pos_bill_item'>
        <div className='pos_bill_item_qualtity'></div>
        <div className='bill_item_name'><button className='btn btn-link btn-no-padding' onClick={e => clickHandler(e, () => editFee(fee))}>{fee.description}</button></div>
        <div className='bill_item_price'>
            <div className='bill_item_main_price'>{`${t('Global:currencySymbol')}${fee.amount.toFixed(2)}`}</div>
            <div className='bill_item_tax'>{fee.taxRate.toFixed(2)}%</div>
        </div>
    </li>
}

BillFeesRow.contextTypes = {
    t: PropTypes.func
}

export default BillFeesRow;