import { AnalyticsComparisonPeriodSelection, AnalyticsPeriodSelection } from "../../../store/pages/analytics/types";
import { DayOfWeek } from "../../../store/pages/venues/types";

export const colours = ['#cd0242', '#0482b9', '#820ad5', '#e69300', '#06c356', '#b60293', '#00459f']
export const comparisonColours = ['#ff97b8', '#86d6fa', '#c170fa', '#ffd892', '#7af2ad', '#f998e7', '#4e9bff']


export interface VenueRevenueStats {
    venueId: string;
    venueName: string;
    culture: string;
    currencyCode: string;
    colour: string;
    comparisonColour: string;
    thisPeriodStats: VenuePeriodRevenueStats;
    comparisonPeriodStats: VenuePeriodRevenueStats;
}

export interface VenuePeriodRevenueStats {
    totalRevenueThisPeriod: number;
    netRevenueThisPeriod: number;
    numberOfNonBookingBillsThisPeriod: number;
    onlineNonBookingBillPercentage: number;
    numberOfBookingsThisPeriod: number;
    onlineBookingPercentage: number;
    periodBuckets: AnalyticsRevenueBucket[];
    averageGrossOrderValueThisPeriod: number;
    averageNetOrderValueThisPeriod: number;
}

export interface AnalyticsRevenueBucket {
    year: number;
    period: number;
    date: Date;
    grossRevenue: number;
    netRevenue: number;
    bookings: number;
    onlineBookingPercentage: number;
    otherBills: number;
    onlineBillPercentage: number;
    averageGrossOrderValue: number;
    averageNetOrderValue: number;
}

export interface VenueProductData {
    groupKey: string;
    groupName: string;
    archived: boolean;
    quantitySold: number;
    comparisonQuantitySold: number;
    netSales: number;
    comparisonNetSales: number;
    grossSales: number;
    comparisonGrossSales: number;
    bills: number;
    comparisonBills: number;
    events: number;
    comparisonEvents: number;
    sessions: number;
    comparisonSessions: number;
    averageGrossOrderValue: number;
    comparisonAverageGrossOrderValue: number;
    averageNetOrderValue: number;
    comparisonAverageNetOrderValue: number;
}

export interface VenueAllProductsStats {
    venueId: string;
    venueName: string;
    culture: string;
    currencyCode: string;
    colour: string;
    comparisonColour: string;
    thisPeriodStats: VenuePeriodAllProductsStats,
    comparisonPeriodStats: VenuePeriodAllProductsStats,
    rawProductData: VenueProductData[]
}

export interface VenuePeriodAllProductsStats {
    totalRevenueThisPeriod: number;
    netRevenueThisPeriod: number;
    discountThisPeriod: number;
    quantityThisPeriod: number;
    billsThisPeriod: number;
    sessionsThisPeriod: number,
    eventsThisPeriod: number,
    periodBuckets: AnalyticsAllProductsBucket[];
    averageGrossOrderValue: number;
    averageNetOrderValue: number;
}

export interface AnalyticsAllProductsBucket {
    year: number;
    period: number;
    date: Date;
    grossRevenue: number;
    netRevenue: number;
    discount: number;
    quantity: number;
    bills: number;
    sessions: number;
    events: number;
    averageGrossOrderValue: number;
    averageNetOrderValue: number;
}

export enum AnalyticsProductReportType {
    AllProducts,
    ProductsByNominalCode,
    ProductsByAcocuntingDept,
    ProductsByAccountingCat,
    BestSellingByQuantity,
    BestSellingByRevenue,
    LowestSellingByQuantity,
    LowestSellingByRevenue
}
