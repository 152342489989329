
import * as React from 'react';
import * as PropTypes from 'prop-types'
import moment from 'moment';

import * as ct from '../../global/controls';
import * as v from '../../global/validation';

import { EmailStatus } from '../../../store/pages/emails/types';
import { clickHandler } from '../../../utils/util';

interface EmailsFilterProps {
    show: boolean;
    to: string;
    status: EmailStatus | -1;
    fromDate: moment.Moment | null;
    toDate: moment.Moment | null;
    applyFilter: (to: string, status: EmailStatus | -1, fromDate: Date | null, toDate: Date | null) => void;
}

interface EmailsFilterState {
    to: ct.FormValue<string>;
    status: ct.FormValue<EmailStatus | -1>;
    fromDate: ct.FormValue<moment.Moment | null>;
    toDate: ct.FormValue<moment.Moment | null>;
}

export class EmailsFilter extends React.Component<EmailsFilterProps, EmailsFilterState> {

    constructor(props: EmailsFilterProps) {
        super(props);

        this.state = { to: this.validateTo(props.to), status: this.validateStatus(props.status), fromDate: this.validateFromDate(props.fromDate), toDate: this.validateToDate(props.toDate) };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    applyFilter = () => {
        const { to, status, fromDate, toDate } = this.state;
        this.props.applyFilter(to.value, status.value, fromDate.value ? fromDate.value.toDate() : null, toDate.value ? toDate.value.toDate() : null);
    }

    clearFilter = () => {
        this.setState({ to: this.validateTo(''), status: this.validateStatus(-1), fromDate: this.validateFromDate(null), toDate: this.validateToDate(null) });
        this.props.applyFilter('', -1, null, null);
    }

    statusChanged = (newValue: string) => this.setState({ status: this.validateStatus(parseInt(newValue)) });

    fromDateChanged = (date: moment.Moment | null) => this.setState({ fromDate: this.validateFromDate(date) });

    toDateChanged = (date: moment.Moment | null) => this.setState({ toDate: this.validateToDate(date) });

    validateTo = (val: string) => v.validate(val, 'to', [], []);
    validateStatus = (val: number) => v.validate(val, 'status', [], []);
    validateFromDate = (val: moment.Moment | null) => v.validate(val, 'fromDate', [], []);
    validateToDate = (val: moment.Moment | null) => v.validate(val, 'toDate', [], []);

    render() {
        if (!this.props.show) {
            return null;
        }

        const { t } = this.context;
        const { to, status, fromDate, toDate } = this.state;

        const statusOptions = [{ key: '-1', name: t('EmailStatus:all') }].concat(Object.keys(EmailStatus).filter(key => typeof EmailStatus[key as any] === 'number').map(key => ({ key: EmailStatus[key as any].toString(), name: t(`EmailStatus:${key}`) })));

        return <div className="alert alert-info">
            <div className='row'>
                <div className='col-xs-2'>
                    <ct.DatePicker id='fromDate' labelKey='EmailDetailsPage:fromDate' value={fromDate} callback={this.fromDateChanged} />
                </div>
                <div className='col-xs-2'>
                    <ct.DatePicker id='toDate' labelKey='EmailDetailsPage:toDate' value={toDate} callback={this.toDateChanged} />
                </div>
                <div className='col-xs-3'>
                    <ct.Select id='status' labelKey='EmailDetailsPage:status' value={({ ...status, value: status.value.toString() })} callback={this.statusChanged} options={statusOptions} />
                </div>
                <div className='col-xs-4'>
                    <ct.TextBox id='to' labelKey='EmailDetailsPage:toEmail' placeholderKey='EmailDetailsPage:to' value={to} callback={val => this.setState({ to: this.validateTo(val) })} />
                </div>
                <div className='col-xs-1'>
                    <div className='section-actions right' style={({ marginTop: '35px', minWidth: '72px' })}>
                        <button className='btn btn-xs btn-info' onClick={e => clickHandler(e, this.applyFilter)}>
                            <span className='glyphicon glyphicon-ok'></span>
                        </button>
                        <button className='btn btn-xs btn-info' onClick={e => clickHandler(e, this.clearFilter)}>
                            <span className='glyphicon glyphicon-remove'></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>;
    }
}