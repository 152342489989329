
import * as React from 'react';
import * as PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

import * as ct from '../../../global/controls';

import { ActivityFormat } from '../../../../store/pages/activityFormats/types';
import { Product } from '../../../../store/pages/products/types';
import { Resource } from '../../../../store/pages/resources/types';
import Loading from '../../../global/loading';
import { isNullOrEmpty } from '../../../../utils/util';
import { MembershipType } from '../../../../store/pages/memberships/types';
import TagLabel from '../../../global/tagLabel';

interface ActivityFormatListProps {
    venueId: string;
    isLoadingActivityFormats: boolean;
    activityFormats: ActivityFormat[];
    products: Product[];
    isLoadingProducts: boolean;
    resources: Resource[];
    isLoadingResources: boolean;
    membershipTypes: MembershipType[];
    editActivityFormat: () => void;
}

interface ActivityFormatListState {
    showArchived: boolean;
    filter: string;
}

class ActivityFormatList extends React.Component<ActivityFormatListProps, ActivityFormatListState> {

    constructor(props: ActivityFormatListProps) {
        super(props);

        this.state = { showArchived: false, filter: '' }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    isLoading = () => this.props.isLoadingActivityFormats || this.props.isLoadingProducts || this.props.isLoadingResources;

    render() {
        const { showArchived, filter } = this.state;
        const { venueId } = this.props;
        const { t } = this.context;
        const body = this.isLoading() ? <Loading /> : this.renderActivityFormatList();

        return <section className='venue-item-list'>
            <header className='section-header'>
                <div className='section-actions'>
                    <label>
                        <input type='checkbox' checked={showArchived} onChange={e => this.setState({ showArchived: e.currentTarget.checked })} />
                        <span style={({ marginLeft: '10px' })}>{t('Global:showArchived')}</span>
                    </label>

                    {/*<button className='btn btn-info' onClick={e => btnHandler(e, this.addActivityFormat)}>{this.context.t('ActivityFormatList:addActivityFormat')}</button>*/}
                    <Link to={`/venue/${venueId}/af/new`} className='btn btn-info'>{this.context.t('ActivityFormatList:addActivityFormat')}</Link>

                    <div className='pull-right'>
                        <label>{t('Global:filter')}</label>
                        <ct.TextBox style={{ display: 'inline-block', marginLeft: '8px'}} id='filter' labelKey='' value={ct.asFormValue('filter', filter)} callback={val => this.setState({filter: val}) } />
                    </div>
                </div>
            </header>
            <div className='at-panel sub-section-panel-240px'>
                {body}
            </div>
        </section>;
    }

    private renderActivityFormatList = () => {

        const { t } = this.context;
        const { venueId, activityFormats, resources, products, membershipTypes } = this.props;
        const { showArchived, filter } = this.state;
        const warningIconStyle = { color: '#f9b745', marginRight: '6px' }
        const lowerFilter = filter.toLowerCase();

        const formats = activityFormats.filter(af => af.venueId === venueId && (showArchived || !af.archived) && (isNullOrEmpty(filter) || af.name.toLowerCase().includes(lowerFilter))).map(af => {

            const warnings: string[] = [];
            if (af.customerCategories.length === 0) warnings.push(t('ActivityFormatList:NoCustomerCategories'));
            if (af.products.length === 0) warnings.push(t('ActivityFormatList:ActivityHasNoProduct'));
            const productsWithMissingCategories = af.products.filter(p => p.customerCategories.length === 0);
            for (let pi = 0; pi < productsWithMissingCategories.length; pi++) {
                var prod = products.find(p => p.id === productsWithMissingCategories[pi].productId);
                if (prod) warnings.push(t('ActivityFormatList:ProductWithNoCustomerCategory', { productName: prod.name }));
            }

            if (af.linkedActivityFormats.filter(la => {
                const linkedActivity = activityFormats.find(af => af.id === la.linkedActivityFormatId)
                if (!linkedActivity || linkedActivity.archived) return false;
                const unmappedCategories = af.customerCategories.filter(c => {
                    const hasMatchingCategory = linkedActivity.customerCategories.findIndex(lc => lc.customerCategoryId === c.customerCategoryId) >= 0;
                    if (hasMatchingCategory) return false;
                    const mappingdForCategory = la.customerCategoryMap.filter(cm => cm.originalActivityCustomerCategoryId === c.customerCategoryId
                        && linkedActivity.customerCategories.findIndex(lc => lc.customerCategoryId === cm.linkedActivityCustomerCategoryId) >= 0);
                    return mappingdForCategory.length === 0;
                })

                return unmappedCategories.length > 0;
            }).length > 0) {
                warnings.push(t('ActivityFormatList:linkedActivityMissingCategoryMappings'));
            }

            const variationsWithNoRunTime = af.variations.filter(v => v.schedule.findIndex(s => s.runningTime.getHours() === 0 && s.runningTime.getMinutes() == 0) >= 0);
            if (variationsWithNoRunTime.length > 0) warnings.push(t('ActivityFormatForm:AllVariationsMustHaveARummingTime'));

            const membershipType = af.membershipTypeId ? membershipTypes.find(mt => mt.id === af.membershipTypeId) : null;

            return (
                <tr key={af.id}>
                    <td><Link to={`/venue/${venueId}/af/${af.id}`} className='btn btn-link btn-no-padding'>{isNullOrEmpty(af.name) ? <span>[{t('Global:edit')}]</span> : af.name}</Link></td>
                    <td>{af.sequence}</td>
                    <td>{membershipType && membershipType.tag ? <TagLabel colour={membershipType.tag.tagColour} name={membershipType.tag.tagName} /> : null}</td>
                    <td>{
                        af.resources.filter(afr => resources.findIndex(r => r.id === afr.resourceId) >= 0).length === 0
                            ? <div><span className='glyphicon glyphicon-alert' style={warningIconStyle}></span><span>{t('ActivityFormatList:NoResourcesSelected')}</span></div>
                            : <ul className='list-unstyled list-no-margin'>{resources.filter(r => af.resources.some(x => x.resourceId === r.id)).map(r => <li key={r.id} style={this.buildResourceStyle(r)}>{this.buildResourceName(r, af)}</li>)}</ul>
                    }</td>
                    <td>{warnings.map(msg => <div><span className='glyphicon glyphicon-alert' style={warningIconStyle}></span><span>{msg}</span></div>)}</td>
                </tr>
            );
        });
        
        return (
            <table className='table table-condensed'>
                <thead>
                    <tr>
                        <th>{t('ActivityFormatList:nameHeading')}</th>
                        <th>{t('ActivityFormatList:sequenceHeading')}</th>
                        <th></th>
                        <th>{t('ActivityFormatList:resources')}</th>
                    </tr>
                </thead>
                <tbody>
                    {formats}
                </tbody>
            </table>
        );
    }

    buildResourceName = (r: Resource, af: ActivityFormat) => {
        const resourceSelection = af.resources.find(x => x.resourceId === r.id);
        const configs = resourceSelection ? r.configurations.filter(c => resourceSelection.configurationIds.length > 0 && resourceSelection.configurationIds.some(x => x === c.id)) : [];

        if (configs && configs.length > 0 && configs.length < r.configurations.length) {
            return <span>{r.name} {configs.map(c => <span key={`${r.id}_${c.id}`} className='label label-default'>{c.name}</span>)}</span>;
        } else {
            return r.name;
        }
    }

    buildResourceStyle = (resource: Resource) => ({ display: 'inline-block', marginLeft: '5px', borderLeft: `4px solid ${resource.colour}`, paddingLeft: '2px' });
}

export default ActivityFormatList;
