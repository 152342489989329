import { ValidationError } from '../../global/types';
import * as api from '../../apiClient';
import { PaymentGatewayType } from '../paymentGateways/types';

export enum PaymentMethodType {
    None = 0,
    Cash = 1,
    ManualCard = 2,
    Bacs = 3,
    Gateway = 4,
    CustomerCredit = 5,
    Voucher = 6
}

export interface PaymentMethodSetting {
    key: string;
    secure: boolean;
    hasValue: boolean;
    unsecuredValue: string | null;
    optional: boolean;
    delete?: boolean;
}

export interface PaymentInformation {
    id: string;
    name: string;
    required: boolean;
}

export interface PaymentMethod {
    id: string;
    venueId: string;
    name: string;
    pointOfSaleText: string;
    openCashDrawer: boolean;
    eventPaymentsOnly: boolean;
    type: PaymentMethodType;
    settings: PaymentMethodSetting[];
    extraFields: PaymentInformation[];
    archived: boolean;
    canRefund: boolean;
    limitRefundToPaidAmount: boolean;
    allowCustomerWebPayment: boolean;
    webPaymentButtonText: string;
    publicPaymentName: string;
    paymentGatewayId: number | null;
    paymentGatewayType: PaymentGatewayType;
    paymentGatewayMinimumPaymentAmount: number | null;
    createdBy?: string;
    createDateTime?: Date;
    lastUpdatedBy?: string | null;
    lastUpdated?: Date | null;
    integrationKey: string | null;
}

export interface PaymentMethodState {
    isLoading: boolean;
    paymentMethods: PaymentMethod[];
    loadError: api.ApiError | null;
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
    lastSavedId: string | null;
    lastSavedIntegrationKey: string | null;
}

export enum PaymentMethodActionTypes {
    RequestPaymentMethods = 'REQUEST_PAYMENT_METHODS',
    ReceivedPaymentMethods = 'RECEIVE_PAYMENT_METHODS',
    SavePaymentMethod = 'SAVE_PAYMENT_METHOD',
    PaymentMethodSaved = 'PAYMENT_METHOD_SAVED',
    PaymentMethodSaveFailed = 'PAYMENT_METHOD_SAVE_FAILED',
    EditPaymentMethod = 'EDIT_PAYMENT_METHOD'
}


export interface LoadPaymentMethods {
    type: PaymentMethodActionTypes.RequestPaymentMethods;
}


export interface ReceivePaymentMethods {
    type: PaymentMethodActionTypes.ReceivedPaymentMethods;
    paymentMethods: PaymentMethod[];
    error: api.ApiError | null;
}

export interface SavePaymentMethod {
    type: PaymentMethodActionTypes.SavePaymentMethod;
    isNew: boolean;
    paymentMethodId?: string;
    venueId: string;
    paymentMethod: PaymentMethod;
    extraFields: PaymentInformation[];
}

export interface PaymentMethodSaved {
    type: PaymentMethodActionTypes.PaymentMethodSaved;
    paymentMethodId: string;
    paymentMethodIntegrationKey: string;
}

export interface PaymentMethodSaveFailed {
    type: PaymentMethodActionTypes.PaymentMethodSaveFailed;
    error: api.ApiError;
}

export interface EditPaymentMethod {
    type: PaymentMethodActionTypes.EditPaymentMethod
}
