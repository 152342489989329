
import * as React from 'react';
import * as PropTypes from 'prop-types'
import { Link, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import * as LoginState from '../../../store/pages/login/types';
import * as LoginActions from '../../../store/pages/login/actions';
import * as v from '../../global/validation';
import * as ct from '../../global/controls';
import * as api from '../../../store/apiClient';
import ApiError from '../../global/apiError';

type ResetPasswordPageProps =
    LoginState.LoginState                       // ... state we've requested from the Redux store
    & typeof LoginActions.actionCreators        // ... plus action creators we've requested
    & RouteComponentProps<{}>;                 // ... plus incoming routing parameters

interface ResetPasswordPageState {
    token: string | null;
    username: ct.FormValue<string>;
    newPassword: ct.FormValue<string>;
    confirmPassword: ct.FormValue<string>;
    error: api.ApiError | null;
}

class ResetPasswordPage extends React.Component<ResetPasswordPageProps, ResetPasswordPageState> {

    constructor(props: ResetPasswordPageProps) {
        super(props);
        
        const query = props.location.search;
        const parts = (query.length > 1 && query[0] === '?' ? query.substr(1) : query).split('&');
        let token: string | null = null;

        for (let i = 0; i < parts.length; i++) {
            if (token === null && parts[i].startsWith('token=')) {
                token = parts[i].substr(6);
            }
        }

        this.state = { error: null, token: token, username: this.validateUsername(''), newPassword: this.validateNewPassword(''), confirmPassword: this.validateConfirmPassword('') };
    }

    static contextTypes = {
        t: PropTypes.func
    }


    componentDidMount() {
        // Ensure all stored tokens are cleared
        this.props.ensureSignedOut();
    }

    checkConfPasswordMatchesNewPassword = (value: string) => value === this.state.newPassword.value ? undefined : 'Global:passwordMissmatch';

    validateUsername = (val: string) => v.validate(val, 'username', [v.required], []);
    validateNewPassword = (val: string) => v.validate(val, 'newPassword', [v.required], []);
    validateConfirmPassword = (val: string) => v.validate(val, 'confirmPassword', [v.required, this.checkConfPasswordMatchesNewPassword], []);

    resetPassword = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {
            this.setState({ error: null });
            this.props.resetUserPassword(this.state.token||'', this.state.username.value, this.state.newPassword.value, this.state.confirmPassword.value);
        }
    }

    render() {

        const errorMsg = this.props.resetUserPasswordError || this.state.error;

        const resetButtonProps = v.isValid(this.state) ? {} : { disabled: true };

        const body = this.state.token === null
            ? <div className='alert alert-danger'>
                {this.context.t('ResetPassword:invalidUrl')}
              </div>
            : <form className='data-form' onSubmit={this.resetPassword} autoComplete='off'>
                  <p className='alert alert-info'>{this.context.t('ResetPassword:resetPasswordText')}</p>

                  <ct.TextBox id='username' labelKey='ResetPassword:username' placeholderKey='Global:username-placeholder' value={this.state.username} callback={val => this.setState({ username: this.validateUsername(val) })} />

                  <ct.Password id='newPassword' labelKey='Global:newPassword' placeholderKey='Global:password-placeholder' value={this.state.newPassword} callback={val => this.setState({ newPassword: this.validateNewPassword(val) })} />

                  <ct.Password id='confirmPassword' labelKey='Global:passwordConfirmation' placeholderKey='Global:password-placeholder' value={this.state.confirmPassword} callback={val => this.setState({ confirmPassword: this.validateConfirmPassword(val) })} />

                  <ApiError error={errorMsg} className='error-placeholder' />

                  <div>
                      <input type='submit' value={this.context.t('ResetPassword:resetPassword')} className='btn btn-primary' {...resetButtonProps} />
                      <Link className='btn btn-basic' to={'/login'} style={({ float: 'right' })}>{this.context.t('Global:cancel')}</Link>
                  </div>
              </form>;

        return (
            <div className='login-page'>
                <div className='login-content'>
                    <div className='login cf'>
                        <h2 className='login-title'>{this.context.t('ResetPassword:title')}</h2>

                        {body}

                    </div>
                </div>
            </div>);
    }
}


const matStateToProps = (state: ApplicationState) => state.login;

// Wire up the React component to the Redux store
export default connect(
    matStateToProps,                    // Selects which state properties are merged into the component's props
    LoginActions.actionCreators        // Selects which action creators are merged into the component's props
)(ResetPasswordPage);
