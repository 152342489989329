
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { ITranslationContext } from '../../../../translations';
import { PaymentReminderConfig } from '../../../../store/pages/paymentReminderConfigurations/types';
import { ClientEmailTemplate } from '../../../../store/pages/emailTemplates/types';
import PaymentReminderConfigList from './paymentReminderConfigList';
import BookingEmailScheduleConfigList from './bookingEmailScheduleConfigList';
import { Venue } from '../../../../store/pages/venues/types';

interface LocalProps {
    venue: Venue;
    isLoadingPaymentReminderConfigs: boolean;
    paymentReminderConfigs: PaymentReminderConfig[];
    isLoadingPaymentReminderEmailTemplates: boolean;
    paymentReminderEmailTemplates: ClientEmailTemplate[];
    editPaymentReminderConfig: () => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
}

const RemindersPanel = (props: LocalProps, context: ITranslationContext) => {
    const { venue, paymentReminderConfigs, isLoadingPaymentReminderConfigs, paymentReminderEmailTemplates, isLoadingPaymentReminderEmailTemplates, editPaymentReminderConfig, showModal} = props;
    return (
        <section className='venue-integrations-panel'>
            <div className='at-panel'>
                <PaymentReminderConfigList venue={venue} paymentReminderConfigs={paymentReminderConfigs} isLoadingPaymentReminderConfigs={isLoadingPaymentReminderConfigs} paymentReminderEmailTemplates={paymentReminderEmailTemplates} isLoadingPaymentReminderEmailTemplates={isLoadingPaymentReminderEmailTemplates} editPaymentReminderConfig={editPaymentReminderConfig} showModal={showModal} />
                <BookingEmailScheduleConfigList venue={venue} />
            </div>
        </section>);
}

RemindersPanel.contextTypes = {
    t: PropTypes.func
}

export default RemindersPanel;
