
import * as React from 'react';

export enum GapPosition {
    Before,
    After
}

interface DiaryReservationGapProps {
    position: GapPosition;
    gapInMinutes: number;
    pixelsPerMinute: number;
    left: string;
    width: string;
    reservationTop: number;
    reservationHeight: number;
}

const DiaryReservationGap = (props: DiaryReservationGapProps) => {
    const { position, gapInMinutes, pixelsPerMinute, left, width, reservationTop, reservationHeight } = props;
    const height = gapInMinutes * pixelsPerMinute;
    const top = position === GapPosition.Before ? reservationTop - (height) : reservationTop + reservationHeight;
    const style = { top: `${top}px`, left: left, width: width, height: `${height}px` }

    return <div className='cal-reservation-gap' style={style}></div>
}

export default DiaryReservationGap;