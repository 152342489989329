
import { ActionsObservable } from 'redux-observable';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as nt from './types';
import { LocationChangeAction } from 'react-router-redux';
import { IStore } from '../..';
import { Observable } from 'rxjs';

export type NavigationActions = nt.NavigationRouteChanged;

export const actionCreators = {
    navigationRouteChanged: (route: string, date: Date) => ({ type: nt.NavigationActionTypes.NavigationRouteChanged, route: route, whenRouteChanged: date }),
}

export const routeChangedEpic = (action$: ActionsObservable<any>, store: IStore) =>
  epic.createDebounced(action$,
      '@@router/LOCATION_CHANGE',
      500,
      action => {
          let path = 'UNKNOWN';
          const locationChangeAction = action as LocationChangeAction;
          if (locationChangeAction) {
              path = locationChangeAction.payload.pathname;
              if (!path) {
                  // the object we are getting doesn't match the typings, 
                  // so this hack is in here to get the correct value from the object
                  const pl = locationChangeAction.payload as any;
                  if (pl && pl.location && pl.location.pathname) {
                      path = pl.location.pathname
                  }
              }
          }

          const now = new Date();
          const screenWidth = window.screen.width;
          const screenHeight = window.screen.height;
          const windowWidth = window.innerWidth;
          const windowHeight = window.innerHeight
          const duration = store.value.navigation.lastChange ? now.getTime() - store.value.navigation.lastChange.getTime() : 0;

          const body = { app: 'AMS', path: path, screenWidth: screenWidth, screenHeight: screenHeight, windowWidth: windowWidth, windowHeight: windowHeight, duration: duration };
          return api.post(`api/v1/appStats/`, body).map(_ => actionCreators.navigationRouteChanged(path, now));
      },
      (err: api.ApiError) => actionCreators.navigationRouteChanged('UNKNOWN', new Date()));