

export interface NavigationState {
    currentRoute: string;
    lastChange: Date;
}

export enum NavigationActionTypes {
    NavigationRouteChanged = 'NAVIGATION_ROUTE_CHANGED',
}

export interface NavigationRouteChanged {
    type: NavigationActionTypes.NavigationRouteChanged;
    route: string;
    whenRouteChanged: Date;
}