
// Nested components taken from here to fix typing issue calling withRouter() on inner component https://github.com/DefinitelyTyped/DefinitelyTyped/issues/17181

import * as React from 'react';
import { withRouter, Redirect, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as H from 'history';
import { ApplicationState } from '../../store/index';
import * as LoginActions from '../../store/pages/login/actions';

interface AuthComponentProps {
    isAuthenticated: boolean;
    isLoggedIn: boolean;
    switchingClient: boolean;
    children: any;
    location: H.Location;
}

class AuthComponent extends React.Component<AuthComponentProps, {}> {
    render() {
        if (this.props.isAuthenticated && this.props.isLoggedIn && !this.props.switchingClient) {
            return this.props.children;
        } else if (this.props.switchingClient) {
            return <div style={{ backgroundColor: '#ccc', color: '#000', textAlign: 'center', paddingTop: '150px', height: '100%', fontSize: '18pt' }}>Switching client, please wait.....</div>
        } else if (!this.props.location.pathname.startsWith('/login')) {
            return <Redirect to='/login' />;
        } 

        return null;
    }
}

interface LocalAuthProps {
    children: any;
    isAuthenticated?: boolean;
    isLoggedIn?: boolean;
    switchingClient: boolean;
    lastActivity: Date;
    autoLogoutInterval: number | null;
    modalShown: boolean;
    modalScreenName: string;
}

interface AuthState {
    lastMouseMove: Date;
}

interface ReduxActions {
    logout: () => void;
}

type AuthProps = LocalAuthProps & ReduxActions & RouteComponentProps<{}>;

const externalModalViews = ['EmailTemplateForm', 'ExternalWindowCoordinator'];

class Auth extends React.Component<AuthProps, AuthState> {


    intervalHandle: NodeJS.Timeout | null;

    constructor(props: AuthProps) {
        super(props);

        this.intervalHandle = null;
        this.state = { lastMouseMove: new Date() }
    }

    componentDidMount() {
        // Check for session timeout every 15 seconds
        this.intervalHandle = setInterval(() => {
            const { autoLogoutInterval, lastActivity, modalShown, modalScreenName, logout } = this.props;
            const { lastMouseMove } = this.state;

            const externalModalShown = modalShown && externalModalViews.includes(modalScreenName);

            if (autoLogoutInterval && !externalModalShown) {
                const activityIntervalInMinutes = Math.floor((new Date().getTime() - lastActivity.getTime()) / 60000);
                const lastMouseMopveInterval = Math.floor((new Date().getTime() - lastMouseMove.getTime()) / 60000);
                if (activityIntervalInMinutes > autoLogoutInterval && lastMouseMopveInterval > autoLogoutInterval) {
                    logout();
                }
            }
        }, 15000);
    }

    componentDidUpdate(prevProps: AuthProps) {
        if (this.props.autoLogoutInterval && this.props.autoLogoutInterval > 0 && (!prevProps.autoLogoutInterval || prevProps.autoLogoutInterval === 0)) {
            document.addEventListener('mousemove', this.handleMouseMove);
        } else if (!this.props.autoLogoutInterval || this.props.autoLogoutInterval === 0) {
            document.removeEventListener('mousemove', this.handleMouseMove);
        }
    }

    handleMouseMove = (e: MouseEvent) => {
        console.log('handled mouse move');
        this.setState({ lastMouseMove: new Date() });
    }

    componentWillUnmount() {
        if (this.intervalHandle) {
            clearInterval(this.intervalHandle);
        }

        if (this.props.autoLogoutInterval) {
            document.removeEventListener('mousemove', this.handleMouseMove);
        }
    }

    render() {
        return <AuthComponent
            children={this.props.children}
            location={this.props.location}
            isAuthenticated={this.props.isAuthenticated || false}
            isLoggedIn={this.props.isLoggedIn || false}
            switchingClient={this.props.switchingClient} />;
    }
}

const matStateToProps = (state: ApplicationState) => ({
    isAuthenticated: state.login.isAuthenticated,
    isLoggedIn: state.login.isLoggedIn,
    switchingClient: state.login.switchingClient,
    lastActivity: state.login.lastActivity,
    autoLogoutInterval: state.login.autoLogoutInterval,
    modalShown: state.modal.showOverlay,
    modalScreenName: state.modal.screenName
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    logout: bindActionCreators(LoginActions.actionCreators.logout, dispatch)
});

export default withRouter(connect(matStateToProps, mapDispatchToProps)(Auth));
