
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as pct from './types'; 
import * as lt from '../login/types';
import { IStore } from '../..';


export type ProductCategoryActions = pct.LoadProductCategories | pct.ReceiveProductCategories | pct.SaveProductCategory | pct.ProductCategorySaved | pct.ProductCategorySaveFailed | pct.EditProductCategory;

export const actionCreators = {
    loadProductCategories: () => ({ type: pct.ProductCategoryActionTypes.RequestProductCategories }),
    loadProductCategoriesComplete: (productCategories: pct.ProductCategory[], err: api.ApiError | null) => ({ type: pct.ProductCategoryActionTypes.ReceivedProductCategories, productCategories: productCategories, error: err }),
    editProductCategory: () => ({ type: pct.ProductCategoryActionTypes.EditProductCategory }),
    saveProductCategory: (isNew: boolean, productCategoryId: string | null, venueId: string, name: string, colour: string, sequence: number, archived: boolean, showOnPointOfSale: boolean, showOnWebShop: boolean, reportingPriority: number | null) =>
        ({ type: pct.ProductCategoryActionTypes.SaveProductCategory, isNew: isNew, productCategoryId: productCategoryId, venueId: venueId, name: name, colour: colour, sequence: sequence, archived: archived, showOnPointOfSale: showOnPointOfSale, showOnWebShop: showOnWebShop, reportingPriority: reportingPriority }),
    productCategorySaved: (productCategoryId: string) => ({ type: pct.ProductCategoryActionTypes.ProductCategorySaved, productCategoryId: productCategoryId }),
    productCategorySaveFailed: (error: api.ApiError) => ({ type: pct.ProductCategoryActionTypes.ProductCategorySaveFailed, error: error })
}

interface IGetProductCategoriesResponse {
    productCategories: pct.ProductCategory[];
}

interface ISaveProductCategoryResponse {
    productCategoryId: string;
}

export const handleClientChange = (store: IStore) => [actionCreators.loadProductCategories]

//https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-after-catching-and-processing-an-error-with-emitting-somethi

const loadProductCategories = () => Observable.defer(() => api.getJson<IGetProductCategoriesResponse>('api/v1/productCategory/'))
    .map(response => actionCreators.loadProductCategoriesComplete(response.productCategories, null));

export const loadProductCategoriesEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        pct.ProductCategoryActionTypes.RequestProductCategories,
        action => loadProductCategories(),
        err => actionCreators.loadProductCategoriesComplete([], err));

export const reloadProductCategoriesEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        pct.ProductCategoryActionTypes.ProductCategorySaved,
        action => loadProductCategories(),
        err => actionCreators.loadProductCategoriesComplete([], err));

export const saveProductCategoryEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        pct.ProductCategoryActionTypes.SaveProductCategory,
        action => {
            const spca = action as pct.SaveProductCategory;
            var body = {
                venueId: spca.venueId,
                name: spca.name,
                colour: spca.colour,
                sequence: spca.sequence,
                archived: spca.archived,
                showOnPointOfSale: spca.showOnPointOfSale,
                showOnWebShop: spca.showOnWebShop,
                reportingPriority: spca.reportingPriority
            };

            return (spca.isNew ? api.post('api/v1/productCategory/', body) : api.put(`api/v1/productCategory/${spca.productCategoryId}`, body))
                .map(response => {

                    let productCategoryId = spca.productCategoryId;
                    if (spca.isNew) {
                        const strr = response.response as ISaveProductCategoryResponse;
                        if (strr) {
                            productCategoryId = strr.productCategoryId;
                        }
                    }

                    return ({ type: pct.ProductCategoryActionTypes.ProductCategorySaved, productCategoryId: productCategoryId });
                });
        },
        (err: api.ApiError) => actionCreators.productCategorySaveFailed(err));
