
import { IEventReservation } from './types';
import ReservationInfoLabel from '../diary/reservationInfoLabel';
import CompetitorCounts from './competitorCounts';
import { ReservationType } from '../../../store/pages/diary/types';

interface ReservationCompetitorCountsProps {
    reservation: IEventReservation;
}

const ReservationCompetitorCounts = (props: ReservationCompetitorCountsProps) => {
    const { reservation } = props;
    return <CompetitorCounts
        bookedParticipants={reservation.bookedParticipants}
        registeredParticipants={reservation.registeredParticipants}
        checkedInParticipants={reservation.checkedInParticipants}
        maxParticipants={reservation.reservationType === ReservationType.Exclusive ? undefined : reservation.maxParticipants}
        />
}

export default ReservationCompetitorCounts;