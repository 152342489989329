
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { ProductCategory } from '../../../store/pages/productCategories/types';
import { ITranslationContext } from '../../../translations';
import { adjustColour, clickHandler } from '../../../utils/util';
import { Product, ProductType } from '../../../store/pages/products/types';
import { VoucherProduct } from '../../../store/pages/vouchers/types';
import { MembershipType } from '../../../store/pages/memberships/types';
import { PosProductCategory } from './types';

interface CategoriesPanelProps {
    venueId: string;
    categories: PosProductCategory[];
    products: Product[];
    vouchers: VoucherProduct[];
    membershipTypes: MembershipType[];
    categorySelected: (categoryId: string) => void;
}

const CategoriesPanel = (props: CategoriesPanelProps, context: ITranslationContext) => {
    const renderCategoryItems = (categories: PosProductCategory[]) => {
        const cats = categories.map(c => {
            var bgCol = adjustColour(c.colour, 140);
            return <li className='pos_category_item text-center' key={c.id} style={({ background: bgCol, border: `solid 2px ${c.colour}` })} onClick={e => clickHandler(e, () => props.categorySelected(c.id))}><span>{c.name}</span></li>;
        });

        return cats;
    }

    return (
        <ul className='list-unstyled pos_category_list'>
            {renderCategoryItems(props.categories)}
        </ul>
    );
}


CategoriesPanel.contextTypes = {
    t: PropTypes.func
}

export default CategoriesPanel;