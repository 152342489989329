
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'
import { ApplicationState } from '../../../../store';
import { ActivityFormatGroup } from '../../../../store/pages/activityFormatGroups/types';
import * as ct from '../../../global/controls';
import * as v from '../../../global/validation';
import * as api from '../../../../store/apiClient';
import * as ActivityFormatGroupActions from '../../../../store/pages/activityFormatGroups/actions';
import * as ModalActions from '../../../../store/global/modal/actions';
import ColourPicker from '../../../global/colourPicker';
import { quillToolbarWithImageAndLinks } from '../../../global/quillSettings';
import { ImageFile, getImageProps } from '../../../../utils/images';
import Dropzone from 'react-dropzone';

import { ValidationError } from '../../../../store/global/types';
import { clickHandler, isNullOrEmpty } from '../../../../utils/util';
import ApiError from '../../../global/apiError';

interface LocalProps {
    isNew: boolean;
    venueId: string;
    activityFormatGroup: ActivityFormatGroup | null;
}

interface MappedReduxState {
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
}

interface Actions {
    saveActivityFormatGroup: (isNew: boolean, activityFormatGroup: ActivityFormatGroup, img: File | null) => void;
    closeModal: () => void;
}

type ActivityFormatGroupFormProps = MappedReduxState & Actions & LocalProps;

interface ActivityFormatGroupFormState {
    name: ct.FormValue<string>;
    sequence: ct.FormValue<number>;
    webDescription: ct.FormValue<string>;
    webDescriptionBgColour: string;
    webDescriptionTextColour: string;
    webDescriptionStyle: React.CSSProperties | undefined;
    imageUrl: string | null;
    image: ImageFile | null;
    imageError: string | null;
    archived: ct.FormValue<boolean>;
}

class ActivityFormatGroupForm extends React.Component<ActivityFormatGroupFormProps, ActivityFormatGroupFormState> {

    constructor(props: ActivityFormatGroupFormProps) {
        super(props);

        this.state = this.buildStateFromProps(this.props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps(props: ActivityFormatGroupFormProps): ActivityFormatGroupFormState {

        const {isNew, activityFormatGroup } = props;

        const webDescriptionBgColour = (isNew || !activityFormatGroup || !activityFormatGroup.webDescriptionBgColour) ? '#000000' : activityFormatGroup.webDescriptionBgColour;
        const webDescriptionTextColour = (isNew || !activityFormatGroup || !activityFormatGroup.webDescriptionTextColour) ? '#ffffff' : activityFormatGroup.webDescriptionTextColour;

        return {
            name: this.validateName((isNew || !activityFormatGroup) ? '' : activityFormatGroup.name),
            sequence: this.validateSequence((isNew || !activityFormatGroup) ? 0 : activityFormatGroup.sequence || 0),
            webDescription: this.validateWebDescription((isNew || !activityFormatGroup || !activityFormatGroup.webDescription) ? '' : activityFormatGroup.webDescription),
            webDescriptionBgColour: webDescriptionBgColour,
            webDescriptionTextColour: webDescriptionTextColour,
            webDescriptionStyle: this.buildWebDescriptionStyle(webDescriptionBgColour, webDescriptionTextColour),
            archived: ct.asFormValue('archived', isNew || !activityFormatGroup || !activityFormatGroup.archived ? false : true),
            imageUrl: (isNew || !activityFormatGroup) ? null : activityFormatGroup.imageUrl,
            image: null,
            imageError: null
        };
    }

    componentDidUpdate(prevProps: ActivityFormatGroupFormProps) {
        const { activityFormatGroup: prevActivityFormatGroup, saveComplete: prevSaveComplete} = prevProps;
        const { activityFormatGroup, saveComplete  } = this.props;

        // Only update state is tax rate has changed
        if ((!prevActivityFormatGroup && activityFormatGroup) || (prevActivityFormatGroup && !activityFormatGroup) || (prevActivityFormatGroup && activityFormatGroup && prevActivityFormatGroup.id !== activityFormatGroup.id)) {
            this.setState(this.buildStateFromProps(this.props));
        }

        if (saveComplete && !prevSaveComplete) {
            setTimeout(() => { this.close(); }, 750);
        }
    }

    saveProductCategory = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }
    
    close = () => {
        this.props.closeModal();
    }

    save = () => {
        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {
            const { isNew, activityFormatGroup, venueId } = this.props;
            const { name, webDescription, webDescriptionBgColour, webDescriptionTextColour, sequence, archived, image } = this.state;

            const activityFormatGroupId = isNew || !activityFormatGroup ? null : activityFormatGroup.id;
            this.props.saveActivityFormatGroup(isNew, {
                id: activityFormatGroupId || '',
                venueId: venueId,
                name: name.value,
                sequence: sequence.value,
                webDescription: webDescription.value,
                webDescriptionBgColour: webDescriptionBgColour,
                webDescriptionTextColour: webDescriptionTextColour,
                archived: archived.value,
                imageId: null,
                imageUrl: null
            }, image ? image.file : null);
        }
    }

    webDescriptionChanged = (val: string) => this.setState({ webDescription: this.validateWebDescription(val) });

    validateName = (val: string) => v.validate(val, 'name', [v.required], this.props.validationErrors);
    validateSequence = (val: number) => v.validate(val, 'sequence', [v.numeric], this.props.validationErrors);
    validateWebDescription = (val: string) => v.validate(val, 'webDescription', [], this.props.validationErrors);

    onImageDrop = (files: File[]) => {
        const file = files[0];

        getImageProps(file).then(img => {
            if (img.width < 400 || img.width > 800) {
                this.setState({ imageError: 'ActivityFormatForm:imageWidth' });
            } else if (img.height > 800) {
                this.setState({ imageError: 'ActivityFormatForm:imageHeight' });
            } else {
                this.setState({ imageError: null, image: { file: file, preview: URL.createObjectURL(file) } });
            }
        });
    }

    buildWebDescriptionStyle = (webDescriptionBgColour: string, webDescriptionTextColour: string): React.CSSProperties => ({ backgroundColor: webDescriptionBgColour, color: webDescriptionTextColour });

    render() {

        const { t } = this.context;
        const { isNew, activityFormatGroup, saveError, saveComplete } = this.props;
        const { name, sequence, webDescription, webDescriptionTextColour, webDescriptionBgColour, webDescriptionStyle, archived, imageUrl, image, imageError } = this.state;

        let message: any;

        if (saveError) {
            message = <ApiError error={saveError} />;
        } else if (saveComplete) {
            message = (<div className='bg-success'>{t('Global:saveComplete')}</div>);
        }

        let previewImg: JSX.Element | null = null;
        const previewImageUrl = image ? image.preview : imageUrl !== null ? imageUrl : null;
        if (previewImageUrl) {
            previewImg = <img src={previewImageUrl} className='file-preview' alt='preview' style={({ width: '100px' })}></img>;
        }

        return <div className='productCategoryPage'>
            <h1 className='activityFormatForm_title'>{isNew ? t('ActivityFormatGroupForm:addActivityFormatGroup') : t('ActivityFormatGroupForm:editActivityFormatGroup')}</h1>

            {isNew || !activityFormatGroup
                ? null
                : <div className='mb-15'>
                    <label>{t('ActivityFormatGroupForm:groupId')}</label>
                    <div>{activityFormatGroup.id}</div>
                  </div>}

            <form className='data-form' onSubmit={this.saveProductCategory} autoComplete='off'>
                <ct.TextBox id='name' labelKey='Global:name' placeholderKey='Global:name' value={name} callback={val => this.setState({ name: this.validateName(val) })} />

                <ct.NumberBox id='sequence' labelKey='ActivityFormatForm:sequence' placeholderKey='ActivityFormatForm:sequence' value={sequence} callback={val => this.setState({ sequence: this.validateSequence(val || 0) })} min='0' />

                <section className='form-panel'>
                    <h4 className='form-section-heading'>{t('ActivityFormatForm:webDescription')}</h4>
                    <ColourPicker labelKey='ActivityFormatForm:websiteDescriptionBackgroundColour' colourHex={webDescriptionBgColour} onColourChanged={color => this.setState(s => ({ webDescriptionBgColour: color, webDescriptionStyle: this.buildWebDescriptionStyle(color, s.webDescriptionTextColour) }))} />
                    <ColourPicker labelKey='ActivityFormatForm:websiteDescriptionTextColour' colourHex={webDescriptionTextColour} onColourChanged={color => this.setState(s => ({ webDescriptionTextColour: color, webDescriptionStyle: this.buildWebDescriptionStyle(s.webDescriptionBgColour, color) }))} />
                    <ct.HtmlInput id='htmlTemplate' labelKey='' value={webDescription} callback={this.webDescriptionChanged} modules={quillToolbarWithImageAndLinks} editorStyle={webDescriptionStyle} inlineStyles={true} />

                    <label>{t('ActivityFormatForm:activityFormatImage')}</label>
                    <div>
                        <Dropzone multiple={false} accept={{ 'image/*': ['.png', '.gif', '.jpeg', '.jpg'] }} onDrop={this.onImageDrop}>
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()} className='file-drop'>
                                    <input {...getInputProps()} />
                                    <p>{t('Global:imageDropText')}</p>
                                </div>
                            )}
                        </Dropzone>
                        {previewImg}
                        {isNullOrEmpty(imageError) ? null : <div className='alert alert-danger'>{t(imageError)}</div>}
                    </div>
                </section>

                <ct.Checkbox id='archived' labelKey='Global:archive' value={archived} callback={val => this.setState({ archived: ct.asFormValue('archived', val) })} />

                {message}

                <p />
                <div className='btn-toolbar'>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)}>{t('Global:save')}</button> 
                    <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{t('Global:cancel')}</button> 
                </div>
            </form>
        </div>;
    }
};

const mapStateToProps = (state: ApplicationState) => ({
    isSaving: state.activityFormatGroups.isSaving,
    saveComplete: state.activityFormatGroups.saveComplete,
    saveError: state.activityFormatGroups.saveError,
    validationErrors: state.activityFormatGroups.validationErrors,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    saveActivityFormatGroup: bindActionCreators(ActivityFormatGroupActions.actionCreators.saveActivityFormatGroup, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,           // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(ActivityFormatGroupForm);
