

import * as React from 'react';
import { Time } from '../../../store/global/types';
import { DiaryReservation } from '../../../store/pages/diary/types';
import { findBreaksForDay, Resource } from '../../../store/pages/resources/types';
import { Venue } from '../../../store/pages/venues/types';
import { formatTime } from '../../../utils/util';

import DiaryResource from './diaryResource';


interface InlineCalendarResourceProps {
    slotSizeInMinutes: number;
    slotHeight: number;
    date: Date;
    startTime: number;
    endTime: number;
    scrollToTime?: Time;
    venue: Venue;
    resource: Resource;
    width: number;
    reservations: DiaryReservation[];
    createNewReservation: (resource: Resource, startTime: Date, endTime: Date) => void;
    onReservationSelected: (reservationId: string, eventId: string) => void;
}

class InlineCalendarResource extends React.Component<InlineCalendarResourceProps> {

    scrollDiv: HTMLDivElement | null;

    constructor(props: InlineCalendarResourceProps) {
        super(props);

        this.scrollDiv = null;
    }

    componentDidMount() {
        const { scrollToTime } = this.props;

        if (scrollToTime && this.scrollDiv) {
            const top = this.timeToPixel(scrollToTime);
            this.scrollDiv.scrollTop = top;
        }
    }

    timeToPixel = (time: Time) => {
        const { slotSizeInMinutes, slotHeight, startTime } = this.props;
        const pixelsPerMinute = slotHeight / slotSizeInMinutes;

        const slotTop = ((time.getMinutes() + (time.getHours() * 60)) * pixelsPerMinute);
        const top = slotTop - (startTime * 60 * pixelsPerMinute);
        return top;
    }

    render() {

        const { date, startTime, endTime, slotSizeInMinutes, slotHeight, scrollToTime, reservations,
            resource, createNewReservation, onReservationSelected, venue } = this.props;

        interface time { val: string; hour: boolean };
        const times: time[] = [];
        for (let h = startTime; h < endTime; h++) {
            for (let m = 0; m < 60; m += slotSizeInMinutes) {
                times.push({ val: formatTime(h, m, venue.timeFormat), hour: m === 0 });
            }
        }

        const slots = times.length;
        const lineHeightStyle = { lineHeight: `${slotHeight}px` };
        const eventStyle = { ...lineHeightStyle, height: `${slots * slotHeight}px` };
        const timeItems = times.slice(1).map(t => <div key={t.val} className={t.hour ? 'cal-hour cal-time' : 'cal-time'}>{t.val}</div>);

        return <div className='inline-calendar-wrapper' ref={(div: HTMLDivElement) => { this.scrollDiv = div; }}>
            <div className='flex flex-row' style={eventStyle}>
                <div className='cal-times' style={({ flex: '0 0 auto' })}>{timeItems}</div>
                <div style={({ flex: '1 1 auto' })}>
                    <DiaryResource
                        venue={venue}
                        addReservation={createNewReservation}
                        date={date}
                        preSelectTime={scrollToTime}
                        startTime={startTime}
                        endTime={endTime}
                        reservations={reservations.sort((r1, r2) => r1.startTime.getTime() - r2.startTime.getTime())}
                        resource={resource}
                        breaks={findBreaksForDay(resource, date)}
                        slotHeight={slotHeight}
                        slotSizeInMinutes={slotSizeInMinutes}
                        editReservation={onReservationSelected}
                        width={100}
                    />
                </div>
            </div>
        </div>
    }
}

export default InlineCalendarResource;