
import { ActivityFormat } from '../../../store/pages/activityFormats/types';
import { CustomerCategoryCount, ReservationType } from '../../../store/pages/diary/types';
import { RegistrationEventBookingNote } from '../../../store/pages/reception/types';

export enum PanelStatus {
    None = 0,
    Collapsed = 1,
    Expanded = 2
}

export interface IEventReservation {
    reservationId: string;
    activityFormat?: ActivityFormat;
    maxCompetitors: number;
    activityFormatVariationId: string;
    activityFormatVariationScheduleId: string;
    resourceId: string;
    resourceConfigurationId: string | null;
    activityFormatId: string;
    reservationType: ReservationType;
    resourceName: string;
    resourceShortName: string;
    resourceColour: string;
    maxParticipants: number;
    isBookable: boolean;
    bookedParticipants: CustomerCategoryCount[];
    confirmedParticipants: CustomerCategoryCount[];
    registeredParticipants: CustomerCategoryCount[];
    checkedInParticipants: CustomerCategoryCount[];
    startTime: Date;
    endTime: Date;
    flagged: boolean;
    notes: string;
}

export interface IBooking {
    id: string;
    organiserCustomerId: string;
    organiserFirstName: string;
    organiserLastName: string;
    organiserCompanyName: string;
    organiserPhoneNumber: string;
    organiserPostcode: string;
    organiserLastRegistrationId: string;
    billId: string;
    billNumber: string;
    bookedParticipants: CustomerCategoryCount[];
    registeredParticipants: CustomerCategoryCount[];
    checkedInParticipants: CustomerCategoryCount[];
    totalAmount: number;
    paidAmount: number;
    outstandingAmount: number;
    flagged: boolean;
    notes: RegistrationEventBookingNote[];
}

export interface IEvent {
    id: string;
    venueId: string;
    name: string;
    colour: string;
    startTime: Date;
    reservations: IEventReservation[];
    bookings: IBooking[];
    numberOfRegistrations: number;
    outstandingBalance: number;
    flagged: boolean;
    allowMultipleCategoriesPerCustomer: boolean;
}