
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';
import * as api from '../../../store/apiClient';

import { Membership } from '../../../store/pages/memberships/types';
import { clickHandler, isNullOrEmpty, parseLocalDateTime } from '../../../utils/util';
import { FindCustomerSummary } from '../../../store/pages/customer/types';
import Loading from '../../global/loading';
import Pagination from '../../global/pagination';
import ApiError from '../../global/apiError';
import { DateFormat } from '../../../store/pages/venues/types';
import { ICustomerSearchResponse } from '../../../store/pages/diary/actions';
import { CustomerSearchResult } from '../../../store/pages/diary/types';
import TagList from '../../global/tagList';

interface TransferMembershipProps {
    membership: Membership;
    dateFormat: DateFormat;
    close: (transferred: boolean) => void;
    logout: () => void;
}

interface TransferMembershipState {
    searchTerm: string;
    searchResults: CustomerSearchResult[];
    newCustomerId: string | null;
    searching: boolean;
    searchError: api.ApiError | null;
    transferring: boolean;
    transferError: api.ApiError | null;
}

export default class TransferMembership extends React.Component<TransferMembershipProps, TransferMembershipState> {

    constructor(props: TransferMembershipProps) {
        super(props);

        this.state = {
            searchTerm: '',
            searchResults: [],
            newCustomerId: null,
            searching: false,
            searchError: null,
            transferring: false,
            transferError: null
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    clearSearch = () => {
        this.setState({ searchTerm: '', searchResults: [] })
    }

    search = () => {
        const { searchTerm } = this.state;

        this.setState({ searching: true, transferError: null });

        api.getWithAuth<ICustomerSearchResponse>(`api/v1/customer/search?searchTerm=${searchTerm}`, this.props.logout)
            .subscribe(
                res => this.setState({
                    searching: false,
                    searchResults: res.customers.map(c => ({
                        ...c,
                        lastEvent: c.lastEvent ? { ...c.lastEvent, date: parseLocalDateTime(c.lastEvent.date) } : null,
                        lastBooking: c.lastBooking ? { ...c.lastBooking, date: parseLocalDateTime(c.lastBooking.date) } : null
                    })),
                    searchError: null
                }),
                err => this.setState({ searching: false, searchResults: [], searchError: err }))
    }

    transferToSelectedCustomer = (cus: CustomerSearchResult) => {
        const { membership, logout, close } = this.props;
        this.setState({ transferring: true }, () => {
            api.postWithAuth(`api/v1/membership/${membership.id}/transfer`, { customerId: cus.customerId }, logout).subscribe(
                res => close(true),
                err => this.setState({ transferring: false, transferError: err }))
        });
    }

    render() {
        const { t } = this.context;
        const { searchTerm, searching, transferError } = this.state;
        const { close } = this.props;

        return <div>
            <h3 className='text-center'>{t('MembershipDetails:transfermembership')}</h3>

            <div className='flex flex-nowrap flex-center'>
                <div className='flex-stretch'>
                    <ct.TextBox id='name' labelKey='customerDetails:nameSearchLabel' placeholderKey='customerDetails:nameSearchLabel' value={ct.asFormValue('name', searchTerm)} callback={val => this.setState({ searchTerm: val })} />
                </div>
                <button className='btn btn-primary flex-shrink ml-10' onClick={e => clickHandler(e, this.search)} disabled={searching}>{t('Global:search')}</button>
                <button className='btn btn-warning flex-shrink ml-10' onClick={e => clickHandler(e, this.clearSearch)} disabled={searching}>{t('Global:clear')}</button>
            </div>

            {searching ? <Loading /> : this.renderResultsTable()}

            {transferError ? <ApiError error={transferError} /> : null }

            <div className='row button-panel'>
                <button onClick={e => clickHandler(e, () => close(false))} className='btn btn-basic'>{t('Global:cancel')}</button>
            </div>
        </div>
    }

    renderResultsTable = () => {
        const { t } = this.context;
        const { dateFormat } = this.props;
        const { searching, searchResults, searchError, transferring } = this.state;

        if (searchError) {
            return <ApiError error={searchError} />
        }
        if (searchResults.length === 0 && !searching) {
            return <div className='alert alert-info'>{t('CustomersPage:enterSearchTerm')}</div>
        }

        const rows = searchResults.map(cust =>
            <tr key={cust.customerId}>
                <td><button className='btn btn-primary' onClick={e => clickHandler(e, () => this.transferToSelectedCustomer(cust))} disabled={transferring}>{t('MembershipDetails:transfer')}</button></td>
                <td>{this.formatCustomer(cust)}</td>
                <td>{cust.emailAddress}</td>
                <td>{cust.phoneNumber}</td>
                <td>{cust.postalCode}</td>
                <td>{cust.lastBooking ? <div style={{ marginBottom: '2px' }}>{cust.lastBooking.date.toAbbrDateString(dateFormat, t)} {isNullOrEmpty(cust.lastBooking.venueName) ? null : <span className='label label-default'>{cust.lastBooking.venueName}</span>}</div> : ''}</td>
                <td>{cust.companyName}</td>
                <td>{cust.hasActiveMembership}</td>
                <td><TagList tags={cust.tags} /></td>
            </tr>
        );

        return <div className='panel'>
            <div className='table-responsive'>
                <table className='table table-hover customer-search-table table-condensed'>
                    <thead>
                        <tr key='customer-search-header'>
                            <th></th>
                            <th>{t('CustomerSearch:nameHeading')}</th>
                            <th>{t('CustomerSearch:emailHeading')}</th>
                            <th>{t('CustomerSearch:phoneHeading')}</th>
                            <th>{t('CustomerSearch:postcodeHeading')}</th>
                            <th>{t('CustomerSearch:lastEventHeading')}</th>
                            <th>{t('CustomerSearch:companyHeading')}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>
        </div>
    }

    formatCustomer = (val: CustomerSearchResult | null) => val ? `${val.firstname} ${val.lastname}` : '';
}