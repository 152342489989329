
import * as React from 'react';
import * as PropTypes from 'prop-types'
import { Booking, BookingCustomerInfo } from './types';
import { isNullOrEmpty, clickHandler, generateTempId } from '../../../utils/util';
import { formatCustomerName, WebBookingStatus } from '../../../store/pages/diary/types';
import { CustomerCategory } from '../../../store/pages/customerCategories/types';
import { ITranslationContext } from '../../../translations';
import Flag from '../../icons/flag';

interface EventCustomerListItemProps {
    bookingInfo: BookingCustomerInfo;
    customerCategories: CustomerCategory[];
    index: number;
    isSelected: boolean;
    removeBooking: (index: number) => void;
    confirmRemoveBooking: (index: number) => void;
    cancelRemoveBooking: (index: number) => void;
    selectBooking: (booking: Booking) => void;
    editBookingQuestions: (booking: Booking) => void;
}

const EventCustomerListItem = (props: EventCustomerListItemProps, translationContext: ITranslationContext) => {

    const { bookingInfo, customerCategories, isSelected, editBookingQuestions } = props;
    const { t } = translationContext;
    const { booking, customer } = bookingInfo;

    const selectBooking = (e: React.MouseEvent<HTMLElement>) => clickHandler(e, () => props.selectBooking(booking));

    const company = !customer || isNullOrEmpty(customer.companyName) ? null : <div className='eventCustomerList-company'>{`${customer.companyName}`}</div>;

    const billOutstanding = bookingInfo.outstandingBalance ? `${t('PointOfSale:balance')}: ${t('Global:currencySymbol')}${bookingInfo.outstandingBalance.toFixed(2)}` : '';

    const billTotal = bookingInfo.totalAmount ? `${t('PointOfSale:total')}: ${t('Global:currencySymbol')}${bookingInfo.totalAmount.toFixed(2)}` : '';

    const competitorCount = bookingInfo.booking.cancelled
        ? <td className='text-right'><label key={`${bookingInfo.booking.id}_cancelledLbl`} className='label label-danger'>{t('Global:cancelled')}</label></td>
        : customerCategories.map(cc => <td key={`${bookingInfo.booking.id}_${cc.id}`} className='text-right'>{bookingInfo.customerCategoryCounts[cc.code]}</td>);

    const info: JSX.Element[] = [];
    if (booking.isWebBooking) {
        info.push(<label key={`${booking.id}_completeLbl`} className={`label label-${booking.webBookingComplete ? 'success' : 'default'}`}>{t('Global:webBooking')}</label>)

        if (!booking.webBookingComplete && !booking.cancelled) {
            if ((booking.webBookingStatus & WebBookingStatus.PaymentFailed) === WebBookingStatus.PaymentFailed) info.push(<label key={`${booking.id}_pymtFailedLbl`} className={'label label-warning'}>{t('WebBookingStatus:PaymentFailed')}</label>);
            else if ((booking.webBookingStatus & WebBookingStatus.PaymentStarted) === WebBookingStatus.PaymentStarted) info.push(<label key={`${booking.id}_pymtStartedLbl`} className={'label label-info'}>{t('WebBookingStatus:PaymentStarted')}</label>);
            else if ((booking.webBookingStatus & WebBookingStatus.NewCustomerAdded) === WebBookingStatus.NewCustomerAdded) info.push(<label key={`${booking.id}_newCustLbl`} className={'label label-info'}>{t('WebBookingStatus:NewCustomerAdded')}</label>);
            else if ((booking.webBookingStatus & WebBookingStatus.ExistingCustomerAdded) === WebBookingStatus.ExistingCustomerAdded) info.push(<label key={`${booking.id}_custAddedLbl`} className={'label label-info'}>{t('WebBookingStatus:ExistingCustomerAdded')}</label>);
            else if ((booking.webBookingStatus & WebBookingStatus.Created) === WebBookingStatus.Created) info.push(<label key={`${booking.id}_createdLbl`} className={'label label-info'}>{t('WebBookingStatus:Created')}</label>);
        }
    }

    if (booking.flagged) {
        info.push(<Flag width={20} height={20} colour='#d9534f' />);
    }

    const extraInfo = booking.customFields.length > 0
        ? booking.customFields.map(f => <div className='small' key={`${booking.id}_${f.customFieldName}`}><label>{f.customFieldName}</label>: {f.value}</div>).concat(<div className='small' key={`${booking.id}_edit_questions`}><button className='btn btn-sm' onClick={e => clickHandler(e, () => editBookingQuestions(booking))}>{t('Global:editQuestions')}</button></div>)
        : null;

    const rowCls = isSelected ? booking.cancelled ? 'selected-item-cancelled' : 'selected-item' : '';

    return (
        <>
            <tr key={booking.key} className={rowCls}>
                <td className='expand' onClick={selectBooking}>
                    <div>
                        <div className='eventCustomerList-name'>{formatCustomerName(booking.customer, t('Global:unconfirmedWebBooking'))}</div>
                        {company}
                    </div>
                </td>
                {competitorCount}
            </tr>
            <tr key={`${booking.key}_row2`} className={rowCls}>
                <td className='table-second-row' onClick={selectBooking}>
                    <div>{extraInfo}</div>
                    <div className='flex flex-row'>{info}</div>
                </td>
                <td className='table-second-row text-right shrink' onClick={selectBooking} colSpan={customerCategories.length}>
                    <div>{billTotal}</div>
                    <div className='text-danger'>{billOutstanding}</div>
                </td>
            </tr>
        </>
    );
}

EventCustomerListItem.contextTypes = {
    t: PropTypes.func
}

export default EventCustomerListItem;

