
// https://blog.angularindepth.com/how-to-reduce-action-boilerplate-90dc3d389e2b

import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as pwst from './types';
import * as lt from '../login/types';
import { IStore } from '../..';

export type PublicWebsiteSettingsActions = pwst.LoadPublicWebsiteSettings | pwst.ReceivePublicWebsiteSettings | pwst.SavePublicWebsiteSettings | pwst.PublicWebsiteSettingsSaved | pwst.PublicWebsiteSettingsSaveFailed | pwst.EditPublicWebsiteSettings;

export const actionCreators = {
    loadPublicWebsiteSettings: () => ({ type: pwst.PublicWebsiteSettingsActionTypes.RequestPublicWebsiteSettings }),
    loadPublicWebsiteSettingsComplete: (publicWebsiteSettings: pwst.VenuePublicWebsiteSettings[], err: api.ApiError | null) => ({ type: pwst.PublicWebsiteSettingsActionTypes.ReceivedPublicWebsiteSettings, publicWebsiteSettings: publicWebsiteSettings, error: err }),
    editPublicWebsiteSettings: () => ({ type: pwst.PublicWebsiteSettingsActionTypes.EditPublicWebsiteSettings }),
    savePublicWebsiteSettings: (publicWebsiteSettings: pwst.VenuePublicWebsiteSettings, backgroundImg: File | null, headerImg: File | null) => ({ type: pwst.PublicWebsiteSettingsActionTypes.SavePublicWebsiteSettings, publicWebsiteSettings: publicWebsiteSettings, backgroundImg: backgroundImg, headerImg: headerImg }),
    publicWebsiteSettingSaved: (venueId: string) => ({ type: pwst.PublicWebsiteSettingsActionTypes.PublicWebsiteSettingsSaved, venueId: venueId }),
    publicWebsiteSettingSaveFailed: (error: api.ApiError) => ({ type: pwst.PublicWebsiteSettingsActionTypes.PublicWebsiteSettingsSaveFailed, error: error })
}

interface WebsiteSetting extends pwst.VenuePublicWebsiteSettings {
    lastUpdated: Date | string | null;
}

interface IGetPublicWebsiteSettingsResponse {
    publicWebsiteSettings: WebsiteSetting[];
}

interface ISaveClientPublicWebsiteSettingsResponse { }

export const handleClientChange = (store: IStore) => [actionCreators.loadPublicWebsiteSettings]

//https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-after-catching-and-processing-an-error-with-emitting-somethi

const loadPublicWebsiteSettings = () => Observable.defer(() => api.getJson<IGetPublicWebsiteSettingsResponse>('api/v1/publicWebsite/pagesettings'))
    .map(response => actionCreators.loadPublicWebsiteSettingsComplete(response.publicWebsiteSettings.map(s => ({...s, lastUpdatedTimestamp: s.lastUpdated ? new Date(s.lastUpdated).getTime() : 0})), null));

export const loadPublicWebsiteSettingsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        pwst.PublicWebsiteSettingsActionTypes.RequestPublicWebsiteSettings,
        action => loadPublicWebsiteSettings(),
        err => actionCreators.loadPublicWebsiteSettingsComplete([], err));

export const reloadPublicWebsiteSettingsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        pwst.PublicWebsiteSettingsActionTypes.PublicWebsiteSettingsSaved,
        action => loadPublicWebsiteSettings(),
        err => actionCreators.loadPublicWebsiteSettingsComplete([], err));

export const savePublicWebsiteSettingsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        pwst.PublicWebsiteSettingsActionTypes.SavePublicWebsiteSettings,
        action => {
            const spws = action as pwst.SavePublicWebsiteSettings;
            const venueId = spws.publicWebsiteSettings.venueId;
            const backgroundImg = spws.backgroundImg;
            const headerImg = spws.headerImg;

            const imageObservables: Observable<string>[] = [
                backgroundImg ? api.uploadFile(backgroundImg, 'api/v1/clientimage').map(res => res.response.imageId) : Observable.of(null),
                headerImg ? api.uploadFile(headerImg, 'api/v1/clientimage').map(res => res.response.imageId) : Observable.of(null)
            ];

            const images = Observable.forkJoin(imageObservables);
            return images.flatMap(imgIds => {
                var body = {
                    backgroundImageId: imgIds[0],
                    headerImageId: imgIds[1],
                    publicWebsitePageSettings: spws.publicWebsiteSettings
                };

                return api.post('api/v1/publicWebsite/pagesettings', body)
                    .map(response => actionCreators.publicWebsiteSettingSaved(venueId));
            })
        },
        (err: api.ApiError) => actionCreators.publicWebsiteSettingSaveFailed(err));
