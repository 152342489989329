
import { Action } from 'redux';
import { LocationChangeAction } from 'react-router-redux';
import { ModalActionTypes, ModalState } from './types';
import { ModalActions } from './actions'
import { createReducer } from '../../reducer';

const unloadedState: ModalState = { showOverlay: false, resetScroll: false, overlayComponent: null, screenName: '' };

export const reducer = (state: ModalState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: ModalState, incomingAction: Action) : ModalState => {

    // Always clear the modal when the route changes
    if (incomingAction.type === '@@router/LOCATION_CHANGE') {
        const locationChangeAction = incomingAction as LocationChangeAction;
        if (locationChangeAction) {
            return unloadedState;
        }
    }

    const action = incomingAction as ModalActions;
    switch (action.type) {
        case ModalActionTypes.ShowModal:
            return {
                showOverlay: true,
                resetScroll: true,
                noScroll: action.noScroll,
                overlayComponent: action.overlayComponent,
                screenName: action.screenName
            };
        case ModalActionTypes.CloseModal:
            return unloadedState;
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};



