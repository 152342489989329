import { ValidationError } from '../../global/types';
import * as api from '../../apiClient';

export interface PaymentReminderConfig {
    id: string;
    venueId: string;
    name: string;
    timeOffsetInDays: number;
    clientEmailTemplateId: string;
    triggerType: PaymentReminderTriggerType;
    paymentTypes: PaymentReminderPaymentTypes;
    queueEmailImmediately: boolean;
    archived: boolean;
}

export enum PaymentReminderPaymentTypes {
    None = 0,
    Deposit = 1 << 0,
    NonDeposit = 1 << 1,
    AllPayments = Deposit | NonDeposit
}

export enum PaymentReminderTriggerType {
    DueDate = 1,
    CreationDate = 2
}

export interface PaymentReminderConfigState {
    isLoading: boolean;
    paymentReminderConfigs: PaymentReminderConfig[];
    loadError: api.ApiError | null;
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
}

export enum PaymentReminderConfigActionTypes {
    RequestPaymentReminderConfigs = 'REQUEST_PAYMENT_REMINDER_CONFIGS',
    ReceivedPaymentReminderConfigs = 'RECEIVE_PAYMENT_REMINDER_CONFIGS',
    SavePaymentReminderConfig = 'SAVE_PAYMENT_REMINDER_CONFIG',
    PaymentReminderConfigSaved = 'PAYMENT_REMINDER_CONFIG_SAVED',
    PaymentReminderConfigSaveFailed = 'PAYMENT_REMINDER_CONFIG_SAVE_FAILED',
    EditPaymentReminderConfig = 'EDIT_PAYMENT_REMINDER_CONFIG'
}

export interface LoadPaymentReminderConfigs {
    type: PaymentReminderConfigActionTypes.RequestPaymentReminderConfigs;
}

export interface ReceivePaymentReminderConfigs {
    type: PaymentReminderConfigActionTypes.ReceivedPaymentReminderConfigs;
    paymentReminderConfigs: PaymentReminderConfig[];
    error: api.ApiError | null;
}

export interface SavePaymentReminderConfig {
    type: PaymentReminderConfigActionTypes.SavePaymentReminderConfig;
    isNew: boolean;
    paymentReminderConfigId?: string;
    paymentReminderConfig: PaymentReminderConfig;
}

export interface PaymentReminderConfigSaved {
    type: PaymentReminderConfigActionTypes.PaymentReminderConfigSaved;
    paymentReminderConfigId: string;
}

export interface PaymentReminderConfigSaveFailed {
    type: PaymentReminderConfigActionTypes.PaymentReminderConfigSaveFailed;
    error: api.ApiError;
}

export interface EditPaymentReminderConfig {
    type: PaymentReminderConfigActionTypes.EditPaymentReminderConfig
}
