
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';
import * as api from '../../../store/apiClient';

import { Bill, BillPayment, BillRefund, PaymentStatus } from '../../../store/pages/pointOfSale/types';
import { PaymentMethod, PaymentMethodType } from '../../../store/pages/paymentMethods/types';
import BillPanel from './billPanel';
import { round, isNullOrEmpty, calculatePercentage, clickHandler } from '../../../utils/util';
import SchedulePayment from './schedulePayment'
import RefundForm from './refundForm';
import { Booking } from '../diary/types';
import { CustomerCategory } from '../../../store/pages/customerCategories/types';
import ApiError from '../../global/apiError';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';

interface PointOfSalePaymentsPanelProps {
    bill: Bill;
    booking: Booking | null;
    isRefund: boolean;
    isFirstPayment: boolean;
    payment: BillPayment | null;
    paymentAmount?: number;
    paymentInProgrss: boolean;
    paymentError: api.ApiError | null;
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
    recordPayment: (billId: string, paymentMethod: PaymentMethod, amount: number, paymentId: string | null, text: string | null, paymentComplete: (success: boolean, bill: Bill | null, error: api.ApiError | null) => void) => void;
    schedulePayment: (billId: string, amount: number, description: string, dueDate: Date, isSecurityPayment: boolean, paymentComplete: (success: boolean, bill: Bill | null, error: api.ApiError | null) => void) => void;
    editRefund: (refund: BillRefund) => void;
    paymentMethods: PaymentMethod[];
    customerCategories: CustomerCategory[];
    showOverlay: (component: JSX.Element) => void;
    closeOverlay: () => void;
    close: (bill: Bill | null) => void;
    openTill: () => void;
}

enum InputSource {
    None,
    NumberPad,
    FixedButton
}

enum NumberMode {
    Normal = 0,
    Decimals = 1
}

interface PointOfSalePaymentsPanelState {
    amountToPay: string;
    mode: NumberMode;
    balanceIncludingDuePayments: number;
    balance: number;
    lastInputSource: InputSource;
    showChange: boolean;
    changeAmount: number;
    paid: boolean;
    errorKey: string | null;
    errorArgs: any | null;
    error: api.ApiError | null
}

export default class PointOfSalePaymentsPanel extends React.Component<PointOfSalePaymentsPanelProps, PointOfSalePaymentsPanelState> {

    private amountInput: React.RefObject<HTMLInputElement>;

    constructor(props: PointOfSalePaymentsPanelProps) {
        super(props);

        const { payment, paymentAmount } = props;

        this.amountInput = React.createRef();

        const { balance, balanceIncludingDuePayments } = this.calculateBalance(payment ? payment : null, props.isRefund, props.bill);

        this.state = {
            amountToPay: payment ? payment.amount.toFixed(2) : paymentAmount ? paymentAmount.toFixed(2) : '',
            mode: NumberMode.Normal,
            balanceIncludingDuePayments: Math.max(0, balanceIncludingDuePayments),
            balance: Math.max(0, balance),
            lastInputSource: InputSource.None,
            showChange: false,
            changeAmount: 0,
            paid: false,
            errorKey: null,
            errorArgs: null,
            error: null
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        if (this.amountInput.current) {
            this.amountInput.current.focus();
        }
    }

    calculateBalance = (payment: BillPayment | null, isRefund: boolean, bill: Bill) => {
        let balance = 0;
        let balanceIncludingDuePayments = 0;

        if (isRefund) {
            balance = bill.payments.filter(p => p.paid
                && !p.void
                && !p.deleted
                && p.status != PaymentStatus.Failed
                && p.status != PaymentStatus.GatewayCancelled
                && p.status != PaymentStatus.GatewayFailed
                && p.status != PaymentStatus.GatewayInProgress)
                .reduce((ttl, p) => ttl + p.amount, 0);

            balanceIncludingDuePayments = balance;
        } else if (payment) {
            balance = payment.amount;
            balanceIncludingDuePayments = balance;
        } else {
            balance = bill.outstandingBalance;
            balanceIncludingDuePayments = bill.outstandingBalance - bill.dueAmount;
        }

        return { balance, balanceIncludingDuePayments };
    }

    getEnteredValue = () => {
        const { amountToPay } = this.state;

        if (isNullOrEmpty(amountToPay))
            return 0;

        const parsed = parseFloat(amountToPay);

        return isNaN(parsed) ? 0 : parsed;
    }

    getEnteredValueAsInt = () => {
        const { amountToPay } = this.state;

        if (isNullOrEmpty(amountToPay))
            return 0;

        const floatVal = parseFloat(amountToPay);
        const parsed = !isNaN(floatVal) && floatVal < 1 ? Math.round(floatVal * 100) : parseInt(amountToPay);

        return isNaN(parsed) ? 0 : parsed;
    }

    incrementCharged = (val: number) => {
        const toPay = this.getEnteredValue();
        this.setState({ amountToPay: (toPay + val).toFixed(2), lastInputSource: InputSource.FixedButton, mode: NumberMode.Normal, errorKey: null, errorArgs: null, error: null });
    }

    calculateFraction = (denominator: number) => {
        if (denominator > 0) {
            const { balance } = this.state;
            const val = round(balance / denominator, 2);
            this.setState({ amountToPay: (val).toFixed(2), lastInputSource: InputSource.FixedButton, mode: NumberMode.Normal, errorKey: null, errorArgs: null, error: null });
        }
    }

    calculatePercentage = (val: number) => {
        if (val > 0) {
            const { balance } = this.state;
            const percentageVal = calculatePercentage(balance, val);
            this.setState({ amountToPay: (percentageVal).toFixed(2), lastInputSource: InputSource.FixedButton, mode: NumberMode.Normal, errorKey: null, errorArgs: null, error: null });
        }
    }

    calculateNewAmount = (currentAmount: number, toAdd: number, mode: NumberMode) => {
        if (mode === NumberMode.Normal) {
            return (currentAmount * 10) + toAdd;
        }

        const intPart = Math.floor(currentAmount);
        const decimals = currentAmount - intPart;

        const newDecimals = (decimals * 10) + toAdd;
        return (newDecimals >= 1 ? (intPart * 10) : intPart) + newDecimals;
    }

    setCharged = (val: number) => {
        const { lastInputSource, mode } = this.state;

        const currentAmount = this.getEnteredValue();
    
        const newVal = lastInputSource === InputSource.NumberPad ? this.calculateNewAmount(currentAmount, val, mode) : val;

        this.setState({ amountToPay: newVal.toFixed(2), lastInputSource: InputSource.NumberPad, errorKey: null, errorArgs: null, error: null });
    }

    setDecimalPoint = () => {
        const currentAmount = this.getEnteredValue();
        const newVal = currentAmount * 100;
        this.setState({ amountToPay: newVal.toFixed(2), lastInputSource: InputSource.NumberPad, mode: NumberMode.Decimals, errorKey: null, errorArgs: null, error: null });
    }

    clearCharged = () => {
        this.setState({ amountToPay: '', lastInputSource: InputSource.NumberPad, mode: NumberMode.Normal });
    }

    processRefund = (paymentMethod: PaymentMethod) => {
        const { amountToPay } = this.state;
        const { bill, closeOverlay } = this.props;
        const refundAmount = parseFloat(amountToPay);

        if (isNaN(refundAmount) || refundAmount === 0) {
            this.setState({ errorKey: 'PointOfSale:EnterRefundAmount', errorArgs: null, error: null });
        } else {
            this.props.showOverlay(<RefundForm amount={refundAmount} paymentMethod={paymentMethod} bill={bill} refund={this.refund} cancel={closeOverlay} />)
        }
    }

    refund = (paymentMethod: PaymentMethod, amount: number, reason: string, callback: (success: boolean, error: api.ApiError | null) => void) => {
        const { bill, recordPayment, close } = this.props;

        recordPayment(bill.id, paymentMethod, amount, null, reason, (success: boolean, bill: Bill | null, error: api.ApiError | null) => {
            if (!success) {
                callback(false, error);
            } else {
                if (paymentMethod.openCashDrawer) {
                    this.props.openTill();
                }

                this.setState({ errorKey: null, errorArgs: null, error: null });
                close(bill);
            }
        });
    }

    pay = (paymentMethod: PaymentMethod) => {
        const { t } = this.context;
        const { bill, payment, recordPayment, close } = this.props;
        const { amountToPay, balance} = this.state;
        const paymentAmount = parseFloat(amountToPay);

        if (isNaN(paymentAmount) || paymentAmount === 0) {
            this.setState({ errorKey: 'PointOfSale:EnterPaymentAmount', errorArgs: null, error: null });
        } else {
            const isCash = paymentMethod.type === PaymentMethodType.Cash;
            const amountPaid = isCash ? Math.min(paymentAmount, balance) : paymentAmount;
            const change = isCash ? paymentAmount - amountPaid : 0;
            const paymentId = payment ? payment.id : null;

            if (paymentMethod.paymentGatewayMinimumPaymentAmount && paymentMethod.paymentGatewayMinimumPaymentAmount > amountPaid) {
                this.setState({ errorKey: 'PointOfSale:AmountBelowGatewayMinimum', errorArgs: { minAmount: paymentMethod.paymentGatewayMinimumPaymentAmount.toFixed(2) }, error: null });
            } else {
                this.setState({ errorKey: null, error: null });
                recordPayment(bill.id, paymentMethod, amountPaid, paymentId, null, (success: boolean, bill: Bill | null, error: api.ApiError | null) => {
                    if (success && bill) {
                        if (paymentMethod.openCashDrawer) {
                            this.props.openTill();
                        }

                        if (change > 0) {
                            this.setState({ showChange: true, paid: true, changeAmount: change, errorKey: null, errorArgs: null, error: null });
                        } else {
                            this.setState({ errorKey: null, errorArgs: null, error: null });
                            close(bill);
                        }
                    } else {
                        this.setState({ errorKey: null, errorArgs: null, error: error });
                    }
                });
            }
        }
    }

    addScheduledPayment = (description: string, dueDate: Date) => {
        const { bill, schedulePayment, close, closeOverlay } = this.props;
        const { amountToPay, balanceIncludingDuePayments } = this.state;
        const paymentAmount = parseFloat(amountToPay);
        const isSecurityPayment = paymentAmount < balanceIncludingDuePayments && bill.payments.filter(p => p.isSecurityPayment && !p.void && !p.deleted).length === 0;

        if (isNaN(paymentAmount) || paymentAmount === 0) {
            this.setState({ errorKey: 'PointOfSale:EnterPaymentAmount', error: null});
        } else {
            const amountPaid = Math.min(paymentAmount, balanceIncludingDuePayments);
            schedulePayment(bill.id, amountPaid, description, dueDate, isSecurityPayment, (success: boolean, bill: Bill | null, error: api.ApiError | null) => {
                if (success) {
                    this.setState({ errorKey: null, errorArgs: null, error: null });
                    closeOverlay();
                    close(bill);
                } else {
                    this.setState({ errorKey: null, errorArgs: null, error: error });
                }
            });
        }
    }

    close = () => {
        this.props.close(this.props.bill);
    }

    schedulePayment = () => {
        const { t } = this.context;
        const { amountToPay, balanceIncludingDuePayments } = this.state;
        const { booking, paymentMethods, isFirstPayment, timeFormat, dateFormat } = this.props;
        const paymentAmount = parseFloat(amountToPay);
        const isSecurityPayment = isFirstPayment && paymentAmount < balanceIncludingDuePayments;
        const description = isSecurityPayment ? t('PointOfSale:deposit') : '';

        if (isNaN(paymentAmount) || paymentAmount === 0) {
            this.setState({ errorKey: 'PointOfSale:EnterPaymentAmount', errorArgs: null, error: null });
        } else if (booking) {
            this.props.showOverlay(<SchedulePayment
                amount={paymentAmount}
                eventDate={booking.firstEventStartDateTime}
                isSecurityPayment={isSecurityPayment}
                paymentMethods={paymentMethods}
                paymentDescription={description}
                timeFormat={timeFormat}
                dateFormat={dateFormat}
                schedule={this.addScheduledPayment}
                cancel={this.props.closeOverlay} />)
        }
    }

    render() {
        const { t } = this.context;
        const { booking, payment, bill, paymentMethods, customerCategories, isRefund, paymentInProgrss, paymentError, editRefund, timeFormat, dateFormat } = this.props;
        const { balance, amountToPay, showChange, changeAmount, errorKey, errorArgs, error } = this.state;

        const paymentHandler = (paymentMethod: PaymentMethod) => {
            if (isRefund) {
                this.processRefund(paymentMethod);
            } else {
                this.pay(paymentMethod);
            }
        }

        return (
            <div className='pos_flex_wrapper'>
                <div className='pos_bill_panel'>
                    <BillPanel
                        bill={bill}
                        bookingCancelled={booking != null && booking.cancelled}
                        customerCategories={customerCategories}
                        editRefund={editRefund}
                        timeFormat={timeFormat}
                        dateFormat={dateFormat} />
                </div>
                <div className='pos_payment_panel'>
                    <div className='pos_payment_main_panel'>
                        {this.renderHeader(balance, amountToPay, showChange, changeAmount, payment ? true : false, isRefund, paymentError || error, errorKey, errorArgs)}
                        <div className='pos_payment_buttons'>
                            <div className='pos_payment_cash_buttons'>
                                {[1, 5, 10, 20, 50].map(x => <button key={`inc-${x}`} disabled={paymentInProgrss} className='btn btn-primary' onClick={e => clickHandler(e, () => this.incrementCharged(x))}>{x}</button>)}
                            </div>
                            <div className='pos_payment_number_buttons'>
                                <div className='pos_payment_button_col'>
                                    {[1, 4, 7].map(x => <button key={`npb-${x}`} disabled={paymentInProgrss} className='btn btn-basic pos-btn' onClick={e => clickHandler(e, () => this.setCharged(x / 100))}>{x}</button>)}
                                    <button key={`npb-.`} disabled={paymentInProgrss} className='btn btn-basic pos-btn' onClick={e => clickHandler(e, () => this.setDecimalPoint())}>{t('Global:decimalSymbol')}</button>
                                </div>
                                <div className='pos_payment_button_col'>
                                    {[2, 5, 8, 0].map(x => <button key={`npb-${x}`} disabled={paymentInProgrss} className='btn btn-basic pos-btn' onClick={e => clickHandler(e, () => this.setCharged(x / 100))}>{x}</button>)}
                                </div>
                                <div className='pos_payment_button_col'>
                                    {[3, 6, 9].map(x => <button key={`npb-${x}`} disabled={paymentInProgrss} className='btn btn-basic pos-btn' onClick={e => clickHandler(e, () => this.setCharged(x / 100))}>{x}</button>)}
                                    <button className='btn pos-btn btn-warning' disabled={paymentInProgrss} onClick={e => clickHandler(e, this.clearCharged)}>{t('Global:clearCharacter')}</button>
                                </div>
                            </div>
                            <div className='pos_payment_split_buttons'>
                                <button className='btn btn-basic' disabled={paymentInProgrss} onClick={e => clickHandler(e, () => this.calculateFraction(1))}>{t('Global:all')}</button>
                                <button className='btn btn-basic' disabled={paymentInProgrss} onClick={e => clickHandler(e, () => this.calculateFraction(2))}>1/2</button>
                                <button className='btn btn-basic' disabled={paymentInProgrss} onClick={e => clickHandler(e, () => this.calculateFraction(3))}>1/3</button>
                                <button className='btn btn-basic' disabled={paymentInProgrss} onClick={e => clickHandler(e, () => this.calculateFraction(this.getEnteredValueAsInt()))}>1/n</button>
                                <button className='btn btn-basic' disabled={paymentInProgrss} onClick={e => clickHandler(e, () => this.calculatePercentage(this.getEnteredValueAsInt()))}>%</button>
                            </div>
                        </div>
                        <div className='pos_payment_actions'>
                            {/*<button className='btn pos-btn'>Act 1</button>
                            <button className='btn pos-btn'>Act 2</button>
                            <button className='btn pos-btn'>Act 3</button>*/}
                        </div>
                    </div>
                    <div className='pos_payment_types'>
                        {paymentMethods.map(pm => <button key={pm.id} disabled={paymentInProgrss} className='btn btn-basic' onClick={e => clickHandler(e, () => paymentHandler(pm))}>{pm.pointOfSaleText}</button>)}
                        {(booking && !payment && !isRefund) ? <button key='schedule' disabled={paymentInProgrss} className='btn btn-info' onClick={e => clickHandler(e, this.schedulePayment)}>{this.context.t('PointOfSale:schedulePayment')}</button> : null}
                        <button key='close' disabled={paymentInProgrss} className='btn btn-danger' onClick={e => clickHandler(e, this.close)}>{this.context.t('Global:close')}</button>
                    </div>
                </div>
            </div>
        );
    }

    renderHeader = (balance: number, amountToPay: string, showChange: boolean, changeAmount: number, scheduledPayment: boolean, isRefund: boolean, paymentError: api.ApiError | null, errorKey: string | null, errorArgs: any | null) => {
        const { t } = this.context;

        if (showChange) {
            return (
                <div className='pos_payment_header'>
                    <div className='pos_payment_change_panel'>{`${t('PointOfSale:changeDue')}: ${t('Global:currencySymbol')} ${changeAmount.toFixed(2)}`}</div>
                </div>
            );
        }

        const chargedText = !scheduledPayment && isRefund ? <span className='pos_payment_panel_payment_name'>{(isRefund ? t('PointOfSale:refundAmount') : '')}:</span> : <span>{t('PointOfSale:charged')}:</span>;

        let error = null;
        if (paymentError) {
            error = <ApiError error={ paymentError} />
        } else if (!isNullOrEmpty(errorKey)) {
            error = <div className='pos_error alert-danger'> {t(errorKey, errorArgs)}</div>
        }

        const inlineControlStyle: React.CSSProperties = ({ minHeight: '10px', margin: '0 6px', maxWidth: '160px', display: 'inline-block' });
        { /* Safari has decided to fuck up their rendering.  Nasty hack to work around this is to clear the height and remove vertical padding */ }
        const inputStyle: React.CSSProperties = ({ fontSize: '20pt', textAlign: 'right', height: 'auto', padding: '0 12px' });
        return (
            <>
                <div className='pos_payment_header'>
                    <div>
                        <span>{t(isRefund ? 'PointOfSale:totalPaid' : 'PointOfSale:balance')}:</span>
                        <span className='text-right right'>{t('Global:currencySymbol')} {balance.toFixed(2)}</span>
                    </div>
                    <div>
                        {chargedText}
                        <span className='right'>{t('Global:currencySymbol')}
                            <ct.NumericText id='amountToPay' labelKey='' placeholderKey='' value={ct.asFormValue('amountToPay', amountToPay)} min={1} callback={val => this.setState({ amountToPay: typeof val === 'string' ? val : val.toFixed(2), lastInputSource: InputSource.NumberPad, errorKey: null })} style={inlineControlStyle} inputStyle={inputStyle} minimal={true} inputRef={this.amountInput} />
                        </span>
                    </div>
                </div>
                {error}
            </>
        );
    }
        
}