
import '../../../css/reactdatetime.css';

import * as React from 'react';
import * as PropTypes from 'prop-types'
import moment from 'moment';

import * as ct from '../../global/controls';
import * as v from '../../global/validation';

import * as api from '../../../store/apiClient';

import { clickHandler } from '../../../utils/util';
import { Bill, deserializeBill } from '../../../store/pages/pointOfSale/types';
import { ValidationError } from '../../../store/global/types';
import ValidationSummary from '../../global/validationSummary';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';

interface EditScheduledPaymentProps {
    billId: string;
    paymentId: string;
    amount: number;
    paymentDate: Date;
    eventDate: Date | null;
    paymentDescription: string;
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
    paymentUpdated: (bill: Bill) => void;
    cancel: () => void;
}

interface EditScheduledPaymentState {
    description: ct.FormValue<string>;
    dueDate: ct.FormValue<moment.Moment>;
    amount: ct.FormValue<number>;
    confirmDelete: boolean;
    saving: boolean;
    saveError: api.ApiError | null;
    saveValidationErrors: ValidationError[];
}

interface IUpdatePaymentResponse {
    bill: Bill;
}


export default class EditScheduledPayment extends React.Component<EditScheduledPaymentProps, EditScheduledPaymentState> {

    today: Date;

    constructor(props: EditScheduledPaymentProps) {
        super(props);

        this.today = new Date().datePart();

        this.state = {
            description: this.validateDescription(props.paymentDescription || ''),
            dueDate: this.validateDueDate(moment(props.paymentDate)),
            amount: this.validateAmount(props.amount),
            confirmDelete: false,
            saving: false,
            saveError: null,
            saveValidationErrors: []
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    updatePayment = () => {
        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {
            const { description, dueDate, amount } = this.state;
            this.savePaymentChanges({ description: description.value, amount: amount.value, paymentDueDate: dueDate.value });
        }
    }

    savePaymentChanges = (body: any) => {
        const { billId, paymentId, paymentUpdated } = this.props;
        api.putWithAuth(`api/v1/bill/${billId}/payment/${paymentId}`, body, () => ({ /* TODO: Handle auth error*/ }))
            .subscribe(response => {
                const mpr = response.response as IUpdatePaymentResponse;
                if (mpr) {
                    const mappedBill = deserializeBill(mpr.bill);
                    paymentUpdated(mappedBill);
                }
            }, (err: api.ApiError) => {
                this.setState({ saving: false, saveError: err, saveValidationErrors: err.validationErrors });
            });
    }

    deletePayment = () => {
        this.setState({ confirmDelete: true });
    }

    confirmDeletePayment = () => this.savePaymentChanges({ deleted: true });

    cancelDeletePayment = () => {
        this.setState({ confirmDelete: false });
    }

    dueDateChanged = (date: moment.Moment) => {
        // strip time from date
        var dateVal = date.clone().startOf('day');
        this.setState({ dueDate: this.validateDueDate(dateVal) });
    }

    validateInRange = (date: Date) => {
        const { eventDate} = this.props;
        // Only validate if date has changed
        if (eventDate && date !== this.props.paymentDate && date > eventDate) {
            return 'PointOfSale:validation:dateAfterEventDate';
        }

        return undefined;
    }

    validateAmountInRange = (amount: number) => {
        if (amount <= 0) {
            return 'PointOfSale:NonZeroAmount';
        }

        return undefined;
    }

    validateDescription = (val: string) => v.validate(val, 'description', [v.required], []);
    validateAmount = (val: number) => v.validate(val, 'amount', [v.required, v.numeric], []);
    validateDueDate = (val: moment.Moment) => v.validate(val, 'dueDate', [v.required, this.validateInRange], []);

    render() {
        const { eventDate, timeFormat, dateFormat } = this.props;
        const { description, dueDate, amount, confirmDelete, saveError, saveValidationErrors } = this.state;
        const { t } = this.context;

        return (
            <div style={({ width: '100%', position: 'relative' })}>
                <div style={({ maxWidth: '480px', margin: '0 auto' })}>
                    <h3>{t('PointOfSale:editSchedulePayment')}</h3>

                    <form className='data-form' onSubmit={e => e.preventDefault()} autoComplete='off'>
                        <div className='row'>
                            <div className='col-sm-12'>
                                {eventDate ? (<><label>{t('PointOfSale:eventDate')}</label> <span>{eventDate.toLongDateString(dateFormat, t)} {eventDate.toShortTimeString(timeFormat)}</span></>) : null}
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12'>
                                <ct.TextBox id='description' labelKey='Global:description' placeholderKey='Global:description' value={description} callback={val => this.setState({ description: this.validateDescription(val) })} />
                            </div>

                            <div className='col-sm-12'>
                                <ct.NumberBox id='amount' labelKey='PointOfSale:paymentAmount' placeholderKey='PointOfSale:paymentAmount' value={amount} callback={val => this.setState({ amount: this.validateAmount(val || 0) })} />
                            </div>

                            <div className='col-sm-12'>
                                <ct.DatePicker id='dueDate' labelKey='PointOfSale:dueDate' value={dueDate} callback={this.dueDateChanged} />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='form-group col-sm-12 button-panel'>
                                <button className='btn btn-primary' onClick={e => clickHandler(e, this.updatePayment)}>{t('Global:update')}</button>
                                <button className='btn btn-danger' onClick={e => clickHandler(e, this.deletePayment)}>{t('Global:delete')}</button>
                                <button onClick={e => clickHandler(e, this.props.cancel)} className='btn btn-basic'>{t('Global:cancel')}</button>
                            </div>
                        </div>
                        <div className='row'>
                            <ValidationSummary error={saveError} keyPrefix='PointOfSale' validationMessages={saveValidationErrors} t={t} />
                        </div>
                    </form>
                </div>
                {this.renderOverlay(confirmDelete)}
            </div>
        );
    }

    renderOverlay = (showOverlay: boolean) => {
        const { t } = this.context;
        if (showOverlay) {
            return (
                <div className='overlay'>
                    <div className='overlay-content'>
                        <div style={({ maxWidth: '500px', margin: '0 auto'})}>
                            <div className='row' style={({ marginTop: '80px' })}>
                                <div className='col-xs-12 text-center'>
                                    <span style={({ fontSize: '22px' })}>{t('PointOfSale:confirmDeletePayment')}</span>
                                </div>
                            </div>
                            <div className='row' style={({ marginTop: '50px' })}>
                                <div className='form-group col-sm-12 button-panel'>
                                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.confirmDeletePayment)}>{t('Global:yes')}</button>
                                    <button onClick={e => clickHandler(e, this.cancelDeletePayment)} className='btn btn-basic'>{t('Global:cancel')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}