
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'
import { ApplicationState } from '../../../store';
import * as it from '../../../store/pages/integrations/types';
import {IntegrationsState} from '../../../store/pages/integrations/types';
import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import * as IntegrationActions from '../../../store/pages/integrations/actions';
import * as ModalActions from '../../../store/global/modal/actions';
import TransactionalEmailForm from './transactionalEmail/transactionalEmailForm';
import { clickHandler } from '../../../utils/util';
import ApiError from '../../global/apiError';
import { IntegrationType } from '../../../store/pages/venues/types';

interface LocalProps {
    isNew: boolean;
    integration: it.Integration | null;
}

type Actions = typeof IntegrationActions.actionCreators & typeof ModalActions.actionCreators

type IntegrationFormProps = IntegrationsState
    & Actions
    & LocalProps;

interface IntegrationFormState {
    integrationType: ct.FormValue<it.IntegrationType | 0>;
    name: ct.FormValue<string>;
    implementationType: ct.FormValue<it.IntegrationImplementationType | -1>;
    settings: ct.FormValue<string>;
    archived: ct.FormValue<boolean>;
}

class IntegrationForm extends React.Component<IntegrationFormProps, IntegrationFormState> {

    constructor(props: IntegrationFormProps) {
        super(props);

        this.state = this.buildStateFromProps(props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps(props: IntegrationFormProps): IntegrationFormState {

        const { isNew, integration } = props;

        return {
            integrationType: this.validateIntegrationType((isNew || !integration) ? 0 : integration.integrationType),
            name: this.validateName((isNew || !integration) ? '' : integration.name || ''),
            implementationType: this.validateImplementationType((isNew || !integration) ? 0 : integration.implementationType),
            settings: v.validate((isNew || !integration) ? '' : integration.settings, 'settings', []),
            archived: ct.asFormValue('archived', isNew || !integration || !integration.archived ? false : true),
        };
    }

    componentDidUpdate(prevProps: IntegrationFormProps) {
        if (this.props.saveComplete && !prevProps.saveComplete) {
            setTimeout(() => { this.close(); }, 750);
        }
    }

    integrationTypeChanged = (newValue: string) => {
        const val = parseInt(newValue);
        if (!isNaN(val)) {
            this.setState({
                integrationType: this.validateIntegrationType(val),
                implementationType: this.validateImplementationType(val === IntegrationType.ViewPointVideo ? it.IntegrationImplementationType.None : -1),
            });
        }
    }

    implementationSettingsChanged = (implementationType: ct.FormValue<it.IntegrationImplementationType | -1>, settings: ct.FormValue<string>) => {
        this.setState({ implementationType: implementationType, settings: settings });
    }

    validateIntegrationType = (val: it.IntegrationType | 0) => v.validate(val, 'integrationType', [v.numeric, selection => it.IntegrationType.hasOwnProperty(selection) ? undefined : 'validation:required'], this.props.validationErrors);
    validateName = (val: string) => v.validate(val, 'name', [v.required], this.props.validationErrors);
    validateImplementationType = (val: it.IntegrationImplementationType | -1) => v.validate(val, 'integrationType', [v.numeric, selection => it.IntegrationImplementationType.hasOwnProperty(selection) ? undefined : 'validation:required'], this.props.validationErrors);

    saveIntegration = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }
    private close = () => {
        this.props.closeModal();
    }

    private save = () => {
        const { isNew, integration } = this.props;
        const { integrationType, name, implementationType, settings, archived } = this.state;

        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {
            if (integrationType.value === 0) {
                // TODO: Show error message!
            } else {
                const integrationId = isNew || !integration ? null : integration.id;
                const implType = implementationType.value === -1 ? it.IntegrationImplementationType.None : implementationType.value;
                this.props.saveIntegration(isNew, integrationId, integrationType.value, name.value, implType, settings.value, archived.value);
            }
        }
    }

    render() {

        const { name, integrationType, implementationType, settings, archived } = this.state;
        const { integration, isNew, saveError, saveComplete } = this.props;
        const { t } = this.context;   
        let message: any = null;

        if (saveError) {
            message = <ApiError error={saveError} />;
        } else if (saveComplete) {
            message = (<div className='bg-success'>{t('Global:saveComplete')}</div>);
        }

        const integrationTypeOptions = [
            { key: '0', name: t('TransactionalEmail:selectIntegrationType') },
            { key: it.IntegrationType.TransactionalEmail.toString(), name: t(`IntegrationType:${it.IntegrationType[it.IntegrationType.TransactionalEmail].toString()}`) },
            { key: it.IntegrationType.MarketingEmail.toString(), name: t(`IntegrationType:${it.IntegrationType[it.IntegrationType.MarketingEmail].toString()}`) },
            { key: it.IntegrationType.ViewPointVideo.toString(), name: t(`IntegrationType:${it.IntegrationType[it.IntegrationType.ViewPointVideo].toString()}`) }
        ];

        const integrationTypeFld = isNew
            ? (<ct.Select id='integrationType' labelKey='IntegrationForm:integrationType' value={({ ...integrationType, value: integrationType.value.toString() })} callback={this.integrationTypeChanged} options={integrationTypeOptions} />)
            : <h3>{t(`IntegrationType:${(it.IntegrationType[integrationType.value] || '').toString()}`)}</h3>;

        const saveButtonProps = v.isValid(this.state) ? {} : { 'disabled': true };

        return <div className='integrationForm'>
            <h1 className='integration_title'>{isNew ? t('IntegrationForm:addIntegration') : name.value}</h1>

            <form className='data-form' onSubmit={this.saveIntegration} autoComplete='off'>
                {integrationTypeFld}

                <ct.TextBox id='name' labelKey='Global:name' placeholderKey='Global:name' value={name} callback={val => this.setState({ name: this.validateName(val) })} />

                {this.renderIntegrationType(integrationType.value, implementationType, settings, isNew, !isNew && integration ? integration.id : null)}

                {!isNew ? <ct.Checkbox id='archived' labelKey='Global:archive' value={archived} callback={val => this.setState({ archived: ct.asFormValue('archived', val) })} /> : null}

                {message}

                <p />
                <div className='btn-toolbar'>
                    <button className='btn btn-primary' {...saveButtonProps} onClick={e => clickHandler(e, this.save)}>{t('Global:save')}</button>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{t('Global:cancel')
                    }</button>
                </div>
            </form>
        </div>;
    }

    renderIntegrationType = (integrationType: it.IntegrationType | 0, implementationType: ct.FormValue<it.IntegrationImplementationType | -1>, settings: ct.FormValue<string>, isNew: boolean, integrationId: string | null) => {
        switch (integrationType) {
            case it.IntegrationType.TransactionalEmail:
            case it.IntegrationType.MarketingEmail:
                return <TransactionalEmailForm isNew={isNew} integrationId={integrationId} implementationType={implementationType} settings={settings} valueChanged={this.implementationSettingsChanged} validationErrors={this.props.validationErrors} />;
            default:
                return null;
        }
    }
}

const mapStateToProps = (state: ApplicationState) => state.integrations;
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(Object.assign({}, IntegrationActions.actionCreators, ModalActions.actionCreators), dispatch);

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,           // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(IntegrationForm);
