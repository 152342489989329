
import { Action } from 'redux';
import { LocationChangeAction } from 'react-router-redux';

import { ReceptionActionTypes, ReceptionState } from './types';
import { ReceptionActions, getReceptionView } from './actions'
import { createReducer } from '../../reducer';
import { parseLocalDateTime } from '../../../utils/util';

const unloadedState: ReceptionState = {
    isActive: false,
    isLoadingRegistrations: false,
    isLoadingEvents: false,
    selectedDate: new Date(),
    registrations: [],
    events: [],
    expandedEvents: [],
    loadRegistrationsError: null,
    loadEventsError: null,
    isSaving: false,
    saveError: null,
    selectedView: getReceptionView()
};

export const reducer = (state: ReceptionState, incomingAction: Action) => createReducer(state, incomingAction, unloadedState, reducerHandler);

export const reducerHandler = (state: ReceptionState, incomingAction: Action): ReceptionState => {

    if (incomingAction.type === '@@router/LOCATION_CHANGE') {
        const locationChangeAction = incomingAction as LocationChangeAction;
        if (locationChangeAction) {
            return { ...state, isActive: locationChangeAction.payload.pathname === '/registrations' };
        }
    }

    const action = incomingAction as ReceptionActions;
    switch (action.type) {
        case ReceptionActionTypes.SwitchDate:
            return {
                ...state,
                selectedDate: action.date,
                expandedEvents:[]
            };
        case ReceptionActionTypes.RequestRegistrations:
            return {
                ...state,
                isLoadingRegistrations: true,
                loadRegistrationsError: null
            };
        case ReceptionActionTypes.ReceivedRegistrations:
            return {
                ...state,
                isLoadingRegistrations: false,
                registrations: action.registrations.map(r => ({ ...r, dateOfBirth: r.dateOfBirth ? parseLocalDateTime(r.dateOfBirth) : null })),
                loadRegistrationsError: action.error
            };

        case ReceptionActionTypes.RequestEvents:
            return {
                ...state,
                isLoadingEvents: true,
                loadEventsError: null
            };
        case ReceptionActionTypes.ReceivedEvents:
            return {
                ...state,
                isLoadingEvents: false,
                events: (action.events || []).map(e => ({
                    ...e,
                    startTime: parseLocalDateTime(e.startTime),
                    endTime: parseLocalDateTime(e.endTime),
                    reservations: e.reservations.map(r => ({ ...r, eventName: e.name, colour: e.colour, startTime: parseLocalDateTime(r.startTime), endTime: parseLocalDateTime(r.endTime) })),
                    bookings: e.bookings.map(b => ({ ...b, notes: b.notes.map(n => ({ ...n, createDateTime: parseLocalDateTime(n.createDateTime) })) })),
                    version: new Date().getTime(),
                })),
                loadEventsError: action.error
            };

        case ReceptionActionTypes.AddRegistrationsToBooking:
            return {
                ...state,
                isSaving: true,
                saveError: null
            };
        case ReceptionActionTypes.AddRegistrationsToBookingSuccess:
            return {
                ...state,
                isSaving: false,
                saveError: null
            };
        case ReceptionActionTypes.AddRegistrationsToBookingFailed:
            return {
                ...state,
                isSaving: false,
                saveError: action.error
            };
        case ReceptionActionTypes.CheckInCustomers:
            return {
                ...state,
                isSaving: true,
                saveError: null
            };
        case ReceptionActionTypes.CheckInCustomersSuccess:
            return {
                ...state,
                isSaving: false,
                saveError: null
            };
        case ReceptionActionTypes.CheckInCustomersFailed:
            return {
                ...state,
                isSaving: false,
                saveError: action.error
            };
        case ReceptionActionTypes.UndoCheckedInCustomers:
            return {
                ...state,
                isSaving: true,
                saveError: null
            };
        case ReceptionActionTypes.UndoCheckedInCustomersSuccess:
            return {
                ...state,
                isSaving: false,
                saveError: null
            };
        case ReceptionActionTypes.UndoCheckedInCustomersFailed:
            return {
                ...state,
                isSaving: false,
                saveError: action.error
            };
        case ReceptionActionTypes.Unregister:
            return {
                ...state,
                isSaving: true,
                saveError: null
            };
        case ReceptionActionTypes.UnregisterSuccess:
            return {
                ...state,
                isSaving: false,
                saveError: null
            };
        case ReceptionActionTypes.UnregisterFailed:
            return {
                ...state,
                isSaving: false,
                saveError: action.error
            };
        case ReceptionActionTypes.RegistrationsChanged:
            return state;
        case ReceptionActionTypes.CustomerCheckedIn:
            return state;
        case ReceptionActionTypes.UpdateRegistration:
            return {
                ...state,
                isSaving: true,
                saveError: null
            };
        case ReceptionActionTypes.UpdateRegistrationSuccess:
            return {
                ...state,
                isSaving: false,
                saveError: null
            };
        case ReceptionActionTypes.UpdateRegistrationFailed:
            return {
                ...state,
                isSaving: false,
                saveError: action.error
            };
        case ReceptionActionTypes.ExpandEvent:
            return {
                ...state,
                expandedEvents: state.expandedEvents.concat([action.eventId])
            }
        case ReceptionActionTypes.CollapseEvent:
            return {
                ...state,
                expandedEvents: state.expandedEvents.filter(eid => eid !== action.eventId)
            }
        case ReceptionActionTypes.SetReceptionView:
            return {
                ...state,
                selectedView: action.view
            }
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

