
import * as React from 'react';
import * as api from '../../store/apiClient';
import { ValidationError } from '../../store/global/types';
import { isNullOrEmpty } from '../../utils/util';

interface ValidationSummaryProps {
    error: api.ApiError | string | null;
    keyPrefix: string;
    validationMessages: ValidationError[];
    getFieldFromError?: (field: string) => string;
    t: (key: string) => string;
}

const ValidationSummary = (props: ValidationSummaryProps) => {
    const { error, validationMessages, getFieldFromError, keyPrefix, t } = props;
    const msg = !error ? '' :
        (typeof error == 'string')
            ? t(error)
            : isNullOrEmpty(error.messageKey) ? error.message : t(error.messageKey);

    if (isNullOrEmpty(msg) && validationMessages.length === 0) {
        return null;
    }

    const fieldMapper = getFieldFromError ? getFieldFromError : (field: string) => t(`${keyPrefix}:${field}`);
    const summary = validationMessages ? renderValidationErrors(validationMessages, fieldMapper) : null;


    return (
        <div>
            <div className='alert alert-danger'>
                <div><strong>{msg}</strong></div>
                {summary}
            </div>
        </div>
    );
}

const renderValidationErrors = (errors: ValidationError[], getFieldFromError: (field: string) => string) => {
    if (!errors || errors.length === 0) {
        return;
    }

    return (
        <ul>
            {errors.map((e,ix) => <li key={ix.toString()}>{renderError(e, getFieldFromError)}</li>)}
        </ul>
    );
}

const renderError = (e: ValidationError, getFieldFromError: (field: string) => string) => {
    const field = getFieldFromError(e.field);
    const message = getMessageFromError(e);

    return `${field}: ${message}`;
}

const getMessageFromError = (e: ValidationError) => !isNullOrEmpty(e.messageKey) ? e.messageKey : '';

export default ValidationSummary;


