

import * as React from 'react';
import * as PropTypes from 'prop-types'

import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';

import * as api from '../../../../../store/apiClient';

import { AjaxResponse } from 'rxjs/ajax';
import { BillPayment, GatewayPaymentStatus } from '../../../../../store/pages/pointOfSale/types';
import { PaymentMethod } from '../../../../../store/pages/paymentMethods/types';
import { clickHandler, isNullOrEmpty } from '../../../../../utils/util';

interface StripeElementsPaymentProps {
    venueId: string;
    billId: string;
    payment: BillPayment;
    amount: number;
    paymentMethod: PaymentMethod;
    cancelStripePayment: (paymentGatewayPaymentId: string | null) => void;
    onSaveError: (saveError: api.ApiError) => void;
    onComplete: (billId: string) => void;
    logout: () => void;
}

interface StripeElementsPaymentState {
    initialized: boolean;
    paymentGatewayPaymentId: string | null;
    paymentAmount: number;
    token: string | null;
    publishabeKey: string | null;
    stripeUserId: string | null;
    clientSecret: string | null;
    processing: boolean;
    error: string | null;
}

interface ICreateStripeGatewayPaymentResponse {
    gatewayPayment: GatewayPayment;
    paymentAmount: number;
    token: string;
    publishabeKey: string;
    stripeUserId: string;
    clientSecret: string;
}

interface IGetGatewayPaymentResponse {
    status: GatewayPaymentStatus;
}

interface GatewayPayment {
    paymentGatewayPaymentId: string;
}


export default class StripeElementsPayment extends React.Component<StripeElementsPaymentProps, StripeElementsPaymentState> {

    constructor(props: StripeElementsPaymentProps) {
        super(props);

        this.state = {
            initialized: false,
            paymentGatewayPaymentId: null,
            paymentAmount: props.amount,
            token: null,
            publishabeKey: null,
            stripeUserId: null,
            clientSecret: null,
            processing: false,
            error: null
        }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        const { billId, payment, amount, paymentMethod } = this.props;
        this.initiateGatewayPayment(billId, payment, amount, paymentMethod);
    }

    initiateGatewayPayment = (billId: string, payment: BillPayment, amount: number, paymentMethod: PaymentMethod) => {
        const { logout, onSaveError } = this.props;
        const body = {
            venueId: this.props.venueId,
            billId: billId,
            billPaymentId: payment.id,
            paymentMethodId: paymentMethod.id,
            description: payment.description,
            isSecurityPayment: payment.isSecurityPayment,
            amount: amount,
            additionalInfo: payment.additionalInfo,
            offSession: true
        };

        const responseHandler = (response: AjaxResponse) => {
            const resp = response.response as ICreateStripeGatewayPaymentResponse;
            if (resp) {
                this.setState({
                    initialized: true,
                    paymentGatewayPaymentId: resp.gatewayPayment.paymentGatewayPaymentId,
                    paymentAmount: resp.paymentAmount,
                    token: resp.token,
                    publishabeKey: resp.publishabeKey,
                    stripeUserId: resp.stripeUserId,
                    clientSecret: resp.clientSecret
                });
            }
        }

        if (isNullOrEmpty(payment.id)) {
            api.postWithAuth(`api/v1/paymentGateway/payment/stripe`, body, logout).subscribe(responseHandler, onSaveError);
        } else {
            api.putWithAuth(`api/v1/paymentGateway/payment/stripe`, body, logout).subscribe(responseHandler, onSaveError);
        }
    }

    handleSubmit = async (event: React.FormEvent<HTMLFormElement>, stripe: Stripe | null, elements: StripeElements | null) => {
        event.preventDefault();
        if (!stripe || !elements) return;

        var cardElement = elements.getElement(CardElement);
        if (!cardElement) {
            return;
        }

        this.setState({ processing: true, error: null });

        try {
            const { error } = await stripe.confirmCardPayment(this.state.clientSecret || '', {
                payment_method: { card: cardElement }
            });

            if (error) {
                this.setState({ error: error.message || '', processing: false })
            } else {
                this.handlePaymentComplete(this.state.token || '');
            }
        } catch (e: any) {
            const msg = e.message ? e.message : e.toString()
            this.setState({ error: msg, processing: false })
        }
    };

    handlePaymentComplete = (token: string, retryCouunt: number = 0) => {
        api.putWithAuth(`api/v1/paymentGateway/customerPayment/stripe/check`, { token: token }, () => ({ /* TODO: Handle auth error*/ }))
            .subscribe(resp => {
                const response = resp.response as IGetGatewayPaymentResponse;
                if (response.status === GatewayPaymentStatus.Success) {
                    this.props.onComplete(this.props.billId)
                } else if (response.status === GatewayPaymentStatus.InProgress) {
                    // wait a coupe of seconds for processing to complete
                    setTimeout(() => this.handlePaymentComplete(token, retryCouunt + 1), 2000);
                } else {
                    this.setState({ error: this.context.t('ProcessStripePayment:failed') })
                }
            }, err => this.setState({ error: err }));
    }

    render() {
        const { t } = this.context;
        const { cancelStripePayment } = this.props;
        const { initialized, processing, paymentGatewayPaymentId, stripeUserId, publishabeKey, error } = this.state;

        if (!initialized || !publishabeKey)
            return this.renderInitializing();

        const buttonStyle: React.CSSProperties = { margin: '0 8px' }
        const options = stripeUserId ? { stripeAccount: stripeUserId } : {};

        return (
            <Elements stripe={loadStripe(publishabeKey, options)}>
                <ElementsConsumer>
                    {({ stripe, elements }) => (
                        <form onSubmit={e => this.handleSubmit(e, stripe, elements)}>
                            <div className='row'>
                                <div className='col-xs-12'>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-xs-12' style={{ display: 'flex',justifyContent: 'center' }}>
                                    <div style={{ maxWidth: '600px', flex: '1 1 100%' }}>
                                        <CardElement />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-xs-12 btn-toolbar-centered mt-15'>
                                    <button className='btn btn-primary' type="submit" style={buttonStyle} disabled={!stripe || processing}>{t('ProcessStripePayment:pay')}</button>
                                    <button className='btn btn-default' type='button' style={buttonStyle} onClick={e => clickHandler(e, () => cancelStripePayment(paymentGatewayPaymentId))}>{t('Global:cancel')}</button>
                                </div>
                            </div>
                            {processing ? <div className='row'><div className='col-xs-12 alert alert-info'>{t('ProcessStripePayment:processing')}</div></div> : null}
                            {error ? <div className='row'><div className='col-xs-12 alert alert-danger'>{error}</div></div> : null }
                        </form>

                    )}
                </ElementsConsumer>
            </Elements>
        )
    }

    renderInitializing = () => {
        const { t } = this.context;
        return <div className='row'><div className='col-xs-12 text-center'>{t('ProcessStripePayment:loading')}</div></div>
    }

    renderProcessing = () => {
        const { t } = this.context;
        return <div className='row'><div className='col-xs-12 text-center'>{t('ProcessStripePayment:processing')}</div></div>
    }
}