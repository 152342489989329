
import { AnalyticsRevenueBucket, VenueRevenueStats } from './../types';
import { DateFormat } from '../../../../store/pages/venues/types';
import { AnalyticsBucketResolution } from '../../../../store/pages/analytics/types';
import { formatXAxisTickWithDay } from './utils';

interface LineData {
    color: string;
    dataKey: string;
    name: string;
    value: string;
    payload: AnalyticsRevenueBucket;
}

interface ToolTipProps {
    active: boolean;
    payload: LineData[];
    label: any;
    venues: VenueRevenueStats[];
    dataKey: string;
    bucketResolution: AnalyticsBucketResolution;
    dateFormat: DateFormat;
    formatValue: (value: number, venue: VenueRevenueStats) => string;
    t: (val: string) => string;
}

const getValue = (item: any, dataKey: string) => {
    const val = item[dataKey];
    return val;
}

const CustomLineTooltip = (props: ToolTipProps) => {
    const { active, payload, label, venues, dataKey, bucketResolution, dateFormat, formatValue, t } = props;

    if (active && payload && payload.length) {

        const formatLabel = (lbl: string, venue: VenueRevenueStats) => {
            if (venues.length < 2) {
                return lbl;
            } else {
                return `${venue.venueName} - ${lbl}`;
            }
        }

        const item = payload[0];
        if (item.payload) {
            const { year, period } = item.payload;

            // find all data points for this peroid;
            const dataPoints: { year: number, period: number, key: string, value: number, colour: string, venue: VenueRevenueStats }[] = [];

            for (let ix = 0; ix < venues.length; ix++) {
                const v = venues[ix];
                const thisPdStatsForVenue = v.thisPeriodStats.periodBuckets.filter(b => b.year === year && b.period === period)[0];
                if (thisPdStatsForVenue) {
                    dataPoints.push({ year: thisPdStatsForVenue.year, period: thisPdStatsForVenue.period, key: formatLabel('This period', v), value: getValue(thisPdStatsForVenue, dataKey), colour: v.colour, venue: v })
                }
                const comparisonPdStatsForVenue = v.comparisonPeriodStats.periodBuckets.filter(b => b.year === year && b.period === period)[0];
                if (comparisonPdStatsForVenue) {
                    dataPoints.push({ year: comparisonPdStatsForVenue.year, period: comparisonPdStatsForVenue.period, key: formatLabel('Comparison', v), value: getValue(comparisonPdStatsForVenue, dataKey), colour: v.comparisonColour, venue: v })
                }
            }

            return (
                <div className='analytics-custom-tooltip'>
                    <div className='analytics-custom-tooltip-header'>{formatXAxisTickWithDay(label, bucketResolution, dateFormat, t)}</div>
                    {dataPoints.map(dp => <div className='analytics-custom-tooltip-item'>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div className='analytics-custom-tooltip-item-colour' style={{ borderColor: dp.colour }}></div>{dp.key}</div>
                        <div style={{marginLeft: '10px', textAlign: 'right'}}>{formatValue(dp.value, dp.venue)}</div>
                    </div>)}
                </div>
            );

        }
    }

    return null;
}

export default CustomLineTooltip;